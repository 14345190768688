import {Component, Input, OnInit} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {PdfServicesService} from '../../../services/pdf-services.service';
import {PatientHistoryService} from '../../../services/patient-history.service';

@Component({
  selector: 'app-prescription-report-data',
  templateUrl: './prescription-report-data.component.html',
  styleUrls: ['./prescription-report-data.component.css']
})
export class PrescriptionReportDataComponent implements OnInit {
@Input() controls: any;
@Input() prescriptionControlsRight: any;
@Input() prescriptionControlsLeft: any;
@Input() prescriptionData: any;
@Input() showPrescriptionHeaderImage: any;
@Input() Id: any;
@Input() visitId: any;
@Input() visitData: any;
 medicineAsTabular = false;
  patient: any;
  showProvisionalDia = [];
  showDifferentialDia = [];
  allConsultations = [];
  showFinalDia = [];
  showInvestigationsData = [];
  showProceduresData = [];
  gynaecologicalComplaint: any;
  obstetricalHistory: any;
  gynaecologicalHistory: any;
  patientGeneralHistory: any;
  patientPastHistory: any;
  patientSocialHistory: any;
  reviewSystemHistory: any;
  physicalSystemicData: any;
  pelvicExamination: any;
  chestExamination: any;
  gpe: any;
  abdomen: any;
  heent: any;
  skin: any;
  neurological: any;
  musculoskeletal: any;
  cardiovascular: any;
  obstetricalExamination: any;
  bodyHeight = 0;
  physicalExamination: any;
  isFamilyHistory = false;
  constructor(private ngxLoader: NgxUiLoaderService,
              private pdfService: PdfServicesService,
              private patientHistoryService: PatientHistoryService) { }

  fetchPrescriptionData(): any{
    this.prescriptionData.vitals = this.prescriptionData.vitals.reverse();
    this.showProvisionalDia = this.prescriptionData.diagnosis.filter(
      (m) => m.type === 'ProvisionalDiagnosis',
    );
    this.showDifferentialDia = this.prescriptionData.diagnosis.filter(
      (m) => m.type === 'DifferentialDiagnosis',
    );
    this.showFinalDia = this.prescriptionData.diagnosis.filter(
      (m) => m.type === 'FinalDiagnosis',
    );

    this.showInvestigationsData =
      this.prescriptionData.investigations.filter(
        (m) =>
          m.service_type === 'Radiology' ||
          m.service_type === 'LabInvestigation',
      );
    this.showProceduresData = this.prescriptionData.investigations.filter(
      (m) =>
        m.service_type !== 'Radiology' &&
        m.service_type !== 'LabInvestigation' &&
        m.service_type !== 'DoctorFee',
    );
    this.gynaecologicalComplaint =
      this.prescriptionData.gynaecological_presenting_complaint;
    this.obstetricalHistory =
      this.prescriptionData.patient_obstetrical_history;
    this.gynaecologicalHistory =
      this.prescriptionData.patient_gynaecological_history;
  }

  convertTo12HourFormat(time: string): string {
    if (!time) return '';

    const [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;

    return `${this.padZero(adjustedHours)}:${this.padZero(minutes)} ${period}`;
  }

  private padZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }
  fetchGeneralHistory(): any {
    this.pdfService.fetchGeneralHistoryV2(this.Id, this.visitId).subscribe(
      (data) => {
        if (data.patient_general_history) {
          this.patientGeneralHistory = data.patient_general_history;
          this.patientPastHistory =
            this.patientGeneralHistory.patient_past_history_attributes;
          this.patientSocialHistory =
            this.patientGeneralHistory.patient_social_history_attributes;
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        // toastr.error(err.error.message);
      },
    );
  }
  fetchReviewSystemHistory(): any {
    this.patient = {
      visit_id: this.visitId,
      id: this.Id,
    };
    this.patientHistoryService
      .fetchReviewSystemHistoryOpd(this.patient)
      .subscribe(
        (data) => {
          this.reviewSystemHistory = data.patient_review_system;
        },
        (err) => {},
      );
  }
  physicalSystemicExaminations(): any {
    this.ngxLoader.start();
    this.pdfService
      .getPhysicalSystemicExaminationsV2(this.visitId, this.Id)
      .subscribe(
        (data) => {
          this.physicalSystemicData = data.physical_systemic_examination;
          this.pelvicExamination =
            this.physicalSystemicData.patient_pelvic_examination_attributes;
          this.chestExamination =
            this.physicalSystemicData.patient_chest_history_attributes;
          this.gpe =
            this.physicalSystemicData.patient_gpe_history_attributes;
          this.abdomen =
            this.physicalSystemicData.patient_abdomen_history_attributes;
          this.heent =
            this.physicalSystemicData.patient_heent_history_attributes;
          this.skin =
            this.physicalSystemicData.patient_skin_history_attributes;
          this.neurological =
            this.physicalSystemicData.patient_neurological_history_attributes;
          this.musculoskeletal =
            this.physicalSystemicData.patient_musculoskeletal_history_attributes;
          this.cardiovascular =
            this.physicalSystemicData.patient_cardiovascular_history_attributes;
          this.obstetricalExamination =
            this.physicalSystemicData.patient_obstetrical_examination_attributes;
          this.physicalExamination =
            this.physicalSystemicData.patient_physical_examination_attributes;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  fetchPatientConsultation(): any {
    this.ngxLoader.start();
    this.allConsultations = [];
    this.pdfService
      .getConsultations(
        this.Id,
        '',
        false,
        '',
        this.visitId,
      )
      .subscribe(
        (data) => {
          this.allConsultations = data.ipd_doctors;
          if (this.allConsultations.length < 1) {
            // toastr.error('Data not found');
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          // toastr.error(err.error.message);
        },
      );
  }
  setBodyHeight(): any{
    let extraSpace = 0;
    if (this.controls.is_header) {
      if (this.showPrescriptionHeaderImage) {
        extraSpace = 350;
      } else {
        extraSpace = 270;
      }
    } else {
      extraSpace = 150 + (this.controls.header_height || 0);
    }
    if (this.controls.is_footer) {
      extraSpace += 110;
    } else {
      extraSpace += this.controls.footer_height || 0;
    }
    this.bodyHeight = 1270 - (extraSpace || 0);
    this.medicineAsTabular = this.controls.medicine_as_tabular;
  }
  ngOnInit(): void {
    this.setBodyHeight();
    if (this.Id) {
      if (this.visitId) {
        this.fetchGeneralHistory();
        this.fetchReviewSystemHistory();
        this.physicalSystemicExaminations();
        this.fetchPatientConsultation();
        this.fetchPrescriptionData();
        this.checkFamilyHistoryValues();
      }
    }
  }
  checkFamilyHistoryValues(): any{
    this.prescriptionData?.family_history.forEach((family: any) => {
      if (family.dm || family.hypertention ||
        family.ihd || family.arthritis ||
        family.cancer || family.stroke ||
        family.depression || family.tb) {
        this.isFamilyHistory = true;
      } else {
        this.isFamilyHistory = false;
      }
    });
  }

}
