<form [formGroup]="employeeVisitForm" (ngSubmit)="onSubmit()">
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1 w-100">
          <!--begin::Page Heading-->
          <div
            class="d-flex align-items-baseline justify-content-between flex-wrap mr-5 w-100"
          >
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5" *ngIf="!visitId">
              Add Visiting Employee
            </h5>
            <h5 class="text-dark font-weight-bold my-1 mr-5" *ngIf="visitId">
              Update Visiting Employee
            </h5>
            <button
              class="btn btn-sm btn-primary"
              [routerLink]="['/employee-visiting/list']"
            >
              <i class="fas fa-long-arrow-alt-left"></i> Back
            </button>
          </div>
          <!--end::Page Heading-->
        </div>
        <!--end::Info-->
        <!--begin::Toolbar-->
        <div class="d-flex align-items-center"></div>
        <!--end::Toolbar-->
      </div>
    </div>
    <div class="container-fluid">
      <div class="card card-custom gutter-b">
        <div class="card-body mt-1">
          <div class="row">
            <!--       1- Title-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Title <span class="text-danger">*</span></label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      isAdd && this.employeeVisitForm.controls.title.errors
                  }"
                >
                  <select
                    class="form-control form-control-sm bootstrap-select-container"
                    id="title"
                    data-container="body"
                    data-live-search="true"
                    formControlName="title"
                  >
                    <option value="''" [disabled]="true">Select title</option>
                    <ng-container *ngFor="let titles of title">
                      <option [value]="titles.id">{{ titles.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <!--        2- First Name-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>First Name <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm allowaplhabets"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  formControlName="first_name"
                  [ngClass]="{
                    'is-invalid':
                      isAdd && this.employeeVisitForm.controls.first_name.errors
                  }"
                />
              </div>
            </div>
            <!--   3- Last Name-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  name="last_name"
                  placeholder="Last Name"
                  formControlName="last_name"
                />
                <!-- [ngClass]="{'is-invalid': isAdd && this.basicDetails.controls.last_name.errors}">-->
              </div>
            </div>

            <!--        4- Gender*-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Gender <span class="text-danger">*</span></label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      isAdd && this.employeeVisitForm.controls.gender.errors
                  }"
                >
                  <select
                    name="gender"
                    id="gender"
                    class="form-control form-control-sm bootstrap-select-container"
                    formControlName="gender"
                    data-container="body"
                    data-live-search="true"
                  >
                    <option [value]="''" [disabled]="true">
                      Select Gender
                    </option>
                    <ng-container *ngFor="let gender of genderDetail">
                      <option [value]="gender.id">{{ gender.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Speciality</label>
                <div>
                  <select
                    name="gender"
                    id="speciality"
                    class="form-control form-control-sm"
                    formControlName="speciality"
                    data-container="body"
                    data-live-search="true"
                  >
                    <option [value]="''" [disabled]="true">
                      Select Speciality
                    </option>
                    <ng-container
                      *ngFor="let emp of allEmployeesStaffTypeDoctor"
                    >
                      <option [value]="emp.id">
                        {{ emp.first_name }} {{ emp.middle_name || "" }}
                        {{ emp.last_name }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <!--        5- National ID card-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>National ID Card No</label>
                <input
                  type="text"
                  (keypress)="_onlyNumeric($event)"
                  class="form-control form-control-sm"
                  placeholder="National ID Card No"
                  name="nic"
                  id="nic"
                  formControlName="nic"
                />
                <!--            [ngClass]="{'is-invalid': isAdd && this.employeeVisitForm.controls.nic.errors}">-->
              </div>
            </div>
            <!--        PMDC registration number-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>PMDC Registration Number</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="PMDC Registration Number"
                  name="nic"
                  id="pmdc_registration_no"
                  formControlName="pmdc_registration_no"
                />
              </div>
            </div>
            <!--        6- Primary Contact-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Primary Contact</label>
                <input
                  type="tel"
                  class="form-control form-control-sm"
                  (keypress)="_onlyNumeric($event)"
                  maxlength="11"
                  placeholder="Primary Contact"
                  name="primary_contact"
                  id="primary_contact"
                  formControlName="primary_contact"
                />
                <!--[ngClass]="{'is-invalid': isAdd && this.basicDetails.controls.primary_contact.errors}">-->
              </div>
            </div>
            <!-- Office Number-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Doctor Office Number</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="office_number"
                  placeholder="Office Number"
                />
              </div>
            </div>
            <!-- Mobile Number-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Doctor Mobile Number</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="mobile_number"
                  placeholder="Mobile Number"
                />
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Clinic / Hospital name </label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="hospital_name"
                  placeholder="Hospital name"
                />
              </div>
            </div>
            <!--Country-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Country <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm country"
                  id="country"
                  data-live-search="true"
                  data-container="body"
                  (change)="updateCountryFun($event.target.value)"
                  formControlName="country"
                  [ngClass]="{
                    'is-invalid':
                      isAdd && this.employeeVisitForm.controls.country.errors
                  }"
                >
                  <option value="" [selected]="true">select</option>
                  <ng-container *ngFor="let country of countries">
                    <option [value]="country.id">{{ country.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>State <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm"
                  formControlName="state"
                  (change)="updateStateFun($event.target.value)"
                  [ngClass]="{
                    'is-invalid':
                      isAdd && this.employeeVisitForm.controls.state.errors
                  }"
                >
                  <option value="" [disabled]="true">Select</option>
                  <ng-container *ngFor="let state of states">
                    <option [value]="state.id">{{ state.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>City <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm"
                  formControlName="city"
                  [ngClass]="{
                    'is-invalid':
                      isAdd && this.employeeVisitForm.controls.city.errors
                  }"
                >
                  <option value="" [disabled]="true">Select</option>
                  <ng-container *ngFor="let city of cities">
                    <option [value]="city.id">{{ city.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--        7- Address*-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Address <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="address"
                  placeholder="Address"
                  [ngClass]="{
                    'is-invalid':
                      isAdd && employeeVisitForm.controls.address.errors
                  }"
                />
              </div>
            </div>
            <!--Staff Type-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Staff Type <span class="text-danger">*</span></label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      isAdd && this.employeeVisitForm.controls.staff_type.errors
                  }"
                >
                  <select
                    class="form-control form-control-sm bootstrap-select-container"
                    name="staff_type"
                    id="staff_type"
                    formControlName="staff_type"
                    data-container="body"
                    data-live-search="true"
                  >
                    <option [value]="''">Select Staff Type</option>
                    <ng-container *ngFor="let stafftype of StaffType">
                      <option [value]="stafftype.id">
                        {{ stafftype.name }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <!--  9- Department*-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Department <span class="text-danger">*</span></label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      isAdd && this.employeeVisitForm.controls.department.errors
                  }"
                >
                  <select
                    name="department"
                    id="department"
                    class="form-control form-control-sm bootstrap-select-container selectPickerDepartment"
                    data-container="body"
                    data-live-search="true"
                    formControlName="department"
                  >
                    <option [value]="''" [disabled]="true">
                      Select Department
                    </option>
                    <ng-container *ngFor="let dep of depart">
                      <option [value]="dep.id">{{ dep.title }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <!--        10- Designation*-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Designation <span class="text-danger">*</span></label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      isAdd &&
                      this.employeeVisitForm.controls.designation.errors
                  }"
                >
                  <select
                    name="designation"
                    id="designation"
                    class="form-control form-control-sm bootstrap-select-container selectPickerDesignation"
                    data-container="body"
                    data-live-search="true"
                    formControlName="designation"
                  >
                    <option [value]="''" [disabled]="true">
                      Select Designation
                    </option>
                    <ng-container *ngFor="let desig of designation">
                      <option [value]="desig.id">{{ desig.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <!--        13- Status *-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Status <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm"
                  name="status"
                  id="status"
                  formControlName="status"
                  [ngClass]="{
                    'is-invalid':
                      isAdd && this.employeeVisitForm.controls.status.errors
                  }"
                >
                  <option [selected]="true" [disabled]="true">select</option>
                  <option [ngValue]="true">Active</option>
                  <option [ngValue]="false">Inactive</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-right">
        <div class="form-group">
          <button class="btn btn-primary" type="submit" *ngIf="!visitId">
            Save
          </button>
          <button class="btn btn-sm btn-primary" type="submit" *ngIf="visitId">
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
