import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HospitaldoctorService } from '../../services/hospitaldoctor.service';
import { ReportWritingComponent } from './report-writing/report-writing.component';
import { RadiologyPdfReportComponent } from './reports/radiology-pdf-report/radiology-pdf-report.component';
import { TemplatePrintComponent } from './template-print/template-print.component';
import { ReportPanelWiseNewComponent } from '../system-reports/report-panel-wise-new/report-panel-wise-new.component';
import { ReportWritingNewComponent } from './report-writing-new/report-writing-new.component';
import { PdfReportNewComponent } from './reports/pdf-report-new/pdf-report-new.component';
import { AuthorizationGuard } from '../../services/authorization.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AcknowledgementV2Component } from './acknowledgement-v2/acknowledgement-v2.component';
import { ReportWritingNewV2Component } from './report-writing-new-v2/report-writing-new-v2.component';
import { ReportAddendumV2Component } from './report-addendum-v2/report-addendum-v2.component';
import { PdfReportNewV2Component } from './reports/pdf-report-new-v2/pdf-report-new-v2.component';
import { DeliveryReportsV2Component } from './reports/delivery-reports-v2/delivery-reports-v2.component';
import { RadiologyServiceListingV2Component } from './radiology-service-listing-v2/radiology-service-listing-v2.component';
import { RadiologyServiceDetailV2Component } from './radiology-service-detail-v2/radiology-service-detail-v2.component';
import { DefineTemplatesV2Component } from './define-templates-v2/define-templates-v2.component';
import { ReportsReprintV2Component } from './reports/reports-reprint-v2/reports-reprint-v2.component';
import { RadiologyPacsUrlV2Component } from './radiology-pacs-url-v2/radiology-pacs-url-v2.component';
import { LogReportV2Component } from './log-report-v2/log-report-v2.component';

const routes: Routes = [
  {
    path: 'services-v2',
    component: RadiologyServiceListingV2Component,
    canActivate: [AuthorizationGuard],
  },
  // { path: 'services-add-v2', component: RadiologyServiceDetailV2Component, canActivate: [AuthorizationGuard] },
  { path: 'services-update-v2', component: RadiologyServiceDetailV2Component },
  {
    path: 'templates-v2',
    component: DefineTemplatesV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'acknowledgement-v2',
    component: AcknowledgementV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'report-writing',
    component: ReportWritingComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'radiology-writing',
    component: ReportWritingNewComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'radiology-writing-v2',
    component: ReportWritingNewV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'report-addendum-v2',
    component: ReportAddendumV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'reports/delivery-v2',
    component: DeliveryReportsV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'reports/reprint-v2',
    component: ReportsReprintV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'log-report-v2',
    component: LogReportV2Component,
    canActivate: [AuthorizationGuard],
  },
  // { path: 'pdf-report', component: RadiologyPdfReportComponent, },
  // { path: 'pdf-report-new', component: PdfReportNewComponent },
  { path: 'pdf-report', component: PdfReportNewComponent },
  { path: 'pdf-report-v2', component: PdfReportNewV2Component },
  { path: 'print-template', component: TemplatePrintComponent },
  {
    path: 'configuration-v2',
    component: RadiologyPacsUrlV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthorizationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [HospitaldoctorService],
})
export class RadiologyRoutingModule {}
