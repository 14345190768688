<!--begin::Content-->
<div class="d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Mobile Toggle-->
        <button
          class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
          id="kt_subheader_mobile_toggle"
        >
          <span></span>
        </button>
        <!--end::Mobile Toggle-->
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Health Card</h5>
          <!--          <h5 class="text-dark font-weight-bold my-1 mr-5">Permissions</h5>-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
  <div class="container-fluid">
    <div class="">
      <div class="card" style="width: 385px; height: 165px">
        <div class="card-body" style="padding: 5px">
          <div class="d-flex">
            <div style="width: 45% !important"></div>
            <div style="width: 55% !important">
              <div class="mt-4">
                <div class="detailMainArea">
                  <h2 style="font-weight: bold">{{ patient?.patient_name }}</h2>
                  <div style="margin-top: 20px">
                    <p class="m-0" style="font-weight: bold; font-size: 15px">
                      MRN: {{ patient?.mrn }}
                    </p>
                    <p class="m-0" style="font-weight: bold; font-size: 15px">
                      Gender: {{ patient?.gender }}
                    </p>
                    <p class="m-0" style="font-weight: bold; font-size: 15px">
                      CNIC: {{ cnic }}
                    </p>
                    <p class="m-0" style="font-weight: bold; font-size: 15px">
                      Contact: {{ patient?.phone1 }}
                    </p>
                    <p class="m-0" style="font-weight: bold; font-size: 15px">
                      DOB:
                      {{
                        patient?.birth_day +
                          "-" +
                          patient?.birth_month +
                          "-" +
                          patient?.birth_year
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
