import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Radiology } from '../pages/_models';

@Injectable()
export class TemplatesService {
  constructor(private http: HttpClient) {}

  searchTemplate(
    name: string,
    type: string,
    status,
    isInventory,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/template_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&name=' +
          name +
          '&type=' +
          type +
          '&status=' +
          status +
          '&isInventory=' +
          isInventory,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchTemplatesV2(
    name: string,
    type: string,
    status,
    isInventory,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/template_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&name=' +
          name +
          '&type=' +
          type +
          '&status=' +
          status +
          '&isInventory=' +
          isInventory,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchTemplateV2(patient, visitType): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/anesthesia_consents/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&admission_id=' +
          patient.admissions[patient.admissions.length - 1].id +
          '&visit_type=' +
          visitType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchTemplateV2Report(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/anesthesia_consents/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&admission_id=' +
          patient.admission_id +
          '&consent_id=' +
          patient.anesthesia_consent_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createTemplate(data): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/template_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          template_configurator: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateTemplate(data, id): Observable<any> {
    const results = this.http
      .put(
        environment.rorUrl +
          '/v4/template_configurators/' +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          template_configurator: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
