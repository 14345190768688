<div class="mt-6">
  <h4>Stay Summary</h4>
  <table class="table table-bordered mt-6">
    <thead>
      <tr>
        <th style="width: 60%">Ward/Room Name</th>
        <th style="width: 40%; text-align: center">Stay Duration</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let stayData of patientStayHistory; let i = index">
        <tr>
          <td>{{ stayData?.name }}</td>
          <td class="text-center">
            <span *ngIf="stayData?.total_time?.day"
              >{{ stayData?.total_time?.day }} Day(s),
            </span>
            <span *ngIf="stayData?.total_time?.hour"
              >{{ stayData?.total_time?.hour }} Hour(s),
            </span>
            <span *ngIf="stayData?.total_time?.minutes"
              >{{ stayData?.total_time?.minutes }} Mint(s)</span
            >
          </td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr>
        <td class="text-right font-weight-bold">Total:</td>
        <td class="text-center">
          <span *ngIf="totalCount?.day">{{ totalCount?.day }} Day(s), </span>
          <span *ngIf="totalCount?.hour">{{ totalCount?.hour }} Hour(s), </span>
          <span *ngIf="totalCount?.minutes"
            >{{ totalCount?.minutes }} Mint(s)</span
          >
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<div class="mt-6">
  <h4>Transfer History</h4>

  <div class="mt-6">
    <div class="timeline timeline-3">
      <div class="timeline-items">
        <ng-container *ngFor="let data of patientWardHistory; let i = index">
          <div class="timeline-item">
            <div class="timeline-media">
              <i class="fas fa-bed" *ngIf="!data?.condition"></i>
              <i
                class="fas fa-bed text-success"
                *ngIf="data?.condition === 'normal'"
              ></i>
              <i
                class="fas fa-bed text-danger"
                *ngIf="data?.condition === 'abnormal'"
              ></i>
            </div>
            <div class="timeline-content">
              <div
                class="d-flex align-items-center justify-content-between mb-3"
              >
                <div class="w-100 mr-2">
                  <a
                    href="#"
                    class="text-dark-75 text-hover-primary font-weight-bold"
                  >
                    <span>{{ data?.ward_title }}</span>
                    <span *ngIf="data?.bed_number">-</span
                    ><span class="ml-2">{{ data?.bed_number }}</span>
                  </a>
                  <span class="text-muted ml-2">{{
                    data?.created_at | date: "dd-MM-yyyy h:mm a"
                  }}</span>
                  <span class="text-muted ml-2" *ngIf="i !== 0"
                    >-
                    {{
                      patientWardHistory[i - 1]?.created_at
                        | date: "dd-MM-yyyy h:mm a"
                    }}
                  </span>
                  <span
                    class="label label-light-success font-weight-bolder label-inline ml-2 float-right"
                    *ngIf="data?.condition === 'normal'"
                    >{{ data?.condition | titlecase }}</span
                  >
                  <span
                    class="label label-light-danger font-weight-bolder label-inline ml-2 float-right"
                    *ngIf="data?.condition === 'abnormal'"
                    >{{ data?.condition | titlecase }}</span
                  >
                </div>
              </div>
              <div class="d-flex">
                <div style="flex-basis: 50%">
                  <div class="d-block">
                    <span><strong>Inform to:</strong></span
                    ><span class="ml-2">{{ data?.informing_doctor }}</span>
                  </div>
                  <div class="d-block">
                    <span><strong>Response:</strong></span
                    ><span class="ml-2">{{ data?.response }}</span>
                  </div>
                </div>
                <div style="flex-basis: 50%">
                  <div class="d-block">
                    <span><strong>Via:</strong></span
                    ><span class="ml-2">{{ data?.via }}</span>
                  </div>
                  <div class="d-block">
                    <span><strong>Transferred By:</strong></span
                    ><span class="ml-2">{{ data?.shifted_by }}</span>
                  </div>
                </div>
              </div>
              <div>
                <p class="p-0">
                  <strong>Comments: </strong> {{ data?.comments }}
                </p>
              </div>
            </div>
          </div>
        </ng-container>

        <!--                    <div class="timeline-item">-->
        <!--                      <div class="timeline-media">-->
        <!--                        <i class="fas fa-bed text-danger"></i>-->
        <!--                      </div>-->
        <!--                      <div class="timeline-content">-->
        <!--                        <div class="d-flex align-items-center justify-content-between mb-3">-->
        <!--                          <div class="mr-2">-->
        <!--                            <a href="#" class="text-dark-75 text-hover-primary font-weight-bold">-->
        <!--                              Member has sent a request.-->
        <!--                            </a>-->
        <!--                            <span class="text-muted ml-2">-->
        <!--                            8:30PM 20 June-->
        <!--                        </span>-->
        <!--                            <span class="label label-light-danger font-weight-bolder label-inline ml-2">Abnormal</span>-->
        <!--                          </div>-->
        <!--                        </div>-->
        <!--                        <p class="p-0">-->
        <!--                          ...-->
        <!--                        </p>-->
        <!--                      </div>-->
        <!--                    </div>-->
      </div>
    </div>
  </div>
</div>
