<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Patient Dialysis</h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center"></div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9 p-0">
          <div class="card card-custom mb-3" *ngIf="patient">
            <div class="card-body">
              <!--begin::Details-->
              <div class="d-flex">
                <!--begin: Pic-->
                <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                  <div class="symbol symbol-50 symbol-lg-120">
                    <img
                      class="medical_fitness_certificate_image"
                      src="assets/media/users/blank.png"
                      alt="image"
                    />
                  </div>
                </div>
                <!--end::Pic-->
                <!--begin::Info-->
                <div class="flex-grow-1">
                  <!--begin::Title-->
                  <div class="d-flex justify-content-between flex-wrap mt-1">
                    <div class="d-flex mr-3">
                      <a
                        href=""
                        class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                        >{{ patient?.first_name + " " + patient?.last_name }}</a
                      >
                      <a href="#">
                        <i
                          class="flaticon2-correct text-success font-size-h5"
                        ></i>
                      </a>
                    </div>
                  </div>
                  <!--end::Title-->
                  <!--begin::Content-->
                  <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                      <div class="d-flex flex-wrap mb-4">
                        <a
                          href="#"
                          class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <i class="flaticon2-placeholder mr-2 font-size-lg"></i
                          >{{ patient?.mrn }}</a
                        >
                        <a
                          href="#"
                          class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <i class="fas fa-male mr-2 font-size-lg"></i
                          >{{ patient?.gender }}</a
                        >
                        <a
                          href="#"
                          class="text-dark-50 text-hover-primary font-weight-bold"
                        >
                          <i class="fa fa-birthday-cake mr-2 font-size-lg"></i
                          >{{ patient?.age }}</a
                        >
                      </div>
                      <span class="font-weight-bold text-dark-50">{{
                        patient?.patient_type?.name | titlecase
                      }}</span>
                    </div>
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Info-->
              </div>
              <!--begin::Items-->
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card clearfix">
            <div class="card-body patient-card-listing">
              <div class="row">
                <div class="col-12 mb-4">
                  <select
                    class="form-control form-control-sm"
                    (change)="setQueryType($event)"
                  >
                    <option value="default">All</option>
                    <option value="mrn">MRN</option>
                    <option value="patient_nic">CNIC</option>
                    <option value="phone1">Phone</option>
                  </select>
                </div>

                <div class="col-9 button-wrapper">
                  <input
                    type="text"
                    maxlength="13"
                    minlength="11"
                    placeholder="Search patient here"
                    class="form-control form-control-sm input-transparent"
                    (change)="setQuery($event)"
                  />
                </div>
                <div class="col-3">
                  <button
                    class="btn btn-primary btn-sm float-right"
                    type="button"
                    (click)="fetchDialysisRequests()"
                  >
                    Search
                  </button>
                </div>
              </div>
              <div class="mt-3 patient-listing">
                <ng-container
                  *ngFor="
                    let dialysisRequest of dialysisRequests;
                    let i = index
                  "
                >
                  <div
                    class="d-flex py-3 patient-panel"
                    (click)="openModal(dialysisRequest, i)"
                  >
                    <div
                      class="d-flex flex-column flex-grow-1 font-weight-bold"
                    >
                      <a class="text-dark mb-1 font-size-lg">{{
                        dialysisRequest?.patient?.mrn
                      }}</a>
                      <div class="d-inline">
                        <span class="text-muted mr-4"
                          >{{ dialysisRequest?.patient?.first_name }}
                          {{ dialysisRequest?.patient?.last_name }}</span
                        >
                        <span class="text-muted mr-4">{{
                          dialysisRequest?.patient?.gender
                        }}</span>
                        <span class="text-muted mr-4">{{
                          dialysisRequest?.patient?.age
                        }}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-9 p-0 card card-custom">
          <div
            class="wizard wizard-1"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="false"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav border-bottom">
              <div class="wizard-steps p-8 p-lg-2">
                <!--begin::Wizard Step 1 Nav-->
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  [ngClass]="{ 'active-wizard': isPreDialysisVital }"
                >
                  <div class="wizard-label">
                    <!--                    <i class="wizard-icon fa fa-hourglass-start"></i>-->
                    <i class="wizard-icon flaticon2-hourglass-1"></i>
                    <h3 class="wizard-title">1. Pre Dialysis</h3>
                  </div>
                  <span class="svg-icon svg-icon-xl wizard-arrow ml-6">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                          x="11"
                          y="5"
                          width="2"
                          height="14"
                          rx="1"
                        />
                        <path
                          d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </div>
                <!--end::Wizard Step 1 Nav-->
                <!--begin::Wizard Step 2 Nav-->
                <div
                  class="wizard-step px-8"
                  data-wizard-type="step"
                  [ngClass]="{ 'active-wizard': isDuringDialysisVital }"
                >
                  <div class="wizard-label">
                    <i class="wizard-icon flaticon-list"></i>
                    <h3 class="wizard-title">2. During Dialysis</h3>
                  </div>
                  <span class="svg-icon svg-icon-xl wizard-arrow ml-6">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                          x="11"
                          y="5"
                          width="2"
                          height="14"
                          rx="1"
                        />
                        <path
                          d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </div>
                <!--end::Wizard Step 2 Nav-->
                <!--begin::Wizard Step 3 Nav-->
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  [ngClass]="{ 'active-wizard': isPostDialysisVital }"
                >
                  <div class="wizard-label">
                    <i class="wizard-icon flaticon-responsive"></i>
                    <h3 class="wizard-title">3. Post Dialysis</h3>
                  </div>
                </div>
                <!--end::Wizard Step 3 Nav-->
              </div>
            </div>
            <!--end::Wizard Nav-->
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body patient-card">
                    <form
                      *ngIf="isPreDialysisVital"
                      [formGroup]="PatientPreDialysisForm"
                      (ngSubmit)="createPatientDialysis()"
                    >
                      <div class="row">
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label
                            >Vascular Access
                            <span class="text-danger">*</span></label
                          >
                          <select
                            class="form-control form-control-sm"
                            formControlName="vascular_access_id"
                            [ngClass]="{
                              'is-invalid':
                                submitted && f.vascular_access_id.errors
                            }"
                            [ngModel]="
                              patient_dialysis?.vascular_access_id || ''
                            "
                          >
                            <option value="">select</option>
                            <option
                              *ngFor="let bodyRegion of bodyRegions"
                              [value]="bodyRegion?.id"
                            >
                              {{ bodyRegion?.name }}
                            </option>
                          </select>
                        </div>
                        <!--                      <div class="col-md-3 col-sm-6 col-12 mb-3">
                                               <label>Date</label>
                                               <input type="text" class="form-control form-control-sm" [value]="dialysisRequest?.suggested_date" [readOnly]="true">
                                             </div> -->
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>
                            Advised by <span class="text-danger">*</span></label
                          >
                          <div
                            [ngClass]="{
                              'is-invalid': submitted && f.advised_by_id.errors
                            }"
                          >
                            <select
                              class="form-control form-control-sm selectpicker"
                              id="advisedBy"
                              data-container="body"
                              data-dropup-auto="false"
                              data-live-search="true"
                              formControlName="advised_by_id"
                              [ngModel]="
                                dialysisRequest?.dialysis_patient?.advised_by_id
                              "
                            >
                              <option [disabled]="true" value="">select</option>
                              <option
                                *ngFor="let doc of doctor"
                                [value]="doc.id"
                              >
                                {{ doc?.first_name }} {{ doc?.last_name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Dialysis No.</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="dialysis_number"
                            placeholder="Dialysis Number"
                            readonly
                            [ngModel]="invoiceNumber"
                          />
                          <!--                          <input type="hidden" class="form-control form-control-sm" formControlName="dialysis_number" [ngModel]="patient_dialysis?.dialysis_number" />-->
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label
                            >Dialysis Machine No.
                            <span class="text-danger">*</span></label
                          >
                          <select
                            class="form-control form-control-sm"
                            formControlName="equipment_id"
                            [ngClass]="{
                              'is-invalid': submitted && f.equipment_id.errors
                            }"
                            [ngModel]="patient_dialysis?.equipment_id || ''"
                          >
                            <option value="">Select</option>
                            <option
                              *ngFor="let equipment of equipments"
                              [value]="equipment.id"
                            >
                              {{ equipment.code }}
                            </option>
                          </select>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Dialyzer</label>
                          <select
                            class="form-control form-control-sm"
                            formControlName="dialyzer_id"
                            [ngClass]="{
                              'is-invalid': submitted && f.dialyzer_id.errors
                            }"
                            [ngModel]="patient_dialysis?.dialyzer_id || ''"
                          >
                            <option value="">select</option>
                            <option
                              *ngFor="let dialyzer of dialyzers"
                              [value]="dialyzer?.id"
                            >
                              {{ dialyzer?.name }}
                            </option>
                          </select>
                        </div>
                        <!--                        <div class="col-md-3 col-sm-6 col-12 mb-3">-->
                        <!--                          <label>Urgency </label>-->
                        <!--                          <input type="text" class="form-control form-control-sm" placeholder="Urgency" [value]='dialysisRequest?.urgency?.name' [readOnly]="true">-->
                        <!--                        </div>-->
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label
                            >Priming By
                            <span class="text-danger">*</span></label
                          >
                          <div
                            [ngClass]="{
                              'is-invalid': submitted && f.priming_by_id.errors
                            }"
                          >
                            <select
                              class="form-control form-control-sm selectpicker"
                              id="primaryBy"
                              data-container="body"
                              data-dropup-auto="false"
                              data-live-search="true"
                              formControlName="priming_by_id"
                              [ngModel]="patient_dialysis?.priming_by_id"
                            >
                              <option [disabled]="true" value="">select</option>
                              <ng-container *ngFor="let employee of employees">
                                <!--                              *ngIf="employee?.department_code === 'Dialysis'"-->
                                <option [value]="employee.id">
                                  {{ employee.first_name }}
                                  {{ employee.last_name }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Priming Sol.</label>
                          <input
                            class="form-control form-control-sm input-transparent"
                            placeholder="Search medicine name"
                            formControlName="product_name"
                            type="text"
                            (keyup)="
                              getProductList(
                                $event,
                                'priming_sol',
                                '.search-result-ps'
                              )
                            "
                            [ngModel]="
                              patient_dialysis?.priming_solution
                                ? patient_dialysis?.priming_solution
                                    ?.product_name
                                : primingDefaultVal
                            "
                          />
                          <div class="search-result-ps" *ngIf="isPSSearching">
                            <p
                              *ngIf="
                                productListData.length === 0 &&
                                productSearchResult === false
                              "
                            >
                              Searching...
                            </p>
                            <p
                              *ngIf="
                                productListData.length === 0 &&
                                productSearchResult === true
                              "
                            >
                              No Result Found
                            </p>
                            <span *ngIf="productListData.length > 0">
                              <p
                                *ngFor="let product of productListData"
                                (click)="setProduct(product)"
                              >
                                {{ product.product_name }}
                              </p>
                            </span>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Heparin</label>
                          <select
                            class="form-control form-control-sm selectpicker"
                            id="heparin"
                            data-container="body"
                            data-dropup-auto="false"
                            data-live-search="true"
                            formControlName="heparin_id"
                            [ngModel]="patient_dialysis?.heparin_id"
                          >
                            <option value="">Select</option>
                            <option
                              *ngFor="let data of heparins"
                              [value]="data.id"
                            >
                              {{ data?.description }}
                            </option>
                          </select>
                          <!--                          <input class="form-control form-control-sm input-transparent" placeholder="Search medicine name" formControlName="product_name" type="text" (keyup)="getProductList($event, '', '.search-result-main')" [ngModel]='preDialysisVital?.heparin?.product_name'>-->
                          <!--                          <div class="search-result-main" *ngIf="isSearching">-->
                          <!--                            <p *ngIf="productListData.length === 0 && productSearchResult === false">Searching...</p>-->
                          <!--                            <p *ngIf="productListData.length === 0 && productSearchResult === true">No Result Found</p>-->
                          <!--                            <span *ngIf="productListData.length > 0">-->
                          <!--                                <p *ngFor="let product of productListData" (click)="setVitalMed(product, p, i)">{{product.product_name}}</p>-->
                          <!--                              </span>-->
                          <!--                          </div>-->
                        </div>

                        <ng-container
                          class=""
                          formArrayName="patient_dialysis_vitals_attributes"
                          *ngFor="let pdv of p; let i = index"
                        >
                          <ng-container [formGroupName]="i">
                            <div class="col-md-3 col-sm-6 col-12 mb-3">
                              <div class="" formGroupName="vital_attributes">
                                <label>Current Weight (kg)</label>
                                <!--                                  (change)='weightDiffernce($event.target.value, "patient_dialysis_vitals_attributes", i)'-->
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  formControlName="weight"
                                  placeholder="Weight"
                                  [ngModel]="preDialysisVital?.vital?.weight"
                                  (change)="
                                    calcWeightDiffernce(
                                      'patient_dialysis_vitals_attributes',
                                      i
                                    )
                                  "
                                />
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>

                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Last Weight (kg)</label>
                          <!--                          [value]='dialysisRequest?.last_dialysis_weight'-->
                          <!--                          (keypress)="_onlyNumeric($event)"-->
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            placeholder="last weight"
                            [(ngModel)]="lastWeightValue"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="
                              calcWeightDiffernce(
                                'patient_dialysis_vitals_attributes',
                                0
                              )
                            "
                          />
                        </div>

                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label
                            >Dialysis Starting Time
                            <span class="text-danger">*</span></label
                          >
                          <input
                            type="time"
                            class="form-control form-control-sm"
                            formControlName="start_time"
                            [ngModel]="
                              patient_dialysis?.start_time || currentTime
                            "
                            placeholder="Please enter starting time"
                            [ngClass]="{
                              'is-invalid': submitted && f.start_time.errors
                            }"
                            (change)="updateDuration()"
                          />
                        </div>

                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Finish Time</label>
                          <input
                            type="time"
                            class="form-control form-control-sm"
                            formControlName="end_time"
                            [ngModel]="
                              patient_dialysis?.end_time || currentTime
                            "
                            placeholder="Please enter starting time"
                            [ngClass]="{
                              'is-invalid': submitted && f.end_time.errors
                            }"
                            (change)="updateDuration()"
                          />
                        </div>

                        <ng-container
                          class=""
                          formArrayName="patient_dialysis_vitals_attributes"
                          *ngFor="let pdv of p; let i = index"
                        >
                          <ng-container [formGroupName]="i">
                            <div class="col-md-3 col-sm-6 col-12 mb-3">
                              <label>Diff. in Weight (kg)</label>
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                readonly
                                placeholder="Weight"
                                formControlName="weight_difference"
                                (change)="
                                  calculateUf(
                                    $event.target.value,
                                    'patient_dialysis_vitals_attributes',
                                    i
                                  )
                                "
                              />
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-3">
                              <label>UF: ml</label>
                              <input
                                type="number"
                                (keypress)="_onlyNumeric($event)"
                                class="form-control form-control-sm"
                                readonly
                                placeholder="UF"
                                formControlName="uf"
                                (change)="
                                  calculateUfRate(
                                    $event.target.value,
                                    'patient_dialysis_vitals_attributes',
                                    i
                                  )
                                "
                                [ngModel]="preDialysisVital?.uf"
                              />
                            </div>
                            <!--                            <div class="col-md-3 col-sm-6 col-12 mb-3">-->
                            <!--                              <label>Total Hours (h)</label>-->
                            <!--                              <input type="number" (keypress)="_onlyNumeric($event)" class="form-control form-control-sm" formControlName="total_hours" placeholder="Enter time" (change)='calculateUfRate($event.target.value, "patient_dialysis_vitals_attributes", i)'/>-->
                            <!--                            </div>-->
                            <div class="col-md-3 col-sm-6 col-12 mb-3">
                              <label>UF Rate: ml/hr</label>
                              <input
                                type="number"
                                (keypress)="_onlyNumeric($event)"
                                class="form-control form-control-sm"
                                readonly
                                placeholder="UF rate"
                                formControlName="uf_rate"
                                [ngModel]="preDialysisVital?.uf_rate"
                              />
                            </div>
                          </ng-container>
                        </ng-container>

                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Total Duration</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="duration"
                            readonly
                            placeholder="Please enter duration"
                          />
                        </div>
                      </div>
                      <div
                        class="row"
                        formArrayName="patient_dialysis_vitals_attributes"
                        *ngFor="let pdv of p; let i = index"
                      >
                        <ng-container class="p-3" [formGroupName]="i">
                          <div class="col-2">
                            <label>Vital Time</label>
                            <input
                              type="time"
                              class="form-control form-control-sm text-center"
                              formControlName="taken_time"
                              [ngModel]="
                                preDialysisVital?.taken_time || currentTime
                              "
                              placeholder="Taken Time"
                            />
                          </div>

                          <ng-container formGroupName="vital_attributes">
                            <div class="col-2">
                              <label>Temperature (F)</label>
                              <input
                                type="number"
                                class="form-control form-control-sm text-center"
                                formControlName="temperature"
                                placeholder="95..107"
                                [ngModel]="preDialysisVital?.vital?.temperature"
                              />
                            </div>
                            <div class="col-2">
                              <label>BP-Sys (mm/hg)</label>
                              <input
                                type="number"
                                class="form-control form-control-sm text-center"
                                formControlName="bp_systolic"
                                placeholder="50..300"
                                [ngModel]="preDialysisVital?.vital?.bp_systolic"
                              />
                            </div>
                            <div class="col-2">
                              <label>BP-Dias (mm/hg)</label>
                              <input
                                type="number"
                                class="form-control form-control-sm text-center"
                                formControlName="bp_diastolic"
                                placeholder="0..150"
                                [ngModel]="
                                  preDialysisVital?.vital?.bp_diastolic
                                "
                              />
                            </div>
                            <div class="col-2">
                              <label>Pulse</label>
                              <input
                                type="number"
                                class="form-control form-control-sm text-center"
                                formControlName="pulse"
                                placeholder="40..140"
                                [ngModel]="preDialysisVital?.vital?.pulse"
                              />
                            </div>
                          </ng-container>

                          <div class="col-2">
                            <label>Blood Flow</label>
                            <input
                              type="number"
                              class="form-control form-control-sm text-center"
                              formControlName="blood_flow"
                              placeholder="Blood Flow"
                              [ngModel]="preDialysisVital?.blood_flow"
                            />
                          </div>
                          <!-- <div class="col-1">
                            <label>Fluid</label>
                            <input type="text" class="form-control form-control-sm text-center" formControlName="fluid_id" placeholder="Fluid" [ngModel]='preDialysisVital?.fluid_id'>
                          </div> -->

                          <div class="col-12 py-2">
                            <label>Special Instruction</label>
                            <textarea
                              class="form-control form-control-sm"
                              formControlName="instruction"
                              placeholder="Please enter instruction"
                              (change)="specialInstructions($event)"
                              [value]="
                                patient_dialysis
                                  ? patient_dialysis.instruction
                                  : ''
                              "
                              rows="5"
                            >
                            </textarea>
                          </div>
                        </ng-container>
                      </div>
                      <div
                        class="row mb-6"
                        formGroupName="pre_dialysis_assessment_attributes"
                      >
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Complaints</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="complaints"
                            placeholder="Please enter complaints"
                            [ngModel]="
                              patient_dialysis?.pre_dialysis_assessment
                                ?.complaints
                            "
                          />
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Conscious Level</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="conscious_level"
                            placeholder="Please enter conscious level"
                            [ngModel]="
                              patient_dialysis?.pre_dialysis_assessment
                                ?.conscious_level
                            "
                          />
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Edema</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="edema"
                            placeholder="Please enter edema"
                            [ngModel]="
                              patient_dialysis?.pre_dialysis_assessment?.edema
                            "
                          />
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Card Rhythm</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="card_rhythm"
                            placeholder="Please enter card rhythm"
                            [ngModel]="
                              patient_dialysis?.pre_dialysis_assessment
                                ?.card_rhythm
                            "
                          />
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Lungs</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="lungs"
                            placeholder="Please enter lungs"
                            [ngModel]="
                              patient_dialysis?.pre_dialysis_assessment?.lungs
                            "
                          />
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Other</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="other"
                            placeholder="Please enter other"
                            [ngModel]="
                              patient_dialysis?.pre_dialysis_assessment?.other
                            "
                          />
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 mb-3">
                          <label>Remarks</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="remarks"
                            placeholder="Please enter remarks"
                            [ngModel]="
                              patient_dialysis?.pre_dialysis_assessment?.remarks
                            "
                          />
                        </div>
                      </div>
                      <div class="row d-flex justify-content-between">
                        <div class="otherComponent ml-5">
                          <div *ngIf="refreshImagesModal === true">
                            <app-upload-images
                              [isUploadImage]="isUploadImage"
                              [isArrayList]="isArrayList"
                              [isDisplayImage]="isDisplayImage"
                              [receivedImageFiles]="receivedImageFiles"
                            >
                            </app-upload-images>
                          </div>
                        </div>
                        <div class="col text-right">
                          <button
                            *ngIf="!patient_dialysis?.id"
                            type="submit"
                            class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right"
                          >
                            Next
                          </button>
                          <button
                            *ngIf="patient_dialysis?.id"
                            type="button"
                            class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right"
                            (click)="patientPreDialysis()"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </form>

                    <form
                      *ngIf="isDuringDialysisVital"
                      [formGroup]="PatientDuringDialysisForm"
                      (ngSubmit)="patientDuringDialysis(false)"
                    >
                      <div class="row mb-6">
                        <div class="col-4 mb-3">
                          <label>Duration</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            formControlName="duration_during_dialysis"
                            (change)="vitalTakenDuration($event)"
                            placeholder="Please enter duration"
                          />
                        </div>
                        <div class="col-8 mb-3">
                          <button
                            type="button"
                            class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 float-right"
                            (click)="addMoreVital('')"
                          >
                            <span class="svg-icon svg-icon-primary svg-icon-2x">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect x="0" y="0" width="24" height="24" />
                                  <path
                                    d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                    fill="#000000"
                                  />
                                </g>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <label>Time</label>
                        </div>
                        <div class="col-7">
                          <div class="row">
                            <!-- <div class="col">
                              <label>Weight</label>
                            </div> -->
                            <div class="col">
                              <label>Temperature (F)</label>
                            </div>
                            <div class="col">
                              <label>BP-Sys (mm/hg) </label>
                            </div>
                            <div class="col">
                              <label>BP-Dias (mm/hg)</label>
                            </div>
                            <div class="col">
                              <label>Pulse</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-2">
                          <label>Blood Flow</label>
                        </div>
                        <!-- <div class="col-2">
                          <label>Heparin</label>
                        </div> -->
                        <!-- <div class="col-1">
                          <label>Fluid</label>
                        </div> -->
                        <div class="col-1">
                          <label>Action</label>
                        </div>
                      </div>
                      <div
                        class="row mb-6"
                        formArrayName="patient_dialysis_vitals_attributes"
                        *ngFor="let pdv of w; let i = index"
                      >
                        <div class="row p-3" [formGroupName]="i">
                          <div class="col-2">
                            <input
                              type="time"
                              class="form-control form-control-sm text-center"
                              formControlName="taken_time"
                              placeholder="Taken Time"
                            />
                          </div>
                          <div class="col-7">
                            <div class="row" formGroupName="vital_attributes">
                              <!-- <div class="col">
                                <input type="number" class="form-control form-control-sm text-center" formControlName="weight" placeholder="Weight">
                              </div> -->
                              <div class="col">
                                <input
                                  type="number"
                                  class="form-control form-control-sm text-center"
                                  formControlName="temperature"
                                  placeholder="95..107"
                                />
                              </div>
                              <div class="col">
                                <input
                                  type="number"
                                  class="form-control form-control-sm text-center"
                                  formControlName="bp_systolic"
                                  placeholder="50..300"
                                />
                              </div>
                              <div class="col">
                                <input
                                  type="number"
                                  class="form-control form-control-sm text-center"
                                  formControlName="bp_diastolic"
                                  placeholder="0..150"
                                />
                              </div>
                              <div class="col">
                                <input
                                  type="number"
                                  class="form-control form-control-sm text-center"
                                  formControlName="pulse"
                                  placeholder="40..140"
                                />
                              </div>
                              <!--                             <div class="col">
                                                            <input type="number" (keypress)="_onlyNumeric($event)" class="form-control form-control-sm text-center" formControlName="resp_rate" placeholder="12..70">
                                                          </div> -->
                            </div>
                          </div>
                          <div class="col-2">
                            <input
                              type="number"
                              class="form-control form-control-sm text-center"
                              formControlName="blood_flow"
                              placeholder="BF"
                            />
                          </div>
                          <!-- <div class="col-2">
                            <input class="form-control input-transparent" placeholder="Search medicine" formControlName="product_name" type="text" (keyup)="getProductList($event)">
                            <div class="search-result-main" *ngIf="isSearching">
                              <p *ngIf="productListData.length === 0 && productSearchResult === false">Searching...</p>
                              <p *ngIf="productListData.length === 0 && productSearchResult === true">No Result Found</p>
                              <span *ngIf="productListData.length > 0">
                                <p *ngFor="let product of productListData" (click)="setVitalMed(product, w, i)">{{product.product_name}}</p>
                              </span>
                            </div>
                          </div> -->
                          <!-- <div class="col-1">
                            <input type="text" class="form-control form-control-sm text-center" formControlName="fluid_id" placeholder="Fluid">
                          </div> -->
                          <div class="col-1">
                            <button
                              type="button"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                              (click)="removeVital(i)"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path
                                      d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                      fill="#000000"
                                      fill-rule="nonzero"
                                    />
                                    <path
                                      d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                      fill="#000000"
                                      opacity="0.3"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="d-flex justify-content-between border-top mt-5 pt-10"
                      >
                        <div class="mr-2">
                          <button
                            type="button"
                            class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                            (click)="changeTab(0, false)"
                          >
                            Previous
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2"
                            (click)="patientDuringDialysis(true)"
                          >
                            Save
                          </button>
                          <button
                            type="submit"
                            class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </form>

                    <form
                      *ngIf="isPostDialysisVital"
                      [formGroup]="PatientPostDialysisForm"
                      (ngSubmit)="patientPostDialysis()"
                    >
                      <div class="row mb-6">
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Finish Time</label>
                          <input
                            type="time"
                            class="form-control form-control-sm"
                            formControlName="end_time"
                            placeholder="Please enter ending time"
                            (change)="updatePostDuration()"
                            [ngClass]="{
                              'is-invalid': submitted && s.end_time.errors
                            }"
                            [ngModel]="
                              patient_dialysis?.end_time || currentTime
                            "
                          />
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Total Duration</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="duration"
                            placeholder="Please enter duration"
                            [ngModel]="patient_dialysis?.duration"
                          />
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-3">
                          <label>Staff</label>
                          <select
                            class="form-control form-control-sm"
                            formControlName="staff_id"
                            [ngModel]="patient_dialysis?.staff_id"
                          >
                            <option value="">select</option>
                            <option
                              *ngFor="let employee of employees"
                              [value]="employee.id"
                            >
                              {{ employee.first_name }} {{ employee.last_name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        class="row mb-6"
                        formArrayName="patient_dialysis_vitals_attributes"
                        *ngFor="let pdv of r; let i = index"
                      >
                        <div class="row p-3" [formGroupName]="i">
                          <div class="col-2">
                            <label>Time</label>
                            <input
                              type="time"
                              class="form-control form-control-sm text-center"
                              formControlName="taken_time"
                              placeholder="Taken Time"
                              [ngModel]="
                                postDialysisVital?.taken_time || currentTime
                              "
                            />
                            <!--                            [ngModel]='(postDialysisVital?.taken_time || currentTime) | date: "HH:mm"'-->
                          </div>
                          <div class="col-10">
                            <div class="row" formGroupName="vital_attributes">
                              <div class="col">
                                <label>Weight (kg)</label>
                                <input
                                  class="form-control form-control-sm text-center"
                                  formControlName="weight"
                                  placeholder="1..250"
                                  [ngModel]="postDialysisVital?.vital?.weight"
                                />
                              </div>
                              <div class="col">
                                <label>Temperature (F)</label>
                                <input
                                  type="number"
                                  class="form-control form-control-sm text-center"
                                  formControlName="temperature"
                                  placeholder="95..107"
                                  [ngModel]="
                                    postDialysisVital?.vital?.temperature
                                  "
                                />
                              </div>
                              <div class="col">
                                <label>BP-Syst. (mm/hg)</label>
                                <input
                                  type="number"
                                  class="form-control form-control-sm text-center"
                                  formControlName="bp_systolic"
                                  placeholder="50..300"
                                  [ngModel]="
                                    postDialysisVital?.vital?.bp_systolic
                                  "
                                />
                              </div>
                              <div class="col">
                                <label>BP-Dias (mm/hg)</label>
                                <input
                                  type="number"
                                  class="form-control form-control-sm text-center"
                                  formControlName="bp_diastolic"
                                  placeholder="0..150"
                                  [ngModel]="
                                    postDialysisVital?.vital?.bp_diastolic
                                  "
                                />
                              </div>
                              <div class="col">
                                <label>Pulse</label>
                                <input
                                  type="number"
                                  class="form-control form-control-sm text-center"
                                  formControlName="pulse"
                                  placeholder="40..140"
                                  [ngModel]="postDialysisVital?.vital?.pulse"
                                />
                              </div>
                              <!--                              <div class="col">
                                                             <label>Resp.Rate</label>
                                                             <input type="number" (keypress)="_onlyNumeric($event)" class="form-control form-control-sm text-center" formControlName="resp_rate" placeholder="12..70" [ngModel]='postDialysisVital?.vital?.resp_rate'>
                                                           </div> -->
                            </div>
                          </div>
                          <!-- <div class="col-2">
                            <label>Blood Flow</label>
                            <input type="number" class="form-control form-control-sm text-center" formControlName="blood_flow" placeholder="Blood Flow">
                          </div> -->
                          <!-- <div class="col-4">
                            <label>Heparin</label>
                            <input class="form-control input-transparent" placeholder="Search medicine name" formControlName="product_name" type="text" (keyup)="getProductList($event)">
                            <div class="search-result-main" *ngIf="isSearching">
                              <p *ngIf="productListData.length === 0 && productSearchResult === false">Searching...</p>
                              <p *ngIf="productListData.length === 0 && productSearchResult === true">No Result Found</p>
                              <span *ngIf="productListData.length > 0">
                                <p *ngFor="let product of productListData" (click)="setVitalMed(product, r, i)">{{product.product_name}}</p>
                              </span>
                            </div>
                          </div> -->
                          <!-- <div class="col-1">
                            <label>Fluid</label>
                            <input type="text" class="form-control form-control-sm text-center" formControlName="fluid_id" placeholder="Fluid" [ngModel]='postDialysisVital?.fluid_id'>
                          </div> -->
                          <!-- <div class="col-2">
                            <label>Observation</label>
                            <input class="form-control form-control-sm text-center" formControlName="observation" placeholder="Observation" [ngModel]='postDialysisVital?.observation'>
                          </div> -->
                        </div>
                      </div>
                      <div
                        class="d-flex justify-content-between border-top mt-5 pt-10"
                      >
                        <div class="mr-2">
                          <button
                            type="button"
                            class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                            (click)="changeTab(1, false)"
                          >
                            Previous
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                            [disabled]="disableSubmitButton === true"
                          >
                            Submit
                          </button>
                          <!--                          <button type="submit" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" [disabled]="disableSubmitButton === true">Submit</button>-->
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--end::Ot Calendar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<div class="example-preview">
  <div>
    <ng-template #patientChangeModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Change Patient!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body custom-card-body">
          <div class="row">
            <div class="col-12">
              <p>Are you sure you want to change the patient?</p>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="closeModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            (click)="
              fetchDialysisRequestInfo(currentPatientData, currentPatientIndex)
            "
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
