import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ReferralService {
  constructor(private http: HttpClient) {}

  get(patient): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/referrals?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  add(hospital_id, department_id, doctor_id, patient): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` + patient.mrn + '/referrals',
        {
          auth_token: localStorage.getItem('auth_token'),
          visit_id: patient.visit_id,
          medical_unit_id: hospital_id,
          department_id: department_id,
          user_id: doctor_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
