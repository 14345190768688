import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IpdRoutingModule } from './ipd-routing.module';
import { PatientService } from '../../services/patient.service';
import { OpdService } from '../../services/opd.service';
import { CompanyService } from '../../services/company.service';
import { ServiceService } from '../../services/service.service';
import { HospitaldoctorService } from '../../services/hospitaldoctor.service';
import { DepartmentsbyhospitalService } from '../../services/departmentsbyhospital.service';
import { HospitalService } from '../../services/hospital.service';
import { PricingService } from '../../services/pricing.service';
import { DoctorCategoryService } from '../../services/doctor-category.service';
import { VaccinationService } from '../../services/vaccination.service';
import { ReferralService } from '../../services/referral.service';
import { StockService } from '../../services/stock.service';
import { PatientHistoryService } from '../../services/patient-history.service';
import { EmployeeRegistrationService } from '../../services/employee-registration.service';
import { LookupService } from '../../services/lookup.service';
import { WardService } from '../../services/ward.service';
import { PatientAdmissionService } from '../../services/patient-admission.service';
import { ConsentFormService } from '../../services/consent-form.service';
import { BillingCategoryService } from '../../services/billing-category.service';
import { BirthCertificateService } from '../../services/birth-certificate.service';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { TemplatesService } from '../../services/templates.service';
import { HistoryDashboardComponent } from './history-dashboard/history-dashboard.component';
import { PatientInOutTakeService } from '../../services/patient-in-out-take.service';
import { BirthCirtificateComponent } from '../patient-baby-born/birth-cirtificate/birth-cirtificate.component';
import { PdfServicesService } from '../../services/pdf-services.service';
import { StaffService } from '../../services/staff.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { SystemControlsService } from 'src/app/services/system-controls.service';
import { MedicineConfigService } from 'src/app/services/medicine-config.service';
import { OtService } from '../../services/ot.service';
import { NursingProcedureService } from '../../services/nursing-procedure.service';
import { VitalsValidationService } from '../../services/vitals-validation.service';
import { HistoryPrintComponent } from './history-print/history-print.component';
import { BloodBankService } from '../../services/blood-bank.service';
import { IpdV2Component } from './ipd-v2/ipd-v2.component';
import { VitalsComponent } from './ipd-components/vitals/vitals.component';
import { ConsultationsComponent } from './ipd-components/consultations/consultations.component';
import { MedicinesComponent } from './ipd-components/medicines/medicines.component';
import { DiagnosisComponent } from './ipd-components/diagnosis/diagnosis.component';
import { ServicesComponent } from './ipd-components/services/services.component';
import { VaccinationComponent } from './ipd-components/vaccination/vaccination.component';
import { BirthCertificateComponent } from './ipd-components/birth-certificate/birth-certificate.component';
import { HistoryV2Component } from './ipd-components/history-v2/history-v2.component';
import { NotesComponent } from './ipd-components/notes/notes.component';
import { OperationRequestComponent } from './ipd-components/operation-request/operation-request.component';
import { NursingProcedureComponent } from './ipd-components/nursing-procedure/nursing-procedure.component';
import { GlasgowComaComponent } from './ipd-components/glasgow-coma/glasgow-coma.component';
import { IntakeOutputChartComponent } from './ipd-components/intake-output-chart/intake-output-chart.component';
import { BloodSugarComponent } from './ipd-components/blood-sugar/blood-sugar.component';
import { DocumentsComponent } from './ipd-components/documents/documents.component';
import { TransferHistoryComponent } from './ipd-components/transfer-history/transfer-history.component';
import { DischargeComponent } from './ipd-components/discharge/discharge.component';
import { ConsentFormComponent } from './ipd-components/consent-form/consent-form.component';
import { MainComponent } from './ipd-components/main/main.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReferralComponent } from './ipd-components/referral/referral.component';
import { HistTakingComponent } from './ipd-components/hist-taking/hist-taking.component';
import { AdmissionsComponent } from './ipd-components/admissions/admissions.component';
import { HistoryPrintV2Component } from './history-print-v2/history-print-v2.component';
import {NgbPaginationModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { MedicineAdministrationComponent } from './ipd-components/medicine-administration/medicine-administration.component';
import { IsConsumableComponent } from './ipd-components/is-consumable/is-consumable.component';
import {SharedModule} from '../shared-modules/shared.module';

@NgModule({
  declarations: [
    HistoryDashboardComponent,
    BirthCirtificateComponent,
    HistoryPrintComponent,
    IpdV2Component,
    VitalsComponent,
    ConsultationsComponent,
    MedicinesComponent,
    DiagnosisComponent,
    ServicesComponent,
    VaccinationComponent,
    BirthCertificateComponent,
    HistoryV2Component,
    NotesComponent,
    OperationRequestComponent,
    NursingProcedureComponent,
    GlasgowComaComponent,
    IntakeOutputChartComponent,
    BloodSugarComponent,
    DocumentsComponent,
    TransferHistoryComponent,
    DischargeComponent,
    ConsentFormComponent,
    MainComponent,
    ReferralComponent,
    HistTakingComponent,
    AdmissionsComponent,
    HistoryPrintV2Component,
    MedicineAdministrationComponent,
    IsConsumableComponent,
  ],

    imports: [
      CommonModule,
      IpdRoutingModule,
      ReactiveFormsModule,
      FormsModule,
      SharedModule,
      NgbPaginationModule,
      NgbTooltipModule,
    ],
  exports: [
    TransferHistoryComponent,
    VitalsComponent,
    DiagnosisComponent,
    MedicinesComponent,
    ServicesComponent,
    ConsultationsComponent,
    VaccinationComponent,
    DocumentsComponent,
    NotesComponent,
    OperationRequestComponent,
    IntakeOutputChartComponent,
    NursingProcedureComponent,
    BloodSugarComponent,
    ConsentFormComponent,
    AdmissionsComponent,
    DischargeComponent,
  ],
  providers: [
    PatientService,
    OpdService,
    CompanyService,
    ServiceService,
    HospitaldoctorService,
    HospitaldoctorService,
    DepartmentsbyhospitalService,
    HospitalService,
    PricingService,
    DoctorCategoryService,
    VaccinationService,
    ReferralService,
    StockService,
    PatientHistoryService,
    EmployeeRegistrationService,
    LookupService,
    HospitaldoctorService,
    WardService,
    PatientAdmissionService,
    ConsentFormService,
    BillingCategoryService,
    BirthCertificateService,
    TemplatesService,
    PatientInOutTakeService,
    PdfServicesService,
    StaffService,
    ConfigurationService,
    SystemControlsService,
    MedicineConfigService,
    OtService,
    NursingProcedureService,
    VitalsValidationService,
    BloodBankService,
  ],
})
export class IpdModule {}
