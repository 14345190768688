<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            Lab Configuration
          </h5>
          <!--          tab headings-->

          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="card card-custom gutter-b mb-4">
        <div
          class="card-header card-header-tabs-line received-card-header d-block d-print-none"
        >
          <div class="card-toolbar received-toolbar">
            <ul class="nav nav-tabs nav-bold nav-tabs-line">
              <li
                class="nav-item received-li"
                *ngFor="let item of allTypes; let i = index"
                (click)="activeTypes = item.key; searchResultUpdate()"
              >
                <a
                  class="nav-link pb-0"
                  data-toggle="tab"
                  href="#{{ item.key }}"
                  [ngClass]="{ active: i === 0 }"
                >
                  <span class="nav-text">{{ item.value }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="">
        <div class="card mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3 col-3">
                <label>Code</label>
                <input class="form-control" [(ngModel)]="searchCode" />
              </div>
              <div class="col-sm-3 col-3">
                <label>Status</label>
                <select class="form-control" [(ngModel)]="searchStatus">
                  <option value="true">Active</option>
                  <option value="false">In-Active</option>
                  <option value="all">All</option>
                </select>
              </div>
              <div class="col-sm-3 col-3">
                <button
                  class="btn btn-sm btn-primary mt-6"
                  (click)="fetchLabConfigurators()"
                >
                  Search
                </button>
              </div>
              <div class="col-sm-3 col-3">
                <button
                  class="btn btn-light-primary btn-sm mt-6 float-right"
                  (click)="addNewItem()"
                >
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div class="tab-pane fade show active">
            <div class="card" *ngIf="labConfigurations.length > 0">
              <div class="card-body">
                <div class="table-responsive position-relative">
                  <table
                    class="table table-head-custom table-vertical-center"
                    id="kt_advance_table_widget_3"
                  >
                    <thead>
                      <tr>
                        <th style="width: 10%">Code</th>
                        <th style="width: 60%">Title</th>
                        <th style="width: 10%">Title</th>
                        <th style="width: 10%">Status</th>
                        <th style="width: 10%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let lab of labConfigurations; let i = index">
                        <td>
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg d-flex"
                            >{{ lab?.code }}</span
                          >
                        </td>
                        <td>
                          <span>{{ lab?.description }}</span>
                        </td>
                        <td>
                          <span
                            *ngIf="lab?.is_default"
                            class="label font-weight-bold label-lg label-light-primary label-inline"
                            >Default</span
                          >
                        </td>
                        <td>
                          {{ lab?.active === true ? "Active" : "In-Active" }}
                        </td>
                        <td>
                          <div class="dropdown dropdown-inline">
                            <button
                              type="button"
                              class="btn btn-light-primary btn-icon btn-sm"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="ki ki-bold-more-ver"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a
                                class="dropdown-item cursor-pointer"
                                (click)="updateItem(lab, i)"
                                >Update</a
                              >
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="pagination_main d-print-none">
                  <ngb-pagination
                    [(page)]="page"
                    (pageChange)="loadPage(page)"
                    [pageSize]="perPage"
                    [collectionSize]="totalPages"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                  ></ngb-pagination>
                  <div class="dropdown">
                    <a
                      class="btn dropdown-toggle btn-light btn-sm"
                      role="button"
                      id="pagination_btn"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span class="page_count">
                        {{ perPage }}
                      </span>
                      <span class="count_count_icon"></span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="pagination_btn">
                      <a
                        class="dropdown-item"
                        (click)="selectPaginationLimit(25)"
                        >25</a
                      >
                      <a
                        class="dropdown-item"
                        (click)="selectPaginationLimit(50)"
                        >50</a
                      >
                      <a
                        class="dropdown-item"
                        (click)="selectPaginationLimit(75)"
                        >75</a
                      >
                      <a
                        class="dropdown-item"
                        (click)="selectPaginationLimit(100)"
                        >100</a
                      >
                      <a
                        class="dropdown-item"
                        (click)="selectPaginationLimit('')"
                        >All</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<div
  class="modal fade"
  id="configurationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <form [formGroup]="labConfigurationForm" (ngSubmit)="onSubmit()">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" *ngIf="isAddData">Add New</h4>
          <h4 class="modal-title" *ngIf="!isAddData">Update</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-6 col-12">
              <div class="form-group">
                <label>Code</label>
                <input
                  class="form-control form-control-sm"
                  placeholder="Code"
                  type="text"
                  formControlName="code"
                  [ngClass]="{
                    'is-invalid': submitted && controls.code.errors
                  }"
                />
              </div>
            </div>

            <div class="col-sm-6 col-12">
              <div class="form-group">
                <label>Title</label>
                <input
                  class="form-control form-control-sm"
                  placeholder="Title"
                  type="text"
                  formControlName="description"
                  [ngClass]="{
                    'is-invalid': submitted && controls.description.errors
                  }"
                />
              </div>
            </div>

            <div class="col-sm-6 col-12 d-flex justify-content-between">
              <div class="form-group">
                <label>Status</label>
                <span class="switch switch-icon">
                  <label>
                    <input type="checkbox" formControlName="active" />
                    <span></span>
                  </label>
                </span>
              </div>
              <div class="form-group">
                <label>Default</label>
                <span class="switch switch-icon">
                  <label>
                    <input type="checkbox" formControlName="is_default" />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="submit" class="btn btn-primary">
            {{ isAddData ? "Add New" : "Update" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
