import { Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTimeFormat',
})
export class CustomDateTimeFormatPipe {
  formattedDateTime: any;
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const localStorageData = JSON.parse(
      localStorage.getItem('dateTimeFormats'),
    );
    if (localStorageData && localStorageData.time_format) {
      this.formattedDateTime = moment(value).format(
        localStorageData.date_time_format,
      );
    } else {
      this.formattedDateTime = moment(value).format('DD-MM-YYYY h:mm a');
    }
    return this.formattedDateTime;
  }
}
