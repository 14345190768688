import { Component, OnInit } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import { ActivatedRoute } from '@angular/router';

declare var toastr: any;
@Component({
  selector: 'app-health-card',
  templateUrl: './health-card.component.html',
  styleUrls: ['./health-card.component.css'],
})
export class HealthCardComponent implements OnInit {
  patient: any;
  mrn: any;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.mrn = params.id;
    });
  }
  get cnic(): any {
    return `${this.patient?.patient_nic.substring(0, 5)}-${this.patient?.patient_nic.substring(5, 12)}-${this.patient?.patient_nic.substring(12)}`;
  }
  getPatientData(): any {
    this.patientService.getPatientDataForHealthCard(this.mrn).subscribe(
      (data) => {
        this.patient = data?.patient;
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  ngOnInit(): void {
    this.getPatientData();
  }
}
