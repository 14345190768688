<div>
  <!--begin::Card-->

  <div class="card">
    <div class="card-body user-card-body">
      <form>
        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-4 align-self-start">
            <label>Presenting Complaints</label>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8">
            <!--                      <input type="text" class="form-control" name="presenting_complaints" [(ngModel)]="patientHistoryComplaint.presenting_complaints">-->
            <textarea
              rows="4"
              class="textareas"
              id="presentingComplaintsPatient"
              [(ngModel)]="patientHistoryComplaint.presenting_complaints"
              name="presenting_complaints"
            ></textarea>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-4 align-self-start">
            <label>History of presenting Complaints</label>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8">
            <input
              type="text"
              class="form-control form-control-sm"
              name="presenting_complaints_history"
              [(ngModel)]="
                patientHistoryComplaint.presenting_complaints_history
              "
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-4 align-self-start">
            <label>physical Finding</label>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8">
            <input
              type="text"
              class="form-control form-control-sm"
              name="physical_finding"
              [(ngModel)]="patientHistoryComplaint.physical_finding"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-4 align-self-start">
            <label>Past Medical History</label>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8">
            <input
              type="text"
              class="form-control form-control-sm"
              name="past_medical_history"
              [(ngModel)]="patientHistoryComplaint.past_medical_history"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-4 align-self-start">
            <label>Drug History</label>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8">
            <input
              type="text"
              class="form-control form-control-sm"
              name="drug_history"
              [(ngModel)]="patientHistoryComplaint.drug_history"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-4 align-self-start">
            <label>Family History</label>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8">
            <input
              type="text"
              class="form-control form-control-sm"
              name="family_history"
              [(ngModel)]="patientHistoryComplaint.family_history"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-4 align-self-start">
            <label>Allergies</label>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8 tagsInput">
            <input
              class="form-control form-control-sm"
              style="width: 100%"
              id="tags"
              placeholder="Search allergies"
              name="paitentAllergiesSearch"
              autocomplete="off"
            />
            <div
              class="search-result-main"
              *ngIf="paitentAllergiesSearch?.length > 2"
            >
              <p
                *ngIf="
                  allergiesListData?.length === 0 &&
                  allergiesSearchResult === false
                "
              >
                Searching...
              </p>
              <p
                *ngIf="
                  allergiesListData?.length === 0 &&
                  allergiesSearchResult === true
                "
              >
                No Result Found
              </p>
              <span *ngIf="allergiesListData?.length > 0">
                <p
                  *ngFor="let allergies of allergiesListData"
                  (click)="setAllergiesNmae(allergies.name)"
                >
                  {{ allergies.name }}
                </p>
              </span>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-4 align-self-start">
            <label>General Physical Examination</label>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8">
            <input
              type="text"
              class="form-control form-control-sm"
              name="general_physical_examination"
              [(ngModel)]="patientHistoryComplaint.general_physical_examination"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-4 align-self-start">
            <label>Summary</label>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8">
            <input
              type="text"
              class="form-control form-control-sm"
              name="summary"
              [(ngModel)]="patientHistoryComplaint.summary"
            />
          </div>
        </div>

        <div class="text-right mt-8">
          <button
            type=""
            class="btn btn-primary btn-sm"
            (click)="submitPatientHistory('submit')"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>

  <!--end::Card-->
</div>
