<!--begin::Content-->
<div class="d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Entry-->
  <div>
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="settingsForm" (ngSubmit)="updateSettings()">
            <div class="row mb-6">
              <div class="col-6 py-2">
                <label class="font-weight-500"
                  >Print Lab Report in Web Format</label
                >
              </div>
              <div class="col-6">
                <span class="switch switch-icon">
                  <label>
                    <input
                      type="checkbox"
                      name="patient_services_is_invoice"
                      formControlName="web_report"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div class="col-12">
                <p class="text-muted" style="font-size: 11px">
                  If this setting is enable, the lab report will be generated in
                  web format instead of PDF format.
                </p>
              </div>
            </div>

            <div class="row mb-6">
              <div class="col-6">
                <label class="font-weight-500"
                  >Display header in the patient's lab report?</label
                >
                <p class="text-muted m-0 p-0" style="font-size: 11px">
                  The following uses are possible for this configuration:
                </p>
                <p class="text-muted m-0 p-0" style="font-size: 11px">
                  1.To display the report header, set the default options.
                </p>
                <p class="text-muted m-0 p-0" style="font-size: 11px">
                  2.If the header is displayed, you will be able to select
                  between the dynamic information the image.
                </p>
              </div>
              <div class="col-1">
                <span class="switch switch-icon">
                  <label>
                    <input
                      type="checkbox"
                      name="patient_services_is_invoice"
                      formControlName="is_header"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div class="col-5">
                <div>
                  <div class="row">
                    <div class="col-8">
                      Display an image in the report's header?
                      <!--                      <span class="text-muted">(aspect ratio: 10/2)</span>-->
                      <span class="text-danger">*</span>
                      <p class="text-muted p-0 m-0" style="font-size: 11px">
                        Image H*W should be (Width: 1000px, Height: 200px)
                      </p>
                    </div>
                    <div class="col-4 d-flex justify-content-end mb-6">
                      <span class="switch switch-icon">
                        <label>
                          <input
                            type="checkbox"
                            name="patient_services_is_invoice"
                            formControlName="is_header_img"
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </div>
                  <!--                  Header Image 1 Start-->
                  <div class="row mt-4">
                    <h6>Header Image 1</h6>
                  </div>
                  <!--                  </div>-->
                  <div class="row">
                    <div class="col-6">
                      <img
                        *ngIf="showCropperLogo === false"
                        src="{{ settingData?.header_img_url }}"
                        class=""
                        style="width: 150px"
                      />
                    </div>
                    <div class="col-6 text-right">
                      <button
                        (click)="selectHeaderImage1()"
                        type="button"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\Upload.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <path
                                d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                              />
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                x="11"
                                y="2"
                                width="2"
                                height="14"
                                rx="1"
                              />
                              <path
                                d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              />
                            </g></svg
                          ><!--end::Svg Icon--></span
                        >
                      </button>
                    </div>
                  </div>
                  <div style="width: 400px">
                    <image-cropper
                      [imageChangedEvent]="imageChangeHeader1"
                      [maintainAspectRatio]="true"
                      [aspectRatio]="13 / 2"
                      [imageQuality]="75"
                      format="png"
                      (imageCropped)="imageCroppedHeader1($event)"
                      *ngIf="showCropperLogo === true"
                    ></image-cropper>
                  </div>
                  <!--                  Header Image 2 End-->
                  <div class="row mt-4">
                    <h6>Header Image 2</h6>
                  </div>
                  <!--                  </div>-->
                  <div class="row">
                    <div class="col-6">
                      <img
                        *ngIf="showCropperLogoHeader2 === false"
                        src="{{ settingData?.header_file2 }}"
                        class=""
                        style="width: 150px"
                      />
                    </div>
                    <div class="col-6 text-right">
                      <button
                        (click)="selectHeaderImage2()"
                        type="button"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\Upload.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <path
                                d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                              />
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                x="11"
                                y="2"
                                width="2"
                                height="14"
                                rx="1"
                              />
                              <path
                                d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              />
                            </g></svg
                          ><!--end::Svg Icon--></span
                        >
                      </button>
                    </div>
                  </div>
                  <div style="width: 400px">
                    <image-cropper
                      [imageChangedEvent]="imageChangeHeader2"
                      [maintainAspectRatio]="true"
                      [aspectRatio]="13 / 2"
                      [imageQuality]="75"
                      format="png"
                      (imageCropped)="imageCroppedHeader2($event)"
                      *ngIf="showCropperLogoHeader2 === true"
                    ></image-cropper>
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="row mb-6">-->
            <!--              <div class="col-4 py-2">-->
            <!--                <label class="font-weight-500"-->
            <!--                  >Show Header Image in Reports</label-->
            <!--                >-->
            <!--              </div>-->
            <!--              <div class="col-2">-->
            <!--                <span class="switch switch-icon">-->
            <!--                  <label>-->
            <!--                    <input-->
            <!--                      type="checkbox"-->
            <!--                      name="patient_services_is_invoice"-->
            <!--                      formControlName="is_header_img"-->
            <!--                    />-->
            <!--                    <span></span>-->
            <!--                  </label>-->
            <!--                </span>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="row mb-6">
              <div class="col-6">
                <label class="font-weight-500"
                  >Display footer in the patient's lab report?</label
                >
                <p class="text-muted m-0 p-0" style="font-size: 11px">
                  The following uses are possible for this configuration:
                </p>
                <p class="text-muted m-0 p-0" style="font-size: 11px">
                  1.To display the report footer, set the default options.
                </p>
                <p class="text-muted m-0 p-0" style="font-size: 11px">
                  2.If the footer is displayed, you will be able to select
                  between the digital signatures or the image.
                </p>
              </div>
              <div class="col-1">
                <span class="switch switch-icon">
                  <label>
                    <input
                      type="checkbox"
                      name="patient_services_is_invoice"
                      formControlName="is_footer"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div class="col-5">
                <div>
                  <div class="row">
                    <div class="col-8">
                      Display an image in the report's footer?
                      <!--                      <span class="text-muted">(aspect ratio: 10/2)</span>-->
                      <span class="text-danger">*</span>
                      <p class="text-muted p-0 m-0" style="font-size: 11px">
                        Image H*W should be (Width: 1000px, Height: 200px)
                      </p>
                    </div>
                    <div class="col-4 d-flex justify-content-end mb-6">
                      <span class="switch switch-icon">
                        <label>
                          <input
                            type="checkbox"
                            name="patient_services_is_invoice"
                            formControlName="is_footer_img"
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </div>
                  <div class="mt-4 row"><h6>Footer Image 1</h6></div>
                  <div class="row">
                    <div class="col-6">
                      <img
                        *ngIf="showCropperFooter1 === false"
                        src="{{ settingData?.footer_img_url }}"
                        class=""
                        style="width: 150px"
                      />
                    </div>
                    <div class="col-6 text-right">
                      <button
                        (click)="selectImageFooter1()"
                        type="button"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-2"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\Upload.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <path
                                d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                              />
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                x="11"
                                y="2"
                                width="2"
                                height="14"
                                rx="1"
                              />
                              <path
                                d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              />
                            </g></svg
                          ><!--end::Svg Icon--></span
                        >
                      </button>
                    </div>
                  </div>
                  <div style="width: 400px">
                    <image-cropper
                      [imageChangedEvent]="imageChangedFooter1"
                      [maintainAspectRatio]="true"
                      [aspectRatio]="10 / 2"
                      [imageQuality]="75"
                      format="png"
                      (imageCropped)="imageCroppedFooter1($event)"
                      *ngIf="showCropperFooter1 === true"
                    ></image-cropper>
                  </div>

                  <!--                  Footer Image 2 Start-->
                  <div class="mt-4 row"><h6>Footer Image 2</h6></div>
                  <div class="row">
                    <div class="col-6">
                      <img
                        *ngIf="showCropperFooter2 === false"
                        src="{{ settingData?.footer_file2 }}"
                        class=""
                        style="width: 150px"
                      />
                    </div>
                    <div class="col-6 text-right">
                      <button
                        (click)="selectImageFooter2()"
                        type="button"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-2"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\Upload.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <path
                                d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                              />
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                x="11"
                                y="2"
                                width="2"
                                height="14"
                                rx="1"
                              />
                              <path
                                d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              />
                            </g></svg
                          ><!--end::Svg Icon--></span
                        >
                      </button>
                    </div>
                  </div>
                  <div style="width: 400px">
                    <image-cropper
                      [imageChangedEvent]="imageChangedFooter2"
                      [maintainAspectRatio]="true"
                      [aspectRatio]="10 / 2"
                      [imageQuality]="75"
                      format="png"
                      (imageCropped)="imageCroppedFooter2($event)"
                      *ngIf="showCropperFooter2 === true"
                    ></image-cropper>
                  </div>
                  <!--                  Footer Image 2 end-->
                </div>
              </div>
            </div>

            <!--            <div class="row mb-6">-->
            <!--              <div class="col-4 py-2">-->
            <!--                <label class="font-weight-500">Show Footer in Reports</label>-->
            <!--              </div>-->
            <!--              <div class="col-2">-->
            <!--                <span class="switch switch-icon">-->
            <!--                  <label>-->
            <!--                    <input-->
            <!--                      type="checkbox"-->
            <!--                      name="patient_services_is_invoice"-->
            <!--                      formControlName="is_footer"-->
            <!--                    />-->
            <!--                    <span></span>-->
            <!--                  </label>-->
            <!--                </span>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="row mb-6">-->
            <!--              <div class="col-4 py-2">-->
            <!--                <label class="font-weight-500"-->
            <!--                  >Show Footer Image in Reports</label-->
            <!--                >-->
            <!--              </div>-->
            <!--              <div class="col-2">-->
            <!--                <span class="switch switch-icon">-->
            <!--                  <label>-->
            <!--                    <input-->
            <!--                      type="checkbox"-->
            <!--                      name="patient_services_is_invoice"-->
            <!--                      formControlName="is_footer_img"-->
            <!--                    />-->
            <!--                    <span></span>-->
            <!--                  </label>-->
            <!--                </span>-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="row">
              <div class="col-12">
                <div class="row mb-6">
                  <div class="col-6 py-2">
                    <label class="font-weight-500">Skip sample receiving</label>
                  </div>
                  <div class="col-6 text-right">
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          name="patient_services_is_invoice"
                          formControlName="auto_receive"
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                  <div class="col-12">
                    <p class="text-muted p-0 m-0" style="font-size: 11px">
                      By enabling this, the sample receiving step before the
                      result entry entry stage will be skipped.
                    </p>
                  </div>
                </div>
                <div class="row mb-6">
                  <div class="col-6 py-2">
                    <label class="font-weight-500 mt-2"
                      >Skip result verification step?</label
                    >
                  </div>
                  <div class="col-6">
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          name="patient_services_is_invoice"
                          formControlName="auto_verify"
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                  <div class="col-12">
                    <p class="text-muted p-0 m-0" style="font-size: 11px">
                      By enabling this, system will bypass the phase of result
                      verification
                    </p>
                  </div>
                </div>
                <div class="row mb-6">
                  <div class="col-6">
                    <label class="font-weight-500 mt-2"
                      >Restrict lab test cancellation after.</label
                    >
                  </div>
                  <div class="col-4">
                    <select
                      class="form-control form-control-sm"
                      formControlName="lab_status"
                    >
                      <option
                        [ngValue]="''"
                        [selected]="true"
                        [disabled]="true"
                      >
                        Select
                      </option>
                      <option [ngValue]="'Collected'">Collected</option>
                      <option [ngValue]="'Received'">Received</option>
                      <option [ngValue]="'Unverified'">Unverified</option>
                      <option [ngValue]="'Verified'">Verified</option>
                      <option [ngValue]="'Delivered'">Delivered</option>
                    </select>
                  </div>
                  <div class="col-12">
                    <p class="text-muted p-0 m-0" style="font-size: 11px">
                      The option is used to restrict the cancellation of the lab
                      test invoice , if the test status reach the selected
                      status.
                    </p>
                  </div>
                </div>
                <div class="row mb-6">
                  <div class="col-6">
                    <label class="font-weight-500 mt-2"
                      >Display requests for lab tests with a selected payment
                      status.</label
                    >
                  </div>
                  <div class="col-4">
                    <select
                      class="form-control form-control-sm"
                      formControlName="show_worklist"
                    >
                      <option [ngValue]="0">All</option>
                      <option [ngValue]="1">Show Paid only</option>
                      <option [ngValue]="2">
                        Show Paid + Unpaid (of Panel)
                      </option>
                    </select>
                  </div>
                  <div class="col-12">
                    <p class="text-muted p-0 m-0" style="font-size: 11px">
                      On the sample collection screen, the system will show
                      requests for lab tests with selected payment.
                    </p>
                  </div>
                </div>

                <div class="row mb-6">
                  <div class="col-6">
                    <label class="font-weight-500"
                      >Connect with external/Third party LIS</label
                    >
                    <p class="text-muted m-0 p-0" style="font-size: 11px">
                      This setting will enable CHS to be integrated with 3rd
                      party LIS, and the request for lab tests will directly
                      finalized . Additionally, 3rd party LIS will receive
                      patient information.The prepared patient report will be
                      sent back to the CHS via an external url
                    </p>
                  </div>
                  <!--                  <div class="col-2">-->
                  <!--                    <span class="switch switch-icon">-->
                  <!--                      <label>-->
                  <!--                        <input-->
                  <!--                          type="checkbox"-->
                  <!--                          name="patient_services_is_invoice"-->
                  <!--                          formControlName="enable_external_lis"-->
                  <!--                        />-->
                  <!--                        <span></span>-->
                  <!--                      </label>-->
                  <!--                    </span>-->
                  <!--                  </div>-->
                  <div class="col-4">
                    <label>Third party LIS Base URL</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      formControlName="external_lis_base_url"
                    />
                  </div>
                </div>

                <!--                <div class="row mb-6">-->
                <!--                  <div class="col-4 py-2">-->
                <!--                    <label class="font-weight-500">Enable External LIS</label>-->
                <!--                  </div>-->
                <!--                  <div class="col-2">-->
                <!--                    <span class="switch switch-icon">-->
                <!--                      <label>-->
                <!--                        <input-->
                <!--                          type="checkbox"-->
                <!--                          name="patient_services_is_invoice"-->
                <!--                          formControlName="enable_external_lis"-->
                <!--                        />-->
                <!--                        <span></span>-->
                <!--                      </label>-->
                <!--                    </span>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                <div class="row mb-6">-->
                <!--                  <div class="col-3 py-5">-->
                <!--                    <label class="font-weight-500"-->
                <!--                      >External LIS Base Urls</label-->
                <!--                    >-->
                <!--                  </div>-->
                <!--                  <div class="col-2">-->
                <!--                    <div class="form-group">-->
                <!--                      <input-->
                <!--                        type="text"-->
                <!--                        class="form-control form-control-sm"-->
                <!--                        formControlName="external_lis_base_url"-->
                <!--                      />-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                <div class="row">-->
                <!--                  <div class="col-12">-->
                <!--                    <div class="form-group">-->
                <!--                      <div class="mb-2">-->
                <!--                        <label-->
                <!--                          >Report Header Image-->
                <!--                          <span class="text-muted">(aspect ratio: 10/2)</span>-->
                <!--                          <span class="text-danger">*</span></label-->
                <!--                        >-->
                <!--                        <button-->
                <!--                          (click)="selectImage()"-->
                <!--                          type="button"-->
                <!--                          class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-2"-->
                <!--                        >-->
                <!--                          <span class="svg-icon svg-icon-primary svg-icon-2x"-->
                <!--                            >&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\Upload.svg&ndash;&gt;<svg-->
                <!--                              xmlns="http://www.w3.org/2000/svg"-->
                <!--                              xmlns:xlink="http://www.w3.org/1999/xlink"-->
                <!--                              width="24px"-->
                <!--                              height="24px"-->
                <!--                              viewBox="0 0 24 24"-->
                <!--                              version="1.1"-->
                <!--                            >-->
                <!--                              <g-->
                <!--                                stroke="none"-->
                <!--                                stroke-width="1"-->
                <!--                                fill="none"-->
                <!--                                fill-rule="evenodd"-->
                <!--                              >-->
                <!--                                <rect x="0" y="0" width="24" height="24" />-->
                <!--                                <path-->
                <!--                                  d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"-->
                <!--                                  fill="#000000"-->
                <!--                                  fill-rule="nonzero"-->
                <!--                                  opacity="0.3"-->
                <!--                                />-->
                <!--                                <rect-->
                <!--                                  fill="#000000"-->
                <!--                                  opacity="0.3"-->
                <!--                                  x="11"-->
                <!--                                  y="2"-->
                <!--                                  width="2"-->
                <!--                                  height="14"-->
                <!--                                  rx="1"-->
                <!--                                />-->
                <!--                                <path-->
                <!--                                  d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"-->
                <!--                                  fill="#000000"-->
                <!--                                  fill-rule="nonzero"-->
                <!--                                />-->
                <!--                              </g></svg-->
                <!--                            >&lt;!&ndash;end::Svg Icon&ndash;&gt;</span-->
                <!--                          >-->
                <!--                        </button>-->
                <!--                      </div>-->
                <!--                      <img-->
                <!--                        *ngIf="showCropperLogo === false"-->
                <!--                        src="{{ settingData?.header_img_url }}"-->
                <!--                        class="m-t-5"-->
                <!--                        style="width: 150px"-->
                <!--                      />-->
                <!--                      <div style="width: 400px">-->
                <!--                        <image-cropper-->
                <!--                          [imageChangedEvent]="imageChangedEvent"-->
                <!--                          [maintainAspectRatio]="true"-->
                <!--                          [aspectRatio]="10 / 2"-->
                <!--                          [imageQuality]="75"-->
                <!--                          format="png"-->
                <!--                          (imageCropped)="imageCropped($event)"-->
                <!--                          *ngIf="showCropperLogo === true"-->
                <!--                        ></image-cropper>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                <div class="form-group">-->
                <!--                  <div class="mb-6">-->
                <!--                    <label-->
                <!--                      >Report Footer Image-->
                <!--                      <span class="text-muted">(aspect ratio: 10/2)</span>-->
                <!--                      <span class="text-danger">*</span></label-->
                <!--                    >-->
                <!--                    <button-->
                <!--                      (click)="selectImageHome()"-->
                <!--                      type="button"-->
                <!--                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-2"-->
                <!--                    >-->
                <!--                      <span class="svg-icon svg-icon-primary svg-icon-2x"-->
                <!--                        >&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\Upload.svg&ndash;&gt;<svg-->
                <!--                          xmlns="http://www.w3.org/2000/svg"-->
                <!--                          xmlns:xlink="http://www.w3.org/1999/xlink"-->
                <!--                          width="24px"-->
                <!--                          height="24px"-->
                <!--                          viewBox="0 0 24 24"-->
                <!--                          version="1.1"-->
                <!--                        >-->
                <!--                          <g-->
                <!--                            stroke="none"-->
                <!--                            stroke-width="1"-->
                <!--                            fill="none"-->
                <!--                            fill-rule="evenodd"-->
                <!--                          >-->
                <!--                            <rect x="0" y="0" width="24" height="24" />-->
                <!--                            <path-->
                <!--                              d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"-->
                <!--                              fill="#000000"-->
                <!--                              fill-rule="nonzero"-->
                <!--                              opacity="0.3"-->
                <!--                            />-->
                <!--                            <rect-->
                <!--                              fill="#000000"-->
                <!--                              opacity="0.3"-->
                <!--                              x="11"-->
                <!--                              y="2"-->
                <!--                              width="2"-->
                <!--                              height="14"-->
                <!--                              rx="1"-->
                <!--                            />-->
                <!--                            <path-->
                <!--                              d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"-->
                <!--                              fill="#000000"-->
                <!--                              fill-rule="nonzero"-->
                <!--                            />-->
                <!--                          </g></svg-->
                <!--                        >&lt;!&ndash;end::Svg Icon&ndash;&gt;</span-->
                <!--                      >-->
                <!--                    </button>-->
                <!--                  </div>-->
                <!--                  <img-->
                <!--                    *ngIf="showCropperHome === false"-->
                <!--                    src="{{ settingData?.footer_img_url }}"-->
                <!--                    class="m-t-5"-->
                <!--                    style="width: 150px"-->
                <!--                  />-->
                <!--                  <div style="width: 400px">-->
                <!--                    <image-cropper-->
                <!--                      [imageChangedEvent]="imageChangedEventHome"-->
                <!--                      [maintainAspectRatio]="true"-->
                <!--                      [aspectRatio]="10 / 2"-->
                <!--                      [imageQuality]="75"-->
                <!--                      format="png"-->
                <!--                      (imageCropped)="imageCroppedHome($event)"-->
                <!--                      *ngIf="showCropperHome === true"-->
                <!--                    ></image-cropper>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="row mb-6">
                  <div class="col-6 py-2">
                    <label class="font-weight-500"
                      >Restrict lab test report Print/deliver for Un-paid OPD
                      test?</label
                    >
                  </div>
                  <div class="col-6">
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          name="patient_services_invoice"
                          formControlName="print_unpaid_invoice"
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                  <div class="col-12">
                    <p class="text-muted" style="font-size: 11px">
                      Enabling this control will restricted the user to
                      print/deliver the lab report for un-paid OPD lab test.
                    </p>
                  </div>
                </div>
                <div class="row mb-6">
                  <div class="col-6 py-2">
                    <label class="font-weight-500"
                      >Restrict Lab test report print with Unapproved
                      Discount?</label
                    >
                  </div>
                  <div class="col-6">
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          name="patient_services_is_invoice"
                          formControlName="print_unapproved_discount"
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                  <div class="col-12">
                    <p class="text-muted" style="font-size: 11px">
                      Enabling this control will restricted the user to print
                      the report for lab test with unapproved discount.
                    </p>
                  </div>
                </div>
                <div class="row mb-6">
                  <div class="col-6 py-2">
                    <label class="font-weight-500"
                      >Stop OPD unpaid lab service for processing</label
                    >
                  </div>
                  <div class="col-6">
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          name="patient_services_is_invoice"
                          formControlName="stop_unpaid_invoice_in_collection"
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                  <div class="col-12">
                    <p class="text-muted" style="font-size: 11px">
                      Enabling this control will restricted to showing unpaid
                      OPD services for processing
                    </p>
                  </div>
                </div>
              </div>

              <!--              <div class="col-sm-4">-->
              <!--                <div class="form-group">-->
              <!--                  <label></label>-->
              <!--                  -->
              <!--                </div>-->
              <!--              </div>-->

              <!--add button Start-->
              <div class="col-12 text-right">
                <br />
                <button
                  type="submit"
                  class="btn btn-primary btn-sm cus_btm"
                  ngbTooltip="Update Settings"
                  placement="bottom"
                >
                  Update
                </button>
              </div>
              <!--add button End-->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<input
  type="file"
  accept="image/*"
  style="visibility: hidden"
  class="marginInput"
  id="imgHeader1Select"
  (change)="fileChangeHeader1($event)"
/>
<input
  type="file"
  accept="image/*"
  style="visibility: hidden"
  class="marginInput"
  id="imgHeader2Select"
  (change)="fileChangeHeader2($event)"
/>
<input
  type="file"
  accept="image/*"
  style="visibility: hidden"
  class="marginInputHome"
  id="imgSelectFooter1"
  (change)="fileChangeFooter1($event)"
/>
<input
  type="file"
  accept="image/*"
  style="visibility: hidden"
  class="marginInputHome"
  id="imgSelectFooter2"
  (change)="fileChangeFooter2($event)"
/>
