import { Component, OnInit } from '@angular/core';
import { HospitalService } from '../../../services/hospital.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientService } from '../../../services/patient.service';
import { PatientAdmissionService } from '../../../services/patient-admission.service';
import { OpdService } from '../../../services/opd.service';
import { PatientHistoryService } from '../../../services/patient-history.service';

declare var toastr: any;
declare var $: any;
@Component({
  selector: 'app-history-print',
  templateUrl: './history-print.component.html',
  styleUrls: ['./history-print.component.css'],
})
export class HistoryPrintComponent implements OnInit {
  settingData: any;
  ParentPatientSearchForm: UntypedFormGroup;
  patientMRN: number;
  patient: any;
  patientHistorySource: any;
  patientComplaint: any;
  patientGeneralHistory: any;
  paitestDiagnosisHistoryData = [];
  paitestDiagnosisHistory = [];
  patientAllHistory = false;
  reviewSystemHistory: any;
  patientCentralNervousSystem: any;
  physicalSystemicExaminationHistory: any;
  physicalSystemicExaminationHistoryExist = false;
  physical_systemic_examination_id: any;
  paitentMedicineHistory = [];
  allergiesListData = [];
  paitentAllergiesSearch = '';
  allergiesSearchResult = false;
  clearTimeout: any;

  constructor(
    private hospitalService: HospitalService,
    private formBuilder: UntypedFormBuilder,
    private patientHistoryService: PatientHistoryService,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private patientAdmissionService: PatientAdmissionService,
    private opdService: OpdService,
  ) {
    this.route.queryParams.subscribe((data) => {
      this.patientMRN = data.mrn;
      console.log('mrnnnmrnmrn', this.patientMRN);
    });
  }
  historyPrint(): any {
    window.print();
  }
  ngOnInit(): void {
    // if (this.patientMRN && (this.page === 'ipd' || this.page === 'radiology')) {
    this.ParentPatientSearchForm = this.formBuilder.group({
      search_query: [this.patientMRN],
      type: ['mrn'],
    });
    // }
    this.getSettingsData();
    this.fetchPatients();
  }

  fetchPatients(): any {
    // this.patients = [];
    // this.isPatients = false;
    this.ngxLoader.start();
    this.patientService
      .searchPatientByType(this.ParentPatientSearchForm.value)
      .subscribe(
        (data) => {
          if (data.patients) {
            this.patient = data.patients[0];
            // this.setDatesOfMedicines();
            this.fetchPatientHistorySources(this.patient);
            this.fetchPatientComplaints(this.patient);
            // this.fetchPatientAllergies(this.patient);
            this.fetchGeneralHistory(this.patient);
            this.fetchReviewSystemHistory(this.patient);
            this.fetchCentralNervousSystem(this.patient);
            this.fetchPhysicalSystemicExaminationHistory(this.patient);
            this.fetchMedicineHistory();
            this.getPaitentDiagnosis();
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  fetchMedicineHistory(): any {
    this.ngxLoader.start();
    this.paitentMedicineHistory = [];
    this.patientHistoryService.fetchMedicineHistoryFun(this.patient).subscribe(
      (data) => {
        this.paitentMedicineHistory = data.patient_medications;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  fetchPhysicalSystemicExaminationHistory(patient): any {
    this.patientHistoryService
      .fetchPhysicalSystemicExamination(this.patient)
      .subscribe(
        (data) => {
          this.physicalSystemicExaminationHistory =
            data.physical_systemic_examination;
          if (data.physical_systemic_examination) {
            this.physical_systemic_examination_id =
              data.physical_systemic_examination.id;
            this.physicalSystemicExaminationHistoryExist = true;
          }
        },
        (err) => {},
      );
  }
  fetchCentralNervousSystem(patient): any {
    this.patientHistoryService
      .fetchCentralNervousSystem(this.patient)
      .subscribe(
        (data) => {
          this.patientCentralNervousSystem = data;
          // if (Object.keys(data).length !== 0){
          //   this.central_nervous_system_id = data.id;
          //   this.patientCentralNervousSystemExist = true;
          // }
        },
        (err) => {},
      );
  }
  fetchReviewSystemHistory(patient): any {
    this.patientHistoryService.fetchReviewSystemHistory(this.patient).subscribe(
      (data) => {
        this.reviewSystemHistory = data.patient_review_system;
        // if (data.patient_review_system){
        //   this.review_system_history_id = data.patient_review_system.id
        //   this.reviewSystemHistoryExist = true;
        // }
      },
      (err) => {},
    );
  }
  getPaitentDiagnosis(): any {
    this.paitestDiagnosisHistoryData = [];
    this.paitestDiagnosisHistory = [];
    // this.diagnosisSearch = '';
    // this.differentialSearch = '';
    // this.finalSearch = '';
    this.opdService
      .getPaitentDiagnosisFun(this.patient, this.patientAllHistory)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.paitestDiagnosisHistoryData = data;
          this.paitestDiagnosisHistory = data.patient_diagnoses;
          $('#diagnosisFilter').val('patient_diagnosis');
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  fetchGeneralHistory(patient): any {
    this.patientHistoryService.fetchGeneralHistory(this.patient).subscribe(
      (data) => {
        this.patientGeneralHistory = data.patient_general_history;
        // if (data.patient_general_history){
        //   this.patient_general_history_id = data.patient_general_history.id
        //   this.patientGeneralHistoryExist = true;
        // }
      },
      (err) => {},
    );
  }
  fetchPatientComplaints(patient): any {
    this.patientAdmissionService
      .fetchPatientComplaintsByAdmission(this.patient)
      .subscribe(
        (data) => {
          this.patientComplaint = data;
          // $('#tags').tagsinput('add', data.allergies);
          // if (Object.keys(data).length !== 0) {
          //   this.presentingComplaintExist = true;
          // }
        },
        (err) => {},
      );
  }

  fetchPatientHistorySources(patient): any {
    this.patientAdmissionService
      .fetchPatientHistorySourcesByAdmission(this.patient)
      .subscribe(
        (data) => {
          this.patientHistorySource = data;
          // if (Object.keys(data).length !== 0) {
          //   this.patientHistorySourceExist = true;
          // }
        },
        (err) => {},
      );
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
}
