import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class LookupService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/lookups?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  getLookupsMedicalUnitWise(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/lookups/visit_type_medical_unit_wise?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  indexV2(category): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/lookups?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&category=' +
          category,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  lookupsCategoryWise(category): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/lookups_listing?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&category=' +
          category,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  indexVisitType(category): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/lookup_medical_unit_wise/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&category=' +
          category,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  indexVisitTypeV2(
    doctorId,
    departmentId,
    isopd,
    isipd,
    patientId,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/doctor_fee/user_categories/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&doctor_id=' +
          doctorId +
          '&department_id=' +
          departmentId +
          '&patient_id=' +
          patientId +
          '&is_opd=' +
          isopd +
          '&is_ipd=' +
          isipd,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  lookupListingWIthoutPagination(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/lookups?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  lookupListingWIthoutPaginationv2(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/lookups/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  lookupListingWIthPagination(page, per_page): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/lookups/lookups_listing?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=true' +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  lookupListingWIthPaginationv2(page, per_page): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/lookups_listing/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=true' +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  updateLookup(value, parent_id): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/lookups/` +
          parent_id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          category: value.category,
          key: value.key,
          value: value.value,
          parent_id: value.parent_id,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  updateLookupv2(value, parent_id): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/api/lookups/` +
          parent_id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          category: value.category,
          key: value.key,
          value: value.value,
          parent_id: value.parent_id,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  submitLookupForm(value): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/lookups?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          category: value.category,
          key: value.key,
          value: value.value,
          parent_id: value.parent_id,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  submitLookupFormv2(value): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/lookups/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          category: value.category,
          key: value.key,
          value: value.value,
          parent_id: value.parent_id,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  addPrescription(value): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/prescription_templates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  getPrescriptionList(page, per_page): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/prescription_templates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  updatePrescription(value, id): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/prescription_templates/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // Tags Api
  addTags(value): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/tags?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  getTagList(page, per_page, status): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/tags?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  updateTags(value, id): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/tags/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  // Heading listing api
  headingListing(check_list_template_id): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/check_list_headings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&check_list_template_id=' +
          check_list_template_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  getList(check_list_template_id): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/check_list_templates_configurator?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&check_list_template_id=' +
          check_list_template_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
}
