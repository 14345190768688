import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OpdService } from '../../../../services/opd.service';
import { ConfiguratorsService } from '../../../../services/configurators.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css'],
})
export class DocumentsComponent implements OnInit {
  @ViewChild('patientImageModal') patientImageModal: ElementRef;

  @Input() patient: any;
  @Input() visitType: any;
  @Input() isAdd: boolean;

  reportsObject: UntypedFormGroup;

  disableSubmitButton = false;
  patientDocs = [];
  Image = [];
  docTypes = [];
  imageDetail: any;
  currentImage = '';
  currentImageIndex: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private opdService: OpdService,
    private configuratorService: ConfiguratorsService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.reportsObject = this.formBuilder.group({
      docType: '',
      document_name: '',
      files: '',
      text_areas: '',
    });
    setTimeout(() => {
      $('#textareas').summernote();
    }, 500);

    this.getDocTypes();
    // this.getPatDocs('');
  }

  ngOnChanges(changes: SimpleChanges): any {
    this.getPatDocs(changes.patient.currentValue);
  }

  uploadImage(): any {
    if (this.reportsObject.get('docType').value === '') {
      toastr.error('Select Document Type');
      return;
    }
    this.reportsObject.get('files').setValue(this.Image);
    this.reportsObject
      .get('text_areas')
      .setValue($('#textareas').summernote('code'));
    if (this.reportsObject.get('files').value.length === 0) {
      toastr.error('Select Files');
      return;
    }
    this.disableSubmitButton = true;
    this.ngxLoader.start();
    this.opdService
      .uploadPatientDocumentsV2(
        this.reportsObject.value,
        '',
        'ipd',
        '',
        this.patient.visit_id,
        '',
        this.patient.id,
      )
      .subscribe(
        (data) => {
          this.disableSubmitButton = false;
          this.ngxLoader.stop();
          if (data.message) {
            toastr.success(data.message);
            this.getPatDocs('');
            // this.docType = '';
            this.Image = [];
            $('#file').val('');
            this.reportsObject.reset({
              docType: '',
              document_name: '',
            });
            $('#textareas').summernote('code', '');
          }
        },
        (error) => {
          this.disableSubmitButton = false;
        },
      );
  }

  getPatDocs(val): any {
    console.log(val);
    this.patientDocs = [];
    this.ngxLoader.start();
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.opdService
      .getPatientDcmntsV2(
        this.patient.id,
        this.patient.visit_id,
        this.visitType,
      )
      .subscribe(
        (data) => {
          if (data.message) {
            toastr.success(data.message);
          }
          if (data.attachments) {
            this.patientDocs = data.attachments;
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  getDocTypes(): any {
    this.docTypes = [];
    this.ngxLoader.start();
    this.configuratorService.getDocTypesV2('true').subscribe(
      (data) => {
        this.docTypes = data.document_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  onFileChange(event): any {
    for (let i = 0; i < event.target.files.length; i++) {
      this.Image.push(event.target.files[i]);
      if (event.target.files.length === i + 1) {
        $('#file').val('');
      }
    }
  }
  removeFileObject(index: any) {
    this.Image.splice(index, 1);
  }

  openDoc(url: any, index, id): any {
    if (url) {
      this.imageDetail = this.patientDocs.find((t) => t.id === Number(id));
      this.currentImage = url;
      this.currentImageIndex = index;
      this.modalService.open(this.patientImageModal, { size: 'lg' });
    }
  }

  openDetailModal(id): any {
    this.imageDetail = this.patientDocs.find((t) => t.id === Number(id));
    $('#detailModal').modal('show');
  }

  closePatImgModal(): any {
    this.currentImage = '';
    this.modalService.dismissAll(this.patientImageModal);
  }
}
