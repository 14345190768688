<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center"></div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="card card-custom mb-3">
            <div class="card-body">
              <!--begin::Details-->
              <div class="d-flex">
                <!--begin: Pic-->
                <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                  <div class="symbol symbol-50 symbol-lg-120">
                    <img
                      class="medical_fitness_certificate_image"
                      src="assets/media/users/blank.png"
                      alt="image"
                    />
                  </div>
                </div>
                <!--end::Pic-->
                <!--begin::Info-->
                <div class="flex-grow-1">
                  <!--begin::Title-->
                  <div class="d-flex justify-content-between flex-wrap mt-1">
                    <div class="d-flex mr-3">
                      <a
                        href=""
                        class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                        >{{ patient?.first_name + " " + patient?.last_name }}</a
                      >
                      <a href="#">
                        <i
                          class="flaticon2-correct text-success font-size-h5"
                        ></i>
                      </a>
                    </div>
                  </div>
                  <!--end::Title-->
                  <!--begin::Content-->
                  <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                      <div class="d-flex flex-wrap mb-4">
                        <a
                          href="#"
                          class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <i class="flaticon2-placeholder mr-2 font-size-lg"></i
                          >{{ patient?.mrn }}</a
                        >
                        <a
                          href="#"
                          class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <i class="fas fa-male mr-2 font-size-lg"></i
                          >{{ patient?.gender }}</a
                        >
                        <a
                          href="#"
                          class="text-dark-50 text-hover-primary font-weight-bold"
                        >
                          <i class="fa fa-birthday-cake mr-2 font-size-lg"></i
                          >{{ patient?.age }}</a
                        >
                      </div>
                      <span class="font-weight-bold text-dark-50">{{
                        patient?.patient_type?.name | titlecase
                      }}</span>
                    </div>
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Info-->
              </div>
              <!--begin::Items-->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="card">
            <div class="card-body patient-card">
              <form
                [formGroup]="DialysisRequestForm"
                (ngSubmit)="createDialysisRequest()"
              >
                <div class="row mb-6">
                  <div class="col-2">
                    <label>
                      Advised by <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-control form-control-sm"
                      formControlName="advised_by_id"
                      [ngClass]="{
                        'is-invalid': submitted && f.advised_by_id.errors
                      }"
                      data-live-search="true"
                      (change)="fetchDoctorDepartments($event.target.value)"
                    >
                      <option value="">select</option>
                      <option *ngFor="let doc of doctor" [value]="doc.id">
                        {{ doc.first_name }} {{ doc.last_name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-2">
                    <label>
                      Department <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-control form-control-sm"
                      formControlName="department_id"
                      [ngClass]="{
                        'is-invalid': submitted && f.department_id.errors
                      }"
                      (change)="fetchDeptDoctors($event.target.value)"
                    >
                      <option value="">select</option>
                      <option *ngFor="let dep of department" [value]="dep.id">
                        {{ dep.title }}
                      </option>
                    </select>
                  </div>
                  <div class="col-2">
                    <label>Urgency<span class="text-danger">*</span></label>
                    <select
                      class="form-control form-control-sm"
                      formControlName="urgency_id"
                      [ngClass]="{
                        'is-invalid': submitted && f.urgency_id.errors
                      }"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let urgency of urgency"
                        [value]="urgency.id"
                      >
                        {{ urgency.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-2">
                    <label
                      >Suggested Date<span class="text-danger">*</span></label
                    >
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      formControlName="suggested_date"
                      (change)="fetchAvailableSlots()"
                    />
                  </div>

                  <div class="col-2">
                    <label
                      >Suggested Slot<span class="text-danger">*</span></label
                    >
                    <select
                      class="form-control form-control-sm"
                      formControlName="available_slot_id"
                      [ngClass]="{
                        'is-invalid': submitted && f.available_slot_id.errors
                      }"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let available_slot of available_slots"
                        [value]="available_slot.id"
                      >
                        {{ available_slot.start_time | date: "h:mm a" }} -
                        {{ available_slot.end_time | date: "h:mm a" }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mt-6">
                  <div class="col-12 text-right">
                    <button
                      class="btn btn-sm btn-primary mr-1"
                      (click)="backClicked()"
                    >
                      <i class="fas fa-long-arrow-alt-left"></i> Back
                    </button>
                    <button
                      type="button"
                      (click)="resetDialysisRequestForm()"
                      class="btn btn-primary btn-sm"
                    >
                      Clear
                    </button>
                    <button type="submit" class="btn btn-primary btn-sm ml-2">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!--end::Ot Calendar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
