<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            Lab Tracking Report
          </h5>
          <!--          xx  tab headings-->

          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-light-primary btn-sm" (click)="printReport()">
          Print
        </button>
        <!-- <button class="btn btn-primary btn-sm ml-2" (click)="goBack()">Back</button> -->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="card pdf-card" id="reports">
        <div class="card-body">
          <div class="d-flex">
            <div class="d-none d-print-block" style="width: 100%">
              <!-- header table -->
              <table class="table">
                <tbody>
                  <tr class="borders">
                    <td style="width: 15%; padding: 0">
                      <img
                        [src]="settingData?.logo?.url"
                        style="width: auto; height: 106px"
                      />
                    </td>
                    <td style="width: 70%">
                      <div class="text-center">
                        <h3 class="hosp-name">{{ settingData?.title }}</h3>
                      </div>
                      <div class="font-size-h5 text-center">
                        <h5 class="hosp-address">{{ settingData?.address }}</h5>
                      </div>
                      <div class="text-center font-weight-bold">
                        <h3>LAB TRACKING REPORT</h3>
                      </div>
                    </td>
                    <td style="width: 15%"></td>
                  </tr>
                </tbody>
              </table>
              <!-- patient info detail table -->
              <!--                            <table class="w-100 info_table">-->
              <!--                                <tbody>-->
              <!--                                <tr>-->
              <!--                                    <app-patient-info></app-patient-info>-->
              <!--                                </tr>-->
              <!--                                </tbody>-->
              <!--                            </table>-->
            </div>
          </div>

          <hr class="my-6 line" />

          <div class="table-responsive">
            <table class="table table-vertical-center">
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Patient<br />Information</th>
                  <th>Test<br />Information</th>
                  <th>
                    <span>Created By</span><br />
                    <span>Date & Time</span>
                  </th>
                  <th>
                    <span>Collected by</span><br />
                    <span>Date & Time</span>
                  </th>
                  <th>
                    <span>Received by</span><br />
                    <span>Date & Time</span>
                  </th>
                  <th>
                    <span>Result Entry</span><br />
                    <span>Date & Time</span>
                  </th>
                  <th>
                    <span>Verification</span><br />
                    <span>Date & Time</span>
                  </th>
                  <th>
                    <span>Delivered</span><br />
                    <span>Date & Time</span>
                  </th>
                  <th>Reporting Time</th>
                  <th>Processing Time</th>
                  <th>Delay</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let trackingData of labTrackingData; let i = index"
                >
                  <tr>
                    <td>{{ i + 1 }}</td>
                    <td>
                      <div>{{ trackingData?.patient.full_name }}</div>
                      <div>
                        <span>{{ trackingData?.patient.mrn }}-</span>
                        <span
                          >{{
                            trackingData?.patient.gender.charAt(0) | titlecase
                          }}-</span
                        >
                        <span>{{ trackingData?.patient.age }}</span>
                      </div>
                    </td>
                    <td>
                      <div>{{ trackingData?.test?.test_name }}</div>
                      <div>{{ trackingData?.test?.lab_id }}</div>
                      <div>{{ trackingData?.test?.department_name }}</div>
                    </td>
                    <td>
                      <div>{{ trackingData?.created_by?.full_name }}</div>
                      <div>
                        {{
                          trackingData?.created_by?.created_at
                            | date: "dd-MM-yyyy h:mm a"
                        }}
                      </div>
                    </td>
                    <td>
                      <div>{{ trackingData?.collected_by?.full_name }}</div>
                      <div>
                        {{
                          trackingData?.collected_by?.created_at
                            | date: "dd-MM-yyyy h:mm a"
                        }}
                      </div>
                    </td>
                    <td>
                      <div>{{ trackingData?.received_by?.full_name }}</div>
                      <div>
                        {{
                          trackingData?.received_by?.created_at
                            | date: "dd-MM-yyyy h:mm a"
                        }}
                      </div>
                    </td>
                    <td>
                      <div>{{ trackingData?.enter_by?.full_name }}</div>
                      <div>
                        {{
                          trackingData?.enter_by?.created_at
                            | date: "dd-MM-yyyy h:mm a"
                        }}
                      </div>
                    </td>
                    <td>
                      <div>{{ trackingData?.verify_by?.full_name }}</div>
                      <div>
                        {{
                          trackingData?.verify_by?.created_at
                            | date: "dd-MM-yyyy h:mm a"
                        }}
                      </div>
                    </td>
                    <td>
                      <div>{{ trackingData?.delivered_by?.full_name }}</div>
                      <div>
                        {{
                          trackingData?.delivered_by?.created_at
                            | date: "dd-MM-yyyy h:mm a"
                        }}
                      </div>
                    </td>
                    <td>
                      <div
                        *ngIf="trackingData?.reporting_time?.reporting_value"
                      >
                        {{ trackingData?.reporting_time?.reporting_value }}
                        {{ trackingData?.reporting_time?.reporting_type }}
                      </div>
                    </td>
                    <td>
                      <div *ngIf="trackingData?.processing_time?.hour">
                        {{ trackingData?.processing_time?.hour }} Hrs
                      </div>
                      <div *ngIf="trackingData?.processing_time?.minutes">
                        {{ trackingData?.processing_time?.minutes }} Mins
                      </div>
                    </td>
                    <td>
                      <div *ngIf="trackingData?.delay_time?.hour >= 0">
                        {{ trackingData?.delay_time?.hour }} Hr
                      </div>
                      <div *ngIf="trackingData?.delay_time?.minutes >= 0">
                        {{ trackingData?.delay_time?.hour }} Hr
                      </div>
                      <div *ngIf="trackingData?.delay_time?.minutes">
                        {{ trackingData?.delay_time?.minutes }} Mins
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
