import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { DepartmentsByHospitalService } from '../../../../../services/departments-by-hospital.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { HospitalService } from '../../../../../services/hospital.service';
import { RouteShareService } from '../../../../../services/route-share.service';
import * as moment from 'moment';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-day-wise-status-report',
  templateUrl: './day-wise-status-report.component.html',
  styleUrls: ['./day-wise-status-report.component.css'],
})
export class DayWiseStatusReportComponent implements OnInit {
  today = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  yesterday = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  depart: any;
  employeesOfDepart: any;
  employeesOfSelectedDepartment: any;
  selectedLocation: any;
  employeeRoasterData = [];
  shiftLength = [];
  employeeDetails: any;
  locationData = [];
  department: any;
  fromDate: '';
  toDate: '';
  settingData: any;
  screenName = '';
  dummyDepartArray = [];

  constructor(
    private departmentsByHospital: DepartmentsByHospitalService,
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private rosterServiceService: RosterServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef,
    private hospitalService: HospitalService,
    private routeService: RouteShareService,
  ) {
    this.screenName = this.routeService.getValue();
    this.getSettingsData();
    this.getLocation();
    this.getDepartments();
  }
  getLocation(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.locationData = data.location;
        setTimeout((e) => {
          $('#selectedLocation').selectpicker('refresh');
          $('#selectedLocation').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getEmployeesOfDepartment(id): any {
    this.employeesOfDepart = [];
    setTimeout((e) => {
      $('#employeeDepartmentList').selectpicker('refresh');
    }, 200);
    this.configurationService
      .getEmployeesOfSpecificDepartment(this.department)
      .subscribe(
        (data) => {
          this.employeesOfDepart = data;
          setTimeout((e) => {
            $('#employeeDepartmentList').selectpicker('refresh');
            $('#employeeDepartmentList').selectpicker();
          }, 500);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  appendArray(): any {
    this.dummyDepartArray = [];
    if (this.department?.length > 0 && this.department !== '') {
      this.depart.filter((o1) => {
        this.department.some((o2) => {
          if (o1.id === o2) {
            this.dummyDepartArray.push(o1.name);
          }
        });
      });
    }
    console.log('this.dummyDepartArray', this.dummyDepartArray);
    this.fromDate = $('#fromDate').val();
    this.toDate = $('#toDate').val();
    if (this.employeesOfSelectedDepartment === undefined) {
      this.employeesOfSelectedDepartment = '';
    }
    if (this.fromDate === '') {
      toastr.error('Select from date');
      return;
    }
    if (this.toDate === '') {
      toastr.error('Select to date');
      return;
    }

    const dateB = moment($('#fromDate').val(), 'DD-MM-YYYY');
    const dateC = moment($('#toDate').val(), 'DD-MM-YYYY');
    var dateDiff = dateC.diff(dateB, 'days') + 1;
    console.log('dateDiff', dateDiff);
    if (dateDiff > 31) {
      toastr.error('Date range must be between 31 days');
      return;
    }

    this.ngxLoader.start();
    this.rosterServiceService
      .getEmployeeAttendanceByDateRange(
        this.employeesOfSelectedDepartment || '',
        this.fromDate,
        this.toDate,
        this.department || '',
        this.selectedLocation || '',
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.employeeRoasterData = data.list;
          this.employeeDetails = data.employee;
          if (!this.employeeRoasterData.length) {
            toastr.error('Data Not Found');
            // $('#promissionsAlertModal').modal('show');
          }
          if (data.list) {
            this.shiftLength = this.employeeRoasterData[0].data;
          }
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  printReport(): any {
    window.print();
  }
  ngOnInit(): void {
    $('#fromDate, #toDate').datepicker({
      format: 'dd-mm-yyyy',
      orientation: 'bottom left',
    });
    // this.fromDate = this.yesterday;
    // this.toDate = this.today;
  }
}
