<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <!--end::Page Title-->
          <!--begin::Info-->
          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Actions-->
        <button
          class="btn btn-primary btn-sm"
          type="button"
          *ngIf="!mrn"
          (click)="openPatientList()"
        >
          Patients
        </button>
        <!--end::Actions-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->
      <div class="row">
        <div class="col-12">
          <!-- patient info card-->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="d-flex">
                    <!--begin::Pic-->
                    <div class="flex-shrink-0 mr-7">
                      <div class="symbol symbol-50 symbol-lg-100">
                        <img
                          *ngIf="!patient"
                          src="assets/media/users/blank.png"
                          class="h-50 align-self-end"
                          alt="Image"
                        />
                        <img
                          *ngIf="patient?.gender === 'transgender'"
                          src="assets/media/users/blank.png"
                          class="h-50 align-self-end"
                          alt=""
                        />
                        <img
                          *ngIf="patient?.gender === 'unidentified'"
                          src="assets/media/users/blank.png"
                          class="h-50 align-self-end"
                          alt=""
                        />
                        <img
                          *ngIf="patient?.gender === 'male'"
                          src="assets/media/svg/avatars/009-boy-4.svg"
                          class="h-50 align-self-end"
                          alt=""
                        />
                        <img
                          *ngIf="patient?.gender === 'female'"
                          src="assets/media/svg/avatars/002-girl.svg"
                          class="h-50 align-self-end"
                          alt=""
                        />
                      </div>
                    </div>
                    <!--end::Pic-->
                    <!--begin: Info-->
                    <div class="flex-grow-1">
                      <!--begin::Title-->
                      <div
                        class="d-flex align-items-center justify-content-between flex-wrap mt-2"
                      >
                        <!--begin::User-->
                        <div class="mr-3" *ngIf="patient">
                          <!--begin::Name-->
                          <a
                            class="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3"
                            ><span *ngIf="patient?.title"
                              >{{ patient?.title | titlecase }} </span
                            >&nbsp;{{
                              patient?.first_name + " " + patient?.last_name
                                | titlecase
                            }}</a
                          >
                          <!--end::Name-->
                          <!--begin::Contacts-->
                          <div class="d-flex flex-wrap my-2">
                            <a
                              class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                              >{{ patient?.mrn }}</a
                            >
                            <a
                              class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                              >{{ patient?.age }}</a
                            >
                            <a
                              class="text-muted text-hover-primary font-weight-bold"
                              >{{ patient?.gender | titlecase }}</a
                            >
                          </div>
                          <div class="d-flex flex-wrap my-2">
                            <a
                              class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                              >{{ patient?.patient_type?.name | titlecase }}</a
                            >
                          </div>
                          <div class="d-flex flex-wrap my-2">
                            <a
                              class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                              >{{ patient?.phone1 }}</a
                            >
                          </div>
                          <!--end::Contacts-->
                        </div>
                        <!--begin::User-->
                      </div>
                      <!--end::Title-->
                    </div>
                    <!--end::Info-->
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="mr-3" *ngIf="patient">
                    <div class="d-flex flex-wrap my-2">
                      <a
                        class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >Admission No:
                        <span *ngIf="patient?.admissions.length > 0">{{
                          patient.admissions[patient?.admissions.length - 1]?.id
                        }}</span></a
                      >
                    </div>
                    <div class="d-flex flex-wrap my-2">
                      <a
                        class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >Admission Date:
                        <span *ngIf="patient?.admissions.length > 0">{{
                          patient.admissions[patient?.admissions.length - 1]
                            ?.created_at | date: "dd-MM-yyyy"
                        }}</span></a
                      >
                    </div>
                    <div class="d-flex flex-wrap my-2">
                      <a
                        class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >ward/Bed:
                        <span *ngIf="patient?.admissions.length > 0"
                          >{{
                            " " +
                              patient.admissions[patient?.admissions.length - 1]
                                ?.ward
                          }}
                          ({{ patient?.bed_name | titlecase }})
                        </span></a
                      >
                    </div>
                    <div class="d-flex flex-wrap my-2">
                      <a
                        class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >Consultant:
                        <span *ngIf="patient?.consultant_name">{{
                          patient?.consultant_name
                        }}</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Patient Issuing Items -->
          <div class="card mt-4" *ngIf="patient && isShowIssueItemCard">
            <div class="card-body">
              <form [formGroup]="issueItemsForm" (ngSubmit)="submit()">
                <div class="row m-0">
                  <div class="col-12">
                    <div class="">
                      <table
                        class="table table-head-custom table-vertical-center"
                      >
                        <thead>
                          <tr>
                            <th style="width: 10%">Item Code</th>
                            <th style="width: 30%">Item Name</th>
                            <th style="width: 10%">UOM</th>
                            <th style="width: 10%">Available Qty</th>
                            <th style="width: 10%">Issue Qty</th>
                            <th style="width: 10%">Sale Price</th>
                            <th style="width: 10%">Amount</th>
                            <th style="width: 10%" class="text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            formArrayName="issueItemsArray"
                            *ngFor="
                              let item of controlIssueItems.controls;
                              let i = index
                            "
                          >
                            <ng-container [formGroupName]="i">
                              <td>
                                <input
                                  type="text"
                                  disabled
                                  class="form-control form-control-sm"
                                  formControlName="item_code"
                                  placeholder="Code"
                                  [ngClass]="{
                                    'is-invalid':
                                      isSubmit && item.controls.item_code.errors
                                  }"
                                />
                              </td>
                              <td>
                                <div>
                                  <select
                                    class="form-control form-control-sm selectpicker"
                                    id="product{{ i }}"
                                    formControlName="id"
                                    data-container="body"
                                    data-live-search="true"
                                    [ngClass]="{
                                      'is-invalid':
                                        isSubmit && item.controls.id.errors
                                    }"
                                    (change)="
                                      getOTProductDetail($event.target.value, i)
                                    "
                                  >
                                    <option
                                      *ngFor="let data of issueItems"
                                      [value]="data.id"
                                    >
                                      {{ data?.name }}
                                    </option>
                                  </select>
                                </div>
                                <!--                              <input type="text" class="form-control form-control-sm" formControlName="item_name" placeholder="Name" [ngClass]="{ 'is-invalid': isSubmit && item.controls.item_name.errors }" autocomplete="off">-->
                              </td>
                              <td>
                                <input
                                  type="text"
                                  disabled
                                  class="form-control form-control-sm"
                                  formControlName="uom"
                                  placeholder="uom"
                                  [ngClass]="{
                                    'is-invalid':
                                      isSubmit && item.controls.uom.errors
                                  }"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  disabled
                                  class="form-control form-control-sm"
                                  formControlName="available_qty"
                                  placeholder="Qty"
                                  [ngClass]="{
                                    'is-invalid':
                                      isSubmit &&
                                      item.controls.available_qty.errors
                                  }"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  formControlName="issue_qty"
                                  min="1"
                                  (input)="updatePrice($event.target.value, i)"
                                  (keypress)="_onlyNumeric($event)"
                                  placeholder="Qty"
                                  [ngClass]="{
                                    'is-invalid':
                                      isSubmit && item.controls.issue_qty.errors
                                  }"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  disabled
                                  class="form-control form-control-sm"
                                  formControlName="sale_price"
                                  placeholder="Price"
                                  [ngClass]="{
                                    'is-invalid':
                                      isSubmit &&
                                      item.controls.sale_price.errors
                                  }"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  disabled
                                  class="form-control form-control-sm"
                                  formControlName="amount"
                                  placeholder="Amount"
                                  [ngClass]="{
                                    'is-invalid':
                                      isSubmit && item.controls.amount.errors
                                  }"
                                />
                              </td>
                              <td class="text-center">
                                <button
                                  (click)="removeIssueItems(i)"
                                  class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                  type="button"
                                >
                                  <span
                                    class="svg-icon svg-icon-primary svg-icon-2x"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        />
                                        <path
                                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                          fill="#000000"
                                          fill-rule="nonzero"
                                        />
                                        <path
                                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                          fill="#000000"
                                          opacity="0.3"
                                        />
                                      </g></svg
                                    ><!--end::Svg Icon--></span
                                  >
                                </button>
                              </td>
                            </ng-container>
                          </tr>
                          <tr>
                            <td colspan="7"></td>
                            <td class="text-center">
                              <button
                                type="button"
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                (click)="addMoreIssueItems()"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                      <path
                                        d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                        fill="#000000"
                                      />
                                    </g></svg
                                  ><!--end::Svg Icon-->
                                </span>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="7" style="border: 0 !important"></td>
                            <td
                              style="border: 0 !important; text-align: center"
                            >
                              <button
                                type="submit"
                                class="btn btn-sm btn-primary"
                              >
                                Issue
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row m-0 mt-2 text-right">
                  <div class="col-12"></div>
                  <div class="col-12 mt-4"></div>
                </div>
              </form>
            </div>
          </div>
          <!-- Patient Issued Items Listing -->
          <div
            class="card mt-4"
            *ngIf="patient && this.patientIssuedItems.length > 0"
          >
            <div class="card-body">
              <div class="row m-0">
                <div class="col-12">
                  <form
                    [formGroup]="returnItemsForm"
                    (ngSubmit)="submitReturnItems()"
                  >
                    <div class="table-responsive">
                      <table
                        class="table table-head-custom table-vertical-center"
                      >
                        <thead>
                          <tr>
                            <th style="width: 10%">Date</th>
                            <th style="width: 8%">Issue Id</th>
                            <th style="width: 10%">Item Code</th>
                            <th style="width: 30%">Item Name</th>
                            <th style="width: 10%">UOM</th>
                            <th style="width: 8%">Issued Qty</th>
                            <!--                          <th style="width: 10%">Returned Qty</th>-->
                            <th style="width: 8%" *ngIf="isShowReturnCol">
                              Return Qty
                            </th>
                            <th style="width: 8%">Bal. Qty</th>
                            <th style="width: 10%">Sale Price</th>
                            <th style="width: 10%">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            formArrayName="returnItemsArray"
                            *ngFor="
                              let item of controlReturnItems.controls;
                              let i = index
                            "
                          >
                            <ng-container [formGroupName]="i">
                              <td>
                                {{
                                  item.get("date").value | date: "dd-MM-yyyy"
                                }}
                              </td>
                              <td>{{ item.get("reference_no").value }}</td>
                              <td>{{ item.get("item_code").value }}</td>
                              <td>{{ item.get("item_name").value }}</td>
                              <td>{{ item.get("uom").value }}</td>
                              <td>
                                {{
                                  item.get("issue_quantity").value -
                                    item.get("return_qty").value
                                }}
                              </td>
                              <!--                              <td>{{item.get('return_qty').value}}</td>-->
                              <td *ngIf="isShowReturnCol">
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  min="1"
                                  (input)="
                                    checkReturnQty($event.target.value, i)
                                  "
                                  formControlName="returned_qty"
                                />
                              </td>
                              <td>
                                {{
                                  item.get("issue_quantity").value -
                                    item.get("returned_qty").value -
                                    item.get("return_qty").value
                                }}
                              </td>
                              <td>{{ item.get("sale_price").value }}</td>
                              <td>
                                {{
                                  (item.get("issue_quantity").value -
                                    item.get("returned_qty").value -
                                    item.get("return_qty").value) *
                                    item.get("sale_price").value
                                    | number: "1.0-2"
                                }}
                              </td>
                            </ng-container>
                          </tr>
                          <tr>
                            <td colspan="7"></td>
                            <td *ngIf="isShowReturnCol"></td>
                            <td><strong>Total:</strong></td>
                            <td>
                              <strong>{{
                                totalAmount | number: "1.0-2"
                              }}</strong>
                            </td>
                          </tr>

                          <!--                        <tr *ngFor="let data of patientIssuedItems;let i = index">-->
                          <!--                          <td>{{data?.created_on | date: 'dd-MM-yyyy'}}</td>-->
                          <!--                          <td>{{data?.reference_no}}</td>-->
                          <!--                          <td>{{data?.product?.code}}</td>-->
                          <!--                          <td>{{data?.product?.name}}</td>-->
                          <!--                          <td>{{data?.product?.uom}}</td>-->
                          <!--                          <td>{{data?.issue_quantity}}</td>-->
                          <!--                          <td>{{data?.return_qty}}</td>-->
                          <!--                          <td><input type="number" value="0" class="form-control form-control-sm" min="1"></td>-->
                          <!--                          <td>{{data?.return_qty - $()}}</td>-->
                          <!--                          <td>{{data}}</td>-->
                          <!--                          <td>{{data}}</td>-->
                          <!--                        </tr>-->
                        </tbody>
                      </table>
                    </div>
                    <div class="row m-0 text-right mt-3">
                      <div class="col-12">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm mr-2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Print
                        </button>
                        <div class="dropdown-menu">
                          <a
                            class="dropdown-item cursor-pointer"
                            (click)="printDetail()"
                            >Print Detail</a
                          >
                          <a
                            class="dropdown-item cursor-pointer"
                            (click)="printSummary()"
                            >Print Summary</a
                          >
                        </div>
                        <button
                          type="submit"
                          *ngIf="isShowReturnCol"
                          class="btn btn-sm btn-primary"
                        >
                          Return
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<!--begin::Patient Panel-->
<div id="patient-list" class="offcanvas offcanvas-right p-5">
  <!--begin::Header-->
  <div
    class="offcanvas-header d-flex align-items-center justify-content-between pb-7"
  >
    <h4 class="font-weight-bold m-0">Patient List</h4>
    <button
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      (click)="closePatientList()"
    >
      <i class="ki ki-close icon-xs text-muted"></i>
    </button>
  </div>
  <!--end::Header-->
  <!--begin::Content-->
  <div class="offcanvas-content">
    <!--begin::Wrapper-->
    <div class="offcanvas-wrapper mb-5 scroll-pull"></div>
    <!--end::Wrapper-->
    <!--begin::Purchase-->
    <div class="offcanvas-footer">
      <form [formGroup]="PatientSearchForm" (ngSubmit)="fetchPatients()">
        <div class="row m-0">
          <div class="col-12 p-0 mb-2">
            <select
              class="form-control form-control-sm"
              formControlName="type"
              [(ngModel)]="PatientSearchForm.value.type"
            >
              <option value="default">Default</option>
              <option value="mrn">MRN</option>
              <option value="phone1">phone1</option>
              <option value="patient_nic">CNIC</option>
              <option value="name">Name</option>
            </select>
          </div>
          <div class="col-12 p-0 button-wrapper">
            <input
              type="text"
              formControlName="search_query"
              maxlength="12"
              minlength="12"
              placeholder="Search patient here"
              class="form-control form-control-sm"
            />
            <button type="submit" class="btn btn-primary btn-sm" value="Search">
              <span>Search</span>
            </button>
          </div>
        </div>
      </form>
      <br />
      <div class="patient-listing mb-5">
        <ng-container *ngFor="let pa of patientAdmissions">
          <div
            class="d-flex pl-2 py-3 mr-2 patient-panel"
            (click)="selectPatient(pa)"
          >
            <div class="symbol symbol-40 symbol-light-success mr-3">
              <span class="symbol-label">
                <img
                  *ngIf="pa?.gender === 'transgender'"
                  src="assets/media/users/blank.png"
                  class="h-100 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="pa?.gender === 'unidentified'"
                  src="assets/media/users/blank.png"
                  class="h-100 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="pa?.gender === 'male'"
                  src="assets/media/svg/avatars/009-boy-4.svg"
                  class="h-75 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="pa?.gender === 'female'"
                  src="assets/media/svg/avatars/002-girl.svg"
                  class="h-75 align-self-end"
                  alt=""
                />
              </span>
            </div>
            <div class="d-flex flex-column flex-grow-1 font-weight-bold">
              <!--              text-hover-primary-->
              <a class="text-dark mb-1 font-size-lg">{{
                pa?.first_name + " " + pa?.last_name
              }}</a>
              <div class="d-inline">
                <span class="text-muted mr-4">{{ pa?.mrn }}</span>
                <!--                        / {{patient?.gender | titlecase}}-->
                <!--                      <span class="text-muted mr-4">{{pa?.age}}</span>-->
              </div>
              <span class="text-muted mr-2">{{
                pa?.admissions[pa.admissions.length - 1]?.ward | titlecase
              }}</span>
              <!--            <span class="text-muted mr-4">{{ pa?.admissions[pa.admissions.length - 1]?.created_at | date: 'dd-MM-yyyy h:mm a'}}</span>-->
            </div>
          </div>
        </ng-container>
      </div>
      <br />
    </div>
    <!--end::Purchase-->
  </div>
  <!--end::Content-->
</div>
<!--end::Patient Panel-->
