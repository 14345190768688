import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.css'],
})
export class PatientDetailsComponent implements OnInit {
  @Output('changeBed') changeBed: EventEmitter<any> = new EventEmitter();
  @Input() patient: any;
  @Input() type: any;
  @Input() vitalWeight: any;

  constructor() {}

  ngOnInit(): void {}
  changeBedIpd(): any {
    this.changeBed.emit();
  }
}
