<div *ngIf="showMainHeading">
  <h3 class="text-dark font-weight-bolder ml-4 mb-5">Family Information</h3>

  <div class="d-flex">
    <div class="col-10">
      <div class="col-12 d-flex pl-0 patient-info-header-main">
        <div class="col-6 pl-0">
          <table style="width: 100%">
            <tbody>
              <ng-container>
                <tr *ngIf="familyData?.guardian_name">
                  <td class="pdf-report-Patient-heading">
                    <span>Guardian Name:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.guardian_name }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.relation">
                  <td class="pdf-report-Patient-heading">
                    <span>Relation:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.relation }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.contact">
                  <td class="pdf-report-Patient-heading">
                    <span>Contact:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span
                      >+{{ familyData?.contact_dial_code }}
                      {{ familyData?.contact }}</span
                    >
                  </td>
                </tr>
                <tr *ngIf="familyData?.alternate_contact">
                  <td class="pdf-report-Patient-heading">
                    <span>Alt. Contact:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span
                      >+{{ familyData?.alternate_contact_dial_code }}
                      {{ familyData?.alternate_contact }}</span
                    >
                  </td>
                </tr>
                <tr *ngIf="familyData?.cnic">
                  <td class="pdf-report-Patient-heading">
                    <span>CNIC:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.cnic }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.passport">
                  <td class="pdf-report-Patient-heading">
                    <span>Passport:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.passport }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.occupation">
                  <td class="pdf-report-Patient-heading">
                    <span>Occupation:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.occupation }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.qualification">
                  <td class="pdf-report-Patient-heading">
                    <span>Qualification:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.qualification }}</span>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="col-6 pl-0">
          <table style="width: 100%">
            <tbody>
              <ng-container>
                <tr *ngIf="familyData?.mother_name">
                  <td class="pdf-report-Patient-heading">
                    <span>Mother Name:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.mother_name }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.mother_profession">
                  <td class="pdf-report-Patient-heading">
                    <span>Mother Profession:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.mother_profession }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.sibling">
                  <td class="pdf-report-Patient-heading">
                    <span>Siblings:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.sibling }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.country_name">
                  <td class="pdf-report-Patient-heading">
                    <span>Country:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.country_name }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.city">
                  <td class="pdf-report-Patient-heading">
                    <span>City:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.city }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.address">
                  <td class="pdf-report-Patient-heading">
                    <span>Address:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.address }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.accommodation">
                  <td class="pdf-report-Patient-heading">
                    <span>Accomm:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span>{{ familyData?.accommodation }}</span>
                  </td>
                </tr>
                <tr *ngIf="familyData?.family_income">
                  <td class="pdf-report-Patient-heading">
                    <span>Family Income:</span>
                  </td>
                  <td class="pdf-report-patient-heading-content">
                    <span
                      >{{ familyData?.currency_name
                      }}{{ familyData?.family_income }}</span
                    >
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-2">
      <div>
        <td style="width: 15%; vertical-align: top">
          <div class="text-right mb-2">
            <img
              class="patientPic"
              *ngIf="familyData?.profile_image"
              [src]="familyData?.profile_image"
              alt=""
            />
          </div>
          <div class="text-right mb-2">
            <img
              class="patientThumbPic"
              *ngIf="familyData?.guardian_thumb"
              [src]="familyData?.guardian_thumb"
              alt=""
            />
          </div>
        </td>
      </div>
    </div>
  </div>

  <hr class="my-6 line" />
</div>
