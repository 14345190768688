import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SystemControlsService } from '../system-controls.service';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionDataResolver implements Resolve<any> {
  constructor(private systemControlsService: SystemControlsService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const type = route.data['type'];
    return this.systemControlsService.getPrescriptionControlV2(type);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PrescriptionSlipDataResolver implements Resolve<any> {
  constructor(private systemControlsService: SystemControlsService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const type = route.queryParams['visit_id'];
    return this.systemControlsService.getPrescriptionControlByPdfV2(type);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SystemGeneralControls implements Resolve<any> {
  constructor(private userService: UserService) {}
  resolve(): Observable<any> {
    return this.userService.getGeneralControls();
  }
}
