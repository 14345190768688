<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            History Dashboard
          </h5>
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button
          class="btn btn-light-primary btn-sm"
          (click)="toBack()"
          type="button"
        >
          Back
        </button>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="card card-custom mb-4" *ngIf="patient">
        <div class="card-body custom-card-body">
          <!--begin::Top-->
          <div class="d-flex">
            <!--begin::Pic-->
            <div class="flex-shrink-0 mr-4">
              <!--              symbol-lg-100-->
              <div class="symbol symbol-75">
                <img
                  *ngIf="patient?.gender === 'transgender'"
                  src="assets/media/users/blank.png"
                  class="h-50 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="patient?.gender === 'unidentified'"
                  src="assets/media/users/blank.png"
                  class="h-50 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="patient?.gender === 'male'"
                  src="assets/media/svg/avatars/009-boy-4.svg"
                  class="h-50 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="patient?.gender === 'female'"
                  src="assets/media/svg/avatars/002-girl.svg"
                  class="h-50 align-self-end"
                  alt=""
                />
              </div>
            </div>
            <!--end::Pic-->
            <!--begin: Info-->
            <div class="flex-grow-1">
              <!--begin::Title-->
              <div
                class="d-flex align-items-center justify-content-between flex-wrap"
              >
                <!--begin::User-->
                <div class="mr-3">
                  <!--begin::Name-->
                  <a
                    class="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3"
                    >{{
                      patient?.title +
                        " " +
                        patient?.first_name +
                        " " +
                        patient?.last_name | titlecase
                    }}</a
                  >
                  <!--end::Name-->
                  <!--begin::Contacts-->
                  <div class="d-flex flex-wrap my-2">
                    <a
                      class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                      >{{ patient?.mrn }}</a
                    >
                    <a
                      class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                      >{{ patient?.age }}</a
                    >
                    <a class="text-muted text-hover-primary font-weight-bold">{{
                      patient?.gender | titlecase
                    }}</a>
                  </div>
                  <div class="d-flex flex-wrap my-2">
                    <a
                      class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                      >{{ patient?.patient_type?.name | titlecase }}</a
                    >
                    <a
                      class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    >
                      <span *ngIf="patient?.admissions.length > 0"
                        >{{
                          " " +
                            patient.admissions[patient.admissions.length - 1]
                              .ward.title
                        }}
                        <span *ngIf="patient?.bed_name"
                          >({{ patient?.bed_name | titlecase }})</span
                        >
                      </span>
                    </a>
                  </div>
                  <!--end::Contacts-->
                </div>
                <!--begin::User-->
                <!--begin::Actions-->
                <div class="my-lg-0 my-1">
                  <!--                      <a  class="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2">Ask</a>-->
                  <!--                      <a  class="btn btn-sm btn-primary font-weight-bolder text-uppercase">Hire</a>-->
                </div>
                <!--end::Actions-->
              </div>
              <!--end::Title-->
            </div>
            <!--end::Info-->
          </div>
          <!--end::Top-->
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body main-card-body">
          <form [formGroup]="patientHistorySourceForm">
            <div class="row">
              <div class="col-md-3 col-sm-6 col-12">
                <label>Taken from</label>
                <select
                  class="form-control form-control-sm"
                  formControlName="taken_from"
                  [ngModel]="patientHistorySource?.taken_from"
                  (change)="saveHistoryTakenFrom()"
                  [ngClass]="{
                    'is-invalid':
                      (submitted || submittedPC) && k.taken_from.errors
                  }"
                >
                  <option value="">Select</option>
                  Allergies
                  <option value="Patient">Patient</option>
                  <option value="Guardian">Guardian</option>
                  <!--                  <option value="Relation">Relation</option>-->
                  <option value="Both">Both</option>
                </select>
                <div
                  *ngIf="(submitted || submittedPC) && k.taken_from.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="k.taken_from.errors.required">
                    History taken from is required
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 col-12">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="name"
                  [ngModel]="patientHistorySource?.name"
                  (blur)="saveHistoryTakenFrom()"
                  [ngClass]="{
                    'is-invalid': (submitted || submittedPC) && k.name.errors
                  }"
                />
                <div
                  *ngIf="(submitted || submittedPC) && k.name.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="k.name.errors.required">Name is required</div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12 align-self-center">
                <span class="switch switch-sm switch-icon justify-content-end">
                  <label>
                    <input type="checkbox" checked (click)="showsAll()" />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </form>

          <div class="row text-right mt-2">
            <div class="col-12">
              <button
                class="btn btn-light-primary btn-sm"
                (click)="printAdmissionHistory()"
              >
                Print
              </button>
              <button
                class="btn btn-light-primary btn-sm ml-2"
                (click)="onSubmitAll()"
              >
                Save All
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-2">
        <div class="card-body card-body-padding">
          <div class="">
            <h4 class="m-0">Diagnosis</h4>
            <!-- table-responsive -->
            <table class="table diagnosisMain">
              <thead>
                <tr>
                  <th style="width: 60%" class="pl-0">Name</th>
                  <th style="width: 35%" class="pl-0">Type</th>
                  <th style="width: 50px" class="pl-0 pt-0 text-right">
                    <button
                      type="button"
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                      (click)="addMoreDianosisMain()"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                        <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                            <path
                              d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                              fill="#000000"
                            />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let item of mainDiagnosisAdd; let i = index"
                >
                  <tr>
                    <ng-container id="diagnosisSearching">
                      <td
                        style="width: 60%"
                        class="pl-0"
                        id="specifiedDiagnosis"
                      >
                        <div class="search_box">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="diagnosisNameMain{{ i }}"
                            name="name"
                            [(ngModel)]="item.name"
                            placeholder="Search Diagnosis Here"
                            (keyup)="getAllDiagnosisListMain(i)"
                            (click)="getAllDiagnosisListMain(i)"
                          />
                          <div class="diagnosis-search-bar" *ngIf="item.name">
                            <p
                              *ngIf="
                                allDiagnosisDataMain.length === 0 &&
                                diagnosisSearchResultMain === false
                              "
                            >
                              Searching...
                            </p>
                            <p
                              *ngIf="
                                allDiagnosisDataMain.length === 0 &&
                                diagnosisSearchResultMain === true
                              "
                            >
                              No Result Found
                            </p>
                            <span *ngIf="allDiagnosisDataMain.length > 0">
                              <p
                                *ngFor="let diagnosis of allDiagnosisDataMain"
                                (click)="setAllDiagnosisMain(diagnosis, i)"
                              >
                                {{ diagnosis.name }}
                              </p>
                            </span>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <td style="width: 35%" class="pl-0">
                      <select
                        class="form-control form-control-sm"
                        id="diagnosisTypeMain{{ i }}"
                        name="type"
                        [(ngModel)]="item.type"
                      >
                        <option value="">Type</option>
                        <option value="ProvisionalDiagnosis">
                          Provisional Diagnosis
                        </option>
                        <option value="DifferentialDiagnosis">
                          Differential Diagnosis
                        </option>
                        <option value="FinalDiagnosis">Final Diagnosis</option>
                      </select>
                    </td>
                    <td style="width: 50px" class="pl-0 text-right">
                      <button
                        type="button"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                        (click)="removeDianosisMain(i)"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x">
                          <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <path
                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              />
                              <path
                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g>
                          </svg>
                          <!--end::Svg Icon-->
                        </span>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="row">
              <div class="col-8">
                <h5 class="">Current Diagnosis</h5>
              </div>
              <div
                class="col-4 text-right"
                *ngIf="this.mainDiagnosisAdd.length > 0"
              >
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  (click)="submitMainDiagnosis()"
                >
                  Submit
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-12">
                <!--                            (click)="setAndAddDiagnosisMain(data)"-->
                <button
                  type="button"
                  class="btn btn-light-primary btn-sm m-2"
                  *ngFor="let data of paitestDiagnosisHistory; let i = index"
                >
                  {{ data?.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body main-card-body">
          <div
            class="accordion accordion-solid accordion-toggle-plus mb-3"
            id="historyGeneral"
          >
            <div class="card">
              <div class="card-header">
                <div
                  class="card-title"
                  [attr.expanded]="isGHShown"
                  (click)="toggleGHIcon()"
                  [ngClass]="isGHShown ? '' : 'collapsed'"
                  id="generalClick"
                  data-toggle="collapse"
                  data-target="#generalHistory"
                >
                  GENERAL HISTORY
                </div>
              </div>
              <div
                id="generalHistory"
                class="collapse div-collapse"
                data-parent="#historyGeneral"
              >
                <div class="card-body">
                  <form
                    [formGroup]="patientGeneralHistoryForm"
                    (ngSubmit)="saveGeneralHistory()"
                  >
                    <div class="mt-3" formGroupName="patient_complaints">
                      <div class="row m-auto">
                        <div class="col-md-6 col-sm-12 col-12">
                          <div class="form-group">
                            <label>Presenting Complains</label>
                            <textarea
                              style="height: 60px !important"
                              formControlName="presenting_complaints"
                              [ngModel]="
                                patientComplaint?.presenting_complaints
                              "
                              class="form-control"
                              placeholder="Presenting complains"
                              rows="4"
                              [ngClass]="{
                                'is-invalid':
                                  submitted &&
                                  g.patient_complaints.controls
                                    .presenting_complaints.errors
                              }"
                            ></textarea>
                            <div
                              *ngIf="
                                submittedPC &&
                                g.patient_complaints.controls
                                  .presenting_complaints.errors
                              "
                              class="invalid-feedback"
                            >
                              <div
                                *ngIf="
                                  g.patient_complaints.controls
                                    .presenting_complaints.errors.required
                                "
                              >
                                Presenting Complaint is required
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-12 col-12">
                          <div class="form-group tagsInput tagsHeight">
                            <label class="d-block">Allergies</label>
                            <input
                              class="form-control allergy-field"
                              id="tags"
                              placeholder="Search allergies"
                              name="paitentAllergiesSearch"
                              autocomplete="off"
                            />
                            <div
                              class="search-result-main"
                              *ngIf="paitentAllergiesSearch.length > 2"
                            >
                              <p
                                *ngIf="
                                  allergiesListData.length === 0 &&
                                  allergiesSearchResult === false
                                "
                              >
                                Searching...
                              </p>
                              <p
                                *ngIf="
                                  allergiesListData.length === 0 &&
                                  allergiesSearchResult === true
                                "
                              >
                                No Result Found
                              </p>
                              <span *ngIf="allergiesListData.length > 0">
                                <p
                                  *ngFor="let allergies of allergiesListData"
                                  (click)="setAllergiesNmae(allergies.name)"
                                >
                                  {{ allergies.name }}
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Married For</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="married_for"
                              placeholder="Married For"
                              [ngModel]="patientComplaint?.married_for"
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>LMP</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="lmp"
                              placeholder="LMP"
                              [ngModel]="patientComplaint?.lmp"
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>EDD</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="edd"
                              placeholder="EDD"
                              [ngModel]="patientComplaint?.edd"
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Gestational Age</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Gestational Age"
                              formControlName="gestational_age"
                              [ngModel]="patientComplaint?.gestational_age"
                            />
                          </div>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Other</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Other"
                              formControlName="other"
                              [ngModel]="patientComplaint?.other"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="mt-3"
                      formGroupName="patient_gynaecological_histories_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Gynacological History</h4>
                        </div>
                      </div>
                      <div class="row m-0">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Age of Menarche</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Age of Menarche"
                              formControlName="age_of_minarche"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_gynaecological_histories_attributes
                                  ?.age_of_minarche
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Menstrual Cycle</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Menstrual Cycle"
                              formControlName="menstrual_cycle"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_gynaecological_histories_attributes
                                  ?.menstrual_cycle
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Length</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              placeholder="Length"
                              formControlName="length"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_gynaecological_histories_attributes
                                  ?.length
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>IMB</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="IMB"
                              formControlName="imb"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_gynaecological_histories_attributes
                                  ?.imb
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Contraception</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Contraception"
                              formControlName="contraception"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_gynaecological_histories_attributes
                                  ?.contraception
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Pap Smear</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Pap Smear"
                              formControlName="pop_smear"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_gynaecological_histories_attributes
                                  ?.pop_smear
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Dysmenorrhoea</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Dysmenorrhoea"
                              formControlName="dysmenorrhea"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_gynaecological_histories_attributes
                                  ?.dysmenorrhea
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Dyspyerunea</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Dyspyerunea"
                              formControlName="dyspareunia"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_gynaecological_histories_attributes
                                  ?.dyspareunia
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Other</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Other"
                              formControlName="other"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_gynaecological_histories_attributes
                                  ?.other
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="mt-3"
                      formGroupName="patient_obstetrical_histories_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Obstractical History</h4>
                        </div>
                      </div>
                      <div class="row m-0">
                        <div class="col-xl-1 col-lg-1 col-md-1 col-sm-4 col-6">
                          <div class="form-group">
                            <label>G</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="G"
                              formControlName="g"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_obstetrical_histories_attributes?.g
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-1 col-lg-1 col-md-1 col-sm-4 col-6">
                          <div class="form-group">
                            <label>P</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="P"
                              formControlName="p"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_obstetrical_histories_attributes?.p
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-1 col-lg-1 col-md-1 col-sm-4 col-6">
                          <div class="form-group">
                            <label>A</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="A"
                              formControlName="a"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_obstetrical_histories_attributes?.a
                              "
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label>Last Child Born</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Last Child Born"
                              formControlName="last_child_born"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_obstetrical_histories_attributes
                                  ?.last_child_born
                              "
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label>Mode of Delivery</label>
                            <select
                              class="form-control form-control-sm"
                              formControlName="mode_of_delivery"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_obstetrical_histories_attributes
                                  ?.mode_of_delivery
                              "
                            >
                              <option
                                [ngValue]="''"
                                [selected]="true"
                                [disabled]="true"
                              >
                                Select
                              </option>
                              <option [ngValue]="'svd'">SVD</option>
                              <option [ngValue]="'c_s'">C/S</option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label>Other</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Other"
                              formControlName="other"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_obstetrical_histories_attributes
                                  ?.other
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_past_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Past History</h4>
                        </div>
                      </div>

                      <div class="row m-0">
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                #DiaChkEnable
                                formControlName="diabetes"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_past_history_attributes?.diabetes
                                "
                              />
                              <span class="mr-2"></span>Diabeties
                            </label>
                          </div>
                        </div>
                        <div class="col-md-8 col-sm-12 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Details"
                              formControlName="diabetes_details"
                              [disabled]="!DiaChkEnable.checked"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_past_history_attributes
                                  ?.diabetes_details
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Duration"
                              formControlName="diabetes_duration"
                              [disabled]="!DiaChkEnable.checked"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_past_history_attributes
                                  ?.diabetes_duration
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0">
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                style="margin-right: 5px"
                                #IHDChkEnable
                                formControlName="IHD"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_past_history_attributes?.IHD
                                "
                              />
                              <span class="mr-2"></span>IHD
                            </label>
                          </div>
                        </div>
                        <div class="col-md-8 col-sm-12 col-15">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Details"
                              formControlName="IHD_details"
                              [disabled]="!IHDChkEnable.checked"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_past_history_attributes?.IHD_details
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Duration"
                              formControlName="IHD_duration"
                              [disabled]="!IHDChkEnable.checked"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_past_history_attributes
                                  ?.IHD_duration
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0">
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                #IHDChkEnable
                                formControlName="hypertention"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_past_history_attributes
                                    ?.hypertention
                                "
                              />
                              <span class="mr-2"></span>Hypertention
                            </label>
                          </div>
                        </div>
                        <div class="col-md-8 col-sm-12 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Details"
                              formControlName="hypertention_details"
                              [disabled]="!IHDChkEnable.checked"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_past_history_attributes
                                  ?.hypertention_details
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Duration"
                              formControlName="hypertention_duration"
                              [disabled]="!IHDChkEnable.checked"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_past_history_attributes
                                  ?.hypertention_duration
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0">
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                style="margin-right: 5px"
                                #OpeChkEnable
                                formControlName="operation"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_past_history_attributes?.operation
                                "
                              />
                              <span class="mr-2"></span>Operation
                            </label>
                          </div>
                        </div>
                        <div class="col-md-10 col-sm-12 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Details"
                              formControlName="operation_details"
                              [disabled]="!OpeChkEnable.checked"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_past_history_attributes
                                  ?.operation_details
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0">
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                #MIChkEnable
                                formControlName="major_illness"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_past_history_attributes
                                    ?.major_illness
                                "
                              />
                              <span class="mr-2"></span>Major Illness
                            </label>
                          </div>
                        </div>
                        <div class="col-md-10 col-sm-12 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Details"
                              formControlName="major_illness_details"
                              [disabled]="!MIChkEnable.checked"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_past_history_attributes
                                  ?.major_illness_details
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0">
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                #otherChkEnable
                                formControlName="other"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_past_history_attributes?.other
                                "
                              />
                              <span class="mr-2"></span>Other
                            </label>
                          </div>
                        </div>
                        <div class="col-md-10 col-sm-12 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Details"
                              formControlName="other_details"
                              [disabled]="!otherChkEnable.checked"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_past_history_attributes
                                  ?.other_details
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_social_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Social History</h4>
                        </div>
                      </div>

                      <div class="row m-0">
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="alcohol"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_social_history_attributes?.alcohol
                                "
                              />
                              <span class="mr-2"></span>Alcohol
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="smoker"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_social_history_attributes?.smoker
                                "
                              />
                              <span class="mr-2"></span>Smoker
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="other"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_social_history_attributes?.other
                                "
                              />
                              <span class="mr-2"></span>Other
                            </label>
                          </div>
                        </div>
                        <div class="col-md-5 col-sm-12 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Details"
                              formControlName="other_details"
                              [disabled]="!otherChkEnable.checked"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_social_history_attributes
                                  ?.other_details
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_family_histories_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Family History</h4>
                        </div>
                      </div>

                      <div class="row m-0">
                        <div class="col-md-1 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="DM"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_family_history_attributes?.DM
                                "
                              />
                              <span class="mr-2"></span>DM
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="hypertention"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_family_history_attributes
                                    ?.hypertention
                                "
                              />
                              <span class="mr-2"></span>Hypertention
                            </label>
                          </div>
                        </div>
                        <div class="col-md-1 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="arthritis"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_family_history_attributes
                                    ?.arthritis
                                "
                              />
                              <span class="mr-2"></span>Arthritis
                            </label>
                          </div>
                        </div>
                        <div class="col-md-1 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="IHD"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_family_history_attributes?.IHD
                                "
                              />
                              <span class="mr-2"></span>IHD
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="malignancy"
                                autocomplete="off"
                                [ngModel]="
                                  patientGeneralHistory
                                    ?.patient_family_history_attributes
                                    ?.malignancy
                                "
                              />
                              <span class="mr-2"></span>Malignancy
                            </label>
                          </div>
                        </div>
                        <!--                        <div class="col-md-2 col-sm-6 col-12">-->
                        <!--                          <div class="form-group">-->
                        <!--                            <label>-->
                        <!--                              <span class=""></span>Other-->
                        <!--                              <input type="text" formControlName="other" class="form-control form-control-sm" [ngModel]="patientGeneralHistory?.patient_family_history_attributes?.other">-->
                        <!--                            </label>-->
                        <!--                          </div>-->
                        <!--                        </div>-->
                        <div class="col-md-5 col-sm-6 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Other"
                              formControlName="other"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_family_history_attributes?.other
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_birth_histories_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Birth History</h4>
                        </div>
                      </div>

                      <div class="row m-0">
                        <div class="col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Details"
                              formControlName="details"
                              [ngModel]="
                                patientGeneralHistory
                                  ?.patient_birth_histories_attributes?.details
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 mt-3 text-right">
                      <div class="col-12">
                        <button type="submit" class="btn btn-primary btn-sm">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            class="accordion accordion-solid accordion-toggle-plus mb-3"
            id="historyReview"
          >
            <div class="card">
              <div class="card-header">
                <div
                  class="card-title"
                  [attr.expanded]="isSysTabsShown"
                  (click)="toggleSysIcon()"
                  [ngClass]="isSysTabsShown ? '' : 'collapsed'"
                  id="reveiwClick"
                  data-toggle="collapse"
                  data-target="#reviewSystem"
                >
                  REVEIW OF SYSTEM
                </div>
              </div>
              <div
                id="reviewSystem"
                class="collapse div-collapse"
                data-parent="#historyReview"
              >
                <div class="card-body">
                  <form
                    [formGroup]="reviewSystemHistoryForm"
                    (ngSubmit)="saveReviewSystemHistory()"
                  >
                    <div
                      class="mt-3"
                      formGroupName="patient_general_health_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>General Health</h4>
                        </div>
                      </div>
                      <div class="row m-0 mt-2">
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="weight_loss"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_general_health_history_attributes
                                    ?.weight_loss
                                "
                              />
                              <span class="mr-2"></span>Weight loss
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="fatigue_tiredness"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_general_health_history_attributes
                                    ?.fatigue_tiredness
                                "
                              />
                              <span class="mr-2"></span>Fatigue tiredness
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="fever_chills"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_general_health_history_attributes
                                    ?.fever_chills
                                "
                              />
                              <span class="mr-2"></span>Fever Chills
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="night_sweats"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_general_health_history_attributes
                                    ?.night_sweats
                                "
                              />
                              <span class="mr-2"></span>Night Sweats
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="other"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_general_health_history_attributes
                                    ?.other
                                "
                                #GHChkEnable
                              />
                              <span class="mr-2"></span>Other
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="other_details"
                              placeholder="Other Details"
                              [disabled]="!GHChkEnable.checked"
                              [ngModel]="
                                reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.other_details
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_ent_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>ENT</h4>
                        </div>
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="glaucoma"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_ent_history_attributes?.glaucoma
                                "
                              />
                              <span class="mr-2"></span>Glaucoma
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="visual_difdiculty"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_ent_history_attributes
                                    ?.visual_difdiculty
                                "
                              />
                              <span class="mr-2"></span>Visual difdiculty
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="hearing_problem"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_ent_history_attributes
                                    ?.hearing_problem
                                "
                              />
                              <span class="mr-2"></span>Hearing problem
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="recurrent_sore_throat"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_ent_history_attributes
                                    ?.recurrent_sore_throat
                                "
                              />
                              <span class="mr-2"></span>Recurrent Sore throat
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="swollen_glands"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_ent_history_attributes
                                    ?.swollen_glands
                                "
                              />
                              <span class="mr-2"></span>Swollen Glands
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_cardiology_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Cardiology</h4>
                        </div>
                      </div>
                      <div class="row m-0 mt-2">
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="heart_problem"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_cardiology_history_attributes
                                    ?.heart_problem
                                "
                              />
                              <span class="mr-2"></span>Heart problem
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="high_blood_pressure"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_cardiology_history_attributes
                                    ?.high_blood_pressure
                                "
                              />
                              <span class="mr-2"></span>High Blood Pressure
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="stroke"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_cardiology_history_attributes
                                    ?.stroke
                                "
                              />
                              <span class="mr-2"></span>Stroke
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="PCI"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_cardiology_history_attributes?.PCI
                                "
                              />
                              <span class="mr-2"></span>PCI/CABG
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="other"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_cardiology_history_attributes
                                    ?.other
                                "
                                #CardChkEnable
                              />
                              <span class="mr-2"></span>Other
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="other_details"
                              [disabled]="!CardChkEnable.checked"
                              [ngModel]="
                                reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.other_details
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_respiratory_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Respiratory</h4>
                        </div>
                      </div>
                      <div class="row m-0 mt-2">
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="shirtness_of_breath"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_respiratory_history_attributes
                                    ?.shirtness_of_breath
                                "
                              />
                              <span class="mr-2"></span>Shortness of breath
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="asthma"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_respiratory_history_attributes
                                    ?.asthma
                                "
                              />
                              <span class="mr-2"></span>Asthma
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="chronic_cought"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_respiratory_history_attributes
                                    ?.chronic_cought
                                "
                              />
                              <span class="mr-2"></span>Chronic Cough
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="lung_disease"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_respiratory_history_attributes
                                    ?.lung_disease
                                "
                              />
                              <span class="mr-2"></span>Lung Disease
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="heamoptysis"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_respiratory_history_attributes
                                    ?.heamoptysis
                                "
                              />
                              <span class="mr-2"></span>Hemoptysis
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_neuro_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Neuro</h4>
                        </div>
                      </div>
                      <div class="row m-0 mt-2">
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="seizures"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_neuro_history_attributes?.seizures
                                "
                              />
                              <span class="mr-2"></span>Seizures
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="headache"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_neuro_history_attributes?.headache
                                "
                              />
                              <span class="mr-2"></span>Headache
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="vertigo"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_neuro_history_attributes?.vertigo
                                "
                              />
                              <span class="mr-2"></span>Vertigo/Black outs
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_skin_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Skin</h4>
                        </div>
                      </div>
                      <div class="row m-0 mt-2">
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="rash"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_skin_history_attributes?.rash
                                "
                              />
                              <span class="mr-2"></span>Rash/ Striae
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="pigmentation"
                                autocomplete="off"
                                [ngModel]="
                                  reviewSystemHistory
                                    ?.patient_skin_history_attributes
                                    ?.pigmentation
                                "
                              />
                              <span class="mr-2"></span>Pigmentation
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 mt-2 text-right">
                      <div class="col-12">
                        <button
                          type="submit"
                          class="btn btn-sm btn-primary"
                          accesskey="s"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            class="accordion accordion-solid accordion-toggle-plus mb-3"
            id="historyMedication"
          >
            <div class="card">
              <div class="card-header">
                <div
                  class="card-title"
                  [attr.expanded]="isMedShown"
                  (click)="toggleMedIcon()"
                  [ngClass]="isMedShown ? '' : 'collapsed'"
                  id="medicationClick"
                  data-toggle="collapse"
                  data-target="#medications"
                >
                  MEDICATIONS
                </div>
              </div>
              <div
                id="medications"
                class="collapse div-collapse"
                data-parent="#historyMedication"
              >
                <div class="card-body">
                  <div class="row m-0 mt-2">
                    <div class="col-12">
                      <h4>Medicines</h4>
                    </div>
                  </div>

                  <div class="row m-auto">
                    <div class="col-12">
                      <table
                        class="table table-vertical-center table-head-custom medicines-table"
                      >
                        <thead>
                          <tr>
                            <th>
                              Name Of medicine
                              <span class="text-danger">*</span>
                            </th>
                            <th>Dose <span class="text-danger">*</span></th>
                            <th>Days <span class="text-danger">*</span></th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th class="pb-2">
                              <button
                                type="button"
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                                (click)="addMoreMedicine(addMedicine.length)"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                      <path
                                        d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                        fill="#000000"
                                      />
                                    </g></svg
                                  ><!--end::Svg Icon-->
                                </span>
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody id="Medicines">
                          <tr
                            *ngFor="let medicine of addMedicine; let i = index"
                          >
                            <td>
                              <div class="medicine-search">
                                <input
                                  class="form-control form-control-sm"
                                  placeholder="Search product name"
                                  id="medicineName{{ i }}"
                                  [(ngModel)]="medicine.name"
                                  type="text"
                                  (keyup)="getProductList(i)"
                                  (click)="getProductList(i)"
                                />
                                <div
                                  class="search-result-main"
                                  *ngIf="medicine.name.length > 2"
                                >
                                  <p
                                    *ngIf="
                                      productListData.length === 0 &&
                                      productSearchResult === false
                                    "
                                  >
                                    Searching...
                                  </p>
                                  <p
                                    *ngIf="
                                      productListData.length === 0 &&
                                      productSearchResult === true
                                    "
                                  >
                                    No Result Found
                                  </p>
                                  <span *ngIf="productListData.length > 0">
                                    <p
                                      *ngFor="let product of productListData"
                                      (click)="setProduct(product, i)"
                                    >
                                      {{ product.product_name }}
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <select
                                class="form-control form-control-sm"
                                [(ngModel)]="medicine.dose"
                              >
                                <option value="bd">BD</option>
                                <option value="od">OD</option>
                                <option value="tds">TDS</option>
                                <option value="sos">SOS</option>
                                <option value="hs">HS</option>
                                <option value="qid">Qid</option>
                                <option value="stat">STAT</option>
                                <option value="alternate_days">
                                  Alternate Days
                                </option>
                                <option value="qhs">QHS</option>
                                <option value="ac">AC</option>
                                <option value="qhs">QHS</option>
                                <option value="fort_nightly">
                                  Fort Nightly
                                </option>
                                <option value="every_2_hours">
                                  Every 2 hours
                                </option>
                                <option value="every_3_hours">
                                  Every 3 hours
                                </option>
                                <option value="every_4_hours">
                                  Every 4 hours
                                </option>
                                <option value="every_6_hours">
                                  Every 6 hours
                                </option>
                                <option value="every_8_hours">
                                  Every 8 hours
                                </option>
                                <option value="every_12_hours">
                                  Every 12 hours
                                </option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="after_meal">After Meal</option>
                                <option value="every_morning">
                                  Every Morning
                                </option>
                                <option value="every_afternoon">
                                  Every Afternoon
                                </option>
                                <option value="prn">PRN</option>
                                <option value="bs">Bs</option>
                                <option value="before_breakfast">
                                  Before Breakfast
                                </option>
                                <option value="before_meal">Before Meal</option>
                                <option value="bed_time">bed_time</option>
                                <option value="npo">Npo</option>
                              </select>
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                placeholder="days"
                                id="medicineNoOfDays{{ i }}"
                                (keyup)="updateMedicineToDate(i)"
                                [(ngModel)]="medicine.no_of_days"
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                id="medicineStart{{ i }}"
                                (change)="updateMedicineToDate(i)"
                                [(ngModel)]="medicine.from_date"
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                id="medicineEnd{{ i }}"
                                [(ngModel)]="medicine.to_date"
                                disabled
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                                (click)="removeMedicine(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                        fill="#000000"
                                        fill-rule="nonzero"
                                      />
                                      <path
                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                    </g></svg
                                  ><!--end::Svg Icon--></span
                                >
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    class="row m-0 mt-2 text-right"
                    *ngIf="addMedicine.length"
                  >
                    <div class="col-12">
                      <button
                        class="btn btn-sm btn-primary"
                        (click)="addMedicineAgainstPatient('submit')"
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div
                    class="row mx-auto mt-4"
                    style="height: 100%; overflow-y: auto"
                  >
                    <div class="col-12">
                      <table
                        class="table table-head-custom table-vertical-center"
                        *ngIf="paitentMedicineHistory.length > 0"
                      >
                        <thead>
                          <tr>
                            <th style="width: 20%">Date</th>
                            <th style="width: 25%">Name</th>
                            <th>Dose</th>
                            <th>Days</th>
                            <th>From Date</th>
                            <th>To Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container
                            *ngFor="
                              let history of paitentMedicineHistory;
                              let i = index
                            "
                          >
                            <tr>
                              <td>
                                {{
                                  history.created_at | date: "dd-MM-yyyy h:mm a"
                                }}
                              </td>
                              <td style="width: 25%">
                                {{ history.item.product_name }}
                              </td>
                              <td>{{ history.dose }}</td>
                              <td>{{ history.no_of_days }}</td>
                              <td>{{ history.from_date }}</td>
                              <td>{{ history.to_date }}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="accordion accordion-solid accordion-toggle-plus mb-3"
            id="historyPhy"
          >
            <div class="card">
              <div class="card-header">
                <div
                  class="card-title"
                  [attr.expanded]="isPETabsShown"
                  (click)="togglePETIcon()"
                  [ngClass]="isPETabsShown ? '' : 'collapsed'"
                  id="phyExamClick"
                  data-toggle="collapse"
                  data-target="#phyExamSystem"
                >
                  PHYSICAL & SYSTEMIC EXAMINATION
                </div>
              </div>
              <div
                id="phyExamSystem"
                class="collapse div-collapse"
                data-parent="#historyPhy"
              >
                <div class="card-body">
                  <form
                    [formGroup]="physicalSystemicExaminationHistoryForm"
                    (ngSubmit)="savePhysicalSystemicExaminationHistory()"
                  >
                    <div
                      class="mt-3"
                      formGroupName="patient_gpe_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>GPE</h4>
                        </div>
                      </div>
                      <div class="row m-0">
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="pallor"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_gpe_history_attributes?.pallor
                                "
                              />
                              <span class="mr-2"></span>Pallor
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="cyanosis"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_gpe_history_attributes?.cyanosis
                                "
                              />
                              <span class="mr-2"></span>Cyanosis
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="rash"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_gpe_history_attributes?.rash
                                "
                              />
                              <span class="mr-2"></span>Rash
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="clubbing"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_gpe_history_attributes?.clubbing
                                "
                              />
                              <span class="mr-2"></span>Clubbing
                            </label>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="pedal"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_gpe_history_attributes?.pedal
                                "
                              />
                              <span class="mr-2"></span>Pedal/Ankle Edema
                            </label>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="lymphadenopathy"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_gpe_history_attributes
                                    ?.lymphadenopathy
                                "
                              />
                              <span class="mr-2"></span>Lymphadenopathy
                            </label>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="peropheral_vessel_palpable"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_gpe_history_attributes
                                    ?.peropheral_vessel_palpable
                                "
                              />
                              <span class="mr-2"></span>Peropheral vessel
                              palpable
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_chest_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Chest</h4>
                        </div>
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class=""><b>Defermity:</b></label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="chest_defermity"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_chest_history_attributes
                                    ?.chest_defermity
                                "
                              />
                              <span class="mr-2"></span>Yes
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="chest_defermity"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_chest_history_attributes
                                    ?.chest_defermity
                                "
                              />
                              <span class="mr-2"></span>No
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class=""><b>Breathing:</b></label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="vesicular"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_chest_history_attributes
                                    ?.vesicular
                                "
                              />
                              <span class="mr-2"></span>Vesicular
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="bronchial"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_chest_history_attributes
                                    ?.bronchial
                                "
                              />
                              <span class="mr-2"></span>Bronchial
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class=""><b>Added Sounds:</b></label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="rhonchi"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_chest_history_attributes?.rhonchi
                                "
                              />
                              <span class="mr-2"></span>Rhonchi
                            </label>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="wheeeze"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_chest_history_attributes?.wheeeze
                                "
                              />
                              <span class="mr-2"></span>Wheeeze
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_heent_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Heent</h4>
                        </div>
                      </div>
                      <div class="row m-0 mt-2">
                        <div class="col-md-3 col-sm-12 col-12">
                          <div class="form-group">
                            <label> Head </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="head"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_heent_history_attributes?.head
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-12 col-12">
                          <div class="form-group">
                            <label> EYE </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="eye"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_heent_history_attributes?.eye
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-12 col-12">
                          <div class="form-group">
                            <label> ENT </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="ent"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_heent_history_attributes?.ent
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row m-0 mt-2">
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Neck:</label>
                            <select
                              class="form-control form-control-sm"
                              formControlName="neck"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_heent_history_attributes?.neck
                              "
                            >
                              <option value="Soft">Soft</option>
                              <option value="Ankylosis">Ankylosis</option>
                              <option value="Fracture">Fracture</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label>N-Viens:</label>
                            <select
                              class="form-control form-control-sm"
                              formControlName="neck_viens"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_heent_history_attributes?.neck_viens
                              "
                            >
                              <option value="Distended">Distended</option>
                              <option value="Not distended">
                                Not distended
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_cardiovascular_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Cardiovascular</h4>
                        </div>
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label> Apex Beat </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="apex_beat"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_cardiovascular_history_attributes
                                  ?.apex_beat
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_breast_examination_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Breast Examination</h4>
                        </div>
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="form-group">
                            <label>Breast Examination</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Breast Examination"
                              formControlName="breast_examination"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_breast_examination_attributes
                                  ?.breast_examination
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mt-3"
                      formGroupName="patient_abdomen_history_attributes"
                    >
                      <div class="row m-auto">
                        <div class="col-12">
                          <h4>Abdomen</h4>
                        </div>
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="soft"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_abdomen_history_attributes?.soft
                                "
                              />
                              <span class="mr-2"></span>Soft
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="distended"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_abdomen_history_attributes
                                    ?.distended
                                "
                              />
                              <span class="mr-2"></span>Distended
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="ascities"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_abdomen_history_attributes
                                    ?.ascities
                                "
                              />
                              <span class="mr-2"></span>Ascities
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="tenderness"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_abdomen_history_attributes
                                    ?.tenderness
                                "
                              />
                              <span class="mr-2"></span>Tenderness
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="bowel_sounds"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_abdomen_history_attributes
                                    ?.bowel_sounds
                                "
                              />
                              <span class="mr-2"></span>Bowel Sounds
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="hepatomegaly"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_abdomen_history_attributes
                                    ?.hepatomegaly
                                "
                              />
                              <span class="mr-2"></span>Hepatomegaly
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="spleenomegaly"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_abdomen_history_attributes
                                    ?.spleenomegaly
                                "
                              />
                              <span class="mr-2"></span>Spleenomegaly
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="inguinal_hernia"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_abdomen_history_attributes
                                    ?.inguinal_hernia
                                "
                              />
                              <span class="mr-2"></span>Inguinal hernia
                            </label>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                formControlName="umbilical"
                                autocomplete="off"
                                [ngModel]="
                                  physicalSystemicExaminationHistory
                                    ?.patient_abdomen_history_attributes
                                    ?.umbilical
                                "
                              />
                              <span class="mr-2"></span>Umbilical/Paraumbilical
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="row m-0 mt-2">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Palpable Contractions</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Palpable Contractions"
                              formControlName="palpable_contractions"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes
                                  ?.palpable_contractions
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>FHS</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              placeholder="FHS"
                              formControlName="fhs"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes?.fhs
                              "
                            />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Scar Marks</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              placeholder="Scar Marks"
                              formControlName="scar_marks"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes
                                  ?.scar_marks
                              "
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12"
                        >
                          <div class="form-group">
                            <label>P/S Examination</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="P/S Examination"
                              formControlName="ps_examination"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes
                                  ?.ps_examination
                              "
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12"
                        >
                          <div class="form-group">
                            <label>PV/BM Examination</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="PV/BM Examination"
                              formControlName="pv_bm_examination"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes
                                  ?.pv_bm_examination
                              "
                            />
                          </div>
                        </div>
                        <!--                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">-->
                        <!--                          <div class="form-group">-->
                        <!--                            <label>Other</label>-->
                        <!--                            <input type="number" class="form-control form-control-sm" placeholder="Other" formControlName="other_detail" [ngModel]="physicalSystemicExaminationHistory?.patient_abdomen_history_attributes?.other_detail">-->
                        <!--                          </div>-->
                        <!--                        </div>-->
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-md-2 col-sm-12 col-12">
                          <div clas="form-group">
                            <label> Grade: </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="grade"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes?.grade
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-12 col-12">
                          <div class="form-group">
                            <label> Area: </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="area"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes?.area
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="form-group">
                            <label> Genital examination: </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="genital_examination"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes
                                  ?.genital_examination
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-12">
                          <div class="form-group">
                            <label>Other Details:</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="other"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes?.other
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="form-group">
                            <label> Pelvic Genitalia: </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="pelvic_genitalia"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes
                                  ?.pelvic_genitalia
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="form-group">
                            <label> Rectal: </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="rectal"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes?.rectal
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="form-group">
                            <label> Extremities: </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="extremities"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes
                                  ?.extremities
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0 mt-2">
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="form-group">
                            <label> Musculoskeletal: </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="musculoskeletal"
                              [ngModel]="
                                physicalSystemicExaminationHistory
                                  ?.patient_abdomen_history_attributes
                                  ?.musculoskeletal
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row m-0 mt-2 text-right">
                        <div class="col-12">
                          <button type="submit" class="btn btn-sm btn-primary">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            class="accordion accordion-solid accordion-toggle-plus"
            id="historyNervous"
          >
            <div class="card">
              <div class="card-header">
                <div
                  class="card-title"
                  [attr.expanded]="isCNSShown"
                  (click)="toggleCNSIcon()"
                  [ngClass]="isCNSShown ? '' : 'collapsed'"
                  id="nervousClick"
                  data-toggle="collapse"
                  data-target="#nervousSystem"
                >
                  NERVOUS SYSTEM
                </div>
              </div>
              <div
                id="nervousSystem"
                class="collapse div-collapse"
                data-parent="#historyNervous"
              >
                <div class="card-body">
                  <form
                    [formGroup]="patientCentralNervousSystemForm"
                    (ngSubmit)="savePatientCentralNervousSystem()"
                  >
                    <div class="row m-0 mt-4">
                      <div class="col-md-2 col-sm-6 col-12">
                        <div class="form-group">
                          <label> GCS: </label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            formControlName="gcs"
                            [ngModel]="patientCentralNervousSystem?.gcs"
                          />
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-12 align-self-center">
                        <div class="form-group">
                          <label class="checkbox checkbox-sm">
                            <input
                              type="checkbox"
                              formControlName="confused"
                              autocomplete="off"
                              [ngModel]="patientCentralNervousSystem?.confused"
                            />
                            <span class="mr-2"></span>Confused
                          </label>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-12 align-self-center">
                        <div class="form-group">
                          <label class="checkbox checkbox-sm">
                            <input
                              type="checkbox"
                              formControlName="oriented"
                              autocomplete="off"
                              [ngModel]="patientCentralNervousSystem?.oriented"
                            />
                            <span class="mr-2"></span>Oriented
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0">
                      <div class="col-md-2 col-sm-3 col-xs-4 col-lg-2">
                        <div class="form-group">
                          <label> Mood</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="mood"
                            [ngModel]="patientCentralNervousSystem?.mood"
                          />
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-3 col-xs-4 col-lg-2">
                        <div class="form-group">
                          <label> Speech:</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="speech"
                            [ngModel]="patientCentralNervousSystem?.speech"
                          />
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-3 col-xs-4 col-lg-2">
                        <div class="form-group">
                          <label> Congnition:</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="congnition"
                            [ngModel]="patientCentralNervousSystem?.congnition"
                          />
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-12">
                        <div class="form-group">
                          <label>Mem. Recent:</label>
                          <!--                        [ngModel]="memory_recent"-->
                          <select
                            class="form-control"
                            formControlName="memory_recent"
                          >
                            <option value="Intact">Intact</option>
                            <option value="Not">Not</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-12">
                        <div class="form-group">
                          <label>Mem. Past:</label>
                          <!--                        [ngModel]="memory_past"-->
                          <select
                            class="form-control"
                            formControlName="memory_past"
                          >
                            <option value="Intact">Intact</option>
                            <option value="Not">Not</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-3 col-xs-4 col-lg-2">
                        <div class="form-group">
                          <label>Pupil:</label>
                          <!--                        [ngModel]="pupil"-->
                          <select
                            class="form-control form-control-sm"
                            formControlName="pupil"
                          >
                            <option value="non reactive">non reactive</option>
                            <option value="reactive">reactive</option>
                            <option value="mid dilated">mid dilated</option>
                            <option value="constricted">constricted</option>
                            <option value="dilated">dilated</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0">
                      <div class="col-md-2 col-sm-6 col-12">
                        <div class="form-group">
                          <label>Sensory system:</label>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-12">
                        <div class="form-group">
                          <label class="checkbox checkbox-sm">
                            <input
                              type="checkbox"
                              formControlName="sensory_system"
                              autocomplete="off"
                              [ngModel]="
                                patientCentralNervousSystem?.sensory_system
                              "
                            />
                            <span class="mr-2"></span>Intact
                          </label>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-12">
                        <div class="form-group">
                          <label> If deficit details: </label>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12 col-12">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="sensory_system_detail"
                            [ngModel]="
                              patientCentralNervousSystem?.sensory_system_detail
                            "
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row m-0">
                      <div class="col-md-2 col-sm-6 col-12">
                        <div class="form-group">
                          <label>Motor system:</label>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-12">
                        <div class="form-group">
                          <label class="checkbox checkbox-sm">
                            <input
                              type="checkbox"
                              formControlName="motor_system"
                              autocomplete="off"
                              [ngModel]="
                                patientCentralNervousSystem?.motor_system
                              "
                            />
                            <span class="mr-2"></span>Intact
                          </label>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-12">
                        <div class="form-group">
                          <label> If deficit details: </label>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12 col-12">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            formControlName="motor_system_detail"
                            [ngModel]="
                              patientCentralNervousSystem?.motor_system_detail
                            "
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row m-0">
                      <div class="col-md-2 col-sm-6 col-12">
                        <div class="form-group">
                          <label class="checkbox checkbox-sm">
                            <input
                              type="checkbox"
                              formControlName="kerning"
                              autocomplete="off"
                              [ngModel]="patientCentralNervousSystem?.kerning"
                            />
                            <span class="mr-2"></span>Kerning
                          </label>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-12">
                        <div class="form-group">
                          <label class="checkbox checkbox-sm">
                            <input
                              type="checkbox"
                              formControlName="neck_stiffness"
                              autocomplete="off"
                              [ngModel]="
                                patientCentralNervousSystem?.neck_stiffness
                              "
                            />
                            <span class="mr-2"></span>Neck Stiffness
                          </label>
                        </div>
                      </div>
                      <div class="col-md-8 col-sm-12 col-12">
                        <input
                          type="text"
                          class="form-control"
                          formControlName="other"
                          placeholder="Any Other"
                          [ngModel]="patientCentralNervousSystem?.other"
                        />
                      </div>
                    </div>

                    <div class="row m-0 mt-3 text-right">
                      <div class="col-12">
                        <button type="submit" class="btn btn-sm btn-primary">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row m-0 mt-4 text-right">
        <div class="col-12 p-0">
          <button
            class="btn btn-sm btn-primary"
            (click)="printAdmissionHistory()"
          >
            Print
          </button>
          <button class="btn btn-sm btn-primary ml-2" (click)="onSubmitAll()">
            Save All
          </button>
        </div>
      </div>

      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
