import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabTestService } from '../../../services/lab-test.service';
import { environment } from '../../../../environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LabconfigurationService } from '../../../services/labconfiguration.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { RouteShareService } from '../../../services/route-share.service';
import { PatientService } from '../../../services/patient.service';
import { CultureConfigService } from '../../../services/culture-config.service';
import { PatientTypeService } from '../../../services/patient-type.service';
import { ShareDataService } from '../../../services/share-data.service';
import { UserService } from '../../../services/user.service';

declare var $: any;
declare var toastr: any;
declare var tinymce: any;

@Component({
  selector: 'app-lab-processing',
  templateUrl: './lab-processing.component.html',
  styleUrls: ['./lab-processing.component.css'],
})
export class LabProcessingComponent implements OnInit {
  @ViewChild('reportsModal') reportsModal: ElementRef;
  @ViewChild('deliveredLabTestModal') deliveredLabTestModal: ElementRef;
  @ViewChild('collectLabTestModal') collectLabTestModal: ElementRef;
  @ViewChild('updateReportedDateModal') updateReportedDateModal: ElementRef;
  @ViewChild('collectSampleTaken') speciemen: ElementRef;
  @ViewChild('rejectLabTestModal') rejectLabTestModal: ElementRef;
  @ViewChild('cancelLabTestModal') cancelLabTestModal: ElementRef;
  @ViewChild('returnCancelLabTestModal') returnCancelLabTestModal: ElementRef;
  ngbModalStatic: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'xxl',
  };
  current_environment: string;
  currentEnvironmentPython: string;
  labServicesForm: UntypedFormGroup;
  reportDelivered: UntypedFormGroup;
  page = 1;
  totalPages: any;
  perPage = 25;
  departments = [];
  subDepartments = [];
  pageDataList = [];
  filterType = 'All';
  patientTypes = [];
  collectedLabObj: any;
  labConfigurations = [];
  rejectedSampleId = [];
  redoCancel = [];
  rejectedSampleReason = '';
  rejectedSampleReasonList = [];
  screenName = '';
  isChecked = false;
  itemIndex: any;
  showHeader = true;
  headerImage = '1';
  footerImage = '1';
  showFooter = true;
  selectedTestArray = [];
  selectedTestCollectArray = [];
  reportDeliveredSubmited = false;
  activePatient = '';
  collectActivePatient = '';
  isDelivered = false;
  selectedArray = [];
  currentObj: any;
  patientLabTestListing = [];
  reportType = [];
  reportTypeUpdate: any;
  cultureReportSubmitted = false;
  cultureList = [];
  organismList = [];
  detectorList = [];
  cultureListSensitivity = [];
  labTestListing: any;
  activeLabEntry: any;
  pathologistRemarks = '';
  pcrRemarks = '';
  disableBtn = false;
  cultureGroupArray = [];
  cultureReportsForm: UntypedFormGroup;
  modalType = '';
  mrn: any;
  hospitalName = environment.hospital;
  settingData: any;
  currentPatientData: any;
  private isSpiceman = false;
  screenControls: any;
  labCollectionAllow = false;
  labReceivableAllow = false;
  labResultEntryAllow = false;
  labReportVerifyAllow = false;
  labReportDeliverAllow = false;
  showRequisitionButton = false;
  showReportUpdateAfterDeliver = false;
  updateReportedTImeAllow = false;
  labOrderId: number;
  patientId: any;
  isModal = false;
  resultSubmitBtn = false;
  detectorCsv: any;
  organismCsv: any;
  pcrData: {};
  detectorNames = [];
  antiBioticData = [];
  antibioticIds: number[];
  pcrListData: any;
  smsControls: any;
  dateFormat: 'DD-MM-YYYY';
  dateTimeFormat: 'DD-MM-YYYY h:mm a';
  reportedId = '';
  reportedDate = '';
  requiredQuantity = '';
  labCosting: any;
  addReportsCostingDetail: any;
  showCostingDetail = false;
  showCostingDetailModal = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private departmentsbyhospitalService: DepartmentsbyhospitalService,
    private ngxLoader: NgxUiLoaderService,
    private labTestService: LabTestService,
    private ref: ChangeDetectorRef,
    private modalService: NgbModal,
    private labconfigurationService: LabconfigurationService,
    private router: Router,
    private routeSevice: RouteShareService,
    private patientService: PatientService,
    private labService: LabconfigurationService,
    private cultureConfigService: CultureConfigService,
    private patientTypeService: PatientTypeService,
    private ngxService: NgxUiLoaderService,
    private userService: UserService,
  ) {
    this.screenName = this.routeSevice.getValue();
    this.current_environment = environment.rorUrl;
    this.currentEnvironmentPython = environment.pythonUrl;
    this.labServicesForm = this.formBuilder.group({
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      search_name: [''],
      department_id: [''],
      patient_type: [''],
      lab_status: [''],
      type: [''],
      search_type: ['mrn'],
    });
    this.reportDelivered = this.formBuilder.group({
      receiver_relation: ['Self', Validators.required],
      receiver_cnic: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(13),
          Validators.maxLength(13),
        ]),
      ],
      receiver_name: ['', Validators.required],
      id: [''],
    });
    this.getPatientTypes();
    this.fetchLabConfigurators();
    this.getCultureList();
    this.getCultureSensitivityList();
  }
  get reports_culture_plate(): any {
    return this.cultureReportsForm.get('culture_plate') as UntypedFormArray;
  }
  get reports_free_field(): any {
    return this.cultureReportsForm.get('free_field') as UntypedFormArray;
  }
  get reports_gram_stain(): any {
    return this.cultureReportsForm.get('gram_stain') as UntypedFormArray;
  }
  get reports_zn_stain(): any {
    return this.cultureReportsForm.get('zn_stain') as UntypedFormArray;
  }
  get reports_fungal_stain(): any {
    return this.cultureReportsForm.get('fungal_stain') as UntypedFormArray;
  }
  get reports_final_report(): any {
    return this.cultureReportsForm.get('final_report') as UntypedFormArray;
  }
  get reports_microscopy(): any {
    return this.cultureReportsForm.get('microscopy') as UntypedFormArray;
  }
  get reports_culture(): any {
    return this.cultureReportsForm.get('culture') as UntypedFormArray;
  }
  get reports_specimen(): any {
    return this.cultureReportsForm.get('culture_specimen') as UntypedFormArray;
  }
  get reports_sensitivity(): any {
    return this.cultureReportsForm.get('sensitivity') as UntypedFormArray;
  }
  get reports_comment(): any {
    return this.cultureReportsForm.get('reports_comment') as UntypedFormArray;
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  openAddDiscountModal(element): any {
    this.isModal = true;
    this.labOrderId = element.id;
    this.patientId = element.patient_mrn;
    console.log('element', element);
    $('#fullScreenModal').modal('show');
  }
  hideDiscountModal(): any {
    this.isModal = false;
    $('#fullScreenModal').modal('hide');
  }
  hideModal(event): any {
    this.isModal = false;
    $('#fullScreenModal').modal('hide');
    this.searchFilter();
  }
  ngOnInit(): void {
    let formatedData = JSON.parse(localStorage.getItem('dateTimeFormats'));
    if (formatedData) {
      this.dateFormat = formatedData.date_format;
    }
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 2);
    $('#fromDate').datetimepicker({
      defaultDate: yesterday,
      format: this.dateFormat || 'DD-MM-YYYY',
      autoClose: true,
    });
    const previousDateTime = moment(yesterday).format(
      this.dateFormat || 'DD-MM-YYYY',
    );
    $('#fromDate').val(previousDateTime);

    const current_time = new Date();
    current_time.setDate(current_time.getDate() + 1);
    $('#toDate').datetimepicker({
      defaultDate: current_time,
      format: this.dateFormat || 'DD-MM-YYYY',
    });
    const currentDateTime = moment(current_time).format(
      this.dateFormat || 'DD-MM-YYYY',
    );
    $('#toDate').val(currentDateTime);
    setTimeout(() => {
      this.searchInvoices();
    }, 1000);
    // this.fetchSubDepartments();
    this.getSubDepartment();
    this.cultureReportsForm = this.formBuilder.group({
      report_type: ['Culture'],
      id: [''],
      order_id: [''],
      lab_test_detail_id: [''],
      culture_plate: this.formBuilder.array([]),
      free_field: this.formBuilder.array([]),
      gram_stain: this.formBuilder.array([]),
      zn_stain: this.formBuilder.array([]),
      fungal_stain: this.formBuilder.array([]),
      final_report: this.formBuilder.array([]),
      microscopy: this.formBuilder.array([]),
      culture: this.formBuilder.array([]),
      culture_specimen: this.formBuilder.array([]),
      sensitivity: this.formBuilder.array([]),
      reports_comment: this.formBuilder.array([]),
    });
    this.getWebControls();
    this.getScreenControls();
    this.getOrganism();
    this.getDetector();
    this.getSmsControls();
  }
  showHIdeCostingDetail(): any {
    this.showCostingDetail = !this.showCostingDetail;
  }
  getScreenControls(): any {
    this.userService.UserControlByScreenV2().subscribe(
      (data) => {
        this.screenControls = data.roles;
        if (this.screenControls.length > 0) {
          if (
            this.screenControls.filter((m) => m.code === 'lab_collection')
              .length > 0
          ) {
            this.labCollectionAllow = this.screenControls.filter(
              (m) => m.code === 'lab_collection',
            )[0].is_show;
          }
          if (
            this.screenControls.filter((m) => m.code === 'lab_receivable')
              .length > 0
          ) {
            this.labReceivableAllow = this.screenControls.filter(
              (m) => m.code === 'lab_receivable',
            )[0].is_show;
          }
          if (
            this.screenControls.filter((m) => m.code === 'lab_result_entry')
              .length > 0
          ) {
            this.labResultEntryAllow = this.screenControls.filter(
              (m) => m.code === 'lab_result_entry',
            )[0].is_show;
          }
          if (
            this.screenControls.filter((m) => m.code === 'lab_result_verify')
              .length > 0
          ) {
            this.labReportVerifyAllow = this.screenControls.filter(
              (m) => m.code === 'lab_result_verify',
            )[0].is_show;
          }
          if (
            this.screenControls.filter((m) => m.code === 'lab_result_deliver')
              .length > 0
          ) {
            this.labReportDeliverAllow = this.screenControls.filter(
              (m) => m.code === 'lab_result_deliver',
            )[0].is_show;
          }
          if (
            this.screenControls.filter(
              (m) => m.code === 'showrequisitionlistbutton',
            ).length > 0
          ) {
            this.showRequisitionButton = this.screenControls.filter(
              (m) => m.code === 'showrequisitionlistbutton',
            )[0].is_show;
          }
          if (
            this.screenControls.filter(
              (m) => m.code === 'reportUpdateAfterDeliver',
            ).length > 0
          ) {
            this.showReportUpdateAfterDeliver = this.screenControls.filter(
              (m) => m.code === 'reportUpdateAfterDeliver',
            )[0].is_show;
          }
          // updated Reported Time Control
          if (
            this.screenControls.filter(
              (m) => m.code === 'updateReportedTime',
            ).length > 0
          ) {
            this.updateReportedTImeAllow = this.screenControls.filter(
              (m) => m.code === 'updateReportedTime',
            )[0].is_show;
          }
        }
      },
      (err) => {},
    );
  }
  getWebControls(): any {
    this.ngxService.start();
    this.labTestService.fetchLabsControlsv2().subscribe(
      (data) => {
        this.settingData = data;
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.error);
      },
    );
  }

  getPatientTypes(): any {
    this.patientTypes = [];
    this.ngxLoader.start();
    this.patientTypeService.getSelfPatientTypeOptamize().subscribe(
      (data) => {
        this.patientTypes = data.patient_types;
        setTimeout(() => {
          $('#patientType').selectpicker('refresh');
        }, 500);
        setTimeout(() => {
          $('#patientType').selectpicker();
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getSubDepartment(): any {
    this.subDepartments = [];
    this.ngxLoader.start();
    this.patientService.getSubDepartmentsAgainstServiceTypeLab().subscribe(
      (data) => {
        this.subDepartments = data;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  searchInvoices(): any {
    this.labServicesForm.patchValue({
      from_date:
        moment($('#fromDate').val(), this.dateFormat).format('DD-MM-YYYY') ||
        '',
      to_date:
        moment($('#toDate').val(), this.dateFormat).format('DD-MM-YYYY') || '',
      type: '',
    });
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }

  filtersInvoices(filter): any {
    this.labServicesForm.patchValue({
      from_date:
        moment($('#fromDate').val(), this.dateFormat).format('DD-MM-YYYY') ||
        '',
      to_date:
        moment($('#toDate').val(), this.dateFormat).format('DD-MM-YYYY') || '',
      type: filter,
    });
    if (filter === '') {
      this.filterType = 'All';
    }
    if (filter === 'opd') {
      this.filterType = 'OPD';
    }
    if (filter === 'ipd') {
      this.filterType = 'IPD';
    }
    if (filter === 'urgent') {
      this.filterType = 'Urgent';
    }
    if (filter === 'returned') {
      this.filterType = 'Returned';
    }
    if (filter === 'cancel') {
      this.filterType = 'Cancel';
    }
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }
  searchFilter(): any {
    this.activePatient = '';
    this.reportDeliveredSubmited = false;
    this.ngxLoader.start();
    this.labTestService
      .fetchAllLabProcessingReportsLabTest(
        this.page,
        this.perPage,
        this.labServicesForm.value,
      )
      .subscribe(
        (data) => {
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          if (data.orders) {
            this.pageDataList = data.orders;
          } else {
            this.pageDataList = [];
          }
          this.ref.markForCheck();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  selectPaginationLimit(value): any {
    this.page = 1;
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
    }
    this.searchFilter();
  }
  loadPage(event): any {
    this.searchFilter();
  }

  fetchLabConfigurators(): any {
    this.ngxLoader.start();
    this.labconfigurationService.getLabConfiguratorsV2().subscribe(
      (data) => {
        console.log(data);
        this.labConfigurations = data.lab_configurations;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  collectLabTestConform(id, index, status, obj): any {
    this.labCosting = obj;
    let specimen_id = '';
    if (obj.test_specimen) {
      specimen_id = obj.test_specimen;
    }
    if (obj.specimen_id) {
      specimen_id = obj.specimen_id;
    }
    this.collectedLabObj = {
      id,
      index,
      status,
      sample_taken_from_id: obj.sample_taken_from_id || '',
      specimen_id,
    };
    this.modalService.open(this.collectLabTestModal);
    let formatedDateTime = JSON.parse(localStorage.getItem('dateTimeFormats'));
    if (formatedDateTime) {
      this.dateTimeFormat = formatedDateTime.date_time_format;
    }
    if (obj.collecting_time) {
      $('#collection_date_time').datetimepicker({
        defaultDate: obj.collecting_time,
        format: this.dateTimeFormat || 'DD-MM-YYYY h:mm a',
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom',
        },
      });
      const collectionDateTime = moment(obj.collecting_time).format(
        this.dateTimeFormat || 'DD-MM-YYYY h:mm a',
      );
      $('#collection_date_time').val(collectionDateTime);
    } else {
      const current_time = new Date();
      $('#collection_date_time').datetimepicker({
        defaultDate: current_time,
        format: this.dateTimeFormat || 'DD-MM-YYYY h:mm a',
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom',
        },
      });
      const currentDateTime = moment(current_time).format(
        this.dateTimeFormat || 'DD-MM-YYYY h:mm a',
      );
      $('#collection_date_time').val(currentDateTime);
    }
    if (!obj.sample_taken_from_id) {
      const defaultSampleTakenFromConfig = this.labConfigurations.find(
        (config) =>
          config.is_default && config.configurator_type === 'sample_taken_from',
      );
      this.collectedLabObj.sample_taken_from_id =
        defaultSampleTakenFromConfig.id || '';
    }
    this.collectedLabObj.specimen_id = specimen_id || '';
  }
openUpdateReportedDateModal(id): any{
    this.reportedId = id;
  this.modalService.open(this.updateReportedDateModal);
  $('#reported_date_time').datetimepicker({
    format: this.dateTimeFormat || 'DD-MM-YYYY h:mm a',
    widgetPositioning: {
      horizontal: 'auto',
      vertical: 'bottom',
    },
  });
}
  updateReportedDate(): any{
    const reportedDate = $('#reported_date_time').val();
    if (!reportedDate){
      toastr.error('Select Date');
      return;
    }
    this.ngxLoader.start();
    this.labService.updateReportedDateAndTime(reportedDate, this.reportedId).subscribe(data => {
      this.ngxLoader.stop();
      toastr.success('Updated Successfully');
      this.modalService.dismissAll(this.updateReportedDateModal);
    }, err => {
      this.ngxLoader.stop();
      toastr.error(err.error.message);
    });
  }
  changeSpicmen(id, index, status, obj): any {
    let specimen_id = '';
    if (obj.test_specimen) {
      specimen_id = obj.test_specimen;
    }
    if (obj.specimen_id) {
      specimen_id = obj.specimen_id;
    }
    this.collectedLabObj = {
      id,
      index,
      status,
      sample_taken_from_id: obj.sample_taken_from_id || '',
      specimen_id,
    };
    this.isSpiceman = true;
    this.modalService.open(this.speciemen);
  }
  changeSampleTaken(id, index, status, obj): any {
    this.collectedLabObj = {
      id,
      index,
      status,
      sample_taken_from_id: obj.sample_taken_from_id || '',
      specimen_id: obj.specimen_id || '',
    };
    this.isSpiceman = false;
    this.modalService.open(this.speciemen);
  }
  collectTestIdOnly(id, index, status): any {
    if (this.isSpiceman) {
      if (this.collectedLabObj.specimen_id === '') {
        toastr.error('Select Specimen');
        return;
      }
    }
    if (!this.isSpiceman) {
      if (this.collectedLabObj.sample_taken_from_id === '') {
        toastr.error('Select Sample Taken From');
        return;
      }
    }
    this.ngxLoader.start();
    const value = [];
    value.push(id);
    this.labTestService
      .collectLabTestonly(
        value,
        this.collectedLabObj.specimen_id,
        this.collectedLabObj.sample_taken_from_id,
      )
      .subscribe(
        (data) => {
          if (this.isSpiceman === true) {
            this.pageDataList[index].specimen_id =
              this.collectedLabObj.specimen_id;
            this.pageDataList[index].specimen_name =
              data.lab_order_detail.specimen_name;
          }
          if (this.isSpiceman === false) {
            this.pageDataList[index].sample_taken_from_id =
              this.collectedLabObj.sample_taken_from_id;
            this.pageDataList[index].sample_taken_from_name =
              data.lab_order_detail.sample_taken_from_name;
          }
          this.ngxLoader.stop();
          this.modalService.dismissAll(this.speciemen);
          toastr.success('Successfully Updated');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  collectTestId(id, index, checkValidate): any {
    const value = [];
    let collectedDateAndTime;
    if (!checkValidate) {
      if (
        this.collectedLabObj.specimen_id === '' ||
        this.collectedLabObj.sample_taken_from_id === ''
      ) {
        toastr.error('Select fields');
        return;
      }
      let collectionDateTime = moment(
        $('#collection_date_time').val(),
        this.dateTimeFormat,
      ).format('DD-MM-YYYY h:mm a');
      collectedDateAndTime = moment(collectionDateTime, 'DD-MM-YYYY h:mm a')
        .utc()
        .format('DD-MM-YYYY h:mm a');
      const collectionObj = {
        sample_taken_from_id: this.collectedLabObj.sample_taken_from_id,
        specimen_id: this.collectedLabObj.specimen_id,
        lab_order_id: id,
      };
      value.push(collectionObj);
    } else {
      const defaultSampleTakenFromConfig = this.labConfigurations.find(
        (config) =>
          config.is_default && config.configurator_type === 'sample_taken_from',
      );
      for (let i = 0; i < this.selectedTestCollectArray.length; i++) {
        let testSpecimen = '';
        if (this.selectedTestCollectArray[i]?.specimen_id) {
          testSpecimen = this.selectedTestCollectArray[i]?.specimen_id;
        } else {
          testSpecimen = this.selectedTestCollectArray[i]?.test_specimen || '';
        }
        const collectionObj = {
          sample_taken_from_id: this.selectedTestCollectArray[i]
            ?.sample_taken_from_id
            ? this.selectedTestCollectArray[i]?.sample_taken_from_id
            : defaultSampleTakenFromConfig?.id || '',
          specimen_id: testSpecimen,
          lab_order_id: this.selectedTestCollectArray[i]?.id ?? '',
        };
        if (!collectionObj?.specimen_id) {
          toastr.error('Specimen is empty');
          return;
        }
        value.push(collectionObj);
      }
      collectedDateAndTime = moment().utc().format('DD-MM-YYYY h:mm a');
    }
    this.ngxLoader.start();
    // this.labCosting.test_cost_details
    this.labTestService
      .collectLabProcessingTest(value, collectedDateAndTime, [])
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.collectLabTestModal);
          // this.pageDataList.splice(index, 1);
          if (checkValidate) {
            this.searchInvoices();
          } else {
            this.pageDataList[index] = data.lab_order_detail[0];
          }
          this.selectedTestCollectArray = [];
          this.collectActivePatient = '';
          this.ngxLoader.stop();
          toastr.success('Successfully Updated');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  rejectLabTest(element, index): any {
    this.itemIndex = index;
    this.rejectedSampleId = [];
    this.rejectedSampleId.push(element.id);
    if (element.status === 'Unverified') {
      this.rejectedSampleReasonList = ['Result Error'];
      this.rejectedSampleReason = 'Result Error';
    } else {
      this.rejectedSampleReasonList = [
        'Contaminated',
        'Inappropriate Container/tube',
        'Quantity not sufficient',
        'Labeling Errors',
        'Hemolyzed',
        'Clotted',
      ];
      this.rejectedSampleReason = 'Contaminated';
    }
    this.modalService.open(this.rejectLabTestModal);
  }
  rejectLabTestFun(): any {
    this.ngxLoader.start();
    this.labTestService
      .rejectLabProcessingTest(this.rejectedSampleId, this.rejectedSampleReason)
      .subscribe(
        (data) => {
          // this.searchFilter();
          // this.pageDataList.splice(this.itemIndex, 1);
          this.pageDataList[this.itemIndex] = data.lab_order_detail;
          this.modalService.dismissAll(this.rejectLabTestModal);
          toastr.error('Order Rejected');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  returnCancelLabTest(element): any {
    this.isChecked = false;
    this.redoCancel = [];
    this.redoCancel.push(element.id);
    this.modalService.open(this.returnCancelLabTestModal);
  }
  returnCancelLabTestFun(): any {
    if (this.isChecked === false) {
      toastr.error('Select confirmation');
      return;
    }
    this.ngxLoader.start();
    this.labTestService.returnCancelLabTestV2(this.redoCancel).subscribe(
      (data) => {
        this.searchFilter();
        this.modalService.dismissAll(this.returnCancelLabTestModal);
        toastr.success('Service Restored');
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  cancelLabTest(element, index): any {
    this.itemIndex = index;
    this.isChecked = false;
    this.rejectedSampleId = [];
    this.rejectedSampleId.push(element.id);
    this.modalService.open(this.cancelLabTestModal);
  }
  cancelLabTestFun(): any {
    if (this.isChecked === false) {
      toastr.error('Select confirmation');
      return;
    }
    this.ngxLoader.start();
    this.labTestService
      .cancelLabProcessingTest(this.rejectedSampleId)
      .subscribe(
        (data) => {
          // this.searchFilter();
          this.pageDataList[this.itemIndex] = data.lab_order_detail;
          this.modalService.dismissAll(this.cancelLabTestModal);
          toastr.success('Service Cancelled');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  deliveredResultLabMultiple(): any {
    if (this.selectedTestArray.length === 0) {
      toastr.error('Select lab test');
      return;
    }
    this.reportDeliveredSubmited = false;
    this.showHeader = this.settingData.is_header;
    this.showFooter = this.settingData.is_footer;
    this.headerImage = '1';
    this.footerImage = '1';
    this.reportDelivered.reset();
    this.reportDelivered.controls.receiver_relation.setValue('Self');
    this.reportDelivered.controls.receiver_name.setValue(
      this.currentObj.patient_name,
    );
    this.reportDelivered.controls.receiver_cnic.setValue(
      this.currentObj.patient_nic,
    );
    this.reportDelivered.controls.id.setValue(this.currentObj.id);
    console.log('fjwefnwefj', this.currentObj.id);
    this.modalService.open(this.deliveredLabTestModal, { size: 'lg' });
  }

  deliveredResultFun(): any {
    this.reportDeliveredSubmited = true;
    const ids = [];
    for (let i = 0; i < this.selectedTestArray.length; i++) {
      ids.push(this.selectedTestArray[i].id);
    }
    if (this.reportDelivered.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.labTestService
      .deliveredLabProcessingTestResultFun(this.reportDelivered.value, ids)
      .subscribe(
        (data) => {
          this.printReportByGroup();
          this.searchFilter();
          this.reportDeliveredSubmited = false;
          this.activePatient = '';
          this.modalService.dismissAll(this.deliveredLabTestModal);
          this.ngxLoader.stop();
          toastr.success('Report Delivered');
          // this.pageDataList[this.itemIndex] = data.lab_order_detail;
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  get g() {
    return this.reportDelivered.controls;
  }

  printReportByGroup(): any {
    console.log('1st in');
    $('#printConfirmation').modal('hide');
    this.selectedArray = [];
    // this.pageDataList = [];
    console.log('this.selectedTestArray', this.selectedTestArray);
    for (let i = 0; i < this.selectedTestArray.length; i++) {
      if (this.selectedArray.length === 0) {
        const obj = {
          name: this.selectedTestArray[i].group_ids,
          data: [this.selectedTestArray[i].id],
          mrn: this.selectedTestArray[i].patient_mrn,
          id: this.selectedTestArray[i].invoice_id,
        };
        this.selectedArray.push(obj);
      } else {
        this.setInnerGroupData(i);
      }
    }
    for (let z = 0; z < this.selectedArray.length; z++) {
      if (this.settingData.web_report === true) {
        console.log('this.selectedArray[z].data', this.selectedArray[z].data);
        window.open(
          'lab/lab-report-pdf?' +
            'lab_id=' +
            this.selectedArray[z].data +
            '&invoice_id=' +
            this.selectedArray[z].id +
            '&visit_id=' +
            '&mrn=' +
            this.selectedArray[z].mrn +
            '&show_header=' +
            this.showHeader +
            '&show_footer=' +
            this.showFooter +
            '&header_type=' +
            this.headerImage +
            '&footer_type=' +
            this.footerImage,
        );
      } else {
        window.open(
          this.current_environment +
            '/v4/orders/lab_order_report.pdf?auth_token=' +
            localStorage.getItem('auth_token') +
            '&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id') +
            '&id=' +
            this.selectedArray[z].data +
            '&show_header=' +
            this.showHeader +
            '&show_footer=' +
            this.showFooter,
        );
      }
    }
    this.resetActivePatient();
  }
  setInnerGroupData(i): any {
    let ind = -1;
    for (let j = 0; j < this.selectedArray.length; j++) {
      if (this.selectedArray[j].name === this.selectedTestArray[i].group_ids) {
        ind = j;
      }
    }
    if (ind === -1) {
      const obj = {
        name: this.selectedTestArray[i].group_ids,
        data: [this.selectedTestArray[i].id],
      };
      this.selectedArray.push(obj);
    } else {
      this.selectedArray[ind].data.push(this.selectedTestArray[i].id);
    }
  }

  updateReceiverType(type, value): any {
    if (type === 'Self') {
      this.reportDelivered.controls.receiver_name.setValue(value.patient_name);
      this.reportDelivered.controls.receiver_cnic.setValue(value.patient_nic);
    } else {
      this.reportDelivered.controls.receiver_name.setValue('');
      this.reportDelivered.controls.receiver_cnic.setValue('');
    }
  }
  collectTestRecievable(id, index): any {
    this.ngxLoader.start();
    const value = [];
    value.push(id);
    this.labTestService.receivedLabProcesingTest(value).subscribe(
      (data) => {
        this.pageDataList[index] = data.lab_order_detail;
        // this.pageDataList.splice(index, 1);
        this.ngxLoader.stop();
        toastr.success('Successfully Updated');
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  resetActivePatient(): any {
    this.isDelivered = false;
    this.activePatient = '';
    this.selectedTestArray = [];
    $('.printCheckbox').prop('checked', false);
    this.ref.detectChanges();
  }
  // openQrcode(id): any {
  //   window.open(this.current_environment + '/v2/lab_order_details/' + id + '/print.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'));
  // }
  openQrcode(id: any): any {
    window.open(
      this.currentEnvironmentPython +
        '/api/lab_test_barcode/' +
        id +
        '/?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
    );
  }
  clearHeaderImage(): any {
    this.headerImage = '1';
  }
  updateFooterReportControl(value) {
    this.footerImage = value;
  }
  clearFooterImage(): any {
    this.footerImage = '1';
  }
  printConfirmModal(): any {
    $('#printConfirmation').modal('show');
    this.showHeader = this.settingData.is_header;
    this.showFooter = this.settingData.is_footer;
    this.headerImage = '1';
    this.footerImage = '1';
  }
  openCsvModal(): any {
    $('#csvModal').modal('show');
    $('#detector').val('');
    $('#organism').val('');
    this.detectorCsv = '';
    this.organismCsv = '';
  }
  detectorCsvChange(event): any {
    if (event.target.files.length > 0) {
      event.target.files[0].type.includes('csv');
      this.detectorCsv = event.target.files[0];
    }
  }
  organismCsvChange(event): any {
    if (event.target.files.length > 0) {
      event.target.files[0].type.includes('csv');
      this.organismCsv = event.target.files[0];
    }
  }
  uploadCsv(): any {
    if (!this.detectorCsv && !this.organismCsv) {
      toastr.error('Upload CSV');
      return;
    }
    this.ngxLoader.start();
    this.labService.uploadCsv(this.detectorCsv, this.organismCsv).subscribe(
      (data) => {
        $('#csvModal').modal('hide');
        this.ngxLoader.stop();
        toastr.success('Upload Successfully');
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  previewReport(ele: any, i: any): any {
    if (this.settingData.web_report === true) {
      if (this.settingData.enable_external_lis) {
        this.getLisIntegration(ele.lab_id, ele.test_code);
      } else {
        window.open(
          'lab/lab-report-pdf?' +
            'lab_id=' +
            ele.id +
            '&invoice_id=' +
            ele.invoice_id +
            '&visit_id=' +
            ele.visit_id +
            '&mrn=' +
            ele.patient_mrn +
            '&show_header=true' +
            '&show_footer=true' +
            '&header_type=1' +
            '&footer_type=1',
        );
      }
    } else {
      window.open(
        this.current_environment +
          '/v4/orders/lab_order_report.pdf?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          ele.id +
          '&show_header=' +
          this.showHeader +
          '&show_footer=' +
          this.showFooter,
      );
    }
  }
  selectTestToCollect(data, events): any {
    this.collectActivePatient = data.patient_mrn;
    if (events.target.checked === true) {
      this.selectedTestCollectArray.push(data);
      console.log(
        'this.selectedTestCollectArray',
        this.selectedTestCollectArray,
      );
    } else {
      const index = this.selectedTestCollectArray.findIndex(
        (img) => img.id === data.id,
      );
      if (index !== -1) {
        this.selectedTestCollectArray.splice(index, 1);
      }
    }
    if (this.selectedTestCollectArray.length === 0) {
      this.collectActivePatient = '';
    }
  }
  selectLabTest(data, events): any {
    this.activePatient = data.patient_mrn;
    this.currentObj = data;
    console.log('currentObj', this.currentObj);
    if (events.target.checked === true) {
      this.selectedTestArray.push(data);
      console.log('this.selectedTestArray', this.selectedTestArray);
    } else {
      const index = this.selectedTestArray.findIndex(
        (img) => img.id === data.id,
      );
      if (index !== -1) {
        this.selectedTestArray.splice(index, 1);
      }
    }
    if (this.selectedTestArray.length === 0) {
      this.activePatient = '';
      this.isDelivered = false;
    }
    let verifyedCount = 0;
    let deliveredCount = 0;
    for (let z = 0; z < this.selectedTestArray.length; z++) {
      if (this.selectedTestArray[z].status === 'Verified') {
        verifyedCount++;
      }
      if (this.selectedTestArray[z].status === 'Delivered') {
        deliveredCount++;
      }
    }
    if (
      (verifyedCount > 0 && deliveredCount > 0) ||
      (verifyedCount === 0 && deliveredCount > 0)
    ) {
      this.isDelivered = true;
    } else {
      this.isDelivered = false;
    }
    console.log('selectedTestArray', this.selectedTestArray);
  }

  getPendingResults(mrn): any {
    this.patientLabTestListing = [];
    this.labTestService.fetchReceivedLabTestByMrnV2(mrn).subscribe(
      (data) => {
        this.patientLabTestListing = data.orders;
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getReportDetail(data, index): any {
    this.currentPatientData = data;
    this.showCostingDetail = false;
    this.showCostingDetailModal = false;
    this.itemIndex = index;
    this.ngxLoader.start();
    this.destroyTextEditor();
    this.labTestService.fetchReportDetailsV2(data.id).subscribe(
      (data) => {
        this.showCostingDetailModal = true;
        this.addReportsCostingDetail = data?.lab_order_detail;
        if ($('.modal-body-report').length === 0) {
          this.modalService.open(this.reportsModal, this.ngbModalStatic);
        }
        this.addReports(data.lab_order_detail);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  setTextEditorForUpdate(): any {
    setTimeout(() => {
      for (let i = 0; i < this.reportTypeUpdate.length; i++) {
        for (let j = 0; j < this.reportTypeUpdate[i].value.length; j++) {
          if (this.reportTypeUpdate[i].value[j].report_type === 'editor') {
            const reportId = '#textEditorUpdate' + i + '' + j;
            console.log('textEditorUpdate', reportId);
            const reportData = this.reportTypeUpdate[i].value[j].heading;
            tinymce.init({
              selector: reportId,
              menubar: false,
              height: 400,
              toolbar: [
                'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
              ],
              plugins: 'advlist autolink link image lists charmap code table lineheight',
              fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt',
              content_style: 'body { font-size: 15pt; font-family: Arial; }', // Set default font size
              setup(editor) {
                editor.on('init', function (e) {
                  editor.setContent(reportData); // Load the content
                });
              },
            });
          }
        }
      }
    }, 1000);
    console.log('reportType for update', this.reportTypeUpdate);
  }
  setTextEditorForAdd(): any {
    setTimeout(() => {
      for (let i = 0; i < this.reportType.length; i++) {
        for (let j = 0; j < this.reportType[i].value.length; j++) {
          if (this.reportType[i].value[j].report_type === 'editor') {
            const reportId = '#textEditor' + i + '' + j;
            console.log('reportIdreportIdreportId', reportId);
            const reportData = this.reportType[i].value[j].heading;
            tinymce.init({
              selector: reportId,
              menubar: false,
              height: 400,
              toolbar: [
                'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
              ],
              plugins: 'advlist autolink link image lists charmap code table lineheight',
              fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt',
              content_style: 'body { font-size: 15pt; font-family: Arial; }', // Set default font size to 15pt
              setup(editor) {
                editor.on('init', function (e) {
                  editor.setContent(reportData); // Load content into the editor
                });
              },
            });
          }
        }
      }
    }, 1000);
    console.log('reportType for Add', this.reportType);
  }
  destroyTextEditor(): any {
    while (tinymce.editors.length > 0) {
      tinymce.remove(tinymce.editors[0]);
    }
  }

  addReports(element): any {
    this.disableBtn = false;
    this.pathologistRemarks = '';
    this.reportType = [];
    this.reportTypeUpdate = [];
    this.activeLabEntry = element;
    console.log('element', element);
    if (
      element.lab_investigation.report_type === 'Culture' ||
      element.lab_investigation.report_type === 'Culture-1'
    ) {
      this.clearAllCultureForm();
      this.cultureReportsForm.reset();
      if (element.lab_test_details) {
        if (element.lab_test_details.length > 0) {
          this.pathologistRemarks =
            element.lab_test_details[0].pathologist_remarks;
        }
      }
      this.cultureReportsForm.controls.lab_test_detail_id.setValue('');
      if (
        element.rejected === true ||
        element.draft === true ||
        element.status === 'Unverified' ||
        element.status === 'Delivered' ||
        element.status === 'Verified'
      ) {
        setTimeout(() => {
          for (let i = 0; i < element.lab_test_details.length; i++) {
            if (element.lab_test_details[i].culture_plate) {
              for (
                let j = 0;
                j < element.lab_test_details[i].culture_plate.length;
                j++
              ) {
                this.reports_culture_plate.push(
                  this.addReportsCulturePlates(
                    element.lab_test_details[i].culture_plate[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].free_field) {
              for (
                let j = 0;
                j < element.lab_test_details[i].free_field.length;
                j++
              ) {
                this.reports_free_field.push(
                  this.addReportsFreeFields(
                    element.lab_test_details[i].free_field[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].gram_stain) {
              for (
                let j = 0;
                j < element.lab_test_details[i].gram_stain.length;
                j++
              ) {
                this.reports_gram_stain.push(
                  this.addReportsGramStain(
                    element.lab_test_details[i].gram_stain[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].zn_stain) {
              for (
                let j = 0;
                j < element.lab_test_details[i].zn_stain.length;
                j++
              ) {
                this.reports_zn_stain.push(
                  this.addReportsZnStain(
                    element.lab_test_details[i].zn_stain[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].fungal_stain) {
              for (
                let j = 0;
                j < element.lab_test_details[i].fungal_stain.length;
                j++
              ) {
                this.reports_fungal_stain.push(
                  this.addReportsFungalStain(
                    element.lab_test_details[i].fungal_stain[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].final_report) {
              for (
                let j = 0;
                j < element.lab_test_details[i].final_report.length;
                j++
              ) {
                this.reports_final_report.push(
                  this.addReportsFinalReport(
                    element.lab_test_details[i].final_report[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].microscopy) {
              for (
                let j = 0;
                j < element.lab_test_details[i].microscopy.length;
                j++
              ) {
                this.reports_microscopy.push(
                  this.addReportsMicroscopy(
                    element.lab_test_details[i].microscopy[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].reports_comment) {
              for (
                let j = 0;
                j < element.lab_test_details[i].reports_comment.length;
                j++
              ) {
                this.reports_comment.push(
                  this.addReportsReportsComments(
                    element.lab_test_details[i].reports_comment[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].sensitivity) {
              for (
                let j = 0;
                j < element.lab_test_details[i].sensitivity.length;
                j++
              ) {
                this.reports_sensitivity.push(
                  this.addReportsSensitivity(
                    element.lab_test_details[i].sensitivity[j],
                  ),
                );
                const type = element.lab_test_details[i].sensitivity[j].type;
                this.cultureGroupArray[j] = this.cultureListSensitivity.filter(
                  (t) => t.id === Number(type),
                )[0].groups;

                for (
                  let x = 0;
                  x <
                  element.lab_test_details[i].sensitivity[j]
                    .sensitivity_resistance.length;
                  x++
                ) {
                  const control = this.reports_sensitivity.controls[j].get(
                    'sensitivity_resistance',
                  ) as UntypedFormArray;
                  control.push(
                    this.addReportsSensitivityResistance(
                      element.lab_test_details[i].sensitivity[j]
                        .sensitivity_resistance[x],
                    ),
                  );
                }
              }
            }
            if (element.lab_test_details[i].culture) {
              for (
                let j = 0;
                j < element.lab_test_details[i].culture.length;
                j++
              ) {
                this.reports_culture.push(
                  this.addReportsCulture(
                    element.lab_test_details[i].culture[j],
                  ),
                );

                // for ( var x = 0; x < element.lab_test_details[i].culture[j].reports_comment.length; x++ ) {
                //   let control = <FormArray>this.reports_culture.controls[j].get('reports_comment');
                //   control.push(this.addReportsReportsComments(element.lab_test_details[i].culture[j].reports_comment[x]));
                // }
                // for ( var x = 0; x < element.lab_test_details[i].culture[j].sensitivity.length; x++ ) {
                //   let control = <FormArray>this.reports_culture.controls[j].get('sensitivity');
                //   control.push(this.addReportsSensitivity(element.lab_test_details[i].culture[j].sensitivity[x]));
                // }
              }
            }
            if (element.lab_test_details[i].culture_specimen) {
              for (
                let j = 0;
                j < element.lab_test_details[i].culture_specimen.length;
                j++
              ) {
                this.reports_specimen.push(
                  this.addReportsSpecimen(
                    element.lab_test_details[i].culture_specimen[j],
                  ),
                );
                // this.reports_specimen.push(this.addReportsSpecimen(element.sample_taken_from_id));
              }
            }
          }
        }, 1000);

        this.cultureReportsForm.controls.lab_test_detail_id.setValue(
          element.lab_test_details[0].id,
        );
      } else {
        this.addFieldsInCulture('specimen');
        //   // this.addFieldsInCulture('sensitivity');
        for (let i = 0; i < 2; i++) {
          this.addFieldsInCulture('culture');
          this.reports_comment.push(this.setReportsReportsComments());
        }
        for (let j = 0; j < this.cultureListSensitivity.length; j++) {
          for (
            let k = 0;
            k < this.cultureListSensitivity[j].groups.length;
            k++
          ) {
            if (this.cultureListSensitivity[j].groups[k].default === true) {
              this.cultureGroupArray.push(
                this.cultureListSensitivity[j].groups,
              );
              console.log('cultureGroupArray', this.cultureGroupArray);
              this.reports_sensitivity.push(
                this.addReportsSensitivityDefault(
                  this.cultureListSensitivity[j].id,
                  this.cultureListSensitivity[j].groups[k].id,
                ),
              );
              for (let i = 0; i < this.reports_culture.length; i++) {
                const control = this.reports_sensitivity.controls[
                  this.reports_sensitivity.length - 1
                ].get('sensitivity_resistance') as UntypedFormArray;
                control.push(this.setReportsSensitivityResistance());
              }
            }
          }
        }
      }
      this.cultureReportsForm.controls.order_id.setValue(element.order_id);
      this.cultureReportsForm.controls.report_type.setValue(
        element.lab_investigation.report_type,
      );
      this.cultureReportsForm.controls.id.setValue(element.id);
      console.log('cultureReportsForm', this.cultureReportsForm.value);
      // $('#cultureReportsModal').modal('show');
      // this.modalService.open(this.cultureReportsModal, this.ngbModalStatic);
      this.modalType = 'cultureReportsModal';
    } else if (element.lab_investigation.report_type === 'PCR') {
      this.pcrListData = element;
      this.modalType = 'pcrModal';
      this.pcrData = {
        order_id: element.order_id,
        report_type: element.lab_investigation.report_type,
        id: element.id,
        patient_mrn: element.patient_mrn,
        lab_assessment_id: element?.lab_assessments[0]?.id ?? '',
        lab_test_detail_id: element?.lab_test_details[0]?.id ?? '',
      };
    } else if (element.lab_investigation.report_type === 'Customized' || element.lab_investigation.report_type === 'Solana')  {
      // element.lab_assessments.sort(function(a, b) {
      //   return a.id - b.id;
      // });
      if (
        element.rejected === true ||
        element.draft === true ||
        element.status === 'Unverified' ||
        element.status === 'Delivered' ||
        element.status === 'Verified'
      ) {
        this.setPerametersForUpdate(element);
      } else {
        this.setPerametersForAdd(element);
      }
    }
  }
  getOrganism(): any {
    this.ngxLoader.start();
    this.cultureConfigService.getOrganism(1).subscribe(
      (data) => {
        this.organismList = data?.data;
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }
  getDetector(): any {
    this.ngxLoader.start();
    this.cultureConfigService.getDetector(2).subscribe(
      (data) => {
        this.detectorList = data?.data;
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }
  updateSensitivityType(id, i): any {
    this.cultureGroupArray[i] = this.cultureListSensitivity.filter(
      (t) => t.id === Number(id),
    )[0].groups;
    console.log('cultureGroupArray', this.cultureGroupArray);
    this.reports_sensitivity.at(i).patchValue({ group: '' });
  }

  setPerametersForUpdate(element): any {
    if (element.lab_test_details.length > 0) {
      this.pathologistRemarks = element.lab_test_details[0].pathologist_remarks;
    }
    for (let i = 0; i < element.lab_test_details.length; i++) {
      console.log(i);
      if (element.lab_test_details[i].report_type === 'single') {
        const obj = {
          report_type: 'single',
          value: element.lab_test_details[i].value,
          lab_test_detail_id: element.lab_test_details[i].id,
          caption: element.lab_test_details[i].caption,
          unit: element.lab_test_details[i].unit,
          specimen: element.lab_test_details[i].specimen,
          range: element.lab_test_details[i].range,
          range_start: element.lab_test_details[i].range_start,
          range_end: element.lab_test_details[i].range_end,
          critical_range_type: element.lab_test_details[i].critical_range_type,
          critical_start_range: element.lab_test_details[i].critical_start_range,
          critical_end_range: element.lab_test_details[i].critical_end_range,
          heading: element.lab_test_details[i].heading || '',
          super_heading: element.lab_test_details[i].super_heading || '',
          primary_id: element.lab_test_details[i].primary_id,
          sort_order: element.lab_test_details[i].sort_order,
          lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
          range_comments: element.lab_test_details[i].range_comments,
          range_type: element.lab_test_details[i].range_type || '',
          patient_value: element.lab_test_details[i].patient_value,
          cutoff_value: element.lab_test_details[i].cutoff_value,
          show_cutoff_patient_value:
            element.lab_test_details[i].show_cutoff_patient_value,
          id: element.id,
        };
        this.reportTypeUpdate.push(obj);
      }

      if (element.lab_test_details[i].report_type === 'text') {
        const objtext = {
          report_type: 'text',
          heading: element.lab_test_details[i].heading,
          lab_test_detail_id: element.lab_test_details[i].id,
          caption: element.lab_test_details[i].caption,
          unit: element.lab_test_details[i].unit,
          specimen: element.lab_test_details[i].specimen,
          range: element.lab_test_details[i].range,
          super_heading: element.lab_test_details[i].super_heading || '',
          primary_id: element.lab_test_details[i].primary_id,
          sort_order: element.lab_test_details[i].sort_order,
          lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
          range_comments: element.lab_test_details[i].range_comments,
          patient_value: element.lab_test_details[i].patient_value,
          cutoff_value: element.lab_test_details[i].cutoff_value,
          show_cutoff_patient_value:
            element.lab_test_details[i].show_cutoff_patient_value,
          id: element.id,
        };
        this.reportTypeUpdate.push(objtext);
      }
      if (element.lab_test_details[i].report_type === 'editor') {
        const objtext = {
          report_type: 'editor',
          heading: element.lab_test_details[i].heading,
          lab_test_detail_id: element.lab_test_details[i].id,
          caption: element.lab_test_details[i].caption,
          unit: element.lab_test_details[i].unit,
          specimen: element.lab_test_details[i].specimen,
          range: element.lab_test_details[i].range,
          super_heading: element.lab_test_details[i].super_heading || '',
          primary_id: element.lab_test_details[i].primary_id,
          sort_order: element.lab_test_details[i].sort_order,
          lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
          range_comments: element.lab_test_details[i].range_comments,
          patient_value: element.lab_test_details[i].patient_value,
          cutoff_value: element.lab_test_details[i].cutoff_value,
          show_cutoff_patient_value:
            element.lab_test_details[i].show_cutoff_patient_value,
          id: element.id,
        };
        this.reportTypeUpdate.push(objtext);
      }
      if (element.lab_test_details[i].report_type === 'combo') {
        const objcombo = {
          report_type: 'combo',
          report_type_1: element.lab_test_details[i].report_type_1,
          report_type_2: element.lab_test_details[i].report_type_2,
          report_type_1_result:
            element.lab_test_details[i].report_type_1_result,
          report_type_2_result:
            element.lab_test_details[i].report_type_2_result,
          report_type_1_value:
            element.lab_test_details[i].report_type_1_value || '',
          report_type_2_value:
            element.lab_test_details[i].report_type_2_value || '',
          caption: element.lab_test_details[i].caption,
          unit: element.lab_test_details[i].unit,
          specimen: element.lab_test_details[i].specimen,
          lab_test_detail_id: element.lab_test_details[i].id,
          heading: element.lab_test_details[i].heading || '',
          super_heading: element.lab_test_details[i].super_heading || '',
          primary_id: element.lab_test_details[i].primary_id,
          sort_order: element.lab_test_details[i].sort_order,
          lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
          range_comments: element.lab_test_details[i].range_comments,
          patient_value: element.lab_test_details[i].patient_value,
          cutoff_value: element.lab_test_details[i].cutoff_value,
          show_cutoff_patient_value:
            element.lab_test_details[i].show_cutoff_patient_value,
          id: element.id,
        };
        this.reportTypeUpdate.push(objcombo);
      }
    }

    // $('#reportsModalUpdate').modal('show');
    // this.modalService.open(this.reportsModalUpdate, this.ngbModalStatic);
    this.modalType = 'reportsModalUpdate';

    const groups = {};
    for (let i = 0; i < this.reportTypeUpdate.length; i++) {
      const groupName = this.reportTypeUpdate[i].super_heading;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(this.reportTypeUpdate[i]);
    }
    this.reportTypeUpdate = [];
    for (const objsort in groups) {
      this.reportTypeUpdate.push({ name: objsort, value: groups[objsort] });
    }
    // this.reportTypeUpdate.reverse();
    this.setTextEditorForUpdate();
  }
  convertToNumber(value): any {
    return Number(value);
  }

  setPerametersForAdd(element): any {
    for (let i = 0; i < element.lab_assessments.length; i++) {
      if (element.lab_assessments[i].parameter_type === 'single') {
        console.log('in singl');
        if (element.lab_assessments[i].assessment_ranges) {
          const patientRange = this.singleReportRange(
            element.lab_assessments[i].assessment_ranges,
            element.age_value,
            element.patient_gender,
            element.patient_age_type,
          );
          const obj = {
            report_type: 'single',
            report_range: element.lab_assessments[i].machine_result || '',
            id: element.id,
            order_id: element.order_id,
            title: element.lab_assessments[i].title,
            uom: element.lab_assessments[i].uom,
            specimen: element.lab_assessments[i].specimen,
            assessment_ranges: element.lab_assessments[i].assessment_ranges,
            range: patientRange.result,
            range_start: patientRange.start,
            range_end: patientRange.end,
            critical_range_type: patientRange.critical_range_type,
            critical_start_range: patientRange.critical_start_range,
            critical_end_range: patientRange.critical_end_range,
            heading: element.lab_assessments[i].heading,
            super_heading: element.lab_assessments[i].super_heading,
            primary_id: element.lab_assessments[i].primary_id,
            sort_order: element.lab_assessments[i].sort_order,
            lab_assessment_id: element.lab_assessments[i].id,
            range_comments: element.lab_assessments[i].range_comments,
            cutoff_value: element.lab_assessments[i].cutoff_value,
            patient_value: element.lab_assessments[i].patient_value,
            show_cutoff_patient_value:
              element.lab_assessments[i].show_cutoff_patient_value,
            range_type: patientRange.range_type,
            lab_test_detail_id: '',
          };
          this.reportType.push(obj);
        }
        // this.singleReportRangeType(element.lab_assessments[i].assessment_ranges, element.age_value, element.patient_gender)
      }
      if (element.lab_assessments[i].parameter_type === 'text') {
        console.log('in text');
        if (element.lab_assessments[i].assessment_ranges) {
          const objtext = {
            report_type: 'text',
            report_range: element.lab_assessments[i].machine_result || '',
            id: element.id,
            order_id: element.order_id,
            title: element.lab_assessments[i].title,
            uom: element.lab_assessments[i].uom,
            specimen: element.lab_assessments[i].specimen,
            heading: element.lab_assessments[i].assessment_ranges.heading,
            super_heading: element.lab_assessments[i].super_heading,
            primary_id: element.lab_assessments[i].primary_id,
            sort_order: element.lab_assessments[i].sort_order,
            lab_assessment_id: element.lab_assessments[i].id,
            range_comments: element.lab_assessments[i].range_comments,
            cutoff_value: element.lab_assessments[i].cutoff_value,
            patient_value: element.lab_assessments[i].patient_value,
            show_cutoff_patient_value:
              element.lab_assessments[i].show_cutoff_patient_value,
            lab_test_detail_id: '',
          };
          this.reportType.push(objtext);
        }
      }
      if (element.lab_assessments[i].parameter_type === 'combo') {
        if (element.lab_assessments[i].assessment_ranges) {
          console.log('in combo');
          const objcombo = {
            report_type: 'combo',
            report_type_1:
              element.lab_assessments[i].assessment_ranges.report_type_1,
            report_type_2:
              element.lab_assessments[i].assessment_ranges.report_type_2,
            report_type_1_result:
              element.lab_assessments[i].assessment_ranges.report_type_1_result,
            report_type_2_result:
              element.lab_assessments[i].assessment_ranges.report_type_2_result,
            report_type_1_value:
              element.lab_assessments[i].machine_result ||
              element.lab_assessments[i].assessment_ranges.report_type_1_result,
            report_type_2_value:
              element.lab_assessments[i].assessment_ranges.report_type_2_result,
            id: element.id,
            order_id: element.order_id,
            title: element.lab_assessments[i].title,
            uom: element.lab_assessments[i].uom,
            specimen: element.lab_assessments[i].specimen,
            heading: element.lab_assessments[i].heading,
            super_heading: element.lab_assessments[i].super_heading,
            primary_id: element.lab_assessments[i].primary_id,
            sort_order: element.lab_assessments[i].sort_order,
            lab_assessment_id: element.lab_assessments[i].id,
            range_comments: element.lab_assessments[i].range_comments,
            cutoff_value: element.lab_assessments[i].cutoff_value,
            patient_value: element.lab_assessments[i].patient_value,
            show_cutoff_patient_value:
              element.lab_assessments[i].show_cutoff_patient_value,
            lab_test_detail_id: '',
          };
          if (objcombo.report_type_1 === 'dropdown') {
            // objcombo.report_type_1_value = element.lab_assessments[i].assessment_ranges.report_type_1_result[0];
            if( typeof element.lab_assessments[i].machine_result === 'string') {
              objcombo.report_type_1_value = this.toCamelCase(element.lab_assessments[i].machine_result) || '';
            } else {
              objcombo.report_type_1_value = element.lab_assessments[i].machine_result || '';
            }
          }
          if (objcombo.report_type_2 === 'dropdown') {
            // objcombo.report_type_2_value = element.lab_assessments[i].assessment_ranges.report_type_2_result[0];
            objcombo.report_type_2_value = '';
          }
          this.reportType.push(objcombo);
        }
      }
      if (element.lab_assessments[i].parameter_type === 'editor') {
        console.log('in editor');
        if (element.lab_assessments[i].assessment_ranges) {
          const objtext = {
            report_type: 'editor',
            report_range: '',
            id: element.id,
            order_id: element.order_id,
            title: element.lab_assessments[i].title,
            uom: element.lab_assessments[i].uom,
            specimen: element.lab_assessments[i].specimen,
            heading: element.lab_assessments[i].assessment_ranges.heading,
            super_heading: element.lab_assessments[i].super_heading,
            primary_id: element.lab_assessments[i].primary_id,
            sort_order: element.lab_assessments[i].sort_order,
            lab_assessment_id: element.lab_assessments[i].id,
            range_comments: element.lab_assessments[i].range_comments,
            cutoff_value: element.lab_assessments[i].cutoff_value,
            patient_value: element.lab_assessments[i].patient_value,
            show_cutoff_patient_value:
              element.lab_assessments[i].show_cutoff_patient_value,
            lab_test_detail_id: '',
          };
          this.reportType.push(objtext);
        }
      }
    }
    const groups = {};
    for (let i = 0; i < this.reportType.length; i++) {
      const groupName = this.reportType[i].super_heading;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(this.reportType[i]);
    }
    this.reportType = [];
    for (const obj in groups) {
      this.reportType.push({ name: obj, value: groups[obj] });
    }

    // $('#reportsModal').modal('show');
    // this.modalService.open(this.reportsModal, this.ngbModalStatic);
    this.modalType = 'reportsModal';
    console.log('reportType', this.reportType);
    this.setTextEditorForAdd();
  }
  toCamelCase(str) {
    return str.toLowerCase().replace(/^\w/, function(match) {
      return match.toUpperCase();
    });
  }

  updatePathologistRemarks(value): any {
    this.pathologistRemarks = value;
    $('#pathologistRemarks').val(value);
  }

  singleReportRange(range, age, gender, patient_age_type): any {
    const resultObj = {
      start: 0,
      end: 0,
      critical_range_type: '',
      critical_start_range: 0,
      critical_end_range: 0,
      result: '',
      range_type: '',
    };
    let patient_age = age;
    if (patient_age_type === 'years') {
      patient_age = age * 365;
    }
    if (patient_age_type === 'Month') {
      patient_age = age * 30;
    }
    console.log('patient_age_type', patient_age_type);
    console.log('patient_age', patient_age);
    for (let i = 0; i < range.length; i++) {
      if (gender === range[i].gender || range[i].gender === 'Both') {
        const rangeData = range[i];
        // console.log('rangeDatarangeDatarangeData', rangeData);
        let start = rangeData.age_start;
        let end = rangeData.age_end;
        if (rangeData.age_type === 'years') {
          start = rangeData.age_start / 365;
          end = rangeData.age_end * 365;
        }
        if (rangeData.age_type === 'months') {
          start = rangeData.age_start / 30;
          end = rangeData.age_end * 30;
        }
        // if ( patient_age_type === range[i].age_type &&  patient_age >= range[i].age_start && patient_age <= range[i].age_end )
        // console.log('range[i].age_type', rangeData.age_type);
        // console.log('start', start);
        // console.log('end', end);
        if (patient_age >= start && patient_age <= end) {
          if (rangeData.range_type === 'in_between') {
            resultObj.result = rangeData.start_range + ' - ' + rangeData.end_range;
          }
          if (rangeData.range_type === 'less_then') {
            resultObj.result = ' < ' + rangeData.end_range;
          }
          if (rangeData.range_type === 'greater_then') {
            resultObj.result = ' > ' + rangeData.start_range;
          }
          if (rangeData.range_type === 'equal') {
            resultObj.result = ' = ' + rangeData.start_range;
          }
          resultObj.range_type = rangeData.range_type;
          resultObj.start = rangeData.start_range;
          resultObj.end = rangeData.end_range;
          resultObj.critical_range_type = rangeData.critical_range_type;
          resultObj.critical_start_range = rangeData.critical_start_range;
          resultObj.critical_end_range = rangeData.critical_end_range;
          return resultObj;
        }
      }
    }
    return resultObj;
  }

  singleReportRangeType(range, age, gender): any {
    let result;
    for (let i = 0; i < range.length; i++) {
      if (gender === range[i].gender) {
        return range[i].range_type;
      }
    }
    return '';
  }
  submitResult(isDraft): any {
    // for ( var i = 0; i < this.reportType.length; i++ ) {
    //   if ( this.reportType[i].report_type === 'single' && this.reportType[i].report_range === '' ) {
    //     return;
    //   }
    // }
    const reports = [];
    let reportRange = false;
    let criticalPerameters = [];
    $('#pathologistRemarks').css('border', '1px solid #ccc');
    for (let i = 0; i < this.reportType.length; i++) {
      for (let j = 0; j < this.reportType[i].value.length; j++) {
        if (this.reportType[i].value[j].report_type === 'single') {
          if (this.reportType[i].value[j].report_range) {
            if (this.reportType[i].value[j].critical_range_type === 'in_between'){
              if ( (this.convertToNumber(this.reportType[i].value[j]?.report_range) >= this.reportType[i].value[j]?.critical_start_range &&
                this.convertToNumber(this.reportType[i].value[j]?.report_range) <= this.reportType[i].value[j]?.critical_end_range) ){
                criticalPerameters.push(this.reportType[i].value[j].title);
              }
            }
            if (this.reportType[i].value[j].critical_range_type === 'less_then'){
              if ( (this.convertToNumber(this.reportType[i].value[j]?.report_range)  <= this.reportType[i].value[j]?.critical_end_range) ){
                criticalPerameters.push(this.reportType[i].value[j].title);
              }
            }
            if (this.reportType[i].value[j].critical_range_type === 'greater_then'){
              if ( (this.convertToNumber(this.reportType[i].value[j]?.report_range)  >= this.reportType[i].value[j]?.critical_start_range) ){
                criticalPerameters.push(this.reportType[i].value[j].title);
              }
            }
            if (this.reportType[i].value[j].critical_range_type === 'equal'){
              if ( (this.convertToNumber(this.reportType[i].value[j]?.report_range)  === this.reportType[i].value[j]?.critical_start_range) ){
                criticalPerameters.push(this.reportType[i].value[j].title);
              }
            }
          }
        }
        if (isDraft === false) {
          if (this.reportType[i].value[j].report_type === 'single') {
            if (this.reportType[i].value[j].report_range) {
              reportRange = true;
            }
          }
          // For Combo start
          if (this.reportType[i].value[j].report_type === 'combo') {
            if (this.reportType[i].value[j].report_type_1_value) {
              reportRange = true;
            }
          }
          // For Combo end
          // For Text Start
          if (this.reportType[i].value[j].report_type === 'text') {
            if (this.reportType[i].value[j].heading) {
              reportRange = true;
            }
          }
        }
        // For Text End
        // For Editor Start
        if (this.reportType[i].value[j].report_type === 'editor') {
          const reportId = 'textEditor' + i + '' + j;
          if (tinymce.get(reportId).getContent({ format: 'text' }).trim()) {
            this.reportType[i].value[j].heading = tinymce
              .get(reportId)
              .getContent();
            reportRange = true;
          }
        }
        reports.push(this.reportType[i].value[j]);
      }
    }
    console.log('reportRange', reportRange);
    console.log('isDraft', isDraft);
    if (reportRange === false && isDraft === false) {
      toastr.error('Add Results');
      return;
    }
    if (criticalPerameters.length > 0 &&  this.pathologistRemarks === ''  && isDraft === false) {
      $('#pathologistRemarks').css('border', '1px solid #dc3545');
      const errorMessage = 'Critical results have been detected for this patient. '
        + criticalPerameters.toString() + '. Immediate attention and follow-up are required to ensure patient safety. Please review the results, enter remarks and consult the appropriate medical team without delay.'
      toastr.error(errorMessage, 'Critical Alert: Patient Lab Results');
      return;
    }
    this.resultSubmitBtn = true;

    // for ( let i = 0; i < this.reportType.length; i++ ) {
    //   for ( let j = 0; j < this.reportType[i].value.length; j++ ) {
    //     reports.push(this.reportType[i].value[j]);
    //   }
    // }
    this.labTestService
      .submitLabTestResultV2(
        reports,
        this.pathologistRemarks,
        isDraft,
        this.currentPatientData?.patient_mrn,
        this.addReportsCostingDetail.test_cost_details,
      )
      .subscribe(
        (data) => {
          // $('#reportsModal').modal('hide');
          this.modalService.dismissAll(this.reportsModal);
          if (!isDraft) {
            // this.pageDataList[this.itemIndex].status = 'Unverified';
            this.pageDataList[this.itemIndex].status = data.status;
          }
          this.resultSubmitBtn = false;
          this.ngxLoader.stop();
        },
        (err) => {
          this.resultSubmitBtn = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  submitResultUpdate(isDraft): any {
    // for ( var i = 0; i < this.reportType.length; i++ ) {
    //   if ( this.reportType[i].report_type === 'single' && this.reportType[i].range === '' ) {
    //     return;
    //   }
    // }
    const reports = [];
    let updateReport = false;
    let criticalPerameters = [];
    $('#pathologistRemarksUpdate').css('border', '1px solid #ccc');
    for (let i = 0; i < this.reportTypeUpdate.length; i++) {
      for (let j = 0; j < this.reportTypeUpdate[i].value.length; j++) {
        // if ( isDraft === false ) {
        if (this.reportTypeUpdate[i].value[j].report_type === 'single') {
          if (this.reportTypeUpdate[i].value[j].value) {
            console.log('single', this.reportTypeUpdate[i].value[j]);
            updateReport = true;
            if (this.reportTypeUpdate[i].value[j].critical_range_type === 'in_between'){
              if ( (this.convertToNumber(this.reportTypeUpdate[i].value[j]?.value) >= this.reportTypeUpdate[i].value[j]?.critical_start_range &&
                this.convertToNumber(this.reportTypeUpdate[i].value[j]?.value) <= this.reportTypeUpdate[i].value[j]?.critical_end_range) ){
                criticalPerameters.push(this.reportTypeUpdate[i].value[j].caption);
              }
            }
            if (this.reportTypeUpdate[i].value[j].critical_range_type === 'less_then'){
              if ( (this.convertToNumber(this.reportTypeUpdate[i].value[j]?.value)  <= this.reportTypeUpdate[i].value[j]?.critical_end_range) ){
                criticalPerameters.push(this.reportTypeUpdate[i].value[j].caption);
              }
            }
            if (this.reportTypeUpdate[i].value[j].critical_range_type === 'greater_then'){
              if ( (this.convertToNumber(this.reportTypeUpdate[i].value[j]?.value)  >= this.reportTypeUpdate[i].value[j]?.critical_start_range) ){
                criticalPerameters.push(this.reportTypeUpdate[i].value[j].caption);
              }
            }
            if (this.reportTypeUpdate[i].value[j].critical_range_type === 'equal'){
              if ( (this.convertToNumber(this.reportTypeUpdate[i].value[j]?.value)  === this.reportTypeUpdate[i].value[j]?.critical_start_range) ){
                criticalPerameters.push(this.reportTypeUpdate[i].value[j].caption);
              }
            }
          }
        }
        // For Combo start
        if (this.reportTypeUpdate[i].value[j].report_type === 'combo') {
          if (this.reportTypeUpdate[i].value[j].report_type_1_value) {
            updateReport = true;
          }
        }
        // For Text Start
        if (this.reportTypeUpdate[i].value[j].report_type === 'text') {
          if (this.reportTypeUpdate[i].value[j].heading) {
            updateReport = true;
          }
        }
        // }
        // For Editor Start
        if (this.reportTypeUpdate[i].value[j].report_type === 'editor') {
          const reportId = 'textEditorUpdate' + i + '' + j;
          if (tinymce.get(reportId).getContent({ format: 'text' }).trim()) {
            this.reportTypeUpdate[i].value[j].heading = tinymce
              .get(reportId)
              .getContent();
            updateReport = true;
          }
        }
        reports.push(this.reportTypeUpdate[i].value[j]);
      }
    }
    console.log('isDraft', isDraft);
    if (updateReport === false) {
      toastr.error('Add Results');
      return;
    }
    if (criticalPerameters.length > 0 &&  this.pathologistRemarks === '') {
      $('#pathologistRemarksUpdate').css('border', '1px solid #dc3545');
      const errorMessage = 'Critical results have been detected for this patient. '
        + criticalPerameters.toString() + '. Immediate attention and follow-up are required to ensure patient safety. Please review the results, enter remarks and consult the appropriate medical team without delay.'
      toastr.error(errorMessage, 'Critical Alert: Patient Lab Results');
      return;
    }
    this.resultSubmitBtn = true;
    this.ngxLoader.start();
    this.labTestService
      .submitLabTestResultV2(
        reports,
        this.pathologistRemarks,
        isDraft,
        this.currentPatientData.patient_mrn,
        this.addReportsCostingDetail.test_cost_details
      )
      .subscribe(
        (data) => {
          // $('#reportsModalUpdate').modal('hide');
          // this.modalService.dismissAll(this.reportsModalUpdate);
          this.modalService.dismissAll(this.reportsModal);
          // this.searchInvoices();
          if (!isDraft) {
            // this.pageDataList[this.itemIndex].status = 'Unverified';
            this.pageDataList[this.itemIndex].status = data.status;
          }
          this.resultSubmitBtn = false;
          this.ngxLoader.stop();
        },
        (err) => {
          this.resultSubmitBtn = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  // fetchLabConfigurators(): any {
  //   this.ngxLoader.start();
  //   this.labService.getLabConfigurators().subscribe(
  //     data => {
  //       console.log(data);
  //       this.labConfigurations = data.lab_configurations;
  //       this.ngxLoader.stop();
  //     },
  //     err => {
  //       this.ngxLoader.stop();
  //       toastr.error(err.error.message);
  //     }
  //   );
  // }
  getCultureList(): any {
    this.ngxLoader.start();
    this.cultureConfigService.indexV2().subscribe(
      (data) => {
        this.cultureList = data.culture_configurations;
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }
  getCultureSensitivityList(): any {
    this.ngxLoader.start();
    this.cultureConfigService.indexByTypeV2('sensitivity_type').subscribe(
      (data) => {
        this.cultureListSensitivity = data.culture_configurations;
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }

  addFieldsInCultureWithResistance(): any {
    this.reports_culture.push(this.setReportsCulture());
    this.reports_comment.push(this.setReportsReportsComments());
    setTimeout(() => {
      for (let i = 0; i < this.reports_sensitivity.length; i++) {
        const control = this.reports_sensitivity.controls[i].get(
          'sensitivity_resistance',
        ) as UntypedFormArray;
        control.push(this.setReportsSensitivityResistance());
      }
    }, 200);
    // this.addFieldsInCulture('reports_comment');
  }
  addFieldsInCulture(type): any {
    if (type === 'reports_comment') {
      // this.reports_comment.push(this.setReportsReportsComments());
    }
    if (type === 'sensitivity') {
      this.disableBtn = true;
      this.reports_sensitivity.push(this.setReportsSensitivity());
      setTimeout(() => {
        for (let i = 0; i < this.reports_culture.length; i++) {
          const control = this.reports_sensitivity.controls[
            this.reports_sensitivity.length - 1
          ].get('sensitivity_resistance') as UntypedFormArray;
          control.push(this.setReportsSensitivityResistance());
          // this.addFieldsInCulture('reports_comment');
        }
      }, 200);
      setTimeout(() => {
        this.disableBtn = false;
      }, 500);
    }
    if (type === 'culture_plate') {
      this.reports_culture_plate.push(this.setReportsCulturePlates());
    }
    if (type === 'free_field') {
      this.reports_free_field.push(this.setReportsFreeFields());
    }
    if (type === 'gram_stain') {
      this.reports_gram_stain.push(this.setReportsGramStain());
    }
    if (type === 'zn_stain') {
      this.reports_zn_stain.push(this.setReportsZnStain());
    }
    if (type === 'fungal_stain') {
      this.reports_fungal_stain.push(this.setReportsFungalStain());
    }
    if (type === 'final_report') {
      this.reports_final_report.push(this.setReportsFinalReport());
    }
    if (type === 'microscopy') {
      this.reports_microscopy.push(this.setReportsMicroscopy());
    }
    if (type === 'culture') {
      this.reports_culture.push(this.setReportsCulture());
    }
    if (type === 'specimen') {
      if (this.reports_specimen.length === 0) {
        this.reports_specimen.push(this.setReportsSpecimen());
      }
    }
  }
  setReportsCulturePlates(): any {
    return this.formBuilder.group({
      plate: ['', Validators.required],
      what: ['', Validators.required],
      quantity: ['', Validators.required],
    });
  }
  setReportsFreeFields(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }
  setReportsGramStain(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: [''],
    });
  }
  setReportsZnStain(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: [''],
    });
  }
  setReportsFungalStain(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: [''],
    });
  }
  setReportsFinalReport(): any {
    return this.formBuilder.group({
      type: ['', Validators.required],
      medicine: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }
  setReportsMicroscopy(): any {
    return this.formBuilder.group({
      type: ['', Validators.required],
      result: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }
  setReportsSensitivity(): any {
    return this.formBuilder.group({
      type: ['', Validators.required],
      group: ['', Validators.required],
      sensitivity_resistance: this.formBuilder.array([]),
    });
  }
  setReportsSensitivityResistance(): any {
    return this.formBuilder.group({
      type: [''],
    });
  }
  setReportsReportsComments(): any {
    return this.formBuilder.group({
      heading: [''],
      comments: [''],
    });
  }
  setReportsCulture(): any {
    return this.formBuilder.group({
      growth: ['', Validators.required],
      culture: ['', Validators.required],
      duration_type: ['Minutes', Validators.required],
      duration_value: ['', Validators.required],
      temperature: ['', Validators.required],
      colony_count: ['', Validators.required],
      atmosphere: ['', Validators.required],
      comments: [''],
      sensitivity: this.formBuilder.array([]),
      reports_comment: this.formBuilder.array([]),
    });
  }

  addReportsCulturePlates(data): any {
    return this.formBuilder.group({
      plate: [data.plate, Validators.required],
      what: [data.what, Validators.required],
      quantity: [data.quantity, Validators.required],
    });
  }
  addReportsFreeFields(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsGramStain(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsZnStain(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsFungalStain(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsFinalReport(data): any {
    return this.formBuilder.group({
      type: [data.type, Validators.required],
      medicine: [data.medicine, Validators.required],
      comments: [data.comments, Validators.required],
    });
  }
  addReportsMicroscopy(data): any {
    return this.formBuilder.group({
      type: [data.type, Validators.required],
      result: [data.result, Validators.required],
      comments: [data.comments, Validators.required],
    });
  }
  addReportsSensitivity(data): any {
    return this.formBuilder.group({
      type: [data.type, Validators.required],
      group: [data.group, Validators.required],
      sensitivity_resistance: this.formBuilder.array([]),
    });
  }
  addReportsSensitivityDefault(type, group): any {
    return this.formBuilder.group({
      type: [type, Validators.required],
      group: [group || '', Validators.required],
      sensitivity_resistance: this.formBuilder.array([]),
    });
  }

  addReportsSensitivityResistance(data): any {
    return this.formBuilder.group({
      type: [data.type],
    });
  }
  addReportsReportsComments(data): any {
    return this.formBuilder.group({
      heading: [data.heading],
      comments: [data.comments],
    });
  }
  addReportsCulture(data): any {
    return this.formBuilder.group({
      growth: [data.growth, Validators.required],
      culture: [data.culture, Validators.required],
      duration_type: [data.duration_type || 'Minutes', Validators.required],
      duration_value: [data.duration_value, Validators.required],
      temperature: [data.temperature, Validators.required],
      colony_count: [data.colony_count, Validators.required],
      atmosphere: [data.atmosphere, Validators.required],
      comments: [data.comments],
      sensitivity: this.formBuilder.array([]),
      reports_comment: this.formBuilder.array([]),
    });
  }
  addReportsSpecimen(data): any {
    return this.formBuilder.group({
      name_id: [data.name_id, Validators.required],
      value: [data.value],
    });
  }

  setReportsSpecimen(): any {
    return this.formBuilder.group({
      name_id: [this.activeLabEntry.specimen_id || '', Validators.required],
      value: [''],
    });
  }
  removeCultureIndexWithResistance(ind): any {
    for (let i = 0; i < this.reports_sensitivity.length; i++) {
      const control = this.reports_sensitivity.controls[i].get(
        'sensitivity_resistance',
      ) as UntypedFormArray;
      control.removeAt(ind);
    }
    this.reports_culture.removeAt(ind);
    this.reports_comment.removeAt(ind);
  }
  removeCultureIndex(type, ind): any {
    if (type === 'culture_plate') {
      this.reports_culture_plate.removeAt(ind);
    }
    if (type === 'free_field') {
      this.reports_free_field.removeAt(ind);
    }
    if (type === 'gram_stain') {
      this.reports_gram_stain.removeAt(ind);
    }
    if (type === 'zn_stain') {
      this.reports_zn_stain.removeAt(ind);
    }
    if (type === 'fungal_stain') {
      this.reports_fungal_stain.removeAt(ind);
    }
    if (type === 'final_report') {
      this.reports_final_report.removeAt(ind);
    }
    if (type === 'microscopy') {
      this.reports_microscopy.removeAt(ind);
    }
    if (type === 'culture') {
      // this.reports_culture.removeAt(ind);
    }
    if (type === 'specimen') {
      this.reports_specimen.removeAt(ind);
    }
    if (type === 'sensitivity') {
      this.reports_sensitivity.removeAt(ind);
      this.cultureGroupArray.splice(ind, 1);
    }
    if (type === 'reports_comment') {
      // this.reports_comment.removeAt(ind);
    }
  }
  clearAllCultureForm(): any {
    this.cultureReportSubmitted = false;
    while (this.reports_culture_plate.length) {
      this.reports_culture_plate.removeAt(0);
    }
    while (this.reports_free_field.length) {
      this.reports_free_field.removeAt(0);
    }
    while (this.reports_gram_stain.length) {
      this.reports_gram_stain.removeAt(0);
    }
    while (this.reports_zn_stain.length) {
      this.reports_zn_stain.removeAt(0);
    }
    while (this.reports_fungal_stain.length) {
      this.reports_fungal_stain.removeAt(0);
    }
    while (this.reports_final_report.length) {
      this.reports_final_report.removeAt(0);
    }
    while (this.reports_microscopy.length) {
      this.reports_microscopy.removeAt(0);
    }
    while (this.reports_culture.length) {
      this.reports_culture.removeAt(0);
    }
    while (this.reports_specimen.length) {
      this.reports_specimen.removeAt(0);
    }
    while (this.reports_sensitivity.length) {
      this.reports_sensitivity.removeAt(0);
    }
    while (this.reports_comment.length) {
      this.reports_comment.removeAt(0);
    }
    this.cultureGroupArray = [];
  }
  updateFreeHeadingText(objId, valueFrom, i): any {
    let comment = '';
    if (this.cultureList.find((x) => x.id === Number(objId))) {
      comment = this.cultureList.find((x) => x.id === Number(objId)).comments;
    }
    if (valueFrom === 'free_field') {
      this.reports_free_field.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'gram_stain') {
      this.reports_gram_stain.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'zn_stain') {
      this.reports_zn_stain.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'fungal_stain') {
      this.reports_fungal_stain.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'reports_comment') {
      this.reports_comment.at(i).patchValue({ comments: comment });
    }
  }
  submitCultureReport(isDraft): any {
    console.log(this.cultureReportsForm.value);
    this.cultureReportSubmitted = true;
    if (this.cultureReportsForm.invalid) {
      return;
    }
    this.resultSubmitBtn = true;
    console.log('form submit');
    this.ngxLoader.start();
    const reports = [];
    reports.push(this.cultureReportsForm.value);
    this.labTestService
      .submitLabTestResultV2(
        reports,
        $('#pathologistRemarks').val(),
        isDraft,
        this.currentPatientData.patient_mrn,
        this.addReportsCostingDetail.test_cost_details
      )
      .subscribe(
        (data) => {
          this.cultureReportSubmitted = false;
          // $('#cultureReportsModal').modal('hide');
          // this.modalService.dismissAll(this.cultureReportsModal);
          this.modalService.dismissAll(this.reportsModal);
          // this.searchInvoices();
          if (!isDraft) {
            // this.pageDataList[this.itemIndex].status = 'Unverified';
            this.pageDataList[this.itemIndex].status = data.status;
          }
          this.resultSubmitBtn = false;
          this.ngxLoader.stop();
        },
        (err) => {
          this.resultSubmitBtn = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  verifyReport(id, Pmrn, index): any {
    this.mrn = Pmrn;
    this.ngxLoader.start();
    this.labTestService.approvedUnVerifiedLabTestV2(id, Pmrn).subscribe(
      (data) => {
        toastr.success('Report verified');
        // this.searchInvoices();
        this.pageDataList[index].status = 'Verified';
        if (this.hospitalName === 'farooqHospital') {
          this.verifyapproval(this.mrn);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  verifyapproval(mrn): any {
    this.labTestService.approvedUnVerifiedLab(mrn).subscribe(
      (data) => {},
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  isNumberKey(e): any {
    const charCode = e.which ? e.which : e.keyCode;

    if (e.target.value.includes('.')) {
      if (e.key === '.') {
        return false;
      }
    }

    if (
      charCode !== 190 &&
      charCode !== 96 &&
      charCode !== 97 &&
      charCode !== 98 &&
      charCode !== 99 &&
      charCode !== 100 &&
      charCode !== 101 &&
      charCode !== 102 &&
      charCode !== 103 &&
      charCode !== 104 &&
      charCode !== 105 &&
      charCode !== 46 &&
      charCode !== 110 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    ) {
      return false;
    }
    return true;
  }

  _onlyNumericValue(event: any): any {
    const pattern = /[0-9 .]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  getLisIntegration(lab_id, testCode): any {
    this.ngxLoader.start();
    this.patientService
      .getLisIntegration(this.settingData?.external_lis_base_url, lab_id)
      .subscribe(
        (data) => {
          if (data.length > 0) {
            const serviceObj = data.find((m) => m.testcode === testCode);
            if (serviceObj) {
              window.open(serviceObj?.url);
            } else {
              toastr.error('Report not finalized');
            }
          } else {
            toastr.error('Report not finalized');
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error('Something Went Wrong');
        },
      );
  }
  afterAddPCR(event: any): any {
    this.modalService.dismissAll(this.reportsModal);
    if (!event.isDraft) {
      this.pageDataList[this.itemIndex].status =
        event?.data?.status || 'Unverified';
    }
  }
  closeModalPcr(): any {
    this.modalService.dismissAll(this.reportsModal);
  }
  openLogsReports(): any {
    const fromDate = moment($('#fromDate').val(), this.dateFormat).format('DD-MM-YYYY') || '';
    const toDate = moment($('#toDate').val(), this.dateFormat).format('DD-MM-YYYY') || '';
    console.log('fromDate', fromDate);
    console.log('toDate', toDate);
    window.open(
      'lab/reports/tracking-v2?from_date=' +
      fromDate +
        '&to_date=' +
      toDate +
        '&department_id=' +
        this.labServicesForm.value.department_id +
        '&patient_type=' +
        this.labServicesForm.value.patient_type +
        '&lab_status=' +
        this.labServicesForm.value.lab_status +
        '&search_type=' +
        this.labServicesForm.value.search_type +
        '&search_name=' +
        this.labServicesForm.value.search_name,
    );
  }
  getSmsControls(): any {
    this.labTestService.getSmsData().subscribe(
      (data) => {
        this.smsControls = data.sms_configuration[0];
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  openWhatsApp(data) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let orgName = '';
    if (currentUser) {
      orgName = currentUser.medical_units[0].title;
    }
    let phoneNo = data.patient_phone;
    if (phoneNo.startsWith('03')) {
      phoneNo = phoneNo.replace(/^0/, '92');
    }
    const url = window.location.origin + '/online-lab-report-v2';
    const phoneNumber = phoneNo;
    let message = this.smsControls.lab_verification_sms_body;
    const placeholders = {
      '@p.name': data.patient_name,
      '@MRN': data.patient_mrn,
      '@rpt.url': url,
      '@org': orgName,
      '@inv.num': data.invoice_id,
      '@passcode': data.passcode,
    };
    for (const [placeholder, value] of Object.entries(placeholders)) {
      if (message.includes(placeholder)) {
        message = message.replace(placeholder, value);
      }
    }
    const encodedMessage = encodeURIComponent(message);
    const whatsAppUrl =
      'http://api.whatsapp.com/send?phone=' +
      phoneNumber +
      '&text=' +
      encodedMessage;
    window.open(whatsAppUrl);
  }

  preventChangeDate(): any {
    return false;
  }
}
