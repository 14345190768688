import { Component, OnInit } from '@angular/core';
import { any } from 'codelyzer/util/function';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { formatDate } from '@angular/common';
import { RouteShareService } from '../../../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-refresh-attendance',
  templateUrl: './refresh-attendance.component.html',
  styleUrls: ['./refresh-attendance.component.css'],
})
export class RefreshAttendanceComponent implements OnInit {
  today = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  depart: any;
  employeesOfDepart: any;
  employeesOfSelectedDepartment: any;
  department: any;
  startDate: any;
  endDate: any;
  screenName = '';

  constructor(
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private rosterServiceService: RosterServiceService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getEmployeesOfDepartment(id): any {
    this.employeesOfDepart = [];
    this.configurationService.getEmployeesOfSpecificDepartment(id).subscribe(
      (data) => {
        this.employeesOfDepart = data;
        setTimeout((e) => {
          $('#employeeDepartmentList').selectpicker('refresh');
          $('#employeeDepartmentList').selectpicker();
        }, 500);
      },
      (err) => {
        setTimeout((e) => {
          $('#employeeDepartmentList').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getDate(): any {
    $('.start_date').datepicker({
      format: 'dd-mm-yyyy',
      orientation: 'bottom left',
    });
    $('.end_date').datepicker({
      format: 'dd-mm-yyyy',
      orientation: 'bottom left',
    });
  }

  refreshAttendance(): any {
    this.startDate = $('.start_date').val();
    this.endDate = $('.end_date').val();
    if (!this.startDate) {
      toastr.error('select Start Date');
      return;
    }
    if (!this.endDate) {
      toastr.error('select End Date');
      return;
    }
    if (!this.department) {
      toastr.error('select Department');
      return;
    }
    // if (!this.employeesOfSelectedDepartment) {
    //     toastr.error('select employee');
    //     return;
    // }
    this.ngxLoader.start();
    this.rosterServiceService
      .refreshAttendance(
        this.startDate,
        this.endDate,
        this.department,
        this.employeesOfSelectedDepartment,
      )
      .subscribe(
        (data) => {
          toastr.success('submit Successfully');
          this.ngxLoader.stop();
          this.startDate = '';
          this.endDate = '';
          this.department = '';
          this.employeesOfSelectedDepartment = '';
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
            $('#employeeDepartmentList').selectpicker('refresh');
          }, 500);
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  ngOnInit(): void {
    this.getDate();
    this.getDepartments();
  }
}
