import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Validators } from '@angular/forms';

@Injectable()
export class PricingService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/pricings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  show(id: number): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/pricings/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  search(pricing): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/pricings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          pricing.patient_type_id +
          '&billing_code=' +
          pricing.billing_code +
          '&department_id=' +
          pricing.department_id +
          '&parent_department_id=' +
          pricing.parent_department_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchV2(pricing): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/pricings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          pricing.patient_type_id +
          '&department_id=' +
          pricing.department_id +
          '&billing_code=' +
          pricing.billing_code +
          '&parent_department_id=' +
          pricing.parent_department_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  priceSearchV3(pricing, page, perPage): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/optimize_pricing_list/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&patient_type_id=' +
          pricing.patient_type_id +
          '&service_type=' +
          pricing.service_type +
          '&department_id=' +
          pricing.department_id +
          '&billing_code=' +
          pricing.billing_code +
          '&sub_department_id=' +
          pricing.sub_department_id +
          '&service_name=' +
          pricing.service_name,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  update(pricing): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/pricings/update_pricings_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          pricing: pricing,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateV2(pricing): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/update_pricings_list/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          pricing: pricing,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePricingV3(pricing): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/v3/update_pricing/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          pricing: pricing,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPrice(pricing): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/pricings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          pricing.patient_type_id +
          '&billing_code=' +
          pricing.current_billing_code,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPriceV3(pricing): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/doctor_fee/get_pricing_list/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          pricing.patient_type_id +
          '&billing_code=' +
          pricing.current_billing_code,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPriceV2(pricing): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/pricings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          pricing.patient_type_id +
          '&billing_code=' +
          pricing.current_billing_code,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPriceAgainst(patient, billing_code): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/pricings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          patient.patient_type_id +
          '&billing_code=' +
          billing_code,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPriceAgainstV2(patient, billing_code): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/pricings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          patient.patient_type_id +
          '&billing_code=' +
          billing_code,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPriceByMultipleServices(patient, billing_code): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/pricings/pricing_against_multiple_billing_codes?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          patient.patient_type_id +
          '&billing_code=' +
          billing_code,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatedNewPricesV2(pricing): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/v2/update_pricing/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_type_id: pricing.patient_type_id,
          parameter_type: pricing.parameter_type,
          action: pricing.action,
          category: pricing.category,
          department: pricing.department,
          sub_department: pricing.sub_department,
          value: pricing.value,
          type: pricing.type,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  copyPrice(pricing): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/pricings/copy_pricings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          pricing.patient_type_id +
          '&billing_code=' +
          pricing.billing_code +
          '&department_id=' +
          pricing.department_id +
          '&parent_department_id=' +
          pricing.parent_department_id +
          '&copy_patient_type_id=' +
          pricing.copy_patient_type_id,
        {
          pricing: pricing,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  copyPriceV2(pricing): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/copy_pricings/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          pricing.patient_type_id +
          '&billing_code=' +
          pricing.billing_code +
          '&department_id=' +
          pricing.department_id +
          '&parent_department_id=' +
          pricing.parent_department_id +
          '&copy_patient_type_id=' +
          pricing.copy_patient_type_id,
        {
          pricing: pricing,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  copyPriceV3(pricing): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/v2/copy_pricing/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          pricing: pricing,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateSystemDiscount(pricing): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/pricings/update_system_discount_percentage?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          pricing: pricing,
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateSystemDiscountV2(pricing): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/update_system_discount_percentage/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          pricing: pricing,
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // get pricing share list
  searchPricingShares(currentPage: any, perPage: any, value): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/shares?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          value.patient_type_id +
          '&department_id=' +
          value.department_id +
          '&sub_department_id=' +
          value.sub_department_id +
          '&service_id=' +
          value.service_id +
          '&service_type=' +
          value.service_type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  // update pricing shares
  updatePricingShares(value): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/shares/update_shares_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          share: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
