import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class HospitalService {
  constructor(private http: HttpClient) {}

  index(region_id): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v1/medical_units?auth_token=` +
          localStorage.getItem('auth_token') +
          '&region_id=' +
          region_id +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getFooter(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/signature?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getMedicalUnitDetails(): Observable<any> {
    let medicalunit = localStorage.getItem('current_medical_unit_id') || 152;
    let token = localStorage.getItem('auth_token') || '';
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/get_medical_unit/?auth_token=` +
          token +
          '&medical_unit_id=' +
          medicalunit,
        {
          headers: new HttpHeaders({
            AuthenticationToken: token,
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getMedicalUnitDetailsv2(): Observable<any> {
    let medicalunit = localStorage.getItem('current_medical_unit_id') || 152;
    let token = localStorage.getItem('auth_token') || '';
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/get_medical_unit/?auth_token=` +
          token +
          '&medical_unit_id=' +
          medicalunit,
        {
          headers: new HttpHeaders({
            AuthenticationToken: token,
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getMedicalUnitHomeImage(subDomain): Observable<any> {
    console.log('in');
    let results = this.http
      .get(
        environment.rorUrl +
          '/v4/medical_units/get_homepage_image?org_sub_domain=' +
          subDomain,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getMedicalUnitHomeImageV2(subDomain): Observable<any> {
    let results = this.http
      .get(
        environment.pythonUrl +
          '/api/new_session/get_homepage_image/?org_sub_domain=' +
          subDomain,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateMedicalUnitDetails(medicalData): Observable<any> {
    let results = this.http
      .post(
        environment.rorUrl +
          '/v4/medical_units/update_medical_unit/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          address: medicalData.address,
          title: medicalData.title,
          logo: medicalData.logo,
          website_url: medicalData.website_url,
          email: medicalData.email,
          phone_number: medicalData.phone_number,
          fax: medicalData.fax,
          homepage_image: medicalData.homepage_image,
          homepage_image_top: medicalData.homepage_image_top,
          organization_name_for_login_page:
            medicalData.organization_name_for_login_page,
          organization_image_for_login_page:
            medicalData.organization_image_for_login_page,
          employee_card_image: medicalData.employee_card_image,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateMedicalUnitDetailsv2(medicalData): Observable<any> {
    let results = this.http
      .post(
        environment.pythonUrl +
          '/api/update_medical_unit/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          address: medicalData.address,
          title: medicalData.title,
          logo: medicalData.logo,
          website_url: medicalData.website_url,
          email: medicalData.email,
          phone_number: medicalData.phone_number,
          fax: medicalData.fax,
          homepage_image: medicalData.homepage_image,
          homepage_image_top: medicalData.homepage_image_top,
          organization_name_for_login_page:
            medicalData.organization_name_for_login_page,
          organization_image_for_login_page:
            medicalData.organization_image_for_login_page,
          employee_card_image: medicalData.employee_card_image,
          date_format: medicalData.date_format,
          time_format: medicalData.time_format,
          date_time_format: medicalData.date_time_format,
          currency_type: medicalData.currency_type,
          currency_format: medicalData.currency_format,
          time_zone: medicalData.time_zone,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLedgers(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/configurator_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python Start
  getLedgersV2(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/configurator_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python End

  update(ledger_id: number, id: number): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/sales/` +
          id +
          `/update_ledger/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          ledger_id: ledger_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateDoctor(
    serviceId: number,
    performingId: number,
    referringId: number,
  ): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/patient_service_invoice_items/update_doctor_id?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          id: serviceId,
          performing_doctor_id: performingId,
          referring_doctor_id: referringId,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getExpiryModuleOfHospital(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/notify_pending_dues?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createScreenLogs(screenUrl, userAgent): Observable<any> {
    const results = this.http.post(
        `${environment.pythonUrl}/api/session_log/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          page_url: screenUrl,
          user_agent: userAgent,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
