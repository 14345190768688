import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class EmployeeVisitService {
  constructor(private http: HttpClient) {}

  submitVisitForm(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/visiting_employee_configuration?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getStoreListing(page, perPage): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/visiting_employee_configuration?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateVisitForm(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/visiting_employee_configuration?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificEmployee(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/visiting_employee_configuration?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
