<div>
  <form
    *ngIf="isAdd === true"
    [formGroup]="reportsObject"
    (ngSubmit)="uploadImage()"
  >
    <div class="card mb-4">
      <div class="card-body user-card-body">
        <h4>Documents</h4>
        <!--                  formControlName="file"   multiple=""-->
        <div class="row mt-2">
          <div class="col-4">
            <label>Document Type</label>
            <select
              class="form-control form-control-sm"
              formControlName="docType"
            >
              <option value="">Select</option>
              <option *ngFor="let data of docTypes" [value]="data.id">
                {{ data?.name }}
              </option>
            </select>
          </div>
          <div class="col-4">
            <label>Document Name</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="document_name"
            />
            <!--                      <select class="form-control form-control-sm" [(ngModel)]="docType">-->
            <!--                        <option value="">Select</option>-->
            <!--                        <option value=""> </option>-->
            <!--                      </select>-->
          </div>
          <div class="col-4">
            <div class="form-group">
              <div class="">
                <input
                  id="file"
                  multiple="multiple"
                  type="file"
                  hidden
                  class="form-control form-control-sm"
                  accept="image/*, .png, .jpg, .jpeg, .pdf"
                  (change)="onFileChange($event)"
                />
                <label class="labled" for="file"
                  ><img
                    src="assets/icons/layers3.png"
                    style="height: 20px; width: 20px"
                  />
                  Choose Files</label
                >
              </div>
            </div>
          </div>
          <div>
            <ng-container *ngFor="let doc of Image; let i = index">
              <div class="d-flex justify-content-between">
                <div class="ml-5">{{ doc.name }}</div>
                <div>
                  <button
                    class="btn btn-sm mb-2 p-0 mr-5"
                    type="button"
                    (click)="removeFileObject(i)"
                  >
                    <i class="icon text-hover-danger fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-12-lg">
          <div class="form-group">
            <label>Description</label>
            <div
              class="summernote"
              id="textareas"
              formControlName="text_areas"
            ></div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col">
            <button
              class="btn btn-primary btn-sm"
              type="submit"
              [disabled]="disableSubmitButton"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="card mb-4" *ngIf="!isAdd || patientDocs.length > 0">
    <div class="card-body user-card-body">
      <div class="row">
        <div class="col-4 mb-8" *ngFor="let img of patientDocs; let i = index">
          <div
            class="patient-docs"
            style="border: 1px solid #b7b5b5"
            [style.background-image]="'url(' + img.file.url + ')'"
            (click)="openDoc(img?.file?.url, i, img?.id)"
          ></div>
          <div class="mt-3">
            <div class="ml-0 mt-3 row">
              <h5>File Name:</h5>
              <p class="ml-1">{{ img?.name }}</p>
            </div>
            <a
              class="cursor-pointer"
              style="color: #3699ff"
              (click)="openDetailModal(img?.id)"
              >Details</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--patient image modal-->
<div class="example-preview">
  <div>
    <ng-template #patientImageModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Document Files</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click'); closePatImgModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div
            id="demo"
            class="carousel slide patDocModal"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <ng-container *ngFor="let img of patientDocs; let i = index">
                <div
                  class="carousel-item"
                  [ngClass]="{ active: i === currentImageIndex }"
                >
                  <img
                    [src]="img?.file?.url"
                    alt="Paper with Data"
                    width="100%"
                    height="450"
                    style="min-height: 400px"
                  />
                  <div class="row mt-3 ml-0">
                    <h5 class="mr-1">File Name:</h5>
                    <p style="margin-left: 2px">{{ img?.name }}</p>
                  </div>
                  <div class="row ml-0">
                    <a
                      class="btn btn-primary"
                      href="{{ img?.file?.url }}"
                      download
                      >Download</a
                    >
                    <a
                      class="btn btn-primary cursor-pointer ml-2"
                      (click)="openDetailModal(img?.id)"
                      >Details</a
                    >
                  </div>
                </div>
              </ng-container>
            </div>

            <!-- Left and right controls -->
            <a
              class="carousel-control-prev marginleft"
              href="#demo"
              data-slide="prev"
            >
              <span class="carousel-control-prev-icon arrow-buton"></span>
            </a>
            <a
              class="carousel-control-next marginleft"
              href="#demo"
              data-slide="next"
            >
              <span class="carousel-control-next-icon arrow-buton"></span>
            </a>
          </div>
          <!--          <div class="mt-3" *ngFor="let img of patientDocs">-->

          <!--          </div>-->
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click'); closePatImgModal()"
          >
            Close
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--patient detail modal-->
<div
  class="modal fade"
  id="detailModal"
  tabindex="-1"
  style="z-index: 1051 !important"
  role="dialog"
  aria-labelledby="ModalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ModalTitle">Patient Details</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <div class="row">
            <label class="font-weight-bold ml-4 mr-1">File Name:</label>
            <span class="ml-1">{{ imageDetail?.name }}</span>
          </div>
          <div class="row">
            <label class="font-weight-bold ml-4 mr-1">File Type:</label>
            <span class="ml-1">{{ imageDetail?.document_type }}</span>
          </div>
          <div class="row">
            <label class="font-weight-bold ml-4 mr-1">Description:</label>
            <span [innerHTML]="imageDetail?.description"></span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
