import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class FinanceControlsService {
  constructor(private http: HttpClient) {}

  getFinanceControls(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/finance_system_control/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addFinanceControl(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/finance_system_control/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }

  getLineItemCoaVerificaiton(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/inventory/line_item_coa_verification?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&auth_token=' +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
}
