import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RouteShareService } from '../../../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-system-controls',
  templateUrl: './system-controls.component.html',
  styleUrls: ['./system-controls.component.css'],
})
export class SystemControlsComponent implements OnInit {
  rosterControls: UntypedFormGroup;
  screenName = '';
  constructor(
    private fb: UntypedFormBuilder,
    private rostarService: RosterServiceService,
    private ngxLoader: NgxUiLoaderService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();

    this.rosterControls = this.fb.group({
      show_leaves: ['in-process'],
      copy_roster: ['replace'],
      leave_shift: ['leave'],
    });
  }
  onSubmit = () => {
    if (this.rosterControls.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.rostarService
      .submitSystemControls(this.rosterControls.value)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  };
  getSystemControls = () => {
    this.ngxLoader.start();
    this.rostarService.getSystemControls().subscribe(
      (data) => {
        this.rosterControls.patchValue({
          show_leaves: data.show_leaves ?? 'in-process',
          copy_roster: data.copy_roster ?? 'replace',
          leave_shift: data.leave_shift ?? 'leave',
        });
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  };
  ngOnInit(): void {
    this.getSystemControls();
  }
}
