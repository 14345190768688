import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MedicineConfigService } from '../../../../services/medicine-config.service';
import { formatDate } from '@angular/common';
import { StockService } from '../../../../services/stock.service';
import * as moment from 'moment/moment';
import { OpdService } from '../../../../services/opd.service';
import { duration } from 'moment/moment';
import { HospitaldoctorService } from '../../../../services/hospitaldoctor.service';
import { ConfigurationService } from '../../../../services/configuration.service';

declare var $: any;
declare var toastr: any;
declare var Chart: any;

@Component({
  selector: 'app-medicines',
  templateUrl: './medicines.component.html',
  styleUrls: ['./medicines.component.css'],
})
export class MedicinesComponent implements OnInit {
  @Input() patient: any;
  @Input() visitType: any;
  @Input() type: any;
  @Input() isAdd: boolean;
  @Input() isipd: boolean;
  @Input() isOpd: boolean;
  @Input() isMedicinesUpdate: number;
  @Output() isMedicinesRecieved = new EventEmitter<string>();

  medicineAdministration: any;
  forFrequency = true;
  isConsume = false;
  todayMedDate = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  addMedicine = [];
  doseList: any = [];
  doseTypeList: any = [];
  frequencyList: any = [];
  frequency: any = [];
  allActiveEmployees = [];
  clearTimeout: any;
  productListData = [];
  productSearchResult = false;
  routeList: any = [];
  patientAllHistory = false;
  paitentMedicineHistory = [];
  servicesPrintRecent = true;
  userPassword = '';
  isSubmit = false;
  isConfimed = false;
  intervalId: any;
  isDisable = false;
  doses = [];
  singleMedicineId: any;
  advisedBy = '';
  employeeType = '';
  doseType = '';
  medicine = '';
  frequencyy = '';
  dose: any;
  dose_other_quantity = '';
  routee = '';
  days = '';
  fromDate = '';
  toDate = '';
  pharmacistComments = '';
  doctorComments = '';
  isConfirmedCancel = false;
  cancelId: any;
  isIpdMedicineConfigurator: any;
  isOpdMedicineConfigurator: any;
  rejectionReason: any;
  returnMedicineId: any;
  returnMedicineName = '';
  returnQty = 0;
  qtyToReturn = 0;
  getDiscontinuedList = false;
  dosesList = [];
  logsData = [];
  medicineId = [];
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private medicineConfigService: MedicineConfigService,
    private stockService: StockService,
    private chRef: ChangeDetectorRef,
    private opdService: OpdService,
    private hospitalDoctorsService: HospitaldoctorService,
    private configService: ConfigurationService,
  ) {}

  ngOnInit(): void {
    this.getDoseList('dose');
    this.getDoseTypeList('dose_type');
    this.getFrequencyList('frequency');
    this.getRouteList('route');
    this.getMedicineHistory('');
    this.setDatesOfMedicines('');
    this.allActiveEmployeesList();
    if (this.isAdd === true) {
      this.updateServicesPrintRecent('true');
    }
    if (this.isipd) {
      this.getShowMedicinesInIpd();
    }
    if (this.isOpd) {
      this.getShowMedicinesInOpd();
    }
  }
  ngOnChanges(changes: SimpleChanges): any {
    this.getMedicineHistory(changes.patient.currentValue);
    this.setDatesOfMedicines(changes.patient.currentValue);
    if (this.isAdd === true){
      this.getMedicineAdministration(changes.patient.currentValue);
    }
  }

  getSubmitDiscontinuedforListing(event): any {
    this.getDiscontinuedList = event;
  }

  getMedicineAdministration(val): any {
    this.ngxLoader.start();
    this.medicineConfigService
      .getmedicineAdministration(
        this.patient?.id,
        this.patient?.visit_id,
        $('#medicine_date').val(),
        this.forFrequency,
        false,
      )
      .subscribe(
        (data) => {
          this.medicineAdministration = data;
          this.getDiscontinuedList = false;
          this.ngxLoader.stop();
        },
        (error) => {
          this.ngxLoader.stop();
        },
      );
  }
  addDoseQty(i): any {
    this.addMedicine[i].dose_other_quantity = '';
  }
  updateMedicines(data): any {
    this.singleMedicineId = data?.id;
    this.medicine = data.item.product_name;
    this.doseType = data.stoc_type_id;
    this.frequencyy = data.dosee_id;
    this.routee = data.routee_id;
    this.days = data.no_of_days;
    this.fromDate = data.from_date;
    this.toDate = data.to_date;
    this.pharmacistComments = data.pharmacist_comments;
    this.doctorComments = data.comments;
    // this.dose_other_quantity = data.dose_other_quantity;
    this.updateDosesForSingleMed(this.doseType);
    if (!data?.dose_other_quantity) {
      this.dose = data?.dos_type_id;
    } else {
      this.dose = -1;
      this.dose_other_quantity = data.dose_other_quantity;
    }
    // const now = new Date();
    // const day = ('0' + now.getDate()).slice(-2);
    // const month = ('0' + (now.getMonth() + 1)).slice(-2);
    // const today = now.getFullYear() + '-' + (month) + '-' + (day);
    // this.toDate = today;
    // this.fromDate = today;
    $('#updateMedicine').modal('show');
    this.updateSingleMedicineToDate();
  }
  showDoseQty(): any {
    this.dose_other_quantity = '';
  }
  updateSingleMedicineToDate(): any {
    const startdate = this.fromDate;
    const new_date = moment(startdate, 'YYYY-MM_DD').add(this.days, 'days');
    const date = new_date.format('YYYY-MM-DD');
    $('#medicineEnd').val(date);
    this.toDate = date;
    this.chRef.detectChanges();
  }
  updateMedicine(): any {
    if (this.dose_other_quantity) {
      this.dose = '';
    }
    if (!this.frequencyy) {
      toastr.error('Select Frequency');
      return;
    }
    if (this.dose === -1 && !this.dose_other_quantity) {
      toastr.error('Add Qty');
      return;
    }
    if (!this.days) {
      toastr.error('Add Days');
      return;
    }
    this.ngxLoader.start();
    this.medicineConfigService
      .updateMedicine(
        this.medicine,
        this.doseType,
        this.frequencyy,
        this.dose,
        this.dose_other_quantity,
        this.routee,
        this.days,
        this.fromDate,
        this.toDate,
        this.pharmacistComments,
        this.singleMedicineId,
      )
      .subscribe(
        (data) => {
          toastr.success('Update Successfully');
          $('#updateMedicine').modal('hide');
          this.getMedicineHistory('');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  goToConsume(): any {
    this.isConsume = true;
  }
  changeTab(): any {
    this.isConsume = false;
    if (this.getDiscontinuedList) {
      this.getMedicineHistory('');
    }
  }
  cancelMedicine(id): any {
    this.cancelId = id;
    $('#cancelModal').modal('show');
    this.isConfirmedCancel = false;
  }
  cancelConfirm(): any {
    if (!this.isConfirmedCancel) {
      toastr.error('Select Confirmation');
      return;
    }
    this.ngxLoader.start();
    this.medicineConfigService.cancelMedicine(this.cancelId).subscribe(
      (data) => {
        toastr.success('Cancel Successfully');
        $('#cancelModal').modal('hide');
        this.getMedicineHistory('');
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  returnMedicine(): any {
    const returnQty = Number(this.returnQty);
    if (!returnQty) {
      toastr.error('Return qty cannot be empty or zero');
      return;
    }
    this.ngxLoader.start();
    this.medicineConfigService
      .returnMedicine(this.returnMedicineId, returnQty)
      .subscribe(
        (data) => {
          toastr.success('Return Successfully');
          $('#returnMedicineModal').modal('hide');
          this.getMedicineHistory('');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  getDateMedicine(): any {
    $('#medicine_date').datepicker({
      format: 'dd-mm-yyyy',
      orientation: 'bottom left',
      defaultDate: this.todayMedDate,
      autoClose: true,
      endDate: new Date(),
    });
    $('#medicine_date').datepicker('setDate', this.todayMedDate);
  }

  updateDoses(value, i): any {
    if (value === null) {
      value = '';
    }
    this.addMedicine[i].dos_type_id = '';
    if (value === '') {
      this.doses[i] = this.doseList;
    } else {
      this.doses[i] = this.doseTypeList.filter(
        (d) => d.id === Number(value),
      )[0].dose_data;
    }
  }
  updateDosesForSingleMed(value): any {
    if (value === null) {
      value = '';
    }
    this.dose = '';
    if (value === '') {
      this.dosesList = this.doseList;
    } else {
      this.dosesList = this.doseTypeList.filter(
        (d) => d.id === Number(value),
      )[0]?.dose_data;
    }
    console.log('dosesList', this.dosesList);
  }
  seperateAdvisedBy(data): any {
    let dataValues = data.split('/');
    this.advisedBy = dataValues[0];
    this.employeeType = dataValues[1];
  }
  getDoseTypeList(type): any {
    this.doseTypeList = [];
    this.medicineConfigService.indexconfigv2(type, true).subscribe((data) => {
      if (data.configurators) {
        this.doseTypeList = data.configurators;
      }
      this.ngxLoader.stop();
    });
  }
  allActiveEmployeesList(): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.allActiveRefPerDrs().subscribe(
      (data) => {
        this.allActiveEmployees = data.users;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('.referringDoctors').selectpicker('refresh');
          $('.referringDoctors').selectpicker();
        }, 1000);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getMedicinesOrProductList(ind): any {
    if (this.isipd) {
      this.getItemsListMain(ind);
    } else {
      this.getProductListMain(ind);
    }
  }
  getProductListMain(ind): any {
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.productListData = [];
      this.productSearchResult = false;
      if (this.addMedicine[ind].name.length < 2) {
        return;
      }
      $('#Medicines #medicineSearchMain' + ind + ' .search-result-main').show();
      this.stockService
        .opdEmrProductSearch(
          this.addMedicine[ind].stoc_type_id,
          this.addMedicine[ind].name,
        )
        .subscribe(
          (data) => {
            this.productListData = data.items;
            this.productSearchResult = true;
          },
          (err) => {
            this.productListData = [];
            this.productSearchResult = true;
          },
        );
    }, 1000);
  }

  getItemsListMain(ind): any {
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.productListData = [];
      this.productSearchResult = false;
      if (this.addMedicine[ind].name.length < 2) {
        return;
      }
      $('#Medicines #medicineSearchMain' + ind + ' .search-result-main').show();
      this.stockService
        .searchItemsListv2(
          this.addMedicine[ind].stoc_type_id,
          this.addMedicine[ind].name,
          false,
        )
        .subscribe(
          (data) => {
            this.productListData = data.items;
            this.productSearchResult = true;
          },
          (err) => {
            this.productListData = [];
            this.productSearchResult = true;
          },
        );
    }, 1000);
  }

  setProductMain(value, ind): any {
    this.addMedicine[ind].stoc_type_id = value.stoc_type || '';
    this.addMedicine[ind].name = value.product_name;
    this.addMedicine[ind].item_id = value.id;
    this.addMedicine[ind].dosee_id = value.dosee_id || '';
    this.addMedicine[ind].routee_id = value.routee_id || '';
    this.addMedicine[ind].dose_other_quantity = value.dose_other_quantity || '';
    this.addMedicine[ind].no_of_days = value.no_of_days || '';
    this.addMedicine[ind].duration_type = value.duration_type || '';
    this.updateDoses(this.addMedicine[ind].stoc_type_id, ind);
    this.addMedicine[ind].dos_type_id = value.dos_type_id || '';
    $('#Medicines #medicineSearchMain' + ind + ' .search-result-main').hide();
    this.updateMedicineToDate(Number(ind));
  }

  getFrequencyList(type): any {
    this.frequencyList = [];
    this.medicineConfigService.indexconfigv2(type, true).subscribe((data) => {
      if (data.configurators) {
        this.frequencyList = data.configurators;
      }
      this.ngxLoader.stop();
    });
  }

  removeMedicine(ind): any {
    this.addMedicine.splice(ind, 1);
  }

  focusOut(index): any {
    setTimeout(() => {
      $('.search-result-main').hide();
      if (this.isipd) {
        if (
          !this.addMedicine[index].item_id &&
          this.isIpdMedicineConfigurator?.is_medicine_manual_input === false
        ) {
          toastr.error('Please select medicine from the list');
          return;
        }
      }
      if (this.isOpd) {
        if (
          !this.addMedicine[index].item_id &&
          this.isOpdMedicineConfigurator?.is_medicine_manual_input === false
        ) {
          toastr.error('Please select medicine from the list');
          return;
        }
      }
    }, 300);
  }
  removeSearchString(ind): any {
    this.addMedicine[ind].name = '';
    this.addMedicine[ind].item_id = '';
    $('.quick-search .quick-search-form .quick-search-close').css(
      'display',
      'none',
    );
  }
  getRouteList(type): any {
    this.routeList = [];
    this.medicineConfigService.indexconfigv2(type, true).subscribe((data) => {
      if (data.configurators) {
        this.routeList = data.configurators;
      }
      this.ngxLoader.stop();
    });
  }

  updateMedicineToDate(ind): any {
    const startdate = this.addMedicine[ind].from_date;
    let durationType = this.addMedicine[ind].duration_type || 'days';
    const new_date = moment(startdate, 'YYYY-MM-DD').add(
      this.addMedicine[ind].no_of_days,
      durationType,
    );
    const date = new_date.format('YYYY-MM-DD');
    $('#medicineEnd' + ind).val(date);
    this.addMedicine[ind].to_date = date;
    this.chRef.detectChanges();
  }
  updateMedicineToDurationDays(ind): any {
    const startdate = this.addMedicine[ind].from_date;
    var toDate = this.addMedicine[ind].to_date;
    let sDate = moment(startdate, 'YYYY-MM-DD');
    let tDate = moment(toDate, 'YYYY-MM-DD');
    let result = moment.duration(tDate.diff(sDate)).asDays();
    $('#medicineNoOfDays' + ind).val(result);
    this.addMedicine[ind].no_of_days = result;
  }

  addMoreMedicine(): any {
    const obj = {
      name: '',
      item_id: '',
      dosee_id: '',
      frequency: '',
      stoc_type_id: '',
      dose_other_quantity: '',
      dos_type_id: '',
      no_of_days: '',
      comments: '',
      doses: [],
    };
    this.addMedicine.push(obj);
    // this.doses[this.addMedicine.length - 1] = this.doseList;
    const now = new Date();
    const day = ('0' + now.getDate()).slice(-2);
    const month = ('0' + (now.getMonth() + 1)).slice(-2);
    const today = now.getFullYear() + '-' + month + '-' + day;
    this.addMedicine[this.addMedicine.length - 1].to_date = today;
    this.addMedicine[this.addMedicine.length - 1].from_date = today;
  }

  addMedicineAgainstPatient(value): any {
    $('#Medicines input, #Medicines select').css('border', '1px solid #E4E6EF');
    if (!this.advisedBy) {
      toastr.error('Select Advised By');
      return;
    }
    for (let i = 0; i < this.addMedicine.length; i++) {
      if (this.addMedicine[i].dos_type_id === -1) {
        this.addMedicine[i].dos_type_id = '';
        if (this.addMedicine[i].dose_other_quantity === '') {
          $('#doseQty' + i).css('border', '1px solid #dc3545');
        }
      }
      if (this.addMedicine[i].stoc_type_id === '') {
        $('#stockType' + i).css('border', '1px solid #dc3545');
        $('#stockTypes' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (!this.addMedicine[i].to_date) {
        $('#medicineEnd' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (this.addMedicine[i].name === '') {
        $('#medicineName' + i).css('border', '1px solid #dc3545');
        $('#medicineNames' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (this.addMedicine[i].dosee_id === '') {
        $('#frequencyDose' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (!this.addMedicine[i].no_of_days) {
        $('#medicineNoOfDays' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (this.isipd) {
        if (
          !this.addMedicine[i].item_id &&
          this.isIpdMedicineConfigurator?.is_medicine_manual_input === false
        ) {
          toastr.error('Please select medicine from the list');
          return;
        }
      }
      if (this.isOpd) {
        if (
          !this.addMedicine[i].item_id &&
          this.isOpdMedicineConfigurator?.is_medicine_manual_input === false
        ) {
          toastr.error('Please select medicine from the list');
          return;
        }
      }
    }
    this.ngxLoader.start();
    this.opdService
      .assignMedicineToPatientv2(
        this.addMedicine,
        this.patient,
        this.advisedBy,
        this.employeeType,
        false,
      )
      .subscribe(
        (data) => {
          this.getMedicineHistory('');
          if (value === 'submit') {
            this.setDatesOfMedicines('');
          } else {
            this.addMedicine = [];
            this.addMoreMedicine();
          }
          this.isMedicinesUpdate = Math.random();
          // @ts-ignore
          this.isMedicinesRecieved.emit(this.isServicesUpdate);
          if (value === 'next') {
            $('.navi .navi-item .navi-link').removeClass('active');
            const element: HTMLElement = document.getElementById(
              'ServicesTabs',
            ) as HTMLElement;
            element.click();
          }
          this.ngxLoader.stop();
          this.advisedBy = '';
          setTimeout(() => {
            $('.referringDoctors').selectpicker('refresh');
            $('.referringDoctors').selectpicker();
          });
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  getMedicineHistory(val): any {
    $('#Medicines input').css('border', '1px solid #E4E6EF');
    // this.servicesPrintRecent = true;
    this.ngxLoader.start();
    this.paitentMedicineHistory = [];
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.opdService
      .getMedicineHistoryFunv2(
        this.patient,
        this.patientAllHistory,
        this.forFrequency,
        this.visitType,
        false,
      )
      .subscribe(
        (data) => {
          this.paitentMedicineHistory = data.patient_medications;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  setDatesOfMedicines(val): any {
    this.addMedicine = [];
    for (let i = 0; i < 3; i++) {
      this.addMoreMedicine();
      // this.doses[i] = this.doseList;
    }
  }

  updateServicesPrintRecent(value): any {
    $('#recentMedicineAll').prop('checked', false);
    $('#recentMedicineAlls').prop('checked', false);
    $('#recentServiceAll').prop('checked', false);
    if (value === 'true') {
      this.servicesPrintRecent = true;
    } else {
      this.servicesPrintRecent = false;
    }
  }
  returnMedicines(): any {
    window.open('/patient/issue-item-v2?id=' + this.patient.mrn);
  }
  printRecentMedicineSlip(): any {
    const medicineArray = [];
    if (this.isAdd) {
      if (this.servicesPrintRecent === true) {
        for (let i = 0; i < this.paitentMedicineHistory.length; i++) {
          if (
            this.paitentMedicineHistory[i].recent === true &&
            $('#recientMedicine' + i).prop('checked') === true
          ) {
            medicineArray.push(this.paitentMedicineHistory[i].id);
          }
        }
      }
      if (this.servicesPrintRecent === false) {
        for (let i = 0; i < this.paitentMedicineHistory.length; i++) {
          if (
            this.paitentMedicineHistory[i].recent === false &&
            $('#recientMedicine' + i).prop('checked') === true
          ) {
            medicineArray.push(this.paitentMedicineHistory[i].id);
          }
        }
      }
    } else if (!this.isAdd){
      for (let i = 0; i < this.paitentMedicineHistory.length; i++) {
        if (
          $('#recientMedicine' + i).prop('checked') === true
        ) {
          medicineArray.push(this.paitentMedicineHistory[i].id);
        }
      }
    }

    if (medicineArray.length === 0) {
      toastr.error('Select Medicine First');
      return;
    }

    if (medicineArray.length > 0) {
      $('.recentMedicine').prop('checked', false);
      $('#recentMedicineAll').prop('checked', false);
      $('#recentMedicineAlls').prop('checked', false);
      const URL =
        '/pdf-reports/patient-medicines-v2?medication_id=' +
        medicineArray +
        '&mrn=' +
        this.patient.mrn +
        '&id=' +
        this.patient.id +
        '&visit_id=' +
        this.patient.visit_id +
        '&invoice_id=' +
        '&is_consumable_item=' +
        false;
      window.open(URL, '_blank');
      // window.open(environment.rorUrl + '/v4/patients/' + this.patient.mrn + '/patient_medications/report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&medication_id=' + medicineArray);
    }
  }

  selectAllRecentMedicine(): any {
    if ($('#recentMedicineAll').prop('checked') === true) {
      $('.recentMedicine').prop('checked', true);
    }
    if ($('#recentMedicineAll').prop('checked') === false) {
      $('.recentMedicine').prop('checked', false);
    }
  }

  getDoseList(type): any {
    this.doseList = [];
    this.medicineConfigService.indexconfigv2(type, true).subscribe((data) => {
      if (data.configurators) {
        this.doseList = data.configurators;
        this.setDatesOfMedicines('');
      }
      this.ngxLoader.stop();
    });
  }

  getShowMedicinesInIpd(): any {
    this.ngxLoader.start();
    this.configService.getShowMedicinesInIpd().subscribe(
      (data) => {
        this.isIpdMedicineConfigurator = data;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getShowMedicinesInOpd(): any {
    this.ngxLoader.start();
    this.configService.getShowMedicinesInOpd().subscribe(
      (data) => {
        this.isOpdMedicineConfigurator = data;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.messages);
      },
    );
  }
  _onlyNumeric(e): any {
    var charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  noDecimalPoint(event: any): any {
    return event.charCode >= 48 && event.charCode <= 57;
  }
  rejectionReasonModal(value): any {
    this.rejectionReason = value;
    $('#rejectReason').modal('show');
  }
  openLogsModal(value): any{
    this.medicineId = value.id;
    $('#logsModal').modal('show');
    this.getLogs();
  }
  closeLogsModal(): any{
    $('#logsModal').modal('hide');
  }
  getLogs(): any{
    this.ngxLoader.start();
    this.logsData = [];
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.opdService
      .getLogsData(this.medicineId)
      .subscribe(
        (data) => {
          this.logsData = data.medicine_logs;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  returnQtyValue(): any {
    if (this.returnQty > this.qtyToReturn) {
      this.returnQty = this.qtyToReturn;
    }
  }
  returnMedicineModal(value): any {
    this.returnMedicineId = value.id;
    this.returnMedicineName = value.item.product_name;
    this.qtyToReturn = 0;
    this.returnQty = 0;
    this.ngxLoader.start();
    this.medicineConfigService
      .getMedicineQtyForReturn(this.returnMedicineId)
      .subscribe(
        (data) => {
          this.qtyToReturn =
            data.data.total_issued_quantity -
            data.data.total_approved_returned_quantity -
            data.data.total_pending_returned_quantity - data.data.total_administrative_quantity;
          this.ngxLoader.stop();
          $('#returnMedicineModal').modal('show');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.messages);
        },
      );
  }

  closeModal(): any {
    $('#rejectReason').modal('hide');
  }
}
