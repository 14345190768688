import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { ShareDataService } from '../../services/share-data.service';
import { Router } from '@angular/router';
import { SystemConfigurationService } from '../../services/system-configuration.service';
import { SystemControlsService } from '../../services/system-controls.service';
import { LocationService } from '../../services/location.service';
import { StoreManagementService } from '../../services/store-management.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  currentUser: any;
  updatedData: any;
  hospitalName: any;
  userName: any;
  // moduleName: any;
  main_menus = [];
  data = [];
  countries: any;
  states: any;
  currentId: any;
  country_edit_id: any;
  dataSubscription: Subscription;
  constructor(
    private shareDataService: ShareDataService,
    private router: Router,
    private systemControlService: SystemControlsService,
    private locationService: LocationService,
    private ref: ChangeDetectorRef,
    private storeService: StoreManagementService,
    private ngxLoader: NgxUiLoaderService,
  ) {
    this.dataSubscription = this.shareDataService.dataChanged.subscribe(
      (newData) => {
        localStorage.setItem('currentUser', JSON.stringify(newData));
        if (newData) {
          this.currentUser = newData;
          this.updateNaviBar(this.currentUser.role.role_menus);
        }
      },
    );
  }
  ngOnInit(): void {
    if (localStorage.getItem('auth_token')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.hospitalName = this.currentUser.medical_units[0].title;
      this.userName = this.currentUser.first_name;
      // this.moduleName = this.currentUser.role.role_menus[0].menu.name;
      this.updateNaviBar(this.currentUser.role.role_menus);
    }

    // this.getUpdatedData();
    // this.getProducts();
    // this.getLocationList();
    this.ngxLoader.stop();
  }
  //   this.storeService.getProducts().subscribe( data => {
  //     if (data) {
  //       this.shareDataService.updateProductData(data);
  //     }
  //   }, err => {
  //     toastr.error(err.error.error);
  //   });
  // }
  updateNaviBar(roleMenus): any {
    this.main_menus = roleMenus;
  }
  currentNavBar(menus, ind): any {
    localStorage.setItem('currentScreen', JSON.stringify(menus));
    this.shareDataService.changeMessage(menus);
    $('.menu-item').removeClass('activeMenu');
    $('#menuItem' + ind).addClass('activeMenu');
    this.router.navigate(['menu-details']);
  }
  urlChange(): any {
    $('.menu-item').removeClass('activeMenu');
    $('#menuItem').addClass('activeMenu');
    localStorage.setItem('currentScreen', JSON.stringify([]));
    this.shareDataService.changeMessage([]);
    this.router.navigate(['dashboard']);
  }

  // getUpdatedData(): any {
  //   this.data = [];
  //   // this.ngxLoader.start();
  //   this.systemControlService.getUpdatedChecks().subscribe(data => {
  //       this.data = data.report_controls;
  //       if (data) {
  //         this.shareDataService.changeControlChecks(data.report_controls);
  //       }
  //     },
  //     err => {
  //     }
  //   );
  // }
  // getLocationList(): any {
  //   this.locationService.countries().subscribe( data => {
  //     if (data) {
  //       this.shareDataService.updateCountryData(data.countries);
  //     }
  //   }, err => {
  //     toastr.error(err.error.error);
  //   });
  // }
  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }
}
