<form
  [formGroup]="PatientAdmissionForm"
  (ngSubmit)="createAdmissionRequest()"
>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-12">
        <div class="form-group">
          <label> Doctor <span class="text-danger">*</span></label>
          <div
            [ngClass]="{
                      'is-invalid':
                        isSubmitAdmission && admissionCont.doctor_id.errors
                    }"
          >
            <select
              class="form-control form-control-sm selectpicker"
              id="doctorId"
              formControlName="doctor_id"
              data-live-search="true"
              (change)="getDepartByDoc($event.target.value)"
            >
              <option value="" [selected]="true" [disabled]="true">
                Select
              </option>
              <option
                [value]="doc.id"
                *ngFor="let doc of doctors_by_dept"
              >
                {{ doc.first_name }} {{ doc.last_name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-12">
        <div class="form-group">
          <label> Refer From <span class="text-danger">*</span></label>
          <div
            [ngClass]="{
                      'is-invalid':
                        isSubmitAdmission && admissionCont.referred_from.errors
                    }"
          >
            <select
              class="form-control form-control-sm"
              formControlName="referred_from"
              id="referredFrom"
              data-live-search="true"
              (change)="fetchDoctorsByDep($event.target.value)"
            >
              <option value="" [selected]="true" [disabled]="true">
                Select department
              </option>
              <option [value]="dept.id" *ngFor="let dept of department">
                {{ dept.title }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-12">
        <div class="form-group">
          <label> Ward  <span class="text-danger">*</span></label>
          <div
            [ngClass]="{
                      'is-invalid':
                        isSubmitAdmission && admissionCont.ward_id.errors
                    }"
          >
            <select
              class="form-control form-control-sm"
              formControlName="ward_id"
              id="wardId"
              data-live-search="true"
              (change)="updateWard('', $event.target.value)"
              [ngClass]="{
                        'is-invalid': submitted && admissionCont.ward_id.errors
                      }"
            >
              <option value="" [selected]="true" [disabled]="true">
                Select
              </option>
              <ng-container *ngFor="let ward of wards">
                <option [value]="ward.id">{{ ward.title }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-12">
        <div class="form-group">
          <label> Bed</label>
          <select
            class="form-control form-control-sm"
            formControlName="bed_id"
            id="bedId"
            data-live-search="true"
            [ngClass]="{
                      'is-invalid':
                        isSubmitAdmission && admissionCont.bed_id.errors
                    }"
          >
            <option value="" [selected]="true" [disabled]="true">
              Select
            </option>
            <ng-container *ngFor="let bed of beds">
              <option
                [value]="bed.id"
                *ngIf="bed.status === 'Occupied'"
                [disabled]="true"
              >
                {{ bed.bed_number + "-" + bed.status }}
              </option>
              <option [value]="bed.id" *ngIf="bed.status === 'Free'">
                {{ bed.bed_number + "-" + bed.status }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> Condition</label>
          <select
            class="form-control form-control-sm"
            formControlName="condition"
            [ngClass]="{
                      'is-invalid':
                        isSubmitAdmission && admissionCont.condition.errors
                    }"
          >
            <option [selected]="true" value="normal">Normal</option>
            <option value="abnormal">Abnormal</option>
          </select>
        </div>
      </div>

      <div class="col-lg-9 col-md-9 col-sm-12 col-12">
        <div class="form-group">
          <label> Comments</label>
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="comments"
            placeholder="Enter Comments"
          />
        </div>
      </div>
    </div>
    <h4 class="mt-2 mb-2">Admission Order Detail</h4>
    <div class="col-12 pl-0 pr-0">
      <div class="form-group">
        <!--                <textarea-->
        <!--                  class="form-control form-control-sm"-->
        <!--                  rows="4"-->
        <!--                  formControlName="admission_orders"-->
        <!--                ></textarea>-->
        <textarea
          class="textareas form-control"
          rows="4"
          [id]="'admission_orders'"
          formControlName="admission_orders"
        ></textarea>
      </div>
    </div>
    <h4 class="mt-2">Next of kin (Details):</h4>
    <div class="row">
      <div class="col-lg-4 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> Name (kin)</label>
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="attendent_name"
            placeholder="Enter name"
          />
        </div>
      </div>

      <div class="col-lg-4 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> CNIC </label>
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="attendent_nic"
            placeholder="Enter NIC"
            [ngClass]="{
                      'is-invalid':
                        isSubmitAdmission && admissionCont.attendent_nic.errors
                    }"
            (keypress)="_onlyNumeric($event)"
            maxlength="13"
          />
        </div>
      </div>

      <div class="col-lg-4 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> Phone</label>
          <input
            type="text"
            formControlName="attendent_mobile"
            placeholder="Mobile Number"
            class="form-control form-control-sm"
            (keypress)="_onlyNumeric($event)"
            maxlength="11"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> Relation </label>
          <select
            class="form-control form-control-sm"
            formControlName="attendent_relationship"
            [ngClass]="{
                      'is-invalid':
                        isSubmitAdmission &&
                        admissionCont.attendent_relationship.errors
                    }"
          >
            <option value="" [selected]="true" [disabled]="true">
              Relation
            </option>
            <ng-container *ngFor="let lov of lookupsRelationList">
              <option
                [value]="lov.key"
                *ngIf="lov.category == 'relationship'"
              >
                {{ lov.key }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="col-lg-9 col-md-9 col-sm-6 col-12">
        <div class="form-group">
          <label> Address </label>
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="attendent_address"
            placeholder="Address"
            [ngClass]="{
                      'is-invalid':
                        isSubmitAdmission &&
                        admissionCont.attendent_address.errors
                    }"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-3" *ngIf="type !== 'e-prescription'">
    <button
      type="button"
      class="btn btn-sm btn-light-primary"
      (click)="closeAdmissionModal()"
    >
      Close
    </button>
    <button
      type="submit"
      class="btn btn-sm btn-primary"
      [disabled]="isDisableSubmit"
    >
      Submit Request
    </button>
    <button *ngIf="!hideDirectAdmissionButton"
      type="button"
      class="btn btn-sm btn-primary"
      (click)="createAdmission()"
      [disabled]="isDisableSubmit"
    >
      Admit Patient
    </button>
  </div>
</form>
