<form [formGroup]="attendanceFlowPolicy" (ngSubmit)="onSubmit()">
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1 w-100">
          <!--begin::Page Heading-->
          <div
            class="d-flex align-items-baseline justify-content-between flex-wrap mr-5 w-100"
          >
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5" *ngIf="!policyId">
              Add Attendance Flow Policy
            </h5>
            <h5 class="text-dark font-weight-bold my-1 mr-5" *ngIf="policyId">
              Update Attendance Flow Policy
            </h5>
            <button
              class="btn btn-sm btn-primary"
              [routerLink]="['/roster/attendance-policy']"
            >
              <i class="fas fa-long-arrow-alt-left"></i> Back
            </button>
            <!--end::Page Title-->
            <!--begin::Breadcrumb-->
            <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
            <!--            <li class="breadcrumb-item">-->
            <!--              <a  class="text-muted">General</a>-->
            <!--            </li>-->
            <!--            <li class="breadcrumb-item">-->
            <!--              <a  class="text-muted">Minimized Aside</a>-->
            <!--            </li>-->
            <!--          </ul>-->
            <!--end::Breadcrumb-->
          </div>
          <!--end::Page Heading-->
        </div>
        <!--end::Info-->
        <!--begin::Toolbar-->
        <div class="d-flex align-items-center">
          <!--begin::Actions-->
          <!--        <a  class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a>-->
          <!--end::Actions-->
          <!--begin::Dropdown-->
          <!--        <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">-->
          <!--          <a  class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
          <!--											<span class="svg-icon svg-icon-success svg-icon-2x">-->
          <!--												&lt;!&ndash;begin::Svg Icon | path:assets/media/svg/icons/Files/File-plus.svg&ndash;&gt;-->
          <!--												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
          <!--													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
          <!--														<polygon points="0 0 24 0 24 24 0 24" />-->
          <!--														<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />-->
          <!--														<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000" />-->
          <!--													</g>-->
          <!--												</svg>-->
          <!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
          <!--											</span>-->
          <!--          </a>-->
          <!--          <div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">-->
          <!--            &lt;!&ndash;begin::Navigation&ndash;&gt;-->
          <!--            <ul class="navi navi-hover">-->
          <!--              <li class="navi-header font-weight-bold py-4">-->
          <!--                <span class="font-size-lg">Choose Label:</span>-->
          <!--                <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>-->
          <!--              </li>-->
          <!--              <li class="navi-separator mb-3 opacity-70"></li>-->
          <!--              <li class="navi-item">-->
          <!--                <a  class="navi-link">-->
          <!--														<span class="navi-text">-->
          <!--															<span class="label label-xl label-inline label-light-success">Customer</span>-->
          <!--														</span>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="navi-item">-->
          <!--                <a  class="navi-link">-->
          <!--														<span class="navi-text">-->
          <!--															<span class="label label-xl label-inline label-light-danger">Partner</span>-->
          <!--														</span>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="navi-item">-->
          <!--                <a  class="navi-link">-->
          <!--														<span class="navi-text">-->
          <!--															<span class="label label-xl label-inline label-light-warning">Suplier</span>-->
          <!--														</span>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="navi-item">-->
          <!--                <a  class="navi-link">-->
          <!--														<span class="navi-text">-->
          <!--															<span class="label label-xl label-inline label-light-primary">Member</span>-->
          <!--														</span>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="navi-item">-->
          <!--                <a  class="navi-link">-->
          <!--														<span class="navi-text">-->
          <!--															<span class="label label-xl label-inline label-light-dark">Staff</span>-->
          <!--														</span>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="navi-separator mt-3 opacity-70"></li>-->
          <!--              <li class="navi-footer py-4">-->
          <!--                <a class="btn btn-clean font-weight-bold btn-sm" >-->
          <!--                  <i class="ki ki-plus icon-sm"></i>Add new</a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--            &lt;!&ndash;end::Navigation&ndash;&gt;-->
          <!--          </div>-->
          <!--        </div>-->
          <!--end::Dropdown-->
        </div>
        <!--end::Toolbar-->
      </div>
    </div>
    <!--end::Subheader-->

    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid main-content">
      <!--begin::Container-->
      <!--    container-xl container-xxl-->
      <div class="container-fluid m-0">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="row">
              <!--Code-->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="form-group">
                  <label>Code <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Code"
                    formControlName="code"
                    [ngClass]="{
                      'is-invalid':
                        isAdd && this.attendanceFlowPolicy.controls.code.errors
                    }"
                  />
                </div>
              </div>
              <!--Policy Name-->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="form-group">
                  <label>Policy Name <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Policy Name"
                    formControlName="name"
                    [ngClass]="{
                      'is-invalid':
                        isAdd && this.attendanceFlowPolicy.controls.name.errors
                    }"
                  />
                </div>
              </div>
              <!--Status-->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="form-group">
                  <label>Status <span class="text-danger">*</span></label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="status"
                    [ngClass]="{
                      'is-invalid':
                        isAdd &&
                        this.attendanceFlowPolicy.controls.status.errors
                    }"
                  >
                    <option [ngValue]="true">Active</option>
                    <option [ngValue]="false">Inactive</option>
                  </select>
                </div>
              </div>
              <!--Description-->
              <div class="col-12">
                <div class="form-group">
                  <label>Description:</label>
                  <textarea
                    class="form-control form-control-sm"
                    placeholder="Description"
                    formControlName="description"
                    style="resize: none; height: 70px"
                  >
                  </textarea>
                </div>
              </div>
              <!--              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">-->
              <!--                <div class="form-group">-->
              <!--                  <label>Description</label>-->
              <!--                  <input type="text" class="form-control form-control-sm name" placeholder="Description" formControlName="description" >-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
        <!--end::Card-->

        <div class="row">
          <div class="col-6">
            <!--Check In Card-->
            <div class="card card-custom gutter-b">
              <div class="card-header py-3" style="min-height: 50px; border: 0">
                <div
                  class="card-title align-items-start flex-column justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-100"
                  >
                    <h5
                      class="card-label font-weight-bolder text-dark text-left"
                    >
                      Check In
                    </h5>
                  </div>
                </div>
              </div>

              <div class="card-body py-3">
                <!--    Early In        -->
                <div class="row">
                  <!--heading-->
                  <div class="col-5">
                    <div class="form-group">
                      <strong>Early In</strong>
                    </div>
                  </div>
                  <!--Number/Valve-->
                  <div class="col-4">
                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        placeholder="Number/Value"
                        formControlName="early_in_limit"
                      />
                    </div>
                  </div>
                  <!--Early In Type-->
                  <div class="col-2">
                    <div class="form-group">
                      <select
                        class="form-control form-control-sm"
                        formControlName="early_in_type"
                      >
                        <option [value]="'min'">min</option>
                        <option [value]="'hrs'">hrs</option>
                      </select>
                    </div>
                  </div>
                  <!-- Early In color     -->
                  <div class="col-1">
                    <div class="form-group">
                      <input
                        type="color"
                        class="form-control form-control-sm p-0"
                        placeholder="color"
                        formControlName="early_in_color"
                      />
                    </div>
                  </div>
                </div>
                <!--    Late In        -->
                <div class="row">
                  <!--heading-->
                  <div class="col-5">
                    <div class="form-group">
                      <strong>Late In</strong>
                    </div>
                  </div>
                  <!--Number/Valve-->
                  <div class="col-4">
                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        placeholder="Number/Value"
                        formControlName="late_in_limit"
                      />
                    </div>
                  </div>
                  <!--late In Type-->
                  <div class="col-2">
                    <div class="form-group">
                      <select
                        class="form-control form-control-sm"
                        formControlName="late_in_type"
                      >
                        <option [value]="'min'">min</option>
                        <option [value]="'hrs'">hrs</option>
                      </select>
                    </div>
                  </div>
                  <!-- late In color     -->
                  <div class="col-1">
                    <div class="form-group">
                      <input
                        type="color"
                        class="form-control form-control-sm p-0"
                        placeholder="color"
                        formControlName="late_in_color"
                      />
                    </div>
                  </div>
                </div>
                <!--    Absent (Late)        -->
                <div class="row">
                  <!--heading-->
                  <div class="col-5">
                    <div class="form-group">
                      <strong>Absent (Late)</strong>
                    </div>
                  </div>
                  <!--Number/Valve-->
                  <div class="col-4">
                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        placeholder="Number/Value"
                        formControlName="absent_in_limit"
                      />
                    </div>
                  </div>
                  <!--Early In Type-->
                  <div class="col-2">
                    <div class="form-group">
                      <select
                        class="form-control form-control-sm"
                        formControlName="absent_in_type"
                      >
                        <option [value]="'min'">min</option>
                        <option [value]="'hrs'">hrs</option>
                      </select>
                    </div>
                  </div>
                  <!-- Type In color     -->
                  <div class="col-1">
                    <div class="form-group">
                      <input
                        type="color"
                        class="form-control form-control-sm p-0"
                        placeholder="color"
                        formControlName="absent_in_color"
                      />
                    </div>
                  </div>
                </div>
                <!--    OverTime        -->
                <div class="row">
                  <!--heading-->
                  <div class="col-5">
                    <div class="form-group">
                      <strong>OverTime</strong>
                    </div>
                  </div>
                  <!--Number/Valve-->
                  <div class="col-4">
                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        placeholder="Number/Value"
                        formControlName="overtime_in_limit"
                      />
                    </div>
                  </div>
                  <!--overtime In Type-->
                  <div class="col-2">
                    <div class="form-group">
                      <select
                        class="form-control form-control-sm"
                        formControlName="overtime_in_type"
                      >
                        <option [value]="'hrs'">hrs</option>
                      </select>
                    </div>
                  </div>
                  <!-- overtime In color     -->
                  <div class="col-1">
                    <div class="form-group">
                      <input
                        type="color"
                        class="form-control form-control-sm p-0"
                        placeholder="color"
                        formControlName="overtime_in_color"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <!--Check Out Card-->
            <div class="card card-custom gutter-b">
              <div class="card-header py-3" style="min-height: 50px; border: 0">
                <div
                  class="card-title align-items-start flex-column justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-100"
                  >
                    <h5
                      class="card-label font-weight-bolder text-dark text-left"
                    >
                      Check Out
                    </h5>
                  </div>
                </div>
              </div>

              <div class="card-body py-3">
                <!--    Early In        -->
                <div class="row">
                  <!--heading-->
                  <div class="col-5">
                    <div class="form-group">
                      <strong>Early Out</strong>
                    </div>
                  </div>
                  <!--Number/Valve-->
                  <div class="col-4">
                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        placeholder="Number/Value"
                        formControlName="early_out_limit"
                      />
                    </div>
                  </div>
                  <!--Early In Type-->
                  <div class="col-2">
                    <div class="form-group">
                      <select
                        class="form-control form-control-sm"
                        formControlName="early_out_type"
                      >
                        <option [value]="'min'">min</option>
                        <option [value]="'hrs'">hrs</option>
                      </select>
                    </div>
                  </div>
                  <!-- Early Out color     -->
                  <div class="col-1">
                    <div class="form-group">
                      <input
                        type="color"
                        class="form-control form-control-sm p-0"
                        placeholder="color"
                        formControlName="early_out_color"
                      />
                    </div>
                  </div>
                </div>
                <!--    Late out        -->
                <div class="row">
                  <!--heading-->
                  <div class="col-5">
                    <div class="form-group">
                      <strong>Late Out</strong>
                    </div>
                  </div>
                  <!--Number/Valve-->
                  <div class="col-4">
                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        placeholder="Number/Value"
                        formControlName="late_out_limit"
                      />
                    </div>
                  </div>
                  <!--late Out Type-->
                  <div class="col-2">
                    <div class="form-group">
                      <select
                        class="form-control form-control-sm"
                        formControlName="late_out_type"
                      >
                        <option [value]="'min'">min</option>
                        <option [value]="'hrs'">hrs</option>
                      </select>
                    </div>
                  </div>
                  <!-- late Out color     -->
                  <div class="col-1">
                    <div class="form-group">
                      <input
                        type="color"
                        class="form-control form-control-sm p-0"
                        placeholder="color"
                        formControlName="late_out_color"
                      />
                    </div>
                  </div>
                </div>
                <!--    Absent (Late)        -->
                <div class="row">
                  <!--heading-->
                  <div class="col-5">
                    <div class="form-group">
                      <strong>Absent (Early)</strong>
                    </div>
                  </div>
                  <!--Number/Valve-->
                  <div class="col-4">
                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        placeholder="Number/Value"
                        formControlName="absent_out_limit"
                      />
                    </div>
                  </div>
                  <!--Early out Type-->
                  <div class="col-2">
                    <div class="form-group">
                      <select
                        class="form-control form-control-sm"
                        formControlName="absent_out_type"
                      >
                        <option [value]="'min'">min</option>
                        <option [value]="'hrs'">hrs</option>
                      </select>
                    </div>
                  </div>
                  <!-- absent Out color     -->
                  <div class="col-1">
                    <div class="form-group">
                      <input
                        type="color"
                        class="form-control form-control-sm p-0"
                        placeholder="color"
                        formControlName="absent_out_color"
                      />
                    </div>
                  </div>
                </div>
                <!--    OverTime        -->
                <div class="row">
                  <!--heading-->
                  <div class="col-5">
                    <div class="form-group">
                      <strong>OverTime</strong>
                    </div>
                  </div>
                  <!--Number/Valve-->
                  <div class="col-4">
                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        placeholder="Number/Value"
                        formControlName="overtime_out_limit"
                      />
                    </div>
                  </div>
                  <!--overtime In Type-->
                  <div class="col-2">
                    <div class="form-group">
                      <select
                        class="form-control form-control-sm"
                        formControlName="overtime_out_type"
                      >
                        <option [value]="'hrs'">hrs</option>
                      </select>
                    </div>
                  </div>
                  <!-- overtime Out color     -->
                  <div class="col-1">
                    <div class="form-group">
                      <input
                        type="color"
                        class="form-control form-control-sm p-0"
                        placeholder="color"
                        formControlName="overtime_out_color"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div class="text-right mt-3">
                <button
                  class="btn btn-sm btn-primary"
                  type="submit"
                  *ngIf="!policyId"
                >
                  Add Policy
                </button>
                <button
                  class="btn btn-sm btn-primary"
                  type="submit"
                  *ngIf="policyId"
                >
                  Update Policy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end Internal policy Level-->
    </div>
  </div>
</form>
