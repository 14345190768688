import {Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CheckListConfiguratorSettingService} from '../../../services/check-list-configurator-setting.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {OtService} from '../../../services/ot.service';
import SignaturePad from 'signature_pad';

declare var $: any;
declare var toastr: any;
declare var tinymce: any;
@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.css']
})
export class CheckListComponent implements OnInit {
  @Input() selectedPatient: any;
  @Input() employeesListing: [];
  @Input() doctors: [];
  @Input() checkListType: any;
  @Input() type: any;
  // signature pad canvas id
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  sig: SignaturePad;
  checkList = [];
  consentListing = [];
  checkListConfiguratorId = '';
  checkListDetail = [];
  urlButton = false;
  showCheckListPrint = false;
  urlImage = '';
  signature_url: any;
  requestID: any;
  isSignature = true;
  showSignature: false;
  constructor( private checkService: CheckListConfiguratorSettingService,
               private ngxLoader: NgxUiLoaderService,
               private otService: OtService,
  ) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPatient){
      this.selectedPatient = changes.selectedPatient?.currentValue;
      this.requestID = this.selectedPatient?.id;
      this.getCheckListDetailHistory();
      this.getConsentListing();
      this.clear();
    }
  }
  getCheckNameList(): any {
    this.checkService.getCheckNameListV2(this.checkListType || '').subscribe((data) => {
      this.checkList = data.check_list_templates;
    }, err => {
      toastr.error(err.error.message);
    });
  }
  getConsentListing(): any{
    this.checkService.getConsentListing(this.selectedPatient).subscribe((data) => {
      this.consentListing = data?.patient_templates;
    }, err => {
      toastr.error(err.error.message);
    });
  }
  openPdfReport(id): any{
    window.open('patient/consent-report?id=' + id + '&mrn=' + this.selectedPatient?.mrn, '_blank');
  }
  getCheckListDetailHistory(): any {
    if(this.sig){
      this.sig.clear();
    }
    if(!this.checkListConfiguratorId){
      $('.signature').css('display', 'none');
      this.urlButton = false;
    }
    this.showSignature = this.checkList.find(t => t.show_signature === true);
    tinymce.remove('.reports');
    this.checkListDetail = [];
    if (!this.checkListDetail.length) {
      $('.signature').css('display', 'none');
      this.urlButton = false;
    }
    if (!this.checkListConfiguratorId) {
      return;
    }
    this.ngxLoader.start();
    this.showCheckListPrint = false;
    this.urlImage = '';
    this.signature_url = '';
    this.urlButton = true;
    if (this.type === 'consent_form' || this.type === 'assessment_form'){
       this.otService
         .checkListingGetAgainstConsentFormDetailsV2(
           this.checkListConfiguratorId,
         )
         .subscribe(
           (data) => {
             this.urlImage = data.signature_url;
             $('.signature').css('display', 'none');
             this.checkListDetail = data?.templates;
             if (this.urlImage === '') {
               this.signature_url = '';
               this.urlButton = false;
               $('.signature').css('display', 'block');
             }
             if (data.default_data === false) {
               this.showCheckListPrint = true;
             }
             for (let i = 0; i < this.checkListDetail.length; i++) {
               for (
                 let z = 0;
                 z < this.checkListDetail[i].check_list_temp_config.length;
                 z++
               ) {
                 for (
                   let j = 0;
                   j <
                   this.checkListDetail[i].check_list_temp_config[z]
                     .check_list_template_configurators.length;
                   j++
                 ) {
                   if (
                     this.checkListDetail[i].check_list_temp_config[z]
                       .check_list_template_configurators[j].check_list_type ===
                     'all_doctor_list'
                   ) {
                     const ids = '#doctorPicker' + i + '' + z + '' + j;
                     setTimeout(() => {
                       $(ids).selectpicker();
                     }, 500);
                   }
                   if (
                     this.checkListDetail[i].check_list_temp_config[z]
                       .check_list_template_configurators[j].check_list_type ===
                     'all_employees_list'
                   ) {
                     const ids = '#allEmployees' + i + '' + z + '' + j;
                     setTimeout(() => {
                       $(ids).selectpicker();
                     }, 500);
                   }
                   const textField = this.checkListDetail[i].check_list_temp_config[z]
                     .check_list_template_configurators[j].check_list_type === 'text_field';
                   const textArea = this.checkListDetail[i].check_list_temp_config[z]
                     .check_list_template_configurators[j].check_list_type === 'text_area';
                   // const textEditor = this.checkListDetail[i].check_list_temp_config[z]
                   //   .check_list_template_configurators[j].check_list_type === 'text_editor';
                   const defaultValue = this.checkListDetail[i].check_list_temp_config[z]
                     .check_list_template_configurators[j].default_value;
                   if (textField || textArea && defaultValue) {
                     this.checkListDetail[i].check_list_temp_config[z]
                       .check_list_template_configurators[j].value = defaultValue;
                   }
                   if (
                     this.checkListDetail[i].check_list_temp_config[z]
                       .check_list_template_configurators[j].check_list_type ===
                     'text_editor'
                   ) {
                     // $('#reportMain').empty();
                     const ids = 'reports' + i + '' + z + '' + j;
                     setTimeout(() => {
                       tinymce.init({
                         selector: '#' + ids,
                         menubar: false,
                         height: 400,
                         toolbar: [
                           'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
                         ],
                         plugins:
                           'advlist autolink link image lists charmap code table lineheight',
                         content_style:
                           'body { font-size: 16pt; font-family: Arial; }',
                       });
                       setTimeout(() => {
                         tinymce
                           .get(ids)
                           .setContent(
                             this.checkListDetail[i].check_list_temp_config[z]
                               .check_list_template_configurators[j].default_value ? this.checkListDetail[i].check_list_temp_config[z]
                               .check_list_template_configurators[j].default_value : this.checkListDetail[i].check_list_temp_config[z]
                               .check_list_template_configurators[j].value,
                           );
                       }, 500);
                     }, 500);
                   }
                 }
               }
             }
             this.ngxLoader.stop();
           },
           (err) => {
             toastr.error(err.error.message);
             this.ngxLoader.stop();
           },
         );
     } else {
       this.otService
         .checkListingGetAgainstOtDetailsV2(
           this.requestID,
           this.checkListConfiguratorId,
         )
         .subscribe(
           (data) => {
             this.urlImage = data.signature_url;
             $('.signature').css('display', 'none');
             this.checkListDetail = data.operation_requests;
             if (this.urlImage === '') {
               this.signature_url = '';
               this.urlButton = false;
               $('.signature').css('display', 'block');
             }
             if (data.default_data === false) {
               this.showCheckListPrint = true;
             }
             for (let i = 0; i < this.checkListDetail.length; i++) {
               for (
                 let z = 0;
                 z < this.checkListDetail[i].check_list_temp_config.length;
                 z++
               ) {
                 for (
                   let j = 0;
                   j <
                   this.checkListDetail[i].check_list_temp_config[z]
                     .check_list_template_configurators.length;
                   j++
                 ) {
                   if (
                     this.checkListDetail[i].check_list_temp_config[z]
                       .check_list_template_configurators[j].check_list_type ===
                     'all_doctor_list'
                   ) {
                     const ids = '#doctorPicker' + i + '' + z + '' + j;
                     setTimeout(() => {
                       $(ids).selectpicker();
                     }, 500);
                   }
                   if (
                     this.checkListDetail[i].check_list_temp_config[z]
                       .check_list_template_configurators[j].check_list_type ===
                     'all_employees_list'
                   ) {
                     const ids = '#allEmployees' + i + '' + z + '' + j;
                     setTimeout(() => {
                       $(ids).selectpicker();
                     }, 500);
                   }
                   const textField = this.checkListDetail[i].check_list_temp_config[z]
                     .check_list_template_configurators[j].check_list_type === 'text_field';
                   const textArea = this.checkListDetail[i].check_list_temp_config[z]
                     .check_list_template_configurators[j].check_list_type === 'text_area';
                   // const textEditor = this.checkListDetail[i].check_list_temp_config[z]
                   //   .check_list_template_configurators[j].check_list_type === 'text_editor';
                   const defaultValue = this.checkListDetail[i].check_list_temp_config[z]
                     .check_list_template_configurators[j].default_value;
                   if (textField || textArea && defaultValue) {
                     this.checkListDetail[i].check_list_temp_config[z]
                       .check_list_template_configurators[j].value = defaultValue;
                   }
                   if (
                     this.checkListDetail[i].check_list_temp_config[z]
                       .check_list_template_configurators[j].check_list_type ===
                     'text_editor'
                   ) {
                     // $('#reportMain').empty();
                     const ids = 'reports' + i + '' + z + '' + j;
                     setTimeout(() => {
                       tinymce.init({
                         selector: '#' + ids,
                         menubar: false,
                         height: 400,
                         toolbar: [
                           'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
                         ],
                         plugins:
                           'advlist autolink link image lists charmap code table lineheight',
                         content_style:
                           'body { font-size: 16pt; font-family: Arial; }',
                       });
                       setTimeout(() => {
                         tinymce
                           .get(ids)
                           .setContent(
                             this.checkListDetail[i].check_list_temp_config[z]
                               .check_list_template_configurators[j].default_value ? this.checkListDetail[i].check_list_temp_config[z]
                               .check_list_template_configurators[j].default_value : this.checkListDetail[i].check_list_temp_config[z]
                               .check_list_template_configurators[j].value,
                           );
                       }, 500);
                     }, 500);
                   }
                 }
               }
             }
             this.ngxLoader.stop();
           },
           (err) => {
             toastr.error(err.error.message);
             this.ngxLoader.stop();
           },
         );
     }
  }
  submitChecklistFunction(): any {
    for (let i = 0; i < this.checkListDetail.length; i++) {
      for (
        let z = 0;
        z < this.checkListDetail[i].check_list_temp_config.length;
        z++
      ) {
        for (
          let j = 0;
          j <
          this.checkListDetail[i].check_list_temp_config[z]
            .check_list_template_configurators.length;
          j++
        ) {
          if (
            this.checkListDetail[i].check_list_temp_config[z]
              .check_list_template_configurators[j].check_list_type ===
            'text_editor'
          ) {
            const ids = 'reports' + i + '' + z + '' + j;
            this.checkListDetail[i].check_list_temp_config[
              z
              ].check_list_template_configurators[j].value = tinymce
              .get(ids)
              .getContent();
          }
          if (this.checkListDetail[i].check_list_temp_config[z]
            .check_list_template_configurators[j].is_mandatory && !this.checkListDetail[i].check_list_temp_config[z]
            .check_list_template_configurators[j].value){
            $('#inputValidate' + i + z + j).css('border', '1px solid #dc3545');
            $('#dropdownValidate' + i + z + j).css('border', '1px solid #dc3545').css('border-radius', '4px');
            $('.selectPickerValidate' + i + z + j).css('border', '1px solid #dc3545').css('border-radius', '4px');
            const ids = 'reports' + i + '' + z + '' + j;
            setTimeout(function() {
              $('#' + ids).siblings('.tox-tinymce').css('border', '1px solid #dc3545');
            }, 500);
            return;

          }
        }
      }
    }
    this.getImage();
    this.ngxLoader.start();
    if (this.type === 'consent_form' || this.type === 'assessment_form'){
      this.otService
        .submitConsentFormFunctionReqV2(
          this.checkListDetail,
          this.selectedPatient,
          this.checkListConfiguratorId,
          this.signature_url
        )
        .subscribe(
          (data) => {
            toastr.success('Successfully Updated');
            this.getCheckListDetailHistory();
            this.getConsentListing();
            this.ngxLoader.stop();
            // $('.signature').css('display', 'none');
          },
          (err) => {
            this.ngxLoader.stop();
          },
        );
    } else{
      this.otService
        .submitChecklistFunctionReqV2(
          this.checkListDetail,
          this.requestID,
          this.checkListConfiguratorId,
          this.signature_url
        )
        .subscribe(
          (data) => {
            toastr.success('Successfully Updated');
            this.getCheckListDetailHistory();
            this.ngxLoader.stop();
            // $('.signature').css('display', 'none');
          },
          (err) => {
            this.ngxLoader.stop();
          },
        );
    }
  }

  printChecklist(): any {
    window.open(
      '/ot/report/checklist?id=' +
      this.requestID +
      '&template_id=' +
      this.checkListConfiguratorId,
    );
  }
  clear(): any {
    this.sig.clear();
  }

  getImage(): any {
    this.isSignature = true;
    this.signature_url = this.sig.toDataURL();
  }
  updateSignature(): any {
    this.urlImage = '';
    this.signature_url = '';

    this.urlButton = false;
    $('.signature').css('display', 'block');
  }
  ngOnInit(): void {
    this.getCheckNameList();
    this.getConsentListing();
    this.sig = new SignaturePad(this.canvas.nativeElement);
    $('.signature').css('display', 'none');
  }

}
