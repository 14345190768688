<!--begin::Content-->
<div
  class="content d-flex flex-column flex-column-fluid"
  style="padding-top: 10px"
  id="kt_content"
>
  <!--begin::Subheader-->
  <div class="subheader py-2 subheader-solid h-auto" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-center flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Report Writing</h5>
          <!--          <div class="d-flex ml-6" *ngIf="currentPatient?.patient">-->
          <!--            <div class="text-center">-->
          <!--              <span class="label label-lg label-light-dark label-inline cursor-pointer d-flex">{{currentPatient?.patient?.first_name + ' ' + currentPatient?.patient?.last_name}}</span>-->
          <!--              <span class="label label-lg label-light-dark label-inline cursor-pointer d-flex mt-2">{{ currentPatient?.patient?.mrn }}</span>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="d-flex ml-6" *ngIf="currentPatient?.patient">-->
          <!--            <div class="text-center">-->
          <!--              <span class="label label-lg label-light-dark label-inline cursor-pointer d-flex" *ngIf="currentPatient?.accession_number">Acc No: {{currentPatient?.accession_number}}</span>-->
          <!--              <span class="label label-lg label-light-dark label-inline cursor-pointer d-flex mt-2">{{currentPatient?.patient?.age}}, {{currentPatient?.patient?.gender}}</span>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="d-flex row ml-6" *ngIf="currentPatient?.patient">
            <div class="col-4">
              <span
                class="label label-lg label-light-dark label-inline d-flex"
                >{{
                  currentPatient?.patient?.first_name +
                    " " +
                    currentPatient?.patient?.last_name
                }}</span
              >
              <span
                class="label label-lg label-light-dark label-inline d-flex mt-2"
                >{{ currentPatient?.patient?.mrn }}</span
              >
            </div>
            <div class="col-4">
              <span
                class="label label-lg label-light-dark label-inline d-flex"
                *ngIf="currentPatient?.accession_number"
                >Acc No: {{ currentPatient?.accession_number }}</span
              >
              <span
                class="label label-lg label-light-dark label-inline d-flex mt-2"
                >{{ currentPatient?.patient?.age }},
                {{ currentPatient?.patient?.gender }}</span
              >
            </div>
            <!--            <div class="col-12 patient-data">-->

            <!--          <span class="text-left font-weight-bold padding-top-4" *ngIf="currentPatient?.patient">-->
            <!--            {{ currentPatient?.patient?.mrn }}-->
            <!--            <span class="ml-5">{{ ' ' + currentPatient?.patient?.first_name + ' ' + currentPatient?.patient?.last_name}}</span>-->
            <!--            <span class="ml-5">{{currentPatient?.patient?.age}}, {{currentPatient?.patient?.gender}}</span>-->
            <!--          </span>-->
            <!--            </div>-->
          </div>
          <!--          tab headings-->

          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <span
              class="label label-lg label-light-success label-inline cursor-pointer d-flex"
              *ngIf="currentPatient?.performing_doctor_name"
              (click)="
                changeDoctor(
                  'performing',
                  currentPatient.id,
                  currentPatient.performing_doctor_id
                )
              "
              >Performing Doctor:
              {{ currentPatient?.performing_doctor_name | titlecase }}</span
            >
            <span
              class="label label-lg label-light-warning label-inline cursor-pointer d-flex mt-2"
              *ngIf="currentPatient?.referring_doctor_name"
              (click)="
                changeDoctor(
                  'referring',
                  currentPatient.id,
                  currentPatient.referring_doctor_id
                )
              "
              >Referring Doctor:
              {{ currentPatient?.referring_doctor_name | titlecase }}</span
            >
          </div>
        </div>
        <span class="switch switch-icon ml-5">
          <span class="mr-2">Radiant</span>
          <label>
            <input
              type="checkbox"
              checked="checked"
              #urlCheck
              name="UrlCheck"
              (change)="updatePACSURL($event.target.checked)"
            />
            <span></span>
          </label>
          <span class="ml-1">Web</span>
        </span>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content" style="margin-top: 20px">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-5 col-12 pr-0">
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body" style="padding: 1rem">
              <form>
                <div class="row">
                  <div class="col-12 col-sm-2 pr-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      name="name"
                      [(ngModel)]="manufactureSearchValue"
                      placeholder="Search MRN"
                    />
                  </div>
                  <div class="col-12 col-sm-3 pr-0">
                    <select
                      class="form-control form-control-sm selectpicker"
                      id="department"
                      name="department"
                      [(ngModel)]="departmentId"
                      data-dropup-auto="false"
                      data-live-search="true"
                    >
                      <option value="" disabled>Select</option>
                      <option value="" *ngIf="subDepartments.length > 0">
                        All
                      </option>
                      <option
                        [value]="department.id"
                        *ngFor="let department of subDepartments"
                      >
                        {{ department?.title }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-sm-7">
                    <button
                      type="submit"
                      class="btn btn-light-primary btn-sm font-weight-bold mr-2"
                      (submit)="searchFilterValue()"
                      (click)="searchFilterValue()"
                      ngbTooltip="Search"
                      placement="bottom"
                    >
                      Search
                    </button>
                    <button
                      (click)="updateFilters('')"
                      type="button"
                      class="btn btn-light-primary btn-sm mr-2"
                    >
                      All
                    </button>
                    <button
                      (click)="updateFilters('opd')"
                      type="button"
                      class="btn btn-light-primary btn-sm opd-btn mr-2"
                    >
                      OPD
                    </button>
                    <button
                      (click)="updateFilters('ipd')"
                      type="button"
                      class="btn btn-light-primary btn-sm ipd-btn mr-2"
                    >
                      IPD
                    </button>
                    <button
                      (click)="updateFilters('urgent')"
                      type="button"
                      class="btn btn-light-primary btn-sm urgent mr-2"
                    >
                      Urgent
                    </button>
                  </div>
                </div>
              </form>
              <div
                class="table-responsive position-relative"
                style="height: 300px; overflow: auto"
              >
                <table
                  class="table table-head-custom table-vertical-center"
                  id="kt_advance_table_widget_3"
                >
                  <thead>
                    <tr>
                      <td style="width: 85%"></td>
                      <td style="width: 15%"></td>
                      <!--                    <td></td>-->
                    </tr>
                    <!--                  <tr>-->
                    <!--                    <th style="width: 2%;padding: 0px"></th>-->
                    <!--                    <th style="width: 30%"></th>-->
                    <!--                    <th style="width: 52%"></th>-->
                    <!--                    <td style="width: 8%"></td>-->
                    <!--                    <td style="width: 8%"></td>-->
                    <!--                  </tr>-->
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="let element of pageDataList; let i = index"
                    >
                      <tr
                        class="radiologyReport"
                        id="radiologyReport{{ i }}"
                        [ngClass]="{
                          returned: element.is_rejected === true,
                          urgent: element.urgent === true
                        }"
                      >
                        <td (click)="fetchReports(element, i)">
                          <span
                            class="text-dark-75 font-weight-bolder mb-1 text-one-line"
                            >{{ element.service_name }}</span
                          >
                          <div class="row">
                            <div class="col-4">
                              <span
                                class="text-muted font-weight-bold text-muted d-block"
                                >MRN: {{ element.patient.mrn }}</span
                              >
                            </div>
                            <div class="col-8">
                              <span
                                class="text-muted font-weight-bold text-muted d-block"
                                >Name: {{ element.patient_name }}</span
                              >
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">
                              <span
                                class="text-muted font-weight-bold text-muted"
                                >{{
                                  element.order_date | date: "dd-MM-yyyy h:mm a"
                                }}</span
                              >
                            </div>
                            <div class="col-4">
                              <span
                                class="text-muted font-weight-bold text-muted mr-5"
                                >Acc No: {{ element.accession_number }}</span
                              >
                            </div>
                            <div class="col-4">
                              <span
                                class="text-muted font-weight-bold text-muted"
                                >Films: {{ element.films || 0 }}</span
                              >
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            class="btn btn-icon btn-light-primary btn-sm float-left"
                            (click)="
                              $event.stopPropagation();
                              toHistory(element?.patient?.mrn)
                            "
                          >
                            <i class="fas fa-x-ray"></i>
                          </button>
                          <!--                        <button class="btn btn-icon btn-light-primary btn-sm" (click)="$event.stopPropagation();toHistory(element?.patient?.mrn)">-->
                          <!--                          <i class="fas fa-x-ray"></i>-->
                          <!--                        </button>-->
                          <div class="float-right">
                            <div class="dropdown dropleft">
                              <button
                                type="button"
                                class="btn btn-light-primary btn-icon btn-sm"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="ki ki-bold-more-ver"></i>
                              </button>
                              <div class="dropdown-menu">
                                <a
                                  class="dropdown-item cursor-pointer"
                                  (click)="
                                    $event.stopPropagation();
                                    openDiscomImage(element?.accession_number)
                                  "
                                  >Preview Images</a
                                >
                                <a
                                  class="dropdown-item cursor-pointer"
                                  (click)="
                                    $event.stopPropagation();
                                    rejectLabTest(element)
                                  "
                                  >Reject</a
                                >
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-container>

                    <!--                  <ng-container *ngFor="let element of pageDataList; let i = index">-->
                    <!--                    &lt;!&ndash;              (click)="$event.stopPropagation();openWindow()"&ndash;&gt;-->
                    <!--                    <tr class="radiologyReport" id="radiologyReport{{i}}"  (click)="fetchReports(element, i)"  [ngClass]="{'returned': element.is_rejected === true, 'urgent': element.urgent === true}">-->
                    <!--                      <td  style="width: 10px;padding: 0px" [ngClass]="{'ipd-btn': element.ipd === true, 'opd-btn': element.ipd === false}"></td>-->
                    <!--                      <td>-->
                    <!--                        <span class="text-dark-75 font-weight-bolder mb-1">{{element.order_date | date: 'dd-MM-yyyy h:mm a'}}</span>-->
                    <!--                        <span class="text-muted font-weight-bold text-muted d-block">Mrn: {{element.patient.mrn}}</span>-->
                    <!--                        <span class="text-muted font-weight-bold text-muted d-block">Acc No: {{element.accession_number}}</span>-->
                    <!--                      </td>-->
                    <!--                      <td>-->
                    <!--                        <span class="text-dark-75 font-weight-bolder mb-1">{{element.service_name}}</span>-->
                    <!--                        <span class="text-muted font-weight-bold text-muted d-block">{{element.patient_name}}</span>-->
                    <!--                      </td>-->
                    <!--                      <td><button class="btn btn-icon btn-light-primary btn-sm" (click)="$event.stopPropagation();toHistory(element?.patient?.mrn)">-->
                    <!--                        <i class="flaticon-interface-11"></i>-->
                    <!--                      </button></td>-->
                    <!--                      <td>-->
                    <!--                        <a class="btn btn-icon btn-light-primary btn-sm mr-2" (click)="$event.stopPropagation();openDiscomImage(element?.accession_number)">-->
                    <!--                          <i class="flaticon-technology"></i>-->
                    <!--                        </a>-->
                    <!--                      </td>-->
                    <!--                      <td>-->
                    <!--                        <a class="btn btn-icon btn-light-primary btn-sm mr-2" (click)="$event.stopPropagation();rejectLabTest(element)">-->
                    <!--                          <i class="flaticon2-reload-1"></i>-->
                    <!--                        </a>-->
                    <!--                      </td>-->
                    <!--                    </tr>-->
                    <!--                  </ng-container>-->
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Body-->
          </div>

          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body" style="padding: 1rem">
              <div
                class="table-responsive position-relative"
                style="max-height: 300px; overflow: auto"
              >
                <h5>Previous Reports</h5>
                <table class="table table-head-custom table-vertical-center">
                  <thead>
                    <tr>
                      <th>Number</th>
                      <th>MRN</th>
                      <th>Name</th>
                      <th>Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="let element of reportsData; let i = index"
                    >
                      <tr
                        class="radiologyReportPrevious"
                        id="radiologyReportPrevious{{ i }}"
                        (click)="displayReport(element, i)"
                      >
                        <td>{{ element.accession_number }}</td>
                        <td>{{ element.patient.mrn }}</td>
                        <td>{{ element.patient_name }}</td>
                        <td>{{ element.order_date }}</td>
                        <td>
                          <!--                        <u *ngIf="element?.report" class="pointer" (click)="printReport(element?.report?.id)">Print</u>-->
                          <a
                            class="btn btn-icon btn-light-primary btn-sm mr-2"
                            *ngIf="element?.report"
                            (click)="printReport(element?.report?.id)"
                          >
                            <i class="flaticon-technology"></i>
                          </a>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Body-->
          </div>

          <div class="card card-custom gutter-b" *ngIf="showReport">
            <!--begin::Body-->
            <div class="card-body" style="padding: 1rem">
              <div style="width: 100%; overflow: auto">
                <h5>Previous Report</h5>
                <div id="previousReport"></div>
                <div id="previousReportImpression"></div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="col-sm-7 col-12">
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body" style="padding: 1rem">
              <div class="form-group row">
                <!--                <div class="col-4">-->
                <!--                  <h5  class="font-weight-bold">Reporting Panel</h5>-->
                <!--                </div>-->
                <div class="col-4">
                  <label class="col-form-label text-right float-left mt-2 mr-2"
                    >Classic View :</label
                  >
                  <span class="switch switch-icon mt-2">
                    <label>
                      <input
                        type="checkbox"
                        name="classic"
                        (change)="updateClassicType()"
                        [(ngModel)]="classic"
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
                <label class="col-4 col-form-label text-right"
                  >Report Templates:</label
                >
                <div class="col-4">
                  <!-- selectOptionLeft -->
                  <select
                    class="form-control selectpicker"
                    id="templateList"
                    data-live-search="true"
                    (change)="setReportTemplete($event.target.value)"
                  >
                    <option value="" [selected]="true" [disabled]="true">
                      select
                    </option>
                    <option
                      [value]="temp.id"
                      *ngFor="let temp of templateNames"
                    >
                      {{ temp.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>History</label>
                    <textarea
                      class="form-control"
                      [(ngModel)]="history"
                      rows="2"
                      cols="20"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Reports <span class="text-danger">*</span></label>
                    <div class="" id="reports"></div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group" *ngIf="classic === false">
                    <label
                      >Impressions <span class="text-danger">*</span></label
                    >
                    <div id="impressionsMain">
                      <div class="" id="impressions"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-5">
                  <input
                    class="form-control form-control-sm"
                    name="name"
                    placeholder="Template name"
                    [(ngModel)]="reportName"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3 text-right">
                  <button
                    type="button"
                    class="btn btn-primary btn-sm mr-3"
                    (click)="savePatientReport('template', false)"
                  >
                    Save as template
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm mr-3"
                    (click)="savePatientReport('report', false)"
                  >
                    Save Report
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm mr-3"
                    (click)="savePatientReport('report', true)"
                  >
                    Save & Print Report
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    (click)="savePatientReport('sign', false)"
                    *ngIf="showFinalSignButton === true"
                  >
                    Final Sign
                  </button>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #conformCancelModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Order Rejected!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-8">
              <label>Reasons</label>
              <select class="form-control" [(ngModel)]="rejectedSampleReason">
                <option value="Contaminated">Contaminated</option>
                <option value="Patient Not Arrived">Patient Not Arrived</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="rejectLabTestFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #changeDrModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">
            Change
            <span *ngIf="changDrType === 'referring'">Referring</span>
            <span *ngIf="changDrType === 'performing'">Performing</span>
            Doctor!
          </h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-8">
              <label>Reasons</label>
              <select
                class="form-control form-control-sm"
                data-live-search="true"
                id="doctorsList"
                [(ngModel)]="changDrId"
              >
                <option value="" [disabled]="true">Select Doctor</option>
                <option [value]="doc.id" *ngFor="let doc of doctors">
                  {{ doc.first_name }} {{ doc.last_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="changeDoctorFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
