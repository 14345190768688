import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class BirthCertificateService {
  constructor(private http: HttpClient) {}

  getRequestedlist(values, pageName): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/birth_certificates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          values.type +
          '&query=' +
          values.search_query +
          '&page_name=' +
          pageName +
          '&request_type=' +
          values.status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  getRequestedlistV2(values, pageName): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/birth_certificates/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          values.type +
          '&query=' +
          values.search_query +
          '&page_name=' +
          pageName +
          '&request_type=' +
          values.status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  index(page, perPage, searchData: any, pageName): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/birth_certificates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&from_date=' +
          searchData.from_date +
          '&to_date=' +
          searchData.to_date +
          '&type=' +
          searchData.type +
          '&mrn_type=' +
          searchData.mrn_type +
          '&mrn=' +
          searchData.mrn +
          '&page_name=' +
          pageName,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  createBirthCertificate(birth_certificate, patient): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/birth_certificates.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.mrn,
          birth_certificate: birth_certificate,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createBirthCertificateV2(birth_certificate, patient): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/ipd/birth_certificates/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.id,
          birth_certificate: birth_certificate,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  update(birth_certificate, type): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/birth_certificates/` +
          birth_certificate.id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          birth_certificate: birth_certificate,
          type: type,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateV2(birth_certificate, type): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/birth_certificates/` +
          birth_certificate.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          birth_certificate: birth_certificate,
          type: type,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
