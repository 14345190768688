<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid pt-0" id="kt_content">
  <!--Page Header :: Start-->
  <div class="card card-custom headerCard">
    <div class="card-header ribbon ribbon-top">
      <!--      <div class="ribbon-target bg-danger" style="top: -2px; right: 20px;"></div>-->
      <div class="card-toolbar">
        <ul class="nav nav-light-success nav-bold nav-pills">
          <li class="nav-item">
            <h5>Register Organization</h5>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom mt-3">
        <!--begin::Card Body-->
        <div class="card-body p-0">
          <!--begin::Wizard 5-->
          <div
            class="wizard wizard-5 d-flex flex-column flex-lg-row flex-row-fluid"
            id="kt_wizard"
          >
            <!--begin::Aside-->
            <div class="wizard-aside bg-white d-flex flex-column flex-row-auto">
              <!--begin::Aside Top-->
              <div class="d-flex flex-column-fluid flex-column px-xxl-30 px-10">
                <!--begin: Wizard Nav-->
                <div
                  class="wizard-nav d-flex d-flex justify-content-center pt-10 pt-lg-20 pb-5"
                >
                  <!--begin::Wizard Steps-->
                  <div class="wizard-steps">
                    <!--begin::Wizard Step 1 Nav-->
                    <div
                      class="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="current"
                      (click)="goToOrgDetail()"
                    >
                      <div class="wizard-wrapper">
                        <div
                          class="wizard-icon"
                          [ngClass]="{
                            active:
                              completeStatus === 1 ||
                              completeStatus === 2 ||
                              completeStatus === 3
                          }"
                        >
                          <!--                          <i class="wizard-check ki ki-check"></i>-->
                          <span
                            class="wizard-number"
                            [ngClass]="{
                              active:
                                completeStatus === 1 ||
                                completeStatus === 2 ||
                                completeStatus === 3
                            }"
                            >1</span
                          >
                        </div>
                        <div class="wizard-label">
                          <h3 class="wizard-title">Organization Detail</h3>
                          <div class="wizard-desc">
                            Setup Your Organization Details
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end::Wizard Step 1 Nav-->
                    <!--begin::Wizard Step 2 Nav-->
                    <div
                      class="wizard-step"
                      data-wizard-type="step"
                      (click)="goToAdminDetail(true)"
                    >
                      <div class="wizard-wrapper">
                        <div
                          class="wizard-icon"
                          [ngClass]="{
                            active: completeStatus === 2 || completeStatus === 3
                          }"
                        >
                          <!--                          <i class="wizard-check ki ki-check"></i>-->
                          <span
                            class="wizard-number"
                            [ngClass]="{
                              active:
                                completeStatus === 2 || completeStatus === 3
                            }"
                            >2</span
                          >
                        </div>
                        <div class="wizard-label">
                          <h3 class="wizard-title">Admin Detail</h3>
                          <div class="wizard-desc">Setup Admin Address</div>
                        </div>
                      </div>
                    </div>
                    <!--end::Wizard Step 2 Nav-->
                    <!--begin::Wizard Step 3 Nav-->
                    <div
                      class="wizard-step"
                      data-wizard-type="step"
                      (click)="goToPlanDetail()"
                    >
                      <div class="wizard-wrapper">
                        <div
                          class="wizard-icon"
                          [ngClass]="{ active: completeStatus === 3 }"
                        >
                          <!--                          <i class="wizard-check ki ki-check"></i>-->
                          <span
                            class="wizard-number"
                            [ngClass]="{ active: completeStatus === 3 }"
                            >3</span
                          >
                        </div>
                        <div class="wizard-label">
                          <h3 class="wizard-title">Modules Plan</h3>
                          <div class="wizard-desc">Review and Submit</div>
                        </div>
                      </div>
                    </div>
                    <!--end::Wizard Step 3 Nav-->
                    <!--begin::Wizard Step 4 Nav-->
                    <div class="wizard-step" data-wizard-type="step">
                      <div class="wizard-wrapper">
                        <div class="wizard-icon">
                          <!--                          <i class="wizard-check ki ki-check"></i>-->
                          <span class="wizard-number">4</span>
                        </div>
                        <div class="wizard-label">
                          <h3 class="wizard-title">Prepare Setup</h3>
                          <div class="wizard-desc">preparing your Setup</div>
                        </div>
                      </div>
                    </div>
                    <!--end::Wizard Step 4 Nav-->
                  </div>
                  <!--end::Wizard Steps-->
                </div>
                <!--end: Wizard Nav-->
              </div>
              <!--end::Aside Top-->
              <!--begin::Aside Bottom-->
              <div
                class="d-flex flex-row-auto bgi-no-repeat bgi-position-y-bottom bgi-position-x-center bgi-size-contain pt-2 pt-lg-5 h-350px"
                style="
                  background-image: url(assets/media/svg/illustrations/features.svg);
                "
              ></div>
              <!--end::Aside Bottom-->
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div
              class="wizard-content bg-gray-100 d-flex flex-column flex-row-fluid py-15 px-5 px-lg-10"
            >
              <!--begin::Title-->
              <div class="text-right mr-xxl-10">
                <span class="font-weight-bold text-muted font-size-h5"
                  >Having issues?</span
                >
                <a
                  href="javascript:;"
                  class="font-weight-bolder text-primary font-size-h4"
                  id="kt_login_signup"
                  >Get Help</a
                >
              </div>
              <!--end::Title-->
              <!--begin::Form-->
              <div class="d-flex justify-content-center flex-row-fluid">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <!--begin::Title-->
                  <div class="pb-10 pb-lg-15">
                    <h3
                      class="font-weight-bolder text-dark font-size-h2"
                      *ngIf="orgDetail"
                    >
                      Organization Detail
                    </h3>
                    <h3
                      class="font-weight-bolder text-dark font-size-h2"
                      *ngIf="adminDetail"
                    >
                      Admin Detail
                    </h3>
                    <h3
                      class="font-weight-bolder text-dark font-size-h2"
                      *ngIf="planDetail"
                    >
                      Modules Plan
                    </h3>
                    <h3
                      class="font-weight-bolder text-dark font-size-h2"
                      *ngIf="prepareSetup"
                    >
                      Prepare Setup
                    </h3>
                    <div
                      class="text-muted font-weight-bold font-size-h5"
                      *ngIf="orgDetail"
                    >
                      Already have an Account ?
                      <a
                        [routerLink]="'/login-v2'"
                        class="text-primary font-weight-bolder"
                        >Sign In</a
                      >
                    </div>
                  </div>
                  <!--begin::Title-->
                  <!--begin::Form Group-->
                  <form [formGroup]="orgForm">
                    <div *ngIf="orgDetail">
                      <div class="row">
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                            >
                              Name <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control p-6 rounded-lg font-size-h6"
                              name="name"
                              placeholder="Name"
                              value=""
                              formControlName="title"
                              [ngClass]="{
                                'is-invalid':
                                  isAdd && orgForm.controls.title.errors
                              }"
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >Address <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control p-6 rounded-lg font-size-h6"
                              name="Address "
                              placeholder="Address "
                              value=""
                              formControlName="address"
                              [ngClass]="{
                                'is-invalid':
                                  isAdd && orgForm.controls.address.errors
                              }"
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >Phone <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              maxlength="11"
                              class="form-control p-6 rounded-lg font-size-h6"
                              name="Phone "
                              placeholder="Phone "
                              value=""
                              formControlName="phone_number"
                              (keypress)="_onlyNumeric($event)"
                              [ngClass]="{
                                'is-invalid':
                                  isAdd && orgForm.controls.phone_number.errors
                              }"
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >Email <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control p-6 rounded-lg font-size-h6"
                              name="Email"
                              placeholder="Email"
                              value=""
                              formControlName="email"
                              [ngClass]="{
                                'is-invalid':
                                  isAdd && orgForm.controls.email.errors
                              }"
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >Website Address
                              <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control p-6 rounded-lg font-size-h6"
                              name="Website Address"
                              placeholder="Website Address"
                              value=""
                              formControlName="website_url"
                              [ngClass]="{
                                'is-invalid':
                                  isAdd && orgForm.controls.website_url.errors
                              }"
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >Fax <span class="text-danger">*</span></label
                            >
                            <input
                              type="number"
                              class="form-control p-6 rounded-lg font-size-h6"
                              name="Fax"
                              placeholder="Fax"
                              value=""
                              formControlName="fax"
                              [ngClass]="{
                                'is-invalid':
                                  isAdd && orgForm.controls.fax.errors
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >Enter Your Hospital Url
                              <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control p-6 rounded-lg font-size-h6"
                              placeholder="Enter Your Hospital Url"
                              value=""
                              formControlName="domain_name"
                              style="text-transform: lowercase"
                              (input)="inputValue = $event.target.value"
                              (keypress)="_onlyChar($event)"
                              [ngClass]="{
                                'is-invalid':
                                  isAdd && orgForm.controls.domain_name.errors
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          <div style="margin-bottom: 28px">
                            <span
                              class="font-size-h6 font-weight-bolder text-dark"
                              >{{
                                inputValue | lowercase
                              }}.camdenhealthsys.com</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-end pt-3">
                        <div class="mr-2">
                          <button
                            type="button"
                            class="text-right btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3"
                            data-wizard-type="action-next"
                            (click)="goToAdminDetail(true)"
                          >
                            Next Step
                            <span class="svg-icon svg-icon-md ml-1">
                              <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg-->
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <polygon points="0 0 24 0 24 24 0 24" />
                                  <rect
                                    fill="#000000"
                                    opacity="0.3"
                                    transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                    x="7.5"
                                    y="7.5"
                                    width="2"
                                    height="9"
                                    rx="1"
                                  />
                                  <path
                                    d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                  />
                                </g>
                              </svg>
                              <!--end::Svg Icon-->
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="adminDetail" formGroupName="user_information">
                      <div class="row">
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >First Name
                              <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control p-5 rounded-lg font-size-h6"
                              name="name"
                              placeholder="First Name"
                              value=""
                              formControlName="first_name"
                              [ngClass]="{
                                'is-invalid':
                                  isAdd &&
                                  orgForm.get('user_information.first_name')
                                    .errors
                              }"
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >Last Name
                              <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control p-5 rounded-lg font-size-h6"
                              name="name"
                              placeholder="Last Name"
                              value=""
                              formControlName="last_name"
                              [ngClass]="{
                                'is-invalid':
                                  isAdd &&
                                  orgForm.get('user_information.last_name')
                                    .errors
                              }"
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >Email <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control p-5 rounded-lg font-size-h6"
                              name="name"
                              placeholder="email"
                              value=""
                              formControlName="email"
                              [ngClass]="{
                                'is-invalid':
                                  isAdd &&
                                  orgForm.get('user_information.email').errors
                              }"
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >Phone. <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              maxlength="11"
                              class="form-control p-5 rounded-lg font-size-h6"
                              name="name"
                              placeholder="Phone"
                              value=""
                              formControlName="phone"
                              (keypress)="_onlyNumeric($event)"
                              [ngClass]="{
                                'is-invalid':
                                  isAdd &&
                                  orgForm.get('user_information.phone').errors
                              }"
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >User Name
                              <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control p-5 rounded-lg font-size-h6"
                              name="name"
                              placeholder="User Name"
                              formControlName="user_name"
                              value=""
                              [ngClass]="{
                                'is-invalid':
                                  isAdd &&
                                  orgForm.get('user_information.user_name')
                                    .errors
                              }"
                            />
                          </div>
                        </div>
                        <div
                          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <div class="form-group">
                            <label
                              class="font-size-h6 font-weight-bolder text-dark"
                              >Password
                              <span class="text-danger">*</span></label
                            >
                            <input
                              type="password"
                              class="form-control p-5 rounded-lg font-size-h6"
                              name="name"
                              placeholder="Password"
                              formControlName="password"
                              value=""
                              [ngClass]="{
                                'is-invalid':
                                  isAdd &&
                                  orgForm.get('user_information.password')
                                    .errors
                              }"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-between pt-3">
                        <div class="mr-2">
                          <button
                            type="button"
                            class="btn btn-light-primary font-weight-bolder font-size-h6 pl-6 pr-8 py-4 my-3 mr-3"
                            (click)="goToOrgDetail()"
                          >
                            <span class="svg-icon svg-icon-md mr-1">
                              <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Left-2.svg-->
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <polygon points="0 0 24 0 24 24 0 24" />
                                  <rect
                                    fill="#000000"
                                    opacity="0.3"
                                    transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)"
                                    x="14"
                                    y="7"
                                    width="2"
                                    height="10"
                                    rx="1"
                                  />
                                  <path
                                    d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)"
                                  />
                                </g>
                              </svg>
                              <!--end::Svg Icon--> </span
                            >Previous
                          </button>
                        </div>
                        <div class="mr-2">
                          <button
                            type="button"
                            class="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3"
                            data-wizard-type="action-next"
                            (click)="goToPlanDetail()"
                          >
                            Next Step
                            <span class="svg-icon svg-icon-md ml-1">
                              <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg-->
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <polygon points="0 0 24 0 24 24 0 24" />
                                  <rect
                                    fill="#000000"
                                    opacity="0.3"
                                    transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                    x="7.5"
                                    y="7.5"
                                    width="2"
                                    height="9"
                                    rx="1"
                                  />
                                  <path
                                    d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                  />
                                </g>
                              </svg>
                              <!--end::Svg Icon-->
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="planDetail">
                      <ng-container
                        *ngFor="let module of modulesList; let i = index"
                      >
                        <span
                          class="orgModule mb-3"
                          [ngClass]="
                            module.is_selected
                              ? 'orgModule-selected'
                              : 'orgModule-unselected'
                          "
                          (click)="
                            module?.is_mandatory
                              ? (module.is_selected = true)
                              : (module.is_selected = !module.is_selected)
                          "
                          >{{ module?.name }}</span
                        >
                      </ng-container>
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('front_desk').value ? 'orgModule-selected' : 'orgModule-unselected'">Front Desk</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('ris').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('ris').setValue(!orgForm.get('modules_controls').get('ris').value)">RIS</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('lis').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('lis').setValue(!orgForm.get('modules_controls').get('lis').value)">LIS</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('pharmacy').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('pharmacy').setValue(!orgForm.get('modules_controls').get('pharmacy').value)">Pharmacy</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('ot').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('ot').setValue(!orgForm.get('modules_controls').get('ot').value)">OT</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('dialysis').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('dialysis').setValue(!orgForm.get('modules_controls').get('dialysis').value)">Dialysis</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('ipd').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('ipd').setValue(!orgForm.get('modules_controls').get('ipd').value)">IPD</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('opd').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('opd').setValue(!orgForm.get('modules_controls').get('opd').value)">OPD</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('blood_bank').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('blood_bank').setValue(!orgForm.get('modules_controls').get('blood_bank').value)">Blood Bank</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('emergency').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('emergency').setValue(!orgForm.get('modules_controls').get('emergency').value)">Emergency</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('donation').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('donation').setValue(!orgForm.get('modules_controls').get('donation').value)">Donation</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('hr').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('hr').setValue(!orgForm.get('modules_controls').get('hr').value)">HR</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('pay_roll').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('pay_roll').setValue(!orgForm.get('modules_controls').get('pay_roll').value)">Payroll</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('roastr').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('roastr').setValue(!orgForm.get('modules_controls').get('roastr').value)">Roster</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('inventory').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('inventory').setValue(!orgForm.get('modules_controls').get('inventory').value)">Inventory</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('finance').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('finance').setValue(!orgForm.get('modules_controls').get('finance').value)">Finance</span>-->
                      <!--                        <span class="orgModule mb-3" [ngClass]="orgForm.get('modules_controls').get('complaint_manage').value ? 'orgModule-selected' : 'orgModule-unselected'"-->
                      <!--                              (click)="orgForm.get('modules_controls').get('complaint_manage').setValue(!orgForm.get('modules_controls').get('complaint_manage').value)">Complaint Manage</span>-->
                    </div>
                    <div *ngIf="prepareSetup">
                      <!--                        <div id="loading-bar-spinner" class="spinner">-->
                      <!--                          <div class="spinner-icon"></div>-->
                      <!--                        </div>-->
                      <div class="spinner-text">
                        Your configuration is currently being arranged and will
                        be completed
                      </div>
                      <div class="spinner-text">
                        within the next 10 to 20 minutes. An email notification
                        will be sent to you once
                      </div>
                      <div class="spinner-text">the process is finished.</div>
                      <!--                        <div class="spinner-text">Your setup is being prepared, It will take 10 to 20 minutes.</div>-->
                      <!--                        <div class="spinner-text">You will receive e-mail after process is complete.</div>-->
                    </div>
                    <!--end::Form Group-->
                    <!--end: Wizard Actions-->
                  </form>
                </div>
              </div>
              <!--end::Form-->
              <div
                class="d-flex justify-content-between pt-3"
                *ngIf="planDetail"
              >
                <div class="mr-2">
                  <button
                    type="button"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 pl-6 pr-8 py-4 my-3 mr-3"
                    (click)="goToAdminDetail(false)"
                  >
                    <span class="svg-icon svg-icon-md mr-1">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Left-2.svg-->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)"
                            x="14"
                            y="7"
                            width="2"
                            height="10"
                            rx="1"
                          />
                          <path
                            d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)"
                          />
                        </g>
                      </svg>
                      <!--end::Svg Icon--> </span
                    >Previous
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-primary font-weight-bolder font-size-h6 pl-5 pr-8 py-4 my-3"
                    (click)="Submit()"
                  >
                    Submit
                    <span class="svg-icon svg-icon-md ml-2">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg-->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                            x="7.5"
                            y="7.5"
                            width="2"
                            height="9"
                            rx="1"
                          />
                          <path
                            d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                          />
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <!--end::Content-->
          </div>
          <!--end::Wizard 5-->
        </div>
        <!--end::Card Body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
