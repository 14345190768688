import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { StoreManagementService } from '../../../services/store-management.service';

declare var toastr: any;
declare var $: any;
@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.css'],
})
export class ProductSearchComponent implements OnInit {
  @Output() getProductId: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() apiUrl: any;
  @Input() type: any;
  @Input() vendor: any;
  @Input() showProduct: any;
  @Input() store: any;
  selectedProduct = '';
  typingTimer: any;
  productId: any;
  showloadItemsData = false;
  activeIndex = 0;
  searchValue: any;
  supplier: any;
  eventOfSearchBar: any;
  productSearchValue = [''];
  products = [];
  productSearchArray = [];
  constructor(private storeService: StoreManagementService,
              private ref: ChangeDetectorRef) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vendor && changes.vendor.currentValue !== undefined) {
      this.vendor = changes.vendor.currentValue;
      this.supplier = changes.vendor.currentValue;
    }
    if (changes.showProduct && changes.showProduct.currentValue !== undefined) {
      this.showProduct = changes.showProduct.currentValue;
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): any {
    if (event.key === 'ArrowUp') {
      this.moveSelectionLoadItems(-1);
      event.preventDefault();
    } else if (event.key === 'ArrowDown') {
      this.moveSelectionLoadItems(1);
      event.preventDefault();
    } else if (
      event.key === 'Enter' &&
      this.showloadItemsData &&
      this.selectedProduct
    ) {
      this.appendRow(this.products[this.activeIndex]);
    }
  }

  moveSelectionLoadItems(offset: number): any {
    if (this.showloadItemsData && this.selectedProduct) {
      const newIndex = this.activeIndex + offset;
      if (newIndex >= 0 && newIndex < this.products.length) {
        this.activeIndex = newIndex;
      }
      this.scrollToRowOfLoadItems();
    }
  }
  scrollToRowOfLoadItems(): void {
    const innerScroll = document.getElementById('innerScroll');
    const targetRow = document.getElementById('productID' + this.activeIndex);

    if (innerScroll && targetRow) {
      const scrollOffset = targetRow.offsetTop - innerScroll.offsetTop;
      innerScroll.scrollTop = scrollOffset;
    }
  }
  getLoadItems(search_value): any {
    clearTimeout(this.typingTimer); // Clear the previous timer
    this.typingTimer = setTimeout(() => {
      this.getProducts(search_value); // Call your search function here
    }, 1000);
  }
  getProducts(search_value): any {
    if (this.type === 'wareHouse' && !this.store){
      toastr.error('Select Warehouse');
      this.removeSearchString();
      return;
    } else if (this.type === 'movementReport' && !this.store){
      toastr.error('Select Store');
      this.removeSearchString();
      return;
    }
    if (this.selectedProduct === '' || this.selectedProduct === null) {
      this.selectedProduct = '';
      this.activeIndex = 0;
      this.showloadItemsData = false;
    }
    this.searchValue = search_value.length;
    if (!this.searchValue)  {
      this.selectedProduct = '';
      this.getProductId.emit();
    }
    if (this.searchValue > 0) {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'block',
      );
    } else {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'none',
      );
    }
    if (!this.showProduct) {
      this.vendor = '';
    } else {
      this.vendor = this.supplier;
    }
    if (this.searchValue > 0) {
      this.eventOfSearchBar = event;
      this.storeService
        .getProductAccordingToUrl(search_value, this.apiUrl, this.vendor, this.type,  this.store)
        .subscribe(
          (data) => {
            if (data.services || data.patients || data) {
              this.products = this.type === 'services' ? data.services : this.type === 'donor' || this.type === 'services-new' ? data.patients : data;
              this.showloadItemsData = true;
              setTimeout(() => {
                $('.location-search-bar').addClass('active');
              }, 500);
              this.ref.detectChanges();
              // this.activeDropDownIndex =  0;
            }
              // else {
            //   $('.location-search-bar').removeClass('active');
            // }
          },
          (err) => {
            $('.location-search-bar').removeClass('active');
            toastr.error(err.error.error);
          },
        );
    }
  }
  removeSearchString(): any {
    this.selectedProduct = '';
    this.getProductId.emit();
    this.products = [];
    this.searchValue = 0;
    this.showloadItemsData = false;
    if (this.searchValue > 0) {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'block',
      );
    } else {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'none',
      );
    }
    // $('.location-search-bar').removeClass('active');
  }
  appendRow(product): any {
    this.removeSearchString();
    const duplicate = this.productSearchArray.find(
      (t) => t.id === Number(product.id),
    );
    if (duplicate) {
      toastr.error('Duplicate Not Allowed');
      return;
    }
    if(this.type === 'services') {
      this.getProductId.emit(product.id);
    } else if(this.type === 'movementReport') {
      this.getProductId.emit(product.id);
      this.selectedProduct = product.name;
    } else if(this.type === 'wareHouse') {
      this.getProductId.emit(product);
      this.selectedProduct = product.name;
    } else if(this.type === 'donor') {
      this.getProductId.emit(product.id);
      this.selectedProduct = product.first_name + ' ' + product.last_name;
    } else if(this.type === 'services-new') {
      this.getProductId.emit(product);
      this.selectedProduct = product.first_name + ' ' + product.last_name;
    }
    else{
      this.productSearchArray.unshift(product);
      this.getProductId.emit(this.productSearchArray);
    }
  }
  refreshProductField(data): any {
    this.selectedProduct = '';
  }
  deleteProduct(i): any {
    this.productSearchArray.splice(i, 1);
    this.getProductId.emit(this.productSearchArray);
  }
  showAllProduct(): any {
    $('#allProductsModal').modal('show');
  }
  ngOnInit(): void {}
}
