import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { DepartmentsByHospitalService } from '../../../../../services/departments-by-hospital.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteShareService } from '../../../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-attendance-adjustment',
  templateUrl: './attendance-adjustment.component.html',
  styleUrls: ['./attendance-adjustment.component.css'],
})
export class AttendanceAdjustmentComponent implements OnInit {
  today = formatDate(new Date(), 'MM-yyyy', 'en');
  depart: any;
  employeesOfDepart: any;
  employeesOfSelectedDepartment: any;
  employeeRoasterData = [];
  employeeDetails: any;
  department: any;
  month: any;
  screenName = '';
  confirmUpdatedCheckInOutModal = false;
  confirmUpdatedStatusModal = false;
  singleIndexValue: any;
  singleIndexValueShift: any;
  commentsForStatusChange = '';
  overTimeCount: any;
  overTimeAdjustmentCount: any;
  confirmOvertimeModal = false;
  commentsForOverTime: string;
  attendanceId: any;
  singleIndexValueI: any;
  singleIndexValueJ: any;

  constructor(
    private departmentsByHospital: DepartmentsByHospitalService,
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private rosterServiceService: RosterServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private routeService: RouteShareService,
  ) {
    this.screenName = this.routeService.getValue();
    this.getDepartments();
  }
  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getEmployeesOfDepartment(id): any {
    this.employeesOfDepart = [];
    this.configurationService.getEmployeesOfSpecificDepartment(id).subscribe(
      (data) => {
        this.employeesOfDepart = data;
        setTimeout((e) => {
          $('#employeeDepartmentList').selectpicker('refresh');
          $('#employeeDepartmentList').selectpicker();
        }, 500);
      },
      (err) => {
        setTimeout((e) => {
          $('#employeeDepartmentList').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  appendArray(): any {
    const month = $('.month').val();
    if (this.employeesOfSelectedDepartment === undefined) {
      this.employeesOfSelectedDepartment = '';
    }
    if (this.month === '') {
      toastr.error('Select Month');
      return;
    }
    if (this.department === '') {
      toastr.error('Select Department');
      return;
    }
    if (this.employeesOfSelectedDepartment === '') {
      toastr.error('Select Employee');
      return;
    }
    this.ngxLoader.start();
    this.rosterServiceService
      .getEmployAttendanceDayWise(
        this.employeesOfSelectedDepartment,
        month,
        this.department,
      )
      .subscribe(
        (data) => {
          this.employeeRoasterData = data.list;
          this.employeeDetails = data.employee;
          if (!this.employeeRoasterData.length) {
            toastr.error('Data not Found');
          } else {
            setTimeout(() => {
              this.employeeRoasterData.forEach((value1, index1) => {
                if (value1.attendance.length) {
                  value1.attendance.forEach((value2, index2) => {
                    $('#checkInTime' + index1 + index2).timepicker({
                      minuteStep: 1,
                    });
                    $('#checkInTime' + index1 + index2).timepicker(
                      'setTime',
                      value2?.updated_check_in_time,
                    );
                    value2.updated_check_in_time =
                      value2?.updated_check_in_time ?? '';
                    $('#checkOutTime' + index1 + index2).timepicker({
                      minuteStep: 1,
                    });
                    $('#checkOutTime' + index1 + index2).timepicker(
                      'setTime',
                      value2?.updated_check_out_time,
                    );
                    value2.updated_check_out_time =
                      value2?.updated_check_out_time ?? '';
                  });
                }
              });
            }, 1000);
          }
          setTimeout(() => {
            $('.roasterSelection').selectpicker();
            this.ngxLoader.stop();
          }, 100);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  saveUpdatedCheckInOutTime(data, i, j): any {
    this.singleIndexValue = data;
    this.singleIndexValueI = i;
    this.singleIndexValueJ = j;
    this.employeeRoasterData[i].attendance[j].updated_check_in_time = $(
      '#checkInTime' + i + j,
    ).val();
    this.employeeRoasterData[i].attendance[j].updated_check_out_time = $(
      '#checkOutTime' + i + j,
    ).val();
    $('#openUpdatedCheckInOutModal').modal('show');
  }
  submitUpdatedCheckInOutModal(): any {
    this.ngxLoader.start();
    this.rosterServiceService
      .submitEmployAttendanceDayWise(this.singleIndexValue)
      .subscribe(
        (data) => {
          $('#openUpdatedCheckInOutModal').modal('hide');
          // const findIndex = this.employeeRoasterData.findIndex( t => t.date === data.attendance.date);
          // console.log('findIndex', findIndex);
          this.employeeRoasterData[this.singleIndexValueI].attendance[
            this.singleIndexValueJ
          ] = data.attendance;
          setTimeout(() => {
            this.employeeRoasterData.forEach((value1, index1) => {
              if (value1.attendance.length) {
                value1.attendance.forEach((value2, index2) => {
                  $('#checkInTime' + index1 + index2).timepicker({
                    minuteStep: 1,
                  });
                  $('#checkInTime' + index1 + index2).timepicker(
                    'setTime',
                    value2?.updated_check_in_time,
                  );
                  value2.updated_check_in_time =
                    value2?.updated_check_in_time ?? '';
                  $('#checkOutTime' + index1 + index2).timepicker({
                    minuteStep: 1,
                  });
                  $('#checkOutTime' + index1 + index2).timepicker(
                    'setTime',
                    value2?.updated_check_out_time,
                  );
                  value2.updated_check_out_time =
                    value2?.updated_check_out_time ?? '';
                });
              }
            });
          }, 1000);
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  submitRequestForPresentModal(): any {
    this.ngxLoader.start();
    this.rosterServiceService
      .submitRequestForPresentModal(
        this.singleIndexValueShift,
        this.commentsForStatusChange,
      )
      .subscribe(
        (data) => {
          this.singleIndexValueShift.status = 1;
          $('#requestForPaymentModal').modal('hide');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  submitRequestForOverTimeModal(): any {
    this.ngxLoader.start();
    this.rosterServiceService
      .submitRequestForOverTimeModal(
        this.commentsForOverTime,
        this.attendanceId,
        this.overTimeAdjustmentCount,
      )
      .subscribe(
        (data) => {
          $('#requestForOverTimeModal').modal('hide');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  requestForPaymentModalOpen(data: any, i: any, j: any): any {
    this.singleIndexValueShift = data;
    console.log('this.singleIndexValueShift', this.singleIndexValueShift);
    this.employeeRoasterData[i].attendance[j].updated_check_in_time = $(
      '#checkInTime' + i + j,
    ).val();
    this.employeeRoasterData[i].attendance[j].updated_check_out_time = $(
      '#checkOutTime' + i + j,
    ).val();
    $('#requestForPaymentModal').modal('show');
  }
  requestForOverTimeModalOpen(id): any {
    this.attendanceId = id;
    this.ngxLoader.start();
    this.rosterServiceService.getEmployeeOverTimeData(id).subscribe(
      (data) => {
        if (data?.is_over_time) {
          this.overTimeCount = data?.over_time;
          this.overTimeAdjustmentCount = data?.over_time;
          $('#requestForOverTimeModal').modal('show');
        } else {
          toastr.error('Overtime against this shift does not exists');
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  calcualteMaximumValueForOvertime = () => {
    console.log('this.overTimeAdjustmentCount', this.overTimeAdjustmentCount);
    setTimeout(() => {
      if (
        this.overTimeAdjustmentCount > this.overTimeCount ||
        this.overTimeAdjustmentCount === null
      ) {
        this.overTimeAdjustmentCount = this.overTimeCount;
      }
    }, 2000);
  };
  ngOnInit(): void {
    $('#openUpdatedCheckInOutModal').on('hide.bs.modal', () => {
      this.confirmUpdatedCheckInOutModal = false;
    });
    $('#requestForPaymentModal').on('hide.bs.modal', () => {
      this.confirmUpdatedStatusModal = false;
      this.commentsForStatusChange = '';
    });
    $('#requestForOverTimeModal').on('hide.bs.modal', () => {
      this.overTimeCount = '';
      this.overTimeAdjustmentCount = '';
      this.confirmOvertimeModal = false;
    });
    $('.month').datepicker({
      format: 'mm-yyyy',
      viewMode: 'months',
      minViewMode: 'months',
      orientation: 'bottom left',
    });
    $('.month').datepicker('setDate', this.today);
    this.month = this.today;
  }
}
