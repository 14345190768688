<div>
  <!--begin::Card-->
  <div class="card mb-4" *ngIf="isAdd === true">
    <div class="card-body user-card-body">
      <!--                  <div class="  " style="position: static">-->
      <form>
        <div class="row m-0">
          <div class="col-md-4 col-sm-4">
            <label>Vaccination Name</label>
            <div class="form-group">
              <select
                class="form-control form-control-sm"
                id="VaccinationName"
                (change)="updateVaccination($event.target.value)"
              >
                <option value="" [disabled]="true">Select Vaccination</option>
                <option
                  *ngFor="let vaccination of vaccinationData; let i = index"
                  [value]="i"
                >
                  {{ vaccination.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <label>Dose</label>
            <div class="form-group">
              <input
                class="form-control form-control-sm"
                name="dose"
                id="VaccinationDoes"
                [(ngModel)]="selectedVaccination.dose"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <label>Lot Number</label>
            <div class="form-group">
              <input
                class="form-control form-control-sm"
                name="lot_number"
                disabled
                [(ngModel)]="selectedVaccination.lot_number"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <label>Expiry Date</label>
            <div class="form-group">
              <input
                class="form-control form-control-sm"
                name="expiry"
                disabled
                [(ngModel)]="selectedVaccination.expiry"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <label>Manufacturer</label>
            <div class="form-group">
              <input
                class="form-control form-control-sm"
                name="manufacturer"
                disabled
                [(ngModel)]="selectedVaccination.manufacturer"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <label>Source</label>
            <div class="form-group">
              <input
                class="form-control form-control-sm"
                name="source"
                disabled
                [(ngModel)]="selectedVaccination.source"
              />
            </div>
          </div>
          <div class="col-md-12 col-sm-12">
            <label>Observations</label>
            <div class="form-group">
              <input
                class="form-control form-control-sm"
                name="observations"
                [(ngModel)]="selectedVaccination.observations"
              />
            </div>
          </div>
        </div>

        <div class="row m-0 text-right mt-4">
          <div class="col-12">
            <button
              type="submit"
              class="btn btn-primary btn-sm"
              (click)="addVaccination()"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      <!--                  </div>-->
    </div>
  </div>
  <div class="card mb-4" *ngIf="!isAdd || paitentVaccinationHistory.length > 0">
    <div class="card-body user-card-body">
      <div class="max-height-150 overflow-y-auto table-responsive text-nowrap">
        <table class="table table-head-custom table-vertical-center">
          <thead>
            <tr>
              <th style="width: 15%">Date Time</th>
              <th style="width: 15%">Vaccination</th>
              <th style="width: 15%">Dose</th>
              <th style="width: 55%">Observations</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let vaccination of paitentVaccinationHistory">
              <td>{{ vaccination?.created_at | date: "dd-MM-yyyy h:mm a" }}</td>
              <td>{{ vaccination?.vaccination_type?.name }}</td>
              <td>{{ vaccination?.dose }}</td>
              <td>{{ vaccination?.observation }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!--end::Card-->
</div>
