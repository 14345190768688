import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-thumb-impression',
  templateUrl: './thumb-impression.component.html',
  styleUrls: ['./thumb-impression.component.css'],
})
export class ThumbImpressionComponent implements OnInit {
  impData = '';
  filePath = '';
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.impData = params.id;
    });
  }

  ngOnInit(): void {
    // window.close();
    // const decodedBytes = atob(this.impData);
    // let jsonString = '';
    // for (let i = 0; i < decodedBytes.length; i++) {
    //   jsonString += String.fromCharCode(decodedBytes.charCodeAt(i) & 0xff);
    // }
    // const decodedData = JSON.parse(jsonString);
    // console.log(decodedData);
    // this.filePath = decodedData?.file_path;
    // this.readFile(decodedData?.file_path);
    // $('#fileInput').val(decodedData?.file_path);
    // this.readFile(decodedData?.file_path);
  }
  // readFile(filePath) {
  //           // Create a new XMLHttpRequest object
  //           var xhr = new XMLHttpRequest();
  //
  //           // Define a callback function to handle the file loading
  //           xhr.onload = function() {
  //               // Check if the request was successful
  //               if (xhr.status === 200) {
  //                   // Set the content of the <pre> element to the file's contents
  //                   document.getElementById('fileContent').textContent = xhr.responseText;
  //               } else {
  //                   // Display an error message if the request failed
  //                   document.getElementById('fileContent').textContent = 'Failed to load file: ' + filePath;
  //               }
  //           };
  //
  //           // Open a GET request to the specified file path
  //           xhr.open('GET', filePath, true);
  //
  //           // Send the request
  //           xhr.send();
  //       }
  // readFileData(event: any){
  //   console.log('target', event.target);
  //   console.log('value', event.target);
  // }
}
