<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Templates</h5>
        </div>
        <!--end::Page Heading-->
      </div>
    </div>
  </div>
  <!--end::Subheader-->
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9 p-0">
          <form>
            <div class="card">
              <div class="card-body patient-card">
                <div class="row mb-4">
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label>Name <span class="text-danger">*</span></label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="name"
                        [(ngModel)]="name"
                        placeholder="Template Name"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label>Doctors</label>
                      <select
                        class="form-control form-control-sm selectpicker"
                        name="doctorsTemplates"
                        id="doctorsTemplates"
                        multiple
                        data-live-search="true"
                        [(ngModel)]="doctorIds"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option value="" [disabled]="true">
                          Select doctor
                        </option>
                        <option [value]="doc.id" *ngFor="let doc of doctors">
                          {{ doc.first_name }} {{ doc.last_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-4 py-2">
                    <div class="form-group">
                      <span class="switch switch-icon ml-5">
                        <span class="mr-2">Classic</span>
                        <label>
                          <input
                            type="checkbox"
                            name="classic"
                            (change)="updateClassicType()"
                            [(ngModel)]="classic"
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Reports <span class="text-danger">*</span></label>
                      <div id="reports"></div>
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <div class="form-group" *ngIf="classic === false">
                      <label
                        >Impressions <span class="text-danger">*</span></label
                      >
                      <div id="impressions"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-6">
              <div class="col-12 text-right">
                <button
                  type="button"
                  class="btn btn-sm btn-primary ml-2"
                  (click)="clear()"
                >
                  Clear
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-primary ml-2"
                  (click)="createTemplate()"
                  *ngIf="!id"
                >
                  Create Template
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-primary ml-2"
                  (click)="printTemplate()"
                  *ngIf="id"
                >
                  Preview Template
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-primary ml-2"
                  (click)="UpdateTemplate()"
                  *ngIf="id"
                >
                  Save Template
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-3">
          <div class="card clearfix">
            <div class="card-body" style="padding: 1rem">
              <form>
                <div class="row">
                  <div class="col-12 p-lr-0">
                    <div class="form-group">
                      <select
                        class="form-control"
                        name="searchType"
                        [(ngModel)]="searchType"
                      >
                        <option value="template_name">Template Name</option>
                        <option value="doctor_name">Doctor Name</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 p-lr-0 button-wrapper">
                    <input
                      type="text"
                      placeholder="template search here"
                      name="searchString"
                      [(ngModel)]="searchString"
                      class="form-control form-control-sm input-transparent"
                    />
                    <button
                      class="btn btn-primary btn-sm"
                      type="submit"
                      (click)="searchTemplate()"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
              <div class="mt-3 patient-listing">
                <ng-container *ngFor="let templates of reportsData">
                  <div
                    class="d-flex py-3 patient-panel border-bottom-light border-bottom"
                    (click)="getDetails(templates)"
                  >
                    <div
                      class="d-flex flex-column flex-grow-1 font-weight-bold"
                    >
                      <a class="text-dark mb-1 font-size-lg">{{
                        templates?.name
                      }}</a>
                      <span>{{ templates?.user_names }}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Ot Calendar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
