import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BloodGroup, AlternateBloodGroup } from '../pages/_models';

@Injectable()
export class BloodBankService {
  constructor(private http: HttpClient) {}

  bloodRequisitions(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_requisitions?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&donor_type=Requisition&' +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  donorsList(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_donor_name?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllServices(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/list_all_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getBloodBankBilling(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/add_blood_bank_billing_configurator?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitBloodBankBilling(blood_bank_billing_configurator): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/add_blood_bank_billing_configurator?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          blood_bank_billing_configurator,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  sendRequest(requisition_id, donor_id, type): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/blood_bank_generate_invoiced?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          requisition_id,
          donor_id,
          type,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createBloodRequisition(blood_requisition, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/blood_requisitions.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_requisition,
          patient_id: patient.mrn,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  saveBloodGroup(blood_groups): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/blood_groups.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_groups: blood_groups.blood_groups,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // bloodGroups(current_page: any, per_page: any, search_query: any): Observable<any> {
  //   const results = this.http.get(`${environment.rorUrl}/v4/blood_groups?auth_token=` + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&page=' + current_page + '&per_page=' + per_page + '&search_string=' + search_query
  //     , { headers: new HttpHeaders({
  //         AuthenticationToken: localStorage.getItem('auth_token'),
  //         Accept: 'application/json'
  //       })}).pipe(map(data => data));
  //   return results;
  // }
  // Blood Group List
  bloodGroups(current_page, per_page, search_query): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_group_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  saveAlternateBloodGroup(blood_group, blood_group_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/blood_groups/` +
          blood_group_id +
          `/add_alternate`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_groups: blood_group.alternate_blood_groups,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchBloodGroup(blood_group_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_groups/` +
          blood_group_id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateBloodGroup(blood_group: BloodGroup, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/blood_groups/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          blood_group,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatedBloodGroup(id, blood_group): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/update_requisition_blood_group?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id +
          '&blood_group=' +
          blood_group,
        {},
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchAlternateBloodGroup(blood_group_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/alternate_blood_groups/` +
          blood_group_id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateAlternateBloodGroup(blood_group, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/blood_groups/` + id + `/update_alternate`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_group: blood_group.alternate_blood_groups[0],
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchQuestionnaires(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/questionnaires?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&question_type=Blood Bank' +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // fetch Questionnaire list Api python
  getQuestionnaires(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/questionnaires_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getOptimizeQuestionnaires(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/optimize_questionnaires_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // fetch Lab Employee  list Api python
  getLabEmployee(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/lab_staff?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchRequisitionDonors(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_donors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&donor_type=Requisition&' +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createBloodDonor(blood_donor, blood_requisition_type_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/blood_donors.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_donor,
          blood_requisition_type_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchRequisitionDonor(blood_donor_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_donors/` +
          blood_donor_id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateBloodDonor(blood_donor, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/blood_donors/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          blood_donor,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchCampaigns(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/campaigns?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchCampaignsV2(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/campaign?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchDonorViaCampaigns(queryType, query, selectedCampaign): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/volunteer_registration_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&type=' +
          queryType +
          '&selected_campaign=' +
          selectedCampaign,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchBloodBags(queryType, query, status): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_bags?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&type=' +
          queryType +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchDonorByCode(code): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_donors/fetch_by_code?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&code=' +
          code,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createCrossMatch(
    blood_cross_match,
    blood_requisition_donor_id,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/blood_cross_matches.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_cross_match,
          blood_requisition_donor_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchBloodDonors(
    current_page: any,
    per_page: any,
    search_query: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_donors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createBloodRequisitionDonor(blood_requisition, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/blood_requisitions/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          blood_requisition,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchBloodRequisitionDonors(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_requisition_donors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchCrossMatches(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_cross_matches?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createBloodIssuance(blood_issuance, blood_cross_match_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/blood_issuances.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_issuance,
          blood_cross_match_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchBloodIssuances(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_issuances?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createIssuanceVerification(
    blood_issuance_verification,
    blood_issuance_id,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/blood_issuance_verifications.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_issuance_verification,
          blood_issuance_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitDefferReason(deffer_reason, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/add_deffer_status?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          deffer_reason,
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitDiscardReason(discard_reason, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/add_discarded_status?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          discard_reason,
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  cancelRequisition(id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/add_cancelled_status?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchBloodRequisitionType(blood_requisition_type_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_requisition_types/` +
          blood_requisition_type_id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createCampaign(campaign): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/campaigns.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          campaign,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createCampaignv2(campaign): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/campaign?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          campaign,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateCampaign(campaign, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/campaigns/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          campaign,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateCampaignV2(campaign, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/campaign/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          campaign,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // python API for create Questionnaire
  createQuestionnaire(questionnaire): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/questionnaires_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          questionnaire,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // python API for Update Questionnaire
  updateQuestionnaire(questionnaire, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/questionnaires_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          questionnaire,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  bloodBagsByDate(
    current_page: any,
    per_page: any,
    bloodBag: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_bags?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&from_date=' +
          bloodBag.from_date +
          '&to_date=' +
          bloodBag.to_date +
          '&status=' +
          bloodBag.status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  bloodBagsByDateV2(
    current_page: any,
    per_page: any,
    bloodBag: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_bags?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&from_date=' +
          bloodBag.from_date +
          '&to_date=' +
          bloodBag.to_date +
          '&status=' +
          bloodBag.status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  bloodDonorsByDate(
    current_page: any,
    per_page: any,
    bloodBag: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/blood_donors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&from_date=' +
          bloodBag.from_date +
          '&to_date=' +
          bloodBag.to_date +
          '&type=' +
          bloodBag.type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  bloodDonorsByDateV2(
    current_page: any,
    per_page: any,
    bloodBag: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_donors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&from_date=' +
          bloodBag.from_date +
          '&to_date=' +
          bloodBag.to_date +
          '&type=' +
          bloodBag.type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Blood Maintenance New
  fetchBloodInventory(status, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_bags_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          status +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Blood bank matching Blood bag list filter "creating new API with Musadaq"
  getBloodBagsMachingBloodStock(
    status,
    requsition_id,
    bag_type,
    page,
    per_page,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_bags_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          status +
          '&requsition_id=' +
          requsition_id +
          '&bag_type=' +
          bag_type +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // submit selected blood bags blood bags
  submitSelectedBags(value, requisitionId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/blood_requisition_match_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          requisitionId,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Python Service
  createBloodRequisitionNew(blood_requisition, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/blood_requisition?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_requisition,
          patient_id: patient.mrn,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createBloodRequisitionDonorNew(blood_requisition, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/blood_requisition_detail/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          blood_requisition,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  bloodRequisitionsNew(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_requisition?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&donor_type=Requisition&' +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createBloodDonorNew(blood_donor, blood_requisition_type_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/abcd`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_donor,
          blood_requisition_type_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getBloodRequestListing(
    page,
    per_page,
    fromDate,
    toDate,
    pName,
    mrnNo,
    requestNo,
    status,
    department,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_requisition_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate +
          '&patient_name=' +
          pName +
          '&mrn_no=' +
          mrnNo +
          '&request_no=' +
          requestNo +
          '&status=' +
          status +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getBloodRequestRecieveListing(
    page,
    per_page,
    fromDate,
    toDate,
    pName,
    mrnNo,
    requestNo,
    status,
    department,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_requisition_receiving_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate +
          '&patient_name=' +
          pName +
          '&mrn_no=' +
          mrnNo +
          '&request_no=' +
          requestNo +
          '&status=' +
          status +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  bloodComponent(
    current_page,
    per_page,
    search_query,
    status,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_component_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_query +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //  Blood Bank Configurations New Module
  updateBloodComponent(id, value): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/blood_component_detail?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitBloodComponent(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/blood_component_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //Add Blood Group
  submitBloodGroup(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/blood_group_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Blood Group List
  bloodGroup(current_page, per_page, search_query, status): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_group_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_query +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Blood Group List
  bloodGroupsList(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_group_list_for_drop_down?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Blood Group List
  alternateBloodGroupList(
    current_page,
    per_page,
    search_query,
    status,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/alternate_blood_group_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_query +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Blood Group List which allow alternate
  alternateBloodGroup(
    current_page,
    per_page,
    search_query,
    status,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_group_alternate_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_query +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //  Update blood Group
  updateBloodGroups(id, value): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/blood_group_detail?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Get Bloog Group against ID
  getBloodGroupDataById(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_group_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Get Blood Group against ID
  getAltBloodGroupDataById(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/alternate_blood_group_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //Add Blood Group
  submitAlternateBloodGroup(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/alternate_blood_group_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //  Update Alternate blood Group
  updateAlternateBloodGroups(id, value): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/alternate_blood_group_detail?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getBloodComponentDataById(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_component_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getBloodBankStickerData(id, url): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/label_print/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&donor_id=' +
          id + '&report_url=' + url,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitBloodBag(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/blood_bags_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateBloodBag(blood_donor_id, value): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/donor_detail?blood_donor_id=` +
          blood_donor_id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getBloodBagsDataById(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/abcd_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  bloodBags(
    current_page,
    per_page,
    search_query,
    status,
    from_date,
    to_date,
    donor_name,
    donor_id,
    phone,
    nic,
    blood_group,
    donor_status,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_bags_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_query +
          '&blood_bag_status=' +
          status +
          '&from_date=' +
          from_date +
          '&to_date=' +
          to_date +
          '&donor_name=' +
          donor_name +
          '&donor_id=' +
          donor_id +
          '&phone=' +
          phone +
          '&nic=' +
          nic +
          '&blood_group=' +
          blood_group +
          '&donor_status=' +
          donor_status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Get donor code and id
  getDonorCode(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_donor_code?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //  Get Donor Data Against Donor Id
  fetchDonorDetail(blood_donor_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/donor_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&blood_donor_id=' +
          blood_donor_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //  blood request Mantinance
  submitBagMatch(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/blood_requisition_match_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //  Blood Donor registration against Blood request
  registerBloodDonor(
    blood_donor,
    questions,
    patient,
    requsition_id,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/blood_donor_register_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_donor,
          questions,
          patient,
          requsition_id,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  //  Blood Bag Issue request
  issueBloodBag(
    receiver_name,
    receiver_contact,
    receiver_address,
    comments,
    selectedBagId,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/blood_bag_status_update_views?auth_token=` +
          localStorage.getItem('auth_token') +
          '&bag_id=' +
          selectedBagId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          receiver_name,
          receiver_contact,
          receiver_address,
          comments,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  bloodIssued(
    donor_id,
    requsition_id,
    bag_no,
    collection_date,
    collection_time,
    expiry_dates,
    expiry_time,
    released_by,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/blood_issued_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          donor_id,
          requsition_id,
          bag_no,
          collection_date,
          collection_time,
          expiry_dates,
          expiry_time,
          released_by,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  bloodReserved(
    donor_id,
    requsition_id,
    is_reserved,
    name,
    nic,
    father_name,
    gender,
    bag_no,
    status,
    collection_date,
    collection_time,
    expiry_dates,
    expiry_time,
    released_by,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/blood_reserved_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          donor_id,
          requsition_id,
          is_reserved,
          name,
          nic,
          father_name,
          gender,
          bag_no,
          status,
          collection_date,
          collection_time,
          expiry_dates,
          expiry_time,
          released_by,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //Update Donor
  updateDonor(blood_Donor, questions, currentDonor): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/blood_donor_register_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&currentDonor=' +
          currentDonor,
        {
          blood_Donor,
          questions,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //  Get Registered Donor
  fetchRegisteredDonor(id, requsitionId, searchString, type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_donor_register_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient=' +
          id +
          '&requisition_id=' +
          requsitionId +
          '&search_string=' +
          searchString +
          '&type=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //  Volunter Blood Donor Registration Against Compaing
  regBloodDonor(volunteer_donor, campaigns): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/volunteer_registration_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          volunteer_donor,
          campaigns,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateVolDonor(volunteer_donor, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/blood_bank/volunteer_registration_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          volunteer_donor,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //Add Blood Group
  addBloodGroup(blood_groups): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/blood_group_list` +
          localStorage.getItem('auth_token'),
        {
          // auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          blood_groups: blood_groups.blood_groups,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Submit Zakat
  submitZakat(zakatForm): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/zakat_eligibility/generate_invoice/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          // auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          zakat: zakatForm,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getZakatEligibilityListing(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/zakat_eligibility/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // fatch alternative blood groups against blood group ID
  fetchAltBloodGroup(blood_group_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/alternate_blood_group_for_dropdown?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&blood_group_id=' +
          blood_group_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addBloodSugar(value, patient, visit): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/blood_bank/add_blood_sugar_level?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          Blood_Sugar: value,
          patient: patient,
          visit: visit,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchBloodSugar(id, dateRange, visitType): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/add_blood_sugar_level?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id +
          '&from_date=' +
          dateRange.from_date +
          '&to_date=' +
          dateRange.to_date +
          '&visit_type=' +
          visitType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
