import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DialysisRequestComponent } from './dialysis-request/dialysis-request.component';
import { DefaultDialysisConfigComponent } from './default-dialysis-config/default-dialysis-config.component';
import { ReportsReprintComponent } from './reports-reprint/reports-reprint.component';
import { AuthorizationGuard } from '../../services/authorization.guard';
import { PatientDialysisV2Component } from './patient-dialysis-v2/patient-dialysis-v2.component';
import { DialysisServicesV2Component } from './dialysis-services-v2/dialysis-services-v2.component';
import { DialysisReportComponent } from './dialysis-report/dialysis-report.component';
import { ReportsReprintV2Component } from './reports-reprint-v2/reports-reprint-v2.component';

const routes: Routes = [
  {
    path: 'patient-dialysis-v2',
    component: PatientDialysisV2Component,
    canActivate: [AuthorizationGuard],
  },
  { path: 'dialysis-request/:mrn', component: DialysisRequestComponent },
  {
    path: 'dialysis-services-v2',
    component: DialysisServicesV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'default-dialysis-config',
    component: DefaultDialysisConfigComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'reprint',
    component: ReportsReprintComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'reprint-v2',
    component: ReportsReprintV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'dialysis-report',
    component: DialysisReportComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DialysisRoutingModule {}
