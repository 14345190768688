import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { formatDate } from '@angular/common';
import { RouteShareService } from '../../../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-add-gazetted-holiday',
  templateUrl: './add-gazetted-holiday.component.html',
  styleUrls: ['./add-gazetted-holiday.component.css'],
})
export class AddGazettedHolidayComponent implements OnInit {
  today = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  holidayForm: any = [];
  isAdd = false;
  list: any;
  page = 1;
  totalPages: any;
  perPageValue: any;
  perPage = 25;
  filterList: any;
  activeScreenInd: any;
  screenName = '';

  constructor(
    private fb: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private routeSevice: RouteShareService,
    private rostarService: RosterServiceService,
  ) {
    this.screenName = this.routeSevice.getValue();
    this.holidayForm = this.fb.group({
      date: ['', Validators.required],
      name: ['', Validators.required],
      status: [true, Validators.required],
    });
  }
  edit(list, id): any {
    $('#holidayModal').modal('show');
    $('#date').datepicker();
    this.activeScreenInd = id;
    console.log('this.activeScreenInd', this.activeScreenInd);
    $('.date').datepicker('setDate', list.day);
    this.holidayForm.patchValue({
      date: list.day,
      name: list.name,
      status: list.status,
    });
  }
  openHolidayModal(): any {
    (this.activeScreenInd = ''), (this.isAdd = false);
    $('#holidayModal').modal('show');
    $('#date').datepicker();
    $('.date').datepicker('setDate', '');
    this.holidayForm.reset({
      date: '',
      name: '',
      status: true,
    });
  }
  getDate(): any {
    $('.date').datepicker({
      format: 'dd-mm-yyyy',
      orientation: 'bottom left',
      // startDate: this.today
    });
  }

  onsubmit(): any {
    this.isAdd = true;
    const Date = $('#date').val();
    this.holidayForm.patchValue({
      date: Date,
    });
    if (this.holidayForm.invalid) {
      return;
    }
    if (!this.activeScreenInd) {
      this.ngxLoader.start();
      this.rostarService
        .submitGazettedHolidays(this.holidayForm.value)
        .subscribe(
          (data) => {
            this.isAdd = false;
            this.getGazettedHolidaysWithPagination();
            this.ngxLoader.stop();
            $('#holidayModal').modal('hide');
            toastr.success('Add Successfully');
            this.holidayForm.reset({
              date: '',
              name: '',
              status: true,
            });
          },
          (err) => {
            this.ngxLoader.stop();
            toastr.error(err.error.message);
          },
        );
    } else {
      this.isAdd = true;
      if (this.holidayForm.invalid) {
        return;
      }
      this.ngxLoader.start();
      this.rostarService
        .updateGazettedHolidays(this.holidayForm.value, this.activeScreenInd)
        .subscribe(
          (data) => {
            this.isAdd = false;
            this.getGazettedHolidaysWithPagination();
            $('#holidayModal').modal('hide');
            this.ngxLoader.stop();
            toastr.success(' Update Successfully');
            this.holidayForm.reset({
              date: '',
              name: '',
              status: true,
            });
          },
          (err) => {
            this.ngxLoader.stop();
            toastr.error(err.error.message);
          },
        );
    }
  }
  getGazettedHolidaysWithPagination(): any {
    this.ngxLoader.start();
    this.rostarService
      .getGazettedHolidays(this.page, this.perPage)
      .subscribe((data) => {
        this.list = data;
        this.filterList = data?.items;
        this.totalPages = this.list.total_count;
        this.ngxLoader.stop();
      });
  }
  // loadPage(event): any {
  //   this.page = 1;
  //   this.getGazettedHolidaysWithPagination();
  // }
  loadPage(event): any {
    this.getGazettedHolidaysWithPagination();
  }
  searchFilterValue(): any {
    this.page = 1;
    this.getGazettedHolidaysWithPagination();
  }
  selectPaginationLimit(event): any {
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    this.searchFilterValue();
  }

  ngOnInit(): void {
    this.perPageValue = 25;
    this.searchFilterValue();
    this.getDate();
  }
}
