import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {OpdService} from '../../../services/opd.service';

declare var $: any;
@Component({
  selector: 'app-diagnosis-search',
  templateUrl: './diagnosis-search.component.html',
  styleUrls: ['./diagnosis-search.component.css']
})
export class DiagnosisSearchComponent implements OnInit {
  @Output() diagnosisSearchData: EventEmitter<any> = new EventEmitter<any>();
  @Input() apiUrl: any;
  @Input() causeOfDeath: any;
  @Input() type: any;
  diagnosisListData = [];
  diagnosisSelected = [];
  diagnosisSearch = '';
  diagnosisSearchResult = false;
  diagnosisSearchBar = false;
  constructor(    private opdService: OpdService,
  ) { }
  ngOnChanges(changes: SimpleChanges): any {
    if (changes?.causeOfDeath?.currentValue){
      this.diagnosisSearch = changes?.causeOfDeath?.currentValue;
    } else {
      this.diagnosisSearch = '';
    }
  }
  ngOnInit(): void {
  }
  getDiagnosisList(): any {
    this.diagnosisListData = [];
    this.diagnosisSearchResult = false;
    this.diagnosisSearchBar = true;
    if (this.diagnosisSearch.length < 2) {
      return;
    }
    this.opdService.diagnosisSearchListV2(this.diagnosisSearch, this.apiUrl).subscribe(
      (data) => {
        this.diagnosisListData = data.data;
        this.diagnosisSearchResult = true;
        if (this.diagnosisListData.length) {
          this.diagnosisSearchBar = true;
        } else {
          this.diagnosisSearchBar = false;
        }
        this.diagnosisSearchData.emit({ name: this.diagnosisSearch, type: this.type });
      },
      (err) => {
        this.diagnosisListData = [];
        this.diagnosisSearchResult = true;
        this.diagnosisSearchBar = true;
      },
    );
  }
  setDiagnosis(value): any {
    this.diagnosisSelected = [];
    this.diagnosisSelected.push(value);
    this.diagnosisSearch = value.name;
    this.diagnosisSearchData.emit({ name: this.diagnosisSearch, type: this.type });
    this.diagnosisSearchBar = false;
    $('.search-result-main').hide();
  }
  clearSearchBar(): any{
    this.diagnosisSearchBar = false;
    $('.search-result-main').hide();
  }
}
