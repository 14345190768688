import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { PdfServicesService } from '../../../services/pdf-services.service';
import { HospitalService } from '../../../services/hospital.service';
import { PatientAdmissionService } from '../../../services/patient-admission.service';

declare var toastr: any;

@Component({
  selector: 'app-discharge-certificate',
  templateUrl: './discharge-certificate.component.html',
  styleUrls: ['./discharge-certificate.component.css'],
})
export class DischargeCertificateComponent implements OnInit {
  settingData: any;
  admissionId: any;
  admissionDischargedPatientData: any;

  constructor(
    private location: Location,
    private ngxLoader: NgxUiLoaderService,
    private pdfService: PdfServicesService,
    private hospitalService: HospitalService,
    private route: ActivatedRoute,
    private patientAdmissionService: PatientAdmissionService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.admissionId = params.id || '';
    });
    if (this.admissionId) {
      this.getPreviousDischargeData();
    }
    this.getSettingsData();
  }

  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getPreviousDischargeData(): any {
    this.patientAdmissionService
      .getPatientAdmissionDischargeData(this.admissionId)
      .subscribe(
        (data) => {
          this.admissionDischargedPatientData = data.admission;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  printReport(): any {
    window.print();
  }
  goBack(): any {
    this.location.back();
  }
}
