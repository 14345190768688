import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { formatDate, TitleCasePipe } from '@angular/common';
import * as moment from 'moment';
import { PatientService } from '../../../services/patient.service';
import { CompanyService } from '../../../services/company.service';
import { LocationService } from '../../../services/location.service';
import { LookupService } from '../../../services/lookup.service';
import { PatientTypeService } from '../../../services/patient-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RouteShareService } from '../../../services/route-share.service';
import { Observable, Subscriber, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { ShareDataService } from '../../../services/share-data.service';
import { UserService } from '../../../services/user.service';
import { LanguageService } from '../../../services/language.service';
import { ConfiguratorsService } from '../../../services/configurators.service';
import { SystemControlsService } from '../../../services/system-controls.service';
import {
  accountStatus,
  employeeStatus,
  writtenContactPreference,
} from 'src/app/utilities/constant';
import { MedicineConfigService } from '../../../services/medicine-config.service';
import {DepartmentsbyhospitalService} from '../../../services/departmentsbyhospital.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-registration-v2',
  templateUrl: './registration-v2.component.html',
  styleUrls: ['./registration-v2.component.css'],
  providers: [TitleCasePipe],
})
export class RegistrationV2Component implements OnInit, AfterViewInit {
  @ViewChild('newPatientModal') newPatientModal: ElementRef;
  @ViewChild('conformPatientAdmissionModal')
  conformPatientAdmissionModal: ElementRef;
  @ViewChild('clearConfirmModal') clearConfirmModal: ElementRef;
  @ViewChild('imageModal') imageModal: ElementRef;
  dataValues: any;
  PatientRegistrationForm: UntypedFormGroup;
  PatientSearchForm: UntypedFormGroup;
  ParentPatientSearchForm: UntypedFormGroup;
  submitted = false;
  patient: any;
  patientFields: any;
  regions: any;
  patients: any;
  patient_types = [];
  companies: any;
  groups: any;
  isPatients = false;
  isGuardian = false;
  isMLC = false;
  lookups = [];
  family_patient = '';
  patient_age: any;
  selected_age_type = 'years';
  age_value: any;
  patient_dob: any;
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  parentDiv = true;
  patient_services: any;
  services: any;
  clinic_number = [''];
  doctors: any;
  departments: any;
  current_environment: string;
  current_environment_python: string;
  private_patient_type_id: any;
  name = 'slideToggle';
  id = 'materialSlideToggle';
  checked = false;
  disabled = false;
  privete_company_id: any;
  private_group_id: any;
  age_types = ['years', 'months', 'days', 'hours'];
  current_url = '';
  message = '';
  isSAP = false;
  guardian = 'Guardian';
  placeholder = 'Guardian';
  backFromService = false;
  isClear = false;

  // for Web Cam
  showImage = false;
  patientImage = '';
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public showWebCamAfterReset = false;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();

  countries = [];
  states = [];
  cities = [];
  locations = [];
  clinics = [];

  screenName = '';
  currentPatientInd: any;
  panelType = false;
  isChecked = false;
  ledgersList = [];
  currentControls: any;
  screenControls = [];
  isShowClinic = false;
  isShowUpDateButton = false;
  isShowFilterName = false;
  isDisable = false;
  patientAgainstPhoneNo = [];
  disableAgeValue = false;
  cnicMandatory = false;
  showAdjustmentButton = true;
  country_type: any = 'true';
  countriesList: any;
  stateList: any;
  cityList: any;
  locationList: any;
  billingTab = false;
  zakatEligibility = false;
  familyTab = false;
  documentsTab = false;
  appointmentsTab = false;
  bookingTab = false;
  consentTab = false;
  patientMrn: any;
  isPatientDeceased = false;
  invoiceControl: any;
  templateData: any;
  employeeStatuses = employeeStatus;
  accountStatus = accountStatus;
  writtenContactPreference = writtenContactPreference;
  raceList = [];
  ethnicityList = [];
  radiologySubDepartmentData = [];
  radiologyServicesListing = [];
  radiologySubDepartment = '';
  pattern: any;
  defaultLocations: any;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault();
      event.stopPropagation();
      if (this.isDisable === false && !this.patient) {
        this.isDisable = true;
        this.newRegistration();
      }
    }
  }
  constructor(
    public titleCasePipe: TitleCasePipe,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private companyService: CompanyService,
    private locationService: LocationService,
    private chRef: ChangeDetectorRef,
    private departmentService: DepartmentsbyhospitalService,
    private lookupService: LookupService,
    private patientTypeService: PatientTypeService,
    private modalService: NgbModal,
    private routeSevice: RouteShareService,
    private shareDataService: ShareDataService,
    private userService: UserService,
    private language: LanguageService,
    private configuratorsService: ConfiguratorsService,
    private systemControlService: SystemControlsService,
  ) {
    this.dataValues = this.language.getData();
    this.screenName = this.routeSevice.getValue();
  }
  ngAfterViewInit(): any {
    $('#nic').mask('99999-9999999-9');
    $('#ssn').mask('999-99-9999');
  }
  getDate(): any {
    setTimeout(() => {
      $('#patientAge')
        .datepicker({
          format: 'mm-dd-yyyy',
          orientation: 'bottom left',
          endDate: new Date(),
        })
        .on('changeDate', (e) => {
          this.calculateAge();
        });
    }, 500);
  }
  ngOnInit(): any {
    const resolvedData = this.route.snapshot.data.resolvedData;
    this.defaultLocations =
      resolvedData.system_general_control.default_location;
    this.getDate();
    this.current_url = this.router.url;
    this.ngxLoader.stop();
    this.PatientRegistrationForm = this.formBuilder.group({
      title: ['mr', Validators.required],
      first_name: ['', Validators.required],
      last_name: [''],
      father_name: [''],
      gender: ['male', Validators.required],
      patient_nic: ['', Validators.compose([Validators.minLength(13)])],
      near_by_city: [''],
      address1: [''],
      phone1: [''],
      phone2: [''],
      phone1_type: ['mobile', Validators.required],
      patient_type_id: ['', Validators.required],
      company_id: ['', Validators.required],
      group_id: ['', Validators.required],
      pat_age_type: ['years', Validators.required],
      pat_age_value: [
        '',
        [Validators.required, Validators.min(0), Validators.max(150)],
      ],
      blood_group: [''],
      guardian_nic: ['', Validators.compose([Validators.minLength(13)])],
      family_mrn: ['', Validators.compose([Validators.minLength(8)])],
      email: [
        '',
        Validators.compose([
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ]),
      ],
      country: [''],
      state: [''],
      city: [''],
      birth_day: [''],
      birth_month: [''],
      birth_year: [''],
      unidentify_patient: [''],
      guardian_relationship: [''],
      guardian_first_name: [''],
      guardian_last_name: [''],
      age: [''],
      marital_status: [''],
      unidentify: [false],
      family_name: [''],
      mlc: [''],
      policeman_name: [
        '',
        Validators.compose([Validators.pattern('[a-zA-Z- _]+')]),
      ],
      belt_number: [''],
      police_station_number: [''],
      guardian_state: [''],
      guardian_near_by_city: [''],
      guardian_city: [''],
      guardian_address: [''],
      guardian_phone_type: ['mobile'],
      guardian_phone: [''],
      dob: ['', Validators.required],
      referred_to: [''],
      sap_id: [''],
      old_mrn: [''],
      country_id: ['', Validators.required],
      state_id: ['', Validators.required],
      location_id: [''],
      city_id: ['', Validators.required],
      patient_passport: [''],
      passport_country_id: [''],
      clinic_id: [''],
      alternate_contact: [''],
      alternate_contact_dial_code: [''],
      alternate_contact_dial_code_name: ['pk'],
      ssn: [''],
      race: [''],
      ethnicity: [''],
      zip_code: [''],
      employment_status: [''],
      account_status: ['current'],
      written_contact_preference: ['postal_mail'],
    });
    const maxLength = this.f.phone1_type.value === 'mobile' ? 11 : 25;
    const minLength = this.f.phone1_type.value === 'mobile' ? 11 : 1;
    this.PatientRegistrationForm.get('phone1').setValidators([
      Validators.required,
      Validators.minLength(minLength),
      Validators.maxLength(maxLength),
    ]);
    this.PatientRegistrationForm.get('phone1').updateValueAndValidity();
    this.ParentPatientSearchForm = this.formBuilder.group({
      search_query: [''],
      type: [''],
    });

    this.PatientSearchForm = this.formBuilder.group({
      search_query: [''],
      type: ['default'],
    });
    this.getScreenControls();
    this.getPatientTypeList();
    this.getLookups();
    this.getCountries();
    this.updateClinics();
    this.getInvoiceControls();
    this.getFrontDeskControls();
    this.getConfigTypeRace('race');
    this.getConfigTypeEthnicity('ethnicity');
    this.current_environment = environment.rorUrl;
    this.current_environment_python = environment.pythonUrl;
    this.fetchPatients();
    this.setContactValidation();
    const queryPatient = this.route.snapshot.queryParamMap.get('patient_mrn');
    if (queryPatient) {
      this.PatientSearchForm.value.type = 'mrn';
      this.PatientSearchForm.value.search_query = queryPatient;
      let referal = false;
      if (this.current_url === '/front-desk') {
        referal = true;
      }
      this.patientService
        .searchPatientRegistrationV2(this.PatientSearchForm.value, referal)
        .subscribe(
          (data) => {
            this.patient = data.patients[0];
            this.patient_dob =
              '' +
              this.patient.birth_month +
              '-' +
              this.patient.birth_day +
              '-' +
              this.patient.birth_year;
            this.PatientRegistrationForm.get('dob').setValue(this.patient_dob);
            $('#patientAge').datepicker('update', this.patient_dob);
            this.calculateAge();
            this.fetchPatientInfo(this.patient, 0, false);
            this.ngxLoader.stop();
          },
          (err) => {
            this.ngxLoader.stop();
          },
        );
    }
    // this.shareDataService.controlCheck.subscribe(data => {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
      if (this.currentControls !== '') {
        if (
          this.currentControls.patient_registration.enable_age_value === false
        ) {
          this.disableAgeValue = true;
        }
        if (
          this.currentControls.patient_registration.enable_cnic_mandatory ===
          true
        ) {
          this.cnicMandatory = true;
        }
        if (this.cnicMandatory) {
          this.PatientRegistrationForm.get('patient_nic').setValidators([
            Validators.required,
            Validators.minLength(13),
          ]);
          this.PatientRegistrationForm.get('patient_nic').updateValueAndValidity();
        }
      }
    });
    setTimeout(() => {
      this.chRef.detectChanges();
    }, 100);
    this.initializePhone();
  }
  getScreenControls(): any {
    this.ngxLoader.start();
    this.userService.UserControlByScreenV2().subscribe(
      (data) => {
        this.screenControls = data.roles;
        this.updateClinicCheck();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  updateClinicCheck(): any {
    if (this.screenControls.length > 0) {
      if (
        this.screenControls.filter((m) => m.code === 'showclinic').length > 0
      ) {
        this.isShowClinic = this.screenControls.filter(
          (m) => m.code === 'showclinic',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'showupdatebutton')
          .length > 0
      ) {
        this.isShowUpDateButton = this.screenControls.filter(
          (m) => m.code === 'showupdatebutton',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'shownamefilter').length >
        0
      ) {
        this.isShowFilterName = this.screenControls.filter(
          (m) => m.code === 'shownamefilter',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'showadjustmentbutton')
          .length > 0
      ) {
        this.showAdjustmentButton = this.screenControls.filter(
          (m) => m.code === 'showadjustmentbutton',
        )[0].is_show;
      }
    }
    if (this.isShowClinic) {
      this.PatientRegistrationForm.get('clinic_id').setValidators(
        Validators.required,
      );
    } else {
      this.PatientRegistrationForm.get('clinic_id').clearValidators();
    }
  }
  getLookups(): any {
    this.lookupService.indexV2('relationship').subscribe((data) => {
      this.lookups = data.lookups;
      this.PatientRegistrationForm.controls.guardian_relationship.setValue('');
    });
  }

  updateCountryFun(value): any {
    if (this.countries.length > 0) {
      this.states = this.countries.find((t) => t.id === Number(value)).states;
      this.cities = [];
      this.locations = [];
      this.PatientRegistrationForm.patchValue({
        state_id: '',
        city_id: '',
        location_id: '',
      });
    }
  }
  initializePhone(): any {
    $('#alternate_contact').intlTelInput({
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js',
      preferredCountries: ['PK'],
      preventInvalidNumbers: true,
      preventInvalidDialCodes: true,
      formatOnDisplay: true,
      separateDialCode: true,
    });
    $('#alternate_contact').intlTelInput(
      'setCountry',
      this.patient.alternate_contact_dial_code_name || 'PK',
    );
  }
  fetchSubDepartments(): any {
    this.radiologySubDepartmentData = [];
    this.ngxLoader.start();
    this.departmentService.fetchSubDeptbyServicev2('rad', true).subscribe(
      (data) => {
        this.radiologySubDepartmentData = data?.departments;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  radiologyServicesModal(): any{
    if (!this.radiologySubDepartmentData.length){
      this.fetchSubDepartments();
    }
    this.radiologySubDepartment = '';
    this.radiologyServicesListing = [];
    setTimeout(() => {
      $('#radDepartment').selectpicker('refresh');
    }, 500);
    $('#radiologyWorkListModal').modal('show');
  }
  searchRadiologyServices(): any{
    this.radiologyServicesListing = [];
    this.ngxLoader.start();
    this.patientService.searchRadiologyServices(this.radiologySubDepartment).subscribe(data => {
      this.radiologyServicesListing = data?.rad_summarized_data;
      this.ngxLoader.stop();
    }, err => {
      this.ngxLoader.stop();
      toastr.error(err.error.message);
    });
  }
  getCountries(): any {
    this.ngxLoader.start();
    this.locationService.getCountries().subscribe(
      (data) => {
        this.countriesList = data.countries;
        this.ngxLoader.stop();
        setTimeout((e) => {
          $('.country').selectpicker('refresh');
          $('.country').selectpicker();
        }, 500);
        let country;
        if (this.defaultLocations.country_id) {
          country = this.countriesList.find(
            (t) => t.id === Number(this.defaultLocations.country_id),
          );
        } else if (this.countriesList.find((t) => t.is_default === true)) {
          country = this.countriesList.find((t) => t.is_default === true);
        } else {
          country = this.countriesList[0];
        }
        this.PatientRegistrationForm.patchValue({
          passport_country_id: country?.id,
        });
        this.PatientRegistrationForm.patchValue({ country_id: country?.id });
        this.getPatternAndCountryCode(country?.id, true);
        this.getState(country?.id, false);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getPatternAndCountryCode(value, check): any {
    if (value) {
      const country = this.countriesList.find((t) => t.id === Number(value));
      this.pattern = country.pattern;
      if (!this.pattern) {
        const maxLength = this.f.phone1_type.value === 'mobile' ? 11 : 25;
        const minLength = this.f.phone1_type.value === 'mobile' ? 11 : 1;
        this.PatientRegistrationForm.get('phone1').setValidators([
          Validators.required,
          Validators.minLength(minLength),
          Validators.maxLength(maxLength),
        ]);
        this.PatientRegistrationForm.get('phone1').updateValueAndValidity();
        $('#phone1').unmask(this.pattern);
        this.PatientRegistrationForm.get('phone1').setValue($('#phone1').val());
      } else {
        this.PatientRegistrationForm.get('phone1').clearValidators();
        this.PatientRegistrationForm.get('phone1').updateValueAndValidity();
        $('#phone1').mask(this.pattern);
        this.PatientRegistrationForm.get('phone1').setValue($('#phone1').val());
      }
      if (check) {
        $('#alternate_contact').intlTelInput(
          'setCountry',
          country.country_code_name || 'PK',
        );
      }
    }
  }
  getState(value, setDefault): any {
    this.PatientRegistrationForm.patchValue({
      state_id: '',
      city_id: '',
      location_id: '',
    });
    this.stateList = [];
    this.cityList = [];
    this.locationList = [];
    this.ngxLoader.start();
    this.locationService.getCountryState(value).subscribe(
      (data) => {
        this.stateList = data.states;
        this.ngxLoader.stop();
        setTimeout((e) => {
          $('.location').selectpicker('refresh');
          $('.city').selectpicker('refresh');
          $('.state').selectpicker('refresh');
          $('.state').selectpicker();
        }, 500);
        let state = '';
        if (setDefault) {
          state = this.patient?.state_id;
        } else {
          if (this.defaultLocations.state_id) {
            console.log('state_id', this.defaultLocations.state_id);
            state = this.stateList.find(
              (t) => t.id === Number(this.defaultLocations.state_id),
            ).id;
          } else if (this.stateList.find((t) => t.is_default === true)) {
            state = this.stateList.find((t) => t.is_default === true).id;
            console.log('statteeee', state);
          } else {
            state = this.stateList[0].id;
          }
        }
        this.PatientRegistrationForm.patchValue({ state_id: state });
        this.getCity(state, setDefault);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getCity(value, setDefault): any {
    this.PatientRegistrationForm.patchValue({
      city_id: '',
      location_id: '',
    });
    this.cityList = [];
    this.locationList = [];
    // this.ngxLoader.start();
    this.locationService.getStateCity(value).subscribe(
      (data) => {
        this.cityList = data.cities;
        // this.ngxLoader.stop();
        setTimeout((e) => {
          $('.city').selectpicker('refresh');
          $('.location').selectpicker('refresh');
          $('.city').selectpicker();
        }, 500);
        let city = '';
        if (setDefault) {
          city = this.patient.city_id;
        } else {
          if (this.defaultLocations.city_id) {
            city = this.cityList.find(
              (t) => t.id === Number(this.defaultLocations.city_id),
            ).id;
          } else if (this.cityList.find((t) => t.is_default === true)) {
            city = this.cityList.find((t) => t.is_default === true).id;
          } else {
            city = this.cityList[0].id;
          }
        }
        this.PatientRegistrationForm.patchValue({ city_id: city });
        this.getLocation(city, setDefault);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getLocation(value, setDefault): any {
    this.locationList = [];
    // this.ngxLoader.start();
    this.locationService.getLocation(value).subscribe(
      (data) => {
        this.locationList = data.locations;
        // this.ngxLoader.stop();
        setTimeout((e) => {
          $('.location').selectpicker('refresh');
          $('.location').selectpicker();
        }, 500);
        let location = '';
        if (setDefault) {
          location = this.patient?.location_id;
        } else {
          if (this.defaultLocations.location_id) {
            location = this.locationList.find(
              (t) => t.id === Number(this.defaultLocations.location_id),
            ).id;
          } else if (this.locationList.find((t) => t.is_default === true)) {
            location = this.locationList.find((t) => t.is_default === true).id;
          } else {
            location = this.locationList[0].id;
          }
        }
        this.PatientRegistrationForm.patchValue({ location_id: location });
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  updateStateFun(value): any {
    if (this.states.length > 0) {
      this.cities = this.states.find((t) => t.id === Number(value)).cities;
      this.locations = [];
      this.PatientRegistrationForm.patchValue({
        city_id: '',
        location_id: '',
      });
    }
  }
  updateCityFun(value): any {
    if (this.cities.length > 0) {
      this.locations = this.cities.find(
        (t) => t.id === Number(value),
      ).locations;
      this.PatientRegistrationForm.patchValue({
        location_id: '',
      });
    }
  }
  updateClinics(): any {
    this.locationService.getClinics().subscribe(
      (data) => {
        if (data) {
          this.clinics = data.clinics;
        }
      },
      (err) => {},
    );
  }
  getPatientTypeList(): any {
    this.patientTypeService.getSelfPatientTypeV2().subscribe((data) => {
      this.patient_types = data.patient_types;
      if (this.patient) {
        this.setPatientType();
      } else {
        const response = this.patient_types.filter(
          (t) => t.is_default === true,
        )[0];
        this.private_patient_type_id = response.id;

        this.companies = response.companies;
        let companiesResponse;
        if (this.companies.filter((t) => t.is_default === true)[0]) {
          companiesResponse = this.companies.filter(
            (t) => t.is_default === true,
          )[0];
        } else {
          companiesResponse = this.companies[0];
        }

        this.privete_company_id = companiesResponse.id;
        this.groups = companiesResponse.groups;
        if (this.groups.filter((t) => t.is_default === true)[0]) {
          this.private_group_id = this.groups.filter(
            (t) => t.is_default === true,
          )[0].id;
        } else if (this.groups.length > 0) {
          this.private_group_id = this.groups[0].id;
        }
      }

      // this.set_company_group_private();
    });
  }

  resetPatientTypes(): any {
    const response = this.patient_types.filter((t) => t.is_default === true)[0];
    this.private_patient_type_id = response.id;

    this.companies = response.companies;
    let companiesResponse;
    if (this.companies.filter((t) => t.is_default === true)[0]) {
      companiesResponse = this.companies.filter(
        (t) => t.is_default === true,
      )[0];
    } else {
      companiesResponse = this.companies[0];
    }
    this.privete_company_id = companiesResponse.id;

    this.groups = companiesResponse.groups;
    if (this.groups.filter((t) => t.is_default === true)) {
      this.private_group_id = this.groups.filter(
        (t) => t.is_default === true,
      )[0].id;
    } else if (this.groups.length > 0) {
      this.private_group_id = this.groups[0].id;
    }
    this.PatientRegistrationForm.patchValue({
      patient_type_id: this.private_patient_type_id,
      company_id: this.privete_company_id,
      group_id: this.private_group_id,
    });
  }

  discountPage(patient, page): any {
    const url_string = decodeURIComponent(
      '/' + page + '?patient_id=' + patient.mrn,
    );
    this.router.navigateByUrl(url_string);
  }
  opdPage(): any {
    this.router.navigate(['opd']);
  }
  admissionPage(patient, page): any {
    if (patient.admitted === false) {
      this.ngxLoader.start();
      const url_string = decodeURIComponent(
        '/' + page + '?patient_id=' + patient.mrn,
      );
      this.router.navigateByUrl(url_string);
    } else {
      // $('#conformPatientAdmission').modal('show');
      this.modalService.open(this.conformPatientAdmissionModal);
      // this._flashMessagesService.show('Patient Already Admitted', { cssClass: 'alert-danger', timeout: 3000 });
    }
  }
  admissionPageConform(patient, page): any {
    this.modalService.dismissAll(this.conformPatientAdmissionModal);
    // $('#conformPatientAdmission').modal('hide');
    this.ngxLoader.start();
    const url_string = decodeURIComponent(
      '/' + page + '?patient_id=' + patient.mrn,
    );
    this.router.navigateByUrl(url_string);
  }

  print(url: string): any {
    window.open(
      this.current_environment +
        '/v3/patients/' +
        this.patient.id +
        '/' +
        url +
        '.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&is_national=true',
      '_blank',
    );
  }

  printCard(): any {
    if (this.templateData === 'template_1') {
      window.open(
        this.current_environment +
          '/v3/patients/' +
          this.patient.id +
          '/print_health_card.pdf?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&is_national=true',
        '_blank',
      );
    } else {
      window.open('/patient/health-card?id=' + this.patient.mrn);
    }
  }
  getFrontDeskControls(): any {
    this.systemControlService.getFrontDeskControlTemplateData().subscribe(
      (data) => {
        this.templateData =
          data.system_front_desk_control.patient_card_template.patient_card_template;
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  getInvoiceControls(): any {
    this.userService.getInvoiceControls().subscribe(
      (data) => {
        this.invoiceControl = data.system_billing_control;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  printInvoice(id, visitId, mrn): any {
    if (this.invoiceControl.invoice_controls !== '') {
      if (this.invoiceControl.invoice_controls.show_service_invoice === true) {
        window.open(
          'pdf-reports/patient-services-invoices-v2?patient_id=' +
            id +
            '&visit_id=' +
            visitId +
            '&mrn=' +
            mrn,
        );
      } else {
        window.open(
          this.current_environment +
            '/v4/patient_services/' +
            id +
            '/patient_services_invoice_slip.pdf?auth_token=' +
            localStorage.getItem('auth_token') +
            '&print_type=true&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id'),
          '_blank',
        );
      }
    }
  }
  printInvoiceCancelItem(id): any {
    window.open(
      this.current_environment +
        '/v4/patient_service_invoice_items/refund_report.pdf?id=' +
        id +
        '&auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      '_blank',
    );
  }
  openPrescriptionSlip(id): any {
    // window.open(environment.rorUrl + '/v4/patients/' + this.patient.id + '/prescription_slip.pdf?visit_id=' + id + '&auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'));
    if (this.currentControls !== '') {
      if (this.currentControls.patient_services.is_prescription === false) {
        window.open(
          'pdf-reports/prescription?id=' + this.patient.mrn + '&visit_id=' + id,
        );
      } else {
        window.open(
          environment.rorUrl +
            '/v4/patients/' +
            this.patient.id +
            '/prescription_slip.pdf?visit_id=' +
            id +
            '&auth_token=' +
            localStorage.getItem('auth_token') +
            '&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id'),
        );
      }
    } else {
      window.open(
        environment.rorUrl +
          '/v4/patients/' +
          this.patient.id +
          '/prescription_slip.pdf?visit_id=' +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
      );
    }
  }
  printDialysisService(id): any {
    window.open(
      this.current_environment +
        '/v4/dialysis_patients/report.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&id=' +
        id,
    );
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  _keyPressOnlyChar(event: any): any {
    const pattern = /^[a-zA-Z\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  onChange(): any {
    if (this.PatientRegistrationForm.value.title === 'mrs') {
      this.placeholder = this.dataValues.husband;
    } else {
      this.placeholder = this.dataValues.guardian;
    }
    this.PatientRegistrationForm.controls.gender.setValue('');
    if (
      this.PatientRegistrationForm.value.title === 'mr' ||
      this.PatientRegistrationForm.value.title === 'bo' ||
      this.PatientRegistrationForm.value.title === 'dr'
    ) {
      this.PatientRegistrationForm.controls.gender.setValue('male');
    }
    if (
      this.PatientRegistrationForm.value.title === 'mrs' ||
      this.PatientRegistrationForm.value.title === 'miss' ||
      this.PatientRegistrationForm.value.title === 'do'
    ) {
      this.PatientRegistrationForm.controls.gender.setValue('female');
    }
  }

  clearFormConfirm(): any {
    this.modalService.open(this.clearConfirmModal);
    this.isClear = false;
  }

  resetPatientRegistrationForm(): any {
    this.patientImage = '';
    // if (this.isClear === false) {
    //   toastr.error('Select confirmation');
    //   return;
    // }

    this.modalService.dismissAll(this.clearConfirmModal);

    this.patient = this.patientFields;
    this.PatientRegistrationForm.reset({
      title: 'mr',
      first_name: '',
      last_name: '',
      father_name: '',
      gender: 'male',
      patient_nic: '',
      near_by_city: '',
      address1: '',
      phone1: '',
      phone2: '',
      phone1_type: 'mobile',
      patient_type_id: '',
      company_id: '',
      group_id: '',
      pat_age_type: 'years',
      pat_age_value: '',
      blood_group: '',
      guardian_nic: '',
      family_mrn: '',
      email: '',
      country: '',
      state: '',
      city: '',
      birth_day: '',
      birth_month: '',
      birth_year: '',
      unidentify_patient: '',
      guardian_relationship: '',
      guardian_first_name: '',
      guardian_last_name: '',
      age: '',
      marital_status: '',
      unidentify: '',
      family_name: '',
      mlc: '',
      policeman_name: '',
      belt_number: '',
      police_station_number: '',
      guardian_state: '',
      guardian_near_by_city: '',
      guardian_city: '',
      guardian_address: '',
      guardian_phone_type: 'mobile',
      guardian_phone: '',
      dob: '',
      referred_to: '',
      sap_id: '',
      old_mrn: '',
      country_id: '',
      state_id: '',
      location_id: '',
      city_id: '',
      clinic_id: '',
      patient_passport: '',
      passport_country_id: '',
      race: '',
      ethnicity: '',
      zip_code: '',
      employment_status: '',
      account_status: 'current',
      written_contact_preference: 'postal_mail',
      alternate_contact: '',
      ssn: '',
      alternate_contact_dial_code: '',
      alternate_contact_dial_code_name: 'pk',
    });
    this.submitted = false;
    this.isGuardian = false;
    this.isDisable = false;
    this.getCountries();
    // this.resetAddress();
    this.resetPatientTypes();
  }

  closeResetModal(): any {
    this.modalService.dismissAll(this.clearConfirmModal);
  }

  setContactValidation(): any {
    const phone1Control = this.PatientRegistrationForm.get('phone1');
    this.PatientRegistrationForm.get('phone1_type').valueChanges.subscribe(
      (phone1_type) => {
        if (phone1_type === 'landline') {
          phone1Control.setValidators([Validators.required]);
        } else {
          phone1Control.setValidators([
            Validators.minLength(11),
            Validators.required,
          ]);
        }
      },
    );
  }
  calculateAge(): void {
    const dateValue = $('#patientAge').val();
    if (!dateValue) return;
    const date = moment(dateValue).format('MM-DD-YYYY');
    this.PatientRegistrationForm.get('dob').setValue(date);
    let ageValue = this.getDateType(date);
    console.log('ageValue', ageValue);
    this.age_value = ageValue.value;
    this.selected_age_type = ageValue.type;
    this.PatientRegistrationForm.get('pat_age_value').setValue(ageValue.value);
    this.PatientRegistrationForm.get('pat_age_type').setValue(ageValue.type);
  }

  getDateType(date): any {
    console.log('date format', date);
    const dobMoment = moment(date, 'MM-DD-YYYY');
    const now = moment();

    const years = now.diff(dobMoment, 'years');
    if (years > 0) {
      return { value: years, type: 'years' };
    }

    const months = now.diff(dobMoment, 'months');
    if (months > 0) {
      return { value: months, type: 'months' };
    }

    const days = now.diff(dobMoment, 'days');
    if (days > 0) {
      return { value: days, type: 'days' };
    }

    const hours = now.diff(dobMoment, 'hours');
    return { value: hours, type: 'hours' };
  }
  setDOBfromAge(): void {
    const dob = moment().subtract(
      this.PatientRegistrationForm.value.pat_age_value,
      this.PatientRegistrationForm.value.pat_age_type,
    );
    this.patient_dob = dob.format('MM-DD-YYYY'); // Format the date
    $('#patientAge').datepicker('update', this.patient_dob);
  }
  fetch_company_groups(value): any {
    this.groups = [];
    this.private_group_id = '';
    const companiesResponse = this.companies.filter(
      (t) => t.id === Number(value) || t.id === Number(value),
    )[0];

    this.groups = companiesResponse.groups;
    if (
      this.groups.filter(
        (t) => t.name === 'Private Patients' || t.name === 'Private',
      )[0]
    ) {
      this.private_group_id = this.groups.filter(
        (t) => t.name === 'Private Patients' || t.name === 'Private',
      )[0].id;
    } else if (this.groups.length > 0) {
      this.private_group_id = this.groups[0].id;
    }
  }

  checkPatientType(): any {
    this.PatientSearchForm.get('search_query').enable();
    if (this.PatientSearchForm.value.type === 'thumb') {
      $('#patient_search_here').unmask('99-##');
      this.PatientSearchForm.get('search_query').disable();
    } else if (this.PatientSearchForm.value.type === 'patient_nic') {
      $('#patient_search_here').unmask('99-##');
      $('#patient_search_here').mask('99999-9999999-9');
    } else if (this.PatientSearchForm.value.type === 'mrn') {
      $('#patient_search_here').unmask('99999-9999999-9');
      $('#patient_search_here').mask('99-##');
    } else {
      $('#patient_search_here').unmask('99999-9999999-9');
      $('#patient_search_here').unmask('99-##');
    }
  }

  fetchPatientInfo(patient, ind, prePatientVerify): any {
    setTimeout((e) => {
      $('.country').selectpicker('refresh');
      $('.country').selectpicker();
      $('.state').selectpicker('refresh');
      $('.state').selectpicker();
      $('.city').selectpicker('refresh');
      $('.city').selectpicker();
      $('.location').selectpicker('refresh');
      $('.location').selectpicker();
    }, 200);
    this.isPatientDeceased = patient.is_deceased;
    if (!(this.countriesList.length > 0)) {
      return;
    }
    this.currentPatientInd = ind;
    // if (this.backFromService === false ) {
    if (this.patient && prePatientVerify) {
      if (patient.id === this.patient.id) {
        return;
      }
    }
    if (prePatientVerify === true) {
      if (!this.patient_types.find((m) => m.id === patient.patient_type_id)) {
        toastr.error('You dont have permission to access this patient');
        return;
      }
    }
    this.billingTab = false;
    this.zakatEligibility = false;
    this.familyTab = false;
    this.documentsTab = false;
    this.appointmentsTab = false;
    this.bookingTab = false;
    this.consentTab = false;
    // }
    this.PatientRegistrationForm.controls.family_mrn.setValue('');
    this.PatientRegistrationForm.controls.guardian_relationship.setValue('');
    this.family_patient = '';
    this.patient = JSON.parse(JSON.stringify(patient));
    if (this.pattern){
      $('#phone1').unmask(this.pattern).trigger('input');
    }
    this.PatientRegistrationForm.patchValue({
      title: this.patient.title,
      first_name: this.patient.first_name,
      last_name: this.patient.last_name,
      father_name: this.patient.father_name,
      gender: this.patient.gender,
      pat_age_type: this.patient.pat_age_type,
      patient_nic: this.patient.patient_nic,
      address1: this.patient.address1,
      phone1: this.patient.phone1,
      phone2: this.patient.phone2,
      phone1_type: this.patient.phone1_type,
      blood_group: this.patient.blood_group,
      family_mrn: this.patient.family_mrn,
      family_name: this.patient.family_name,
      guardian_relationship: this.patient.guardian_relationship,
      email: this.patient.email,
      marital_status: this.patient.marital_status,
      unidentify: this.patient.unidentify,
      mlc: this.patient.mlc,
      sap_id: this.patient.sap_id,
      old_mrn: this.patient.old_mrn,
      country_id: this.patient.country_id,
      state_id: this.patient.state_id,
      city_id: this.patient.city_id,
      location_id: this.patient.location_id,
      clinic_id: this.patient.clinic_id,
      patient_passport: this.patient.patient_passport,
      passport_country_id: this.patient.passport_country_id,
      race: this.patient.race || '',
      ethnicity: this.patient.ethnicity || '',
      zip_code: this.patient.zip_code || '',
      employment_status: this.patient.employment_status || '',
      account_status: this.patient.account_status || 'current',
      written_contact_preference:
        this.patient.written_contact_preference || 'postal_mail',
      alternate_contact_dial_code: this.patient.alternate_contact_dial_code,
      alternate_contact_dial_code_name:
        this.patient.alternate_contact_dial_code_name,
      alternate_contact: this.patient.alternate_contact || '',
      ssn: this.patient.ssn || '',
    });
    $('#nic').mask('99999-9999999-9').trigger('input');
    $('#ssn').mask('999-99-9999').trigger('input');
    setTimeout(() => {
      $('#ethnicity').selectpicker('refresh');
      $('#race').selectpicker('refresh');
    }, 500);
    this.initializePhone();
    this.setPatientType();
    if (patient.parent) {
      this.family_patient =
        patient.parent.first_name + '' + patient.parent.last_name;
      this.PatientRegistrationForm.controls.family_mrn.setValue(
        patient.parent.mrn,
      );
      this.PatientRegistrationForm.controls.guardian_relationship.setValue(
        patient.guardian_relationship,
      );
      this.PatientRegistrationForm.value.guardian_relationship = 'Father';
    }
    this.isMLC = false;
    this.checkGuardian(this.patient.guardian_first_name);
    this.patient_dob =
      '' +
      this.patient.birth_month +
      '-' +
      this.patient.birth_day +
      '-' +
      this.patient.birth_year;
    this.PatientRegistrationForm.get('dob').setValue(this.patient_dob);
    $('#patientAge').datepicker('update', this.patient_dob);
    this.calculateAge();
    const country = this.countriesList.find(
      (t) => t.id === Number(this.patient.country_id),
    );
    this.pattern = country.pattern;
    if (!country.pattern) {
      const maxLength = this.f.phone1_type.value === 'mobile' ? 11 : 25;
      const minLength = this.f.phone1_type.value === 'mobile' ? 11 : 1;
      this.PatientRegistrationForm.get('phone1').setValidators([
        Validators.required,
        Validators.minLength(minLength),
        Validators.maxLength(maxLength),
      ]);
      this.PatientRegistrationForm.get('phone1').updateValueAndValidity();
      $('#phone1').unmask(this.pattern).trigger('input');
    } else {
      this.PatientRegistrationForm.get('phone1').clearValidators();
      this.PatientRegistrationForm.get('phone1').updateValueAndValidity();
      $('#phone1').mask(country.pattern).trigger('input');
    }
    this.getState(this.patient.country_id, true);
    // this.setPatientAddress();
    setTimeout(() => {
      this.chRef.detectChanges();
    }, 500);
    this.submitted = false;
    if (this.patient.panel_patient_type) {
      this.panelType = this.patient.panel_patient_type.credit;
    }
    this.closePatientList();
    this.patientMrn = patient;
  }
  setPatientType(): any {
    this.companies = [];
    this.groups = [];
    const response = this.patient_types.filter(
      (t) => t.id === this.patient.patient_type_id,
    )[0];
    this.companies = response.companies;
    const companiesResponse = this.companies.filter(
      (t) => t.id === this.patient.company_id,
    )[0];
    this.groups = companiesResponse.groups;

    this.private_patient_type_id = this.patient.patient_type_id;
    this.privete_company_id = this.patient.company_id;
    this.private_group_id = this.patient.group_id;

    this.PatientRegistrationForm.patchValue({
      patient_type_id: this.private_patient_type_id,
      company_id: this.privete_company_id,
      group_id: this.private_group_id,
    });
  }

  addServices(patient): any {
    $('#patientFoundAgainstNumberModal').modal('hide');
    // $('#patientAddSuccessfully').modal('hide');
    this.modalService.dismissAll(this.newPatientModal);
    this.ngxLoader.start();
    const url_string = decodeURIComponent(
      '/patient/services-new?patient_id=' + patient.mrn,
    );
    this.router.navigateByUrl(url_string);
  }

  updateClinicNumber(obj_id, j): any {
    for (let i = 0; i < this.doctors.length; i++) {
      if (this.doctors[i].id == obj_id) {
        this.clinic_number[j] = this.doctors[i].clinic_number;
      }
    }
  }

  familyPatient(e): any {
    if (e.target.value.length === 8) {
      this.ParentPatientSearchForm.value.search_query =
        this.PatientRegistrationForm.value.family_mrn;
      if (this.ParentPatientSearchForm.value.search_query.length == 11) {
        this.ParentPatientSearchForm.value.type = 'phone1';
      } else if (this.ParentPatientSearchForm.value.search_query.length == 8) {
        this.ParentPatientSearchForm.value.type = 'mrn';
      } else if (this.ParentPatientSearchForm.value.search_query.length == 13) {
        this.ParentPatientSearchForm.value.type = 'patient_nic';
      }
      this.fetchFamilyPatients();
    }
  }

  fetchFamilyPatients(): any {
    this.ngxLoader.start();
    let referal = false;
    if (this.current_url === '/front-desk') {
      referal = true;
    }
    this.family_patient = '';
    this.patientService
      .searchPatientByTypeSearch(this.ParentPatientSearchForm.value, referal)
      .subscribe(
        (data) => {
          if (data.patients) {
            this.family_patient =
              data.patients[0].first_name + ' ' + data.patients[0].last_name;
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  fetchPatients(): any {
    if (this.PatientSearchForm.value.type === 'thumb') {
      this.verifyThumbImpression();
      return;
    }
    this.patients = [];
    this.isPatients = false;
    this.ngxLoader.start();
    this.PatientSearchForm.get('search_query')?.setValue(
      this.PatientSearchForm.get('search_query')?.value.replace(/-/g, ''),
    );
    let referal = false;
    if (this.current_url === '/front-desk') {
      referal = true;
    }
    this.patientService
      .searchPatientRegistrationV2(this.PatientSearchForm.value, referal)
      .subscribe(
        (data) => {
          this.patients = data.patients;
          this.isPatients = true;
          if (this.PatientSearchForm.value.type === 'patient_nic') {
            $('#patient_search_here').mask('99999-9999999-9').trigger('input');
          } else if (this.PatientSearchForm.value.type === 'mrn') {
            $('#patient_search_here').mask('99-##').trigger('input');
          } else {
            $('#patient_search_here')
              .unmask('99999-9999999-9')
              .trigger('input');
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  updateUnidentified(): any {
    // this.PatientRegistrationForm.controls['first_name'].clearValidators();
    // this.PatientRegistrationForm.controls['first_name'].setErrors(null);
    // this.PatientRegistrationForm.controls['first_name'].setValidators(null);
  }
  newRegistration(): any {
    this.isDisable = true;
    if (this.PatientRegistrationForm.value.dob) {
      this.PatientRegistrationForm.get('birth_month').setValue(
        this.PatientRegistrationForm.value.dob.split('-')[0],
      );
      this.PatientRegistrationForm.get('birth_day').setValue(
        this.PatientRegistrationForm.value.dob.split('-')[1],
      );
      this.PatientRegistrationForm.get('birth_year').setValue(
        this.PatientRegistrationForm.value.dob.split('-')[2],
      );
    }
    if (this.PatientRegistrationForm.value.pat_age_type != '') {
      this.PatientRegistrationForm.get('age').setValue(
        this.PatientRegistrationForm.value.pat_age_value +
          ' ' +
          this.PatientRegistrationForm.value.pat_age_type,
      );
    } else {
      this.PatientRegistrationForm.get('age').setValue(
        this.PatientRegistrationForm.value.pat_age_value +
          ' ' +
          this.selected_age_type,
      );
    }
    if (this.PatientRegistrationForm.get('patient_nic')?.value) {
      this.PatientRegistrationForm.get('patient_nic')?.setValue(
        this.PatientRegistrationForm.get('patient_nic')?.value.replace(
          /-/g,
          '',
        ),
      );
    }
    if ($('#phone1').val()) {
      this.PatientRegistrationForm.get('phone1')?.setValue(
        $('#phone1').val().replace(/-/g, ''),
      );
    }
    if (this.PatientRegistrationForm.get('ssn')?.value) {
      this.PatientRegistrationForm.get('ssn')?.setValue(
        this.PatientRegistrationForm.get('ssn')?.value.replace(/-/g, ''),
      );
    }
    this.submitted = true;
    if (this.PatientRegistrationForm.invalid) {
      this.isDisable = false;
      return;
    }
    if (this.isMLC) {
      this.PatientRegistrationForm.value.mlc = true;
    }
    this.PatientRegistrationForm.get('referred_to').setValue('OPD');
    this.isPatients = false;
    const first: any = this.PatientRegistrationForm.get('first_name').value;
    const last: any = this.PatientRegistrationForm.get('last_name').value;
    let resultFirst = '';
    let resultLast = '';
    resultFirst = this.titleCasePipe.transform(first);
    resultLast = this.titleCasePipe.transform(last);

    this.PatientRegistrationForm.get('first_name').setValue(resultFirst);
    this.PatientRegistrationForm.get('last_name').setValue(resultLast);

    // this.patients = [];
    const alternateContact = $('#alternate_contact').intlTelInput(
      'getSelectedCountryData',
    );
    this.PatientRegistrationForm.get('alternate_contact_dial_code')?.patchValue(
      alternateContact.dialCode,
    );
    this.PatientRegistrationForm.get(
      'alternate_contact_dial_code_name',
    )?.patchValue(alternateContact.iso2);
    this.ngxLoader.start();
    this.patientService.createV2(this.PatientRegistrationForm.value).subscribe(
      (data) => {
        this.patient = data.patient;
        if (this.patientImage) {
          this.uploadProfileImage('add');
        } else {
          this.patientAddGenerateComplete();
        }
      },
      (err) => {
        this.isDisable = false;
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  patientAddGenerateComplete() {
    $('#nic').mask('99999-9999999-9').trigger('input');
    $('#ssn').mask('999-99-9999').trigger('input');
    if (this.pattern) {
      $('#phone1').mask(this.pattern).trigger('input');
    }
    this.isDisable = false;
    this.patients.unshift(this.patient);
    this.isPatients = true;
    this.ngxLoader.stop();
    // this.fetchPatients();
    if (this.patient.registration_invoice_id > 0) {
      window.open(
        this.current_environment +
          '/v4/patient_services/' +
          this.patient.registration_invoice_id +
          '/patient_services_invoice_slip.pdf?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        '_blank',
      );
      this.message =
        'Patient has been successfully registered with MRN ' + this.patient.mrn;
    } else {
      this.message =
        'Patient has been successfully registered with MRN ' +
        this.patient.mrn +
        ' but no Price configured';
    }
    // $('#patientAddSuccessfully').modal('show');
    this.modalService.open(this.newPatientModal);
  }

  uploadProfileImage(type) {
    this.patientService
      .uploadProfileImage(
        this.patientImage,
        '',
        this.patient.id,
        'patient_info',
      )
      .subscribe(
        (dataImage) => {
          this.patient.profile_image = dataImage.patient_image;
          this.patientImage = '';
          if (type === 'add') {
            this.patientAddGenerateComplete();
          } else if (type === 'update') {
            this.patientUpdateComplete();
          }
        },
        (err) => {
          this.isDisable = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  updatePatient(): any {
    console.log('PatientRegistrationForm', this.PatientRegistrationForm.value);
    if (this.PatientRegistrationForm.value.dob) {
      this.PatientRegistrationForm.get('birth_month').setValue(
        this.PatientRegistrationForm.value.dob.split('-')[0],
      );
      this.PatientRegistrationForm.get('birth_day').setValue(
        this.PatientRegistrationForm.value.dob.split('-')[1],
      );
      this.PatientRegistrationForm.get('birth_year').setValue(
        this.PatientRegistrationForm.value.dob.split('-')[2],
      );
    }
    if (this.PatientRegistrationForm.value.pat_age_type != '') {
      this.PatientRegistrationForm.get('age').setValue(
        this.PatientRegistrationForm.value.pat_age_value +
          ' ' +
          this.PatientRegistrationForm.value.pat_age_type,
      );
    } else {
      this.PatientRegistrationForm.get('age').setValue(
        this.PatientRegistrationForm.value.pat_age_value +
          ' ' +
          this.selected_age_type,
      );
    }
    if (this.PatientRegistrationForm.get('patient_nic')?.value) {
      this.PatientRegistrationForm.get('patient_nic')?.setValue(
        this.PatientRegistrationForm.get('patient_nic')?.value.replace(
          /-/g,
          '',
        ),
      );
    }
    if ($('#phone1').val()) {
      this.PatientRegistrationForm.get('phone1')?.setValue(
        $('#phone1').val().replace(/-/g, ''),
      );
    }
    if (this.PatientRegistrationForm.get('ssn')?.value) {
      this.PatientRegistrationForm.get('ssn')?.setValue(
        this.PatientRegistrationForm.get('ssn')?.value.replace(/-/g, ''),
      );
    }
    this.submitted = true;
    if (this.PatientRegistrationForm.invalid) {
      return;
    }
    if (this.isMLC) {
      this.PatientRegistrationForm.value.mlc = true;
    }
    this.isPatients = false;
    const first: any = this.PatientRegistrationForm.get('first_name').value;
    const last: any = this.PatientRegistrationForm.get('last_name').value;
    let resultFirst = '';
    let resultLast = '';
    resultFirst = this.titleCasePipe.transform(first);
    resultLast = this.titleCasePipe.transform(last);

    this.PatientRegistrationForm.get('first_name').setValue(resultFirst);
    this.PatientRegistrationForm.get('last_name').setValue(resultLast);

    // this.patients = [];
    const alternateContact = $('#alternate_contact').intlTelInput(
      'getSelectedCountryData',
    );
    this.PatientRegistrationForm.get('alternate_contact_dial_code')?.patchValue(
      alternateContact.dialCode,
    );
    this.PatientRegistrationForm.get(
      'alternate_contact_dial_code_name',
    )?.patchValue(alternateContact.iso2);
    this.ngxLoader.start();
    this.patientService
      .updateV2(this.PatientRegistrationForm.value, this.patient.id)
      .subscribe(
        (data) => {
          this.patient = data.patient;
          if (this.patientImage) {
            this.uploadProfileImage('update');
          } else {
            this.patientUpdateComplete();
          }
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  patientUpdateComplete() {
    $('#nic').mask('99999-9999999-9').trigger('input');
    $('#ssn').mask('999-99-9999').trigger('input');
    if(this.pattern){
      $('#phone1').mask(this.pattern).trigger('input');
    }
    if (this.patients[this.currentPatientInd]?.id === this.patient?.id) {
      this.patients[this.currentPatientInd] = this.patient;
    }
    this.isPatients = true;
    this.ngxLoader.stop();
    toastr.success('Successfully Updated');
  }

  onChangeMLC(event): any {
    this.isMLC = !!event.target.checked;
    this.PatientRegistrationForm.controls.policeman_name.reset();
    this.PatientRegistrationForm.controls.police_station_number.reset();
    this.PatientRegistrationForm.controls.belt_number.reset();
    // <any>$(".mlc-div").slideToggle();
  }

  onChangeGuardian(): any {
    this.isGuardian = !this.isGuardian;
    // this.PatientRegistrationForm.controls.guardian_first_name.reset();
    // this.PatientRegistrationForm.controls.guardian_relationship.reset('');
    // this.PatientRegistrationForm.controls.guardian_nic.reset();
    // this.PatientRegistrationForm.controls.guardian_near_by_city.reset();
    // this.PatientRegistrationForm.controls.guardian_address.reset();
    // this.PatientRegistrationForm.controls.guardian_phone.reset();
  }

  onChangetype(value): any {
    this.companies = [];
    this.groups = [];
    this.privete_company_id = '';
    this.private_group_id = '';

    const response = this.patient_types.filter(
      (t) => t.id === Number(value) || t.id === Number(value),
    )[0];
    this.companies = response.companies;
    let companiesResponse;
    if (this.companies.filter((t) => t.is_default === true)[0]) {
      companiesResponse = this.companies.filter(
        (t) => t.is_default === true,
      )[0];
    } else {
      companiesResponse = this.companies[0];
    }
    this.privete_company_id = companiesResponse.id;

    this.groups = companiesResponse.groups;
    if (this.groups.filter((t) => t.is_default === true)[0]) {
      this.private_group_id = this.groups.filter(
        (t) => t.is_default === true,
      )[0].id;
    } else if (this.groups.length > 0) {
      this.private_group_id = this.groups[0].id;
    }
  }

  checkGuardian(guardian_first_name): any {
    if (guardian_first_name) {
      this.isGuardian = true;
      this.PatientRegistrationForm.patchValue({
        // guardian_relationship: this.patient.guardian_relationship,
        guardian_first_name: this.patient.guardian_first_name,
        guardian_nic: this.patient.guardian_nic,
        guardian_near_by_city: this.patient.guardian_near_by_city,
        guardian_address: this.patient.guardian_address,
        guardian_phone: this.patient.guardian_phone,
      });
    } else {
      this.isGuardian = false;
      this.PatientRegistrationForm.controls.guardian_first_name.reset();
      // this.PatientRegistrationForm.controls.guardian_relationship.reset('');
      this.PatientRegistrationForm.controls.guardian_nic.reset();
      this.PatientRegistrationForm.controls.guardian_near_by_city.reset();
      this.PatientRegistrationForm.controls.guardian_address.reset();
      this.PatientRegistrationForm.controls.guardian_phone.reset();
    }
  }
  printPrescription(): any {
    window.open(
      environment.rorUrl +
        '/v4/patients/' +
        this.patient.id +
        '/prescription_slip.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
    );
  }

  get f(): any {
    return this.PatientRegistrationForm.controls;
  }
  get j(): any {
    return this.PatientSearchForm.controls;
  }

  // For Web Cam

  // cambox(): any {
  //   navigator.mediaDevices.getUserMedia( { video: true } )
  //     .then( ( stream ) => {
  //
  //         $('#imgModal').modal('show');
  //
  //         WebcamUtil.getAvailableVideoInputs()
  //           .then((mediaDevices: MediaDeviceInfo[]) => {
  //             this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
  //           });
  //         this.showImage = false;
  //       },
  //       e => {
  //         this._flashMessagesService.show('Allow camera permission', { cssClass: 'alert-danger', timeout: 3000 });
  //         // microphone not available
  //       } );
  //   // $('#imgModal').modal('show');
  //   //
  //   // WebcamUtil.getAvailableVideoInputs()
  //   //   .then((mediaDevices: MediaDeviceInfo[]) => {
  //   //     this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
  //   //   });
  //   // this.showImage = false;
  // }
  // triggerSnapshot(): void {
  //   this.trigger.next();
  //   this.showImage = true;
  // }
  //
  // toggleWebcam(): void {
  //   this.showWebcam = !this.showWebcam;
  // }
  //
  // handleInitError(error: WebcamInitError): void {
  //   if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
  //   } else {
  //   }
  //   this.errors.push(error);
  // }
  //
  // handleImage(webcamImage: WebcamImage): void {
  //   this.webcamImage = webcamImage;
  // }
  // cameraWasSwitched(deviceId: string): void {
  //   this.deviceId = deviceId;
  // }
  // public get triggerObservable(): Observable<void> {
  //   return this.trigger.asObservable();
  // }
  //
  // resetImageBox(): any {
  //   this.showImage = false;
  // }
  // captureImageComplete(): any {
  //   this.patientImage = this.webcamImage.imageAsDataUrl;
  //   $('#imgModal').modal('hide');
  // }
  // public get nextWebcamObservable(): Observable<boolean|string> {
  //   return this.nextWebcam.asObservable();
  // }

  // stop() {
  //   var stream = video.srcObject;
  //   var tracks = stream.getTracks();
  //
  //   for (var i = 0; i < tracks.length; i++) {
  //     var track = tracks[i];
  //     track.stop();
  //   }
  //
  //   video.srcObject = null;
  // }

  onUnidentify(): any {
    // tslint:disable-next-line:no-shadowed-variable
    // this.PatientRegistrationForm.controls.unidentify.valueChanges.subscribe( value => {
    // });

    if (this.PatientRegistrationForm.value.unidentify === true) {
      const firstName = this.PatientRegistrationForm.get('first_name');
      firstName.setValidators([]);
      firstName.updateValueAndValidity();

      const lastName = this.PatientRegistrationForm.get('last_name');
      lastName.setValidators([]);
      lastName.updateValueAndValidity();
    } else {
      const firstName = this.PatientRegistrationForm.get('first_name');
      firstName.setValidators([Validators.required]);
      firstName.updateValueAndValidity();

      const lastName = this.PatientRegistrationForm.get('last_name');
      lastName.setValidators([Validators.required]);
      lastName.updateValueAndValidity();
    }
  }

  checkAge(event: any): any {
    const value = event.target.value;
    if (value > 150) {
      this.PatientRegistrationForm.get('pat_age_value').patchValue(150);
      event.preventDefault();
    }
  }

  // openModal(newPatientModal: TemplateRef<any>): any {
  //   this.modalService.open(newPatientModal);
  // }
  //
  // closeModal(newPatientModal: TemplateRef<any>): any {
  //   this.modalService.dismissAll(newPatientModal);
  // }
  //
  // openModal1(conformPatientAdmissionModal: TemplateRef<any>): any {
  //   this.modalService.open(conformPatientAdmissionModal);
  // }

  // For Web Cam

  cambox(): any {
    // this boolean is not in this method before
    this.showImage = false;
    // this servoce open line inside the next method before
    this.modalService.open(this.imageModal);

    navigator.mediaDevices.getUserMedia({ video: true }).then(
      (stream) => {
        WebcamUtil.getAvailableVideoInputs().then(
          (mediaDevices: MediaDeviceInfo[]) => {
            this.multipleWebcamsAvailable =
              mediaDevices && mediaDevices.length > 1;
          },
        );
        this.showImage = false;
      },
      (e) => {
        toastr.error('Allow camera permission');
        // microphone not available
      },
    );
    // $('#imgModal').modal('show');
    //
    // WebcamUtil.getAvailableVideoInputs()
    //   .then((mediaDevices: MediaDeviceInfo[]) => {
    //     this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    //   });
    // this.showImage = false;
  }
  triggerSnapshot(): void {
    this.trigger.next();
    this.showImage = true;
  }

  toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  handleInitError(error: WebcamInitError): void {
    if (
      error.mediaStreamError &&
      error.mediaStreamError.name === 'NotAllowedError'
    ) {
    } else {
    }
    this.errors.push(error);
  }

  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }
  cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  resetImageBox(): any {
    this.showImage = false;
  }
  captureImageComplete(): any {
    this.patientImage = this.webcamImage.imageAsDataUrl;
    if (this.patient) {
      this.patient.profile_image = '';
    }
    this.modalService.dismissAll(this.imageModal);
  }
  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  closeModal(modal): any {
    this.modalService.dismissAll(modal);
  }

  // side bar patient list
  openPatientList(): any {
    $('#patient-list').toggleClass('offcanvas-on');
  }

  closePatientList(): any {
    $('#patient-list').removeClass('offcanvas-on');
  }

  toggleTabs(type): any {
    if (type === 'zakat') {
      this.zakatEligibility = true;
    }
  }
  billingCall(): any {
    this.billingTab = true;
  }
  familyCall(): any {
    this.familyTab = true;
  }
  documentsCall(): any {
    this.documentsTab = true;
  }
  appointmentsCall(): any {
    this.appointmentsTab = true;
  }
  bookingCall(): any {
    this.bookingTab = true;
  }
  consentCall(): any {
    this.consentTab = true;
  }

  // ======== services paid to unPaid ==========
  getConfigTypeRace(type): any {
    this.raceList = [];
    this.configuratorsService.fetchConfigurationV2(type).subscribe(
      (data) => {
        if (data.configurator_types) {
          this.raceList = data.configurator_types;
        }
        setTimeout(() => {
          $('#race').selectpicker('refresh');
          $('#race').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getConfigTypeEthnicity(type): any {
    this.ethnicityList = [];
    this.configuratorsService.fetchConfigurationV2(type).subscribe(
      (data) => {
        if (data.configurator_types) {
          this.ethnicityList = data.configurator_types;
        }
        setTimeout(() => {
          $('#ethnicity').selectpicker('refresh');
          $('#ethnicity').selectpicker();
        }, 500);
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  searchPatientByPhoneNumber(): any {
    if ($('#phone1').val()) {
      this.PatientRegistrationForm.get('phone1')?.setValue(
        $('#phone1').val().replace(/-/g, ''),
      );
    }
    const obj = {
      search_query: this.PatientRegistrationForm.value.phone1,
      type: 'phone1',
    };
    this.patientService.searchPatientRegistrationV2(obj, false).subscribe(
      (data) => {
        if (data.patients.length > 0) {
          $('#patientFoundAgainstNumberModal').modal('show');
          this.patientAgainstPhoneNo = data.patients;
          setTimeout(() => {
            document.getElementById('patientFoundAgainstNumberBtn').focus();
          }, 500);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  patientFoundAgainstNumber(): any {
    $('#patientFoundAgainstNumberModal').modal('hide');
    this.isPatients = true;
    this.patients = this.patientAgainstPhoneNo;
  }
  getThumbImpression() {
    if (!this.patient) {
      toastr.error('Select patient');
      return;
    }
    let patientData = {
      is_attendance: 0,
      patient_id: this.patient.id,
      redirect_url: '',
      api_url:
        this.current_environment_python +
        '/api/upload_patient_thumb_expression/',
      data_url: '',
    };
    let jsonData = JSON.stringify(patientData);
    let utf8Bytes = new TextEncoder().encode(jsonData);
    let base64String = btoa(String.fromCharCode.apply(null, utf8Bytes));
    window.open('digitalpersona:' + base64String, '_blank');
  }
  verifyThumbImpression(): any {
    let patientData = {
      is_attendance: 0,
      patient_id: '',
      redirect_url:
        window.location.origin + '/patient/registration-v2?patient_mrn=',
      api_url: '',
      data_url:
        this.current_environment_python +
        '/api/get_patient_for_fingerprint_verification?medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      background_image:
        this.current_environment_python +
        '/hr/get_background_image?medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
    };
    let jsonData = JSON.stringify(patientData);
    let utf8Bytes = new TextEncoder().encode(jsonData);
    let base64String = btoa(String.fromCharCode.apply(null, utf8Bytes));
    window.open('digitalpersona:' + base64String, '_blank');
  }

}
