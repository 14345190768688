<div class="d-flex flex-column-fluid main-content" *ngIf="!searchCard">
  <div class="container">
    <div class="card card-custom gutter-b d-block d-print-none">
      <div class="card-body mt-3">
        <div class="table_top_bar mb-3 hide-print-content">
          <h3 class="text-center marginnn" style="margin-bottom: 20px">
            Worker's Medical Report Verification
          </h3>
          <!--          <form [formGroup]="PatientSearchForm" (ngSubmit)='getWorkerData()'>-->
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-12">
              <div class="form-group">
                <label>Worker ID</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="Worker ID"
                  [(ngModel)]="mrn"
                />
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-6 col-12">
              <div class="form-group">
                <div class="mt-3 text-left">
                  <button
                    type="button"
                    class="btn btn-light-primary btn-sm px-6 font-weight-bold"
                    ngbTooltip="Search Filter"
                    placement="bottom"
                    (click)="getWorkerData()"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--          </form>-->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex flex-column-fluid main-content" *ngIf="workerData">
  <!--begin::Container-->
  <div class="container">
    <!--begin:: Content-->
    <div class="card pdf-card" id="reports">
      <div class="card-body">
        <div
          class="d-print-none"
          style="text-align: right; margin-top: -18px; margin-bottom: 8px"
        >
          <button class="btn btn-primary btn-sm" (click)="printReport()">
            Print
          </button>
        </div>
        <!--Donor Details Head Card :: END-->
        <div class="d-flex">
          <!--Patent information-->
          <div style="width: 80%">
            <!-- header table -->
            <table class="table">
              <tbody>
                <tr class="borders">
                  <td style="width: 15%">
                    <img
                      [src]="settingData?.logo?.url"
                      style="width: auto; height: 106px"
                    />
                  </td>
                  <td style="width: 85%">
                    <div class="text-center">
                      <h2 style="font-size: 28px">{{ settingData?.title }}</h2>
                    </div>
                    <div class="font-size-h5 text-center">
                      <h5 style="font-size: 17px">
                        {{ settingData?.address }}
                      </h5>
                    </div>
                    <div class="font-size-h5 text-center">
                      <h5 style="font-size: 20px">
                        <strong>Worker's Medical Report Verification</strong>
                      </h5>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- patient info table -->
            <!--              <table class="w-100 info_table mb-2">-->
            <!--                <tbody>-->
            <!--                <tr>-->
            <!--&lt;!&ndash;                  <td style="width: 45%"><h3  class="m-0 line-clamp font-print-bold mr-5">Muzammil hussain 22year, Male</h3></td>&ndash;&gt;-->
            <!--                  <td style="width: 45%"><h3  class="m-0 line-clamp font-print-bold mr-5">{{ workerData?.first_name | titlecase }} {{ workerData?.last_name | titlecase }} {{ workerData?.age + ',' }} {{ workerData?.gender  | titlecase }}</h3></td>-->
            <!--                  <td style="width: 55%"><h3 class="m-0 font-print-bold">MRN# {{workerData?.mrn}}</h3></td>-->
            <!--                </tr>-->
            <!--                </tbody>-->
            <!--              </table>-->
            <!--               patient info detail table -->
            <table class="w-100 info_table">
              <tbody>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Worker Name:</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.first_name | titlecase }}
                    {{ workerData?.last_name | titlecase }}
                  </td>
                  <!--                  <td style="width: 20%" class="paraFont">{{ donorInvoiceData?.patient?.father_name || '&#45;&#45;' | titlecase }}</td>-->
                  <td style="width: 40%"></td>
                  <!--                  <td style="width: 20%" class="paraFont">{{donorInvoiceData?.request_no || 'N/A'}}</td>-->
                  <!--                  <td style="width: 20%"></td>-->
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Worker ID:</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.mrn }}
                  </td>
                  <!--                  <td style="width: 20%" class="paraFont">{{ donorInvoiceData?.patient?.father_name || '&#45;&#45;' | titlecase }}</td>-->
                  <td style="width: 40%"></td>
                  <!--                  <td style="width: 20%" class="paraFont">{{donorInvoiceData?.request_no || 'N/A'}}</td>-->
                  <!--                  <td style="width: 20%"></td>-->
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Age/Gender:</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.age + "," }}
                    {{ workerData?.gender | titlecase }}
                  </td>
                  <!--                  <td style="width: 20%" class="paraFont">{{ donorInvoiceData?.patient?.father_name || '&#45;&#45;' | titlecase }}</td>-->
                  <td style="width: 40%"></td>
                  <!--                  <td style="width: 20%" class="paraFont">{{donorInvoiceData?.request_no || 'N/A'}}</td>-->
                  <!--                  <td style="width: 20%"></td>-->
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Registration No.</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.sap_id }}
                  </td>
                  <!--                  <td style="width: 20%" class="paraFont">{{ donorInvoiceData?.patient?.father_name || '&#45;&#45;' | titlecase }}</td>-->
                  <td style="width: 40%"></td>
                  <!--                  <td style="width: 20%" class="paraFont">{{donorInvoiceData?.request_no || 'N/A'}}</td>-->
                  <!--                  <td style="width: 20%"></td>-->
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Father Name:</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.father_name }}
                  </td>
                  <td style="width: 40%"></td>
                </tr>
                <!--                <tr>-->
                <!--                  <td style="width: 25%" class="paraFont"><strong>Gender:</strong></td>-->
                <!--                  <td style="width: 25%" class="paraFont">Male</td>-->
                <!--                  <td style="width: 50%"></td>-->
                <!--                </tr>-->
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Passport No:</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.patient_passport }}
                  </td>
                  <td style="width: 40%"></td>
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Type Of Worker:</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.patient_type_name }}
                  </td>
                  <td style="width: 40%"></td>
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Address:</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.address1 }}
                  </td>
                  <td style="width: 40%"></td>
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Country:</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.country_name }}
                  </td>
                  <td style="width: 40%"></td>
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Name Of Recruiting Agency:</strong>
                  </td>
                  <td style="width: 40%" class="paraFont">
                    {{ workerData?.name_of_recruiting_agency }}
                  </td>
                  <td style="width: 30%"></td>
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Address Of Recruiting Agency:</strong>
                  </td>
                  <td style="width: 40%" class="paraFont">
                    {{ workerData?.address_of_recruiting_agency }}
                  </td>
                  <td style="width: 30%"></td>
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>MC Ref. No:</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.ref_no }}
                  </td>
                  <td style="width: 40%"></td>
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Medical Approval Date:</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.medical_approval_date }}
                  </td>
                  <td style="width: 40%"></td>
                </tr>
                <tr>
                  <td style="width: 30%" class="paraFont">
                    <strong>Valid Until:</strong>
                  </td>
                  <td style="width: 25%" class="paraFont">
                    {{ workerData?.valid_until }}
                  </td>
                  <td style="width: 40%"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="width: 20%">
            <div style="margin-top: 8px" *ngIf="workerData?.patient_image">
              <img
                class="qrCode"
                style="height: 100%; width: 100%"
                *ngIf="workerData?.patient_image"
                [src]="workerData?.patient_image"
                alt=""
              />
            </div>
            <div style="" *ngIf="workerData?.qrcode_url.url">
              <img
                class="qrCode"
                style="height: 100%; width: 100%"
                *ngIf="workerData?.qrcode_url.url"
                [src]="workerData?.qrcode_url.url"
                alt=""
              />
            </div>
            <!--                <img src="../assets/media/pdf-images/qr.png" alt="" style="max-height: 100px">-->
            <div *ngIf="workerData?.barcode_url.url">
              <img
                class="brCode"
                *ngIf="workerData?.barcode_url.url"
                [src]="workerData?.barcode_url.url"
                alt=""
              />
            </div>
            <!--              <div class="font-weight-bolder font-size-h3 mrnCode" id="mrn">{{ workerData?.mrn}}</div>-->
          </div>
        </div>
        <!--Donor Details Head Card :: END-->

        <div
          class="my-6 lines"
          style="height: 1px; border-bottom: 1px solid #363535"
        ></div>
        <div style="font-weight: bolder; font-size: 16px">
          I have examined the following details and found that
        </div>
        <div style="font-weight: bolder; font-size: 16px">
          1. He is free from the following Diseases/Conditions:
        </div>
        <div>
          <table class="table font-weight-bold fontSize table-border-none">
            <tr>
              <td style="width: 25%" class="paraFont">HIV/AIDS:</td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions?.hiv_aids === true
                    ? "Yes"
                    : "No"
                }}
              </td>
              <td style="width: 25%" class="paraFont">SYPHILIS:</td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions?.syphilis === true
                    ? "Yes"
                    : "No"
                }}
              </td>
            </tr>
            <tr>
              <td style="width: 25%" class="paraFont">TB:</td>
              <td style="width: 25%" class="paraFont">
                {{ workerData?.disease_conditions?.tb === true ? "Yes" : "No" }}
              </td>
              <td style="width: 25%" class="paraFont">HEPATITIS B:</td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions?.hepatitis_b === true
                    ? "Yes"
                    : "No"
                }}
              </td>
            </tr>
            <tr>
              <td style="width: 25%" class="paraFont">MALARIA:</td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions?.malaria === true
                    ? "Yes"
                    : "No"
                }}
              </td>
              <td style="width: 25%" class="paraFont">EPILEPSY:</td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions?.epilepsy === true
                    ? "Yes"
                    : "No"
                }}
              </td>
            </tr>
            <tr>
              <td style="width: 25%" class="paraFont">LEPROSY:</td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions?.leprosy === true
                    ? "Yes"
                    : "No"
                }}
              </td>
              <td style="width: 25%" class="paraFont">TUMOR/CANCER:</td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions?.tumor_cancer === true
                    ? "Yes"
                    : "No"
                }}
              </td>
            </tr>
            <tr>
              <td style="width: 25%" class="paraFont">
                FREE FROM OTHER CHRONIC DISEASES:
              </td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions
                    ?.free_from_other_chronic_diseases === true
                    ? "Yes"
                    : "No"
                }}
              </td>
              <td style="width: 25%" class="paraFont">
                UNACCEPTABLE CLINICAL FINDINGS IN CHEST X-RAY:
              </td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions
                    ?.unacceptable_clinical_finding_in_chest_xray === true
                    ? "Yes"
                    : "No"
                }}
              </td>
            </tr>
            <tr>
              <td style="width: 25%" class="paraFont">PHYSICAL EXAMINATION:</td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions?.physical_examination === true
                    ? "Yes"
                    : "No"
                }}
              </td>
              <td style="width: 25%" class="paraFont">MENTAL ILLNESS:</td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions?.mental_illness === true
                    ? "Yes"
                    : "No"
                }}
              </td>
            </tr>
            <tr>
              <td style="width: 25%" class="paraFont">HEPATITIS C:</td>
              <td style="width: 25%" class="paraFont">
                {{
                  workerData?.disease_conditions?.hepatitis_c === true
                    ? "Yes"
                    : "No"
                }}
              </td>
              <td style="width: 25%"></td>
              <td style="width: 25%"></td>
            </tr>
          </table>
          <div class="row margins">
            <div class="col-12 mt-2">
              <div class="">
                <span style="font-weight: bolder; font-size: 16px"
                  >2. His urine is found not to contain
                  Opiate/Cannabis/Amphetamine:</span
                >
                <div class="fontSize pl-4 mt-2 paraFont">
                  {{ workerData?.disease_conditions?.urine_test }}
                </div>
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="">
                <span style="font-weight: bolder; font-size: 16px"
                  >3. He is fit to work:</span
                >
                <div class="fontSize pl-4 mt-2 paraFont">
                  {{ workerData?.disease_conditions?.fit_to_work }}
                </div>
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="">
                <span style="font-weight: bolder; font-size: 16px"
                  >4. I therefore recommend that he be considered for
                  employment:
                </span>
                <div class="fontSize pl-4 mt-2 paraFont">
                  {{ workerData?.disease_conditions?.consider_for_employement }}
                </div>
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="">
                <span style="font-weight: bolder; font-size: 16px"
                  >5. Additional information:</span
                >
                <div class="fontSize pl-4 mt-2 paraFont">
                  {{ workerData?.disease_conditions?.additional_information }}
                </div>
              </div>
            </div>
          </div>

          <!--            <div class="margins">-->
          <!--              <label><b>Doctor Signature and Name</b></label>-->
          <!--              <div style="border: 1px solid black; padding: 38px; width: 20%">-->

          <!--              </div>-->
          <!--            </div>-->

          <div class="footer-bottom onScreenMargin">
            <p class="text-center paraFont">
              <b
                >*This is electronically generated report, no signature
                required.</b
              >
            </p>
            <div class="font-weight-bolder paraFont">
              To verify this report, please visit the link
              <a
                href="https://www.hims.camdenhealthsys.com/wmr"
                target="_blank"
                style="color: blue; text-decoration: underline; cursor: pointer"
                >www.hims.camdenhealthsys.com/wmr</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end:: Content-->
  </div>
</div>
