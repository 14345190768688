<div>
  <div class="card mb-4">
    <div class="d-print-block">
      <div class="card-body user-card-body">
        <h4 class="margin-bottom-10 d-print-none">
          Glasgow Coma Scale Assessment
        </h4>
        <div class="row">
          <div class="col-12 mb-6 d-none d-print-block">
            <div style="width: 85%; float: left">
              <!-- header table -->
              <table class="table">
                <tbody>
                  <tr class="borders">
                    <td style="width: 15%">
                      <img
                        [src]="settingData?.logo?.url"
                        style="width: auto; height: 106px"
                      />
                    </td>
                    <td style="width: 85%">
                      <div class="text-center">
                        <h2>{{ settingData?.title }}</h2>
                      </div>
                      <div class="font-size-h5 text-center">
                        <h3>{{ settingData?.address }}</h3>
                      </div>
                      <!--                          <div class="font-size-h5 text-center"><h5><span *ngIf="settingData?.phone_number">UAN: {{settingData?.phone_number}}</span> <span *ngIf="settingData?.fax"> FAX: {{settingData?.fax}}</span></h5></div>-->
                      <div class="text-center">
                        <h3>Glasco Coma Scale Assessment</h3>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- patient info table -->
              <table class="w-100 info_table mb-2">
                <tbody>
                  <tr>
                    <td style="width: 45%">
                      <h3
                        *ngIf="patient"
                        class="m-0 line-clamp font-print-bold mr-5"
                      >
                        {{ patient?.title | titlecase }}
                        {{
                          patient?.first_name + " " + patient?.last_name + ","
                            | titlecase
                        }}
                        {{ patient?.age + "," }}
                        {{ patient?.gender.charAt(0) | titlecase }}
                      </h3>
                    </td>
                    <td style="width: 55%">
                      <h3 class="m-0 font-print-bold">
                        MRN#{{ patient?.mrn }}
                      </h3>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- patient info detail table -->
              <table class="w-100 info_table">
                <tbody>
                  <tr>
                    <td style="width: 15%"><strong>Father/Husband:</strong></td>
                    <td style="width: 20%">
                      {{ patient?.father_name | titlecase }}
                    </td>
                    <td style="width: 10%"></td>
                    <td style="width: 15%"><strong>Patient Type:</strong></td>
                    <td style="width: 20%">
                      {{ patient?.patient_type?.name }}
                    </td>
                    <td style="width: 20%"></td>
                  </tr>
                  <tr>
                    <td style="width: 15%"><strong>Contact:</strong></td>
                    <td style="width: 20%">{{ patient?.phone1 }}</td>
                    <td style="width: 10%"></td>
                    <td style="width: 15%"><strong>Reg. Date:</strong></td>
                    <td style="width: 20%">
                      {{ patient?.created_at | date: "dd-MM-yyyy h:mm a" }}
                    </td>
                    <td style="width: 20%"></td>
                  </tr>
                  <tr>
                    <td style="width: 15%"><strong>CNIC:</strong></td>
                    <td style="width: 20%">{{ patient?.patient_nic }}</td>
                    <td style="width: 10%"></td>
                    <td style="width: 15%"><strong>Consultant:</strong></td>
                    <td style="width: 20%">{{ patient?.consultant_name }}</td>
                    <td style="width: 20%"></td>
                  </tr>
                  <tr>
                    <td style="width: 15%"><strong>Address:</strong></td>
                    <td style="width: 20%">{{ patient?.address1 }}</td>
                    <td style="width: 10%"></td>
                    <td style="width: 15%"><strong>Department:</strong></td>
                    <td style="width: 20%">
                      {{ patient?.admissions[0].referred_to }}
                    </td>
                    <td style="width: 20%"></td>
                  </tr>
                  <tr>
                    <td style="width: 15%"><strong>Admission:</strong></td>
                    <td style="width: 20%">
                      {{
                        patient?.admissions[0].created_at
                          | date: "dd-MM-yyyy h:mm a"
                      }}
                    </td>
                    <td style="width: 10%"></td>
                    <td style="width: 15%"><strong>Admission No:</strong></td>
                    <td style="width: 20%">{{ patient?.admissions[0].id }}</td>
                    <td style="width: 20%"></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="width: 15%; float: left">
              <div style="width: 100%; height: 180px">
                <img
                  class="qrCode"
                  style="height: 100%; width: 100%"
                  *ngIf="patient?.qrcode_url"
                  [src]="patient?.qrcode_url"
                  alt=""
                />
              </div>
              <img
                style=""
                class="brCode"
                *ngIf="patient?.barcode_url"
                [src]="patient?.barcode_url"
                alt=""
              />
              <div
                style=""
                class="font-weight-bolder font-size-h3 mrnCode"
                id="mrn"
              >
                {{ patient?.mrn }}
              </div>
            </div>
          </div>
        </div>
        <div style="overflow-x: auto">
          <table class="w-100 table table-bordered">
            <thead>
              <!--                      <th  colspan="2" ><div><span class="d-block" >  </span></div></th>-->
              <th style="width: 40%">
                <span class="d-none d-print-block">{{ glascoDate }}</span>
                <div class="mb-0 d-flex justify-content-left d-print-none">
                  <div class="w-50">
                    <input
                      type="date"
                      id="glascoStartDate"
                      max="{{ currentDate | date: 'yyyy-MM-dd' }}"
                      [min]="
                        patient?.admissions[0].created_at | date: 'yyyy-MM-dd'
                      "
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div>
                    <button
                      class="btn btn-primary btn-sm ml-3"
                      (click)="glascoGetData('')"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </th>
              <th style="width: 15%" class="text-center">
                <span class="d-block ml-2">Morning</span>
              </th>
              <th style="width: 15%" class="text-center">
                <span class="d-block ml-2">After Noon</span>
              </th>
              <th style="width: 15%" class="text-center">
                <span class="d-block ml-2">Evening</span>
              </th>
              <th style="width: 15%" class="text-center">
                <span class="d-block ml-2">Night</span>
              </th>
            </thead>
            <tbody>
              <ng-container *ngFor="let list of glascoList; let i = index">
                <tr>
                  <td colspan="5">
                    <span
                      ><h5>{{ list.name }}</h5></span
                    >
                  </td>
                </tr>
                <ng-container *ngFor="let data of list.sub_type; let j = index">
                  <tr>
                    <td>
                      <div class="d-flex justify-content-between">
                        {{ data?.name }}
                        <button
                          class="d-print-none btnGlasco"
                          [ngClass]="
                            data?.is_selected === true
                              ? 'successGlascoActive'
                              : ''
                          "
                          (click)="updateGlascoTypes(data?.id, i, j)"
                        >
                          {{ data?.points }}
                        </button>
                      </div>
                    </td>
                    <td class="text-center">
                      <ng-container *ngFor="let shift of data?.shift">
                        <ng-container *ngIf="shift?.shift === 'morning'">
                          <div>{{ shift?.point }}</div>
                          <span
                            ><small>{{ shift?.done_by_name }}</small></span
                          >
                        </ng-container>
                      </ng-container>
                    </td>
                    <td class="text-center">
                      <ng-container *ngFor="let shift of data?.shift">
                        <ng-container *ngIf="shift?.shift === 'afternoon'">
                          <div>{{ shift?.point }}</div>
                          <span
                            ><small>{{ shift?.done_by_name }}</small></span
                          >
                        </ng-container>
                      </ng-container>
                    </td>

                    <td class="text-center">
                      <ng-container *ngFor="let shift of data?.shift">
                        <ng-container *ngIf="shift?.shift === 'evening'">
                          <div>{{ shift?.point }}</div>
                          <span
                            ><small>{{ shift?.done_by_name }}</small></span
                          >
                        </ng-container>
                      </ng-container>
                    </td>
                    <td class="text-center">
                      <ng-container *ngFor="let shift of data.shift">
                        <ng-container *ngIf="shift?.shift === 'night'">
                          <div>{{ shift?.point }}</div>
                          <span
                            ><small>{{ shift?.done_by_name }}</small></span
                          >
                        </ng-container>
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>

              <tr>
                <td class="font-weight-bold">
                  <div class="d-flex justify-content-between">
                    <div><strong>Total</strong></div>
                    <div class="mr-2 d-print-none">
                      <strong>{{ glascoTotal | number }}</strong>
                    </div>
                  </div>
                </td>
                <td class="text-center font-weight-bold">
                  <strong>{{ totalPointMorning }}</strong>
                </td>
                <td class="text-center font-weight-bold">
                  <strong>{{ totalPointAfternoon }}</strong>
                </td>
                <td class="text-center font-weight-bold">
                  <strong>{{ totalPointEvening }}</strong>
                </td>
                <td class="text-center font-weight-bold">
                  <strong>{{ totalPointNight }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-primary mr-5 d-print-none"
              (click)="PrintGlasco()"
            >
              Print
            </button>
            <button
              class="btn btn-primary ml-2 d-print-none"
              (click)="glascoSubmit()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Glasco Submit Modal Confirmation-->
<div
  class="modal fade"
  id="glascoModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">
          Glasco Coma Scale
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form
        autocomplete="off"
        [formGroup]="glascoSubmitForm"
        (ngSubmit)="glascoData()"
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
              <label>Shift</label>
              <select
                class="form-control"
                formControlName="shift"
                data-live-search="true"
                [ngClass]="{
                  'is-invalid':
                    isAddType && this.glascoSubmitForm.controls.shift.errors
                }"
              >
                <option [ngValue]="''" selected disabled>Select</option>
                <option [ngValue]="'morning'">Morning</option>
                <option [ngValue]="'afternoon'">After Noon</option>
                <option [ngValue]="'evening'">Evening</option>
                <option [ngValue]="'night'">Night</option>
              </select>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label>Employe ID</label>
              <input
                class="form-control form-control-sm"
                formControlName="employe_id"
                type="text"
                [ngClass]="{
                  'is-invalid':
                    isAddType &&
                    this.glascoSubmitForm.controls.employe_id.errors
                }"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label>Password</label>
              <input
                class="form-control form-control-sm"
                formControlName="password"
                type="password"
                [ngClass]="{
                  'is-invalid':
                    isAddType && this.glascoSubmitForm.controls.password.errors
                }"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </div>
  </div>
</div>
