import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { fromEvent, Observable, Subscription } from 'rxjs';
import {HospitalService} from './services/hospital.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'hims-admin';
  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private location: PlatformLocation,
    private hospitalService: HospitalService
  ) {
    location.onPopState(() => {
      $('.dropdown-menu').removeClass('show');
    });
  }
  private handleAppConnectivityChanges(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe((e) => {
        toastr.success('Internet Connection Available');
      }),
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe((e) => {
        toastr.error('Internet Connection Not Available');
      }),
    );
  }

  ngOnInit(): any {
    this.handleAppConnectivityChanges();
    $('html').click(function () {
      $('.datetimePickerInput .form-control').datetimepicker('hide');
      $('.dropdown-menu').removeClass('show');
      $('.pointOfSale').removeClass('active');
    });

    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage) {
        let token = localStorage.getItem('is_session_expired');
        if (token === 'true') {
          $('.dropdown-menu').removeClass('show');
          this.router.navigate(['login']);
          // this.authService.logout();
        }
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const userAgent = navigator.userAgent
        this.hospitalService.createScreenLogs(event.urlAfterRedirects, userAgent).subscribe(
          (data) => {
          },
          (err) => {
          },
        );
      }
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
