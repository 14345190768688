import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../services/patient.service';
import { HospitalService } from '../services/hospital.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-worker-print',
  templateUrl: './worker-print.component.html',
  styleUrls: ['./worker-print.component.css'],
})
export class WorkerPrintComponent implements OnInit {
  PatientSearchForm: UntypedFormGroup;
  current_url = '';
  patients: any;
  settingData: any;
  workerData: any;
  patientId: number;
  searchCard = false;
  mrn: any;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private hospitalService: HospitalService,
  ) {}
  // generateWorkerReport(): any{
  //   console.log('1');
  //   this.ngxLoader.start();
  //   // this.PatientSearchForm.get('search_query')?.setValue(this.PatientSearchForm.get('search_query')?.value.replace(/-/g, ''));
  //   let referal = false;
  //   console.log('1');
  //   if ( this.current_url === '/front-desk' ) {
  //     referal = true;
  //   }
  //   console.log('1');
  //   this.patientService.searchPatientRegistrationV2ForWorker(this.PatientSearchForm.value, referal).subscribe(
  //     data => {
  //       this.workerData = data.patients;
  //       console.log('1');
  //       this.getWorkerData(this.workerData[0].id);
  //       console.log('1');
  //       this.ngxLoader.stop();
  //     },
  //     err => {
  //       this.ngxLoader.stop();
  //       // toastr.error(err.error.message);
  //     }
  //   );
  // }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  printReport(): any {
    window.print();
  }
  getWorkerData(): any {
    this.workerData = '';
    console.log('1');
    if (this.mrn) {
      console.log('1');
      this.patientId = this.mrn;
      console.log('1');
    }
    console.log('2');
    this.patientService.getWorkerData(this.patientId).subscribe(
      (data) => {
        console.log('3');
        this.workerData = data.patients;
        console.log('4');
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  ngOnInit(): void {
    this.patientId = Number(this.route.snapshot.queryParamMap.get('id'));
    if (this.patientId) {
      this.searchCard = true;
    }
    if (this.searchCard) {
      this.getWorkerData();
    }
    this.getSettingsData();
    this.current_url = this.router.url;
  }
}
