import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private http: HttpClient) {}

  getPatientVisitslHistory(mrn): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/visit_category_wise?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          mrn,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientVisitslHistoryV2(mrn, visitType): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/ipd/visit_category_wise?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          mrn +
          '&visit_type=' +
          visitType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientVisits(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patient_visits?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientVitalslHistory(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/vitals/vitals_visit_wise?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientDiagnosisHistory(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_diagnoses/visit_wise_diagnosis?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientMedicinesHistory(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_medications/visit_wise_medications?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientServicesHistory(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_services/visit_wise_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientConsultantHistory(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/ipd_doctors/visit_wise_consultation?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientReferralHistory(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/referrals/visit_wise_referrals?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientVaccinationHistory(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_vaccinations/visit_wise_vaccinations?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientGeneralHistory(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_general_histories/visit_wise_general_histories?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientRSHistory(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_review_systems/visit_wise_review_systems?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientPHEHistory(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/physical_systemic_examinations/visit_wise_physical_examinations?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientCNHistory(patientMRN, type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/central_nervous_systems/visit_wise_nervous_systems?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&type=' +
          type +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientNotesHistory(patientMRN, id, type): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v3/investigation_notes/visit_wise_investigation_notes?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&visit_id=' +
          id +
          '&investigation_type=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientAnesthesiaConsentHistory(patientMRN, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/anesthesia_consents/visit_wise_anesthesia_consent?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientOperationConsentHistory(patientMRN, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/operation_consents/visit_wise_operation_consent?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientDocHistory(id, currentVisitID): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/attachments/patient_wise_attachments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          id +
          '&visit_id=' +
          currentVisitID,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  uploadPatientDoc(id, patientId, file): Observable<any> {
    const body = new FormData();
    body.append('id', id);
    body.append('patient_id', patientId);
    body.append('document', file);
    const results = this.http.put(
      `${environment.rorUrl}/v4/document_types/add_attachment/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }
}
