import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import * as moment from 'moment/moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientAdmissionService } from '../../../../services/patient-admission.service';
import { TemplatesService } from '../../../../services/templates.service';
import { MedicineConfigService } from '../../../../services/medicine-config.service';
import { StockService } from '../../../../services/stock.service';
import { formatDate } from '@angular/common';
import { HospitaldoctorService } from '../../../../services/hospitaldoctor.service';
import { OpdService } from '../../../../services/opd.service';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientService } from '../../../../services/patient.service';
import { ConfigurationService } from '../../../../services/configuration.service';
import {DiagnosisSearchComponent} from '../../../shared-modules/diagnosis-search/diagnosis-search.component';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-discharge',
  templateUrl: './discharge.component.html',
  styleUrls: ['./discharge.component.css'],
})
export class DischargeComponent implements OnInit {
  @ViewChild('dischargeCompleteAlert') dischargeCompleteAlert: ElementRef;
  @ViewChild(DiagnosisSearchComponent) diagnosisSearchComponent!: DiagnosisSearchComponent;
  @Input() patient: any;
  @Input() doctors: any;
  @Output() isPatientRefresh = new EventEmitter<string>();
  @Output() isPatientDischargId = new EventEmitter<string>();

  get width(): any {
    return window.innerWidth;
  }

  dischargedPatientData: any;
  patientID: any;
  diagnosisSelected = [];
  diagnosisSearch = '';
  dischargeTempsValue = '';
  reportsTemplateData = [];
  addMedicine = [];
  doseTypeList: any = [];
  doseList: any = [];
  doses = [];
  clearTimeout: any;
  productListData = [];
  productSearchResult = false;
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  frequencyList: any = [];
  routeList: any = [];
  resetData: any;
  dischargeData: any;
  // doctors = [];
  doctor_groups = [];
  diagnosisListData = [];
  diagnosisSearchResult = false;
  isChecked = false;
  patients = [];
  isPatients = false;
  selectedPatientIndex = 0;
  isIpdMedicineConfigurator: any;
  patientDischarge = {
    follow_up: '',
    discharge_type: '',
    duty_doctor_id: '',
    medications: [],
    operation_details: [],
    chief_complaint_finding: '',
    operative_finding: '',
    operative_procedure: '',
    immediate_complication: '',
    late_complication: '',
    doctor_group_id: '',
    patient_diagnosis: [],
    recevied_dead: false,
    discharge_date_time: '',
    diagnosis_type: '',
    comments_01: '',
    primary_cause_of_death: '',
    secondary_cause_of_death: '',
    underlying_cause: '',
    comments_02: '',
    significant_condition: '',
    contributing_to_death: '',
    template_data: [],
    associated_diagnostic: '',
    is_temp: false,
    patient_diagnosis_name: '',
    post_mortem: 'false',
  };
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private patientAdmissionService: PatientAdmissionService,
    private templatesService: TemplatesService,
    private medicineConfigService: MedicineConfigService,
    private stockService: StockService,
    private chRef: ChangeDetectorRef,
    private hospitalDoctorsService: HospitaldoctorService,
    private opdService: OpdService,
    private router: Router,
    private modalService: NgbModal,
    private patientService: PatientService,
    private configService: ConfigurationService,
  ) {}

  ngOnInit(): void {
    $('#followUpInstructions').summernote({
      height: 100,
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
      ],
    });
    this.getDoseList('dose');
    this.getDoseTypeList('dose_type');
    this.getFrequencyList('frequency');
    this.getRouteList('route');
    this.fetchDoctorGroups();
    this.searchTemplate();
    this.getShowMedicinesInIpd();
  }

  ngOnChanges(changes: SimpleChanges): any {
    if (changes.patient.currentValue) {
      this.setDatesOfMedicinesDis();
    }
  }

  updateDischargeType(): any {
    if (this.patientDischarge.discharge_type === 'Deceased') {
      this.patientDischarge.medications = [];
      setTimeout(() => {
        $('#dischargeDate').datetimepicker({
          defaultDate: new Date(),
          format: 'DD-MM-YYYY h:mm a',
        });
        const currentTime = moment().format('DD-MM-YYYY h:mm a');
        $('#dischargeDate').val(currentTime);
      }, 2000);
    }
  }

  setDatesOfMedicinesDis(): any {
    if (this.patient.discharge_patient_id) {
      this.getPreviousDischargeData();
      return;
    }
    this.patientDischarge = {
      follow_up: '',
      discharge_type: '',
      duty_doctor_id: '',
      medications: [],
      operation_details: [],
      chief_complaint_finding: '',
      operative_finding: '',
      operative_procedure: '',
      immediate_complication: '',
      late_complication: '',
      doctor_group_id: '',
      patient_diagnosis: [],
      recevied_dead: false,
      discharge_date_time: '',
      diagnosis_type: '',
      comments_01: '',
      primary_cause_of_death: '',
      secondary_cause_of_death: '',
      underlying_cause: '',
      comments_02: '',
      significant_condition: '',
      contributing_to_death: '',
      template_data: [],
      associated_diagnostic: '',
      is_temp: false,
      patient_diagnosis_name: '',
      post_mortem: 'false',
    };
    for (let i = 0; i < 3; i++) {
      this.addMoreMedicineDis();
      // this.doses[i] = this.doseList;
    }
    for (let i = 0; i < 1; i++) {
      this.addMoreOperationDetails();
    }
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 500);
    $('html').click(function () {
      $('.bootstrap-select.open').removeClass('open');
      $('.dropdown-menu').removeClass('show');
    });
    setTimeout(() => {
      $('#disDutyDoctor').selectpicker('refresh');
      $('#followUpInstructions').summernote({
        height: 100,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
        ],
      });
    }, 500);
    $('#followUpInstructions').summernote('code', '');

    setTimeout(() => {
      $('#disDutyDoctor').selectpicker('refresh');
    }, 500);
  }

  getPreviousDischargeData(): any {
    this.ngxLoader.start();
    this.patientID = this.patient.admissions[0].id;
    this.patientAdmissionService
      .getDischargeFromIpdV2(this.patientID)
      .subscribe(
        (data) => {
          this.dischargedPatientData = data.discharge_patient;
          const dataObj = data.discharge_patient;
          this.ngxLoader.stop();
          for (let i = 0; i < this.patientDischarge.medications.length; i++) {
            this.updateDoses(dataObj.medications[i]?.stoc_type_id, i);
          }
          this.patientDischarge = {
            follow_up: dataObj.follow_up,
            discharge_type: dataObj.discharge_type,
            duty_doctor_id: dataObj.duty_doctor_id,
            medications: dataObj.medications || [],
            operation_details: dataObj.operation_details,
            chief_complaint_finding: dataObj.chief_complaint_finding,
            operative_finding: dataObj.operative_finding,
            operative_procedure: dataObj.operative_procedure,
            immediate_complication: dataObj.immediate_complication,
            late_complication: dataObj.late_complication,
            doctor_group_id: dataObj.doctor_group_id,
            patient_diagnosis: dataObj.patient_diagnosis,
            recevied_dead: dataObj.recevied_dead,
            discharge_date_time: dataObj.discharge_date_time || '',
            diagnosis_type: dataObj.diagnosis_type,
            comments_01: dataObj.comments_01,
            primary_cause_of_death: dataObj.primary_cause_of_death || '',
            secondary_cause_of_death: dataObj.secondary_cause_of_death || '',
            underlying_cause: dataObj.underlying_cause || '',
            comments_02: dataObj.comments_02,
            significant_condition: dataObj.significant_condition,
            contributing_to_death: dataObj.contributing_to_death,
            template_data: dataObj.template_data,
            associated_diagnostic: dataObj.associated_diagnostic,
            is_temp: false,
            patient_diagnosis_name: '',
            post_mortem: dataObj.post_mortem,
          };
          if (this.patientDischarge.patient_diagnosis.length > 0) {
            this.diagnosisSelected = this.patientDischarge.patient_diagnosis;
            this.diagnosisSearch = this.diagnosisSelected[0].name;
          }
          $('#followUpInstructions').summernote('code', dataObj.follow_up);
          setTimeout(() => {
            $('.selectpicker').selectpicker();
            for (let i = 0; i < dataObj.template_data.length; i++) {
              $('#dischargeReportsTemplate' + i).summernote(
                'code',
                dataObj.template_data[i].detail,
              );
            }
          }, 500);
          setTimeout(() => {
            for (let i = 0; i < dataObj.operation_details.length; i++) {
              $('#operationSurgeonDis' + i).selectpicker();
              $('#operationSurgeonDis' + i).val(
                dataObj.operation_details[i].surgeon_ids,
              );
              $('#operationAssistantDis' + i).selectpicker();
              $('#operationAssistantDis' + i).val(
                dataObj.operation_details[i].assistant_ids,
              );
              const formattedDate = moment(dataObj.operation_details[i].operation_date, 'yyyy/MM/DD').format('YYYY-MM-DD');
              $('#operationDate' + i).val(formattedDate);
            }
            $('#disDutyDoctor').selectpicker('');
            $('#disDutyDoctor').val(dataObj.duty_doctor_id);
            $('.bootstrap-select.open').removeClass('open');
            $('.dropdown-menu').removeClass('show');
            $('.search-result-main').hide();
          }, 1000);
          setTimeout(() => {
            $('#disDutyDoctor').selectpicker('refresh');
          }, 500);
          if (dataObj.discharge_type === 'Deceased') {
            setTimeout(() => {
              $('#dischargeDate').datetimepicker({
                defaultDate: new Date(),
                format: 'DD-MM-YYYY h:mm a',
              });
              const currentTime = moment(
                dataObj.death_certificate?.discharge_date_time,
              ).format('DD-MM-YYYY h:mm a');
              $('#dischargeDate').val(currentTime);
            }, 1000);
          }
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  searchTemplate(): any {
    this.templatesService
      .searchTemplatesV2('', 'discharge_template', 'true', '')
      .subscribe(
        (data) => {
          this.reportsTemplateData = data.template_configurators;
        },
        (err) => {
          toastr.error(err.error.message);
        },
      );
  }

  updateDischargeTemplate(id): any {
    if (id === '') {
      this.patientDischarge.template_data = [];
    } else {
      this.patientDischarge.template_data = [];
      let data;
      data = this.reportsTemplateData.filter((t) => t.id === Number(id))[0]
        .template_data;
      for (let i = 0; i < data.length; i++) {
        const obj = { heading: data[i].heading, detail: data[i].detail };
        this.patientDischarge.template_data.push(obj);
      }
      setTimeout(() => {
        for (let i = 0; i < this.patientDischarge.template_data.length; i++) {
          $('#dischargeReportsTemplate' + i).summernote(
            'code',
            this.patientDischarge.template_data[i].detail,
          );
        }
      }, 500);
    }
  }

  addMoreMedicineDis(): any {
    const obj = {
      name: '',
      item_id: '',
      frequency: '',
      dosee_id: '',
      no_of_days: 1,
      from_date: '',
      to_date: '',
      comment: '',
      dos_type_id: '',
      stoc_type_id: '',
      routee_id: '',
    };
    this.patientDischarge.medications.push(obj);
    // this.doses[this.patientDischarge.medications.length - 1] = this.doseList;
    const now = new Date();
    const day = ('0' + now.getDate()).slice(-2);
    const month = ('0' + (now.getMonth() + 1)).slice(-2);
    const today = now.getFullYear() + '-' + month + '-' + day;
    this.patientDischarge.medications[
      this.patientDischarge.medications.length - 1
    ].to_date = today;
    this.patientDischarge.medications[
      this.patientDischarge.medications.length - 1
    ].from_date = today;
    const ind = this.patientDischarge.medications.length;
    $('#medicineStartDis' + ind, '#medicineEndDis' + ind).val(today);
  }

  updateDoses(value, i): any {
    this.patientDischarge.medications[i].dos_type_id = '';
    if (value === '') {
      this.doses[i] = this.doseList;
    } else {
      this.doses[i] = this.doseTypeList.filter(
        (d) => d.id === Number(value),
      )[0]?.dose_data;
    }
  }

  getDoseList(type): any {
    this.doseList = [];
    this.medicineConfigService.indexconfigv2(type, true).subscribe((data) => {
      if (data.configurators) {
        this.doseList = data.configurators;
        // this.doses[0] = data.configurators;
      }
      this.ngxLoader.stop();
    });
  }

  getDoseTypeList(type): any {
    this.doseTypeList = [];
    this.medicineConfigService.indexconfigv2(type, true).subscribe((data) => {
      if (data.configurators) {
        this.doseTypeList = data.configurators;
      }
      this.ngxLoader.stop();
    });
  }

  getItemsListMain(ind): any {
    clearTimeout(this.clearTimeout);
    this.patientDischarge.medications[ind].item_id = null;
    this.clearTimeout = setTimeout(() => {
      this.productListData = [];
      this.productSearchResult = false;
      if (this.patientDischarge.medications[ind].name.length < 2) {
        return;
      }
      $('#medicineSearchMain' + ind + ' .search-result-main').show();
      this.stockService
        .searchItemsListForIpdDischargeTab(
          this.patientDischarge.medications[ind].stoc_type_id,
          this.patientDischarge.medications[ind].name,
          false,
        )
        .subscribe(
          (data) => {
            this.productListData = data.items;
            this.productSearchResult = true;
          },
          (err) => {
            this.productListData = [];
            this.productSearchResult = true;
          },
        );
    }, 1000);
  }

  focusOutDis(index): any {
    setTimeout(() => {
      $('.search-result-main').hide();
      if (
        !this.patientDischarge.medications[index].item_id &&
        this.isIpdMedicineConfigurator?.is_medicine_manual_input === false
      ) {
        toastr.error('Please select medicine from the list');
        return;
      }
    }, 300);
  }

  getShowMedicinesInIpd(): any {
    this.ngxLoader.start();
    this.configService.getShowMedicinesInIpd().subscribe(
      (data) => {
        this.isIpdMedicineConfigurator = data;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  setProductDis(value, ind): any {
    this.patientDischarge.medications[ind].name = value.product_name;
    this.patientDischarge.medications[ind].item_id = value.id;
    $('#MedicinesDis #medicineSearchDis' + ind + ' .search-result-main').hide();
  }

  updateFromDateDischarge(value, ind): any {
    this.patientDischarge.medications[ind].to_date = value;
    this.chRef.detectChanges();
    setTimeout(() => {
      this.updateMedicineToDateDis(ind);
    }, 50);
  }

  updateMedicineToDateDis(ind): any {
    const startdate = this.patientDischarge.medications[ind].from_date;
    const new_date = moment(startdate, 'YYYY-MM-DD').add(
      this.patientDischarge.medications[ind].no_of_days,
      'days',
    );
    const date = new_date.format('YYYY-MM-DD');
    $('#medicineEndDis' + ind).val(date);
    this.patientDischarge.medications[ind].to_date = date;
    this.chRef.detectChanges();
  }

  updateNoOfDaysDis(i): any {
    const start = moment(
      this.patientDischarge.medications[i].from_date,
      'YYYY-MM-DD',
    );
    const end = moment(
      this.patientDischarge.medications[i].to_date,
      'YYYY-MM-DD',
    );
    const noOfDays = end.diff(start, 'days');
    this.patientDischarge.medications[i].no_of_days = noOfDays + 1;
  }

  removeMedicineDis(ind): any {
    this.patientDischarge.medications.splice(ind, 1);
  }

  getFrequencyList(type): any {
    this.frequencyList = [];
    this.medicineConfigService.indexconfigv2(type, true).subscribe((data) => {
      if (data.configurators) {
        this.frequencyList = data.configurators;
      }
      this.ngxLoader.stop();
    });
  }

  getRouteList(type): any {
    this.routeList = [];
    this.medicineConfigService.indexconfigv2(type, true).subscribe((data) => {
      if (data.configurators) {
        this.routeList = data.configurators;
      }
      this.ngxLoader.stop();
    });
  }

  addMoreOperationDetails(): any {
    const obj = {
      detail: '',
      la_ga: '',
      surgeon_ids: '',
      assistant_ids: '',
      operation_date: '',
    };
    this.patientDischarge.operation_details.push(obj);
    const now = new Date();
    const day = ('0' + now.getDate()).slice(-2);
    const month = ('0' + (now.getMonth() + 1)).slice(-2);
    const today = now.getFullYear() + '-' + month + '-' + day;
    const ind = this.patientDischarge.operation_details.length;
    this.patientDischarge.operation_details[ind - 1].operation_date = today;
    $('#operationDate' + ind).val(today);

    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 500);
    $('html').click(function () {
      $('.bootstrap-select.open').removeClass('open');
      $('.dropdown-menu').removeClass('show');
    });
  }

  removeOperationDis(ind): any {
    this.patientDischarge.operation_details.splice(ind, 1);
  }

  fetchDoctorGroups(): any {
    this.hospitalDoctorsService.doctorGroupsV2().subscribe(
      (data) => {
        this.doctor_groups = data.doctor_groups;
      },
      (err) => {},
    );
  }

  preventChangeDate(): any {
    return false;
  }

  getDiagnosisList(): any {
    this.diagnosisListData = [];
    this.diagnosisSearchResult = false;
    if (this.diagnosisSearch.length < 2) {
      return;
    }
    this.opdService.getDiagnosisListFunv2(this.diagnosisSearch).subscribe(
      (data) => {
        this.diagnosisListData = data.data;
        this.diagnosisSearchResult = true;
      },
      (err) => {
        this.diagnosisListData = [];
        this.diagnosisSearchResult = true;
      },
    );
  }

  setDiagnosis(value): any {
    this.diagnosisSelected = [];
    this.diagnosisSelected.push(value);
    this.diagnosisSearch = value.name;
    $('.search-result-main').hide();
  }

  patientDischargeFunPrint(): any {
    if (this.patientDischarge.discharge_type === 'LAMA') {
      this.router.navigate(['patient/discharge-certificate'], {
        queryParams: { id: this.patient.admissions[0].id },
      });
    } else {
      window.open(
        'pdf-reports/discharge-report?patient_id=' +
          this.patient.id +
          '&visit_id=' +
          this.patient.visit_id +
          '&mrn=' +
          this.patient.mrn +
          '&invoice_id=' +
          '&admission_id=' +
          this.patient.admissions[0].id,
      );
      // window.open(environment.rorUrl + '/v3/discharge_patients/discharge_report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&patient_id=' + this.patient.mrn + '&admission_id=' + this.patient.admissions[0].id);
    }
  }

  patientDischargeFun(type): any {
    let count = 0;
    $('#Discharge input, select').css('border', '1px solid #E4E6EF');
    $('.form-control').css('border', '1px solid #e4e6ef');
    $('.selectpicker').parent().css('border', '1px solid #E4E6EF');
    if (this.patientDischarge.discharge_type === '') {
      $('.dischargeType').css('border', '1px solid  #dc3545');
      // return;
      count++;
    }
    for (let i = 0; i < this.patientDischarge.medications.length; i++) {
      if (this.patientDischarge.medications[i].stoc_type_id === '') {
        $('#stockTypes' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (this.patientDischarge.medications[i].name === '') {
        $('#medicineNameDis' + i).css('border', '1px solid #dc3545');
        // return;
        count++;
      }
      if (this.patientDischarge.medications[i].dosee_id === '') {
        $('#frequency' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (
        this.patientDischarge.medications[i].no_of_days < 1 ||
        this.patientDischarge.medications[i].no_of_days === null
      ) {
        $('#medicineNoOfDaysDis' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (
        !this.patientDischarge.medications[i].item_id &&
        this.isIpdMedicineConfigurator?.is_medicine_manual_input === false
      ) {
        toastr.error('Please select medicine from the list');
        return;
      }
    }
    for (let i = 0; i < this.patientDischarge.operation_details.length; i++) {
      if (this.patientDischarge.operation_details[i].detail === '') {
        $('#operationDetailsDis' + i).css('border', '1px solid #dc3545');
        // return;
        count++;
      }
      if (this.patientDischarge.operation_details[i].la_ga === '') {
        $('#operationLaGaDis' + i).css('border', '1px solid #dc3545');
        // return;
        count++;
      }
      if (this.patientDischarge.operation_details[i].surgeon_ids.length === 0) {
        $('#operationSurgeonDis' + i)
          .parent()
          .css('border', '1px solid #dc3545');
        // return;
        count++;
      }
      if (
        this.patientDischarge.operation_details[i].assistant_ids.length === 0
      ) {
        $('#operationAssistantDis' + i)
          .parent()
          .css('border', '1px solid #dc3545');
        // return;
        count++;
      }
    }
    if (this.patientDischarge.duty_doctor_id === '') {
      $('.disDutyDoctorMain .bootstrap-select').css(
        'border',
        '1px solid  #dc3545',
      );
      // return;
      count++;
    }
    if (count > 0) {
      return;
    }
    for (let i = 0; i < this.patientDischarge.template_data.length; i++) {
      if ($('#dischargeReportsTemplate' + i).summernote('isEmpty')) {
        this.patientDischarge.template_data[i].detail = '';
        // this.patientDischarge.template_data[i].get('detail').patchValue('');
      } else {
        this.patientDischarge.template_data[i].detail = $(
          '#dischargeReportsTemplate' + i,
        ).summernote('code');
      }
    }
    // this.patientDischarge.associated_diagnostic = $('#associatedDiagnostic').summernote('code');
    if (this.diagnosisSelected.length > 0) {
      this.patientDischarge.patient_diagnosis = this.diagnosisSelected || [];
    }
    this.patientDischarge.discharge_date_time =
      this.patientDischarge?.discharge_type === 'Deceased'
        ? $('#dischargeDate').data().date
        : '';
    this.patientDischarge.patient_diagnosis_name = this.diagnosisSearch;
    this.patientDischarge.follow_up = $('#followUpInstructions').summernote(
      'code',
    );
    this.patientDischarge.is_temp = type;
    if (type === false) {
      this.isChecked = false;
      this.modalService.open(this.dischargeCompleteAlert, { size: 'md' });
    } else {
      this.isChecked = true;
      this.patientDischargeConfirm();
    }
  }

  patientDischargeConfirm(): any {
    if (this.isChecked === false) {
      toastr.error('Check confirmation first');
      return;
    }
    this.ngxLoader.start();
    this.patientDischarge.discharge_date_time = $('#dischargeDate').val();
    this.patientAdmissionService
      .dischargePatientFromIpdV2(this.patientDischarge, this.patient)
      .subscribe(
        (data) => {
          this.dischargeData = data.discharge_patient;
          this.diagnosisSearchComponent?.clearSearchBar();
          this.ngxLoader.stop();
          this.modalService.dismissAll(this.dischargeCompleteAlert);
          if (this.patientDischarge.is_temp === false) {
            if (this.patientDischarge.discharge_type === 'LAMA') {
              this.router.navigate(['patient/discharge-certificate'], {
                queryParams: { id: this.patient.admissions[0].id },
              });
            } else {
              window.open(
                'pdf-reports/discharge-report?patient_id=' +
                  this.patient.id +
                  '&visit_id=' +
                  this.patient.visit_id +
                  '&mrn=' +
                  this.patient.mrn +
                  '&admission_id=' +
                  this.patient.admissions[0].id,
              );
              // window.open(environment.rorUrl + '/v3/discharge_patients/discharge_report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&patient_id=' + this.patient.mrn + '&admission_id=' + data.discharge_patient.admission_id);
            }
            this.setDatesOfMedicinesDis();
            this.patient = null;
            this.resetData = Math.random();
            this.isPatientRefresh.emit(this.resetData);
            toastr.success('Patient Discharged');
          } else {
            this.isPatientDischargId.emit(this.dischargeData.id);
            toastr.success('Patient discharged data saved');
            this.patient.admissions[0].discharge_patient_id =
              this.dischargeData.id;
            this.patient.discharge_patient_id = this.dischargeData.id;
          }
          if (this.patientDischarge.is_temp === false) {
            this.resetDischargeForm();
          }
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  resetDischargeForm(): any {
    this.patientDischarge = {
      follow_up: '',
      discharge_type: 'Normal',
      duty_doctor_id: '',
      medications: [],
      operation_details: [],
      chief_complaint_finding: '',
      operative_finding: '',
      operative_procedure: '',
      immediate_complication: '',
      late_complication: '',
      doctor_group_id: '',
      patient_diagnosis: [],
      recevied_dead: false,
      discharge_date_time: '',
      diagnosis_type: '',
      comments_01: '',
      primary_cause_of_death: '',
      secondary_cause_of_death: '',
      underlying_cause: '',
      comments_02: '',
      significant_condition: '',
      contributing_to_death: '',
      template_data: [],
      associated_diagnostic: '',
      is_temp: false,
      patient_diagnosis_name: '',
      post_mortem: 'false',
    };
    this.diagnosisSearch = '';
    this.dischargeTempsValue = '';
    setTimeout(() => {
      $('#disDutyDoctor').selectpicker('refresh');
    }, 500);
  }
  getDiagnosisSearchData(event: any): any{
    if (event?.type === 'primary_cause_of_death'){
      this.patientDischarge.primary_cause_of_death = event?.name;
    } else if (event?.type === 'secondary_cause_of_death'){
      this.patientDischarge.secondary_cause_of_death = event?.name;
    } else if (event?.type === 'underlying_cause'){
      this.patientDischarge.underlying_cause = event?.name;
    }
  }
}
