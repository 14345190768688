<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">Radiology Reports Delivery</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card card-custom">
        <div class="card-body">
          <form [formGroup]="labServicesForm" (ngSubmit)="searchInvoices()">
            <div class="row">
              <div class="col-md-2 col-sm-3 col-4">
                <div class="form-group">
                  <label>From Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="fromDate"
                    data-toggle="datetimepicker"
                    data-target="#fromDate"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <div class="form-group">
                  <label>To Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="toDate"
                    data-target="#toDate"
                    data-toggle="datetimepicker"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <label>Department</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm"
                      formControlName="parent_department_id"
                      (change)="getSubDepartment($event.target.value)"
                    >
                      <option value="" disabled>Select</option>
                      <option value="" *ngIf="departments.length > 0">
                        All
                      </option>
                      <option
                        [value]="department.id"
                        *ngFor="let department of departments"
                      >
                        {{ department.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <label>Sub Department</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm"
                      formControlName="department_id"
                    >
                      <option value="" disabled>Select</option>
                      <option value="" *ngIf="subDepartments.length > 0">
                        All
                      </option>
                      <option
                        [value]="department.id"
                        *ngFor="let department of subDepartments"
                      >
                        {{ department.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <label>MRN</label>
                <div class="form-group">
                  <div class="d-block">
                    <input
                      class="form-control form-control-sm"
                      formControlName="patient_id"
                    />
                  </div>
                </div>
              </div>

              <!--add button-->
              <div class="col-md-2 col-sm-3 col-4 text-right">
                <div class="form-group">
                  <div class="d-block">
                    <button
                      type="submit"
                      class="btn btn-primary btn-sm cus_btm"
                      ngbTooltip="Search Result"
                      placement="bottom"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card card-custom gutter-b mt-7">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Table-->
          <div class="table_top_bar mb-3">
            <div class="row">
              <div class="col-4 col-sm-4 text-right">
                <span class="switch switch-icon ml-5">
                  <span class="mr-2">Radiant</span>
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      #urlCheck
                      name="UrlCheck"
                      (change)="updatePACSURL($event.target.checked)"
                    />
                    <span></span>
                  </label>
                  <span class="ml-1">Web</span>
                </span>
              </div>
              <div class="col-8 col-sm-8 text-right">
                <div class="dropdown dropdown-inline mr-2">
                  <button
                    (click)="filtersInvoices('')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5"
                  >
                    All
                  </button>
                  <button
                    (click)="filtersInvoices('opd')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 opd-btn"
                  >
                    OPD
                  </button>
                  <button
                    (click)="filtersInvoices('ipd')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 ipd-btn"
                  >
                    IPD
                  </button>
                  <button
                    (click)="filtersInvoices('urgent')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 urgent"
                  >
                    Urgent
                  </button>
                  <button
                    (click)="filtersInvoices('returned')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 returned"
                  >
                    Returned
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="table-responsive position-relative">
              <table
                class="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_3"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Acc No</th>
                    <th>Date/Time</th>
                    <th>Test Name</th>
                    <th>Department</th>
                    <th>MRN#</th>
                    <th>Patient Name</th>
                    <th>Action</th>
                    <th>Images</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let element of pageDataList; let i = index"
                  >
                    <tr
                      [ngClass]="{
                        returned: element.is_rejected === true,
                        urgent: element.urgent === true
                      }"
                    >
                      <td
                        [ngClass]="{
                          'ipd-btn': element.ipd === true,
                          'opd-btn': element.ipd === false
                        }"
                      ></td>
                      <td>{{ element.accession_number }}</td>
                      <td>
                        {{ element.order_date | date: "dd-MM-yyyy h:mm a" }}
                      </td>
                      <td>{{ element.service_name }}</td>
                      <td>{{ element.service?.department?.title }}</td>
                      <td>{{ element.patient.mrn }}</td>
                      <td>
                        {{
                          element.patient.first_name +
                            " " +
                            element.patient.last_name
                        }}
                      </td>
                      <td>
                        <ng-container *ngIf="element?.delivered">
                          <button
                            type="button"
                            class="btn btn-light-primary btn-sm font-weight-bolder"
                            (click)="deliveredResult(element)"
                            style="padding: 2px 5px; margin: 2px 5px"
                          >
                            Deliver
                          </button>
                        </ng-container>
                        <ng-container *ngIf="element?.delivered === false">
                          <span
                            class="label font-weight-bold label-lg label-light-danger label-inline d-flex"
                            >UnPaid OPD Service</span
                          >
                        </ng-container>
                        <button
                          type="button"
                          class="btn btn-light-primary btn-sm font-weight-bolder"
                          *ngIf="element.report"
                          (click)="
                            printConfirmModal(element, element.patient.mrn)
                          "
                          style="padding: 2px 5px; margin: 2px 5px"
                        >
                          Print
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-light-primary btn-sm font-weight-bolder"
                          *ngIf="element.report"
                          (click)="openDiscomImage(element?.accession_number)"
                          style="padding: 2px 5px; margin: 2px 5px"
                        >
                          Img
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
            <!--pagination-->
            <div class="pagination_main">
              <ngb-pagination
                [(page)]="page"
                (pageChange)="loadPage(page)"
                [pageSize]="perPage"
                [collectionSize]="totalPages"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              ></ngb-pagination>
              <div class="dropdown">
                <a
                  class="btn dropdown-toggle btn-light btn-sm"
                  role="button"
                  id="pagination_btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="page_count">
                    {{ perPage }}
                  </span>
                  <span class="count_count_icon"></span>
                </a>
                <div class="dropdown-menu" aria-labelledby="pagination_btn">
                  <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                    >25</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                    >50</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                    >75</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                    >100</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit('')"
                    >All</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #deliveredLabTestModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Order Delivered!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="reportDelivered" (ngSubmit)="deliveredResultFun()">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Relation</label>
                  <select
                    class="form-control"
                    formControlName="receiver_relation"
                    (change)="updateReceiverType($event.target.value)"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && g.receiver_relation.errors
                    }"
                  >
                    <option value="Self">Self</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Receiver Name</label>
                  <input
                    class="form-control"
                    formControlName="receiver_name"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && g.receiver_name.errors
                    }"
                  />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label>Receiver CNIC</label>
                  <input
                    class="form-control"
                    type="text"
                    maxlength="13"
                    (keypress)="_onlyNumeric($event)"
                    formControlName="receiver_cnic"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && g.receiver_cnic.errors
                    }"
                  />
                </div>
              </div>

              <div class="col-sm-12">
                <div class="form-group">
                  <label class="checkbox checkbox-sm float-left mr-3">
                    <input
                      type="checkbox"
                      name="showHeader"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="showHeader"
                    />
                    <span></span>
                  </label>
                  <span>You want to show Header on report</span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="checkbox checkbox-sm float-left mr-3">
                    <input
                      type="checkbox"
                      name="showHeader"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="showFooter"
                    />
                    <span></span>
                  </label>
                  <span>You want to show Footer on report</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer p-3">
            <button
              type="button"
              class="btn btn-sm btn-default"
              (click)="c('Close click')"
            >
              Close
            </button>
            <button type="submit" class="btn btn-sm btn-primary">
              Confirm
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>

<div
  class="modal fade"
  id="printConfirmation"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Print Report</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <label class="checkbox checkbox-sm float-left mr-3">
              <input type="checkbox" [(ngModel)]="showHeader" />
              <span></span>
            </label>
            <span>You want to show Header on report</span>
          </div>
          <div class="col-12 mt-5">
            <label class="checkbox checkbox-sm float-left mr-3">
              <input type="checkbox" [(ngModel)]="showFooter" />
              <span></span>
            </label>
            <span>You want to show Footer on report</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="printReport(this.labId, this.patientMRN)"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
