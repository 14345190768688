import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { OpdService } from '../../../../services/opd.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-hist-taking',
  templateUrl: './hist-taking.component.html',
  styleUrls: ['./hist-taking.component.css'],
})
export class HistTakingComponent implements OnInit {
  @Input() patient: any;

  paitentAllergiesSearch = '';
  allergiesSearchResult = false;
  allergiesListData = [];
  clearTimeout: any;
  patientHistoryComplaint = {
    presenting_complaints: '',
    presenting_complaints_history: '',
    physical_finding: '',
    past_medical_history: '',
    drug_history: '',
    family_history: '',
    allergies: '',
    general_physical_examination: '',
    summary: '',
  };

  constructor(
    private opdService: OpdService,
    private ngxLoader: NgxUiLoaderService,
  ) {}

  ngOnInit(): void {
    $('#presentingComplaintsPatient').summernote({
      height: 100,
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
      ],
    });
    this.getAllergiesList();
  }

  ngOnChanges(changes: SimpleChanges): any {
    this.getPatientHistory(changes.patient.currentValue);
  }
  getAllergiesList(): any {
    this.paitentAllergiesSearch = $('.bootstrap-tagsinput input').val();
    if (this.paitentAllergiesSearch?.length < 2) {
      return;
    }
    this.allergiesSearchResult = false;
    this.allergiesListData = [];
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.opdService
        .getAllergiesListFunV2(this.paitentAllergiesSearch)
        .subscribe(
          (data) => {
            this.allergiesListData = data.allergy_types;
            this.allergiesSearchResult = true;
          },
          (err) => {},
        );
    }, 50);
  }

  setAllergiesNmae(name): any {
    let items = $('#tags').tagsinput('items');
    items = items[items.length - 1];
    $('#tags').tagsinput('remove', items);
    $('#tags').tagsinput('add', name);
  }

  getPatientHistory(val): any {
    this.ngxLoader.start();
    this.patientHistoryComplaint = {
      presenting_complaints: '',
      presenting_complaints_history: '',
      physical_finding: '',
      past_medical_history: '',
      drug_history: '',
      family_history: '',
      allergies: '',
      general_physical_examination: '',
      summary: '',
    };
    $('#tags').tagsinput('removeAll');

    $('.select2').val(null);
    $('#ipdPatientAllergies').val(null);
    $('#ipdPatientAllergies').trigger('change');

    this.ngxLoader.start();
    this.opdService.getPatientHistoryFunV2(this.patient).subscribe(
      (data) => {
        this.ngxLoader.stop();
        $('#tags').tagsinput('removeAll');
        this.patientHistoryComplaint = {
          presenting_complaints: '',
          presenting_complaints_history: data.presenting_complaints_history,
          physical_finding: data.physical_finding,
          past_medical_history: data.past_medical_history,
          drug_history: data.drug_history,
          family_history: data.family_history,
          allergies: '',
          general_physical_examination: data.general_physical_examination,
          summary: data.summary,
        };

        const string = '';
        if (data.allergies) {
          $('#tags').tagsinput('add', data.allergies);
          data.allergies.split(',');
        }
        setTimeout(() => {
          $('#ipdPatientAllergies').val(string);
          $('#ipdPatientAllergies').trigger('change');
        }, 1000);

        $('#presentingComplaintsPatient').summernote(
          'code',
          data.presenting_complaints,
        );
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  // submit patient History Function
  submitPatientHistory(value): any {
    this.patientHistoryComplaint.presenting_complaints = $(
      '#presentingComplaintsPatient',
    ).summernote('code');
    this.patientHistoryComplaint.allergies = $('#tags').val();
    // this.patientHistoryComplaint.allergies = $('#ipdPatientAllergies').val().toString();
    if (
      this.patientHistoryComplaint.presenting_complaints === '' &&
      this.patientHistoryComplaint.presenting_complaints_history === '' &&
      this.patientHistoryComplaint.past_medical_history === '' &&
      this.patientHistoryComplaint.drug_history === '' &&
      this.patientHistoryComplaint.family_history === '' &&
      this.patientHistoryComplaint.allergies === '' &&
      this.patientHistoryComplaint.general_physical_examination === '' &&
      this.patientHistoryComplaint.summary === '' &&
      this.patientHistoryComplaint.physical_finding === ''
    ) {
      toastr.error('Add at least 1 history');
      return;
    }
    this.ngxLoader.start();
    this.opdService
      .submitPatientHistoryFunV2(this.patientHistoryComplaint, this.patient)
      .subscribe(
        (data) => {
          if (value === 'next') {
            $('.navi .navi-item .navi-link').removeClass('active');
            const element: HTMLElement = document.getElementById(
              'DiagnosisTabs',
            ) as HTMLElement;
            element.click();
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
}
