import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OtIssueItemsService } from '../../../services/ot-issue-items.service';
import { PdfServicesService } from '../../../services/pdf-services.service';
import { Location } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-print-issue-item-v2',
  templateUrl: './print-issue-item-v2.component.html',
  styleUrls: ['./print-issue-item-v2.component.css'],
})
export class PrintIssueItemV2Component implements OnInit {
  admissionId: any;
  settingData: any;
  listing = [];
  totalOTCash = 0;
  constructor(
    private route: ActivatedRoute,
    private otIssueService: OtIssueItemsService,
    private location: Location,
    private ngxLoader: NgxUiLoaderService,
    private pdfService: PdfServicesService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.admissionId = params['admission_id'];
    });
  }

  ngOnInit(): void {
    this.getSettingsData();
    if (this.admissionId) {
      this.getPatientIssuedItems(this.admissionId);
    }
  }
  getSettingsData(): any {
    this.pdfService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  getPatientIssuedItems(admissionId): any {
    this.ngxLoader.start();
    this.otIssueService.getIssuedItemsV2(admissionId).subscribe(
      (data) => {
        this.listing = data;
        for (let i = 0; i < this.listing.length; i++) {
          this.totalOTCash =
            this.totalOTCash +
            (this.listing[i].issue_quantity - this.listing[i].return_qty) *
              this.listing[i].sale_price;
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  printReport(): any {
    window.print();
  }
  back(): any {
    this.location.back();
  }
}
