import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbPaginationModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TaskManagementRoutingModule } from './task-management-routing.module';
import { AddTaskComponent } from './add-task/add-task.component';
import { ConfigurationService } from '../../services/configuration.service';
import { DepartmentsbyhospitalService } from '../../services/departmentsbyhospital.service';
import { PdfServicesService } from '../../services/pdf-services.service';
import { UserService } from '../../services/user.service';
import { ComplaintManagementRoutingModule } from '../complaint-management/complaint-management-routing.module';
import { TaskManagementService } from '../../services/task-management.service';
import { TaskStatusComponent } from './task-status/task-status.component';
import { TaskTabComponent } from './task-tab/task-tab.component';
import { TaskPriorityComponent } from './task-priority/task-priority.component';
import { UserStatusReportComponent } from './user-status-report/user-status-report.component';
import { RosterRoutingModule } from '../human-resource-management/roster/roster-routing.module';
import { CustomPipeModuleModule } from '../../custom-pipe-module/custom-pipe-module.module';
import { DepartmentsByHospitalService } from '../../services/departments-by-hospital.service';
import { RosterServiceService } from '../../services/roster-service.service';
import { RouteShareService } from '../../services/route-share.service';
import { ProjectComponent } from './project/project.component';
import { EmployeesTimesheetComponent } from './employees-timesheet/employees-timesheet.component';
import { ViewTaskComponent } from './view-task/view-task.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {PagesModule} from '../pages.module';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { PmToolDashboardComponent } from './pm-tool-dashboard/pm-tool-dashboard.component';
import {SharedModule} from '../shared-modules/shared.module';

@NgModule({
    declarations: [
        AddTaskComponent,
        TaskStatusComponent,
        TaskTabComponent,
        TaskPriorityComponent,
        UserStatusReportComponent,
        ProjectComponent,
        EmployeesTimesheetComponent,
        ViewTaskComponent,
        TasksListComponent,
        PmToolDashboardComponent,
    ],
    imports: [
      ReactiveFormsModule,
      NgbPaginationModule,
      FormsModule,
      CommonModule,
      TaskManagementRoutingModule,
      CustomPipeModuleModule,
      CommonModule,
      RosterRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      NgbPaginationModule,
      NgbPopoverModule,
      NgbTooltipModule,
      SharedModule,
    ],
    providers: [
        ConfigurationService,
        DepartmentsbyhospitalService,
        PdfServicesService,
        UserService,
        TaskManagementService,
        ConfigurationService,
        RosterServiceService,
        PdfServicesService,
        RouteShareService,
        UserService,
    ],
    exports: [
        ViewTaskComponent
    ]
})
export class TaskManagementModule {}
