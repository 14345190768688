import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShareDataService } from './share-data.service';

@Injectable({
  providedIn: 'root',
})
export class RouteShareService {
  value = 'abccccc';
  menus: any;
  subMenus: any;
  activeRoute;

  constructor(
    private router: Router,
    private shareDataService: ShareDataService,
  ) {}

  getValue(): any {
    this.activeRoute = this.router.url;
    this.activeRoute = this.activeRoute.substring(1);
    // console.log( 'current route', this.activeRoute);

    this.menus = JSON.parse(localStorage.getItem('currentUser'));
    this.subMenus = this.menus.role;

    if (this.subMenus) {
      for (const mainMenu of this.subMenus.role_menus) {
        for (const mainMenuSecond of mainMenu.role_menu_sub_menus) {
          for (const menuItem of mainMenuSecond?.role_menu_sub_menu_screens) {
            if (menuItem.screen) {
              let urls = menuItem.screen.url;

              if (urls.charAt(0) === '/') {
                urls = urls.substring(1);
              }
              if (urls === this.activeRoute) {
                localStorage.setItem('currentScreen', JSON.stringify(mainMenu));
                this.shareDataService.changeMessage(mainMenu);
                return menuItem.name;
              } else {
              }
            }
          }
        }
      }
    }
  }
}
