<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            Pathologist Remarks
          </h5>

          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--        <a  class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a>-->
        <!--end::Actions-->
        <!--begin::Dropdown-->
        <!--        <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">-->
        <!--          <a  class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--											<span class="svg-icon svg-icon-success svg-icon-2x">-->
        <!--												&lt;!&ndash;begin::Svg Icon | path:assets/media/svg/icons/Files/File-plus.svg&ndash;&gt;-->
        <!--												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
        <!--													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
        <!--														<polygon points="0 0 24 0 24 24 0 24" />-->
        <!--														<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />-->
        <!--														<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000" />-->
        <!--													</g>-->
        <!--												</svg>-->
        <!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--											</span>-->
        <!--          </a>-->
        <!--          <div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">-->
        <!--            &lt;!&ndash;begin::Navigation&ndash;&gt;-->
        <!--            <ul class="navi navi-hover">-->
        <!--              <li class="navi-header font-weight-bold py-4">-->
        <!--                <span class="font-size-lg">Choose Label:</span>-->
        <!--                <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mb-3 opacity-70"></li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-success">Customer</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-danger">Partner</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-warning">Suplier</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-primary">Member</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-dark">Staff</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mt-3 opacity-70"></li>-->
        <!--              <li class="navi-footer py-4">-->
        <!--                <a class="btn btn-clean font-weight-bold btn-sm" >-->
        <!--                  <i class="ki ki-plus icon-sm"></i>Add new</a>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--            &lt;!&ndash;end::Navigation&ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::-->

      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-12 col-12 pl-0">
          <div class="card">
            <div class="card-body department-card-body">
              <div class="row mb-4">
                <div class="col-12">
                  <h4>Lab Departments</h4>
                </div>
              </div>

              <div class="department-table overflow-auto">
                <table class="table table-responsive d-table">
                  <thead>
                    <tr>
                      <th style="width: 20%">Code</th>
                      <th style="width: 40%">Sub Department</th>
                      <th style="width: 20%">Type</th>
                      <!--                    <th style="width: 20%" class="text-center">Action</th>-->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let dep of subDepartmentsList; let i = index"
                      class="cursor-pointer lab-rows"
                      (click)="detailsPathologistRemarks(dep, $event)"
                    >
                      <td style="width: 20%">{{ dep?.department_code }}</td>
                      <td style="width: 40%">{{ dep?.title }}</td>
                      <td style="width: 20%">{{ dep?.floor }}</td>
                      <!--                    <td style="width: 20%" class="text-center">-->
                      <!--                      <span *ngIf="dep?.is_active === true">Active</span>-->
                      <!--                      <span *ngIf="dep?.is_active === false">Inactive</span>-->
                      <!--                    </td>-->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-7 col-md-7 col-sm-12 col-12 pl-0">
          <div class="card">
            <div class="card-body department-card-body">
              <div class="row mb-4">
                <div class="col-6">
                  <h4>Pathologist Remarks</h4>
                </div>
                <div class="col-6 text-right">
                  <button
                    type="button"
                    (click)="addPathologistRemarksModal()"
                    class="btn btn-light-primary btn-sm"
                  >
                    Add
                  </button>
                </div>
              </div>

              <form [formGroup]="updateRemarks" (ngSubmit)="onUpdateRemarks()">
                <div class="department-pathologist-table overflow-auto">
                  <table class="table" *ngIf="remarksList.length > 0">
                    <thead>
                      <tr>
                        <th style="width: 20%">Code</th>
                        <th style="width: 70%" class="pl-0">Remarks</th>
                        <th style="width: 10%" class="text-center pl-0">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container
                        formArrayName="remarks"
                        *ngFor="let g of remarksArray.controls; let i = index"
                      >
                        <tr [formGroupName]="i">
                          <th style="width: 20%">
                            <input
                              class="form-control form-control-sm"
                              type="text"
                              disabled
                              placeholder="Code"
                              formControlName="code"
                            />
                          </th>
                          <th style="width: 70%" class="pl-0">
                            <input
                              class="form-control form-control-sm"
                              type="text"
                              placeholder="Remarks"
                              formControlName="remarks"
                              [ngClass]="{
                                'is-invalid':
                                  submitted && g.controls.remarks.errors
                              }"
                            />
                          </th>
                          <th
                            style="width: 10%; padding-top: 20px"
                            class="text-center pl-0"
                          >
                            <input type="checkbox" formControlName="active" />
                            <!--                          <span>{{g.get('active').value}}</span>-->
                            <!--                          <span *ngIf="g.get('active').value === true">Active</span>-->
                            <!--                          <span *ngIf="g.get('active').value === false">Inactive</span>-->
                          </th>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>

                  <div
                    class="clearfix text-right"
                    *ngIf="remarksList.length > 0"
                  >
                    <button type="submit" class="btn btn-primary btn-sm">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!--end::-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<!--Add New Sub Department Modal-->
<div class="example-preview">
  <div>
    <ng-template #addNewSubDepartment let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Add Sub Department</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body department-card-body">
          <form autocomplete="off">
            <div class="row m-0">
              <div class="col-lg-3 col-md-3 col-sm-3">
                <label>Code</label>
                <input
                  class="form-control form-control-sm"
                  name="code"
                  id="subDepartmentCode"
                  type="text"
                  [(ngModel)]="addSubDepartment.department_code"
                />
              </div>
              <div class="col-lg-5 col-md-5 col-sm-6 pl-0">
                <label>Sub Department</label>
                <input
                  class="form-control form-control-sm"
                  name="name"
                  id="subDepartmentName"
                  type="text"
                  [(ngModel)]="addSubDepartment.title"
                />
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 pl-0">
                <label>Type</label>
                <select
                  class="form-control form-control-sm"
                  name="floor"
                  [(ngModel)]="addSubDepartment.floor"
                >
                  <option value="Basement">Basement</option>
                  <option value="Ground Floor">Ground Floor</option>
                  <option value="1st Floor">1st Floor</option>
                  <option value="2nd Floor">2st Floor</option>
                  <option value="3rd Floor">3rd Floor</option>
                  <option value="4th Floor">4th Floor</option>
                  <option value="5th Floor">5th Floor</option>
                  <option value="6th Floor">6th Floor</option>
                  <option value="7th Floor">7th Floor</option>
                  <option value="8th Floor">8th Floor</option>
                  <option value="9th Floor">9th Floor</option>
                  <option value="10th Floor">10th Floor</option>
                </select>
              </div>
              <div class="col-lg-1 col-md-1 col-sm-2">
                <label>Status</label>
                <div>
                  <input
                    type="checkbox"
                    name="active"
                    [(ngModel)]="addSubDepartment.is_active"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="submitSubDepartmentFun()"
          >
            Add
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Update Sub Department Modal-->
<div class="example-preview">
  <div>
    <ng-template #subDepartmentUpdateModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Update Sub Department</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form autocomplete="off">
            <div class="row">
              <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <label>Code</label>
                <input
                  class="form-control form-control-sm"
                  name="code"
                  [disabled]="true"
                  [(ngModel)]="activeSubDepartment.department_code"
                  type="text"
                />
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                <label>Sub Department</label>
                <input
                  class="form-control form-control-sm subDepartmentName"
                  name="name"
                  id="updateSubDepartmentName"
                  type="text"
                  [(ngModel)]="activeSubDepartment.title"
                />
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                <label>Type</label>
                <select
                  class="form-control form-control-sm"
                  name="type"
                  [(ngModel)]="activeSubDepartment.floor"
                >
                  <option value="Basement">Basement</option>
                  <option value="Ground Floor">Ground Floor</option>
                  <option value="1st Floor">1st Floor</option>
                  <option value="2nd Floor">2st Floor</option>
                  <option value="3rd Floor">3rd Floor</option>
                  <option value="4th Floor">4th Floor</option>
                  <option value="5th Floor">5th Floor</option>
                  <option value="6th Floor">6th Floor</option>
                  <option value="7th Floor">7th Floor</option>
                  <option value="8th Floor">8th Floor</option>
                  <option value="9th Floor">9th Floor</option>
                  <option value="10th Floor">10th Floor</option>
                </select>
              </div>
              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-center">
                <label>Status</label>
                <input
                  type="checkbox"
                  name="status"
                  [(ngModel)]="activeSubDepartment.is_active"
                />
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 align-self-end">
                <!--                (click)="detailsPathologistRemarks(activeSubDepartment)"-->
                <button class="btn btn-default">Pathologist Remarks</button>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="updateSubDepartment()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Add Pathology Remarks Modal-->
<div class="example-preview">
  <div>
    <ng-template #addpathologyRemarks let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Add Pathologist Remarks</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body department-card-body">
          <form>
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-6">
                <label>Code</label>
                <input
                  class="form-control form-control-sm"
                  type="text"
                  placeholder="Code"
                  [(ngModel)]="addRemarks.code"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>

              <div class="col-lg-7 col-md-7 col-sm-8 pr-0">
                <label>Remarks</label>
                <input
                  class="form-control form-control-sm"
                  type="text"
                  placeholder="Remarks"
                  [(ngModel)]="addRemarks.remarks"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>

              <div class="col-lg-2 col-md-2 col-sm-6 text-center">
                <label>Status</label>
                <div class="pt-2">
                  <input
                    type="checkbox"
                    [(ngModel)]="addRemarks.active"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>

              <div class=""></div>
            </div>
          </form>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="addPathologistRemarks()"
          >
            Add
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
