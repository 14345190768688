import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { HospitalService } from '../../../../services/hospital.service';
import * as moment from 'moment/moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NursingProcedureService } from '../../../../services/nursing-procedure.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-nursing-procedure',
  templateUrl: './nursing-procedure.component.html',
  styleUrls: ['./nursing-procedure.component.css'],
})
export class NursingProcedureComponent implements OnInit {
  @Input() patient: any;
  @Input() visitType: any;
  @Input() isAdd: boolean;

  settingData: any;
  currentDateForNursing: any;
  admissionDate: string;
  currentDate = new Date();
  procedureData: any;
  procedureDate: any;
  doneBy = false;
  procedureForm: UntypedFormGroup;
  intervalId: any;
  ServiceId: any;
  isCheckedInterval = true;

  constructor(
    private hospitalService: HospitalService,
    private ngxLoader: NgxUiLoaderService,
    private nursingService: NursingProcedureService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.getSettingsData();
    if (this.isAdd === false) {
      this.procedureDate = $('#procedureDates').val()
        ? moment($('#procedureDates').val(), 'DD-MM-YYYY').format('DD-MM-YYYY')
        : '';
    } else {
      this.procedureDate = $('#procedureDate').val()
        ? moment($('#procedureDate').val(), 'DD-MM-YYYY').format('DD-MM-YYYY')
        : '';
    }
    this.procedureForm = this.formBuilder.group({
      emp_id: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.getDateNursingProcedure('');
  }

  ngOnChanges(changes: SimpleChanges): any {
    this.getNursingProcedure(changes.patient.currentValue);
    this.getDateNursingProcedure(changes.patient.currentValue);
  }
  getIntervalTime(val) {
    if (val) {
      return moment(val, 'hh:mm').format('hh:mm a');
    } else {
      return '';
    }
  }

  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetailsv2().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {},
    );
  }

  sendIdPassword(): any {
    this.doneBy = true;
    if (this.procedureForm.invalid) {
      return;
    }
    this.procedureDate = $('#procedureDate').val()
      ? moment($('#procedureDate').val(), 'DD-MM-YYYY').format('DD-MM-YYYY')
      : '';
    this.ngxLoader.start();
    this.nursingService
      .sendIdPassword(
        this.procedureForm.value,
        this.intervalId,
        this.ServiceId,
        this.patient?.id,
        this.isCheckedInterval,
        this.procedureDate,
        this.patient.id,
        this.patient.visit_id,
      )
      .subscribe(
        (data) => {
          toastr.success('submit Successfully');
          $('#procedureModal').modal('hide');
          this.procedureForm.patchValue({
            emp_id: '',
            password: '',
          });
          this.getNursingProcedure('');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  getDateNursingProcedure(val): any {
    this.currentDateForNursing = new Date();
    this.admissionDate = moment(this.patient?.admissions[0].created_at).format(
      'DD-MM-YYYY',
    );
    this.currentDateForNursing = moment(this.currentDate).format('DD-MM-YYYY');
    if (this.isAdd === false) {
      setTimeout(() => {
        $('#procedureDates').datepicker({
          format: 'dd-mm-yyyy',
          orientation: 'bottom left',
          startDate: this.admissionDate,
          endDate: this.currentDateForNursing,
        });
        $('#procedureDates').datepicker('setDate', this.currentDateForNursing);
      }, 500);
    } else {
      setTimeout(() => {
        $('#procedureDate').datepicker({
          format: 'dd-mm-yyyy',
          orientation: 'bottom left',
          startDate: this.admissionDate,
          endDate: this.currentDateForNursing,
        });
        $('#procedureDate').datepicker('setDate', this.currentDateForNursing);
      }, 500);
    }
  }

  openModal(id, service_id): any {
    this.intervalId = id;
    this.ServiceId = service_id;
    $('#procedureModal').modal('show');
    this.doneBy = false;
    this.procedureForm.reset({
      emp_id: '',
      password: '',
    });
  }

  getNursingProcedure(val): any {
    this.procedureData = [];
    if (this.isAdd === false) {
      this.procedureDate = $('#procedureDates').val();
    } else {
      this.procedureDate = $('#procedureDate').val();
    }
    if (!this.procedureDate) {
      this.procedureDate = moment(this.currentDate).format('DD-MM-YYYY');
    }
    this.ngxLoader.start();
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.nursingService
      .getNursingProcedure(
        this.procedureDate,
        this.patient.id,
        this.patient.visit_id,
        this.visitType,
      )
      .subscribe(
        (data) => {
          this.procedureData = data.data;
          this.procedureData.forEach((m) => {
            m.interval.forEach((intData: any) => {
              intData.start_time = this.getIntervalTime(intData.start_time);
              intData.end_time = this.getIntervalTime(intData.end_time);
            });
          });
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  closeModal(): any {
    $('#procedureModal').modal('hide');
  }

  printNursingProcedure(): any {
    window.open(
      'pdf-reports/nursing-report?id=' +
        this.patient.id +
        '&visit_id=' +
        this.patient.visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&invoice_id=' +
        '&nursing_date=' +
        this.procedureDate,
    );
    // window.print();
  }
}
