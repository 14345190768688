<div class="row">
  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
    <div class="form-group">
      <label>Template Name <span class="text-danger">*</span></label>
      <input type="text" class="form-control form-control-sm" placeholder="Template Name" (keypress)="maxLength($event)" [(ngModel)]="templateName">
    </div>
  </div>
  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mt-4">
    <div class="d-flex align-items-center justify-content-end">
      <label class="pr-2">Self</label>
      <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        checked="checked"
                        name="select"
                        maxlength="50"
                        [(ngModel)]="self"
                      />
                      <span></span>
                    </label>
                  </span>
      <label class="pr-2">My Department</label>
    </div>
  </div>
<!--Description Editor-->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="form-group">
      <label>Description <span class="text-danger">*</span></label>
      <textarea
        class="textareas form-control"
        rows="2"
        id="description"
        [(ngModel)]="description"
      ></textarea>
    </div>
  </div>

<!--  Submit Button-->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right">
    <button type="button" class="btn btn-sm btn-primary" (click)="submit()" [disabled]="isSubmit">Submit</button>
  </div>

<!--  table listing-->
  <div class="table-responsive" style="max-height: 250px; overflow-y: auto">
    <table class="table table-head-custom table-vertical-center" *ngIf="opdTemplateData.length">
      <thead>
      <tr>
        <th style="width: 15%">Code</th>
        <th style="width: 25%">Name</th>
        <th style="width: 25%">User</th>
        <th style="width: 25%">Department</th>
        <th style="width: 10%">Status</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let list of opdTemplateData; let i = index">
        <tr style="border-top: none !important">
          <td>{{list?.code}}</td>
          <td>{{ list?.name }}</td>
          <td>{{list?.employee_name}}</td>
          <td>{{list?.department_name}}</td>
          <td>
            <span class="label label-lg label-light-primary label-inline" *ngIf="list?.status">Active</span>
            <span class="label label-lg label-light-danger label-inline" *ngIf="!list?.status">Inactive</span>
          </td>
        </tr>
        <tr *ngIf="list.description">
          <td colspan="10" class="font-weight-bold" style="border-top: none !important">
            <div class="d-flex">
              <div>Description: </div>
              <div class="ml-3">  <app-summernotes
                [id]="'description' + i"
                [htmlData]="list?.description">
              </app-summernotes>
              </div>
            </div>
          </td>
        </tr>

      </ng-container>
      </tbody>
    </table>
  </div>
</div>

