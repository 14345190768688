import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  DialysisRequest,
  PatientDialysis,
  PatientDialysisVital,
} from '../../_models';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialysisService } from '../../../services/dialysis.service';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { EmployeeRegistrationService } from '../../../services/employee-registration.service';
import { StockService } from '../../../services/stock.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSharingServiceService } from '../../../services/data-sharing-service.service';
import { VitalsValidationService } from '../../../services/vitals-validation.service';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment/moment';
import { utc } from 'moment/moment';
declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-patient-dialysis-v2',
  templateUrl: './patient-dialysis-v2.component.html',
  styleUrls: ['./patient-dialysis-v2.component.css'],
})
export class PatientDialysisV2Component implements OnInit {
  @ViewChild('patientChangeModal') patientChangeModal: ElementRef;

  patient: any;
  isPatients = false;
  PatientPreDialysisForm: UntypedFormGroup;
  PatientDuringDialysisForm: UntypedFormGroup;
  PatientPostDialysisForm: UntypedFormGroup;
  submitted = false;
  submittedPD = false;
  submittedDuring = false;
  current_url = '';
  current_environment: string;
  patient_dialysis: PatientDialysis;
  dialysisRequests = [];
  dialysisRequest: DialysisRequest;
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  dialysisMachines = [];
  doctor = [];
  employeeSearch = { type: 'default', search_query: '' };
  employees = [];
  heparins = [];
  bodyRegions = [];
  preDialysisVital: PatientDialysisVital;
  postDialysisVital: PatientDialysisVital;
  duringDialysisVitals: PatientDialysisVital[];
  isPreDialysisVital = true;
  isDuringDialysisVital = false;
  isPostDialysisVital = false;
  dialyzers = [];
  equipments = [];
  takenTime: any;
  productListData = [];
  productSearchResult = false;
  isPSSearching = false;
  isSearching = false;
  clearTimeout: any;
  stockData = { product_name: '' };
  query = '';
  queryType = 'default';
  lastTakenTime: any;
  durationValue: any;
  currentTime: any;
  lastWeightValue: any;
  invoiceNumber: any;
  curentTimeVital: any;
  primingSolDefaultVal: any;
  primingDefaultVal = '';
  patentIndex: any;
  now = new Date().toString().split(' ')[4].slice(0, -3);
  currentPatientData: any;
  currentPatientIndex: any;
  disableSubmitButton = false;
  dataOfImages = [];
  idForStoringImage: any;
  isPatientDisplayImage = false;
  isRemoveArray: boolean;
  refreshImagesModal = false;
  isUploadImage = false;
  isDisplayImage = false;
  receivedImageFiles = [];
  isArrayList = true;
  isValidTimeStartFormat = true;
  isValidTimeEndFormat = true;
  time: string;
  hoursValue: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private dialysisService: DialysisService,
    private hospitalDoctorsService: HospitaldoctorService,
    private employeeService: EmployeeRegistrationService,
    private stockService: StockService,
    private modalService: NgbModal,
    private shareDataService: DataSharingServiceService,
    private vitalValidation: VitalsValidationService,
  ) {}

  ngOnInit(): any {
    this.isPatientDisplayImage = false;
    this.current_url = this.router.url;
    this.isRemoveArray = false;
    this.ngxLoader.stop();
    this.isArrayList = true;
    this.PatientPreDialysisForm = this.formBuilder.group({
      advised_by_id: ['', Validators.required],
      vascular_access_id: ['', Validators.required],
      dialysis_request_id: [''],
      dialysis_number: [''],
      invoice_number: [''],
      date: [this.today],
      dialyzer_id: [''],
      equipment_id: ['', Validators.required],
      product_name: [''],
      priming_solution_id: [''],
      priming_by_id: ['', Validators.required],
      start_time: ['', Validators.required],
      instruction: [''],
      end_time: [''],
      duration: [''],
      heparin_id: [''],
      last_dialysis_weight: [''],
      patient_dialysis_vitals_attributes: this.formBuilder.array([
        this.createVital('pre', '', ''),
      ]),
      pre_dialysis_assessment_attributes: this.formBuilder.group({
        dialysis_patient_id: [''],
        complaints: [''],
        conscious_level: [''],
        edema: [''],
        card_rhythm: [''],
        lungs: [''],
        remarks: [''],
        other: [''],
      }),
    });

    this.PatientDuringDialysisForm = this.formBuilder.group({
      id: ['', Validators.required],
      duration_during_dialysis: [''],
      patient_dialysis_vitals_attributes: this.formBuilder.array([]),
    });

    this.PatientPostDialysisForm = this.formBuilder.group({
      id: ['', Validators.required],
      end_time: [''],
      duration: [''],
      staff_id: [''],
      status: ['completed'],
      patient_dialysis_vitals_attributes: this.formBuilder.array([]),
    });

    this.currentTime = this.now;
    this.current_environment = environment.rorUrl;
    // this.PatientPreDialysisForm.get('product_name').patchValue('normal saline');
    this.fetchDialysisRequests();
    this.fetchEmployees();
    this.fetchHeparinConfig();
    this.fetchDoctor();
    this.fetchBodyRegions();
    this.fetchDialyzers();
    this.fetchEquipments();
    this.fetchPrimingSol();

    $('html').click(function () {
      $('.search-result-main').hide();
      $('.search-result-ps').hide();
    });

    $('.selectpicker').selectpicker();
  }

  createVital(type, takenTime, defaultData): UntypedFormGroup {
    const current = new Date().toString().split(' ')[4].slice(0, -3);
    this.curentTimeVital = defaultData
      ? moment.utc(defaultData.taken_time).format('HH:mm')
      : current;
    return this.formBuilder.group({
      vital_type: [type],
      taken_time: [
        defaultData
          ? moment.utc(defaultData.taken_time).format('HH:mm')
          : current,
      ],
      // taken_time: [defaultData ? this.convertDateToTime(defaultData.taken_time) : this.curentTimeVital],
      // taken_time: [defaultData ? this.convertDateToTime(defaultData.taken_time) : this.convertDateToTime(current)],
      observation: [''],
      weight_difference: [''],
      total_hours: [''],
      uf: [defaultData ? defaultData.uf : ''],
      uf_rate: [defaultData ? defaultData.uf_rate : ''],
      heparin_id: [defaultData ? defaultData.heparin_id : ''],
      product_name: [
        defaultData && defaultData.heparin
          ? defaultData.heparin.product_name
          : '',
      ],
      fluid_id: [defaultData ? defaultData.fluid_id : ''],
      blood_flow: [defaultData ? defaultData.blood_flow : ''],
      instruction: [defaultData ? defaultData.instruction : ''],
      vital_attributes: this.formBuilder.group({
        weight: [
          defaultData && defaultData.vital ? defaultData.vital.weight : '',
        ],
        height: [
          defaultData && defaultData.vital ? defaultData.vital.height : '',
        ],
        temperature: [
          defaultData && defaultData.vital ? defaultData.vital.temperature : '',
        ],
        bp_systolic: [
          defaultData && defaultData.vital ? defaultData.vital.bp_systolic : '',
        ],
        bp_diastolic: [
          defaultData && defaultData.vital
            ? defaultData.vital.bp_diastolic
            : '',
        ],
        pulse: [
          defaultData && defaultData.vital ? defaultData.vital.pulse : '',
        ],
        resp_rate: [
          defaultData && defaultData.vital ? defaultData.vital.resp_rate : '',
        ],
        o2_saturation: [
          defaultData && defaultData.vital
            ? defaultData.vital.o2_saturation
            : '',
        ],
      }),
    });
  }

  addMoreVital(defaultData): void {
    const control = this.PatientDuringDialysisForm.controls
      .patient_dialysis_vitals_attributes as UntypedFormArray;
    // this.vitalTakenTime();
    control.push(this.createVital('during', '', defaultData));
    // control.push(this.createVital('during', this.takenTime, defaultData));
    // control.push(this.createVital('during', this.takenTime));
  }

  removeVital(i: number): any {
    const control = this.PatientDuringDialysisForm.controls
      .patient_dialysis_vitals_attributes as UntypedFormArray;
    control.removeAt(i);
  }

  getProductList(event, type, className) {
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.productListData = [];
      this.productSearchResult = false;
      $(className).hide();
      if (event.target.value.length < 2) {
        return;
      }
      if (type === 'priming_sol') {
        this.isPSSearching = true;
      } else {
        this.isSearching = true;
      }
      $(className).show();
      this.stockService.searchProduct('', event.target.value).subscribe(
        (data) => {
          this.productListData = data.items;
          this.productSearchResult = true;
        },
        (err) => {
          this.productListData = [];
          this.productSearchResult = true;
        },
      );
    }, 1000);
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  _keyPressOnlyChar(event: any): any {
    const pattern = /^[a-zA-Z\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  setProduct(item): any {
    this.PatientPreDialysisForm.get('priming_solution_id').patchValue(item.id);
    this.PatientPreDialysisForm.get('product_name').patchValue(
      item.product_name,
    );
    $('.search-result-ps').hide();
    this.isPSSearching = false;
  }

  setVitalMed(item, form, i) {
    form[i].get('heparin_id').patchValue(item.id);
    form[i].get('product_name').patchValue(item.product_name);
    $('.search-result-main').hide();
    this.isSearching = false;
  }

  vitalTakenDuration(event): any {
    this.durationValue = event.target.value;
  }

  vitalTakenTime(): any {
    const objArray =
      this.PatientDuringDialysisForm.value.patient_dialysis_vitals_attributes;
    if (objArray.length <= 0) {
      this.lastTakenTime = this.preDialysisVital.taken_time;
    } else {
      const cdate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
      this.lastTakenTime = moment(
        `${cdate} ${objArray[objArray.length - 1].taken_time}`,
        'YYYY-MM-DD HH:mm:ss',
      ).format();
    }
    const time =
      new Date(this.lastTakenTime).getTime() +
      Number(this.durationValue) * 60 * 1000;
    this.takenTime = moment(time).format('HH:mm');
  }

  fetchDialysisRequestInfo(dialysisRequest, ind): any {
    this.patient = dialysisRequest.patient;
    // this.idForStoringImage=dialysisRequest.dialysis_patient.id;
    this.refreshImagesModal = false;
    this.closeModal();
    // if (this.patient.id !== dialysisRequest.patient.id) {
    this.currentTime = new Date().toString().split(' ')[4].slice(0, -3);
    this.patentIndex = ind;
    this.dialysisRequest = dialysisRequest;
    this.PatientPreDialysisForm.get('dialysis_request_id').patchValue(
      this.dialysisRequest.id,
    );
    this.lastWeightValue = '';
    this.changeTab(0, false);
    this.lastWeightValue = this.dialysisRequest.last_dialysis_weight;
    if (dialysisRequest.dialysis_patient) {
      //      this.idForStoringImage= dialysisRequest.dialysis_patient.id;
      //    this.getImagesDialysis();
      this.lastWeightValue =
        this.dialysisRequest.dialysis_patient.last_dialysis_weight;
      this.patient_dialysis = dialysisRequest.dialysis_patient;
      console.log(' this.patient_dialysis', this.patient_dialysis);
      this.PatientPreDialysisForm.get('priming_solution_id').patchValue(
        this.patient_dialysis.priming_solution_id,
      );
      this.PatientPostDialysisForm.get('id').patchValue(
        this.patient_dialysis.id,
      );
      this.PatientPreDialysisForm.get('advised_by_id').patchValue(
        dialysisRequest.dialysis_patient.advised_by_id,
      );
      this.PatientDuringDialysisForm.get('duration_during_dialysis').setValue(
        dialysisRequest.dialysis_patient.duration_during_dialysis,
      );
      if (this.patient_dialysis.patient_dialysis_vitals) {
        const control = this.PatientDuringDialysisForm.controls
          .patient_dialysis_vitals_attributes as UntypedFormArray;
        while (control.length) {
          control.removeAt(0);
        }
        if (
          this.patient_dialysis.patient_dialysis_vitals.filter(
            (v) => v.vital_type === 'pre',
          )
        ) {
          const preVitals =
            this.patient_dialysis.patient_dialysis_vitals.filter(
              (v) => v.vital_type === 'pre',
            );
          this.preDialysisVital = preVitals[0];
          if (this.preDialysisVital) {
            this.PatientPreDialysisForm.get(
              'patient_dialysis_vitals_attributes',
            )
              ['controls'][0].get('heparin_id')
              .patchValue(this.preDialysisVital.heparin_id);
          }
        }
        this.duringDialysisVitals =
          this.patient_dialysis.patient_dialysis_vitals.filter(
            (v) => v.vital_type === 'during',
          );
        if (this.duringDialysisVitals.length > 0) {
          for (const pdv of this.duringDialysisVitals) {
            this.addMoreVital(pdv);
          }
        }
        const postVitals = this.patient_dialysis.patient_dialysis_vitals.filter(
          (v) => v.vital_type === 'post',
        );
        this.postDialysisVital = postVitals[0];
        if (this.postDialysisVital) {
          if (this.postDialysisVital.heparin_id) {
            this.PatientPostDialysisForm.get(
              'patient_dialysis_vitals_attributes',
            )
              ['controls'][0].get('heparin_id')
              .patchValue(this.postDialysisVital.heparin_id);
          }
        }
      }
    } else {
      this.patient_dialysis = dialysisRequest.dialysis_patient;
      this.preDialysisVital = dialysisRequest.dialysis_patient;
      this.postDialysisVital = dialysisRequest.dialysis_patient;
      this.duringDialysisVitals = dialysisRequest.dialysis_patient;
    }
    if (dialysisRequest.patient_service_invoice_id) {
      this.invoiceNumber = dialysisRequest.patient_service_invoice_id;
    } else {
      this.invoiceNumber = '';
    }
    this.dialysisRequest = dialysisRequest;
    let endTime;
    console.log('start_time', dialysisRequest.dialysis_patient.start_time);
    console.log('end_time', dialysisRequest.dialysis_patient.end_time);
    const timePattern =
      /^(([01]?[0-9]|2[0-3]):[0-5][0-9])|(\d{1,2}:\d{1,2}:\d{1,2})$/;

    // Check if the timeInput matches either format.
    const isValidStartTime = timePattern.test(
      dialysisRequest.dialysis_patient.start_time,
    );
    const isValidEndTime = timePattern.test(
      dialysisRequest.dialysis_patient.end_time,
    );
    console.log('isValidStartTime', isValidStartTime);
    console.log('isValidEndTime', isValidEndTime);
    // const regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    // this.isValidTimeStartFormat = regex.test(this.dialysisRequest.dialysis_patient.start_time);
    // this.isValidTimeEndFormat = regex.test(this.dialysisRequest.dialysis_patient.end_time);
    if (dialysisRequest.dialysis_patient) {
      if (!isValidEndTime && !isValidStartTime) {
        const startTime = moment
          .utc(this.dialysisRequest.dialysis_patient.start_time)
          .format('HH:mm');
        const endTime = moment
          .utc(this.dialysisRequest.dialysis_patient.end_time)
          .format('HH:mm');
        console.log('startTime', startTime);
        console.log('endTime', endTime);
        this.PatientPreDialysisForm.get('start_time').patchValue(startTime);
        this.PatientPreDialysisForm.get('end_time').patchValue(endTime);
        this.patient_dialysis.start_time =
          this.PatientPreDialysisForm.get('start_time').value;
        this.patient_dialysis.end_time =
          this.PatientPreDialysisForm.get('end_time').value;
        const preVitals = this.patient_dialysis.patient_dialysis_vitals.filter(
          (v) => v.vital_type === 'pre',
        );
        console.log('preVitals', preVitals);
        const pre = preVitals[0];
        console.log('pre', pre);
        if (pre) {
          const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
          if (!regex.test(this.preDialysisVital.taken_time)) {
            this.preDialysisVital.taken_time = moment
              .utc(pre.taken_time)
              .format('HH:mm');
          } else {
            this.preDialysisVital.taken_time = pre.taken_time;
          }
          // const form = this.PatientPreDialysisForm.get('patient_dialysis_vitals_attributes')['controls'][0];
          // console.log('form', form);
          // form.get('taken_time').patchValue(moment(this.preDialysisVital?.taken_time).format('HH:mm'));
          // console.log('2nd diff',form);
        }
        console.log('start_time', this.patient_dialysis.start_time);
        console.log('end_time', this.patient_dialysis.end_time);
      }
    } else if (this.dialysisRequest.available_slot) {
      this.PatientPreDialysisForm.get('start_time').patchValue(
        this.convertDateToTime(this.dialysisRequest.available_slot.start_time),
      );
      this.PatientPreDialysisForm.get('end_time').patchValue(
        this.convertDateToTime(this.dialysisRequest.available_slot.end_time),
      );
    } else {
      this.PatientPreDialysisForm.get('start_time').patchValue(
        this.currentTime,
      );
      this.PatientPreDialysisForm.get('end_time').patchValue(this.currentTime);
    }
    // this.PatientPreDialysisForm.get('advised_by_id').patchValue(this.dialysisRequest.advised_by_id);
    if (dialysisRequest.dialysis_patient) {
      // this.isDisplayImage=true;
      // this.isArrayList=true;
      // this.isUploadImage=true;
      // this.idForStoringImage= this.dialysisRequest.dialysis_patient.id;
      // this.getImagesDialysis();
      console.log('Pre dialysic', this.preDialysisVital.vital);
      console.log('last value', this.lastWeightValue);
      if (this.lastWeightValue && this.preDialysisVital.vital) {
        if (this.preDialysisVital.vital.weight) {
          let diff: any =
            this.preDialysisVital.vital.weight - this.lastWeightValue;
          diff = diff.toFixed(2);
          const form = this.PatientPreDialysisForm.get(
            'patient_dialysis_vitals_attributes',
          )['controls'][0];
          form.get('weight_difference').patchValue(diff);
        }
      }
    } else {
      const form = this.PatientPreDialysisForm.get(
        'patient_dialysis_vitals_attributes',
      )['controls'][0];
      form.get('weight_difference').patchValue('');
      console.log('2nd diff', form);
    }
    this.updateDuration();
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
      // $('#advisedBy').selectpicker('refresh');
      // $('#primaryBy').selectpicker('refresh');
      // $('#heparin').selectpicker('refresh');
    }, 200);
    this.isRemoveArray = true;
    this.isPatientDisplayImage = true;
    if (
      this.currentPatientData.dialysis_patient !== null &&
      this.currentPatientData.dialysis_patient !== ''
    ) {
      this.isDisplayImage = true;
      this.isArrayList = true;
      this.isUploadImage = true;
      this.idForStoringImage = this.dialysisRequest.dialysis_patient.id;
      this.getImagesDialysis();
    } else {
      this.isArrayList = false;
      this.isDisplayImage = false;
      this.isUploadImage = true;
    }
    setTimeout(() => {
      this.refreshImagesModal = true;
      console.log('referesh image is called');
    }, 500);
  }

  convertDateToTime(value): any {
    return moment(value).format('HH:mm');
  }

  setQuery(e): any {
    this.query = e.target.value;
  }

  setQueryType(e): any {
    this.queryType = e.target.value;
  }

  fetchDialysisRequests(): any {
    this.ngxLoader.start();
    this.dialysisService
      .fetchDialysisRequestsV2(this.queryType, this.query)
      .subscribe(
        (data) => {
          this.dialysisRequests = data.dialysis_requests;
          // this.isDisplayImage=true;
          // this.isArrayList=true;
          // this.isUploadImage=true;
          // this.idForStoringImage= this.dialysisRequest.dialysis_patient.id;
          // this.getImagesDialysis();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          this.dialysisRequests = [];
          toastr.error(err.error.message);
        },
      );
  }

  fetchDoctor(): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.getHospitalDoctorsByNameV2().subscribe(
      (data) => {
        this.doctor = data.users;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#advisedBy').selectpicker('refresh');
          $('.doctorSelectpicker').selectpicker({
            dropupAuto: false,
          });
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchEmployees(): any {
    this.ngxLoader.start();
    this.employeeService.fetchAllEmployees(this.employeeSearch).subscribe(
      (data) => {
        this.employees = data.employees;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#primaryBy').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchHeparinConfig(): any {
    this.ngxLoader.start();
    this.dialysisService.fetchHeparinV2().subscribe(
      (data) => {
        this.heparins = data.dialysis_configurations;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#heparin').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchBodyRegions(): any {
    this.bodyRegions = [];
    this.employeeService.fetchConfigurationV2('body_region').subscribe(
      (data) => {
        this.bodyRegions = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchDialyzers(): any {
    this.bodyRegions = [];
    this.employeeService.fetchConfigurationV2('dialyzer').subscribe(
      (data) => {
        this.dialyzers = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchEquipments(): any {
    this.ngxLoader.start();
    this.dialysisService.fetchEquipmentsV2('Dialysis').subscribe(
      (data) => {
        this.equipments = data.equipments;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchPrimingSol(): any {
    this.ngxLoader.start();
    this.dialysisService.getDialysisPrimingSol().subscribe(
      (data) => {
        this.primingSolDefaultVal = data.default_configurators;
        this.ngxLoader.stop();
        if (this.primingSolDefaultVal.length > 0) {
          this.PatientPreDialysisForm.get('priming_solution_id').patchValue(
            this.primingSolDefaultVal[0].defaultable_id,
          );
          this.primingDefaultVal =
            this.primingSolDefaultVal[0].defaultable.product_name;
          // this.PatientPreDialysisForm.get('product_name').patchValue(this.primingSolDefaultVal[0].defaultable.product_name);
          $('.search-result-ps').hide();
          this.isPSSearching = false;
        }
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  weightDiffernce(value, form_type, i): any {
    const form = this.PatientPreDialysisForm.get(form_type)['controls'][i];
    if (this.dialysisRequest && value) {
      value =
        Number(value) -
        this.dialysisRequest.dialysis_patient.last_dialysis_weight;
      // value = Number(value) - (this.dialysisRequest.last_dialysis_weight  || Number(value));
      value = value.toFixed(2);
      form.get('weight_difference').patchValue(value);
      this.calculateUf(value, form_type, i);
    }
    // value = (value/2.2046).toFixed(2);
  }

  calcWeightDiffernce(formType, i): any {
    if (!this.lastWeightValue) {
      this.lastWeightValue = this.dialysisRequest.dialysis_patient
        ? this.dialysisRequest.dialysis_patient.last_dialysis_weight
        : '';
      // this.lastWeightValue = Number(this.dialysisRequest.dialysis_patient.last_dialysis_weight);
    }
    const form = this.PatientPreDialysisForm.get(formType)['controls'][i];
    if (
      this.lastWeightValue &&
      this.PatientPreDialysisForm.get([
        'patient_dialysis_vitals_attributes',
        0,
      ]).get('vital_attributes').value.weight
    ) {
      const currentWeight = this.PatientPreDialysisForm.get([
        'patient_dialysis_vitals_attributes',
        0,
      ]).get('vital_attributes').value.weight;
      const lastWeight = Number(this.lastWeightValue);
      if (lastWeight > currentWeight) {
        toastr.error('Current Weight sholud be greater or equal');
        return;
      }
      let diff: any = currentWeight - lastWeight;
      diff = diff.toFixed(2);
      form.get('weight_difference').patchValue(diff);
      this.calculateUf(diff, formType, i);
    }
  }

  calculateUf(value, form_type, i): any {
    const form = this.PatientPreDialysisForm.get(form_type)['controls'][i];
    value = (value * 1000).toFixed(2);
    form.get('uf').patchValue(value);
    this.calculateUfRate(this.hoursValue, form_type, i);
  }

  calculateUfRate(value, form_type, i): any {
    const form = this.PatientPreDialysisForm.get(form_type)['controls'][i];
    const uf = form.value.uf;
    if (value > 0) {
      value = (Number(uf) / Number(value)).toFixed(2);
      form.get('uf_rate').patchValue(value);
    } else {
      form.get('uf_rate').patchValue(0);
    }
    if (isNaN(form.get('uf_rate').value)) {
      form.get('uf_rate').patchValue(0);
    }
  }

  updateDuration(): any {
    const startTime = moment(
      this.PatientPreDialysisForm.get('start_time').value,
      'HH:mm',
    );
    const endTime = moment(
      this.PatientPreDialysisForm.get('end_time').value,
      'HH:mm',
    );
    this.PatientPreDialysisForm.get('duration').patchValue(
      this.durationCal(startTime, endTime),
    );

    setTimeout(() => {
      const duration = moment.duration(endTime.diff(startTime));
      const hours = Math.floor(duration.asHours());
      const minutes = duration.asMinutes() - hours * 60;
      // console.log('cal', ( (hours * 60) + minutes ) / 60);
      const value = (hours * 60 + minutes) / 60;
      this.hoursValue = (hours * 60 + minutes) / 60;
      this.calculateUfRate(
        this.hoursValue,
        'patient_dialysis_vitals_attributes',
        0,
      );
    }, 100);
  }

  updatePostDuration(): any {
    const startTime = moment(
      this.PatientPreDialysisForm.get('start_time').value,
      'HH:mm',
    );
    const endTime = moment(
      this.PatientPostDialysisForm.get('end_time').value,
      'HH:mm',
    );
    this.PatientPostDialysisForm.get('duration').patchValue(
      this.durationCal(startTime, endTime),
    );
  }

  durationCal(startTime, endTime): any {
    const duration = moment.duration(endTime.diff(startTime));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.asMinutes() - hours * 60;
    if (hours > 0) {
      this.time = hours + ' hour and ' + minutes + ' minutes.';
    } else {
      this.time = minutes + ' minutes.';
    }
    return this.time;
  }

  specialInstructions(event): any {
    this.PatientPreDialysisForm.get('instruction').patchValue(
      event.target.value,
    );
  }

  changeTab(tab, saveCheck): any {
    console.log('tab', tab);
    if (tab === 1) {
      const control = this.PatientDuringDialysisForm.get(
        'patient_dialysis_vitals_attributes',
      ) as UntypedFormArray;
      while (control.length) {
        control.removeAt(0);
      }
      console.log('dialysis', this.patient_dialysis);
      const duringVitals = this.patient_dialysis.patient_dialysis_vitals.filter(
        (v) => v.vital_type === 'during',
      );
      if (duringVitals.length > 0) {
        for (const pdv of duringVitals) {
          this.addMoreVital(pdv);
        }
      }
      if (saveCheck === false) {
        this.isPreDialysisVital = false;
        this.isDuringDialysisVital = true;
        this.isPostDialysisVital = false;
      }
    } else if (tab === 2) {
      if (saveCheck === false) {
        this.isPreDialysisVital = false;
        this.isDuringDialysisVital = false;
        this.isPostDialysisVital = true;
      } else if (saveCheck === true) {
        const control = this.PatientDuringDialysisForm.get(
          'patient_dialysis_vitals_attributes',
        ) as UntypedFormArray;
        while (control.length) {
          control.removeAt(0);
        }
        const duringVitals =
          this.patient_dialysis.patient_dialysis_vitals.filter(
            (v) => v.vital_type === 'during',
          );
        if (duringVitals.length > 0) {
          for (const pdv of duringVitals) {
            this.addMoreVital(pdv);
          }
        }
      }
    } else {
      if (this.dialysisRequest) {
        if (this.dialysisRequest.dialysis_patient) {
          this.isDisplayImage = true;
          this.isArrayList = true;
          this.isUploadImage = true;
          this.idForStoringImage = this.dialysisRequest.dialysis_patient.id;
          this.getImagesDialysis();
          // this.PatientPreDialysisForm.get('advised_by_id').patchValue(this.dialysisRequest.dialysis_patient.advised_by_id);
          if (
            this.patient_dialysis &&
            this.patient_dialysis.patient_dialysis_vitals
          ) {
            const preVitals =
              this.patient_dialysis.patient_dialysis_vitals.filter(
                (v) => v.vital_type === 'pre',
              );
            this.preDialysisVital = preVitals[preVitals.length - 1];
            const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
            if (!regex.test(this.preDialysisVital.taken_time)) {
              this.preDialysisVital.taken_time = moment
                .utc(this.preDialysisVital.taken_time)
                .format('HH:mm');
            } else {
              this.preDialysisVital.taken_time =
                this.preDialysisVital.taken_time;
            }
          }
          // this.patient_dialysis.start_time =  this.PatientPreDialysisForm.get('start_time').value;
          // this.patient_dialysis.end_time =  this.PatientPreDialysisForm.get('end_time').value;
          this.PatientPreDialysisForm.get('start_time').patchValue(
            this.dialysisRequest.dialysis_patient.start_time,
          );
          this.PatientPostDialysisForm.get('end_time').patchValue(
            this.dialysisRequest.dialysis_patient.end_time,
          );
        } else {
          this.currentTime = new Date().toString().split(' ')[4].slice(0, -3);
        }
      } else {
        this.currentTime = new Date().toString().split(' ')[4].slice(0, -3);
      }
      this.isPreDialysisVital = true;
      this.isDuringDialysisVital = false;
      this.isPostDialysisVital = false;
    }

    // console.log('a', this.PatientPreDialysisForm.get('advised_by_id').value());
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
      // $('#advisedBy').selectpicker('refresh');
    }, 200);
  }

  createPatientDialysis(): any {
    if (!this.patient) {
      toastr.error('Please Select Patient');
      return;
    }
    const vitalsArray = this.PatientPreDialysisForm.get([
      'patient_dialysis_vitals_attributes',
      0,
    ]).get('vital_attributes').value;
    if (this.vitalValidation.actionOnVitals(vitalsArray) === false) {
      return;
    }
    this.submitted = true;
    if (this.PatientPreDialysisForm.invalid) {
      return;
    }

    this.PatientPreDialysisForm.get('last_dialysis_weight').patchValue(
      this.lastWeightValue,
    );
    this.patient_dialysis = this.PatientPreDialysisForm.value;
    if (
      this.dialysisRequest &&
      this.PatientPreDialysisForm.get([
        'patient_dialysis_vitals_attributes',
        0,
      ]).get('vital_attributes').value.weight
    ) {
      if (
        this.lastWeightValue >
        this.PatientPreDialysisForm.get([
          'patient_dialysis_vitals_attributes',
          0,
        ]).get('vital_attributes').value.weight
      ) {
        toastr.error('Current weight should be equal or greater');
        return;
      }
    }

    // this.PatientPreDialysisForm.patchValue({last_dialysis_weight: this.lastWeightValue});
    this.ngxLoader.start();
    this.dialysisService
      .createPatientDislysisV2(this.patient_dialysis, this.patient.id)
      .subscribe(
        (data) => {
          this.patient_dialysis = data.dialysis_patient;
          this.idForStoringImage = data.dialysis_patient.id;
          if (this.patient_dialysis) {
            const takenStart = moment
              .utc(this.patient_dialysis.start_time)
              .format('HH:mm');
            const takenEnd = moment
              .utc(this.patient_dialysis.end_time)
              .format('HH:mm');
            this.PatientPreDialysisForm.get('start_time').patchValue(
              takenStart,
            );
            this.PatientPreDialysisForm.get('end_time').patchValue(takenEnd);
            this.patient_dialysis.start_time =
              this.PatientPreDialysisForm.get('start_time').value;
            this.patient_dialysis.end_time =
              this.PatientPreDialysisForm.get('end_time').value;
          }
          this.sendImageDialysis();
          this.dialysisRequests[this.patentIndex].dialysis_patient =
            data.dialysis_patient;
          this.PatientPostDialysisForm.get('id').patchValue(
            this.patient_dialysis.id,
          );
          if (this.patient_dialysis.patient_dialysis_vitals) {
            const preVitals =
              this.patient_dialysis.patient_dialysis_vitals.filter(
                (v) => v.vital_type === 'pre',
              );
            this.preDialysisVital = preVitals[0];
          }
          this.ngxLoader.stop();
          toastr.success('Added successfully');
          this.submitted = false;
          this.isPreDialysisVital = false;
          this.isDuringDialysisVital = true;
          this.isPostDialysisVital = false;
          const control = this.PatientDuringDialysisForm.get(
            'patient_dialysis_vitals_attributes',
          ) as UntypedFormArray;
          while (control.length) {
            control.removeAt(0);
          }
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  patientPreDialysis(): any {
    const vitalsArray = this.PatientPreDialysisForm.get([
      'patient_dialysis_vitals_attributes',
      0,
    ]).get('vital_attributes').value;
    if (this.vitalValidation.actionOnVitals(vitalsArray) === false) {
      return;
    }
    this.submitted = true;
    if (this.PatientPreDialysisForm.invalid || !this.patient_dialysis.id) {
      return;
    }

    const id = this.patient_dialysis.id;
    this.PatientPreDialysisForm.patchValue({
      last_dialysis_weight: this.lastWeightValue,
    });
    this.patient_dialysis = this.PatientPreDialysisForm.value;
    this.patient_dialysis.id = id;
    this.updatePatientDislysis(1, false);
  }
  patientDuringDialysis(saveCheck): any {
    this.submittedDuring = true;
    this.PatientDuringDialysisForm.get('id').patchValue(
      this.patient_dialysis.id,
    );
    this.patient_dialysis = this.PatientDuringDialysisForm.value;
    if (this.PatientDuringDialysisForm.invalid || !this.patient_dialysis.id) {
      return;
    }
    console.log('dialysis', this.PatientDuringDialysisForm);
    const array =
      this.patient_dialysis.patient_dialysis_vitals_attributes.length;
    console.log('length', array);
    for (
      let i = 0;
      i < this.patient_dialysis.patient_dialysis_vitals_attributes.length;
      i++
    ) {
      const vitalsArray = this.PatientDuringDialysisForm.get([
        'patient_dialysis_vitals_attributes',
        i,
      ])?.get('vital_attributes').value;
      if (this.vitalValidation.actionOnVitals(vitalsArray) === false) {
        return;
      }
    }

    this.updatePostDuration();
    this.updatePatientDislysis(2, saveCheck);
  }
  patientPostDialysis(): any {
    this.disableSubmitButton = true;
    this.submittedPD = true;
    for (
      let i = 0;
      i <
      this.PatientPostDialysisForm.value.patient_dialysis_vitals_attributes
        .length;
      i++
    ) {
      const vitalsArray = this.PatientPostDialysisForm.get([
        'patient_dialysis_vitals_attributes',
        i,
      ]).get('vital_attributes').value;
      if (this.vitalValidation.actionOnVitals(vitalsArray) === false) {
        this.disableSubmitButton = false;
        this.submittedPD = false;
        return;
      }
    }
    if (this.PatientPostDialysisForm.invalid || !this.patient_dialysis.id) {
      this.disableSubmitButton = false;
      return;
    }
    this.patient_dialysis = this.PatientPostDialysisForm.value;
    const id = this.patient_dialysis.id;
    this.patient_dialysis.id = id;
    // this.resetPatientPreDialysisForm();
    this.isUploadImage = false;
    this.isDisplayImage = false;
    this.updatePatientDislysis(0, false);
  }
  updatePatientDislysis(tab, saveCheck): any {
    this.ngxLoader.start();
    if (
      tab === 2 &&
      this.patient_dialysis.patient_dialysis_vitals_attributes.length === 0
    ) {
      const obj = { vital_type: 'during' };
      this.patient_dialysis.patient_dialysis_vitals_attributes.push(
        obj as PatientDialysisVital,
      );
    }
    this.dialysisService
      .updatePatientDislysisV2(this.patient_dialysis, this.patient.id)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.dialysisRequests[this.patentIndex].dialysis_patient =
            data.dialysis_patient;
          this.patient_dialysis = data.dialysis_patient;
          this.idForStoringImage = data.dialysis_patient.id;
          if (
            this.patient_dialysis.start_time ||
            this.patient_dialysis.end_time
          ) {
            const takenStart = moment
              .utc(this.patient_dialysis.start_time)
              .format('HH:mm');
            const takenEnd = moment
              .utc(this.patient_dialysis.end_time)
              .format('HH:mm');
            this.PatientPreDialysisForm.get('start_time').patchValue(
              takenStart,
            );
            this.PatientPreDialysisForm.get('end_time').patchValue(takenEnd);
            this.patient_dialysis.start_time =
              this.PatientPreDialysisForm.get('start_time').value;
            this.patient_dialysis.end_time =
              this.PatientPreDialysisForm.get('end_time').value;
          }
          this.sendImageDialysis();
          toastr.success('updated successfully');
          if (tab === 1) {
            const control = this.PatientDuringDialysisForm.get(
              'patient_dialysis_vitals_attributes',
            ) as UntypedFormArray;
            // for (let i = 0; i < this.patient_dialysis.patient_dialysis_vitals.length; i++){
            //   if (this.patient_dialysis.patient_dialysis_vitals[i].vital_type === 'during'){
            //     console.log('this.patient_dialysis.patient_dialysis_vitals[i]', this.patient_dialysis.patient_dialysis_vitals[i]);
            //     control.push(this.createVital('during', '', this.patient_dialysis.patient_dialysis_vitals[i]));
            //   }
            // }
            // control.push(this.createVital('during', '', ''));
          }
          if (tab === 2) {
            const control = this.PatientPostDialysisForm.get(
              'patient_dialysis_vitals_attributes',
            ) as UntypedFormArray;
            while (control.length) {
              control.removeAt(0);
            }
            control.push(this.createVital('post', '', ''));
            setTimeout(() => {
              this.disableSubmitButton = false;
            }, 500);
          }
          if (data.dialysis_patient && tab === 0) {
            this.printPatientDialysis();
            this.fetchDialysisRequests();
            this.resetPatientPreDialysisForm();
            this.resetPatientDuringDialysisForm();
            this.resetPatientPostDialysisForm();
          }
          this.submitted = false;
          this.submittedDuring = false;
          this.submittedPD = false;
          this.changeTab(tab, saveCheck);
        },
        (err) => {
          this.disableSubmitButton = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  setTimes(): any {
    this.PatientPreDialysisForm.get('start_time').patchValue(
      new Date().toString().split(' ')[4].slice(0, -3),
    );
    this.PatientPreDialysisForm.get('end_time').patchValue(
      new Date().toString().split(' ')[4].slice(0, -3),
    );
  }
  resetPatientPreDialysisForm(): any {
    this.PatientPreDialysisForm.reset();
    this.dialysisRequest = undefined;
    this.patient_dialysis = undefined;
    this.preDialysisVital = undefined;
    // this.PatientPreDialysisForm.get("patient_dialysis_vitals_attributes").reset();
    this.invoiceNumber = '';
    this.lastWeightValue = '';
    this.patient = null;
    this.PatientPreDialysisForm.get('start_time').patchValue(
      new Date().toString().split(' ')[4].slice(0, -3),
    );
    this.PatientPreDialysisForm.get('end_time').patchValue(
      new Date().toString().split(' ')[4].slice(0, -3),
    );
    this.primingDefaultVal =
      this.primingSolDefaultVal[0].defaultable.product_name;
    const control = this.PatientPreDialysisForm.get(
      'patient_dialysis_vitals_attributes',
    ) as UntypedFormArray;
    while (control.length) {
      control.removeAt(0);
    }
    setTimeout(() => {
      control.push(this.createVital('pre', '', ''));
    }, 1000);
  }
  resetPatientDuringDialysisForm(): any {
    this.PatientDuringDialysisForm.get(
      'patient_dialysis_vitals_attributes',
    ).reset();
    const control = this.PatientDuringDialysisForm.get(
      'patient_dialysis_vitals_attributes',
    ) as UntypedFormArray;
    while (control.length) {
      control.removeAt(0);
    }
    // setTimeout( () => {
    //   control.push(this.createVital('during', '', ''));
    // }, 1000);
  }
  resetPatientPostDialysisForm(): any {
    this.PatientPostDialysisForm.reset({
      staff_id: '',
      status: 'completed',
    });
    const control = this.PatientPostDialysisForm.get(
      'patient_dialysis_vitals_attributes',
    ) as UntypedFormArray;
    while (control.length) {
      control.removeAt(0);
    }
    // setTimeout( () => {
    //   control.push(this.createVital('post', '', ''));
    // }, 2000);
    // this.PatientPostDialysisForm.get("patient_dialysis_vitals_attributes").reset();
  }

  printPatientDialysis(): any {
    // window.open(
    //   this.current_environment +
    //     '/v4/dialysis_patients/report.pdf?auth_token=' +
    //     localStorage.getItem('auth_token') +
    //     '&medical_unit_id=' +
    //     localStorage.getItem('current_medical_unit_id') +
    //     '&id=' +
    //     this.patient_dialysis.id,
    // );
    window.open(
      'dialysis/dialysis-report?patient_id=' +
        this.patient.id +
        '&mrn=' +
        this.patient.mrn +
        '&dialysis_id=' +
        this.patient_dialysis.id +
        '&invoice_id=' +
        this.invoiceNumber || '',
    );
  }

  openModal(dialysisRequest, ind): any {
    this.currentPatientData = dialysisRequest;
    console.log('this.currentPatientData', this.currentPatientData);
    this.currentPatientIndex = ind;
    this.modalService.open(this.patientChangeModal);
  }
  closeModal(): any {
    this.modalService.dismissAll(this.patientChangeModal);
  }
  get f(): any {
    return this.PatientPreDialysisForm.controls;
  }
  get p(): any {
    return this.PatientPreDialysisForm.get(
      'patient_dialysis_vitals_attributes',
    )['controls'];
  }
  get q(): any {
    return this.PatientDuringDialysisForm.controls;
  }
  get w(): any {
    return this.PatientDuringDialysisForm.get(
      'patient_dialysis_vitals_attributes',
    )['controls'];
  }
  get s(): any {
    return this.PatientPostDialysisForm.controls;
  }
  get r(): any {
    return this.PatientPostDialysisForm.get(
      'patient_dialysis_vitals_attributes',
    )['controls'];
  }
  sendImageDialysis(): any {
    this.isUploadImage = true;
    this.isDisplayImage = false;
    this.shareDataService.isArrayData = true;
    this.dataOfImages = this.shareDataService.imageFiles;
    console.log('Recieved data', this.dataOfImages);
    this.ngxLoader.start();
    this.dialysisService
      .addImageInPo(this.dataOfImages, this.idForStoringImage)
      .subscribe(
        (data) => {
          this.shareDataService.isArrayData = true;
          this.shareDataService.imageFiles = [];
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
  getImagesDialysis(): any {
    this.isUploadImage = false;
    this.isDisplayImage = true;
    console.log('get Data', this.dataOfImages);
    this.ngxLoader.start();
    this.dialysisService.getImagesFiles(this.idForStoringImage).subscribe(
      (data) => {
        this.receivedImageFiles = data.files;
        this.isUploadImage = true;
        console.log('length', this.receivedImageFiles.length);
        if (!this.receivedImageFiles.length) {
          this.isDisplayImage = false;
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
}
