import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FirebaseMessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private afMessaging: AngularFireMessaging,
    private http: HttpClient
  ) {}

  // Request permission for notifications
  requestPermission() {
    this.afMessaging.requestToken.subscribe({
      next: (token) => {
        const storedToken = localStorage.getItem('firebase_token');
        if (!storedToken && token !== storedToken) {
          this.registerFirebaseToken(token).subscribe({
            next: (data) => {
              console.log('Token registered successfully');
              localStorage.setItem('firebase_token', token);
            },
            error: (err) => {
              console.error('Error registering token:', err);
            },
          });
        }
      },
      error: (error) => {
        if (error.code === 'messaging/permission-blocked') {
          console.error(
            'Notifications are blocked. Please enable them in the browser settings.'
          );
        } else {
          console.error('Permission denied or error:', error);
        }
      },
    });
  }

  // Listen for messages when the app is in the foreground
  receiveMessage() {
    this.afMessaging.messages.subscribe((payload: any) => {
      console.log('Message received:', payload);
      // Show notification manually
      if (Notification.permission === 'granted') {
        new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: payload.notification.icon, // Optional: Add an icon if available
        });
      }

      this.currentMessage.next(payload);
    });
  }


  // Register the Service Worker
  registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log(
            'Service Worker registered with scope:',
            registration.scope
          );
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    } else {
      console.error('Service Worker is not supported in this browser.');
    }
  }

  // API call to register the Firebase token
  registerFirebaseToken(token: string): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/api/register_firebase_token/?auth_token=` +
        localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          token,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        }
      )
      .pipe(map((data) => data));
  }
}
