import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CultureConfigService } from '../../../services/culture-config.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-pcr-configuration',
  templateUrl: './pcr-configuration.component.html',
  styleUrls: ['./pcr-configuration.component.css'],
})
export class PcrConfigurationComponent implements OnInit {
  pcrForm: UntypedFormGroup;
  page = 1;
  perPage = 25;
  totalPages: any;
  perPageValue: any;
  pcrData: any;
  pcrList = [];
  isSubmit = false;
  disableBtn = false;
  pcrId: any;
  cultureList = [];
  constructor(
    private fb: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private cultureConfigService: CultureConfigService,
  ) {
    this.pcrForm = this.fb.group({
      id: [''],
      type: [1, Validators.required],
      name: ['', Validators.required],
      short_name: ['', Validators.required],
      code: ['', Validators.required],
      range_from: [''],
      range_to: [''],
      low_from: [''],
      low_to: [''],
      medium_from: [''],
      medium_to: [''],
      high_from: [''],
      high_to: [''],
      comments: [''],
      wound_range_from: [''],
      wound_range_to: [''],
      wound_comments: [''],
      anti_biotic: [''],
      status: true,
    });
  }
  clearPcrForm(): any {
    this.pcrForm.reset({
      id: '',
      // type: '',
      name: '',
      short_name: '',
      code: '',
      range_from: '',
      range_to: '',
      low_from: '',
      low_to: '',
      medium_from: '',
      medium_to: '',
      high_from: '',
      high_to: '',
      anti_biotic: '',
      comments: '',
      wound_range_from: '',
      wound_range_to: '',
      wound_comments: '',
      status: true,
    });
    setTimeout(() => {
      $('#anti_biotic').selectpicker('refresh');
      $('#anti_biotic').selectpicker();
    }, 500);
    this.isSubmit = false;
  }
  submitPcr(): any {
    this.disableBtn = true;
    this.isSubmit = true;
    if (this.pcrForm.invalid) {
      this.disableBtn = false;
      return;
    }
    if (this.pcrForm.get('anti_biotic').value === '') {
      this.pcrForm.get('anti_biotic').setValue([]);
    }
    if (!this.pcrId) {
      this.ngxLoader.start();
      this.cultureConfigService.addPcrConfig(this.pcrForm.value).subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.disableBtn = false;
          toastr.success('Added Successfully');
          $('#pcrModal').modal('hide');
          this.getPcrConfigList();
        },
        (err) => {
          this.disableBtn = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
    } else {
      this.ngxLoader.start();
      this.cultureConfigService
        .updatePcrConfig(this.pcrForm.value, this.pcrData?.id)
        .subscribe(
          (data) => {
            this.ngxLoader.stop();
            this.disableBtn = false;
            toastr.success('Updated Successfully');
            $('#pcrModal').modal('hide');
            this.getPcrConfigList();
          },
          (err) => {
            this.disableBtn = false;
            this.ngxLoader.stop();
            toastr.error(err.error.message);
          },
        );
    }
  }
  getPcrConfigList(): any {
    this.pcrList = [];
    this.cultureConfigService
      .getPcrConfigList(this.page, this.perPage)
      .subscribe(
        (data) => {
          this.pcrList = data.items;
          this.totalPages = data.total_count;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  selectPaginationLimit(event): any {
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    this.getPcrConfigList();
  }
  loadPage(event): any {
    this.getPcrConfigList();
  }
  openPcrModal(id): any {
    this.pcrId = id;
    if (!this.pcrId) {
      this.clearPcrForm();
      this.pcrForm.get('type').setValue(1);
      this.pcrForm.get('anti_biotic').clearValidators();
      this.pcrForm.get('anti_biotic').updateValueAndValidity();
    } else {
      this.cultureConfigService.getSinglePcrList(id).subscribe(
        (data) => {
          this.pcrData = data;
          if (this.pcrData) {
            this.pcrForm.patchValue({
              id: this.pcrData.id,
              type: this.pcrData.type,
              name: this.pcrData.name,
              short_name: this.pcrData.short_name,
              code: this.pcrData.code,
              range_from: this.pcrData.range_from,
              range_to: this.pcrData.range_to,
              low_from: this.pcrData.low_from,
              low_to: this.pcrData.low_to,
              medium_from: this.pcrData.medium_from,
              medium_to: this.pcrData.medium_to,
              high_from: this.pcrData.high_from,
              high_to: this.pcrData.high_to,
              comments: this.pcrData.comments,
              wound_range_from: this.pcrData.wound_range_from,
              wound_range_to: this.pcrData.wound_range_to,
              wound_comments: this.pcrData.wound_comments,
              status: this.pcrData.status,
              anti_biotic: this.pcrData.anti_biotic,
            });
            setTimeout(() => {
              $('#anti_biotic').selectpicker('refresh');
              $('#anti_biotic').selectpicker();
            }, 500);
            if (this.pcrData?.type === 1) {
              this.pcrForm.get('anti_biotic').clearValidators();
              this.pcrForm.get('anti_biotic').updateValueAndValidity();
            } else {
              this.pcrForm
                .get('anti_biotic')
                .setValidators(Validators.required);
              this.pcrForm.get('anti_biotic').updateValueAndValidity();
            }
          }
        },
        (error) => {
          toastr.err(error.error.message);
        },
      );
    }
    $('#pcrModal').modal('show');
  }
  notAllowSpace(e): any {
    if (e.which == 32) {
      return false;
    }
  }
  refreshAntiBiotic(type): any {
    this.clearPcrForm();
    if (type === '2: 2') {
      this.pcrForm.get('type').setValue(2);
      this.pcrForm.get('anti_biotic').setValidators(Validators.required);
      this.pcrForm.get('anti_biotic').updateValueAndValidity();
      setTimeout(() => {
        $('#anti_biotic').selectpicker('refresh');
        $('#anti_biotic').selectpicker();
      }, 500);
    } else {
      this.pcrForm.get('type').setValue(1);
      this.pcrForm.get('anti_biotic').clearValidators();
      this.pcrForm.get('anti_biotic').updateValueAndValidity();
      this.pcrForm.get('anti_biotic').setValue([]);
      setTimeout(() => {
        $('#anti_biotic').selectpicker('refresh');
        $('#anti_biotic').selectpicker();
      }, 500);
    }
  }
  getCultureList(): any {
    this.ngxLoader.start();
    this.cultureConfigService.indexV2().subscribe(
      (data) => {
        this.cultureList = data.culture_configurations;
        setTimeout(() => {
          $('#anti_biotic').selectpicker('refresh');
          $('#anti_biotic').selectpicker();
        }, 500);
        console.log(this.cultureList);
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }

  ngOnInit(): void {
    this.getCultureList();
    this.getPcrConfigList();
  }
}
