import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../../../services/patient.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientServiceInvoicesService } from '../../../services/patient-service-invoices.service';
import { EmployeeRegistrationService } from '../../../services/employee-registration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-patient-discount-v3',
  templateUrl: './patient-discount-v3.component.html',
  styleUrls: ['./patient-discount-v3.component.css'],
})
export class PatientDiscountV3Component implements OnInit {
  @ViewChild('updateConfirm') updateConfirm: ElementRef;
  @ViewChild('cancelConfirm') cancelConfirm: ElementRef;
  @ViewChild('cancelAdvanceConfirm') cancelAdvanceConfirm: ElementRef;
  @ViewChild('invoiceItemDetailsModal') invoiceItemDetailsModal: ElementRef;
  PatientSearchForm: UntypedFormGroup;
  PatientServiceInvoiceForm: UntypedFormGroup;
  submitted = false;
  family_mrn: any;
  patient: any;
  patient_admissions = [];
  current_environment: string;
  patientServiceHistory = [];
  patientDiscount = [];
  copyPatientDiscount = [];
  isChecked = false;

  patientServiceInvoices = [];
  discount_comments = '';
  discount_reason_id = '';
  discountReasonList = [];
  cancelReasonList = [];
  invoiceItemDetails: any;
  discountTypeInvoiceAll = 'percentage';
  discountTypeAmountInvoiceAll = 0;
  discountTypeValueInvoiceAll = 0;
  totalDiscountedServiceAmount = 0;
  totalDiscountedAmount = 0;

  isOpen = true;
  filterType = 'updateDiscount';
  cancelServiceType = 'invoice';
  selectedCancelInvoice = [];
  isInvoice = false;
  cancelServices = {
    invoice_ids: [],
    service_ids: [],
    cash: 0,
    receivable: 0,
    patient_id: '',
    service_cancel_reason_id: '',
    service_cancel_comments: '',
  };
  totalCancelAmount = 0;
  totalCancelCash = 0;
  totalCancelReceivable = 0;
  totalCancelSelectedTotal = 0;

  patinetAdvancesList = [];
  cancelAdvances = {
    advance_ids: [],
    cash: 0,
    patient_id: '',
    advance_cancel_reason_id: '',
    advance_cancel_comments: '',
  };
  cancelAdvancesReasonList = [];
  page = 1;
  totalPages: any;
  perPageValue: any;
  perPage = 25;
  screenName = '';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private patientServiceInvoicesService: PatientServiceInvoicesService,
    private chRef: ChangeDetectorRef,
    private employeeService: EmployeeRegistrationService,
    private modalService: NgbModal,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  ngOnInit(): any {
    this.perPageValue = 25;
    // const today = new Date() ;
    // const yesterday = new Date();
    // yesterday.setDate(yesterday.getDate() - 1);
    // const formttedYesterday = date.format(yesterday, 'YYYY-MM-DDThh:mm');
    // const formattedToday = date.format(today, 'YYYY-MM-DDThh:mm');

    this.current_environment = environment.rorUrl;
    this.PatientSearchForm = this.formBuilder.group({
      search_query: [''],
      type: ['default'],
    });
    this.PatientServiceInvoiceForm = this.formBuilder.group({
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      patient_id: ['', Validators.required],
      department_id: [''],
      parent_department_id: [''],
      sub_department: [''],
    });
    if (this.route.snapshot.queryParams.patient_id) {
      this.PatientSearchForm.patchValue({
        search_query: this.route.snapshot.queryParams.patient_id,
      });
    }
    this.searchPatient();
    this.getDiscountReason();
    this.getCanceladvanceReasonList();
    this.getCancelReasonList();
  }
  getDiscountReason(): any {
    this.ngxLoader.start();
    this.employeeService.fetchConfigurationV2('discount_reason').subscribe(
      (data) => {
        this.discountReasonList = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getCanceladvanceReasonList(): any {
    this.ngxLoader.start();
    this.employeeService
      .fetchConfigurationV2('advance_cancel_reason')
      .subscribe(
        (data) => {
          this.cancelAdvancesReasonList = data.configurator_types;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  getCancelReasonList(): any {
    this.ngxLoader.start();
    this.employeeService
      .fetchConfigurationV2('service_cancel_reason')
      .subscribe(
        (data) => {
          this.cancelReasonList = data.configurator_types;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  searchPatient(): any {
    this.fetchPatients();
  }
  fetchPatients(): any {
    this.ngxLoader.start();
    const referal = false;
    this.patientService
      .searchPatientByTypeDiscountV2(this.PatientSearchForm.value)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.patient_admissions = data.patients;
          if (!this.patient) {
            if (this.patient_admissions.length > 0) {
              this.selectPatient(this.patient_admissions[0]);
            }
          }
        },
        (err) => {
          this.patient_admissions = [];
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  selectPatient(data): any {
    this.page = 1;
    this.isOpen = true;
    this.patient = data;
    this.closePatientList();
    this.searchInvoices();
    this.patientAdvances();
    this.resetSelectBoxesCancel();
  }
  updateInvoiceTypePatient(): any {
    this.page = 1;
    this.closePatientList();
    this.searchInvoices();
    this.patientAdvances();
    this.resetSelectBoxesCancel();
  }
  patientAdvances(): any {
    this.ngxLoader.start();
    this.patientServiceInvoicesService
      .patientAdvancesFinV2(this.patient, '', '', '', '')
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.patinetAdvancesList = data.advances;
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  searchInvoices(): any {
    this.patientServiceInvoices = [];
    this.copyPatientDiscount = [];
    this.patientDiscount = [];
    this.discount_reason_id = '';
    this.discount_comments = '';
    this.discountTypeInvoiceAll = 'percentage';
    this.discountTypeAmountInvoiceAll = 0;
    this.discountTypeValueInvoiceAll = 0;
    this.totalDiscountedServiceAmount = 0;
    this.totalDiscountedAmount = 0;
    this.ngxLoader.start();
    this.PatientServiceInvoiceForm.controls.patient_id.setValue(
      this.patient.mrn,
    );
    this.patientServiceInvoicesService
      .getPatientServicesInvoiceForDiscountV2(
        this.PatientServiceInvoiceForm.value,
        this.patient,
        '',
        this.page,
        this.perPage,
        '',
        '',
        '',
        '',
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.patientServiceInvoices = data.patient_service_invoices;
          for (let i = 0; i < this.patientServiceInvoices.length; i++) {
            const patient_service_invoices = {
              id: this.patientServiceInvoices[i].id,
              patient_service_invoice_items: [],
              manual_discount:
                this.patientServiceInvoices[i].manual_discount || 0,
              discount_on_receivable:
                this.patientServiceInvoices[i].discount_on_receivable || 0,
              discount_on_cash:
                this.patientServiceInvoices[i].discount_on_cash || 0,
              type_amount: this.patientServiceInvoices[i].type_amount || 0,
              quantity: 0,
              selected: false,
            };
            for (
              let j = 0;
              j <
              this.patientServiceInvoices[i].patient_service_invoice_items
                .length;
              j++
            ) {
              let manualDiscount =
                this.patientServiceInvoices[i].patient_service_invoice_items[j]
                  .manual_discount;
              if (
                this.patientServiceInvoices[i].patient_service_invoice_items[j]
                  .discount_approved_status === 'approved'
              ) {
                manualDiscount = 0;
              }
              const obj = {
                id: this.patientServiceInvoices[i]
                  .patient_service_invoice_items[j].id,
                unit_type:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].unit_type || 'percentage',
                type_value:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].type_value || 0,
                type_amount:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].type_amount || 0,
                manual_discount: manualDiscount,
                discount_on_receivable:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].discount_on_receivable,
                discount_on_cash:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].discount_on_cash,
                quantity:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].quantity,
                is_tax_adjustable:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].is_tax_adjustable,
                is_taxable_service:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].is_taxable_service,
                // invoice_amount: ( this.patientServiceInvoices[i].patient_service_invoice_items[j].base_price - this.patientServiceInvoices[i].patient_service_invoice_items[j].discount ) * this.patientServiceInvoices[i].patient_service_invoice_items[j].quantity,
                invoice_amount:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].base_price *
                    this.patientServiceInvoices[i]
                      .patient_service_invoice_items[j].quantity -
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].approved_discount,
                selected: false,
              };
              patient_service_invoices.patient_service_invoice_items.push(obj);
            }
            this.patientDiscount.push(patient_service_invoices);
            // this.copyPatientDiscount.push(patient_service_invoices);
          }
          this.copyPatientDiscount = JSON.parse(
            JSON.stringify(this.patientDiscount),
          );
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  selectPaginationLimit(event): any {
    this.perPage = event;
    this.page = 1;
    if (event === '') {
      this.perPage = this.totalPages;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    this.searchInvoices();
  }
  loadPage(event): any {
    this.searchInvoices();
  }

  // _onlyNumeric(event: any): any {
  //   if (event.charCode != 46){
  //     const pattern = /^\d{1,6}(\.\d{1,2})?$/;
  //     const inputChar = String.fromCharCode(event.charCode);
  //     if (!pattern.test(inputChar)) {
  //       // invalid character, prevent input
  //       event.preventDefault();
  //     }
  //   }
  //
  // }
  onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  updateDiscountValue(i, j): any {
    const invoice = this.patientDiscount[i];
    if (invoice.patient_service_invoice_items[j].unit_type === 'amount') {
      if (
        invoice.patient_service_invoice_items[j].type_value >=
        invoice.patient_service_invoice_items[j].invoice_amount
      ) {
        invoice.patient_service_invoice_items[j].type_value =
          invoice.patient_service_invoice_items[j].invoice_amount;
      }
      invoice.patient_service_invoice_items[j].type_amount =
        invoice.patient_service_invoice_items[j].invoice_amount -
          invoice.patient_service_invoice_items[j].type_value || 0;
      invoice.patient_service_invoice_items[j].manual_discount =
        invoice.patient_service_invoice_items[j].type_value || 0;
      invoice.patient_service_invoice_items[j].manual_discount = Number(
        invoice.patient_service_invoice_items[j].manual_discount.toFixed(1),
      );

      // let percentage = (invoice.patient_service_invoice_items[j].type_value / invoice.patient_service_invoice_items[j].invoice_amount) * 100;
      let percentage =
        (invoice.patient_service_invoice_items[j].manual_discount /
          (this.patientServiceInvoices[i].cash +
            this.patientServiceInvoices[i].receivable)) *
        100;
      invoice.patient_service_invoice_items[j].discount_on_cash =
        (Number(this.patientServiceInvoices[i].cash) / 100) * percentage;
      invoice.patient_service_invoice_items[j].discount_on_cash = Number(
        invoice.patient_service_invoice_items[j].discount_on_cash.toFixed(1),
      );
      invoice.patient_service_invoice_items[j].discount_on_receivable =
        (Number(this.patientServiceInvoices[i].receivable) / 100) * percentage;
      invoice.patient_service_invoice_items[j].discount_on_receivable = Number(
        invoice.patient_service_invoice_items[j].discount_on_receivable.toFixed(
          1,
        ),
      );
    }
    if (invoice.patient_service_invoice_items[j].unit_type === 'percentage') {
      if (invoice.patient_service_invoice_items[j].type_value >= 100) {
        invoice.patient_service_invoice_items[j].type_value = 100;
      }
      const percentageAmount =
        (invoice.patient_service_invoice_items[j].invoice_amount / 100) *
        invoice.patient_service_invoice_items[j].type_value;
      invoice.patient_service_invoice_items[j].type_amount =
        invoice.patient_service_invoice_items[j].invoice_amount -
          percentageAmount || 0;
      invoice.patient_service_invoice_items[j].manual_discount =
        invoice.patient_service_invoice_items[j].invoice_amount -
          invoice.patient_service_invoice_items[j].type_amount || 0;
      invoice.patient_service_invoice_items[j].manual_discount = Number(
        invoice.patient_service_invoice_items[j].manual_discount.toFixed(1),
      );

      // let percentage = Number(invoice.patient_service_invoice_items[j].type_value);
      let percentage =
        (invoice.patient_service_invoice_items[j].manual_discount /
          (this.patientServiceInvoices[i].cash +
            this.patientServiceInvoices[i].receivable)) *
        100;
      invoice.patient_service_invoice_items[j].discount_on_cash =
        (Number(this.patientServiceInvoices[i].cash) / 100) * percentage;
      invoice.patient_service_invoice_items[j].discount_on_cash = Number(
        invoice.patient_service_invoice_items[j].discount_on_cash.toFixed(1),
      );
      invoice.patient_service_invoice_items[j].discount_on_receivable =
        (Number(this.patientServiceInvoices[i].receivable) / 100) * percentage;
      invoice.patient_service_invoice_items[j].discount_on_receivable = Number(
        invoice.patient_service_invoice_items[j].discount_on_receivable.toFixed(
          1,
        ),
      );
    }
    this.patientDiscount[i] = invoice;
    this.patientDiscount[i].manual_discount = 0;
    this.patientDiscount[i].type_amount = 0;

    this.patientDiscount[i].discount_on_cash = 0;
    this.patientDiscount[i].discount_on_receivable = 0;
    this.patientDiscount[i].quantity = 0;

    for (let z = 0; z < invoice.patient_service_invoice_items.length; z++) {
      this.patientDiscount[i].manual_discount =
        this.patientDiscount[i].manual_discount +
        invoice.patient_service_invoice_items[z].manual_discount;
      this.patientDiscount[i].type_amount =
        this.patientDiscount[i].type_amount +
        invoice.patient_service_invoice_items[z].type_amount;

      this.patientDiscount[i].discount_on_cash =
        this.patientDiscount[i].discount_on_cash +
        invoice.patient_service_invoice_items[z].discount_on_cash;
      this.patientDiscount[i].discount_on_receivable =
        this.patientDiscount[i].discount_on_receivable +
        invoice.patient_service_invoice_items[z].discount_on_receivable;
      this.patientDiscount[i].quantity =
        this.patientDiscount[i].quantity +
        invoice.patient_service_invoice_items[z].quantity;
    }
    this.updateTotalAmount();
  }
  updateSelectBoxOfInvoice(i): any {
    for (
      let j = 0;
      j < this.patientDiscount[i].patient_service_invoice_items.length;
      j++
    ) {
      if (
        (this.patientServiceInvoices[i].patient_service_invoice_items[j]
          .discount_approved_status !== 'approved' &&
        this.patientDiscount[i].patient_service_invoice_items[j]
          .invoice_amount > 0 ) &&  (
          (!this.patientDiscount[i].patient_service_invoice_items[j].is_taxable_service) ||
          (this.patientDiscount[i].patient_service_invoice_items[j].is_taxable_service &&
            this.patientDiscount[i].patient_service_invoice_items[j].is_tax_adjustable)
        )
      ) {
        this.patientDiscount[i].patient_service_invoice_items[j].selected =
          this.patientDiscount[i].selected;
      }
    }
    setTimeout(() => {
      if (this.patientDiscount[i].selected === false) {
        $('#discountTypeInvoiceAll' + i).val('amount');
        $('#discountTypeValueInvoiceAll' + i).val('');
        this.patientDiscount[i] = JSON.parse(
          JSON.stringify(this.copyPatientDiscount[i]),
        );
      }
    }, 500);
    this.updateAllDiscountValueOfInvoice();
    this.updateAllDiscountTypeOfInvoice();
    this.updateTotalAmount();
    this.chRef.detectChanges();
  }
  updateSelectBoxOfInvoiceItems(i, j): any {
    setTimeout(() => {
      if (
        this.patientDiscount[i].patient_service_invoice_items[j].selected ===
        false
      ) {
        this.patientDiscount[i].patient_service_invoice_items[j] = JSON.parse(
          JSON.stringify(
            this.copyPatientDiscount[i].patient_service_invoice_items[j],
          ),
        );
      }
    }, 500);
    // if ( !(this.copyPatientDiscount[i].patient_service_invoice_items[j].type_value > 0) ) {
    // }
    // this.updateAllDiscountValueOfInvoice();
    // this.updateAllDiscountTypeOfInvoice();
    // this.updateTotalAmount();

    this.updateDiscountValue(i, j);
    this.chRef.detectChanges();
  }
  updateTotalAmount(): any {
    this.totalDiscountedServiceAmount = 0;
    this.totalDiscountedAmount = 0;
    for (let i = 0; i < this.patientDiscount.length; i++) {
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        if (
          this.patientDiscount[i].patient_service_invoice_items[j].selected ===
          true
        ) {
          this.totalDiscountedServiceAmount =
            this.totalDiscountedServiceAmount +
            this.patientDiscount[i].patient_service_invoice_items[j]
              .invoice_amount;
          this.totalDiscountedAmount =
            this.totalDiscountedAmount +
            this.patientDiscount[i].patient_service_invoice_items[j]
              .manual_discount;
        }
      }
    }
    if (
      this.discountTypeValueInvoiceAll > 0 &&
      this.discountTypeInvoiceAll === 'percentage'
    ) {
      // this.discountTypeAmountInvoiceAll = this.totalDiscountedServiceAmount - this.totalDiscountedAmount;
      this.discountTypeAmountInvoiceAll = this.totalDiscountedAmount;
    }
  }
  updateDiscountTypeOfInvoice(value, i): any {
    for (
      let j = 0;
      j < this.patientDiscount[i].patient_service_invoice_items.length;
      j++
    ) {
      if (
        this.patientDiscount[i].patient_service_invoice_items[j].selected ===
          true &&
        this.patientDiscount[i].patient_service_invoice_items[j]
          .invoice_amount > 0
      ) {
        this.patientDiscount[i].patient_service_invoice_items[j].unit_type =
          value;
        this.updateDiscountValue(i, j);
        this.chRef.detectChanges();
      }
    }
    this.chRef.detectChanges();
  }

  updateAllDiscountTypeOfInvoice(): any {
    this.discountTypeAmountInvoiceAll = 0;
    for (let i = 0; i < this.patientDiscount.length; i++) {
      if (this.patientDiscount[i].selected === true) {
        $('#discountTypeInvoiceAll' + i).val(this.discountTypeInvoiceAll);
      }
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        if (
          this.patientDiscount[i].patient_service_invoice_items[j].selected ===
            true &&
          this.patientDiscount[i].patient_service_invoice_items[j]
            .invoice_amount > 0
        ) {
          this.patientDiscount[i].patient_service_invoice_items[j].unit_type =
            this.discountTypeInvoiceAll;
          this.updateDiscountValue(i, j);
          this.chRef.detectChanges();
        }
      }
    }
    this.chRef.detectChanges();
    this.updateAllDiscountValueOfInvoice();
  }

  updateDiscountValueOfInvoice(value, i): any {
    if ($('#discountTypeInvoiceAll' + i).val() === 'percentage') {
      if (value >= 100) {
        $('#discountTypeValueInvoiceAll' + i).val(100);
        value = '100';
      }
    }
    for (
      let j = 0;
      j < this.patientDiscount[i].patient_service_invoice_items.length;
      j++
    ) {
      if (
        this.patientDiscount[i].patient_service_invoice_items[j].selected ===
          true &&
        this.patientDiscount[i].patient_service_invoice_items[j]
          .invoice_amount > 0
      ) {
        this.patientDiscount[i].patient_service_invoice_items[j].type_value =
          Number(value);
        this.updateDiscountValue(i, j);
      }
    }
    this.chRef.detectChanges();
  }

  updateAllDiscountValueOfInvoice(): any {
    // if ( $('#discountTypeInvoiceAll').val() === 'percentage' ) {
    if (this.discountTypeValueInvoiceAll >= 100) {
      $('#discountTypeValueInvoiceAll').val(100);
      this.discountTypeValueInvoiceAll = 100;
    }
    // }
    for (let i = 0; i < this.patientDiscount.length; i++) {
      if (this.patientDiscount[i].selected === true) {
        $('#discountTypeValueInvoiceAll' + i).val(
          this.discountTypeValueInvoiceAll,
        );
      }
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        if (
          this.patientDiscount[i].patient_service_invoice_items[j].selected ===
            true &&
          this.patientDiscount[i].patient_service_invoice_items[j]
            .invoice_amount > 0
        ) {
          this.patientDiscount[i].patient_service_invoice_items[j].type_value =
            Number(this.discountTypeValueInvoiceAll);
        }
        this.updateDiscountValue(i, j);
      }
    }
    this.chRef.detectChanges();
  }
  updateAllDiscountAmountOfInvoice(): any {
    if (
      this.discountTypeAmountInvoiceAll >= this.totalDiscountedServiceAmount
    ) {
      this.discountTypeAmountInvoiceAll = this.totalDiscountedServiceAmount;
    }
    this.discountTypeValueInvoiceAll = Number(
      (this.discountTypeAmountInvoiceAll / this.totalDiscountedServiceAmount) *
        100,
    );
    this.discountTypeValueInvoiceAll = Number(
      this.discountTypeValueInvoiceAll.toFixed(4),
    );
    this.updateAllDiscountValueOfInvoice();
  }

  updateDiscountOnRece(i, j): any {
    const invoice = this.patientDiscount[i];
    if (
      invoice.patient_service_invoice_items[j].discount_on_receivable >
      invoice.patient_service_invoice_items[j].manual_discount
    ) {
      invoice.patient_service_invoice_items[j].discount_on_receivable =
        invoice.patient_service_invoice_items[j].manual_discount;
    }
    invoice.patient_service_invoice_items[j].discount_on_cash =
      invoice.patient_service_invoice_items[j].manual_discount -
      invoice.patient_service_invoice_items[j].discount_on_receivable;
    invoice.patient_service_invoice_items[j].discount_on_cash = Number(
      invoice.patient_service_invoice_items[j].discount_on_cash.toFixed(1),
    );
    this.patientDiscount[i] = invoice;
    // this.updateDiscountValue(i, j);
  }

  updateDiscountOnCash(i, j): any {
    const invoice = this.patientDiscount[i];
    if (
      invoice.patient_service_invoice_items[j].discount_on_cash >
      invoice.patient_service_invoice_items[j].manual_discount
    ) {
      invoice.patient_service_invoice_items[j].discount_on_cash =
        invoice.patient_service_invoice_items[j].manual_discount;
    }
    invoice.patient_service_invoice_items[j].discount_on_receivable =
      invoice.patient_service_invoice_items[j].manual_discount -
      invoice.patient_service_invoice_items[j].discount_on_cash;
    invoice.patient_service_invoice_items[j].discount_on_receivable = Number(
      invoice.patient_service_invoice_items[j].discount_on_receivable.toFixed(
        1,
      ),
    );
    this.patientDiscount[i] = invoice;
    // this.updateDiscountValue(i, j);
  }

  updateDiscount(): any {
    let errorCount = 0;
    $('.form-control').css('border', '1px solid #ccc');
    for (let i = 0; i < this.patientDiscount.length; i++) {
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        const services =
          this.patientDiscount[i].patient_service_invoice_items[j];
        if (services.type_value === null || services.type_value === undefined) {
          $('#serviceDiscount' + i + j).css('border', '1px solid red');
          errorCount++;
        }
        if (
          services.type_value > 0 &&
          services.discount_on_receivable === 0 &&
          services.discount_on_cash === 0
        ) {
          $('#serviceDiscountOnRece' + i + j).css('border', '1px solid red');
          $('#serviceDiscountOnCash' + i + j).css('border', '1px solid red');
          errorCount++;
        }
        if (
          services.type_value > 0 &&
          services.discount_on_receivable + services.discount_on_cash !==
            services.manual_discount
        ) {
          $('#serviceDiscountOnRece' + i + j).css('border', '1px solid red');
          $('#serviceDiscountOnCash' + i + j).css('border', '1px solid red');
          errorCount++;
        }
      }
    }

    if (this.discount_reason_id === '') {
      $('#serviceDisReasonsId').css('border', '1px solid red');
      errorCount++;
    }
    if (this.discount_comments === '') {
      $('#serviceDisComments').css('border', '1px solid red');
      errorCount++;
    }

    if (errorCount > 0) {
      return;
    }

    this.isChecked = false;
    // $('#updateConfirm').modal('show');
    this.modalService.open(this.updateConfirm);
  }

  addDiscount(): any {
    if (this.isChecked === false) {
      toastr.error('Select confirmation');
      return;
    }
    $('#updateConfirm').modal('hide');
    this.modalService.dismissAll(this.updateConfirm);
    this.ngxLoader.start();
    this.patientServiceInvoicesService
      .updateDiscountV2(
        this.patientDiscount,
        this.patient,
        this.discount_reason_id,
        this.discount_comments,
      )
      .subscribe(
        (data) => {
          this.searchInvoices();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  get k(): any {
    return this.PatientSearchForm.controls;
  }

  openPatientList(): any {
    $('#patient-list').toggleClass('offcanvas-on');
  }

  closePatientList(): any {
    $('#patient-list').removeClass('offcanvas-on');
  }
  printInvoice(id): any {
    window.open(
      this.current_environment +
        '/v4/patient_services/' +
        id +
        '/patient_services_invoice_slip.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&print_type=true&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      '_blank',
    );
  }
  openInvoiceItemDetails(i, j, data): any {
    this.invoiceItemDetails = data;
    this.modalService.open(this.invoiceItemDetailsModal, { size: 'lg' });
  }

  unSelectAllInvoicesItem(): any {
    for (let i = 0; i < this.patientDiscount.length; i++) {
      this.patientDiscount[i].selected = false;
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        this.patientDiscount[i].patient_service_invoice_items[j].selected =
          false;
      }
    }
    this.updateTotalAmount();
  }
  resetSelectBoxes(): any {
    this.patientDiscount = JSON.parse(JSON.stringify(this.copyPatientDiscount));
    this.unSelectAllInvoicesItem();
    this.discountTypeInvoiceAll = 'percentage';
    this.discountTypeAmountInvoiceAll = 0;
    this.discountTypeValueInvoiceAll = 0;
    this.totalDiscountedServiceAmount = 0;
    this.totalDiscountedAmount = 0;
    // this.selectedServices = [];
    this.selectedCancelInvoice = [];
    this.updateTotalAmount();
    this.cancelServiceType = 'invoice';
    this.resetSelectBoxesCancel();
    this.resetSelectBoxesCancelAdvance();
  }
  resetSelectBoxesCancelAdvance(): any {
    this.cancelAdvances = {
      advance_ids: [],
      cash: 0,
      patient_id: '',
      advance_cancel_reason_id: '',
      advance_cancel_comments: '',
    };
    $('.cancelCheckbox').prop('checked', false);
  }
  resetSelectBoxesCancel(): any {
    this.cancelServices = {
      invoice_ids: [],
      service_ids: [],
      cash: 0,
      receivable: 0,
      patient_id: this.patient.id,
      service_cancel_reason_id: '',
      service_cancel_comments: '',
    };
    this.totalCancelAmount = 0;
    this.totalCancelCash = 0;
    this.totalCancelReceivable = 0;
    this.totalCancelSelectedTotal = 0;
  }
  selectInvoiceForCancel(
    id,
    invoiceAmount,
    invoiceCash,
    invoiceReceivable,
  ): any {
    if (this.cancelServices.invoice_ids.includes(id)) {
      const ind = this.cancelServices.invoice_ids.indexOf(id);
      this.cancelServices.invoice_ids.splice(ind, 1);
      this.totalCancelAmount -= invoiceAmount;
      this.totalCancelCash -= invoiceCash;
      this.totalCancelReceivable -= invoiceReceivable;
    } else {
      this.cancelServices.invoice_ids.push(id);
      this.totalCancelAmount += invoiceAmount;
      this.totalCancelCash += invoiceCash;
      this.totalCancelReceivable += invoiceReceivable;
    }
    this.totalCancelSelectedTotal = this.totalCancelAmount;
    this.cancelServices.cash = this.totalCancelCash;
    this.cancelServices.receivable = this.totalCancelReceivable;
  }

  selectInvoiceServiceForCancel(
    id,
    serviceId,
    invoiceAmount,
    invoiceCash,
    invoiceReceivable,
    serviceCash,
    event,
  ): any {
    this.ngxLoader.start();
    if (event.target.checked === false) {
      if (this.cancelServices.service_ids.includes(serviceId)) {
        const ind = this.cancelServices.service_ids.indexOf(serviceId);
        this.cancelServices.service_ids.splice(ind, 1);
        this.totalCancelSelectedTotal -= serviceCash;
        if (this.cancelServices.service_ids.length === 0) {
          this.resetSelectBoxesCancel();
        }
      }
    }
    if (event.target.checked === true) {
      if (
        this.cancelServices.invoice_ids.length === 0 &&
        this.cancelServices.service_ids.length === 0
      ) {
        this.cancelServices.invoice_ids.push(id);
        this.cancelServices.service_ids.push(serviceId);
        this.totalCancelAmount = invoiceAmount;
        this.totalCancelCash = invoiceCash;
        this.totalCancelReceivable = invoiceReceivable;
        this.totalCancelSelectedTotal += serviceCash;
      } else {
        if (this.cancelServices.invoice_ids.includes(id)) {
          this.cancelServices.service_ids.push(serviceId);
          this.totalCancelSelectedTotal += serviceCash;
        } else {
          event.target.checked = false;
          toastr.error('You just cancel multiple services under one invoice');
          this.ngxLoader.stop();
          return;
        }
      }
    }
    const servicePercentage =
      (this.totalCancelSelectedTotal / this.totalCancelAmount) * 100 || 0;
    this.cancelServices.cash =
      Math.round((this.totalCancelCash / 100) * servicePercentage) || 0;
    this.cancelServices.receivable =
      Math.round((this.totalCancelReceivable / 100) * servicePercentage) || 0;
    this.ngxLoader.stop();
  }

  updateCancelServicesCash(event): any {
    if (event.target.value === 'null' || event.target.value === 'undefined') {
      event.target.value = 0;
    }
    let smallCash = 0;
    if (this.totalCancelSelectedTotal <= this.totalCancelCash) {
      smallCash = this.totalCancelSelectedTotal;
    } else {
      smallCash = this.totalCancelCash;
    }
    if (event.target.value > smallCash) {
      event.target.value = smallCash;
      this.cancelServices.cash = smallCash;
    }
    this.cancelServices.receivable =
      Number(this.totalCancelSelectedTotal) - Number(this.cancelServices.cash);
  }
  cancelServiceConfirmModal(): any {
    if (
      this.cancelServices.invoice_ids.length === 0 &&
      this.cancelServices.service_ids.length === 0
    ) {
      toastr.error('Select atleast one invoice or service');
      return;
    }
    let smallCash = 0;
    if (this.totalCancelSelectedTotal <= this.totalCancelCash) {
      smallCash = this.totalCancelSelectedTotal;
    } else {
      smallCash = this.cancelServices.cash;
    }
    if (this.cancelServices.cash > smallCash) {
      toastr.error('Refund Cash must be less then or equal to ' + smallCash);
      return;
    }
    if (
      this.totalCancelCash - this.cancelServices.cash < 0 ||
      this.totalCancelReceivable - this.cancelServices.receivable < 0
    ) {
      toastr.error('Final Cash and Receivable cannot be less then 0');
      return;
    }
    if (
      this.cancelServices.service_cancel_reason_id === '' ||
      this.cancelServices.service_cancel_comments === ''
    ) {
      toastr.error('Refund comments or reason cannot be empty');
      return;
    }
    this.modalService.open(this.cancelConfirm);
  }
  servicesCancelFun(): any {
    if (this.cancelServices.service_ids.length > 0) {
      this.cancelServices.invoice_ids = [];
    }
    this.ngxLoader.start();
    this.patientServiceInvoicesService
      .cancelServicesV2(this.cancelServices, this.patient)
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.cancelConfirm);
          this.resetSelectBoxesCancel();
          this.searchInvoices();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  cancelAdvanceConfirmModal(): any {
    if (this.cancelAdvances.advance_ids.length === 0) {
      toastr.error('Select atleast one advance for cancel');
      return;
    }
    if (this.cancelAdvances.advance_cancel_reason_id === '') {
      toastr.error('Select cancel reason');
      return;
    }
    // if ( this.cancelAdvances.advance_cancel_comments === '' ) {
    //   toastr.error('Select atleast one advance for cancel');
    //   return;
    // }
    this.cancelAdvances.patient_id = this.patient.id;
    this.modalService.open(this.cancelAdvanceConfirm);
  }
  advanceCancelFun(): any {
    this.ngxLoader.start();
    this.patientServiceInvoicesService
      .cancelAdvancesV2(this.cancelAdvances)
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.cancelAdvanceConfirm);
          this.resetSelectBoxesCancelAdvance();
          this.patientAdvances();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  toggleCheckBoxAdvance(data): any {
    if (this.cancelAdvances.advance_ids.includes(data.id)) {
      const ind = this.cancelAdvances.advance_ids.indexOf(data.id);
      this.cancelAdvances.advance_ids.splice(ind, 1);
      this.cancelAdvances.cash -= data.amount;
    } else {
      this.cancelAdvances.advance_ids.push(data.id);
      this.cancelAdvances.cash += data.amount;
    }
  }
}
