import { Component, Input, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-report-customize-one',
  templateUrl: './report-customize-one.component.html',
  styleUrls: ['./report-customize-one.component.css'],
})
export class ReportCustomizeOneComponent implements OnInit {
  @Input() pageDataList: any;
  @Input() groupIndex: any;
  isUomShow = false;
  isRefShow = false;
  resultData = [];
  editor: any;
  cutoffValue: any;
  result: any;
  historyDates = [];
  showResult = false;
  showSutoffValue = false;
  showEditor = false;
  instructionDetails: any;
  showLabTestName = true;
  sampleResults = '';
  criticalResults = []
  constructor() {}
  filterDataArray(dataArray) {
    return dataArray.filter((item) => {
      if (item.report_type === 'text' && item?.heading) {
        return true;
      } else if (item.report_type === 'combo' && item?.report_type_1_value) {
        return true;
      } else if (item.report_type === 'single' && item?.value) {
        return true;
      } else if (item.report_type === 'editor' && item?.heading) {
        return true;
      } else {
        return false;
      }
    });
  }
  ngOnInit(): void {
    if (window.location.origin.includes('zdiagnosticslab')) {
      this.showLabTestName = false;
    }
    this.pageDataList.lab_test_details = this.filterDataArray(
      this.pageDataList.lab_test_details,
    );
    let editorList = [];
    let cutoffValueList = [];
    let resultList = [];
    for (let j = 0; j < this.pageDataList.lab_test_details.length; j++) {
      if (
        this.pageDataList.lab_test_details[j].show_cutoff_patient_value === true
      ) {
        cutoffValueList.push(this.pageDataList.lab_test_details[j]);
      } else if (
        this.pageDataList.lab_test_details[j].report_type === 'editor'
      ) {
        editorList.push(this.pageDataList.lab_test_details[j]);
      } else {
        resultList.push(this.pageDataList.lab_test_details[j]);
      }
      if (this.pageDataList.lab_test_details[j].unit) {
        this.isUomShow = true;
      }
      if (
        this.pageDataList.lab_test_details[j].range_comments ||
        this.pageDataList.lab_test_details[j].range
      ) {
        this.isRefShow = true;
      }
    }
    console.log('resultList', resultList);
    resultList.map((element) => {
      if ( element.report_type === 'single' ) {
        if (element.value) {
          if (element.critical_range_type === 'in_between'){
            if ( (this.convertToNumber(element?.value) >= element?.critical_start_range &&
              this.convertToNumber(element?.value) <= element?.critical_end_range) ){
                this.criticalResults.push(element);
            }
          }
          if (element.critical_range_type === 'less_then'){
            if ( (this.convertToNumber(element?.value)  <= element?.critical_end_range) ){
              this.criticalResults.push(element);
            }
          }
          if (element.critical_range_type === 'greater_then'){
            if ( (this.convertToNumber(element?.value)  >= element?.critical_start_range) ){
              this.criticalResults.push(element);
            }
          }
          if (element.critical_range_type === 'equal'){
            if ( (this.convertToNumber(element?.value)  === element?.critical_start_range) ){
              this.criticalResults.push(element);
            }
          }
        }
      }
    });
    console.log('criticalResults', this.criticalResults);
    this.editor = this.groupBy(editorList, 'super_heading');
    this.cutoffValue = this.groupBy(cutoffValueList, 'super_heading');
    this.result = this.groupBy(resultList, 'super_heading');
    if (this.result.length > 0) {
      this.showResult = true;
    }
    if (this.result.length > 0 && this.pageDataList?.report_type === 'Solana') {
      this.sampleResults = 'The patient’s ' + this.pageDataList.specimen_name + ' obtained tested';
      const testGroups = {
        StrepA: this.result[0].filter(t => t.machine_code === 'STREPA'),
        Positive: this.result[0].filter(t => t.report_type_1_value === 'Positive'),
        Negative: this.result[0].filter(t => t.report_type_1_value === 'Negative'),
        Detected: this.result[0].filter(t => t.report_type_1_value === 'Detected'),
        NotDetected: this.result[0].filter(t => t.report_type_1_value === 'Not detected' || t.report_type_1_value === 'Not Detected')
      };
      const formatTests = (tests) => tests.map(test => test?.caption ?? '').join(', ');
      if (testGroups.StrepA.length > 0) {
        const StrepA  = testGroups.StrepA[0].report_type_1_value === 'Detected' ? 'Positive' : 'Negative';
        const StrepAValue  = testGroups.StrepA[0].report_type_1_value === 'Detected' ? 'Detected' : 'Not Detected';
        this.sampleResults += ` ${formatTests(testGroups.StrepA)} was <strong>${StrepA}</strong>, Streptococcus pyogenes DNA was <strong class="text-danger">${StrepAValue}</strong>.`;
      } else {
        if (testGroups.Positive.length > 0) {
          this.sampleResults += ` <strong>Positive </strong> for ${formatTests(testGroups.Positive)},`;
        }
        if (testGroups.Negative.length > 0) {
          this.sampleResults += ` <strong>Negative</strong> for ${formatTests(testGroups.Negative)}, `;
        }
        if ((testGroups.Positive.length > 0 || testGroups.Negative.length > 0) && (testGroups.Detected.length > 0)) {
          this.sampleResults = this.sampleResults.replace(/,\s*$/, ''); // remove trailing comma
          this.sampleResults += ' and ';
        }
        if (testGroups.Detected.length > 0) {
          this.sampleResults += ` ${formatTests(testGroups.Detected)}  ${testGroups.Detected.length > 1 ? 'were' : 'was'} <strong>Detected</strong>`;
        }
        if ((testGroups.Positive.length > 0 || testGroups.Negative.length > 0 || testGroups.Detected.length > 0) && (testGroups.NotDetected.length > 0)) {
          this.sampleResults = this.sampleResults.replace(/,\s*$/, ''); // remove trailing comma
          this.sampleResults += ' and ';
        }
        if (testGroups.NotDetected.length > 0) {
          this.sampleResults += ` ${formatTests(testGroups.NotDetected)} ${testGroups.NotDetected.length > 1 ? 'were' : 'was'} <strong>Not Detected</strong>.`;
        }
      }
// Remove any trailing commas from the paragraph
      this.sampleResults = this.sampleResults.replace(/,\s*$/, '');
    }
    if (this.cutoffValue.length > 0) {
      this.showSutoffValue = true;
    }
    if (this.editor.length > 0) {
      this.showEditor = true;
    }

    this.historyDates = [];
    if (this.showResult) {
      for (let date of this.result[0][0].history) {
        this.historyDates.push(date.previous_result_verification_time);
      }
    }

    setTimeout(() => {
      console.log('editor', this.editor);
      for (let name = 0; name < this.editor.length; name++) {
        for (let z = 0; z < this.editor[name].length; z++) {
          let id = '#editor' + this.groupIndex + '' + name + '' + z;
          // document.getElementById(id).innerHTML = this.editor[name][z].heading;
          $(id).html(this.editor[name][z].heading);
        }
      }
    }, 1500);
    this.instructionDetails = this.pageDataList?.lab_instruction_details;
  }
  // groupBy(array: any, key: any) {
  //   return array.reduce((result, currentValue) => {
  //      let keyValue = currentValue[key];
  //      if (keyValue === null || keyValue === '') {
  //        keyValue = 'null';
  //      }
  //      result[keyValue] = result[keyValue] || [];
  //      result[keyValue].push(currentValue);
  //      return result;
  //   }, {});
  // }
  groupBy(array, key) {
    const grouped = {};
    array.forEach((item) => {
      const keyValue = item[key] || 'null';
      if (!grouped[keyValue]) {
        grouped[keyValue] = [];
      }
      grouped[keyValue].push(item);
    });
    const result = Object.keys(grouped).map((key) => grouped[key]);
    return result;
  }
  convertToNumber(value): any {
    return Number(value);
  }
}
