import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class OtIssueItemsService {
  constructor(private http: HttpClient) {}

  otStoreProducts(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/inventory/get_ot_store_product?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  otStoreProductDetail(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/inventory/get_ot_store_product_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&product=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  postIssueItems(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/inventory/issue_note_for_ot?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getIssuedItems(admissionId): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/inventory/issue_notes_for_ot_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission=' +
          admissionId,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  getIssuedItemsV2(admissionId): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/inventory/merge_issue_notes_ot_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission=' +
          admissionId,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getIssueReturnItems(admissionId): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/inventory/patient_ot_medicine_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission=' +
          admissionId,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  postReturnItems(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/inventory/issue_notes_return_for_ot?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
}
