import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ServiceService } from '../../../../services/service.service';
import { SystemControlsService } from '../../../../services/system-controls.service';
import { PricingService } from '../../../../services/pricing.service';
import { HospitaldoctorService } from '../../../../services/hospitaldoctor.service';
import { PatientService } from '../../../../services/patient.service';
import { environment } from '../../../../../environments/environment';
import { ShareDataService } from '../../../../services/share-data.service';
import { LabTestService } from '../../../../services/lab-test.service';
import {ConfigurationService} from "../../../../services/configuration.service";

declare var $: any;
declare var toastr: any;
declare var Chart: any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
})
export class ServicesComponent implements OnInit {
  @Input() currentPage: any;
  @Input() patient: any;
  @Input() doctor: any;
  @Input() showMsg: any;
  @Input() visitType: any;
  @Input() isAdd: boolean;
  @Input() isopd: boolean;
  @Input() isReq: boolean;
  @Input() isServicesUpdate: number;
  @Output() isServicesRecieved = new EventEmitter<string>();
  get width(): any {
    return window.innerWidth;
  }

  serviceData = {
    patient_id: '',
    mode_of_payment: 'Credit',
    discount: 0,
    receivable: 0,
    cash: 0,
    ledger: '',
    eprescription: true,
    patient_services: [
      {
        service_id: '',
        pricing_id: '',
        doctor_id: '',
        service_type: '',
        comments: '',
        department_id: '',
        billing_code: '',
        performing_doctor_id: '',
        referring_doctor_id: '',
        price: 0,
        service_dept: '',
        discount: 0,
        service_price: 0,
        quantity: 1,
        urgent: false,
      },
    ],
  };

  services = [];
  servicesTotalBill = 0;
  uniq_services = [];
  allowServiceZeroPrice = false;
  checkDuplicateServices = true;
  servicesPrintRecent = true;
  patientServiceHistory = [];
  patientAllHistory = false;
  isChecked = false;
  // patientTypeCredit = false;
  labParameterStatus = false;
  currentPacUrl = '';
  radiologyPacsUrls: any;
  current_environment: string;
  currentControls: any;
  recentServices: any;
  page = 1;
  perPage = 25;
  totalPages: any;
  settingData: any;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private serviceService: ServiceService,
    private chRef: ChangeDetectorRef,
    private systemControlsService: SystemControlsService,
    private pricingService: PricingService,
    private hospitalDoctorsService: HospitaldoctorService,
    private patientService: PatientService,
    private shareDataService: ShareDataService,
    private systemControlService: SystemControlsService,
    private labTestService: LabTestService,
    private configService: ConfigurationService,
  ) {}

  ngOnInit(): void {
    // this.shareDataService.controlCheck.subscribe(data => {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
      if (
        this.currentControls.patient_services.allow_zero_service_price === true
      ) {
        this.allowServiceZeroPrice = true;
      }
    });
    this.fetchServicesTypes();
    this.fetchServicesHistory('');
    this.resetServices('');
    this.getWebControls();
  }
  ngOnChanges(changes: SimpleChanges): any {
    if (changes.isServicesUpdate?.currentValue) {
      this.fetchServicesHistory('');
    }
    this.fetchServicesHistory(changes.patient?.currentValue);
    this.resetServices(changes.patient?.currentValue);
  }

  getWebControls(): any {
    this.labTestService.fetchLabsControlsv2().subscribe(
      (data) => {
        this.settingData = data;
      },
      (err) => {},
    );
  }
  fetchSelectedServices(ind, type, serviceType): any {
    this.serviceData.patient_services[ind].service_dept = '';
    this.serviceData.patient_services[ind].department_id = '';
    this.serviceData.patient_services[ind].billing_code = '';
    this.serviceData.patient_services[ind].service_id = '';
    this.serviceData.patient_services[ind].service_price = 0;
    this.serviceData.patient_services[ind].discount = 0;
    this.serviceData.patient_services[ind].pricing_id = '';
    this.serviceData.patient_services[ind].price = 0;
    this.services[ind] = [];
    this.ngxLoader.start();
    this.serviceService.getServiceByTypesV2(serviceType).subscribe(
      (data) => {
        this.services[ind] = data.services;
        if (type === 'main') {
          $('#serviceNameMain' + ind).val('-1');
          setTimeout(() => {
            $('#serviceNameMain' + ind).selectpicker();
          }, 500);
          setTimeout(() => {
            $('#serviceNameMain' + ind).selectpicker('refresh');
          }, 500);
        } else {
          $('#serviceName' + ind).val('-1');
          setTimeout(() => {
            $('#serviceName' + ind).selectpicker();
          }, 500);
          setTimeout(() => {
            $('#serviceName' + ind).selectpicker('refresh');
          }, 500);
        }
        this.servicesTotalBill = 0;
        this.chRef.detectChanges();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchServicesTypes(): any {
    this.ngxLoader.start();
    this.uniq_services = [];
    this.systemControlsService.getServicesTypeConfigV2().subscribe(
      (data) => {
        this.uniq_services = data.service_type_configurators;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updateServicesDept(ind, data, event): any {
    const serviceObj = this.services[ind].find((m) => m.id === Number(event));
    // for (let i = 0; i < this.services.length; i++) {
    //   if (event === this.services[i].id.toString()) {
    if (serviceObj.department) {
      this.serviceData.patient_services[ind].service_dept =
        serviceObj.department.title;
      this.serviceData.patient_services[ind].department_id =
        serviceObj.department_id;
    }
    this.serviceData.patient_services[ind].billing_code =
      serviceObj.billing_code;
    this.serviceData.patient_services[ind].service_id = serviceObj.id;
    this.fetchServicePrice(serviceObj.billing_code, ind);
  }

  fetchServicePrice(code, ind): any {
    this.ngxLoader.start();
    this.pricingService.searchPriceAgainstV2(this.patient, code).subscribe(
      (data) => {
        let discount;
        if (
          this.patient.admissions.length > 0 &&
          this.patient.admitted === true
        ) {
          this.serviceData.patient_services[ind].service_price =
            data.pricings[0].ipd_price;
          discount =
            (data.pricings[0].ipd_price / 100) *
            data.pricings[0].system_discount_percentage;
        } else {
          this.serviceData.patient_services[ind].service_price =
            data.pricings[0].opd_price;
          discount =
            (data.pricings[0].opd_price / 100) *
            data.pricings[0].system_discount_percentage;
        }
        this.serviceData.patient_services[ind].discount = discount;
        this.serviceData.patient_services[ind].pricing_id = data.pricings[0].id;
        this.serviceData.patient_services[ind].price =
          this.serviceData.patient_services[ind].service_price;
        this.serviceData.patient_services[ind].quantity = 1;
        this.ngxLoader.stop();
        this.servicesTotalBill = 0;

        for (let i = 0; i < this.serviceData.patient_services.length; i++) {
          this.servicesTotalBill =
            this.servicesTotalBill + this.serviceData.patient_services[i].price;
        }
        // if (!this.allowServiceZeroPrice ) {
        //   if (this.serviceData.patient_services[ind].price === 0) {
        //     toastr.error('This service is not configured. Please contact IT Administrator');
        //   }
        // }
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  removeServices(i, price): any {
    if (this.serviceData.patient_services.length > 1) {
      this.servicesTotalBill = this.servicesTotalBill - price;
      this.serviceData.patient_services.splice(i, 1);
      this.services.splice(i, 1);
    } else if (this.serviceData.patient_services.length === 1) {
      this.servicesTotalBill = this.servicesTotalBill - price;
      this.serviceData.patient_services.splice(i, 1);
      this.services.splice(i, 1);
      this.addMoreServices();
    }
    setTimeout(() => {
      $('.serviceName').selectpicker('refresh');
    }, 200);
  }

  addMoreServices(): any {
    setTimeout(() => {
      $('.serviceSelectpickerMain').selectpicker();
      $('.serviceSelectpicker').selectpicker();
    }, 10);
    const addMoreData = {
      service_id: '',
      pricing_id: '',
      doctor_id: '',
      service_type: '',
      comments: '',
      department_id: '',
      billing_code: '',
      performing_doctor_id: '',
      referring_doctor_id: '',
      price: 0,
      service_dept: '',
      discount: 0,
      service_price: 0,
      quantity: 1,
      urgent: false,
    };
    this.serviceData.patient_services.push(addMoreData);
    this.services[this.serviceData.patient_services.length - 1] = [];
    if (this.serviceData.patient_services.length == 1) {
      this.services[0] = [];
    }
    let index = this.serviceData.patient_services.length - 1;
    setTimeout(() => {
      $('#referringDoctor' + index).selectpicker();
    }, 500);
  }

  fetchServicesHistory(val): any {
    $('#ipdServicedoctor').selectpicker();
    this.servicesPrintRecent = true;
    this.ngxLoader.start();
    this.patientServiceHistory = [];
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.patientService
      .patientServicesTabV2(
        this.patient,
        this.patientAllHistory,
        this.visitType,
        this.page,
        this.perPage,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.patientServiceHistory = data.patient_services;
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  getLisIntegration(LabOrderId, testCode): any {
    this.ngxLoader.start();
    this.patientService
      .getLisIntegration(this.settingData?.external_lis_base_url, LabOrderId)
      .subscribe(
        (data) => {
          if (data.length > 0) {
            const serviceObj = data.find((m) => m.testcode === testCode);
            if (serviceObj) {
              window.open(serviceObj?.url);
            } else {
              toastr.error('Report not finalized');
            }
          } else {
            toastr.error('Report not finalized');
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error('Something Went Wrong');
        },
      );
  }

  hasDuplicateIds(patient_services): any {
    return (
      patient_services.filter(
        (service, index, arr) =>
          arr.findIndex((s) => s.service_id === service.service_id) !== index,
      ).length > 0
    );
  }

  addServices(type): any {
    $('#duplicateServicesAllertModal').modal('hide');
    let counter = 0;
    $('.addServiceForm select').css('border', '1px solid #E4E6EF');
    this.serviceData.discount = 0;
    if (type === '') {
      for (let i = 0; i < this.serviceData.patient_services.length; i++) {
        this.serviceData.discount =
          this.serviceData.discount +
          this.serviceData.patient_services[i].discount;
        const serviceType = $('#serviceType' + i).val();
        const serviceName = $('#serviceName' + i).val();
        const referringDoctor = $('#referringDoctor' + i).val();
        const performingDoctor = $('#performingDoctor' + i).val();

        if (serviceType === 'Select type' || serviceType === null) {
          $('#serviceType' + i).css('border', '1px solid #dc3545');
          counter++;
        }

        // let typeObj = this.uniq_services.find(m => m.name === this.serviceData.patient_services[i].service_type);
        // if (typeObj.ref_doctor === true && this.serviceData.patient_services[i].doctor_name === '') {
        //   counter++;
        //   $('#referringDoctor' + i).css('border', '1px solid #dc3545');
        // }
        if (
          serviceName === 'Select service' ||
          serviceName === '-1' ||
          serviceName === null
        ) {
          toastr.error('Select Service');
          return;
          // $('#serviceName' + i).css('border', '1px solid #dc3545');
          // counter++;
        }
        if (
          !referringDoctor
        ) {
          toastr.error('Select Referring Doctor');
          return;
        }
        this.serviceData.patient_services[i].referring_doctor_id = $(
          '#referringDoctor' + i,
        ).val();
        this.serviceData.patient_services[i].performing_doctor_id = $(
          '#performingDoctor' + i,
        ).val();
        if (this.currentControls.ipd_controls.is_requested_service === false) {
          if (!this.allowServiceZeroPrice) {
            if (this.serviceData.patient_services[i].service_price === 0) {
              toastr.error(
                'This Service is not configured correctly. Please contact IT Administrator',
              );
              return;
            }
          }
        }
      }
    } else {
      for (let i = 0; i < this.serviceData.patient_services.length; i++) {
        this.serviceData.discount =
          this.serviceData.discount +
          this.serviceData.patient_services[i].discount;
        const serviceType = $('#serviceTypeMain' + i).val();
        const serviceName = $('#serviceNameMain' + i).val();
        if (serviceType === 'Select type' || serviceType === null) {
          $('#serviceTypeMain' + i).css('border', '1px solid #dc3545');
          counter++;
        }
        // let typeObj = this.uniq_services.find(m => m.name === this.serviceData.patient_services[i].service_type);
        // if (typeObj.ref_doctor === true && this.serviceData.patient_services[i].doctor_name === '') {
        //   counter++;
        //   $('#referringDoctorMian' + i).css('border', '1px solid #dc3545');
        // }
        if (
          serviceName === 'Select service' ||
          serviceName === '-1' ||
          serviceName === null
        ) {
          $('#serviceNameMain' + i).css('border', '1px solid #dc3545');
          counter++;
        }
        if (this.currentControls.ipd_controls.is_requested_service === true) {
          if (!this.allowServiceZeroPrice) {
            if (this.serviceData.patient_services[i].service_price === 0) {
              toastr.error(
                'This Service is not configured correctly. Please contact IT Administrator',
              );
              return;
            }
          }
        }
      }
    }
    if (counter > 0) {
      return;
    }
    if (this.checkDuplicateServices === true && type === '') {
      for (let i = 0; i < this.serviceData.patient_services.length; i++) {
        for (let z = 0; z < this.patientServiceHistory.length; z++) {
          if (
            this.serviceData.patient_services[i].service_id ===
              this.patientServiceHistory[z].service?.id &&
            new Date(this.patientServiceHistory[z].created_at).getDate() ===
              new Date().getDate()
          ) {
            $('#serviceType' + i).css('border', '1px solid #dc3545');
            counter++;
          }
        }
      }
      if (counter > 0) {
        this.isChecked = false;
        $('#duplicateServicesAllertModal').modal('show');
        return;
      }
    }
    if (this.hasDuplicateIds(this.serviceData.patient_services)) {
      toastr.error('Duplicate services are selected');
      return;
    }
    this.checkDuplicateServices = true;
    this.serviceData.cash = this.servicesTotalBill;
    this.ngxLoader.start();
    this.patientService
      .addPatientServiceIPDV2(
        this.patient,
        this.serviceData,
        'unpaid',
        this.isopd,
        this.isReq,
        this.currentPage
      )
      .subscribe(
        (data) => {
          this.services = [];
          setTimeout(() => {
            $('.serviceSelectpicker').selectpicker();
          }, 10);
          this.isServicesUpdate = Math.random();
          this.isServicesRecieved.emit(this.isServicesUpdate.toString());
          this.fetchServicesHistory('');
          this.servicesTotalBill = 0;
          this.serviceData = {
            patient_id: '',
            mode_of_payment: 'Credit',
            discount: 0,
            receivable: 0,
            cash: 0,
            ledger: '',
            eprescription: true,
            patient_services: [
              {
                service_id: '',
                pricing_id: '',
                doctor_id: '',
                service_type: '',
                comments: '',
                department_id: '',
                billing_code: '',
                performing_doctor_id: '',
                referring_doctor_id: '',
                price: 0,
                service_dept: '',
                discount: 0,
                service_price: 0,
                quantity: 1,
                urgent: false,
              },
            ],
          };
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  updateServicesPrintRecent(value): any {
    $('#recentMedicineAll').prop('checked', false);
    $('#recentMedicineAlls').prop('checked', false);
    $('#recentServiceAll').prop('checked', false);
    if (value === 'true') {
      this.servicesPrintRecent = true;
    } else {
      this.servicesPrintRecent = false;
    }
  }

  openLabParameterModal(): any {
    this.labParameterStatus = true;
    $('#lappatametermodal').modal('show');
  }

  printRecentServicesSlip(): any {
    if (
      $('#recentServiceAll').prop('checked') === false &&
      $('.recentService').prop('checked') === false
    ) {
      toastr.error('Select Atleast 1 Service');
    }

    const serviceArray = [];
    if (this.servicesPrintRecent === true) {
      for (let i = 0; i < this.patientServiceHistory.length; i++) {
        if (
          this.patientServiceHistory[i].recent === true &&
          $('#recentService' + i).prop('checked') === true
        ) {
          serviceArray.push(this.patientServiceHistory[i].id);
        }
      }
    }
    if (this.servicesPrintRecent === false) {
      for (let i = 0; i < this.patientServiceHistory.length; i++) {
        if (
          this.patientServiceHistory[i].recent === false &&
          $('#recentService' + i).prop('checked') === true
        ) {
          serviceArray.push(this.patientServiceHistory[i].id);
        }
      }
    }
    if (serviceArray.length > 0) {
      $('.recentService').prop('checked', false);
      $('#recentServiceAll').prop('checked', false);
      window.open(
        'pdf-reports/patient-services-report?patient_id=' +
          this.patient.id +
          '&visit_id=' +
          this.patient.visit_id +
          '&mrn=' +
          this.patient.mrn +
          '&invoice_id=' +
          '&service_id=' +
          serviceArray,
      );
      // window.open(environment.rorUrl + '/v4/patient_services/recent_services_report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&service_id=' + serviceArray + '&patient_id=' + this.patient.id);
    }
  }

  selectAllRecentServices(): any {
    if ($('#recentServiceAll').prop('checked') === true) {
      $('.recentService').prop('checked', true);
    }
    if ($('#recentServiceAll').prop('checked') === false) {
      $('.recentService').prop('checked', false);
    }
  }

  openDiscomImage(id): any {
    if (this.currentPacUrl === ''){
      this.getRadiologyPACSUrls(id);
    } else {
      const newUrl = this.currentPacUrl.replace('{:id}', id);
      window.open(newUrl);
    }
  }

  getRadiologyPACSUrls(id): any {
    this.ngxLoader.start();
    this.configService.getPACSUrlV2().subscribe(
      (data) => {
        if (data.radiology_configurator) {
          this.radiologyPacsUrls = data.radiology_configurator;
          if (this.radiologyPacsUrls.web_url){
            this.currentPacUrl = this.radiologyPacsUrls.web_url;
          } else {
            this.currentPacUrl = this.radiologyPacsUrls.radiant_url;
          }
          const newUrl = this.currentPacUrl.replace('{:id}', id);
          window.open(newUrl);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  printRadiologyReport(id, patientMrn): any {
    window.open(
      '/radiology/pdf-report-v2?rad_id=' +
        id +
        '&mrn=' +
        patientMrn.mrn +
        '&visit_id=' +
        patientMrn.visit_id +
        '&show_header=true' +
        '&show_footer=true',
    );
    // window.open(this.current_environment + '/v4/reports/print_report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&id=' + id);
  }

  viewExternalReport(lab_order_detail_id): any {
    window.open(
      environment.rorUrl +
        '/v4/lab_order_details/report?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&lab_order_detail_id=' +
        lab_order_detail_id +
        '&patient_id=' +
        this.patient.mrn,
    );
  }

  printService(id, LabOrderId, testCode): any {
    if (this.settingData?.enable_external_lis) {
      this.getLisIntegration(LabOrderId, testCode);
    } else {
      window.open(
        'lab/lab-report-pdf?id=' +
          this.patient.id +
          '&visit_id=' +
          this.patient.visit_id +
          '&mrn=' +
          this.patient.mrn +
          '&invoice_id=' +
          '&lab_id=' +
          id,
      );
    }
    // window.open(this.current_environment + '/v4/orders/lab_order_report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&id=' + id);
  }

  openPrescriptionSlip(id): any {
    // window.open(environment.rorUrl + '/v4/patients/' + this.patient.id + '/prescription_slip.pdf?visit_id=' + id + '&auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'));
    if (this.currentControls !== '') {
      if (this.currentControls.patient_services.is_prescription === false) {
        window.open(
          'pdf-reports/prescription-v2?patient_id=' +
            this.patient.id +
            '&visit_id=' +
            this.patient.visit_id +
            '&mrn=' +
            this.patient.mrn,
        );
      } else {
        window.open(
          environment.rorUrl +
            '/v4/patients/' +
            this.patient.id +
            '/prescription_slip.pdf?visit_id=' +
            id +
            '&auth_token=' +
            localStorage.getItem('auth_token') +
            '&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id'),
        );
      }
    } else {
      window.open(
        environment.rorUrl +
          '/v4/patients/' +
          this.patient.id +
          '/prescription_slip.pdf?visit_id=' +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
      );
    }
  }
  addServicesDuplicateConfirm(): any {
    if (this.isChecked === false) {
      toastr.error('Check confirmation first');
      return;
    }
    this.checkDuplicateServices = false;
    this.addServices('');
  }

  resetServices(val): any {
    setTimeout(() => {
      $('.serviceSelectpicker').selectpicker();
      $('#referringDoctor0').selectpicker();
    }, 10);
    this.services[0] = [];
    this.servicesTotalBill = 0;
    this.serviceData = {
      patient_id: '',
      mode_of_payment: 'Credit',
      discount: 0,
      receivable: 0,
      cash: 0,
      ledger: '',
      eprescription: true,
      patient_services: [
        {
          service_id: '',
          pricing_id: '',
          doctor_id: '',
          service_type: '',
          comments: '',
          department_id: '',
          billing_code: '',
          performing_doctor_id: '',
          referring_doctor_id: '',
          price: 0,
          service_dept: '',
          discount: 0,
          service_price: 0,
          quantity: 1,
          urgent: false,
        },
      ],
    };
    // this.patientTypeCredit = false;
    // if (this.patient.panel_patient_type) {
    //   this.patientTypeCredit = this.patient.panel_patient_type.credit;
    // }
    this.checkDuplicateServices = true;
  }

  loadPage(event): any {
    this.fetchServicesHistory('');
  }

  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.fetchServicesHistory('');
  }
}
