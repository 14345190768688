<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">Lab Result Entry</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card card-custom">
        <div class="card-body">
          <form
            [formGroup]="labServicesForm"
            (ngSubmit)="searchInvoices()"
            autocomplete="off"
          >
            <div class="row">
              <div class="col-md-2 col-sm-3 col-4">
                <div class="form-group">
                  <label>From Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="fromDate"
                    data-toggle="datetimepicker"
                    data-target="#fromDate"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <div class="form-group">
                  <label>To Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="toDate"
                    data-target="#toDate"
                    data-toggle="datetimepicker"
                  />
                </div>
              </div>

              <!--              <div class="col-md-2 col-sm-3 col-4">-->
              <!--                <label>Department</label>-->
              <!--                <div class="form-group">-->
              <!--                  <div class="d-block">-->
              <!--                    <select class="form-control form-control-sm" formControlName="parent_department_id" (change)="getSubDepartment($event.target.value)">-->
              <!--                      <option value="" disabled>Select</option>-->
              <!--                      <option value="" *ngIf="departments?.length > 0">All</option>-->
              <!--                      <option [value]="department.id" *ngFor="let department of departments">{{department.title}}</option>-->
              <!--                    </select>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->

              <div class="col-md-2 col-sm-3 col-4">
                <label>Sub Department</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm"
                      id="department"
                      formControlName="department_id"
                    >
                      <option value="" disabled>Select</option>
                      <option value="" *ngIf="subDepartments?.length > 0">
                        All
                      </option>
                      <option
                        [value]="department.id"
                        *ngFor="let department of subDepartments"
                      >
                        {{ department.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-4 col-6">
                <div style="display: flex">
                  <div style="flex-basis: 48%" class="mr-5">
                    <label>MRN</label>
                    <div class="form-group">
                      <div class="d-block">
                        <input
                          class="form-control form-control-sm"
                          formControlName="patient_id"
                          placeholder="MRN"
                          (keypress)="_onlyNumeric($event)"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="flex-basis: 48%">
                    <label>Lab Id</label>
                    <div class="form-group">
                      <div class="d-block">
                        <input
                          class="form-control form-control-sm"
                          placeholder="Lab Id"
                          formControlName="lab_id"
                          (keypress)="_onlyNumeric($event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--add button-->
              <div class="col-md-1 col-sm-3 col-4 text-right">
                <div class="form-group">
                  <div class="d-block">
                    <button
                      type="submit"
                      class="btn btn-primary btn-sm cus_btm"
                      ngbTooltip="Search Result"
                      placement="bottom"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card card-custom gutter-b mt-7">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Table-->
          <div class="table_top_bar mb-3">
            <div class="row">
              <div class="col-12 col-sm-12 text-right">
                <div class="dropdown dropdown-inline mr-2">
                  <button
                    (click)="filtersInvoices('')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5"
                  >
                    All
                  </button>
                  <button
                    (click)="filtersInvoices('opd')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 opd-btn"
                  >
                    OPD
                  </button>
                  <button
                    (click)="filtersInvoices('ipd')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 ipd-btn"
                  >
                    IPD
                  </button>
                  <button
                    (click)="filtersInvoices('urgent')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 urgent"
                  >
                    Urgent
                  </button>
                  <button
                    (click)="filtersInvoices('returned')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 returned"
                  >
                    Returned
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="table-responsive position-relative">
              <table
                class="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_3"
              >
                <thead>
                  <tr>
                    <th style="width: 1%"></th>
                    <th style="width: 15%">Date / Lab Id</th>
                    <th style="width: 30%">Test Name / Department</th>
                    <th style="width: 20%">Patient Name / MRN#</th>
                    <th style="width: 12%">Specimen</th>
                    <th style="width: 12%">Sample</th>
                    <th style="width: 10%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let element of labTestListing; let i = index"
                  >
                    <tr
                      [ngClass]="{
                        returned: element.rejected === true,
                        urgent: element.urgent === true
                      }"
                    >
                      <td
                        [ngClass]="{
                          'ipd-btn': element.ipd === true,
                          'opd-btn': element.ipd === false
                        }"
                      ></td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                          >{{
                            element.receiving_time | date: "yyyy-MM-dd, h:mm a"
                          }}</span
                        >
                        <span class="text-muted font-weight-bold d-block">{{
                          element.lab_id
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                          >{{ element.test }}</span
                        >
                        <span class="text-muted font-weight-bold d-block">{{
                          element.department_name
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                          >{{ element.patient_name }}</span
                        >
                        <span class="text-muted font-weight-bold d-block">{{
                          element.patient_mrn
                        }}</span>
                      </td>
                      <td>{{ element.specimen_name }}</td>
                      <td>{{ element.sample_taken_from_name }}</td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-light-primary btn-sm"
                          (click)="
                            getReportDetail(element.id);
                            getPendingResults(element.patient_mrn)
                          "
                        >
                          Add Reports
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
            <!--pagination-->
            <div class="pagination_main">
              <ngb-pagination
                [(page)]="page"
                (pageChange)="loadPage(page)"
                [pageSize]="perPage"
                [collectionSize]="totalPages"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              ></ngb-pagination>
              <div class="dropdown">
                <a
                  class="btn dropdown-toggle btn-light btn-sm"
                  role="button"
                  id="pagination_btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="page_count">
                    {{ perPage }}
                  </span>
                  <span class="count_count_icon"></span>
                </a>
                <div class="dropdown-menu" aria-labelledby="pagination_btn">
                  <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                    >25</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                    >50</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                    >75</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                    >100</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit('')"
                    >All</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #reportsModal let-c="close" let-d="dismiss">
      <div class="">
        <!--        <div class="modal-header p-3">-->
        <!--          <h4 class="modal-title">{{activeLabEntry?.test}}</h4>-->
        <!--          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">-->
        <!--            <span aria-hidden="true">&times;</span>-->
        <!--          </button>-->
        <!--        </div>-->
        <div class="modal-body modal-body-report">
          <div class="d-flex flex-row">
            <!--begin::Aside-->
            <div class="flex-row-auto w-150px w-xxl-250px">
              <div class="card card-custom mb-5">
                <div class="card-body user-card-body pt-4">
                  <div class="py-2">
                    <div
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <span class="font-weight-bold mr-2">{{
                        activeLabEntry?.patient_name
                      }}</span>
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <span class="font-weight-bold mr-2">{{
                        activeLabEntry?.patient_mrn
                      }}</span>
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <span class="font-weight-bold mr-2">{{
                        activeLabEntry?.patient_age +
                          " " +
                          activeLabEntry?.patient_age_type
                      }}</span>
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <span class="font-weight-bold mr-2">{{
                        activeLabEntry?.patient_gender | uppercase
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom mb-5">
                <div class="card-body user-card-body pt-4">
                  <div class="py-2">
                    <table class="table">
                      <tr
                        *ngFor="
                          let element of patientLabTestListing;
                          let i = index
                        "
                      >
                        <td (click)="getReportDetail(element.id)">
                          <span
                            class="mb-1 font-size-lg cursor-pointer"
                            [ngClass]="{
                              'font-weight-bold text-primary':
                                element.id === activeLabEntry.id
                            }"
                            >{{ element.test }}</span
                          >
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-row-fluid ml-lg-8">
              <div class="card card-custom mb-5">
                <div class="card-body">
                  <div class="row">
                    <div class="col-10">
                      <h4 class="m-0">{{ activeLabEntry?.test }}</h4>
                    </div>
                    <div class="col-2">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        (click)="d('Cross click')"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="modalType === 'reportsModal'">
                <div>
                  <div
                    class="card card-custom mb-5"
                    *ngFor="let reports of reportType; let i = index"
                  >
                    <div class="card-body">
                      <div class="clearfix">
                        <div
                          class="col-xs-12 p-lr-0"
                          *ngIf="
                            reports.name !== 'null' &&
                            reports.name !== 'undefined'
                          "
                        >
                          <h4 class="margin-0">{{ reports.name }}</h4>
                        </div>
                      </div>
                      <div class="clearfix">
                        <table class="table table-striped margin-bottom-0">
                          <thead>
                            <tr>
                              <th style="width: 30%">Name</th>
                              <th style="width: 10%">Uom</th>
                              <!--                        <th style="width: 10%">specimen</th>-->
                              <th style="width: 10%">Range</th>
                              <th style="width: 10%">Comments</th>
                              <th style="width: 20%">Value</th>
                              <th style="width: 20%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container
                              *ngFor="
                                let report of reports.value;
                                let j = index
                              "
                            >
                              <tr>
                                <td>{{ report.title }}</td>
                                <td>{{ report.uom }}</td>
                                <!--                        <td>{{report.specimen}}</td>-->
                                <td>{{ report.range || "--" }}</td>
                                <td>
                                  <span
                                    [innerHTML]="report.range_comments"
                                  ></span>
                                </td>
                                <td *ngIf="report?.report_type === 'single'">
                                  <ng-container
                                    *ngIf="report?.range_type === 'in_between'"
                                  >
                                    <input
                                      type="text"
                                      (keydown)="isNumberKey($event)"
                                      style="max-width: 100%"
                                      placeholder="number"
                                      class="form-control form-control-sm"
                                      [ngClass]="
                                        (convertToNumber(report.report_range) >=
                                          report.range_start &&
                                          report.report_range <=
                                            report.range_end) ||
                                        report.report_range === '' ||
                                        report.report_range === null ||
                                        report.report_range === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                      [(ngModel)]="report.report_range"
                                    />
                                  </ng-container>
                                  <ng-container
                                    *ngIf="report?.range_type === 'less_then'"
                                  >
                                    <input
                                      type="text"
                                      (keydown)="isNumberKey($event)"
                                      style="max-width: 100%"
                                      placeholder="number"
                                      class="form-control form-control-sm"
                                      [ngClass]="
                                        convertToNumber(report.report_range) <=
                                          report.range_end ||
                                        report.report_range === '' ||
                                        report.report_range === null ||
                                        report.report_range === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                      [(ngModel)]="report.report_range"
                                    />
                                  </ng-container>
                                  <ng-container
                                    *ngIf="
                                      report?.range_type === 'greater_then'
                                    "
                                  >
                                    <input
                                      type="text"
                                      (keydown)="isNumberKey($event)"
                                      style="max-width: 100%"
                                      placeholder="number"
                                      class="form-control form-control-sm"
                                      [ngClass]="
                                        convertToNumber(report.report_range) >=
                                          report.range_start ||
                                        report.report_range === '' ||
                                        report.report_range === null ||
                                        report.report_range === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                      [(ngModel)]="report.report_range"
                                    />
                                  </ng-container>
                                  <ng-container
                                    *ngIf="report?.range_type === 'equal'"
                                  >
                                    <input
                                      type="text"
                                      (keydown)="isNumberKey($event)"
                                      style="max-width: 100%"
                                      placeholder="number"
                                      class="form-control form-control-sm"
                                      [ngClass]="
                                        convertToNumber(report.report_range) ===
                                          report.range_start ||
                                        report.report_range === '' ||
                                        report.report_range === null ||
                                        report.report_range === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                      [(ngModel)]="report.report_range"
                                    />
                                  </ng-container>
                                  <ng-container
                                    *ngIf="
                                      report?.range_type !== 'equal' &&
                                      report?.range_type !== 'greater_then' &&
                                      report?.range_type !== 'less_then' &&
                                      report?.range_type !== 'in_between'
                                    "
                                  >
                                    <input
                                      type="text"
                                      (keydown)="isNumberKey($event)"
                                      style="max-width: 100%"
                                      placeholder="number"
                                      class="form-control form-control-sm"
                                      [(ngModel)]="report.report_range"
                                    />
                                  </ng-container>
                                </td>
                                <td
                                  *ngIf="report?.report_type === 'single'"
                                ></td>

                                <td
                                  colspan="2"
                                  *ngIf="report?.report_type === 'text'"
                                >
                                  <input
                                    type="text"
                                    placeholder="text"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="report.heading"
                                  />
                                </td>
                                <td
                                  colspan="2"
                                  *ngIf="report?.report_type === 'editor'"
                                ></td>

                                <td *ngIf="report?.report_type === 'combo'">
                                  <span
                                    *ngIf="report?.report_type_1 === 'text'"
                                  >
                                    <input
                                      type="text"
                                      style="max-width: 100%"
                                      placeholder="text"
                                      class="form-control form-control-sm"
                                      [(ngModel)]="report.report_type_1_value"
                                    />
                                  </span>
                                  <span
                                    *ngIf="report?.report_type_1 === 'decimal'"
                                  >
                                    <input
                                      type="text"
                                      (keydown)="isNumberKey($event)"
                                      style="max-width: 100%"
                                      placeholder="number"
                                      class="form-control form-control-sm"
                                      [(ngModel)]="report.report_type_1_value"
                                    />
                                  </span>
                                  <span
                                    *ngIf="report?.report_type_1 === 'dropdown'"
                                  >
                                    <select
                                      class="form-control form-control-sm"
                                      style="max-width: 100%"
                                      [(ngModel)]="report.report_type_1_value"
                                    >
                                      <option value=""></option>
                                      <option
                                        *ngFor="
                                          let val of report?.report_type_1_result
                                        "
                                        [value]="val"
                                      >
                                        {{ val }}
                                      </option>
                                    </select>
                                  </span>
                                </td>

                                <td
                                  *ngIf="
                                    report?.report_type === 'combo' &&
                                      report?.report_type_2 !== '';
                                    else notShowContent
                                  "
                                >
                                  <span
                                    *ngIf="report?.report_type_2 === 'text'"
                                  >
                                    <input
                                      type="text"
                                      style="max-width: 100%"
                                      placeholder="text"
                                      class="form-control form-control-sm"
                                      [(ngModel)]="report.report_type_2_value"
                                    />
                                  </span>
                                  <span
                                    *ngIf="report?.report_type_2 === 'decimal'"
                                  >
                                    <input
                                      type="text"
                                      (keydown)="isNumberKey($event)"
                                      style="max-width: 100%"
                                      placeholder="number"
                                      class="form-control form-control-sm"
                                      [(ngModel)]="report.report_type_2_value"
                                    />
                                  </span>
                                  <span
                                    *ngIf="report?.report_type_2 === 'dropdown'"
                                  >
                                    <select
                                      class="form-control form-control-sm"
                                      style="max-width: 100%"
                                      [(ngModel)]="report.report_type_2_value"
                                    >
                                      <option value=""></option>
                                      <option
                                        *ngFor="
                                          let val of report?.report_type_2_result
                                        "
                                        [value]="val"
                                      >
                                        {{ val }}
                                      </option>
                                    </select>
                                  </span>
                                </td>

                                <ng-template #notShowContent>
                                  <td></td>
                                </ng-template>
                              </tr>
                              <tr *ngIf="report?.report_type === 'editor'">
                                <td
                                  colspan="7"
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  <div
                                    class="textEditor"
                                    id="textEditor{{ i }}{{ j }}"
                                  ></div>
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card card-custom mb-5">
                    <div class="card-body">
                      <div class="clearfix">
                        <div class="col-xs-12 p-lr-0">
                          <h4 class="margin-0">Remarks</h4>
                        </div>
                      </div>
                      <div class="clearfix">
                        <table class="table table-striped margin-bottom-0">
                          <thead>
                            <tr>
                              <th style="width: 20%">Remarks</th>
                              <th style="width: 80%">Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <select
                                  class="form-control form-control-sm"
                                  (change)="
                                    updatePathologistRemarks(
                                      $event.target.value
                                    )
                                  "
                                >
                                  <option value="">Select</option>
                                  <ng-container
                                    *ngFor="
                                      let comments of activeLabEntry?.pathologist_remarks
                                    "
                                  >
                                    <option value="{{ comments.remarks }}">
                                      {{ comments.remarks }}
                                    </option>
                                  </ng-container>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="pathologistRemarks"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card card-custom mb-5">
                    <div class="card-body text-right">
                      <button
                        type="button"
                        class="btn btn-sm btn-default mr-2"
                        (click)="c('Close click')"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-light-primary mr-2"
                        (click)="submitResult(true)"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        (click)="submitResult(false)"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="modalType === 'reportsModalUpdate'">
                <div
                  class="card card-custom mb-5"
                  *ngFor="let reports of reportTypeUpdate; let i = index"
                >
                  <div class="card-body">
                    <div class="clearfix">
                      <div
                        class="col-xs-12 p-lr-0"
                        *ngIf="
                          reports.name !== 'null' &&
                          reports.name !== 'undefined'
                        "
                      >
                        <h4 class="margin-0">{{ reports.name }}</h4>
                      </div>
                    </div>
                    <div class="clearfix">
                      <table class="table table-striped margin-bottom-0">
                        <thead>
                          <tr>
                            <th style="width: 20%">Name</th>
                            <th style="width: 10%">Uom</th>
                            <th style="width: 10%">specimen</th>
                            <th style="width: 10%">Range</th>
                            <th style="width: 10%">Comments</th>
                            <th style="width: 20%">Value</th>
                            <th style="width: 20%"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container
                            *ngFor="let report of reports.value; let j = index"
                          >
                            <tr>
                              <td>{{ report.caption }}</td>
                              <td>{{ report.unit }}</td>
                              <td>{{ report.specimen }}</td>
                              <td>{{ report.range || "--" }}</td>
                              <td>
                                <span
                                  [innerHTML]="report.range_comments"
                                ></span>
                              </td>
                              <!--              <td  style="width: 20%"><input type="number" class="form-control form-control-sm" [(ngModel)]="report.value"></td>-->

                              <td *ngIf="report?.report_type === 'single'">
                                <input
                                  type="text"
                                  (keydown)="isNumberKey($event)"
                                  style="max-width: 100%"
                                  placeholder="number"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="report.value"
                                />
                              </td>
                              <td *ngIf="report?.report_type === 'single'"></td>

                              <td
                                colspan="2"
                                *ngIf="report?.report_type === 'text'"
                              >
                                <input
                                  type="text"
                                  placeholder="text"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="report.heading"
                                />
                              </td>
                              <td
                                colspan="2"
                                *ngIf="report?.report_type === 'editor'"
                              ></td>

                              <td *ngIf="report?.report_type === 'combo'">
                                <span *ngIf="report?.report_type_1 === 'text'">
                                  <input
                                    type="text"
                                    style="max-width: 100%"
                                    placeholder="text"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="report.report_type_1_value"
                                  />
                                </span>
                                <span
                                  *ngIf="report?.report_type_1 === 'decimal'"
                                >
                                  <input
                                    type="text"
                                    (keydown)="isNumberKey($event)"
                                    style="max-width: 100%"
                                    placeholder="number"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="report.report_type_1_value"
                                  />
                                </span>
                                <span
                                  *ngIf="report?.report_type_1 === 'dropdown'"
                                >
                                  <select
                                    class="form-control form-control-sm"
                                    style="max-width: 100%"
                                    [(ngModel)]="report.report_type_1_value"
                                  >
                                    <option value=""></option>
                                    <option
                                      *ngFor="
                                        let val of report?.report_type_1_result
                                      "
                                      [value]="val"
                                    >
                                      {{ val }}
                                    </option>
                                  </select>
                                </span>
                              </td>
                              <td
                                *ngIf="
                                  report?.report_type === 'combo' &&
                                    report?.report_type_2 !== '';
                                  else notShowContent1
                                "
                              >
                                <span *ngIf="report?.report_type_2 === 'text'">
                                  <input
                                    type="text"
                                    style="max-width: 100%"
                                    placeholder="text"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="report.report_type_2_value"
                                  />
                                </span>
                                <span
                                  *ngIf="report?.report_type_2 === 'decimal'"
                                >
                                  <input
                                    type="text"
                                    (keydown)="isNumberKey($event)"
                                    style="max-width: 100%"
                                    placeholder="number"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="report.report_type_2_value"
                                  />
                                </span>
                                <span
                                  *ngIf="report?.report_type_2 === 'dropdown'"
                                >
                                  <select
                                    class="form-control form-control-sm"
                                    style="max-width: 100%"
                                    [(ngModel)]="report.report_type_2_value"
                                  >
                                    <option value=""></option>
                                    <option
                                      *ngFor="
                                        let val of report?.report_type_2_result
                                      "
                                      [value]="val"
                                    >
                                      {{ val }}
                                    </option>
                                  </select>
                                </span>
                              </td>
                              <ng-template #notShowContent1>
                                <td></td>
                              </ng-template>
                            </tr>
                            <tr *ngIf="report?.report_type === 'editor'">
                              <td
                                colspan="7"
                                style="padding-left: 0px; padding-right: 0px"
                              >
                                <div
                                  class="textEditor"
                                  id="textEditorUpdate{{ i }}{{ j }}"
                                ></div>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card card-custom mb-5">
                  <div class="card-body">
                    <div class="clearfix">
                      <div class="col-xs-12 p-lr-0">
                        <h4 class="margin-0">Remarks</h4>
                      </div>
                    </div>
                    <div class="clearfix">
                      <table class="table table-striped margin-bottom-0">
                        <thead>
                          <tr>
                            <th style="width: 20%">Remarks</th>
                            <th style="width: 80%">Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <select
                                class="form-control form-control-sm"
                                (change)="
                                  updatePathologistRemarks($event.target.value)
                                "
                              >
                                <option value="">Select</option>
                                <ng-container
                                  *ngFor="
                                    let comments of activeLabEntry?.pathologist_remarks
                                  "
                                >
                                  <option value="{{ comments.remarks }}">
                                    {{ comments.remarks }}
                                  </option>
                                </ng-container>
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                [(ngModel)]="pathologistRemarks"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card card-custom mb-5">
                  <div class="card-body text-right">
                    <button
                      type="button"
                      class="btn btn-sm btn-default mr-2"
                      (click)="c('Close click')"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-light-primary mr-2"
                      (click)="submitResultUpdate(true)"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      (click)="submitResultUpdate(false)"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

              <div *ngIf="modalType === 'cultureReportsModal'">
                <form
                  [formGroup]="cultureReportsForm"
                  (ngSubmit)="submitCultureReport(false)"
                >
                  <div class="">
                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">Specimen</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              (click)="addFieldsInCulture('specimen')"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                                ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="
                            cultureReportsForm.value.culture_specimen.length > 0
                          "
                        >
                          <table class="table table-condensed table-hover">
                            <thead>
                              <tr>
                                <th>Specimen Name</th>
                                <th>Result</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                formArrayName="culture_specimen"
                                *ngFor="
                                  let g of reports_specimen.controls;
                                  let i = index
                                "
                              >
                                <ng-container [formGroupName]="i">
                                  <td style="width: 30%">
                                    <select
                                      class="form-control form-control-sm"
                                      formControlName="name_id"
                                      [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.name_id.errors
                                      }"
                                    >
                                      <option value="">Select</option>
                                      <ng-container
                                        *ngFor="let type of labConfigurations"
                                      >
                                        <option
                                          *ngIf="
                                            type.configurator_type ===
                                            'specimen'
                                          "
                                          [value]="type.id"
                                        >
                                          {{ type.description }}
                                        </option>
                                      </ng-container>
                                    </select>
                                  </td>
                                  <td style="width: 30%">
                                    <select
                                      class="form-control form-control-sm"
                                      formControlName="value"
                                      [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.value.errors
                                      }"
                                    >
                                      <option value="">Select</option>
                                      <option value="Growth">Growth</option>
                                      <option value="No Growth">
                                        No Growth
                                      </option>
                                    </select>
                                  </td>
                                  <td style="width: 40%" class="text-right">
                                    <button
                                      (click)="
                                        removeCultureIndex('specimen', i)
                                      "
                                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                      type="button"
                                    >
                                      <span
                                        class="svg-icon svg-icon-primary svg-icon-2x"
                                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          width="24px"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          version="1.1"
                                        >
                                          <g
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                              fill="#000000"
                                              fill-rule="nonzero"
                                            />
                                            <path
                                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                          </g></svg
                                        ><!--end::Svg Icon--></span
                                      >
                                    </button>
                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">Culture</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              *ngIf="
                                cultureReportsForm.value.culture.length < 3
                              "
                              (click)="addFieldsInCultureWithResistance()"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                                ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="cultureReportsForm.value.culture.length > 0"
                        >
                          <div
                            class="widget margin-bottom-10"
                            *ngFor="
                              let g of reports_culture.controls;
                              let i = index
                            "
                          >
                            <h5 class="margin-0 font-weight-bold">
                              G {{ i + 1 }}
                            </h5>
                            <table class="table table-condensed table-hover">
                              <thead>
                                <tr>
                                  <th>Growth</th>
                                  <th>Culture</th>
                                  <th>Atmosphere</th>
                                  <th>Duration</th>
                                  <th></th>
                                  <th>Temperature</th>
                                  <th>Colony Count</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container formArrayName="culture">
                                  <ng-container [formGroupName]="i">
                                    <tr>
                                      <td style="width: 14%">
                                        <select
                                          class="form-control form-control-sm"
                                          formControlName="growth"
                                          [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.growth.errors
                                          }"
                                        >
                                          <option value="">Select</option>
                                          <!--                            <option value="Yes">Yes</option>-->
                                          <!--                            <option value="No">No</option>-->
                                          <ng-container
                                            *ngFor="let type of cultureList"
                                          >
                                            <option
                                              *ngIf="
                                                type.culture_type ===
                                                'cultures_type'
                                              "
                                              [value]="type.id"
                                            >
                                              {{ type.name }}
                                            </option>
                                          </ng-container>
                                        </select>
                                      </td>
                                      <td style="width: 14%">
                                        <select
                                          class="form-control form-control-sm"
                                          formControlName="culture"
                                          [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.culture.errors
                                          }"
                                        >
                                          <option value="">Select</option>
                                          <ng-container
                                            *ngFor="let type of cultureList"
                                          >
                                            <option
                                              *ngIf="
                                                type.culture_type ===
                                                'culture_name'
                                              "
                                              [value]="type.id"
                                            >
                                              {{ type.name }}
                                            </option>
                                          </ng-container>
                                        </select>
                                      </td>
                                      <td style="width: 14%">
                                        <select
                                          class="form-control form-control-sm"
                                          formControlName="atmosphere"
                                          [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.atmosphere.errors
                                          }"
                                        >
                                          <option value="">Select</option>
                                          <ng-container
                                            *ngFor="let type of cultureList"
                                          >
                                            <option
                                              *ngIf="
                                                type.culture_type ===
                                                'culture_atmosphere'
                                              "
                                              [value]="type.id"
                                            >
                                              {{ type.name }}
                                            </option>
                                          </ng-container>
                                        </select>
                                      </td>
                                      <td style="width: 14%">
                                        <select
                                          class="form-control form-control-sm float-left"
                                          formControlName="duration_type"
                                          [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.duration_type.errors
                                          }"
                                        >
                                          <option value="Minutes">
                                            Minutes
                                          </option>
                                          <option value="Hours">Hours</option>
                                          <option value="Days">Days</option>
                                          <option value="Weeks">Weeks</option>
                                        </select>
                                      </td>
                                      <td style="width: 14%">
                                        <input
                                          type="text"
                                          (keydown)="isNumberKey($event)"
                                          class="form-control form-control-sm float-left"
                                          placeholder="Value"
                                          formControlName="duration_value"
                                          [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.duration_value.errors
                                          }"
                                        />
                                      </td>
                                      <td style="width: 14%">
                                        <select
                                          class="form-control form-control-sm"
                                          formControlName="temperature"
                                          [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.temperature.errors
                                          }"
                                        >
                                          <option value="">Select</option>
                                          <ng-container
                                            *ngFor="let type of cultureList"
                                          >
                                            <option
                                              *ngIf="
                                                type.culture_type ===
                                                'culture_temperature'
                                              "
                                              [value]="type.id"
                                            >
                                              {{ type.name }}
                                            </option>
                                          </ng-container>
                                        </select>
                                      </td>
                                      <td style="width: 14%">
                                        <input
                                          type="text"
                                          class="form-control form-control-sm"
                                          placeholder="Colony Count"
                                          formControlName="colony_count"
                                          [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.colony_count.errors
                                          }"
                                        />
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <!--                        <td  style="width: 15%">-->
                                      <!--                          <input type="text" class="form-control form-control-sm" placeholder="Colony Count" formControlName="colony_count" [ngClass]="{ 'is-invalid': cultureReportSubmitted && g.controls.colony_count.errors }">-->
                                      <!--                        </td>-->
                                      <td colspan="6" style="width: 90%">
                                        <input
                                          type="text"
                                          class="form-control form-control-sm"
                                          placeholder="Details"
                                          formControlName="comments"
                                          [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.comments.errors
                                          }"
                                        />
                                      </td>
                                      <td style="width: 10%" class="text-right">
                                        <button
                                          (click)="
                                            removeCultureIndexWithResistance(i)
                                          "
                                          class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                          type="button"
                                        >
                                          <span
                                            class="svg-icon svg-icon-primary svg-icon-2x"
                                            ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlns:xlink="http://www.w3.org/1999/xlink"
                                              width="24px"
                                              height="24px"
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                              >
                                                <rect
                                                  x="0"
                                                  y="0"
                                                  width="24"
                                                  height="24"
                                                />
                                                <path
                                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                  fill="#000000"
                                                  fill-rule="nonzero"
                                                />
                                                <path
                                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                  fill="#000000"
                                                  opacity="0.3"
                                                />
                                              </g></svg
                                            ><!--end::Svg Icon--></span
                                          >
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">Sensitivity</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              [disabled]="disableBtn"
                              (click)="addFieldsInCulture('sensitivity')"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                                ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="
                            cultureReportsForm.value.sensitivity.length > 0
                          "
                        >
                          <table class="table table-condensed table-hover">
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Medicine</th>
                                <ng-container
                                  formArrayName="culture"
                                  *ngFor="
                                    let g of reports_culture.controls;
                                    let i = index
                                  "
                                >
                                  <ng-container [formGroupName]="i">
                                    <th>G{{ i + 1 }}</th>
                                  </ng-container>
                                </ng-container>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                formArrayName="sensitivity"
                                *ngFor="
                                  let sensitivity of reports_sensitivity.controls;
                                  let i = index
                                "
                              >
                                <ng-container [formGroupName]="i">
                                  <td>
                                    <select
                                      class="form-control form-control-sm"
                                      formControlName="type"
                                      (change)="
                                        updateSensitivityType(
                                          $event.target.value,
                                          i
                                        )
                                      "
                                      [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          sensitivity.controls.type.errors
                                      }"
                                    >
                                      <option value="">Select</option>
                                      <ng-container
                                        *ngFor="
                                          let type of cultureListSensitivity
                                        "
                                      >
                                        <!--                          <option *ngIf="type.culture_type === 'sensitivity_type'" [value]="type.id">{{type.name}}</option>-->
                                        <option [value]="type.id">
                                          {{ type.name }}
                                        </option>
                                      </ng-container>
                                    </select>
                                  </td>
                                  <td>
                                    <select
                                      class="form-control form-control-sm"
                                      formControlName="group"
                                      [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          sensitivity.controls.group.errors
                                      }"
                                    >
                                      <option value="">Select</option>
                                      <ng-container
                                        *ngFor="
                                          let type of cultureGroupArray[i]
                                        "
                                      >
                                        <!--                          <option *ngIf="type.culture_type === 'sensitivity_group'" [value]="type.id">{{type.name}}</option>-->
                                        <option [value]="type.id">
                                          {{ type.name }}
                                        </option>
                                      </ng-container>
                                    </select>
                                  </td>

                                  <td
                                    formArrayName="sensitivity_resistance"
                                    *ngFor="
                                      let resistance of sensitivity.get(
                                        'sensitivity_resistance'
                                      ).controls;
                                      let j = index
                                    "
                                  >
                                    <ng-container [formGroupName]="j">
                                      <select
                                        class="form-control form-control-sm"
                                        formControlName="type"
                                        [ngClass]="{
                                          'is-invalid':
                                            cultureReportSubmitted &&
                                            resistance.controls.type.errors
                                        }"
                                      >
                                        <option value="">Select</option>
                                        <ng-container
                                          *ngFor="let type of cultureList"
                                        >
                                          <option
                                            *ngIf="
                                              type.culture_type ===
                                              'sensitivity_resistance_ir'
                                            "
                                            [value]="type.id"
                                          >
                                            {{ type.name }}
                                          </option>
                                        </ng-container>
                                      </select>
                                    </ng-container>
                                  </td>

                                  <!--                  <td>-->
                                  <!--                    <select class="form-control form-control-sm" formControlName="sensitivity_resistance" [ngClass]="{ 'is-invalid': cultureReportSubmitted && sensitivity.controls.sensitivity_resistance.errors }">-->
                                  <!--                      <option value="">Select</option>-->
                                  <!--                      <ng-container *ngFor="let type of cultureList">-->
                                  <!--                        <option *ngIf="type.culture_type === 'sensitivity_resistance_ir'" [value]="type.id">{{type.name}}</option>-->
                                  <!--                      </ng-container>-->
                                  <!--                    </select>-->
                                  <!--                  </td>-->
                                  <!--                  -->
                                  <td class="text-right">
                                    <button
                                      (click)="
                                        removeCultureIndex('sensitivity', i)
                                      "
                                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                      type="button"
                                    >
                                      <span
                                        class="svg-icon svg-icon-primary svg-icon-2x"
                                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          width="24px"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          version="1.1"
                                        >
                                          <g
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                              fill="#000000"
                                              fill-rule="nonzero"
                                            />
                                            <path
                                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                          </g></svg
                                        ><!--end::Svg Icon--></span
                                      >
                                    </button>
                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div
                          class="clearfix"
                          *ngIf="
                            cultureReportsForm.value.reports_comment.length > 0
                          "
                        >
                          <div
                            class="widget"
                            formArrayName="reports_comment"
                            *ngFor="
                              let comments of reports_comment.controls;
                              let i = index
                            "
                            style="margin-bottom: 10px"
                          >
                            <h4 class="margin-0">Reports Comment</h4>
                            <table
                              class="table table-condensed table-hover mb-0"
                            >
                              <thead>
                                <tr>
                                  <th>Heading G{{ i + 1 }}</th>
                                  <th>Details</th>
                                  <!--                  <th></th>-->
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <ng-container [formGroupName]="i">
                                    <td>
                                      <select
                                        class="form-control form-control-sm"
                                        formControlName="heading"
                                        (change)="
                                          updateFreeHeadingText(
                                            $event.target.value,
                                            'reports_comment',
                                            i
                                          )
                                        "
                                        [ngClass]="{
                                          'is-invalid':
                                            cultureReportSubmitted &&
                                            comments.controls.heading.errors
                                        }"
                                      >
                                        <option value="">Select</option>
                                        <ng-container
                                          *ngFor="let type of cultureList"
                                        >
                                          <option
                                            *ngIf="
                                              type.culture_type ===
                                              'report_comments_heading'
                                            "
                                            [value]="type.id"
                                          >
                                            {{ type.name }}
                                          </option>
                                        </ng-container>
                                      </select>
                                    </td>
                                    <td colspan="4">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        formControlName="comments"
                                        [ngClass]="{
                                          'is-invalid':
                                            cultureReportSubmitted &&
                                            comments.controls.comments.errors
                                        }"
                                      />
                                    </td>
                                    <!--                    <td class="text-right">-->
                                    <!--                      <span style="font-size: 18px" class="pointer" (click)="removeCultureIndex('reports_comment', i)"><i class="fa fa-remove"></i></span>-->
                                    <!--                    </td>-->
                                  </ng-container>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">Gram Stain</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              (click)="addFieldsInCulture('gram_stain')"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                                ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="cultureReportsForm.value.gram_stain.length > 0"
                        >
                          <table class="table table-condensed table-hover">
                            <thead>
                              <tr>
                                <th>Result</th>
                                <th>Details</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                formArrayName="gram_stain"
                                *ngFor="
                                  let g of reports_gram_stain.controls;
                                  let i = index
                                "
                              >
                                <ng-container [formGroupName]="i">
                                  <td style="width: 30%">
                                    <select
                                      class="form-control form-control-sm"
                                      formControlName="heading"
                                      (change)="
                                        updateFreeHeadingText(
                                          $event.target.value,
                                          'gram_stain',
                                          i
                                        )
                                      "
                                      [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.heading.errors
                                      }"
                                    >
                                      <option value="">Select</option>
                                      <ng-container
                                        *ngFor="let type of cultureList"
                                      >
                                        <option
                                          *ngIf="
                                            type.culture_type ===
                                            'free_fields_heading'
                                          "
                                          [value]="type.id"
                                        >
                                          {{ type.name }}
                                        </option>
                                      </ng-container>
                                    </select>
                                  </td>
                                  <td style="width: 60%">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      formControlName="comments"
                                      [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.comments.errors
                                      }"
                                    />
                                  </td>
                                  <td style="width: 10%" class="text-right">
                                    <button
                                      (click)="
                                        removeCultureIndex('gram_stain', i)
                                      "
                                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                      type="button"
                                    >
                                      <span
                                        class="svg-icon svg-icon-primary svg-icon-2x"
                                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          width="24px"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          version="1.1"
                                        >
                                          <g
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                              fill="#000000"
                                              fill-rule="nonzero"
                                            />
                                            <path
                                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                          </g></svg
                                        ><!--end::Svg Icon--></span
                                      >
                                    </button>
                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">ZN Stain</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              (click)="addFieldsInCulture('zn_stain')"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                                ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="cultureReportsForm.value.zn_stain.length > 0"
                        >
                          <table class="table table-condensed table-hover">
                            <thead>
                              <tr>
                                <th>Result</th>
                                <th>Details</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                formArrayName="zn_stain"
                                *ngFor="
                                  let g of reports_zn_stain.controls;
                                  let i = index
                                "
                              >
                                <ng-container [formGroupName]="i">
                                  <td style="width: 30%">
                                    <select
                                      class="form-control form-control-sm"
                                      formControlName="heading"
                                      (change)="
                                        updateFreeHeadingText(
                                          $event.target.value,
                                          'zn_stain',
                                          i
                                        )
                                      "
                                      [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.heading.errors
                                      }"
                                    >
                                      <option value="">Select</option>
                                      <ng-container
                                        *ngFor="let type of cultureList"
                                      >
                                        <option
                                          *ngIf="
                                            type.culture_type ===
                                            'free_fields_zn_stain'
                                          "
                                          [value]="type.id"
                                        >
                                          {{ type.name }}
                                        </option>
                                      </ng-container>
                                    </select>
                                  </td>
                                  <td style="width: 60%">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      formControlName="comments"
                                      [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.comments.errors
                                      }"
                                    />
                                  </td>
                                  <td style="width: 10%" class="text-right">
                                    <button
                                      (click)="
                                        removeCultureIndex('zn_stain', i)
                                      "
                                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                      type="button"
                                    >
                                      <span
                                        class="svg-icon svg-icon-primary svg-icon-2x"
                                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          width="24px"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          version="1.1"
                                        >
                                          <g
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                              fill="#000000"
                                              fill-rule="nonzero"
                                            />
                                            <path
                                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                          </g></svg
                                        ><!--end::Svg Icon--></span
                                      >
                                    </button>
                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">Fungal Stain</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              (click)="addFieldsInCulture('fungal_stain')"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                                ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="
                            cultureReportsForm.value.fungal_stain.length > 0
                          "
                        >
                          <table class="table table-condensed table-hover">
                            <thead>
                              <tr>
                                <th>Result</th>
                                <th>Details</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                formArrayName="fungal_stain"
                                *ngFor="
                                  let g of reports_fungal_stain.controls;
                                  let i = index
                                "
                              >
                                <ng-container [formGroupName]="i">
                                  <td style="width: 30%">
                                    <select
                                      class="form-control form-control-sm"
                                      formControlName="heading"
                                      (change)="
                                        updateFreeHeadingText(
                                          $event.target.value,
                                          'fungal_stain',
                                          i
                                        )
                                      "
                                      [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.heading.errors
                                      }"
                                    >
                                      <option value="">Select</option>
                                      <ng-container
                                        *ngFor="let type of cultureList"
                                      >
                                        <option
                                          *ngIf="
                                            type.culture_type ===
                                            'free_fields_fungal_stain'
                                          "
                                          [value]="type.id"
                                        >
                                          {{ type.name }}
                                        </option>
                                      </ng-container>
                                    </select>
                                  </td>
                                  <td style="width: 60%">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      formControlName="comments"
                                      [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.comments.errors
                                      }"
                                    />
                                  </td>
                                  <td style="width: 10%" class="text-right">
                                    <button
                                      (click)="
                                        removeCultureIndex('fungal_stain', i)
                                      "
                                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                      type="button"
                                    >
                                      <span
                                        class="svg-icon svg-icon-primary svg-icon-2x"
                                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          width="24px"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          version="1.1"
                                        >
                                          <g
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                              fill="#000000"
                                              fill-rule="nonzero"
                                            />
                                            <path
                                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                          </g></svg
                                        ><!--end::Svg Icon--></span
                                      >
                                    </button>
                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="clearfix">
                          <div class="col-xs-12 p-lr-0">
                            <h4 class="margin-0">Remarks</h4>
                          </div>
                        </div>
                        <div class="clearfix">
                          <table class="table table-striped margin-bottom-0">
                            <thead>
                              <tr>
                                <th style="width: 20%">Remarks</th>
                                <th style="width: 80%">Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <select
                                    class="form-control form-control-sm"
                                    (change)="
                                      updatePathologistRemarks(
                                        $event.target.value
                                      )
                                    "
                                  >
                                    <option value="">Select</option>
                                    <ng-container
                                      *ngFor="
                                        let comments of activeLabEntry?.pathologist_remarks
                                      "
                                    >
                                      <option value="{{ comments.remarks }}">
                                        {{ comments.remarks }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    [value]="pathologistRemarks"
                                    id="pathologistRemarks"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="card card-custom mb-5">
                  <div class="card-body text-right">
                    <button
                      type="button"
                      class="btn btn-sm btn-default mr-2"
                      (click)="c('Close click')"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-light-primary mr-2"
                      (click)="submitCultureReport(true)"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      (click)="submitCultureReport(false)"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #reportsModalUpdate let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">{{ activeLabEntry?.test }} update</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div
            class="card card-custom mb-5"
            *ngFor="let reports of reportTypeUpdate"
          >
            <div class="card-body">
              <div class="clearfix">
                <div
                  class="col-xs-12 p-lr-0"
                  *ngIf="
                    reports.name !== 'null' && reports.name !== 'undefined'
                  "
                >
                  <h4 class="margin-0">{{ reports.name }}</h4>
                </div>
              </div>
              <div class="clearfix">
                <table class="table table-striped margin-bottom-0">
                  <thead>
                    <tr>
                      <th style="width: 20%">Name</th>
                      <th style="width: 10%">Uom</th>
                      <th style="width: 10%">specimen</th>
                      <th style="width: 10%">Range</th>
                      <th style="width: 10%">Comments</th>
                      <th style="width: 20%">Value</th>
                      <th style="width: 20%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let report of reports.value">
                      <td>{{ report.caption }}</td>
                      <td>{{ report.unit }}</td>
                      <td>{{ report.specimen }}</td>
                      <td>{{ report.range || "--" }}</td>
                      <td><span [innerHTML]="report.range_comments"></span></td>
                      <!--              <td  style="width: 20%"><input type="number" class="form-control form-control-sm" [(ngModel)]="report.value"></td>-->

                      <td *ngIf="report?.report_type === 'single'">
                        <input
                          type="text"
                          (keydown)="isNumberKey($event)"
                          style="max-width: 100%"
                          placeholder="number"
                          class="form-control form-control-sm"
                          [(ngModel)]="report.value"
                        />
                      </td>
                      <td *ngIf="report?.report_type === 'single'"></td>

                      <td colspan="2" *ngIf="report?.report_type === 'text'">
                        <input
                          type="text"
                          placeholder="text"
                          class="form-control form-control-sm"
                          [(ngModel)]="report.heading"
                        />
                      </td>

                      <td *ngIf="report?.report_type === 'combo'">
                        <span *ngIf="report?.report_type_1 === 'text'">
                          <input
                            type="text"
                            style="max-width: 100%"
                            placeholder="text"
                            class="form-control form-control-sm"
                            [(ngModel)]="report.report_type_1_value"
                          />
                        </span>
                        <span *ngIf="report?.report_type_1 === 'decimal'">
                          <input
                            type="text"
                            (keydown)="isNumberKey($event)"
                            style="max-width: 100%"
                            placeholder="number"
                            class="form-control form-control-sm"
                            [(ngModel)]="report.report_type_1_value"
                          />
                        </span>
                        <span *ngIf="report?.report_type_1 === 'dropdown'">
                          <select
                            class="form-control form-control-sm"
                            style="max-width: 100%"
                            [(ngModel)]="report.report_type_1_value"
                          >
                            <option value=""></option>
                            <option
                              *ngFor="let val of report?.report_type_1_result"
                              [value]="val"
                            >
                              {{ val }}
                            </option>
                          </select>
                        </span>
                      </td>
                      <td
                        *ngIf="
                          report?.report_type === 'combo' &&
                            report?.report_type_2 !== '';
                          else notShowContent1
                        "
                      >
                        <span *ngIf="report?.report_type_2 === 'text'">
                          <input
                            type="text"
                            style="max-width: 100%"
                            placeholder="text"
                            class="form-control form-control-sm"
                            [(ngModel)]="report.report_type_2_value"
                          />
                        </span>
                        <span *ngIf="report?.report_type_2 === 'decimal'">
                          <input
                            type="text"
                            (keydown)="isNumberKey($event)"
                            style="max-width: 100%"
                            placeholder="number"
                            class="form-control form-control-sm"
                            [(ngModel)]="report.report_type_2_value"
                          />
                        </span>
                        <span *ngIf="report?.report_type_2 === 'dropdown'">
                          <select
                            class="form-control form-control-sm"
                            style="max-width: 100%"
                            [(ngModel)]="report.report_type_2_value"
                          >
                            <option value=""></option>
                            <option
                              *ngFor="let val of report?.report_type_2_result"
                              [value]="val"
                            >
                              {{ val }}
                            </option>
                          </select>
                        </span>
                      </td>
                      <ng-template #notShowContent1>
                        <td></td>
                      </ng-template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card card-custom mb-5">
            <div class="card-body">
              <div class="clearfix">
                <div class="col-xs-12 p-lr-0">
                  <h4 class="margin-0">Remarks</h4>
                </div>
              </div>
              <div class="clearfix">
                <table class="table table-striped margin-bottom-0">
                  <thead>
                    <tr>
                      <th style="width: 20%">Remarks</th>
                      <th style="width: 80%">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <select
                          class="form-control form-control-sm"
                          (change)="
                            updatePathologistRemarks($event.target.value)
                          "
                        >
                          <option value="">Select</option>
                          <ng-container
                            *ngFor="
                              let comments of activeLabEntry?.pathologist_remarks
                            "
                          >
                            <option value="{{ comments.remarks }}">
                              {{ comments.remarks }}
                            </option>
                          </ng-container>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          [(ngModel)]="pathologistRemarks"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="submitResultUpdate(true)"
          >
            Save as Draft
          </button>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="submitResultUpdate(false)"
          >
            Submit
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #cultureReportsModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">{{ activeLabEntry?.test }}</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form
          [formGroup]="cultureReportsForm"
          (ngSubmit)="submitCultureReport(false)"
        >
          <div class="modal-body">
            <div class="">
              <div class="card card-custom mb-5">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 p-lr-0">
                      <h4 class="margin-0">Specimen</h4>
                    </div>
                    <div class="col-4 p-lr-0 text-right">
                      <button
                        (click)="addFieldsInCulture('specimen')"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                        type="button"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                              <path
                                d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                fill="#000000"
                              />
                            </g></svg
                          ><!--end::Svg Icon-->
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="clearfix"
                    *ngIf="cultureReportsForm.value.culture_specimen.length > 0"
                  >
                    <table class="table table-condensed table-hover">
                      <thead>
                        <tr>
                          <th>Specimen Name</th>
                          <th>Result</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          formArrayName="culture_specimen"
                          *ngFor="
                            let g of reports_specimen.controls;
                            let i = index
                          "
                        >
                          <ng-container [formGroupName]="i">
                            <td style="width: 30%">
                              <select
                                class="form-control form-control-sm"
                                formControlName="name_id"
                                [ngClass]="{
                                  'is-invalid':
                                    cultureReportSubmitted &&
                                    g.controls.name_id.errors
                                }"
                              >
                                <option value="">Select</option>
                                <ng-container
                                  *ngFor="let type of labConfigurations"
                                >
                                  <option
                                    *ngIf="
                                      type.configurator_type === 'specimen'
                                    "
                                    [value]="type.id"
                                  >
                                    {{ type.description }}
                                  </option>
                                </ng-container>
                              </select>
                            </td>
                            <td style="width: 30%">
                              <select
                                class="form-control form-control-sm"
                                formControlName="value"
                                [ngClass]="{
                                  'is-invalid':
                                    cultureReportSubmitted &&
                                    g.controls.value.errors
                                }"
                              >
                                <option value="">Select</option>
                                <option value="Growth">Growth</option>
                                <option value="No Growth">No Growth</option>
                              </select>
                            </td>
                            <td style="width: 40%" class="text-right">
                              <button
                                (click)="removeCultureIndex('specimen', i)"
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                type="button"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                        fill="#000000"
                                        fill-rule="nonzero"
                                      />
                                      <path
                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                    </g></svg
                                  ><!--end::Svg Icon--></span
                                >
                              </button>
                            </td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="card card-custom mb-5">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 p-lr-0">
                      <h4 class="margin-0">Culture</h4>
                    </div>
                    <div class="col-4 p-lr-0 text-right">
                      <button
                        *ngIf="cultureReportsForm.value.culture.length < 3"
                        (click)="addFieldsInCultureWithResistance()"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                        type="button"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                              <path
                                d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                fill="#000000"
                              />
                            </g></svg
                          ><!--end::Svg Icon-->
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="clearfix"
                    *ngIf="cultureReportsForm.value.culture.length > 0"
                  >
                    <div
                      class="widget margin-bottom-10"
                      *ngFor="let g of reports_culture.controls; let i = index"
                    >
                      <h5 class="margin-0 font-weight-bold">G {{ i + 1 }}</h5>
                      <table class="table table-condensed table-hover">
                        <thead>
                          <tr>
                            <th>Growth</th>
                            <th>Culture</th>
                            <th>Atmosphere</th>
                            <th>Duration</th>
                            <th></th>
                            <th>Temperature</th>
                            <th>Colony Count</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container formArrayName="culture">
                            <ng-container [formGroupName]="i">
                              <tr>
                                <td style="width: 14%">
                                  <select
                                    class="form-control form-control-sm"
                                    formControlName="growth"
                                    [ngClass]="{
                                      'is-invalid':
                                        cultureReportSubmitted &&
                                        g.controls.growth.errors
                                    }"
                                  >
                                    <option value="">Select</option>
                                    <!--                            <option value="Yes">Yes</option>-->
                                    <!--                            <option value="No">No</option>-->
                                    <ng-container
                                      *ngFor="let type of cultureList"
                                    >
                                      <option
                                        *ngIf="
                                          type.culture_type === 'cultures_type'
                                        "
                                        [value]="type.id"
                                      >
                                        {{ type.name }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </td>
                                <td style="width: 14%">
                                  <select
                                    class="form-control form-control-sm"
                                    formControlName="culture"
                                    [ngClass]="{
                                      'is-invalid':
                                        cultureReportSubmitted &&
                                        g.controls.culture.errors
                                    }"
                                  >
                                    <option value="">Select</option>
                                    <ng-container
                                      *ngFor="let type of cultureList"
                                    >
                                      <option
                                        *ngIf="
                                          type.culture_type === 'culture_name'
                                        "
                                        [value]="type.id"
                                      >
                                        {{ type.name }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </td>
                                <td style="width: 14%">
                                  <select
                                    class="form-control form-control-sm"
                                    formControlName="atmosphere"
                                    [ngClass]="{
                                      'is-invalid':
                                        cultureReportSubmitted &&
                                        g.controls.atmosphere.errors
                                    }"
                                  >
                                    <option value="">Select</option>
                                    <ng-container
                                      *ngFor="let type of cultureList"
                                    >
                                      <option
                                        *ngIf="
                                          type.culture_type ===
                                          'culture_atmosphere'
                                        "
                                        [value]="type.id"
                                      >
                                        {{ type.name }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </td>
                                <td style="width: 14%">
                                  <select
                                    class="form-control form-control-sm float-left"
                                    formControlName="duration_type"
                                    [ngClass]="{
                                      'is-invalid':
                                        cultureReportSubmitted &&
                                        g.controls.duration_type.errors
                                    }"
                                  >
                                    <option value="Minutes">Minutes</option>
                                    <option value="Hours">Hours</option>
                                    <option value="Days">Days</option>
                                    <option value="Weeks">Weeks</option>
                                  </select>
                                </td>
                                <td style="width: 14%">
                                  <input
                                    type="text"
                                    (keydown)="isNumberKey($event)"
                                    class="form-control form-control-sm float-left"
                                    placeholder="Value"
                                    formControlName="duration_value"
                                    [ngClass]="{
                                      'is-invalid':
                                        cultureReportSubmitted &&
                                        g.controls.duration_value.errors
                                    }"
                                  />
                                </td>
                                <td style="width: 14%">
                                  <select
                                    class="form-control form-control-sm"
                                    formControlName="temperature"
                                    [ngClass]="{
                                      'is-invalid':
                                        cultureReportSubmitted &&
                                        g.controls.temperature.errors
                                    }"
                                  >
                                    <option value="">Select</option>
                                    <ng-container
                                      *ngFor="let type of cultureList"
                                    >
                                      <option
                                        *ngIf="
                                          type.culture_type ===
                                          'culture_temperature'
                                        "
                                        [value]="type.id"
                                      >
                                        {{ type.name }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </td>
                                <td style="width: 14%">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="Colony Count"
                                    formControlName="colony_count"
                                    [ngClass]="{
                                      'is-invalid':
                                        cultureReportSubmitted &&
                                        g.controls.colony_count.errors
                                    }"
                                  />
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <!--                        <td  style="width: 15%">-->
                                <!--                          <input type="text" class="form-control form-control-sm" placeholder="Colony Count" formControlName="colony_count" [ngClass]="{ 'is-invalid': cultureReportSubmitted && g.controls.colony_count.errors }">-->
                                <!--                        </td>-->
                                <td colspan="6" style="width: 90%">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="Details"
                                    formControlName="comments"
                                    [ngClass]="{
                                      'is-invalid':
                                        cultureReportSubmitted &&
                                        g.controls.comments.errors
                                    }"
                                  />
                                </td>
                                <td style="width: 10%" class="text-right">
                                  <button
                                    (click)="
                                      removeCultureIndexWithResistance(i)
                                    "
                                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                    type="button"
                                  >
                                    <span
                                      class="svg-icon svg-icon-primary svg-icon-2x"
                                      ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          stroke-width="1"
                                          fill="none"
                                          fill-rule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          />
                                          <path
                                            d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                            fill="#000000"
                                            fill-rule="nonzero"
                                          />
                                          <path
                                            d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                          />
                                        </g></svg
                                      ><!--end::Svg Icon--></span
                                    >
                                  </button>
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card card-custom mb-5">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 p-lr-0">
                      <h4 class="margin-0">Sensitivity</h4>
                    </div>
                    <div class="col-4 p-lr-0 text-right">
                      <button
                        [disabled]="disableBtn"
                        (click)="addFieldsInCulture('sensitivity')"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                        type="button"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                              <path
                                d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                fill="#000000"
                              />
                            </g></svg
                          ><!--end::Svg Icon-->
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="clearfix"
                    *ngIf="cultureReportsForm.value.sensitivity.length > 0"
                  >
                    <table class="table table-condensed table-hover">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Medicine</th>
                          <ng-container
                            formArrayName="culture"
                            *ngFor="
                              let g of reports_culture.controls;
                              let i = index
                            "
                          >
                            <ng-container [formGroupName]="i">
                              <th>G{{ i + 1 }}</th>
                            </ng-container>
                          </ng-container>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          formArrayName="sensitivity"
                          *ngFor="
                            let sensitivity of reports_sensitivity.controls;
                            let i = index
                          "
                        >
                          <ng-container [formGroupName]="i">
                            <td>
                              <select
                                class="form-control form-control-sm"
                                formControlName="type"
                                (change)="
                                  updateSensitivityType($event.target.value, i)
                                "
                                [ngClass]="{
                                  'is-invalid':
                                    cultureReportSubmitted &&
                                    sensitivity.controls.type.errors
                                }"
                              >
                                <option value="">Select</option>
                                <ng-container
                                  *ngFor="let type of cultureListSensitivity"
                                >
                                  <!--                          <option *ngIf="type.culture_type === 'sensitivity_type'" [value]="type.id">{{type.name}}</option>-->
                                  <option [value]="type.id">
                                    {{ type.name }}
                                  </option>
                                </ng-container>
                              </select>
                            </td>
                            <td>
                              <select
                                class="form-control form-control-sm"
                                formControlName="group"
                                [ngClass]="{
                                  'is-invalid':
                                    cultureReportSubmitted &&
                                    sensitivity.controls.group.errors
                                }"
                              >
                                <option value="">Select</option>
                                <ng-container
                                  *ngFor="let type of cultureGroupArray[i]"
                                >
                                  <!--                          <option *ngIf="type.culture_type === 'sensitivity_group'" [value]="type.id">{{type.name}}</option>-->
                                  <option [value]="type.id">
                                    {{ type.name }}
                                  </option>
                                </ng-container>
                              </select>
                            </td>

                            <td
                              formArrayName="sensitivity_resistance"
                              *ngFor="
                                let resistance of sensitivity.get(
                                  'sensitivity_resistance'
                                ).controls;
                                let j = index
                              "
                            >
                              <ng-container [formGroupName]="j">
                                <select
                                  class="form-control form-control-sm"
                                  formControlName="type"
                                  [ngClass]="{
                                    'is-invalid':
                                      cultureReportSubmitted &&
                                      resistance.controls.type.errors
                                  }"
                                >
                                  <option value="">Select</option>
                                  <ng-container
                                    *ngFor="let type of cultureList"
                                  >
                                    <option
                                      *ngIf="
                                        type.culture_type ===
                                        'sensitivity_resistance_ir'
                                      "
                                      [value]="type.id"
                                    >
                                      {{ type.name }}
                                    </option>
                                  </ng-container>
                                </select>
                              </ng-container>
                            </td>

                            <!--                  <td>-->
                            <!--                    <select class="form-control form-control-sm" formControlName="sensitivity_resistance" [ngClass]="{ 'is-invalid': cultureReportSubmitted && sensitivity.controls.sensitivity_resistance.errors }">-->
                            <!--                      <option value="">Select</option>-->
                            <!--                      <ng-container *ngFor="let type of cultureList">-->
                            <!--                        <option *ngIf="type.culture_type === 'sensitivity_resistance_ir'" [value]="type.id">{{type.name}}</option>-->
                            <!--                      </ng-container>-->
                            <!--                    </select>-->
                            <!--                  </td>-->
                            <!--                  -->
                            <td class="text-right">
                              <button
                                (click)="removeCultureIndex('sensitivity', i)"
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                type="button"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                        fill="#000000"
                                        fill-rule="nonzero"
                                      />
                                      <path
                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                    </g></svg
                                  ><!--end::Svg Icon--></span
                                >
                              </button>
                            </td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="card card-custom mb-5">
                <div class="card-body">
                  <div
                    class="clearfix"
                    *ngIf="cultureReportsForm.value.reports_comment.length > 0"
                  >
                    <div
                      class="widget"
                      formArrayName="reports_comment"
                      *ngFor="
                        let comments of reports_comment.controls;
                        let i = index
                      "
                      style="margin-bottom: 10px"
                    >
                      <h4 class="margin-0">Reports Comment</h4>
                      <table class="table table-condensed table-hover mb-0">
                        <thead>
                          <tr>
                            <th>Heading G{{ i + 1 }}</th>
                            <th>Details</th>
                            <!--                  <th></th>-->
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <ng-container [formGroupName]="i">
                              <td>
                                <select
                                  class="form-control form-control-sm"
                                  formControlName="heading"
                                  (change)="
                                    updateFreeHeadingText(
                                      $event.target.value,
                                      'reports_comment',
                                      i
                                    )
                                  "
                                  [ngClass]="{
                                    'is-invalid':
                                      cultureReportSubmitted &&
                                      comments.controls.heading.errors
                                  }"
                                >
                                  <option value="">Select</option>
                                  <ng-container
                                    *ngFor="let type of cultureList"
                                  >
                                    <option
                                      *ngIf="
                                        type.culture_type ===
                                        'report_comments_heading'
                                      "
                                      [value]="type.id"
                                    >
                                      {{ type.name }}
                                    </option>
                                  </ng-container>
                                </select>
                              </td>
                              <td colspan="4">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  formControlName="comments"
                                  [ngClass]="{
                                    'is-invalid':
                                      cultureReportSubmitted &&
                                      comments.controls.comments.errors
                                  }"
                                />
                              </td>
                              <!--                    <td class="text-right">-->
                              <!--                      <span style="font-size: 18px" class="pointer" (click)="removeCultureIndex('reports_comment', i)"><i class="fa fa-remove"></i></span>-->
                              <!--                    </td>-->
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card card-custom mb-5">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 p-lr-0">
                      <h4 class="margin-0">Gram Stain</h4>
                    </div>
                    <div class="col-4 p-lr-0 text-right">
                      <button
                        (click)="addFieldsInCulture('gram_stain')"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                        type="button"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                              <path
                                d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                fill="#000000"
                              />
                            </g></svg
                          ><!--end::Svg Icon-->
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="clearfix"
                    *ngIf="cultureReportsForm.value.gram_stain.length > 0"
                  >
                    <table class="table table-condensed table-hover">
                      <thead>
                        <tr>
                          <th>Result</th>
                          <th>Details</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          formArrayName="gram_stain"
                          *ngFor="
                            let g of reports_gram_stain.controls;
                            let i = index
                          "
                        >
                          <ng-container [formGroupName]="i">
                            <td style="width: 30%">
                              <select
                                class="form-control form-control-sm"
                                formControlName="heading"
                                (change)="
                                  updateFreeHeadingText(
                                    $event.target.value,
                                    'gram_stain',
                                    i
                                  )
                                "
                                [ngClass]="{
                                  'is-invalid':
                                    cultureReportSubmitted &&
                                    g.controls.heading.errors
                                }"
                              >
                                <option value="">Select</option>
                                <ng-container *ngFor="let type of cultureList">
                                  <option
                                    *ngIf="
                                      type.culture_type ===
                                      'free_fields_heading'
                                    "
                                    [value]="type.id"
                                  >
                                    {{ type.name }}
                                  </option>
                                </ng-container>
                              </select>
                            </td>
                            <td style="width: 60%">
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                formControlName="comments"
                                [ngClass]="{
                                  'is-invalid':
                                    cultureReportSubmitted &&
                                    g.controls.comments.errors
                                }"
                              />
                            </td>
                            <td style="width: 10%" class="text-right">
                              <button
                                (click)="removeCultureIndex('gram_stain', i)"
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                type="button"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                        fill="#000000"
                                        fill-rule="nonzero"
                                      />
                                      <path
                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                    </g></svg
                                  ><!--end::Svg Icon--></span
                                >
                              </button>
                            </td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="card card-custom mb-5">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 p-lr-0">
                      <h4 class="margin-0">ZN Stain</h4>
                    </div>
                    <div class="col-4 p-lr-0 text-right">
                      <button
                        (click)="addFieldsInCulture('zn_stain')"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                        type="button"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                              <path
                                d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                fill="#000000"
                              />
                            </g></svg
                          ><!--end::Svg Icon-->
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="clearfix"
                    *ngIf="cultureReportsForm.value.zn_stain.length > 0"
                  >
                    <table class="table table-condensed table-hover">
                      <thead>
                        <tr>
                          <th>Result</th>
                          <th>Details</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          formArrayName="zn_stain"
                          *ngFor="
                            let g of reports_zn_stain.controls;
                            let i = index
                          "
                        >
                          <ng-container [formGroupName]="i">
                            <td style="width: 30%">
                              <select
                                class="form-control form-control-sm"
                                formControlName="heading"
                                (change)="
                                  updateFreeHeadingText(
                                    $event.target.value,
                                    'zn_stain',
                                    i
                                  )
                                "
                                [ngClass]="{
                                  'is-invalid':
                                    cultureReportSubmitted &&
                                    g.controls.heading.errors
                                }"
                              >
                                <option value="">Select</option>
                                <ng-container *ngFor="let type of cultureList">
                                  <option
                                    *ngIf="
                                      type.culture_type ===
                                      'free_fields_zn_stain'
                                    "
                                    [value]="type.id"
                                  >
                                    {{ type.name }}
                                  </option>
                                </ng-container>
                              </select>
                            </td>
                            <td style="width: 60%">
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                formControlName="comments"
                                [ngClass]="{
                                  'is-invalid':
                                    cultureReportSubmitted &&
                                    g.controls.comments.errors
                                }"
                              />
                            </td>
                            <td style="width: 10%" class="text-right">
                              <button
                                (click)="removeCultureIndex('zn_stain', i)"
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                type="button"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                        fill="#000000"
                                        fill-rule="nonzero"
                                      />
                                      <path
                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                    </g></svg
                                  ><!--end::Svg Icon--></span
                                >
                              </button>
                            </td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="card card-custom mb-5">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 p-lr-0">
                      <h4 class="margin-0">Fungal Stain</h4>
                    </div>
                    <div class="col-4 p-lr-0 text-right">
                      <button
                        (click)="addFieldsInCulture('fungal_stain')"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                        type="button"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                              <path
                                d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                fill="#000000"
                              />
                            </g></svg
                          ><!--end::Svg Icon-->
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="clearfix"
                    *ngIf="cultureReportsForm.value.fungal_stain.length > 0"
                  >
                    <table class="table table-condensed table-hover">
                      <thead>
                        <tr>
                          <th>Result</th>
                          <th>Details</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          formArrayName="fungal_stain"
                          *ngFor="
                            let g of reports_fungal_stain.controls;
                            let i = index
                          "
                        >
                          <ng-container [formGroupName]="i">
                            <td style="width: 30%">
                              <select
                                class="form-control form-control-sm"
                                formControlName="heading"
                                (change)="
                                  updateFreeHeadingText(
                                    $event.target.value,
                                    'fungal_stain',
                                    i
                                  )
                                "
                                [ngClass]="{
                                  'is-invalid':
                                    cultureReportSubmitted &&
                                    g.controls.heading.errors
                                }"
                              >
                                <option value="">Select</option>
                                <ng-container *ngFor="let type of cultureList">
                                  <option
                                    *ngIf="
                                      type.culture_type ===
                                      'free_fields_fungal_stain'
                                    "
                                    [value]="type.id"
                                  >
                                    {{ type.name }}
                                  </option>
                                </ng-container>
                              </select>
                            </td>
                            <td style="width: 60%">
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                formControlName="comments"
                                [ngClass]="{
                                  'is-invalid':
                                    cultureReportSubmitted &&
                                    g.controls.comments.errors
                                }"
                              />
                            </td>
                            <td style="width: 10%" class="text-right">
                              <button
                                (click)="removeCultureIndex('fungal_stain', i)"
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                type="button"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                        fill="#000000"
                                        fill-rule="nonzero"
                                      />
                                      <path
                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                    </g></svg
                                  ><!--end::Svg Icon--></span
                                >
                              </button>
                            </td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="card card-custom mb-5">
                <div class="card-body">
                  <div class="clearfix">
                    <div class="col-xs-12 p-lr-0">
                      <h4 class="margin-0">Remarks</h4>
                    </div>
                  </div>
                  <div class="clearfix">
                    <table class="table table-striped margin-bottom-0">
                      <thead>
                        <tr>
                          <th style="width: 20%">Remarks</th>
                          <th style="width: 80%">Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <select
                              class="form-control form-control-sm"
                              (change)="
                                updatePathologistRemarks($event.target.value)
                              "
                            >
                              <option value="">Select</option>
                              <ng-container
                                *ngFor="
                                  let comments of activeLabEntry?.pathologist_remarks
                                "
                              >
                                <option value="{{ comments.remarks }}">
                                  {{ comments.remarks }}
                                </option>
                              </ng-container>
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              [value]="pathologistRemarks"
                              id="pathologistRemarks"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="submitCultureReport(true)"
          >
            Save as Draft
          </button>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="submitCultureReport(false)"
          >
            Submit
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
