import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class StockService {
  constructor(private http: HttpClient) {}

  // searchProduct(type, query): Observable<any>{
  //   const results = this.http.get(`${environment.rorUrl}/v3/items/search?auth_token=`
  //     + localStorage.getItem('auth_token') + '&item_type=' + type + '&query=' + query
  //     , { headers: new HttpHeaders({
  //         AuthenticationToken: localStorage.getItem('auth_token'),
  //         Accept: 'application/json'
  //       })}).pipe(map(data => data));
  //   return results;
  // }
  searchProduct(type, query): Observable<any> {
    return this.http
      .post(
        `${environment.rorUrl}/v4/items/search`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          item_type: type,
          query: query,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  opdEmrProductSearch(type, query): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/opd/patient_medications/opd_search_items_control_wise/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&item_type=' +
          type +
          '&query=' +
          query,
        // {
        //   auth_token: localStorage.getItem('auth_token'),
        //   medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        //   item_type: type,
        //   query: query,
        // },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  searchProductv2(type, query): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/opd/search/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          item_type: type,
          query: query,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  searchIssuanceProductV2(type, query, genericNameOfMedicine): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/opd/search/search_product_by_generic_name/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          item_type: type,
          query: query,
          generic_name: genericNameOfMedicine,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  searchItemsListv2(type, query, isConsumable): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/search_item/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&item_type=' +
          type +
          '&query=' +
          query +
          '&is_consumable_item=' +
          isConsumable,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchItemsListForIpdDischargeTab(
    type,
    query,
    isConsumable,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/search_item/search_item_without_control/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&item_type=' +
          type +
          '&query=' +
          query +
          '&is_consumable_item=' +
          isConsumable,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchGeneric(query): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v3/generic_items/search?auth_token=` +
          localStorage.getItem('auth_token') +
          '&device_id=fb56574625fba22c&query=' +
          query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addStockFun(items): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/items`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          item: items,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchProductsByGroup(group): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v3/items/items_by_group?auth_token=` +
          localStorage.getItem('auth_token') +
          '&device_id=fb56574625fba22c&group=' +
          group +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchProductsByGroupV2(group): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/items_by_group?auth_token=` +
          localStorage.getItem('auth_token') +
          '&device_id=fb56574625fba22c&group=' +
          group +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
