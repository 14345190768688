import { Injectable } from '@angular/core';
import { BehaviorSubject, ObjectUnsubscribedError, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmployeeSelfServiceService {
  constructor(private http: HttpClient) {}

  private currentStateOfLeave = new BehaviorSubject('');
  currentLeaveStatus = this.currentStateOfLeave.asObservable();

  currentStateLeave(data): any {
    this.currentStateOfLeave.next(data);
  }
  submitleaveRequest(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/get_leave?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeavePreviousHistory(page, per_page, search_string): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveRecentHistory(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_leave?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getLeaveHistory(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_user_leave_history?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveRequestListing(
    page,
    per_page,
    search_string,
    status,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_leave_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getCurrentUserLeaveRequestListing(
    page,
    per_page,
    search_string,
    status,
    show_all,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string +
          '&status=' +
          status +
          '&show_all=' +
          show_all,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateLeaveStatus(cancelRequestId, status): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/change_status?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          cancelRequestId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          status,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoginRoles(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/roles?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitJobApplication(value: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/job_application_form?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getJobApplicationListing(page, per_page, search_string): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/job_application_form?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addMedicalCertificate(value, employeeId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/employee_medical_certificate?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getMedicalFitnessDetail(employeeId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_medical_certificate?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // submitTermsAndConditions(code, title): Observable<any> {
  //   const results = this.http.post(`${environment.pythonUrl}/hr/term_and_condition_configurator?auth_token=` + localStorage.getItem('auth_token'), {
  //     medical_unit_id: localStorage.getItem('current_medical_unit_id'),
  //     code , title
  //   }, {
  //     headers: new HttpHeaders({
  //       //'AuthenticationToken': localStorage.getItem('auth_token'),
  //       'Accept': 'application/json'
  //     })
  //   }).pipe(map(data => data));
  //   return results;
  // }
  addConsentTitle(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/consent_form?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getConsentTitleList(typeValue): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/consent_form?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          typeValue,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getJobApplicantData(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/job_application_form_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateJobApplication(value, jobApplicantId): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/job_application_form_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          jobApplicantId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addHeadthQuestionaire(value, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/health_question?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getHeadthQuestionaire(jobApplicantId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/health_question?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          jobApplicantId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateConsentTitle(value, id, type) {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/consent_form_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id +
          '&type=' +
          type,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitTermsData(value, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/consent_template?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTermsData(updateId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/consent_template?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          updateId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getManagerDataForCurrentUser(is_self): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave_setting_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&is_self=' +
          is_self,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getManagerDataForUser(is_self, managerId) {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave_setting_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&is_self=' +
          is_self +
          '&id=' +
          managerId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveRecentApproveHistory(employeeId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_recent_leave?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateLeaveStatusValue(
    cancelRequestId,
    status,
    hr_comments,
    hr_start_date,
    hr_end_date,
    hr_approve_days,
    is_deduction,
    deduction_days,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/change_status?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          cancelRequestId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          status,
          hr_comments,
          hr_start_date,
          hr_end_date,
          hr_approve_days,
          is_deduction,
          deduction_days,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveDetail(leaveId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          leaveId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveReport(
    start_date,
    end_date,
    department,
    id,
    status,
    type,
    page,
    per_page,
    req_type,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&department=' +
          department +
          '&id=' +
          id +
          '&status=' +
          status +
          '&type=' +
          type +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&req_type=' +
          req_type,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  checkLeaveStatus(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/check_leave_status?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // API for leave status NEW.
  newCheckLeaveStatus(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/check_leave_status_new?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  approveLeaveRequest(
    remarks,
    status,
    is_external,
    level_number,
    is_internal,
    id,
    deduction_type,
    deduction_days,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/change_leave_approve_status?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          remarks,
          status,
          is_external,
          level_number,
          is_internal,
          id,
          deduction_type,
          deduction_days,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeLeaveStatusGmHr(
    remarks,
    status,
    is_external,
    level_number,
    is_internal,
    id,
    hr_start_date,
    hr_end_date,
    hr_approve_days,
    deduction_type,
    deduction_days,
    full_half_day,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/change_leave_status_gm_hr?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          remarks,
          status,
          is_external,
          level_number,
          is_internal,
          id,
          hr_start_date,
          hr_end_date,
          hr_approve_days,
          deduction_type,
          deduction_days,
          full_half_day,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  directChangeLeaveStatusGmHr(
    remarks,
    status,
    is_external,
    level_number,
    is_internal,
    id,
    hr_start_date,
    hr_end_date,
    hr_approve_days,
    deduction_type,
    deduction_days,
    full_half_day,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/direct_leave_approval?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          remarks,
          status,
          is_external,
          level_number,
          is_internal,
          id,
          hr_start_date,
          hr_end_date,
          hr_approve_days,
          deduction_type,
          deduction_days,
          full_half_day,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveRequestForApproval(page, per_page, employee): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave_request_for_approval?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&employee=' +
          employee,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  DirectLeaveRequestForApproval(page, per_page, employee): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_all_in_process_leave?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&employee=' +
          employee,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveRequestApprovalDetail(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave_request_approval_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  directleaveApproval(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_direct_leave_approval_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitRosterSettings(
    employee,
    location_list,
    department_list,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/employee_roster_settings?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          employee,
          location_list,
          department_list,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRosterSettings(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_roster_settings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveTypeActive(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveAdjustment(employee, department): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/adjust_leave?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&employee=' +
          employee +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitLeaveAdjustment(value): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/adjust_leave?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPreviousLeaveHistoryRecord(
    page,
    per_page,
    filter,
    employee,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave_history?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&status=' +
          filter +
          '&employee=' +
          employee,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPreviousLoanHistoryRecord(page, per_page, filter): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_loan_history?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&status=' +
          filter,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveAPprovedRequestsForReversal(
    page: any,
    per_page: any,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/leave_listing?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveStatusWiseForReversal(
    page: any,
    per_page: any,
    status: any,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/leave_listing?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitLeaveReversalDates(dates, leave_id, reason): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/hr/leave_listing?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          dates,
          leave_id,
          reason,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
