import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class TechAcknowledgementService {
  constructor(private http: HttpClient) {}
  index(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_services/investigation?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchRadiologyServicesByAck(
    patient_nic,
    query,
    accession_number,
    departmentId,
    type,
    urgency,
    current_page: any,
    per_page: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_services/tech_acknowledgement?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&accession_number=' +
          accession_number +
          '&department_id=' +
          departmentId +
          '&type=' +
          type +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&patient_nic=' +
          patient_nic +
          '&urgency=' +
          urgency,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchRadiologyServicesByAckV2(
    patient_nic,
    query,
    accession_number,
    departmentId,
    type,
    urgency,
    fromDate,
    toDate,
    current_page: any,
    per_page: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/tech_acknowledgement/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&accession_number=' +
          accession_number +
          '&department_id=' +
          departmentId +
          '&type=' +
          type +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&patient_nic=' +
          patient_nic +
          '&urgency=' +
          urgency +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchRadiologyServices(
    current_page: any,
    per_page: any,
    query,
    isAcknowledged,
    departmentId,
    type,
    fromDate,
    toDate,
    page_url,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_services/search?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&is_acknowledged=' +
          isAcknowledged +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&department_id=' +
          departmentId +
          '&type=' +
          type +
          '&page_url=' +
          page_url +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchRadiologyWritingServices(
    current_page: any,
    per_page: any,
    query,
    isAcknowledged,
    departmentId,
    type,
    fromDate,
    toDate,
    page_url,
    patientType,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_services/search?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&is_acknowledged=' +
          isAcknowledged +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&department_id=' +
          departmentId +
          '&type=' +
          type +
          '&page_url=' +
          page_url +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate +
          '&result_type=' +
          patientType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchRadiologyWritingServicesV2(
    current_page: any,
    per_page: any,
    query,
    isAcknowledged,
    departmentId,
    type,
    fromDate,
    toDate,
    page_url,
    patientType,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/search_radiology_writing/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&is_acknowledged=' +
          isAcknowledged +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&department_id=' +
          departmentId +
          '&type=' +
          type +
          '&page_url=' +
          page_url +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate +
          '&result_type=' +
          patientType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  acknowledge(ids, history, value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services/acknowledge?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          ids,
          history,
          value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  acknowledgeV2(ids, history, value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/acknowledge/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          ids,
          history,
          value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  add_history(ids: any, history_text: string): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services/add_history?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          ids,
          history: history_text,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getDoctors(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/hospital_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  changeDoctor(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services/change_doctor?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchFinalizedReports(
    patient_id,
    cnic: string,
    start_date: string,
    end_date: string,
  ): Observable<any> {
    const results = this.http
      .get(
        environment.rorUrl +
          '/v4/patient_services/search_finalized_investigation?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_id +
          '&cnic=' +
          cnic +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchFinalizedReportsV2(
    patient_id,
    cnic: string,
    start_date: string,
    end_date: string,
  ): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/search_finalized_investigation/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_id +
          '&cnic=' +
          cnic +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addendum(report_id: number, text: string): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/reports/addendum_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          id: report_id,
          text,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addendumV2(report_id: number, text: string): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/addendum_report/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          id: report_id,
          text,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
