import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {RouteShareService} from '../../../../../services/route-share.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {HospitalService} from '../../../../../services/hospital.service';
import {InventoryGeneralSettingsService} from '../../../../../services/inventory-general-settings.service';
import {RosterServiceService} from '../../../../../services/roster-service.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-auto-late-in-deduction',
  templateUrl: './auto-late-in-deduction.component.html',
  styleUrls: ['./auto-late-in-deduction.component.css']
})
export class AutoLateInDeductionComponent implements OnInit {
  screenName = '';
  settingData: any;
  stateOfHeader: any;
  reportData: any;
  constructor(    private routeService: RouteShareService,
                  private ngxLoader: NgxUiLoaderService,
                  private hospitalService: HospitalService,
                  private inventoryControlService: InventoryGeneralSettingsService,
                  private RosterService: RosterServiceService,
                  private ref: ChangeDetectorRef
  ) {
    this.screenName = this.routeService.getValue();
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getInventorySysControl(): any {
    this.ngxLoader.start();
    this.inventoryControlService.getInventorySystemControls().subscribe(
      (data) => {
        this.stateOfHeader = data.report_header_alignment;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  searchReport(): any {
    // this.startDate = $('.start_date').val();
    // this.endDate = $('.end_date').val();
    this.ngxLoader.start();
    this.RosterService.searchAutoLateInDeduction().subscribe(
      (data) => {
        this.reportData = data?.employees;
        if (this.reportData.length) {
          this.reportData.forEach((employee) => {
            employee.late_in = false;
          });
          // this.totalPages = data.meta.total_count;
          this.ref.markForCheck();
          // this.getListDataCSV();
          // this.getCSV();
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  submit(): any{
    const arrayForUpdate = [];
    for (let i = 0; i < this.reportData.length; i++) {
      if ($('#late_in' + i).prop('checked') === true) {
        arrayForUpdate.push(this.reportData[i]);
      }
    }
    if (!arrayForUpdate.length){
      toastr.error('Select Record');
      return;
    }
    this.ngxLoader.start();
    this.RosterService.submitAutoLateInDeduction(arrayForUpdate).subscribe(
      (data) => {
        toastr.success('Submit Successfully');
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  ngOnInit(): void {
    this.getSettingsData();
    this.getInventorySysControl();
    this.searchReport();
  }

}
