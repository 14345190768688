<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">{{ screenName }}</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card card-custom">
        <div class="card-body">
          <form [formGroup]="searchFilterForm" (ngSubmit)="searchInvoices()">
            <div class="row">
              <div class="col-md-3 col-sm-3 col-4">
                <div class="form-group">
                  <label>From Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="fromDate"
                    data-toggle="datetimepicker"
                    data-target="#fromDate"
                  />
                </div>
              </div>

              <div class="col-md-3 col-sm-3 col-4">
                <div class="form-group">
                  <label>To Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="toDate"
                    data-target="#toDate"
                    data-toggle="datetimepicker"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <label>MRN</label>
                <div class="form-group">
                  <div class="d-block">
                    <input
                      class="form-control form-control-sm"
                      formControlName="patient_id"
                    />
                  </div>
                </div>
              </div>

              <!--add button-->
              <div class="col-md-2 col-sm-3 col-4 text-left">
                <div class="form-group">
                  <div class="d-block">
                    <button
                      type="submit"
                      class="btn btn-primary btn-sm cus_btm"
                      ngbTooltip="Search Result"
                      placement="bottom"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card card-custom gutter-b mt-7">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Table-->

          <div>
            <div class="table-responsive position-relative">
              <table
                class="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_3"
              >
                <thead>
                  <tr>
                    <th>Date/Time</th>
                    <th>Patient Name</th>
                    <th>MRN#</th>
                    <th>Dialysis Number</th>
                    <th>Return</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let element of pageDataList; let i = index"
                  >
                    <tr>
                      <td>
                        {{ element.created_at | date: "dd-MM-yyyy h:mm a" }}
                      </td>
                      <td>{{ element.patient.full_name }}</td>
                      <td>{{ element.patient.mrn }}</td>
                      <td>{{ element.dialysis_number }}</td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-light-primary btn-sm font-weight-bolder"
                          (click)="confirmreport(element.id)"
                          style="padding: 2px 5px; margin: 2px 5px"
                        >
                          Return
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-light-primary btn-sm font-weight-bolder"
                          (click)="printReport(element.id)"
                          style="padding: 2px 5px; margin: 2px 5px"
                        >
                          Print
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
            <!--pagination-->
            <div class="pagination_main">
              <ngb-pagination
                [(page)]="page"
                (pageChange)="loadPage(page)"
                [pageSize]="perPage"
                [collectionSize]="totalPages"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              ></ngb-pagination>
              <div class="dropdown">
                <a
                  class="btn dropdown-toggle btn-light btn-sm"
                  role="button"
                  id="pagination_btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="page_count">
                    {{ perPage }}
                  </span>
                  <span class="count_count_icon"></span>
                </a>
                <div class="dropdown-menu" aria-labelledby="pagination_btn">
                  <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                    >25</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                    >50</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                    >75</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                    >100</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit('')"
                    >All</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</div>

<!--Confirmation Modal-->
<div id="confirmationModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="checkbox-inline">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="Checkbox1"
                  [(ngModel)]="isConfimed"
                />
                <span></span>
              </label>
              Are you sure you want to return this request !
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          Close
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          (click)="confirmDialysis()"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
