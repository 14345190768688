import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDateConversion',
})
export class CustomDateConversionPipe implements PipeTransform {
  transform(value): any {
    let data = value.split(' ')[0].substr(0, 3);
    return data + ' ' + value.split(' ')[1] + ' ' + value.split(' ')[2];
  }
}
