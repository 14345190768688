import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlascoService {
  constructor(private http: HttpClient) {}
  addType(value, user): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/glasco_coma_type/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit: localStorage.getItem('current_medical_unit_id'),
          user,
          value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getTypeList(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/glasco_coma_type/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTypeupdateList(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/glasco_coma_type/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getSubTypeList(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/glasco_coma_sub_type/` +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addSubType(value, user, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/glasco_coma_sub_type/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit: localStorage.getItem('current_medical_unit_id'),
          user,
          value,
          glasco_coma_sub_id: id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSubTypeupdateList(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/glasco_coma_sub_type/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateType(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/glasco_coma_type/` +
          id +
          '/?&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateSubType(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/glasco_coma_sub_type/` +
          id +
          '/?&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getGlascoList(date, patient, visit): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/glasco_coma_scale_assessment/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&date=' +
          date +
          '&patient=' +
          patient +
          '&visit=' +
          visit,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addGlascoData(value, sub_type, visit, patient, date): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/glasco_coma_form/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&date=' +
          date,
        {
          medical_unit: localStorage.getItem('current_medical_unit_id'),
          shift: value.shift,
          employee_id: value.employe_id,
          password: value.password,
          sub_type,
          visit,
          patient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getGlascoData(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/xyz/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
