<div>
  <div class="card mb-4">
    <div class="card-body user-card-body">
      <div class="vital-table" style="overflow-x: auto">
        <table class="mb-0" style="min-width: 970px">
          <thead>
            <tr>
              <td
                style="
                  min-width: 130px;
                  max-width: 130px;
                  width: 130px;
                  min-height: 52px;
                "
                class="text-muted"
              >
                <strong>Parameter</strong>
              </td>
              <td
                style="min-width: 100px; max-width: 100px; width: 100px"
                class="text-muted text-center"
              >
                <strong *ngIf="isAdd === true">Value</strong>
              </td>
              <th *ngIf="vitalsListData.length === 0" style="height: 52px"></th>
              <td
                style="min-width: 100px; max-width: 100px"
                class="text-center vital-table-th"
                *ngFor="let history of vitalsListData; let i = index"
              >
                <span
                  ><strong class="d-block">{{
                    history?.created_at | date: "dd-MM-yyyy"
                  }}</strong></span
                ><span>{{ history?.created_at | date: "h:mm a" }}</span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style="min-width: 130px; max-width: 130px; width: 130px">
                <span class="d-block">Weight</span
                ><span class="small-label">Kg</span>
              </th>
              <td style="min-width: 100px; max-width: 100px; width: 100px">
                <input
                  *ngIf="isAdd === true"
                  class="form-control form-control-sm text-center"
                  type="text"
                  [(ngModel)]="vitalsData.weight"
                  placeholder="1..250"
                  (keydown)="isNumberKey($event)"
                  (input)="bmiCalculate()"
                />
              </td>
              <td *ngIf="vitalsListData.length === 0"></td>
              <td
                class="text-center"
                *ngFor="let history of vitalsListData; let i = index"
              >
                <span>{{ history?.weight }}</span>
              </td>
            </tr>
            <tr>
              <th style="min-width: 130px; max-width: 130px; width: 130px">
                <span class="d-block">Height</span
                ><span class="small-label">cm</span>
              </th>
              <td style="min-width: 100px; max-width: 100px; width: 100px">
                <input
                  *ngIf="isAdd === true"
                  class="form-control form-control-sm text-center"
                  type="text"
                  [(ngModel)]="vitalsData.height"
                  placeholder="38..240"
                  (keydown)="isNumberKey($event)"
                  (input)="bmiCalculate()"
                />
              </td>
              <td *ngIf="vitalsListData.length === 0"></td>
              <td
                class="text-center"
                *ngFor="let history of vitalsListData; let i = index"
              >
                <span>{{ history?.height }}</span>
              </td>
            </tr>
            <tr>
              <th style="min-width: 130px; max-width: 130px; width: 130px">
                <span class="d-block">BMI</span
                ><span class="small-label">kg/m^2</span>
              </th>
              <td style="min-width: 100px; max-width: 100px; width: 100px">
                <input
                  *ngIf="isAdd === true"
                  class="form-control form-control-sm text-center"
                  disabled
                  [(ngModel)]="vitalsData.bmi"
                  type="text"
                />
              </td>
              <td *ngIf="vitalsListData.length === 0"></td>
              <td
                class="text-center"
                *ngFor="let history of vitalsListData; let i = index"
              >
                <span>{{ history?.bmi }}</span>
              </td>
            </tr>
            <tr>
              <th style="min-width: 130px; max-width: 130px; width: 130px">
                <span class="d-block">BP-Systolic</span
                ><span class="small-label">mm/hg</span>
              </th>
              <td style="min-width: 100px; max-width: 100px; width: 100px">
                <input
                  *ngIf="isAdd === true"
                  class="form-control form-control-sm text-center"
                  [(ngModel)]="vitalsData.bp_systolic"
                  (keydown)="isNumberKey($event)"
                  (keypress)="_onlyNumeric($event)"
                  placeholder="50..300"
                  (input)="bmiCalculate()"
                />
              </td>
              <td *ngIf="vitalsListData.length === 0"></td>
              <td
                class="text-center"
                *ngFor="let history of vitalsListData; let i = index"
              >
                <span>{{ history?.bp_systolic }}</span>
              </td>
            </tr>
            <tr>
              <th style="min-width: 130px; max-width: 130px; width: 130px">
                <span class="d-block">BP-Diastolic</span
                ><span class="small-label">mm/hg</span>
              </th>
              <td style="min-width: 100px; max-width: 100px; width: 100px">
                <input
                  *ngIf="isAdd === true"
                  class="form-control form-control-sm text-center"
                  [(ngModel)]="vitalsData.bp_diastolic"
                  (keydown)="isNumberKey($event)"
                  (keypress)="_onlyNumeric($event)"
                  placeholder="0..150"
                />
              </td>
              <td *ngIf="vitalsListData.length === 0"></td>
              <td
                class="text-center"
                *ngFor="let history of vitalsListData; let i = index"
              >
                <span>{{ history?.bp_diastolic }}</span>
              </td>
            </tr>
            <tr>
              <th style="min-width: 130px; max-width: 130px; width: 130px">
                <span class="d-block font-weight-bolder">Pulse</span
                ><span class="small-label">/min</span>
              </th>
              <td style="min-width: 100px; max-width: 100px; width: 100px">
                <input
                  *ngIf="isAdd === true"
                  class="form-control form-control-sm text-center"
                  [(ngModel)]="vitalsData.pulse"
                  (keydown)="isNumberKey($event)"
                  (keypress)="_onlyNumeric($event)"
                  type="text"
                  placeholder="40..300"
                />
              </td>
              <td *ngIf="vitalsListData.length === 0"></td>
              <td
                class="text-center"
                *ngFor="let history of vitalsListData; let i = index"
              >
                <span>{{ history?.pulse }}</span>
              </td>
            </tr>
            <tr>
              <th style="min-width: 130px; max-width: 130px; width: 130px">
                <span class="d-block">Resp.Rate</span
                ><span class="small-label">/min</span>
              </th>
              <td style="min-width: 100px; max-width: 100px; width: 100px">
                <input
                  *ngIf="isAdd === true"
                  class="form-control form-control-sm text-center"
                  [(ngModel)]="vitalsData.resp_rate"
                  (keydown)="isNumberKey($event)"
                  (keypress)="_onlyNumeric($event)"
                  type="text"
                  placeholder="12..120"
                />
              </td>
              <td *ngIf="vitalsListData.length === 0"></td>
              <td
                class="text-center"
                *ngFor="let history of vitalsListData; let i = index"
              >
                <span>{{ history?.resp_rate }}</span>
              </td>
            </tr>
            <tr>
              <th style="min-width: 130px; max-width: 130px; width: 130px">
                <span class="d-block">Temperature</span
                ><span class="small-label">F</span>
              </th>
              <td style="min-width: 100px; max-width: 100px; width: 100px">
                <input
                  *ngIf="isAdd === true"
                  class="form-control form-control-sm text-center"
                  [(ngModel)]="vitalsData.temperature"
                  (keydown)="isNumberKey($event)"
                  type="text"
                  placeholder="95..107"
                />
              </td>
              <td *ngIf="vitalsListData.length === 0"></td>
              <td
                class="text-center"
                *ngFor="let history of vitalsListData; let i = index"
              >
                <span>{{ history?.temperature }}</span>
              </td>
            </tr>
            <tr>
              <th style="min-width: 130px; max-width: 130px; width: 130px">
                <span class="d-block">SPO2</span
                ><span class="small-label">%</span>
              </th>
              <td style="min-width: 100px; max-width: 100px; width: 100px">
                <input
                  *ngIf="isAdd === true"
                  class="form-control form-control-sm text-center"
                  [(ngModel)]="vitalsData.o2_saturation"
                  (keydown)="isNumberKey($event)"
                  (keypress)="_onlyNumeric($event)"
                  type="text"
                  placeholder="0..100"
                />
              </td>
              <td *ngIf="vitalsListData.length === 0"></td>
              <td
                class="text-center"
                *ngFor="let history of vitalsListData; let i = index"
              >
                <span>{{ history?.o2_saturation }}</span>
              </td>
            </tr>
            <tr>
              <th style="min-width: 130px; max-width: 130px; width: 130px">
                <span class="d-block">BSR</span
                ><span class="small-label">mg/dl</span>
              </th>
              <td style="min-width: 100px; max-width: 100px; width: 100px">
                <input
                  *ngIf="isAdd === true"
                  placeholder="1..9999"
                  class="form-control form-control-sm text-center"
                  [(ngModel)]="vitalsData.bsr"
                  type="text"
                />
              </td>
              <td *ngIf="vitalsListData.length === 0"></td>
              <td
                class="text-center"
                *ngFor="let history of vitalsListData; let i = index"
              >
                <span>{{ history?.bsr }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-12 text-right mt-4">
        <div class="dropdown dropdown-inline" *ngIf="vitalsListData.length > 0">
          <button
            type="button"
            class="btn btn-light-primary btn-icon btn-sm"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-print"></i>
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item cursor-pointer" (click)="printVitals(false)"
              >Current Visit</a
            >
            <a class="dropdown-item cursor-pointer" (click)="printVitals(true)"
              >History</a
            >
          </div>
        </div>
        <button
          class="btn btn-primary btn-sm ml-2"
          *ngIf="isAdd === true"
          [disabled]="disableAddVitalsBtn"
          (click)="addVitals('submit')"
        >
          Submit
        </button>
      </div>
    </div>
  </div>

  <!-- Charts -->
  <div class="card mb-4" *ngIf="vitalsListData.length > 0">
    <div class="card-body user-card-body">
      <h4 class="m-t-0"><b>Temperature</b></h4>
      <canvas id="temperatureChart" width="100" height="30%"></canvas>
    </div>
  </div>

  <div class="card mb-4" *ngIf="vitalsListData.length > 0">
    <div class="card-body user-card-body">
      <h4 class="m-t-0"><b>BP</b></h4>
      <canvas id="bPChart" width="100" height="30%"></canvas>
    </div>
  </div>

  <div class="card mb-4" *ngIf="vitalsListData.length > 0">
    <div class="card-body user-card-body">
      <h4 class="m-t-0"><b>Pulse</b></h4>
      <canvas id="pulseChart" width="100" height="30%"></canvas>
    </div>
  </div>

  <div class="card mb-4" *ngIf="vitalsListData.length > 0">
    <div class="card-body user-card-body">
      <h4 class="m-t-0"><b>Respiration</b></h4>
      <canvas id="respirationChart" width="100" height="30%"></canvas>
    </div>
  </div>
  <!--end::Card-->
</div>

<div class="example-preview">
  <div>
    <ng-template #alertBox let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Message</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">{{ alert_message }}</div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            OK
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
