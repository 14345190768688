import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabTestService } from '../../../services/lab-test.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../../../services/patient.service';
import { OpdService } from '../../../services/opd.service';
import { environment } from '../../../../environments/environment';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-admission-history',
  templateUrl: './admission-history.component.html',
  styleUrls: ['./admission-history.component.css'],
})
export class AdmissionHistoryComponent implements OnInit {
  departments = [];

  investigations = [];
  searchKey: string;

  isSubmit = false;
  screenName = '';
  PatientSearchForm: UntypedFormGroup;
  patient: any;
  patient_full_name: any;
  patient_gender_age: any;
  default_patient_mrn: any;
  rejectedSampleReason = '';
  labTestListing = [];
  patientAdmissions = [];
  constructor(
    private hospitalDoctorsService: HospitaldoctorService,
    private ngxLoader: NgxUiLoaderService,
    private labTestService: LabTestService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private opdService: OpdService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  ngOnInit(): void {
    this.PatientSearchForm = this.formBuilder.group({
      search_query: ['', Validators.required],
      type: [''],
    });

    this.patient_full_name = '';
    this.patient_gender_age = '';
    this.default_patient_mrn = '';
  }

  fetchPatient(): any {
    this.isSubmit = true;
    if (this.PatientSearchForm.invalid) {
      return;
    }
    if (this.route.snapshot.queryParams.patient_id) {
      this.PatientSearchForm.value.search_query =
        this.route.snapshot.queryParams.patient_id;
      this.default_patient_mrn = this.route.snapshot.queryParams.patient_id;
    }
    if (this.PatientSearchForm.value.search_query.length == 8) {
      this.PatientSearchForm.value.type = 'mrn';
      this.ngxLoader.start();
      this.patient = null;
      this.patient_full_name = '';
      this.patient_gender_age = '';
      this.patientAdmissions = [];
      this.patientService
        .searchPatientByType(this.PatientSearchForm.value)
        .subscribe(
          (data) => {
            this.isSubmit = false;
            if (data) {
              this.patient = data.patients[0];
              this.patient_full_name =
                data.patients[0].first_name + ' ' + data.patients[0].last_name;
              this.patient_gender_age =
                data.patients[0].age + '/' + data.patients[0].gender;
              this.getPatientAdmissions();
            }
            this.ngxLoader.stop();
          },
          (err) => {
            this.ngxLoader.stop();
            toastr.error(err.error.message);
          },
        );
    }
  }

  getPatientAdmissions(): any {
    if (this.patient) {
      this.opdService.getPatientAdmission(this.patient).subscribe(
        (data) => {
          this.patientAdmissions = data.admissions;

          if (!this.patientAdmissions) {
            toastr.error(data.message);
          }
        },
        (err) => {
          toastr.error(err.error.message);
        },
      );
    }
  }

  openAdmissionPdf(id): any {
    window.open(
      environment.rorUrl +
        '/v3/discharge_patients/discharge_report.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&patient_id=' +
        this.patient.mrn +
        '&admission_id=' +
        id,
    );
  }

  printIpdInvoicesReport(id): any {
    window.open(
      environment.rorUrl +
        '/v4/patients/' +
        this.patient.mrn +
        '/final_settlements/ipd_invoice.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&admission_id=' +
        id,
    );
  }

  _onlyNumeric(event: any): any {
    if (event.charCode != 46) {
      const pattern = /^\d{1,6}(\.\d{1,2})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }

  get f(): any {
    return this.PatientSearchForm.controls;
  }

  billBeforeSettlement(id, admission): any {
    this.router.navigate(['sale/invoice'], {
      queryParams: { id, admission, status: true },
    });
  }

  billAfterSettlement(id, admission): any {
    this.router.navigate(['sale/invoice'], {
      queryParams: { id, admission, status: false },
    });
  }
  printHswBill(mrn, admission, status): any {
    window.open(
      'sale/hsw-invoice?id=' +
        mrn +
        '&status=' +
        status +
        '&hsw_bill=true' +
        '&admission=' +
        admission,
    );
    setTimeout(() => {
      window.open(
        'sale/hsw-invoice?id=' +
          mrn +
          '&status=' +
          status +
          '&hsw_bill=false' +
          '&admission=' +
          admission,
      );
    }, 1000);
  }
  clearanceCertificate(mrn, visit_id): any {
    const URL =
      '/pdf-reports/clearance-certificate?mrn=' +
      mrn +
      '&visit_id=' +
      visit_id +
      '&id=' +
      '';
    window.open(URL, '_blank');
    // this.router.navigate(['/pdf-reports/clearance-certificate'], { queryParams: { id: mrn } });
    // this.router.navigate(['/pdf-reports/clearance-certificate'], {
    //   queryParams: {
    //     id: '',
    //     mrn,
    //     visit_id,
    //   }
    // });
  }
}
