import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class DepartmentService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v3/wards/get_hospital_wards?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getOverAllDepartmentList(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/departments?type=all&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getOverAllDepartmentListv2(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/departments?type=all&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllDepartmentList(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/departments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllDepartmentListV2(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/departments/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addNewDepartment(items): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/departments`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          department: items,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addNewDepartmentv2(items): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/departments/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          department: items,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDepartment(items): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/departments/update_departments`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          departments: items,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDepartmentv2(items): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/update_department/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          departments: items,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addSubDepartmentFun(item): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/departments`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          department: item,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addSubDepartmentFunv2(item): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/sub_department/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          department: item,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSubDepartmentListFun(department): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/departments/fetch_sub_department?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          department.id +
          '&type=all',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSubDepartmentListFunv2(department): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/sub_department?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          department.id +
          '&type=all',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addPathologistRemarks(remarks, department_id): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/pathologist_remarks`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          remarks,
          department_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  UpdatePathologistRemarks(remarks, department_id): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/pathologist_remarks/update_remarks`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          remarks,
          department_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPathologistRemarks(department_id): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/pathologist_remarks?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          department_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
