import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservedValueOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ProfileService {
  constructor(private http: HttpClient) {}

  resetPass(oldPassword, newPassword): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/users/reset`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          old_password: oldPassword,
          new_password: newPassword,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  resetPassV2(oldPassword, newPassword): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/reset/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          old_password: oldPassword,
          new_password: newPassword,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  forgotPass(newPassword, token): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/users/reset`,
        {
          new_password: newPassword,
          t_token: token,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  forgotPassV2(newPassword, token): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/reset_password/`,
        {
          new_password: newPassword,
          t_token: token,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  verifyToken(token): Observable<any> {
    const results = this.http
      .get(`${environment.rorUrl}/v4/users/check_temp_token?t_token=` + token, {
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
    return results;
  }

  verifyTokenV2(token): Observable<any> {
    const results = this.http
      .get(`${environment.pythonUrl}/api/check_temp_token/?t_token=` + token, {
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
    return results;
  }

  updateUserPassword(userObj, password): Observable<any> {
    return this.http
      .put(
        `${environment.rorUrl}/v4/users/update_password/`,
        {
          user_id: userObj.userId,
          password_token: userObj.authToken,
          new_password: password,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
}
