import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { FooterComponent } from './footer/footer.component';
import { SystemControlsService } from '../services/system-controls.service';
import { StoreManagementService } from '../services/store-management.service';
import { FormsModule } from '@angular/forms';
import {TaskManagementModule} from '../pages/task-management/task-management.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FirebaseMessagingService} from '../services/firebase-messaging.service';

@NgModule({
  declarations: [
    TopbarComponent,
    SidebarComponent,
    LayoutComponent,
    MobileHeaderComponent,
    FooterComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, TaskManagementModule, NgbModule],
  providers: [SystemControlsService, StoreManagementService, FirebaseMessagingService],
})
export class LayoutsModule {}
