import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VisitingEmployeeConfigurationRoutingModule } from './visiting-employee-configuration-routing.module';
import { AddEmployeeVisitComponent } from './add-employee-visit/add-employee-visit.component';
import { EmployeeVisitingListComponent } from './employee-visiting-list/employee-visiting-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfiguratorsService } from '../../../services/configurators.service';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { EmployeeVisitService } from '../../../services/employee-visit.service';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FinanceConfigurationService } from '../../../services/finance-configuration.service';
import { ConfigurationService } from '../../../services/configuration.service';

@NgModule({
  declarations: [AddEmployeeVisitComponent, EmployeeVisitingListComponent],
  imports: [
    CommonModule,
    VisitingEmployeeConfigurationRoutingModule,
    ReactiveFormsModule,
    NgbPaginationModule,
  ],
  providers: [
    ConfiguratorsService,
    DepartmentsbyhospitalService,
    EmployeeVisitService,
    FinanceConfigurationService,
    ConfigurationService,
  ],
})
export class VisitingEmployeeConfigurationModule {}
