<div>
  <div class="card mb-4">
    <div class="card-body user-card-body">
      <div class="table-responsive">
        <table
          class="table table-head-custom table-vertical-center"
          style="table-layout: fixed"
        >
          <thead>
            <tr>
              <th style="width: 20%">Admission Id</th>
              <th style="width: 30%">Admission Date / Time</th>
              <th style="width: 30%">Discharge Date / Time</th>
              <th style="width: 20%" class="text-center">Report</th>
            </tr>
          </thead>
          <tbody *ngIf="visitTypes.length > 0">
            <ng-container *ngFor="let type of visitTypes">
              <tr>
                <td>
                  <span>{{ type?.admission_id }}</span>
                </td>
                <td>
                  <span class="">{{
                    type?.created_at | date: "dd-MM-yyyy h:mm a"
                  }}</span>
                </td>
                <td>
                  <span class="" *ngIf="type.is_returned === false">{{
                    type?.discharge_date_time | date: "dd-MM-yyyy h:mm a"
                  }}</span>
                </td>
                <td class="clickable text-center cursor-pointer">
                  <button
                    class="btn btn-icon btn-light-primary btn-sm mr-2"
                    (click)="printAdmissionSlip(type?.id)"
                  >
                    <i class="flaticon-technology"></i>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
