import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment/moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientInOutTakeService } from '../../../../services/patient-in-out-take.service';
import { StockService } from '../../../../services/stock.service';
import { HospitalService } from '../../../../services/hospital.service';
import { formatDate } from '@angular/common';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-intake-output-chart',
  templateUrl: './intake-output-chart.component.html',
  styleUrls: ['./intake-output-chart.component.css'],
})
export class IntakeOutputChartComponent implements OnInit {
  @Input() patient: any;
  @Input() visitType: any;
  @Input() isAdd: boolean;

  InOutTakeChartForm: UntypedFormGroup;

  isSubmitInOutTake = false;
  InTakeSubGroupItems = [];
  patientInTakes = [];
  patientOutTakes = [];
  todayInTakeQuantity: any[];
  todayOutTakeQuantity: any[];
  totalInTakeQtyType: any[];
  totalOutTakeQtyType: any[];
  todayInTakeQtyTotal: any;
  todayOutTakeQtyTotal: any;
  givenDate: any;
  InTakeItems = [];
  OutTakeItems = [];
  methods = [];
  settingData: any;
  currentDate = new Date();
  summaryDate: any;
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private patientInOutTakeService: PatientInOutTakeService,
    private stockService: StockService,
    private hospitalService: HospitalService,
  ) {}

  ngOnInit(): void {
    this.summaryDate = this.today;
    this.InOutTakeChartForm = this.formBuilder.group({
      time: ['', Validators.required],
      taken_time: ['', Validators.required],
      in_take_data: this.formBuilder.array([]),
      out_take_data: this.formBuilder.array([]),
    });
    this.getSettingsData();
    this.resetInOutTakeForm('');
    this.updateDate(this.today);
    this.getInOutTakes('');
  }

  ngOnChanges(changes: SimpleChanges): any {
    this.getInOutTakes(changes.patient.currentValue);
    this.fetchOutTakeItems(changes.patient.currentValue);
    this.fetchInTakeItems(changes.patient.currentValue);
    this.resetInOutTakeForm(changes.patient.currentValue);
  }

  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetailsv2().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {},
    );
  }

  fetchInTakeItems(val): any {
    this.InTakeItems = [];
    this.stockService.fetchProductsByGroupV2('In-Take').subscribe(
      (data) => {
        this.InTakeItems = data.items;
        if (data.items) {
          this.methods = [];
          let flags = [],
            l = data.items.length,
            i;
          for (i = 0; i < l; i++) {
            if (flags[data.items[i].sub_group]) {
              continue;
            }
            flags[data.items[i].sub_group] = true;
            this.methods.push(data.items[i].sub_group);
          }
        }
      },
      (err) => {
        this.InTakeItems = [];
      },
    );
  }

  fetchOutTakeItems(val): any {
    this.OutTakeItems = [];
    this.stockService.fetchProductsByGroupV2('Out-Take').subscribe(
      (data) => {
        this.OutTakeItems = data.items;
      },
      (err) => {
        this.OutTakeItems = [];
      },
    );
  }

  get controlInTake(): any {
    return this.InOutTakeChartForm.get('in_take_data') as UntypedFormArray;
  }
  get controlOutTake(): any {
    return this.InOutTakeChartForm.get('out_take_data') as UntypedFormArray;
  }

  submitInOutTake(): any {
    this.isSubmitInOutTake = true;
    const time = this.InOutTakeChartForm.get('time').value;
    if (time) {
      const taken = moment(time, 'HH:mm:ss');
      const takenTime = moment(taken).utc().format('DD-MM-YYYY h:mm a');
      this.InOutTakeChartForm.get('taken_time').patchValue(takenTime);
    }
    if (this.InOutTakeChartForm.invalid) {
      return;
    }
    if (this.controlInTake.length === 0 && this.controlOutTake.length === 0) {
      toastr.error('At least add one In-Take or Out-Take');
      return;
    }
    this.ngxLoader.start();
    this.patientInOutTakeService
      .createInOutTakeV2(this.InOutTakeChartForm.value, this.patient)
      .subscribe(
        (data) => {
          // this.patientInOutTake = data.patient_in_out_take;
          toastr.success('Successfully Added In Out Take Time');
          this.resetInOutTakeForm('');
          this.getInOutTakes('');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  addMoreInTake(): any {
    this.controlInTake.push(this.createInTake());
  }
  removeInTake(i: number): any {
    this.controlInTake.removeAt(i);
    // if (this.controlInTake.length === 0) { this.addMoreInTake(); }
  }

  addMoreOutTake(): any {
    this.controlOutTake.push(this.createOutTake());
  }

  removeOutTake(i: number): any {
    this.controlOutTake.removeAt(i);
    // if (this.controlOutTake.length === 0) { this.addMoreOutTake(); }
  }

  createInTake(): any {
    return this.formBuilder.group({
      in_take_method: ['', Validators.required],
      in_take_type_id: ['', Validators.required],
      in_take_type: ['', Validators.required],
      comments: [''],
      in_take_quantity: ['', Validators.required],
      in_take_unit: ['', Validators.required],
      in_take: [true, Validators.required],
    });
  }

  createOutTake(): any {
    return this.formBuilder.group({
      out_take_type_id: ['', Validators.required],
      out_take_type: ['', Validators.required],
      comments: [''],
      out_take_quantity: ['', Validators.required],
      out_take_unit: ['', Validators.required],
      out_take: [true, Validators.required],
    });
  }

  selectInTakeSubGroup(index): any {
    const method = this.controlInTake.at(index).get('in_take_method').value;
    this.InTakeSubGroupItems[index] = this.InTakeItems.filter(
      (x) => x.sub_group === method,
    );
    if (this.InTakeSubGroupItems[index].length > 0) {
      this.controlInTake
        .at(index)
        .get('in_take_type_id')
        .patchValue(this.InTakeSubGroupItems[index][0].id);
      this.controlInTake
        .at(index)
        .get('in_take_type')
        .patchValue(this.InTakeSubGroupItems[index][0].item_name);
    }
  }
  selectInTakeItem(index): any {
    const itemId = this.controlInTake.at(index).get('in_take_type_id').value;
    const items = this.InTakeSubGroupItems[index].filter(
      (x) => x.id === Number(itemId),
    );
    if (items.length > 0) {
      const item = items[items.length - 1];
      this.controlInTake
        .at(index)
        .get('in_take_type')
        .patchValue(item.item_name);
    }
  }

  selectOutTakeItem(index): any {
    if (
      this.controlOutTake.at(index).get('out_take_type_id').value.length > 1
    ) {
      const itemId = this.controlOutTake
        .at(index)
        .get('out_take_type_id').value;
      const items = this.OutTakeItems.filter((x) => x.id === Number(itemId));
      if (items.length > 0) {
        const item = items[items.length - 1];
        this.controlOutTake
          .at(index)
          .get('out_take_type')
          .patchValue(item.item_name);
      }
    }
  }

  resetInOutTakeForm(val): any {
    this.InOutTakeChartForm.reset({
      taken_time: '',
      time: '',
    });
    while (this.controlInTake.length) {
      this.controlInTake.removeAt(0);
    }
    while (this.controlOutTake.length) {
      this.controlOutTake.removeAt(0);
    }
    this.isSubmitInOutTake = false;
    this.addMoreInTake();
    this.addMoreOutTake();
    this.InTakeSubGroupItems = [];
    const admissionDate = moment(this.patient?.admissions[0].created_at).format(
      'YYYY-MM-DD',
    );
    $('#intakeDateFilter').attr('min', admissionDate);
  }
  updateDate(event): any {
    this.givenDate = event;
  }

  getInOutTakes(val): any {
    this.patientInTakes = [];
    this.patientOutTakes = [];
    this.todayInTakeQuantity = [];
    this.todayOutTakeQuantity = [];
    this.totalInTakeQtyType = [];
    this.totalOutTakeQtyType = [];
    this.todayInTakeQtyTotal = '';
    this.todayOutTakeQtyTotal = '';
    this.ngxLoader.start();
    // this.patientInOutTakes = [];
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.patientInOutTakeService
      .fetchInOutTakeV2(
        this.patient,
        this.givenDate,
        this.patient.visit_id,
        this.visitType,
      )
      .subscribe(
        (data) => {
          this.patientInTakes = data.patient_in_takes;
          this.patientOutTakes = data.patient_out_takes;
          this.todayInTakeQtyTotal = data.today_in_take_quantity;
          this.todayOutTakeQtyTotal = data.today_out_take_quantity;
          this.todayInTakeQuantity = [];
          for (const type in data.today_in_take_quantity) {
            const item: any = {};
            item.key = type;
            item.value = data.today_in_take_quantity[type];
            this.todayInTakeQuantity.push(item);
          }
          this.todayOutTakeQuantity = [];
          for (const type in data.today_out_take_quantity) {
            const item: any = {};
            item.key = type;
            item.value = data.today_out_take_quantity[type];
            this.todayOutTakeQuantity.push(item);
          }
          this.totalInTakeQtyType = [];
          for (const type in data.today_total_in_take_type) {
            const item: any = {};
            item.key = type;
            item.value = data.today_total_in_take_type[type];
            this.totalInTakeQtyType.push(item);
          }
          this.totalOutTakeQtyType = [];
          for (const type in data.today_total_out_take_type) {
            const item: any = {};
            item.key = type;
            item.value = data.today_total_out_take_type[type];
            this.totalOutTakeQtyType.push(item);
          }
          // }
          // this.listData = new MatTableDataSource(data['patient_in_out_takes'.toString()]);
          // this.ngAfterViewInit();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  get inOutCont(): any {
    return this.InOutTakeChartForm.controls;
  }

  printCurrentScreen(): any {
    window.open(
      'pdf-reports/intakeoutput-report?id=' +
        this.patient.id +
        '&visit_id=' +
        this.patient.visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&invoice_id=' +
        '&inout_date=' +
        this.givenDate,
    );
    // window.print();
  }
}
