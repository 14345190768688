<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>

          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center"></div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::-->

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="card">
            <div class="card-body department-card-body">
              <div class="row mb-4">
                <div class="col-6"></div>
                <div class="col-6 text-right">
                  <button
                    type="button"
                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                    (click)="addTags()"
                  >
                    <span class="svg-icon svg-icon-primary svg-icon-2x"
                      ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                          <path
                            d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                            fill="#000000"
                          />
                        </g></svg
                      ><!--end::Svg Icon-->
                    </span>
                  </button>
                </div>
              </div>

              <div class="department-table overflow-auto">
                <table
                  class="table table-responsive d-table w-100 table-head-custom table-vertical-center"
                >
                  <thead>
                    <tr>
                      <th style="width: 25%">Name</th>

                      <th class="text-center" style="width: 25%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!--                  <tr *ngFor="let dep of departmentList; let i = index" class="cursor-pointer department-rows"  (click)="departmentDetail(dep)"-->
                    <!--                      [ngClass]="{ 'active': dep.id === activeID }">-->

                    <ng-container
                      *ngFor="let listType of typeList; let i = index"
                    >
                      <tr>
                        <td>{{ listType.name }}</td>

                        <td class="text-center">
                          <button
                            class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                            (click)="getTagUpdate(listType.id, i, listType)"
                            type="button"
                          >
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <svg
                                _ngcontent-qmk-c112=""
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  _ngcontent-qmk-c112=""
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    _ngcontent-qmk-c112=""
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    _ngcontent-qmk-c112=""
                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                                  ></path>
                                  <path
                                    _ngcontent-qmk-c112=""
                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--end::-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<div
  class="modal fade"
  id="addTypeModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="showUpdateType === false">
          Add priority
        </h5>
        <h5 class="modal-title" *ngIf="showUpdateType === true">
          Update priority
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form autocomplete="off" [formGroup]="priority">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-4 form-group">
              <label>Name<span class="text-danger">*</span></label>
              <input
                class="form-control form-control-sm"
                formControlName="name"
                id="name"
                type="text"
                [ngClass]="{
                  'is-invalid': isAddType && this.priority.controls.name.errors
                }"
              />
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button
          type="submit"
          *ngIf="showUpdateType === false"
          class="btn btn-sm btn-primary"
          (click)="submitType()"
        >
          Add New Priority
        </button>
        <button
          type="submit"
          *ngIf="showUpdateType === true"
          class="btn btn-sm btn-primary"
          (click)="submitType()"
        >
          Update changes
        </button>
        <!--        <button type="button" class="btn btn-primary" (click)="submitType()">Save changes</button>-->
      </div>
    </div>
  </div>
</div>
