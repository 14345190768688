<div class="row">
  <div class="col-lg-2 col-md-3 col-sm-12 col-12">
    <div class="card h-100">
      <div class="card-body patient-img-card">
        <div class="row justify-content-center">
          <!--Image Upload-->
          <div
            class="image-input image-input-empty image-input-outline mb-4"
            id="kt_image_5"
            style="position: relative"
          >
            <img
              *ngIf="familyData?.profile_image; else profileImageElse"
              [src]="familyData?.profile_image"
              class="patient_img"
              style="
                object-fit: contain;
                position: absolute;
                padding: 5px;
                width: 100%;
                height: 100%;
              "
            />
            <ng-template #profileImageElse>
              <img
                [src]="
                  patientImage
                    ? patientImage
                    : '../../../../../assets/media/users/blank.png'
                "
                class="patient_img"
                style="
                  object-fit: contain;
                  position: absolute;
                  padding: 5px;
                  width: 100%;
                  height: 100%;
                "
              />
            </ng-template>
            <div class="image-input-wrapper"></div>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-12">
            <button
              class="btn btn-primary btn-sm mb-2 button-width"
              type="button"
              (click)="cambox()"
            >
              {{ dataValues.picture }}
            </button>
            <br />
            <button class="btn btn-primary btn-sm button-width" type="button">
              {{ dataValues.impression }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-10 col-md-9 col-sm-12 col-12">
    <div class="card">
      <div class="card-body">
        <!--      <lib-country-list></lib-country-list>-->
        <!--      <ngx-countries-dropdown></ngx-countries-dropdown>-->
        <form [formGroup]="familyForm" (ngSubmit)="submitFamilyInfo()">
          <div class="row">
            <!--        Guardian Name-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Guardian Name</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Guardian Name"
                  maxlength="30"
                  formControlName="guardian_name"
                />
              </div>
            </div>
            <!--        Relation-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Relation</label>
                <select
                  class="form-control form-control-sm bootstrap-select-container"
                  data-container="body"
                  formControlName="relation"
                  data-live-search="true"
                  id="relation"
                >
                  <option value="" [selected]="true">Select</option>
                  <ng-container *ngFor="let lov of lookups">
                    <option [value]="lov.key">{{ lov.key }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--        Contact-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Contact</label>
                <input
                  type="text"
                  id="contact"
                  class="form-control form-control-sm"
                  placeholder="Contact"
                  maxlength="10"
                  minlength="10"
                  formControlName="contact"
                  (keypress)="_onlyNumeric($event)"
                />
              </div>
            </div>
            <!--        Alternate Contact-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Alternate Contact</label>
                <input
                  type="text"
                  id="alternate_contact"
                  class="form-control form-control-sm"
                  formControlName="alternate_contact"
                  placeholder="Alternative Contact"
                  maxlength="10"
                  (keypress)="_onlyNumeric($event)"
                  minlength="10"
                />
              </div>
            </div>
            <!--        CNIC-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>CNIC</label>
                <input
                  type="text"
                  id="cnic"
                  class="form-control form-control-sm"
                  placeholder="Guardian CNIC"
                  formControlName="cnic"
                  (keypress)="_onlyNumeric($event)"
                />
              </div>
            </div>
            <!--        Passport-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Passport</label>
                <input
                  type="text"
                  id="passport"
                  class="form-control form-control-sm"
                  formControlName="passport"
                  placeholder="Guardian Passport"
                  maxlength="10"
                />
              </div>
            </div>
            <!--        Occupation-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Occupation</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="occupation"
                  placeholder="Guardian Occupation"
                  maxlength="30"
                />
              </div>
            </div>
            <!--        Qualification-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Qualification</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="qualification"
                  placeholder="Guardian Qualification"
                  maxlength="30"
                />
              </div>
            </div>
            <!--        Country-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Country</label>
                <select
                  class="form-control form-control-sm countryLib"
                  id="countryLib"
                  formControlName="country"
                  style="padding-left: 40px"
                  data-container="body"
                  data-live-search="true"
                  (change)="onCountryChange($event.target.value)"
                >
                  <option value="">Select</option>
                  <option
                    *ngFor="let country of countriesList"
                    [value]="country.id"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </div>
            </div>
            <!--        City-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>City</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="City"
                  maxlength="20"
                  formControlName="city"
                />
              </div>
            </div>
            <!--        Address-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Address</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Address"
                  maxlength="100"
                  formControlName="address"
                />
              </div>
            </div>
            <!--        Accommodation-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Accommodation</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Type of Accommodation"
                  maxlength="30"
                  formControlName="accommodation"
                />
              </div>
            </div>
            <!--        Mother Name-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Mother Name</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Mother Name"
                  maxlength="30"
                  formControlName="mother_name"
                />
              </div>
            </div>
            <!--        Mother Profession-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Mother Profession</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Mother Profession"
                  maxlength="30"
                  formControlName="mother_profession"
                />
              </div>
            </div>
            <!--        Sibling-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Siblings</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Siblings"
                  maxlength="2"
                  formControlName="sibling"
                  (keypress)="_onlyNumeric($event)"
                />
              </div>
            </div>
            <!--        Family Income-->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="form-group">
                <label>Family Income</label>
                <div class="d-flex">
                  <div>
                    <select
                      class="form-control form-control-sm"
                      formControlName="currency"
                      style="
                        max-width: 87px;
                        border-right: none;
                        background-color: #0000000d;
                      "
                    >
                      <option value="" selected>select</option>
                      <ng-container *ngFor="let data of allConfigs">
                        <option
                          [value]="data.id"
                          *ngIf="data.config_type === 'currency'"
                        >
                          {{ data.name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="border-left: none; padding: 0px 8.75rem 0px 6px"
                      placeholder="Family Income"
                      maxlength="8"
                      formControlName="family_income"
                      (keypress)="_onlyNumeric($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!--        Submit Button-->
            <div class="col-12 text-right">
              <button
                *ngIf="!familyDataId"
                type="submit"
                class="btn btn-sm btn-primary"
              >
                Save
              </button>
              <button
                *ngIf="familyDataId"
                type="submit"
                class="btn btn-sm btn-primary"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!--get Image Mdoal-->
<div class="example-preview">
  <div>
    <ng-template #imageModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Capturing the Image!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <div>
            <div *ngIf="showImage === false">
              <webcam
                [height]="199.99999999999997"
                [width]="266.66666666666663"
                [trigger]="triggerObservable"
                (imageCapture)="handleImage($event)"
                *ngIf="showWebcam"
                [allowCameraSwitch]="allowCameraSwitch"
                [switchCamera]="nextWebcamObservable"
                [videoOptions]="videoOptions"
                [imageQuality]="1"
                (cameraSwitched)="cameraWasSwitched($event)"
                (initError)="handleInitError($event)"
              ></webcam>
            </div>
            <img
              [src]="webcamImage.imageAsDataUrl"
              style="width: 266px; height: 200px"
              class=""
              *ngIf="showImage === true"
              alt=""
            />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === false"
            (click)="triggerSnapshot()"
          >
            Take A Image
          </button>
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === true"
            (click)="resetImageBox()"
          >
            Reset
          </button>
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === true"
            (click)="captureImageComplete()"
          >
            Done
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
