<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-center justify-content-between flex-wrap mr-5 w-100"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card card-custom gutter-b d-block d-print-none">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Table-->
          <div class="table_top_bar mb-3 hide-print-content">
            <div class="row">
              <!--Search Type -->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <div class="form-group">
                  <label class="mr-3 mb-0 d-none d-md-block">Search Type</label>
                  <select
                    class="form-control form-control-sm"
                    [(ngModel)]="search_query"
                  >
                    <!--                    <option [value]="'default'" selected>All</option>-->
                    <option [value]="'crn'">CRN</option>
                    <option [value]="'name'">Name</option>
                    <option [value]="'mobile'">Mobile</option>
                  </select>
                </div>
              </div>
              <!--CRN:-->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <div class="form-group">
                  <label>
                    <span *ngIf="search_query === 'default'"
                      >Client Search</span
                    >
                    <span *ngIf="search_query === 'crn'">CRN</span>
                    <span *ngIf="search_query === 'name'">Name</span>
                    <span *ngIf="search_query === 'mobile'">Mobile No.</span>
                  </label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="{{
                      search_query === 'default'
                        ? 'Client Search'
                        : (search_query | titlecase)
                    }}"
                    [(ngModel)]="crn"
                  />
                </div>
              </div>
              <!--Invoice No.:-->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <div class="form-group">
                  <label>Invoice No.</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Invoice No."
                    [(ngModel)]="invoiceNo"
                  />
                </div>
              </div>
              <!--    Department div          -->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <div class="d-block form-group">
                  <label>Department</label>
                  <select
                    class="form-control form-control-sm selectpicker"
                    data-live-search="true"
                    data-container="body"
                    id="department"
                    name="department"
                    [(ngModel)]="department"
                  >
                    <!--                  <select class="form-control form-control-sm selectpicker" data-live-search="true" multiple id="department" name="department" (change)="getEmployeesOfDepartment()" [(ngModel)]="department">-->
                    <option value="">Select Department</option>
                    <ng-container *ngFor="let dep of depart">
                      <option [value]="dep.id">{{ dep?.title }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <!--Employee Of Department-->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <div class="d-block form-group">
                  <label>Technical Staff</label>
                  <select
                    class="form-control form-control-sm selectpicker"
                    data-live-search="true"
                    id="employeeDepartmentList"
                    name="employee"
                    [(ngModel)]="technicalStaff"
                  >
                    <option value="" [selected]="true">Technical Staff</option>
                    <ng-container *ngFor="let emp of employeesList">
                      <option
                        [value]="emp.id"
                        *ngIf="emp?.staff_type === 'Technical'"
                      >
                        {{ emp?.first_name }} {{ emp?.middle_name || "" }}
                        {{ emp?.last_name }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <!--Loan Type-->
              <!--              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
              <!--                <div class="form-group">-->
              <!--                  <label>Loan Type</label>-->
              <!--                  <select class="form-control leave-type form-control-sm" data-live-search="true" name="loan_type" [(ngModel)]="type">-->
              <!--                    <option value="" [disabled]="true" [selected]="true" [hidden]="true" >Select</option>-->
              <!--                    <ng-container *ngFor="let list of getDataForloan">-->
              <!--                      <option  [value]="list.id">{{list?.loan_type}}</option>-->
              <!--                    </ng-container>-->
              <!--                    &lt;!&ndash;                    <ng-container *ngFor="let list of getDataForleave?.leave_setting?.leave_type_list">&ndash;&gt;-->
              <!--                    &lt;!&ndash;                      <option  [value]="list.leave_type">{{list?.type_name}}</option>&ndash;&gt;-->
              <!--                    &lt;!&ndash;                      <option  [value]="list.leave_type">{{list?.type_name}}</option>&ndash;&gt;-->
              <!--                    &lt;!&ndash;                    </ng-container>&ndash;&gt;-->
              <!--                  </select>-->
              <!--                </div>-->
              <!--              </div>-->

              <!--              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
              <!--                <div class="d-block form-group">-->
              <!--                  <label>Department:</label>-->
              <!--                  <select class="form-control form-control-sm selectpicker" data-live-search="true" id="department" name="department" [(ngModel)]="departmentSearchValue">-->
              <!--                    <option value="">Select Department</option>-->
              <!--                    <ng-container *ngFor="let dep of depart">-->
              <!--                      <option [value]="dep.title">{{dep.title}}</option>-->
              <!--                    </ng-container>-->
              <!--                  </select>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
              <!--                <div class="d-block form-group">-->
              <!--                  <label>Name:</label>-->
              <!--                  <input class="form-control form-control-sm" type="text" placeholder="Name" [(ngModel)]="name"/>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--Status-->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <div class="form-group">
                  <label class="mr-3 mb-0 d-none d-md-block">Status</label>
                  <select
                    class="form-control form-control-sm"
                    [(ngModel)]="status"
                  >
                    <option [value]="-1" [selected]="true">All</option>
                    <option [value]="1">In progress</option>
                    <option [value]="0">Pending</option>
                    <option [value]="2">Performed</option>
                    <option [value]="3">Removed</option>
                  </select>
                </div>
              </div>
              <!--Employee Category-->
              <!--              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
              <!--                <div class="form-group">-->
              <!--                  <label>Employee Category</label>-->
              <!--                  <select class="form-control form-control-sm selectpicker" name="employee_category" id="employee_category" data-live-search="true" [(ngModel)]="employeeCategorySearchValue">-->
              <!--                    <option value="">Select employee category</option>-->
              <!--                    <option value="27">Part Time</option>-->
              <!--                    <option value="26">Full Time</option>-->
              <!--                  </select>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="col-12 col-sm-3 align-self-end">
                <div class="form-group">
                  <div class="mt-3 text-left">
                    <a
                      class="btn btn-light-primary btn-sm px-6 font-weight-bold"
                      ngbTooltip="Search Filter"
                      placement="bottom"
                      (click)="workerListing(true)"
                      >Search</a
                    >
                  </div>
                </div>
              </div>
              <!--              <div class="col-12 col-sm-3">-->
              <!--                <div class="d-flex align-items-center justify-content-between form-group">-->
              <!--                  <label class="mr-3 mb-0 d-none d-md-block">Show Terminated Only:</label>-->
              <!--                  <span class="switch switch-icon">-->
              <!--                    <label>-->
              <!--                      <input type="checkbox" name="select" [(ngModel)]="showterminated" />-->
              <!--                      <span></span>-->
              <!--                    </label>-->
              <!--                  </span>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="col-12">-->
              <!--                <div class="mt-3 text-right">-->
              <!--                  <a class="btn btn-light-primary btn-sm px-6 font-weight-bold" (click)="searchFilterValue()" ngbTooltip="Search Filter" placement="bottom">Search</a>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>

      <div class="card card-custom gutter-b">
        <div class="card-body">
          <div>
            <!--            <div style="width: 100%;" class="d-none d-print-block">-->
            <!--              &lt;!&ndash; header table &ndash;&gt;-->
            <!--              <table class="table">-->
            <!--                <tbody>-->
            <!--                <tr class="borders">-->
            <!--                  <td style="width: 15%; border-top: 0 !important;">-->
            <!--                    <img [src]="settingData?.logo?.url" style="width: auto;height: 106px">-->
            <!--                  </td>-->
            <!--                  <td style="width: 70%; border-top: 0 !important;">-->
            <!--                    <div class="text-center"><h3>{{settingData?.title}}</h3></div>-->
            <!--                    <div class="font-size-h5 text-center"><h5>{{settingData?.address}}</h5></div>-->
            <!--                    <div class="font-size-h5 text-center"><h5>Workers List</h5></div>-->
            <!--                  </td>-->
            <!--                  <td style="width: 15%; border-top: 0 !important;"> </td>-->
            <!--                </tr>-->
            <!--                </tbody>-->
            <!--              </table>-->
            <!--            </div>-->
            <div>
              <div class="table-responsive position-relative">
                <table
                  class="table table-head-custom table-vertical-center"
                  id="kt_advance_table_widget_1"
                >
                  <thead>
                    <tr class="text-left">
                      <th class="pr-0" style="width: 10%">
                        <!--                      Date / Inv. No /payment Status-->
                        <span class="d-block">Date</span>
                        <span class="d-block">Invoice No</span>
                        <span class="d-block">payment Status</span>
                      </th>
                      <th style="width: 12%">
                        <!--                      Client Name/ CRN / Category-->
                        <span class="d-block">Client Name</span>
                        <span class="d-block">CRN</span>
                        <span class="d-block">Category</span>
                      </th>
                      <th style="width: 15%">
                        <!--                      Service Name/ Department / Chair-->
                        <span class="d-block">Service Name</span>
                        <span class="d-block">Department</span>
                        <span class="d-block">Chair</span>
                      </th>
                      <th style="width: 10%">
                        <!--                      In/Out Time-->
                        <span class="d-block">Check In Time</span>
                        <span class="d-block">Check Out Time</span>
                      </th>
                      <th style="width: 20%">Technical Staff</th>
                      <th></th>
                      <th style="width: 20%">Non Technical Staff</th>
                      <!--                    <th style="width: 11%; text-align: center">Kit Request</th>-->
                      <th style="width: 8%; text-align: center">Status</th>
                      <th style="width: 5%; text-align: center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let worker of workerData; let i = index">
                      <td>
                        <span class="d-block font-weight-bold">{{
                          worker?.created_at | date: "dd-MM-yyyy"
                        }}</span>
                        <span class="d-block text-muted font-weight-bold">{{
                          worker?.patient_service_invoice_id
                        }}</span>
                        <span
                          class="text-success font-weight-bold"
                          *ngIf="worker?.payment_status === 'paid'"
                          >Invoiced</span
                        >
                        <span
                          class="text-danger font-weight-bold"
                          *ngIf="worker?.payment_status === 'unpaid'"
                          >Not Invoiced</span
                        >
                      </td>
                      <td>
                        <span class="d-block font-weight-bold"
                          >{{ worker?.client?.first_name }}
                          {{ worker?.client?.last_name }}</span
                        >
                        <span class="d-block text-muted font-weight-bold">{{
                          worker?.client?.crn
                        }}</span>
                        <span class="text-muted font-weight-bold">{{
                          worker?.client?.client_category
                        }}</span>
                      </td>
                      <td>
                        <span class="d-block font-weight-bold">{{
                          worker?.service?.name
                        }}</span>
                        <span class="d-block text-muted font-weight-bold">{{
                          worker?.department
                        }}</span>
                        <span
                          class="font-weight-bold cursor-pointer label label-lg label-light-success label-inline"
                          (click)="
                            openChairModal(
                              worker?.sub_department_id,
                              worker?.id,
                              worker?.chair_id
                            )
                          "
                          >{{ worker?.chair || "assign chair" }}</span
                        >
                      </td>
                      <td>
                        <button
                          style="
                            min-width: 136px;
                            max-width: 136px;
                            border: none;
                          "
                          class="buttons font-weight-bold cursor-pointer label label-lg label-light-success label-inline"
                          [disabled]="worker?.time_check_out !== ''"
                          (click)="
                            openCheckInModal(worker?.id, worker?.time_check_in)
                          "
                        >
                          Check In:
                          <span *ngIf="worker?.time_check_in !== ''">{{
                            worker?.time_check_in
                          }}</span>
                          <span *ngIf="worker?.time_check_in === ''">{{
                            "00:00"
                          }}</span>
                        </button>
                        <!--                      <button style="min-width: 136px; max-width: 136px; border: none" class="buttons font-weight-bold cursor-pointer label label-lg label-light-success label-inline" [disabled]="worker?.time_check_out !== ''" (click)="openCheckInModal(worker?.id, worker?.time_check_in)">Check In: <span *ngIf="worker?.time_check_in !== ''">{{worker?.time_check_in | amPm }}</span> <span *ngIf="worker?.time_check_in === ''">{{"00:00" }}</span> </button>-->
                        <button
                          style="
                            min-width: 136px;
                            max-width: 136px;
                            border: none;
                          "
                          class="buttons2 d-block font-weight-bold mt-2 pt-1 cursor-pointer label label-lg label-light-danger label-inline"
                          [disabled]="
                            worker?.time_check_in === '' ||
                            worker?.time_check_out !== ''
                          "
                          (click)="
                            openCheckOutModal(
                              worker?.id,
                              worker?.time_check_out,
                              worker?.time_check_in
                            )
                          "
                        >
                          Check out:
                          <span *ngIf="worker?.time_check_out !== ''">
                            {{ worker?.time_check_out }}</span
                          >
                          <span *ngIf="worker?.time_check_out === ''">
                            {{ "00:00" }}</span
                          >
                        </button>
                        <!--                      <button style="min-width: 136px; max-width: 136px; border: none" class="buttons2 d-block font-weight-bold mt-2 pt-1 cursor-pointer label label-lg label-light-danger label-inline" [disabled]="worker?.time_check_in === '' || worker?.time_check_out !== ''" (click)="openCheckOutModal(worker?.id, worker?.time_check_out, worker?.time_check_in )">Check out:  <span *ngIf="worker?.time_check_out !== ''"> {{worker?.time_check_out | amPm }}</span> <span *ngIf="worker?.time_check_out === ''"> {{"00:00"}}</span>  </button>-->
                      </td>
                      <td style="display: grid">
                        <span
                          class="font-weight-bold cursor-pointer label label-lg label-light-primary label-inline"
                          (click)="
                            openTechnicalModal(
                              worker?.id,
                              worker?.technical_list
                            )
                          "
                          *ngFor="let names of worker?.technical"
                          ><span style="display: grid">{{ names }}</span> </span
                        ><br />
                        <span
                          class="font-weight-bold cursor-pointer label label-lg label-light-primary label-inline"
                          (click)="
                            openTechnicalModal(
                              worker?.id,
                              worker?.technical_list
                            )
                          "
                          *ngIf="!worker?.technical"
                          >{{ "Assign tech Staff" }}
                        </span>
                      </td>
                      <td></td>
                      <td style="display: grid">
                        <span
                          class="font-weight-bold cursor-pointer label label-lg label-light-info label-inline"
                          (click)="
                            openNonTechnicalModal(
                              worker?.id,
                              worker?.non_technical_list
                            )
                          "
                          *ngFor="let names of worker?.non_technical"
                          ><span style="display: grid">{{ names }}</span> </span
                        ><br />
                        <span
                          class="font-weight-bold cursor-pointer label label-lg label-light-info label-inline"
                          (click)="
                            openNonTechnicalModal(
                              worker?.id,
                              worker?.non_technical_list
                            )
                          "
                          *ngIf="!worker?.non_technical"
                          >{{ "Assign Non-tech Staff" }}
                        </span>
                      </td>
                      <!--                    <td class="text-center">-->
                      <!--                      <button [disabled]="worker?.service_request_status" class="buttons btn-sm py-2" style="border: none; background-color: rgba(127,212,243,0.66)" (click)="submitKitRequestBtn(worker?.id)"><span class="py-3" style="color: #024069">-->
                      <!--                        Request Service Recipe-->
                      <!--                      </span></button>-->
                      <!--                    </td>-->
                      <td class="text-center">
                        <span
                          class="label label-lg label-light-warning label-inline"
                          *ngIf="worker?.service_status === 0"
                          >Pending</span
                        >
                        <span
                          class="label label-lg label-light-primary label-inline"
                          *ngIf="worker?.service_status === 1"
                          >In progress</span
                        >
                        <span
                          class="label label-lg label-light-success label-inline"
                          *ngIf="worker?.service_status === 2"
                          >Performed</span
                        >
                        <span
                          class="label label-lg label-light-danger label-inline"
                          *ngIf="worker?.service_status === 3"
                          >Removed</span
                        >
                      </td>
                      <td class="text-center">
                        <div class="dropdown dropdown-inline">
                          <button
                            type="button"
                            class="btn btn-light-primary btn-icon btn-sm"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="ki ki-bold-more-ver"></i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-sm dropdown-menu-right"
                          >
                            <a
                              class="dropdown-item cursor-pointer"
                              (click)="
                                openTechnicalModal(
                                  worker?.id,
                                  worker?.technical_list
                                )
                              "
                              >Assign Tech Staff</a
                            >
                            <a
                              class="dropdown-item cursor-pointer"
                              (click)="
                                openNonTechnicalModal(
                                  worker?.id,
                                  worker?.non_technical_list
                                )
                              "
                              >Assign Non Tech Staff</a
                            >
                            <a
                              class="dropdown-item cursor-pointer"
                              (click)="
                                openChairModal(
                                  worker?.department_id,
                                  worker?.id,
                                  worker?.chair_id
                                )
                              "
                              >Assign Chair</a
                            >
                            <a
                              *ngIf="allowRemoveService"
                              class="dropdown-item cursor-pointer"
                              id="removeButton"
                              (click)="openRemoveServiceModal(worker?.id)"
                              >Remove Service</a
                            >
                            <!--                          <a class="dropdown-item cursor-pointer" (click)="serviceKit(worker?.id, worker?.service?.name)">Service Kit</a>-->
                            <a
                              class="dropdown-item cursor-pointer"
                              (click)="
                                openRecipyModal(
                                  worker?.service?.id,
                                  worker?.client?.id,
                                  worker?.id
                                )
                              "
                              >Request Additional Products</a
                            >
                            <a
                              class="dropdown-item cursor-pointer"
                              (click)="openFeedBackModal(worker?.client?.id)"
                              >Feedback</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!--            <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>-->
              </div>
            </div>

            <!--end::Table-->
            <!--pagination-->
            <div class="pagination_main d-print-none">
              <ngb-pagination
                [(page)]="page"
                (pageChange)="loadPage(page)"
                [pageSize]="perPage"
                [collectionSize]="totalPages"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              ></ngb-pagination>
              <div class="dropdown">
                <a
                  class="btn dropdown-toggle btn-light btn-sm"
                  role="button"
                  id="pagination_btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="page_count">
                    {{ perPageValue }}
                  </span>
                  <span class="count_count_icon"></span>
                </a>
                <div class="dropdown-menu" aria-labelledby="pagination_btn">
                  <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                    >25</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                    >50</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                    >75</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                    >100</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit('')"
                    >All</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</div>

<!--technical WOrker start-->
<div
  class="modal fade"
  id="technicalModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2">
        <h5 class="modal-title">Technical Staff</h5>
        <!--                <h5 class="modal-title" *ngIf="tagId">Update Tag</h5>-->
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <label>Technical Staff:</label>
            <select
              class="form-control form-control-sm"
              data-live-search="true"
              multiple
              data-container="body"
              id="employeeTechnical"
              data-size="5"
              name="employee"
              [(ngModel)]="technicalStaffData"
            >
              <option value="" [disabled]="true">Select Technical Staff</option>
              <ng-container *ngFor="let emp of employees">
                <option
                  [value]="emp.id"
                  *ngIf="emp?.staff_type === 'Technical'"
                >
                  {{ emp?.first_name }} {{ emp?.middle_name || "" }}
                  {{ emp?.last_name }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>

      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-sm btn-default"
          (click)="closeTechnicalModal()"
        >
          Close
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-primary"
          (click)="addEmployee()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
<!--technical worker End-->

<!--Worker non TechnicalModal start-->
<div
  class="modal fade"
  id="nonTechnicalModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2">
        <h5 class="modal-title">Non- Technical Satff</h5>
        <!--                <h5 class="modal-title" *ngIf="tagId">Update Tag</h5>-->
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <label>Non-Technical:</label>
            <select
              class="form-control form-control-sm"
              data-live-search="true"
              multiple
              data-container="body"
              id="employee"
              data-size="5"
              name="employee"
              [(ngModel)]="nonTechnical"
            >
              <option value="" [disabled]="true">Select Non-Technical</option>
              <ng-container *ngFor="let emp of employees">
                <option
                  [value]="emp.id"
                  *ngIf="emp?.staff_type === 'Non-Technical'"
                >
                  {{ emp?.first_name }} {{ emp?.middle_name || "" }}
                  {{ emp?.last_name }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>

      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-sm btn-default"
          (click)="closeWorkerModal()"
        >
          Close
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-primary"
          (click)="addNonTechnical()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
<!--Worker non TechnicalModal End-->
<!--chair start-->
<div
  class="modal fade"
  id="addChairModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2">
        <h5 class="modal-title">Add Chair</h5>
        <!--                <h5 class="modal-title" *ngIf="tagId">Update Tag</h5>-->
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <label>Chair:</label>
            <select
              class="form-control form-control-sm"
              data-live-search="true"
              data-container="body"
              id="chair"
              data-size="5"
              name="chair"
              [(ngModel)]="chair"
            >
              <option value="" [disabled]="true">Select Chair</option>
              <ng-container *ngFor="let chair of chairData">
                <option [value]="chair.id">{{ chair?.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>

      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-sm btn-default"
          (click)="closeChairModal()"
        >
          Close
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-primary"
          (click)="addChair()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
<!--chairModal End-->
<!--WorkerRemoveServiceModal start-->
<div
  class="modal fade"
  id="RemoveServiceModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2">
        <h5 class="modal-title">Reason</h5>
        <!--                <h5 class="modal-title" *ngIf="tagId">Update Tag</h5>-->
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <label>Reason:</label>
            <input
              type="text"
              class="form-control-sm form-control"
              placeholder="Reason"
              [(ngModel)]="reason"
            />
          </div>
        </div>
      </div>

      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-sm btn-default"
          (click)="closeRemoveServiceModal()"
        >
          Close
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-primary"
          (click)="submitReason()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
<!--WorkerRemoveServiceModal End-->
<!--check in  modal start-->
<div
  class="modal fade"
  id="checkInModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2">
        <h5 class="modal-title">Check In</h5>
        <!--                <h5 class="modal-title" *ngIf="tagId">Update Tag</h5>-->
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
            <label>Check In</label>
            <input
              type="time"
              id="avail_from"
              class="form-control form-control-sm"
              [(ngModel)]="checkIn"
            />
            <!--                    <input class="form-control form-control-sm timepicker" id="availFrom" readonly placeholder="Available From" type="time" formControlName="avail_from"/>-->
          </div>
        </div>
      </div>

      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-sm btn-default"
          (click)="closeCheckInModal()"
        >
          Close
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-primary"
          (click)="submitCheckIn()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
<!--check in  modal end-->
<!--check out  modal start-->
<div
  class="modal fade"
  id="checkOutModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2">
        <h5 class="modal-title">Check Out</h5>
        <!--                <h5 class="modal-title" *ngIf="tagId">Update Tag</h5>-->
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
            <label>Check Out</label>
            <input
              type="time"
              id="avail_to"
              name="avail_to"
              class="form-control form-control-sm avail_to"
              [(ngModel)]="checkOut"
            />
            <!--                    <input class="form-control form-control-sm timepicker" id="availFrom" readonly placeholder="Available From" type="time" formControlName="avail_from"/>-->
          </div>
        </div>
      </div>

      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-sm btn-default"
          (click)="closeCheckOutModal()"
        >
          Close
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-primary"
          (click)="submitCheckOut()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
<!--check out  modal end-->

<!--Service kit modal-->

<div
  class="modal fade"
  id="recipyModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Request Service kit</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-2 col-md-3 col-sm-12 col-12">
            <div class="pickers">
              <label>Store</label>

              <select
                class="form-control form-control-sm bootstrap-select-container"
                id="store"
                data-live-search="true"
                data-size="10"
                [(ngModel)]="store"
              >
                <option [value]="''" [selected]="true" [disabled]="true">
                  Select Store
                </option>
                <ng-container *ngFor="let data of stores">
                  <option [value]="data?.id">{{ data?.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-12 col-12">
            <div class="pickers">
              <label>Department</label>
              <select
                class="form-control form-control-sm lineItem"
                data-live-search="true"
                id="lineItem"
                [(ngModel)]="lineItem"
                (change)="getProductsById($event.target.value)"
              >
                <option value="" [selected]="true">Select Department</option>
                <ng-container *ngFor="let data of lineItems">
                  <option [value]="data?.id">{{ data?.type_name }}</option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="col-lg-2 col-md-3 col-sm-12 col-12">
            <div class="pickers">
              <label>Products</label>
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                id="products"
                [(ngModel)]="products"
                (change)="getProductId($event.target.value)"
              >
                <!--                  <select  class="form-control form-control-sm selectpicker" data-live-search="true" id="labTests" formControlName="search_title" (change)="setParameterCode($event.target.value)">-->
                <option value="" [selected]="true" [disabled]="true">
                  Select product
                </option>
                <ng-container *ngFor="let lp of list">
                  <option [value]="lp.id">
                    {{ lp.code }} - {{ lp.name }}
                    <span *ngIf="lp?.manufacturer_name">{{
                      "(" + lp?.manufacturer_name + ")"
                    }}</span>
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
          <!--              <div class="col-md-4 col-sm-12 col-12 mt-3 mt-sm-3 mt-md-0">-->
          <div
            class="col-lg-2 col-md-3 col-sm-12 col-12"
            style="margin-top: 1.5rem"
          >
            <button class="btn btn-primary btn-sm" (click)="addProduct()">
              <span>Add</span>
            </button>
          </div>

          <!--            <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">-->
          <!--              <span class="font-size-14" style="display: block;padding-top: 22px;" > <span class="setHeading font-weight-bolder">Service Name: </span>{{this.MiscServicesForm?.service_name}}</span>-->
          <!--              &lt;!&ndash;                <span class="font-size-14" style="display: block;padding-top: 8px;" *ngIf="labTest"> <span class="setHeading">Test Name: </span> {{labTest?.profile_name}}</span>&ndash;&gt;-->
          <!--            </div>-->
        </div>
        <form [formGroup]="servicesKit">
          <table class="table table-head-custom table-vertical-center w-100">
            <thead>
              <tr>
                <th style="width: 3%">Sr#</th>
                <th style="width: 10%">Code</th>
                <th style="width: 15%">Name</th>
                <th style="width: 10%">Brand</th>
                <th style="width: 10%">Department</th>
                <th style="width: 10%">Qty. Per Unit</th>
                <th style="width: 10%">Qty Required</th>
                <th style="width: 10%">UOM</th>
                <!--              <th style="width: 5%">Cost</th>-->
                <!--              <th style="width: 5%">T.Cost</th>-->
                <th style="width: 5%" class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                formArrayName="serviceKit"
                *ngFor="let product of controlDose.controls; let i = index"
              >
                <tr [formGroupName]="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ product?.get("code").value }}</td>
                  <!--                <td>{{product?.code}}</td>-->
                  <td>{{ product?.get("name").value }}</td>
                  <td>{{ product?.get("manufacturer_name").value || "--" }}</td>
                  <td>{{ product?.get("line_item").value }}</td>
                  <td>{{ product?.get("unit_quantity").value }}</td>
                  <td>
                    <!--                  <input type="text" class="form-control form-control-sm" placeholder="unit required" style="max-width: 125px" [(ngModel)]="product.unit_req">-->
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      value="1"
                      formControlName="unit_req"
                      placeholder="unit required"
                      style="max-width: 125px"
                      (input)="productCost($event.target.value)"
                    />
                    <!--                  <input type="number" class="form-control form-control-sm" value="1" formControlName="unit_req" placeholder="unit required" style="max-width: 125px" (keyup)="calculateServiceCostNew($event.target.value)">-->
                  </td>
                  <td>{{ product?.get("uom").value }}</td>
                  <!--                <td>{{product?.get('purchase_price').value}}</td>-->
                  <!--                <td>{{product?.get('purchase_price').value * product?.get('unit_req').value}}</td>-->
                  <td class="text-center">
                    <button
                      type="button"
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                      (click)="removeProductData(i)"
                    >
                      <!--                      <button type='button' class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3" (click)='removeLabParameter(i)'>-->
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                            />
                            <path
                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </g></svg
                        ><!--end::Svg Icon-->
                      </span>
                    </button>
                  </td>
                </tr>
              </ng-container>
              <!--            <ng-container>-->
              <!--              <tr>-->
              <!--                <td colspan="7">-->
              <!--                </td>-->
              <!--                <td style="font-weight: 700">Total Recipe Cost:</td>-->
              <!--                <td>70</td>-->
              <!--                <td></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td colspan="7">-->
              <!--                </td>-->
              <!--                <td style="font-weight: 700">Worker Cost:</td>-->
              <!--                <td><input type="number" class="form-control form-control-sm" placeholder="0"></td>-->
              <!--                <td></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td colspan="7">-->
              <!--                </td>-->
              <!--                <td style="font-weight: 700">Over Head Cost:</td>-->
              <!--                <td><input type="number" class="form-control form-control-sm" placeholder="0"></td>-->
              <!--                <td></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td colspan="7">-->
              <!--                </td>-->
              <!--                <td style="font-weight: 700">Other Cost:</td>-->
              <!--                <td><input type="number" class="form-control form-control-sm" placeholder="0"></td>-->
              <!--                <td></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td colspan="7">-->
              <!--                </td>-->
              <!--                <td style="font-weight: 700">Total Service Cost:</td>-->
              <!--                <td>70</td>-->
              <!--                <td></td>-->
              <!--              </tr>-->
              <!--            </ng-container>-->
            </tbody>
          </table>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="submitNewKit()"
        >
          Add Request
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="feedbackModal"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <!--        <span *ngIf="requestStatus === 1">Approve</span> <span *ngIf="requestStatus === 1">Reject</span>-->
        <h5 class="modal-title">We Value your feedback</h5>
        <a type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </a>
      </div>
      <!--Modal Body-->
      <form [formGroup]="feedbackForm" (ngSubmit)="submitFeedbackForm()">
        <div class="modal-body pb-0">
          <div class="row">
            <div class="col-12 d-flex">
              <div class="flex-shrink-0 mr-2 mt-lg-0 mt-3" style="padding: 8px">
                <div class="symbol symbol-45 symbol-lg-45">
                  <img
                    class="medical_fitness_certificate_image"
                    src="assets/media/users/blank.png"
                    alt="image"
                  />
                </div>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex">
                    <a
                      class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                      >{{ clientData?.first_name }}
                      {{ clientData?.last_name }}</a
                    >
                  </div>
                </div>
                <!--end::Title-->
                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="d-flex flex-wrap">
                      <a
                        class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0"
                        >{{ clientData?.gender }} {{ " " }}
                        {{ clientData?.age }}</a
                      >
                    </div>
                    <div class="d-flex flex-wrap">
                      <a
                        class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0"
                        >{{ clientData?.crn }} {{ " " }}
                        {{ clientData?.contact }}</a
                      >
                    </div>
                  </div>
                </div>
                <!--end::Content-->
              </div>
            </div>
          </div>
          <div>
            <div class="table-responsive position-relative">
              <table
                class="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_12"
              >
                <thead>
                  <tr class="text-left">
                    <th class="pr-0" style="width: 45%">
                      <span class="d-block">Services Taken</span>
                    </th>
                    <th class="text-center" style="width: 20%">
                      <span class="d-block">Select Service</span>
                    </th>
                    <th style="width: 35%; text-align: right">
                      <span class="d-block">How was your expericnce?</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!--              <tr *ngFor="let worker of workerData; let i = index">-->
                  <!--              <tr *ngFor="let service of feedBackData; let i = index">-->
                  <tr
                    formArrayName="services"
                    *ngFor="let item of controlFeedback.controls; let i = index"
                  >
                    <ng-container [formGroupName]="i">
                      <td>
                        <span class="d-block font-weight-bold">{{
                          item.get("name").value
                        }}</span>
                      </td>
                      <td class="text-center">
                        <div class="d-inline-flex align-items-center mb-4">
                          <label class="checkbox checkbox-sm">
                            <input
                              type="checkbox"
                              name="acknowledge_"
                              id="is_check{{ i }}"
                              [checked]="item.get('is_check').value === true"
                              (input)="changeStatus(i)"
                              class="is_acknowledged checkbox singleCheck"
                            />
                            <!--                             id="singleCheck{{i}}" [(ngModel)]="group.service_assigned">-->
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td
                        style="
                          justify-content: flex-end;
                          text-align: right;
                          display: flex;
                        "
                      >
                        <span
                          class="switch switch-icon"
                          *ngIf="item.get('is_check').value === true"
                        >
                          <label class="pr-2">Unsatisfied</label>
                          <label>
                            <input
                              type="checkbox"
                              checked="checked"
                              id="is_satisfied{{ i }}"
                              [checked]="
                                item.get('is_satisfied').value === true
                              "
                              (input)="changeStatusSatisfied(i)"
                            />
                            <!--                                   <input type="checkbox" checked="checked" name="select" [(ngModel)]="showFullReport"/>-->
                            <span></span>
                          </label>
                          <label class="pr-2">Satisfied</label>
                        </span>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
              <!--            <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>-->
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="form-group">
              <label>How can we improve?</label>
              <textarea
                type="text"
                class="form-control form-control-sm"
                formControlName="feedback_suggestion"
              ></textarea>
            </div>
          </div>
        </div>
        <!--Modal Footer-->
        <div class="modal-footer">
          <a
            type="button"
            class="btn btn-light-primary font-weight-bold"
            data-dismiss="modal"
            >Close</a
          >
          <!--        <a type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Clear</a>-->
          <button type="submit" class="btn btn-primary font-weight-bold">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!--Notification Modal Start-->
<!--<div class="modal fade" id="notificationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"-->
<!--     aria-hidden="true">-->
<!--  <div class="modal-dialog modal-md" role="document">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-header pt-2 pb-2">-->
<!--        <h5 class="modal-title">Time Notification</h5>-->
<!--        &lt;!&ndash;                <h5 class="modal-title" *ngIf="tagId">Update Tag</h5>&ndash;&gt;-->
<!--        <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--          <span aria-hidden="true">&times;</span>-->
<!--        </button>-->
<!--      </div>-->
<!--      <div class="modal-body">-->
<!--        <div class="row">-->
<!--          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">-->
<!--            <h5>Service time out, please checkout the client</h5>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="modal-footer p-3">-->
<!--        <button type="button" class="btn btn-sm btn-default" (click)="closeNotificationModal()">Close</button>-->
<!--&lt;!&ndash;        <button type="submit" class="btn btn-sm btn-primary" (click)="submitCheckOut()">Submit</button>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--Notification Modal End-->
