import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientService } from '../../../services/patient.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OpdService } from '../../../services/opd.service';
import { ActivatedRoute } from '@angular/router';

declare var toastr: any;
@Component({
  selector: 'app-patient-medicine-detail',
  templateUrl: './patient-medicine-detail.component.html',
  styleUrls: ['./patient-medicine-detail.component.css'],
})
export class PatientMedicineDetailComponent implements OnInit {
  patients: any;
  PatientSearchForm: UntypedFormGroup;
  paitentMedicineHistory = [];
  patientAllHistory = false;
  requestedId: any;
  medicineId: any;
  visitID: any;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
  ) {
    this.requestedId = this.route.snapshot.queryParamMap.get('id');
    this.medicineId = this.route.snapshot.queryParamMap.get('medicineId');
  }
  ngOnInit(): void {
    this.fetchPatientInfo();
  }
  fetchPatientInfo(): any {
    this.ngxLoader.start();
    this.patientService.patientAndMedicinesData(this.requestedId).subscribe(
      (data) => {
        if (data) {
          this.patients = data.patient_data.patient;
          this.paitentMedicineHistory = data.data;
          const medicineData = this.paitentMedicineHistory.find(
            (t) => t.id === Number(this.medicineId),
          );
          if (medicineData) {
            this.paitentMedicineHistory = [];
            this.paitentMedicineHistory.push(medicineData);
          }
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
}
