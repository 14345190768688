import { Component, OnInit } from '@angular/core';
import { PdfServicesService } from '../../../services/pdf-services.service';
import { DialysisService } from '../../../services/dialysis.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-dialysis-report',
  templateUrl: './dialysis-report.component.html',
  styleUrls: ['./dialysis-report.component.css'],
})
export class DialysisReportComponent implements OnInit {
  settingData: any;
  dialysisReportData: any;
  dialysisId: any;
  userName: any;
  today = new Date();

  constructor(
    private pdfService: PdfServicesService,
    private dialysisService: DialysisService,
    private ngxLoader: NgxUiLoaderService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getSettingsData();
    this.route.queryParams.subscribe((params) => {
      this.dialysisId = params['dialysis_id'] || '';
    });
    this.userName = JSON.parse(localStorage.getItem('currentUser'));
    this.getDialysisReportData();
  }

  getSettingsData(): any {
    this.pdfService.getMedicalUnitDetailsV2().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  getDialysisReportData(): any {
    this.ngxLoader.start();
    this.dialysisService.getDialysisReportData(this.dialysisId).subscribe(
      (data) => {
        this.dialysisReportData = data;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  printReport(): any {
    window.print();
  }
}
