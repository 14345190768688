<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div
    class="subheader py-2 py-lg-6 subheader-solid d-print-none"
    id="kt_subheader"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-baseline justify-content-between flex-wrap mr-5 w-100"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <div class="dropdown dropdown-inline mr-2">
            <button class="btn btn-light-primary ml-3" (click)="printReport()">
              Print
            </button>
            <!--end::Dropdown Menu-->
          </div>
          <!--          <button class="btn btn-sm btn-primary" [routerLink]="['/roster/shifts-detail']"><i class="fas fa-long-arrow-alt-left"></i> Back</button>-->
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::Card-->
      <div class="card card-custom gutter-b d-print-none">
        <div class="card-body">
          <div class="row roster-bar">
            <!--Select Month-->
            <div class="col-12 col-sm-3">
              <div class="form-group">
                <label>From Date <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  name="date"
                  id="fromDate"
                  readonly="readonly"
                  placeholder="Select Date"
                  readonly="readonly"
                />
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <div class="form-group">
                <label>To Date <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  name="date"
                  id="toDate"
                  readonly="readonly"
                  placeholder="Select Date"
                  readonly="readonly"
                />
              </div>
            </div>
            <!--Department-->
            <div class="col-12 col-sm-3">
              <div class="d-block form-group">
                <label>Department:</label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  multiple
                  id="department"
                  name="department"
                  (change)="getEmployeesOfDepartment($event.target.value)"
                  [(ngModel)]="department"
                >
                  <option value="" disabled>Select Department</option>
                  <ng-container *ngFor="let dep of depart">
                    <option [value]="dep.id">{{ dep.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--Employee Of Department-->
            <div class="col-12 col-sm-3">
              <div class="d-block form-group">
                <label>Employee:</label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  multiple
                  id="employeeDepartmentList"
                  name="employee"
                  [(ngModel)]="employeesOfSelectedDepartment"
                >
                  <option value="" [disabled]="true">Select Employee</option>
                  <ng-container *ngFor="let emp of employeesOfDepart">
                    <option [value]="emp.id">
                      {{ emp?.employee_file_no }} - {{ emp?.first_name }}
                      <span *ngIf="emp?.middle_name !== null">
                        {{ emp?.middle_name }}
                      </span>
                      {{ emp?.last_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--Location-->
            <div class="col-12 col-sm-3">
              <div class="d-block form-group">
                <label>Location:</label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  multiple
                  id="selectedLocation"
                  name="selectedLocation"
                  [(ngModel)]="selectedLocation"
                >
                  <option value="">Select Location</option>
                  <ng-container *ngFor="let location of locationData">
                    <option [value]="location.id">{{ location?.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <div class="d-block form-group">
                <button
                  class="btn btn-light-primary btn-sm"
                  (click)="appendArray()"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <!--shifts-->
        </div>
      </div>
      <!--end::Card-->
      <div class="card card-custom gutter-b" *ngIf="employeeRoasterData.length">
        <div class="card-body">
          <!--        <div *ngIf="list?.length > 0"> -->
          <div class="mb-6 d-none d-print-block">
            <div class="d-flex">
              <div style="width: 85%">
                <!-- header table -->
                <table class="table">
                  <tbody>
                    <tr>
                      <td style="width: 15%; padding: 0" class="border-top-0">
                        <img
                          [src]="settingData?.logo?.url"
                          style="width: auto; height: 106px"
                        />
                      </td>
                      <td style="width: 85%" class="border-top-0">
                        <div class="text-center">
                          <h3>{{ settingData?.title }}</h3>
                        </div>
                        <div class="font-size-h5 text-center">
                          <h5>{{ settingData?.address }}</h5>
                        </div>
                        <div class="text-center">
                          <h3>
                            Attendance Report Of {{ fromDate }} to {{ toDate }}
                          </h3>
                        </div>
                        <div class="text-center">
                          <h4
                            *ngIf="dummyDepartArray.length > 0; else noData"
                            class="dummyArray"
                          >
                            <span *ngFor="let a of dummyDepartArray"
                              ><span class="text">{{ a }}</span
                              ><span class="comma">,</span></span
                            >
                          </h4>
                          <ng-template #noData
                            ><h4>All Departments</h4></ng-template
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            class="table-responsive position-relative employeeShiftTable"
            *ngIf="shiftLength.length > 0"
          >
            <table
              class="table shift-report-table"
              id="kt_advance_table_widget_1"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <tr class="text-left">
                  <th class="text-center serialNumber">Sr#</th>
                  <th style="width: 170px; min-width: 170px !important;">Name</th>
                  <th
                    style="text-align: center"
                    *ngFor="let da of shiftLength; let i = index"
                  >
                    <span style="width: 100px; min-width: 100px">{{
                      da.date
                    }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let employeeData of employeeRoasterData;
                    let i = index
                  "
                >
                  <td class="text-center">{{i + 1}}</td>
                  <td>
                    <span class="d-block" style="word-break: break-word"
                      >{{ employeeData?.employee?.first_name }}
                      {{ employeeData?.employee?.middle_name || "" }}
                      {{ employeeData?.employee?.last_name }}</span
                    >
                    <span style="word-break: break-word; margin-right: 3px">{{
                      employeeData?.employee?.employee_file_no
                    }}</span>
                    <span style="word-break: break-word">
                      {{ employeeData?.employee?.designation?.name }}</span
                    >
                  </td>

                  <td
                    style="text-align: center"
                    class="coma-sp fontSize"
                    *ngFor="let employee of employeeData?.data"
                  >
                    <ng-container
                      *ngFor="let shif of employee?.attendance"
                      class="pb-1"
                    >
                      <span
                        class="label label-lg label-inline label-success mb-1"
                        style="color: #ffffff; width: 70px"
                        *ngIf="shif?.status === 1"
                        >P</span
                      >
                      <span
                        class="label label-lg label-inline label-danger mb-1"
                        style="color: #ffffff; width: 70px"
                        *ngIf="shif?.status === 2"
                        >A</span
                      >
                      <span
                        class="label label-lg label-inline label-dark mb-1"
                        style="color: #ffffff; width: 70px"
                        *ngIf="shif?.status === 3"
                        >L</span
                      >
                      <span
                        class="label label-lg label-inline label-warning mb-1"
                        style="color: #ffffff; width: 70px"
                        *ngIf="shif?.status === 4"
                        >DO</span
                      >
                      <span
                        class="label label-lg label-inline label-primary mb-1"
                        style="color: #ffffff; width: 70px"
                        *ngIf="shif?.status === 5"
                        >GO</span
                      >
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal-->
<!--<div class="modal fade" id="promissionsAlertModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
<!--  <div class="modal-dialog" role="document">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-body">-->
<!--        <div class="text-center">-->
<!--          <img src="../../../assets/media/error/allert.png">-->
<!--          <p class="font-weight-bold font-size-h5 text-dark-75 mt-3">No Record Found!</p>-->
<!--          &lt;!&ndash;          <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
