import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SystemConfigurationService } from '../../services/system-configuration.service';

declare var toastr: any;
@Component({
  selector: 'app-register-organization',
  templateUrl: './register-organization.component.html',
  styleUrls: ['./register-organization.component.css'],
})
export class RegisterOrganizationComponent implements OnInit {
  orgDetail = true;
  adminDetail = false;
  planDetail = false;
  prepareSetup = false;
  isAdd = false;
  orgForm: UntypedFormGroup;
  completeStatus = 0;
  inputValue = '';
  modulesList = [];
  constructor(
    private fb: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private systemServices: SystemConfigurationService,
  ) {
    this.orgForm = this.fb.group({
      title: [''],
      address: [''],
      phone_number: [''],
      email: [
        '',
        Validators.compose([
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$'),
        ]),
      ],
      website_url: [''],
      fax: [''],
      domain_name: [''],
      user_information: this.fb.group({
        first_name: [''],
        last_name: [''],
        user_name: [''],
        email: [
          '',
          Validators.compose([
            Validators.email,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$'),
          ]),
        ],
        phone: [''],
        password: [''],
      }),
      modules_controls: this.fb.array([]),
      // modules_controls: this.fb.group({
      //   front_desk: [true],
      //   ris: [false],
      //   lis: [false],
      //   pharmacy: [false],
      //   ot: [false],
      //   dialysis: [false],
      //   opd: [false],
      //   ipd: [false],
      //   blood_bank: [false],
      //   emergency: [false],
      //   donation: [false],
      //   hr: [false],
      //   pay_roll: [false],
      //   roastr: [false],
      //   inventory: [false],
      //   finance: [false],
      //   complaint_manage: [false]
      // })
    });
  }
  goToOrgDetail(): any {
    this.orgForm.get('user_information').get('first_name').clearValidators();
    this.orgForm
      .get('user_information')
      .get('first_name')
      .updateValueAndValidity();
    this.orgForm.get('user_information').get('last_name').clearValidators();
    this.orgForm
      .get('user_information')
      .get('last_name')
      .updateValueAndValidity();
    this.orgForm.get('user_information').get('phone').clearValidators();
    this.orgForm.get('user_information').get('phone').updateValueAndValidity();
    this.orgForm.get('user_information').get('email').clearValidators();
    this.orgForm.get('user_information').get('email').updateValueAndValidity();
    this.orgForm.get('user_information').get('user_name').clearValidators();
    this.orgForm
      .get('user_information')
      .get('user_name')
      .updateValueAndValidity();
    this.orgForm.get('user_information').get('password').clearValidators();
    this.orgForm
      .get('user_information')
      .get('password')
      .updateValueAndValidity();
    this.orgDetail = true;
    this.adminDetail = false;
    if (this.completeStatus === 3) {
      this.completeStatus = 0;
    }
    this.isAdd = false;
    this.planDetail = false;
    this.prepareSetup = false;
  }
  _onlyChar(event): void {
    const inputChar = event.key;
    // Check if the input character is not a letter (A-Z or a-z)
    if (!inputChar.match(/[A-Za-z]/)) {
      event.preventDefault(); // Prevent the character from being inserted
    }
  }
  goToPlanDetail(): any {
    this.orgForm
      .get('user_information')
      .get('first_name')
      .setValidators([Validators.required]);
    this.orgForm
      .get('user_information')
      .get('first_name')
      .updateValueAndValidity();
    this.orgForm
      .get('user_information')
      .get('last_name')
      .setValidators([Validators.required]);
    this.orgForm
      .get('user_information')
      .get('last_name')
      .updateValueAndValidity();
    this.orgForm
      .get('user_information')
      .get('phone')
      .setValidators([Validators.required]);
    this.orgForm.get('user_information').get('phone').updateValueAndValidity();
    this.orgForm
      .get('user_information')
      .get('email')
      .setValidators(
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$'),
        ]),
      );
    this.orgForm.get('user_information').get('email').updateValueAndValidity();
    this.orgForm
      .get('user_information')
      .get('user_name')
      .setValidators([Validators.required]);
    this.orgForm
      .get('user_information')
      .get('user_name')
      .updateValueAndValidity();
    this.orgForm
      .get('user_information')
      .get('password')
      .setValidators([Validators.required]);
    this.orgForm
      .get('user_information')
      .get('password')
      .updateValueAndValidity();
    if (this.orgForm.invalid) {
      this.isAdd = true;
      return;
    }
    this.orgDetail = false;
    this.adminDetail = false;
    this.prepareSetup = false;
    this.planDetail = true;
    this.completeStatus = 2;
  }
  goToAdminDetail(status): any {
    this.orgForm.get('title').setValidators([Validators.required]);
    this.orgForm.get('title').updateValueAndValidity();
    this.orgForm.get('address').setValidators([Validators.required]);
    this.orgForm.get('address').updateValueAndValidity();
    this.orgForm.get('phone_number').setValidators([Validators.required]);
    this.orgForm.get('phone_number').updateValueAndValidity();
    this.orgForm
      .get('email')
      .setValidators(
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$'),
        ]),
      );
    this.orgForm.get('email').updateValueAndValidity();
    this.orgForm.get('website_url').setValidators([Validators.required]);
    this.orgForm.get('website_url').updateValueAndValidity();
    this.orgForm.get('fax').setValidators([Validators.required]);
    this.orgForm.get('fax').updateValueAndValidity();
    this.orgForm.get('domain_name').setValidators([Validators.required]);
    this.orgForm.get('domain_name').updateValueAndValidity();
    if (this.orgForm.invalid) {
      this.isAdd = true;
      return;
    }
    this.orgDetail = false;
    this.adminDetail = true;
    this.planDetail = false;
    this.prepareSetup = false;
    if (status && this.completeStatus !== 2) {
      this.completeStatus = 1;
    }
  }
  Submit(): any {
    if (this.orgForm.invalid) {
      this.isAdd = true;
      return;
    }
    while (this.ModulesControls.length) {
      this.ModulesControls.removeAt(0);
    }
    this.modulesList.forEach((moduleItem) => {
      this.ModulesControls.push(this.fb.control(moduleItem));
    });
    this.orgForm
      .get('domain_name')
      .setValue(this.orgForm.get('domain_name').value.toLowerCase());
    this.ngxLoader.start();
    this.systemServices.submitDetail(this.orgForm.value).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Add Successfully');
        this.completeStatus = 0;
        this.orgForm.reset();
        this.completeStatus = 3;
        this.prepareSetup = true;
        this.orgDetail = false;
        this.adminDetail = false;
        this.planDetail = false;
        this.isAdd = false;
        this.inputValue = '';
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  get ModulesControls(): any {
    return this.orgForm.get('modules_controls') as UntypedFormArray;
  }
  _onlyNumeric(event: any): any {
    const pattern = /[0-9 .]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  getModules(): any {
    this.systemServices.getModulesList().subscribe(
      (data) => {
        if (data.menus.length) {
          this.modulesList = data.menus.map((t) => {
            return { ...t, is_selected: false };
          });
        }
        if (this.modulesList.length) {
          this.modulesList.map((t) => {
            if (t.is_mandatory) {
              t.is_selected = true;
            }
          });
        }
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  activeModule(i): any {
    this.modulesList[i].is_selected = true;
  }
  ngOnInit(): void {
    this.getModules();
  }
}
