<div>
  <!--begin::Card-->
  <form [formGroup]="anesthesiaConsentForm">
    <div class="card mb-4" *ngIf="isAdd">
      <div class="card-body user-card-body">
        <div class="row m-0 mb-4">
          <h4>Consent Form</h4>
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="">
              <div class="row m-0 mb-3">
                <div class="col-md-4 col-sm-4 col-12">
                  <div class="form-group">
                    <label
                      >Consent Template
                      <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-control form-control-sm"
                      id="consentTemplate"
                      formControlName="consent_templete_id"
                      (change)="
                        selectAnesthesiaTemplate($event.target.value);
                        clearCanvasAn()
                      "
                      [ngClass]="{
                        'is-invalid':
                          isSubmitAnesthesiaConsent &&
                          cont.consent_templete_id.errors
                      }"
                    >
                      <option value="" [selected]="true" [disabled]="true">
                        Select Consent Template
                      </option>
                      <option
                        [value]="templete.id"
                        *ngFor="let templete of consentTempletes"
                      >
                        {{ templete.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4 col-12">
                  <div class="form-group">
                    <label
                      >Consent Type <span class="text-danger">*</span></label
                    >
                    <div
                      [ngClass]="{
                        'is-invalid':
                          isSubmitAnesthesiaConsent &&
                          cont.anesthesia_type.errors
                      }"
                    >
                      <select
                        class="form-control form-control-sm"
                        formControlName="consent_type"
                        (change)="proposedAnesthesia()"
                      >
                        <option value="General" [selected]="true">
                          General
                        </option>
                        <option value="Anesthesia">Anesthesia</option>
                      </select>
                    </div>
                  </div>
                </div>
                <ng-container
                  *ngIf="
                    this.anesthesiaConsentForm.get('consent_type').value ===
                    'Anesthesia'
                  "
                >
                  <div class="col-md-4 col-sm-4 col-12">
                    <div class="form-group">
                      <label
                        >Anesthetist Name
                        <span class="text-danger">*</span></label
                      >
                      <div
                        [ngClass]="{
                          'is-invalid':
                            isSubmitAnesthesiaConsent && cont.employee.errors
                        }"
                      >
                        <select
                          class="form-control form-control-sm"
                          id="allEmployee"
                          data-live-search="true"
                          formControlName="employee"
                        >
                          <option value="" [selected]="true">
                            Select Anesthetist
                          </option>
                          <option *ngFor="let doc of doctor" [value]="doc.id">
                            {{ doc.first_name }} {{ doc.last_name }}
                            <span *ngIf="doc?.doctor_department"
                              >({{ doc?.doctor_department?.title }})</span
                            >
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-4 col-12">
                    <div class="form-group">
                      <label
                        >Anesthesia Type
                        <span class="text-danger">*</span></label
                      >
                      <div>
                        <select
                          class="form-control form-control-sm"
                          formControlName="anesthesia_type"
                          [ngClass]="{
                            'is-invalid':
                              isSubmitAnesthesiaConsent &&
                              cont.anesthesia_type.errors
                          }"
                        >
                          <option value="" [selected]="true">
                            Select Anesthesia type
                          </option>
                          <option value="Local Anesthesia">
                            Local Anesthesia
                          </option>
                          <option value="General Anesthesia">
                            General Anesthesia
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-4 col-12">
                    <div class="form-group">
                      <label
                        >Proposed Anesthesia Technique
                        <span class="text-danger">*</span></label
                      >
                      <div
                        [ngClass]="{
                          'is-invalid':
                            isSubmitAnesthesiaConsent && cont.technique.errors
                        }"
                      >
                        <select
                          class="form-control form-control-sm"
                          id="anesthesiaTechnique"
                          data-live-search="true"
                          multiple
                          formControlName="technique"
                          title="Select technique"
                        >
                          <!-- <option value="" [selected]=true [disabled]="true">Select technique</option> -->
                          <!--                                      <option value="" [selected]=true [disabled]="true">Select technique</option>-->
                          <option value="General">General</option>
                          <option value="Reginal">Reginal</option>
                          <option value="Nerve Block">Nerve Block</option>
                          <option value="Sedation">Sedation</option>
                          <option value="MAC">MAC</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>

              <input
                type="hidden"
                [ngModel]="consent_templete?.id"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isSelectTemplete && isAdd">
      <div class="card mb-4">
        <div class="card-body user-card-body">
          <div
            *ngFor="
              let consent_templete_paragraph of consent_templete?.template_data;
              let i = index
            "
          >
            <div class="row mx-0 my-1">
              <div class="col-md-12 col-sm-12 col-12">
                <label>{{ consent_templete_paragraph.heading }}</label>
              </div>
              <div class="col-md-12 col-sm-12 col-12">
                <div
                  class="paragraph_text"
                  id="{{ 'paragraph' + i }}"
                  [innerHTML]="consent_templete_paragraph.detail"
                ></div>
                <!--                  <textarea class="paragraph_text" id="{{'paragraph'+i}}" rows="5" cols="20" [value]="consent_templete_paragraph.paragraph"></textarea>-->
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>

      <div>
        <div class="card mb-4">
          <div class="card-body user-card-body">
            <div class="row m-0 mt-3">
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label
                    >Relative Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="relative_name1"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitAnesthesiaConsent && cont.relative_name1.errors
                    }"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Relation <span class="text-danger">*</span></label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="relative_relation1"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitAnesthesiaConsent &&
                        cont.relative_relation1.errors
                    }"
                  >
                    <option value="">Select</option>
                    <ng-container *ngFor="let lov of lookups">
                      <option
                        [value]="lov.key"
                        *ngIf="lov.category == 'relationship'"
                      >
                        {{ lov.key }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Witness Name</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="witness_name1"
                  />
                </div>
              </div>
              <!--                                <div class="col-md-3 col-sm-6 col-12">-->
              <!--                                  <div class="form-group">-->
              <!--                                    <label>Relation</label>-->
              <!--                                    <select class="form-control form-control-sm" formControlName="witness_relation1">-->
              <!--                                      <option value="">Select</option>-->
              <!--                                      <ng-container *ngFor="let lov of lookups">-->
              <!--                                        <option [value]="lov.key" *ngIf="lov.category == 'relationship'">{{lov.key}}-->
              <!--                                        </option>-->
              <!--                                      </ng-container>-->
              <!--                                    </select>-->
              <!--                                  </div>-->
              <!--                                </div>-->
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Doctor <span class="text-danger">*</span></label>
                  <div
                    class=""
                    [ngClass]="{
                      'is-invalid':
                        isSubmitAnesthesiaConsent && cont.consultant_id.errors
                    }"
                  >
                    <select
                      class="form-control form-control-sm bootstrap-select-container"
                      data-size="7"
                      id="allConsultant"
                      data-live-search="true"
                      formControlName="consultant_id"
                    >
                      <option value="" [selected]="true">Select Doctor</option>
                      <option *ngFor="let doc of doctor" [value]="doc.id">
                        {{ doc.first_name }} {{ doc.last_name }}
                        <span *ngIf="doc?.doctor_department"
                          >({{ doc?.doctor_department?.title }})</span
                        >
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="card mb-4" [ngClass]="{ 'd-none': isAdd === false }">
      <div class="card-body user-card-body">
        <ng-container>
          <img class="ml-2" src="{{ anesthesiaImageUrl }}" />
          <label class="signature ml-2">Sign Here </label>
          <div class="signature ml-2">
            <canvas
              class="border"
              #canvasDataAnesthesia
              width="300"
              height="200"
            ></canvas>
          </div>
        </ng-container>
        <div class="">
          <ng-container *ngIf="isSelectTemplete">
            <button
              *ngIf="urlButton"
              type="button"
              class="btn btn-sm btn-primary float-left"
              (click)="updateSignatureAn()"
            >
              Update Signature
            </button>
          </ng-container>
          <ng-container *ngIf="isSelectTemplete">
            <button
              *ngIf="!urlButton"
              class="btn btn-outline-primary ml-2 float-left"
              (click)="clearCanvasAn()"
            >
              Clear Signature
            </button>
          </ng-container>
          <button
            class="btn btn-primary btn-sm w-100px mr-2 float-right"
            type="button"
            (click)="saveAnesthesiaConsentForm()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="card mb-4">
    <div class="card-body user-card-body">
      <table
        class="table table-head-custom table-vertical-center table-a"
        id="kt_advance_table_widget_1"
      >
        <thead>
          <tr>
            <td style="width: 25%">Date / Time</td>
            <td style="width: 65%">Consent Name</td>
            <td style="width: 10%">Action</td>
          </tr>
        </thead>
        <tr *ngFor="let ct of consentPatientTemplate">
          <td>{{ ct?.created_at | date: "dd-MM-yyyy h:mm a" }}</td>
          <td>{{ ct?.name }}</td>
          <td>
            <a
              class="btn btn-primary btn-sm"
              (click)="printAnesthesiaForm(ct.id)"
            >
              <span class="navi-icon">
                <i class="la la-print"></i>
              </span>
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <!--end::Card-->
</div>
