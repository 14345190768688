<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-baseline justify-content-between flex-wrap mr-5 w-100"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            Attendance Status Policy
          </h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center"></div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid m-0">
      <!--begin::Card-->
      <!--end::Card-->

      <div class="row">
        <div class="col-12">
          <!--Check In Card-->
          <form
            [formGroup]="attendancePolicy"
            (ngSubmit)="onPolicySubmitSubmit()"
          >
            <div class="card card-custom gutter-b">
              <div class="card-body py-3">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label>Code</label>
                      <input
                        class="text"
                        class="form-control form-control-sm"
                        name="code"
                        formControlName="code"
                        [ngClass]="{
                          'is-invalid':
                            isAdd && this.attendancePolicy.controls.code.errors
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        class="text"
                        class="form-control form-control-sm"
                        name="name"
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid':
                            isAdd && this.attendancePolicy.controls.name.errors
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Description</label>
                      <input
                        class="text"
                        class="form-control form-control-sm"
                        name="description"
                        formControlName="description"
                        [ngClass]="{
                          'is-invalid':
                            isAdd &&
                            this.attendancePolicy.controls.description.errors
                        }"
                      />
                    </div>
                  </div>
                  <!--                    <div class="col-sm-2">-->
                  <!--                      <div class="form-group">-->
                  <!--                        <label>Status</label>-->
                  <!--                        <span class="switch switch-icon">-->
                  <!--                          <label>-->
                  <!--                            <input type="checkbox" name="status" formControlName="status" [ngClass]="{ 'is-invalid': isAdd && this.attendancePolicy.controls.status.errors }" />-->
                  <!--                            <span></span>-->
                  <!--                          </label>-->
                  <!--                        </span>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                </div>
              </div>
            </div>

            <div class="card card-custom gutter-b">
              <div class="card-body py-3">
                <ng-container
                  formArrayName="attendance_rules"
                  *ngFor="let g of attendance_rules.controls; let i = index"
                >
                  <div class="row" [formGroupName]="i">
                    <div class="col-2">
                      <div class="form-group">
                        <label>Check In</label>
                        <select
                          class="form-control form-control-sm"
                          name="check_in_status"
                          formControlName="check_in_status"
                          [ngClass]="{
                            'is-invalid':
                              isAdd && g.controls.check_in_status.errors
                          }"
                        >
                          <option value="1">On Time</option>
                          <option value="2">Early In</option>
                          <option value="3">Late In</option>
                          <option value="4">Absent</option>
                          <option value="5">Pending</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label>Check Out</label>
                        <select
                          class="form-control form-control-sm"
                          name="check_out_status"
                          formControlName="check_out_status"
                          [ngClass]="{
                            'is-invalid':
                              isAdd && g.controls.check_out_status.errors
                          }"
                        >
                          <option value="1">On Time</option>
                          <option value="2">Late Out</option>
                          <option value="3">Early Out</option>
                          <option value="4">Absent</option>
                          <option value="5">Pending</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="form-group">
                        <label>Status</label>
                        <select
                          class="form-control form-control-sm"
                          name="status"
                          formControlName="status"
                          [ngClass]="{
                            'is-invalid': isAdd && g.controls.status.errors
                          }"
                        >
                          <option value="1">Present</option>
                          <option value="2">Absent</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <button
                          (click)="removePolicy(i)"
                          class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                          type="button"
                        >
                          <span class="svg-icon svg-icon-primary svg-icon-2x"
                            ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                />
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                              </g></svg
                            ><!--end::Svg Icon--></span
                          >
                        </button>

                        <button
                          (click)="addMore()"
                          class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-3"
                          type="button"
                        >
                          <span class="svg-icon svg-icon-primary svg-icon-2x"
                            ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                <path
                                  d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                  fill="#000000"
                                />
                              </g></svg
                            ><!--end::Svg Icon-->
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div class="text-right mt-3">
                  <button class="btn btn-sm btn-primary" type="submit">
                    Update Policy
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end Internal policy Level-->
  </div>
</div>
