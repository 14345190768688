<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <!--          <h5 class="text-dark font-weight-bold my-1 mr-5" *ngIf="activeScreenInd"> Update Lookups</h5>-->
        </div>
      </div>
      <div class="d-flex align-items-center">
        <button
          type="button"
          class="btn btn-sm btn-light-primary"
          (click)="openHolidayModal()"
        >
          Add Gazetted Holiday
        </button>
      </div>
    </div>
  </div>

  <!--  Table-->
  <div class="d-flex flex-column-fluid main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="card mb-4">
            <div class="card-body body-padding">
              <table class="table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <th style="width: 50%">Name</th>
                    <th style="width: 20%">Date</th>
                    <th style="width: 20%">Status</th>
                    <th style="width: 10%; text-align: right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let list of filterList">
                    <tr>
                      <td>{{ list?.name }}</td>
                      <td>{{ list?.day }}</td>
                      <td class="">
                        <span
                          class="label label-lg label-light-primary label-inline"
                          *ngIf="list?.status === true"
                          >Active</span
                        >
                        <span
                          class="label label-lg label-light-danger label-inline"
                          *ngIf="list?.status === false"
                          >Inactive</span
                        >
                      </td>
                      <td style="text-align: right">
                        <button
                          type="button"
                          class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                          (click)="edit(list, list?.id)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                                ></path>
                                <path
                                  d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  opacity="0.3"
                                ></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <!--          pagination-->
              <div class="pagination_main">
                <ngb-pagination
                  [(page)]="page"
                  (pageChange)="loadPage(page)"
                  [pageSize]="perPage"
                  [collectionSize]="totalPages"
                  [maxSize]="5"
                  [rotate]="true"
                  [ellipses]="false"
                  [boundaryLinks]="true"
                ></ngb-pagination>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle btn-light btn-sm"
                    role="button"
                    id="pagination_btn"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="page_count">
                      {{ perPageValue }}
                    </span>
                    <span class="count_count_icon"></span>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="pagination_btn">
                    <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                      >25</a
                    >
                    <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                      >50</a
                    >
                    <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                      >75</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="selectPaginationLimit(100)"
                      >100</a
                    >
                    <a class="dropdown-item" (click)="selectPaginationLimit('')"
                      >All</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal-->

<div
  class="modal fade"
  id="holidayModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="!activeScreenInd">
          Add Gazetted Holiday
        </h5>
        <h5 class="modal-title" *ngIf="activeScreenInd">
          Update Gazetted Holiday
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="holidayForm" (ngSubmit)="onsubmit()">
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Name <span class="text-danger">*</span></label>
                <input
                  type="text"
                  placeholder="Name"
                  class="form-control form-control-sm"
                  formControlName="name"
                  [ngClass]="{
                    'is-invalid': isAdd && this.holidayForm.controls.name.errors
                  }"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Date:</label>
                <input
                  type="text"
                  class="form-control form-control-sm date"
                  placeholder="Date"
                  id="date"
                  formControlName="date"
                  readonly="readonly"
                  [ngClass]="{
                    'is-invalid': isAdd && this.holidayForm.controls.date.errors
                  }"
                />
              </div>
            </div>
            <!--            Status-->
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label>Status</label>
                <br />
                <span class="switch switch-icon">
                  <label>
                    <input
                      type="checkbox"
                      name="status"
                      formControlName="status"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!--          <button type="submit" class="btn btn-sm btn-primary">Add</button>-->
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            *ngIf="!activeScreenInd"
          >
            Add
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            *ngIf="activeScreenInd"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
