import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RadiologyService } from '../../../services/radiology.service';
import { Router } from '@angular/router';
import { TechAcknowledgementService } from '../../../services/tech-acknowledgement.service';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;
declare var tinymce: any;
@Component({
  selector: 'app-report-addendum-v2',
  templateUrl: './report-addendum-v2.component.html',
  styleUrls: ['./report-addendum-v2.component.css'],
})
export class ReportAddendumV2Component implements OnInit {
  @ViewChild('conformCancelModal') conformCancelModal: ElementRef;
  pageDataList: any;
  manufactureSearchValue = '';
  history = '';
  impression = '';
  type = '';
  report = '';
  reportName = '';
  service_id: any;
  currentPatient: any;
  rejectedSampleReason = '';
  patientId = '';
  cnic = '';
  startDate: any;
  endDate: any;
  showAddendum = false;
  screenName = '';
  rejectedSampleId: any;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private radiologyService: RadiologyService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private techAcknowledgementService: TechAcknowledgementService,
    private modalService: NgbModal,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }
  searchFilterValue(): any {
    this.searchFilter();
  }
  searchFilter(): any {
    this.showAddendum = false;
    this.ngxLoader.start();
    this.techAcknowledgementService
      .searchFinalizedReportsV2(
        this.patientId,
        this.cnic,
        $('#fromDate').val() || '',
        $('#toDate').val() || '',
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.pageDataList = data.patient_services;
          this.ref.markForCheck();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  resetSummerNotes(): any {
    // $('#impressions').summernote('reset');
    // $('#reports').summernote('reset');
    tinymce.get('reports').setContent('');
    tinymce.get('impressions').setContent('');
    this.reportName = '';
  }

  fetchReports(row, id): any {
    this.showAddendum = true;
    // $('#addendum').summernote({
    //   height: 150,
    //   disable: true
    // });
    $('.radiologyReport').removeClass('btn-light');
    $('#radiologyReport' + id).addClass('btn-light');
    this.resetSummerNotes();
    this.currentPatient = row;
    this.service_id = row.report.id;
    this.history = row.history;
    if (row.report) {
      // $('#impressions').summernote('code', row.report.impression);
      // $('#reports').summernote('code', row.report.report);
      tinymce.get('reports').setContent(row.report.report);
      tinymce.get('impressions').setContent(row.report.impression);
    }
    // $('#reports').summernote('disable');
    // $('#impressions').summernote('disable');
    setTimeout(() => {
      tinymce.remove('#addendum');
      tinymce.init({
        selector: '#addendum',
        menubar: false,
        toolbar: [
          'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap',
          'styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
        ],
        plugins:
          'advlist autolink link image lists charmap code table lineheight',
      });
      // $('#addendum').summernote({
      //   height: 150,
      //   disable: true
      // });
      this.clear();
    }, 500);
  }

  ngOnInit(): void {
    setTimeout(() => {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 2);
      $('#fromDate').datetimepicker({
        defaultDate: yesterday,
        format: 'DD-MM-YYYY h:mm a',
        autoClose: true,
      });
      let previousDateTime = moment(yesterday).format('DD-MM-YYYY h:mm a');
      $('#fromDate').val(previousDateTime);
      $('#toDate').datetimepicker({
        defaultDate: new Date(),
        format: 'DD-MM-YYYY h:mm a',
      });
      let current_time = moment().format('DD-MM-YYYY h:mm a');
      $('#toDate').val(current_time);
    }, 10);
    // $('.summernote').summernote({
    //   height: 150,
    // });
    // $('#reports').summernote('disable');
    // $('#impressions').summernote('disable');
    tinymce.remove('#reports');
    tinymce.init({
      selector: '#reports',
      menubar: false,
      readonly: true,
      toolbar: [
        'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap',
        'styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
      ],
      plugins:
        'advlist autolink link image lists charmap code table lineheight',
    });
    tinymce.remove('#impressions');
    tinymce.init({
      selector: '#impressions',
      menubar: false,
      readonly: true,
      toolbar: [
        'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap',
        'styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
      ],
      plugins:
        'advlist autolink link image lists charmap code table lineheight',
    });
    setTimeout(() => {
      this.searchFilter();
    }, 2000);
  }
  clear(): any {
    // $('#addendum').summernote('code', '');
    tinymce.get('addendum').setContent('');
  }
  saveReport(): any {
    const addendumData = tinymce.get('addendum').getContent({ format: 'text' });
    if ($.trim(addendumData) === '') {
      toastr.error('Add addendum');
      return;
    }
    // if ( $('#addendum').summernote('isEmpty') === true ) {
    //   toastr.error('Add addendum');
    //   return;
    // }
    this.ngxLoader.start();
    this.techAcknowledgementService
      .addendumV2(this.service_id, tinymce.get('addendum').getContent())
      .subscribe(
        (data) => {
          toastr.success(data.message);
          this.resetSummerNotes();
          this.searchFilter();
        },
        (err) => {
          toastr.error(err.error.message);
          this.ngxLoader.stop();
        },
      );
  }

  rejectLabTest(): any {
    this.modalService.open(this.conformCancelModal);
  }
  rejectLabTestFun(): any {
    this.ngxLoader.start();
    this.radiologyService.verifyToUnverifyV2(this.currentPatient.id).subscribe(
      (data) => {
        // $('#impressions').summernote('code', '');
        // $('#reports').summernote('code', '');
        tinymce.get('reports').setContent('');
        tinymce.get('impressions').setContent('');
        this.clear();
        this.searchFilterValue();
        this.modalService.dismissAll(this.conformCancelModal);
        toastr.success('Report Rejected');
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
}
