import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ProductCatalogService {
  constructor(private http: HttpClient) {}

  getProductListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/product_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitAttribute(attributes, product): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/product_attribute_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&product=' +
          product,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          attributes,
        },
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAttributesListData(product): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_attribute_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&product=' +
          product,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAttributes(product): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/product_attribute_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&product=' +
          product,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  variationAgainstAttributes(product, attribute): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/product_variant_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&product=' +
          product +
          '&attribute=' +
          attribute,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitVariation(variants, product, attribute): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/product_variant_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&product=' +
          product +
          '&attribute=' +
          attribute,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          variants,
        },
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addProduct(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/product_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateProduct(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/product_detail?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateReview(is_review_required, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/product_setting?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          id,
          is_review_required,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getReview(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/product_setting?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificProduct(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/product_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  removeProduct(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/send_outs?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
