import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DepartmentsbyhospitalService } from '../../../../services/departmentsbyhospital.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabTestService } from '../../../../services/lab-test.service';
import { environment } from '../../../../../environments/environment';
import { ServiceService } from '../../../../services/service.service';
import * as moment from 'moment';
import { RouteShareService } from '../../../../services/route-share.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-lab-tracking-report',
  templateUrl: './lab-tracking-report.component.html',
  styleUrls: ['./lab-tracking-report.component.css'],
})
export class LabTrackingReportComponent implements OnInit {
  current_environment: string;
  labServicesForm: UntypedFormGroup;
  page = 1;
  totalPages: any;
  perPage = 50;
  labServices = [];
  subDepartments = [];
  pageDataList = [];
  screenName = '';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private departmentsbyhospitalService: DepartmentsbyhospitalService,
    private ngxLoader: NgxUiLoaderService,
    private labTestService: LabTestService,
    private ref: ChangeDetectorRef,
    private serviceService: ServiceService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  ngOnInit(): void {
    this.current_environment = environment.rorUrl;
    this.labServicesForm = this.formBuilder.group({
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      patient_mrn: ['', Validators.required],
      department_id: [''],
      test_id: [''],
    });
    this.getServicesList();
    // this.getSubDepartment(154);
    this.fetchDepartments();
    setTimeout(() => {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      $('#fromDate').datetimepicker({
        defaultDate: yesterday,
        format: 'DD-MM-YYYY h:mm a',
        autoClose: true,
      });
      let previousDateTime = moment(yesterday).format('DD-MM-YYYY h:mm a');
      $('#fromDate').val(previousDateTime);
      $('#toDate').datetimepicker({
        defaultDate: new Date(),
        format: 'DD-MM-YYYY h:mm a',
      });
      let current_time = moment().format('DD-MM-YYYY h:mm a');
      $('#toDate').val(current_time);
      this.searchInvoices();
    }, 1000);
  }
  getServicesList(): any {
    this.labServices = [];
    this.serviceService.getServiceByTypes('LabInvestigation').subscribe(
      (data) => {
        this.labServices = data.services;
        setTimeout(() => {
          $('#testId').selectpicker('refresh');
        }, 500);
        setTimeout(() => {
          $('#testId').selectpicker();
        }, 500);
      },
      (err) => {},
    );
  }
  // getSubDepartment(): any {
  //   this.subDepartments = [];
  //   this.labServicesForm.patchValue({
  //     department_id: '',
  //   });
  //   if ( value !== '' ) {
  //     this.departmentsbyhospitalService.fetchSubDeptbyService('lab').subscribe(
  //       data => {
  //         this.subDepartments = data.departments;
  //         setTimeout(() => {
  //
  //         }, 500);
  //         setTimeout(() => {
  //           $('#department').selectpicker();
  //         }, 500);
  //       },
  //       err => {
  //       }
  //     );
  //   }
  // }
  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentsbyhospitalService.fetchSubDeptbyService('lab').subscribe(
      (data) => {
        this.subDepartments = data.departments;
        setTimeout(() => {
          $('#department').selectpicker('refresh');
          $('#department').selectpicker();
        }, 1000);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  searchInvoices(): any {
    this.labServicesForm.patchValue({
      from_date: $('#fromDate').val(),
      to_date: $('#toDate').val(),
    });
    this.page = 1;
    this.perPage = 50;
    this.searchFilter();
  }
  searchFilter(): any {
    this.ngxLoader.start();
    this.labTestService
      .fetchAllLabTrackingReport(
        this.page,
        this.perPage,
        this.labServicesForm.value,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          if (data.orders) {
            this.pageDataList = data.orders;
          } else {
            this.pageDataList = [];
          }
          this.ref.markForCheck();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.searchFilter();
  }
  loadPage(event): any {
    this.searchFilter();
  }
  printReport(): any {
    const searchData = this.labServicesForm.value;
    window.open(
      environment.rorUrl +
        '/v4/orders/lab_tracking_report.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&from_date=' +
        searchData.from_date +
        '&to_date=' +
        searchData.to_date +
        '&patient_mrn=' +
        searchData.patient_mrn +
        '&department_id=' +
        searchData.department_id +
        '&test_id=' +
        searchData.test_id,
    );
  }
}
