<input
  class="form-control form-control-sm"
  placeholder="Search"
  [(ngModel)]="diagnosisSearch"
  type="text"
  (keyup)="getDiagnosisList()"
  (click)="getDiagnosisList()"
/>
<div
  class="search-result-main zindex-n1"
  *ngIf="diagnosisSearch.length > 2 && diagnosisSearchBar"
>
  <p
    *ngIf="
                diagnosisListData.length === 0 &&
                diagnosisSearchResult === false && diagnosisSearchBar
              "
  >
    Searching...
  </p>
  <p
    *ngIf="
                diagnosisListData.length === 0 && diagnosisSearchResult === true && diagnosisSearchBar
              "
  >
    No Result Found
  </p>
  <span *ngIf="diagnosisListData.length > 0">
              <p
                *ngFor="let diagnosis of diagnosisListData"
                (click)="setDiagnosis(diagnosis)"
              >
                {{ diagnosis.name }}
              </p>
            </span>
</div>
