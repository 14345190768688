  <!--begin::Card-->
<div class="card card-custom mt-2">
 <div class="card-body user-card-body">
     <!--begin::Form-->
     <div style="max-height: 200px!important;" class="employeeShiftTable"
    [formGroup]="physicalSystemicExaminationHistoryForm"
  >
       <h4>{{label}}</h4>
    <div>
      <h4 class="mt-2">GPE</h4>
      <div class="row"
        formGroupName="patient_gpe_history_attributes"
      >
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="pallor"
                autocomplete="off"
              />
              <span class="mr-2"></span>Pallor
            </label>
          </div>
          <div  class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="clubbing"
                autocomplete="off"
              />
              <span class="mr-2"></span>Clubbing
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="cyanosis"
                autocomplete="off"
              />
              <span class="mr-2"></span>Cyanosis
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="lymphadenopathy"
                autocomplete="off"
              />
              <span class="mr-2"></span>Lymphadenopathy
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="pedal"
                autocomplete="off"
              />
              <span class="mr-2"></span>Pedal/Ankle Edema
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="rash"
                autocomplete="off"
              />
              <span class="mr-2"></span>Rash
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="peropheral_vessel_palpable"
                autocomplete="off"
              />
              <span class="mr-2"></span>Peropheral vessel palpable
            </label>
          </div>
      </div>
    </div>
    <div>
      <h4 class="mt-2">Chest</h4>
      <div
        formGroupName="patient_chest_history_attributes"
      >
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-4 col-6">
            <label>Chest Defermity:</label>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-4 col-6">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="chest_defermity"
                autocomplete="off"
              />
              <span class="mr-2"></span>Yes
            </label>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-4 col-6">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="chest_defermity"
                autocomplete="off"
              />
              <span class="mr-2"></span>No
            </label>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-4 col-6">
            <label>Breathing:</label>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-4 col-6">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="vesicular"
                autocomplete="off"
              />
              <span class="mr-2"></span>Vesicular
            </label>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-4 col-6">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="bronchial"
                autocomplete="off"
              />
              <span class="mr-2"></span>Bronchial
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-4 col-6">
            <label>Added Sounds:</label>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-4 col-6">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="rhonchi"
                autocomplete="off"
              />
              <span class="mr-2"></span>Rhonchi
            </label>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-4 col-6">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="wheeeze"
                autocomplete="off"
              />
              <span class="mr-2"></span>Wheeeze
            </label>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4 class="mt-2">Abdomen</h4>
      <div
        class="row"
        formGroupName="patient_abdomen_history_attributes"
      >
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="soft"
                autocomplete="off"
              />
              <span class="mr-2"></span>Soft
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="distended"
                autocomplete="off"
              />
              <span class="mr-2"></span>Distended
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="ascities"
                autocomplete="off"
              />
              <span class="mr-2"></span>Ascities
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="bowel_sounds"
                autocomplete="off"
              />
              <span class="mr-2"></span>Bowel Sounds
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="hepatomegaly"
                autocomplete="off"
              />
              <span class="mr-2"></span>Hepatomegaly
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="spleenomegaly"
                autocomplete="off"
              />
              <span class="mr-2"></span>Spleenomegaly
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="inguinal_hernia"
                autocomplete="off"
              />
              <span class="mr-2"></span>Inguinal hernia
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="tenderness"
                autocomplete="off"
              />
              <span class="mr-2"></span>Tenderness
            </label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
            <label class="checkbox checkbox-sm">
              <input
                type="checkbox"
                formControlName="umbilical"
                autocomplete="off"
              />
              <span class="mr-2"></span>Umbilical/Paraumbilical
            </label>
          </div>
      </div>
    </div>
  </div>
  </div>
</div>

