import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HrReportsService } from '../../../../../services/hr-reports.service';
import { HospitalService } from '../../../../../services/hospital.service';
import { ConfiguratorsService } from '../../../../../services/configurators.service';
import { DepartmentsbyhospitalService } from '../../../../../services/departmentsbyhospital.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { RouteShareService } from '../../../../../services/route-share.service';
import * as moment from 'moment/moment';
import { InventoryGeneralSettingsService } from '../../../../../services/inventory-general-settings.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-late-in-early-out-report',
  templateUrl: './late-in-early-out-report.component.html',
  styleUrls: ['./late-in-early-out-report.component.css'],
})
export class LateInEarlyOutReportComponent implements OnInit {
  depart: any;
  designation: any;
  endDate: any;
  startDate: any;
  department = '';
  employee = '';
  status = 'all';
  designations = '';
  employees = [];
  reportData: any;
  screenName = '';
  page = 1;
  totalPages: any;
  perPage = 25;
  perPageValue: any;
  reportDataCSV = [];
  objOfManufactrureCSV: {};
  settingData: any;
  stateOfHeader: any;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private configuratorsService: ConfiguratorsService,
    private departmentsByHospital: DepartmentsbyhospitalService,
    private configurationService: ConfigurationService,
    private hospitalService: HospitalService,
    private inventoryControlService: InventoryGeneralSettingsService,
    private RosterService: RosterServiceService,
    private routeSevice: RouteShareService,
    private ref: ChangeDetectorRef,
  ) {
    this.screenName = this.routeSevice.getValue();
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getInventorySysControl(): any {
    this.ngxLoader.start();
    this.inventoryControlService.getInventorySystemControls().subscribe(
      (data) => {
        this.stateOfHeader = data.report_header_alignment;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  getDepartments(): any {
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
    }, 500);
    this.departmentsByHospital.index().subscribe((data) => {
      this.depart = data.departments;
      setTimeout((e) => {
        $('.selectpicker').selectpicker('refresh');
      }, 500);
      setTimeout((e) => {
        $('.selectpicker').selectpicker();
      }, 500);
    });
  }
  getdesignation(): any {
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
    }, 500);
    this.configuratorsService
      .fetchConfiguration('designation')
      .subscribe((data) => {
        this.designation = data.configurator_types;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
        }, 500);
        setTimeout((e) => {
          $('.selectpicker').selectpicker();
        }, 500);
      });
  }
  getEmployeesOfDepartment(): any {
    this.employees = [];
    setTimeout((e) => {
      $('#employee').selectpicker('refresh');
    }, 200);
    this.configurationService
      .getEmployeesOfSpecificDepartment(this.department)
      .subscribe(
        (data) => {
          this.employees = data;
          setTimeout((e) => {
            $('#employee').selectpicker('refresh');
            $('#employee').selectpicker();
          }, 500);
        },
        (err) => {
          setTimeout((e) => {
            $('#employee').selectpicker('refresh');
          }, 500);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  searchReport(): any {
    this.startDate = $('.start_date').val();
    this.endDate = $('.end_date').val();
    this.ngxLoader.start();
    this.RosterService.searchEarlyInLateOutReport(
      this.startDate,
      this.endDate,
      this.department,
      this.designations,
      this.employee,
      this.status,
    ).subscribe(
      (data) => {
        this.reportData = data;
        if (this.reportData.length) {
          // this.totalPages = data.meta.total_count;
          this.ref.markForCheck();
          this.getListDataCSV();
          this.getCSV();
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getListDataCSV(): any {
    this.reportDataCSV = [];
    for (var i = 0; i < this.reportData.length; i++) {
      for (var j = 0; j < this.reportData[i].employees.length; j++) {
        this.objOfManufactrureCSV = {
          Department: this.reportData[i].department,
          'Employee ID': this.reportData[i].employees[j].employee_code,
          'Employee Name': this.reportData[i].employees[j].name,
          Designation: this.reportData[i].employees[j].designation,
          'Late In': this.reportData[i].employees[j].late_in,
          'Early Out': this.reportData[i].employees[j].early_out,
          'Leave Balance(EL)': this.reportData[i].employees[j].leave_balance,
        };
        this.ref.markForCheck();
        this.reportDataCSV.push(this.objOfManufactrureCSV);
      }
    }
  }
  getCSV(): any {
    const array = [Object.keys(this.reportDataCSV[0])].concat(
      this.reportDataCSV,
    );
    console.log('array', array);
    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join('\n');
  }

  downloadCSV(): any {
    const hiddenElement = document.createElement('a');
    hiddenElement.href =
      'data:text/csv;charset=utf-8,' + encodeURI(this.getCSV());
    hiddenElement.target = '_blank';
    hiddenElement.download = 'LateIn&EarlyOutReport.csv';
    hiddenElement.click();
  }
  getDate(): any {
    setTimeout(() => {
      $('.start_date').datepicker({
        format: 'dd-mm-yyyy',
        orientation: 'bottom-left',
      });
      $('.start_date').datepicker('setDate', new Date());
      $('.end_date').datepicker({
        format: 'dd-mm-yyyy',
        orientation: 'bottom-left',
      });
      $('.end_date').datepicker('setDate', new Date());
    }, 500);
  }
  loadPage(event): any {
    this.searchReport();
  }
  selectPaginationLimit(event): any {
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    this.searchReport();
  }
  printReport(): any {
    window.print();
  }
  ngOnInit(): void {
    this.perPageValue = 25;
    this.getEmployeesOfDepartment();
    this.getdesignation();
    this.getDepartments();
    this.getDate();
    this.getInventorySysControl();
    this.getSettingsData();
  }
}
