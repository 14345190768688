<div style="display: flex; align-items: center; justify-content: center">
  <div class="card card-custom" style="width: 636px">
    <div class="card-body">
      <div class="d-flex">
        <div style="width: 40%">
          <div><h4>Patient Details:</h4></div>
          <div>
            <img
              class="brCode"
              *ngIf="patients?.barcode_url"
              [src]="patients?.barcode_url"
            />
          </div>
          <div style="font-weight: bold; font-size: 16px">
            {{ patients?.name }}
          </div>
          <div style="font-weight: bold; font-size: 16px">
            {{ patients?.mrn }}
          </div>
          <div style="font-weight: bold">
            {{ patients?.age }}, {{ patients?.gender | titlecase }}
          </div>
          <div style="font-weight: bold">{{ patients?.patient_type }}</div>
          <!--              <td style="width: 15%; font-weight: bold; margin-left: 10px">Allergies</td>-->
          <div style="font-weight: bold" *ngIf="patients?.ward">
            {{ " " + patients.ward }}
          </div>
          <div style="font-weight: bold" *ngIf="patients?.bed">
            Bed/Room: ({{ patients?.bed }})
          </div>
        </div>
        <div style="border-right: 2px solid black"></div>
        <div style="width: 60%; margin-left: 10px">
          <div><h4>Medicine Details:</h4></div>
          <ng-container *ngFor="let data of paitentMedicineHistory">
            <span style="font-weight: bold">{{ data?.stoc_type }}, </span>
            <span style="font-weight: bold">{{ data.name }}, </span>
            <span style="font-weight: bold">{{ data?.dos_type }}, </span>
            <span style="font-weight: bold">{{ data?.frequency }}, </span>
            <span style="font-weight: bold">{{ data?.no_of_days }}, </span>
            <!--              <td style="width: 15%; font-weight: bold; margin-left: 10px">Allergies</td>-->
            <span style="font-weight: bold">{{ data?.comments }} </span>
            <!--          <td style="width: 10%; font-weight: bold; margin-left: 10px">bar code:</td>-->
            <div
              style="
                border-bottom: 1px solid rgba(0, 0, 0, 0.22);
                margin-top: 10px;
                margin-bottom: 10px;
              "
            ></div>
          </ng-container>
        </div>
      </div>
      <!--      <div style="border-bottom: 2px solid black"></div>-->
      <!--      <table>-->
      <!--        <tr class="mt-4">-->
      <!--          <td style="font-weight: bold">Panadol, </td>-->
      <!--          <td style="font-weight: bold">Before Breakfast, </td>-->
      <!--          <td style="font-weight: bold">Bed Time, </td>-->
      <!--          &lt;!&ndash;              <td style="width: 15%; font-weight: bold; margin-left: 10px">Allergies</td>&ndash;&gt;-->
      <!--          <td style="font-weight: bold">10 packets </td>-->
      <!--&lt;!&ndash;          <td style="width: 10%; font-weight: bold; margin-left: 10px">bar code:</td>&ndash;&gt;-->
      <!--        </tr>-->
      <!--      </table>-->
    </div>
  </div>
</div>
