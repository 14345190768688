import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class PatientTypeService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/patient_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getSelfPatientType(): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/patient_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&self=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  getSelfPatientTypeV2(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/patient_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&self=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getSelfPatientTypeOptamize(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/patient_type_optimize?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&self=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  penalPatientTypes(): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/panel_patient_types/get_panel_patient_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  indexV2(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/patient_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // new optimise code start

  // patient type
  fetchPatientTypes(): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/patient_types/get_patient_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  fetchPatientTypesV2(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/get_patient_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  fetchServiceTypes(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/add_service_type/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addPatientTypes(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_types/get_patient_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_type: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePatientTypes(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patient_types/get_patient_type/` + id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_type: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDonationType(donationType, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_donation_type/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          donation_type: donationType,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchPatientCompanies(id): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/companies/get_companies?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  fetchPatientCompaniesV2(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/get_companies/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          id +
          '&status=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  fetchPatientCompaniesReportV2(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/get_companies/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          id +
          '&status=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  fetchPatientGroups(id): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/groups/get_groups?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&company_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  fetchPatientGroupsV2(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/get_groups/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&company_id=' +
          id +
          '&status=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  fetchPatientGroupsReportV2(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/get_groups/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&company_id=' +
          id +
          '&status=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // new optimise code end

  getPatientTypes(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientTypesAgainstServiceCharges(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/pat_type_against_service_charges?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientCompanies(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/companies?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientGroups(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/groups?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addPatientType(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_types`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_type: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addPatientTypeV2(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/patient_types/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_type: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addCompanies(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/companies`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          company: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addCompaniesV2(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/companies/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          company: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addGroups(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/groups`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          group: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addGroupsV2(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/groups/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          group: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientConfigurations(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/panel_patient_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientConfigurationsv2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/panel_patient_types/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatePatientsConfigurations(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/groups/update_groups`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          groups: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePatientsConfigurationsv2(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/update_groups/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          groups: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatePatientType(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patient_types/` + id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_type: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePatientTypeV2(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/patient_types/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_type: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateCompany(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/companies/` + id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          company: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateCompanyV2(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/companies/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          company: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateGroup(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/groups/` + id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          group: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateGroupV2(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/groups/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          group: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchByUser(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/user_patient_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&user_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchByUserv2(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/user_patient_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&user_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateUserPatientType(id, patient_type_ids): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/user_patient_types/update_user_patient_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          user_id: id,
          patient_type_ids,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateUserPatientTypev2(id, patient_type_ids): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_user_patient_types/1/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          user_id: id,
          patient_type_ids,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateServiceType(id, service_type): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/add_service_type/1/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          user_id: id,
          service_type,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateBranchAllocation(defaultBranchId, AllocateBranchId, empId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/user_general_controls/set_emp_branch/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          emp_id: empId,
          default_branch: defaultBranchId,
          allocated_branch: AllocateBranchId,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateOpdTempletes(id, opd_prescription_template_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/user_prescription_templates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          user_ids: id,
          opd_prescription_template_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateEmergencyTempletes(id, emr_prescription_template_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/user_prescription_templates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          user_ids: id,
          emr_prescription_template_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
