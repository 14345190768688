<div>
  <!--begin::Card-->

  <div class="card mb-4" *ngIf="isAdd === true">
    <div class="card-body user-card-body">
      <div class="row">
        <div class="col-md-4 col-sm-4 col-12">
          <label>Provisional Diagnosis</label>
          <input
            class="form-control form-control-sm"
            [(ngModel)]="diagnosisSearch"
            type="text"
            (keyup)="getDiagnosisList()"
            (click)="getDiagnosisList()"
          />
          <div
            class="search-result-main zindex-n1"
            *ngIf="diagnosisSearch.length > 2"
          >
            <p
              *ngIf="
                diagnosisListData.length === 0 &&
                diagnosisSearchResult === false
              "
            >
              Searching...
            </p>
            <p
              *ngIf="
                diagnosisListData.length === 0 && diagnosisSearchResult === true
              "
            >
              No Result Found
            </p>
            <span *ngIf="diagnosisListData.length > 0">
              <p
                *ngFor="let diagnosis of diagnosisListData"
                (click)="setDiagnosis(diagnosis)"
              >
                {{ diagnosis.name }}
              </p>
            </span>
          </div>

          <div class="text-right mt-3">
            <button
              class="btn btn-primary btn-sm"
              [disabled]="disableDiagnosisBtn"
              (click)="addDiagnosis('ProvisionalDiagnosis')"
            >
              Submit
            </button>
          </div>
        </div>

        <div class="col-md-4 col-sm-4 col-12">
          <label>Differential Diagnosis</label>
          <input
            class="form-control form-control-sm"
            [(ngModel)]="differentialSearch"
            type="text"
            (keyup)="getDifferentialDiagnosisList()"
            (click)="getDifferentialDiagnosisList()"
          />
          <div
            class="search-result-main zindex-n1"
            *ngIf="differentialSearch.length > 2"
          >
            <p
              *ngIf="
                diagnosisListData.length === 0 &&
                diagnosisSearchResult === false
              "
            >
              Searching...
            </p>
            <p
              *ngIf="
                diagnosisListData.length === 0 && diagnosisSearchResult === true
              "
            >
              No Result Found
            </p>
            <span *ngIf="diagnosisListData.length > 0">
              <p
                *ngFor="let diagnosis of diagnosisListData"
                (click)="setDifferentialDiagnosis(diagnosis)"
              >
                {{ diagnosis.name }}
              </p>
            </span>
          </div>

          <div class="text-right mt-3">
            <button
              class="btn btn-primary btn-sm"
              [disabled]="disableDiagnosisBtn"
              (click)="addDifferentialDiagnosis('DifferentialDiagnosis')"
            >
              Submit
            </button>
          </div>
        </div>

        <div class="col-md-4 col-sm-4 col-12">
          <label>Final Diagnosis</label>
          <input
            class="form-control form-control-sm"
            [(ngModel)]="finalSearch"
            type="text"
            (keyup)="geFinalDiagnosisList()"
            (click)="geFinalDiagnosisList()"
          />
          <div
            class="search-result-main zindex-n1"
            *ngIf="finalSearch.length > 2"
          >
            <p
              *ngIf="
                diagnosisListData.length === 0 &&
                diagnosisSearchResult === false
              "
            >
              Searching...
            </p>
            <p
              *ngIf="
                diagnosisListData.length === 0 && diagnosisSearchResult === true
              "
            >
              No Result Found
            </p>
            <span *ngIf="diagnosisListData.length > 0">
              <p
                *ngFor="let diagnosis of diagnosisListData"
                (click)="setFinalDiagnosis(diagnosis)"
              >
                {{ diagnosis.name }}
              </p>
            </span>
          </div>

          <div class="text-right mt-3">
            <button
              class="btn btn-primary btn-sm"
              [disabled]="disableDiagnosisBtn"
              (click)="addFinalDiagnosis('FinalDiagnosis')"
            >
              Submit
            </button>
            <!--                        <button class="btn btn-primary btn-sm ml-2" (click)="moveNextTab('MedicinesTabs')">Next</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="!isAdd || paitestDiagnosisHistory.length > 0">
    <div class="card-body user-card-body">
      <div class="row m-0 mb-4" *ngIf="isAdd === false">
        <h4>Diagnosis</h4>
      </div>
      <div class="max-height-250 overflow-auto w-100 diagnosis-scroll">
        <div *ngFor="let history of paitestDiagnosisHistory">
          <!--                  {{history.user.first_name + ' ' + history.user.last_name}}-->
          <p style="margin-bottom: 0px; font-size: 12px">
            {{ history.created_at | date: "dd-MM-yyyy h:mm a" }} - by
            {{ history?.user?.first_name + " " + history?.user?.last_name }}
          </p>
          <p style="font-size: 13px; font-weight: 600">
            ({{ history.type }}) {{ history.name }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!--end::Card-->
</div>
