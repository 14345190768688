<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            Radiology Template
          </h5>
          <!--          tab headings-->
          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-light-primary btn-sm" (click)="printReport()">
          Print
        </button>
        <!-- <button class="btn btn-primary btn-sm ml-2" (click)="goBack()">Back</button> -->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->
      <div class="card pdf-card" id="reports">
        <div class="card-body">
          <table style="width: 100% !important">
            <thead>
              <tr>
                <th><div class="table-header"></div></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-break">
                  <div class="row">
                    <div class="col-12">
                      <h2 class="mt-8 font-size-service-name">
                        {{ templateData?.name }}
                      </h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" *ngIf="templateData?.report">
                      <h2 class="mt-8 font-size-service-name">Result</h2>
                    </div>
                    <div class="col-12 data">
                      <div id="resultHTML"></div>
                      <!--                      <div [innerHTML]="templateData?.report"></div>-->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" *ngIf="templateData?.impression">
                      <h2 class="mt-8 font-size-service-name">Impression</h2>
                    </div>
                    <div class="col-12 data">
                      <div id="impressionHTML"></div>
                      <!--                      <div [innerHTML]="templateData?.impression"></div>-->
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td><div class="table-footer"></div></td>
              </tr>
            </tfoot>
          </table>

          <!--          <hr class="mb-6 line">-->
        </div>
      </div>
      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
