import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pcr-report',
  templateUrl: './pcr-report.component.html',
  styleUrls: ['./pcr-report.component.css'],
})
export class PcrReportComponent implements OnInit {
  @Input() pageDataList: any;
  @Input() reportName: any;
  labReportName: any;

  constructor() {}

  ngOnInit(): void {
    this.labReportName = this.reportName.toLowerCase();
    if (this.pageDataList.lab_test_details.length > 0) {
      let sortData =
        this.pageDataList?.lab_test_details[0]?.pcr_report_details?.organism.sort(
          (a, b) => {
            var nameA = a.organism_name.toUpperCase();
            var nameB = b.organism_name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          },
        );
    }
    let commentsInd = 0;
    for (let i = 0; i < this.pageDataList.lab_test_details.length; i++) {
      for (
        let j = 0;
        j <
        this.pageDataList.lab_test_details[i].pcr_report_details.organism
          .length;
        j++
      ) {
        if (
          this.pageDataList.lab_test_details[i].pcr_report_details.organism[j]
            .organism_comments &&
          this.pageDataList.lab_test_details[i].pcr_report_details.organism[j]
            .organism_result === 'Positive'
        ) {
          commentsInd++;
          this.pageDataList.lab_test_details[i].pcr_report_details.organism[
            j
          ].organism_comments_index = commentsInd;
        }
      }
    }
  }
}
