import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { LookupService } from '../../../services/lookup.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LocationService } from '../../../services/location.service';
import { PatientService } from '../../../services/patient.service';
import { ConfiguratorsService } from '../../../services/configurators.service';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../../../services/language.service';
import { Observable, Subject } from 'rxjs';

declare var toastr: any;
declare var $: any;

@Component({
  selector: 'app-family-information',
  templateUrl: './family-information.component.html',
  styleUrls: ['./family-information.component.css'],
})
export class FamilyInformationComponent implements OnInit {
  @ViewChild('imageModal') imageModal: ElementRef;

  @Input() patient: any;
  lookups = [];
  familyForm: UntypedFormGroup;
  countriesList: any;
  familyData: any;
  familyDataId = '';
  allConfigs = [];
  patientImage = '';
  showImage = false;
  public multipleWebcamsAvailable = false;
  dataValues: any;
  private trigger: Subject<void> = new Subject<void>();
  public webcamImage: WebcamImage = null;
  public allowCameraSwitch = true;
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  public videoOptions: MediaTrackConstraints = {};
  public deviceId: string;
  public errors: WebcamInitError[] = [];
  public showWebcam = true;

  constructor(
    private lookupService: LookupService,
    private ngxLoader: NgxUiLoaderService,
    private locationService: LocationService,
    private patientService: PatientService,
    private fb: UntypedFormBuilder,
    private configService: ConfiguratorsService,
    private modalService: NgbModal,
    private language: LanguageService,
  ) {
    this.familyForm = this.fb.group({
      alternate_contact: [''],
      alternate_contact_dial_code: [''],
      alternate_contact_dial_code_name: ['pk'],
      accommodation: [''],
      address: [''],
      cnic: [''],
      city: [''],
      contact: [''],
      contact_dial_code: [''],
      contact_dial_code_name: ['pk'],
      cnic_dial_code_name: ['pk'],
      passport_dial_code_name: ['pk'],
      country_dial_code_name: ['pk'],
      occupation: [''],
      qualification: [''],
      passport: [''],
      mother_name: [''],
      mother_profession: [''],
      sibling: [''],
      guardian_name: [''],
      family_income: [''],
      relation: [''],
      country: [''],
      currency: [''],
    });
    this.dataValues = this.language.getData();
  }

  // initializePhone(): void {
  //     $('#alternate_contact').intlTelInput({
  //       // autoFormat: true,
  //       utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js',
  //       // autoPlaceholder: true,
  //       preferredCountries: ['PK'],
  //       // initialCountry: "PK",
  //       preventInvalidNumbers: true,
  //       // // stop the user from typing invalid dial codes
  //       preventInvalidDialCodes: true,
  //       formatOnDisplay: true,
  //       separateDialCode: false,
  //     });
  //     $('#alternate_contact').intlTelInput('setCountry', 'PK');
  // }
  initializePhone(): void {
    $('#contact').intlTelInput({
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js',
      preferredCountries: ['PK'],
      preventInvalidNumbers: true,
      preventInvalidDialCodes: true,
      formatOnDisplay: true,
      separateDialCode: true, // Set separateDialCode to false
    });
    $('#alternate_contact').intlTelInput({
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js',
      preferredCountries: ['PK'],
      preventInvalidNumbers: true,
      preventInvalidDialCodes: true,
      formatOnDisplay: true,
      separateDialCode: true, // Set separateDialCode to false
    });
    $('#cnic').intlTelInput({
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js',
      preferredCountries: ['PK'],
      preventInvalidNumbers: true,
      preventInvalidDialCodes: true,
      formatOnDisplay: true,
      separateDialCode: false, // Set separateDialCode to false
    });
    $('#passport').intlTelInput({
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js',
      preferredCountries: ['PK'],
      preventInvalidNumbers: true,
      preventInvalidDialCodes: true,
      formatOnDisplay: true,
      separateDialCode: false, // Set separateDialCode to false
    });
    $('#countryLib').intlTelInput({
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js',
      preferredCountries: ['PK'],
      preventInvalidNumbers: true,
      preventInvalidDialCodes: true,
      formatOnDisplay: true,
      separateDialCode: false, // Set separateDialCode to false
      allowDropdown: false,
    });
    if (this.familyDataId) {
      $('#cnic').intlTelInput(
        'setCountry',
        this.familyData?.cnic_dial_code_name || 'PK',
      );
      $('#passport').intlTelInput(
        'setCountry',
        this.familyData?.passport_dial_code_name || 'PK',
      );
      $('#countryLib').intlTelInput(
        'setCountry',
        this.familyData?.country_dial_code_name || 'PK',
      );
      $('#contact').intlTelInput(
        'setCountry',
        this.familyData.contact_dial_code_name || 'PK',
      );
      $('#alternate_contact').intlTelInput(
        'setCountry',
        this.familyData.alternate_contact_dial_code_name || 'PK',
      );
    }
  }
  onCountryChange(id): any {
    const findCountry = this.countriesList.find(
      (t) => t?.id === Number(id),
    )?.country_code_name;
    $('#countryLib').intlTelInput('setCountry', findCountry || 'PK');
  }
  getCountries(): any {
    this.ngxLoader.start();
    this.locationService.getCountries().subscribe(
      (data) => {
        this.countriesList = data.countries;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  getLookups(): any {
    this.lookupService.lookupsCategoryWise('relationship').subscribe((data) => {
      this.lookups = data.lookups;
      setTimeout((e) => {
        $('#relation').selectpicker('refresh');
        $('#relation').selectpicker();
      }, 500);
    });
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  submitFamilyInfo(): any {
    const contact = $('#contact').intlTelInput('getSelectedCountryData');
    const alternateContact = $('#alternate_contact').intlTelInput(
      'getSelectedCountryData',
    );
    const cnic = $('#cnic').intlTelInput('getSelectedCountryData');
    const passport = $('#passport').intlTelInput('getSelectedCountryData');
    const country = $('.countryLib').intlTelInput('getSelectedCountryData');
    this.familyForm.get('contact_dial_code')?.patchValue(contact.dialCode);
    this.familyForm.get('contact_dial_code_name')?.patchValue(contact.iso2);
    this.familyForm
      .get('alternate_contact_dial_code')
      ?.patchValue(alternateContact.dialCode);
    this.familyForm
      .get('alternate_contact_dial_code_name')
      ?.patchValue(alternateContact.iso2);
    this.familyForm.get('cnic_dial_code_name')?.patchValue(cnic.iso2);
    this.familyForm.get('passport_dial_code_name')?.patchValue(passport.iso2);
    this.familyForm.get('country_dial_code_name')?.patchValue(country.iso2);
    this.familyForm
      .get('cnic')
      ?.setValue(this.familyForm.get('cnic')?.value.replace(/-/g, ''));
    this.familyForm
      .get('family_income')
      ?.setValue(Number(this.familyForm.get('family_income')?.value));
    this.ngxLoader.start();
    if (!this.familyDataId) {
      this.patientService
        .submitFamilyInfo(this.familyForm.value, this.patient.id)
        .subscribe(
          (data) => {
            toastr.success('Submit Successfully');
            if (this.patientImage) {
              this.uploadProfileImage('add');
            }
            this.getFamilyInfoData();
            this.ngxLoader.stop();
          },
          (err) => {
            this.ngxLoader.stop();
            toastr.error(err.error.message);
          },
        );
    } else {
      this.patientService
        .updateFamilyInfo(
          this.familyForm.value,
          this.familyDataId,
          this.patient.id,
        )
        .subscribe(
          (data) => {
            toastr.success('Updated Successfully');
            if (this.patientImage) {
              this.uploadProfileImage('update');
            }
            this.getFamilyInfoData();
            this.ngxLoader.stop();
          },
          (err) => {
            this.ngxLoader.stop();
            toastr.error(err.error.message);
          },
        );
    }
  }

  getFamilyInfoData(): any {
    this.patientService.getFamilyInfoData(this.patient.id).subscribe(
      (data) => {
        this.familyData = data[0];
        if (this.familyData) {
          this.familyDataId = data[0].id;
          this.familyForm.patchValue({
            alternate_contact: this.familyData.alternate_contact,
            alternate_contact_dial_code:
              this.familyData.alternate_contact_dial_code,
            alternate_contact_dial_code_name:
              this.familyData.alternate_contact_dial_code_name,
            accommodation: this.familyData.accommodation,
            address: this.familyData.address,
            cnic: this.familyData.cnic,
            city: this.familyData.city,
            contact: this.familyData.contact,
            contact_dial_code: this.familyData.contact_dial_code,
            contact_dial_code_name: this.familyData.contact_dial_code_name,
            cnic_dial_code_name: this.familyData.cnic_dial_code_name,
            passport_dial_code_name: this.familyData.passport_dial_code_name,
            country_dial_code_name: this.familyData.country_dial_code_name,
            occupation: this.familyData.occupation,
            qualification: this.familyData.qualification,
            passport: this.familyData.passport,
            mother_name: this.familyData.mother_name,
            mother_profession: this.familyData.mother_profession,
            sibling: this.familyData.sibling,
            guardian_name: this.familyData.guardian_name,
            family_income: this.familyData.family_income,
            relation: this.familyData.relation,
            country: this.familyData.country,
            currency: this.familyData.currency,
          });
          $('#cnic').mask('99999-9999999-9').trigger('input');
        }
        setTimeout(() => {
          this.initializePhone();
          $('#countryLib').selectpicker('refresh');
          $('#countryLib').selectpicker();
        }, 1000);
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  getAllConfigs(): any {
    this.ngxLoader.start();
    this.configService.fetchAllConfigurationsV2().subscribe(
      (data) => {
        this.allConfigs = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  ngOnInit(): void {
    this.getAllConfigs();
    this.getFamilyInfoData();
    $('#cnic').mask('99999-9999999-9');
    this.getLookups();
    this.getCountries();
    console.log('patient', this.patient);
  }

  cambox(): any {
    this.showImage = false;
    this.modalService.open(this.imageModal);
    navigator.mediaDevices.getUserMedia({ video: true }).then(
      (stream) => {
        WebcamUtil.getAvailableVideoInputs().then(
          (mediaDevices: MediaDeviceInfo[]) => {
            this.multipleWebcamsAvailable =
              mediaDevices && mediaDevices.length > 1;
          },
        );
        this.showImage = false;
      },
      (e) => {
        toastr.error('Allow camera permission');
      },
    );
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  handleInitError(error: WebcamInitError): void {
    if (
      error.mediaStreamError &&
      error.mediaStreamError.name === 'NotAllowedError'
    ) {
    } else {
    }
    this.errors.push(error);
  }

  triggerSnapshot(): void {
    this.trigger.next();
    this.showImage = true;
  }

  resetImageBox(): any {
    this.showImage = false;
  }

  captureImageComplete(): any {
    this.patientImage = this.webcamImage.imageAsDataUrl;
    if (this.patient) {
      this.patient.profile_image = '';
    }
    this.modalService.dismissAll(this.imageModal);
  }

  uploadProfileImage(type) {
    this.patientService
      .uploadProfileImage('', this.patientImage, this.patient.id, 'family_info')
      .subscribe(
        (dataImage) => {
          this.patient.profile_image = dataImage.patient_image;
          this.patientImage = '';
        },
        (err) => {
          toastr.error(err.error.message);
        },
      );
  }
}
