import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscriber } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { environment } from '../../../../environments/environment';

import * as moment from 'moment/moment';
import { formatDate } from '@angular/common';
import { TaskManagementService } from '../../../services/task-management.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { RosterServiceService } from '../../../services/roster-service.service';
import { RouteShareService } from '../../../services/route-share.service';
declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css'],
})
export class AddTaskComponent implements OnInit {
  addComplaint: UntypedFormGroup;
  getComplainData: UntypedFormGroup;
  page = 1;
  totalPages: any;
  perPage = 25;
  isSelf = true;
  designation: any;
  statusString: number = -1;
  priorityString: number = -1;
  perPageValue: any;
  isAdd = false;
  employeesOfDepart: any;
  department: any;
  list: any;
  locationData: any;
  depart: any;
  userData: any;
  complaintSingledata: any;
  complaintId: any;
  imagePath: any;
  isAddTransaction = false;
  screenName = '';
  day = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  tagListData: any;
  taskStatus: any;
  employee: any;
  priority: any;
  view = false;
  viewData: any;
  timeLogData: UntypedFormGroup;
  employee_id: any;
  task_id: any;
  timeloglist: any;
  indexVariable: any;
  searchForm: UntypedFormGroup;
  commentsCheck = false;
  timeCheck = false;
  currentTask_id: 0;
  loaderCheck = true;
  project: any;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private rosterServiceService: RosterServiceService,
    private task: TaskManagementService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
    this.getComplainData = this.fb.group({
      description: ['', Validators.required],
      department: '',
      comments: '',
      owner: '',
      tag: '',
      assign_to: '',
      status: [1, Validators.required],
      priority: 3,
      assign: this.fb.array([]),
    });
    this.timeLogData = this.fb.group({
      time: [0, Validators.required],
      comments: ['', Validators.required],
      is_completed: false,
    });
    this.getDepartments();
  }
  loadPage(event): any {
    this.searchFilter();
  }
  searchFilterValue(): any {
    this.page = 1;
    this.searchFilter();
  }
  searchPriorityFilter(i): any {
    this.priorityString = i;
    this.searchFilter();
  }
  searchStatusFilter(i): any {
    this.statusString = i;
    this.searchFilter();
  }
  searchFilter(): any {
    console.log('Values', this.searchForm.value);

    this.ngxLoader.start();
    this.task
      .getComplaintListing(
        this.page,
        this.isSelf,
        this.perPage,
        this.searchForm.value,
      )
      .subscribe(
        (data) => {
          this.list = data;
          this.totalPages = this.list.total_count;
          this.list = this.list.items;
          console.log('Items of abcd', this.list);
          setTimeout((e) => {
            $('.selectpicker').selectpicker('refresh');
          }, 500);

          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
  changeStatus(event): any {
    this.statusString = event;
  }
  changePriority(event): any {
    this.priorityString = event;
  }
  selectPaginationLimit(event): any {
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    this.searchFilter();
  }
  onSubmit(): any {
    this.isAdd = true;
    if (this.addComplaint.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.task.addComplaint(this.addComplaint.value).subscribe(
      (data) => {
        this.isAdd = false;
        this.ngxLoader.stop();
        this.closeIndentList();
        this.addComplaint.reset();
        this.searchFilter();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  onSubmitData(): any {
    //   document.getElementById('days').style.display = 'block';
    //   this.addDayWiseSalary.get('days').setValidators([Validators.required]);
    //   this.addDayWiseSalary.get('days').updateValueAndValidity();
    // } else {
    // document.getElementById('days').style.display = 'none';
    // this.addDayWiseSalary.get('days').clearValidators();
    // this.addDayWiseSalary.get('days').updateValueAndValidity();
    //   if(this.commentsCheck===true)
    //   {
    //     this.getComplainData.get('comments').setValidators([Validators.required]);
    //     this.getComplainData.get('comments').updateValueAndValidity();
    //   }
    //   if(this.commentsCheck===false)
    //   {
    //     console.log('onit')
    //     this.getComplainData.get('comments').clearValidators();
    //     this.getComplainData.get('comments').updateValueAndValidity();
    //   }

    this.isAddTransaction = true;
    console.log('noted', this.getComplainData.value);
    if (this.getComplainData.invalid) {
      console.log('returned');
      return;
    }
    this.getComplainData
      .get('tag')
      .setValue($('#medicationsAllergies').val().toString());

    console.log('this.getComplainData', this.getComplainData.value);
    this.ngxLoader.start();
    this.task
      .updateTask(this.getComplainData.value, this.complaintSingledata.id)
      .subscribe(
        (data) => {
          this.isAddTransaction = false;
          this.getComplainData.reset();
          this.openComplaint(this.complaintId);
          if (this.commentsCheck === true) {
            this.assignData.removeAt(0);
            this.updateassignForm('');
          }
          this.searchFilter();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
  getLocation(): any {
    this.task.getDepartmentLocations().subscribe(
      (data) => {
        this.locationData = data.location;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getProject(): any {
    this.task.getProject(true).subscribe(
      (data) => {
        this.project = data;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
        const defaultStatus = this.taskStatus.filter(
          (t) => t.is_default === true,
        );
        console.log('status', defaultStatus);
        this.addComplaint.get('status').patchValue(defaultStatus[0].id);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getStatus(): any {
    this.task.getStatusList(true).subscribe(
      (data) => {
        this.taskStatus = data;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
        const defaultStatus = this.taskStatus.filter(
          (t) => t.is_default === true,
        );
        console.log('status', defaultStatus);
        this.addComplaint.get('status').patchValue(defaultStatus[0].id);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        this.locationData = data.location;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getEmployess(): any {
    this.task.getEmployees('').subscribe(
      (data) => {
        this.employee = data;
        console.log('dataaaa', this.employee);
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  fileUploadFunc($event): any {
    const file = ($event.target as HTMLInputElement).files[0];
    this.convertToBase64(file);
  }
  convertToBase64(file: File): any {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.addComplaint.get('file').setValue(d);
    });
  }
  readFile(file: File, subscriber: Subscriber<any>): any {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);

    filereader.onload = () => {
      subscriber.next(filereader.result);
      subscriber.complete();
    };
    filereader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
  openComplaint(id): any {
    this.updateassignForm('');

    setTimeout(() => {
      $('#selectStatus').selectpicker('refresh');
      $('#modalSelect').selectpicker('refresh');
      $('#assign_to').selectpicker('refresh');
      $('#prioritySelect').selectpicker('refresh');
      $('#prioritySelect2').selectpicker('refresh');
      $('#prioritySelect3').selectpicker('refresh');
      $('#taskStatus').selectpicker('refresh');
      $('#viewStatus').selectpicker('refresh');
    }, 1000);

    // const allergies = this.specificPatientTemplate.allergies[0].split(',');
    // setTimeout(() => {
    //   console.log('tags before',this.tagListData);
    // }, 2000);

    // const tagsData=this.tagListData;
    // for(let i =0; i<=this.tagListData.length;i++)
    // {
    //   tagsData.push(this.tagListData[i].name);
    // }
    // console.log('tags After',tagsData)

    // $('.select2').select2({
    //   placeholder: 'Select or type Tags',
    //   tags: true,
    //   data: tagsData,
    // });
    // setTimeout(() => {
    //   $('#medicationsAllergies').val(tagsData);
    //   $('#medicationsAllergies').trigger('change');
    // }, 2000);

    this.complaintId = id;
    this.isAddTransaction = false;

    if (this.assignData) {
      while (this.assignData.length) {
        this.assignData.removeAt(0);
      }
    }
    if (this.view === false) {
      if (this.commentsCheck === true) {
        $('#exampleModal').modal('show');
      }
    } else {
      console.log('views');

      $('#viewModal').modal('show');
    }

    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    console.log('current User', this.userData);
    if (this.userData) {
      this.getComplainData
        .get('owner')
        .setValue(this.userData.first_name + ' ' + this.userData.last_name);
    }

    if (this.loaderCheck === true) {
      console.log();
      this.ngxLoader.start();
    }
    this.task.getComplaintDataById(id).subscribe(
      (data) => {
        this.complaintSingledata = data;
        this.view = false;

        for (let j = 0; j < this.complaintSingledata.task_assign.length; j++) {
          console.log('patching', this.complaintSingledata.task_assign[j]);
          // this.assignData.push(this.complaintSingledata.task_assign[j]);

          this.assignData.push(
            this.updateassignForm(this.complaintSingledata.task_assign[j]),
          );

          setTimeout(() => {
            $('#employee' + j).selectpicker('refresh');
            $('#employee' + j).selectpicker();
          }, 500);
        }

        console.log('patching', this.complaintSingledata);
        // if (this.complaintSingledata.transaction.length) {
        //   this.getComplainData.get('assign_to').setValue(this.complaintSingledata.transaction[0].assign_to.id);
        // }
        this.imagePath = environment.pythonUrl;
        this.getComplainData.patchValue({
          description: this.complaintSingledata.description,
          department: this.complaintSingledata.department,
          tag: this.complaintSingledata.tags.id,
          status: this.complaintSingledata.task_status.id,
          priority: this.complaintSingledata.priority.id,
          comments: this.complaintSingledata.comments,
          phone: this.complaintSingledata.phone,
        });
        if (this.loaderCheck === true) {
          this.loaderCheck = false;
          this.ngxLoader.stop();
        }
      },
      (err) => {
        if (this.loaderCheck === true) {
          this.loaderCheck = false;
          this.ngxLoader.stop();
        }
        toastr.error(err.error.error);
      },
    );
    setTimeout(() => {
      $('.select2').select2({
        placeholder: 'Select or type tags',
        tags: false,
      });
      // $('.select2').empty().trigger('change');
    }, 1000);
  }
  getEmployeesOfDepartment(): any {
    if (
      this.department === undefined ||
      this.department === null ||
      !this.department.length
    ) {
      this.department = '';
    }
    this.employeesOfDepart = [];
    setTimeout((e) => {
      $('#employeeDepartmentList').selectpicker('refresh');
    }, 200);
    this.configurationService
      .getEmployeesOfSpecificDepartment(this.department)
      .subscribe(
        (data) => {
          this.employeesOfDepart = data;
          setTimeout((e) => {
            $('#employeeDepartmentList').selectpicker('refresh');
            $('#employeeDepartmentList').selectpicker();
          }, 500);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  ngOnInit(): void {
    this.searchForm = this.fb.group({
      status: '',
      priority: '',
      project: '',
      employee: '',
    });
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
    }, 500);
    $('.clicked').addClass('collapsed');
    $('#exampleModal').on('hide.bs.modal', () => {
      this.assignData.removeAt(0);
      console.log('modal close');
      this.updateassignForm('');
    });
    $('#timeLogModal').on('hide.bs.modal', () => {
      this.indexVariable = '';
    });

    this.perPageValue = 25;
    this.getEmployeesOfDepartment();
    this.addComplaint = this.fb.group({
      name: ['', Validators.required],
      phone: '',
      description: ['', Validators.required],
      department: '',
      location: '',
      priority: ['', Validators.required],
      project: ['', Validators.required],
      file: [null],
      tag: [''],
      assign: [''],
      status: 1,
      contact_person: '',
      external_link: '',
    });

    // this.getComplainData = this.fb.group({
    //   assign: this.fb.array( [this.assignForm()]),
    // });
    this.getProject();
    this.getAllTags();
    this.searchFilter();
    this.getLocation();
    this.getStatus();
    this.getEmployess();
    this.getPriority();
    $('[data-toggle="collapse"]').on('click', function () {
      $(this).toggleClass('collapsed');
    });
  }
  getDate(): any {}
  getAllTags(): any {
    this.tagListData = [];
    this.task.getTagList().subscribe(
      (data) => {
        this.tagListData = data;
        // console.log(data);

        // for (let i = 0; i < data.length; i++) {
        //   console.log(data[i].name)
        //   this.tagListData.push(data[i].name);
        //
        // }
        // console.log('tags',this.tagListData)
        //
        // setTimeout( ()=>{
        //   $('.select2').selectpicker('refresh');
        //   $('.select2').selectpicker();
        //   }, 300);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getPriority(): any {
    this.priority = [];
    this.task.getPriority().subscribe(
      (data) => {
        this.priority = data;
        // console.log(data);

        // for (let i = 0; i < data.length; i++) {
        //   console.log(data[i].name)
        //   this.tagListData.push(data[i].name);
        //
        // }
        // console.log('tags',this.tagListData)
        //
        // setTimeout( ()=>{
        //   $('.select2').selectpicker('refresh');
        //   $('.select2').selectpicker();
        //   }, 300);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  preventChangeDate(): any {
    return false;
  }

  assignForm(): UntypedFormGroup {
    return this.fb.group({
      id: '',
      is_remove: false,
      assign_to: ['', Validators.required],
      time: [0, Validators.required],
      start_date: ['', Validators.required],
      due_date: ['', Validators.required],
    });
  }
  updateassignForm(data): UntypedFormGroup {
    return this.fb.group({
      id: [data.id],
      is_remove: [false],
      assign_to: [data.employee, Validators.required],
      time: [data.allocated_time, Validators.required],
      start_date: [data.start_date, Validators.required],
      due_date: [data.end_date, Validators.required],
    });
  }
  get assignData(): any {
    return this.getComplainData.get('assign') as UntypedFormArray;
  }

  addAssignData(): any {
    this.assignData.push(this.assignForm());
    for (let i = 0; i < this.assignData.length; i++) {
      console.log('itration', i);
      setTimeout(() => {
        $('#employee' + i).selectpicker('refresh');
        $('#employee' + i).selectpicker();
      }, 200);
    }
    // const ind=this.assignData.length;
    // const now = new Date();
    // const day = ('0' + now.getDate()).slice(-2);
    // const month = ('0' + (now.getMonth() + 1)).slice(-2);
    // const today =   (month) + '-' +(day)+ '-' +now.getFullYear();

    // console.log('hello' ,  this.assignData.at(ind-1).get('due_date').patchValue(today));
    // console.log('helo' ,  this.assignData.at(ind-1).get('start_date').patchValue(today));

    // this.getComplainData.get(['assign', this.assignData.length-1]).get('start_date').patchValue(today);
    //   this.getComplainData.get(['assign', this.assignData.length-1]).get('due_date').patchValue(today);
    //      this.assignData[this.assignData.length - 1].start_date = today;
    //    this.assignData[this.assignData.length - 1].due_date = today;
    //   console.log('today is',$('#toDate' + ind, '#toDate' + ind).val(today));

    // ;
    // ;
    // this.assignData.at(ind-1).get('due_date').patchValue($('#fromDate' + ind, '#fromDate' + ind).val(today));
    // this.assignData.at(ind-1).get('start_date').patchValue($('#toDate' + ind, '#toDate' + ind).val(today));
    console.log(this.assignData.value);
    //     setTimeout( () => {
    //       $('#fromDate' + ind).datetimepicker({
    //         defaultDate: new Date(),
    //         format: 'DD/MM/YYYY ',
    //       });
    //       //  $('#fromDate').datepicker('setDate', new Date());
    //
    //       $('#toDate'+ ind).datetimepicker({
    //         defaultDate: new Date(),
    //         format: 'DD/MM/YYYY '
    //       });
    // //      $('#toDate').datepicker('setDate', new Date());
    //       // let current_time = moment().format('DD-MM-YYYY h:mm a');
    //     }, 100);
    //     $('.selectpicker').selectpicker();
  }
  deleteAssignData(i: number): any {
    console.log(i);
    let id = this.getComplainData.get(['assign', i]).get('id').value;
    if (id === '') {
      this.assignData.removeAt(i);
    } else {
      this.getComplainData.get(['assign', i]).get('is_remove').setValue(true);
    }

    //  this.assignForm().get(['is_removed',i]).setValue(false)
    //
    // this.assignData.push(this.assignForm().get('is_removed').setValue(false));
    console.log('values patched', this.assignData);
    // console.log(this.assignForm().get('is_removed').setValue(false))
  }
  _onlyNumeric(e): any {
    var charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  closeIndentList(): any {
    $('#indent-complaint').removeClass('offcanvas-on');
  }
  openIndentList(): any {
    $('#department').selectpicker('refresh');
    $('#selectedLocation').selectpicker('refresh');
    $('#selectStatus').selectpicker('refresh');
    $('#modalSelect').selectpicker('refresh');
    $('#taskStatus').selectpicker('refresh');
    $('#assign_to').selectpicker('refresh');
    $('#prioritySelect').selectpicker('refresh');
    $('#prioritySelect2').selectpicker('refresh');
    $('#prioritySelect3').selectpicker('refresh');
    $('#selectStatus').selectpicker('refresh');
    $('#viewStatus').selectpicker('refresh');
    $('#addProject').selectpicker('refresh');
    $('#searchProject').selectpicker('refresh');

    this.addComplaint.get('file').setValue('');
    // this.addComplaint.get('priority').setValue(3);
    $('#indent-complaint').toggleClass('offcanvas-on');
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    if (this.userData) {
      this.addComplaint
        .get('owner')
        .setValue(this.userData.first_name + ' ' + this.userData.last_name);
    }
  }
  getfocusdata(value, type, index, task_id): any {
    if (this.getComplainData.get(['assign', index]).get('id').value === '') {
      return;
    }

    console.log('hello', value, type);
    console.log(this.getComplainData.value);
    let values: any;
    values = this.getComplainData.get(['assign', index]);
    console.log('data', values.value, task_id);
    this.task.updateAsign(values.value, type, task_id).subscribe(
      (data) => {
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  openView(id): any {
    this.view = true;
    this.commentsCheck = false;
    this.currentTask_id = id;
    console.log('current Task', this.currentTask_id);

    this.openComplaint(id);
  }
  showView(id): any {
    this.commentsCheck = true;
    this.openComplaint(id);
  }
  onSubmitData2(): any {
    console.log('current Task', this.currentTask_id);
    this.timeCheck = true;
    if (this.timeLogData.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.task
      .addTimelog(this.timeLogData.value, this.task_id, this.employee_id)
      .subscribe(
        (data) => {
          this.timeCheck = false;
          this.commentsCheck = false;
          this.openComplaint(this.currentTask_id);
          this.loaderCheck = false;

          this.ngxLoader.stop();

          $('#timeLogModal').modal('hide');

          this.timeLogData.reset();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }

  timeLog(emp_id, tsk_id, is_completed): any {
    this.employee_id = emp_id;
    this.task_id = tsk_id;
    this.timeloglist = '';
    this.timeLogData.get('is_completed').patchValue(is_completed);

    $('#timeLogModal').modal('show');
  }

  getLogs(emp_id, id, i): any {
    $('.isShow').addClass('collapsed.mainRow');
    $('.isShow').addClass('mainRows');
    $('.isShow').addClass('collapsed');

    $('.isShow').addClass('mainRow');
    if (this.indexVariable === i) {
      // $('.isShow' ).removeClass('show');
      $('.isShow' + i).addClass('mainRows');
      return;
    }
    $('.collapse').removeClass('show');
    $('.isShow' + i).addClass('mainRows');
    this.indexVariable = i;
    // if(this.timeloglist)
    // {
    //   $('.isShow' ).removeClass('show');
    //   this.timeloglist='';
    //
    //   return;
    // }
    this.ngxLoader.start();

    this.task.getTimelog(id, emp_id).subscribe(
      (data) => {
        this.timeloglist = data;

        this.ngxLoader.stop();
        setTimeout(() => {
          // $('.isShow').removeClass('show');
          // $('.isShow' +i).addClass('collapsed');
          // $('.isShow' +i).addClass('show');
          $('.testContent' + i).addClass('show');
        }, 1000);
      },

      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
    // setTimeout(()=>{
    //   $('.isShow').removeClass('show');
    //   // $('.isShow' +i).addClass('collapsed');
    //   $('.isShow' +i).addClass('show');
    // },1000);
  }

  _preventChangeData(event: any): any {
    event.preventDefault();
  }
}
