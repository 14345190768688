<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center justify-content-between">
        <button
          type="button"
          class="btn btn-light-primary font-weight-bolder btn-sm mr-5"
          *ngIf="showBackButton"
          accesskey="b"
          (click)="patientRegistration(patient)"
        >
          Back
        </button>
        <a
          class="btn btn-light-primary font-weight-bolder btn-sm"
          (click)="openPatientList()"
          >Patients</a
        >
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::Ot Calendar-->

      <!--      <div class="row mb-5" >-->
      <!--        <div class="col-md-12">-->

      <!--        </div>-->
      <!--      </div>-->

      <div
        class="card card-custom mb-4 d-none d-print-block"
        *ngIf="patient?.admitted === true"
      >
        <div class="card-body custom-card-body admissionTags">
          <div style="border-bottom: 2px solid #000000; margin-top: 30px">
            <div class="row mb-2">
              <div class="col-4 font-weight-bold">Patient Name:</div>
              <div class="col-8 font-weight-bold">
                {{ patient?.title | titlecase }}&nbsp;{{
                  patient?.first_name + " " + patient?.last_name | titlecase
                }}
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-4 font-weight-bold">Consultant:</div>
              <div class="col-8 font-weight-bold">
                {{ patient?.consultant_name }}
              </div>
            </div>
            <div class="row mb-2" style="margin-bottom: 30px">
              <div class="col-4 font-weight-bold">Admission Date:</div>
              <div class="col-8 font-weight-bold">
                {{
                  patient?.admissions[0]?.created_at
                    | date: "dd/MM/yyyy, h:mm a"
                }}
              </div>
            </div>
          </div>

          <div style="border-bottom: 2px solid #000000; margin-top: 30px">
            <div class="row mb-2">
              <div class="col-4 font-weight-bold">Patient Name:</div>
              <div class="col-8 font-weight-bold">
                {{ patient?.title | titlecase }}&nbsp;{{
                  patient?.first_name + " " + patient?.last_name | titlecase
                }}
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-4 font-weight-bold">Consultant:</div>
              <div class="col-8 font-weight-bold">
                {{ patient?.consultant_name }}
              </div>
            </div>
            <div class="row mb-2" style="margin-bottom: 30px">
              <div class="col-4 font-weight-bold">Admission Date:</div>
              <div class="col-8 font-weight-bold">
                {{
                  patient?.admissions[0]?.created_at | date: "dd-MM-yyyy h:mm a"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row d-print-none">
        <div class="col-12">
          <div class="card card-custom mb-4" *ngIf="patient">
            <div class="card-body custom-card-body">
              <!--begin::Top-->
              <div class="d-flex">
                <!--begin::Pic-->
                <div class="flex-shrink-0 mr-7">
                  <div class="symbol symbol-50 symbol-lg-100">
                    <img
                      *ngIf="patient?.gender === 'transgender'"
                      src="assets/media/users/blank.png"
                      class="h-50 align-self-end"
                      alt=""
                    />
                    <img
                      *ngIf="patient?.gender === 'unidentified'"
                      src="assets/media/users/blank.png"
                      class="h-50 align-self-end"
                      alt=""
                    />
                    <img
                      *ngIf="patient?.gender === 'male'"
                      src="assets/media/svg/avatars/009-boy-4.svg"
                      class="h-50 align-self-end"
                      alt=""
                    />
                    <img
                      *ngIf="patient?.gender === 'female'"
                      src="assets/media/svg/avatars/002-girl.svg"
                      class="h-50 align-self-end"
                      alt=""
                    />
                  </div>
                </div>
                <!--end::Pic-->
                <!--begin: Info-->
                <div class="flex-grow-1">
                  <!--begin::Title-->
                  <div
                    class="d-flex align-items-center justify-content-between flex-wrap mt-2"
                  >
                    <!--begin::User-->
                    <div class="mr-3">
                      <!--begin::Name-->
                      <a
                        class="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3"
                        ><span *ngIf="patient?.title"
                          >{{ patient?.title | titlecase }} </span
                        >&nbsp;{{
                          patient?.first_name + " " + patient?.last_name
                            | titlecase
                        }}</a
                      >
                      <!--end::Name-->
                      <!--begin::Contacts-->
                      <div class="d-flex flex-wrap my-2">
                        <a
                          class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >{{ patient?.mrn }}</a
                        >
                        <a
                          class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >{{ patient?.age }}</a
                        >
                        <a
                          class="text-muted text-hover-primary font-weight-bold"
                          >{{ patient?.gender | titlecase }}</a
                        >
                      </div>
                      <div class="d-flex flex-wrap my-2">
                        <a
                          class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >{{ patient?.patient_type?.name | titlecase }}</a
                        >
                        <a
                          class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <span *ngIf="patient?.admissions.length > 0"
                            >{{
                              " " +
                                patient.admissions[
                                  patient?.admissions.length - 1
                                ].ward.title
                            }}
                            ({{ patient?.bed_name | titlecase }})
                          </span>
                        </a>
                      </div>
                      <div class="d-flex flex-wrap my-2">
                        <a
                          class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >{{ patient?.phone1 }}</a
                        >
                      </div>
                      <!--end::Contacts-->
                    </div>
                    <!--begin::User-->
                    <!--begin::Actions-->
                    <div class="my-lg-0 my-1">
                      <!--                      <a  class="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2">Ask</a>-->
                      <!--                      <a  class="btn btn-sm btn-primary font-weight-bolder text-uppercase">Hire</a>-->
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Title-->
                </div>
                <!--end::Info-->
              </div>
              <!--end::Top-->
            </div>
          </div>

          <!--          <form [formGroup]="PatientSearchForm">-->

          <div class="card">
            <div class="card-body patient-card">
              <form [formGroup]="PatientAdmissionForm">
                <h4 class="mb-2 mt-2">Referring:</h4>

                <div class="row">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label> Doctor</label>
                      <select
                        class="form-control form-control-sm selectpicker"
                        id="doctorId"
                        formControlName="doctor_id"
                        data-container="body"
                        data-live-search="true"
                        (change)="fetchDepByDoctor($event.target.value)"
                      >
                        <option value="" [selected]="true" [disabled]="true">
                          Select
                        </option>
                        <option
                          [value]="doc.id"
                          *ngFor="let doc of doctors_by_dept"
                        >
                          {{ doc.first_name }} {{ doc.last_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>
                        Admitted To <span class="text-danger">*</span></label
                      >
                      <div
                        [ngClass]="{
                          'is-invalid': submitted && f.referred_to.errors
                        }"
                      >
                        <select
                          class="form-control form-control-sm"
                          formControlName="referred_to"
                          id="referred_to"
                          data-container="body"
                          data-live-search="true"
                          (change)="
                            fetchDoctorsByDep($event.target.value);
                            getSubDepartment($event.target.value)
                          "
                        >
                          <option value="">Select department</option>
                          <option
                            [value]="dept.id"
                            *ngFor="let dept of departmentsIpd"
                          >
                            {{ dept.title }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-4">
                    <label>Sub Department</label>
                    <div class="form-group">
                      <div class="d-block">
                        <select
                          class="form-control form-control-sm selectpicker"
                          id="subDepartment"
                          formControlName="sub_department_id"
                          data-dropup-auto="false"
                          data-live-search="true"
                        >
                          <option value="">Select</option>
                          <option
                            [value]="department.id"
                            *ngFor="let department of subDepartments"
                          >
                            {{ department.title }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>
                        Refer From <span class="text-danger">*</span></label
                      >
                      <div
                        [ngClass]="{
                          'is-invalid': submitted && f.referred_from.errors
                        }"
                      >
                        <select
                          class="form-control form-control-sm"
                          formControlName="referred_from"
                          id="referredFrom"
                          data-container="body"
                          data-live-search="true"
                        >
                          <option value="" [selected]="true" [disabled]="true">
                            Select department
                          </option>
                          <option
                            [value]="dept.id"
                            *ngFor="let dept of departments"
                          >
                            {{ dept.title }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label> Ward <span class="text-danger">*</span></label>
                      <div
                        [ngClass]="{
                          'is-invalid': submitted && f.ward_id.errors
                        }"
                      >
                        <select
                          class="form-control form-control-sm selectpicker"
                          formControlName="ward_id"
                          id="wardId"
                          data-container="body"
                          data-live-search="true"
                          (change)="getWardBeds('', $event.target.value)"
                          [ngClass]="{
                            'is-invalid': submitted && f.ward_id.errors
                          }"
                        >
                          <option value="" [selected]="true" [disabled]="true">
                            Select
                          </option>
                          <ng-container *ngFor="let ward of wards">
                            <option [value]="ward.id">{{ ward?.title }}</option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label> Bed</label>
                      <select
                        class="form-control form-control-sm selectpicker"
                        formControlName="bed_id"
                        id="bedId"
                        data-container="body"
                        data-live-search="true"
                        [ngClass]="{
                          'is-invalid': submitted && f.bed_id.errors
                        }"
                      >
                        <option value="" [selected]="true" [disabled]="true">
                          Select
                        </option>
                        <ng-container *ngFor="let bed of wardBeds">
                          <option
                            [value]="bed.id"
                            *ngIf="bed.status === 'Occupied'"
                            [disabled]="true"
                          >
                            {{ bed.bed_number + "-" + bed.status }}
                          </option>
                          <option
                            [value]="bed.id"
                            *ngIf="bed.status === 'Free'"
                          >
                            {{ bed.bed_number + "-" + bed.status }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Procedure</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="procedure_id"
                        id="procedureId"
                        data-container="body"
                        data-live-search="true"
                      >
                        <option value="" [selected]="true" [disabled]="true">
                          Select
                        </option>
                        <ng-container *ngFor="let otServices of allOtService">
                          <option [value]="otServices.id">
                            {{ otServices.name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label> Ref. Visit ID</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="ref_visit"
                        maxlength="20"
                        placeholder="Enter ID"
                      />
                    </div>
                  </div>
                </div>

                <h4 class="mb-2 mt-2">Next of kin (Details):</h4>

                <div class="row">
                  <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>
                        Name (kin) <span class="text-danger">*</span></label
                      >
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="attendent_name"
                        placeholder="Enter name"
                        [ngClass]="{
                          'is-invalid': submitted && f.attendent_name.errors
                        }"
                        (keypress)="_keyPressOnlyChar($event)"
                      />
                    </div>
                  </div>

                  <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label> CNIC </label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="attendent_nic"
                        placeholder="Enter NIC"
                        [ngClass]="{
                          'is-invalid': submitted && f.attendent_nic.errors
                        }"
                        (keypress)="_onlyNumeric($event)"
                        maxlength="13"
                      />
                    </div>
                  </div>

                  <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label> Phone <span class="text-danger">*</span></label>
                      <input
                        type="text"
                        formControlName="attendent_mobile"
                        placeholder="Mobile Number"
                        class="form-control form-control-sm"
                        (keypress)="_onlyNumeric($event)"
                        maxlength="11"
                        [ngClass]="{
                          'is-invalid': submitted && f.attendent_mobile.errors
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label> Relation </label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="attendent_relationship"
                        [ngClass]="{
                          'is-invalid':
                            submitted && f.attendent_relationship.errors
                        }"
                      >
                        <option value="" [selected]="true" [disabled]="true">
                          Relation
                        </option>
                        <ng-container *ngFor="let lov of lookups">
                          <option
                            [value]="lov.key"
                            *ngIf="lov.category == 'relationship'"
                          >
                            {{ lov.key }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label> Address </label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="attendent_address"
                        placeholder="Address"
                        [ngClass]="{
                          'is-invalid': submitted && f.attendent_address.errors
                        }"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-3 col-md-4 col-sm-6 col-12 moDoctors">
                    <div class="form-group">
                      <label> Duty Doctor</label>
                      <!--                      <div [ngClass]="{ 'is-invalid': submitted && f.medical_officer_id.errors }">-->
                      <select
                        class="form-control form-control-sm selectpicker"
                        id="moDoctors"
                        data-live-search="true"
                        formControlName="medical_officer_id"
                      >
                        <option value="" [selected]="true" [disabled]="true">
                          select doctor
                        </option>
                        <option [value]="doc.id" *ngFor="let doc of doctors">
                          {{ doc.first_name }} {{ doc.last_name }}
                        </option>
                      </select>
                      <!--                      </div>-->
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label> Condition</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="condition"
                        [ngClass]="{
                          'is-invalid': submitted && f.condition.errors
                        }"
                      >
                        <option [selected]="true" value="normal">Normal</option>
                        <option value="abnormal">Abnormal</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-8 col-12">
                    <div class="form-group">
                      <label> Comments</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="comments"
                        placeholder="Enter Comments"
                      />
                    </div>
                  </div>
                </div>
                <ng-container>
                  <img class="border" src="{{ urlImage }}" />
                </ng-container>

                <div class="signature border w-300px h-200px">
                  <canvas #canvas width="300" height="200"></canvas>
                </div>
                <div class="col-md-12 p-0 text-right">
                  <ng-container *ngIf="urlButton">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary mt-3 ml-2"
                      (click)="updateSignature()"
                    >
                      Update Signature
                    </button>
                  </ng-container>
                  <ng-container *ngIf="!urlButton">
                    <button
                      type="button"
                      class="btn btn-outline-primary mt-3 ml-2"
                      (click)="clearSignature()"
                    >
                      clear Signature
                    </button>
                  </ng-container>

                  <button
                    type="button"
                    class="btn btn-light-primary btn-sm ml-3 mt-2 btn-fix"
                    accesskey="c"
                    (click)="resetPatientAdmissionForm()"
                  >
                    Clear
                  </button>
                  <!--                    <button type="button"  class="btn btn-primary btn-sm ml-3 mt-2 btn-fix" (click)="gotoOpdPage()">OPD</button>-->
                  <button
                    type="button"
                    class="btn btn-light-primary btn-sm ml-3 mt-2 btn-fix"
                    accesskey="s"
                    (click)="draftAdmission()"
                  >
                    Draft
                  </button>
                  <button
                    type="button"
                    class="btn btn-light-primary btn-sm ml-3 mt-2 btn-fix"
                    *ngIf="patient?.admitted === true"
                    (click)="printAdmitionSlip()"
                  >
                    Print
                  </button>
                  <!--                    <button type="button"  class="btn btn-primary btn-sm ml-3 mt-2 btn-fix" *ngIf="isUpdate === true" (click)='dischargeAdmittedPatientConform()'>Discharge</button>-->
                  <button
                    type="button"
                    class="btn btn-light-primary btn-sm ml-3 mt-2 btn-fix"
                    *ngIf="patient?.admitted === true"
                    (click)="patientTagPrint()"
                  >
                    Tag Print
                  </button>
                  <button
                    type="button"
                    class="btn btn-light-primary btn-sm ml-3 mt-2 btn-fix"
                    *ngIf="patient?.admitted === true"
                    [disabled]="disableUpdate"
                    (click)="updateAdmittedPatient()"
                  >
                    Update
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm ml-3 mt-2 btn-fix"
                    accesskey="a"
                    *ngIf="patient?.admitted === false"
                    [disabled]="isDisable"
                    (click)="createAdmission()"
                  >
                    Admission
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!--          </form>-->
        </div>
      </div>

      <!--end::Ot Calendar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<!--begin::Quick Cart-->
<div id="patient-list" class="offcanvas offcanvas-right p-5">
  <!--begin::Header-->
  <div
    class="offcanvas-header d-flex align-items-center justify-content-between pb-7"
  >
    <h4 class="font-weight-bold m-0">Patient List</h4>
    <button
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      (click)="closePatientList()"
    >
      <i class="ki ki-close icon-xs text-muted"></i>
    </button>
  </div>
  <!--end::Header-->
  <!--begin::Content-->
  <div class="offcanvas-content ot-patients">
    <!--begin::Wrapper-->
    <div class="offcanvas-wrapper mb-5 scroll-pull">
      <form
        [formGroup]="PatientSearchForm"
        (ngSubmit)="searchPatients(patients.checked)"
      >
        <div class="row">
          <div class="col-12 mb-4">
            <select
              class="form-control form-control-sm"
              formControlName="type"
              [(ngModel)]="PatientSearchForm.value.type"
            >
              <option value="default">All</option>
              <option value="mrn">MRN</option>
              <option value="name">Name</option>
              <option value="phone1">Phone</option>
              <option value="patient_nic">CNIC</option>
              <!--                      <option value="sap_id">SAP ID</option>-->
            </select>
          </div>

          <div class="col-12 p-lr-0 button-wrapper">
            <input
              type="text"
              formControlName="search_query"
              type="text"
              placeholder="Search patient here"
              class="form-control form-control-sm"
            />
            <button class="btn btn-primary btn-sm" type="submit">Search</button>
          </div>
        </div>
      </form>

      <div class="row mt-3">
        <!--        <div class="col-4 text-right align-self-center">Admitted</div>-->
        <div class="col-12 d-flex justify-content-end">
          <span class="mt-2 mr-2">Admitted</span>
          <span class="switch switch-icon">
            <label>
              <input
                type="checkbox"
                #patients
                name="select"
                [(ngModel)]="admittedPatients"
                (click)="changePatients($event)"
              />
              <span></span>
            </label>
          </span>
          <span class="mt-2 ml-1">Requested</span>
        </div>
        <!--        <div class="col-4 text-left align-self-center pl-0">Requested</div>-->
      </div>

      <div class="mt-4 patient-listing">
        <ng-container *ngFor="let patient of patient_admissions; let i = index">
          <!--begin::Item-->
          <!--                  align-items-center-->
          <div
            class="d-flex py-3 px-2 patient-panel"
            (click)="selectPatient(patient)"
          >
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light-success mr-5">
              <span class="symbol-label">
                <img
                  *ngIf="patient?.gender === 'transgender'"
                  src="assets/media/users/blank.png"
                  class="h-100 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="patient?.gender === 'unidentified'"
                  src="assets/media/users/blank.png"
                  class="h-100 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="patient?.gender === 'male'"
                  src="assets/media/svg/avatars/009-boy-4.svg"
                  class="h-75 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="patient?.gender === 'female'"
                  src="assets/media/svg/avatars/002-girl.svg"
                  class="h-75 align-self-end"
                  alt=""
                />
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <!--            flex-grow-1-->
            <div class="d-flex flex-column flex-grow-1 font-weight-bold">
              <!--              text-hover-primary-->
              <a class="text-dark mb-1 font-size-lg">{{
                patient?.first_name + " " + patient?.last_name
              }}</a>
              <div class="d-inline">
                <span class="text-muted mr-4">{{ patient?.mrn }}</span>
                <!--                        / {{patient?.gender | titlecase}}-->
                <span class="text-muted mr-4">{{ patient?.age }}</span>
              </div>
              <span class="text-muted mr-4" *ngIf="patient?.admissions">{{
                patient?.admissions[0]?.ward?.title | titlecase
              }}</span>
            </div>
          </div>
          <!--end::Item-->
        </ng-container>
      </div>
    </div>
    <!--end::Wrapper-->
    <!--begin::Purchase-->
    <div class="offcanvas-footer"></div>
    <!--end::Purchase-->
  </div>
  <!--end::Content-->
</div>
<!--end::Quick Cart-->

<!--Confirm patient Discharge Modal-->
<div class="example-preview">
  <div>
    <ng-template #conformPatientDischargeModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>Are you sure you want to discharge this patient?<br /></div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="dischargeAdmittedPatient()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Confirm patient Discharge Modal-->
<div class="example-preview">
  <div>
    <ng-template #conformFinalSettlementModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <p>Final Settlement Is Pending.</p>
            <p>Are you sure you want to finalize the payments!</p>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="finalSettlementPatient()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
