<div class="mb-5">
  <h4 class="secndData">
    <ng-container *ngIf="pageDataList?.group_name; else testName">
      <strong>{{ pageDataList?.group_name }}</strong>
    </ng-container>
    <ng-template #testName>
      <strong>{{ pageDataList?.test_name }}</strong>
    </ng-template>
  </h4>
</div>
<table class="w-100">
  <div>
    <ng-container
      *ngFor="let listObj of pageDataList?.lab_test_details; let j = index"
    >
      <div class="">
        <div>
          <div class="d-flex" *ngIf="listObj?.culture_specimen?.length">
            <p class="font-weight-bolder cultSpec" style="width: 17%">
              SPECIMEN:
            </p>
            <ng-container *ngFor="let culturelist of listObj?.culture_specimen">
              <p class="cultSpecData" style="width: 83%">
                {{ culturelist?.name }}
              </p>
            </ng-container>
          </div>
          <div class="d-flex" *ngIf="listObj?.culture_specimen?.length">
            <p class="font-weight-bolder cultSpec" style="width: 17%">
              GROWTH:
            </p>
            <ng-container *ngFor="let culturelist of listObj?.culture_specimen">
              <p class="cultSpecData" style="width: 83%">
                 {{ culturelist?.value }}
              </p>
            </ng-container>
          </div>
          <div>
            <div class="d-flex" *ngIf="listObj?.culture?.length">
              <div class="w-100">
                <div class="d-flex">
                  <div style="width: 20.5%">
                    <p class="font-weight-bolder cultSpec" style="width: 60%">
                      CULTURE:
                    </p>
                  </div>
                  <div class="d-flex flex-column" style="width: 100%">
                    <div
                      class="cultSpecData"
                      style="width: 83%"
                      *ngFor="let list of listObj.culture; let k = index"
                    >
                      <p>
                        G{{ k + 1 }}- {{ list?.growth }} growth of
                        {{ list?.culture }} isolated after
                        {{ list?.duration_value }} {{ list?.duration_type }} of
                        incubation at {{ list?.temperature }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-3">
          <!--          <div style="width: 80%">-->
          <!--            <p class="cultSpec font-weight-bolder"><u>ANTIMICROBIAL SENSITIVITY</u></p>-->
          <!--          </div>-->
          <!--            <div class="d-flex text-center antiSens font-weight-bolder" style="width: 18.5%">-->
          <!--              <p class="tHeaderSide leftBorders text-center font-weight-bolder mb-0" style="width: 10%" *ngFor="let list of listObj.culture; let k = index">G{{k + 1}}</p>>-->
          <!--            </div>-->
          <div class="d-flex w-100" *ngIf="listObj?.culture?.length && listObj?.sensitivity?.length">
            <p class="cultSpec mb-0 font-weight-bolder" style="width: 90%">
              ANTIMICROBIAL SENSITIVITY
            </p>
            <p
              class="tHeaderSide leftBorders text-center font-weight-bolder mb-0"
              style="width: 10%"
              *ngFor="let list of listObj.culture; let k = index"
            >
              G{{ k + 1 }}
            </p>
            <!--          <p class="tHeaderSide rightBorders text-center font-weight-bolder" style="width: 10%">E</p>-->
            <!--          <p class="tHeaderSide text-center font-weight-bolder" style="width: 10%">E</p>-->
          </div>
        </div>
        <div style="width: 100%" *ngIf="listObj?.sensitivity?.length">
          <ng-container
            *ngFor="let sensitData of listObj?.sensitivity; let l = index"
          >
            <div class="ml-6">
              <p class="type1 font-weight-bolder mt-6">
                {{ sensitData?.type_name }}
              </p>
            </div>
            <div
              class="d-flex"
              *ngFor="let senseitivity of sensitData?.data; let m = index"
            >
              <p
                class="tHeaderSide cultSpecData mb-0"
                style="width: 90%; padding-left: 30px"
              >
                {{ senseitivity?.group_name }}
              </p>
              <p
                class="tHeaderSide leftBorders text-center font-weight-bolder mb-0"
                style="width: 10%"
                *ngFor="
                  let data of senseitivity?.sensitivity_resistance;
                  let q = index
                "
              >
                {{ data?.type }}
              </p>
              <!--          <p class="tHeaderSide rightBorders text-center font-weight-bolder" style="width: 10%">E</p>-->
              <!--          <p class="tHeaderSide text-center font-weight-bolder" style="width: 10%">E</p>-->
            </div>
          </ng-container>
        </div>
        <div class="d-flex ml-6 mt-3 intRes" *ngIf="listObj?.sensitivity?.length">
          <p>S=Sensitive</p>
          <p class="ml-5">I=Intermediate</p>
          <p class="ml-5">R=Resistant</p>
        </div>
        <div>
          <div class="d-flex" *ngIf="listObj?.reports_comment?.length">
            <div class="w-100">
              <div class="d-flex">
                <div style="width: 20.5%">
                  <p class="font-weight-bolder cultSpec" style="width: 100%">
                    COMMENTS:
                  </p>
                </div>
                <div class="d-flex flex-column" style="width: 100%">
                  <div
                    class="cultSpecData"
                    style="width: 83%"
                    *ngFor="let list of listObj?.reports_comment; let n = index"
                  >
                    <p>G{{ n + 1 }}- {{ list?.comments }} {{ list?.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex" *ngIf="listObj?.gram_stain?.length">
            <div class="w-100">
              <div class="d-flex">
                <div style="width: 20.5%">
                  <p class="font-weight-bolder cultSpec" style="width: 100%">
                    GRAM STAIN:
                  </p>
                </div>
                <div class="d-flex flex-column" style="width: 100%">
                  <div
                    class="cultSpecData"
                    style="width: 83%"
                    *ngFor="let list of listObj?.gram_stain; let o = index"
                  >
                    <p>{{ list?.comments }} {{ list?.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex" *ngIf="listObj?.zn_stain?.length">
            <div class="w-100">
              <div class="d-flex">
                <div style="width: 20.5%">
                  <p class="font-weight-bolder cultSpec" style="width: 100%">
                    ZN STAIN:
                  </p>
                </div>
                <div class="d-flex flex-column" style="width: 100%">
                  <div
                    class="cultSpecData"
                    style="width: 83%"
                    *ngFor="let list of listObj?.zn_stain; let p = index"
                  >
                    <p>{{ list?.comments }} {{ list?.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex" *ngIf="listObj?.fungal_stain?.length">
            <div class="w-100">
              <div class="d-flex">
                <div style="width: 20.5%">
                  <p class="font-weight-bolder cultSpec" style="width: 100%">
                    FUNGAL STAIN:
                  </p>
                </div>
                <div class="d-flex flex-column" style="width: 100%">
                  <div
                    class="cultSpecData"
                    style="width: 83%"
                    *ngFor="let list of listObj?.fungal_stain; let r = index"
                  >
                    <p>{{ list?.comments }} {{ list?.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex" *ngIf="listObj?.pathologist_remarks">
          <div class="w-100">
            <div class="d-flex">
              <div style="width: 20.5%">
                <p class="font-weight-bolder cultSpec" style="width: 100%">
                  Pathologist Remarks:
                </p>
              </div>
              <div class="d-flex flex-column" style="width: 100%">
                <div class="cultSpecData" style="width: 83%">
                  <p>{{ listObj?.pathologist_remarks }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</table>
