import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '../../../services/patient.service';

@Component({
  selector: 'app-family-info',
  templateUrl: './family-info.component.html',
  styleUrls: ['./family-info.component.css'],
})
export class FamilyInfoComponent implements OnInit {
  patientId: any;
  familyData: any;
  showMainHeading = false;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.patientId = params['patient_id'];
    });
    this.getFamilyInfoData();
  }

  getFamilyInfoData(): any {
    this.patientService.getFamilyInfoData(this.patientId).subscribe(
      (data) => {
        this.familyData = data[0];
        if (
          this.familyData?.guardian_name ||
          this.familyData?.relation ||
          this.familyData?.contact ||
          this.familyData?.alternate_contact ||
          this.familyData?.cnic ||
          this.familyData?.passport ||
          this.familyData?.occupation ||
          this.familyData?.qualification ||
          this.familyData?.mother_name ||
          this.familyData?.mother_profession ||
          this.familyData?.sibling ||
          this.familyData?.country_name ||
          this.familyData?.city ||
          this.familyData?.address ||
          this.familyData?.accommodation ||
          this.familyData?.family_income ||
          this.familyData?.profile_image
        ) {
          this.showMainHeading = true;
        }
      },
      (err) => {},
    );
  }
}
