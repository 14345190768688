<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-baseline justify-content-between flex-wrap mr-5 w-100"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Actions-->
        <!--        <a  class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a>-->
        <!--end::Actions-->
        <!--begin::Dropdown-->
        <!--        <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">-->
        <!--          <a  class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--											<span class="svg-icon svg-icon-success svg-icon-2x">-->
        <!--												&lt;!&ndash;begin::Svg Icon | path:assets/media/svg/icons/Files/File-plus.svg&ndash;&gt;-->
        <!--												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
        <!--													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
        <!--														<polygon points="0 0 24 0 24 24 0 24" />-->
        <!--														<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />-->
        <!--														<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000" />-->
        <!--													</g>-->
        <!--												</svg>-->
        <!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--											</span>-->
        <!--          </a>-->
        <!--          <div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">-->
        <!--            &lt;!&ndash;begin::Navigation&ndash;&gt;-->
        <!--            <ul class="navi navi-hover">-->
        <!--              <li class="navi-header font-weight-bold py-4">-->
        <!--                <span class="font-size-lg">Choose Label:</span>-->
        <!--                <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mb-3 opacity-70"></li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-success">Customer</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-danger">Partner</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-warning">Suplier</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-primary">Member</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-dark">Staff</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mt-3 opacity-70"></li>-->
        <!--              <li class="navi-footer py-4">-->
        <!--                <a class="btn btn-clean font-weight-bold btn-sm" >-->
        <!--                  <i class="ki ki-plus icon-sm"></i>Add new</a>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--            &lt;!&ndash;end::Navigation&ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::Card-->
      <div class="card card-custom gutter-b d-print-none">
        <div class="card-body">
          <div class="row roster-bar">
            <!--Select Month-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Select Month <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm month"
                  placeholder="Select Month"
                  readonly="readonly"
                  [(ngModel)]="month"
                />
              </div>
            </div>
            <!--Location-->
            <!--Department-->
            <div class="col-sm-3 col-6">
              <div class="d-block form-group">
                <label>Department:</label>
                <select
                  class="form-control form-control-sm selectpicker"
                  multiple
                  data-live-search="true"
                  data-container="body"
                  id="department"
                  name="department"
                  [(ngModel)]="department"
                >
                  <option value="" [disabled]="true">Select Department</option>
                  <ng-container *ngFor="let dep of depart">
                    <option [value]="dep.id">{{ dep.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--Location-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="d-block form-group">
                <label>Location: </label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  id="selectedLocation"
                  name="selectedLocation"
                  [(ngModel)]="selectedLocation"
                >
                  <option value="" [disabled]="true">Select Location</option>
                  <ng-container *ngFor="let location of locationData">
                    <option [value]="location.id">{{ location?.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--Search-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="d-block form-group">
                <button
                  class="btn btn-light-primary btn-sm"
                  (click)="getRostarShiftReport()"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <!--shifts-->
        </div>
      </div>
      <!--end::Card-->

      <!--begin::Card-->
      <!--      *ngIf="showData === true"-->
      <div class="card card-custom gutter-b pdf-card" *ngIf="shiftValue.length">
        <!--begin::Body-->
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <div
              class="form-group d-flex align-items-center justify-content-end d-print-none d-block"
            >
              <label>Show Designation</label>
              <span class="switch switch-icon ml-5">
                <label>
                  <input type="checkbox" [(ngModel)]="showDesignation" />
                  <span></span>
                </label>
              </span>
            </div>
            <div class="export_btn d-block d-print-none text-right mb-5">
              <div class="dropdown dropdown-inline mr-2">
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="svg-icon svg-icon-md">
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Design/PenAndRuller.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                          fill="#000000"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                          fill="#000000"
                        ></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon--> </span
                  >Export
                </button>
                <!--begin::Dropdown Menu-->
                <div
                  class="dropdown-menu dropdown-menu-sm dropdown-menu-right"
                  style=""
                >
                  <!--begin::Navigation-->
                  <ul class="navi flex-column navi-hover py-2">
                    <li
                      class="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2"
                    >
                      Choose an option:
                    </li>
                    <li class="navi-item">
                      <a (click)="printReport()" class="navi-link">
                        <span class="navi-icon">
                          <i class="la la-print"></i>
                        </span>
                        <span class="navi-text">Print</span>
                      </a>
                    </li>
                  </ul>
                  <!--end::Navigation-->
                </div>
                <!--end::Dropdown Menu-->
              </div>
            </div>
          </div>
          <!-- header table -->
          <table class="table">
            <tbody>
              <tr class="borders">
                <td style="width: 15%">
                  <img
                    [src]="settingData?.logo?.url"
                    style="width: auto; height: 106px"
                  />
                </td>
                <td style="width: 70%">
                  <div class="text-center">
                    <h3>{{ settingData?.title }}</h3>
                  </div>
                  <div class="font-size-h5 text-center">
                    <h5>{{ settingData?.address }}</h5>
                  </div>
                  <div class="font-size-h5 text-center">
                    <h5>Duty Roster of {{ shiftValue[0]?.location }}</h5>
                  </div>
                  <div class="font-size-h5 text-center">
                    <h5>{{ showMonth | date: "MMMM, yyyy" }}</h5>
                  </div>
                </td>
                <td style="width: 15%"></td>
              </tr>
            </tbody>
          </table>
          <!--begin::Table-->
          <div class="table-responsive position-relative employeeShiftTable">
            <table
              class="table shift-report-table"
              id="kt_advance_table_widget_1"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <tr class="text-left">
                  <th class="pr-0" style="width: 10%">Name</th>
                  <th
                    style="text-align: center"
                    *ngFor="let da of shiftLength; let i = index"
                  >
                    {{ i + 1 }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let val of shiftValue; let i = index">
                  <td>
                    <span
                      class="d-block"
                      style="font-weight: 500; word-break: break-word"
                      >{{ val?.employee?.first_name }}
                      {{ val?.employee?.last_name }}</span
                    >
                    <span
                      *ngIf="showDesignation"
                      style="word-break: break-word"
                      >{{ val?.employee?.designation?.name }}</span
                    >
                    <span
                      class="text-muted font-weight-bold text-muted d-block"
                      >{{ val?.employee?.employee_file_no }}</span
                    >
                  </td>
                  <td
                    style="text-align: center"
                    *ngFor="let ros of val?.roster_detail"
                  >
                    <ng-container *ngFor="let data of ros?.attendance">
                      <!--                    <span *ngIf="data?.status === 1"><b>P</b></span>-->
                      <!--                    <span *ngIf="data?.status === 2"><b>O</b></span>-->
                      <span *ngIf="data?.status === 3"><b>L</b></span>
                      <span *ngIf="data?.status === 4"><b>DO</b></span>
                      <span *ngIf="data?.status === 5"><b>G</b></span>
                    </ng-container>
                    <!--                  <span *ngIf="ros?.is_day_off === true">O</span>-->
                    <!--                  <span *ngIf="ros?.is_gazetted_off === true">G</span>-->
                    <!--                  <span *ngIf="ros?.is_leave === true">L</span>-->
                    <!--                  <span *ngIf="ros?.is_week_off === true">W</span>-->
                    <div *ngFor="let shif of ros?.shift">{{ shif }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--            <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>-->
          </div>
          <!--end::Table-->
          <!--          <div>-->
          <!--            <div>Note:</div>-->
          <!--            <div><span>E:</span>Hello,This is descripition</div>-->
          <!--          </div>-->
          <table class="w-100 mt-3 mb-6">
            <tbody>
              <tr>
                <td class="paraFont">
                  <span style="display: inline-block; height: 70px"></span>
                  <span
                    class="pdfCheckText"
                    style="
                      padding-top: 3px;
                      font-weight: 600;
                      display: inline-block;
                      width: 200px;
                      border-top: 1px solid #000;
                      text-align: center;
                    "
                    >Team Leader</span
                  >
                </td>
                <td class="text-right paraFont">
                  <span style="display: inline-block; height: 70px"></span>
                  <span
                    class="pdfCheckText"
                    style="
                      padding-top: 3px;
                      font-weight: 600;
                      display: inline-block;
                      width: 200px;
                      border-top: 1px solid #000;
                      text-align: center;
                    "
                    >Head of Department</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <table class="w-100 mt-3 mb-6">
            <tbody>
              <tr>
                <td class="paraFont">
                  <span style="display: inline-block; height: 40px"></span>
                  <span
                    class="pdfCheckText"
                    style="
                      padding-top: 3px;
                      font-weight: 600;
                      display: inline-block;
                      width: 200px;
                      border-top: 1px solid #000;
                      text-align: center;
                    "
                    >H.R Department</span
                  >
                </td>
                <td class="text-right paraFont">
                  <span style="display: inline-block; height: 40px"></span>
                  <span
                    class="pdfCheckText"
                    style="
                      padding-top: 3px;
                      font-weight: 600;
                      display: inline-block;
                      width: 200px;
                      border-top: 1px solid #000;
                      text-align: center;
                    "
                    >Chief operating officer</span
                  >
                  <!--                <span class="pdfCheckText" style="padding-top: 3px; font-weight: 600; display: inline-block; width: 200px; border-top: 1px solid #000; text-align: center;">Administrator</span>-->
                </td>
              </tr>
            </tbody>
          </table>
          <table class="w-100 mt-3 mb-6">
            <tbody>
              <tr>
                <td class="paraFont">
                  <div>
                    <div class="mb-5"><strong>Note:</strong></div>
                    <div *ngFor="let shift of shiftData" class="mb-3">
                      <div>
                        <span style="font-weight: 600">{{ shift.name }}:</span>
                        {{ shift.time_from }} - {{ shift.time_to }} (
                        {{ shift.description }} )
                      </div>
                      <!--                  <div>{{shift.description}}</div>-->
                    </div>
                  </div>
                  <div>
                    <div class="mb-3">
                      <!--                    <div><span style="font-weight: 600">O:</span> Day Off</div>-->
                      <div>
                        <span style="font-weight: 600">DO:</span> Weekly Off
                      </div>
                      <div><span style="font-weight: 600">L:</span> Leave</div>
                      <div>
                        <span style="font-weight: 600">G:</span> Gazetted
                        Holiday
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--end::Card-->
    </div>
  </div>
</div>
<!--end::Content-->
