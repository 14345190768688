import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientService } from '../../../services/patient.service';
import { ServiceService } from '../../../services/service.service';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { LookupService } from '../../../services/lookup.service';
import { PricingService } from '../../../services/pricing.service';
import { HospitalService } from '../../../services/hospital.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareDataService } from '../../../services/share-data.service';
import { UserService } from '../../../services/user.service';
import { SystemControlsService } from '../../../services/system-controls.service';
import { EmployeeVisitService } from '../../../services/employee-visit.service';
import { ConfiguratorsService } from '../../../services/configurators.service';
import { ConfigurationService } from '../../../services/configuration.service';
import * as moment from 'moment';
import { LocationService } from '../../../services/location.service';
import { FinanceConfigurationService } from '../../../services/finance-configuration.service';
import SignaturePad from 'signature_pad';
import { InventoryGeneralSettingsService } from '../../../services/inventory-general-settings.service';
import { StoreManagementService } from '../../../services/store-management.service';
import { Location } from '@angular/common';
import { ProductCatalogService } from '../../../services/product-catalog.service';
import { MiscService } from '../../../services/misc.service';
import { TemplatesService } from '../../../services/templates.service';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-services-v2',
  templateUrl: './services-v2.component.html',
  styleUrls: ['./services-v2.component.css'],
})
export class ServicesV2Component implements OnInit {
  screenName = '';
  walletPaymentMode = 'cash';
  LabParameterForm: UntypedFormGroup;
  servicesKit: UntypedFormGroup;
  list = [];
  productArrayList = [];
  stores: any;
  store = '';
  lineItems = [];
  lineItem = '';
  productId: any;
  serviceID: any;
  serviceName: any;

  walletId = '';
  walletSelectedBooking = '';
  walletService = '';
  walletCashAmount = 0;
  systemDiscountPercentage = 0;
  walletCreditCardAmount = 0;
  walletOnlineAmount = 0;
  walletCardBank = '';
  walletCardBankRef = '';
  walletOnlineBank = '';
  walletOnlineBankRef = '';
  isRecipeForCurrentService: boolean;
  @ViewChild('serviceAddConfirm') serviceAddConfirm: ElementRef;
  @ViewChild('serviceHistoryUpdateConfirm')
  serviceHistoryUpdateConfirm: ElementRef;
  @ViewChild('updatePerformingModal') updatePerformingModal: ElementRef;
  @ViewChild('updateReferringModal') updateReferringModal: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  sig: SignaturePad;

  current_environment = environment.rorUrl;
  PatientSearchForm: UntypedFormGroup;
  employeeVisitForm: UntypedFormGroup;
  depart: any;
  bookingList = [];
  paymentMode = 'cash';
  productSearch = '';
  searchProductValue = '';
  searchProductType = 'crn';
  productSearchList = [];
  ClientSearchData: any;
  ClientSearchId: any;
  searchValue: any;
  eventOfSearchBar: any;
  productSearchValue = [''];
  idOfSingleProduct = '';
  servicePaidForm = {
    patient_id: '',
    mode_of_payment: 'Cash',
    payment_details: '',
    invoice_ids: [],
    receivable: 0,
    cash: 0,
    ledger: '',
    receivable_percentage: 0,
  };
  serviceForm = {
    patient_id: '',
    mode_of_payment: 'cash',
    payment_details: '',
    patient_services: [],
    credit_service: [],
    product: [],
    discount: 0,
    discount_percentage: 0,
    // manual_discount_percentage: 0,
    receivable: 0,
    cash: 0,
    ledger: '',
    cashAmount: 0,
    creditCardAmount: 0,
    onlineAmount: 0,
    walletAmount: 0,
    payableAmount: 0,
    voucher_discount_percent: 0,
    onlineBank: '',
    onlineBankRef: '',
    cardBankRef: '',
    cardBank: '',
    taxType: '',
    taxAmount: 0,
    systemDiscountPercentage: 0,
    systemDiscountTotal: 0,
    totalServiceAdvance: 0,
    manualDiscountTotal: 0,
    manualDiscountPercentage: 0,
    voucherDiscountTotal: 0,
    voucherDiscountPercentage: 0,
  };
  consultation_services = [
    {
      service_id: '',
      pricing_id: '',
      doctor_id: '',
      service_type: '',
      comments: '',
      department_id: '',
      sub_department_id: '',
      billing_code: '',
      performing_doctor_id: '',
      referring_doctor_id: '',
      mode_of_payment: '',
      price: 0,
      discount: 0,
      service_price: 0,
      actual_price: 0,
      quantity: 0,
      urgent_new: '',
      quantity_allowed: false,
      editable: false,
      patient_service_id: '',
      flight_date_time: '',
      reporting_time_required: false,
    },
  ];
  patient_services = [
    {
      id: '',
      service_advance: 0,
      service_id: '',
      discount_approval: '',
      voucher_id: '',
      code: '',
      voucher_discount_amount: 0,
      voucher_discount_percentage: 0,
      booking_id: '',
      booking_service_id: '',
      pricing_id: '',
      doctor_id: '',
      service_type: '',
      sub_department: '',
      service_dept: '',
      comments: '',
      department_id: '',
      billing_code: '',
      performing_doctor_id: '',
      referring_doctor_id: '',
      mode_of_payment: '',
      quantity: 1,
      service_price: 0,
      billing_package_service_id: '',
      actual_price: 0,
      price: 0,
      // service_advance: 0,
      discount: 0,
      urgent_new: 'routine',
      quantity_allowed: false,
      editable: false,
      patient_service_id: '',
      flight_date_time: '',
      reporting_time_required: false,
      employee_type: '',
      sys_dis: 0,
      man_dis: '',
      man_discount_type: '',
      time: '',
      manual_discount_field_read_only: true,
      discount_above_limit: true,
      discount_type: '',
      discount_limit: '',
      recipe_item: [],
    },
  ];
  product = [
    {
      type: '',
      product: '',
      uom: '',
      unit_price: '',
      quantity: 0,
      total_price: 0,
    },
  ];
  // tslint:disable-next-line:variable-name
  credit_service = {
    id: [],
    cash: 0,
    receivable: 0,
    net_amount: 0,
  };

  doctors = [];
  doctorsAll = [];
  services = [];
  productList = [];
  servicesWallet = [];
  servicesMain = [];
  bookedServices: any;
  departments = [];
  subDepartments = [];
  lookups = [];
  ledgersList: any;
  uniq_services = [];
  patient: any;
  consultantionTotal = 0;
  servicesTotal = 0;
  servicesGrandTotal = 0;
  productsTotal = 0;
  panelType = false;
  patientServiceHistory = [];
  patientServiceHistoryBackup = [];
  activeTab = 'Vitals';
  all_invoices_checked = false;
  paymentStatus = 'paid';
  isAdmitted = false;
  isChecked = false;
  currentControls: any;
  currentHospital = environment.hospital;
  isSubmitted = false;
  selectedPerformingID: number;
  selectedReferringID: number;
  serviceId: number;
  consentServiceId: number;
  signature_url = '';
  visitType = 'opd';
  visitTypeStatus = true;
  paymentTypeStatus = true;
  paymentType = 'unpaid';
  paymentTypeDisabled = false;
  hideUnPaid = false;
  screenControls = [];
  defaultServiceType = '';
  selectedBooking = '';
  defaultServiceName = '';
  requestedServices = false;
  requestedServicesList = [];
  selectedReportingIndex = 0;
  isSubmitReferring = false;
  genderList = [];
  designationList = [];
  titleList = [];
  staffTypeList = [];
  refDoctorsAll = [];
  countries = [];
  states = [];
  cities = [];
  allEmployeesStaffTypeDoctor = [];
  showPriceOnService = true;
  changeTitleOfPrefDoctor = true;
  changeTextToRequestedDoctor = false;
  showElecgiesFields = false;

  anyContraindicationsToReceiveContrast = false;
  anyAllergiesToIodinatedContrast = false;
  anyContraindicationsToReceiveContrastTextarea = '';
  userInfo = '';
  subDepartmentsAgainstServiceType = [];
  addDefaultOtherService = false;
  showSubmit = false;
  showKitButton = false;
  hideCreditCard = false;
  disableServiceCard = false;
  isConsultant = false;
  showRedeemVoucher = false;
  showProductTab = false;
  serviceType: any;
  bankList: any;
  subDepartment: any;
  VoucherList: any[];
  uomList = [];
  departmentID: '';
  bookingId: any = '';
  selectedBookingData: any[];
  bookingListDetail = [];
  savedDraft = [];
  servicesWalletList = [];
  voucherId: '';
  isGeneral: '';
  genralDiscountPercentage: 0;
  voucherListDetail = [];
  manualDiscountList = [];
  manualDiscountData: any;
  manualDiscountID: '';
  manualDiscountAboveLimit: any[];
  manualDiscountAboveLimitAllow: any[];
  walletServices: any[];
  walletTransactionList: any[];
  // categoryDiscountTotal = 0;
  walletCreditInvoices = 0;
  walletUseAbleAmount = 0;
  walletServicesAdvance = 0;
  totalServiceAdvance = 0;
  selectedCreditServiceTotal = 0;
  serviceTotalNetAmount = 0;
  manDisAmount = 0;
  sysDisAmount = 0;
  vocDisAmount = 0;
  serviceIdForRecipe: any;
  serviceIndex: any;
  consetData: any;
  serviceData = [];
  productlist: any[];
  consentsId: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    // private miscService: MiscService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private chRef: ChangeDetectorRef,
    private patientService: PatientService,
    private serviceService: ServiceService,
    private hospitalDoctorsService: HospitaldoctorService,
    private departmentService: DepartmentsbyhospitalService,
    // private lookupService: LookupService,
    private pricingService: PricingService,
    private hospitalService: HospitalService,
    private modalService: NgbModal,
    private shareDataService: ShareDataService,
    private userService: UserService,
    private systemControlsService: SystemControlsService,
    private fb: UntypedFormBuilder,
    private visitService: EmployeeVisitService,
    private configuratorsService: ConfiguratorsService,
    private configurationService: ConfigurationService,
    // private locationService: LocationService,
    private financeConfigService: FinanceConfigurationService,
    // private departmentsByHospitalService: DepartmentsbyhospitalService,
    private departmentsByHospital: DepartmentsbyhospitalService,
    private generalSetting: InventoryGeneralSettingsService,
    private storeService: StoreManagementService,
    private location: Location,
    private productService: ProductCatalogService,
    private ref: ChangeDetectorRef,
    private templatesService: TemplatesService,
    private routeSevice: RouteShareService,
    private systemControlService: SystemControlsService,
  ) {
    // const logInUser = JSON.parse(localStorage.getItem('currentUser'));
    this.screenName = this.routeSevice.getValue();
    // this.screenName = this.routeSevice.getValue();
    // this.LabParameterForm = this.fb.group({
    //   parametersArray: this.fb.array([this.productArray()]),
    // });
    this.serviceID = this.route.snapshot.queryParamMap.get('id');
    this.serviceName = this.route.snapshot.queryParamMap.get('name');
    this.servicesKit = this.fb.group({
      serviceKit: this.fb.array([]),
    });
    this.searchFilter();
    this.getParentStoreList();
    this.getLineItem();
  }

  productArray(): any {
    return this.fb.group({
      id: [''],
      unit_required: [''],
      product_type: [''],
      product_name: [''],
      code: [''],
    });
  }

  get labParametersArray(): any {
    return this.LabParameterForm.get('parametersArray') as UntypedFormArray;
  }

  searchFilter(): any {
    this.ngxLoader.start();
    this.list = [];
    this.productService.getProductListing(1, 25, '').subscribe(
      (data) => {
        this.list = data.items;
        this.ref.markForCheck();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  getParentStoreList(): any {
    this.storeService.getParentStoreList().subscribe(
      (data) => {
        this.stores = data;
        setTimeout(() => {
          $('#store').selectpicker();
        }, 500);
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  goBack(): any {
    this.location.back();
  }

  calculateDiscountAmount(value): any {
    const total = this.servicesTotal + this.consultantionTotal;
    if (value === 'amount') {
      if (Number(this.serviceForm.discount) >= Number(this.serviceForm.cash)) {
        this.serviceForm.discount = this.serviceForm.cash;
        this.serviceForm.discount_percentage = 100;
      }
      const per = (this.serviceForm.discount / total) * 100;
      this.serviceForm.discount_percentage = parseFloat(per.toFixed(2));
    } else {
      if (this.serviceForm.discount_percentage >= 100) {
        this.serviceForm.discount = this.serviceForm.cash;
        this.serviceForm.discount_percentage = 100;
      }
      const per = (total * this.serviceForm.discount_percentage) / 100;
      this.serviceForm.discount = Math.round(per);
    }
    // this.serviceForm.cash = total - this.serviceForm.discount;
    this.serviceForm.cash = total;
    this.updateServiceReceivable();
  }

  ngOnInit(): any {
    setTimeout(() => {
      $('#tax').selectpicker('refresh');
      $('#tax').selectpicker();
      $('.productsName').selectpicker('refresh');
      $('.productsName').selectpicker();
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').selectpicker();
      $('.summernote').summernote({
        height: 180,
      });
    }, 300);
    this.services[0] = [];
    this.subDepartments[0] = [];
    this.subDepartmentsAgainstServiceType[0] = [];
    this.PatientSearchForm = this.formBuilder.group({
      search_query: [''],
      type: ['default'],
    });
    this.getScreenControls();
    this.getBookings(this.route.snapshot.queryParams.patient_id);
    this.fetchUOM();
    this.getBankDetail();
    this.fetchPatient('');
    this.getDepartments();
    this.getManualDiscount();
    this.fetchServicesTypes();
    // this.fetchServices();
    // this.fetchDoctors();
    // this.fetchLookup();
    this.leadersAccount();
    this.fetchDepartments();
    // this.getCountryData();
    this.getEmployeesDoctorWise();
    this.getVoucher();
    this.fetchServicesHistory(true, true);
    setTimeout(() => {
      $('.selectpicker').selectpicker({
        dropupAuto: false,
      });
      // $('.doctorSelectpicker').selectpicker();
    }, 500);
    $('html').click(function () {
      $('.bootstrap-select.open').removeClass('open');
      $('.dropdown-menu').removeClass('show');
    });

    this.employeeVisitForm = this.fb.group({
      title: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: [''],
      gender: ['', Validators.required],
      speciality: [''],
      nic: [
        '',
        Validators.compose([
          Validators.minLength(13),
          Validators.maxLength(13),
        ]),
      ],
      pmdc_registration_no: [''],
      primary_contact: [''],
      hospital_name: [''],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      office_number: [''],
      mobile_number: [''],
      staff_type: ['', Validators.required],
      address: ['', Validators.required],
      department: ['', Validators.required],
      designation: ['', Validators.required],
      status: [true],
    });
    this.getStaffType();
    this.getGenderList();
    // this.getDesignationList();
    this.getTitleList();
    this.fetchReferringDoctors();
    this.getLineItem();
    this.getControls();
  }

  getControls(): any {
    // this.shareDataService.controlCheck.subscribe(data => {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;

      if (this.currentControls !== '') {
        if (this.currentControls.patient_services.is_consultation === false) {
          this.isAdmitted = true;
          this.consultation_services = [];
        }
      }
      // if (this.currentControls !== '') {
      //   if (this.currentControls.patient_services.is_payment_mode === true) {
      //     this.paymentTypeDisabled = true;
      //   } else {
      //     this.paymentTypeDisabled = false;
      //   }
      // }
    });
  }

  getLineItem(): any {
    this.ngxLoader.start();
    this.storeService.getlineItems().subscribe(
      (data) => {
        this.lineItems = data;
        setTimeout(() => {
          $('.productType').selectpicker('refresh');
          $('.productName').selectpicker('refresh');
          $('.productType').selectpicker();
          $('.productName').selectpicker();
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  changePayment(type): any {
    if (type === 'credit_card') {
    }
  }

  openWalletModal(): any {
    // const patientServices = this.patient_services;
    // this.serviceForm.patient_services = ($.merge([], patientServices));
    // this.serviceService.getWalletDetails(patientServices, this.patient).subscribe(
    //   data => {
    //     this.walletServices = data.services;
    //     this.ngxLoader.stop();
    //     },
    //   err => {
    //     this.isSubmitted = false;
    //     this.ngxLoader.stop();
    //     toastr.error(err.error.message);
    //   }
    // );
    $('#walletModal').modal('show');
    setTimeout((e) => {
      $('.selectPickerBankDetail').selectpicker('refresh');
      $('.selectPickerBankDetail').selectpicker();
      $('.selectPickerBooking').selectpicker();
      $('.selectPickerBooking').selectpicker('refresh');
    }, 500);
    this.getwalletHistory();
    this.walletPaymentMode = 'cash';
  }

  addWalletAmount(): any {
    if (
      this.walletCashAmount +
        this.walletOnlineAmount +
        this.walletCreditCardAmount <=
      0
    ) {
      toastr.error('Amount Should not Zero.');
      return;
    }
    this.walletId = this.patient?.wallet.id;
    // this.serviceService.addWalletAmount(this.walletSelectedBooking, this.walletId, this.walletService, this.walletPaymentMode, this.walletCashAmount, this.walletCreditCardAmount, this.walletCardBank, this.walletCardBankRef, this.walletOnlineAmount, this.walletOnlineBank, this.walletOnlineBankRef, this.patient).subscribe(
    //   data => {
    this.getwalletHistory();
    this.resetWalletCard();
    // if (data) {
    //   // window.open('pdf-reports/wallet-invoice/');
    //   window.open('pdf-reports/wallet-invoice?id=' + data?.data.id + '&client_id=' + data?.data.client + '&booking_id=' + data?.data.booking_service);
    // }
    this.ngxLoader.stop();
    //   },
    //   err => {
    //     this.getwalletHistory();
    //     this.isSubmitted = false;
    //     this.ngxLoader.stop();
    //     toastr.error(err.error.message);
    //   }
    // );
    $('#walletModal').modal('show');
    this.walletPaymentMode = 'cash';
  }

  // API to Get manual discount List
  getwalletHistory(): any {
    this.walletTransactionList = [];
    // this.serviceService.getWalletHistoryList(this.patient).subscribe(data => {
    //   this.walletServicesAdvance = data.services_advance;
    //   this.walletUseAbleAmount = data.use_able_amount;
    //   this.walletCreditInvoices = data.credit_invoices;
    //   this.walletTransactionList = data.services_advances;
    // });
  }

  openSignatureModal(id): any {
    this.consentServiceId = id;
    this.sig = new SignaturePad(this.canvas.nativeElement);
    $('#signatureModal').modal('show');
    this.searchTemplate();
  }

  clearSignature(): any {
    this.sig.clear();
  }

  getImage(): any {
    this.signature_url = this.sig.toDataURL();
  }

  submitConsentForm(): any {
    if (this.signature_url === '') {
      this.clearSignature();
    }
    this.getImage();
    this.ngxLoader.start();
    this.consetData.template_data[0].detail = $('#reports').summernote('code');
    // this.templatesService.submitConsentForm(this.consetData.template_data[0].detail, this.consetData.template_data[0].heading, this.signature_url, this.patient.id, this.consentServiceId).subscribe(data => {
    this.consentsId;
    // this.consentsId = data?.consents?.id;
    this.ngxLoader.stop();
    $('#signatureModal').modal('hide');
    this.routeToConsentsForm(this.consentsId);
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  routeToConsentsForm(id): any {
    this.router.navigate(['/pdf-reports/consents'], { queryParams: { id } });
  }

  get controlDose(): any {
    return this.servicesKit.get('serviceKit') as UntypedFormArray;
  }

  getProductId(id): any {
    this.productId = id;
  }

  // addProduct(): any{
  //   const getSingleProduct = this.productlist.find(t => t.id === Number(this.productId));
  //   this.productArrayList.push(getSingleProduct);
  // }
  addProduct(): any {
    const getSingleProduct = this.list.find(
      (t) => t.id === Number(this.productId),
    );
    this.controlDose.push(this.controlDoseArray(getSingleProduct));
  }

  controlDoseArray(data): any {
    return this.fb.group({
      ingredient_id: [''],
      id: [data.id],
      code: [data.code],
      name: [data.name],
      manufacturer_name: [data.manufacturer_name],
      line_item: [data.line_item],
      line_item_id: [data.line_item_id],
      unit_quantity: [data.unit_quantity],
      // unit_req: [data.unit_quantity],
      uom: [data.uom],
      is_remove: false,
      is_modified: true,
      purchase_price: [data.purchase_price],
      total_purchase_price: [0],
      unit_req: [data.unit_req || 1],
      old_unit_req: [data.unit_req || 1],
    });
  }

  getProductsById(e): any {
    // this.storeService.getProductsByLineItemIdNew(e).subscribe(data => {
    this.list;
    // this.list = data.items;
    setTimeout(() => {
      $('#products').selectpicker('refresh');
      $('#products').selectpicker();
    }, 500);
    // }, err => {
    //   toastr.error(err.error.error);
    // });
  }

  // get Service Recipe data against service ID.while open recipe modal against an ID
  getServiceKit(): any {
    // this.productService.getServiceKit(this.serviceIdForRecipe).subscribe(data => {
    this.serviceData;
    // this.serviceData = data.service_kit;
    if (this.serviceData) {
      while (this.controlDose.length) {
        this.controlDose.removeAt(0);
      }
      for (let i = 0; i < this.serviceData.length; i++) {
        if (this.serviceData[i].is_remove === false) {
          this.controlDose.push(this.getKitData(this.serviceData[i]));
        }
      }
    }
    // }, err => {
    //   toastr.error(err.error.message);
    // });
  }

  getKitData(data): any {
    return this.fb.group({
      ingredient_id: [data.ingredient_id],
      id: [data.id],
      code: [data.code],
      name: [data.name],
      manufacturer_name: [data.manufacturer_name],
      line_item: [data.line_item],
      line_item_id: [data.line_item_id],
      unit_quantity: [data.unit_quantity],
      // unit_req: [data.unit_quantity],
      uom: [data.uom],
      is_remove: [data.is_remove],
      is_modified: [data.is_modified || false],
      purchase_price: [data.purchase_price || 0],
      total_purchase_price: [0],
      unit_req: [data.unit_req || 1],
      old_unit_req: [data.unit_req || 1],
    });
  }

  // Open Service Recipy modal
  openRecipyModal(id, i): any {
    this.serviceIndex = i;
    this.serviceIdForRecipe = id;
    this.getServiceKit();
    $('#recipyModal').modal('show');
    setTimeout(() => {
      $('#store').selectpicker();
    }, 500);
  }

  // Submit Service Recipy Modal Botton
  submitNewKit(): any {
    if (!this.controlDose.length) {
      toastr.error('Add Ingrediants');
      return;
    }
    // for (let i = 0;  i < this.patient_services.length; i++){
    while (this.patient_services[this.serviceIndex].recipe_item.length) {
      this.patient_services[this.serviceIndex].recipe_item = [];
    }
    this.patient_services[this.serviceIndex].recipe_item.push(
      this.servicesKit.value.serviceKit,
    );
    $('#recipyModal').modal('hide');
    // return;
    // this.productService.submitServicesKit(this.servicesKit.value.serviceKit, this.serviceIdForRecipe, this.serviceForm.patient_id).subscribe(data => {
    //   this.ngxLoader.stop();
    //   $('#recipyModal').modal('hide');
    //   toastr.success('Added Successfully');
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  searchTemplate(): any {
    this.ngxLoader.start();
    this.templatesService.searchTemplate('', '', '', '').subscribe(
      (data) => {
        this.consetData = data.template_configurators[0];
        $('#reports').summernote(
          'code',
          this.consetData.template_data[0].detail,
        );
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
  }

  getScreenControls(): any {
    this.ngxLoader.start();
    this.userService.fetchUserControlByScreen().subscribe(
      (data) => {
        this.screenControls = data.roles;
        this.updatePaymentTypeConfig();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updatePaymentTypeConfig(): any {
    if (this.screenControls?.length > 0) {
      if (
        this.screenControls.filter((m) => m.code === 'paymenttypedisable')
          .length > 0
      ) {
        this.paymentTypeDisabled = this.screenControls.filter(
          (m) => m.code === 'paymenttypedisable',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'defaultpatienttypeunpaid')
          .length > 0
      ) {
        if (
          this.screenControls.filter(
            (m) => m.code === 'defaultpatienttypeunpaid',
          )[0].is_show === true
        ) {
          this.paymentStatus = 'unpaid';
          this.serviceForm.mode_of_payment = 'credit_card';
          // this.paymentTypeDisabled = true;
        }
      }
      if (
        this.screenControls.filter((m) => m.code === 'showpriceonaddservice')
          .length > 0
      ) {
        this.showPriceOnService = this.screenControls.filter(
          (m) => m.code === 'showpriceonaddservice',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'changetitleofprefdoctor')
          .length > 0
      ) {
        this.changeTitleOfPrefDoctor = this.screenControls.filter(
          (m) => m.code === 'changetitleofprefdoctor',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'adddefaultotherservice')
          .length > 0
      ) {
        this.addDefaultOtherService = this.screenControls.filter(
          (m) => m.code === 'adddefaultotherservice',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'showsubmitbutton')
          .length > 0
      ) {
        this.showSubmit = this.screenControls.filter(
          (m) => m.code === 'showsubmitbutton',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'showservicekit').length >
        0
      ) {
        this.showKitButton = this.screenControls.filter(
          (m) => m.code === 'showservicekit',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'hidecreditcard').length >
        0
      ) {
        this.hideCreditCard = this.screenControls.filter(
          (m) => m.code === 'hidecreditcard',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'disableservicecard')
          .length > 0
      ) {
        this.disableServiceCard = this.screenControls.filter(
          (m) => m.code === 'disableservicecard',
        )[0].is_show;
        if (this.disableServiceCard) {
          $('#department').prop('disabled', true);
        }
        if (this.disableServiceCard) {
          $('#serviceNameMain').prop('disabled', true);
        }
        if (
          this.screenControls.filter((m) => m.code === 'isconsultant').length >
          0
        ) {
          this.isConsultant = this.screenControls.filter(
            (m) => m.code === 'isconsultant',
          )[0].is_show;
        }
      }

      if (
        this.screenControls.filter((m) => m.code === 'showredeemvoucher')
          .length > 0
      ) {
        this.showRedeemVoucher = this.screenControls.filter(
          (m) => m.code === 'showredeemvoucher',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'showproductcard').length >
        0
      ) {
        this.showProductTab = this.screenControls.filter(
          (m) => m.code === 'showproductcard',
        )[0].is_show;
      }
    }
    if (this.patient) {
      if (this.patient.admitted === true) {
        this.isAdmitted = true;
        this.consultation_services = [];
        this.paymentStatus = 'unpaid';
        this.serviceForm.mode_of_payment = 'credit_card';
        this.paymentTypeDisabled = false;
      }
      if (this.patient.patient_type) {
        this.panelType = this.patient.patient_type.is_credit;
        if (this.patient.patient_type.is_credit === true) {
          this.serviceForm.mode_of_payment = 'credit_card';
          this.paymentStatus = 'unpaid';
          this.paymentTypeDisabled = true;
        }
      }
      // if (this.patient.admitted === true) {
      //   this.hideUnPaid = false;
      // }
      // if (this.patient.admitted === false) {
      //   this.hideUnPaid = true;
      // }
    }
    if (this.addDefaultOtherService === true) {
      this.patient_services = [];
    }
  }

  getDepartments(): any {
    this.depart = [];
    // this.departmentsByHospital.serviceDepartmentUserWise().subscribe(data => {
    this.depart;
    // this.depart = data.departments;
    setTimeout(() => {
      $('.selectPickerDepartment').selectpicker();
      $('.selectPickerDepartment').selectpicker('refresh');
    }, 500);
    // });
  }

  // API to Get manual discount List
  getManualDiscount(): any {
    this.manualDiscountList = [];
    // this.serviceService.getManualDiscount().subscribe(data => {
    this.manualDiscountList;
    // this.manualDiscountList = data.discounts;
    // });
  }

  // API to get menual discount details against ID
  getManualDiscountDetail(id, i): any {
    this.manualDiscountID = id;
    this.manualDiscountData = [];
    this.manualDiscountAboveLimit = [];
    this.manualDiscountAboveLimitAllow = [];
    // this.manualDiscountData = '';
    this.ngxLoader.start();
    // this.serviceService.getManualDiscountDetailById(id).subscribe(
    //   data => {
    this.manualDiscountData[i] = 0;
    // this.manualDiscountData[i] = data.discount.default_value;
    // this.patient_services[i].manual_discount_field_read_only = data.discount.default_value_editable;
    // this.patient_services[i].discount_limit = data.discount.discount_limit;
    // this.patient_services[i].discount_above_limit = data.discount.discount_above_limit;
    // this.patient_services[i].discount_type = data.discount.discount_type;
    // this.patient_services[i].man_dis = this.manualDiscountData[i];
    this.patient_services[i].price = Math.round(
      this.patient_services[i].service_price -
        (this.patient_services[i].service_price / 100) *
          this.patient_services[i].sys_dis,
    );
    this.patient_services[i].price = Math.round(
      this.patient_services[i].price -
        ((this.patient_services[i].service_price / 100) *
          Number(this.patient_services[i].man_dis) +
          (this.patient_services[i].service_price / 100) *
            this.patient_services[i].voucher_discount_percentage),
    );
    // this.categoryDiscountTotal += Number(this.manualDiscountData[i]);
    // if (this.manualDiscountID){
    //   this.serviceForm.discount = this.serviceForm.discount + Number(this.patient_services[i].man_dis);
    //   this.serviceForm.cash = this.servicesTotal - this.serviceForm.discount;
    // }
    this.updatePrice();
    this.ngxLoader.stop();
    // },
    // err => {
    //   this.ngxLoader.stop();
    // });
  }

  // Update Manual discount on Change
  updateManualDiscount(i, value): any {
    // if (!this.patient_services[i].discount_above_limit){
    if (
      !this.patient_services[i].discount_above_limit &&
      this.patient_services[i].discount_limit < value
    ) {
      // value = this.patient_services[i].discount_limit;
      this.patient_services[i].man_dis =
        this.patient_services[i].discount_limit;
    }
    this.manualDiscountData[i] = this.patient_services[i].man_dis;
    this.patient_services[i].man_dis = this.manualDiscountData[i];
    this.patient_services[i].price = Math.round(
      this.patient_services[i].service_price -
        (this.patient_services[i].service_price / 100) *
          this.patient_services[i].sys_dis,
    );
    this.patient_services[i].price = Math.round(
      this.patient_services[i].price -
        ((this.patient_services[i].service_price / 100) *
          Number(this.patient_services[i].man_dis) +
          (this.patient_services[i].service_price / 100) *
            this.patient_services[i].voucher_discount_percentage),
    );
    // this.patient_services[i].man_dis = this.manualDiscountData[i];
    // this.patient_services[i].price = this.patient_services[i].service_price - ((this.patient_services[i].service_price / 100) * this.patient_services[i].sys_dis);
    // this.patient_services[i].price = this.patient_services[i].price - ((this.patient_services[i].service_price / 100) * Number(this.patient_services[i].man_dis) + ((this.patient_services[i].service_price / 100) * this.patient_services[i].voucher_discount_percentage));
    // this.categoryDiscountTotal += Number(this.manualDiscountData[i]);
    // this.getManualDiscountDetail(i, this.manualDiscountID);
    this.updatePrice();
    // this.serviceForm.discount = this.serviceForm.discount + Number(this.patient_services[i].man_dis);
    // this.serviceForm.cash = this.servicesTotal - this.serviceForm.discount;
    // }
  }

  getBookings(data): any {
    this.bookingList = [];
    // this.patientService.searchBookingByPatient(data).subscribe(data => {
    this.bookingList;
    // this.bookingList = data.bookings;
    setTimeout(() => {
      $('.selectPickerBooking').selectpicker();
      $('.selectPickerBooking').selectpicker('refresh');
    }, 500);
    // });
  }

  getVoucher(): any {
    this.VoucherList = [];
    // this.serviceService.getVoucherList().subscribe(data => {
    this.VoucherList;
    // this.VoucherList = data.voucher;
    setTimeout(() => {
      $('.selectPickerVoucher').selectpicker();
      $('.selectPickerVoucher').selectpicker('refresh');
    }, 500);
    // });
  }

  // get patient
  fetchPatient(ClientMRN): any {
    if (
      ClientMRN !== '' ||
      this.route.snapshot.queryParams.patient_id === undefined
    ) {
      this.PatientSearchForm.value.search_query = ClientMRN;
    } else {
      this.PatientSearchForm.value.search_query =
        this.route.snapshot.queryParams.patient_id;
    }
    this.PatientSearchForm.value.type = 'mrn';
    this.ngxLoader.start();
    this.patientService
      .searchPatientByRegTypeV2(this.PatientSearchForm.value)
      .subscribe(
        (data) => {
          this.patient = data.patients[0];
          this.anyContraindicationsToReceiveContrast =
            this.patient.any_contraindications_To_receive_contrast;
          this.anyAllergiesToIodinatedContrast =
            this.patient.any_allergies_to_iodinated_contrast;
          this.anyContraindicationsToReceiveContrastTextarea =
            this.patient.any_contraindications_to_receive_contrast_textarea;
          if (this.patient.user_info) {
            this.userInfo = this.patient.user_info;
          } else {
            this.userInfo =
              this.patient?.age +
              ', ' +
              this.patient?.gender.charAt(0).toUpperCase() +
              this.patient?.gender.slice(1) +
              ' with ';
          }
          this.serviceForm.patient_id = this.patient.id;
          this.ngxLoader.stop();
          this.updatePaymentTypeConfig();
          // if (!this.isConsultant) {
          this.getDraftAgainstCrn(this.patient.mrn);
          // }
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  // get saved Draft against Patient ID
  getDraftAgainstCrn(clientCrn): any {
    // booking_services
    // this.serviceService.getDraftAgainstCrn(clientCrn, this.isConsultant).subscribe(data => {
    this.savedDraft;
    // this.savedDraft = data.booking_services;
    this.ngxLoader.stop();
    if (this.savedDraft.length > 0) {
      if (
        this.patient_services.length > 0 &&
        this.patient_services[0].department_id === ''
      ) {
        this.patient_services = [];
      }
    }
    for (let i = 0; i < this.savedDraft.length; i++) {
      const obj = {
        id: this.savedDraft[i].id || '',
        booking_id: this.savedDraft[i].booking_id || '',
        consultant_id: this.savedDraft[i].consultant_id || '',
        is_used: this.savedDraft[i].is_used || false,
        // booking_id: this.bookingId,
        booking_service_id: this.savedDraft[i].booking_service_id || '',
        service_id: this.savedDraft[i].service_id,
        discount_approval: this.savedDraft[i].discount_approval || '',
        voucher_id: this.savedDraft[i].voucher_id || '',
        code: this.savedDraft[i].code || '',
        voucher_discount_amount:
          this.savedDraft[i].voucher_discount_amount || 0,
        voucher_discount_percentage:
          this.savedDraft[i].voucher_discount_percentage || 0,
        pricing_id: this.savedDraft[i].pricing_id || '',
        service_advance: this.savedDraft[i].service_advance || 0,
        doctor_id: this.savedDraft[i].doctor_id || '',
        service_type: this.savedDraft[i].service_type || '',
        sub_department: this.savedDraft[i].sub_department || '',
        service_dept: this.savedDraft[i].service_dept || '',
        comments: this.savedDraft[i].comments || '',
        department_id: this.savedDraft[i].department_id || '',
        billing_code: this.savedDraft[i].billing_code,
        performing_doctor_id: this.savedDraft[i].performing_doctor_id || '',
        referring_doctor_id: this.savedDraft[i].referring_doctor_id || '',
        mode_of_payment: '',
        quantity: 1,
        service_price: this.savedDraft[i].service_price,
        billing_package_service_id:
          this.savedDraft[i].billing_package_service_id || '',
        actual_price: this.savedDraft[i].actual_price,
        price: this.savedDraft[i].price,
        discount: this.savedDraft[i].discount,
        urgent_new: 'routine',
        quantity_allowed: this.savedDraft[i].quantity_allowed || false,
        editable: this.savedDraft[i].editable || false,
        patient_service_id: this.savedDraft[i].patient_service_id || '',
        flight_date_time: this.savedDraft[i].flight_date_time,
        reporting_time_required: false,
        employee_type: this.savedDraft[i].employee_type || '',
        sys_dis: this.savedDraft[i].sys_dis || '',
        man_dis: this.savedDraft[i].man_dis || '',
        manual_discount_field_read_only: true,
        discount_above_limit: this.savedDraft[i].discount_above_limit || true,
        man_discount_type: this.savedDraft[i].man_discount_type,
        discount_limit: this.savedDraft[i].discount_above_limit || '',
        discount_type: this.savedDraft[i].discount_type || '',
        time: this.savedDraft[i].time || '',
        recipe_item: [],
      };
      // this.patient_services[this.serviceIndex].recipe_item.push(this.servicesKit.value.serviceKit);

      this.patient_services.push(obj);
      const ind = this.patient_services.length - 1;
      this.getManualDiscountDetail(this.savedDraft[i].man_discount_type, i);
      if (this.isRecipeForCurrentService) {
        // this.getServiceRecipeAgainstID(this.savedDraft[ind].service_id, ind);
      }
      // this.getServiceRecipeAgainstID(this.savedDraft[i].service_id, ind);
      $('#serviceName' + ind).selectpicker('refresh');
      $('#serviceType' + ind).selectpicker('refresh');
      setTimeout(() => {
        $('#serviceName' + ind).selectpicker();
        $('#serviceType' + ind).selectpicker();
      }, 200);
      this.getManualDiscount();
      // this.getServices(this.savedDraft[i].department_id, this.patient_services.length - 1, this.savedDraft[i].billing_code, this.savedDraft[i].service_id, false, true);
      setTimeout(() => {
        $('#serviceName' + i).selectpicker('refresh');
        $('#serviceType' + i).selectpicker('refresh');
      }, 300);
    }
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  fetchServicesTypes(): any {
    this.ngxLoader.start();
    this.uniq_services = [];
    this.systemControlsService.getServicesTypeConfigV2().subscribe(
      (data) => {
        this.uniq_services = data.service_type_configurators;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  // fetchServices(type, i): any {
  //   this.services[i] = [];
  //   this.ngxLoader.start();
  //   this.serviceService.getServiceByTypesV2(type).subscribe(
  //     data => {
  //       this.services[i] = data.services;
  //       this.fetchSelectedServices(i);
  //       this.ngxLoader.stop();
  //     },
  //     err => {
  //       this.fetchSelectedServices(i);
  //       this.ngxLoader.stop();
  //     }
  //   );
  // }

  // fetchServices(type, i): any {
  //   this.services[i] = [];
  //   this.ngxLoader.start();
  //   this.serviceService.getServiceByTypesV2(type).subscribe(
  //     data => {
  //       this.services[i] = data.services;
  //       this.fetchSelectedServices(i);
  //       this.ngxLoader.stop();
  //     },
  //     err => {
  //       this.fetchSelectedServices(i);
  //       this.ngxLoader.stop();
  //     }
  //   );
  // }

  // ********* Services Card :: Start ********
  fetchSelectedServices(ind): any {
    // this.patient_services[ind].service_dept = '';
    // // this.patient_services[ind].department_id = '';
    // this.patient_services[ind].billing_code = '';
    // this.patient_services[ind].service_id = '';
    // this.patient_services[ind].service_price = 0;
    // this.patient_services[ind].actual_price = 0;
    // this.patient_services[ind].price = 0;
    // this.patient_services[ind].sys_dis = 0;
    // this.patient_services[ind].quantity = 1;
    // $('#serviceName' + ind).val('refresh');
    this.updatePrice();
    this.chRef.detectChanges();
    $('#serviceName' + ind).selectpicker('refresh');
    $('#referringDoctor' + ind).selectpicker('refresh');
    $('#performingDoctor' + ind).selectpicker('refresh');
    setTimeout(() => {
      $('#serviceName' + ind).selectpicker();
      $('#referringDoctor' + ind).selectpicker();
      $('#performingDoctor' + ind).selectpicker();
    }, 500);
  }

  updateServicesDept(ind, event): any {
    for (let i = 0; i < this.services[ind].length; i++) {
      if (event === this.services[ind][i].id.toString()) {
        if (this.services[ind][i].department) {
          this.patient_services[ind].service_dept =
            this.services[ind][i].department.title || '';
          this.patient_services[ind].department_id =
            this.services[ind][i].department_id;
        }
        // this.patient_services[ind].department_id = this.services[ind][i].department_id;
        this.patient_services[ind].mode_of_payment =
          this.services[ind][i].mode_of_payment;
        this.patient_services[ind].billing_code =
          this.services[ind][i].billing_code;
        this.patient_services[ind].service_id = this.services[ind][i].id;
        this.patient_services[ind].patient_service_id =
          this.services[ind][i].patient_service_id;
        this.patient_services[ind].reporting_time_required =
          this.services[ind][i].reporting_time;
        this.patient_services[ind].price = 0;
        this.patient_services[ind].sys_dis = this.services[ind][i].sys_dis;
        this.patient_services[ind].patient_service_id = '';
        this.patient_services[ind].actual_price = 0;
        this.patient_services[ind].service_price = 0;
        this.patient_services[ind].discount = 0;
        this.patient_services[ind].quantity_allowed = false;
        this.patient_services[ind].editable = false;
        this.patient_services[ind].quantity = 1;
        this.patient_services[ind].pricing_id = '';
        this.fetchServicePrice(this.services[ind][i].billing_code, ind);
        if (this.isRecipeForCurrentService) {
          this.getServiceRecipeAgainstID(this.services[ind][i].id, ind);
        }
        // this.fetchServiceAdvanceAmount(this.services[ind][i].billing_code, ind);
        this.getManualDiscount();
      }
    }
  }

  // get Service Price Against Product billing Code
  fetchServicePrice(code, ind): any {
    this.ngxLoader.start();
    // this.pricingService.searchPriceAgainst(this.patient, code).subscribe(
    // this.pricingService.searchPriceAgainstClientId(this.patient, code).subscribe(
    //   data => {
    //     this.systemDiscountPercentage = data.pricings[0].system_discount_percentage;
    //     this.isRecipeForCurrentService = data.pricings[0].is_recipe;
    //     if (data.pricings[0].is_show_system_discount === false) {
    //       this.systemDiscountPercentage = 0;
    //     } else {
    //       this.systemDiscountPercentage = data.pricings[0].system_discount_percentage;
    //     }
    //     let discount;
    //     if (this.patient.admissions.length > 0 && this.patient.admitted === true) {
    //       this.patient_services[ind].price = data.pricings[0].ipd_price;
    //       this.patient_services[ind].service_price = data.pricings[0].ipd_price;
    //       this.patient_services[ind].actual_price = data.pricings[0].ipd_price;
    //       // discount = (data.pricings[0].ipd_price / 100) * data.pricings[0].system_discount_percentage;
    //       discount = (data.pricings[0].ipd_price / 100) * this.systemDiscountPercentage;
    //     } else {
    //       // this.patient_services[ind].price = data.pricings[0].opd_price - (((data.pricings[0].opd_price / 100) * data.pricings[0].system_discount_percentage) + ((data.pricings[0].opd_price / 100) * this.patient_services[ind].voucher_discount_percentage) );
    //       this.patient_services[ind].price = Math.round( data.pricings[0].opd_price - (((data.pricings[0].opd_price / 100) * this.systemDiscountPercentage) + ((data.pricings[0].opd_price / 100) * this.patient_services[ind].voucher_discount_percentage)));
    //       this.patient_services[ind].service_price = data.pricings[0].opd_price;
    //       this.patient_services[ind].actual_price = data.pricings[0].opd_price;
    //       // discount = (data.pricings[0].opd_price / 100) * data.pricings[0].system_discount_percentage ;
    //       discount = (data.pricings[0].opd_price / 100) * this.systemDiscountPercentage;
    //     }
    //     this.patient_services[ind].discount = Math.round(discount);
    //     if (this.panelType === true) {
    //     } else {
    //     }
    //     this.patient_services[ind].pricing_id = data.pricings[0].id;
    //     this.patient_services[ind].quantity_allowed = data.pricings[0].quantity_allowed || false;
    //     if (this.currentHospital !== 'uolHospital') {
    //       this.patient_services[ind].editable = data.pricings[0].editable || false;
    //     }
    this.patient_services[ind].quantity = 1;
    // this.patient_services[ind].sys_dis = data.pricings[0].system_discount_percentage;
    this.patient_services[ind].sys_dis = this.systemDiscountPercentage;
    this.fetchServiceAdvanceAmount(
      this.patient_services[ind].billing_code,
      ind,
      this.patient_services[ind].id,
    );
    this.ngxLoader.stop();
    this.updatePrice();
    //   },
    //   err => {
    //     this.updatePrice();
    //     this.ngxLoader.stop();
    //     toastr.error(err.error.message);
    //   }
    // );
  }

  // get service Advance against Booking & service billing code
  fetchServiceAdvanceAmount(code, ind, id): any {
    this.ngxLoader.start();
    // this.pricingService.searchServiceAdvanceAgainstId(this.patient, code, id).subscribe(
    //   data => {
    this.patient_services[ind].service_advance = 0;
    // this.patient_services[ind].service_advance = data?.service_advance.service_advance_amount || 0;
    // this.serviceForm.walletAmount += this.patient_services[ind].service_advance || 0;
    // this.totalServiceAdvance += this.patient_services[ind].service_advance || 0;
    this.updatePrice();
    this.ngxLoader.stop();
    //   },
    //   err => {
    //     this.updatePrice();
    //     this.ngxLoader.stop();
    //     toastr.error(err.error.message);
    //   }
    // );
  }

  // get Service Recipe data against service ID.while open recipe modal against an ID
  getServiceRecipeAgainstID(serviceId, ind): any {
    this.serviceIdForRecipe = serviceId;
    this.serviceIndex = ind;
    // this.productService.getServiceKit(this.serviceIdForRecipe).subscribe(data => {
    this.serviceData;
    // this.serviceData = data.service_kit;
    if (this.serviceData) {
      while (this.controlDose.length) {
        this.controlDose.removeAt(0);
      }
      for (let i = 0; i < this.serviceData.length; i++) {
        if (this.serviceData[i].is_remove === false) {
          this.controlDose.push(this.getKitData(this.serviceData[i]));
        }
      }
    }

    // for (let i = 0;  i < this.patient_services.length; i++){
    while (this.patient_services[this.serviceIndex].recipe_item.length) {
      this.patient_services[this.serviceIndex].recipe_item = [];
    }
    this.patient_services[this.serviceIndex].recipe_item.push(
      this.servicesKit.value.serviceKit,
    );
    // }

    // }, err => {
    //   toastr.error(err.error.message);
    // });
  }

  // checkWalletLimit
  checkWalletLimit(): any {
    if (this.serviceForm.walletAmount > this.patient?.wallet.amount) {
      this.serviceForm.walletAmount = Number(this.patient?.wallet.amount);
    }
  }

  // remove Service
  removeServices(i): any {
    this.patient_services.splice(i, 1);
    this.updatePrice();
  }

  // Add Service
  addMoreServices(): any {
    const obj = {
      id: '',
      uom: '',
      service_id: '',
      consultant_id: '',
      is_used: false,
      voucher_id: '',
      code: '',
      voucher_discount_amount: 0,
      voucher_discount_percentage: 0,
      booking_id: '',
      discount_approval: '',
      booking_service_id: '',
      type: '',
      pricing_id: '',
      doctor_id: '',
      service_type: '',
      sub_department: '',
      service_dept: '',
      comments: '',
      department_id: '',
      billing_code: '',
      performing_doctor_id: '',
      referring_doctor_id: '',
      mode_of_payment: '',
      quantity: 1,
      service_price: 0,
      service_advance: 0,
      actual_price: 0,
      billing_package_service_id: '',
      price: 0,
      discount: 0,
      urgent_new: 'routine',
      quantity_allowed: false,
      editable: false,
      patient_service_id: '',
      flight_date_time: '',
      reporting_time_required: false,
      employee_type: '',
      sys_dis: 0,
      man_dis: '',
      manual_discount_field_read_only: true,
      discount_above_limit: true,
      man_discount_type: '',
      discount_type: '',
      discount_limit: '',
      time: '',
      recipe_item: [],
    };
    this.patient_services.push(obj);
    const ind = this.patient_services.length - 1;
    $('#serviceName' + ind).selectpicker('refresh');
    $('#serviceType' + ind).selectpicker('refresh');
    $('#referringDoctor' + ind).selectpicker('refresh');
    $('#performingDoctor' + ind).selectpicker('refresh');
    setTimeout(() => {
      $('#serviceName' + ind).selectpicker();
      $('#serviceType' + ind).selectpicker();
      $('#referringDoctor' + ind).selectpicker();
      $('#performingDoctor' + ind).selectpicker();
    }, 200);
  }

  fetchServicesByDepartment(id, i): any {
    this.services[i] = [];
    this.ngxLoader.start();
    this.patientService.fetchServicesName(this.serviceType, id).subscribe(
      (data) => {
        this.services[i] = data.services;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('.serviceName').selectpicker('refresh');
        }, 300);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  fetchSubDepartments(event, index): any {
    this.serviceType = event;
    this.subDepartmentsAgainstServiceType[index] = [];
    this.ngxLoader.start();
    this.patientService.getSubDepartmentsAgainstServiceType(event).subscribe(
      (data) => {
        this.subDepartmentsAgainstServiceType[index] = data;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  fetchServicesMain(type): any {
    this.departmentID = type;
    this.servicesMain = [];
    this.ngxLoader.start();
    // this.serviceService.getDepartmentServices(type).subscribe(
    //   data => {
    this.servicesMain;
    // this.servicesMain = data.services;
    $('#serviceNameMain').val('-1');
    setTimeout(() => {
      $('#serviceNameMain').selectpicker('refresh');
    }, 500);
    this.ngxLoader.stop();
    //   },
    //   err => {
    //     this.ngxLoader.stop();
    //   }
    // );
  }

  fetchSelectedVoucher(type): any {
    this.voucherId = type;
    // this.isGeneral = is_general;
    this.ngxLoader.start();
    // this.serviceService.getVoucherAgainstId(this.voucherId).subscribe(data => {
    this.bookingListDetail;
    // this.bookingListDetail = data.booking_services;
    this.isGeneral;
    // this.isGeneral = data.booking_services[0]?.service_id;
    this.genralDiscountPercentage;
    // this.genralDiscountPercentage = data.booking_services[0]?.voucher_discount_percentage;
    this.ngxLoader.stop();
    if (this.isGeneral === '') {
      for (let p = 0; p < this.patient_services.length; p++) {
        this.patient_services[p].voucher_discount_percentage =
          this.genralDiscountPercentage;
        this.patient_services[p].code;
        // this.patient_services[p].code = data.booking_services[0]?.code;
        this.patient_services[p].voucher_id;
        // this.patient_services[p].voucher_id = data.booking_services[0]?.voucher_id;
        this.patient_services[p].price = Math.round(
          this.patient_services[p].service_price -
            (this.patient_services[p].service_price / 100) *
              this.patient_services[p].sys_dis,
        );
        this.patient_services[p].price = Math.round(
          this.patient_services[p].price -
            ((this.patient_services[p].service_price / 100) *
              Number(this.patient_services[p].man_dis) +
              (this.patient_services[p].service_price / 100) *
                this.patient_services[p].voucher_discount_percentage),
        );
      }
      this.updatePrice();
    } else {
      if (
        this.patient_services.length > 0 &&
        this.patient_services[0].department_id === ''
      ) {
        this.patient_services = [];
      }
      for (let i = 0; i < this.bookingListDetail.length; i++) {
        const obj = {
          id: this.bookingListDetail[i].id,
          booking_id: this.bookingId,
          consultant_id: '',
          is_used: false,
          booking_service_id: '',
          service_id: this.bookingListDetail[i].service_id,
          discount_approval: this.bookingListDetail[i].discount_approval || '',
          voucher_id: this.bookingListDetail[i].voucher_id,
          voucher_discount_amount:
            this.bookingListDetail[i].voucher_discount_amount || 0,
          voucher_discount_percentage:
            this.bookingListDetail[i].voucher_discount_percentage || 0,
          pricing_id: '',
          doctor_id: '',
          service_type: '',
          sub_department: '',
          service_dept: '',
          comments: '',
          department_id: this.bookingListDetail[i].department.id,
          billing_code: this.bookingListDetail[i].billing_code,
          code: this.bookingListDetail[i].code,
          performing_doctor_id: '',
          referring_doctor_id: '',
          mode_of_payment: '',
          quantity: 1,
          service_price: 0,
          service_advance: 0,
          billing_package_service_id: '',
          actual_price: 0,
          price: 0,
          discount: 0,
          urgent_new: 'routine',
          quantity_allowed: false,
          editable: false,
          patient_service_id: '',
          flight_date_time: '',
          reporting_time_required: false,
          employee_type: '',
          sys_dis: 0,
          man_dis: '',
          manual_discount_field_read_only: true,
          discount_above_limit: true,
          man_discount_type: '',
          discount_type: '',
          discount_limit: '',
          time: '',
          recipe_item: [],
        };
        this.patient_services.push(obj);
        this.getManualDiscount();
        this.getServices(
          this.bookingListDetail[i].department.id,
          this.patient_services.length - 1,
          this.bookingListDetail[i].billing_code,
          this.bookingListDetail[i].service_id,
          false,
          false,
        );
        setTimeout(() => {
          $('#serviceName' + ind).selectpicker('refresh');
          $('#serviceType' + ind).selectpicker('refresh');
        }, 300);
      }
    }
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
    let ind;
  }

  fetchSelectedBooking(type): any {
    this.bookingId = type;

    // this.duplicationArrayCheck = this.selectedProductsArray.find( t => t.id === Number(this.selectedProduct));
    // if (this.duplicationArrayCheck) {
    //   toastr.error('product Already Exists');
    //   return;
    // }
    // console.lo
    const duplicate = this.patient_services.find(
      (t) => t.booking_id === this.bookingId,
    );
    if (duplicate) {
      toastr.error('Duplicate not allowed');
      return;
    }
    // else{
    this.ngxLoader.start();
    // this.serviceService.getBookingAgainstId(this.bookingId).subscribe(data => {
    this.bookingListDetail;
    // this.bookingListDetail = data.booking_services;
    this.ngxLoader.stop();
    if (
      this.patient_services.length > 0 &&
      this.patient_services[0].department_id === ''
    ) {
      this.patient_services = [];
    }
    for (let i = 0; i < this.bookingListDetail.length; i++) {
      const obj = {
        id: this.bookingListDetail[i].id,
        booking_id: this.bookingId,
        consultant_id: '',
        is_used: false,
        booking_service_id: this.bookingListDetail[i].id,
        service_id: this.bookingListDetail[i].service_id,
        discount_approval: this.bookingListDetail[i].discount_approval || '',
        voucher_id: '',
        code: '',
        voucher_discount_amount:
          this.bookingListDetail[i].voucher_discount_amount || 0,
        voucher_discount_percentage: 0,
        pricing_id: '',
        service_advance: 0,
        doctor_id: '',
        service_type: '',
        sub_department: '',
        service_dept: '',
        comments: '',
        department_id: this.bookingListDetail[i].department.id,
        billing_code: this.bookingListDetail[i].billing_code,
        performing_doctor_id: '',
        referring_doctor_id: '',
        mode_of_payment: '',
        quantity: 1,
        service_price:
          this.bookingListDetail[i].billing_package_service_amount || 0,
        billing_package_service_id:
          this.bookingListDetail[i].billing_package_service_id || '',
        actual_price:
          this.bookingListDetail[i].billing_package_service_amount || 0,
        price: this.bookingListDetail[i].billing_package_service_amount || 0,
        discount: 0,
        urgent_new: 'routine',
        quantity_allowed: false,
        editable: false,
        patient_service_id: '',
        flight_date_time: '',
        reporting_time_required: false,
        employee_type: '',
        sys_dis: 0,
        man_dis: '',
        manual_discount_field_read_only: true,
        discount_above_limit: true,
        man_discount_type: '',
        discount_limit: '',
        discount_type: '',
        time: '',
        recipe_item: [],
      };
      this.patient_services.push(obj);
      const ind = this.patient_services.length - 1;
      $('#serviceName' + ind).selectpicker('refresh');
      $('#serviceType' + ind).selectpicker('refresh');
      setTimeout(() => {
        $('#serviceName' + ind).selectpicker();
        $('#serviceType' + ind).selectpicker();
      }, 200);
      this.getManualDiscount();
      this.getServices(
        this.bookingListDetail[i].department.id,
        this.patient_services.length - 1,
        this.bookingListDetail[i].billing_code,
        this.bookingListDetail[i].service_id,
        this.bookingListDetail[i].is_billing_package_service,
        false,
      );
      setTimeout(() => {
        $('#serviceName' + i).selectpicker('refresh');
        $('#serviceType' + i).selectpicker('refresh');
      }, 300);
    }
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
    // }
  }

  getServices(type, i, code, serviceID, isPackege, isCashier): any {
    setTimeout(() => {
      $('.serviceName').selectpicker('refresh');
    }, 300);
    this.services[i] = [];
    this.ngxLoader.start();
    // this.serviceService.getDepartmentServices(type).subscribe(
    //   data => {
    this.services[i];
    // this.services[i] = data.services;
    const ind = this.patient_services.length - 1;
    setTimeout(() => {
      $('.serviceName' + ind).selectpicker('refresh');
    }, 300);
    this.fetchSelectedServices(i);
    this.ngxLoader.stop();
    if (code !== '') {
      if (!isPackege && !isCashier) {
        this.fetchServicePrice(code, i);
      }
      if (this.isRecipeForCurrentService) {
        this.getServiceRecipeAgainstID(serviceID, i);
      }
      // this.getServiceRecipeAgainstID(serviceID, i);
    }
    //   },
    //   err => {
    //     this.fetchSelectedServices(i);
    //     this.ngxLoader.stop();
    //   }
    // );
  }

  fetchServicesDeptMain(value, requested): any {
    let ind;
    if (this.patient_services.length === 0) {
      this.addMoreServices();
    }
    if (
      !(
        this.patient_services.length === 1 &&
        this.patient_services[0].department_id === ''
      )
    ) {
      this.addMoreServices();
    }
    setTimeout(() => {
      ind = this.patient_services.length - 1;
      this.services[ind] = this.servicesMain;
      // this.patient_services[ind].id = this.services[ind].id;
      this.patient_services[ind].department_id = this.departmentID;
      this.patient_services[ind].service_id = value;
      this.chRef.detectChanges();
      $('#serviceName' + ind).val(value);
      $('#serviceType' + ind).val(this.departmentID);
      setTimeout(() => {
        $('#serviceName' + ind).selectpicker('refresh');
        $('#serviceType' + ind).selectpicker('refresh');
      }, 300);
      this.updateServicesDept(ind, value);
    }, 800);
  }

  // ********* Services Card :: End *********

  fetchSelectedBookingWallet(type): any {
    this.bookingId = type;
    this.ngxLoader.start();
    // this.serviceService.getBookingServicesOnWalletModal(this.bookingId).subscribe(data => {
    this.servicesWalletList;
    // this.servicesWalletList = data.booking_services;
    this.ngxLoader.stop();
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  // ********* Product :: Start *********
  // Add More Products
  addMoreProducts(): any {
    const obj = {
      type: '',
      product: '',
      uom: '',
      unit_price: '',
      quantity: 0,
      total_price: 0,
    };
    this.product.push(obj);
    const ind = this.product.length - 1;
    $('#type' + ind).selectpicker('refresh');
    $('#productName' + ind).selectpicker('refresh');
    $('#pickerUOM' + ind).selectpicker('refresh');
    setTimeout(() => {
      $('#type' + ind).selectpicker();
      $('#productName' + ind).selectpicker();
      $('#pickerUOM' + ind).selectpicker();
    }, 200);
  }

  // Remove Products
  removeProductServices(i): any {
    if (this.product.length > 1) {
      this.product.splice(i, 1);
    }
    this.updatePrice();
  }

  // Function for get products against Product Type
  getProductsViaType(type, i): any {
    this.productList[i] = [];
    $('.productsName').selectpicker('refresh');
    this.ngxLoader.start();
    // this.serviceService.getProductsViaProductType(type).subscribe(
    //   data => {
    this.productList[i];
    // this.productList[i] = data.products;
    this.ngxLoader.stop();
    setTimeout(() => {
      $('.productsName').selectpicker('refresh');
    }, 300);
    // this.fetchSelectedServices(i);
    // this.ngxLoader.stop();
    //   },
    //   err => {
    //     // this.fetchSelectedServices(i);
    //     this.ngxLoader.stop();
    //   }
    // );
  }

  // Function For get product Details
  getProductDetails(ind, event): any {
    for (let i = 0; i < this.productList[ind].length; i++) {
      if (event === this.productList[ind][i].id.toString()) {
        this.product[ind].product = this.productList[ind][i].id;
        this.product[ind].uom = this.productList[ind][i].uom;
        this.product[ind].unit_price = this.productList[ind][i].sale_price;
      }
    }
  }

  // Function For Get Price against Products
  fetchProductPrice(code, ind): any {
    this.ngxLoader.start();
    // this.pricingService.searchPriceAgainstClientId(this.patient, code).subscribe(
    //   data => {
    let total;
    this.product[ind].uom;
    // this.product[ind].uom = data.pricings[0].uom;
    this.product[ind].unit_price;
    // this.product[ind].unit_price = data.pricings[0].unit_price;
    total = this.product[ind].quantity * 12;
    // total = this.product[ind].quantity * data.pricings[0].unit_price;
    this.product[ind].total_price = Math.round(total);
    this.ngxLoader.stop();
    //   },
    //   err => {
    //     this.ngxLoader.stop();
    //     toastr.error(err.error.message);
    //   }
    // );
  }

  // Function for Calculate Product Total
  updateProductTotal(ind): any {
    let unitPrice = Number(this.product[ind].unit_price);
    let unitQuantity = Number(this.product[ind].quantity);
    this.product[ind].total_price = unitPrice * unitQuantity;
    this.updatePrice();
  }

  // Function for product UOM get
  fetchUOM(): any {
    this.ngxLoader.start();
    this.generalSetting.uomListing().subscribe(
      (data) => {
        this.uomList = data;
        this.ngxLoader.stop();
        $('.pickerUOM').selectpicker('refresh');
        $('.pickerUOM').selectpicker();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  // ********* Product :: End *********

  // ******** Consultant Start ******
  fetchDoctorDepartments(id, index): any {
    const objIdDepart = '#conDept' + index;
    setTimeout(() => {
      $(objIdDepart).selectpicker('refresh');
    }, 100);

    this.consultation_services[index].service_id = '';
    this.consultation_services[index].billing_code = '';
    this.consultation_services[index].pricing_id = '';
    this.consultation_services[index].price = 0;
    this.consultation_services[index].discount = 0;
    this.consultation_services[index].service_price = 0;
    this.consultation_services[index].actual_price = 0;
    this.consultation_services[index].quantity = 0;
    // this.consultation_services[index].voucher_discount_percent = 0;
    this.consultation_services[index].sub_department_id = '';
    // this.consultation_services[index].service_type = '';
    this.consultation_services[index].department_id = this.doctors[
      index
    ].filter((t) => t.id === Number(id))[0].doctor_department_id;
    this.getSubDepartment(
      this.consultation_services[index].department_id,
      index,
    );
    setTimeout(() => {
      $(objIdDepart).selectpicker('refresh');
    }, 100);

    setTimeout(() => {
      $(objIdDepart).selectpicker();
    }, 100);
    this.fetchVisitService(
      this.consultation_services[index].service_type,
      index,
    );
    // this.ngxLoader.start();
    // this.departmentService.fetchUserDepartment(id).subscribe(
    //   data => {
    //     if (data.departments) {
    //       this.consultation_services[index].department_id = data.departments[0].id;
    //     }
    //     this.ngxLoader.stop();
    //   },
    //   err => {
    //     this.consultation_services[index].department_id = '';
    //     this.ngxLoader.stop();
    //     this._flashMessagesService.show(err.error.message, {cssClass: 'alert-danger', timeout: 5000});
    //   }
    // );
    this.updatePrice();
  }

  fetchDeptDoctors(id, index): any {
    this.fetchVisitService(
      this.consultation_services[index].service_type,
      index,
    );
    this.consultation_services[index].service_id = '';
    this.consultation_services[index].billing_code = '';
    this.consultation_services[index].pricing_id = '';
    this.consultation_services[index].price = 0;
    this.consultation_services[index].discount = 0;
    // this.consultation_services[index].service_type = '';
    this.consultation_services[index].doctor_id = '';
    this.consultation_services[index].sub_department_id = '';
    this.doctors[index] = [];
    this.subDepartments[index] = [];
    const objId = '#conDoctor' + index;
    this.getSubDepartment(id, index);
    setTimeout(() => {
      $(objId).selectpicker('refresh');
    }, 500);
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchDepartmentDoctorListV2(id).subscribe(
      (data) => {
        this.doctors[index] = data.users;
        this.ngxLoader.stop();
        setTimeout(() => {
          $(objId).selectpicker('refresh');
        }, 500);
        setTimeout(() => {
          $(objId).selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
    this.updatePrice();
  }

  getSubDepartment(id, index): any {
    const objIdSubDepart = '#conSubDept' + index;
    this.departmentService.parent_dept(id).subscribe((data) => {
      this.subDepartments[index] = data.departments;
      setTimeout(() => {
        $(objIdSubDepart).selectpicker('refresh');
      }, 500);
    });
  }

  fetchVisitService(id, index): any {
    if (id === '' || id === null || id === undefined) {
      return;
    }
    $('.conService').css('border-color', '#ccc');
    // if (this.consultation_services[index].doctor_id === '') {
    //   this._flashMessagesService.show('Select doctor first', {cssClass: 'alert-danger', timeout: 5000});
    //   return;
    // }
    this.ngxLoader.start();
    this.serviceService
      .getVisitServiceV2(
        this.patient.id,
        id,
        this.consultation_services[index].doctor_id,
        this.consultation_services[index].department_id,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.consultation_services[index].service_id = data?.service.id;
          this.consultation_services[index].billing_code =
            data.service.billing_code;
          this.fetchConsultationPrice(index);
        },
        (err) => {
          this.consultation_services[index].service_id = '';
          this.consultation_services[index].billing_code = '';
          this.consultation_services[index].pricing_id = '';
          this.consultation_services[index].price = 0;
          this.consultation_services[index].discount = 0;
          this.consultation_services[index].service_price = 0;
          this.consultation_services[index].actual_price = 0;
          this.consultation_services[index].quantity = 0;
          this.ngxLoader.stop();
          this.updatePrice();
          toastr.error(err.error.message);
        },
      );
  }

  fetchConsultationPrice(index): any {
    const obj = {
      patient_type_id: this.patient.patient_type.id,
      current_billing_code: this.consultation_services[index].billing_code,
    };
    this.ngxLoader.start();
    this.pricingService.searchPrice(obj).subscribe(
      (data) => {
        this.systemDiscountPercentage =
          data.pricings[0].system_discount_percentage;
        if (data.pricings[0].is_show_system_discount === false) {
          this.systemDiscountPercentage = 0;
        } else {
          this.systemDiscountPercentage =
            data.pricings[0].system_discount_percentage;
        }
        let discount;
        if (
          this.patient.admissions.length > 0 &&
          this.patient.admitted === true
        ) {
          this.consultation_services[index].price = data.pricings[0].ipd_price;
          this.consultation_services[index].service_price =
            data.pricings[0].ipd_price;
          this.consultation_services[index].actual_price =
            data.pricings[0].ipd_price;
          // discount = (data.pricings[0].ipd_price / 100) * data.pricings[0].system_discount_percentage;
          discount =
            (data.pricings[0].ipd_price / 100) * this.systemDiscountPercentage;
        } else {
          this.consultation_services[index].price = data.pricings[0].opd_price;
          this.consultation_services[index].service_price =
            data.pricings[0].opd_price;
          this.consultation_services[index].actual_price =
            data.pricings[0].opd_price;
          // discount = (data.pricings[0].opd_price / 100) * data.pricings[0].system_discount_percentage;
          discount =
            (data.pricings[0].opd_price / 100) * this.systemDiscountPercentage;
        }
        this.consultation_services[index].discount = Math.round(discount);
        this.consultation_services[index].pricing_id = data.pricings[0].id;
        // this.consultation_services[index].price = pricing;
        // this.consultation_services[index].service_price = pricing;
        this.consultation_services[index].quantity = 1;
        if (this.panelType === true) {
        } else {
        }
        this.ngxLoader.stop();
        if (Number(this.consultation_services[index].service_price) === 0) {
          toastr.error('This service has 0 price');
        }
        this.updatePrice();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  addConsultant(): any {
    const obj = {
      service_id: '',
      pricing_id: '',
      doctor_id: '',
      service_type: '',
      comments: '',
      department_id: '',
      sub_department_id: '',
      billing_code: '',
      performing_doctor_id: '',
      referring_doctor_id: '',
      mode_of_payment: '',
      price: 0,
      discount: 0,
      service_price: 0,
      actual_price: 0,
      quantity: 1,
      urgent_new: '',
      quantity_allowed: false,
      editable: false,
      patient_service_id: '',
      flight_date_time: '',
      reporting_time_required: false,
    };
    this.consultation_services.push(obj);
    const length = this.consultation_services.length;

    this.doctors[length - 1] = this.doctorsAll;

    // this.consultation_services[length - 1].doctor_id = this.doctorsAll[0].id;
    // this.consultation_services[length - 1].department_id = this.doctorsAll[0].doctor_department_id.toString();

    setTimeout(() => {
      const ind = length - 1;
      $('#conDoctor' + ind).selectpicker();
    }, 500);

    this.consultation_services[length - 1].service_type =
      this.lookups.filter(
        (t) =>
          t.category === 'Visit Type' &&
          (t.key === 'Normal' || t.key === 'General'),
      )[0].id || '';
  }

  removeConsultant(index): any {
    // if ( this.consultation_services.length === 1 ) {
    //   return;
    // }
    this.consultation_services.splice(index, 1);
    this.doctors.splice(index, 1);
    this.subDepartments.splice(index, 1);
    this.updatePrice();
  }

  // ******* Consultant End *******

  // ****** Extra Work Function ******

  // Get Wallet Details by id
  getServicesWallets(type): any {
    this.servicesWallet = [];
    this.ngxLoader.start();
    // this.serviceService.getDepartmentServices(type).subscribe(
    //   data => {
    this.servicesWallet;
    // this.servicesWallet = data.services;
    // this.fetchSelectedServices(i);
    this.ngxLoader.stop();
    setTimeout(() => {
      $('.serviceName').selectpicker('refresh');
      $('.serviceName').selectpicker();
    }, 200);
    //   },
    //   err => {
    //     // this.fetchSelectedServices(i);
    //     this.ngxLoader.stop();
    //   }
    // );
  }

  radiologyCheck(event): any {
    if (this.changeTitleOfPrefDoctor) {
      this.changeTextToRequestedDoctor = event === 'Radiology' ? true : false;
    }
    const checkValue = this.patient_services.find(
      (t) => t.service_type === 'Radiology',
    );
    this.showElecgiesFields =
      checkValue && Object.keys(checkValue).length ? true : false;
    // if (checkValue && Object.keys(checkValue).length) {
    //   this.showElecgiesFields = true;
    // } else {
    //   this.showElecgiesFields = false;
    // }
  }

  leadersAccount(): any {
    this.ngxLoader.start();
    this.hospitalService.getLedgersV2().subscribe(
      (data) => {
        this.ledgersList = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentService.getOpdDepartmentsListV2().subscribe(
      (data) => {
        this.departments = data.departments;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('.departmentSelectpicker').selectpicker();
        }, 1000);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  // ****** Extra Work Function ******

  // Function For total Price Calculation :: Start
  updatePrice(): any {
    this.consultantionTotal = 0;
    this.servicesTotal = 0;
    this.servicesGrandTotal = 0;
    this.productsTotal = 0;
    this.serviceForm.discount = 0;
    this.manDisAmount = 0;
    this.sysDisAmount = 0;
    this.vocDisAmount = 0;
    this.totalServiceAdvance = 0;
    this.serviceForm.voucher_discount_percent = 0;
    this.serviceForm.discount_percentage = 0;
    this.serviceForm.systemDiscountTotal = 0;
    this.serviceForm.totalServiceAdvance = 0;
    this.serviceForm.manualDiscountTotal = 0;
    this.serviceForm.voucherDiscountTotal = 0;
    this.serviceForm.payableAmount = 0;
    for (let i = 0; i < this.patient_services.length; i++) {
      // this.servicesTotal = this.servicesTotal + this.patient_services[i].price - this.patient_services[i].voucher_discount_amount - this.manualDiscountData[i];
      this.servicesTotal = this.servicesTotal + this.patient_services[i].price;
      this.servicesGrandTotal =
        this.servicesGrandTotal + this.patient_services[i].service_price;
      this.serviceForm.systemDiscountTotal =
        this.serviceForm.systemDiscountTotal + this.patient_services[i].sys_dis;
      this.serviceForm.manualDiscountTotal = Number(
        this.serviceForm.manualDiscountTotal + this.patient_services[i].man_dis,
      );
      this.serviceForm.voucherDiscountTotal = Number(
        this.serviceForm.voucherDiscountTotal +
          this.patient_services[i].voucher_discount_percentage,
      );
      this.serviceForm.systemDiscountPercentage =
        this.serviceForm.systemDiscountTotal / this.patient_services.length;
      this.serviceForm.manualDiscountPercentage =
        this.serviceForm.manualDiscountTotal / this.patient_services.length;
      this.serviceForm.voucherDiscountPercentage =
        this.serviceForm.voucherDiscountTotal / this.patient_services.length;
      this.serviceForm.totalServiceAdvance +=
        this.patient_services[i].service_advance || 0;
      this.serviceForm.discount =
        this.serviceForm.discount +
        this.patient_services[i].service_price -
        ((this.patient_services[i].service_price / 100) *
          Number(this.patient_services[i].man_dis) +
          (this.patient_services[i].service_price / 100) *
            Number(this.patient_services[i].sys_dis) +
          (this.patient_services[i].service_price / 100) *
            Number(this.patient_services[i].voucher_discount_percentage));
      this.manDisAmount = Math.round(
        this.manDisAmount +
          (this.patient_services[i].service_price / 100) *
            Number(this.patient_services[i].man_dis),
      );
      this.sysDisAmount = Math.round(
        this.sysDisAmount +
          (this.patient_services[i].service_price / 100) *
            Number(this.patient_services[i].sys_dis),
      );
      this.vocDisAmount = Math.round(
        this.vocDisAmount +
          (this.patient_services[i].service_price / 100) *
            Number(this.patient_services[i].voucher_discount_percentage),
      );

      // this.serviceForm.discount = this.serviceForm.discount + (this.patient_services[i].quantity * this.patient_services[i].discount);
      // if (this.voucherId){
      //   // @ts-ignore
      //   this.serviceForm.discount = this.serviceForm.discount + this.patient_services[i].voucher_discount_amount;
      //   // this.serviceForm.discount = this.serviceForm.discount + (this.patient_services[i].quantity * this.patient_services[i].voucher_discount_amount);
      //   this.serviceForm.voucher_discount_percent = this.serviceForm.voucher_discount_percent + this.patient_services[i].voucher_discount_percentage;
      // }
      // if (this.manualDiscountID){
      //   this.serviceForm.discount = this.serviceForm.discount +  ((this.patient_services[i].service_price / 100) * Number(this.patient_services[i].man_dis));
      //   // this.serviceForm.cash = this.servicesTotal - this.serviceForm.discount;
      // }
      // if (this.manualDiscountID){
      //   this.serviceForm.discount = this.serviceForm.discount + Number(this.patient_services[i].man_dis);
      // }
    }
    for (let i = 0; i < this.product.length; i++) {
      this.productsTotal = Number(
        this.productsTotal + this.product[i].total_price,
      );
      // this.serviceForm.discount = this.serviceForm.discount + (this.patient_services[i].quantity * this.patient_services[i].discount);
    }
    // this.serviceForm.cash = (this.consultantionTotal + this.servicesTotal + this.productsTotal) - this.serviceForm.discount;
    this.serviceForm.cash =
      this.consultantionTotal + this.servicesTotal + this.productsTotal;
    if (this.serviceForm.discount) {
      this.calculateDiscountAmount('amount');
    } else {
      this.updateServiceReceivable();
      this.serviceForm.discount_percentage = 0;
    }
  }

  // Function For Calculation Total
  updateServiceReceivable(): any {
    // const totalCash = (this.consultantionTotal + this.servicesTotal + this.productsTotal) - this.serviceForm.discount;
    const totalCash =
      this.consultantionTotal + this.servicesTotal + this.productsTotal;
    if (this.panelType === false) {
      if (this.serviceForm.cash >= totalCash) {
        this.serviceForm.cash = totalCash;
        this.serviceForm.receivable = 0;
        this.serviceForm.ledger = '';
      } else {
        this.serviceForm.receivable = totalCash - this.serviceForm.cash;
      }
    } else {
      // this.serviceForm.cash = 0;
      // this.serviceForm.receivable = totalCash;
      this.serviceForm.ledger = '';

      this.serviceForm.cash = totalCash;
      this.serviceTotalNetAmount = totalCash;
      this.serviceForm.receivable = 0;
      this.serviceForm.mode_of_payment = 'credit';
      this.paymentStatus = 'unpaid';
    }
    this.chRef.detectChanges();
  }

  // Function For total Price Calculation :: End

  paymentMethods(method): any {
    this.serviceForm.mode_of_payment = method;
    this.serviceForm.payment_details = '';
  }

  paymentMethod(method, event): any {
    if (event.target.checked) {
      this.serviceForm.mode_of_payment = method;
    } else {
      event.target.checked = true;
    }
    this.serviceForm.payment_details = '';
  }

  updatePaymentStatus(): any {
    this.paymentTypeDisabled = false;
    if (this.paymentStatus === 'unpaid') {
      this.serviceForm.mode_of_payment = 'credit';
      // this.paymentTypeDisabled = true;
    } else {
      this.serviceForm.mode_of_payment = 'cash';
    }
    this.updatePrice();
  }

  // Add Services Cinfirm Button
  addServiceConfirm(): any {
    let count = 0;
    $('.conService').css('border-color', '#ccc');
    $('.conDoctorMain').css('border-color', '#ccc');
    $('.conDeptMain').css('border-color', '#ccc');
    $('.serviceType').css('border-color', '#ccc');
    $('.serviceName').css('border-color', '#ccc');
    $('.serviceQuantity').css('border-color', '#ccc');
    $('div').removeClass('border-alert');
    const patientServices = this.patient_services;
    const creditService = this.credit_service;
    const productServices = this.product;
    if (this.serviceForm.walletAmount === null) {
      this.serviceForm.walletAmount = 0;
    }
    for (let i = 0; i < patientServices.length; i++) {
      if (
        patientServices[i].quantity === null ||
        patientServices[i].quantity === undefined ||
        patientServices[i].quantity === 0
      ) {
        count++;
        $('#serviceQuantity' + i).css('border-color', 'red');
      }
    }
    if (count > 0) {
      return;
    }
    count = 0;
    for (let i = 0; i < patientServices.length; i++) {
      if (patientServices[i].billing_package_service_id === '') {
        if (patientServices[i].pricing_id === '') {
          count++;
          $('#serviceName' + i).css('border-color', 'red');
          toastr.error('Price not exist');
        }
      }
    }
    if (count > 0) {
      return;
    }

    this.serviceForm.patient_services = $.merge([], patientServices);
    this.serviceForm.credit_service = $.merge([], creditService);
    // this.serviceForm.product = ($.merge([], productServices));
    // if (this.serviceForm.patient_services.length === 0 && this.serviceForm.credit_service.length === 0) {
    //   toastr.error('Add at least one service');
    //   return;
    // }

    if (this.serviceForm.cash === null || this.serviceForm.cash === undefined) {
      this.serviceForm.cash = 0;
    }
    this.isChecked = false;
    if (this.isSubmitted === false) {
      this.modalService.open(this.serviceAddConfirm);
    }
  }

  // Add Services After Confirmantion button Click
  addService(): any {
    this.serviceForm.credit_service = [];
    this.patientServiceHistory.map((invoice) => {
      if (invoice.selected === true) {
        const obj = {
          id: invoice.id,
          cash: invoice.cash,
          receivable: invoice.receivable,
          net_amount: invoice.base_price - invoice.approved_discount,
        };
        this.serviceForm.credit_service.push(obj);
      }
    });

    if (
      this.serviceForm.patient_services.length === 0 &&
      this.serviceForm.credit_service.length === 0
    ) {
      toastr.error('Add at least one service');
      return;
    }
    this.serviceForm.discount =
      this.servicesGrandTotal -
      (this.servicesTotal + this.selectedCreditServiceTotal);
    this.serviceForm.payableAmount =
      this.servicesTotal +
      this.selectedCreditServiceTotal -
      (this.serviceForm.cashAmount +
        this.serviceForm.creditCardAmount +
        this.serviceForm.onlineAmount +
        this.serviceForm.walletAmount +
        this.serviceForm.totalServiceAdvance);
    this.serviceForm.cash += this.selectedCreditServiceTotal || 0;
    this.modalService.dismissAll(this.serviceAddConfirm);
    this.ngxLoader.start();
    this.isSubmitted = true;
    this.patientService
      .createPatientServiceV2(
        this.serviceForm,
        this.patient,
        this.paymentStatus,
        this.anyContraindicationsToReceiveContrast,
        this.anyAllergiesToIodinatedContrast,
        this.anyContraindicationsToReceiveContrastTextarea,
        this.userInfo,
        '',
      )
      .subscribe(
        (data) => {
          this.initServices();
          this.fetchPatient('');
          this.fetchServicesHistory(
            this.visitTypeStatus,
            this.paymentTypeStatus,
          );

          // this.fetchServicesHistory(true, true);
          this.ngxLoader.stop();
          this.isSubmitted = false;
          // this.chRef.detectChanges();
          // this.location.back();
          // if (this.currentControls !== '') {
          //   if (this.currentControls.patient_services.is_invoice === false) {
          //     if (this.currentControls.patient_services.is_invoice_short === false) {
          //       window.open('pdf-reports/patient-services-invoices?id=' + data.patient_services[0].patient_service_invoice_item.patient_service_invoice_id + '&visit_id=' + data.patient_services[0].visit_id);
          //     } else {
          //       window.open('pdf-reports/services-invoices-short?id=' + data.patient_services[0].patient_service_invoice_item.patient_service_invoice_id + '&visit_id=' + data.patient_services[0].visit_id);
          //     }
          //   } else {
          //     window.open(this.current_environment + '/v4/patient_services/' + data.patient_services[0].patient_service_invoice_item.patient_service_invoice_id + '/patient_services_invoice_slip.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&visit_id=' + data.patient_services[0].visit_id, '_blank');
          //   }
          // } else {
          // this.router.navigate(['leave/leave-approval'], {queryParams: {id: id}});
          this.router.navigate(['slot/day-wise']);
          // New Invoice V2, Python

          window.open(
            'pdf-reports/service-invoice-v2?id=' +
              data.patient_services[0].id +
              '&visit_id=' +
              data.patient_services[0].visit_id,
          );

          // OLD Invoice
          // window.open('pdf-reports/patient-services-invoices?id=' + data.patient_services[0].patient_service_invoice_item.patient_service_invoice_id + '&visit_id=' + data.patient_services[0].visit_id);

          // window.open(this.current_environment + '/v4/patient_services/' + data.patient_services[0]?.patient_service_invoice_item.patient_service_invoice_id + '/patient_services_invoice_slip.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&visit_id=' + data.patient_services[0].visit_id, '_blank');
          // }

          // if (this.consultation_services.length > 0) {
          //   if (this.currentHospital !== 'nationalHospital') {
          //     if (this.currentControls !== '') {
          //       if (this.currentControls.patient_services.is_prescription === false) {
          //         window.open('pdf-reports/prescription?id=' + this.patient.mrn + '&visit_id=' + data.patient_services[0].visit_id);
          //       } else {
          //         window.open(environment.rorUrl + '/v4/patients/' + this.patient.id + '/prescription_slip.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&visit_id=' + data.patient_services[0].visit_id);
          //       }
          //     } else {
          //       window.open(environment.rorUrl + '/v4/patients/' + this.patient.id + '/prescription_slip.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&visit_id=' + data.patient_services[0].visit_id);
          //     }
          //   }
          // }
          this.isSubmitted = false;
          // this.backRoute();
          this.serviceForm = {
            patient_id: this.patient.id,
            mode_of_payment: 'cash',
            payment_details: '',
            patient_services: [],
            credit_service: [],
            product: [],
            discount: 0,
            discount_percentage: 0,
            receivable: 0,
            cash: 0,
            ledger: '',
            cashAmount: 0,
            creditCardAmount: 0,
            onlineAmount: 0,
            walletAmount: 0,
            payableAmount: 0,
            voucher_discount_percent: 0,
            onlineBank: '',
            onlineBankRef: '',
            cardBank: '',
            cardBankRef: '',
            taxType: '',
            taxAmount: 0,
            systemDiscountPercentage: 0,
            systemDiscountTotal: 0,
            totalServiceAdvance: 0,
            manualDiscountTotal: 0,
            manualDiscountPercentage: 0,
            voucherDiscountTotal: 0,
            voucherDiscountPercentage: 0,
          };

          // this.fetchPatient( '');
        },
        (err) => {
          this.isSubmitted = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  // Save Draft Services
  saveAsDraft(): any {
    let count = 0;
    $('.conService').css('border-color', '#ccc');
    $('.conDoctorMain').css('border-color', '#ccc');
    $('.conDeptMain').css('border-color', '#ccc');
    $('.serviceType').css('border-color', '#ccc');
    $('.serviceName').css('border-color', '#ccc');
    $('.serviceQuantity').css('border-color', '#ccc');
    $('div').removeClass('border-alert');
    const patientServices = this.patient_services;
    const creditService = this.credit_service;
    const productServices = this.product;
    if (this.serviceForm.walletAmount === null) {
      this.serviceForm.walletAmount = 0;
    }
    for (let i = 0; i < patientServices.length; i++) {
      if (
        patientServices[i].quantity === null ||
        patientServices[i].quantity === undefined ||
        patientServices[i].quantity === 0
      ) {
        count++;
        $('#serviceQuantity' + i).css('border-color', 'red');
      }
    }
    if (count > 0) {
      return;
    }
    count = 0;
    for (let i = 0; i < patientServices.length; i++) {
      if (patientServices[i].billing_package_service_id === '') {
        if (patientServices[i].pricing_id === '') {
          count++;
          $('#serviceName' + i).css('border-color', 'red');
          toastr.error('Price not exist');
        }
      }
    }
    if (count > 0) {
      return;
    }

    this.serviceForm.patient_services = $.merge([], patientServices);
    this.serviceForm.credit_service = $.merge([], creditService);
    // this.serviceForm.product = ($.merge([], productServices));
    // if (this.serviceForm.patient_services.length === 0 && this.serviceForm.credit_service.length === 0) {
    //   toastr.error('Add at least one service');
    //   return;
    // }

    if (this.serviceForm.cash === null || this.serviceForm.cash === undefined) {
      this.serviceForm.cash = 0;
    }
    this.isChecked = false;
    // if (this.isSubmitted === false) {
    //   this.modalService.open(this.serviceAddConfirm);
    // }
    this.serviceForm.credit_service = [];
    this.patientServiceHistory.map((invoice) => {
      if (invoice.selected === true) {
        const obj = {
          id: invoice.id,
          cash: invoice.cash,
          receivable: invoice.receivable,
          net_amount: invoice.base_price - invoice.approved_discount,
        };
        this.serviceForm.credit_service.push(obj);
      }
    });

    if (
      this.serviceForm.patient_services.length === 0 &&
      this.serviceForm.credit_service.length === 0
    ) {
      toastr.error('Add at least one service');
      return;
    }
    this.serviceForm.discount =
      this.servicesGrandTotal -
      (this.servicesTotal + this.selectedCreditServiceTotal);
    this.serviceForm.payableAmount =
      this.servicesTotal +
      this.selectedCreditServiceTotal -
      (this.serviceForm.cashAmount +
        this.serviceForm.creditCardAmount +
        this.serviceForm.onlineAmount +
        this.serviceForm.walletAmount +
        this.serviceForm.totalServiceAdvance);
    this.serviceForm.cash += this.selectedCreditServiceTotal || 0;
    this.modalService.dismissAll(this.serviceAddConfirm);
    this.ngxLoader.start();
    this.isSubmitted = true;
    // this.patientService.saveDraft(this.serviceForm, this.patient, this.paymentStatus, this.userInfo).subscribe(
    //   data => {
    this.initServices();
    this.fetchPatient('');
    this.fetchServicesHistory(this.visitTypeStatus, this.paymentTypeStatus);

    // this.fetchServicesHistory(true, true);
    this.ngxLoader.stop();
    this.isSubmitted = false;
    // this.chRef.detectChanges();
    // this.location.back();
    // if (this.currentControls !== '') {
    //   if (this.currentControls.patient_services.is_invoice === false) {
    //     if (this.currentControls.patient_services.is_invoice_short === false) {
    //       window.open('pdf-reports/patient-services-invoices?id=' + data.patient_services[0].patient_service_invoice_item.patient_service_invoice_id + '&visit_id=' + data.patient_services[0].visit_id);
    //     } else {
    //       window.open('pdf-reports/services-invoices-short?id=' + data.patient_services[0].patient_service_invoice_item.patient_service_invoice_id + '&visit_id=' + data.patient_services[0].visit_id);
    //     }
    //   } else {
    //     window.open(this.current_environment + '/v4/patient_services/' + data.patient_services[0].patient_service_invoice_item.patient_service_invoice_id + '/patient_services_invoice_slip.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&visit_id=' + data.patient_services[0].visit_id, '_blank');
    //   }
    // } else {
    // this.router.navigate(['leave/leave-approval'], {queryParams: {id: id}});
    // this.router.navigate(['slot/day-wise']);
    // New Invoice V2, Python

    // window.open('pdf-reports/service-invoice-v2?id=' + data.patient_services[0].id + '&visit_id=' + data.patient_services[0].visit_id);

    // OLD Invoice
    // window.open('pdf-reports/patient-services-invoices?id=' + data.patient_services[0].patient_service_invoice_item.patient_service_invoice_id + '&visit_id=' + data.patient_services[0].visit_id);

    // window.open(this.current_environment + '/v4/patient_services/' + data.patient_services[0]?.patient_service_invoice_item.patient_service_invoice_id + '/patient_services_invoice_slip.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&visit_id=' + data.patient_services[0].visit_id, '_blank');
    // }

    // if (this.consultation_services.length > 0) {
    //   if (this.currentHospital !== 'nationalHospital') {
    //     if (this.currentControls !== '') {
    //       if (this.currentControls.patient_services.is_prescription === false) {
    //         window.open('pdf-reports/prescription?id=' + this.patient.mrn + '&visit_id=' + data.patient_services[0].visit_id);
    //       } else {
    //         window.open(environment.rorUrl + '/v4/patients/' + this.patient.id + '/prescription_slip.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&visit_id=' + data.patient_services[0].visit_id);
    //       }
    //     } else {
    //       window.open(environment.rorUrl + '/v4/patients/' + this.patient.id + '/prescription_slip.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&visit_id=' + data.patient_services[0].visit_id);
    //     }
    //   }
    // }
    this.isSubmitted = false;
    // this.backRoute();
    this.serviceForm = {
      patient_id: this.patient.id,
      mode_of_payment: 'cash',
      payment_details: '',
      patient_services: [],
      credit_service: [],
      product: [],
      discount: 0,
      discount_percentage: 0,
      receivable: 0,
      cash: 0,
      ledger: '',
      cashAmount: 0,
      creditCardAmount: 0,
      onlineAmount: 0,
      walletAmount: 0,
      payableAmount: 0,
      voucher_discount_percent: 0,
      onlineBank: '',
      onlineBankRef: '',
      cardBank: '',
      cardBankRef: '',
      taxType: '',
      taxAmount: 0,
      systemDiscountPercentage: 0,
      systemDiscountTotal: 0,
      totalServiceAdvance: 0,
      manualDiscountTotal: 0,
      manualDiscountPercentage: 0,
      voucherDiscountTotal: 0,
      voucherDiscountPercentage: 0,
    };

    // this.fetchPatient( '');
    //   },
    //   err => {
    //     this.isSubmitted = false;
    //     this.ngxLoader.stop();
    //     toastr.error(err.error.message);
    //   }
    // );
  }

  // Reset payment Card After Change Payment Mode
  resetCard(): any {
    this.serviceForm.onlineAmount = 0;
    this.serviceForm.onlineBank = '';
    this.serviceForm.onlineBankRef = '';
    this.serviceForm.cardBank = '';
    this.serviceForm.cardBankRef = '';
    this.serviceForm.creditCardAmount = 0;
    setTimeout((e) => {
      $('.selectPickerBankDetail').selectpicker('refresh');
      $('.selectPickerBankDetail').selectpicker();
    }, 500);
  }

  resetWalletCard(): any {
    // this.walletPaymentMode = 'online';
    this.walletCashAmount = 0;
    this.walletOnlineAmount = 0;
    this.walletCreditCardAmount = 0;
    this.walletOnlineBankRef = '';
    this.walletCardBankRef = '';
    this.walletOnlineBank = '';
    this.walletCardBank = '';
    setTimeout((e) => {
      $('.selectPickerBankDetail').selectpicker('refresh');
      $('.selectPickerBankDetail').selectpicker();
    }, 500);
  }

  openPrescriptionSlip(id): any {
    if (this.currentControls !== '') {
      if (this.currentControls.patient_services.is_prescription === false) {
        window.open(
          'pdf-reports/prescription?id=' + this.patient.mrn + '&visit_id=' + id,
        );
      } else {
        window.open(
          environment.rorUrl +
            '/v4/patients/' +
            this.patient.id +
            '/prescription_slip.pdf?visit_id=' +
            id +
            '&auth_token=' +
            localStorage.getItem('auth_token') +
            '&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id'),
        );
      }
    } else {
      window.open(
        environment.rorUrl +
          '/v4/patients/' +
          this.patient.id +
          '/prescription_slip.pdf?visit_id=' +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
      );
    }
  }

  skipNegative(e): any {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode == 8
      )
    ) {
      return false;
    }
  }

  fetchServicesHistory(visitType, paymentType): any {
    this.paymentTypeStatus = paymentType;
    this.visitTypeStatus = visitType;
    this.requestedServices = false;
    if (paymentType === true) {
      this.paymentType = 'paid';
    } else {
      this.paymentType = 'unpaid';
    }
    if (visitType === true) {
      this.visitType = 'opd';
    } else {
      this.visitType = 'ipd';
    }
    this.servicePaidForm.payment_details = '';
    this.servicePaidForm.mode_of_payment = 'cash';
    this.patientServiceHistory = [];
    // this.patientServiceHistoryBackup = [];
    // this.patientService.patientServicesHistory(this.patient, this.visitType, this.paymentType).subscribe(
    //   data => {
    this.patientServiceHistory;
    // this.patientServiceHistory = data.patient_service_invoices;
    // this.patientServiceHistoryBackup = JSON.parse(JSON.stringify(data.patient_service_invoices));
    // this.patientServiceHistory.map(invoice => {
    //   invoice.selected = false;
    // });
    //   },
    //   err => {
    //     toastr.error(err.error.message);
    //   }
    // );
  }

  selectAllRecentServices(): any {
    if ($('#recentServiceAll').prop('checked') === true) {
      $('.recentService').prop('checked', true);
    }
    if ($('#recentServiceAll').prop('checked') === false) {
      $('.recentService').prop('checked', false);
    }
  }

  searchProduct(search_value): any {
    if (this.searchProductValue === '' || this.searchProductValue === null) {
      this.productSearchValue = [''];
    }
    this.searchValue = search_value.length;
    if (this.searchValue > 0) {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'block',
      );
    } else {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'none',
      );
      this.searchProductValue = '';
    }
    this.eventOfSearchBar = event;
    // this.storeService.searchPatient(search_value, this.searchProductType).subscribe(data => {
    if (this.searchProductValue === search_value) {
      this.productSearchList;
      // this.productSearchList = data.client;
    }
    // }, err => {
    //   toastr.error(err.error.message);
    // });
  }

  removeSearchString(): any {
    this.searchProductValue = '';
    this.productSearchList = [];
    this.searchValue = 0;
    if (this.searchValue > 0) {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'block',
      );
    } else {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'none',
      );
    }
  }

  appendRow(data): any {
    this.removeSearchString();
    // this.ClientSearchData = data.mrn;
    // this.ClientSearchData = data;
    this.ClientSearchId = data.id;
    this.fetchPatient(data);
    this.patient_services = [
      {
        id: '',
        voucher_discount_amount: 0,
        voucher_discount_percentage: 0,
        voucher_id: '',
        code: '',
        booking_id: '',
        booking_service_id: '',
        service_id: '',
        discount_approval: '',
        pricing_id: '',
        doctor_id: '',
        service_type: '',
        sub_department: '',
        service_advance: 0,
        service_dept: '',
        comments: '',
        department_id: '',
        billing_code: '',
        performing_doctor_id: '',
        referring_doctor_id: '',
        mode_of_payment: '',
        quantity: 0,
        service_price: 0,
        billing_package_service_id: '',
        actual_price: 0,
        price: 0,
        // service_advance: 0,
        discount: 0,
        urgent_new: 'routine',
        quantity_allowed: false,
        editable: false,
        patient_service_id: '',
        flight_date_time: '',
        reporting_time_required: false,
        employee_type: '',
        sys_dis: 0,
        man_dis: '',
        manual_discount_field_read_only: true,
        discount_above_limit: true,
        man_discount_type: '',
        discount_limit: '',
        discount_type: '',
        time: '',
        recipe_item: [],
      },
    ];
    this.updatePrice();
    this.getBookings(data);
    this.services = [];
    setTimeout(() => {
      $('.selectPickerDepartment').selectpicker('refresh');
      $('.selectPickerBooking').selectpicker('refresh');
      $('.selectPickerVoucher').selectpicker('refresh');
      $('.selectPickerVoucher').selectpicker();
      $('.serviceName').selectpicker('refresh');
      $('.serviceName').selectpicker();
    }, 300);
  }

  selectSideTabs(id, removeClass, tab): any {
    this.activeTab = tab;
    $('.' + removeClass + ' a').removeClass('activeSideTabs');
    $('#' + id + ' a').addClass('activeSideTabs');
    this.initServices();
  }

  initServices(): any {
    this.servicesTotal = 0;
    this.serviceTotalNetAmount = 0;
    this.servicesGrandTotal = 0;
    this.selectedCreditServiceTotal = 0;
    this.selectedCreditServiceTotal = 0;
    this.serviceForm.discount = 0;
    this.serviceForm.cash = 0;
    this.serviceForm.cashAmount = 0;
    this.consultantionTotal = 0;
    this.requestedServicesList = [];
    this.requestedServices = false;
    this.servicePaidForm = {
      patient_id: this.patient.id,
      mode_of_payment: 'Cash',
      payment_details: '',
      invoice_ids: [],
      receivable: 0,
      cash: 0,
      ledger: '',
      receivable_percentage: 0,
    };
    this.serviceForm = {
      patient_id: this.patient.id,
      mode_of_payment: 'cash',
      payment_details: '',
      patient_services: [],
      credit_service: [],
      product: [],
      discount: 0,
      discount_percentage: 0,
      receivable: 0,
      cash: 0,
      ledger: '',
      cashAmount: 0,
      creditCardAmount: 0,
      onlineAmount: 0,
      walletAmount: 0,
      payableAmount: 0,
      voucher_discount_percent: 0,
      onlineBank: '',
      onlineBankRef: '',
      cardBank: '',
      cardBankRef: '',
      taxType: '',
      taxAmount: 0,
      systemDiscountPercentage: 0,
      systemDiscountTotal: 0,
      totalServiceAdvance: 0,
      manualDiscountTotal: 0,
      manualDiscountPercentage: 0,
      voucherDiscountTotal: 0,
      voucherDiscountPercentage: 0,
    };
    this.consultation_services = [
      {
        service_id: '',
        pricing_id: '',
        doctor_id: '',
        service_type: '',
        comments: '',
        department_id: '',
        sub_department_id: '',
        billing_code: '',
        performing_doctor_id: '',
        referring_doctor_id: '',
        mode_of_payment: '',
        price: 0,
        discount: 0,
        service_price: 0,
        actual_price: 0,
        quantity: 0,
        urgent_new: '',
        quantity_allowed: false,
        editable: false,
        patient_service_id: '',
        flight_date_time: '',
        reporting_time_required: false,
      },
    ];
    this.patient_services = [
      {
        id: '',
        voucher_discount_amount: 0,
        voucher_discount_percentage: 0,
        booking_id: '',
        voucher_id: '',
        code: '',
        booking_service_id: '',
        service_id: '',
        discount_approval: '',
        pricing_id: '',
        doctor_id: '',
        service_type: '',
        sub_department: '',
        service_dept: '',
        comments: '',
        department_id: '',
        billing_code: '',
        performing_doctor_id: '',
        referring_doctor_id: '',
        mode_of_payment: '',
        quantity: 0,
        service_price: 0,
        billing_package_service_id: '',
        actual_price: 0,
        price: 0,
        service_advance: 0,
        discount: 0,
        urgent_new: 'routine',
        quantity_allowed: false,
        editable: false,
        patient_service_id: '',
        flight_date_time: '',
        reporting_time_required: false,
        employee_type: '',
        sys_dis: 0,
        man_dis: '',
        manual_discount_field_read_only: true,
        discount_above_limit: true,
        man_discount_type: '',
        discount_type: '',
        discount_limit: '',
        time: '',
        recipe_item: [],
      },
    ];
    this.product = [
      {
        type: '',
        product: '',
        uom: '',
        unit_price: '',
        quantity: 0,
        total_price: 0,
      },
    ];
    this.paymentStatus = 'paid';
    setTimeout(() => {
      $('.selectpicker').selectpicker({
        dropupAuto: false,
      });
      $('.doctorSelectpicker').selectpicker();
      $('.departmentSelectpicker').selectpicker();
    }, 500);
  }

  backRoute(): any {
    this.ngxLoader.start();
    if (this.route.snapshot.queryParams.patient_from === 'settlement') {
      this.router.navigate(['/sale/final-settlement-v2'], {
        queryParams: { patient_mrn: this.patient.mrn },
      });
    } else {
      this.router.navigate(['/client/registration-v2'], {
        queryParams: { patient_mrn: this.patient.mrn },
      });
    }
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  updateServiceQuantity(ind): any {
    this.patient_services[ind].price = Math.round(
      this.patient_services[ind].service_price *
        this.patient_services[ind].quantity,
    );
    this.updatePrice();
  }

  // calculate service Price After Change
  updateServicePrice(ind): any {
    if (
      this.patient_services[ind].service_price <
      this.patient_services[ind].discount
    ) {
      this.patient_services[ind].service_price =
        this.patient_services[ind].discount;
    }
    // if(this.patient_services[ind].unit === '1' && this.voucherId){
    //   this.patient_services[ind].price = this.patient_services[ind].service_price * this.patient_services[ind].quantity;
    // }
    this.patient_services[ind].price = Math.round(
      this.patient_services[ind].service_price *
        this.patient_services[ind].quantity,
    );
    this.updatePrice();
  }

  print(id): any {
    window.open('pdf-reports/patient-services-invoices?id=' + id);
    // if (this.currentControls !== '') {
    //   if (this.currentControls.patient_services.is_invoice === false) {
    //     if (this.currentControls.patient_services.is_invoice_short === false) {
    //       window.open('pdf-reports/patient-services-invoices?id=' + id);
    //     } else {
    //       window.open('pdf-reports/services-invoices-short?id=' + id);
    //     }
    //   } else {
    //     window.open(this.current_environment + '/v4/patient_services/' + id + '/patient_services_invoice_slip.pdf?auth_token=' + localStorage.getItem('auth_token') + '&print_type=true&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'), '_blank');
    //   }
    // } else {
    //   window.open(this.current_environment + '/v4/patient_services/' + id + '/patient_services_invoice_slip.pdf?auth_token=' + localStorage.getItem('auth_token') + '&print_type=true&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'), '_blank');
    // }
  }

  printInvoiceCancelItem(id): any {
    window.open(
      this.current_environment +
        '/v4/patient_service_invoice_items/refund_report.pdf?id=' +
        id +
        '&auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      '_blank',
    );
  }

  printDialysisService(id): any {
    window.open(
      this.current_environment +
        '/v4/dialysis_patients/report.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&id=' +
        id,
    );
  }

  selectInvoice(): any {
    setTimeout(() => {
      this.updateInvoicesPrice();
    }, 500);
  }

  select_all_invoices(event): any {
    if (event.target.checked) {
      this.patientServiceHistory.map((invoice) => {
        if (invoice.payment_status === 'unpaid') {
          invoice.selected = true;
        }
      });
      this.updateInvoicesPrice();
    } else {
      this.patientServiceHistory.map((invoice) => {
        if (invoice.payment_status === 'unpaid') {
          invoice.selected = false;
        }
      });
      this.updateInvoicesPrice();
    }
  }

  updateInvoicesPrice(): any {
    this.servicePaidForm.cash = 0;
    this.selectedCreditServiceTotal = 0;
    this.servicePaidForm.receivable = 0;
    this.servicePaidForm.receivable_percentage = 0;
    this.servicePaidForm.ledger = '';
    this.patientServiceHistory.map((invoice, index) => {
      if (invoice.selected === true) {
        this.servicePaidForm.cash =
          this.servicePaidForm.cash + invoice.payable_amount || 0;
        this.selectedCreditServiceTotal += invoice.payable_amount || 0;
      } else {
        invoice.id = this.patientServiceHistory[index].id;
        invoice.cash = this.patientServiceHistory[index].cash;
        invoice.receivable = this.patientServiceHistory[index].receivable;
      }
    });
  }

  //
  // updateInvoicesPrice(): any {
  //   this.servicePaidForm.cash = 0;
  //   this.servicePaidForm.receivable = 0;
  //   this.servicePaidForm.receivable_percentage = 0;
  //   this.servicePaidForm.ledger = '';
  //   this.patientServiceHistory.map((invoice, index) => {
  //     if (invoice.selected === true) {
  //       this.servicePaidForm.cash = this.servicePaidForm.cash + invoice.cash || 0;
  //     } else {
  //       invoice.id = this.patientServiceHistoryBackup[index].id;
  //       invoice.cash = this.patientServiceHistoryBackup[index].cash;
  //       invoice.receivable = this.patientServiceHistoryBackup[index].receivable;
  //     }
  //   });
  // }
  paymentMethodForInvoices(method, event): any {
    if (event.target.checked) {
      this.servicePaidForm.mode_of_payment = method;
    } else {
      event.target.checked = true;
    }
    this.servicePaidForm.payment_details = '';
  }

  updateServiceReceivableOfInvoices(): any {
    let totalCash = 0;
    this.patientServiceHistory.map((invoice) => {
      if (invoice.selected === true) {
        totalCash =
          totalCash + (invoice.base_price - invoice.approved_discount);
      }
    });
    if (this.panelType === false) {
      if (this.servicePaidForm.cash >= totalCash) {
        this.servicePaidForm.cash = totalCash;
        this.servicePaidForm.receivable = 0;
        this.servicePaidForm.ledger = '';
      } else {
        if (
          this.servicePaidForm.cash === null ||
          this.servicePaidForm.cash === undefined
        ) {
          this.servicePaidForm.cash = 0;
        }
        this.servicePaidForm.receivable = totalCash - this.servicePaidForm.cash;
      }
    } else {
      // this.serviceForm.cash = 0;
      // this.serviceForm.receivable = totalCash;
      this.serviceForm.cash = totalCash;
      this.serviceForm.receivable = 0;
      this.serviceForm.ledger = '';
    }
    let percentage = 0;
    percentage = (this.servicePaidForm.receivable / totalCash) * 100;
    this.servicePaidForm.receivable_percentage = Number(percentage.toFixed(2));
    this.patientServiceHistory.map((invoice) => {
      if (invoice.selected === true) {
        const totalCash = invoice.base_price - invoice.approved_discount;
        invoice.receivable = Math.round(
          (this.servicePaidForm.receivable_percentage * totalCash) / 100,
        );
        invoice.cash = totalCash - invoice.receivable;
      }
    });
  }

  unpaidToPaidServicesConfirm(): any {
    this.servicePaidForm.invoice_ids = [];
    this.patientServiceHistory.map((invoice) => {
      if (invoice.selected === true) {
        const obj = {
          id: invoice.id,
          cash: invoice.cash,
          receivable: invoice.receivable,
          net_amount: invoice.base_price - invoice.approved_discount,
        };
        this.servicePaidForm.invoice_ids.push(obj);
      }
    });
    if (this.servicePaidForm.invoice_ids.length === 0) {
      toastr.error('Select Invoices');
      return;
    }
    if (
      this.servicePaidForm.receivable > 0 &&
      this.servicePaidForm.ledger === ''
    ) {
      toastr.error('Select Ledger');
      return;
    }

    this.isChecked = false;
    this.modalService.open(this.serviceHistoryUpdateConfirm);
  }

  unpaidToPaidServices(): any {
    // if (this.isChecked === false) {
    //   toastr.error('Please give confirmation');
    //   return;
    // }
    this.modalService.dismissAll(this.serviceHistoryUpdateConfirm);

    // this.servicePaidForm.invoice_ids = this.selctedInvoceIds;
    this.ngxLoader.start();
    this.patientService
      .unpaidToPaidPatientInvoicesV2(this.servicePaidForm, this.patient)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.fetchServicesHistory(
            this.visitTypeStatus,
            this.paymentTypeStatus,
          );
          this.selectSideTabs('history-tabs', 'subTabsItems', 'history');
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  servicesTab(): any {
    if (this.consultation_services.length === 0) {
      this.addConsultant();
    }

    if (this.patient_services.length === 0) {
      if (this.addDefaultOtherService === false) {
        this.addMoreServices();
      }
    }
  }

  changePerformingType(data): any {
    this.serviceId = data.id;
    this.selectedPerformingID = data.doctor_id;
    this.selectedReferringID = data.referring_doctor_id;
    this.modalService.open(this.updatePerformingModal);
  }

  closePerformingModal(): any {
    this.modalService.dismissAll(this.updatePerformingModal);
  }

  updatePerforming(): any {
    this.ngxLoader.start();
    this.hospitalService
      .updateDoctor(
        this.serviceId,
        this.selectedPerformingID,
        this.selectedReferringID,
      )
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.updatePerformingModal);
          this.fetchServicesHistory(
            this.visitTypeStatus,
            this.paymentTypeStatus,
          );
          this.ngxLoader.stop();
          toastr.success('Updated Performing Doctor');
        },
        (err) => {
          this.modalService.dismissAll(this.updatePerformingModal);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  changeReferringType(data): any {
    this.serviceId = data.id;
    this.selectedReferringID = data.referring_doctor_id;
    this.selectedPerformingID = data.doctor_id;
    this.modalService.open(this.updateReferringModal);
  }

  closeReferringModal(): any {
    this.modalService.dismissAll(this.updateReferringModal);
  }

  updateReferring(): any {
    this.ngxLoader.start();
    this.hospitalService
      .updateDoctor(
        this.serviceId,
        this.selectedPerformingID,
        this.selectedReferringID,
      )
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.updateReferringModal);
          this.fetchServicesHistory(
            this.visitTypeStatus,
            this.paymentTypeStatus,
          );
          this.ngxLoader.stop();
          toastr.success('Updated referring Doctor');
        },
        (err) => {
          this.modalService.dismissAll(this.updateReferringModal);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  changeRequestedServices(): any {
    this.servicesTotal = 0;
    this.consultantionTotal = 0;
    this.paymentStatus = 'paid';
    this.patient_services = [];
    this.services = [];
    if (this.requestedServices === false) {
      this.addMoreServices();
    } else {
      this.fetchRequestedServices();
    }
  }

  fetchRequestedServices(): any {
    this.ngxLoader.start();
    this.requestedServicesList = [];
    this.serviceService.getRequestedServiceByMrn(this.patient.mrn).subscribe(
      (data) => {
        this.requestedServicesList = data.services;
        $('#serviceRequested').val('-1');
        setTimeout(() => {
          $('#serviceRequested').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  preventChangeDate(): any {
    return false;
  }

  setReportingTimeModal(index): any {
    $('#reportingTimeModal').modal('show');
    this.selectedReportingIndex = index;
    setTimeout(() => {
      const today = new Date();
      $('#fromDate').datetimepicker({
        defaultDate: today,
        format: 'DD-MM-YYYY h:mm a',
      });
    }, 1000);
  }

  setReportingTime(): any {
    const date = $('#reportingTime').val();
    if (date !== '') {
      $('#reportingTimeModal').modal('hide');
      this.patient_services[this.selectedReportingIndex].flight_date_time =
        moment(date, 'MM/DD/YYYY h:mm a').format('DD-MM-YYYY h:mm a');
    }
  }

  getStaffType(): any {
    this.configurationService.getStaffType().subscribe(
      (data) => {
        this.staffTypeList = data;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  getGenderList(): any {
    this.configuratorsService.fetchConfigurationV2('gender').subscribe(
      (data) => {
        this.genderList = data.configurator_types;
      },
      (err) => {
        toastr.error(err.message.message);
      },
    );
  }

  // getDesignationList(): any {
  //   this.configuratorsService.fetchConfigurationV2('designation').subscribe(data => {
  //     this.designationList = data;
  //   });
  // }
  getTitleList(): any {
    this.configuratorsService.fetchConfigurationV2('title').subscribe(
      (data) => {
        this.titleList = data.configurator_types;
      },
      (err) => {
        toastr.error(err.message.message);
      },
    );
  }

  getBankDetail(): any {
    // this.serviceService.getbankALlList().subscribe(data => {
    // this.bankList;
    // this.bankList = data;
    // if (this.bankList.length) {
    setTimeout((e) => {
      $('.selectPickerBankDetail').selectpicker('refresh');
      $('.selectPickerBankDetail').selectpicker();
    }, 500);
    // }
    // },
    // err => {
    //   toastr.error(err.error.error);
    // });
  }

  addNewReferringDoctor(): any {
    this.isSubmitReferring = false;
    this.employeeVisitForm.reset({
      title: '',
      first_name: '',
      last_name: '',
      gender: '',
      speciality: '',
      nic: '',
      pmdc_registration_no: '',
      primary_contact: '',
      hospital_name: '',
      country: '',
      state: '',
      city: '',
      staff_type: '',
      address: '',
      department: '',
      designation: '',
      status: true,
    });
    $('#referringDoctorModal').modal('show');
    setTimeout(() => {
      if (this.staffTypeList.length) {
        $('#staff_type').selectpicker('refresh');
        $('#staff_type').selectpicker();
      }
      $('#refDepartment').selectpicker('refresh');
      $('#refDepartment').selectpicker();
      $('#gender').selectpicker('refresh');
      $('#gender').selectpicker();
      $('#title').selectpicker('refresh');
      $('#title').selectpicker();
      $('#designation').selectpicker('refresh');
      $('#designation').selectpicker();
    }, 1000);
  }

  onSubmitReferringDoctorAdd(): any {
    this.isSubmitReferring = true;
    if (this.employeeVisitForm.invalid) {
      return;
    }
    // const str = this.employeeVisitForm.get('nic').value;
    // const firstHalf = str.slice(0, 13);
    // const secondHalf = str.slice(13, 14);
    // if ( firstHalf !== '' && secondHalf !== '-' ) {
    //   this.employeeVisitForm.get('nic').setValue(firstHalf + '-' + secondHalf);
    // }
    this.employeeVisitForm.get('status').setValue(true);
    this.ngxLoader.start();
    this.visitService.submitVisitForm(this.employeeVisitForm.value).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Successfully Submitted');
        $('#referringDoctorModal').modal('hide');
        let lastName = '';
        if (data.last_name) {
          lastName = data.last_name;
        }
        const obj = {
          id: data.id,
          full_name: data.first_name + ' ' + lastName || '',
          employee_type: 'visiting',
        };
        this.refDoctorsAll.push(obj);
        setTimeout(() => {
          $('.referringDoctors').selectpicker();
          $('.referringDoctors').selectpicker('refresh');
        }, 1000);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  fetchReferringDoctors(): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchRefDoctorsDoctorV2().subscribe(
      (data) => {
        this.refDoctorsAll = data.users;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#referringDoctor0').selectpicker();
        }, 1000);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updateReferringDoctor(index): any {
    setTimeout(() => {
      const data = this.patient_services[index].referring_doctor_id;
      const dataValues = data.split('/');
      this.patient_services[index].referring_doctor_id = dataValues[0];
      this.patient_services[index].employee_type = dataValues[1];
      // this.patient_services[index].employee_type = this.refDoctorsAll.find(m => m.id === Number(this.patient_services[index].referring_doctor_id)).employee_type;
    }, 500);
  }

  // getCountryData(): any {
  //   this.locationService.countries().subscribe(
  //     data => {
  //       if (data) {
  //         this.shareDataService.updateCountryData(data.countries);
  //         this.getLocationList();
  //       }
  //     },
  //     err => {}
  //   );
  // }
  // getLocationList(): any {
  //   this.shareDataService.countriesData.subscribe(data => {
  //     if ( data.length === 0 ) {
  //       this.getCountryData();
  //       return;
  //     }
  //     this.countries = data;
  //     this.employeeVisitForm.patchValue({
  //       state: '',
  //     });
  //   });
  // }
  // updateCountryFun(value): any {
  //   if ( this.countries.length > 0 ) {
  //     this.states = this.countries.filter(t => t.id === Number(value))[0].states;
  //     this.cities = [];
  //     this.employeeVisitForm.patchValue({
  //       state: '',
  //       city: '',
  //     });
  //   }
  // }
  // updateStateFun(value): any {
  //   if ( this.states.length > 0 ) {
  //     this.cities = this.states.filter(t => t.id === Number(value))[0].cities;
  //     this.employeeVisitForm.patchValue({
  //       city: '',
  //     });
  //   }
  // }
  getEmployeesDoctorWise(): any {
    this.allEmployeesStaffTypeDoctor = [];
    this.financeConfigService.getAllActiveEmployees().subscribe(
      (data) => {
        this.allEmployeesStaffTypeDoctor = data;
        setTimeout(() => {
          $('#employees').selectpicker();
          $('#employees').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  // Only Numeric & Positive Number Only
  _onlyNumericPositive(e): any {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  closeWalletModal(): any {
    $('#walletModal').modal('hide');
    this.walletPaymentMode = 'cash';
    this.resetWalletCard();
  }

  removeProductData(i): any {
    // this.controlDose.removeAt(i);
    if (this.controlDose.at(i).get('ingredient_id').value) {
      this.controlDose.at(i).get('is_remove').setValue(true);
      // this.miscService.removeProductDataOfServices(this.servicesKit.value.serviceKit).subscribe(data => {
      //   this.getServiceKit();
      // }, err => {
      //   toastr.error(err.error.message);
      // });
    } else {
      this.controlDose.removeAt(i);
    }
  }

  checkModification(i): any {
    // this.controlDose.removeAt(i);
    if (this.controlDose.at(i).get('ingredient_id').value) {
      if (
        this.controlDose.at(i).get('old_unit_req').value !=
        this.controlDose.at(i).get('unit_req').value
      ) {
        this.controlDose.at(i).get('is_modified').setValue(true);
      }
      if (
        this.controlDose.at(i).get('old_unit_req').value ==
        this.controlDose.at(i).get('unit_req').value
      ) {
        this.controlDose.at(i).get('is_modified').setValue(false);
      }
    } else {
      this.controlDose.at(i).get('is_modified').setValue(true);
    }
  }

  resetValues(): any {
    this.removeSearchString();
  }
}
