<!--begin::Card-->
<div class="card card-custom h-100">
  <!--begin::Header-->
  <div class="row p-6">
    <div class="col-4">
      <div class="d-block form-group">
        <label>{{type === 'consent_form' ? 'Consent Template' : type === 'assessment_form' ? 'Template' : 'Check-list:'}}<span class="text-danger">*</span></label>
        <!--                      <select class="form-control form-control-sm " id="checkListConfiguratorId" [(ngModel)]="checkListConfiguratorId" (change)="getCheckListDetail()">-->
        <select
          class="form-control form-control-sm"
          id="checkListConfiguratorId"
          [(ngModel)]="checkListConfiguratorId"
          (change)="getCheckListDetailHistory()"
        >
          <option value="">Select</option>
          <ng-container *ngFor="let check of checkList">
            <option [value]="check.id">{{ check.name }}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>
  <!--end::Header-->
  <!--begin::Form-->

  <div class="card-body">
    <ng-container
      *ngFor="let dataItem of checkListDetail; let i = index"
    >
      <ng-container
        *ngIf="dataItem.check_list_temp_config.length > 0"
      >
        <h3>{{ dataItem.heading | titlecase }}</h3>

        <div
          class="row"
          *ngFor="
                          let data of dataItem.check_list_temp_config;
                          let z = index
                        "
        >
          <div
            class="col"
            *ngFor="
                            let item of data.check_list_template_configurators;
                            let j = index
                          "
          >
            <div class="form-group">
              <label>{{ item.name | titlecase }}</label>
              <ng-container
                *ngIf="item.check_list_type === 'text_field'"
              >
                <input
                  class="form-control form-control-sm" id="inputValidate{{i}}{{z}}{{j}}"
                  type="text" [readonly]="item?.is_readonly"
                  [(ngModel)]="item.value"
                />
              </ng-container>
              <ng-container
                *ngIf="item.check_list_type === 'numeric_field'"
              >
                <input
                  class="form-control form-control-sm"  id="inputValidate{{i}}{{z}}{{j}}"
                  type="number" [readonly]="item?.is_readonly"
                  [(ngModel)]="item.value"
                />
              </ng-container>
              <ng-container
                *ngIf="item.check_list_type === 'date'"
              >
                <input
                  class="form-control form-control-sm" id="inputValidate{{i}}{{z}}{{j}}"
                  type="date" [readonly]="item?.is_readonly"
                  [(ngModel)]="item.value"
                />
              </ng-container>
              <ng-container
                *ngIf="item.check_list_type === 'time'"
              >
                <input
                  class="form-control form-control-sm " id="inputValidate{{i}}{{z}}{{j}}"
                  type="time" [readonly]="item?.is_readonly"
                  [(ngModel)]="item.value"
                />
              </ng-container>
              <ng-container
                *ngIf="item.check_list_type === 'date_and_time'"
              >
                <input
                  class="form-control form-control-sm" id="inputValidate{{i}}{{z}}{{j}}"
                  type="datetime-local" [readonly]="item?.is_readonly"
                  [(ngModel)]="item.value"
                />
              </ng-container>
              <ng-container
                *ngIf="item.check_list_type === 'text_area'"
              >
                              <textarea
                                class="form-control form-control-sm" id="inputValidate{{i}}{{z}}{{j}}"
                                type="text" [readonly]="item?.is_readonly"
                                [(ngModel)]="item.value"
                              ></textarea>
              </ng-container>
              <ng-container
                *ngIf="item.check_list_type === 'heading'"
              >
                <!--                              <input class="form-control form-control-sm" type="text" [(ngModel)]="item.value">-->
              </ng-container>
              <ng-container
                *ngIf="item.check_list_type === 'text_editor'"
              >
                <div
                  class="reports"
                  id="reports{{ i }}{{ z }}{{ j }}"
                ></div>
              </ng-container>
              <ng-container
                *ngIf="item.check_list_type === 'drop_down' || item.check_list_type === 'multi_select_dropdown'"
              >
                <select
                  class="form-control form-control-sm" id="dropdownValidate{{i}}{{z}}{{j}}"
                  [(ngModel)]="item.value" [disabled]="item?.is_readonly"
                >
                  <option value="">Select</option>
                  <ng-container
                    *ngFor="
                                    let itemList of item.check_list_values
                                  "
                  >
                    <option value="{{ itemList.id }}">
                      {{ itemList.name }}
                    </option>
                  </ng-container>
                </select>
              </ng-container>
              <ng-container
                *ngIf="
                                item.check_list_type === 'all_employees_list'
                              "
              >
                <div class="d-block selectPickerValidate{{ i }}{{ z }}{{ j }}">
                  <select
                    class="form-control form-control-sm"
                    data-live-search="true" [disabled]="item?.is_readonly"
                    id="allEmployees{{ i }}{{ z }}{{ j }}"
                    [(ngModel)]="item.value"
                  >
                    <option value="">Select</option>
                    <option
                      [value]="doc.id"
                      *ngFor="let doc of employeesListing"
                    >
                      {{ doc.first_name }} {{ doc.last_name }}
                    </option>
                  </select>
                </div>
              </ng-container>
              <ng-container
                *ngIf="item.check_list_type === 'all_doctor_list'"
              >
                <div class="d-block selectPickerValidate{{ i }}{{ z }}{{ j }}">
                  <select
                    class="form-control form-control-sm"
                    id="doctorPicker{{ i }}{{ z }}{{ j }}"
                    data-live-search="true"
                    [disabled]="item?.is_readonly"
                    [(ngModel)]="item.value"
                  >
                    <option value="">Select</option>
                    <option
                      [value]="doctor.id"
                      *ngFor="let doctor of doctors"
                    >
                      {{
                        doctor?.first_name +
                        " " +
                        doctor?.last_name
                      }}
                    </option>
                  </select>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="showSignature && urlImage">
      <img class="border" src="{{ urlImage }}"/>
    </ng-container>
<!--<ng-container *ngIf="showSignature && selectedPatient && checkListDetail.length > 0">-->
    <div class="signature border w-300px h-200px">
      <canvas #canvas width="300" height="200"></canvas>
    </div>
<!--</ng-container>-->
    <div
      class="mt-3 text-right"
      *ngIf="selectedPatient && checkListDetail.length > 0"
    >
      <ng-container *ngIf="urlButton && showSignature">
        <button
          type="button"
          class="btn btn-sm btn-primary mr-2"
          (click)="updateSignature()"
        >
          Update Signature
        </button>
      </ng-container>
      <ng-container *ngIf="!urlButton && showSignature">
        <button
          type="button"
          class="btn btn-outline-primary mr-2"
          (click)="clear()"
        >
          clear Signature
        </button>
      </ng-container>
      <button
        type="button"
        (click)="printChecklist()"
        class="btn btn-sm btn-primary mr-2"
        *ngIf="showCheckListPrint"
      >
        Print
      </button>
      <button
        type="submit"
        class="btn btn-sm btn-primary"
        (click)="submitChecklistFunction()"
      >
        Submit
      </button>
    </div>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</div>

<div class="card mt-4" *ngIf="(type === 'consent_form' || type === 'assessment_form') && consentListing.length">
  <div class="card-body">
    <table class="table table-head-custom table-vertical-center">
      <thead>
      <tr>
        <th style="width: 30%">Date</th>
        <th style="width: 30%">Name</th>
        <th style="width: 40%;text-align: right">Action</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let list of consentListing">
        <tr>
          <td>{{ list?.created_at | dateTimeFormat }}</td>
          <td>{{ list?.consent_name }}</td>
          <td class="text-right">
            <div class="dropdown dropdown-inline mr-2">
              <button
                type="button"
                class="btn btn-light-primary btn-icon btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="ki ki-bold-more-ver"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <a (click)="openPdfReport(list?.id)"
                  class="dropdown-item cursor-pointer"
                >Print</a
                >
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>
