<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">Culture Configuration</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card card-custom">
        <div class="card-body">
          <form [formGroup]="addCultureForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Report</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="report_type"
                    (change)="updateCultureType($event.target.value)"
                    [ngClass]="{
                      'is-invalid': submitted && controls.report_type.errors
                    }"
                  >
                    <option value="">Select Type</option>
                    <option value="culture_plates">Culture Plates</option>
                    <option value="culture">Culture</option>
                    <option value="final_report">Final Report</option>
                    <option value="sensitivity">Sensitivity</option>
                    <option value="free_fields">Free Fields</option>
                    <option value="report_comments">Report Comments</option>
                    <option value="microscopy">Microscopy</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Type</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="culture_type"
                    (change)="updateCultureName()"
                    [ngClass]="{
                      'is-invalid': submitted && controls.culture_type.errors
                    }"
                  >
                    <option value="">Select Type</option>
                    <ng-container *ngIf="reportType === 'culture_plates'">
                      <option value="culture_plate">Plate</option>
                      <option value="culture_what">What</option>
                    </ng-container>
                    <ng-container *ngIf="reportType === 'culture'">
                      <option value="cultures_type">Culture Type</option>
                      <option value="culture_name">Culture Name</option>
                      <option value="culture_atmosphere">Atmosphere</option>
                      <option value="culture_temperature">Temperature</option>
                    </ng-container>
                    <ng-container *ngIf="reportType === 'final_report'">
                      <option value="final_report_type">Type</option>
                      <option value="final_report_medicine">Medicine</option>
                    </ng-container>
                    <ng-container *ngIf="reportType === 'microscopy'">
                      <option value="microscopy_type">Type</option>
                      <option value="microscopy_result">Result</option>
                    </ng-container>
                    <ng-container *ngIf="reportType === 'sensitivity'">
                      <option value="sensitivity_type">Group</option>
                      <option value="sensitivity_group">Medicine Name</option>
                      <option value="sensitivity_resistance_ir">
                        Sensitivity Resistance IR
                      </option>
                    </ng-container>
                    <ng-container *ngIf="reportType === 'free_fields'">
                      <option value="free_fields_heading">Gram Stain</option>
                      <option value="free_fields_zn_stain">ZN Stain</option>
                      <option value="free_fields_fungal_stain">
                        Fungal Stain
                      </option>
                    </ng-container>
                    <ng-container *ngIf="reportType === 'report_comments'">
                      <option value="report_comments_heading">Heading</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Short Name</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    formControlName="short_name"
                    placeholder="Short Name"
                    [ngClass]="{
                      'is-invalid': submitted && controls.short_name.errors
                    }"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    formControlName="name"
                    placeholder="Name"
                    [ngClass]="{
                      'is-invalid': submitted && controls.name.errors
                    }"
                  />
                </div>
              </div>
              <div
                class="col-lg-3 col-md-4 col-sm-6 col-12"
                *ngIf="
                  controls.report_type.value === 'free_fields' ||
                    controls.report_type.value === 'report_comments';
                  else content
                "
              >
                <div class="form-group">
                  <label>Comments</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    formControlName="comments"
                    [ngClass]="{
                      'is-invalid': submitted && controls.comments.errors
                    }"
                  />
                </div>
              </div>
              <ng-template #content>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Comments</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      disabled
                      placeholder="Comments"
                    />
                  </div>
                </div>
              </ng-template>
              <div class="col-lg-1 col-md-1 col-sm-6 col-12 text-center">
                <div class="form-group">
                  <label>Active</label>
                  <label class="checkbox justify-content-center">
                    <input
                      type="checkbox"
                      formControlName="status"
                      [ngClass]="{
                        'is-invalid': submitted && controls.status.errors
                      }"
                    />
                    <span></span>
                  </label>
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-6 col-12 text-right">
                <button class="btn btn-sm btn-primary form-group" type="submit">
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card card-custom gutter-b mt-4">
        <div class="card-header card-header-tabs-line culture-card-header">
          <div class="card-toolbar culture-toolbar">
            <ul class="nav nav-tabs nav-bold nav-tabs-line cultures">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#culture-plates-tab"
                >
                  <span class="nav-text">Culture Plates</span>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#culture-tab">
                  <span class="nav-text">culture</span>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#final-report-tab">
                  <span class="nav-text">Final Report</span>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#Sensitivity-tab">
                  <span class="nav-text">Sensitivity</span>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#free-fields-tab">
                  <span class="nav-text">Free Fields</span>
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#reports-comments-tab"
                >
                  <span class="nav-text">Report Comments</span>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#microscopy-tab">
                  <span class="nav-text">Microscopy</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body custom-card-body">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="culture-plates-tab"
              role="tabpanel"
              aria-labelledby="culture-plates-tab"
            >
              <form
                [formGroup]="updateCulturePlatesForm"
                (ngSubmit)="onUpdate(updateCulturePlatesForm)"
              >
                <div
                  class="row mb-1"
                  formArrayName="culture_configurations"
                  *ngFor="let g of culturePlatesArray.controls; let i = index"
                >
                  <ng-container [formGroupName]="i" style="width: 100%">
                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0">Report</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="report_type"
                        disabled
                      >
                        <option value="culture_plates">Culture Plates</option>
                      </select>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0">Type</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="culture_type"
                        [ngClass]="{
                          'is-invalid':
                            isUpdate && g.controls.culture_type.errors
                        }"
                      >
                        <ng-container>
                          <option value="culture_plate">Plate</option>
                          <option value="culture_what">What</option>
                        </ng-container>
                      </select>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                      <label *ngIf="i === 0">Name</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid': isUpdate && g.controls.name.errors
                        }"
                      />
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-6 col-12 text-center">
                      <label *ngIf="i === 0" style="width: 100%">Status</label>
                      <label class="checkbox justify-content-center">
                        <input type="checkbox" formControlName="status" />
                        <span></span>
                      </label>
                    </div>
                  </ng-container>
                </div>
                <div class="row text-right mt-3">
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="tab-pane fade"
              id="culture-tab"
              role="tabpanel"
              aria-labelledby="culture-tab"
            >
              <form
                [formGroup]="updateCultureForm"
                (ngSubmit)="onUpdate(updateCultureForm)"
              >
                <div
                  class="row mb-1"
                  formArrayName="culture_configurations"
                  *ngFor="let g of cultureArray.controls; let i = index"
                >
                  <ng-container [formGroupName]="i" style="width: 100%">
                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Report</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="report_type"
                        disabled
                      >
                        <option value="culture">Culture</option>
                      </select>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Type</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="culture_type"
                        [ngClass]="{
                          'is-invalid':
                            isUpdate && g.controls.culture_type.errors
                        }"
                      >
                        <ng-container>
                          <option value="cultures_type">Culture Type</option>
                          <option value="culture_name">Culture Name</option>
                          <option value="culture_atmosphere">Atmosphere</option>
                          <option value="culture_temperature">
                            Temperature
                          </option>
                        </ng-container>
                      </select>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Name</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid': isUpdate && g.controls.name.errors
                        }"
                      />
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-6 col-12 text-center">
                      <label *ngIf="i === 0" style="width: 100%">Status</label>
                      <label class="checkbox justify-content-center">
                        <input type="checkbox" formControlName="status" />
                        <span></span>
                      </label>
                    </div>
                    <!--                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">-->
                    <!--                      </div>-->
                  </ng-container>
                </div>
                <div class="row text-right mt-3">
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="tab-pane fade"
              id="final-report-tab"
              role="tabpanel"
              aria-labelledby="final-report-tab"
            >
              <form
                [formGroup]="updateFinalReportForm"
                (ngSubmit)="onUpdate(updateFinalReportForm)"
              >
                <div
                  class="row mb-1"
                  formArrayName="culture_configurations"
                  *ngFor="let g of finalReportArray.controls; let i = index"
                >
                  <ng-container [formGroupName]="i" style="width: 100%">
                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Report</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="report_type"
                        disabled
                      >
                        <option value="final_report">Final Report</option>
                      </select>
                    </div>

                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Type</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="culture_type"
                        [ngClass]="{
                          'is-invalid':
                            isUpdate && g.controls.culture_type.errors
                        }"
                      >
                        <ng-container>
                          <option value="final_report_type">Type</option>
                          <option value="final_report_medicine">
                            Medicine
                          </option>
                        </ng-container>
                      </select>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Name</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid': isUpdate && g.controls.name.errors
                        }"
                      />
                    </div>

                    <div class="col-lg-1 col-md-1 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Status</label>
                      <label class="checkbox">
                        <input type="checkbox" formControlName="status" />
                        <span></span>
                      </label>
                    </div>
                    <!--                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">-->
                    <!--                      </div>-->
                  </ng-container>
                </div>
                <div class="row text-right mt-3">
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="tab-pane fade"
              id="Sensitivity-tab"
              role="tabpanel"
              aria-labelledby="Sensitivity-tab"
            >
              <form
                [formGroup]="updateSensitivityForm"
                (ngSubmit)="onUpdate(updateSensitivityForm)"
              >
                <div
                  class="row mb-1"
                  formArrayName="culture_configurations"
                  *ngFor="let g of sensitivityArray.controls; let i = index"
                >
                  <ng-container [formGroupName]="i" style="width: 100%">
                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0">Report</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="report_type"
                        disabled
                      >
                        <option value="sensitivity">Sensitivity</option>
                      </select>
                    </div>

                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0">Type</label>
                      <select
                        class="form-control form-control-sm"
                        disabled
                        formControlName="culture_type"
                        [ngClass]="{
                          'is-invalid':
                            isUpdate && g.controls.culture_type.errors
                        }"
                      >
                        <ng-container>
                          <option value="sensitivity_type">Group</option>
                          <option value="sensitivity_group">
                            Medicine Name
                          </option>
                          <option value="sensitivity_resistance_ir">
                            Sensitivity Resistance IR
                          </option>
                        </ng-container>
                      </select>
                    </div>

                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0">Name</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid': isUpdate && g.controls.name.errors
                        }"
                      />
                    </div>

                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <ng-container *ngIf="i === 0">
                        <label style="width: 100%">Grouping</label>
                        <div style="height: calc(1.35em + 1.1rem + 2px)"></div>
                      </ng-container>
                      <select
                        class="form-control form-control-sm"
                        formControlName="default_name_id"
                        [ngClass]="{
                          'is-invalid': submitted && g.controls.default.errors
                        }"
                        *ngIf="
                          g.controls.culture_type.value === 'sensitivity_group'
                        "
                      >
                        <option value="">Select</option>
                        <ng-container *ngFor="let type of cultureList">
                          <option
                            *ngIf="type.culture_type === 'sensitivity_type'"
                            [value]="type.id"
                          >
                            {{ type.name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-6 col-12 text-center">
                      <ng-container *ngIf="i === 0">
                        <label style="width: 100%">Default</label>
                        <div style="height: calc(1.35em + 1.1rem + 2px)"></div>
                      </ng-container>
                      <label
                        class="checkbox justify-content-center"
                        *ngIf="
                          g.controls.culture_type.value === 'sensitivity_group'
                        "
                      >
                        <input
                          type="checkbox"
                          formControlName="default"
                          [ngClass]="{
                            'is-invalid': submitted && g.controls.default.errors
                          }"
                        />
                        <span></span>
                      </label>
                    </div>

                    <div class="col-lg-1 col-md-1 col-sm-6 col-12 text-center">
                      <label *ngIf="i === 0" style="width: 100%">Status</label>
                      <label class="checkbox justify-content-center">
                        <input type="checkbox" formControlName="status" />
                        <span></span>
                      </label>
                    </div>
                  </ng-container>
                </div>
                <div class="row text-right mt-3">
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="tab-pane fade"
              id="free-fields-tab"
              role="tabpanel"
              aria-labelledby="free-fields-tab"
            >
              <form
                [formGroup]="updateFreeFieldsForm"
                (ngSubmit)="onUpdate(updateFreeFieldsForm)"
              >
                <div
                  class="row mb-1"
                  formArrayName="culture_configurations"
                  *ngFor="let g of freeFieldsArray.controls; let i = index"
                >
                  <ng-container [formGroupName]="i" style="width: 100%">
                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Report</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="report_type"
                        disabled
                      >
                        <option value="free_fields">Free Fields</option>
                      </select>
                    </div>

                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Type</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="culture_type"
                        [ngClass]="{
                          'is-invalid':
                            isUpdate && g.controls.culture_type.errors
                        }"
                      >
                        <ng-container>
                          <option value="free_fields_heading">
                            Gram Stain
                          </option>
                          <option value="free_fields_zn_stain">ZN Stain</option>
                          <option value="free_fields_fungal_stain">
                            Fungal Stain
                          </option>
                        </ng-container>
                      </select>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Name</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid': isUpdate && g.controls.name.errors
                        }"
                      />
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%"
                        >Comments</label
                      >
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="comments"
                      />
                    </div>

                    <div class="col-lg-1 col-md-1 col-sm-6 col-12 text-center">
                      <label *ngIf="i === 0" style="width: 100%">Status</label>
                      <label class="checkbox justify-content-center">
                        <input type="checkbox" formControlName="status" />
                        <span></span>
                      </label>
                    </div>
                  </ng-container>
                </div>
                <div class="row text-right mt-3">
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="tab-pane fade"
              id="reports-comments-tab"
              role="tabpanel"
              aria-labelledby="reports-comments-tab"
            >
              <form
                [formGroup]="updateReportsCommentsForm"
                (ngSubmit)="onUpdate(updateReportsCommentsForm)"
              >
                <div
                  class="row mb-1"
                  formArrayName="culture_configurations"
                  *ngFor="let g of reportCommentsArray.controls; let i = index"
                >
                  <ng-container [formGroupName]="i" style="width: 100%">
                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Report</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="report_type"
                        disabled
                      >
                        <option value="report_comments">Report Comments</option>
                      </select>
                    </div>

                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Type</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="culture_type"
                        [ngClass]="{
                          'is-invalid':
                            isUpdate && g.controls.culture_type.errors
                        }"
                      >
                        <ng-container>
                          <option value="report_comments_heading">
                            Heading
                          </option>
                        </ng-container>
                      </select>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Name</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid': isUpdate && g.controls.name.errors
                        }"
                      />
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%"
                        >Comments</label
                      >
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="comments"
                      />
                    </div>

                    <div class="col-lg-1 col-md-1 col-sm-6 col-12 text-center">
                      <label *ngIf="i === 0" style="width: 100%">Status</label>
                      <label class="checkbox justify-content-center">
                        <input type="checkbox" formControlName="status" />
                        <span></span>
                      </label>
                    </div>
                  </ng-container>
                </div>
                <div class="row text-right mt-3">
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="tab-pane fade"
              id="microscopy-tab"
              role="tabpanel"
              aria-labelledby="microscopy-tab"
            >
              <form
                [formGroup]="updateMicroscopyForm"
                (ngSubmit)="onUpdate(updateMicroscopyForm)"
              >
                <div
                  class="row mb-1"
                  formArrayName="culture_configurations"
                  *ngFor="let g of microscopyArray.controls; let i = index"
                >
                  <ng-container [formGroupName]="i" style="width: 100%">
                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Report</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="report_type"
                        disabled
                      >
                        <option value="microscopy">Microscopy</option>
                      </select>
                    </div>

                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Type</label>
                      <select
                        class="form-control form-control-sm"
                        formControlName="culture_type"
                        [ngClass]="{
                          'is-invalid':
                            isUpdate && g.controls.culture_type.errors
                        }"
                      >
                        <ng-container>
                          <option value="microscopy_type">Type</option>
                          <option value="microscopy_result">Result</option>
                        </ng-container>
                      </select>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Name</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid': isUpdate && g.controls.name.errors
                        }"
                      />
                    </div>

                    <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                      <label *ngIf="i === 0" style="width: 100%">Status</label>
                      <label class="checkbox justify-content-center">
                        <input type="checkbox" formControlName="status" />
                        <span></span>
                      </label>
                    </div>
                    <!--                      <div class="col-lg-4 col-md-4 col-sm-6 col-12">-->
                    <!--                      </div>-->
                  </ng-container>
                </div>
                <div class="row text-right mt-3">
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
