import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class BillingCategoryService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/ward_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  indexV2(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/ward_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  create(value): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/ward_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          ward_service: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // tslint:disable-next-line:variable-name
  updateBillingCategory(values, ward_service_category_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/ward_services/save_ward_service_categories?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          ward_service_category_id,
          ward_service: values,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getAllOtServices(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/ot_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllOtProcedureServicesV2(search, page, perPage): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/ot_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&service_code=' +
          search.service_code +
          '&service_name=' +
          search.service_name +
          '&department_id=' +
          search.department_id +
          '&type_id=' +
          search.type_id +
          '&status=' +
          search.status +
          '&page=' +
          page +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllOtServicesV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/ot_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getOtServiceData(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/ot_services/ot_service_id_wise/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&ot_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createOtServices(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/ot_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          ot_service: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createOtServicesV2(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/ot_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          ot_service: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateOtServices(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/ot_services/update_ot_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          ot_services: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateOtServicesV2(value): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/ot_services/` +
          value.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          ot_service: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
