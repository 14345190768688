import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class LinkLabParameterService {
  constructor(private http: HttpClient) {}

  linkLabParameters(labParameters: any[], labTestId: number): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_investigations/link_lab_assessments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_assessments: labParameters,
          testId: labTestId,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  linkLabParametersV2(
    labParameters: any[],
    parameterComment: string,
    labTestId: number,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/link_lab_assessments/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_assessments: labParameters,
          testId: labTestId,
          parameter_comments: parameterComment,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchLabTest(testId: number): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/lab_investigations/search_by_id?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          testId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchLabTestV2(testId: number): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/search_by_id/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          testId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
