import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class ServiceService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  indexServiceV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getServiceUnpaidOrAdvanceAmount(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/detail_invoices_against_visit/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getServices(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/billing_packages?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getServiceByTypes(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/services/services_by_type?assignable_type=` +
          type +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getServiceByTypesV2(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/services_by_type?assignable_type=` +
          type +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getServiceTypes(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/services/service_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getVisitService(
    patient_id: number,
    visit_type_id: number,
    doctor_id,
    department_id,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/services/doctor_fee_service/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_type_id=' +
          visit_type_id +
          '&doctor_id=' +
          doctor_id +
          '&patient_id=' +
          patient_id +
          '&department_id=' +
          department_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Python Start
  getVisitServiceV2(
    patient_id: number,
    visit_type_id: number,
    doctor_id,
    department_id,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/doctor_fee_service/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_type_id=' +
          visit_type_id +
          '&doctor_id=' +
          doctor_id +
          '&patient_id=' +
          patient_id +
          '&department_id=' +
          department_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getVisitServiceV3(
    patient_id: number,
    visit_type_id: number,
    doctor_id,
    department_id,
    isopd,
    isipd,
    isemr,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/doctor_fee/categories_against_user/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_type_id=' +
          visit_type_id +
          '&doctor_id=' +
          doctor_id +
          '&patient_id=' +
          patient_id +
          '&department_id=' +
          department_id +
          '&is_opd=' +
          isopd +
          '&is_ipd=' +
          isipd +
          '&is_emr=' +
          isemr,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python End
  getRequestedServiceByMrn(mrn): Observable<any> {
    const results = this.http
      .get(
        environment.rorUrl +
          '/v4/patient_services/requested_services?patient_id=' +
          mrn +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRequestedServiceByMrnV2(mrn): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/requested_services/?patient_id=' +
          mrn +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPriceAgainst(patient, billing_code): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/pricings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          patient.patient_type_id +
          '&billing_code=' +
          billing_code,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPriceAgainstv2(patient, billing_code): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/pricings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_type_id=' +
          patient.patient_type_id +
          '&billing_code=' +
          billing_code,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientByRegTypeV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_registration_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          'mrn' +
          '&query=' +
          patient,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
