import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class PatientHistoryService {
  constructor(private http: HttpClient) {}

  vitalsHistoryDates(patient, departmentName): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/vitals/group_by_date.json?auth_token=` +
          localStorage.getItem('auth_token') +
          `&department_name=` +
          departmentName +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  paitentDiagnosisHistoryDates(patient, departmentName): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_diagnoses/group_by_date?auth_token=` +
          localStorage.getItem('auth_token') +
          '&patient_id=' +
          patient.mrn +
          `&department_name=` +
          departmentName +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  medicineHistoryDates(patient, departmentName): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_medications/group_by_date.json?auth_token=` +
          localStorage.getItem('auth_token') +
          `&department_name=` +
          departmentName +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  investigationNotesDates(patient, departmentName): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v3/investigation_notes/group_by_date?auth_token=` +
          localStorage.getItem('auth_token') +
          '&patient_id=' +
          patient.mrn +
          `&department_name=` +
          departmentName +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  referralHistoryDates(patient, departmentName): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/referrals/group_by_date.json?auth_token=` +
          localStorage.getItem('auth_token') +
          `&department_name=` +
          departmentName +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  vaccinationsHistoryDates(patient, departmentName): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_vaccinations/group_by_date.json?auth_token=` +
          localStorage.getItem('auth_token') +
          `&department_name=` +
          departmentName +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientAdmissionHistoryDates(patient, departmentName): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/admissions/group_by_date?auth_token=` +
          localStorage.getItem('auth_token') +
          '&patient_id=' +
          patient.mrn +
          `&department_name=` +
          departmentName +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientHistory(patient, date, activeTab): Observable<any> {
    if (activeTab == 'Vitals') {
      var url = '/v4/patients/' + patient.mrn + '/vitals/vitals_by_date';
    } else if (activeTab == 'Diagonosis') {
      var url = '/v4/patient_diagnoses/patient_diagnoses_by_date';
    } else if (activeTab == 'Medicines') {
      var url =
        '/v4/patients/' +
        patient.mrn +
        '/patient_medications/patient_medications_by_date';
    } else if (activeTab == 'Service') {
      var url = '/v4/patient_services/patient_services_by_date';
    } else if (activeTab == 'ProgressNotesNurse') {
      var url = '/v3/investigation_notes/investigation_notes_by_date';
    } else if (activeTab == 'Referral') {
      var url = '/v4/patients/' + patient.mrn + '/referrals/referrals_by_date';
    } else if (activeTab == 'Vaccination') {
      var url =
        '/v4/patients/' +
        patient.mrn +
        '/patient_vaccinations/patient_vaccinations_by_date';
    } else if (activeTab == 'Discharge') {
      var url = '/v4/admissions/admissions_by_date';
    }
    let results = this.http
      .get(
        `${environment.rorUrl}` +
          url +
          `.json?auth_token=` +
          localStorage.getItem('auth_token') +
          '&date=' +
          date +
          '&patient_id=' +
          patient.mrn +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createReviewSystemHistoryByAdmission(
    patient_review_system,
    patient,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_review_systems.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_review_system: patient_review_system,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createReviewSystemHistoryByAdmissionV2(
    patient_review_system,
    patient,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/ipd/patient_review_systems/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_review_system: patient_review_system,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateReviewSystemHistoryByAdmission(
    patient_review_system,
    patient,
    review_system_history_id,
  ): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_review_systems/` +
          review_system_history_id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_review_system: patient_review_system,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createPhysicalSystemicExaminationHistoryByAdmission(
    physical_systemic_examination,
    patient,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/physical_systemic_examinations.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          physical_systemic_examination: physical_systemic_examination,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createPhysicalSystemicExaminationHistoryByAdmissionV2(
    physical_systemic_examination,
    patient,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/ipd/physical_systemic_examinations/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1]?.id,
          physical_systemic_examination: physical_systemic_examination,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatePhysicalSystemicExaminationHistoryByAdmission(
    physical_systemic_examination,
    patient,
    physical_systemic_examination_id,
  ): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/physical_systemic_examinations/` +
          physical_systemic_examination_id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          physical_systemic_examination: physical_systemic_examination,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  assignMedicineToPatient(medicine, patient): Observable<any> {
    // debugger
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_medications.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[0].id,
          ward_id: patient.admissions[0].ward_id,
          visit_id: patient.visit_id,
          patient_medication: medicine,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  assignMedicineToPatientV2(medicine, patient, isHistory): Observable<any> {
    // debugger
    let results = this.http
      .post(
        `${environment.pythonUrl}/opd/patient_medications/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&admission_id=' +
          patient.admissions[0].id +
          '&visit_id=' +
          patient.visit_id +
          '&ward_id=' +
          patient.admissions[0].ward_id +
          '&patient_mrn=' +
          patient.id +
          '&is_history=' +
          isHistory +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[0].id,
          ward_id: patient.admissions[0].ward_id,
          visit_id: patient.visit_id,
          patient_medication: medicine,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchPatientAllergiesByAdmission(patient): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/allergies/allergies_by_admission?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission_id=' +
          patient.admissions[patient.admissions.length - 1].id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchMedicineHistoryFun(patient): Observable<any> {
    let admissonId = '';
    if (patient.admissions[0]) {
      admissonId = patient.admissions[0].id;
    }
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_medications.json?auth_token=` +
          localStorage.getItem('auth_token') +
          '&admission_id=' +
          admissonId +
          '&visit_id=' +
          patient.visit_id +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchMedicineHistoryFunV2(patient, isHistory): Observable<any> {
    let admissonId = '';
    if (patient.admissions[0]) {
      admissonId = patient.admissions[0].id;
    }
    let results = this.http
      .get(
        `${environment.pythonUrl}/opd/patient_medications/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&patient_mrn=' +
          patient.id +
          '&admission_id=' +
          admissonId +
          '&visit_id=' +
          patient.visit_id +
          '&is_history=' +
          isHistory +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchGeneralHistory(patient): Observable<any> {
    // let results = this.http.get(`${environment.rorUrl}/v4/patients/`+patient.mrn+`/patient_general_histories?auth_token=`+localStorage.getItem('auth_token')+'&medical_unit_id='+localStorage.getItem('current_medical_unit_id')+'&admission_id='+patient.admissions[patient.admissions.length - 1].id
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_general_histories?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchGeneralHistoryOpd(patient): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/opd/general_history/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchGeneralHistoryV2(patient): Observable<any> {
    // let results = this.http.get(`${environment.rorUrl}/v4/patients/`+patient.mrn+`/patient_general_histories?auth_token=`+localStorage.getItem('auth_token')+'&medical_unit_id='+localStorage.getItem('current_medical_unit_id')+'&admission_id='+patient.admissions[patient.admissions.length - 1].id
    let results = this.http
      .get(
        `${environment.pythonUrl}/ipd/patient_general_histories/` +
          patient.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchReviewSystemHistory(patient): Observable<any> {
    // let results = this.http.get(`${environment.rorUrl}/v4/patients/`+patient.mrn+`/patient_review_systems?auth_token=`+localStorage.getItem('auth_token')+'&medical_unit_id='+localStorage.getItem('current_medical_unit_id') + '&admission_id=' + patient.admissions[patient.admissions.length - 1].id
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_review_systems?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchReviewSystemHistoryOpd(patient): Observable<any> {
    // let results = this.http.get(`${environment.rorUrl}/v4/patients/`+patient.mrn+`/patient_review_systems?auth_token=`+localStorage.getItem('auth_token')+'&medical_unit_id='+localStorage.getItem('current_medical_unit_id') + '&admission_id=' + patient.admissions[patient.admissions.length - 1].id
    let results = this.http
      .get(
        `${environment.pythonUrl}/opd/patient_review_systems/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchReviewSystemHistoryV2(patient): Observable<any> {
    // let results = this.http.get(`${environment.rorUrl}/v4/patients/`+patient.mrn+`/patient_review_systems?auth_token=`+localStorage.getItem('auth_token')+'&medical_unit_id='+localStorage.getItem('current_medical_unit_id') + '&admission_id=' + patient.admissions[patient.admissions.length - 1].id
    let results = this.http
      .get(
        `${environment.pythonUrl}/ipd/patient_review_systems/` +
          patient.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchPhysicalSystemicExamination(patient): Observable<any> {
    // let results = this.http.get(`${environment.rorUrl}/v4/patients/` + patient.mrn + `/physical_systemic_examinations?auth_token=`+localStorage.getItem('auth_token')+'&medical_unit_id='+localStorage.getItem('current_medical_unit_id') + '&admission_id=' + patient.admissions[patient.admissions.length - 1].id
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/physical_systemic_examinations?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchPhysicalSystemicExaminationOpd(patient): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/opd/physical_systemic_examinations/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchPhysicalSystemicExaminationV2(patient): Observable<any> {
    // let results = this.http.get(`${environment.rorUrl}/v4/patients/` + patient.mrn + `/physical_systemic_examinations?auth_token=`+localStorage.getItem('auth_token')+'&medical_unit_id='+localStorage.getItem('current_medical_unit_id') + '&admission_id=' + patient.admissions[patient.admissions.length - 1].id
    let results = this.http
      .get(
        `${environment.pythonUrl}/ipd/physical_systemic_examinations/` +
          patient.mrn +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchCentralNervousSystem(patient): Observable<any> {
    // let results = this.http.get(`${environment.rorUrl}/v4/patients/`+patient.mrn+`/central_nervous_systems?auth_token=`+localStorage.getItem('auth_token')+'&medical_unit_id='+localStorage.getItem('current_medical_unit_id')+'&admission_id='+patient.admissions[patient.admissions.length - 1].id
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/central_nervous_systems?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchCentralNervousSystemOpd(patient): Observable<any> {
    // let results = this.http.get(`${environment.rorUrl}/v4/patients/`+patient.mrn+`/central_nervous_systems?auth_token=`+localStorage.getItem('auth_token')+'&medical_unit_id='+localStorage.getItem('current_medical_unit_id')+'&admission_id='+patient.admissions[patient.admissions.length - 1].id
    let results = this.http
      .get(
        `${environment.pythonUrl}/opd/central_nervous_systems/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchCentralNervousSystemV2(patient): Observable<any> {
    // let results = this.http.get(`${environment.rorUrl}/v4/patients/`+patient.mrn+`/central_nervous_systems?auth_token=`+localStorage.getItem('auth_token')+'&medical_unit_id='+localStorage.getItem('current_medical_unit_id')+'&admission_id='+patient.admissions[patient.admissions.length - 1].id
    let results = this.http
      .get(
        `${environment.pythonUrl}/ipd/central_nervous_systems/` +
          patient.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
