import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeSelfServiceService } from '../../services/employee-self-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { DashboardService } from '../../services/dashboard.service';
import { AuthService } from '../../services/auth.service';
import { ShareDataService } from '../../services/share-data.service';

declare let toastr: any;
declare let $: any;
declare var Highcharts: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  list: any;
  fromDate: any;
  toDate: any;
  today = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  statsData: any;
  filterName = 'Today';
  remarks = '';
  hospitalName: any;
  employeeLeaveData: any;
  leaveSelectedIndex: any;
  leaveStatus: any;
  isDeduction = 0;
  deductionDays: any;
  transactionDetail = [];
  page = 1;
  totalPages: any;
  perPage = 25;
  transactionDetailsType = '';
  constructor(
    private router: Router,
    private ref: ChangeDetectorRef,
    private employeeSelfServiceService: EmployeeSelfServiceService,
    private dashboardService: DashboardService,
    private ngxLoader: NgxUiLoaderService,
    private authService: AuthService,
    private shareDataService: ShareDataService,
  ) {
    let userData = JSON.parse(localStorage.getItem('currentUser'));
    if (userData.is_super_admin === false) {
      // this.router.navigate(['/']);
    }
    this.hospitalName = JSON.parse(localStorage.getItem('currentUser'));
    this.hospitalName = this.hospitalName.medical_units[0].title;
  }
  getLeaveRequest(): any {
    this.ngxLoader.start();
    this.employeeSelfServiceService
      .getLeaveRequestForApproval('1', '100', '')
      .subscribe(
        (data) => {
          this.list = data.items;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
  getStatsData(): any {
    this.ngxLoader.start();
    this.dashboardService
      .getSuperAdminStatsData(this.fromDate, this.toDate)
      .subscribe(
        (data) => {
          this.statsData = data;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
  getTransactionDetails(type): any {
    this.transactionDetailsType = type;
    this.page = 1;
    this.perPage = 25;
    this.getTransactionDetailsData();
  }
  getTransactionDetailsData(): any {
    this.ngxLoader.start();
    this.dashboardService
      .getSuperAdminPatientStatsData(
        this.fromDate,
        this.toDate,
        this.transactionDetailsType,
        this.page,
        this.perPage,
      )
      .subscribe(
        (data) => {
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          this.transactionDetail = data.patients;
          this.ngxLoader.stop();
          $('#patientDetailsStatus').modal('show');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
  approveLeaveRequest(data, index, status): any {
    // this.employeeLeaveData = data;
    this.getLoanRequestForApproval(data.id);
    this.remarks = '';
    this.leaveSelectedIndex = index;
    this.leaveStatus = status;
    $('#leaveStatusModal').modal('show');
  }
  getLoanRequestForApproval(id): any {
    this.ngxLoader.start();
    this.employeeSelfServiceService.getLeaveRequestApprovalDetail(id).subscribe(
      (data) => {
        this.employeeLeaveData = data;
        this.deductionDays =
          this.employeeLeaveData.leave_request.number_of_days;
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }
  getDashboardCounts(value, type): any {
    this.fromDate = '';
    this.toDate = '';
    if (Number(value) === 1) {
      this.fromDate = this.today;
      this.toDate = this.today;
    } else if (value === 'all') {
      this.fromDate = 'all';
      this.toDate = 'all';
    } else {
      const dates = moment()
        .subtract(Number(value), 'days')
        .format('DD-MM-YYYY');
      this.fromDate = dates;
      this.toDate = this.today;
    }
    if (type === 1) {
      this.getStatsData();
      if (value === '1') {
        this.filterName = 'Today';
      } else if (value === '3') {
        this.filterName = 'Last 3 Days';
      } else if (value === '7') {
        this.filterName = 'Week';
      } else if (value === '30') {
        this.filterName = 'Month';
      } else if (value === '365') {
        this.filterName = '1 Year';
      } else if (value === 'all') {
        this.filterName = 'Lifetime';
      } else {
        this.filterName = 'Today';
      }
    }
    if (type === 3) {
      this.getDashboardCharts();
    }
  }
  updateLeaveStatus(leaveStatus): any {
    if (
      this.remarks === '' ||
      this.remarks === null ||
      this.remarks === undefined
    ) {
      return;
    }
    this.ngxLoader.start();
    this.employeeSelfServiceService
      .approveLeaveRequest(
        this.remarks,
        leaveStatus,
        this.employeeLeaveData.is_external,
        this.employeeLeaveData.level_number,
        this.employeeLeaveData.is_internal,
        this.employeeLeaveData.id,
        this.isDeduction,
        this.deductionDays,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          if (leaveStatus === 1) {
            toastr.success('Approved Successfully');
          }
          if (leaveStatus === 3) {
            toastr.success('Rejected Successfully');
          }
          this.list.splice(this.leaveSelectedIndex, 1);
          $('#leaveStatusModal').modal('hide');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
  getDashboardCharts(): any {
    this.ngxLoader.start();
    let dates = {};
    dates = {
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
    this.dashboardService.chartsData(dates).subscribe(
      (data) => {
        // this.dashboardData = data;
        // this.statisticsData = data.total_stats;
        // this.opdvsEmergencyChart = data.line_chart;
        // this.patientTypeChart = data.patient_type_wise_income;
        // this.patientGenderWiseChart = data.gender_wise_patients;
        // this.dashboardTopTenLab = data.top_department_sales;
        // this.dashboardTopLabServices = data.top_10_lab_services;
        // this.dashboardRadiology = data.top_10_radiology_services;
        // this.patientVisitWise = data.patient_age_wise;
        //
        // this.initOPDvsEmergency(this.opdvsEmergencyChart);
        this.initDasboardPatinetTypeWiseIncome(data.patient_type_wise_income);
        // this.initDashboardGenderWiseIncome(this.patientGenderWiseChart);
        this.initDashboardTopDeparts(data.top_department_sales);
        // this.initDashboardLabServices(this.dashboardTopLabServices);
        // this.initDashboardRadiology(this.dashboardRadiology);
        // this.getOpdLineAgeWisePatientVisits(this.patientVisitWise.data);
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.success('Data not found');
        // toastr.error(err.error.error);
        this.ngxLoader.stop();
      },
    );
  }
  initDashboardTopDeparts(data): any {
    Highcharts.chart('TopDepart-Chart', {
      chart: {
        type: 'column',
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        labelStyle: {
          display: 'none',
        },
        selected: 0,
        inputEnabled: false,
        allButtonsEnabled: false,
        buttons: [],
      },
      navigator: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },
      credit: {
        enabled: false,
      },
      xAxis: {
        categories: data.labels.labels,
        crosshair: true,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: 'Top Departments',
          type: 'column',
          showInLegend: false,
          data: data.data.counts,
        },
      ],
    });
  }
  initDasboardPatinetTypeWiseIncome(data): any {
    Highcharts.chart('patientType-Chart', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      navigator: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Patient Type',
          colorByPoint: true,
          data: data.data,
        },
      ],
    });
  }

  logout(): any {
    this.ngxLoader.start();
    this.authService
      .logOutv2(localStorage.getItem('auth_token'))
      .subscribe((data) => {
        localStorage.clear();
        this.shareDataService.changeMessage([]);
        this.router.navigate(['login-v2']);
        this.ngxLoader.stop();
        localStorage.setItem('is_session_expired', 'true');
      });
  }
  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.getTransactionDetailsData();
  }
  loadPage(event): any {
    this.getTransactionDetailsData();
  }

  ngOnInit(): void {
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
    }, 500);
    $('.selectpicker').selectpicker('');
    this.getDashboardCounts('1', 1);
    this.getLeaveRequest();
    this.getDashboardCounts('1', 3);
  }
}
