<form [formGroup]="rosterControls" (ngSubmit)="onSubmit()">
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1 w-100">
          <!--begin::Page Heading-->
          <div
            class="d-flex align-items-baseline justify-content-between flex-wrap mr-5 w-100"
          >
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">
              {{ screenName }}
            </h5>
            <!--end::Page Title-->
          </div>
          <!--end::Page Heading-->
        </div>
        <!--end::Info-->
      </div>
    </div>
    <!--end::Subheader-->

    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid main-content">
      <!--begin::Container-->
      <!--    container-xl container-xxl-->
      <div class="container-fluid">
        <!--begin::Leave Card-->
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div
              class="d-flex align-items-center justify-content-between flex-grow-1 mb-3"
            >
              <div class="col-3">
                <div
                  class="card-title align-items-start flex-column justify-content-center w-100"
                >
                  <h6 class="card-label text-dark text-left w-100">
                    Show Leaves
                  </h6>
                </div>
              </div>
              <div class="col-9">
                <div class="radio-btns">
                  <div>
                    <input
                      type="radio"
                      id="in-process"
                      name="show_leaves"
                      formControlName="show_leaves"
                      [value]="'in-process'"
                    />
                    <label for="in-process">In-process</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="approved"
                      name="show_leaves"
                      formControlName="show_leaves"
                      [value]="'approved'"
                    />
                    <label for="approved">Approved</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="both"
                      name="show_leaves"
                      formControlName="show_leaves"
                      [value]="'both'"
                    />
                    <label for="both">Both</label>
                  </div>
                </div>
                <span class="text-muted font-weight-bold text-muted d-block"
                  >This control is for Roster Screen, to show In-process,
                  approved or both leaves</span
                >
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between flex-grow-1"
            >
              <div class="col-3">
                <div
                  class="card-title align-items-start flex-column justify-content-center w-100"
                >
                  <h6 class="card-label text-dark text-left w-100">
                    Copy Roster
                  </h6>
                </div>
              </div>
              <div class="col-9">
                <div class="radio-btns">
                  <div>
                    <input
                      type="radio"
                      id="replace"
                      name="copy_roster"
                      formControlName="copy_roster"
                      [value]="'replace'"
                    />
                    <label for="replace">Replace</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="append"
                      name="copy_roster"
                      formControlName="copy_roster"
                      [value]="'append'"
                    />
                    <label for="append">Append</label>
                  </div>
                </div>
                <span class="text-muted font-weight-bold text-muted d-block"
                  >This control is for Roster Screen, to append or replace
                  shifts while using copy roster</span
                >
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between flex-grow-1"
            >
              <div class="col-3">
                <div
                  class="card-title align-items-start flex-column justify-content-center w-100"
                >
                  <h6 class="card-label text-dark text-left w-100">
                    Employee Attendence Report (Status)
                  </h6>
                </div>
              </div>
              <div class="col-9">
                <div class="radio-btns">
                  <div>
                    <input
                      type="radio"
                      id="leave"
                      name="leave_shift"
                      formControlName="leave_shift"
                      [value]="'leave'"
                    />
                    <label for="leave">Leave</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="shift"
                      name="leave_shift"
                      formControlName="leave_shift"
                      [value]="'shift'"
                    />
                    <label for="shift">Shift</label>
                  </div>
                </div>
                <span class="text-muted font-weight-bold text-muted d-block"
                  >This control is for Employee Attendence Report (Status)
                  Screen, to set the priority of leave or shift</span
                >
              </div>
            </div>
          </div>
        </div>
        <!--end::Leave Card-->
        <div class="submit-btn text-right">
          <button class="btn btn-sm btn-primary" type="submit">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
