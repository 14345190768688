<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">{{ screenName }}</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card card-custom">
        <div class="card-body">
          <form [formGroup]="labServicesForm" (ngSubmit)="searchInvoices()">
            <div class="row">
              <div class="col-md-2 col-sm-3 col-4">
                <div class="form-group">
                  <label>From Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="fromDate"
                    data-toggle="datetimepicker"
                    data-target="#fromDate"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <div class="form-group">
                  <label>To Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="toDate"
                    data-target="#toDate"
                    data-toggle="datetimepicker"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <label>Department</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm selectpicker"
                      id="department"
                      formControlName="department_id"
                      data-dropup-auto="false"
                      data-live-search="true"
                    >
                      <option value="" disabled>Select</option>
                      <option value="" *ngIf="subDepartments.length > 0">
                        All
                      </option>
                      <option
                        [value]="department.id"
                        *ngFor="let department of subDepartments"
                      >
                        {{ department.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <label>Services</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm selectpicker"
                      id="testId"
                      formControlName="test_id"
                      data-dropup-auto="false"
                      data-live-search="true"
                    >
                      <option value="" disabled>Select</option>
                      <option value="" *ngIf="labServices.length > 0">
                        All
                      </option>
                      <ng-container *ngFor="let s of labServices">
                        <option [value]="s.assignable_id">{{ s.name }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <label>MRN</label>
                <div class="form-group">
                  <div class="d-block">
                    <input
                      class="form-control form-control-sm"
                      formControlName="patient_mrn"
                    />
                  </div>
                </div>
              </div>

              <!--add button-->
              <div class="col-md-2 col-sm-3 col-4 text-right">
                <div class="form-group">
                  <div class="d-block">
                    <button
                      type="submit"
                      class="btn btn-primary btn-sm cus_btm"
                      ngbTooltip="Search Result"
                      placement="bottom"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card card-custom gutter-b mt-7">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Table-->

          <div class="row">
            <div class="col-12 text-right">
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-primary btn-sm cus_btm"
                  *ngIf="pageDataList.length > 0"
                  (click)="printReport()"
                >
                  Print Report
                </button>
              </div>
            </div>
          </div>

          <div>
            <div class="table-responsive position-relative">
              <table
                class="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_3"
              >
                <thead>
                  <tr>
                    <th style="width: 20px"></th>
                    <th>Lab Id</th>
                    <th>Date/Time</th>
                    <th>Test Name</th>
                    <th>Department</th>
                    <th>MRN#</th>
                    <th>Patient Name</th>
                    <th>Specimen</th>
                    <th>Sample</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let element of pageDataList; let i = index"
                    [ngClass]="{
                      returned: element.rejected === true,
                      urgent: element.urgent === true
                    }"
                  >
                    <td
                      [ngClass]="{
                        'ipd-btn': element.ipd === true,
                        'opd-btn': element.ipd === false
                      }"
                    ></td>
                    <td>{{ element.lab_id }}</td>
                    <td>
                      <span *ngIf="element.status === 'Open'">{{
                        element.created_at | date: "dd-MM-yyyy h:mm a"
                      }}</span>
                      <span *ngIf="element.status === 'Collected'">{{
                        element.collecting_time | date: "dd-MM-yyyy h:mm a"
                      }}</span>
                      <span *ngIf="element.status === 'Received'">{{
                        element.receiving_time | date: "dd-MM-yyyy h:mm a"
                      }}</span>
                      <span *ngIf="element.status === 'Unverified'">{{
                        element.result_entry_time | date: "dd-MM-yyyy h:mm a"
                      }}</span>
                      <span *ngIf="element.status === 'Verified'">{{
                        element.verification_time | date: "dd-MM-yyyy h:mm a"
                      }}</span>
                      <span *ngIf="element.status === 'Delivered'">{{
                        element.delivered_time | date: "dd-MM-yyyy h:mm a"
                      }}</span>
                    </td>
                    <td>{{ element.test }}</td>
                    <td>{{ element.department_name }}</td>
                    <td>{{ element.patient_mrn }}</td>
                    <td>{{ element.patient_name }}</td>
                    <td>{{ element.specimen_name }}</td>
                    <td>{{ element.sample_taken_from_name }}</td>
                    <td>
                      <span
                        *ngIf="element.status === 'Open'; else notShowContent"
                      >
                        Phlebotomy
                      </span>
                      <ng-template #notShowContent>
                        {{ element.status }}
                      </ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
            <!--pagination-->
            <div class="pagination_main">
              <ngb-pagination
                [(page)]="page"
                (pageChange)="loadPage(page)"
                [pageSize]="perPage"
                [collectionSize]="totalPages"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              ></ngb-pagination>
              <div class="dropdown">
                <a
                  class="btn dropdown-toggle btn-light btn-sm"
                  role="button"
                  id="pagination_btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="page_count">
                    {{ perPage }}
                  </span>
                  <span class="count_count_icon"></span>
                </a>
                <div class="dropdown-menu" aria-labelledby="pagination_btn">
                  <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                    >25</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                    >50</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                    >75</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                    >100</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit('')"
                    >All</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</div>
