<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div
    class="subheader py-2 py-lg-6 subheader-solid d-print-none"
    id="kt_subheader"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-baseline justify-content-between flex-wrap mr-5 w-100"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <div class="dropdown dropdown-inline mr-2">
            <button
              type="button"
              class="btn btn-light-primary font-weight-bolder dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="svg-icon svg-icon-md">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Design/PenAndRuller.svg-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                      fill="#000000"
                      opacity="0.3"
                    ></path>
                    <path
                      d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                      fill="#000000"
                    ></path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
              Import
            </button>
            <!--begin::Dropdown Menu-->
            <div
              class="dropdown-menu dropdown-menu-sm dropdown-menu-right"
              style=""
            >
              <!--begin::Navigation-->
              <ul class="navi flex-column navi-hover py-2">
                <li
                  class="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2"
                >
                  Choose an option:
                </li>
                <li class="navi-item">
                  <a
                    class="navi-link"
                    data-toggle="modal"
                    data-target="#csvImportModal"
                  >
                    <span class="navi-icon">
                      <i class="la la-file-text-o"></i>
                    </span>
                    <span class="navi-text">xlsx</span>
                  </a>
                </li>
              </ul>
              <!--end::Navigation-->
            </div>

            <button class="btn btn-light-primary ml-3" (click)="printReport()">
              Print
            </button>

            <!--end::Dropdown Menu-->
          </div>
          <!--          <button class="btn btn-sm btn-primary" [routerLink]="['/roster/shifts-detail']"><i class="fas fa-long-arrow-alt-left"></i> Back</button>-->
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::Card-->
      <div class="card card-custom gutter-b d-print-none">
        <div class="card-body">
          <div class="row roster-bar">
            <!--Select Month-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Select Month: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm month"
                  placeholder="Select Month"
                  readonly="readonly"
                  [(ngModel)]="month"
                />
              </div>
            </div>
            <!--Department-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="d-block form-group">
                <label>Department: <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  id="department"
                  name="department"
                  [disabled]="!isShowAttendance"
                  (change)="getEmployeesOfDepartment($event.target.value)"
                  [(ngModel)]="department"
                >
                  <option value="">Select Department</option>
                  <ng-container *ngFor="let dep of depart">
                    <option [value]="dep.id">{{ dep.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--Employee Of Department-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="d-block form-group">
                <label>Employee:<span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  id="employeeDepartmentList"
                  name="employee"
                  [disabled]="!isShowAttendance"
                  [(ngModel)]="employeesOfSelectedDepartment"
                >
                  <option value="" [disabled]="true">Select Employee</option>
                  <ng-container *ngFor="let emp of employeesOfDepart">
                    <option [value]="emp.id">
                      {{ emp?.employee_file_no }} -{{ emp?.first_name }}
                      {{ emp?.last_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--Location-->
            <!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
            <!--              <div class="d-block form-group">-->
            <!--                <label>Location:</label>-->
            <!--                <select class="form-control form-control-sm selectpicker" data-live-search="true" id="selectedLocation" name="selectedLocation" [(ngModel)]="selectedLocation">-->
            <!--                  <option value="">Select Location</option>-->
            <!--                  <ng-container *ngFor="let location of locationData">-->
            <!--                    <option [value]="location.id">{{location?.name}}</option>-->
            <!--                  </ng-container>-->
            <!--                </select>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--Search-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="d-block form-group">
                <button
                  class="btn btn-light-primary btn-sm"
                  (click)="appendArray()"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <!--shifts-->
        </div>
      </div>
      <!--end::Card-->
      <div class="card card-custom gutter-b" *ngIf="employeeRoasterData.length">
        <div class="card-body">
          <!--        <div *ngIf="list?.length > 0">-->
          <div class="mb-6 d-none d-print-block">
            <div class="d-flex">
              <div style="width: 85%">
                <!-- header table -->
                <table class="table">
                  <tbody>
                    <tr>
                      <td style="width: 15%; padding: 0" class="border-top-0">
                        <img
                          [src]="settingData?.logo?.url"
                          style="width: auto; height: 106px"
                        />
                      </td>
                      <td style="width: 85%" class="border-top-0">
                        <div class="text-center">
                          <h3>{{ settingData?.title }}</h3>
                        </div>
                        <div class="font-size-h5 text-center">
                          <h5>{{ settingData?.address }}</h5>
                        </div>
                        <div class="text-center">
                          <h3>
                            Attendance Report Of
                            {{
                              employeeDetails?.first_name +
                                " " +
                                employeeDetails?.last_name
                            }}
                            {{ employeeDetails?.employee_file_no }}
                          </h3>
                        </div>
                        <div class="text-center">
                          <h3>
                            {{ employeeDetails?.department?.title }} of
                            {{ month }}
                          </h3>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="table-responsive position-relative print-table">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 10%">Date</th>
                  <th style="width: 10%">Shift</th>
                  <th style="width: 10%">Check In</th>
                  <th style="width: 10%">Check In Status</th>
                  <th style="width: 15%">Check Out</th>
                  <th style="width: 15%">Check Out Status</th>
                  <th style="width: 10%">Total Time</th>
                  <th style="width: 10%">Status</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let data of employeeRoasterData; let i = index"
                >
                  <ng-container *ngFor="let subData of data?.attendance">
                    <tr>
                      <td>{{ data.date }}</td>
                      <td>{{ subData?.shift?.name }}</td>
                      <td>
                        {{ subData?.check_in_time }} <br /><span
                          style="font-size: 11px"
                          >Expected: {{ subData?.shift?.time_from }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="label label-lg label-primary label-inline"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_in_color"
                          *ngIf="subData?.check_in_status === 1"
                          >On Time</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_in_color"
                          *ngIf="subData?.check_in_status === 2"
                          >Early In</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_in_color"
                          *ngIf="subData?.check_in_status === 3"
                          >Late In</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_in_color"
                          *ngIf="subData?.check_in_status === 4"
                          >Absent</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_in_color"
                          *ngIf="subData?.check_in_status === 5"
                          >Pending</span
                        >
                      </td>
                      <td>
                        {{ subData?.check_out_time }} <br /><span
                          style="font-size: 11px"
                          >Expected: {{ subData?.shift?.time_to }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_out_color"
                          *ngIf="subData?.check_out_status === 1"
                          >On Time</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_out_color"
                          *ngIf="subData?.check_out_status === 2"
                          >Late Out</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_out_color"
                          *ngIf="subData?.check_out_status === 3"
                          >Early Out</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_out_color"
                          *ngIf="subData?.check_out_status === 4"
                          >Absent</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_out_color"
                          *ngIf="subData?.check_out_status === 5"
                          >Pending</span
                        >
                      </td>
                      <td>
                        {{ subData?.works_hours }}
                        <br /><span style="font-size: 11px"
                          >Expected: {{ subData?.estimated_hours }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.color"
                          *ngIf="subData?.status === 0"
                          >A</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.color"
                          *ngIf="subData?.status === 1"
                          >P</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.color"
                          *ngIf="subData?.status === 2"
                          >A</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff; background-color: yellowgreen"
                          *ngIf="subData?.status === 3"
                          >L</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff; background-color: steelblue"
                          *ngIf="subData?.status === 4"
                          >O</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff; background-color: darkred"
                          *ngIf="subData?.status === 5"
                          >G</span
                        >
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
            <!--            <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal-->
<div
  class="modal fade"
  id="csvImportModal"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Import File</h5>
        <button type="button" class="close" (click)="removeCsvImport()">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <input
          type="file"
          (change)="fileUploadFunc($event)"
          accept=".xlsx,.xls,.csv"
        />
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-light-primary font-weight-bold"
          (click)="removeCsvImport()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary font-weight-bold"
          (click)="submitCsvImport()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal-->
<!--<div class="modal fade" id="promissionsAlertModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
<!--  <div class="modal-dialog" role="document">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-body">-->
<!--        <div class="text-center">-->
<!--          <img src="../../../assets/media/error/allert.png">-->
<!--          <p class="font-weight-bold font-size-h5 text-dark-75 mt-3">No Record Found!</p>-->
<!--          &lt;!&ndash;          <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
