import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class LabParameterService {
  constructor(private http: HttpClient) {}

  create(labParameterService: any): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_assessments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_assessment: labParameterService,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createV2(labParameterService: any, access_ranges: any): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/lab_assessments/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_assessment: labParameterService,
          access_ranges: access_ranges,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  update(labParameterService: any, id): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_assessments/` +
          id +
          '/update_lab_assessment?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_assessment: labParameterService,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateV2(
    labParameterService: any,
    access_ranges: any,
    id,
    newParameter,
    updateParameter,
    parameterId,
    lab_investigation_id,
  ): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/api/update_lab_assessment/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_assessment: labParameterService,
          access_ranges: access_ranges,
          is_created: newParameter,
          is_updated: updateParameter,
          parameterId: parameterId,
          lab_investigation_id: lab_investigation_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetch_all_parameters(
    currentPage: any,
    perPage: any,
    code: any,
    name: any,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/lab_assessments/get_lab_assessments_in_medical_unit?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&code=' +
          code +
          '&name=' +
          name,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetch_all_parametersV2(
    currentPage: any,
    perPage: any,
    code: any,
    name: any,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/get_lab_assessments_in_medical_unit/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&code=' +
          code +
          '&name=' +
          name,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  saveAccessRanges(
    allAccessRanges: any,
    lab_parameter_id: number,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_assessments/save_access_ranges?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          access_ranges: allAccessRanges,
          lab_assessment_id: lab_parameter_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  search_lab_parameter_by_code(parameterCode: string): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/lab_assessments/search_by_code?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&code=' +
          parameterCode,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  search_lab_parameter_by_id(parameterId: string): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/lab_assessments/search_by_code?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          parameterId +
          '&type=id',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  search_lab_parameter_by_idV2(parameterId: string): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/search_by_code/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          parameterId +
          '&type=id',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getLabParameter(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/report/lab_param_history/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
