<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flexbrCode-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Radiology Report</h5>
          <!--          tab headings-->

          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-light-primary btn-sm" (click)="printReport()">
          Print
        </button>
        <button
          class="btn btn-light-primary btn-sm ml-5"
          (click)="downloadDocReport()"
        >
          Download .docx
        </button>
        <!-- <button class="btn btn-primary btn-sm ml-2" (click)="goBack()">Back</button> -->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="card pdf-card" id="reports">
        <div class="card-body">
          <table style="width: 100%">
            <thead>
              <tr>
                <th>
                  <!--                <div *ngIf="showHeader === false || showHeader === 'false'" style="height: 250px"></div>-->
                  <!--                <div class="table-header" *ngIf="showHeader === true || showHeader === 'true'">-->
                  <div class="table-header">
                    <div style="width: 100%" class="clearfix">
                      <div style="width: 85%; float: left">
                        <div
                          *ngIf="showHeader === false || showHeader === 'false'"
                          [ngStyle]="{ height: headerHeight + 'px' }"
                        ></div>
                        <table
                          class="table"
                          *ngIf="showHeader === true || showHeader === 'true'"
                        >
                          <tbody>
                            <tr class="borders">
                              <td style="width: 15%; padding: 0">
                                <img
                                  [src]="settingData?.logo?.url"
                                  *ngIf="
                                    hospitalName === 'nationalHospital' ||
                                    hospitalName === 'cloudSetting'
                                  "
                                  style="width: auto; height: 190px"
                                />
                                <img
                                  [src]="settingData?.logo?.url"
                                  *ngIf="
                                    hospitalName !== 'nationalHospital' &&
                                    hospitalName !== 'cloudSetting'
                                  "
                                  style="width: auto; height: 106px"
                                />
                              </td>
                              <td style="width: 85%; vertical-align: middle">
                                <div class="font-size-h2 text-center">
                                  <h3 class="hosp-name">
                                    {{ settingData?.title }}
                                  </h3>
                                </div>
                                <div class="font-size-h3 text-center">
                                  <h5 class="hosp-address">
                                    {{ settingData?.address }}
                                  </h5>
                                </div>
                                <!--                          <div class="text-center"><h3>INVOICE </h3></div>-->
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="w-100 info_table mb-2">
                          <tbody>
                            <tr>
                              <td style="width: 45%">
                                <h2
                                  *ngIf="
                                    patientRadiologyData?.patient_service
                                      ?.patient
                                  "
                                  class="m-0 line-clamp pdf-report-Patient-name-area mr-5"
                                >
                                  {{
                                    patientRadiologyData?.patient_service
                                      ?.patient?.title || "N/A" | titlecase
                                  }}
                                  {{
                                    patientRadiologyData?.patient_service
                                      ?.patient?.full_name || "N/A" | titlecase
                                  }}
                                  {{
                                    patientRadiologyData?.patient_service
                                      ?.patient?.age + ","
                                  }}
                                  {{
                                    patientRadiologyData?.patient_service?.patient?.gender.charAt(
                                      0
                                    ) | titlecase
                                  }}
                                </h2>
                              </td>
                              <td style="width: 55%">
                                <h2 class="m-0 pdf-report-Patient-name-area">
                                  MRN#{{
                                    patientRadiologyData?.patient_service
                                      ?.patient?.mrn || "N/A"
                                  }}
                                </h2>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="w-100 info_table">
                          <tbody>
                            <tr>
                              <td
                                style="width: 20%"
                                class="pdf-report-Patient-heading"
                              >
                                <strong>Father/Husband:</strong>
                              </td>
                              <td
                                style="width: 20%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                              >
                                <span
                                  *ngIf="
                                    patientRadiologyData?.patient_service
                                      ?.patient?.father_name
                                  "
                                  >{{
                                    patientRadiologyData?.patient_service
                                      ?.patient?.father_name || "N/A"
                                      | titlecase
                                  }}</span
                                >
                                <span
                                  *ngIf="
                                    !patientRadiologyData?.patient_service
                                      ?.patient?.father_name
                                  "
                                  >N/A</span
                                >
                              </td>
                              <td style="width: 9%"></td>
                              <td
                                style="width: 15%"
                                class="pdf-report-Patient-heading"
                              >
                                <strong>Reg. Date:</strong>
                              </td>

                              <td
                                style="width: 36%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                              >
                                {{
                                  patientRadiologyData?.patient_service?.patient
                                    ?.created_at || "N/A"
                                    | date: "dd-MM-yyyy h:mm a"
                                }}
                              </td>

                              <td style="width: 0%"></td>
                            </tr>
                            <tr>
                              <td style="width: 20%" class="font-size-content">
                                <strong>Contact:</strong>
                              </td>
                              <td
                                style="width: 20%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                              >
                                {{
                                  patientRadiologyData?.patient_service?.patient
                                    ?.phone1 || "N/A"
                                }}
                              </td>
                              <td style="width: 9%"></td>
                              <td
                                style="width: 15%"
                                class="pdf-report-Patient-heading"
                              >
                                <strong>Department:</strong>
                              </td>

                              <td
                                style="width: 36%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                              >
                                {{
                                  patientRadiologyData?.patient_service
                                    ?.department?.title || "N/A"
                                }}
                              </td>
                              <td style="width: 0%"></td>
                            </tr>
                            <tr>
                              <td
                                style="width: 20%"
                                class="pdf-report-Patient-heading"
                              >
                                <strong>CNIC:</strong>
                              </td>
                              <td
                                style="width: 20%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                              >
                                {{
                                  patientRadiologyData?.patient_service?.patient
                                    ?.patient_nic || "N/A"
                                }}
                              </td>
                              <td style="width: 9%"></td>
                              <td
                                style="width: 15%"
                                class="pdf-report-Patient-heading"
                              >
                                <strong>Consultant:</strong>
                              </td>
                              <td
                                style="width: 20%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                                *ngIf="
                                  patientRadiologyData?.patient_service
                                    ?.referring_doctor_name
                                "
                              >
                                {{
                                  patientRadiologyData?.patient_service
                                    ?.referring_doctor_name
                                }}
                              </td>
                              <td
                                style="width: 36%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                                *ngIf="
                                  !patientRadiologyData?.patient_service
                                    ?.referring_doctor_name
                                "
                              >
                                N/A
                              </td>
                              <td style="width: 0%"></td>
                            </tr>
                            <tr>
                              <td
                                style="width: 20%"
                                class="pdf-report-Patient-heading"
                              >
                                <strong>Address:</strong>
                              </td>
                              <td
                                style="width: 20%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                                *ngIf="
                                  patientRadiologyData?.patient_service?.patient
                                    ?.address1
                                "
                              >
                                {{
                                  patientRadiologyData?.patient_service?.patient
                                    ?.address1
                                }}
                              </td>
                              <td
                                style="width: 20%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                                *ngIf="
                                  !patientRadiologyData?.patient_service
                                    ?.patient?.address1
                                "
                              >
                                N/A
                              </td>
                              <td style="width: 9%"></td>
                              <td
                                style="width: 15%"
                                class="pdf-report-Patient-heading"
                              >
                                <strong>Created at:</strong>
                              </td>

                              <td
                                style="width: 36%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                              >
                                {{
                                  patientRadiologyData?.patient_service
                                    ?.created_at || "N/A"
                                    | date: "dd-MM-yyyy h:mm a"
                                }}
                              </td>
                              <td style="width: 0%"></td>
                            </tr>
                            <tr>
                              <td style="" class="pdf-report-Patient-heading">
                                <strong>Performed at:</strong>
                              </td>
                              <td
                                style=""
                                class="font-weight-normal pdf-report-patient-heading-content"
                              >
                                {{
                                  patientRadiologyData?.patient_service
                                    ?.acknowledged_date || "N/A"
                                    | date: "dd-MM-yyyy h:mm a"
                                }}
                              </td>
                              <td style="width: 9%"></td>
                              <td style="" class="pdf-report-Patient-heading">
                                <strong>Reported at:</strong>
                              </td>
                              <td
                                style=""
                                class="font-weight-normal pdf-report-patient-heading-content"
                              >
                                {{
                                  patientRadiologyData?.patient_service
                                    ?.finalsign_date || "N/A"
                                    | date: "dd-MM-yyyy h:mm a"
                                }}
                              </td>
                              <!--                          <td style="" class="font-size-content"><strong>Reported at:</strong></td>-->
                              <!--                          <td style="" class="font-weight-normal">12/02/2022</td>-->
                            </tr>
                            <tr>
                              <td
                                style="width: 20%"
                                class="pdf-report-Patient-heading"
                              >
                                <strong>Patient Type:</strong>
                              </td>
                              <td
                                style="width: 20%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                              >
                                {{
                                  patientRadiologyData?.patient_service
                                    ?.patient_type?.name || "N/A"
                                }}
                              </td>
                              <td style="width: 9%"></td>
                              <td
                                style="width: 15%"
                                class="pdf-report-Patient-heading"
                              >
                                <strong>Accession #:</strong>
                              </td>
                              <td
                                style="width: 36%"
                                class="font-weight-normal pdf-report-patient-heading-content"
                              >
                                {{
                                  patientRadiologyData?.patient_service
                                    ?.accession_number || "N/A"
                                }}
                              </td>
                              <td style="width: 0%"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        style="width: 15%; float: left; text-align: center"
                        class="barcodeSection"
                      >
                        <div
                          *ngIf="showHeader === true || showHeader === 'true'"
                        >
                          <div style="width: 100%; height: 180px">
                            <img
                              class="qrCode"
                              style="height: 100%; width: 100%"
                              *ngIf="
                                patientRadiologyData?.patient_service?.patient
                                  ?.qrcode_url
                              "
                              [src]="
                                patientRadiologyData?.patient_service?.patient
                                  ?.qrcode_url?.url
                              "
                              alt=""
                            />
                          </div>
                          <!--                <img src="../assets/media/pdf-images/qr.png" alt="" style="max-height: 100px">-->
                          <img
                            class="brCode"
                            *ngIf="
                              patientRadiologyData?.patient_service?.patient
                                ?.barcode_url
                            "
                            [src]="
                              patientRadiologyData?.patient_service?.patient
                                ?.barcode_url?.url
                            "
                          />
                          <div
                            class="font-weight-bolder font-size-h3 mrnCode"
                            id="mrn"
                          >
                            {{
                              patientRadiologyData?.patient_service?.patient
                                ?.mrn
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="clearfix"
                      style="
                        border-top: 3px solid #000000;
                        height: 15px;
                        width: 100%;
                      "
                    ></div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <div>
                <h2 class="mt-8 font-size-service-heading">
                  {{ patientRadiologyData?.patient_service?.service?.name }}
                </h2>
                <ng-container
                  *ngIf="patientRadiologyData?.patient_service?.history"
                >
                  <h2 class="mt-8 font-size-service-name">
                    Indication For exam
                  </h2>
                  <div class="radiology-report-area">
                    {{ patientRadiologyData?.patient_service?.history }}
                  </div>
                </ng-container>
                <ng-container *ngIf="patientRadiologyData?.report">
                  <h2 class="mt-8 font-size-service-name">Result</h2>
                  <div class="radiology-report-area" id="resultHTML"></div>
                </ng-container>
                <ng-container *ngIf="patientRadiologyData?.impression">
                  <h2 class="mt-8 font-size-service-name">Impression</h2>
                  <div class="radiology-report-area" id="impressionHTML"></div>
                </ng-container>
                <ng-container *ngIf="patientRadiologyData?.addendum">
                  <h2 class="mt-8 font-size-service-name">Addendum</h2>
                  <div class="radiology-report-area" id="addendumHTML"></div>
                </ng-container>
              </div>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div class="">
                    <div
                      [ngStyle]="{ height: footerHeight + 'px' }"
                      *ngIf="showFooter === false || showFooter === 'false'"
                    ></div>
                    <div
                      class="footer-bottom"
                      *ngIf="showFooter === true || showFooter === 'true'"
                    >
                      <div class="d-flex justify-content-between container">
                        <div class="">
                          <div>
                            <img
                              *ngIf="
                                hospitalName === 'cloudSetting' &&
                                  patientRadiologyData?.verified_by_id === 935;
                                else showAuthenticated
                              "
                              src="../../../../../assets/images/amjad-iqbal.png"
                              style="width: auto; height: 60px"
                            />
                            <ng-template #showAuthenticated>
                              <span
                                ><strong
                                  >Dictated & Authenticated By:
                                </strong></span
                              >
                            </ng-template>
                          </div>
                          <div>
                            <span>{{
                              patientRadiologyData?.verified_by_name
                            }}</span>
                          </div>
                          <div>
                            <span>{{
                              patientRadiologyData?.verified_by_qualification
                            }}</span>
                          </div>
                          <div>
                            <span>{{
                              patientRadiologyData?.verified_by_designation
                            }}</span>
                          </div>
                          <div>
                            <span
                              ><strong>Date & Time: </strong
                              >{{
                                patientRadiologyData?.verification_date
                                  | date: "EE, MMM dd, yy, h:mm:ss a"
                              }}</span
                            >
                          </div>

                          <div
                            *ngIf="
                              hospitalName !== 'cloudSetting' &&
                              patientRadiologyData?.verified_by_id !== 935
                            "
                          >
                            <span
                              ><strong
                                >No Signature(s) required, Electronically
                                Verified</strong
                              ></span
                            >
                          </div>
                        </div>
                        <div
                          class=""
                          *ngIf="hospitalName === 'nationalHospital'"
                        >
                          <p class="mb-0"><strong>Printed by:</strong></p>
                          <p class="mb-0">{{ uName }}</p>
                          <p class="mb-0">{{ masterUser }}</p>
                          <p>
                            {{ todayDate | date: "EE, MMM dd, yy, h:mm:ss a" }}
                          </p>
                        </div>
                        <!--          </div>-->
                      </div>
                      <!--            <p class="text-center"><b>*This is electronically generated report, no signature required.</b></p>-->
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!--end:: Content-->
  </div>
  <!--end::Container-->
</div>
<!--end::Entry-->
<!--end::Content-->
