import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PdfServicesService } from '../../../../services/pdf-services.service';
import { LabTestService } from '../../../../services/lab-test.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-lab-tracking-report-v2',
  templateUrl: './lab-tracking-report-v2.component.html',
  styleUrls: ['./lab-tracking-report-v2.component.css'],
})
export class LabTrackingReportV2Component implements OnInit {
  fromDate: any;
  toDate: any;
  departmentId: any;
  patientType: any;
  labStatus: any;
  searchType: any;
  searchName: any;
  settingData: any;
  labTrackingData: any;

  constructor(
    private route: ActivatedRoute,
    private pdfService: PdfServicesService,
    private labTestService: LabTestService,
    private ngxLoader: NgxUiLoaderService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.fromDate = params['from_date'] || '';
      this.toDate = params['to_date'] || '';
      this.departmentId = params['department_id'] || '';
      this.patientType = params['patient_type'] || '';
      this.labStatus = params['lab_status'] || '';
      this.searchType = params['search_type'] || '';
      this.searchName = params['search_name'] || '';
    });
    this.getSettingsData();
    this.getLabTrackingData();
  }

  getSettingsData(): any {
    this.pdfService.getMedicalUnitDetailsV2().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  printReport(): any {
    window.print();
  }

  getLabTrackingData(): any {
    this.ngxLoader.start();
    this.labTestService
      .fetchLabTrackingData(
        this.fromDate,
        this.toDate,
        this.departmentId,
        this.patientType,
        this.labStatus,
        this.searchType,
        this.searchName,
      )
      .subscribe(
        (data) => {
          this.labTrackingData = data.orders;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
}
