import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-rad-pdf-report',
  templateUrl: './rad-pdf-report.component.html',
  styleUrls: ['./rad-pdf-report.component.css'],
})
export class RadPdfReportComponent implements OnInit {
  medicalUnit = '';
  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.medicalUnit = params['medical_unit_id'];
    });
    if (this.medicalUnit) {
      localStorage.setItem('auth_token', '');
      localStorage.setItem('current_medical_unit_id', this.medicalUnit);
      localStorage.setItem('is_session_expired', 'false');
    }
  }

  ngOnInit(): void {}
}
