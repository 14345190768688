<div>
  <div class="mt-5">
    <div
      *ngIf="
        instructionDetails?.special_instructions &&
        instructionDetails?.special_instructions_show_top === true
      "
    >
      <h4 class="labTestData mt-5">
        {{ instructionDetails?.special_instructions_name }}
      </h4>
      <app-summernotes
        [id]="'special_instructions'"
        [htmlData]="instructionDetails?.special_instructions"
      >
      </app-summernotes>
    </div>
    <div
      *ngIf="
        instructionDetails?.protocol &&
        instructionDetails?.protocol_show_top === true
      "
    >
      <h4 class="labTestData mt-5">{{ instructionDetails?.protocol_name }}</h4>
      <app-summernotes
        [id]="'protocol'"
        [htmlData]="instructionDetails?.protocol"
      >
      </app-summernotes>
    </div>
    <div
      *ngIf="
        instructionDetails?.methodology &&
        instructionDetails?.methodology_show_top === true
      "
    >
      <h4 class="labTestData mt-5">
        {{ instructionDetails?.methodology_name }}
      </h4>
      <app-summernotes
        [id]="'methodology'"
        [htmlData]="instructionDetails?.methodology"
      >
      </app-summernotes>
    </div>
    <div
      *ngIf="
        instructionDetails?.performed_method &&
        instructionDetails?.performed_method_show_top === true
      "
    >
      <h4 class="labTestData mt-5">
        {{ instructionDetails?.performed_method_name }}
      </h4>
      <app-summernotes
        [id]="'performed_method'"
        [htmlData]="instructionDetails?.performed_method"
      >
      </app-summernotes>
    </div>
    <div
      *ngIf="
        instructionDetails?.interpretation &&
        instructionDetails?.interpretation_show_top === true
      "
    >
      <h4 class="labTestData mt-5">
        {{ instructionDetails?.interpretation_name }}
      </h4>
      <app-summernotes
        [id]="'interpretation'"
        [htmlData]="instructionDetails?.interpretation"
      >
      </app-summernotes>
    </div>
    <div
      *ngIf="
        instructionDetails?.comments &&
        instructionDetails?.comments_show_top === true
      "
    >
      <h4 class="labTestData mt-5">{{ instructionDetails?.comments_name }}</h4>
      <app-summernotes
        [id]="'comments'"
        [htmlData]="instructionDetails?.comments"
      >
      </app-summernotes>
    </div>
  </div>

  <div class="mb-5 mt-5" *ngIf="showLabTestName">
    <h4 class="secndData">
      <ng-container *ngIf="pageDataList?.group_name; else testName">
        <strong>{{ pageDataList?.group_name }}</strong>
      </ng-container>
      <ng-template #testName>
        <strong>{{ pageDataList?.test_name }}</strong>
      </ng-template>
    </h4>
  </div>
  <table class="w-100 tHeadFont mt-5" *ngIf="showResult">
    <thead>
      <tr>
        <th class="tHeaderSide tHeadFont" style="width: 20%">Test</th>
        <th class="tHeader tHeadFont" *ngIf="isRefShow" style="width: 20%">
          Reference value
        </th>
        <th class="tHeader tHeadFont" *ngIf="isUomShow" style="width: 10%">
          Unit
        </th>
        <th
          class="tHeader tHeadFont"
          style="line-height: 1"
          [ngStyle]="{ width: pageDataList?.previous_result ? '12%' : '50%' }"
        >
          <div>
            <span>Result</span>
          </div>
          <div
            *ngIf="pageDataList.verification_time && showLabTestName"
            style="line-height: 1; font-size: 12px"
          >
            <span style="font-weight: 400">{{
              pageDataList.verification_time | dateFormat
            }}</span>
            <br />
            <span style="font-weight: 400">{{
              pageDataList.verification_time | timeFormat
            }}</span>
          </div>
        </th>
        <th
          *ngIf="this.pageDataList?.previous_result"
          class="tHeader tHeadFont"
          style="width: 38%; line-height: 1"
        >
          Previous Result <br />
          <table class="w-100" *ngIf="showLabTestName">
            <tr>
              <td
                *ngFor="let date of historyDates"
                class=""
                style="font-weight: 400; line-height: 1; font-size: 12px"
                [ngStyle]="{ width: '33%' }"
              >
                {{ date | dateFormat }} <br />
                {{ date | timeFormat }}
              </td>
            </tr>
          </table>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let group of result; let i = index">
        <tr *ngIf="group.length > 0">
          <td colspan="5" class="pl-2 font-weight-bolder testName">
            {{
              group[0].super_heading === null || group[0].super_heading === ""
                ? "&nbsp;"
                : group[0].super_heading
            }}
          </td>
        </tr>
        <tr *ngFor="let lists of group; let z = index">
          <td class="pl-2">{{ lists?.caption }}</td>
          <td class="pl-2" *ngIf="lists?.range_comments && isRefShow">
            <span [innerHTML]="lists?.range_comments"></span>
          </td>
          <td class="pl-2" *ngIf="!lists?.range_comments && isRefShow">
            {{ lists?.range }}
          </td>
          <td class="pl-2" *ngIf="isUomShow">{{ lists?.unit }}</td>
          <td class="pl-2">
            <ng-container *ngIf="lists?.report_type === 'single'">
              <ng-container *ngIf="lists?.range_type === 'in_between'">
                <span
                  *ngIf="lists?.range_start; else inBetweenValueElse"
                  [ngClass]="
                    convertToNumber(lists?.value) >= lists?.range_start &&
                    lists?.value <= lists?.range_end
                      ? ''
                      : 'is-invalid-text resultBold'
                  "
                  >{{ lists?.value }}</span
                >
                <ng-template #inBetweenValueElse>
                  <span
                    [ngClass]="
                      convertToNumber(lists?.value) >=
                        convertToNumber(
                          lists?.range.replaceAll(' ', '').split('-')[0]
                        ) &&
                      convertToNumber(lists?.value) <=
                        convertToNumber(
                          lists?.range.replaceAll(' ', '')?.split('-')[1]
                        )
                        ? ''
                        : 'is-invalid-text resultBold'
                    "
                    >{{ lists?.value }}</span
                  >
                </ng-template>
              </ng-container>
              <ng-container *ngIf="lists?.range_type === 'less_then'">
                <span
                  *ngIf="lists?.range_end; else inLessThenValueElse"
                  [ngClass]="
                    convertToNumber(lists?.value) <= lists?.range_end
                      ? ''
                      : 'is-invalid-text resultBold'
                  "
                  >{{ lists?.value }}</span
                >
                <ng-template #inLessThenValueElse>
                  <span
                    [ngClass]="
                      convertToNumber(lists?.value) <=
                      convertToNumber(
                        lists?.range.replaceAll(' ', '').split('<')[1]
                      )
                        ? ''
                        : 'is-invalid-text resultBold'
                    "
                    >{{ lists?.value }}</span
                  >
                </ng-template>
              </ng-container>
              <ng-container *ngIf="lists?.range_type === 'greater_then'">
                <span
                  *ngIf="lists?.range_start; else inGreaterThenValueElse"
                  [ngClass]="
                    convertToNumber(lists?.value) >= lists?.range_start
                      ? ''
                      : 'is-invalid-text resultBold'
                  "
                  >{{ lists?.value }}</span
                >
                <ng-template #inGreaterThenValueElse>
                  <span
                    [ngClass]="
                      convertToNumber(lists?.value) <=
                      convertToNumber(
                        lists?.range.replaceAll(' ', '').split('>')[1]
                      )
                        ? ''
                        : 'is-invalid-text resultBold'
                    "
                    >{{ lists?.value }}</span
                  >
                </ng-template>
              </ng-container>
              <ng-container *ngIf="lists?.range_type === 'equal'">
                <span
                  *ngIf="lists?.range_start; else inEqualValueElse"
                  [ngClass]="
                    convertToNumber(lists?.value) === lists?.range_start
                      ? ''
                      : 'is-invalid-text resultBold'
                  "
                  >{{ lists?.value }}</span
                >
                <ng-template #inEqualValueElse>
                  <span
                    [ngClass]="
                      convertToNumber(lists?.value) <=
                      convertToNumber(
                        lists?.range.replaceAll(' ', '').split('=')[1]
                      )
                        ? ''
                        : 'is-invalid-text resultBold'
                    "
                    >{{ lists?.value }}</span
                  >
                </ng-template>
              </ng-container>
              <ng-container
                *ngIf="
                  lists?.range_type !== 'equal' &&
                  lists?.range_type !== 'greater_then' &&
                  lists?.range_type !== 'less_then' &&
                  lists?.range_type !== 'in_between'
                "
              >
                <span>{{ lists?.value }}</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="lists?.report_type === 'combo'">{{
              lists?.report_type_1_value
            }}</ng-container>
            <ng-container *ngIf="lists?.report_type === 'text'">{{
              lists?.heading
            }}</ng-container>
          </td>
          <td *ngIf="this.pageDataList?.previous_result" class="pl-2">
            <table class="w-100">
              <tr>
                <td
                  *ngFor="let hist of lists?.history"
                  [ngStyle]="{ width: '33%' }"
                >
                  <ng-container *ngIf="lists?.report_type === 'single'">
                    <ng-container *ngIf="lists?.range_type === 'in_between'">
                      <span
                        *ngIf="hist?.range_start; else inBetweenValueElse"
                        [ngClass]="
                          convertToNumber(hist?.test_value) >=
                            hist?.range_start &&
                          hist?.test_value <= hist?.range_end
                            ? ''
                            : 'is-invalid-text resultBold'
                        "
                        >{{ hist?.test_value }}</span
                      >
                      <ng-template #inBetweenValueElse>
                        <span
                          [ngClass]="
                            convertToNumber(hist?.test_value) >=
                              convertToNumber(
                                hist?.range.replaceAll(' ', '').split('-')[0]
                              ) &&
                            convertToNumber(hist?.test_value) <=
                              convertToNumber(
                                hist?.range.replaceAll(' ', '')?.split('-')[1]
                              )
                              ? ''
                              : 'is-invalid-text resultBold'
                          "
                          >{{ hist?.test_value }}</span
                        >
                      </ng-template>
                    </ng-container>
                    <ng-container *ngIf="lists?.range_type === 'less_then'">
                      <span
                        *ngIf="hist?.range_end; else inLessThenValueElse"
                        [ngClass]="
                          convertToNumber(hist?.test_value) <= hist?.range_end
                            ? ''
                            : 'is-invalid-text resultBold'
                        "
                        >{{ hist?.test_value }}</span
                      >
                      <ng-template #inLessThenValueElse>
                        <span
                          [ngClass]="
                            convertToNumber(hist?.test_value) <=
                            convertToNumber(
                              hist?.range.replaceAll(' ', '').split('<')[1]
                            )
                              ? ''
                              : 'is-invalid-text resultBold'
                          "
                          >{{ hist?.test_value }}</span
                        >
                      </ng-template>
                    </ng-container>
                    <ng-container *ngIf="lists?.range_type === 'greater_then'">
                      <span
                        *ngIf="hist?.range_start; else inGreaterThenValueElse"
                        [ngClass]="
                          convertToNumber(hist?.test_value) >= hist?.range_start
                            ? ''
                            : 'is-invalid-text resultBold'
                        "
                        >{{ hist?.test_value }}</span
                      >
                      <ng-template #inGreaterThenValueElse>
                        <span
                          [ngClass]="
                            convertToNumber(hist?.test_value) <=
                            convertToNumber(
                              hist?.range.replaceAll(' ', '').split('>')[1]
                            )
                              ? ''
                              : 'is-invalid-text resultBold'
                          "
                          >{{ hist?.test_value }}</span
                        >
                      </ng-template>
                    </ng-container>
                    <ng-container *ngIf="lists?.range_type === 'equal'">
                      <span
                        *ngIf="hist?.range_start; else inEqualValueElse"
                        [ngClass]="
                          convertToNumber(hist?.test_value) ===
                          hist?.range_start
                            ? ''
                            : 'is-invalid-text resultBold'
                        "
                        >{{ hist?.test_value }}</span
                      >
                      <ng-template #inEqualValueElse>
                        <span
                          [ngClass]="
                            convertToNumber(hist?.test_value) <=
                            convertToNumber(
                              hist?.range.replaceAll(' ', '').split('=')[1]
                            )
                              ? ''
                              : 'is-invalid-text resultBold'
                          "
                          >{{ hist?.test_value }}</span
                        >
                      </ng-template>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        lists?.range_type !== 'equal' &&
                        lists?.range_type !== 'greater_then' &&
                        lists?.range_type !== 'less_then' &&
                        lists?.range_type !== 'in_between'
                      "
                    >
                      <span>{{ lists?.test_value }}</span>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="lists?.report_type !== 'single'">
                    {{ hist?.test_value }}
                  </ng-container>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <table class="w-100 tHeadFont mt-5" *ngIf="showSutoffValue">
    <thead>
      <tr>
        <th class="tHeaderSide tHeadFont" style="width: 30%">Test</th>
        <th class="tHeader tHeadFont" style="width: 20%">Result</th>
        <th class="tHeader tHeadFont" style="width: 25%">Cutoff Value</th>
        <th class="tHeader tHeadFont" style="width: 25%">Patient Value</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let group of cutoffValue; let i = index">
        <tr *ngIf="group.length > 0">
          <td colspan="4" class="pl-2 font-weight-bolder testName">
            {{
              group[0].super_heading === null
                ? "&nbsp;"
                : group[0].super_heading
            }}
          </td>
        </tr>
        <tr *ngFor="let lists of group; let z = index">
          <td class="pl-2">{{ lists?.caption }}</td>
          <td class="pl-2">
            <ng-container *ngIf="lists?.report_type === 'single'">
              {{ lists?.value }}
            </ng-container>
            <ng-container *ngIf="lists?.report_type === 'combo'">{{
              lists?.report_type_1_value
            }}</ng-container>
            <ng-container *ngIf="lists?.report_type === 'text'">{{
              lists?.heading
            }}</ng-container>
          </td>
          <td class="pl-2">{{ lists?.cutoff_value }}</td>
          <td class="pl-2">{{ lists?.patient_value }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <table class="w-100 tHeadFont mt-5" *ngIf="showEditor">
    <ng-container *ngFor="let group of editor; let i = index">
      <tr *ngIf="group.length > 0">
        <th class="pl-2 font-weight-bolder testName">
          {{
            group[0].super_heading === "null"
              ? "&nbsp;"
              : group[0].super_heading
          }}
          &nbsp;
        </th>
      </tr>
      <tbody>
      <ng-container *ngFor="let lists of group; let z = index">
        <tr>
          <td style="background-color: #ffffff">
            <div><strong>{{lists?.caption}}: </strong></div>
          </td>
        </tr>
        <tr>
          <td style="background-color: #ffffff">
            <div id="editor{{ groupIndex + '' + i + '' + z }}"></div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </ng-container>
  </table>

<div class="mt-4" *ngIf="this.pageDataList?.report_type === 'Solana' && sampleResults">
  <h4 class="labTestData mt-5">RESULT INTERPRETATION</h4>
  <p style="font-size: 15px!important;" [innerHTML]="sampleResults"></p>
</div>

  <div class="mt-5">
    <div
      *ngIf="
        instructionDetails?.special_instructions &&
        instructionDetails?.special_instructions_show_top === false
      "
    >
      <h4 class="labTestData mt-5">
        {{ instructionDetails?.special_instructions_name }}
      </h4>
      <app-summernotes
        [id]="'special_instructions'"
        [htmlData]="instructionDetails?.special_instructions"
      >
      </app-summernotes>
    </div>
    <div
      *ngIf="
        instructionDetails?.protocol &&
        instructionDetails?.protocol_show_top === false
      "
    >
      <h4 class="labTestData mt-5">{{ instructionDetails?.protocol_name }}</h4>
      <app-summernotes
        [id]="'protocol'"
        [htmlData]="instructionDetails?.protocol"
      >
      </app-summernotes>
    </div>
    <div
      *ngIf="
        instructionDetails?.methodology &&
        instructionDetails?.methodology_show_top === false
      "
    >
      <h4 class="labTestData mt-5">
        {{ instructionDetails?.methodology_name }}
      </h4>
      <app-summernotes
        [id]="'methodology'"
        [htmlData]="instructionDetails?.methodology"
      >
      </app-summernotes>
    </div>
    <div
      *ngIf="
        instructionDetails?.performed_method &&
        instructionDetails?.performed_method_show_top === false
      "
    >
      <h4 class="labTestData mt-5">
        {{ instructionDetails?.performed_method_name }}
      </h4>
      <app-summernotes
        [id]="'performed_method'"
        [htmlData]="instructionDetails?.performed_method"
      >
      </app-summernotes>
    </div>
    <div
      *ngIf="
        instructionDetails?.interpretation &&
        instructionDetails?.interpretation_show_top === false
      "
    >
      <h4 class="labTestData mt-5">
        {{ instructionDetails?.interpretation_name }}
      </h4>
      <app-summernotes
        [id]="'interpretation'"
        [htmlData]="instructionDetails?.interpretation"
      >
      </app-summernotes>
    </div>
    <div
      *ngIf="
        instructionDetails?.comments &&
        instructionDetails?.comments_show_top === false
      "
    >
      <h4 class="labTestData mt-5">{{ instructionDetails?.comments_name }}</h4>
      <app-summernotes
        [id]="'comments'"
        [htmlData]="instructionDetails?.comments"
      >
      </app-summernotes>
    </div>
    <div *ngIf="pageDataList?.pathologist_remarks || criticalResults.length > 0">
      <h4 class="labTestData mt-5" *ngIf="pageDataList?.pathologist_remarks || criticalResults.length > 0">Remarks:</h4>
      <div [innerHTML]="pageDataList?.pathologist_remarks"></div>
      <div *ngIf="criticalResults.length > 0">The patient's
        <span *ngFor="let critical of criticalResults; let criticalInd = index">
          <span *ngIf="criticalInd !== 0 && criticalInd !== criticalResults.length - 1">, </span>
          <span *ngIf="criticalInd !== 0 && criticalInd === criticalResults.length - 1">and </span>
          {{critical.caption}}
        </span> values are critical. Immediate clinical attention required.
      </div>
    </div>
  </div>
</div>
