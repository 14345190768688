<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::Ot Calendar-->

      <!--      <div class="row mb-5" >-->
      <!--        <div class="col-md-12">-->

      <!--        </div>-->
      <!--      </div>-->

      <div
        class=""
        style="
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 20px;
        "
      >
        <div class="">
          <div class="card">
            <div class="card-body">
              <h5 *ngIf="isAuth === false" class="text-center">
                This Link has expired
              </h5>
              <form
                [formGroup]="passwordResetForm"
                (ngSubmit)="onSubmit()"
                *ngIf="isAuth === true"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>New Password</label>
                      <div style="position: relative">
                        <div class="input-icon">
                          <input
                            formControlName="newPassword"
                            type="password"
                            class="form-control form-control-sm"
                            placeholder="New Password"
                            #new
                            [ngClass]="{
                              'is-invalid': submit && f.newPassword.errors
                            }"
                          />
                          <span>
                            <i
                              (click)="
                                new.type =
                                  new.type == 'password' ? 'text' : 'password';
                                changeEyeIconSecond()
                              "
                              class="eye-icon"
                              [ngClass]="
                                secondIcon ? 'far fa-eye' : 'far fa-eye-slash'
                              "
                            >
                            </i>
                          </span>
                        </div>
                      </div>
                      <span
                        *ngIf="submit && f.newPassword.errors"
                        class=""
                        style="color: #dc3545"
                      >
                        <span *ngIf="f.newPassword.errors.minlength"
                          >Password must be 8 characters</span
                        >
                      </span>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <label>Confirm Password</label>
                      <div style="position: relative">
                        <div class="input-icon">
                          <input
                            formControlName="newPasswordConfirm"
                            type="password"
                            class="form-control form-control-sm"
                            placeholder="Confirm Password"
                            #newC
                            [ngClass]="{
                              'is-invalid':
                                submit && f.newPasswordConfirm.errors
                            }"
                          />
                          <span>
                            <i
                              (click)="
                                newC.type =
                                  newC.type == 'password' ? 'text' : 'password';
                                changeEyeIconThird()
                              "
                              class="eye-icon"
                              [ngClass]="
                                thirdIcon ? 'far fa-eye' : 'far fa-eye-slash'
                              "
                            >
                            </i>
                          </span>
                        </div>
                      </div>
                      <span
                        *ngIf="submit && f.newPasswordConfirm.errors"
                        style="color: #dc3545"
                      >
                        <span *ngIf="f.newPasswordConfirm.errors.mustMatch"
                          >Password must match</span
                        >
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row text-right mt-2">
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>

      <!--end::Ot Calendar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
