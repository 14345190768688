import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {LanguageService} from '../../../services/language.service';
import {FormBuilder, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {PatientService} from '../../../services/patient.service';
import {PatientTypeService} from '../../../services/patient-type.service';
import {LocationService} from '../../../services/location.service';
import {ActivatedRoute} from '@angular/router';
import {customValidations} from '../../../custom-validators/custom-validators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import * as moment from 'moment';
import {TitleCasePipe} from '@angular/common';
import {SystemControlsService} from '../../../services/system-controls.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-patient-registration',
  templateUrl: './patient-registration.component.html',
  styleUrls: ['./patient-registration.component.css']
})
export class PatientRegistrationComponent implements OnInit, AfterViewInit {
  @ViewChild('clearConfirmModal') clearConfirmModal: ElementRef;
  @Output() patientDataList: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild('imageModal') imageModal: ElementRef;
  dataValues: any;
  PatientRegistrationForm: FormGroup;
  submitted = false;
  age_types = ['years', 'months', 'days', 'hours'];
  private_patient_type_id: any;
  selected_age_type = 'years';
  patientAgainstPhoneNo = [];
  cityList = [];
  patient_types = [];
  countriesList = [];
  stateList = [];
  pattern = '';
  defaultLocations: any;
  current_environment_python: string;
  current_environment: string;
  // for Web Cam
  showImage = false;
  message = '';
  isDisable = false;
  dob: any;
  patientImage = '';
  patient: any;
  companies: any;
  groups: any;
  privete_company_id: any;
  currentControls: any;
  cnicMandatory = false;
  private_group_id: any;
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  constructor(private language: LanguageService,
              private ngxLoader: NgxUiLoaderService,
              private route: ActivatedRoute,
              private patientService: PatientService,
              private locationService: LocationService,
              private patientTypeService: PatientTypeService,
              public titleCasePipe: TitleCasePipe,
              private fb: FormBuilder,
              private modalService: NgbModal,
              private systemControlService: SystemControlsService) {
    this.dataValues = this.language.getData();
    this.current_environment_python = environment.pythonUrl;
    this.current_environment = environment.rorUrl;
    this.PatientRegistrationForm = this.fb.group({
      title: ['mr', Validators.required],
      first_name: ['', [Validators.required, customValidations()]],
      last_name: [''],
      gender: ['male', Validators.required],
      patient_nic: ['', Validators.compose([Validators.minLength(13)])],
      address1: [''],
      phone1: ['', Validators.compose([Validators.required, Validators.minLength(11)])],
      is_zakat_eligible: [''],
      country_id: [''],
      passport_country_id: [''],
      state_id: [''],
      location_id: [''],
      birth_day: [''],
      birth_month: [''],
      birth_year: [''],
      phone1_type: ['mobile'],
      patient_type_id: ['', Validators.required],
      company_id: [''],
      group_id: [''],
      pat_age_type: ['years', Validators.required],
      pat_age_value: [
        '',
        [Validators.required, Validators.min(0), Validators.max(150)],
      ],
      age: [''],
      guardian_nic: ['', Validators.compose([Validators.minLength(13)])],
      guardian_relationship: [''],
      guardian_first_name: [''],
      guardian_phone: [''],
      city_id: [''],
    });
  }
  onChange(): any {
    this.PatientRegistrationForm.controls.gender.setValue('');
    if (
      this.PatientRegistrationForm.value.title === 'mr' ||
      this.PatientRegistrationForm.value.title === 'bo' ||
      this.PatientRegistrationForm.value.title === 'dr'
    ) {
      this.PatientRegistrationForm.controls.gender.setValue('male');
    }
    if (
      this.PatientRegistrationForm.value.title === 'mrs' ||
      this.PatientRegistrationForm.value.title === 'miss' ||
      this.PatientRegistrationForm.value.title === 'do'
    ) {
      this.PatientRegistrationForm.controls.gender.setValue('female');
    }
  }
  get f(): any {
    return this.PatientRegistrationForm.controls;
  }
  _keyPressOnlyChar(event: any): any {
    const pattern = /^[a-zA-Z\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  searchPatientByPhoneNumber(): any {
    if ($('#phone1').val()) {
      this.PatientRegistrationForm.get('phone1')?.setValue(
        $('#phone1').val().replace(/-/g, ''),
      );
    }
    const obj = {
      search_query: this.PatientRegistrationForm.value.phone1,
      type: 'phone1',
    };
    this.patientService.searchPatientRegistrationV2(obj, false).subscribe(
      (data) => {
        if (data.patients.length > 0) {
          $('#patientFoundAgainstNumberModal').modal('show');
          this.patientAgainstPhoneNo = data.patients;
          setTimeout(() => {
            document.getElementById('patientFoundAgainstNumberBtn').focus();
          }, 500);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  checkAge(event: any): any {
    const value = event.target.value;
    if (value > 150) {
      this.PatientRegistrationForm.get('pat_age_value').patchValue(150);
      event.preventDefault();
    }
  }
  getPatientTypeList(): any {
    this.patientTypeService.getSelfPatientTypeV2().subscribe((data) => {
      this.patient_types = data.patient_types;
      if(this.patient_types.length){
      if (this.patient) {
        this.setPatientType();
      } else {
        const response = this.patient_types.filter(
          (t) => t.is_default === true,
        )[0];
        this.private_patient_type_id = response.id;

        this.companies = response.companies;
        let companiesResponse;
        if (this.companies.filter((t) => t.is_default === true)[0]) {
          companiesResponse = this.companies.filter(
            (t) => t.is_default === true,
          )[0];
        } else {
          companiesResponse = this.companies[0];
        }

        this.privete_company_id = companiesResponse.id;
        this.groups = companiesResponse.groups;
        if (this.groups.filter((t) => t.is_default === true)[0]) {
          this.private_group_id = this.groups.filter(
            (t) => t.is_default === true,
          )[0].id;
        } else if (this.groups.length > 0) {
          this.private_group_id = this.groups[0].id;
        }
        this.PatientRegistrationForm.patchValue({
          patient_type_id: this.private_patient_type_id,
          company_id: this.privete_company_id,
          group_id: this.private_group_id,
        });
      }
      }
    });
  }
  setPatientType(): any {
    this.companies = [];
    this.groups = [];
    const response = this.patient_types.filter(
      (t) => t.id === this.patient.patient_type_id,
    )[0];
    this.companies = response.companies;
    const companiesResponse = this.companies.filter(
      (t) => t.id === this.patient.company_id,
    )[0];
    this.groups = companiesResponse.groups;

    this.private_patient_type_id = this.patient.patient_type_id;
    this.privete_company_id = this.patient.company_id;
    this.private_group_id = this.patient.group_id;

    this.PatientRegistrationForm.patchValue({
      patient_type_id: this.private_patient_type_id,
      company_id: this.privete_company_id,
      group_id: this.private_group_id,
    });

  }
  getCountries(): any {
    this.ngxLoader.start();
    this.locationService.getCountries().subscribe(
      (data) => {
        this.countriesList = data.countries;
        this.ngxLoader.stop();
        let country;
        if (this.defaultLocations.country_id) {
          country = this.countriesList.find(
            (t) => t.id === Number(this.defaultLocations.country_id),
          );
        } else if (this.countriesList.find((t) => t.is_default === true)) {
          country = this.countriesList.find((t) => t.is_default === true);
        } else {
          country = this.countriesList[0];
        }
        this.PatientRegistrationForm.patchValue({
          passport_country_id: country?.id,
        });
        this.PatientRegistrationForm.patchValue({ country_id: country?.id });
        // this.getPatternAndCountryCode(country?.id);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getPatternAndCountryCode(value): any {
    if (value) {
      const country = this.countriesList.find((t) => t.id === Number(value));
      this.pattern = country.pattern;
      if (!this.pattern) {
        const maxLength = this.f.phone1_type.value === 'mobile' ? 11 : 25;
        const minLength = this.f.phone1_type.value === 'mobile' ? 11 : 1;
        this.PatientRegistrationForm.get('phone1').setValidators([
          Validators.required,
          Validators.minLength(minLength),
          Validators.maxLength(maxLength),
        ]);
        this.PatientRegistrationForm.get('phone1').updateValueAndValidity();
        $('#phone1').unmask(this.pattern);
        this.PatientRegistrationForm.get('phone1').setValue($('#phone1').val());
      } else {
        this.PatientRegistrationForm.get('phone1').clearValidators();
        this.PatientRegistrationForm.get('phone1').updateValueAndValidity();
        $('#phone1').mask(this.pattern);
        this.PatientRegistrationForm.get('phone1').setValue($('#phone1').val());
      }
    }
  }

  getState(): any {
    this.ngxLoader.start();
    this.locationService.getCountryState(this.defaultLocations.country_id).subscribe(
      (data) => {
        this.stateList = data.states;
        let state = '';
        if (this.defaultLocations.state_id) {
          state = this.stateList.find(
            (t) => t.id === Number(this.defaultLocations.state_id),
          ).id;
        } else if (this.stateList.find((t) => t.is_default === true)) {
          state = this.stateList.find((t) => t.is_default === true).id;
        } else {
          state = this.stateList[0].id;
        }
        this.PatientRegistrationForm.patchValue({ state_id: state });
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getCity(): any {
    this.cityList = [];
    this.locationService.getStateCity(this.defaultLocations.state_id).subscribe(
      (data) => {
        this.cityList = data.cities;
        // this.ngxLoader.stop();
        setTimeout((e) => {
          $('.city').selectpicker('refresh');
          $('.city').selectpicker();
        }, 500);
        let city = '';
        if (this.defaultLocations.city_id) {
          city = this.cityList.find(
            (t) => t.id === Number(this.defaultLocations.city_id),
          ).id;
        } else if (this.cityList.find((t) => t.is_default === true)) {
          city = this.cityList.find((t) => t.is_default === true).id;
        } else {
          city = this.cityList[0].id;
        }
        this.PatientRegistrationForm.patchValue({ city_id: city });
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  newRegistration(): any {
    this.isDisable = true;
    if (this.PatientRegistrationForm.get('patient_nic')?.value) {
      this.PatientRegistrationForm.get('patient_nic')?.setValue(
        this.PatientRegistrationForm.get('patient_nic')?.value.replace(
          /-/g,
          '',
        ),
      );
    }
    this.submitted = true;
    if (this.PatientRegistrationForm.invalid) {
      this.isDisable = false;
      return;
    }
    const first: any = this.PatientRegistrationForm.get('first_name').value;
    const last: any = this.PatientRegistrationForm.get('last_name').value;
    this.PatientRegistrationForm.get('state_id').setValue(this.defaultLocations.state_id);
    this.PatientRegistrationForm.get('location_id').setValue(this.defaultLocations.location_id);
    let resultFirst = '';
    let resultLast = '';
    resultFirst = this.titleCasePipe.transform(first);
    resultLast = this.titleCasePipe.transform(last);

    this.PatientRegistrationForm.get('first_name').setValue(resultFirst);
    this.PatientRegistrationForm.get('last_name').setValue(resultLast);
    if (this.PatientRegistrationForm.value.pat_age_type !== '') {
      this.PatientRegistrationForm.get('age').setValue(
        this.PatientRegistrationForm.value.pat_age_value +
        ' ' +
        this.PatientRegistrationForm.value.pat_age_type,
      );
    } else {
      this.PatientRegistrationForm.get('age').setValue(
        this.PatientRegistrationForm.value.pat_age_value +
        ' ' +
        this.selected_age_type,
      );
    }
    if (this.PatientRegistrationForm.value.pat_age_value && this.PatientRegistrationForm.value.pat_age_type) {
      this.dob = moment().subtract(this.PatientRegistrationForm.value.pat_age_value, this.PatientRegistrationForm.value.pat_age_type).format('MM-DD-YYYY');
    } else {
      this.dob = '';
    }
    if (this.dob !== ''){
      this.PatientRegistrationForm.get('birth_month').setValue(
        this.dob.split('-')[0],
      );
      this.PatientRegistrationForm.get('birth_day').setValue(
        this.dob.split('-')[1],
      );
      this.PatientRegistrationForm.get('birth_year').setValue(
        this.dob.split('-')[2],
      );
    }
    this.ngxLoader.start();
    this.patientService.createV2(this.PatientRegistrationForm.value).subscribe(
      (data) => {
        this.patient = data.patient;
        toastr.success('Patient Successfully Registered');
        this.patientDataList.emit(this.patient);
        this.ngxLoader.stop();
        if (this.patientImage) {
          this.uploadProfileImage('add');
        } else {
          // this.patientAddGenerateComplete();
        }
        this.resetPatientRegistrationForm();
      },
      (err) => {
        this.isDisable = false;
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }


  uploadProfileImage(type) {
    this.patientService
      .uploadProfileImage(
        this.patientImage,
        '',
        this.patient.id,
        'patient_info',
      )
      .subscribe(
        (dataImage) => {
          this.patient.profile_image = dataImage.patient_image;
          this.patientImage = '';
          // if (type === 'add') {
          //   this.patientAddGenerateComplete();
          // } else if (type === 'update') {
          //   this.patientUpdateComplete();
          // }
        },
        (err) => {
          this.isDisable = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  ngAfterViewInit(): any {
    $('#nic').mask('99999-9999999-9');
  }
  clearFormConfirm(): any {
    this.modalService.open(this.clearConfirmModal);
  }

  resetPatientRegistrationForm(): any {
    this.patientImage = '';

    this.modalService.dismissAll(this.clearConfirmModal);

    this.PatientRegistrationForm.reset({
      title: 'mr',
      first_name: '',
      last_name: '',
      gender: 'male',
      patient_nic: '',
      address1: '',
      phone1: '',
      pat_age_type: 'years',
      pat_age_value: '',
      blood_group: '',
      guardian_nic: '',
      guardian_relationship: '',
      guardian_first_name: '',
      guardian_phone: '',
      city_id: '',
    });
    this.patient = '';
    this.submitted = false;
    this.isDisable = false;
    this.resetPatientTypes();
    this.resetPatientCity();
  }
  resetPatientTypes(): any {
    const response = this.patient_types.filter((t) => t.is_default === true)[0];
    this.private_patient_type_id = response.id;

    this.companies = response.companies;
    let companiesResponse;
    if (this.companies.filter((t) => t.is_default === true)[0]) {
      companiesResponse = this.companies.filter(
        (t) => t.is_default === true,
      )[0];
    } else {
      companiesResponse = this.companies[0];
    }
    this.privete_company_id = companiesResponse.id;

    this.groups = companiesResponse.groups;
    if (this.groups.filter((t) => t.is_default === true)) {
      this.private_group_id = this.groups.filter(
        (t) => t.is_default === true,
      )[0].id;
    } else if (this.groups.length > 0) {
      this.private_group_id = this.groups[0].id;
    }
    let city = '';
    if (this.defaultLocations.city_id) {
      city = this.cityList.find(
        (t) => t.id === Number(this.defaultLocations.city_id),
      ).id;
    } else if (this.cityList.find((t) => t.is_default === true)) {
      city = this.cityList.find((t) => t.is_default === true).id;
    } else {
      city = this.cityList[0].id;
    }
    this.PatientRegistrationForm.patchValue({ city_id: city });
    let country;
    if (this.defaultLocations.country_id) {
      country = this.countriesList.find(
        (t) => t.id === Number(this.defaultLocations.country_id),
      );
    } else if (this.countriesList.find((t) => t.is_default === true)) {
      country = this.countriesList.find((t) => t.is_default === true);
    } else {
      country = this.countriesList[0];
    }
    this.PatientRegistrationForm.patchValue({
      passport_country_id: country?.id,
    });
    this.PatientRegistrationForm.patchValue({ country_id: country?.id });
    this.PatientRegistrationForm.patchValue({
      patient_type_id: this.private_patient_type_id,
      location_id: this.defaultLocations.location_id,
      state_id: this.defaultLocations?.state_id,
      company_id: this.privete_company_id,
      group_id: this.private_group_id,
    });
  }
  resetPatientCity(): any{
    setTimeout((e) => {
      $('.city').selectpicker('refresh');
      $('.city').selectpicker();
    }, 500);
    let city = '';
    if (this.defaultLocations.city_id) {
      city = this.cityList.find(
        (t) => t.id === Number(this.defaultLocations.city_id),
      ).id;
    } else if (this.cityList.find((t) => t.is_default === true)) {
      city = this.cityList.find((t) => t.is_default === true).id;
    } else {
      city = this.cityList[0].id;
    }
    this.PatientRegistrationForm.patchValue({ city_id: city });
  }
  ngOnInit(): void {
    const resolvedData = this.route.snapshot.data.resolvedData;
    this.defaultLocations =
      resolvedData.system_general_control.default_location;
    this.getPatientTypeList();
    this.getCity();
    this.getState();
    this.getCountries();
    // this.shareDataService.controlCheck.subscribe(data => {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
      if (this.currentControls !== '') {
        if (
          this.currentControls.patient_registration.enable_cnic_mandatory ===
          true
        ) {
          this.cnicMandatory = true;
        }
        if (this.cnicMandatory) {
          this.PatientRegistrationForm.get('patient_nic').setValidators([
            Validators.required,
            Validators.minLength(13),
          ]);
          this.PatientRegistrationForm.get('patient_nic').updateValueAndValidity();
        }
      }
    });
  }

  // For Web Cam

  cambox(): any {
    // this boolean is not in this method before
    this.showImage = false;
    // this servoce open line inside the next method before
    this.modalService.open(this.imageModal);

    navigator.mediaDevices.getUserMedia({ video: true }).then(
      (stream) => {
        WebcamUtil.getAvailableVideoInputs().then(
          (mediaDevices: MediaDeviceInfo[]) => {
            this.multipleWebcamsAvailable =
              mediaDevices && mediaDevices.length > 1;
          },
        );
        this.showImage = false;
      },
      (e) => {
        toastr.error('Allow camera permission');
        // microphone not available
      },
    );
  }
  triggerSnapshot(): void {
    this.trigger.next();
    this.showImage = true;
  }

  handleInitError(error: WebcamInitError): void {
    if (
      error.mediaStreamError &&
      error.mediaStreamError.name === 'NotAllowedError'
    ) {
    } else {
    }
    this.errors.push(error);
  }

  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }
  cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  resetImageBox(): any {
    this.showImage = false;
  }
  captureImageComplete(): any {
    this.patientImage = this.webcamImage.imageAsDataUrl;
    if (this.patient) {
      this.patient.profile_image = '';
    }
    this.modalService.dismissAll(this.imageModal);
  }
  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  getThumbImpression() {
    // if (!this.patient) {
    //   toastr.error('Select patient');
    //   return;
    // }
    let patientData = {
      is_attendance: 0,
      // patient_id: this.patient.id,
      redirect_url: '',
      api_url:
        this.current_environment_python +
        '/api/upload_patient_thumb_expression/',
      data_url: '',
    };
    let jsonData = JSON.stringify(patientData);
    let utf8Bytes = new TextEncoder().encode(jsonData);
    let base64String = btoa(String.fromCharCode.apply(null, utf8Bytes));
    window.open('digitalpersona:' + base64String, '_blank');
  }
  verifyThumbImpression(): any {
    let patientData = {
      is_attendance: 0,
      patient_id: '',
      redirect_url:
        window.location.origin + '/patient/registration-v2?patient_mrn=',
      api_url: '',
      data_url:
        this.current_environment_python +
        '/api/get_patient_for_fingerprint_verification?medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      background_image:
        this.current_environment_python +
        '/hr/get_background_image?medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
    };
    let jsonData = JSON.stringify(patientData);
    let utf8Bytes = new TextEncoder().encode(jsonData);
    let base64String = btoa(String.fromCharCode.apply(null, utf8Bytes));
    window.open('digitalpersona:' + base64String, '_blank');
  }
}
