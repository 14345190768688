<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 col-12">
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body" style="padding: 1rem">
              <form>
                <div class="row">
                  <div class="col-12 col-sm-5">
                    <div class="form-group">
                      <label>MRN</label>
                      <input
                        type="number"
                        (keypress)="_onlyNumeric($event)"
                        class="form-control form-control-sm"
                        placeholder="MRN"
                        name="patientId"
                        [(ngModel)]="patientId"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-sm-5">
                    <div class="form-group">
                      <label>CNIC</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="CNIC"
                        name="cnic"
                        [(ngModel)]="cnic"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-sm-5">
                    <div class="form-group">
                      <label>From Date</label>
                      <input
                        type="text"
                        class="form-control form-control-sm datetimepicker-input"
                        id="fromDate"
                        data-toggle="datetimepicker"
                        data-target="#fromDate"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-sm-5">
                    <div class="form-group">
                      <label>To Date</label>
                      <input
                        type="text"
                        class="form-control form-control-sm datetimepicker-input"
                        id="toDate"
                        data-toggle="datetimepicker"
                        data-target="#toDate"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-sm-2">
                    <div class="form-group">
                      <button
                        type="submit"
                        class="btn btn-light-primary btn-sm px-6 font-weight-bold"
                        (submit)="searchFilterValue()"
                        (click)="searchFilterValue()"
                        ngbTooltip="Search"
                        placement="bottom"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div
                class="table-responsive position-relative"
                style="height: 300px; overflow: auto"
              >
                <table
                  class="table table-head-custom table-vertical-center"
                  id="kt_advance_table_widget_3"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Acc / N</th>
                      <th>Patient Name</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="let element of pageDataList; let i = index"
                    >
                      <tr
                        class="radiologyReport"
                        id="radiologyReport{{ i }}"
                        (click)="fetchReports(element, i)"
                      >
                        <td>{{ element.id }}</td>
                        <td>{{ element.accession_number }}</td>
                        <td>{{ element.patient_name }}</td>
                        <td>
                          {{ element.order_date | date: "dd-MM-yyyy h:mm a" }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Body-->
          </div>

          <div class="card card-custom gutter-b" *ngIf="showAddendum">
            <!--begin::Body-->
            <div class="card-body" style="padding: 1rem">
              <div class="form-group">
                <label>Addendum <span class="text-danger">*</span></label>
                <!--                <div class="summernote" id="addendum"></div>-->
                <div id="addendum"></div>
              </div>
              <div class="form-group text-right">
                <button class="btn btn-default mr-2" (click)="clear()">
                  Clear
                </button>
                <button
                  class="btn btn-light-primary mr-2"
                  (click)="rejectLabTest()"
                >
                  Reject Report
                </button>
                <button class="btn btn-primary" (click)="saveReport()">
                  Save Report
                </button>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body" style="padding: 1rem">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>History</label>
                    <textarea
                      class="form-control"
                      disabled
                      [(ngModel)]="history"
                      rows="2"
                      cols="20"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Reports <span class="text-danger">*</span></label>
                    <!--                    <div class="summernote" id="reports"></div>-->
                    <div id="reports"></div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label
                      >Impressions <span class="text-danger">*</span></label
                    >
                    <!--                    <div class="summernote" id="impressions"></div>-->
                    <div id="impressions"></div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #conformCancelModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Report Rejected!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to rejected this report</p>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="rejectLabTestFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
