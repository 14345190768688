<div>
  <form
    *ngIf="isAdd === true"
    [formGroup]="InOutTakeChartForm"
    (ngSubmit)="submitInOutTake()"
  >
    <div class="row mb-4 d-print-none">
      <div class="col-lg-7 col-md-7 col-sm-12 col-12">
        <div class="card h-100">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-lg-4 col-md-4 col-sm-12 col-12 pl-1">
                <div class="d-flex">
                  <div style="flex-basis: 30%; align-self: center">
                    <label>Time:</label>
                  </div>
                  <div style="flex-basis: 70%">
                    <select
                      class="form-control form-control-sm"
                      formControlName="time"
                      [ngClass]="{
                        'is-invalid': isSubmitInOutTake && inOutCont.time.errors
                      }"
                    >
                      <option value="" [selected]="true">Select</option>
                      <option value="08:00:00">8:00 am</option>
                      <option value="09:00:00">9:00 am</option>
                      <option value="10:00:00">10:00 am</option>
                      <option value="11:00:00">11:00 am</option>
                      <option value="12:00:00">12:00 pm</option>
                      <option value="13:00:00">1:00 pm</option>
                      <option value="14:00:00">2:00 pm</option>
                      <option value="15:00:00">3:00 pm</option>
                      <option value="16:00:00">4:00 pm</option>
                      <option value="17:00:00">5:00 pm</option>
                      <option value="18:00:00">6:00 pm</option>
                      <option value="19:00:00">7:00 pm</option>
                      <option value="20:00:00">8:00 pm</option>
                      <option value="21:00:00">9:00 pm</option>
                      <option value="22:00:00">10:00 pm</option>
                      <option value="23:00:00">11:00 pm</option>
                      <option value="00:00:00">12:00 am</option>
                      <option value="01:00:00">01:00 am</option>
                      <option value="02:00:00">02:00 am</option>
                      <option value="03:00:00">03:00 am</option>
                      <option value="04:00:00">04:00 am</option>
                      <option value="05:00:00">05:00 am</option>
                      <option value="06:00:00">06:00 am</option>
                      <option value="07:00:00">07:00 am</option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-4 col-sm-12 col-12 text-center align-self-end"
              >
                <h3>In-Take</h3>
              </div>
              <div
                class="col-lg-4 col-md-4 col-sm-12 col-12 text-right align-self-end pr-0"
              >
                <button
                  type="button"
                  class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                  (click)="addMoreInTake()"
                >
                  <span class="svg-icon svg-icon-primary svg-icon-2x">
                    <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                        <path
                          d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </button>
              </div>
            </div>
            <div
              class="row mb-2"
              formArrayName="in_take_data"
              *ngFor="let item of controlInTake.controls; let i = index"
            >
              <ng-container [formGroupName]="i">
                <div class="col-2 plr-5">
                  <label *ngIf="i === 0">Method</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="in_take_method"
                    (change)="selectInTakeSubGroup(i)"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitInOutTake && item.controls.in_take_method.errors
                    }"
                  >
                    <option value="" selected>Select</option>
                    <option *ngFor="let method of methods" [value]="method">
                      {{ method }}
                    </option>
                  </select>
                </div>
                <div class="col-2 plr-5">
                  <label *ngIf="i === 0">Type</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="in_take_type_id"
                    (change)="selectInTakeItem(i)"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitInOutTake &&
                        item.controls.in_take_type_id.errors
                    }"
                  >
                    <option value="" [selected]="true">Select</option>
                    <option
                      *ngFor="let item of InTakeSubGroupItems[i]"
                      [value]="item?.id"
                    >
                      {{ item.item_name }}
                    </option>
                  </select>
                </div>
                <div class="col plr-5">
                  <label *ngIf="i === 0">Comments</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="comments"
                    placeholder="Comments"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitInOutTake && item.controls.comments.errors
                    }"
                  />
                </div>
                <div class="col-2 plr-5">
                  <label *ngIf="i === 0">Qty</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    formControlName="in_take_quantity"
                    placeholder="Qty"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitInOutTake &&
                        item.controls.in_take_quantity.errors
                    }"
                  />
                </div>
                <div class="col-2 plr-5">
                  <label *ngIf="i === 0">Unit</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="in_take_unit"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitInOutTake && item.controls.in_take_unit.errors
                    }"
                  >
                    <option value="" selected>Select</option>
                    <option value="Time">Time</option>
                    <option value="ml">ml</option>
                    <!--                                <option value="litre">litre</option>-->
                  </select>
                </div>
                <div class="col-1 text-right align-self-end mr-n3">
                  <button
                    (click)="removeInTake(i)"
                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                    type="button"
                  >
                    <span class="svg-icon svg-icon-primary svg-icon-2x">
                      <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg-->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                          />
                          <path
                            d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-12 col-12 pl-0">
        <div class="card h-100">
          <div class="card-body">
            <div class="row mb-4">
              <div
                class="col-lg-9 col-md-9 col-sm-12 col-12 text-center align-self-end"
              >
                <h3>Out-Put</h3>
              </div>
              <div
                class="col-lg-3 col-md-3 col-sm-12 col-12 text-right align-self-end pr-0"
              >
                <button
                  type="button"
                  class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm m-0"
                  (click)="addMoreOutTake()"
                >
                  <span class="svg-icon svg-icon-primary svg-icon-2x">
                    <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                        <path
                          d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </button>
              </div>
            </div>
            <div
              class="row mb-2"
              formArrayName="out_take_data"
              *ngFor="let item of controlOutTake.controls; let i = index"
            >
              <ng-container [formGroupName]="i">
                <div class="col-2 plr-5">
                  <label *ngIf="i === 0">Type</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="out_take_type_id"
                    (change)="selectOutTakeItem(i)"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitInOutTake && item.controls.out_take_type.errors
                    }"
                  >
                    <option value="">Select</option>
                    <option *ngFor="let item of OutTakeItems" [value]="item.id">
                      {{ item?.item_name }}
                    </option>
                  </select>
                </div>
                <div class="col plr-5">
                  <label *ngIf="i === 0">Comments</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="comments"
                    placeholder="Comments"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitInOutTake && item.controls.comments.errors
                    }"
                  />
                </div>
                <div class="col-2 plr-5">
                  <label *ngIf="i === 0">Qty</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    formControlName="out_take_quantity"
                    placeholder="Qty"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitInOutTake &&
                        item.controls.out_take_quantity.errors
                    }"
                  />
                </div>
                <div class="col-2 plr-5">
                  <label *ngIf="i === 0">Unit</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="out_take_unit"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitInOutTake && item.controls.out_take_unit.errors
                    }"
                  >
                    <option value="" [selected]="true">Select</option>
                    <option value="Time">Time</option>
                    <option value="ml">ml</option>
                    <!--                                <option value="time">time</option>-->
                  </select>
                </div>
                <div class="col-1 text-right plr-5 align-self-end">
                  <button
                    (click)="removeOutTake(i)"
                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                    type="button"
                  >
                    <span class="svg-icon svg-icon-primary svg-icon-2x">
                      <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg-->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                          />
                          <path
                            d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                  </button>
                </div>
              </ng-container>
            </div>
            <div class="row mt-4 text-right">
              <div class="col-12 p-0">
                <button type="submit" class="btn btn-sm btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="row mb-0">
    <div class="col-12 mb-6 d-none d-print-block">
      <div style="width: 85%; float: left">
        <!-- header table -->
        <table class="table">
          <tbody>
            <tr class="borders">
              <td style="width: 15%">
                <img
                  [src]="settingData?.logo?.url"
                  style="width: auto; height: 106px"
                />
              </td>
              <td style="width: 85%">
                <div class="text-center">
                  <h2>{{ settingData?.title }}</h2>
                </div>
                <div class="font-size-h5 text-center">
                  <h3>{{ settingData?.address }}</h3>
                </div>
                <!--                          <div class="font-size-h5 text-center"><h5><span *ngIf="settingData?.phone_number">UAN: {{settingData?.phone_number}}</span> <span *ngIf="settingData?.fax"> FAX: {{settingData?.fax}}</span></h5></div>-->
                <div class="text-center">
                  <h3>In-Take/Out-Put Chart</h3>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- patient info table -->
        <table class="w-100 info_table mb-2">
          <tbody>
            <tr>
              <td style="width: 45%">
                <h3 *ngIf="patient" class="m-0 line-clamp font-print-bold mr-5">
                  {{ patient?.title | titlecase }}
                  {{
                    patient?.first_name + " " + patient?.last_name + ","
                      | titlecase
                  }}
                  {{ patient?.age + "," }}
                  {{ patient?.gender.charAt(0) | titlecase }}
                </h3>
              </td>
              <td style="width: 55%">
                <h3 class="m-0 font-print-bold">MRN#{{ patient?.mrn }}</h3>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- patient info detail table -->
        <table class="w-100 info_table">
          <tbody>
            <tr>
              <td style="width: 15%"><strong>Father/Husband:</strong></td>
              <td style="width: 20%">{{ patient?.father_name | titlecase }}</td>
              <td style="width: 10%"></td>
              <td style="width: 15%"><strong>Patient Type:</strong></td>
              <td style="width: 20%">{{ patient?.patient_type?.name }}</td>
              <td style="width: 20%"></td>
            </tr>
            <tr>
              <td style="width: 15%"><strong>Contact:</strong></td>
              <td style="width: 20%">{{ patient?.phone1 }}</td>
              <td style="width: 10%"></td>
              <td style="width: 15%"><strong>Reg. Date:</strong></td>
              <td style="width: 20%">
                {{ patient?.created_at | date: "dd-MM-yyyy h:mm a" }}
              </td>
              <td style="width: 20%"></td>
            </tr>
            <tr>
              <td style="width: 15%"><strong>CNIC:</strong></td>
              <td style="width: 20%">{{ patient?.patient_nic }}</td>
              <td style="width: 10%"></td>
              <td style="width: 15%"><strong>Consultant:</strong></td>
              <td style="width: 20%">{{ patient?.consultant_name }}</td>
              <td style="width: 20%"></td>
            </tr>
            <tr>
              <td style="width: 15%"><strong>Address:</strong></td>
              <td style="width: 20%">{{ patient?.address1 }}</td>
              <td style="width: 10%"></td>
              <td style="width: 15%"><strong>Department:</strong></td>
              <td style="width: 20%">
                {{ patient?.admissions[0]?.referred_to }}
              </td>
              <td style="width: 20%"></td>
            </tr>
            <tr>
              <td style="width: 15%"><strong>Admission:</strong></td>
              <td style="width: 20%">
                {{
                  patient?.admissions[0]?.created_at | date: "dd-MM-yyyy h:mm a"
                }}
              </td>
              <td style="width: 10%"></td>
              <td style="width: 15%"><strong>Admission No:</strong></td>
              <td style="width: 20%">{{ patient?.admissions[0].id }}</td>
              <td style="width: 20%"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style="width: 15%; float: left">
        <div style="width: 100%; height: 180px">
          <img
            class="qrCode"
            style="height: 100%; width: 100%"
            *ngIf="patient?.qrcode_url"
            [src]="patient?.qrcode_url"
            alt=""
          />
        </div>
        <img
          style=""
          class="brCode"
          *ngIf="patient?.barcode_url"
          [src]="patient?.barcode_url"
          alt=""
        />
        <div style="" class="font-weight-bolder font-size-h3 mrnCode" id="mrn">
          {{ patient?.mrn }}
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="isAdd === false || isAdd === true"
    class="d-flex justify-content-between mb-4"
  >
    <!--In-Take Card-->
    <div class="col-lg-4 col-md-4 col-sm-4 pl-0 col-4 pr-lg-0 pr-md-0">
      <div class="card mb-4 gutter-b h-100">
        <div class="card-body user-card-body">
          <table class="table table-head-custom table-vertical-center">
            <thead>
              <tr>
                <th colspan="4" class="text-center">In-Take</th>
              </tr>
              <tr>
                <th>Date & Time</th>
                <th>Qty</th>
                <th>Type / Unit</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let data of patientInTakes">
                <tr>
                  <td>{{ data?.taken_time | date: "dd-MM-yyyy h:mm a" }}</td>
                  <td>{{ data?.in_take_quantity }}</td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ data?.in_take_type }}</span
                    >
                    <span class="text-muted font-weight-bold d-block">{{
                      data?.in_take_unit
                    }}</span>
                  </td>
                </tr>
                <tr *ngIf="data?.comments">
                  <td colspan="4">{{ data?.comments }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--Out-Put Card-->
    <div class="col-lg-4 col-md-4 col-sm-4 col-4 pr-lg-0 pr-md-0">
      <div class="card gutter-b mb-4 h-100">
        <div class="card-body user-card-body">
          <table class="table table-head-custom table-vertical-center">
            <thead>
              <tr>
                <th colspan="4" class="text-center">Out-Put</th>
              </tr>
              <tr>
                <th>Date & Time</th>
                <th>Qty</th>
                <th>Type / Unit</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let data of patientOutTakes">
                <tr>
                  <td>{{ data?.taken_time | date: "dd-MM-yyyy h:mm a" }}</td>
                  <td>{{ data?.out_take_quantity }}</td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ data?.out_take_type }}</span
                    >
                    <span class="text-muted font-weight-bold d-block">{{
                      data?.out_take_unit
                    }}</span>
                  </td>
                </tr>
                <tr *ngIf="data?.comments">
                  <td colspan="4">{{ data?.comments }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--  Total Card -->
    <div class="col-lg-4 col-md-4 col-sm-4 pr-0 col-4">
      <div class="card gutter-b mb-4 h-100">
        <div class="card-body user-card-body">
          <div class="d-flex justify-content-center px-3"></div>
          <div class="d-flex justify-content-center px-3 d-print-none">
            <div style="flex-basis: 40%" *ngIf="isAdd === true">
              <input
                type="date"
                id="intakeDateFilter"
                [(ngModel)]="summaryDate"
                (change)="updateDate($event.target.value)"
                max="{{ currentDate | date: 'yyyy-MM-dd' }}"
                class="form-control form-control-sm"
              />
            </div>
            <div class="" style="flex-basis: 48%; text-align: right">
              <button
                *ngIf="isAdd === true"
                class="btn btn-light-primary btn-sm mr-1"
                (click)="getInOutTakes('')"
              >
                Search
              </button>
              <button
                class="btn btn-light-primary btn-sm"
                (click)="printCurrentScreen()"
              >
                Print
              </button>
            </div>
          </div>
          <!-- *ngIf="patientInOutTakes.length > 0" -->
          <div class="mt-4 mx-4 p-3">
            <span class="font-weight-bold">Total In-Take:</span>
            <div *ngFor="let in_take_qty of todayInTakeQuantity">
              {{ in_take_qty.value }} {{ in_take_qty.key }}
            </div>
            <br />
            <div *ngFor="let data of totalInTakeQtyType">
              <div *ngFor="let values of data?.value">
                {{ data?.key }} {{ values }}
              </div>
              <!--                            {{data?.key}} {{data?.value}}-->
            </div>
            <br />
            <span class="font-weight-bold">Total Out-Put:</span>
            <div *ngFor="let out_take_qty of todayOutTakeQuantity">
              {{ out_take_qty.value }} {{ out_take_qty.key }}
            </div>
            <br />
            <div *ngFor="let data of totalOutTakeQtyType">
              <div *ngFor="let values of data?.value">
                {{ data?.key }} {{ values }}
              </div>
              <!--                            {{data?.key}} {{data?.value}}-->
            </div>
            <div class="row mt-5">
              <span class="font-weight-bolder ml-4">Balance: </span>
              <span class="font-weight-bolder ml-2"
                >{{
                  todayInTakeQtyTotal?.ml - todayOutTakeQtyTotal?.ml || 0
                    | number
                }}
                ml</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
