<div class="card">
  <div class="card-body">
    <h4>Admission History</h4>
    <div class="table-responsive">
      <table class="table table-vertical-center table-head-custom">
        <thead>
          <tr>
            <th style="width: 18%">Department / Date</th>
            <th style="width: 5%">Issues Item</th>
            <th style="width: 7%">Discharge Report</th>
            <th style="width: 7%"><span class="d-block">IPD Bill</span> Before FS</th>
            <th style="width: 7%"><span class="d-block">Bill Before</span> FS</th>
            <th style="width: 6%"><span class="d-block">Bill After</span> FS</th>
            <th style="width: 4%">HSW</th>
            <th style="width: 7%">Summarize Bill</th>
            <th style="width: 9%"><span class="d-block">Summarize 2.0</span> Before FS</th>
            <th style="width: 9%"><span class="d-block">Summarize 2.0</span> After FS</th>
            <th style="width: 9%">HSW Before FS</th>
            <th style="width: 9%">Clearance Certificate</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let v of patientAdmissions">
            <td>
              <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{
                v?.referred_to?.title
              }}</span>
              <span class="text-muted font-weight-bold text-muted d-block">{{
                v.created_at | date: "dd-MM-yyyy"
              }}</span>
            </td>
            <td>
              <a
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                (click)="issuedItemPrint(v.id)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\File.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <rect
                        fill="#000000"
                        x="6"
                        y="11"
                        width="9"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        x="6"
                        y="15"
                        width="5"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </a>
            </td>
            <td>
              <a
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                (click)="openDischargePdf(v)"
                *ngIf="v?.discharge_patient_id"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\File.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <rect
                        fill="#000000"
                        x="6"
                        y="11"
                        width="9"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        x="6"
                        y="15"
                        width="5"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </a>
            </td>
            <td>
              <a
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                (click)="printIpdInvoicesReport(v.visit_id)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\File.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <rect
                        fill="#000000"
                        x="6"
                        y="11"
                        width="9"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        x="6"
                        y="15"
                        width="5"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </a>
            </td>
            <td>
              <a
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                (click)="billBeforeSettlement(patient.mrn, v.id, v.visit_id)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="9"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="9"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="13"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="13"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="17"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="17"
                        width="7"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </a>
            </td>
            <td>
              <a
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                (click)="billAfterSettlement(patient.mrn, v.id, v.visit_id)"
                *ngIf="v?.final_settlement"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="9"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="9"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="13"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="13"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="17"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="17"
                        width="7"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </a>
            </td>
            <td>
              <a
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                (click)="printHswBill(patient.mrn, v.id, false, v.visit_id)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="9"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="9"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="13"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="13"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="17"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="17"
                        width="7"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </a>
            </td>
            <td>
              <a
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                (click)="printSummarizeBill(patient.mrn, v.id, v.visit_id)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="9"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="9"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="13"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="13"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="17"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="17"
                        width="7"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </a>
            </td>
            <td>
              <a
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                (click)="printandView(patient.mrn, v.visit_id, true)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="9"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="9"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="13"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="13"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="17"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="17"
                        width="7"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                ><!--end::Svg Icon--></span
                >
              </a>
            </td>
            <td>
              <a
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                (click)="printandView(patient.mrn, v.visit_id, false)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="9"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="9"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="13"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="13"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="17"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="17"
                        width="7"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                ><!--end::Svg Icon--></span
                >
              </a>
            </td>
            <td>
              <a
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                (click)="printHswBill(patient.mrn, v.id, true, v.visit_id)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="9"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="9"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="13"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="13"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="17"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="17"
                        width="7"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </a>
            </td>
            <td>
              <a
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                (click)="clearanceCertificate(patient.mrn, v.visit_id)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="9"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="9"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="13"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="13"
                        width="7"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="17"
                        width="2"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="10"
                        y="17"
                        width="7"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!--OT Merge Medicine Modal Start-->
<div
  class="modal fade"
  id="otMedicineModal"
  tabindex="-1"
  style="z-index: 1051 !important"
  role="dialog"
  aria-labelledby="ModalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <div class="col-3">
            <div class="py-3" style="min-height: 50px; border: 0">
              <div
                class="card-title align-items-start flex-column justify-content-center w-100"
              >
                <h6 class="card-label text-dark text-left w-100">
                  Merge OT Medicines
                </h6>
              </div>
            </div>
          </div>
          <div class="col-9">
            <div class="card-body pt-1">
              <div class="d-flex align-items-center justify-content-start">
                <!--            <label class="pr-2">disable</label>-->
                <span class="switch switch-icon">
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      name="select"
                      [(ngModel)]="mergeOTMedicines"
                    />
                    <span></span>
                  </label>
                </span>
                <label class="pr-2">Enable?</label>
                <!--            <label class="pl-2">Right</label>-->
              </div>
              <!--              <span class="text-muted font-weight-bold text-muted d-block">This is for Internal Requisition Report Static Signature Session.</span>-->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          data-dismiss="modal"
          (click)="printBill()"
        >
          Print
        </button>
      </div>
    </div>
  </div>
</div>
<!--OT Merge Medicine Modal end-->
