import { Component, OnInit } from '@angular/core';
import { EmployeeVisitService } from '../../../../services/employee-visit.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { RouteShareService } from '../../../../services/route-share.service';

@Component({
  selector: 'app-employee-visiting-list',
  templateUrl: './employee-visiting-list.component.html',
  styleUrls: ['./employee-visiting-list.component.css'],
})
export class EmployeeVisitingListComponent implements OnInit {
  page = 1;
  perPage = 25;
  totalPages: any;
  perPageValue: any;
  list: any;
  screenName = '';

  constructor(
    private visitService: EmployeeVisitService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  getEmployeeVisitList(): any {
    this.ngxLoader.start();
    this.visitService
      .getStoreListing(this.page, this.perPage)
      .subscribe((data) => {
        this.list = data;
        this.totalPages = this.list.total_count;
        this.list = this.list.items;
        this.ngxLoader.stop();
      });
  }

  loadPage(page): any {
    this.getEmployeeVisitList();
  }

  selectPaginationLimit(event): any {
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    console.log('inside select pagination function');
    this.getEmployeeVisitList();
  }

  edit(id): any {
    this.router.navigate(['/employee-visiting/update'], {
      queryParams: { id },
    });
  }

  ngOnInit(): void {
    this.getEmployeeVisitList();
    this.perPageValue = 25;
  }
}
