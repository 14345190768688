<!-- patient info table -->
<div style="overflow-x: auto" class="employeeShiftTable">
  <table class="w-100 table-bordered mb-2">
    <tbody>
      <tr>
        <td style="width: 150px">
          <span class="ml-2 font-weight-bold">Lab Parameter</span>
        </td>
        <td style="width: 150px">
          <span class="ml-2 font-weight-bold">Ref Range</span>
        </td>
        <ng-container *ngFor="let dateData of dateRange; let i = index">
          <td style="min-width: 150px" class="text-center">
            <div class="d-flex flex-column font-weight-bold">
              <span>{{ dateData?.date | date: "dd-MM-yyyy" }}</span
              ><span>{{ dateData?.date | date: "hh:mm a" }}</span>
            </div>
          </td>
        </ng-container>
      </tr>
      <ng-container *ngFor="let data of dataRange; let i = index">
        <tr>
          <td style="min-width: 150px">
            <span class="ml-2">{{ data?.param_name }}</span>
          </td>
          <td style="min-width: 150px">
            <span class="ml-2">{{ data?.range }}</span>
          </td>
          <ng-container *ngFor="let lab of data?.values; let i = index">
            <td class="text-center" style="min-width: 150px">
              {{ lab?.value }}
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
