import { Component, OnInit } from '@angular/core';
// import any = jasmine.any;

declare var $: any;
@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.css'],
})
export class MobileHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  toggleUserBar() {
    $('#kt_header_mobile_topbar_toggle').toggleClass('active');
  }
}
