import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment/moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LocationService } from '../../services/location.service';
import { formatDate } from '@angular/common';
import { DoctorSurveyService } from '../../services/doctor-survey.service';
import { DepartmentsbyhospitalService } from '../../services/departmentsbyhospital.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-doctor-survey-report',
  templateUrl: './doctor-survey-report.component.html',
  styleUrls: ['./doctor-survey-report.component.css'],
})
export class DoctorSurveyReportComponent implements OnInit {
  today = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  searchForm: UntypedFormGroup;
  surveyData = [];
  depart: any;
  countriesList = [];
  page = 1;
  totalPages: any;
  perPage = 25;

  constructor(
    private fb: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private doctorSurveyService: DoctorSurveyService,
  ) {
    this.searchForm = this.fb.group({
      from_date: [''],
      to_date: [''],
      speciality: [''],
      country_of_practice: [''],
      compensation: [''],
    });
  }
  searchList(): any {
    this.searchForm.get('from_date').setValue($('#fromDate').val());
    this.searchForm.get('to_date').setValue($('#toDate').val());
    this.ngxLoader.start();
    this.doctorSurveyService
      .getSurveyList(this.searchForm.value, this.page, this.perPage)
      .subscribe(
        (data) => {
          this.surveyData = data.doctor_survey;
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  getDepartments(): any {
    this.depart = [];
    this.doctorSurveyService.Departmentsv2DoctorSurvey().subscribe((data) => {
      this.depart = data.departments;
      setTimeout(() => {
        $('.selectPickerDepartment').selectpicker('refresh');
        $('.selectPickerDepartment').selectpicker();
      }, 500);
    });
  }
  getCountries(): any {
    this.ngxLoader.start();
    this.doctorSurveyService.getCountriesDoctorSurvey().subscribe(
      (data) => {
        this.countriesList = data.countries;
        setTimeout(() => {
          $('.country').selectpicker('refresh');
          $('.country').selectpicker();
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getDate(): any {
    setTimeout(() => {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      $('#fromDate').datetimepicker({
        defaultDate: yesterday,
        format: 'YYYY-MM-DD',
        autoClose: true,
      });
      let previousDateTime = moment(yesterday).format('YYYY-MM-DD');
      $('#fromDate').val(previousDateTime);
      $('#toDate').datetimepicker({
        defaultDate: new Date(),
        format: 'YYYY-MM-DD',
      });
      let current_time = moment().format('YYYY-MM-DD');
      $('#toDate').val(current_time);
    }, 500);
  }

  loadPage(event): any {
    this.searchList();
  }
  selectPaginationLimit(event): any {
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.searchList();
  }
  searchFilterValue(): any {
    this.page = 1;
    this.searchList();
  }
  ngOnInit(): void {
    this.getDepartments();
    this.getCountries();
    this.getDate();
  }
}
