<div
  class="general-history-section font-size-print-16 row mb-5"
  *ngIf="
                              this.reviewSystemHistory
                                ?.patient_general_health_history_attributes
                                ?.weight_loss ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.fatigue_tiredness ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.fever_chills ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.night_sweats ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.other ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.appetite ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.fatigue ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.activity_change ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.fever ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.unexpected_weight_change ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.appetite_change ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.other ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.glaucoma ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes
                                  ?.visual_difdiculty ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes
                                  ?.hearing_problem ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes
                                  ?.swollen_glands ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes
                                  ?.recurrent_sore_throat ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes
                                  ?.nose_bleeds ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.tinnitus ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.other ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.headache ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.eye_pain ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.ringing ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.congestion ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.hearing_loss ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.ear_pain ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.facial_swelling ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.vision_disturbance ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.heart_problem ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.high_blood_pressure ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.stroke ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.pci ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.other ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.chest_pain ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.htn ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.palpitations ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.edema ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.syncope ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.chest_pain ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.pnd ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.palpitations ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.shortness_of_breath ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.orthopnea ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.shirtness_of_breath ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.asthma ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.chronic_cought ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.lung_disease ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.heamoptysis ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.other ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.cough ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.shortness_of_breath ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.stridor ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.wheeze ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.chest_tightness ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.choking ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.other ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes
                                  ?.seizures ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes
                                  ?.headache ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.vertigo ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes
                                  ?.tingling ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.tremors ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.dizziness ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.numbness ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.weakness ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.memory_loss ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.facial_asymmetry ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.seizure ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.fainting_spells ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.tingling ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.confusion ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.speech_difficulty ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes?.rash ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.pigmentation ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes?.hives ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.skin_cancer ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.skin_other ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.environmental_allergies ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.recurrent_infections ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.food_allergies ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.immunodeficiency ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.dizziness ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.headache ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.cough ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.glasses ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.abdominal_pairs ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.n_v_d ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.constipation ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.heart_burn ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.brb_stool ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.breast_masses ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.nipple_disch ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.arthralgia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.back_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.bursitis ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.gout ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.osteroporsis ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.anixious ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.depression ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.swollen_glands ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.diff_swal ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.stress ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.nervous_disorder ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.bruise_easily ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.anemia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.bleeding ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.eye_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.resp_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.gi_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.endo_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.musclosk_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.psych_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.heme_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.heme_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.polyphagia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.polydipsia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.polyuria ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.skin_changes ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.hair_changes ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.heat_intolerance ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.joint_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.joint_swelling ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.muscle_cramps ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.neck_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.joint_stiffness ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.muscle_aches ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.back_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.gait_changes ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.agitation ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.suicidal_ideation ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.sleep_changes ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.self_injury ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.anxiety ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.mood_changes ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.decreased_concentration ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.past_history ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.bruising ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.lymph_node_swelling ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.bleeding ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.discharge ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.redness ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.itching ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.sensitivity_to_light ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.pain_on_urination ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.increased_frequency ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.nocturia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.incontinence ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.vaginal_discharge ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.testicular_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.blood_in_urine ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.urgency ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.flank_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.dyspareunia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.vaginal_discharge ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.nausea ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.diarrhea ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.blooting ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.blood_in_stool ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.vomitting ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.abdominal_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.change_in_stool_habits ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.rectal_pain
                              else ReviewHistoryElse
                            "
>
  <div class="col-12">
    <h4 class="para-heading-font-size">
      {{ presControl.heading }}
    </h4>
  </div>
  <!--                            general health-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.weight_loss ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.fatigue_tiredness ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.fever_chills ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.night_sweats ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.other ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.appetite ||
                                  this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.fatigue ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.activity_change ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.fever ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.unexpected_weight_change ||
                                this.reviewSystemHistory
                                  ?.patient_general_health_history_attributes
                                  ?.appetite_change
                              "
  >
    <strong>General Health:</strong>
    <span>
                                (
      {{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.appetite_change
          ? "Appetite Change,"
          : ""
      }}
      {{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.unexpected_weight_change
          ? "Unexpected Weight Change,"
          : ""
      }}
      {{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.fever
          ? "Fever,"
          : ""
      }}
      {{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.activity_change
          ? "Activity Change,"
          : ""
      }}
      {{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.fatigue
          ? "Fatigue,"
          : ""
      }}
      {{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.weight_loss
          ? "Weight loss,"
          : ""
      }}
      {{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.fatigue_tiredness
          ? "Fatigue tiredness,"
          : ""
      }}
      {{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.fever_chills
          ? "Fever Chills,"
          : ""
      }}
      {{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.night_sweats
          ? "Night Sweats,"
          : ""
      }}
      {{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.appetite
          ? "Appetite,"
          : ""
      }}
      {{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_general_health_history_attributes
                                    ?.other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_general_health_history_attributes
          ?.other_details
      }}
                              </span>
  </div>
  <!--                            ENT-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes
                                  ?.hearing_problem ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes
                                  ?.swollen_glands ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes
                                  ?.recurrent_sore_throat ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes
                                  ?.nose_bleeds ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.tinnitus ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.other ||
                                   this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.headache ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.eye_pain ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.ringing ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.congestion ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.hearing_loss ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.ear_pain ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.facial_swelling ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.vision_disturbance
                              "
  >
    <strong>ENT:</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.facial_swelling
          ? "Facial Swelling,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.ear_pain
          ? "Ear Pain,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.hearing_loss
          ? "Hearing Loss,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.congestion
          ? "Congestion,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.ringing
          ? "Ringing,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.eye_pain
          ? "Eye Pain,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.headache
          ? "Headache,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.vision_disturbance
          ? "Vision Disturbance,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.hearing_problem
          ? "Hearing Problem,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.swollen_glands
          ? "Swollen Glands,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.recurrent_sore_throat
          ? "Recurrent Sore throat,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.nose_bleeds
          ? "Nosebleeds,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes?.tinnitus
          ? "TINNITUS"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes?.other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_ent_history_attributes
                                    ?.other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.other_details
      }}
                              </span>
  </div>
  <!--                            Cardiology-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.heart_problem ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.high_blood_pressure ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.stroke ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.pci ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.other ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.htn ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.palpitations ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.edema ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.syncope ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.chest_pain ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.pnd ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.shortness_of_breath ||
                                this.reviewSystemHistory
                                  ?.patient_cardiology_history_attributes
                                  ?.orthopnea
                              "
  >
    <strong>Cardiology:</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.orthopnea
          ? "Orthopnea,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.shortness_of_breath
          ? "Shortness of Breath,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.pnd
          ? "PND,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.heart_problem
          ? "Heart problem,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.high_blood_pressure
          ? "High BP,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.stroke
          ? "Stroke,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes?.pci
          ? "PCI/CABG,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.chest_pain
          ? "Chest Pain,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes?.htn
          ? "HTN,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.palpitations
          ? "Palpitations,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.edema
          ? "Edema,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.syncope
          ? "Syncope,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_cardiology_history_attributes
                                    ?.other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_cardiology_history_attributes
          ?.other_details
      }}
                              </span>
  </div>
  <!--                            Respiratory-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.shirtness_of_breath ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.asthma ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.chronic_cought ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.lung_disease ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.heamoptysis ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.other ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.cough ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.shortness_of_breath ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.stridor ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.wheeze ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.chest_tightness ||
                                this.reviewSystemHistory
                                  ?.patient_respiratory_history_attributes
                                  ?.choking
                              "
  >
    <strong>Respiratory:</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.choking
          ? "Choking,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.chest_tightness
          ? "Chest Tightness,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.wheeze
          ? "Wheeze,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.stridor
          ? "Stridor,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.shortness_of_breath
          ? "Shortness of Breath,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.cough
          ? "Cough,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.asthma
          ? "Asthma,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.chronic_cought
          ? "Chronic Cought,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.lung_disease
          ? "Lung Disease,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.heamoptysis
          ? "Heamoptysis,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.shirtness_of_breath
          ? "Shirtness of breath,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_respiratory_history_attributes
                                    ?.other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_respiratory_history_attributes
          ?.other_details
      }}
                              </span>
  </div>
  <!--                            Neuro-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.other ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes
                                  ?.seizures ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes
                                  ?.headache ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.vertigo ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes
                                  ?.tingling ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes
                                  ?.numbsness ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.tremors ||
                                   this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.dizziness ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.numbness ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.weakness ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.memory_loss ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.facial_asymmetry ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.fainting_spells ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.confusion ||
                                this.reviewSystemHistory
                                  ?.patient_neuro_history_attributes?.speech_difficulty
                              "
  >
    <strong>Neuro:</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.speech_difficulty
          ? "Speech Difficulty,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.confusion
          ? "Confusion,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.fainting_spells
          ? "Fainting Spells,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.facial_asymmetry
          ? "Facial Asymmetry,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.memory_loss
          ? "Memory Loss,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.weakness
          ? "Weakness,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.numbness
          ? "Numbness,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.dizziness
          ? "Dizziness,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.seizures
          ? "Seizures,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.headache
          ? "Headache,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.vertigo
          ? "Vertigo/Black outs,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.tingling
          ? "Tingling,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes
          ?.numbsness
          ? "Numbsness,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.tremors
          ? "Tremors,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_neuro_history_attributes?.other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_neuro_history_attributes
                                    ?.other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_neuro_history_attributes
          ?.other_details
      }}
                              </span>
  </div>
  <div class="col-12" *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.environmental_allergies ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.recurrent_infections ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.food_allergies ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.immunodeficiency">
    <strong>Allergy/Immuno:</strong>
    <span>(
      {{
        reviewSystemHistory
          ?.patient_skin_history_attributes?.immunodeficiency
          ? "Immunodeficiency,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_skin_history_attributes?.food_allergies
          ? "Food Allergies,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_skin_history_attributes?.recurrent_infections
          ? "Recurrent Infections,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_skin_history_attributes?.environmental_allergies
          ? "Environmental Allergies,"
          : ""
      }}
      )</span>
  </div>
  <!--                            Skin-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes?.rash ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.pigmentation ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes?.hives ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes
                                  ?.skin_cancer ||
                                this.reviewSystemHistory
                                  ?.patient_skin_history_attributes?.skin_other "
  >
    <strong>Skin:</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_skin_history_attributes?.rash
          ? "Rash/ Striae,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_skin_history_attributes
          ?.pigmentation
          ? "Pigmentation,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_skin_history_attributes?.hives
          ? "Hives,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_skin_history_attributes
          ?.skin_cancer
          ? "Skin Cancer,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_skin_history_attributes
          ?.skin_other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_skin_history_attributes
                                    ?.skin_other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_skin_history_attributes
          ?.skin_other_details
      }}
                              </span>
  </div>
  <!--                            Eyes/Head-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.dizziness ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.headache ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.eye_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.glasses ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.glaucoma ||
                                this.reviewSystemHistory
                                  ?.patient_ent_history_attributes?.visual_difdiculty ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.discharge ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.redness ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.itching ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.sensitivity_to_light
                              "
  >
    <strong>Eyes/Head:</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.redness
          ? "Redness,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.pain
          ? "Pain,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.itching
          ? "Itching,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.sensitivity_to_light
          ? "Sensitivity To Light,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.discharge
          ? "Discharge,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.dizziness
          ? "Dizziness,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.headache
          ? "Headache,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.glasses
          ? "Glasses/Contacts,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes?.glaucoma
          ? "Glaucoma,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_ent_history_attributes
          ?.visual_difdiculty
          ? "Visual Difficulty,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.eye_other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_other_review_history
                                    ?.eye_other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_other_review_history
          ?.eye_other_details
      }}
                              </span>
  </div>
  <!--                            RESP-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.cough ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.resp_other
                              "
  >
    <strong>RESP:</strong>
    <span>
                                ({{
        reviewSystemHistory
          ?.patient_other_review_history?.cough
          ? "Cough,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.resp_other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_other_review_history
                                    ?.resp_other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_other_review_history
          ?.resp_other_details
      }}
                              </span>
  </div>
  <!--                            GI-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.abdominal_pairs ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.n_v_d ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.constipation ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.heart_burn ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.brb_stool ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.gi_other
                              "
  >
    <strong>GI:</strong>
    <span>
                                ({{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.abdominal_pairs
          ? "Abdominal Pairs,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.n_v_d
          ? "N/V/D,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.constipation
          ? "Constipation,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.heart_burn
          ? "Heart Burn,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.brb_stool
          ? "BRB Stool,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.gi_other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_other_review_history
                                    ?.gi_other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_other_review_history
          ?.gi_other_details
      }}
                              </span>
  </div>
  <!--                            Endo-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.breast_masses ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.nipple_disch ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.endo_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.cold_intolerance ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.polyphagia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.polydipsia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.polyuria ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.skin_changes ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.hair_changes ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.heat_intolerance
                              "
  >
    <strong>Endo:</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.cold_intolerance
          ? "Cold Intolerance,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.polyphagia
          ? "Polyphagia,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.polydipsia
          ? "Polydipsia,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.polyuria
          ? "Polyuria,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.skin_changes
          ? "Skin Changes,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.hair_changes
          ? "Hair Changes,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.heat_intolerance
          ? "Heat Intolerance,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.breast_masses
          ? "Breast Masses,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.nipple_disch
          ? "Nipple Disch,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.endo_other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_other_review_history
                                    ?.endo_other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_other_review_history
          ?.endo_other_details
      }}
                              </span>
  </div>
  <!--                            Musclosk-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.arthralgia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.back_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.bursitis ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.gout ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.osteroporsis ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.musclosk_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.joint_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.joint_swelling ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.muscle_cramps ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.neck_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.joint_stiffness ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.muscle_aches ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.back_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.gait_changes
                              "
  >
    <strong>Musclosk:</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.joint_pain
          ? "Joint Pain,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.joint_swelling
          ? "Joint Swelling,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.muscle_cramps
          ? "Muscle Cramps,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.neck_pain
          ? "Neck Pain,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.joint_stiffness
          ? "Joint Stiffness,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.muscle_aches
          ? "Muscle Aches,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.back_pain
          ? "Back Pain,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.gait_changes
          ? "Gait Changes,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.arthralgia
          ? "Arthralgia,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.back_pain
          ? "Back Pain,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.bursitis
          ? "Bursitis,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.gout
          ? "Gout,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.osteroporsis
          ? "Osteroporsis,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.musclosk_other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_other_review_history
                                    ?.musclosk_other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_other_review_history
          ?.musclosk_other_details
      }}
                              </span>
  </div>
  <!--                            Psych-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.anixious ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.depression ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.stress ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.nervous_disorder ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.psych_other ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.agitation ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.suicidal_ideation ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.sleep_changes ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.self_injury ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.anxiety ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.mood_changes ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.decreased_concentration ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.past_history
                              "
  >
    <strong>Psych:</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.agitation
          ? "Agitation,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.suicidal_ideation
          ? "Suicidal Ideation,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.sleep_changes
          ? "Sleep Changes,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.self_injury
          ? "Self Injury,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.anxiety
          ? "Anxiety,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.mood_changes
          ? "Mood Changes,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.decreased_concentration
          ? "Decreased Concentration,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.past_history
          ? "Past History,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.anixious
          ? "Anixious,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.depression
          ? "Depression,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.stress
          ? "Stress,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.nervous_disorder
          ? "Nervous Disorder,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.psych_other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_other_review_history
                                    ?.psych_other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_other_review_history
          ?.psych_other_details
      }}
                              </span>
  </div>
  <!--                            Heme/Lymph-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.bruise_easily ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.bleeding ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.anemia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.swollen_glands ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.diff_swal ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.heme_other||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.bruising||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.lymph_node_swelling||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.bleeding
                              "
  >
    <strong>Heme/Lymph:</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.bruising
          ? "Bruising,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.lymph_node_swelling
          ? "Lymph Node Swelling,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.bleeding
          ? "Bleeding,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.bruise_easily
          ? "Bruise Easily,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.anemia
          ? "Anemia,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.swollen_glands
          ? "Swollen Glands,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.diff_swal
          ? "Diff. Swal,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history?.heme_other
          ? "Other"
          : ""
      }})
                              </span>
    <span
      *ngIf="
                                  this.reviewSystemHistory
                                    ?.patient_other_review_history
                                    ?.heme_other_details
                                "
    >{{
        this.reviewSystemHistory
          ?.patient_other_review_history
          ?.heme_other_details
      }}
                              </span>
  </div>
<!--  GU-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.pain_on_urination ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.increased_frequency ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.nocturia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.incontinence ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.vaginal_discharge ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.testicular_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.blood_in_urine ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.urgency ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.flank_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.dyspareunia ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.vaginal_bleeding
                              "
  >
    <strong>GU</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.pain_on_urination
          ? "Pain On Urination,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.increased_frequency
          ? "Increased Frequency,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.nocturia
          ? "Nocturia,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.incontinence
          ? "Incontinence,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.vaginal_discharge
          ? "Vaginal Discharge,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.testicular_pain
          ? "Testicular Pain,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.blood_in_urine
          ? "Blood In Urine,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.urgency
          ? "Urgency,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.flank_pain
          ? "Flank Pain,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.dyspareunia
          ? "Dyspareunia,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.vaginal_bleeding
          ? "Vaginal Bleeding,"
          : ""
      }}
   )

    </span>
  </div>
<!--  GI-->
  <div
    class="col-12"
    *ngIf="
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.nausea ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.diarrhea ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.blooting ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history
                                  ?.blood_in_stool ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.vomitting ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.abdominal_pain ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.change_in_stool_habits ||
                                this.reviewSystemHistory
                                  ?.patient_other_review_history?.rectal_pain
                              "
  >
    <strong>GI</strong>
    <span>
                                (
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.nausea
          ? "Nausea,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.diarrhea
          ? "Diarrhea,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.blooting
          ? "Blooting,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.blood_in_stool
          ? "Blood In Stool,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.vomitting
          ? "Vomitting,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.abdominal_pain
          ? "Abdominal Pain,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.change_in_stool_habits
          ? "Change In Stool Habits,"
          : ""
      }}
      {{
        reviewSystemHistory
          ?.patient_other_review_history
          ?.rectal_pain
          ? "Rectal Pain,"
          : ""
      }}
      )

    </span>
  </div>
</div>
<ng-template #ReviewHistoryElse>
  <div
    class="row mb-5"
    *ngIf="presControl.module_type === 'static'"
  >
    <div class="col-12">
      <h4 class="para-heading-font-size">
        {{ presControl.heading }}
      </h4>
    </div>
    <div
      [style.height]="presControl.height + 'px'"
    ></div>
  </div>
</ng-template>
