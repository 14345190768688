<!--<app-mobile-header></app-mobile-header>-->

<div
  *ngIf="settingData"
  class="hospital_name_toggle d-print-none"
  [ngClass]="{ hospital_image: settingData?.homepage_image_top.url }"
>
  <div style="position: relative">
    <div class="hospital_name row">
      <!--      <img *ngIf="!hospitalName" class="pr-4" [src]="settingData?.homepage_image_top" style="width: 50px; height: 60px">-->
      <p
        *ngIf="!settingData?.homepage_image_top.url; else logoName"
        class="text-center m-0"
        style="font-weight: 500"
      >
        {{ settingData?.title }}
      </p>
      <ng-template #logoName>
        <img
          [src]="settingData?.homepage_image_top.url"
          style="
            width: auto;
            max-height: 80px;
            min-height: 80px;
            object-fit: cover;
          "
        />
      </ng-template>
    </div>
    <div class="d-flex justify-content-center">
      <div style="cursor: pointer; width: 100%" (click)="activeToggleBar()">
        <div class="bar-main text-center" aria-label="Resize">
          <i style="line-height: 12px" class="fas fa-grip-lines"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex flex-column flex-root">
  <!--begin::Page-->
  <div class="d-flex flex-row flex-column-fluid page">
    <app-sidebar></app-sidebar>

    <div
      class="d-flex flex-column flex-row-fluid wrapper setBodyLayout"
      id="kt_wrapper"
    >
      <div class="newsAlert" *ngIf="expiryData.length > 0">
        <marquee class="text-white font-weight-bolder pt-1 d-print-none">
          <span class="mr-10">Alert =></span>
          <ng-container *ngFor="let data of expiryData">
            <span class="mr-5" *ngIf="this.today == data?.billing_date"
              >Billing Date: {{ data?.billing_date }} -
              {{ data?.billing_error }}</span
            >
            <span
              class="mr-5"
              *ngIf="
                this.today > data?.billing_date && this.today <= data?.due_date
              "
              >Due Date: {{ data?.due_date }} - {{ data?.due_error }}</span
            >
            <span
              class="mr-5"
              *ngIf="
                this.today > data?.billing_date &&
                this.today > data?.due_date &&
                this.today <= data?.closure_date
              "
              >Closure Date: {{ data?.closure_date }} -
              {{ data?.closure_error }}</span
            >
          </ng-container>
        </marquee>
      </div>
      <app-topbar></app-topbar>
      <router-outlet (activate)="changeOfRoutes()"></router-outlet>
      <app-footer></app-footer>
    </div>
  </div>
</div>

<!-- Modal-->
<div
  class="modal fade"
  id="promissionsAlertModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <img src="../../../assets/media/error/allert.png" />
          <p class="font-weight-bold font-size-h5 text-dark-75 mt-3">
            you do not have permission to access this functionality.
          </p>
          <p>Please connect with IT Administration</p>
          <!--          <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>-->
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="currentUrl !== '/task-management/employees-timesheet' && currentUrl !== '/task-management/task-list'" class="icon-container d-print-none">
  <div class="class-icon" (click)="openModal()"  data-toggle="tooltip" title="Add task" data-placement="left">
   <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Devices\Headphones.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M19,16 L19,12 C19,8.13400675 15.8659932,5 12,5 C8.13400675,5 5,8.13400675 5,12 L5,16 L19,16 Z M21,16 L3,16 L3,12 C3,7.02943725 7.02943725,3 12,3 C16.9705627,3 21,7.02943725 21,12 L21,16 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
        <path d="M5,14 L6,14 C7.1045695,14 8,14.8954305 8,16 L8,19 C8,20.1045695 7.1045695,21 6,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,16 C3,14.8954305 3.8954305,14 5,14 Z M18,14 L19,14 C20.1045695,14 21,14.8954305 21,16 L21,19 C21,20.1045695 20.1045695,21 19,21 L18,21 C16.8954305,21 16,20.1045695 16,19 L16,16 C16,14.8954305 16.8954305,14 18,14 Z" fill="#000000"/>
    </g>
</svg><!--end::Svg Icon--></span>
  </div>
</div>

<!--open Modal-->
<div
  class="modal fade main-modal"
  id="viewTaskModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
  [ngClass]="{ 'scroll-active': isMainModalOpen }"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Task</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeAddTaskModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ng-container *ngIf="openAddTaskModal">
      <div class="modal-body">
        <app-view-task
        ></app-view-task>
      </div>
      </ng-container>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
