<!--begin::Content-->
<div class="d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Entry-->
  <div>
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class=" ">
      <div class="card mb-4">
        <div class="card-body body-padding">
          <form [formGroup]="packsUrlForm" (ngSubmit)="submit()">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Radiant Url</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Radiant Url"
                    formControlName="radiant_url"
                    [ngClass]="{
                      'is-invalid': isSubmit && control.radiant_url.errors
                    }"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Web Url</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Web Url"
                    formControlName="web_url"
                    [ngClass]="{
                      'is-invalid': isSubmit && control.web_url.errors
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-4 text-right">
              <div class="col-3">
                <label></label>
                <!--                <div class="form-group">-->
                <select
                  class="form-control form-control-sm"
                  formControlName="default_pacs"
                  [ngClass]="{
                    'is-invalid': isSubmit && control.default_pacs.errors
                  }"
                >
                  <option value="">Select</option>
                  <option value="radiant">Radiant</option>
                  <option value="weasis">Weasis</option>
                </select>
                <!--                </div>-->
              </div>
              <div class="col-3 text-left">
                <div class="form-group">
                  <label>Header Height</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    placeholder="Header Height"
                    formControlName="header_height"
                  />
                </div>
              </div>
              <div class="col-3 text-left">
                <div class="form-group">
                  <label>Footer Height</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    placeholder="Footer Height"
                    formControlName="footer_height"
                  />
                </div>
              </div>
              <div class="col-3 text-left">
                <div class="form-group">
                  <label>Restrict lab test cancelation</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="rad_status"
                  >
                    <option [ngValue]="''" [selected]="true" [disabled]="true">
                      Select
                    </option>
                    <option [ngValue]="'acknowledged'">Acknowledged</option>
                    <option [ngValue]="'reporting'">Reporting</option>
                    <option [ngValue]="'delivered'">Delivered</option>
                  </select>
                </div>
              </div>
              <div class="col-3 text-left">
                <div class="form-group">
                  <label>Show Worklist</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="show_worklist"
                  >
                    <option [ngValue]="0">All</option>
                    <option [ngValue]="1">Show Paid only</option>
                    <option [ngValue]="2">Show Paid + Unpaid (of Panel)</option>
                  </select>
                </div>
              </div>
              <div class="col-9 align-self-end">
                <button type="submit" class="btn btn-primary btn-sm">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card mt-5">
        <div class="card-body">
          <h4 class="mb-6">Radiology Auto Acknowledged</h4>
          <table class="table">
            <thead>
              <tr>
                <th>Department</th>
                <th>Auto Acknowledged</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of servicesControl; let i = index">
                <td>{{ data.department_title }}</td>
                <td>
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        name="refDr{{ i }}"
                        [(ngModel)]="data.default_acknowledged"
                      />
                      <span></span>
                    </label>
                  </span>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div class="row text-right mt-4">
            <div class="col-12">
              <button
                class="btn btn-primary btn-sm"
                type="button"
                (click)="updateServicesControl()"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
