import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { RouteShareService } from '../../../../../services/route-share.service';

declare var toastr: any;

@Component({
  selector: 'app-attendance-policy-list',
  templateUrl: './attendance-policy-list.component.html',
  styleUrls: ['./attendance-policy-list.component.css'],
})
export class AttendancePolicyListComponent implements OnInit {
  list: any;
  page = 1;
  totalPages: any;
  perPageValue: any;
  perPage = 25;
  employeeName = '';
  private currentId: number;
  screenName = '';

  constructor(
    private router: Router,
    private ref: ChangeDetectorRef,
    private ngxLoader: NgxUiLoaderService,
    private rosterSettings: RosterServiceService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  editIndex(indexedValue): any {
    this.router.navigate(['/roster/update-attendance-policy'], {
      queryParams: { id: indexedValue },
    });
  }
  loadPage(event): any {
    this.searchFilter();
  }
  searchFilterValue(): any {
    this.page = 1;
    this.searchFilter();
  }
  searchFilter(): any {
    this.ngxLoader.start();
    this.rosterSettings
      .searchAttendanceFlowPolicyListing(
        this.page,
        this.perPage,
        this.employeeName,
        '',
      )
      .subscribe(
        (data) => {
          this.list = data;
          this.totalPages = this.list.total_count;
          this.list = this.list.items;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
  selectPaginationLimit(event): any {
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    this.searchFilterValue();
  }
  ngOnInit(): void {
    this.perPageValue = 25;
    this.searchFilter();
  }
}
