import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RadiologyService } from '../../../services/radiology.service';
import { Router } from '@angular/router';
import { ShareDataService } from '../../../services/share-data.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-radiology-service-listing-v2',
  templateUrl: './radiology-service-listing-v2.component.html',
  styleUrls: ['./radiology-service-listing-v2.component.css'],
})
export class RadiologyServiceListingV2Component implements OnInit {
  pageDataList: any;
  page = 1;
  totalPages: any;
  perPage = 25;
  manufactureSearchValue = '';
  status = false;
  dataListingCSV: any = [];
  objOfManufactrureCSV: {};
  radiologyServiceId: any;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private radiologyService: RadiologyService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private sharedService: ShareDataService,
  ) {}
  editradiologyIndex(indexedValue): any {
    if (indexedValue) {
      this.radiologyServiceId = indexedValue;
    } else {
      this.radiologyServiceId = '';
    }
    this.sharedService.updateRadiologyService(indexedValue);
    $('#radiologyModal').modal('show');
    // this.router.navigate(['radiology/services-update-v2'], {queryParams: {id: indexedValue}});
  }
  radiologyServiceData(event): any {
    this.searchFilter();
    $('#radiologyModal').modal('hide');
  }
  loadPage(event): any {
    this.searchFilter();
  }
  searchFilterValue(): any {
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }
  addRadiologyModal(): any {
    $('#radiologyModal').modal('show');
    this.radiologyServiceId = '';
  }
  searchFilter(): any {
    this.ngxLoader.start();
    this.radiologyService
      .getAllServicesv2(this.page, this.perPage, this.manufactureSearchValue)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.pageDataList = data;
          if (this.pageDataList.meta) {
            this.totalPages = this.pageDataList.meta.total_count;
          }
          this.pageDataList = this.pageDataList.radiologies;
          this.ref.markForCheck();
          this.getListDataCSV();
          this.getCSV();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  getListDataCSV(): any {
    this.dataListingCSV = [];
    for (var i = 0; i < this.pageDataList.length; i++) {
      this.objOfManufactrureCSV = {
        'Service Code': this.pageDataList[i].service.billing_code,
        'Service Name': this.pageDataList[i].service.name,
        Contrast: this.pageDataList[i].contrast,
        'Body Region': this.pageDataList[i].body_region,
        Status: this.pageDataList[i].is_active,
      };
      this.ref.markForCheck();
      this.dataListingCSV.push(this.objOfManufactrureCSV);
    }
  }
  selectPaginationLimit(value): any {
    this.perPage = value;
    this.searchFilter();
  }
  getCSV(): any {
    const array = [Object.keys(this.dataListingCSV[0])].concat(
      this.dataListingCSV,
    );
    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join('\n');
  }
  downloadCSV(): any {
    const hiddenElement = document.createElement('a');
    hiddenElement.href =
      'data:text/csv;charset=utf-8,' + encodeURI(this.getCSV());
    hiddenElement.target = '_blank';
    hiddenElement.download = 'RadiologyServices.csv';
    hiddenElement.click();
  }
  ngOnInit(): void {
    this.searchFilter();
  }
}
