import { Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
})
export class CustomDateFormatPipe {
  formattedDate: any;
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const localStorageData = JSON.parse(
      localStorage.getItem('dateTimeFormats'),
    );
    if (localStorageData && localStorageData.date_format) {
      this.formattedDate = moment(value).format(localStorageData.date_format);
    } else {
      this.formattedDate = moment(value).format('MM-DD-YYYY');
    }
    return this.formattedDate;
  }
}
