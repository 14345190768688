import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ShareDataService } from '../../../services/share-data.service';
import { HospitalService } from '../../../services/hospital.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../services/user.service';
import { SystemControlsService } from '../../../services/system-controls.service';
declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-services-history',
  templateUrl: './services-history.component.html',
  styleUrls: ['./services-history.component.css'],
})
export class ServicesHistoryComponent implements OnInit {
  @ViewChild('serviceHistoryUpdateConfirm')
  serviceHistoryUpdateConfirm: ElementRef;
  @Input() patient: any;
  @Input() isBackButton: any;

  current_environment = environment.rorUrl;
  paymentTypeStatus = true;
  visitTypeStatus = true;
  requestedServices = false;
  all_invoices_checked = false;
  paymentType = 'unpaid';
  visitType = 'opd';
  patientServiceHistory = [];
  patientServiceHistoryBackup = [];
  // patient: any;
  currentControls: any;
  panelType = false;
  ledgersList: any;
  isChecked = false;
  screenControls = [];
  taxesData = [];
  totalTaxValue = 0;
  paymentStatus = 'paid';

  servicePaidForm = {
    patient_id: '',
    mode_of_payment: 'Cash',
    payment_details: '',
    invoice_ids: [],
    taxes: [],
    receivable: 0,
    cash: 0,
    ledger: '',
    receivable_percentage: 0,
  };
  serviceForm = {
    patient_id: '',
    mode_of_payment: 'Cash',
    payment_details: '',
    patient_services: [],
    discount: 0,
    discount_percentage: 0,
    receivable: 0,
    cash: 0,
    ledger: '',
  };
  selctedInvoceIds = [];
  selctedInvoces = [];
  invoiceControl: any;

  constructor(
    private patientService: PatientService,
    private ngxLoader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private shareDataService: ShareDataService,
    private hospitalService: HospitalService,
    private router: Router,
    private modalService: NgbModal,
    private systemControlService: SystemControlsService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.resetPageData();
    this.getInvoiceControls();
    // this.shareDataService.controlCheck.subscribe(data => {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
    });
    this.leadersAccount();
    this.fetchServicesHistory(true, true);
  }

  getTaxesOfPaymentMode(paymentMode): any{
    this.ngxLoader.start();
    this.patientService.getTaxesOfPaymentMode(paymentMode).subscribe(data => {
      this.taxesData = data;
      this.addTaxToServicesArray();
      this.ngxLoader.stop();
    }, err => {
      this.ngxLoader.stop();
      toastr.error(err.error.message);
    });
  }
  addTaxToServicesArray(): any{
    const serviceNetAmt = this.servicePaidForm.cash;
    this.totalTaxValue = 0;
    for (let j = 0; j < this.taxesData.length; j++) {
      if (this.taxesData[j].value_type === 'Percentage') {
        this.taxesData[j].totalTax = Math.ceil((serviceNetAmt * this.taxesData[j].value) / 100);
      } else {
        this.taxesData[j].totalTax = this.taxesData[j].value;
      }
      this.totalTaxValue += this.taxesData[j].totalTax;
    }
  }
  resetPageData(): any {
    this.selctedInvoceIds = [];
    this.selctedInvoces = [];
    this.all_invoices_checked = false;
    this.servicePaidForm = {
      patient_id: this.patient.id,
      mode_of_payment: 'Cash',
      payment_details: '',
      invoice_ids: [],
      taxes: [],
      receivable: 0,
      cash: 0,
      ledger: '',
      receivable_percentage: 0,
    };
  }
  fetchServicesHistory(visitType, paymentType): any {
    this.ngxLoader.start();
    this.paymentTypeStatus = paymentType;
    this.visitTypeStatus = visitType;
    this.requestedServices = false;
    if (paymentType === true) {
      this.paymentType = 'unpaid';
    } else {
      this.paymentType = 'paid';
    }
    if (visitType === true) {
      this.visitType = 'opd';
    } else {
      this.visitType = 'ipd';
    }
    this.servicePaidForm.payment_details = '';
    this.servicePaidForm.mode_of_payment = 'Cash';
    this.patientServiceHistory = [];
    this.patientServiceHistoryBackup = [];
    this.patientService
      .patientServicesInvoiceByMrnV2(
        this.patient,
        this.visitType,
        this.paymentType,
      )
      .subscribe(
        (data) => {
          this.patientServiceHistory = data.patient_service_invoices;
          this.patientServiceHistoryBackup = JSON.parse(
            JSON.stringify(data.patient_service_invoices),
          );
          this.patientServiceHistory.map((invoice) => {
            invoice.selected = false;
          });
          this.getTaxesOfPaymentMode(this.servicePaidForm.mode_of_payment);
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  select_all_invoices(event): any {
    if (event.target.checked) {
      this.patientServiceHistory.map((invoice) => {
        if (invoice.payment_status === 'unpaid') {
          invoice.selected = true;
        }
      });
      this.updateInvoicesPrice();
    } else {
      this.patientServiceHistory.map((invoice) => {
        if (invoice.payment_status === 'unpaid') {
          invoice.selected = false;
        }
      });
      this.updateInvoicesPrice();
    }
  }
  getInvoiceControls(): any {
    this.userService.getInvoiceControls().subscribe(
      (data) => {
        this.invoiceControl = data.system_billing_control;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  printFullInvoice(id, visitId, mrn): any {
    window.open(
      'pdf-reports/patient-services-invoices-v2?invoice_id=' +
        id +
        '&visit_id=' +
        visitId +
        '&mrn=' +
        mrn +
        '&patient_id=' +
        this.patient?.id,
    );
  }

  printThermalReceipt(id, visitId): any {
    if (this.currentControls.patient_services.pos_invoice_python_short) {
      window.open(
        environment.pythonUrl +
          '/report/generate_service_invoice/' +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
      );
    } else {
      window.open(
        'pdf-reports/services-invoices-short?id=' + id + '&visit_id=' + visitId,
      );
    }
  }
  printInvoiceCancelItem(id): any {
    window.open(
      this.current_environment +
        '/v4/patient_service_invoice_items/refund_report.pdf?id=' +
        id +
        '&auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      '_blank',
    );
  }
  printDialysisService(id): any {
    window.open(
      this.current_environment +
        '/v4/dialysis_patients/report.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&id=' +
        id,
    );
  }
  openPrescriptionSlip(id): any {
    if (this.currentControls !== '') {
      if (this.currentControls.patient_services.is_prescription === false) {
        window.open(
          'pdf-reports/prescription-v2?patient_id=' +
            this.patient.id +
            '&mrn=' +
            this.patient.mrn +
            '&visit_id=' +
            id,
        );
      } else {
        window.open(
          environment.rorUrl +
            '/v4/patients/' +
            this.patient.id +
            '/prescription_slip.pdf?visit_id=' +
            id +
            '&auth_token=' +
            localStorage.getItem('auth_token') +
            '&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id'),
        );
      }
    } else {
      window.open(
        environment.rorUrl +
          '/v4/patients/' +
          this.patient.id +
          '/prescription_slip.pdf?visit_id=' +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
      );
    }
  }
  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  updateServiceReceivableOfInvoices(): any {
    let totalCash = 0;
    this.patientServiceHistory.map((invoice) => {
      if (invoice.selected === true) {
        totalCash =
          totalCash + (invoice.base_price - invoice.approved_discount) + this.totalTaxValue;
      }
    });
    if (this.panelType === false) {
      if (this.servicePaidForm.cash >= totalCash) {
        this.servicePaidForm.cash = totalCash;
        this.servicePaidForm.receivable = 0;
        this.servicePaidForm.ledger = '';
      } else {
        if (
          this.servicePaidForm.cash === null ||
          this.servicePaidForm.cash === undefined
        ) {
          this.servicePaidForm.cash = 0;
        }
        this.servicePaidForm.receivable = totalCash - this.servicePaidForm.cash;
      }
    } else {
      this.serviceForm.cash = totalCash;
      this.serviceForm.receivable = 0;
      this.serviceForm.ledger = '';
    }
    let percentage = 0;
    percentage = (this.servicePaidForm.receivable / totalCash) * 100;
    this.servicePaidForm.receivable_percentage = Number(percentage.toFixed(2));
    this.patientServiceHistory.map((invoice) => {
      if (invoice.selected === true) {
        let totalCash = invoice.base_price - invoice.approved_discount;
        invoice.receivable = Math.round(
          (this.servicePaidForm.receivable_percentage * totalCash) / 100,
        );
        invoice.cash = totalCash - invoice.receivable;
      }
    });
  }
  leadersAccount(): any {
    this.ngxLoader.start();
    this.hospitalService.getLedgersV2().subscribe(
      (data) => {
        this.ledgersList = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  paymentMethodForInvoices(method, event): any {
    if (event.target.checked) {
      this.servicePaidForm.mode_of_payment = method;
    } else {
      event.target.checked = true;
    }
    this.servicePaidForm.payment_details = '';
    this.getTaxesOfPaymentMode(this.servicePaidForm.mode_of_payment);
  }
  backRoute(): any {
    this.ngxLoader.start();
    if (this.route.snapshot.queryParams.patient_from === 'settlement') {
      this.router.navigate(['/sale/final-settlement'], {
        queryParams: { patient_mrn: this.patient.mrn },
      });
    } else {
      this.router.navigate(['/patient/registration-v2'], {
        queryParams: { patient_mrn: this.patient.mrn },
      });
    }
    this.ngxLoader.stop();
  }
  unpaidToPaidServicesConfirm(): any {
    this.servicePaidForm.invoice_ids = [];
    this.patientServiceHistory.map((invoice) => {
      if (invoice.selected === true) {
        let obj = {
          id: invoice.id,
          cash: invoice.cash,
          receivable: invoice.receivable,
          net_amount: invoice.base_price - invoice.approved_discount,
        };
        this.servicePaidForm.invoice_ids.push(obj);
      }
    });
    const taxes = [];
    for (let j = 0; j < this.taxesData.length; j++) {
      const taxesObj = {
        id: this.taxesData[j].id,
        service: this.taxesData[j]?.service ?? '',
        name: this.taxesData[j].name,
        value: this.taxesData[j].value,
        value_type: this.taxesData[j].value_type,
        tax_amount: this.taxesData[j].totalTax,
        is_tax_adjustable: this.taxesData[j]?.is_tax_adjustable,
      };
      taxes.push(taxesObj);
    }
    this.servicePaidForm.taxes = taxes;
    if (this.servicePaidForm.invoice_ids.length === 0) {
      toastr.error('Select Invoices');
      return;
    }
    if (
      this.servicePaidForm.receivable > 0 &&
      this.servicePaidForm.ledger === ''
    ) {
      toastr.error('Select Ledger');
      return;
    }

    this.isChecked = false;
    this.modalService.open(this.serviceHistoryUpdateConfirm);
  }

  unpaidToPaidServices(): any {
    // if (this.isChecked === false) {
    //   toastr.error('Please give confirmation');
    //   return;
    // }
    this.modalService.dismissAll(this.serviceHistoryUpdateConfirm);

    // this.servicePaidForm.invoice_ids = this.selctedInvoceIds;
    this.ngxLoader.start();
    this.patientService
      .unpaidToPaidPatientInvoicesV2(this.servicePaidForm, this.patient)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.resetPageData();
          this.fetchServicesHistory(
            this.visitTypeStatus,
            this.paymentTypeStatus,
          );
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  selectInvoice(): any {
    setTimeout(() => {
      this.updateInvoicesPrice();
    }, 500);
  }
  updateInvoicesPrice(): any {
    this.servicePaidForm.cash = 0;
    this.servicePaidForm.receivable = 0;
    this.servicePaidForm.receivable_percentage = 0;
    this.servicePaidForm.ledger = '';
    this.patientServiceHistory.map((invoice, index) => {
      if (invoice.selected === true) {
        this.servicePaidForm.cash = this.servicePaidForm.cash + invoice.cash;
      } else {
        invoice.cash = this.patientServiceHistoryBackup[index].cash;
        invoice.receivable = this.patientServiceHistoryBackup[index].receivable;
        console.log('invoice', invoice);
        console.log(
          'patientServiceHistoryBackup',
          this.patientServiceHistoryBackup[index],
        );
      }
    });
    this.addTaxToServicesArray();
    this.servicePaidForm.cash += this.totalTaxValue;
  }
}
