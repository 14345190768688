import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddAttendancePolicyComponent } from './Configurators/add-attendance-policy/add-attendance-policy.component';
import { AttendancePolicyListComponent } from './Configurators/attendance-policy-list/attendance-policy-list.component';
import { EmployeeAttendanceReoprtComponent } from './Reports/employee-attendance-reoprt/employee-attendance-reoprt.component';
import { PrintShiftReportComponent } from './Reports/print-shift-report/print-shift-report.component';
import { AttendanceStatusPolicyComponent } from './Configurators/attendance-status-policy/attendance-status-policy.component';
import { DayWiseReportComponent } from './Reports/day-wise-report/day-wise-report.component';
import { DayWiseStatusReportComponent } from './Reports/day-wise-status-report/day-wise-status-report.component';
import { CreateRosterComponent } from './Worklist/create-roster/create-roster.component';
import { RosterApprovalComponent } from './Worklist/roster-approval/roster-approval.component';
import { UpdateRosterComponent } from './Worklist/update-roster/update-roster.component';
import { AuthorizationGuard } from '../../../services/authorization.guard';
import { CreateRosterNewComponent } from './Worklist/create-roster-new/create-roster-new.component';
import { SystemControlsComponent } from './Configurators/system-controls/system-controls.component';
import { AddGazettedHolidayComponent } from './Configurators/add-gazetted-holiday/add-gazetted-holiday.component';
import { AddWeeklyOffComponent } from './Configurators/add-weekly-off/add-weekly-off.component';
import { AttendanceAdjustmentComponent } from './Worklist/attendance-adjustment/attendance-adjustment.component';
import { RefreshAttendanceComponent } from './Worklist/refresh-attendance/refresh-attendance.component';
import { LateInEarlyOutReportComponent } from './Reports/late-in-early-out-report/late-in-early-out-report.component';
import {AutoLateInDeductionComponent} from './Worklist/auto-late-in-deduction/auto-late-in-deduction.component';

const routes: Routes = [
  {
    path: 'employee-shifts',
    component: CreateRosterComponent,
    canActivate: [AuthorizationGuard],
  },
  { path: 'employee-shifts-new', component: CreateRosterNewComponent },
  {
    path: 'shifts-approval',
    component: RosterApprovalComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'shifts-update',
    component: UpdateRosterComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'attendance-status-policy',
    component: AttendanceStatusPolicyComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'add-attendance-policy',
    component: AddAttendancePolicyComponent,
    canActivate: [AuthorizationGuard],
  },
  { path: 'update-attendance-policy', component: AddAttendancePolicyComponent },
  {
    path: 'attendance-policy',
    component: AttendancePolicyListComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'employee-attendance-report',
    component: EmployeeAttendanceReoprtComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'print',
    component: PrintShiftReportComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'day-wise-report',
    component: DayWiseReportComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'day-wise-status-report',
    component: DayWiseStatusReportComponent,
    canActivate: [AuthorizationGuard],
  },
  { path: 'system-controls', component: SystemControlsComponent },
  { path: 'add-gazetted-holiday', component: AddGazettedHolidayComponent },
  { path: 'add-weekly-off', component: AddWeeklyOffComponent },
  { path: 'attendance-adjustment', component: AttendanceAdjustmentComponent },
  {
    path: 'refresh-attendance',
    component: RefreshAttendanceComponent,
    canActivate: [AuthorizationGuard],
  },
  { path: 'early-in-late-out', component: LateInEarlyOutReportComponent },
  { path: 'auto-deduction', component: AutoLateInDeductionComponent },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RosterRoutingModule {}
