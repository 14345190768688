import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class DoctorCategoryService {
  constructor(private http: HttpClient) {}

  indexWithPagination(page, perPage): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/doctor_categories?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&active=true' +
          '&page=' +
          page +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  index(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/doctor_categories?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  indexVisitConfig(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/doctor_fee/doctor_categories/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addDoctorCategory(value, value2): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/doctor_categories?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value,
          is_active: value2,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addDoctorCategoryV2(value, value2): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/doctor_fee/doctor_categories/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value,
          is_active: value2,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDoctorCategory(value): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/doctor_categories/` + value.id,
        {
          name: value.name,
          is_active: value.is_active,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchDoctorByDepartment(departmentId): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/users/department_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&department_id=' +
          departmentId +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createVisitConfiguration(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/doctor_fee/doctor_fees/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          doctor_category_id: value.doctor_category_id,
          ward_id: value.wardId,
          unit_type: value.unit_type_id,
          visit_type_id: value.visit_type_id,
          is_active: value.status,
          description: value.description,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  UpdateVisitConfiguration(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/doctor_fee/doctor_fees/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          doctor_category_id: value.doctor_category_id,
          ward_id: value.wardId || '',
          unit_type: value.unit_type_id,
          visit_type_id: value.visit_type_id,
          is_active: value.status,
          description: value.description || '',
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  UpdatePricing(value): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/doctor_fee/update_doctor_fee_pricing/1/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          pricing: value,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getVisitConfiguration(page, perPage): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/doctor_fee/doctor_fees/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
