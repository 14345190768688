import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-opd-review-history-print',
  templateUrl: './opd-review-history-print.component.html',
  styleUrls: ['./opd-review-history-print.component.css']
})
export class OpdReviewHistoryPrintComponent implements OnInit {
@Input() reviewSystemHistory: any;
@Input() presControl: any;
  constructor() { }

  ngOnInit(): void {
  }

}
