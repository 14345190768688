import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VaccinationService } from '../../../../services/vaccination.service';

declare var $: any;

@Component({
  selector: 'app-vaccination',
  templateUrl: './vaccination.component.html',
  styleUrls: ['./vaccination.component.css'],
})
export class VaccinationComponent implements OnInit {
  @Input() patient: any;
  @Input() visitType: any;
  @Input() isAdd: boolean;

  vaccinationData = [];
  paitentVaccinationHistory = [];
  selectedVaccination = {
    vaccination_id: '',
    dose: '',
    lot_number: '',
    expiry: '',
    manufacturer: '',
    source: '',
    observations: '',
  };

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private vaccinationService: VaccinationService,
  ) {}

  ngOnInit(): void {
    this.addVaccinationHistory('');
    this.fetchVaccinationList('');
  }

  ngOnChanges(changes: SimpleChanges): any {
    this.addVaccinationHistory(changes.patient.currentValue);
    this.fetchVaccinationList(changes.patient.currentValue);
  }

  fetchVaccinationList(val): any {
    $('#VaccinationName').css('border', '1px solid #E4E6EF');
    $('#VaccinationDoes').css('border', '1px solid #E4E6EF');
    $('#VaccinationName').val(null);
    this.ngxLoader.start();
    this.selectedVaccination = {
      vaccination_id: '',
      dose: '',
      lot_number: '',
      expiry: '',
      manufacturer: '',
      source: '',
      observations: '',
    };
    this.vaccinationData = [];
    this.vaccinationService.getVaccinationTypeV2().subscribe(
      (data) => {
        this.vaccinationData = data;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updateVaccination(index): any {
    this.selectedVaccination.vaccination_id = this.vaccinationData[index].id;
    this.selectedVaccination.lot_number =
      this.vaccinationData[index].lot_number;
    this.selectedVaccination.expiry = this.vaccinationData[index].expiry_date;
    this.selectedVaccination.manufacturer =
      this.vaccinationData[index].manufacturer;
    this.selectedVaccination.source = this.vaccinationData[index].source;
  }

  addVaccination(): any {
    $('#VaccinationName').css('border', '1px solid #E4E6EF');
    $('#VaccinationDoes').css('border', '1px solid #E4E6EF');
    if (this.selectedVaccination.vaccination_id === '') {
      $('#VaccinationName').css('border', '1px solid #dc3545');
      return;
    }
    if (this.selectedVaccination.dose === '') {
      $('#VaccinationDoes').css('border', '1px solid #dc3545');
      return;
    }
    this.ngxLoader.start();
    this.vaccinationService
      .addV2(
        this.selectedVaccination.dose,
        this.selectedVaccination.vaccination_id,
        this.selectedVaccination.observations,
        this.patient,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          $('#VaccinationName').val(null);
          this.addVaccinationHistory('');
          this.selectedVaccination = {
            vaccination_id: '',
            dose: '',
            lot_number: '',
            expiry: '',
            manufacturer: '',
            source: '',
            observations: '',
          };
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  addVaccinationHistory(val): any {
    this.ngxLoader.start();
    $('#VaccinationName').val(null);
    this.selectedVaccination = {
      vaccination_id: '',
      dose: '',
      lot_number: '',
      expiry: '',
      manufacturer: '',
      source: '',
      observations: '',
    };
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.vaccinationService
      .getHistoryV2(this.patient, this.visitType)
      .subscribe(
        (data) => {
          this.paitentVaccinationHistory = data.patient_vaccinations;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
}
