<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">Lab Tests</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <a
          class="btn btn-sm btn-primary font-weight-bolder font-size-sm"
          (click)="updateTest('', '', '')"
        >
          <span class="svg-icon svg-icon-md svg-icon-white">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Add-user.svg-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  opacity="0.3"
                ></path>
                <path
                  d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                ></path>
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>
          Add Lab Test
        </a>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card gutter-b mb-3">
        <div class="card-body card-custom">
          <form class="mb-3">
            <div class="row">
              <div class="col-12 col-sm-2">
                <div class="d-block form-group">
                  <input
                    class="form-control form-control-sm"
                    placeholder="Test code"
                    name="Test Code"
                    [(ngModel)]="testCode"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-3">
                <div class="d-block form-group">
                  <input
                    class="form-control form-control-sm"
                    placeholder="Test Name"
                    name="Test Name"
                    [(ngModel)]="testName"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-3">
                <div class="form-group">
                  <div class="text-left">
                    <button
                      type="submit"
                      class="btn btn-light-primary btn-sm px-6 font-weight-bold"
                      ngbTooltip="Search Filter"
                      (click)="searchLabTest()"
                      placement="bottom"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_3"
            >
              <thead>
                <tr>
                  <th>Name / Code</th>
                  <th>Department</th>
                  <th class="text-center">Color</th>
                  <th class="text-center">Report Time</th>
                  <th class="text-center">Descriptions</th>
                  <th>Report Type?</th>
<!--                  <th class="text-center">PCR?</th>-->
<!--                  <th class="text-center">Solana?</th>-->
                  <th class="text-center">Show previous Result</th>
                  <th class="text-center" style="width: 6%">Status</th>
                  <th class="text-center">Test Format</th>
                  <th class="text-center">Update</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let element of pageDataList; let i = index">
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ element?.profile_name }}</span
                    >
                    <span
                      class="text-muted font-weight-bold text-muted d-block"
                      >{{ element?.profile_code }}</span
                    >
                  </td>
                  <td>{{ element?.lab_investigation_type?.title }}</td>
                  <td class="text-center">
                    <!--                  <span>{{element?.color || 0}}</span>-->
                    <span
                      ><div
                        *ngIf="element?.color"
                        [style.background-color]="element?.color"
                        class="color-td"
                      ></div
                    ></span>
                  </td>
                  <td class="text-center">
                    <button
                      (click)="openSelectionArea(element)"
                      class="btn btn-light-primary btn-sm"
                      type="button"
                    >
                      Time
                    </button>
                  </td>

                  <td class="text-center">
                    <button
                      (click)="openTextArea(element)"
                      class="btn btn-light-primary btn-sm"
                      type="button"
                    >
                      Descriptions
                    </button>
                  </td>
                  <td>
                    <select class="form-control form-control-sm selectpicker" data-style="btn-light-primary" [(ngModel)]="element.report_type" (change)="sendReportTypes($event.target.value, element.id, i)">
                      <option value="Culture">Culture</option>
                      <option value="PCR">PCR</option>
                      <option value="Customized">Customized</option>
                      <option value="Solana">Solana</option>
                    </select>
                  </td>

<!--                  <td class="text-center">-->
<!--                    <span class="switch switch-icon justify-content-center">-->
<!--                      <label>-->
<!--                        <input-->
<!--                          type="checkbox"-->
<!--                          [checked]="-->
<!--                            element.report_type === 'Culture' ? 'checked' : ''-->
<!--                          "-->
<!--                          id="reportType{{ element.id }}"-->
<!--                          (click)="sendReportType($event.target, element.id, i)"-->
<!--                          name="updateNewParameter"-->
<!--                        />-->
<!--                        <span></span>-->
<!--                      </label>-->
<!--                    </span>-->
<!--                  </td>-->
<!--                  <td class="text-center">-->
<!--                    <span class="switch switch-icon justify-content-center">-->
<!--                      <label>-->
<!--                        <input-->
<!--                          type="checkbox"-->
<!--                          [checked]="-->
<!--                            element.report_type === 'PCR' ? 'checked' : ''-->
<!--                          "-->
<!--                          id="reportTypePcr{{ element.id }}"-->
<!--                          (click)="-->
<!--                            sendReportTypePcr($event.target, element.id, i)-->
<!--                          "-->
<!--                          name="updateNewParameter"-->
<!--                        />-->
<!--                        <span></span>-->
<!--                      </label>-->
<!--                    </span>-->
<!--                  </td>-->
<!--                  <td class="text-center">-->
<!--                    <span class="switch switch-icon justify-content-center">-->
<!--                      <label>-->
<!--                        <input-->
<!--                          type="checkbox"-->
<!--                          [checked]="-->
<!--                            element.report_type === 'Solana' ? 'checked' : ''-->
<!--                          "-->
<!--                          id="reportTypeSolana{{ element.id }}"-->
<!--                          (click)="-->
<!--                            sendReportTypeSolana($event.target, element.id, i)-->
<!--                          "-->
<!--                          name="updateNewParameter"-->
<!--                        />-->
<!--                        <span></span>-->
<!--                      </label>-->
<!--                    </span>-->
<!--                  </td>-->
                  <td class="text-center">
                    <span class="switch switch-icon justify-content-center">
                      <label>
                        <span></span>
                        <input
                          type="checkbox"
                          [checked]="
                            element.previous_result === true ? 'checked' : false
                          "
                          id="previous_result{{ element.id }}"
                          (click)="
                            showPreviousResults($event.target, element.id)
                          "
                          name="updateNewParameter"
                        />
                        <span></span>
                      </label>
                    </span>
                  </td>
                  <td class="text-center">
                    <span *ngIf="element?.status === true">Active</span>
                    <!--                  <span *ngIf="element?.status === t">Active</span>-->
                    <span *ngIf="element?.status === false">Inactive</span>
                    <!--                  <span *ngIf="element?.status === f">Inactive</span>-->
                  </td>
                  <td class="text-center">
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                      type="button"
                      *ngIf="
                        element.report_type !== 'Culture' &&
                          element.report_type !== 'Culture-1' &&
                          element.report_type !== 'PCR';
                        else notshow
                      "
                      (click)="
                        fetchLabTest(
                          element.id,
                          element.assess_investigation_id
                        )
                      "
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                            <path
                              d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                              fill="#000000"
                            />
                          </g></svg
                        ><!--end::Svg Icon-->
                      </span>
                    </a>
                    <!--                  <button class="btn btn-primary btn-sm" *ngIf="element.report_type !== 'Culture' && element.report_type !== 'Culture-1';else notshow" (click)="navigateToLink(element.id)"><i class="fa fa-plus"></i></button>-->
                    <ng-template #notshow>-</ng-template>
                  </td>
                  <td class="text-center">
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                      (click)="updateTest(element.id, element, i)"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Design\Edit.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="5"
                              y="20"
                              width="15"
                              height="2"
                              rx="1"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      >
                    </a>
                    <!--                  <button (click)="updateTest(element)" class="btn btn-primary btn-sm" type="button">Update</button>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!--pagination-->
          <div class="pagination_main mt-4">
            <ngb-pagination
              [(page)]="page"
              (pageChange)="loadPage(page)"
              [pageSize]="perPage"
              [collectionSize]="totalPages"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="false"
              [boundaryLinks]="true"
            ></ngb-pagination>
            <div class="dropdown">
              <a
                class="btn dropdown-toggle btn-light btn-sm"
                role="button"
                id="pagination_btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="page_count">
                  {{ perPage }}
                </span>
                <span class="count_count_icon"></span>
              </a>
              <div class="dropdown-menu" aria-labelledby="pagination_btn">
                <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                  >25</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                  >50</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                  >75</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                  >100</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit('')"
                  >All</a
                >
              </div>
            </div>
          </div>
          <!--pagination End-->
        </div>
      </div>
    </div>
  </div>
</div>
<!--End::Content-->

<!--descriptions Modal-->
<div class="example-preview">
  <div>
    <ng-template #commentForm let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Test Information</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-3">
                <label class="font-weight-bold mt-2"
                  >Special Instructions</label
                >
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  [(ngModel)]="labTestDescription.special_instructions_name"
                />
              </div>
              <div class="col-5">
                <label class="mt-2 float-right">Show before test?</label>
              </div>
              <div class="col-1">
                <span class="switch switch-icon justify-content-center">
                  <label>
                    <input
                      type="checkbox"
                      [(ngModel)]="
                        labTestDescription.special_instructions_show_top
                      "
                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div class="col-12">
                <div
                  id="special_instructions"
                  class="special_instructions"
                ></div>
              </div>
            </div>

            <div class="row mt-10">
              <div class="col-3">
                <label class="font-weight-bold mt-2">Protocol</label>
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  [(ngModel)]="labTestDescription.protocol_name"
                />
              </div>
              <div class="col-5">
                <label class="mt-2 float-right">Show before test?</label>
              </div>
              <div class="col-1">
                <span class="switch switch-icon justify-content-center">
                  <label>
                    <input
                      type="checkbox"
                      [(ngModel)]="labTestDescription.protocol_show_top"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div class="col-12">
                <div id="protocol" class="protocol"></div>
              </div>
            </div>

            <div class="row mt-10">
              <div class="col-3">
                <label class="font-weight-bold mt-2">Methodology</label>
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  [(ngModel)]="labTestDescription.methodology_name"
                />
              </div>
              <div class="col-5">
                <label class="mt-2 float-right">Show before test?</label>
              </div>
              <div class="col-1">
                <span class="switch switch-icon justify-content-center">
                  <label>
                    <input
                      type="checkbox"
                      [(ngModel)]="labTestDescription.methodology_show_top"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div class="col-12">
                <div id="methodology" class="methodology"></div>
              </div>
            </div>

            <div class="row mt-10">
              <div class="col-3">
                <label class="font-weight-bold mt-2">Performed Method</label>
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  [(ngModel)]="labTestDescription.performed_method_name"
                />
              </div>
              <div class="col-5">
                <label class="mt-2 float-right">Show before test?</label>
              </div>
              <div class="col-1">
                <span class="switch switch-icon justify-content-center">
                  <label>
                    <input
                      type="checkbox"
                      [(ngModel)]="labTestDescription.performed_method_show_top"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div class="col-12">
                <div id="performed_method" class="performed_method"></div>
              </div>
            </div>

            <div class="row mt-10">
              <div class="col-3">
                <label class="font-weight-bold mt-2">Interpretation</label>
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  [(ngModel)]="labTestDescription.interpretation_name"
                />
              </div>
              <div class="col-5">
                <label class="mt-2 float-right">Show before test?</label>
              </div>
              <div class="col-1">
                <span class="switch switch-icon justify-content-center">
                  <label>
                    <input
                      type="checkbox"
                      [(ngModel)]="labTestDescription.interpretation_show_top"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div class="col-12">
                <div id="interpretation" class="interpretation"></div>
              </div>
            </div>

            <div class="row mt-10">
              <div class="col-3">
                <label class="font-weight-bold mt-2">Comments</label>
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  [(ngModel)]="labTestDescription.comments_name"
                />
              </div>
              <div class="col-5">
                <label class="mt-2 float-right">Show before test?</label>
              </div>
              <div class="col-1">
                <span class="switch switch-icon justify-content-center">
                  <label>
                    <input
                      type="checkbox"
                      [(ngModel)]="labTestDescription.comments_show_top"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div class="col-12">
                <div id="comments" class="comments"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="addLabDescription()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--time Modal-->
<div class="example-preview">
  <div>
    <ng-template #selectionForm let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Test Reporting Time</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <label>Reporting Time</label>
                  <select
                    class="form-control form-control-sm"
                    name="value_type"
                    [(ngModel)]="reportingTime.type"
                  >
                    <option value="minutes">Minutes</option>
                    <option value="hours">Hours</option>
                    <option value="days">Days</option>
                    <option value="week">Weeks</option>
                    <option value="month">Month</option>
                  </select>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <label>Reporting Value</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    name="value"
                    [(ngModel)]="reportingTime.value"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="addReportingTime()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!-- Modal-->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal Title</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">...</div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="button" class="btn btn-primary font-weight-bold">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="addLabModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="!updateId">Add Lab Test</h5>
        <h5 class="modal-title" *ngIf="updateId">Update Lab Test</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ng-container *ngIf="isOpenModal">
        <app-lab-test-modal
          [labHeadingConfigurations]="labHeadingConfigurations"
          (updatedData)="afterUpdateSuccess($event.data, $event.check)"
        ></app-lab-test-modal>
      </ng-container>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="addParameterModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal_width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assign Parameters</h5>
        <!--        <h5 class="modal-title" *ngIf="addLabTest === false">Update Lab Test</h5>-->
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 col-12">
              <div class="pickers">
                <label>Search Parameters</label>
                <select
                  class="form-control form-control-sm bootstrap-select-container"
                  data-live-search="true"
                  id="labTestsPerameter"
                  multiple
                >
                  <option value="" [selected]="true" [disabled]="true">
                    Select Parameter
                  </option>
                  <ng-container *ngFor="let lp of labParameters">
                    <option value="{{ lp.id }}" *ngIf="lp.is_active === true">
                      {{ lp?.parameter_code }}--{{ lp.title }} &nbsp; &nbsp;
                      <span
                        *ngIf="
                          lp.parameter_type === 'combo';
                          else parameterTypeElse
                        "
                      >
                        ({{
                          lp?.lab_assess_ranges[0]?.report_type_1 === "decimal"
                            ? "Number"
                            : (lp?.lab_assess_ranges[0]?.report_type_1
                              | titlecase)
                        }})
                      </span>
                      <ng-template #parameterTypeElse>
                        ({{ lp.parameter_type | titlecase }})
                      </ng-template>
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="col-md-3 col-sm-12 col-12 d-flex">
              <div class="d-flex flex-column justify-content-center">
                <span>&nbsp;</span>
                <button
                  type="button"
                  (click)="setParameterCode()"
                  class="btn btn-primary btn-sm ml-2"
                >
                  Submit
                </button>
              </div>
              <div class="d-flex flex-column justify-content-center ml-5">
                <span>Add New</span>
                <a
                  class="btn btn-icon btn-light btn-hover-primary btn-sm ml-3"
                  type="button"
                  (click)="addLabParameter()"
                >
                  <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="15px"
                    height="15px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="15" height="15" />
                        <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                        <path
                          d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                          fill="#000000"
                        />
                      </g></svg
                  ><!--end::Svg Icon-->
                  </span>
                </a>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-12">
              <div class="d-flex justify-content-end">
                <div class="pickers col-md-6">
                  <label>Copy Format From</label>
                  <select
                    class="form-control form-control-sm selectpicker bootstrap-select-container"
                    id="copyParameter"
                    data-live-search="true"
                    (change)="fetchLabTestAddingParameters($event.target.value)"
                  >
                    <option value="" [selected]="true" [disabled]="true">
                      Select Test
                    </option>
                    <ng-container *ngFor="let lp of pageDataListForListing">
                      <option
                        *ngIf="
                          lp.report_type !== 'Culture' &&
                          lp.report_type !== 'Culture-1'
                        "
                        [value]="lp.id"
                      >
                        {{ lp.profile_name }}
                      </option> </ng-container
                    >0
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
        <form [formGroup]="LabParameterForm">
          <h4 class="mt-5" *ngIf="isArray">Lab Parameters</h4>
          <table
            *ngIf="isArray"
            class="table-responsive mt-5"
            style="overflow: inherit"
          >
            <thead>
              <tr>
                <th>Order</th>
                <th>Code</th>
                <th>Title</th>
                <th>UOM</th>
                <!--              <th>Specimen</th>-->
                <th>Parameter type</th>
                <th>Heading</th>
                <th>Show next to</th>
                <!--              <th class="text-center">Status</th>-->
                <th class="text-center">Action</th>
                <!--              <th class="text-center">delete</th>-->
              </tr>
            </thead>
            <tbody>
              <ng-container
                formArrayName="parametersArray"
                *ngFor="let g of labParametersArray.controls; let i = index"
              >
                <ng-container [formGroupName]="i">
                  <tr>
                    <td style="width: 10%">
                      <input
                        style="width: 90%"
                        type="number"
                        formControlName="sort_order"
                        value=""
                        class="form-control form-control-sm"
                        (keypress)="onlyNumeric($event)"
                        placeholder="Sorting"
                      />
                    </td>
                    <td style="width: 15%">
                      <span> {{ g.get("parameter_code").value || "-" }} </span>
                    </td>
                    <td style="width: 15%">
                      <span> {{ g.get("title").value || "-" }} </span>
                    </td>
                    <td style="width: 10%">
                      <span>{{ g.get("uom").value || "-" }} </span>
                    </td>

                    <td style="width: 10%">
                      <span
                        >{{ g.get("parameter_type_new").value | titlecase }}
                      </span>
                    </td>
                    <td style="width: 15%">
                      <select
                        formControlName="lab_configuration_id"
                        class="form-control form-control-sm selectpicker"
                        id="labConfig"
                        data-width="173px"
                        data-live-search="true"
                        data-container="body"
                      >
                        <option value="">Select</option>
                        <ng-container
                          *ngFor="let heading of labHeadingConfigurations"
                        >
                          <option value="{{ heading.id }}">
                            {{ heading.description }}
                          </option>
                        </ng-container>
                      </select>
                    </td>
                    <td style="width: 15%">
                      <select
                        formControlName="primary_id"
                        class="form-control form-control-sm selectpicker"
                        id="parameters{{ i }}"
                        data-width="173px"
                        data-live-search="true"
                        data-container="body"
                      >
                        <option value="">Select</option>
                        <ng-container *ngFor="let lp of labParameters">
                          <option [value]="lp.id" *ngIf="lp.is_active === true">
                            {{ lp.title }}
                          </option>
                        </ng-container>
                      </select>
                    </td>
                    <td class="text-center" style="width: 10%">
                      <div class="dropdown dropdown-inline">
                        <button
                          style="padding: 15px"
                          type="button"
                          class="btn btn-light-primary btn-icon btn-xs"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="ki ki-bold-more-ver"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a
                            class="dropdown-item cursor-pointer"
                            (click)="editParameter(g, i)"
                            *ngIf="!g.get('is_add').value"
                            >Edit</a
                          >
                          <a
                            class="dropdown-item cursor-pointer"
                            (click)="removeLabParameter(i)"
                            >Delete</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </form>
        <div class="form-group mt-5">
          <label>Test Description</label>
          <hr class="mb-0 mt-0" />
          <div id="testDescription">
            <textarea rows="3" name="parameterComment"></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer mt-5">
        <button
          type="button"
          class="btn btn-secondary btn-hover-primary btn-sm mr-2"
          data-dismiss="modal"
        >
          Close
        </button>

        <button
          type="button"
          (click)="linkLabParameters()"
          class="btn btn-primary btn-sm ml-2"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="addMainParameter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal_width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="!isUpdateParameter">Add Parameter</h5>
        <h5 class="modal-title" *ngIf="isUpdateParameter">Edit Parameter</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="showParameterModalData">
          <app-share-parameter
            (newItemEvent)="nameEventHander($event)"
            (hideValues)="modalHiden = $event"
            (fetchAllLab)="isAddParameterNew = $event"
            (returnParameter)="returnObjectParameter = $event"
            (isUpdate)="isEditParameter = $event"
            (returnUpdated)="returnUpdate = $event"
            [isUpdated]="isUpdated"
            [parameterId]="assessInvestigationID"
            [showConfirmation]="true"
          ></app-share-parameter>
        </ng-container>
      </div>
    </div>
  </div>
</div>
