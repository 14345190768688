import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { LabTestService } from '../../../services/lab-test.service';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { environment } from '../../../../environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { PatientService } from '../../../services/patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CultureConfigService } from '../../../services/culture-config.service';
import { LabconfigurationService } from '../../../services/labconfiguration.service';
import * as moment from 'moment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
// import {environment} from '../../../../environments/environment.prod';

declare var $: any;
declare var toastr: any;
declare var tinymce: any;
@Component({
  selector: 'app-lab-reports-update',
  templateUrl: './lab-reports-update.component.html',
  styleUrls: ['./lab-reports-update.component.css'],
})
export class LabReportsUpdateComponent implements OnInit {
  @ViewChild('rejectLabTestModal') rejectLabTestModal: ElementRef;
  @ViewChild('reportsModal') reportsModal: ElementRef;
  // @ViewChild('cultureReportsModal') cultureReportsModal: ElementRef;
  ngbModalStatic: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'xxl',
  };
  modalType = '';
  departments = [];

  investigations = [];
  searchKey: string;
  reportType = [];
  current_environment: string;

  cultureReportsForm: UntypedFormGroup;
  labServicesForm: UntypedFormGroup;
  subDepartments = [];
  patient: any;
  patient_full_name: any;
  patient_gender_age: any;
  default_patient_mrn: any;
  cultureReportSubmitted = false;
  cultureList = [];
  rejectedSampleId = [];
  rejectedSampleReason = '';
  labTestListing = [];
  labConfigurations = [];
  activeLabEntry: any;
  pathologistRemarks = '';
  cultureListSensitivity = [];
  cultureGroupArray = [];
  disableBtn = false;

  filterType = 'All';
  page = 1;
  totalPages: any;
  perPage = 25;
  patientLabTestListing = [];
  constructor(
    private hospitalDoctorsService: HospitaldoctorService,
    private ngxService: NgxUiLoaderService,
    private departmentService: DepartmentsbyhospitalService,
    private labTestService: LabTestService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private cultureConfigService: CultureConfigService,
    private labService: LabconfigurationService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): any {
    // const today = new Date() ;
    // const yesterday = new Date();
    // yesterday.setDate(yesterday.getDate() - 1);
    // const formttedYesterday = date.format(yesterday, 'YYYY-MM-DDThh:mm');
    // const formattedToday = date.format(today, 'YYYY-MM-DDThh:mm');

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    $('#fromDate').datetimepicker({
      defaultDate: yesterday,
      format: 'DD-MM-YYYY h:mm a',
      autoClose: true,
    });
    const previousDateTime = moment(yesterday).format('DD-MM-YYYY h:mm a');
    $('#fromDate').val(previousDateTime);
    $('#toDate').datetimepicker({
      defaultDate: new Date(),
      format: 'DD-MM-YYYY h:mm a',
    });
    const currentTime = moment().format('DD-MM-YYYY h:mm a');
    $('#toDate').val(currentTime);

    this.labServicesForm = this.formBuilder.group({
      from_date: [previousDateTime, Validators.required],
      to_date: [currentTime, Validators.required],
      patient_id: ['', Validators.required],
      department_id: [''],
      parent_department_id: [''],
      sub_department: [''],
      type: [''],
      lab_id: [''],
    });

    this.cultureReportsForm = this.formBuilder.group({
      report_type: ['Culture'],
      id: [''],
      order_id: [''],
      lab_test_detail_id: [''],
      culture_plate: this.formBuilder.array([]),
      free_field: this.formBuilder.array([]),
      gram_stain: this.formBuilder.array([]),
      zn_stain: this.formBuilder.array([]),
      fungal_stain: this.formBuilder.array([]),
      final_report: this.formBuilder.array([]),
      microscopy: this.formBuilder.array([]),
      culture: this.formBuilder.array([]),
      culture_specimen: this.formBuilder.array([]),
      sensitivity: this.formBuilder.array([]),
      reports_comment: this.formBuilder.array([]),
    });

    // this.cultureReportsForm = this.formBuilder.group({
    //   report_type: ['Culture'],
    //   id: [''],
    //   order_id: [''],
    //   lab_test_detail_id: [''],
    //   culture_plate: this.formBuilder.array([]),
    //   free_field: this.formBuilder.array([]),
    //   gram_stain: this.formBuilder.array([]),
    //   final_report: this.formBuilder.array([]),
    //   culture: this.formBuilder.array([]),
    //   culture_specimen: this.formBuilder.array([]),
    //   microscopy: this.formBuilder.array([]),
    // });

    this.patient_full_name = '';
    this.patient_gender_age = '';
    this.default_patient_mrn = '';
    this.current_environment = environment.rorUrl;
    this.searchInvoices();
    this.getDepartmentList();
    this.getCultureList();
    this.getCultureSensitivityList();
    this.fetchLabConfigurators();
  }

  get reports_culture_plate(): any {
    return this.cultureReportsForm.get('culture_plate') as UntypedFormArray;
  }
  get reports_free_field(): any {
    return this.cultureReportsForm.get('free_field') as UntypedFormArray;
  }
  get reports_gram_stain(): any {
    return this.cultureReportsForm.get('gram_stain') as UntypedFormArray;
  }
  get reports_zn_stain(): any {
    return this.cultureReportsForm.get('zn_stain') as UntypedFormArray;
  }
  get reports_fungal_stain(): any {
    return this.cultureReportsForm.get('fungal_stain') as UntypedFormArray;
  }
  get reports_final_report(): any {
    return this.cultureReportsForm.get('final_report') as UntypedFormArray;
  }
  get reports_microscopy(): any {
    return this.cultureReportsForm.get('microscopy') as UntypedFormArray;
  }
  get reports_culture(): any {
    return this.cultureReportsForm.get('culture') as UntypedFormArray;
  }
  get reports_specimen(): any {
    return this.cultureReportsForm.get('culture_specimen') as UntypedFormArray;
  }
  get reports_sensitivity(): any {
    return this.cultureReportsForm.get('sensitivity') as UntypedFormArray;
  }
  get reports_comment(): any {
    return this.cultureReportsForm.get('reports_comment') as UntypedFormArray;
  }

  fetchLabConfigurators(): any {
    this.ngxService.start();
    this.labService.getLabConfigurators().subscribe(
      (data) => {
        console.log(data);
        this.labConfigurations = data.lab_configurations;
        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.message);
      },
    );
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  count(string, char): any {
    var re = new RegExp(char, 'gi');
    return string.match(re).length;
  }

  isNumberKey(e): any {
    var charCode = e.which ? e.which : e.keyCode;

    if (e.target.value.includes('.')) {
      if (e.key === '.') {
        return false;
      }
    }

    if (
      charCode !== 190 &&
      charCode !== 96 &&
      charCode !== 97 &&
      charCode !== 98 &&
      charCode !== 99 &&
      charCode !== 100 &&
      charCode !== 101 &&
      charCode !== 102 &&
      charCode !== 103 &&
      charCode !== 104 &&
      charCode !== 105 &&
      charCode !== 46 &&
      charCode !== 110 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    ) {
      return false;
    }
    return true;
  }

  getDepartmentList(): any {
    this.departmentService.index().subscribe(
      (data) => {
        this.departments = data.departments;
      },
      (err) => {},
    );
  }
  getSubDepartment(value): any {
    this.subDepartments = [];
    this.labServicesForm.patchValue({
      department_id: '',
    });
    if (value !== '') {
      this.ngxService.start();
      this.departmentService.parent_dept(value).subscribe(
        (data) => {
          this.subDepartments = data.departments;
          this.ngxService.stop();
        },
        (err) => {
          this.ngxService.stop();
        },
      );
    }
  }

  searchInvoices(): any {
    this.labServicesForm.patchValue({
      from_date: $('#fromDate').val() || '',
      to_date: $('#toDate').val() || '',
      type: '',
    });
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }

  filtersInvoices(filter): any {
    this.labServicesForm.patchValue({
      from_date: $('#fromDate').val(),
      to_date: $('#toDate').val(),
      type: filter,
    });
    if (filter === '') {
      this.filterType = 'All';
    }
    if (filter === 'opd') {
      this.filterType = 'OPD';
    }
    if (filter === 'ipd') {
      this.filterType = 'IPD';
    }
    if (filter === 'urgent') {
      this.filterType = 'Urgent';
    }
    if (filter === 'returned') {
      this.filterType = 'Returned';
    }
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }
  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.searchFilter();
  }
  loadPage(event): any {
    this.searchFilter();
  }

  searchFilter(): any {
    this.ngxService.start();
    this.labTestService
      .verifiedOrDeliveredOrders(
        this.page,
        this.perPage,
        this.labServicesForm.value,
      )
      .subscribe(
        (data) => {
          // this.investigations = data.orders;
          // this.updateFilters('all-btn');
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          if (data.orders) {
            this.labTestListing = data.orders;
          } else {
            this.labTestListing = [];
          }
          this.ngxService.stop();
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }
  getPendingResults(mrn): any {
    this.patientLabTestListing = [];
    this.labTestService.verifiedOrDeliveredOrdersByMrn(mrn).subscribe(
      (data) => {
        this.patientLabTestListing = data.orders;
      },
      (err) => {
        this.ngxService.stop();
      },
    );
  }
  getReportDetail(id): any {
    this.destroyTextEditor();
    this.ngxService.start();
    this.labTestService.fetchReportDetails(id).subscribe(
      (data) => {
        this.ngxService.stop();
        if ($('.modal-body-report').length === 0) {
          this.modalService.open(this.reportsModal, this.ngbModalStatic);
        }
        this.addReports(data.lab_order_detail);
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.message);
      },
    );
  }

  addReports(element): any {
    this.disableBtn = false;
    this.reportType = [];
    this.pathologistRemarks = '';
    this.activeLabEntry = element;
    this.pathologistRemarks = '';
    if (element.lab_test_details.length > 0) {
      this.pathologistRemarks = element.lab_test_details[0].pathologist_remarks;
    }
    if (
      element.lab_investigation.report_type === 'Culture' ||
      element.lab_investigation.report_type === 'Culture-1'
    ) {
      this.clearAllCultureForm();
      this.cultureReportsForm.reset();
      this.cultureReportsForm.controls.report_type.setValue(
        element.lab_investigation.report_type,
      );
      console.log('element', element);
      setTimeout(() => {
        for (let i = 0; i < element.lab_test_details.length; i++) {
          if (element.lab_test_details[i].culture_plate) {
            for (
              let j = 0;
              j < element.lab_test_details[i].culture_plate.length;
              j++
            ) {
              this.reports_culture_plate.push(
                this.addReportsCulturePlates(
                  element.lab_test_details[i].culture_plate[j],
                ),
              );
            }
          }
          if (element.lab_test_details[i].free_field) {
            for (
              let j = 0;
              j < element.lab_test_details[i].free_field.length;
              j++
            ) {
              this.reports_free_field.push(
                this.addReportsFreeFields(
                  element.lab_test_details[i].free_field[j],
                ),
              );
            }
          }
          if (element.lab_test_details[i].gram_stain) {
            for (
              let j = 0;
              j < element.lab_test_details[i].gram_stain.length;
              j++
            ) {
              this.reports_gram_stain.push(
                this.addReportsGramStain(
                  element.lab_test_details[i].gram_stain[j],
                ),
              );
            }
          }
          if (element.lab_test_details[i].zn_stain) {
            for (
              let j = 0;
              j < element.lab_test_details[i].zn_stain.length;
              j++
            ) {
              this.reports_zn_stain.push(
                this.addReportsZnStain(element.lab_test_details[i].zn_stain[j]),
              );
            }
          }
          if (element.lab_test_details[i].fungal_stain) {
            for (
              let j = 0;
              j < element.lab_test_details[i].fungal_stain.length;
              j++
            ) {
              this.reports_fungal_stain.push(
                this.addReportsFungalStain(
                  element.lab_test_details[i].fungal_stain[j],
                ),
              );
            }
          }
          if (element.lab_test_details[i].final_report) {
            for (
              let j = 0;
              j < element.lab_test_details[i].final_report.length;
              j++
            ) {
              this.reports_final_report.push(
                this.addReportsFinalReport(
                  element.lab_test_details[i].final_report[j],
                ),
              );
            }
          }
          if (element.lab_test_details[i].microscopy) {
            for (
              let j = 0;
              j < element.lab_test_details[i].microscopy.length;
              j++
            ) {
              this.reports_microscopy.push(
                this.addReportsMicroscopy(
                  element.lab_test_details[i].microscopy[j],
                ),
              );
            }
          }
          if (element.lab_test_details[i].reports_comment) {
            for (
              let j = 0;
              j < element.lab_test_details[i].reports_comment.length;
              j++
            ) {
              this.reports_comment.push(
                this.addReportsReportsComments(
                  element.lab_test_details[i].reports_comment[j],
                ),
              );
            }
          }
          if (element.lab_test_details[i].sensitivity) {
            for (
              let j = 0;
              j < element.lab_test_details[i].sensitivity.length;
              j++
            ) {
              this.reports_sensitivity.push(
                this.addReportsSensitivity(
                  element.lab_test_details[i].sensitivity[j],
                ),
              );
              var type = element.lab_test_details[i].sensitivity[j].type;
              this.cultureGroupArray[j] = this.cultureListSensitivity.filter(
                (t) => t.id === Number(type),
              )[0].groups;

              for (
                let x = 0;
                x <
                element.lab_test_details[i].sensitivity[j]
                  .sensitivity_resistance.length;
                x++
              ) {
                const control = this.reports_sensitivity.controls[j].get(
                  'sensitivity_resistance',
                ) as UntypedFormArray;
                control.push(
                  this.addReportsSensitivityResistance(
                    element.lab_test_details[i].sensitivity[j]
                      .sensitivity_resistance[x],
                  ),
                );
              }
            }
          }
          if (element.lab_test_details[i].culture) {
            for (
              let j = 0;
              j < element.lab_test_details[i].culture.length;
              j++
            ) {
              this.reports_culture.push(
                this.addReportsCulture(element.lab_test_details[i].culture[j]),
              );

              // for ( var x = 0; x < element.lab_test_details[i].culture[j].reports_comment.length; x++ ) {
              //   let control = <FormArray>this.reports_culture.controls[j].get('reports_comment');
              //   control.push(this.addReportsReportsComments(element.lab_test_details[i].culture[j].reports_comment[x]));
              // }
              // for ( var x = 0; x < element.lab_test_details[i].culture[j].sensitivity.length; x++ ) {
              //   let control = <FormArray>this.reports_culture.controls[j].get('sensitivity');
              //   control.push(this.addReportsSensitivity(element.lab_test_details[i].culture[j].sensitivity[x]));
              // }
            }
          }
          if (element.lab_test_details[i].culture_specimen) {
            for (
              let j = 0;
              j < element.lab_test_details[i].culture_specimen.length;
              j++
            ) {
              this.reports_specimen.push(
                this.addReportsSpecimen(
                  element.lab_test_details[i].culture_specimen[j],
                ),
              );
              // this.reports_specimen.push(this.addReportsSpecimen(element.sample_taken_from_id));
            }
          }
        }
      }, 1000);
      this.cultureReportsForm.controls.id.setValue(element.id);
      this.cultureReportsForm.controls.order_id.setValue(element.order_id);
      this.cultureReportsForm.controls.lab_test_detail_id.setValue(
        element.lab_test_details[0].id,
      );
      // this.modalService.open(this.cultureReportsModal, this.ngbModalStatic);
      this.modalType = 'cultureReportsModal';
      console.log('reports_culture', this.reports_culture);
    } else {
      for (var i = 0; i < element.lab_test_details.length; i++) {
        console.log(i);
        if (element.lab_test_details[i].report_type === 'single') {
          var obj = {
            report_type: 'single',
            value: element.lab_test_details[i].value,
            lab_test_detail_id: element.lab_test_details[i].id,
            caption: element.lab_test_details[i].caption,
            unit: element.lab_test_details[i].unit,
            specimen: element.lab_test_details[i].specimen,
            range: element.lab_test_details[i].range,
            range_start: element.lab_test_details[i].range_start,
            range_end: element.lab_test_details[i].range_end,
            heading: element.lab_test_details[i].heading || '',
            super_heading: element.lab_test_details[i].super_heading || '',
            primary_id: element.lab_test_details[i].primary_id,
            sort_order: element.lab_test_details[i].sort_order,
            lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
            range_comments: element.lab_test_details[i].range_comments,
            range_type: element.lab_test_details[i].range_type,
            id: element.id,
          };
          this.reportType.push(obj);
        }
        if (element.lab_test_details[i].report_type === 'text') {
          var objtext = {
            report_type: 'text',
            heading: element.lab_test_details[i].heading,
            lab_test_detail_id: element.lab_test_details[i].id,
            caption: element.lab_test_details[i].caption,
            unit: element.lab_test_details[i].unit,
            specimen: element.lab_test_details[i].specimen,
            range: element.lab_test_details[i].range,
            super_heading: element.lab_test_details[i].super_heading || '',
            primary_id: element.lab_test_details[i].primary_id,
            sort_order: element.lab_test_details[i].sort_order,
            lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
            range_comments: element.lab_test_details[i].range_comments,
            id: element.id,
          };
          this.reportType.push(objtext);
        }
        if (element.lab_test_details[i].report_type === 'editor') {
          var objtext = {
            report_type: 'editor',
            heading: element.lab_test_details[i].heading,
            lab_test_detail_id: element.lab_test_details[i].id,
            caption: element.lab_test_details[i].caption,
            unit: element.lab_test_details[i].unit,
            specimen: element.lab_test_details[i].specimen,
            range: element.lab_test_details[i].range,
            super_heading: element.lab_test_details[i].super_heading || '',
            primary_id: element.lab_test_details[i].primary_id,
            sort_order: element.lab_test_details[i].sort_order,
            lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
            range_comments: element.lab_test_details[i].range_comments,
            id: element.id,
          };
          this.reportType.push(objtext);
        }
        if (element.lab_test_details[i].report_type === 'combo') {
          var objcombo = {
            report_type: 'combo',
            report_type_1: element.lab_test_details[i].report_type_1,
            report_type_2: element.lab_test_details[i].report_type_2,
            report_type_1_result:
              element.lab_test_details[i].report_type_1_result,
            report_type_2_result:
              element.lab_test_details[i].report_type_2_result,
            report_type_1_value:
              element.lab_test_details[i].report_type_1_value,
            report_type_2_value:
              element.lab_test_details[i].report_type_2_value,
            caption: element.lab_test_details[i].caption,
            unit: element.lab_test_details[i].unit,
            specimen: element.lab_test_details[i].specimen,
            lab_test_detail_id: element.lab_test_details[i].id,
            heading: element.lab_test_details[i].heading || '',
            super_heading: element.lab_test_details[i].super_heading || '',
            primary_id: element.lab_test_details[i].primary_id,
            sort_order: element.lab_test_details[i].sort_order,
            lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
            range_comments: element.lab_test_details[i].range_comments,
            id: element.id,
          };
          this.reportType.push(objcombo);
        }
      }

      var groups = {};
      for (var i = 0; i < this.reportType.length; i++) {
        var groupName = this.reportType[i].super_heading;
        if (!groups[groupName]) {
          groups[groupName] = [];
        }
        groups[groupName].push(this.reportType[i]);
      }
      this.reportType = [];
      for (var objSort in groups) {
        this.reportType.push({ name: objSort, value: groups[objSort] });
      }
      // this.reportType.reverse();
      console.log('reportType', this.reportType);
      // this.modalService.open(this.reportsModal, this.ngbModalStatic);
      this.modalType = 'reportsModal';
      this.setTextEditorForAdd();
    }
  }
  setTextEditorForAdd(): any {
    setTimeout(() => {
      for (let i = 0; i < this.reportType.length; i++) {
        for (let j = 0; j < this.reportType[i].value.length; j++) {
          if (this.reportType[i].value[j].report_type === 'editor') {
            let reportId = '#textEditor' + i + '' + j;
            console.log('reportIdreportIdreportId', reportId);
            let reportData = this.reportType[i].value[j].heading;
            tinymce.init({
              selector: reportId,
              menubar: false,
              height: 400,
              toolbar: [
                'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
              ],
              plugins:
                'advlist autolink link image lists charmap code table lineheight',
              content_style: 'body { font-size: 16pt; font-family: Arial; }',
              setup: function (editor) {
                editor.on('init', function (e) {
                  editor.setContent(reportData);
                });
              },
            });
          }
        }
      }
      console.log('reportType for Add', this.reportType);
    }, 1000);
  }
  destroyTextEditor(): any {
    while (tinymce.editors.length > 0) {
      tinymce.remove(tinymce.editors[0]);
    }
  }
  updateSensitivityType(id, i): any {
    console.log('i', i);
    this.cultureGroupArray[i] = this.cultureListSensitivity.filter(
      (t) => t.id === Number(id),
    )[0].groups;
    console.log('cultureGroupArray', this.cultureGroupArray);
    this.reports_sensitivity.at(i).patchValue({ group: '' });
  }
  updatePathologistRemarks(value): any {
    this.pathologistRemarks = value;
    $('#pathologistRemarks').val(value);
  }
  submitResult(): any {
    // for ( var i = 0; i < this.reportType.length; i++ ) {
    //   if ( this.reportType[i].report_type === 'single' && this.reportType[i].range === '' ) {
    //     return;
    //   }
    // }
    var reports = [];
    for (var i = 0; i < this.reportType.length; i++) {
      for (var j = 0; j < this.reportType[i].value.length; j++) {
        if (this.reportType[i].value[j].report_type === 'editor') {
          let reportId = 'textEditor' + i + '' + j;
          this.reportType[i].value[j].heading = tinymce
            .get(reportId)
            .getContent();
        }
        reports.push(this.reportType[i].value[j]);
      }
    }
    this.ngxService.start();
    this.labTestService
      .updateUnVerifiedLabTest(reports, this.pathologistRemarks, false)
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.reportsModal);
          this.searchInvoices();
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }

  verifyReport(id): any {
    this.ngxService.start();
    this.labTestService.approvedUnVerifiedLabTest(id).subscribe(
      (data) => {
        toastr.success('Report verified');
        this.searchInvoices();
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.message);
      },
    );
  }

  printReport(id): any {
    window.open(
      this.current_environment +
        '/v4/orders/lab_order_report.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&id=' +
        id,
    );
  }

  getCultureList(): any {
    this.ngxService.start();
    this.cultureConfigService.index().subscribe(
      (data) => {
        this.cultureList = data.culture_configurations;
        this.ngxService.stop();
      },
      (error) => {
        this.ngxService.stop();
      },
    );
  }
  getCultureSensitivityList(): any {
    this.ngxService.start();
    this.cultureConfigService.indexByType('sensitivity_type').subscribe(
      (data) => {
        this.cultureListSensitivity = data.culture_configurations;
        this.ngxService.stop();
      },
      (error) => {
        this.ngxService.stop();
      },
    );
  }

  addReportsCulturePlates(data): any {
    return this.formBuilder.group({
      plate: [data.plate, Validators.required],
      what: [data.what, Validators.required],
      quantity: [data.quantity, Validators.required],
    });
  }
  addReportsFreeFields(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsGramStain(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsZnStain(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsFungalStain(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsFinalReport(data): any {
    return this.formBuilder.group({
      type: [data.type, Validators.required],
      medicine: [data.medicine, Validators.required],
      comments: [data.comments, Validators.required],
    });
  }
  addReportsMicroscopy(data): any {
    return this.formBuilder.group({
      type: [data.type, Validators.required],
      result: [data.result, Validators.required],
      comments: [data.comments, Validators.required],
    });
  }
  addReportsSensitivity(data): any {
    return this.formBuilder.group({
      type: [data.type, Validators.required],
      group: [data.group, Validators.required],
      sensitivity_resistance: this.formBuilder.array([]),
    });
  }

  addReportsSensitivityResistance(data): any {
    return this.formBuilder.group({
      type: [data.type],
    });
  }
  addReportsReportsComments(data): any {
    return this.formBuilder.group({
      heading: [data.heading],
      comments: [data.comments],
    });
  }
  addReportsCulture(data): any {
    return this.formBuilder.group({
      growth: [data.growth, Validators.required],
      culture: [data.culture, Validators.required],
      duration_type: [data.duration_type || 'Minutes', Validators.required],
      duration_value: [data.duration_value, Validators.required],
      temperature: [data.temperature, Validators.required],
      colony_count: [data.colony_count, Validators.required],
      atmosphere: [data.atmosphere, Validators.required],
      comments: [data.comments],
      sensitivity: this.formBuilder.array([]),
      reports_comment: this.formBuilder.array([]),
    });
  }
  addReportsSpecimen(data): any {
    return this.formBuilder.group({
      name_id: [data.name_id, Validators.required],
      value: [data.value],
    });
  }

  setReportsSpecimen(): any {
    return this.formBuilder.group({
      name_id: [this.activeLabEntry.specimen_id || '', Validators.required],
      value: [''],
    });
  }

  addFieldsInCultureWithResistance(): any {
    this.reports_culture.push(this.setReportsCulture());
    this.reports_comment.push(this.setReportsReportsComments());
    setTimeout(() => {
      for (let i = 0; i < this.reports_sensitivity.length; i++) {
        const control = this.reports_sensitivity.controls[i].get(
          'sensitivity_resistance',
        ) as UntypedFormArray;
        control.push(this.setReportsSensitivityResistance());
      }
    }, 200);
    // this.addFieldsInCulture('reports_comment');
  }
  addFieldsInCulture(type): any {
    if (type === 'reports_comment') {
      // this.reports_comment.push(this.setReportsReportsComments());
    }
    if (type === 'sensitivity') {
      this.disableBtn = true;
      this.reports_sensitivity.push(this.setReportsSensitivity());
      setTimeout(() => {
        for (let i = 0; i < this.reports_culture.length; i++) {
          const control = this.reports_sensitivity.controls[
            this.reports_sensitivity.length - 1
          ].get('sensitivity_resistance') as UntypedFormArray;
          control.push(this.setReportsSensitivityResistance());
          // this.addFieldsInCulture('reports_comment');
        }
      }, 200);
      setTimeout(() => {
        this.disableBtn = false;
      }, 500);
    }
    if (type === 'culture_plate') {
      this.reports_culture_plate.push(this.setReportsCulturePlates());
    }
    if (type === 'free_field') {
      this.reports_free_field.push(this.setReportsFreeFields());
    }
    if (type === 'gram_stain') {
      this.reports_gram_stain.push(this.setReportsGramStain());
    }
    if (type === 'zn_stain') {
      this.reports_zn_stain.push(this.setReportsZnStain());
    }
    if (type === 'fungal_stain') {
      this.reports_fungal_stain.push(this.setReportsFungalStain());
    }
    if (type === 'final_report') {
      this.reports_final_report.push(this.setReportsFinalReport());
    }
    if (type === 'microscopy') {
      this.reports_microscopy.push(this.setReportsMicroscopy());
    }
    if (type === 'culture') {
      this.reports_culture.push(this.setReportsCulture());
    }
    if (type === 'specimen') {
      if (this.reports_specimen.length === 0) {
        this.reports_specimen.push(this.setReportsSpecimen());
      }
    }
  }

  setReportsCulturePlates(): any {
    return this.formBuilder.group({
      plate: ['', Validators.required],
      what: ['', Validators.required],
      quantity: ['', Validators.required],
    });
  }
  setReportsFreeFields(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: [''],
    });
  }
  setReportsGramStain(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: [''],
    });
  }
  setReportsZnStain(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: [''],
    });
  }
  setReportsFungalStain(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: [''],
    });
  }
  setReportsFinalReport(): any {
    return this.formBuilder.group({
      type: ['', Validators.required],
      medicine: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }
  setReportsMicroscopy(): any {
    return this.formBuilder.group({
      type: ['', Validators.required],
      result: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }
  setReportsSensitivity(): any {
    return this.formBuilder.group({
      type: ['', Validators.required],
      group: ['', Validators.required],
      sensitivity_resistance: this.formBuilder.array([]),
    });
  }
  setReportsSensitivityResistance(): any {
    return this.formBuilder.group({
      type: [''],
    });
  }
  setReportsReportsComments(): any {
    return this.formBuilder.group({
      heading: [''],
      comments: [''],
    });
  }
  setReportsCulture(): any {
    return this.formBuilder.group({
      growth: ['', Validators.required],
      culture: ['', Validators.required],
      duration_type: ['Minutes', Validators.required],
      duration_value: ['', Validators.required],
      temperature: ['', Validators.required],
      colony_count: ['', Validators.required],
      atmosphere: ['', Validators.required],
      comments: [''],
      sensitivity: this.formBuilder.array([]),
      reports_comment: this.formBuilder.array([]),
    });
  }

  removeCultureIndexWithResistance(ind): any {
    for (let i = 0; i < this.reports_sensitivity.length; i++) {
      const control = this.reports_sensitivity.controls[i].get(
        'sensitivity_resistance',
      ) as UntypedFormArray;
      control.removeAt(ind);
    }
    this.reports_culture.removeAt(ind);
    this.reports_comment.removeAt(ind);
  }
  removeCultureIndex(type, ind): any {
    if (type === 'culture_plate') {
      this.reports_culture_plate.removeAt(ind);
    }
    if (type === 'free_field') {
      this.reports_free_field.removeAt(ind);
    }
    if (type === 'gram_stain') {
      this.reports_gram_stain.removeAt(ind);
    }
    if (type === 'zn_stain') {
      this.reports_zn_stain.removeAt(ind);
    }
    if (type === 'fungal_stain') {
      this.reports_fungal_stain.removeAt(ind);
    }
    if (type === 'final_report') {
      this.reports_final_report.removeAt(ind);
    }
    if (type === 'microscopy') {
      this.reports_microscopy.removeAt(ind);
    }
    if (type === 'culture') {
      // this.reports_culture.removeAt(ind);
    }
    if (type === 'specimen') {
      this.reports_specimen.removeAt(ind);
    }
    if (type === 'sensitivity') {
      this.reports_sensitivity.removeAt(ind);
    }
    if (type === 'reports_comment') {
      // this.reports_comment.removeAt(ind);
    }
  }

  clearAllCultureForm(): any {
    this.cultureReportSubmitted = false;
    while (this.reports_culture_plate.length) {
      this.reports_culture_plate.removeAt(0);
    }
    while (this.reports_free_field.length) {
      this.reports_free_field.removeAt(0);
    }
    while (this.reports_gram_stain.length) {
      this.reports_gram_stain.removeAt(0);
    }
    while (this.reports_zn_stain.length) {
      this.reports_zn_stain.removeAt(0);
    }
    while (this.reports_fungal_stain.length) {
      this.reports_fungal_stain.removeAt(0);
    }
    while (this.reports_final_report.length) {
      this.reports_final_report.removeAt(0);
    }
    while (this.reports_microscopy.length) {
      this.reports_microscopy.removeAt(0);
    }
    while (this.reports_culture.length) {
      this.reports_culture.removeAt(0);
    }
    while (this.reports_specimen.length) {
      this.reports_specimen.removeAt(0);
    }
    while (this.reports_sensitivity.length) {
      this.reports_sensitivity.removeAt(0);
    }
    while (this.reports_comment.length) {
      this.reports_comment.removeAt(0);
    }
  }

  updateFreeHeadingText(objId, valueFrom, i): any {
    let comment = '';
    if (this.cultureList.find((x) => x.id === Number(objId))) {
      comment = this.cultureList.find((x) => x.id === Number(objId)).comments;
    }
    if (valueFrom === 'free_field') {
      this.reports_free_field.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'gram_stain') {
      this.reports_gram_stain.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'zn_stain') {
      this.reports_zn_stain.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'fungal_stain') {
      this.reports_fungal_stain.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'reports_comment') {
      this.reports_comment.at(i).patchValue({ comments: comment });
    }
  }
  submitCultureReport(): any {
    console.log(this.cultureReportsForm.value);
    this.cultureReportSubmitted = true;
    if (this.cultureReportsForm.invalid) {
      return;
    }
    this.ngxService.start();
    const reports = [];
    reports.push(this.cultureReportsForm.value);
    this.labTestService
      .updateUnVerifiedLabTest(reports, $('#pathologistRemarks').val(), false)
      .subscribe(
        (data) => {
          this.cultureReportSubmitted = false;
          // this.modalService.dismissAll(this.cultureReportsModal);
          this.searchInvoices();
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }

  rejectLabTest(element): any {
    this.rejectedSampleId = [];
    this.rejectedSampleId.push(element.id);
    this.rejectedSampleReason = 'Result Error';
    this.modalService.open(this.rejectLabTestModal);
  }
  rejectLabTestFun(): any {
    this.ngxService.start();
    this.labTestService
      .rejectLabTest(this.rejectedSampleId, this.rejectedSampleReason)
      .subscribe(
        (data) => {
          this.searchInvoices();
          this.modalService.dismissAll(this.rejectLabTestModal);
          toastr.success('Order Rejected');
          this.ngxService.stop();
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }

  resetField(): any {
    $('#imageField').val('');
  }
}
