export const employeeStatus = [
  { value: 'govt_employed', label: 'Govt. Employed' },
  { value: 'private_employed', label: 'Private Employed' },
  { value: 'self_employed', label: 'Self-employed' },
  { value: 'unemployed', label: 'Unemployed' },
  { value: 'Others', label: 'Others' },
];
export const accountStatus = [
  { value: 'current', label: 'Current' },
  { value: 'saving', label: 'Saving' },
  { value: 'default', label: 'Default' },
];
export const writtenContactPreference = [
  { value: 'postal_mail', label: 'Postal Mail' },
  { value: 'mail', label: 'Email' },
];
export const CURRENCY_FORMAT_KEY =
  localStorage.getItem('currency_format') || 'PKR';
