import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LocationService } from '../../services/location.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DepartmentsbyhospitalService } from '../../services/departmentsbyhospital.service';
import { DoctorSurveyService } from '../../services/doctor-survey.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-doctor-survey',
  templateUrl: './doctor-survey.component.html',
  styleUrls: ['./doctor-survey.component.css'],
})
export class DoctorSurveyComponent implements OnInit {
  countriesList = [];
  doctorSurveyForm: UntypedFormGroup;
  isAdd = false;
  depart: any;
  showMoreData = false;
  MoreList = false;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private fb: UntypedFormBuilder,
    private doctorSurveyService: DoctorSurveyService,
  ) {
    this.doctorSurveyForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$'),
        ]),
      ],
      speciality: [''],
      medical_id_number: [''],
      primary_practice_name: [''],
      country_of_practice: [''],
      address: [''],
      work_place_phone_number: [''],
      personal_phone_number: [''],
      compensation: [''],
      can_contact: [false],
      privacy_policy_for_verification: [false],
      photo_provide_for_verification: [false],
    });
  }
  getCountries(): any {
    this.ngxLoader.start();
    this.doctorSurveyService.getCountriesDoctorSurvey().subscribe(
      (data) => {
        this.countriesList = data.countries;
        setTimeout(() => {
          $('.country').selectpicker('refresh');
          $('.country').selectpicker();
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  submitSurveyForm(): any {
    this.isAdd = true;
    if (this.doctorSurveyForm.invalid) {
      return;
    }
    if (
      !this.doctorSurveyForm.get('can_contact').value ||
      !this.doctorSurveyForm.get('privacy_policy_for_verification').value ||
      !this.doctorSurveyForm.get('photo_provide_for_verification').value
    ) {
      toastr.error('Terms and Conditions Not Accepted');
      return;
    }
    this.ngxLoader.start();
    this.doctorSurveyService
      .submitSurvey(this.doctorSurveyForm.value)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.doctorSurveyForm.reset();
          this.isAdd = false;
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
          $('.selectPickerDepartment').selectpicker();
          $('.selectPickerDepartment').selectpicker('refresh');
          $('.country').selectpicker('refresh');
          $('.country').selectpicker();
          $('#confirmationModal').modal('show');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  getDepartments(): any {
    this.depart = [];
    this.doctorSurveyService.Departmentsv2DoctorSurvey().subscribe((data) => {
      this.depart = data.departments;
      setTimeout(() => {
        $('.selectPickerDepartment').selectpicker();
        $('.selectPickerDepartment').selectpicker('refresh');
      }, 500);
    });
  }
  showMoreList(): any {
    this.MoreList = true;
  }
  showMore(): any {
    this.showMoreData = true;
  }
  ngOnInit(): void {
    this.getCountries();
    this.getDepartments();
    $('.selectpicker').selectpicker('refresh');
    $('.selectpicker').selectpicker();
  }
}
