import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RadiologyService } from '../../../services/radiology.service';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-template-print',
  templateUrl: './template-print.component.html',
  styleUrls: ['./template-print.component.css'],
})
export class TemplatePrintComponent implements OnInit {
  Id: number;
  templateData: any;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private radiologyService: RadiologyService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.Id = +params.id;
    });

    if (this.Id) {
      this.searchTemplate();
    }
  }
  searchTemplate(): any {
    this.ngxLoader.start();
    this.radiologyService.getTemplate(this.Id).subscribe(
      (data) => {
        this.templateData = data;
        setTimeout(() => {
          if (this.templateData) {
            document.getElementById('resultHTML').innerHTML = this.templateData
              .report
              ? this.templateData.report
              : '';
            document.getElementById('impressionHTML').innerHTML = this
              .templateData.impression
              ? this.templateData.impression
              : '';
            $('.data table').css({
              'max-width': '100%',
              'margin-bottom': '10px',
              'margin-top': '10px',
            });
            // $('.data table td').css('border', '0');
          }
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
  }
  printReport(): any {
    window.print();
  }
}
