<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">{{ screenName }}</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card card-custom">
        <div class="card-body">
          <form [formGroup]="labServicesForm" (ngSubmit)="searchInvoices()">
            <div class="row">
              <div class="col-md-2 col-sm-3 col-4">
                <div class="form-group">
                  <label>From Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="fromDate"
                    data-toggle="datetimepicker"
                    data-target="#fromDate"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <div class="form-group">
                  <label>To Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="toDate"
                    data-target="#toDate"
                    data-toggle="datetimepicker"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <label>Department</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm"
                      formControlName="parent_department_id"
                      (change)="getSubDepartment($event.target.value)"
                    >
                      <option value="" disabled>Select</option>
                      <option value="" *ngIf="departments.length > 0">
                        All
                      </option>
                      <option
                        [value]="department.id"
                        *ngFor="let department of departments"
                      >
                        {{ department.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-sm-3 col-4">
                <label>Sub Department</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm"
                      formControlName="department_id"
                    >
                      <option value="" disabled>Select</option>
                      <option value="" *ngIf="subDepartments.length > 0">
                        All
                      </option>
                      <option
                        [value]="department.id"
                        *ngFor="let department of subDepartments"
                      >
                        {{ department.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-4 col-6">
                <div style="display: flex">
                  <div style="flex-basis: 48%" class="mr-5">
                    <label>MRN</label>
                    <div class="form-group">
                      <div class="d-block">
                        <input
                          class="form-control form-control-sm"
                          formControlName="patient_id"
                          placeholder="MRN"
                          (keypress)="_onlyNumeric($event)"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="flex-basis: 48%">
                    <label>Lab Id</label>
                    <div class="form-group">
                      <div class="d-block">
                        <input
                          class="form-control form-control-sm"
                          placeholder="Lab Id"
                          formControlName="lab_id"
                          (keypress)="_onlyNumeric($event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--add button-->
              <div class="col-md-1 col-sm-3 col-4 text-right">
                <div class="form-group">
                  <div class="d-block">
                    <button
                      type="submit"
                      class="btn btn-primary btn-sm cus_btm"
                      ngbTooltip="Search Result"
                      placement="bottom"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card card-custom gutter-b mt-7">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Table-->
          <div class="table_top_bar mb-3">
            <div class="row">
              <div class="col-12 col-sm-12 text-right">
                <div class="dropdown dropdown-inline mr-2">
                  <button
                    (click)="filtersInvoices('')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5"
                  >
                    All
                  </button>
                  <button
                    (click)="filtersInvoices('opd')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 opd-btn"
                  >
                    OPD
                  </button>
                  <button
                    (click)="filtersInvoices('ipd')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 ipd-btn"
                  >
                    IPD
                  </button>
                  <button
                    (click)="filtersInvoices('urgent')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 urgent"
                  >
                    Urgent
                  </button>
                  <!--                  <button (click)="filtersInvoices('returned')" type="button" class="btn btn-light-primary btn-sm mr-5 returned">Returned</button>-->
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="table-responsive position-relative">
              <table
                class="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_3"
              >
                <thead>
                  <tr>
                    <th style="width: 1%"></th>
                    <th style="width: 20%">Date / Lab Id</th>
                    <th style="width: 20%">Test Name / Department</th>
                    <th style="width: 20%">Patient Name / MRN#</th>
                    <th style="width: 10%">Specimen</th>
                    <th style="width: 10%">Sample</th>
                    <th style="width: 19%">
                      <button
                        class="btn btn-light-primary btn-sm mr-2"
                        [disabled]="selectedTestArray.length === 0"
                        (click)="resetActivePatient()"
                      >
                        Clear
                      </button>
                      <button
                        class="btn btn-light-primary btn-sm"
                        [disabled]="selectedTestArray.length === 0"
                        (click)="printConfirmModal()"
                      >
                        Print
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let element of pageDataList; let i = index"
                  >
                    <tr
                      [ngClass]="{
                        returned: element.rejected === true,
                        urgent: element.urgent === true
                      }"
                    >
                      <td
                        [ngClass]="{
                          'ipd-btn': element.ipd === true,
                          'opd-btn': element.ipd === false
                        }"
                      ></td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                          >{{
                            element.collecting_time | date: "yyyy-MM-dd, h:mm a"
                          }}</span
                        >
                        <span class="text-muted font-weight-bold d-block">{{
                          element.lab_id
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                          >{{ element.test }}</span
                        >
                        <span class="text-muted font-weight-bold d-block">{{
                          element.department_name
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                          >{{ element.patient_name }}</span
                        >
                        <span class="text-muted font-weight-bold d-block">{{
                          element.patient_mrn
                        }}</span>
                      </td>
                      <td>{{ element.specimen_name }}</td>
                      <td>{{ element.sample_taken_from_name }}</td>

                      <!--                    <td>-->
                      <!--                      <button type="button" class="btn btn-light-primary btn-sm font-weight-bolder" (click)="printReport(element.id)">Print</button>-->
                      <!--                    </td>-->
                      <td>
                        <label
                          class="checkbox checkbox-sm"
                          *ngIf="
                            activePatient === '' ||
                            activePatient === element.patient_mrn
                          "
                          (change)="selectLabTest(element, $event)"
                        >
                          <input class="" type="checkbox" />
                          <span></span>
                        </label>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
            <!--pagination-->
            <div class="pagination_main">
              <ngb-pagination
                [(page)]="page"
                (pageChange)="loadPage(page)"
                [pageSize]="perPage"
                [collectionSize]="totalPages"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              ></ngb-pagination>
              <div class="dropdown">
                <a
                  class="btn dropdown-toggle btn-light btn-sm"
                  role="button"
                  id="pagination_btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="page_count">
                    {{ perPage }}
                  </span>
                  <span class="count_count_icon"></span>
                </a>
                <div class="dropdown-menu" aria-labelledby="pagination_btn">
                  <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                    >25</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                    >50</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                    >75</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                    >100</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit('')"
                    >All</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="printConfirmation"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <label class="checkbox checkbox-sm float-left mr-3">
              <input type="checkbox" [(ngModel)]="showHeader" />
              <span></span>
            </label>
            <span>You want to show Header on report</span>
          </div>
          <div class="col-12 mt-5">
            <label class="checkbox checkbox-sm float-left mr-3">
              <input type="checkbox" [(ngModel)]="showFooter" />
              <span></span>
            </label>
            <span>You want to show Footer on report</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="printReportByGroup()"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
