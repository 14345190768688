<div>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
      <!-- Vital Card -->
      <div class="card mb-4">
        <div class="card-body p-2">
          <div class="vital-table" style="overflow-x: auto">
            <table class="mb-0" style="width: 100%">
              <thead>
                <tr>
                  <td
                    style="
                      min-width: 110px;
                      max-width: 110px;
                      width: 110px;
                      min-height: 52px;
                      padding-left: 0px !important;
                    "
                  >
                    <h5>Vitals</h5>
                  </td>
                  <th
                    style="min-width: 80px; max-width: 80px; width: 80px"
                  ></th>
                  <th
                    *ngIf="vitalsListData.length === 0"
                    style="height: 52px"
                  ></th>
                  <td
                    style="min-width: 100px; max-width: 100px"
                    class="text-center vital-table-th pl-0 pr-0"
                    *ngFor="
                      let history of vitalsListData | slice: 0 : 3;
                      let i = index
                    "
                  >
                    <span
                      ><strong class="d-block">{{
                        history?.created_at | date: "dd-MM-yyyy"
                      }}</strong></span
                    ><span>{{ history?.created_at | date: "h:mm a" }}</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="min-width: 110px; max-width: 110px; width: 110px">
                    <span class="d-block">Temperature</span
                    ><span class="small-label">F</span>
                  </td>
                  <td style="min-width: 80px; max-width: 80px; width: 80px">
                    <input
                      class="form-control form-control-sm text-center"
                      [(ngModel)]="vitalsData.temperature"
                      (keydown)="isNumberKey($event)"
                      (keypress)="_onlyNumeric($event)"
                      type="text"
                      placeholder="95..107"
                    />
                  </td>
                  <td *ngIf="vitalsListData.length === 0"></td>
                  <td
                    class="text-center pl-0 pr-0"
                    *ngFor="
                      let history of vitalsListData | slice: 0 : 3;
                      let i = index
                    "
                  >
                    <span>{{ history?.temperature }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="min-width: 110px; max-width: 110px; width: 110px">
                    <span class="d-block">BP-Systolic</span
                    ><span class="small-label">mm/hg</span>
                  </td>
                  <td style="min-width: 80px; max-width: 80px; width: 80px">
                    <input
                      class="form-control form-control-sm text-center"
                      [(ngModel)]="vitalsData.bp_systolic"
                      (keydown)="isNumberKey($event)"
                      (keypress)="_onlyNumeric($event)"
                      placeholder="50..300"
                      (input)="bmiCalculate()"
                    />
                  </td>
                  <td *ngIf="vitalsListData.length === 0"></td>
                  <td
                    class="text-center pl-0 pr-0"
                    *ngFor="
                      let history of vitalsListData | slice: 0 : 3;
                      let i = index
                    "
                  >
                    <span>{{ history?.bp_systolic }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="min-width: 110px; max-width: 110px; width: 110px">
                    <span class="d-block">BP-Diastolic</span
                    ><span class="small-label">mm/hg</span>
                  </td>
                  <td style="min-width: 80px; max-width: 80px; width: 80px">
                    <input
                      class="form-control form-control-sm text-center"
                      [(ngModel)]="vitalsData.bp_diastolic"
                      (keydown)="isNumberKey($event)"
                      (keypress)="_onlyNumeric($event)"
                      placeholder="0..150"
                    />
                  </td>
                  <td *ngIf="vitalsListData.length === 0"></td>
                  <td
                    class="text-center pl-0 pr-0"
                    *ngFor="
                      let history of vitalsListData | slice: 0 : 3;
                      let i = index
                    "
                  >
                    <span>{{ history?.bp_diastolic }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="min-width: 110px; max-width: 110px; width: 110px">
                    <span class="d-block">Pulse</span
                    ><span class="small-label">/min</span>
                  </td>
                  <td style="min-width: 80px; max-width: 80px; width: 80px">
                    <input
                      class="form-control form-control-sm text-center"
                      [(ngModel)]="vitalsData.pulse"
                      (keydown)="isNumberKey($event)"
                      (keypress)="_onlyNumeric($event)"
                      type="text"
                      placeholder="40..300"
                    />
                  </td>
                  <td *ngIf="vitalsListData.length === 0"></td>
                  <td
                    class="text-center pl-0 pr-0"
                    *ngFor="
                      let history of vitalsListData | slice: 0 : 3;
                      let i = index
                    "
                  >
                    <span>{{ history?.pulse }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-12 text-right mt-4">
            <!--                    <button class="btn btn-primary btn-sm" *ngIf="vitalsListData.length > 0" (click)="printVitals()">Print</button>-->
            <div
              class="dropdown dropdown-inline"
              *ngIf="vitalsListData.length > 0"
            >
              <button
                type="button"
                class="btn btn-light-primary btn-icon btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-print"></i>
              </button>
              <div class="dropdown-menu">
                <a
                  class="dropdown-item cursor-pointer"
                  (click)="printVitals(false)"
                  >Current Visit</a
                >
                <a
                  class="dropdown-item cursor-pointer"
                  (click)="printVitals(true)"
                  >History</a
                >
              </div>
            </div>
            <button
              class="btn btn-primary btn-sm ml-2"
              (click)="addVitals('submit')"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <!-- Doctor visit Card -->
      <div class="card mb-4">
        <div class="card-body p-2">
          <h5>Doctor Visit</h5>

          <form
            [formGroup]="consultationForm"
            (ngSubmit)="onSubmitConsultations('main')"
          >
            <div class="row m-0">
              <div class="col-5">
                <label> Doctor </label>
                <div
                  [ngClass]="{ 'is-invalid': isSubmit && f.doctor_id.errors }"
                >
                  <select
                    class="form-control form-control-sm doctorSelectpicker pickers"
                    data-container="body"
                    id="ipdConsultingdoctors"
                    data-live-search="true"
                    formControlName="doctor_id"
                    (change)="fetchDoctorDepartments($event.target.value)"
                  >
                    <option value="">select</option>
                    <option *ngFor="let doc of doctor" [value]="doc.id">
                      {{ doc.first_name }} {{ doc.last_name }}
                      <span *ngIf="doc?.doctor_department"
                        >({{ doc?.doctor_department?.title }})</span
                      >
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-3 pl-0">
                <label> Visit Type </label>
                <select
                  class="form-control form-control-sm"
                  formControlName="visit_type_id"
                  [ngClass]="{
                    'is-invalid': isSubmit && f.visit_type_id.errors
                  }"
                  (change)="fetchVisitService($event.target.value)"
                >
                  <option value="">select</option>
                  <ng-container *ngFor="let look of lookups">
                    <option
                      *ngIf="look.category == 'Visit Type'"
                      [value]="look.id"
                    >
                      {{ look.key }}
                    </option>
                  </ng-container>
                </select>
              </div>
              <div class="col-3 pl-0">
                <label> Att./Visiting </label>
                <select
                  class="form-control form-control-sm"
                  formControlName="doctor_type_id"
                  [ngClass]="{
                    'is-invalid': isSubmit && f.doctor_type_id.errors
                  }"
                >
                  <option value="">select</option>
                  <option
                    *ngFor="let config of attendVisit"
                    [value]="config.id"
                  >
                    {{ config.name }}
                  </option>
                </select>
              </div>
              <div class="col-1 pl-0">
                <label> Bill </label>
                <br />
                <label class="checkbox checkbox-sm">
                  <input
                    type="checkbox"
                    formControlName="bill"
                    [checked]="false"
                  />
                  <span></span>
                </label>
              </div>
              <!--                    <div class="col p-l-0 text-align-center">-->
              <!--                      <br>-->
              <!--                      <button type="submit" class="btn btn-primary" style="margin-top: 3px;">Submit</button>-->
              <!--                    </div>-->
            </div>

            <div class="row m-0 mt-3">
              <!--                          <div class="col-12">-->
              <!--                            <textarea rows="4" class="textareas" formControlName="detail" id="consultingDetail" name="consultingDetail"></textarea>-->
              <!--                          </div>-->

              <div class="col-12 mt-3 text-right">
                <button type="submit" class="btn btn-primary btn-sm">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- Investigation Card -->
      <div class="card mb-4">
        <div class="card-body p-2">
          <h5>Investigations</h5>
          <div class="row m-0">
            <div class="col-3 pr-2"><label>Type</label></div>
            <div class="col-5 pl-0 pr-2"><label>Service Name</label></div>
            <div class="col-2 pl-0 pr-2"><label>Ref Doc</label></div>
            <!--                        <div class="col-2 pr-0"><label>Total</label></div>-->
            <div class="col-1 pl-0 pr-2 text-center"><label>Urgent</label></div>
            <div class="col-2 pr-0 pl-0 text-center"><label></label></div>
          </div>
          <div
            class="row m-0 mb-5"
            *ngFor="let service of serviceData.patient_services; let i = index"
          >
            <div class="col-3 pr-2">
              <select
                class="form-control form-control-sm"
                id="serviceTypeMain{{ i }}"
                (change)="fetchSelectedServices(i, 'main', $event.target.value)"
                [(ngModel)]="service.service_type"
              >
                <option value="" [selected]="true" [disabled]="true">
                  Select type
                </option>
                <ng-container *ngFor="let s of uniq_services">
                  <option [value]="s.name" *ngIf="s.name != 'DoctorFee'">
                    {{ s.name }}
                  </option>
                </ng-container>
              </select>
            </div>
            <div class="col-5 pl-0 pr-2">
              <div style="width: 100% !important; height: 29px">
                <select
                  class="form-control form-control-sm serviceSelectpickerMain bootstrap-select-container serviceName"
                  id="serviceNameMain{{ i }}"
                  data-container="body"
                  data-width="100%"
                  data-live-search="true"
                  (change)="
                    updateServicesDept(i, uniq_services[i], $event.target.value)
                  "
                >
                  <option value="-1" [disabled]="true">Select service</option>
                  <ng-container *ngFor="let s of services[i]">
                    <option [value]="s.id">{{ s.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-2 pl-0 pr-2">
              <div class="form-group">
                <select
                  class="form-control form-control-sm refdoctorSelectpicker pickers"
                  data-live-search="true"
                  [(ngModel)]="service.referring_doctor_id"
                  id="referringDoctorMian{{ i }}"
                >
                  <option value="">select</option>
                  <option *ngFor="let doc of refdoctor" [value]="doc.id">
                    {{ doc.first_name }} {{ doc.last_name }} ({{
                      doc.doc_dept_name
                    }})
                  </option>
                </select>
              </div>
            </div>
            <!--                        <div class="col-2 pr-0">-->
            <!--                          <input type="text" class="form-control form-control-sm" placeholder="Price" [readonly]="true" [(ngModel)]="service.price"/>-->
            <!--                        </div>-->
            <div class="col-1 pr-0 pl-0 text-center">
              <label class="checkbox checkbox-sm justify-content-center">
                <input [(ngModel)]="service.urgent" type="checkbox" />
                <span></span>
              </label>
            </div>
            <div class="col-1 pr-0 pl-0 text-center">
              <button
                type="button"
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                (click)="removeServices(i, service.price)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                      <path
                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </button>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-7"></div>
            <div class="col-3 pr-0 text-right">
              <button
                type="button"
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                (click)="addMoreServices()"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                      <path
                        d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </button>
            </div>
            <div class="col-2 text-center">
              <button
                class="btn btn-primary btn-sm"
                (click)="addServices('main')"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Investigation Card List -->
      <div class="card mb-4">
        <div class="card-body p-2">
          <div class="max-height-250 overflow-auto">
            <table class="table table-head-custom table-vertical-center">
              <thead>
                <tr>
                  <th style="width: 60%">Investigations</th>
                  <th style="width: 20%" class="text-center">Report</th>
                  <th style="width: 20%" class="text-center">Status</th>
                </tr>
              </thead>
              <tbody *ngIf="patientServiceHistory.length > 0">
                <ng-container
                  *ngFor="let history of patientServiceHistory; let i = index"
                >
                  <tr>
                    <td>
                      <span class="">{{ history?.service?.name }}</span>
                    </td>
                    <td class="text-center">
                      <span
                        *ngIf="
                          history?.service?.assignable_type === 'Radiology' &&
                          history?.is_final === true
                        "
                        (click)="
                          printRadiologyReport(history?.report_id, patient)
                        "
                      >
                        <a class="btn btn-icon btn-light-primary btn-sm mr-2">
                          <i class="flaticon-technology"></i>
                        </a>
                      </span>

                      <span
                        *ngIf="
                          history?.service?.assignable_type ===
                            'LabInvestigation' && history?.is_external === true
                        "
                        (click)="
                          viewExternalReport(history?.lab_order_detail?.id)
                        "
                      >
                        <a class="btn btn-icon btn-light-primary btn-sm mr-2">
                          <i class="flaticon-technology"></i>
                        </a>
                      </span>

                      <span
                        *ngIf="
                          history?.service?.assignable_type ===
                            'LabInvestigation' &&
                          history?.is_external === false &&
                          (history?.lab_order_detail?.status === 'Verified' ||
                            history?.lab_order_detail?.status === 'Delivered')
                        "
                        (click)="
                          printService(
                            history?.lab_order_detail_id,
                            history?.lab_order_id,
                            history?.test_code
                          )
                        "
                      >
                        <a class="btn btn-icon btn-light-primary btn-sm mr-2">
                          <i class="flaticon-technology"></i>
                        </a>
                      </span>
                      <span
                        class="cursor-pointer"
                        *ngIf="history?.consultation_id"
                        (click)="openPrescriptionSlip(history?.consultation_id)"
                      >
                        <a class="btn btn-icon btn-light-primary btn-sm mr-2">
                          <i class="flaticon-technology"></i>
                        </a>
                      </span>
                    </td>
                    <td class="text-center">
                      <span
                        class="label label-lg label-inline"
                        [ngClass]="{
                          'label-light-primary':
                            history?.service_status === 'Pending',
                          'label-light-warning':
                            history?.service_status === 'In process',
                          'label-light-success':
                            history?.service_status === 'Finalized',
                          'label-light-danger':
                            history?.service_status === 'Cancelled'
                        }"
                        *ngIf="history?.service_status !== ''"
                        >{{ history?.service_status }}</span
                      >
                      <!--                          <span class="label label-lg label-light-primary label-inline"-->
                      <!--                                *ngIf="history?.service_status === 'Pending'">Pending</span>-->
                      <!--                                <span class="label label-lg label-light-warning label-inline"-->
                      <!--                                      *ngIf="history?.service_status === 'In process'">In Process</span>-->
                      <!--                                <span class="label label-lg label-light-success label-inline"-->
                      <!--                                      *ngIf="history?.service_status === 'Finalized'">Finalized</span>-->
                      <!--                                <span class="label label-lg label-light-danger label-inline"-->
                      <!--                                  *ngIf="history?.service_status === 'Cancelled'">Cancelled</span>-->
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-6 col-sm-12 col-12 pl-4 pl-sm-4 pl-md-0 pl-lg-0"
    >
      <div class="card mb-2">
        <div class="card-body card-body-padding">
          <div class="">
            <h4 class="m-0">Diagnosis</h4>
            <!-- table-responsive -->
            <table class="table diagnosisMain">
              <thead>
                <tr>
                  <th style="width: 60%" class="pl-0">Name</th>
                  <th style="width: 35%" class="pl-0">Type</th>
                  <th style="width: 50px" class="pl-0 pt-0 text-right">
                    <button
                      type="button"
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                      (click)="addMoreDianosisMain()"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                        <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                            <path
                              d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                              fill="#000000"
                            />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let item of mainDiagnosisListAdd; let i = index"
                >
                  <tr>
                    <ng-container id="diagnosisSearching">
                      <td
                        style="width: 60%"
                        class="pl-0"
                        id="specifiedDiagnosis"
                      >
                        <div class="search_box">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="diagnosisNameMain{{ i }}"
                            name="name"
                            [(ngModel)]="item.name"
                            placeholder="Search Diagnosis Here"
                            (keyup)="getAllDiagnosisListMain(i)"
                            (click)="getAllDiagnosisListMain(i)"
                          />
                          <div class="diagnosis-search-bar" *ngIf="item.name">
                            <p
                              *ngIf="
                                allDiagnosisDataMain.length === 0 &&
                                diagnosisSearchResultMain === false
                              "
                            >
                              Searching...
                            </p>
                            <p
                              *ngIf="
                                allDiagnosisDataMain.length === 0 &&
                                diagnosisSearchResultMain === true
                              "
                            >
                              No Result Found
                            </p>
                            <span *ngIf="allDiagnosisDataMain.length > 0">
                              <p
                                *ngFor="let diagnosis of allDiagnosisDataMain"
                                (click)="setAllDiagnosisMain(diagnosis, i)"
                              >
                                {{ diagnosis.name }}
                              </p>
                            </span>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <td style="width: 35%" class="pl-0">
                      <select
                        class="form-control form-control-sm"
                        id="diagnosisTypeMain{{ i }}"
                        name="type"
                        [(ngModel)]="item.type"
                      >
                        <option value="">Type</option>
                        <option value="ProvisionalDiagnosis">
                          Provisional Diagnosis
                        </option>
                        <option value="DifferentialDiagnosis">
                          Differential Diagnosis
                        </option>
                        <option value="FinalDiagnosis">Final Diagnosis</option>
                      </select>
                    </td>
                    <td style="width: 50px" class="pl-0 text-right">
                      <button
                        type="button"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                        (click)="removeDianosisMain(i)"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x">
                          <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <path
                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              />
                              <path
                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g>
                          </svg>
                          <!--end::Svg Icon-->
                        </span>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="row">
              <div class="col-8">
                <h5 class="">Current Diagnosis</h5>
              </div>
              <div class="col-4 text-right">
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  (click)="submitMainDiagnosis()"
                >
                  Submit
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-12">
                <!--                            (click)="setAndAddDiagnosisMain(data)"-->
                <button
                  type="button"
                  class="btn btn-light-primary btn-sm m-2"
                  *ngFor="let data of paitestDiagnosisHistory; let i = index"
                >
                  {{ data?.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body p-2">
          <h5>Nursing Notes</h5>

          <form
            [formGroup]="progressNotesForm"
            (ngSubmit)="showNursingConfirmationModal()"
          >
            <div class="row m-0">
              <div class="col-12">
                <div class="form-group">
                  <label>Notes</label>
                  <textarea
                    class="form-control form-control-sm"
                    placeholder="Notes"
                    rows="4"
                    formControlName="note"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="">
              <div class="row m-0">
                <div class="col">
                  <label>Shift</label>
                </div>
                <div class="col">
                  <label>Temperature</label>
                </div>
                <div class="col">
                  <label>BP-Sys</label>
                </div>
                <div class="col">
                  <label>Pulse</label>
                </div>
              </div>

              <div class="row m-0">
                <div class="col">
                  <select
                    class="form-control form-control-sm"
                    formControlName="shift"
                  >
                    <option value="Morning">Morning</option>
                    <option value="Evening">Evening</option>
                    <option value="Night">Night</option>
                  </select>
                </div>
                <div class="col" formGroupName="vital_attributes">
                  <input
                    class="form-control form-control-sm text-center"
                    formControlName="temperature"
                    placeholder="95..107"
                  />
                </div>
                <div class="col" formGroupName="vital_attributes">
                  <input
                    class="form-control form-control-sm text-center"
                    formControlName="bp_systolic"
                    placeholder="50..300"
                  />
                </div>
                <div class="col" formGroupName="vital_attributes">
                  <input
                    class="form-control form-control-sm text-center"
                    formControlName="pulse"
                    placeholder="40..300"
                  />
                </div>
              </div>
            </div>

            <div class="row text-right mt-3 mx-0">
              <div class="col-12">
                <button class="btn btn-primary btn-sm mt-3" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body p-2">
          <h5>Doctor Notes</h5>

          <div class="row m-0">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <textarea
                  class="form-control form-control-sm"
                  placeholder="Notes"
                  rows="4"
                  [(ngModel)]="progressNotes"
                ></textarea>
              </div>
            </div>
            <div class="col-md-3 col-sm-3">
              <div class="form-group">
                <label>Shift</label>
                <select
                  class="form-control form-control-sm"
                  [(ngModel)]="progressNotesShift"
                >
                  <option value="Morning">Morning</option>
                  <option value="Evening">Evening</option>
                  <option value="Night">Night</option>
                </select>
              </div>
            </div>
            <!--            <div class="col-md-9 col-sm-9 selectOptionBottom">-->
            <!--              <div class="form-group ">-->
            <!--                <label>Doctor</label>-->
            <!--                <select class="form-control form-control-sm doctorNotes pickers" data-container="body" id="ipdConsultingdoctor"-->
            <!--                        data-live-search="true" [(ngModel)]="progressNotesDoctor">-->
            <!--                  <option [value]="d.id" *ngFor="let d of doctors">{{d.first_name}} {{d.last_name}}</option>-->
            <!--                </select>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>

          <div class="row mx-0 mb-2 text-right">
            <div class="col-12">
              <button
                class="btn btn-primary btn-sm"
                (click)="showDoctorUpdateConfirmationModal('')"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body p-2">
          <div class="row m-0">
            <div class="col-12">
              <div class="form-group tagsInput">
                <h5>Allergies</h5>
                                <select
                                  class="form-control select2"
                                  multiple="multiple"
                                  id="ipdPatientAllergiesMain"
                                >
                                </select>


<!--                <select-->
<!--                  class="form-control select2"-->
<!--                  multiple="multiple"-->
<!--                  id="ipdPatientAllergiesMain"-->
<!--                >-->
<!--                  <ng-container *ngFor="let allergies of allAllergiesListData">-->
<!--                    <option value="{{ allergies.name }}">-->
<!--                      {{ allergies.name }}-->
<!--                    </option>-->
<!--                  </ng-container>-->
<!--                </select>-->
              </div>
            </div>
          </div>

          <div class="row mx-0 mb-2 text-right">
            <div class="col-12">
              <button
                class="btn btn-primary btn-sm"
                (click)="submitPatientAllergies()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="nursingNotesModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Nursing Notes Confirmation</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex">
              <div class="form-group">
                <label
                  >Emp ID / User Name:<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Employe ID"
                  name="empId"
                  [(ngModel)]="empId"
                />
              </div>
              <div class="form-group pl-8">
                <label>User Password:<span class="text-danger">*</span></label>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="Password"
                  name=""
                  [(ngModel)]="userPassword"
                />
              </div>
            </div>
            <div class="checkbox-inline">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="Checkbox1"
                  [(ngModel)]="isConfimedNursing"
                />
                <span></span>
              </label>
              Are you sure you want to add Nursing Notes?
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          Close
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          (click)="confirmNursingNotes()"
        >
          Confirm
        </button>
        <!--        <button type="submit" [disabled]="isButtonClicked" class="btn btn-primary" (click)="deleteGeneral()">Confirm</button>-->
      </div>
    </div>
  </div>
</div>

<!--Doctor EMp Passowrd modal-->
<div
  id="doctorNotesModal"
  class="modal fade"
  data-backdrop="false"
  style="z-index: 1051 !important"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Doctor Notes Confirmation</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex">
              <div class="form-group">
                <label
                  >Emp ID / User Name:<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Employe ID"
                  name="empId"
                  [(ngModel)]="doctorEmpId"
                />
              </div>
              <div class="form-group pl-8">
                <label>User Password:<span class="text-danger">*</span></label>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="Password"
                  name=""
                  [(ngModel)]="doctorUserPassword"
                />
              </div>
            </div>
            <div class="checkbox-inline">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="Checkbox1"
                  [(ngModel)]="isConfirmedDoctor"
                />
                <span></span>
              </label>
              <span> Are you sure you want to add Doctor Notes?</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="addDoctorProgressNotes('')"
        >
          Confirm
        </button>
        <!--        <button type="submit" [disabled]="isButtonClicked" class="btn btn-primary" (click)="deleteGeneral()">Confirm</button>-->
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="duplicateServicesAllertModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation!</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label class="checkbox checkbox-sm float-left mr-3">
          <input type="checkbox" [(ngModel)]="isChecked" />
          <span></span>
        </label>
        <span
          >This service is already added within same day. Are you sure you want
          to add this again ?</span
        >
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="addServicesDuplicateConfirm()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>
