import { Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeFormat',
})
export class CustomTimeFormatPipe {
  formattedTime: any;
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const localStorageData = JSON.parse(
      localStorage.getItem('dateTimeFormats'),
    );
    if (localStorageData && localStorageData.time_format) {
      this.formattedTime = moment(value).format(localStorageData.time_format);
    } else {
      this.formattedTime = moment(value).format('h:mm a');
    }
    return this.formattedTime;
  }
}
