import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CultureConfigService } from '../../../services/culture-config.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-culture-configuration-v2',
  templateUrl: './culture-configuration-v2.component.html',
  styleUrls: ['./culture-configuration-v2.component.css'],
})
export class CultureConfigurationV2Component implements OnInit {
  updateCultureForm: UntypedFormGroup;
  updateSensitivityForm: UntypedFormGroup;
  updateCulturePlatesForm: UntypedFormGroup;
  updateFinalReportForm: UntypedFormGroup;
  updateMicroscopyForm: UntypedFormGroup;
  updateFreeFieldsForm: UntypedFormGroup;
  detectorForm: UntypedFormGroup;
  organismForm: UntypedFormGroup;
  updateReportsCommentsForm: UntypedFormGroup;

  addCultureForm: UntypedFormGroup;
  submitted = false;
  isUpdate = false;
  cultureList = [];
  reportType = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private cultureConfigService: CultureConfigService,
  ) {}

  ngOnInit(): void {
    this.addCultureForm = this.formBuilder.group({
      report_type: ['', Validators.required],
      name: ['', Validators.required],
      culture_type: ['', Validators.required],
      comments: [''],
      short_name: [''],
      status: [true],
    });
    this.updateSensitivityForm = this.formBuilder.group({
      culture_configurations: this.formBuilder.array([]),
    });
    this.updateCultureForm = this.formBuilder.group({
      culture_configurations: this.formBuilder.array([]),
    });
    this.updateCulturePlatesForm = this.formBuilder.group({
      culture_configurations: this.formBuilder.array([]),
    });
    this.updateFinalReportForm = this.formBuilder.group({
      culture_configurations: this.formBuilder.array([]),
    });
    this.updateMicroscopyForm = this.formBuilder.group({
      culture_configurations: this.formBuilder.array([]),
    });
    this.updateFreeFieldsForm = this.formBuilder.group({
      culture_configurations: this.formBuilder.array([]),
    });
    this.detectorForm = this.formBuilder.group({
      culture_configurations: this.formBuilder.array([]),
    });
    this.organismForm = this.formBuilder.group({
      culture_configurations: this.formBuilder.array([]),
    });
    this.updateReportsCommentsForm = this.formBuilder.group({
      culture_configurations: this.formBuilder.array([]),
    });
    this.getCultureList();
  }

  get cultureArray(): any {
    return this.updateCultureForm.get('culture_configurations') as UntypedFormArray;
  }
  get sensitivityArray(): any {
    return this.updateSensitivityForm.get(
      'culture_configurations',
    ) as UntypedFormArray;
  }
  get culturePlatesArray(): any {
    return this.updateCulturePlatesForm.get(
      'culture_configurations',
    ) as UntypedFormArray;
  }
  get finalReportArray(): any {
    return this.updateFinalReportForm.get(
      'culture_configurations',
    ) as UntypedFormArray;
  }
  get microscopyArray(): any {
    return this.updateMicroscopyForm.get('culture_configurations') as UntypedFormArray;
  }
  get freeFieldsArray(): any {
    return this.updateFreeFieldsForm.get('culture_configurations') as UntypedFormArray;
  }
  get detectorArray(): any {
    return this.detectorForm.get('culture_configurations') as UntypedFormArray;
  }
  get organismArray(): any {
    return this.organismForm.get('culture_configurations') as UntypedFormArray;
  }
  get reportCommentsArray(): any {
    return this.updateReportsCommentsForm.get(
      'culture_configurations',
    ) as UntypedFormArray;
  }
  setCultureValues(data): any {
    return this.formBuilder.group({
      id: [data.id],
      report_type: [data.report_type, Validators.required],
      name: [data.name, Validators.required],
      culture_type: [data.culture_type, Validators.required],
      short_name: [data.short_name],
      status: [data.status],
      comments: [data.comments],
    });
  }
  setCultureMainValues(data): any {
    return this.formBuilder.group({
      id: [data.id],
      report_type: [data.report_type, Validators.required],
      name: [data.name, Validators.required],
      culture_type: [data.culture_type, Validators.required],
      status: [data.status],
      comments: [data.comments],
      default: [data.default],
      default_name_id: [data.default_name_id || ''],
    });
  }
  onSubmit(): any {
    this.submitted = true;
    // console.log(this.addCultureForm.value);
    if (this.addCultureForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.cultureConfigService.createV2(this.addCultureForm.value).subscribe(
      (data) => {
        this.submitted = false;
        console.log(data);
        this.addCultureForm.reset();
        this.ngxLoader.stop();
        this.getCultureList();
        toastr.suuccess('Successfully Added.');
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.message);
      },
    );
  }
  getCultureList(): any {
    this.ngxLoader.start();
    this.cultureConfigService.indexV2().subscribe(
      (data) => {
        this.cultureList = data.culture_configurations;
        console.log(this.cultureList);
        while (this.sensitivityArray.length) {
          this.sensitivityArray.removeAt(0);
        }
        while (this.culturePlatesArray.length) {
          this.culturePlatesArray.removeAt(0);
        }
        while (this.finalReportArray.length) {
          this.finalReportArray.removeAt(0);
        }
        while (this.microscopyArray.length) {
          this.microscopyArray.removeAt(0);
        }
        while (this.freeFieldsArray.length) {
          this.freeFieldsArray.removeAt(0);
        }
        while (this.reportCommentsArray.length) {
          this.reportCommentsArray.removeAt(0);
        }
        while (this.cultureArray.length) {
          this.cultureArray.removeAt(0);
        }
        while (this.detectorArray.length) {
          this.detectorArray.removeAt(0);
        }
        while (this.organismArray.length) {
          this.organismArray.removeAt(0);
        }

        for (let i = 0; i < this.cultureList.length; i++) {
          if (this.cultureList[i].report_type === 'sensitivity') {
            this.sensitivityArray.push(
              this.setCultureMainValues(this.cultureList[i]),
            );
          }
          if (this.cultureList[i].report_type === 'culture_plates') {
            this.culturePlatesArray.push(
              this.setCultureValues(this.cultureList[i]),
            );
          }
          if (this.cultureList[i].report_type === 'final_report') {
            this.finalReportArray.push(
              this.setCultureValues(this.cultureList[i]),
            );
          }
          if (this.cultureList[i].report_type === 'microscopy') {
            this.microscopyArray.push(
              this.setCultureValues(this.cultureList[i]),
            );
          }
          if (this.cultureList[i].report_type === 'free_fields') {
            this.freeFieldsArray.push(
              this.setCultureValues(this.cultureList[i]),
            );
          }
          if (this.cultureList[i].report_type === 'detector') {
            this.detectorArray.push(this.setCultureValues(this.cultureList[i]));
          }
          if (this.cultureList[i].report_type === 'organism') {
            this.organismArray.push(this.setCultureValues(this.cultureList[i]));
          }
          if (this.cultureList[i].report_type === 'report_comments') {
            this.reportCommentsArray.push(
              this.setCultureValues(this.cultureList[i]),
            );
          }
          if (this.cultureList[i].report_type === 'culture') {
            this.cultureArray.push(this.setCultureValues(this.cultureList[i]));
          }
        }
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }
  get controls(): any {
    return this.addCultureForm.controls;
  }
  onUpdate(form): any {
    this.isUpdate = true;
    this.ngxLoader.start();
    this.cultureConfigService
      .updateEhmV2(form.value.culture_configurations)
      .subscribe(
        (data) => {
          this.isUpdate = false;
          this.ngxLoader.stop();
          toastr.success('Successfully Update.');
        },
        (error) => {
          this.isUpdate = false;
          this.ngxLoader.stop();
          toastr.error(error.error.message);
        },
      );
  }
  updateCultureType(value): any {
    this.reportType = value;
    this.addCultureForm.controls.culture_type.setValue('');
    this.addCultureForm.controls.name.setValue('');
    this.addCultureForm.controls.short_name.setValue('');
    this.addCultureForm.controls.comment.setValue('');
  }
  updateCultureName(): any {
    this.addCultureForm.get('name').patchValue('');
  }
  selectSideTabs(id, removeClass): any {
    $('.' + removeClass).removeClass('active');
    $('#' + id).addClass('active');
    // $('#'+id).click();
    // const element: HTMLElement = document.getElementById(id) as HTMLElement;
    // element.click();
  }
}
