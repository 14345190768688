<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div
    class="subheader py-2 py-lg-6 subheader-solid d-print-none"
    id="kt_subheader"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-baseline justify-content-between flex-wrap mr-5 w-100"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::Card-->
      <div class="card card-custom gutter-b d-print-none">
        <div class="card-body">
          <div class="row">
            <!--Starting Date (Day, Month , Year):-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Start Date<span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm start_date"
                  placeholder="Start Date"
                  readonly="readonly"
                  [(ngModel)]="startDate"
                />
              </div>
            </div>
            <!-- End Date (Day, Month , Year)  -->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>End Date<span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm end_date"
                  placeholder="End Date"
                  readonly="readonly"
                  [(ngModel)]="endDate"
                />
              </div>
            </div>
            <!--Department-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="d-block form-group">
                <label>Department: <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-container="body"
                  data-live-search="true"
                  id="department"
                  name="department"
                  (change)="getEmployeesOfDepartment($event.target.value)"
                  [(ngModel)]="department"
                >
                  <option value="">Select Department</option>
                  <ng-container *ngFor="let dep of depart">
                    <option [value]="dep.id">{{ dep.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--Employee Of Department-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="d-block form-group">
                <label>Employee:</label>
                <select
                  class="form-control form-control-sm selectpicker"
                  multiple
                  data-live-search="true"
                  id="employeeDepartmentList"
                  data-container="body"
                  name="employee"
                  [(ngModel)]="employeesOfSelectedDepartment"
                >
                  <option value="" [disabled]="true">Select Employee</option>
                  <ng-container *ngFor="let emp of employeesOfDepart">
                    <option [value]="emp.id">
                      {{ emp?.employee_file_no }} -{{ emp?.first_name }}
                      {{ emp?.last_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <!--submit button-->
          <div style="text-align: right">
            <button
              class="btn btn-primary btn-sm"
              (click)="refreshAttendance()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
