import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

// import {Department, Patient, User} from '../../_models';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { LabTestService } from '../../../services/lab-test.service';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { PatientService } from '../../../services/patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CultureConfigService } from '../../../services/culture-config.service';
import { ChangeDetectorRef } from '@angular/core';
import { LabconfigurationService } from '../../../services/labconfiguration.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
declare var toastr: any;
declare var tinymce: any;
@Component({
  selector: 'app-lab-reports',
  templateUrl: './lab-reports.component.html',
  styleUrls: ['./lab-reports.component.css'],
})
export class LabReportsComponent implements OnInit {
  @ViewChild('reportsModal') reportsModal: ElementRef;
  @ViewChild('reportsModalUpdate') reportsModalUpdate: ElementRef;
  @ViewChild('cultureReportsModal') cultureReportsModal: ElementRef;
  ngbModalStatic: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'xxl',
  };
  modalType = '';
  departments = [];

  investigations = [];
  displayedColumns: string[] = [
    'id',
    'date',
    'test',
    'department',
    'mrn',
    'patient_name',
    'report',
  ];
  searchKey: string;
  reportType = [];
  reportTypeUpdate: any;

  labServicesForm: UntypedFormGroup;
  cultureReportsForm: UntypedFormGroup;
  subDepartments = [];
  patient: any;
  patient_full_name: any;
  patient_gender_age: any;
  default_patient_mrn: any;
  cultureReportSubmitted = false;
  cultureList = [];
  cultureListSensitivity = [];
  labTestListing: any;
  patientLabTestListing: any;
  labConfigurations = [];
  activeLabEntry: any;
  pathologistRemarks = '';
  disableBtn = false;
  cultureGroupArray = [];

  filterType = 'All';
  page = 1;
  totalPages: any;
  perPage = 25;
  isClassActive = 2;
  constructor(
    private hospitalDoctorsService: HospitaldoctorService,
    private ngxService: NgxUiLoaderService,
    private departmentService: DepartmentsbyhospitalService,
    private labTestService: LabTestService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private cultureConfigService: CultureConfigService,
    private ref: ChangeDetectorRef,
    private labService: LabconfigurationService,
    private modalService: NgbModal,
    private ngxLoader: NgxUiLoaderService,
  ) {}

  ngOnInit(): any {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    $('#fromDate').datetimepicker({
      defaultDate: yesterday,
      format: 'DD-MM-YYYY h:mm a',
      autoClose: true,
    });
    const previousDateTime = moment(yesterday).format('DD-MM-YYYY h:mm a');
    $('#fromDate').val(previousDateTime);
    $('#toDate').datetimepicker({
      defaultDate: new Date(),
      format: 'DD-MM-YYYY h:mm a',
    });
    const currentTime = moment().format('DD-MM-YYYY h:mm a');
    $('#toDate').val(currentTime);

    this.labServicesForm = this.formBuilder.group({
      from_date: [previousDateTime, Validators.required],
      to_date: [currentTime, Validators.required],
      patient_id: ['', Validators.required],
      department_id: [''],
      parent_department_id: [''],
      sub_department: [''],
      type: [''],
      lab_id: [''],
    });

    this.cultureReportsForm = this.formBuilder.group({
      report_type: ['Culture'],
      id: [''],
      order_id: [''],
      lab_test_detail_id: [''],
      culture_plate: this.formBuilder.array([]),
      free_field: this.formBuilder.array([]),
      gram_stain: this.formBuilder.array([]),
      zn_stain: this.formBuilder.array([]),
      fungal_stain: this.formBuilder.array([]),
      final_report: this.formBuilder.array([]),
      microscopy: this.formBuilder.array([]),
      culture: this.formBuilder.array([]),
      culture_specimen: this.formBuilder.array([]),
      sensitivity: this.formBuilder.array([]),
      reports_comment: this.formBuilder.array([]),
    });

    this.patient_full_name = '';
    this.patient_gender_age = '';
    this.default_patient_mrn = '';
    this.searchInvoices();
    // this.getDepartmentList();
    // this.getSubDepartment(154);
    this.fetchDepartments();
    this.getCultureList();
    this.getCultureSensitivityList();
    this.fetchLabConfigurators();
  }
  get reports_culture_plate(): any {
    return this.cultureReportsForm.get('culture_plate') as UntypedFormArray;
  }
  get reports_free_field(): any {
    return this.cultureReportsForm.get('free_field') as UntypedFormArray;
  }
  get reports_gram_stain(): any {
    return this.cultureReportsForm.get('gram_stain') as UntypedFormArray;
  }
  get reports_zn_stain(): any {
    return this.cultureReportsForm.get('zn_stain') as UntypedFormArray;
  }
  get reports_fungal_stain(): any {
    return this.cultureReportsForm.get('fungal_stain') as UntypedFormArray;
  }
  get reports_final_report(): any {
    return this.cultureReportsForm.get('final_report') as UntypedFormArray;
  }
  get reports_microscopy(): any {
    return this.cultureReportsForm.get('microscopy') as UntypedFormArray;
  }
  get reports_culture(): any {
    return this.cultureReportsForm.get('culture') as UntypedFormArray;
  }
  get reports_specimen(): any {
    return this.cultureReportsForm.get('culture_specimen') as UntypedFormArray;
  }
  get reports_sensitivity(): any {
    return this.cultureReportsForm.get('sensitivity') as UntypedFormArray;
  }
  get reports_comment(): any {
    return this.cultureReportsForm.get('reports_comment') as UntypedFormArray;
  }

  _onlyNumeric(event: any): any {
    if (event.charCode !== 46) {
      const pattern = /^\d{1,6}(\.\d{1,2})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }

  isNumberKey(e): any {
    const charCode = e.which ? e.which : e.keyCode;

    if (e.target.value.includes('.')) {
      if (e.key === '.') {
        return false;
      }
    }

    if (
      charCode !== 190 &&
      charCode !== 96 &&
      charCode !== 97 &&
      charCode !== 98 &&
      charCode !== 99 &&
      charCode !== 100 &&
      charCode !== 101 &&
      charCode !== 102 &&
      charCode !== 103 &&
      charCode !== 104 &&
      charCode !== 105 &&
      charCode !== 46 &&
      charCode !== 110 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    ) {
      return false;
    }
    return true;
  }

  getDepartmentList(): any {
    this.departmentService.index().subscribe(
      (data) => {
        this.departments = data.departments;
      },
      (err) => {},
    );
  }

  // getSubDepartment(value): any {
  //   this.subDepartments = [];
  //   this.labServicesForm.patchValue({
  //     department_id: '',
  //   });
  //   if ( value !== '' ) {
  //     this.ngxService.start();
  //     this.departmentService.parent_dept(value).subscribe(
  //       data => {
  //         this.subDepartments = data.departments;
  //         this.ngxService.stop();
  //       },
  //       err => {
  //         this.ngxService.stop();
  //       }
  //     );
  //   }
  // }
  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentService.fetchSubDeptbyService('lab').subscribe(
      (data) => {
        this.subDepartments = data.departments;
        setTimeout(() => {
          $('#department').selectpicker('refresh');
          $('#department').selectpicker();
        }, 1000);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  searchInvoices(): any {
    this.labServicesForm.patchValue({
      from_date: $('#fromDate').val() || '',
      to_date: $('#toDate').val() || '',
      type: '',
    });
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }

  filtersInvoices(filter): any {
    this.labServicesForm.patchValue({
      from_date: $('#fromDate').val(),
      to_date: $('#toDate').val(),
      type: filter,
    });
    if (filter === '') {
      this.filterType = 'All';
    }
    if (filter === 'opd') {
      this.filterType = 'OPD';
    }
    if (filter === 'ipd') {
      this.filterType = 'IPD';
    }
    if (filter === 'urgent') {
      this.filterType = 'Urgent';
    }
    if (filter === 'returned') {
      this.filterType = 'Returned';
    }
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }
  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.searchFilter();
  }
  loadPage(event): any {
    this.searchFilter();
  }

  searchFilter(): any {
    this.ngxService.start();
    this.labTestListing = [];
    this.labTestService
      .fetchReceiivedLabTest(
        this.page,
        this.perPage,
        this.labServicesForm.value,
      )
      .subscribe(
        (data) => {
          // this.updateFilters('all-btn');
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          if (data.orders) {
            this.investigations = data.orders;
            this.labTestListing = data.orders;
          } else {
            this.labTestListing = [];
            toastr.error('Orders not found');
          }
          this.ngxService.stop();
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }

  getPendingResults(mrn): any {
    this.patientLabTestListing = [];
    this.labTestService.fetchReceivedLabTestByMrn(mrn).subscribe(
      (data) => {
        this.patientLabTestListing = data.orders;
      },
      (err) => {
        this.ngxService.stop();
      },
    );
  }

  getReportDetail(id): any {
    this.ngxService.start();
    this.destroyTextEditor();
    this.labTestService.fetchReportDetails(id).subscribe(
      (data) => {
        if ($('.modal-body-report').length === 0) {
          this.modalService.open(this.reportsModal, this.ngbModalStatic);
        }
        this.addReports(data.lab_order_detail);
        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.message);
      },
    );
  }
  setTextEditorForUpdate(): any {
    setTimeout(() => {
      for (let i = 0; i < this.reportTypeUpdate.length; i++) {
        for (let j = 0; j < this.reportTypeUpdate[i].value.length; j++) {
          if (this.reportTypeUpdate[i].value[j].report_type === 'editor') {
            const reportId = '#textEditorUpdate' + i + '' + j;
            console.log('textEditorUpdate', reportId);
            const reportData = this.reportTypeUpdate[i].value[j].heading;
            tinymce.init({
              selector: reportId,
              menubar: false,
              height: 400,
              toolbar: [
                'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
              ],
              plugins:
                'advlist autolink link image lists charmap code table lineheight',
              content_style: 'body { font-size: 16pt; font-family: Arial; }',
              setup(editor) {
                editor.on('init', function (e) {
                  editor.setContent(reportData);
                });
              },
            });
          }
        }
      }
    }, 1000);
    console.log('reportType for update', this.reportTypeUpdate);
  }
  setTextEditorForAdd(): any {
    setTimeout(() => {
      for (let i = 0; i < this.reportType.length; i++) {
        for (let j = 0; j < this.reportType[i].value.length; j++) {
          if (this.reportType[i].value[j].report_type === 'editor') {
            const reportId = '#textEditor' + i + '' + j;
            console.log('reportIdreportIdreportId', reportId);
            const reportData = this.reportType[i].value[j].heading;
            tinymce.init({
              selector: reportId,
              menubar: false,
              height: 400,
              toolbar: [
                'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
              ],
              plugins:
                'advlist autolink link image lists charmap code table lineheight',
              content_style: 'body { font-size: 16pt; font-family: Arial; }',
              setup(editor) {
                editor.on('init', function (e) {
                  editor.setContent(reportData);
                });
              },
            });
          }
        }
      }
    }, 1000);
    console.log('reportType for Add', this.reportType);
  }
  destroyTextEditor(): any {
    while (tinymce.editors.length > 0) {
      tinymce.remove(tinymce.editors[0]);
    }
  }
  addReports(element): any {
    this.disableBtn = false;
    this.pathologistRemarks = '';
    this.reportType = [];
    this.reportTypeUpdate = [];
    this.activeLabEntry = element;
    console.log('element', element);
    if (
      element.lab_investigation.report_type === 'Culture' ||
      element.lab_investigation.report_type === 'Culture-1'
    ) {
      this.clearAllCultureForm();
      this.cultureReportsForm.reset();
      if (element.lab_test_details) {
        if (element.lab_test_details.length > 0) {
          this.pathologistRemarks =
            element.lab_test_details[0].pathologist_remarks;
        }
      }
      this.cultureReportsForm.controls.lab_test_detail_id.setValue('');
      if (element.rejected === true || element.draft === true) {
        setTimeout(() => {
          for (let i = 0; i < element.lab_test_details.length; i++) {
            if (element.lab_test_details[i].culture_plate) {
              for (
                let j = 0;
                j < element.lab_test_details[i].culture_plate.length;
                j++
              ) {
                this.reports_culture_plate.push(
                  this.addReportsCulturePlates(
                    element.lab_test_details[i].culture_plate[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].free_field) {
              for (
                let j = 0;
                j < element.lab_test_details[i].free_field.length;
                j++
              ) {
                this.reports_free_field.push(
                  this.addReportsFreeFields(
                    element.lab_test_details[i].free_field[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].gram_stain) {
              for (
                let j = 0;
                j < element.lab_test_details[i].gram_stain.length;
                j++
              ) {
                this.reports_gram_stain.push(
                  this.addReportsGramStain(
                    element.lab_test_details[i].gram_stain[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].zn_stain) {
              for (
                let j = 0;
                j < element.lab_test_details[i].zn_stain.length;
                j++
              ) {
                this.reports_zn_stain.push(
                  this.addReportsZnStain(
                    element.lab_test_details[i].zn_stain[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].fungal_stain) {
              for (
                let j = 0;
                j < element.lab_test_details[i].fungal_stain.length;
                j++
              ) {
                this.reports_fungal_stain.push(
                  this.addReportsFungalStain(
                    element.lab_test_details[i].fungal_stain[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].final_report) {
              for (
                let j = 0;
                j < element.lab_test_details[i].final_report.length;
                j++
              ) {
                this.reports_final_report.push(
                  this.addReportsFinalReport(
                    element.lab_test_details[i].final_report[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].microscopy) {
              for (
                let j = 0;
                j < element.lab_test_details[i].microscopy.length;
                j++
              ) {
                this.reports_microscopy.push(
                  this.addReportsMicroscopy(
                    element.lab_test_details[i].microscopy[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].reports_comment) {
              for (
                let j = 0;
                j < element.lab_test_details[i].reports_comment.length;
                j++
              ) {
                this.reports_comment.push(
                  this.addReportsReportsComments(
                    element.lab_test_details[i].reports_comment[j],
                  ),
                );
              }
            }
            if (element.lab_test_details[i].sensitivity) {
              for (
                let j = 0;
                j < element.lab_test_details[i].sensitivity.length;
                j++
              ) {
                this.reports_sensitivity.push(
                  this.addReportsSensitivity(
                    element.lab_test_details[i].sensitivity[j],
                  ),
                );
                const type = element.lab_test_details[i].sensitivity[j].type;
                this.cultureGroupArray[j] = this.cultureListSensitivity.filter(
                  (t) => t.id === Number(type),
                )[0].groups;

                for (
                  let x = 0;
                  x <
                  element.lab_test_details[i].sensitivity[j]
                    .sensitivity_resistance.length;
                  x++
                ) {
                  const control = this.reports_sensitivity.controls[j].get(
                    'sensitivity_resistance',
                  ) as UntypedFormArray;
                  control.push(
                    this.addReportsSensitivityResistance(
                      element.lab_test_details[i].sensitivity[j]
                        .sensitivity_resistance[x],
                    ),
                  );
                }
              }
            }
            if (element.lab_test_details[i].culture) {
              for (
                let j = 0;
                j < element.lab_test_details[i].culture.length;
                j++
              ) {
                this.reports_culture.push(
                  this.addReportsCulture(
                    element.lab_test_details[i].culture[j],
                  ),
                );

                // for ( var x = 0; x < element.lab_test_details[i].culture[j].reports_comment.length; x++ ) {
                //   let control = <FormArray>this.reports_culture.controls[j].get('reports_comment');
                //   control.push(this.addReportsReportsComments(element.lab_test_details[i].culture[j].reports_comment[x]));
                // }
                // for ( var x = 0; x < element.lab_test_details[i].culture[j].sensitivity.length; x++ ) {
                //   let control = <FormArray>this.reports_culture.controls[j].get('sensitivity');
                //   control.push(this.addReportsSensitivity(element.lab_test_details[i].culture[j].sensitivity[x]));
                // }
              }
            }
            if (element.lab_test_details[i].culture_specimen) {
              for (
                let j = 0;
                j < element.lab_test_details[i].culture_specimen.length;
                j++
              ) {
                this.reports_specimen.push(
                  this.addReportsSpecimen(
                    element.lab_test_details[i].culture_specimen[j],
                  ),
                );
                // this.reports_specimen.push(this.addReportsSpecimen(element.sample_taken_from_id));
              }
            }
          }
        }, 1000);

        this.cultureReportsForm.controls.lab_test_detail_id.setValue(
          element.lab_test_details[0].id,
        );
      } else {
        this.addFieldsInCulture('specimen');
        //   // this.addFieldsInCulture('sensitivity');
        for (let i = 0; i < 2; i++) {
          this.addFieldsInCulture('culture');
          this.reports_comment.push(this.setReportsReportsComments());
        }
        for (let j = 0; j < this.cultureListSensitivity.length; j++) {
          for (
            let k = 0;
            k < this.cultureListSensitivity[j].groups.length;
            k++
          ) {
            if (this.cultureListSensitivity[j].groups[k].default === true) {
              this.cultureGroupArray.push(
                this.cultureListSensitivity[j].groups,
              );
              console.log('cultureGroupArray', this.cultureGroupArray);
              this.reports_sensitivity.push(
                this.addReportsSensitivityDefault(
                  this.cultureListSensitivity[j].id,
                  this.cultureListSensitivity[j].groups[k].id,
                ),
              );
              for (let i = 0; i < this.reports_culture.length; i++) {
                const control = this.reports_sensitivity.controls[
                  this.reports_sensitivity.length - 1
                ].get('sensitivity_resistance') as UntypedFormArray;
                control.push(this.setReportsSensitivityResistance());
              }
            }
          }
        }
      }
      this.cultureReportsForm.controls.order_id.setValue(element.order_id);
      this.cultureReportsForm.controls.report_type.setValue(
        element.lab_investigation.report_type,
      );
      this.cultureReportsForm.controls.id.setValue(element.id);
      console.log('cultureReportsForm', this.cultureReportsForm.value);
      // $('#cultureReportsModal').modal('show');
      // this.modalService.open(this.cultureReportsModal, this.ngbModalStatic);
      this.modalType = 'cultureReportsModal';
    } else {
      // element.lab_assessments.sort(function(a, b) {
      //   return a.id - b.id;
      // });
      if (element.rejected === true || element.draft === true) {
        this.setPerametersForUpdate(element);
      } else {
        this.setPerametersForAdd(element);
      }
    }
  }
  updateSensitivityType(id, i): any {
    console.log('i', i);
    this.cultureGroupArray[i] = this.cultureListSensitivity.filter(
      (t) => t.id === Number(id),
    )[0].groups;
    console.log('cultureGroupArray', this.cultureGroupArray);
    this.reports_sensitivity.at(i).patchValue({ group: '' });
  }

  setPerametersForUpdate(element): any {
    if (element.lab_test_details.length > 0) {
      this.pathologistRemarks = element.lab_test_details[0].pathologist_remarks;
    }
    for (let i = 0; i < element.lab_test_details.length; i++) {
      console.log(i);
      if (element.lab_test_details[i].report_type === 'single') {
        const obj = {
          report_type: 'single',
          value: element.lab_test_details[i].value,
          lab_test_detail_id: element.lab_test_details[i].id,
          caption: element.lab_test_details[i].caption,
          unit: element.lab_test_details[i].unit,
          specimen: element.lab_test_details[i].specimen,
          range: element.lab_test_details[i].range,
          heading: element.lab_test_details[i].heading || '',
          super_heading: element.lab_test_details[i].super_heading || '',
          primary_id: element.lab_test_details[i].primary_id,
          sort_order: element.lab_test_details[i].sort_order,
          lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
          range_comments: element.lab_test_details[i].range_comments,
          range_type: element.lab_test_details[i].range_type || '',
          id: element.id,
        };
        this.reportTypeUpdate.push(obj);
      }

      if (element.lab_test_details[i].report_type === 'text') {
        const objtext = {
          report_type: 'text',
          heading: element.lab_test_details[i].heading,
          lab_test_detail_id: element.lab_test_details[i].id,
          caption: element.lab_test_details[i].caption,
          unit: element.lab_test_details[i].unit,
          specimen: element.lab_test_details[i].specimen,
          range: element.lab_test_details[i].range,
          super_heading: element.lab_test_details[i].super_heading || '',
          primary_id: element.lab_test_details[i].primary_id,
          sort_order: element.lab_test_details[i].sort_order,
          lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
          range_comments: element.lab_test_details[i].range_comments,
          id: element.id,
        };
        this.reportTypeUpdate.push(objtext);
      }
      if (element.lab_test_details[i].report_type === 'editor') {
        const objtext = {
          report_type: 'editor',
          heading: element.lab_test_details[i].heading,
          lab_test_detail_id: element.lab_test_details[i].id,
          caption: element.lab_test_details[i].caption,
          unit: element.lab_test_details[i].unit,
          specimen: element.lab_test_details[i].specimen,
          range: element.lab_test_details[i].range,
          super_heading: element.lab_test_details[i].super_heading || '',
          primary_id: element.lab_test_details[i].primary_id,
          sort_order: element.lab_test_details[i].sort_order,
          lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
          range_comments: element.lab_test_details[i].range_comments,
          id: element.id,
        };
        this.reportTypeUpdate.push(objtext);
      }
      if (element.lab_test_details[i].report_type === 'combo') {
        const objcombo = {
          report_type: 'combo',
          report_type_1: element.lab_test_details[i].report_type_1,
          report_type_2: element.lab_test_details[i].report_type_2,
          report_type_1_result:
            element.lab_test_details[i].report_type_1_result,
          report_type_2_result:
            element.lab_test_details[i].report_type_2_result,
          report_type_1_value:
            element.lab_test_details[i].report_type_1_value || '',
          report_type_2_value:
            element.lab_test_details[i].report_type_2_value || '',
          caption: element.lab_test_details[i].caption,
          unit: element.lab_test_details[i].unit,
          specimen: element.lab_test_details[i].specimen,
          lab_test_detail_id: element.lab_test_details[i].id,
          heading: element.lab_test_details[i].heading || '',
          super_heading: element.lab_test_details[i].super_heading || '',
          primary_id: element.lab_test_details[i].primary_id,
          sort_order: element.lab_test_details[i].sort_order,
          lab_assessment_id: element.lab_test_details[i].lab_assessment_id,
          range_comments: element.lab_test_details[i].range_comments,
          id: element.id,
        };
        this.reportTypeUpdate.push(objcombo);
      }
    }

    // $('#reportsModalUpdate').modal('show');
    // this.modalService.open(this.reportsModalUpdate, this.ngbModalStatic);
    this.modalType = 'reportsModalUpdate';

    const groups = {};
    for (let i = 0; i < this.reportTypeUpdate.length; i++) {
      const groupName = this.reportTypeUpdate[i].super_heading;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(this.reportTypeUpdate[i]);
    }
    this.reportTypeUpdate = [];
    for (const objsort in groups) {
      this.reportTypeUpdate.push({ name: objsort, value: groups[objsort] });
    }
    // this.reportTypeUpdate.reverse();
    this.setTextEditorForUpdate();
  }
  convertToNumber(value): any {
    return Number(value);
  }

  setPerametersForAdd(element): any {
    for (let i = 0; i < element.lab_assessments.length; i++) {
      if (element.lab_assessments[i].parameter_type === 'single') {
        console.log('in singl');
        if (element.lab_assessments[i].assessment_ranges) {
          const patientRange = this.singleReportRange(
            element.lab_assessments[i].assessment_ranges,
            element.age_value,
            element.patient_gender,
            element.patient_age_type,
          );
          const obj = {
            report_type: 'single',
            report_range: '',
            id: element.id,
            order_id: element.order_id,
            title: element.lab_assessments[i].title,
            uom: element.lab_assessments[i].uom,
            specimen: element.lab_assessments[i].specimen,
            assessment_ranges: element.lab_assessments[i].assessment_ranges,
            range: patientRange.result,
            range_start: patientRange.start,
            range_end: patientRange.end,
            heading: element.lab_assessments[i].heading,
            super_heading: element.lab_assessments[i].super_heading,
            primary_id: element.lab_assessments[i].primary_id,
            sort_order: element.lab_assessments[i].sort_order,
            lab_assessment_id: element.lab_assessments[i].id,
            range_comments: element.lab_assessments[i].range_comments,
            range_type: patientRange.range_type,
            lab_test_detail_id: '',
          };
          this.reportType.push(obj);
        }
        // this.singleReportRangeType(element.lab_assessments[i].assessment_ranges, element.age_value, element.patient_gender)
      }
      if (element.lab_assessments[i].parameter_type === 'text') {
        console.log('in text');
        if (element.lab_assessments[i].assessment_ranges) {
          const objtext = {
            report_type: 'text',
            report_range: '',
            id: element.id,
            order_id: element.order_id,
            title: element.lab_assessments[i].title,
            uom: element.lab_assessments[i].uom,
            specimen: element.lab_assessments[i].specimen,
            heading: element.lab_assessments[i].assessment_ranges.heading,
            super_heading: element.lab_assessments[i].super_heading,
            primary_id: element.lab_assessments[i].primary_id,
            sort_order: element.lab_assessments[i].sort_order,
            lab_assessment_id: element.lab_assessments[i].id,
            range_comments: element.lab_assessments[i].range_comments,
            lab_test_detail_id: '',
          };
          this.reportType.push(objtext);
        }
      }
      if (element.lab_assessments[i].parameter_type === 'combo') {
        if (element.lab_assessments[i].assessment_ranges) {
          console.log('in combo');
          const objcombo = {
            report_type: 'combo',
            report_type_1:
              element.lab_assessments[i].assessment_ranges.report_type_1,
            report_type_2:
              element.lab_assessments[i].assessment_ranges.report_type_2,
            report_type_1_result:
              element.lab_assessments[i].assessment_ranges.report_type_1_result,
            report_type_2_result:
              element.lab_assessments[i].assessment_ranges.report_type_2_result,
            report_type_1_value:
              element.lab_assessments[i].assessment_ranges.report_type_1_result,
            report_type_2_value:
              element.lab_assessments[i].assessment_ranges.report_type_2_result,
            id: element.id,
            order_id: element.order_id,
            title: element.lab_assessments[i].title,
            uom: element.lab_assessments[i].uom,
            specimen: element.lab_assessments[i].specimen,
            heading: element.lab_assessments[i].heading,
            super_heading: element.lab_assessments[i].super_heading,
            primary_id: element.lab_assessments[i].primary_id,
            sort_order: element.lab_assessments[i].sort_order,
            lab_assessment_id: element.lab_assessments[i].id,
            range_comments: element.lab_assessments[i].range_comments,
            lab_test_detail_id: '',
          };
          if (objcombo.report_type_1 === 'dropdown') {
            // objcombo.report_type_1_value = element.lab_assessments[i].assessment_ranges.report_type_1_result[0];
            objcombo.report_type_1_value = '';
          }
          if (objcombo.report_type_2 === 'dropdown') {
            // objcombo.report_type_2_value = element.lab_assessments[i].assessment_ranges.report_type_2_result[0];
            objcombo.report_type_2_value = '';
          }
          this.reportType.push(objcombo);
        }
      }
      if (element.lab_assessments[i].parameter_type === 'editor') {
        console.log('in editor');
        if (element.lab_assessments[i].assessment_ranges) {
          const objtext = {
            report_type: 'editor',
            report_range: '',
            id: element.id,
            order_id: element.order_id,
            title: element.lab_assessments[i].title,
            uom: element.lab_assessments[i].uom,
            specimen: element.lab_assessments[i].specimen,
            heading: element.lab_assessments[i].assessment_ranges.heading,
            super_heading: element.lab_assessments[i].super_heading,
            primary_id: element.lab_assessments[i].primary_id,
            sort_order: element.lab_assessments[i].sort_order,
            lab_assessment_id: element.lab_assessments[i].id,
            range_comments: element.lab_assessments[i].range_comments,
            lab_test_detail_id: '',
          };
          this.reportType.push(objtext);
        }
      }
    }
    const groups = {};
    for (let i = 0; i < this.reportType.length; i++) {
      const groupName = this.reportType[i].super_heading;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(this.reportType[i]);
    }
    this.reportType = [];
    for (const obj in groups) {
      this.reportType.push({ name: obj, value: groups[obj] });
    }

    // $('#reportsModal').modal('show');
    // this.modalService.open(this.reportsModal, this.ngbModalStatic);
    this.modalType = 'reportsModal';
    console.log('reportType', this.reportType);
    this.setTextEditorForAdd();
  }

  updatePathologistRemarks(value): any {
    this.pathologistRemarks = value;
    $('#pathologistRemarks').val(value);
  }

  singleReportRange(range, age, gender, patient_age_type): any {
    const resultObj = {
      start: 0,
      end: 0,
      result: '',
      range_type: '',
    };
    let patient_age = age;
    if (patient_age_type === 'years') {
      patient_age = age * 365;
    }
    if (patient_age_type === 'Month') {
      patient_age = age * 30;
    }
    console.log('patient_age_type', patient_age_type);
    console.log('patient_age', patient_age);
    for (let i = 0; i < range.length; i++) {
      if (gender === range[i].gender || range[i].gender === 'Both') {
        const rangeData = range[i];
        // console.log('rangeDatarangeDatarangeData', rangeData);
        let start = rangeData.age_start;
        let end = rangeData.age_end;
        if (rangeData.age_type === 'years') {
          start = rangeData.age_start / 365;
          end = rangeData.age_end * 365;
        }
        if (rangeData.age_type === 'months') {
          start = rangeData.age_start / 30;
          end = rangeData.age_end * 30;
        }
        // if ( patient_age_type === range[i].age_type &&  patient_age >= range[i].age_start && patient_age <= range[i].age_end )
        // console.log('range[i].age_type', rangeData.age_type);
        // console.log('start', start);
        // console.log('end', end);
        if (patient_age >= start && patient_age <= end) {
          if (rangeData.range_type === 'in_between') {
            resultObj.result =
              rangeData.start_range + ' - ' + rangeData.end_range;
            resultObj.start = rangeData.start_range;
            resultObj.end = rangeData.end_range;
            resultObj.range_type = 'in_between';
            return resultObj;
          }
          if (rangeData.range_type === 'less_then') {
            resultObj.result = ' < ' + rangeData.end_range;
            resultObj.start = rangeData.start_range;
            resultObj.end = rangeData.end_range;
            resultObj.range_type = 'less_then';
            return resultObj;
          }
          if (rangeData.range_type === 'greater_then') {
            resultObj.result = ' > ' + rangeData.start_range;
            resultObj.start = rangeData.start_range;
            resultObj.end = rangeData.end_range;
            resultObj.range_type = 'greater_then';
            return resultObj;
          }
          if (rangeData.range_type === 'equal') {
            resultObj.result = ' = ' + rangeData.start_range;
            resultObj.start = rangeData.start_range;
            resultObj.end = rangeData.end_range;
            resultObj.range_type = 'equal';
            return resultObj;
          }
        }
      }
    }
    return resultObj;
  }

  singleReportRangeType(range, age, gender): any {
    let result;
    for (let i = 0; i < range.length; i++) {
      if (gender === range[i].gender) {
        return range[i].range_type;
      }
    }
    return '';
  }
  submitResult(isDraft): any {
    // for ( var i = 0; i < this.reportType.length; i++ ) {
    //   if ( this.reportType[i].report_type === 'single' && this.reportType[i].report_range === '' ) {
    //     return;
    //   }
    // }
    const reports = [];
    console.log('6');
    let reportRange = true;
    console.log('1');
    // debugger;
    for (let i = 0; i < this.reportType.length; i++) {
      for (let j = 0; j < this.reportType[i].value.length; j++) {
        console.log('reporttype', this.reportType[i].value[j].report_type);
        // if ( this.reportType[i].value[j].report_type === 'single'){
        //   if (this.reportType[i].value[j].report_range){
        //     console.log('2');
        //     reportRange = false;
        //     break;
        //   }
        // }
        // For Combo start
        // if ( this.reportType[i].value[j].report_type === 'combo'){
        //   if (this.reportType[i].value[j].report_type_1 || this.reportType[i].value[j].report_type_2){
        //     console.log('3');
        //     reportRange = false;
        //     break;
        //   }
        // }
        // For Combo end
        // For Text Start
        // if ( this.reportType[i].value[j].report_type === 'text'){
        //   if (this.reportType[i].value[j].heading){
        //     console.log('4');
        //     reportRange = false;
        //     break;
        //   }
        // }
        // For Text End
        // For Editor Start
        if (this.reportType[i].value[j].report_type === 'editor') {
          const reportId = 'textEditor' + i + '' + j;
          this.reportType[i].value[j].heading = tinymce
            .get(reportId)
            .getContent();
          // if (this.reportType[i].value[j].heading){
          //   console.log('9');
          //   reportRange = false;
          //   break;
          // }
        }
        // For Editor End
        // console.log('111', reports);
        // reports.push(this.reportType[i].value[j]);
      }
    }
    // if (reportRange === true){
    //   console.log('5');
    //   toastr.error('Add Results');
    //   return;
    // }
    for (let i = 0; i < this.reportType.length; i++) {
      for (let j = 0; j < this.reportType[i].value.length; j++) {
        // if ( this.reportType[i].value[j].report_type === 'editor' ) {
        //   let reportId = 'textEditor' + i + '' + j;
        //   this.reportType[i].value[j].heading = tinymce.get(reportId).getContent();
        // }
        reports.push(this.reportType[i].value[j]);
      }
    }
    console.log('222', reports);
    this.ngxService.start();
    this.labTestService
      .submitLabTestResult(reports, this.pathologistRemarks, isDraft)
      .subscribe(
        (data) => {
          // $('#reportsModal').modal('hide');
          this.modalService.dismissAll(this.reportsModal);
          this.searchInvoices();
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }

  submitResultUpdate(isDraft): any {
    // for ( var i = 0; i < this.reportType.length; i++ ) {
    //   if ( this.reportType[i].report_type === 'single' && this.reportType[i].range === '' ) {
    //     return;
    //   }
    // }
    console.log('update this result');
    const reports = [];
    let updateReport = true;
    for (let i = 0; i < this.reportTypeUpdate.length; i++) {
      for (let j = 0; j < this.reportTypeUpdate[i].value.length; j++) {
        console.log(
          'reporttype',
          this.reportTypeUpdate[i].value[j].report_type,
        );
        // if ( this.reportTypeUpdate[i].value[j].report_type === 'single'){
        //   if (this.reportTypeUpdate[i].value[j].value){
        //     console.log('2');
        //     updateReport = false;
        //     break;
        //   }
        // }
        // For Combo start
        // if ( this.reportTypeUpdate[i].value[j].report_type === 'combo'){
        //   console.log('value', this.reportTypeUpdate[i].value[j]);
        //   if (this.reportTypeUpdate[i].value[j].report_type_1_value || this.reportTypeUpdate[i].value[j].report_type_2_value){
        //     console.log('3');
        //     updateReport = false;
        //     break;
        //   }
        // }
        // For Combo end
        // For Text Start
        // if ( this.reportTypeUpdate[i].value[j].report_type === 'text'){
        //   if (this.reportTypeUpdate[i].value[j].heading){
        //     console.log('4');
        //     updateReport = false;
        //     break;
        //   }
        // }
        // For Text End
        // For Editor Start
        if (this.reportTypeUpdate[i].value[j].report_type === 'editor') {
          const reportId = 'textEditorUpdate' + i + '' + j;
          this.reportTypeUpdate[i].value[j].heading = tinymce
            .get(reportId)
            .getContent();
          // if (this.reportTypeUpdate[i].value[j].heading){
          //   updateReport = false;
          //   break;
          // }
        }
        // For Editor End
        // console.log('111', reports);
        // reports.push(this.reportType[i].value[j]);
      }
    }
    // if (updateReport === true){
    //   console.log('5');
    //   toastr.error('Add Results');
    //   return;
    // }

    for (let i = 0; i < this.reportTypeUpdate.length; i++) {
      for (let j = 0; j < this.reportTypeUpdate[i].value.length; j++) {
        reports.push(this.reportTypeUpdate[i].value[j]);
      }
    }
    this.ngxService.start();
    this.labTestService
      .updateUnVerifiedLabTest(reports, this.pathologistRemarks, isDraft)
      .subscribe(
        (data) => {
          // $('#reportsModalUpdate').modal('hide');
          // this.modalService.dismissAll(this.reportsModalUpdate);
          this.modalService.dismissAll(this.reportsModal);
          this.searchInvoices();
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }

  fetchLabConfigurators(): any {
    this.ngxService.start();
    this.labService.getLabConfigurators().subscribe(
      (data) => {
        console.log(data);
        this.labConfigurations = data.lab_configurations;
        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getCultureList(): any {
    this.ngxService.start();
    this.cultureConfigService.index().subscribe(
      (data) => {
        this.cultureList = data.culture_configurations;
        this.ngxService.stop();
      },
      (error) => {
        this.ngxService.stop();
      },
    );
  }
  getCultureSensitivityList(): any {
    this.ngxService.start();
    this.cultureConfigService.indexByType('sensitivity_type').subscribe(
      (data) => {
        this.cultureListSensitivity = data.culture_configurations;
        this.ngxService.stop();
      },
      (error) => {
        this.ngxService.stop();
      },
    );
  }

  addFieldsInCultureWithResistance(): any {
    this.reports_culture.push(this.setReportsCulture());
    this.reports_comment.push(this.setReportsReportsComments());
    setTimeout(() => {
      for (let i = 0; i < this.reports_sensitivity.length; i++) {
        const control = this.reports_sensitivity.controls[i].get(
          'sensitivity_resistance',
        ) as UntypedFormArray;
        control.push(this.setReportsSensitivityResistance());
      }
    }, 200);
    // this.addFieldsInCulture('reports_comment');
  }
  addFieldsInCulture(type): any {
    if (type === 'reports_comment') {
      // this.reports_comment.push(this.setReportsReportsComments());
    }
    if (type === 'sensitivity') {
      this.disableBtn = true;
      this.reports_sensitivity.push(this.setReportsSensitivity());
      setTimeout(() => {
        for (let i = 0; i < this.reports_culture.length; i++) {
          const control = this.reports_sensitivity.controls[
            this.reports_sensitivity.length - 1
          ].get('sensitivity_resistance') as UntypedFormArray;
          control.push(this.setReportsSensitivityResistance());
          // this.addFieldsInCulture('reports_comment');
        }
      }, 200);
      setTimeout(() => {
        this.disableBtn = false;
      }, 500);
    }
    if (type === 'culture_plate') {
      this.reports_culture_plate.push(this.setReportsCulturePlates());
    }
    if (type === 'free_field') {
      this.reports_free_field.push(this.setReportsFreeFields());
    }
    if (type === 'gram_stain') {
      this.reports_gram_stain.push(this.setReportsGramStain());
    }
    if (type === 'zn_stain') {
      this.reports_zn_stain.push(this.setReportsZnStain());
    }
    if (type === 'fungal_stain') {
      this.reports_fungal_stain.push(this.setReportsFungalStain());
    }
    if (type === 'final_report') {
      this.reports_final_report.push(this.setReportsFinalReport());
    }
    if (type === 'microscopy') {
      this.reports_microscopy.push(this.setReportsMicroscopy());
    }
    if (type === 'culture') {
      this.reports_culture.push(this.setReportsCulture());
    }
    if (type === 'specimen') {
      if (this.reports_specimen.length === 0) {
        this.reports_specimen.push(this.setReportsSpecimen());
      }
    }
  }
  setReportsCulturePlates(): any {
    return this.formBuilder.group({
      plate: ['', Validators.required],
      what: ['', Validators.required],
      quantity: ['', Validators.required],
    });
  }
  setReportsFreeFields(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }
  setReportsGramStain(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: [''],
    });
  }
  setReportsZnStain(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: [''],
    });
  }
  setReportsFungalStain(): any {
    return this.formBuilder.group({
      heading: ['', Validators.required],
      comments: [''],
    });
  }
  setReportsFinalReport(): any {
    return this.formBuilder.group({
      type: ['', Validators.required],
      medicine: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }
  setReportsMicroscopy(): any {
    return this.formBuilder.group({
      type: ['', Validators.required],
      result: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }
  setReportsSensitivity(): any {
    return this.formBuilder.group({
      type: ['', Validators.required],
      group: ['', Validators.required],
      sensitivity_resistance: this.formBuilder.array([]),
    });
  }
  setReportsSensitivityResistance(): any {
    return this.formBuilder.group({
      type: [''],
    });
  }
  setReportsReportsComments(): any {
    return this.formBuilder.group({
      heading: [''],
      comments: [''],
    });
  }
  setReportsCulture(): any {
    return this.formBuilder.group({
      growth: ['', Validators.required],
      culture: ['', Validators.required],
      duration_type: ['Minutes', Validators.required],
      duration_value: ['', Validators.required],
      temperature: ['', Validators.required],
      colony_count: ['', Validators.required],
      atmosphere: ['', Validators.required],
      comments: [''],
      sensitivity: this.formBuilder.array([]),
      reports_comment: this.formBuilder.array([]),
    });
  }

  addReportsCulturePlates(data): any {
    return this.formBuilder.group({
      plate: [data.plate, Validators.required],
      what: [data.what, Validators.required],
      quantity: [data.quantity, Validators.required],
    });
  }
  addReportsFreeFields(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsGramStain(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsZnStain(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsFungalStain(data): any {
    return this.formBuilder.group({
      heading: [data.heading, Validators.required],
      comments: [data.comments],
    });
  }
  addReportsFinalReport(data): any {
    return this.formBuilder.group({
      type: [data.type, Validators.required],
      medicine: [data.medicine, Validators.required],
      comments: [data.comments, Validators.required],
    });
  }
  addReportsMicroscopy(data): any {
    return this.formBuilder.group({
      type: [data.type, Validators.required],
      result: [data.result, Validators.required],
      comments: [data.comments, Validators.required],
    });
  }
  addReportsSensitivity(data): any {
    return this.formBuilder.group({
      type: [data.type, Validators.required],
      group: [data.group, Validators.required],
      sensitivity_resistance: this.formBuilder.array([]),
    });
  }
  addReportsSensitivityDefault(type, group): any {
    return this.formBuilder.group({
      type: [type, Validators.required],
      group: [group || '', Validators.required],
      sensitivity_resistance: this.formBuilder.array([]),
    });
  }

  addReportsSensitivityResistance(data): any {
    return this.formBuilder.group({
      type: [data.type],
    });
  }
  addReportsReportsComments(data): any {
    return this.formBuilder.group({
      heading: [data.heading],
      comments: [data.comments],
    });
  }
  addReportsCulture(data): any {
    return this.formBuilder.group({
      growth: [data.growth, Validators.required],
      culture: [data.culture, Validators.required],
      duration_type: [data.duration_type || 'Minutes', Validators.required],
      duration_value: [data.duration_value, Validators.required],
      temperature: [data.temperature, Validators.required],
      colony_count: [data.colony_count, Validators.required],
      atmosphere: [data.atmosphere, Validators.required],
      comments: [data.comments],
      sensitivity: this.formBuilder.array([]),
      reports_comment: this.formBuilder.array([]),
    });
  }
  addReportsSpecimen(data): any {
    return this.formBuilder.group({
      name_id: [data.name_id, Validators.required],
      value: [data.value],
    });
  }

  setReportsSpecimen(): any {
    return this.formBuilder.group({
      name_id: [this.activeLabEntry.specimen_id || '', Validators.required],
      value: [''],
    });
  }
  removeCultureIndexWithResistance(ind): any {
    for (let i = 0; i < this.reports_sensitivity.length; i++) {
      const control = this.reports_sensitivity.controls[i].get(
        'sensitivity_resistance',
      ) as UntypedFormArray;
      control.removeAt(ind);
    }
    this.reports_culture.removeAt(ind);
    this.reports_comment.removeAt(ind);
  }
  removeCultureIndex(type, ind): any {
    if (type === 'culture_plate') {
      this.reports_culture_plate.removeAt(ind);
    }
    if (type === 'free_field') {
      this.reports_free_field.removeAt(ind);
    }
    if (type === 'gram_stain') {
      this.reports_gram_stain.removeAt(ind);
    }
    if (type === 'zn_stain') {
      this.reports_zn_stain.removeAt(ind);
    }
    if (type === 'fungal_stain') {
      this.reports_fungal_stain.removeAt(ind);
    }
    if (type === 'final_report') {
      this.reports_final_report.removeAt(ind);
    }
    if (type === 'microscopy') {
      this.reports_microscopy.removeAt(ind);
    }
    if (type === 'culture') {
      // this.reports_culture.removeAt(ind);
    }
    if (type === 'specimen') {
      this.reports_specimen.removeAt(ind);
    }
    if (type === 'sensitivity') {
      this.reports_sensitivity.removeAt(ind);
      this.cultureGroupArray.splice(ind, 1);
    }
    if (type === 'reports_comment') {
      // this.reports_comment.removeAt(ind);
    }
  }
  clearAllCultureForm(): any {
    this.cultureReportSubmitted = false;
    while (this.reports_culture_plate.length) {
      this.reports_culture_plate.removeAt(0);
    }
    while (this.reports_free_field.length) {
      this.reports_free_field.removeAt(0);
    }
    while (this.reports_gram_stain.length) {
      this.reports_gram_stain.removeAt(0);
    }
    while (this.reports_zn_stain.length) {
      this.reports_zn_stain.removeAt(0);
    }
    while (this.reports_fungal_stain.length) {
      this.reports_fungal_stain.removeAt(0);
    }
    while (this.reports_final_report.length) {
      this.reports_final_report.removeAt(0);
    }
    while (this.reports_microscopy.length) {
      this.reports_microscopy.removeAt(0);
    }
    while (this.reports_culture.length) {
      this.reports_culture.removeAt(0);
    }
    while (this.reports_specimen.length) {
      this.reports_specimen.removeAt(0);
    }
    while (this.reports_sensitivity.length) {
      this.reports_sensitivity.removeAt(0);
    }
    while (this.reports_comment.length) {
      this.reports_comment.removeAt(0);
    }
    this.cultureGroupArray = [];
  }
  updateFreeHeadingText(objId, valueFrom, i): any {
    let comment = '';
    if (this.cultureList.find((x) => x.id === Number(objId))) {
      comment = this.cultureList.find((x) => x.id === Number(objId)).comments;
    }
    if (valueFrom === 'free_field') {
      this.reports_free_field.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'gram_stain') {
      this.reports_gram_stain.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'zn_stain') {
      this.reports_zn_stain.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'fungal_stain') {
      this.reports_fungal_stain.at(i).patchValue({ comments: comment });
    }
    if (valueFrom === 'reports_comment') {
      this.reports_comment.at(i).patchValue({ comments: comment });
    }
  }
  submitCultureReport(isDraft): any {
    console.log(this.cultureReportsForm.value);
    this.cultureReportSubmitted = true;
    if (this.cultureReportsForm.invalid) {
      return;
    }
    console.log('form submit');
    this.ngxService.start();
    const reports = [];
    reports.push(this.cultureReportsForm.value);
    this.labTestService
      .submitLabTestResult(reports, $('#pathologistRemarks').val(), isDraft)
      .subscribe(
        (data) => {
          this.cultureReportSubmitted = false;
          // $('#cultureReportsModal').modal('hide');
          // this.modalService.dismissAll(this.cultureReportsModal);
          this.modalService.dismissAll(this.reportsModal);
          this.searchInvoices();
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }

  updateFilters(type): any {
    this.labTestListing = [];
    if (type === 'all-btn') {
      this.labTestListing = this.investigations;
    }
    if (type === 'urgent') {
      for (let i = 0; i < this.investigations.length; i++) {
        if (this.investigations[i].urgent === true) {
          this.labTestListing.push(this.investigations[i]);
        }
      }
    }
    if (type === 'returned') {
      for (let i = 0; i < this.investigations.length; i++) {
        if (this.investigations[i].rejected === true) {
          this.labTestListing.push(this.investigations[i]);
        }
      }
    }
    if (type === 'opd-btn') {
      for (let i = 0; i < this.investigations.length; i++) {
        if (this.investigations[i].ipd === false) {
          this.labTestListing.push(this.investigations[i]);
        }
      }
    }
    if (type === 'ipd-btn') {
      for (let i = 0; i < this.investigations.length; i++) {
        if (this.investigations[i].ipd === true) {
          this.labTestListing.push(this.investigations[i]);
        }
      }
    }
  }
}
