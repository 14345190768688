<div class="card">
  <div class="card-body user-card-body">
    <div  style="max-height: 200px!important;" class="employeeShiftTable" [formGroup]="reviewSystemHistoryForm">
    <h4>{{label}}</h4>
<!--    ENT-->
    <h6>ENT</h6>
      <div class="row" formGroupName="patient_ent_history_attributes">
        <!--   Vision Disturbance-->
        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
          <label class="checkbox checkbox-sm">
            <input
              type="checkbox"
              formControlName="vision_disturbance"
            />
            <span class="mr-2"></span>Vision Disturbance
          </label>
        </div>
        <!--   Headache-->
        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
          <label class="checkbox checkbox-sm">
            <input
              type="checkbox"
              formControlName="headache"
            />
            <span class="mr-2"></span>Headache
          </label>
        </div>
        <!--   Eye pain-->
        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
          <label class="checkbox checkbox-sm">
            <input
              type="checkbox"
              formControlName="eye_pain"
            />
            <span class="mr-2"></span>Eye Pain
          </label>
        </div>
        <!--   Ringing-->
        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
          <label class="checkbox checkbox-sm">
            <input
              type="checkbox"
              formControlName="ringing"
            />
            <span class="mr-2"></span>Ringing
          </label>
        </div>
        <!--   Congestion-->
        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
          <label class="checkbox checkbox-sm">
            <input
              type="checkbox"
              formControlName="congestion"
            />
            <span class="mr-2"></span>Congestion
          </label>
        </div>
        <!--   Hearing Loss-->
        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
          <label class="checkbox checkbox-sm">
            <input
              type="checkbox"
              formControlName="hearing_loss"
            />
            <span class="mr-2"></span>Hearing Loss
          </label>
        </div>
        <!--   Ear Pain-->
        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
          <label class="checkbox checkbox-sm">
            <input
              type="checkbox"
              formControlName="ear_pain"
            />
            <span class="mr-2"></span>Ear Pain
          </label>
        </div>
        <!--   Facial Swelling-->
        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
          <label class="checkbox checkbox-sm">
            <input
              type="checkbox"
              formControlName="facial_swelling"
            />
            <span class="mr-2"></span>Facial Swelling
          </label>
        </div>
      </div>
<!--    General Health-->
    <h6 class="mt-3">General Health</h6>
    <div class="row" formGroupName="patient_general_health_history_attributes">
<!--      Fatigue-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="fatigue"
          />
          <span class="mr-2"></span>Fatigue
        </label>
      </div>
<!--      Activity Change-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="activity_change"
          />
          <span class="mr-2"></span>Activity Change
        </label>
      </div>
<!--      Fever-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="fever"
          />
          <span class="mr-2"></span>Fever
        </label>
      </div>
<!--      Unexpected Weight Change-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="unexpected_weight_change"
          />
          <span class="mr-2"></span>Unexpected Weight Change
        </label>
      </div>
<!--      Appetite Change-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="appetite_change"
          />
          <span class="mr-2"></span>Appetite Change
        </label>
      </div>
<!--      Other Change-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="other"
          />
          <span class="mr-2"></span>Other
        </label>
      </div>
    </div>
<!--    Cardiology-->
    <h6 class="mt-3">Cardiology</h6>
    <div class="row" formGroupName="patient_cardiology_history_attributes">
<!--      Chest Pain-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="chest_pain"
          />
          <span class="mr-2"></span>Chest Pain
        </label>
      </div>
      <!--      Leg Swelling-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="edema"
          />
          <span class="mr-2"></span>Leg Swelling
        </label>
      </div>
<!--      PND-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="pnd"
          />
          <span class="mr-2"></span>PND
        </label>
      </div>
<!--      Palpitations-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="palpitations"
          />
          <span class="mr-2"></span>Palpitations
        </label>
      </div>
<!--      Shortness of Breath-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="shortness_of_breath"
          />
          <span class="mr-2"></span>Shortness of Breath
        </label>
      </div>
<!--      Orthopnea-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="orthopnea"
          />
          <span class="mr-2"></span>Orthopnea
        </label>
      </div>
    </div>
<!--    Respiratory-->
    <h6 class="mt-3">Respiratory</h6>
    <div class="row" formGroupName="patient_respiratory_history_attributes">
<!--      Cough-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="cough"
          />
          <span class="mr-2"></span>Cough
        </label>
      </div>
<!--      Shortness of Breath-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="shortness_of_breath"
          />
          <span class="mr-2"></span>Shortness of Breath
        </label>
      </div>
<!--      Stridor-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="stridor"
          />
          <span class="mr-2"></span>Stridor
        </label>
      </div>
<!--      Wheeze-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="wheeze"
          />
          <span class="mr-2"></span>Wheeze
        </label>
      </div>
<!--      Chest tightness-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="chest_tightness"
          />
          <span class="mr-2"></span>Chest tightness
        </label>
      </div>
<!--      Choking-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="choking"
          />
          <span class="mr-2"></span>Choking
        </label>
      </div>
    </div>
      <div formGroupName="patient_other_review_history">
<!--    Endo-->
    <h6 class="mt-3">Endo</h6>
    <div class="row">
<!--      Cold intolerance-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="cold_intolerance"
          />
          <span class="mr-2"></span>Cold intolerance
        </label>
      </div>
<!--      Polyphagia-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="polyphagia"
          />
          <span class="mr-2"></span>Polyphagia
        </label>
      </div>
<!--      Polydipsia-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="polydipsia"
          />
          <span class="mr-2"></span>Polydipsia
        </label>
      </div>
<!--      Polyuria-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="polyuria"
          />
          <span class="mr-2"></span>Polyuria
        </label>
      </div>
<!--      Skin changes-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="skin_changes"
          />
          <span class="mr-2"></span>Skin changes
        </label>
      </div>
<!--      Hair Changes-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="hair_changes"
          />
          <span class="mr-2"></span>Hair Changes
        </label>
      </div>
<!--      Heat Intolerance-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="heat_intolerance"
          />
          <span class="mr-2"></span>Heat Intolerance
        </label>
      </div>
    </div>
<!--    Musclosk-->
    <h6 class="mt-3">Musclosk</h6>
    <div class="row">
<!--      Joint Pain-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="joint_pain"
          />
          <span class="mr-2"></span>Joint Pain
        </label>
      </div>
<!--      Joint Swelling-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="joint_swelling"
          />
          <span class="mr-2"></span>Joint Swelling
        </label>
      </div>
<!--      Muscle Cramps-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="muscle_cramps"
          />
          <span class="mr-2"></span>Muscle Cramps
        </label>
      </div>
<!--      Neck Pain-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="neck_pain"
          />
          <span class="mr-2"></span>Neck Pain
        </label>
      </div>
<!--      Joint Stiffness-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="joint_stiffness"
          />
          <span class="mr-2"></span>Joint Stiffness
        </label>
      </div>
<!--      Muscle Aches-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="muscle_aches"
          />
          <span class="mr-2"></span>Muscle Aches
        </label>
      </div>
<!--      Back Pain-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="back_pain"
          />
          <span class="mr-2"></span>Back Pain
        </label>
      </div>
<!--      Gait Changes-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="gait_changes"
          />
          <span class="mr-2"></span>Gait Changes
        </label>
      </div>
    </div>
<!--    Psych-->
    <h6 class="mt-3">Psych</h6>
    <div class="row">
<!--      Agitation-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="agitation"
          />
          <span class="mr-2"></span>Agitation
        </label>
      </div>
<!--      Suicidal ideation-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="suicidal_ideation"
          />
          <span class="mr-2"></span>Suicidal ideation
        </label>
      </div>
<!--      Sleep Changes-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="sleep_changes"
          />
          <span class="mr-2"></span>Sleep Changes
        </label>
      </div>
<!--      Self Injury-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="self_injury"
          />
          <span class="mr-2"></span>Self Injury
        </label>
      </div>
<!--      Anxiety-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="anxiety"
          />
          <span class="mr-2"></span>Anxiety
        </label>
      </div>
<!--      Mood Changes-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="mood_changes"
          />
          <span class="mr-2"></span>Mood Changes
        </label>
      </div>
<!--      Decreased concentraion-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="decreased_concentration"
          />
          <span class="mr-2"></span>Decreased concentration
        </label>
      </div>
<!--      Past History-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="past_history"
          />
          <span class="mr-2"></span>Past History
        </label>
      </div>
    </div>
<!--    Heme/Lymph-->
    <h6 class="mt-3">Heme/Lymph</h6>
    <div class="row">
<!--      Bruising-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="bruising"
          />
          <span class="mr-2"></span>Bruising
        </label>
      </div>
<!--      Lymph Node Swelling-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="lymph_node_swelling"
          />
          <span class="mr-2"></span>Lymph Node Swelling
        </label>
      </div>
<!--      Bleeding-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="bleeding"
          />
          <span class="mr-2"></span>Bleeding
        </label>
      </div>
    </div>
<!--    Eyes-->
    <h6 class="mt-3">Eyes</h6>
    <div class="row">
<!--      Discharge-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="discharge"
          />
          <span class="mr-2"></span>Discharge
        </label>
      </div>
<!--      Redness-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="redness"
          />
          <span class="mr-2"></span>Redness
        </label>
      </div>
<!--      Pain-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="pain"
          />
          <span class="mr-2"></span>Pain
        </label>
      </div>
      <!--      Itching-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="itching"
          />
          <span class="mr-2"></span>Itching
        </label>
      </div>
      <!--      Sensitivity to Light-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="sensitivity_to_light"
          />
          <span class="mr-2"></span>Sensitivity to Light
        </label>
      </div>
      <!--      Visual Disturbance-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            formControlName="visual_difdiculty"
          />
          <span class="mr-2"></span>Visual Disturbance
        </label>
      </div>
    </div>
<!--    GU-->
    <h6 class="mt-3">GU</h6>
    <div class="row">
<!--      Pain on Urination-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="pain_on_urination"
          />
          <span class="mr-2"></span>Pain on Urination
        </label>
      </div>
<!--      Increased Frequency-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="increased_frequency"
          />
          <span class="mr-2"></span>Increased Frequency
        </label>
      </div>
<!--      Nocturia-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="nocturia"
          />
          <span class="mr-2"></span>Nocturia
        </label>
      </div>
      <!--      Incontinence-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="incontinence"
          />
          <span class="mr-2"></span>Incontinence
        </label>
      </div>
      <!--      Vaginal Discharge-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="vaginal_discharge"
          />
          <span class="mr-2"></span>Vaginal Discharge
        </label>
      </div>
      <!--      Testicular pain-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="testicular_pain"
          />
          <span class="mr-2"></span>Testicular pain
        </label>
      </div>
      <!--      Blood in Urine-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="blood_in_urine"
          />
          <span class="mr-2"></span>Blood in Urine
        </label>
      </div>
      <!--      Urgency-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="urgency"
          />
          <span class="mr-2"></span>Urgency
        </label>
      </div>
      <!--      Flank Pain-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="flank_pain"
          />
          <span class="mr-2"></span>Flank Pain
        </label>
      </div>
      <!--      Dyspareunia-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="dyspareunia"
          />
          <span class="mr-2"></span>Dyspareunia
        </label>
      </div>
      <!--      Vaginal Bleeding-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="vaginal_bleeding"
          />
          <span class="mr-2"></span>Vaginal Bleeding
        </label>
      </div>
    </div>
<!--    GI-->
    <h6 class="mt-3">GI</h6>
    <div class="row">
<!--       Nausea-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="nausea"
          />
          <span class="mr-2"></span>Nausea
        </label>
      </div>
<!--      Diarrhea-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="diarrhea"
          />
          <span class="mr-2"></span>Diarrhea
        </label>
      </div>
<!--      Blooting-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="blooting"
          />
          <span class="mr-2"></span>Blooting
        </label>
      </div>
      <!--      Blood in Stool-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="blood_in_stool"
          />
          <span class="mr-2"></span>Blood in Stool
        </label>
      </div>
      <!--      Vomitting-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="vomitting"
          />
          <span class="mr-2"></span>Vomitting
        </label>
      </div>
      <!--      Abdominal Pain-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="abdominal_pain"
          />
          <span class="mr-2"></span>Abdominal Pain
        </label>
      </div>
      <!--     Change in Stool Habits-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="change_in_stool_habits"
          />
          <span class="mr-2"></span>Change in Stool Habits
        </label>
      </div>
      <!--      Rectal Pain-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="rectal_pain"
          />
          <span class="mr-2"></span>Rectal Pain
        </label>
      </div>
    </div>
    </div>
<!--    Allergy/Immuno-->
    <h6 class="mt-3">Allergy/Immuno</h6>
    <div class="row" formGroupName="patient_skin_history_attributes">
<!--       Environmental Allergies-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="environmental_allergies"
          />
          <span class="mr-2"></span>Environmental Allergies
        </label>
      </div>
<!--      Recurrent infections-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="recurrent_infections"
          />
          <span class="mr-2"></span>Recurrent infections
        </label>
      </div>
<!--      Food Allergies-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="food_allergies"
          />
          <span class="mr-2"></span>Food Allergies
        </label>
      </div>
      <!--      Immunodeficiency-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="immunodeficiency"
          />
          <span class="mr-2"></span>Immunodeficiency
        </label>
      </div>
    </div>
<!--    Neuro-->
    <h6 class="mt-3">Neuro</h6>
    <div formGroupName="patient_neuro_history_attributes" class="row">
<!--       Dizziness-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="dizziness"
          />
          <span class="mr-2"></span>Dizziness
        </label>
      </div>
<!--      Numbness-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="numbness"
          />
          <span class="mr-2"></span>Numbness
        </label>
      </div>
<!--      Weakness-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="weakness"
          />
          <span class="mr-2"></span>Weakness
        </label>
      </div>
      <!--      Memory Loss-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="memory_loss"
          />
          <span class="mr-2"></span>Memory Loss
        </label>
      </div>
      <!--      Facial Asymmetry-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="facial_asymmetry"
          />
          <span class="mr-2"></span>Facial Asymmetry
        </label>
      </div>
      <!--      Seizure-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="seizures"
          />
          <span class="mr-2"></span>Seizure
        </label>
      </div>
      <!--      Fainting Spells-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="fainting_spells"
          />
          <span class="mr-2"></span>Fainting Spells
        </label>
      </div>
      <!--      Tingling-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="tingling"
          />
          <span class="mr-2"></span>Tingling
        </label>
      </div>
      <!--      Tremors-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="tremors"
          />
          <span class="mr-2"></span>Tremors
        </label>
      </div>
      <!--      Confusion-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="confusion"
          />
          <span class="mr-2"></span>Confusion
        </label>
      </div>
      <!--      Speech difficulty-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <label class="checkbox checkbox-sm">
          <input
            type="checkbox"
            autocomplete="off"
            formControlName="speech_difficulty"
          />
          <span class="mr-2"></span>Speech difficulty
        </label>
      </div>
    </div>
  </div>
</div>
</div>
