<div class="row">
  <div class="col-md-12 col-sm-12 col-12">
    <form
      [formGroup]="PatientRegistrationForm"
      (ngSubmit)="newRegistration()"
    >
      <div class="row mb-3">
        <div
          class="col-lg-2 col-md-3 col-sm-12 col-12 pr-0 mb-4 mb-sm-4 mb-md-0"
        >
          <div class="card h-auto">
            <div class="card-body patient-img-card" style="padding: 1.1rem">
              <div class="row justify-content-center">
                <!--Image Upload-->
                <div
                  class="image-input image-input-empty image-input-outline mb-4"
                  id="kt_image_5"
                  style="position: relative"
                >
<!--                  <img-->
<!--                    *ngIf="-->
<!--                                patient?.profile_image;-->
<!--                                else profileImageElse-->
<!--                              "-->
<!--                    [src]="patient.profile_image"-->
<!--                    class="patient_img"-->
<!--                    style="-->
<!--                                object-fit: contain;-->
<!--                                position: absolute;-->
<!--                                padding: 5px;-->
<!--                                width: 100%;-->
<!--                                height: 100%;-->
<!--                              "-->
<!--                  />-->
<!--                  <ng-template #profileImageElse>-->
                  <img
                    [src]="
                                  patientImage
                                    ? patientImage
                                    : '../../../../../assets/media/users/blank.png'
                                "
                    class="patient_img"
                    style="
                                  object-fit: contain;
                                  position: absolute;
                                  padding: 5px;
                                  width: 100%;
                                  height: 100%;
                                "
                  />
<!--                  </ng-template>-->

                  <div class="image-input-wrapper"></div>

                  <!--                            <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">-->
                  <!--                              <i class="fa fa-pen icon-sm text-muted"></i>-->
                  <!--                              <input type="file" name="profile_avatar" (change)="fileUploadFunc($event)" accept=".png, .jpg, .jpeg"/>-->
                  <!--                              <input type="hidden" name="profile_avatar_remove"/>-->
                  <!--                            </label>-->
                </div>
              </div>

              <div class="row text-center">
<!--                <div class="col-12">-->
<!--                  <button-->
<!--                    class="btn btn-primary btn-sm mb-2 button-width"-->
<!--                    type="button"-->
<!--                    (click)="cambox()"-->
<!--                  >-->
<!--                    {{ dataValues.picture }}-->
<!--                  </button>-->
<!--                  <br />-->
<!--                  <button-->
<!--                    class="btn btn-primary btn-sm button-width"-->
<!--                    type="button"-->
<!--                    (click)="getThumbImpression()"-->
<!--                  >-->
<!--                    {{ dataValues.impression }}-->
<!--                  </button>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-10 col-md-10 col-sm-12 col-12">
          <div class="card">
            <div class="card-body patient-img-card" style="padding: 1rem">
              <div class="row mb-0 mx-0">
                <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>{{ dataValues.title }}</label>
                    <select
                      class="form-control form-control-sm normal-height p-lr-0"
                      formControlName="title"
                      [ngClass]="{
                                  'is-invalid': submitted && f.title.errors
                                }"
                      (change)="onChange()"
                    >
                      <option value="mr">Mr</option>
                      <option value="mrs">Mrs</option>
                      <option value="miss">Miss</option>
                      <option value="bo">B/O</option>
                      <option value="do">D/O</option>
                      <option value="dr">Dr</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label
                    >{{ dataValues.first_name }}
                      <span class="text-danger">*</span></label
                    >
<!--                    [value]="-->
<!--                    PatientRegistrationForm.value.first_name-->
<!--                    | titlecase-->
<!--                    "-->
                    <input
                      type="text"
                      autofocus
                      formControlName="first_name"
                      class="form-control form-control-sm"
                      placeholder="{{ dataValues.first_name }}"
                      [ngClass]="{
                                  'is-invalid': submitted && f.first_name.errors
                                }"
                      (keypress)="_keyPressOnlyChar($event)"
                    />
                  </div>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>{{ dataValues.last_name }}</label>
<!--                    [value]="-->
<!--                    PatientRegistrationForm.value.last_name-->
<!--                    | titlecase-->
<!--                    "-->
                    <input
                      type="text"
                      formControlName="last_name"
                      class="form-control form-control-sm"
                      placeholder="{{ dataValues.last_name }}"
                      (keypress)="_keyPressOnlyChar($event)"
                    />
                  </div>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>
                      {{ dataValues.number }}
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="phone1"
                      id="phone1"
                      maxlength="11"
                      class="form-control form-control-sm"
                      placeholder="{{ dataValues.number }}"
                      (keypress)="_onlyNumeric($event)"
                      (focusout)="searchPatientByPhoneNumber()"
                      [ngClass]="{'is-invalid': submitted && f.phone1.errors}"
                    />
                  </div>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>
                      {{ dataValues.cnic }}
                      <span *ngIf="cnicMandatory"  class="text-danger"
                      >*</span
                      >
                    </label>
                    <input
                      type="text"
                      formControlName="patient_nic"
                      id="nic"
                      class="form-control form-control-sm"
                      placeholder="{{ dataValues.cnic }}"
                      (keypress)="_onlyNumeric($event)"
                      maxlength="13"
                      [ngClass]="{
                                  'is-invalid':
                                    submitted && f.patient_nic.errors
                                }"
                    />
                  </div>
                </div>


                <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div style="display: flex !important">
                    <div class="">
                      <label>
                        {{ dataValues.age }}
                        <span class="text-danger">*</span></label
                      >
                      <div class="d-flex">
                        <div class="col-5 pl-0 pr-0">
                          <input
                            type="text"
                            min="0"
                            max="150"
                            placeholder="{{ dataValues.age }}"
                            formControlName="pat_age_value"
                            class="form-control form-control-sm input-transparent p-r-0"
                            (keypress)="checkAge($event); _onlyNumeric($event)"
                            (keyup)="checkAge($event)"
                            [ngClass]="{
                                    'is-invalid':
                                      submitted && f.pat_age_value.errors
                                  }"
                          />
                        </div>
                        <div class="col-7 pr-0">
                          <select
                            style="text-transform: capitalize"
                            class="form-control form-control-sm"
                            formControlName="pat_age_type"
                            [value]="selected_age_type || 'years'"
                          >
                            <option
                              [value]="age_type"
                              *ngFor="let age_type of age_types"
                            >
                              {{ age_type }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>
                      {{ dataValues.gender }}
                      <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-control form-control-sm"
                      formControlName="gender"
                      [ngClass]="{
                                  'is-invalid': submitted && f.gender.errors
                                }"
                    >
                      <option value="" [disabled]="true">
                        {{ dataValues.select }}
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="unidentified">
                        Unidentified
                      </option>
                      <option value="transgender">Transgender</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div class="form-group"
                       [ngClass]="{
                              'is-invalid':
                                submitted && f.patient_type_id.errors
                            }">
                    <label> {{ dataValues.patient_type }} </label>
                    <select
                      class="form-control form-control-sm"
                      formControlName="patient_type_id"
                      [ngModel]="private_patient_type_id"
                      id="selected_type"
                    >
                      <option value="" [disabled]="true">
                        {{ dataValues.select }}
                      </option>
                      <option
                        [value]="patient_type.id"
                        *ngFor="let patient_type of patient_types"
                      >
                        {{ patient_type.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>
                      {{ dataValues.city }}
                    </label
                    >
                    <div
                    >
                      <select
                        class="form-control form-control-sm city bootstrap-select-container"
                        formControlName="city_id"
                        data-container="body"
                        data-live-search="true"
                      >
                        <option value="" [disabled]="true">
                          {{ dataValues.select }}
                        </option>
                        <ng-container *ngFor="let city of cityList">
                          <option [value]="city.id">
                            {{ city.name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-8 col-sm-10 col-12">
                  <div class="form-group">
                    <label> {{ dataValues.address }} </label>
                    <input
                      type="text"
                      formControlName="address1"
                      class="form-control form-control-sm"
                      autocomplete="off"
                      placeholder="{{ dataValues.address }}"
                    />
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3 col-3 mt-6 text-right">
                  <div class="form-group">
                    <button type="button" class="btn btn-sm btn-primary mr-2" (click)="clearFormConfirm()">Refresh</button>
                    <button type="submit" class="btn btn-sm btn-primary" [disabled]="isDisable">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!--Clear Confirmation Modal-->
<div class="example-preview">
  <div>
    <ng-template #clearConfirmModal let-c="close" let-d="dismiss">
      <form>
        <div class="">
          <div class="modal-header p-3">
            <h4 class="modal-title">Confirmation</h4>
            <a
              class="close cursor-pointer"
              aria-label="Close"
              (click)="d('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <div class="modal-body">
            <div>Are you sure you sure you want to clear the fields?</div>
          </div>
          <div class="modal-footer p-3">
            <a class="btn btn-sm btn-light-primary" (click)="d('Cross click')"
            >Close</a
            >
            <button
              type="submit"
              class="btn btn-sm btn-light-primary"
              (submit)="resetPatientRegistrationForm()"
              (click)="resetPatientRegistrationForm()"
            >
              Confirm
            </button>
          </div>
        </div>
      </form>
    </ng-template>
  </div>
</div>

<!--Image Modal-->
<div class="example-preview">
  <div>
    <ng-template #imageModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Capturing the Image!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <div>
            <div *ngIf="showImage === false">
              <webcam
                [height]="199.99999999999997"
                [width]="266.66666666666663"
                [trigger]="triggerObservable"
                (imageCapture)="handleImage($event)"
                *ngIf="showWebcam"
                [allowCameraSwitch]="allowCameraSwitch"
                [switchCamera]="nextWebcamObservable"
                [videoOptions]="videoOptions"
                [imageQuality]="1"
                (cameraSwitched)="cameraWasSwitched($event)"
                (initError)="handleInitError($event)"
              ></webcam>
            </div>
            <img
              [src]="webcamImage.imageAsDataUrl"
              style="width: 266px; height: 200px"
              class=""
              *ngIf="showImage === true"
              alt=""
            />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === false"
            (click)="triggerSnapshot()"
          >
            Take A Image
          </button>
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === true"
            (click)="resetImageBox()"
          >
            Reset
          </button>
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === true"
            (click)="captureImageComplete()"
          >
            Done
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div
  class="modal fade"
  id="patientFoundAgainstNumberModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <form>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Patient is already register against this phone number</p>
        </div>
        <div class="modal-footer">
          <a
            type="button"
            class="btn btn-light-primary font-weight-bold"
            data-dismiss="modal"
          >Close</a
          >
          <button
            type="button"
            class="btn btn-primary font-weight-bold"
            id="patientFoundAgainstNumberBtn">
<!--            (submit)="patientFoundAgainstNumber()"-->
<!--            (click)="patientFoundAgainstNumber()"-->
<!--          >-->
            See Patient
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
