import { Component, OnInit } from '@angular/core';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HospitalService } from '../../../../../services/hospital.service';
import { formatDate } from '@angular/common';
import { RouteShareService } from '../../../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-print-shift-report',
  templateUrl: './print-shift-report.component.html',
  styleUrls: ['./print-shift-report.component.css'],
})
export class PrintShiftReportComponent implements OnInit {
  today = formatDate(new Date(), 'MM-yyyy', 'en');
  locationData = [];
  shiftValue = [];
  shiftLength: any;
  settingData: any;
  reportMonth: any;
  showMonth: any;
  reportLocation: any;
  month: any;
  selectedLocation = '';
  department: any;
  shiftData: any;
  showData = false;
  showDesignation = true;
  depart: any;
  screenName = '';

  constructor(
    private rosterServiceService: RosterServiceService,
    private ngxLoader: NgxUiLoaderService,
    private hospitalService: HospitalService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }
  getLocation(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.locationData = data.location;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        this.locationData = data.location;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getRostarShiftReport(): any {
    var month = $('.month').val();
    if (month === undefined || month === null || month === '') {
      toastr.error('Please select Month');
      return;
    }
    // if ( this.selectedLocation === undefined || this.selectedLocation === null || this.selectedLocation === '') {
    //   toastr.error('Please select Location');
    //   return;
    // }
    if (!this.selectedLocation && !this.department) {
      toastr.error('Please select atleast one filed (Location or department)');
      return;
    }
    console.log('inside loan report');
    this.ngxLoader.start();
    this.rosterServiceService
      .printRostarShiftReport(
        month,
        this.selectedLocation,
        this.department || '',
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.month = $('.month').val();
          const monthSplit = this.month.split('-');
          this.showMonth = new Date(
            monthSplit[1],
            monthSplit[0],
            0,
            0,
            0,
            0,
            0,
          );
          this.showData = true;
          this.shiftValue = data.roster;
          this.shiftData = data.shift;
          if (!this.shiftValue.length) {
            toastr.error('Record Not Found!');
          }
          if (data.roster) {
            this.shiftLength = this.shiftValue[0].roster_detail;
          }
        },
        (err) => {
          toastr.error(err.error.error);
          this.ngxLoader.stop();
        },
      );
  }
  printReport(): any {
    window.print();
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getDate(): any {
    $('.month').datepicker({
      format: 'mm-yyyy',
      viewMode: 'months',
      minViewMode: 'months',
      orientation: 'bottom left',
      // startDate: this.today,
    });
    $('.month').datepicker('setdate', this.today);
    this.month = $('.month').val();
    console.log('this.month', this.month);
  }
  ngOnInit(): void {
    this.getLocation();
    this.getSettingsData();
    this.getDate();
    this.getDepartments();
  }
}
