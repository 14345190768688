import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import SignaturePad from 'signature_pad';
import { TemplatesService } from '../../../../services/templates.service';
import { HospitaldoctorService } from '../../../../services/hospitaldoctor.service';
import { LookupService } from '../../../../services/lookup.service';
import { ConsentFormService } from '../../../../services/consent-form.service';
import { AnesthesiaConsent } from '../../../_models/anesthesia-consent';
import { environment } from '../../../../../environments/environment';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-consent-form',
  templateUrl: './consent-form.component.html',
  styleUrls: ['./consent-form.component.css'],
})
export class ConsentFormComponent implements OnInit {
  @ViewChild('canvasDataAnesthesia', { static: true })
  canvasDataAnesthesia: ElementRef;

  @Input() patient: any;
  @Input() visitType: any;
  @Input() doctor: any;
  @Input() isAdd: boolean;

  anesthesiaConsentForm: UntypedFormGroup;

  isSelectTemplete = false;
  consentTemplete: any;
  consentTempletes: any[];
  anesthesiaImageUrl: any;
  urlButton = false;
  consent_templete: any;
  sig: SignaturePad;
  isSubmitAnesthesiaConsent = false;
  sigStatus = false;
  // doctor = [];
  lookups = [];
  anesthesia_consent: AnesthesiaConsent;
  signatureAn: any;
  consentPatientTemplate: any[];
  anesthesia_consent_id: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private templatesService: TemplatesService,
    private hospitalDoctorsService: HospitaldoctorService,
    private lookupService: LookupService,
    private consentFormService: ConsentFormService,
  ) {}

  ngOnInit(): void {
    this.anesthesiaConsentForm = this.formBuilder.group({
      anesthesia_technique: [''],
      anesthesia_name: [''],
      anesthesia_type: [''],
      consent_type: ['General'],
      employee: [''],
      technique: [''],
      consent_templete_id: ['', Validators.required],
      relative_name1: ['', Validators.required],
      relative_relation1: ['', Validators.required],
      witness_name1: [''],
      witness_relation1: [''],
      consultant_id: ['', Validators.required],
    });

    this.fetchLookup();
    this.clearCanvasAn();
  }

  ngOnChanges(changes: SimpleChanges): any {
    this.fetchPatientAnesthesiaTemplates(changes.patient.currentValue);
    this.fetchAnesthesiaTemplates(
      changes.patient.currentValue,
      'anesthesia_template',
    );
  }

  fetchAnesthesiaTemplates(val, type): any {
    this.consentTempletes = [];
    this.sig = new SignaturePad(this.canvasDataAnesthesia.nativeElement);
    this.sigStatus = true;
    $('.signature').css('display', 'none');
    this.anesthesiaImageUrl = '';
    this.urlButton = true;
    this.ngxLoader.start();
    this.templatesService.searchTemplatesV2('', type, 'true', '').subscribe(
      (data) => {
        this.consentTempletes = data.template_configurators;
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
    setTimeout(() => {
      $('#anesthesiaTechnique').selectpicker();
      $('#anesthesiaTechnique').selectpicker('refresh');
    }, 500);
  }

  addRichTextEditor(i): any {
    setTimeout(() => {
      $('#paragraph' + i).summernote('disable');
    }, 500);
  }

  selectAnesthesiaTemplate(templeteId): any {
    if (this.anesthesiaConsentForm.get('consent_templete_id').value === '') {
      this.isSelectTemplete = false;
      return;
    }
    this.consentTemplete = this.consentTempletes.find(
      (t: any) => t.id === Number(templeteId),
    );
    this.isSelectTemplete = true;
    if (this.anesthesiaImageUrl === '') {
      $('.signature').css('display', 'block');
      this.urlButton = false;
    }
    setTimeout(() => {
      if (this.consentTemplete.template_data.length > 0) {
        for (let i = 0; i < this.consentTemplete.template_data.length; i++) {
          if (this.consentTemplete.config_type !== '') {
            this.addRichTextEditor(i);
          }
        }
      }
    }, 1000);
    this.consent_templete = this.consentTemplete;
    setTimeout(() => {
      $('#allConsultant').selectpicker();
      $('#allConsultant').selectpicker('refresh');
    }, 1500);
  }

  clearCanvasAn(): any {
    this.sig.clear();
  }

  get cont(): any {
    return this.anesthesiaConsentForm.controls;
  }

  proposedAnesthesia(): any {
    if (this.anesthesiaConsentForm.get('consent_type').value === 'Anesthesia') {
      this.anesthesiaConsentForm
        .get('employee')
        .setValidators([Validators.required]);
      this.anesthesiaConsentForm.get('employee').updateValueAndValidity();
      this.anesthesiaConsentForm
        .get('anesthesia_type')
        .setValidators([Validators.required]);
      this.anesthesiaConsentForm
        .get('anesthesia_type')
        .updateValueAndValidity();
      this.anesthesiaConsentForm
        .get('technique')
        .setValidators([Validators.required]);
      this.anesthesiaConsentForm.get('technique').updateValueAndValidity();
      setTimeout(() => {
        $('#anesthesiaTechnique').selectpicker();
        $('#allEmployee').selectpicker();
      }, 1000);
    } else {
      this.anesthesiaConsentForm.get('employee').clearValidators();
      this.anesthesiaConsentForm.get('employee').updateValueAndValidity();
      this.anesthesiaConsentForm.get('anesthesia_type').clearValidators();
      this.anesthesiaConsentForm
        .get('anesthesia_type')
        .updateValueAndValidity();
      this.anesthesiaConsentForm.get('technique').clearValidators();
      this.anesthesiaConsentForm.get('technique').updateValueAndValidity();
    }
  }

  fetchLookup(): any {
    this.ngxLoader.start();
    this.lookupService.indexV2('').subscribe(
      (data) => {
        this.lookups = data.lookups;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updateSignatureAn(): any {
    this.anesthesiaImageUrl = '';
    this.urlButton = false;
    $('.signature').css('display', 'block');
  }

  saveAnesthesiaConsentForm(): any {
    this.isSubmitAnesthesiaConsent = true;
    if (this.anesthesiaConsentForm.invalid) {
      return;
    }
    if (this.urlButton === false) {
      this.getImageAn();
    }
    this.ngxLoader.start();
    this.consentFormService
      .createAnesthesiaConsentForm(
        this.anesthesiaConsentForm.value,
        this.patient,
        this.signatureAn,
      )
      .subscribe(
        (data) => {
          this.isSubmitAnesthesiaConsent = false;
          this.anesthesia_consent = data.anesthesia_consent;
          this.fetchPatientAnesthesiaTemplates('');
          this.anesthesiaImageUrl = '';
          $('.signature').css('display', 'none');
          this.urlButton = false;
          this.clearCanvasAn();
          this.isSelectTemplete = false;
          this.anesthesiaConsentForm.reset({
            anesthesia_technique: '',
            anesthesia_name: '',
            anesthesia_type: '',
            consent_type: 'General',
            employee: '',
            technique: '',
            consent_templete_id: '',
            relative_name1: '',
            relative_relation1: '',
            witness_name1: '',
            witness_relation1: '',
            consultant_id: '',
          });
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  getImageAn(): any {
    this.signatureAn = this.sig.toDataURL();
  }

  fetchPatientAnesthesiaTemplates(val): any {
    this.consentPatientTemplate = [];
    this.sig = new SignaturePad(this.canvasDataAnesthesia.nativeElement);
    this.sigStatus = true;
    $('.signature').css('display', 'none');
    this.anesthesiaImageUrl = '';
    this.urlButton = true;
    this.ngxLoader.start();
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.templatesService
      .searchTemplateV2(this.patient, this.visitType)
      .subscribe(
        (data) => {
          this.consentPatientTemplate = data.anesthesia_consent;
          this.ngxLoader.stop();
          window.scroll(0, 0);
        },
        (err) => {
          toastr.error(err.error.message);
          this.ngxLoader.stop();
        },
      );
  }

  printAnesthesiaForm(id): any {
    this.anesthesia_consent_id = id;
    window.open(
      'pdf-reports/patient-consent-form-report?id=' +
        this.patient.id +
        '&visit_id=' +
        this.patient.visit_id +
        '&invoice_id=' +
        '&mrn=' +
        this.patient.mrn +
        '&anesthesia_consent_id=' +
        this.anesthesia_consent_id +
        '&patient_id=' +
        this.patient.id +
        '&admission_id=' +
        this.patient.admissions[this.patient.admissions.length - 1].id,
    );
    // window.open(environment.rorUrl + '/v4/anesthesia_consents/anesthesia_form.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&anesthesia_consent_id=' + this.anesthesia_consent_id + '&patient_id=' + this.patient.id);
  }
}
