<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            Discharge Certificate
          </h5>
          <!--          tab headings-->

          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-primary btn-sm" (click)="printReport()">
          Print
        </button>
        <button class="btn btn-primary btn-sm ml-2" (click)="goBack()">
          Back
        </button>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="card pdf-card" id="reports">
        <div class="card-body discharge-main">
          <div class="d-none d-print-block">
            <table class="table">
              <tbody>
                <tr class="borders">
                  <td style="width: 15%">
                    <img
                      [src]="settingData?.logo?.url"
                      alt="Logo"
                      style="width: auto; height: 106px"
                    />
                  </td>
                  <td style="width: 85%">
                    <div class="text-center">
                      <h3>{{ settingData?.title }}</h3>
                    </div>
                    <div class="font-size-h5 text-center">
                      <h5>{{ settingData?.address }}</h5>
                    </div>
                    <div class="text-center">
                      <h2 style="font-size: 23px">Discharge Slip</h2>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row m-0">
            <div class="col-6">
              <div>
                <span class="d-block">Release from responsibility for</span>
                <span class="font-weight-bolder border-bottom-dark"
                  ><u>LEAVING AGAINST MEDICAL ADVICE</u></span
                >
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Patient's Name:</span>
                <span class="heading-detail">{{
                  admissionDischargedPatientData?.patient?.first_name +
                    " " +
                    admissionDischargedPatientData?.patient?.last_name
                }}</span>
              </div>
            </div>
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">MR No:</span>
                <span class="heading-detail">{{
                  admissionDischargedPatientData?.patient?.mrn
                }}</span>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Date:</span>
                <span class="heading-detail">{{
                  admissionDischargedPatientData?.discharge_patient?.created_at
                    | date: "dd-MM-yyyy"
                }}</span>
              </div>
            </div>
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Time:</span>
                <span class="heading-detail">{{
                  admissionDischargedPatientData?.discharge_patient?.created_at
                    | date: "hh:mm a"
                }}</span>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Ward:</span>
                <span class="heading-detail">{{
                  admissionDischargedPatientData?.ward?.title
                }}</span>
              </div>
            </div>
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Consultant:</span>
                <span class="heading-detail">{{
                  admissionDischargedPatientData?.consultant
                }}</span>
              </div>
            </div>
          </div>

          <!-- Patient Box -->
          <div class="pt-6">
            <div class="row m-0">
              <div class="col-12">
                I, <span class="patientName heading-detail"></span> a patient in
                {{ settingData.title }}. am leaving against the advice of
                physician. I have been informed about involving risk(s), and
                hereby release my physician, the hospital administration and all
                staff, from responsibility from any ill effects which many
                result form this action.
              </div>
            </div>

            <div class="row m-0">
              <div class="col-12 heading-main pl-0 mb-2">
                <div class="heading-sub-main">
                  <span class="heading-title">Comments:</span>
                  <span class="heading-detail"></span>
                </div>
              </div>
              <div class="col-12 heading-main pl-0 mb-2">
                <div class="heading-sub-main">
                  <span class="heading-title"></span>
                  <span class="heading-detail"></span>
                </div>
              </div>
              <div class="col-12 heading-main pl-0 mb-2">
                <div class="heading-sub-main">
                  <span class="heading-title"></span>
                  <span class="heading-detail"></span>
                </div>
              </div>
              <div class="col-12 heading-main pl-0">
                <div class="heading-sub-main">
                  <span class="heading-title"></span>
                  <span class="heading-detail"></span>
                </div>
              </div>
            </div>

            <div class="row m-0" style="margin-bottom: 5px !important">
              <div class="col-4 heading-main pl-0">
                <div class="heading-sub-main">
                  <span class="heading-title">Date:</span>
                  <span class="heading-detail"></span>
                </div>
              </div>
              <div class="col-4 heading-main pl-0">
                <div class="heading-sub-main">
                  <span class="heading-title">Time:</span>
                  <span class="heading-detail"></span>
                </div>
              </div>
              <div class="col-4 align-self-end">
                <div style="border-bottom: 1px solid black"></div>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-8"></div>
              <div class="col-4 text-center sign-heading">
                Patient's Signature
              </div>
            </div>

            <div
              class="row m-0"
              style="margin-bottom: 5px !important; margin-top: 50px !important"
            >
              <div class="col-8">
                <div style="border-bottom: 1px solid black"></div>
              </div>
              <div class="col-4">
                <div style="border-bottom: 1px solid black"></div>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-8 text-center sign-heading">
                Name and Signature of Guardian/Relative
              </div>
              <div class="col-4 text-center sign-heading">
                Relationship to Patient
              </div>
            </div>

            <div
              class="row m-0"
              style="margin-bottom: 5px !important; margin-top: 50px !important"
            >
              <div class="col-8">
                <div style="border-bottom: 1px solid black"></div>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-8 text-center sign-heading">
                Name and Signature of Witness
              </div>
            </div>

            <hr class="my-2 bg-dark" />

            <div class="text-center mb-4">To be filled by the staff</div>
            <div class="row m-0" style="margin-bottom: 20px !important">
              <div class="col-12">
                Reasons for leaving Against Medical Advice
              </div>
            </div>

            <div class="row m-0">
              <div class="col-6">
                <span class="pdfCheckBox"></span>Financial
              </div>
              <div class="col-6">
                <span class="pdfCheckBox"></span>Domestic Problems
              </div>
              <div class="col-6">
                <span class="pdfCheckBox"></span>Dissatisfied with
                Ward/inpatients routines
              </div>
              <div class="col-6">
                <span class="pdfCheckBox"></span>Wish to continue treatment
                elsewhere
              </div>
              <div class="col-6 heading-main pl-0 mt-4">
                <div class="heading-sub-main">
                  <span
                    class="heading-title"
                    style="padding-left: 12px !important"
                    >Primary Diagnosis:</span
                  >
                  <span class="heading-detail"></span>
                </div>
              </div>
            </div>

            <div class="row m-0">
              <div class="col-12 heading-main pl-0 mb-2">
                <div class="heading-sub-main">
                  <span class="heading-title">Comments:</span>
                  <span class="heading-detail"></span>
                </div>
              </div>
              <div class="col-12 heading-main pl-0 mb-2">
                <div class="heading-sub-main">
                  <span class="heading-title"></span>
                  <span class="heading-detail"></span>
                </div>
              </div>
              <div class="col-12 heading-main pl-0 mb-2">
                <div class="heading-sub-main">
                  <span class="heading-title"></span>
                  <span class="heading-detail"></span>
                </div>
              </div>
            </div>

            <div
              class="row m-0"
              style="margin-bottom: 5px !important; margin-top: 60px !important"
            >
              <div class="col-3">
                <div style="border-bottom: 1px solid black"></div>
              </div>
              <div class="col-3">
                <div style="border-bottom: 1px solid black"></div>
              </div>
              <div class="col-3">
                <div style="border-bottom: 1px solid black"></div>
              </div>
              <div class="col-3">
                <div style="border-bottom: 1px solid black"></div>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-3 text-center sign-heading">Nurse's Sign</div>
              <div class="col-3 text-center sign-heading">Date/Time</div>
              <div class="col-3 text-center sign-heading">Physicians Sign</div>
              <div class="col-3 text-center sign-heading">Date/Time</div>
            </div>
          </div>
        </div>
      </div>

      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
