import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BloodBankService } from '../../../services/blood-bank.service';
import { ServiceService } from '../../../services/service.service';
import { PricingService } from '../../../services/pricing.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {PatientService} from "../../../services/patient.service";

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-zakat',
  templateUrl: './zakat.component.html',
  styleUrls: ['./zakat.component.css'],
})
export class ZakatComponent implements OnInit {
  @Input() patient: any;
  zakatForm: UntypedFormGroup;
  PatientSearchForm: UntypedFormGroup;

  questionnaires = [];
  allServices = [];
  options: any;
  pricing: any;
  isSubmit = false;
  isDisabledSubmit = false;
  totalScore = 0;
  zakatListing = [];
  patients: any;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private bloodBankService: BloodBankService,
    private serviceService: ServiceService,
    private pricingService: PricingService,
    private fb: UntypedFormBuilder,
    private patientService: PatientService,
  ) {
    this.zakatForm = this.fb.group({
      service_id: ['', Validators.required],
      estimated_cost: [''],
      donated_by: [''],
      org_share: [''],
      patient_share: [''],
      total_score: [''],
      pricing_id: [''],
      visit_id: [''],
      patient_id: [''],
    });
    this.PatientSearchForm = this.fb.group({
      search_query: [''],
      type: ['default'],
    });
  }

  fetchQuestionnaires(): any {
    this.ngxLoader.start();
    this.bloodBankService.getOptimizeQuestionnaires('Zakat', '').subscribe(
      (data) => {
        this.questionnaires = data.questionnaires;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
        this.questionnaires = [];
      },
    );
  }

  fetchServices(): any {
    this.ngxLoader.start();
    this.serviceService.getServiceByTypesV2('OtService').subscribe(
      (data) => {
        this.allServices = data.services;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#service').selectpicker('refresh');
          $('#service').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchServicePrice(id): any {
    let billingCode;
    if (id) {
      billingCode = this.allServices.find(
        (t) => t.id === Number(id),
      ).billing_code;
    }
    this.ngxLoader.start();
    this.pricingService
      .searchPriceByMultipleServices(this.patient, billingCode)
      .subscribe(
        (price) => {
          this.pricing = price.pricings;
          this.zakatForm.get('estimated_cost').setValue(0);
          this.zakatForm.get('org_share').setValue(0);
          this.zakatForm.get('patient_share').setValue(0);
          if (this.pricing[0].is_configured === false) {
            toastr.error(this.pricing[0].message);
            this.zakatForm.get('pricing_id').setValue('');
          } else {
            this.zakatForm.get('pricing_id').setValue(this.pricing[0].id);
            let discount;
            if (
              this.patient.admissions.length > 0 &&
              this.patient.admitted === true
            ) {
              discount =
                (this.pricing[0].ipd_price / 100) *
                this.pricing[0].system_discount_percentage;
              this.zakatForm
                .get('estimated_cost')
                .setValue(this.pricing[0].ipd_price - discount);
            } else {
              discount =
                (this.pricing[0].opd_price / 100) *
                this.pricing[0].system_discount_percentage;
              this.zakatForm
                .get('estimated_cost')
                .setValue(this.pricing[0].opd_price - discount);
            }
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  _onlyNumeric(e): any {
    var charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  noDecimalPoint(event: any): any {
    return event.charCode >= 48 && event.charCode <= 57;
  }
  updateShares(type: string): any {
    const estimatedCost = this.zakatForm.get('estimated_cost').value;
    const orgShare = this.zakatForm.get('org_share').value;
    const patientShare = this.zakatForm.get('patient_share').value;
    if (type === 'org_share') {
      if (orgShare > estimatedCost) {
        this.zakatForm.get('org_share').setValue(estimatedCost);
        this.zakatForm.get('patient_share').setValue(0);
      } else {
        this.zakatForm.get('patient_share').setValue(estimatedCost - orgShare);
      }
    } else if (type === 'patient_share') {
      if (patientShare > estimatedCost) {
        this.zakatForm.get('patient_share').setValue(estimatedCost);
        this.zakatForm.get('org_share').setValue(0);
      } else {
        this.zakatForm.get('org_share').setValue(estimatedCost - patientShare);
      }
    }
  }
  submitZakat(): any {
    this.isSubmit = true;
    if (this.zakatForm.invalid) {
      return;
    }
    if (this.totalScore > 100) {
      toastr.error('Marks cannot be greater than 100');
      return;
    }
    this.zakatForm.get('patient_id').setValue(this.patient.id);
    this.zakatForm
      .get('patient_share')
      .setValue(Number(this.zakatForm.get('patient_share').value));
    this.zakatForm.get('visit_id').setValue(this.patient.visit_id);
    this.zakatForm.get('total_score').setValue(this.totalScore);
    const zakatData = this.zakatForm.value;
    zakatData.questions = this.questionnaires;
    this.isDisabledSubmit = true;
    this.ngxLoader.start();
    this.bloodBankService.submitZakat(zakatData).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Update Successfully');
        this.fetchQuestionnaires();
        this.totalScore = 0;
        this.isDisabledSubmit = false;
        this.clearForm();
        this.getZakatEligibilityListing();
      },
      (err) => {
        this.ngxLoader.stop();
        this.isDisabledSubmit = false;
        toastr.error(err.error.message);
      },
    );
  }
  clearForm(): any {
    this.isSubmit = false;
    this.zakatForm.reset({
      service_id: '',
      estimated_cost: '',
      org_share: '',
      patient_share: '',
      total_score: '',
      pricing_id: '',
    });
    setTimeout(() => {
      $('#service').selectpicker('refresh');
      $('#service').selectpicker();
    }, 500);
  }
  updateOption(ind: number, value: string): any {
    if (value) {
      let obj = this.questionnaires[ind].option_score_details.find(
        (m) => m.options === value,
      );
      this.questionnaires[ind].options = obj?.options;
      this.questionnaires[ind].score = Number(obj?.score) ?? 0;
    } else {
      this.questionnaires[ind].options = '';
      this.questionnaires[ind].score = 0;
    }
    this.totalScore = 0;
    this.questionnaires?.map((ques) => {
      this.totalScore += ques?.score ?? 0;
    });
  }
  getZakatEligibilityListing(): any {
    this.bloodBankService.getZakatEligibilityListing(this.patient).subscribe(
      (data) => {
        this.zakatListing = data;
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  fetchPatients(): any {
    this.patients = [];
    this.ngxLoader.start();
    this.patientService
      .searchPatientRegistrationV2(this.PatientSearchForm.value, false)
      .subscribe(
        (data) => {
          this.patients = data.patients;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  ngOnInit(): void {
    this.getZakatEligibilityListing();
    this.fetchQuestionnaires();
    this.fetchServices();
    this.fetchPatients();
  }
}
