<div class="bgImage">
  <div class="container-fluid" style="padding-top: 3rem; padding-bottom: 3rem">
    <div class="row reverse">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="heading">
          <div class="transparent-text">
            <div><h5>Join to participate in medical research surveys</h5></div>
            <p>
              If you are a physician or other health care provider who is
              interested in participating in our online medical marketing
              research studies and medical billing service, we invite you to add
              your name and contact information to our database. By doing so,
              you will be considered for possible participation in future
              studies. However, due to specific sampling criteria determined by
              each study's sponsor, we cannot guarantee that you will be
              selected. Please be assured that the information you provide on
              this site will be used solely to notify you of any studies for
              which you have been selected as a potential participant. Medxhub
              adheres to strict privacy policy standards. Your information will
              never be divulged to outside sources.
            </p>
          </div>
        </div>
        <div class="heading mt-3">
          <div class="transparent-text">
            <p>
              <b>Medxhub Clinical Trial Company :</b> A leading organization
              advancing medical research.
            </p>
            <p>
              <b>Study Design and Protocol Development :</b> Collaborative
              approach to develop robust study designs and meticulous protocols.
            </p>
            <p>
              <b>Site Selection and Management :</b> Extensive network of
              qualified investigative sites with comprehensive site management.
            </p>
            <span
              *ngIf="!MoreList"
              (click)="showMoreList()"
              style="cursor: pointer"
              ><b>Show more...</b></span
            >
            <span *ngIf="MoreList">
              <p>
                <b>Patient Recruitment and Retention :</b> Innovative strategies
                to effectively engage eligible participants for successful
                enrollment and retention.
              </p>
              <p>
                <b>Regulatory Compliance :</b> Expert assistance in navigating
                complex regulatory requirements and ensuring compliance.
              </p>
              <p>
                <b>Data Management and Analysis :</b> State-of-the-art
                technology and experienced biostatisticians for accurate data
                collection, processing, and analysis.
              </p>
              <p>
                <b>Safety Monitoring and Pharmacovigilance :</b> Vigilant
                systems to ensure patient safety and compliance with safety
                reporting requirements.
              </p>
            </span>
          </div>
        </div>
        <div class="heading mt-3">
          <div class="transparent-text">
            <h5>Medxhub Medical Billing Service</h5>
            <p>
              is an end-to-end Revenue Cycle Management Solution. Healthcare
              practices lose between 5-12% because of denials, poor
              negotiations, and underpayments. That’s why our teams of certified
              coding and billing professionals are here to boost your
              reimbursement streams and streamline your revenue workflows. Few
              Key Features:
            </p>
            <span
              *ngIf="!showMoreData"
              (click)="showMore()"
              style="cursor: pointer"
              ><b>Show more...</b></span
            >
            <span *ngIf="showMoreData">
              <div>· One Stop Smart Billing Solution</div>
              <div>· Dedicated Account Manager</div>
              <div>· Customized Monthly/Weekly Reports</div>
              <div>· Old Account Receivables Recovery</div>
              <div>· Patient Receivable Management</div>
              <div>· Maximum Revenue Collection</div>
              <div>· Timely Rejection/Denial Management</div>
              <div>· Active Account Receivable Management</div>

              <p>
                Get a Specialized Quote Now and get the best value through our
                RCM services. Your job is to provide care and our job is to
                maximize your revenue.
              </p>
            </span>
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h4>Registration Form</h4>
            </div>
            <hr />
            <form
              [formGroup]="doctorSurveyForm"
              (ngSubmit)="submitSurveyForm()"
            >
              <div class="row mt-10">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>First Name <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      formControlName="first_name"
                      class="form-control form-control-sm"
                      placeholder="First Name"
                      [ngClass]="{
                        'is-invalid':
                          isAdd && doctorSurveyForm.controls.first_name.errors
                      }"
                    />
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Last Name <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      formControlName="last_name"
                      class="form-control form-control-sm"
                      placeholder="Last Name"
                      [ngClass]="{
                        'is-invalid':
                          isAdd && doctorSurveyForm.controls.last_name.errors
                      }"
                    />
                  </div>
                </div>

                <!--start Email-->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Email <span class="text-danger">*</span></label>
                    <input
                      type="email"
                      class="form-control form-control-sm"
                      placeholder="Email"
                      formControlName="email"
                      [ngClass]="{
                        'is-invalid':
                          isAdd && doctorSurveyForm.controls.email.errors
                      }"
                    />
                  </div>
                </div>
                <!--              end Email-->
                <!--              speciality-->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Speciality</label>
                    <select
                      class="form-control form-control-sm selectPickerDepartment bootstrap-select-container"
                      data-live-search="true"
                      formControlName="speciality"
                    >
                      <option value="" [selected]="true">Select</option>
                      <ng-container *ngFor="let dep of depart">
                        <option [value]="dep.id">{{ dep.title }}</option>
                      </ng-container>
                      <!--                      <option>QA</option>-->
                      <!--                      <option>SE</option>-->
                      <!--                      <option>DR</option>-->
                    </select>
                  </div>
                </div>
                <!--              speciality-->
                <!--              medical ID number-->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Medical ID Number</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Medical ID Number"
                      formControlName="medical_id_number"
                      (keypress)="_onlyNumeric($event)"
                    />
                  </div>
                </div>
                <!--              medical ID number-->
                <!--              Primary Practice Name/ Organization/ Hospital Name-->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Organization/ Hospital Name</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Organization/ Hospital Name"
                      formControlName="primary_practice_name"
                    />
                  </div>
                </div>
                <!--              Primary Practice Name/ Organization/ Hospital Name-->
                <!--              Country Of Practice-->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <label>Country of practice</label>
                  <div class="form-group">
                    <div class="d-block">
                      <select
                        class="form-control form-control-sm country"
                        data-live-search="true"
                        formControlName="country_of_practice"
                      >
                        <option value="" [selected]="true">Select</option>
                        <ng-container *ngFor="let country of countriesList">
                          <option [value]="country.id">
                            {{ country.name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
                <!--              Country Of Practice-->
                <!--              Address-->
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Address"
                      formControlName="address"
                    />
                  </div>
                </div>
                <!--              Address-->
                <!--              phone number-->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Work Place Phone No.</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Work Place Phone No."
                      formControlName="work_place_phone_number"
                      maxlength="20"
                      (keypress)="_onlyNumeric($event)"
                    />
                  </div>
                </div>
                <!--              phone number-->
                <!--             personel phone number-->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Personal Phone No.</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Personal Phone No."
                      maxlength="20"
                      formControlName="personal_phone_number"
                      (keypress)="_onlyNumeric($event)"
                    />
                  </div>
                </div>
                <!--            personel  phone number-->

                <!--how wold you like receive the compensation?-->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                  <label>Compensation type</label>
                  <!--                    <label>How would you like receive the compensation?</label>-->
                  <div class="form-group">
                    <select
                      class="form-control form-control-sm selectpicker"
                      data-none-selected-text="Select"
                      multiple
                      formControlName="compensation"
                    >
                      <option value="" [disabled]="true">Select</option>
                      <option [ngValue]="1">Voucher</option>
                      <option [ngValue]="2">Bank transfer</option>
                      <option [ngValue]="3">Donation to charity</option>
                    </select>
                    <small style="font-size: 8px"
                      >* How would you like to receive the compensation?</small
                    >
                  </div>
                </div>
                <!--how wold you like receive the compensation?-->
                <div
                  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4"
                >
                  <div class="form-group">
                    <div class="checkbox-inline">
                      <label class="checkbox">
                        <input type="checkbox" formControlName="can_contact" />
                        <span style="margin-bottom: 25px"></span>
                        <p class="mr-3">
                          <b
                            >I agree to Medxhub contacting me in relation to
                            market research, clinical research, medical billing
                            and other healthcare studies and surveys.
                          </b>
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
                <!--                <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-12 mt-3">-->
                <!--                  <p><b>I agree to Medxhub contacting me in relation to market research, clinical research, medical-->
                <!--                    billing and other healthcare studies and surveys. </b></p>-->
                <!--                </div>-->
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <div class="checkbox-inline">
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          formControlName="privacy_policy_for_verification"
                        />
                        <span style="margin-bottom: 30px"></span>
                        <p class="mr-3">
                          <b
                            >By checking this box, you are confirming that you
                            have read and agree to our terms of use , privacy
                            policy , compensation provisions, and consent to the
                            collection , processing , and retention of your data
                            in accordance with our privacy policy.
                          </b>
                        </p>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <div class="checkbox-inline">
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          formControlName="photo_provide_for_verification"
                        />
                        <span style="margin-bottom: 37px"></span>
                        <p class="mr-3">
                          <b
                            >You will asked to provide a photo ID to safeguard
                            your identity through our privacy and verification
                            process. if you do not wish to provide this during
                            registration, you will have the option to skip , but
                            an Medxhub representative may be in contact
                            requesting an alternate form of verification.
                          </b>
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right">
                <button type="submit" class="btn btn-sm btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="confirmationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <!--          <img src="../../../assets/media/error/allert.png">-->
          <p class="font-weight-bold font-size-h5 text-dark-75 mt-3">
            Submitted Successfully
          </p>
          <!--          <p>Please connect with IT Administ/ration</p>-->
          <!--          <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>-->
        </div>
      </div>
    </div>
  </div>
</div>
