<div>
  <!--begin::Card-->
  <div class="card mb-4 d-print-none" *ngIf="isAdd">
    <div class="card-body user-card-body">
      <h4 class="mb-8">Blood Sugar Level</h4>

      <form [formGroup]="bloodSugarForm" (ngSubmit)="onBloodRequest()">
        <table class="table table-borderless m-0">
          <tbody>
            <tr>
              <td style="width: 20%">
                <div class="">
                  <label>Date <span class="text-danger">*</span></label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    formControlName="bsl_date"
                    [min]="previousDateTime | date: 'yyyy-MM-dd'"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitBloodRequest &&
                        bloodSugarForm.controls.bsl_date.errors
                    }"
                  />
                </div>
              </td>
              <td style="width: 20%">
                <div class="">
                  <label> Time<span class="text-danger">*</span></label>
                  <input
                    type="time"
                    class="form-control form-control-sm"
                    formControlName="bs_time"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitBloodRequest &&
                        bloodSugarForm.controls.bs_time.errors
                    }"
                  />
                </div>
              </td>
              <td style="width: 20%">
                <div class="selectOptionBottom">
                  <label>
                    BS Level (mg/dl) <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="bs_level"
                    (keydown)="isNumberKey($event)"
                    maxlength="3"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitBloodRequest &&
                        bloodSugarForm.controls.bs_level.errors
                    }"
                  />
                </div>
              </td>
              <td style="width: 20%">
                <div class="">
                  <label> Checked By <span class="text-danger">*</span></label>
                  <div>
                    <select
                      class="form-control form-control-sm"
                      id="checkedByPicker"
                      data-live-search="true"
                      formControlName="checked_by"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitBloodRequest &&
                          bloodSugarForm.controls.checked_by.errors
                      }"
                    >
                      <option value="">Select</option>
                      <option *ngFor="let emp of allEmployees" [value]="emp.id">
                        {{ emp?.first_name + " " + emp?.last_name }}
                        <span *ngIf="emp?.designation_name"
                          >({{ emp?.designation_name }})</span
                        >
                      </option>
                    </select>
                  </div>
                </div>
              </td>
              <td style="width: 20%">
                <div class="">
                  <label> Informed To<span class="text-danger">*</span> </label>

                  <select
                    class="form-control form-control-sm bootstrap-select-container"
                    id="docPicker"
                    data-live-search="true"
                    formControlName="doctor"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitBloodRequest &&
                        bloodSugarForm.controls.doctor.errors
                    }"
                  >
                    <option value="">select</option>
                    <option *ngFor="let emp of allEmployees" [value]="emp.id">
                      {{ emp?.first_name + " " + emp?.last_name }}
                      <span *ngIf="emp?.designation_name"
                        >({{ emp?.designation_name }})</span
                      >
                    </option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 20%">
                <div class="">
                  <label>Insulin Required</label>
                  <div>
                    <select
                      class="form-control form-control-sm"
                      data-live-search="true"
                      formControlName="insulin_req"
                      (change)="InsulineChanged($event.target.value)"
                    >
                      <!--                                <option value="">Select</option>-->
                      <option value="yes">Yes</option>
                      <option value="no" [selected]="true">No</option>
                    </select>
                  </div>
                </div>
              </td>
              <td
                style="width: 20%"
                *ngIf="bloodSugarForm.get('insulin_req').value === 'yes'"
              >
                <div class="">
                  <label>Insulin Type <span class="text-danger">*</span></label>
                  <div>
                    <select
                      class="form-control form-control-sm"
                      data-live-search="true"
                      formControlName="insulin_type"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitBloodRequest &&
                          bloodSugarForm.controls.insulin_type.errors
                      }"
                    >
                      <option value="">Select</option>
                      <ng-container *ngFor="let data of insulinList">
                        <option [value]="data.id">{{ data?.name }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </td>
              <td
                style="width: 20%"
                *ngIf="bloodSugarForm.get('insulin_req').value === 'yes'"
              >
                <div class="selectOptionBottom">
                  <label> Units <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="unit"
                    (keydown)="isNumberKey($event)"
                    maxlength="3"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitBloodRequest &&
                        bloodSugarForm.controls.unit.errors
                    }"
                  />
                </div>
              </td>
              <td
                style="width: 20%"
                *ngIf="bloodSugarForm.get('insulin_req').value === 'yes'"
              >
                <div class="">
                  <label> Given By <span class="text-danger">*</span></label>
                  <div>
                    <select
                      class="form-control form-control-sm"
                      id="givenByPicker"
                      data-live-search="true"
                      formControlName="given_by_nurse"
                      [ngClass]="{
                        'is-invalid':
                          isSubmitBloodRequest &&
                          bloodSugarForm.controls.given_by_nurse.errors
                      }"
                    >
                      <option value="">Select</option>
                      <!--                                <ng-container *ngFor="let data of staffNurses">-->
                      <!--                                  <option [value]="data.id">{{data?.name}}</option>-->
                      <!--                                </ng-container>-->
                      <option *ngFor="let emp of allEmployees" [value]="emp.id">
                        {{ emp?.first_name + " " + emp?.last_name }}
                        <span *ngIf="emp?.designation_name"
                          >({{ emp?.designation_name }})</span
                        >
                      </option>
                    </select>
                  </div>
                </div>
              </td>
              <td
                style="width: 20%"
                *ngIf="bloodSugarForm.get('insulin_req').value === 'yes'"
              ></td>
              <td
                style="width: 80%"
                colspan="4"
                *ngIf="bloodSugarForm.get('insulin_req').value === 'no'"
              ></td>
            </tr>
            <tr>
              <td colspan="4">
                <div class="">
                  <label>Comments</label>
                  <input
                    class="form-control form-control-sm"
                    formControlName="comments"
                  />
                </div>
              </td>
              <td class="text-right">
                <button type="submit" class="btn btn-primary btn-sm mt-5">
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
  <div class="row mb-0">
    <div class="col-12 mb-6 d-none d-print-block">
      <div style="width: 85%; float: left">
        <!-- header table -->
        <table class="table">
          <tbody>
            <tr class="borders">
              <td style="width: 15%">
                <img
                  [src]="settingData?.logo?.url"
                  style="width: auto; height: 106px"
                />
              </td>
              <td style="width: 85%">
                <div class="text-center">
                  <h2>{{ settingData?.title }}</h2>
                </div>
                <div class="font-size-h5 text-center">
                  <h3>{{ settingData?.address }}</h3>
                </div>
                <!--                          <div class="font-size-h5 text-center"><h5><span *ngIf="settingData?.phone_number">UAN: {{settingData?.phone_number}}</span> <span *ngIf="settingData?.fax"> FAX: {{settingData?.fax}}</span></h5></div>-->
                <div class="text-center">
                  <h3>Blood Sugar Chart</h3>
                </div>
                <div class="text-center">
                  <h3>
                    {{ bloodSugarData.value.from_date | date: "dd-MM-yyyy" }} to
                    {{ bloodSugarData.value.to_date | date: "dd-MM-yyyy" }}
                  </h3>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- patient info table -->
        <table class="w-100 info_table mb-2">
          <tbody>
            <tr>
              <td style="width: 45%">
                <h3 *ngIf="patient" class="m-0 line-clamp font-print-bold mr-5">
                  {{ patient?.title | titlecase }}
                  {{
                    patient?.first_name + " " + patient?.last_name + ","
                      | titlecase
                  }}
                  {{ patient?.age + "," }}
                  {{ patient?.gender.charAt(0) | titlecase }}
                </h3>
              </td>
              <td style="width: 55%">
                <h3 class="m-0 font-print-bold">MRN#{{ patient?.mrn }}</h3>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- patient info detail table -->
        <table class="w-100 info_table">
          <tbody>
            <tr>
              <td style="width: 15%"><strong>Father/Husband:</strong></td>
              <td style="width: 20%">{{ patient?.father_name | titlecase }}</td>
              <td style="width: 10%"></td>
              <td style="width: 15%"><strong>Patient Type:</strong></td>
              <td style="width: 20%">{{ patient?.patient_type?.name }}</td>
              <td style="width: 20%"></td>
            </tr>
            <tr>
              <td style="width: 15%"><strong>Contact:</strong></td>
              <td style="width: 20%">{{ patient?.phone1 }}</td>
              <td style="width: 10%"></td>
              <td style="width: 15%"><strong>Reg. Date:</strong></td>
              <td style="width: 20%">
                {{ patient?.created_at | date: "dd-MM-yyyy h:mm a" }}
              </td>
              <td style="width: 20%"></td>
            </tr>
            <tr>
              <td style="width: 15%"><strong>CNIC:</strong></td>
              <td style="width: 20%">{{ patient?.patient_nic }}</td>
              <td style="width: 10%"></td>
              <td style="width: 15%"><strong>Consultant:</strong></td>
              <td style="width: 20%">{{ patient?.consultant_name }}</td>
              <td style="width: 20%"></td>
            </tr>
            <tr>
              <td style="width: 15%"><strong>Address:</strong></td>
              <td style="width: 20%">{{ patient?.address1 }}</td>
              <td style="width: 10%"></td>
              <td style="width: 15%"><strong>Department:</strong></td>
              <td style="width: 20%">
                {{ patient?.admissions[0]?.referred_to }}
              </td>
              <td style="width: 20%"></td>
            </tr>
            <tr>
              <td style="width: 15%"><strong>Admission:</strong></td>
              <td style="width: 20%">
                {{
                  patient?.admissions[0]?.created_at | date: "dd-MM-yyyy h:mm a"
                }}
              </td>
              <td style="width: 10%"></td>
              <td style="width: 15%"><strong>Admission No:</strong></td>
              <td style="width: 20%">{{ patient?.admissions[0].id }}</td>
              <td style="width: 20%"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style="width: 15%; float: left">
        <div style="width: 100%; height: 180px">
          <img
            class="qrCode"
            style="height: 100%; width: 100%"
            *ngIf="patient?.qrcode_url"
            [src]="patient?.qrcode_url.url"
            alt=""
          />
        </div>
        <img
          style=""
          class="brCode"
          *ngIf="patient?.barcode_url"
          [src]="patient?.barcode_url?.url"
          alt=""
        />
        <div style="" class="font-weight-bolder font-size-h3 mrnCode" id="mrn">
          {{ patient?.mrn }}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="bloodData" class="card mb-4">
    <div class="card-body user-card-body">
      <form [formGroup]="bloodSugarData" (ngSubmit)="getBloodRequest()">
        <div class="row d-print-none">
          <div class="col-8 d-flex pl-0">
            <td class="col-5">
              <div class="form-group">
                <label>From Date</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  formControlName="from_date"
                />
              </div>
            </td>

            <td class="col-5">
              <div class="form-group">
                <label>To Date</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  formControlName="to_date"
                />
              </div>
            </td>
            <div class="col-2">
              <div class="col text-right mt-6">
                <button type="submit" class="btn btn-primary btn-sm ml-2">
                  Search
                </button>
              </div>
            </div>
          </div>
          <div class="col-4 text-right mt-5">
            <button
              class="btn btn-light-primary btn-sm d-print-none"
              (click)="printCurrentScreen()"
            >
              Print
            </button>
          </div>
        </div>
      </form>
      <div class="max-height-150 overflow-y-auto table-responsive text-nowrap">
        <table class="table table-head-custom table-vertical-center">
          <thead>
            <tr>
              <th style="width: 20%">Date/Time</th>
              <th style="width: 12%">Bs level</th>
              <th style="width: 12%">Checked By</th>
              <th style="width: 12%">Informed To</th>
              <th style="width: 10%">Insulin Required</th>
              <th style="width: 12%">Insulin Type</th>
              <th style="width: 10%">Units</th>
              <th style="width: 12%">Given By</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let b of bloodData">
              <tr>
                <td>
                  <span class="d-block"
                    >{{ b?.bsl_date | date: "dd-MM-yyyy" }}
                    {{ b?.bs_time }}</span
                  >
                </td>
                <td>
                  <span style="word-break: break-word">{{ b?.bs_level }}</span
                  ><span> mg/dl</span>
                </td>
                <td>
                  <span>{{ b?.checked_by }}</span>
                </td>
                <td>
                  <span>{{ b?.doctor }}</span>
                </td>
                <td>
                  <span style="word-break: break-word">{{
                    b?.insulin_req | titlecase
                  }}</span>
                </td>
                <td>
                  <span>{{ b?.insulin_type }}</span>
                </td>
                <td>
                  <span style="word-break: break-word">{{ b?.unit }}</span>
                </td>
                <td>
                  <span style="word-break: break-word">{{
                    b?.given_by_nurses
                  }}</span>
                </td>
              </tr>
              <tr>
                <td colspan="8" class="border-top-0 pt-0">
                  <strong>Comments: </strong>{{ b?.comments }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
