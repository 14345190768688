import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplace',
})
export class StringReplacementPipe implements PipeTransform {
  //   transform(value: string): string {
  //   return value ? value.replace(/_/g, ' ') : value;
  // }
  transform(
    value: string,
    strToReplace: string,
    replacementStr: string,
  ): string {
    if (!value || !strToReplace || !replacementStr) {
      return value;
    }

    return value.replace(new RegExp(strToReplace, 'g'), replacementStr);
  }
}
