import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SystemControlsService } from '../../../services/system-controls.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PdfServicesService } from '../../../services/pdf-services.service';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.css'],
})
export class PatientInfoComponent implements OnInit {
  @Output() patientsData = new EventEmitter<string>();
  @Output() patient = new EventEmitter<string>();
  @Output() summaryControl = new EventEmitter<string>();
  patientObject(): any {
    this.patientsData.emit(this.patientServiceInvoicesData);
  }
  // @Input() showContact: any;
  // @Input() patientServiceInvoicesData: any;
  showContact: any;
  selectedTemplete: any = '';
  patientMRN: any;
  patientLabId: any;
  patientOtReuqestId: any;
  patientOtReuqestIds: any;
  patientLabIds: any;
  visitId: any;
  invoiceID: any;
  patientRadID: any;
  patientRadIDs: any;
  invoicePatientId: any;
  patientID: any;
  patientServiceInvoicesData: any;
  patientServiceInvoicesDataSummary: any;
  summaryControls: any;
  birthDate: any;
  showPatientPictures = false;

  constructor(
    private systemControlService: SystemControlsService,
    private ngxLoader: NgxUiLoaderService,
    private pdfService: PdfServicesService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.invoiceID = params['invoice_id'] || '';
      this.patientRadID = params['rad_id'] || '';
      this.visitId = params['visit_id'] || '';
      this.patientMRN = params['mrn'] || '';
      this.patientLabId = params['lab_id'] || '';
      this.patientOtReuqestId = params['request_id'] || '';
    });
    this.getPrescription();
    this.getPatientInfo();
  }
  getPrescription(): any {
    this.ngxLoader.start();
    this.systemControlService.getPatientHeaderControlTemplateByUrl().subscribe(
      (data) => {
        this.showContact = data.report_header_config;
        this.summaryControls = data;
        if (this.summaryControls) {
          if (
            this.summaryControls?.show_patient_picture ||
            this.summaryControls?.show_patient_thumb_scan ||
            this.summaryControls?.show_qrcode ||
            this.summaryControls?.barcode_url ||
            this.summaryControls?.show_mrn
          ) {
            this.showPatientPictures = true;
          } else {
            this.showPatientPictures = false;
          }
        }
        this.ngxLoader.stop();
        if (data) {
          this.summaryControl.emit();
        }
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getPatientInfo(): any {
    this.ngxLoader.start();
    if (this.invoiceID) {
      this.invoicePatientId = this.invoiceID;
    } else {
      this.invoicePatientId = '';
    }
    if (this.patientLabId) {
      this.patientLabIds = this.patientLabId;
    } else {
      this.patientLabIds = '';
    }
    if (this.patientRadID) {
      this.patientRadIDs = this.patientRadID;
    } else {
      this.patientRadIDs = '';
    }
    if (this.patientOtReuqestId) {
      this.patientOtReuqestIds = this.patientOtReuqestId;
    } else {
      this.patientOtReuqestIds = '';
    }
    this.pdfService
      .getPatientData(
        this.patientRadIDs,
        this.invoicePatientId,
        this.visitId,
        this.patientMRN,
        this.patientLabIds,
        this.patientOtReuqestIds,
      )
      .subscribe(
        (data) => {
          this.patientServiceInvoicesData = data?.patient;
          this.birthDate = new Date(
            this.patientServiceInvoicesData.birth_year,
            this.patientServiceInvoicesData.birth_month - 1,
            this.patientServiceInvoicesData.birth_day,
          );
          this.patientObject();
          this.patientServiceInvoicesDataSummary = data;
          this.ngxLoader.stop();
          if (data?.patient) {
            this.patient.emit(data?.patient);
          }
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
}
