import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddEmployeeVisitComponent } from './add-employee-visit/add-employee-visit.component';
import { EmployeeVisitingListComponent } from './employee-visiting-list/employee-visiting-list.component';
import { AuthorizationGuard } from '../../../services/authorization.guard';

const routes: Routes = [
  { path: 'add', component: AddEmployeeVisitComponent },
  { path: 'update', component: AddEmployeeVisitComponent },
  {
    path: 'list',
    component: EmployeeVisitingListComponent,
    canActivate: [AuthorizationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VisitingEmployeeConfigurationRoutingModule {}
