import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { DashboardService } from '../../../services/dashboard.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';
import { RadiologyService } from '../../../services/radiology.service';
import { PdfServicesService } from '../../../services/pdf-services.service';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;
declare var Highcharts: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  today = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  dates = { fromDate: '', toDate: '' };

  radiologyDashboardStats: any;
  servicesList = [];
  rangeType = '1';
  settingData: any;
  screenName = '';

  constructor(
    private radiologyService: RadiologyService,
    private ngxLoader: NgxUiLoaderService,
    private pdfService: PdfServicesService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  ngOnInit(): void {
    $('.selectpicker').selectpicker();

    // const dates = moment().subtract(1, 'days').format('DD-MM-YYYY');
    this.dates.fromDate = this.today;
    this.dates.toDate = this.today;

    this.getRadiologyDashboardCount();
    this.getSettingsData();
  }

  radiologyfilters(value: any): any {
    this.dates.fromDate = '';
    this.dates.toDate = '';

    if (Number(value) === 1) {
      this.dates.fromDate = this.today;
      this.dates.toDate = this.today;
    } else if (value === 'all') {
      this.dates.fromDate = 'all';
      this.dates.toDate = 'all';
    } else if (value === 'dateRange') {
      $('#fromDate').datepicker({
        format: 'dd-mm-yyyy',
        orientation: 'bottom left',
      });
      $('#toDate').datepicker({
        format: 'dd-mm-yyyy',
        orientation: 'bottom left',
      });
      setTimeout(() => {
        const date = new Date();
        $('#fromDate').datepicker('setDate', date);
        $('#toDate').datepicker('setDate', date);
      }, 100);
      return;
    } else {
      const dates = moment()
        .subtract(Number(value), 'days')
        .format('DD-MM-YYYY');
      // console.log('From', dates);
      // console.log('To', this.today);
      this.dates.fromDate = dates;
      this.dates.toDate = this.today;
    }

    this.getRadiologyDashboardCount();
  }
  searchDateFilters(): any {
    let fromDate = $('#fromDate').val();
    let toDate = $('#toDate').val();
    console.log('toDate', toDate);
    if (fromDate === '' || toDate === '') {
      toastr.error('Select From date or End Date');
      return;
    }
    this.dates.fromDate = moment(fromDate, 'MM/DD/YYYY').format('DD-MM-YYYY');
    this.dates.toDate = moment(toDate, 'MM/DD/YYYY').format('DD-MM-YYYY');
    this.getRadiologyDashboardCount();
  }
  getRadiologyDashboardCount(): any {
    this.ngxLoader.start();
    this.radiologyService.getRadiologyDashboardData(this.dates).subscribe(
      (data) => {
        this.servicesList = data.services_count;
        this.radiologyDashboardStats = data.stats;
        this.getInvesPerformed(
          this.radiologyDashboardStats.top_10_investigations,
        );
        this.getTopRadiologyDept(
          this.radiologyDashboardStats.top_10_radiology_departments,
        );
        this.getDepartmentReferrals(
          this.radiologyDashboardStats.department_referrals,
        );
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.error);
        this.ngxLoader.stop();
      },
    );
  }

  // charts
  preventChangeDate(): any {
    return false;
  }
  getInvesPerformed(data): any {
    Highcharts.chart('RadInvesPerformed-Chart', {
      chart: {
        type: 'column',
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        labelStyle: {
          display: 'none',
        },
        selected: 0,
        inputEnabled: false,
        allButtonsEnabled: false,
        buttons: [],
      },
      navigator: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },
      xAxis: {
        categories: data[0].labels,
        crosshair: true,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: 'Top Investigation',
          type: 'column',
          color: '#5de85b8c',
          showInLegend: false,
          data: data[1].counts,
        },
      ],
    });
  }

  getTopRadiologyDept(data): any {
    Highcharts.chart('RadTopDepart-Chart', {
      chart: {
        type: 'column',
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        labelStyle: {
          display: 'none',
        },
        selected: 0,
        inputEnabled: false,
        allButtonsEnabled: false,
        buttons: [],
      },
      navigator: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },
      xAxis: {
        categories: data[0].labels,
        crosshair: true,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: 'Top Department',
          type: 'column',
          color: 'lightblue',
          showInLegend: false,
          data: data[1].counts,
        },
      ],
    });
  }

  getDepartmentReferrals(data): any {
    Highcharts.chart('RadTopDepartReferrals-Chart', {
      chart: {
        type: 'column',
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        labelStyle: {
          display: 'none',
        },
        selected: 0,
        inputEnabled: false,
        allButtonsEnabled: false,
        buttons: [],
      },
      navigator: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },
      xAxis: {
        categories: data[0].labels,
        crosshair: true,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: 'Top Referral Department',
          type: 'column',
          color: '#F15C80',
          showInLegend: false,
          data: data[1].counts,
        },
      ],
    });
  }
  printReport(): any {
    window.print();
  }
  getSettingsData(): any {
    this.pdfService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
}
