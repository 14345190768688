import { Injectable } from '@angular/core';
declare var toastr: any;

@Injectable({
  providedIn: 'root',
})
export class VitalsValidationService {
  vitalsData: any;
  returnVitalsArray = [];

  constructor() {}
  actionOnVitals(data): any {
    console.log(data);
    let count = 0;
    if (data?.weight) {
      if (!(Number(data.weight) >= 1 && Number(data.weight) <= 250)) {
        count++;
        toastr.error('weight Should be greater than 1 Kg and less then 250 Kg');
      }
      console.log(count);
    }
    if (data?.height) {
      if (!(Number(data.height) >= 38 && Number(data.height) <= 240)) {
        count++;
        toastr.error(
          'height Should be greater than 38 cm and less then 240 cm',
        );
      }
      console.log(count);
    }
    if (data?.temperature) {
      if (
        !(Number(data.temperature) >= 95 && Number(data.temperature) <= 107)
      ) {
        count++;
        toastr.error(
          'Temperature Should be greater than 95 F and less then 107 F',
        );
      }
      console.log(count);
    }
    if (data?.bp_systolic) {
      console.log(Number(data.bp_systolic));
      if (
        !(Number(data.bp_systolic) >= 50 && Number(data.bp_systolic) <= 300)
      ) {
        count++;
        toastr.error(
          'BP-Systolic Should be greater than 50 mm/hg and less then 300 mm/hg',
        );
      }
    }

    if (data?.bp_diastolic) {
      if (
        !(Number(data.bp_diastolic) >= 0 && Number(data.bp_diastolic) <= 150)
      ) {
        count++;
        toastr.error(
          'BP-Diastolic Should be greater than 0 mm/hg and less then 150 mm/hg',
        );
      }
      console.log(count);
    }
    if (data?.pulse) {
      if (!(Number(data.pulse) >= 40 && Number(data.pulse) <= 300)) {
        count++;
        toastr.error(
          'Pulse Should be greater than 40 min and less then 300 min',
        );
      }
      console.log(count);
    }
    if (data?.resp_rate) {
      if (!(Number(data.resp_rate) >= 12 && Number(data.resp_rate) <= 120)) {
        count++;
        toastr.error(
          ' Resp.Rate should be greater than 12 min and less then 120 min',
        );
      }
      console.log(count);
    }

    if (data?.o2_saturation) {
      if (
        !(Number(data.o2_saturation) >= 0 && Number(data.o2_saturation) <= 100)
      ) {
        count++;
        toastr.error('SPO2 should be greater than 0 % and less then 100 %');
      }
      console.log(count);
    }

    if (data?.bsr) {
      if (!(Number(data.bsr) >= 1 && Number(data.bsr) <= 9999)) {
        count++;
        toastr.error('BSR should be greater than 1  and less then 9999 ');
      }
      console.log(count);
    }

    return count <= 0;
  }
}
