<div class="d-flex flex-column flex-root">
  <!--begin::Page-->
  <div class="d-flex flex-row flex-column-fluid page">
    <div class="d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="d-flex flex-column-fluid main-content">
          <div class="container-fluid">
            <div class="card card-custom headerCard">
              <div class="card-header ribbon ribbon-top">
                <div
                  class="ribbon-target bg-danger"
                  style="top: -2px; right: 20px"
                >
                  {{ hospitalName }}
                </div>
                <div class="card-toolbar">
                  <ul class="nav nav-light-success nav-bold nav-pills">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#statsTab"
                      >
                        <span class="nav-icon"
                          ><i class="flaticon2-chat-1"></i
                        ></span>
                        <span class="nav-text">Stats</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#leavesTab">
                        <span class="nav-icon"
                          ><i class="flaticon2-drop"></i
                        ></span>
                        <span class="nav-text">Leaves</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#financialTab"
                      >
                        <span class="nav-icon"
                          ><i class="flaticon2-drop"></i
                        ></span>
                        <span class="nav-text">Financial</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="tab-content mt-5">
              <div
                class="tab-pane fade show active"
                id="statsTab"
                role="tabpanel"
                aria-labelledby="statsTab"
              >
                <div style="max-width: 200px">
                  <select
                    class="form-control form-control-sm selectpicker show-menu-arrow show-tick"
                    (change)="getDashboardCounts($event.target.value, 1)"
                  >
                    <option value="" disabled>Select</option>
                    <option value="1" selected>Today</option>
                    <option value="3">Last 3 Days</option>
                    <option value="7">Week</option>
                    <option value="30">Month</option>
                    <option value="365">1 Year</option>
                    <option value="all">Lifetime</option>
                  </select>
                </div>
                <div class="row mt-5">
                  <div class="col-lg-4">
                    <!--begin::List Widget 11-->
                    <div class="card card-custom card-stretch gutter-b">
                      <div class="card-body pt-5">
                        <div
                          class="d-flex align-items-center mb-5 bg-light-success rounded p-5"
                        >
                          <!--begin::Icon-->
                          <img
                            src="assets/media/hospital-icons/patients.png"
                            class="mr-5"
                            style="width: 30px"
                          />
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >Patients</a
                            >
                            <span class="text-muted font-weight-bold"
                              >{{ filterName }}:
                              {{
                                statsData?.stats?.patients || 0 | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Yesterday:
                              {{
                                statsData?.yesterday_stats?.patients || 0
                                  | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Last registered:
                              {{ statsData?.latest_stat?.last_patient }}</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <!--                          <span class="font-weight-bolder text-success py-1 font-size-h4">100</span>-->
                          <span
                            class="btn btn-light btn-sm"
                            (click)="getTransactionDetails('all')"
                            >View</span
                          >
                          <!--end::Lable-->
                        </div>
                        <div
                          class="d-flex align-items-center mb-5 bg-light-danger rounded p-5"
                        >
                          <!--begin::Icon-->
                          <img
                            src="assets/media/hospital-icons/emergency.png"
                            class="mr-5"
                            style="width: 30px"
                          />
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >Emergency Patients</a
                            >
                            <span class="text-muted font-weight-bold"
                              >{{ filterName }}:
                              {{
                                statsData?.stats?.emergency_count || 0 | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Yesterday:
                              {{
                                statsData?.yesterday_stats?.emergency_count || 0
                                  | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Last Visit:
                              {{
                                statsData?.latest_stat?.last_emr_patient
                              }}</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <!--                          <span class="font-weight-bolder text-danger py-1 font-size-h4">28</span>-->
                          <span
                            class="btn btn-light btn-sm"
                            (click)="getTransactionDetails('emr')"
                            >View</span
                          >
                          <!--end::Lable-->
                        </div>
                        <div
                          class="d-flex align-items-center mb-5 bg-light-info rounded p-5"
                        >
                          <!--begin::Icon-->
                          <img
                            src="assets/media/hospital-icons/opd.png"
                            class="mr-5"
                            style="width: 30px"
                          />
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >OPD Patients</a
                            >
                            <span class="text-muted font-weight-bold"
                              >{{ filterName }}:
                              {{
                                statsData?.stats?.opd_count || 0 | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Yesterday:
                              {{
                                statsData?.yesterday_stats?.opd_count || 0
                                  | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Last Visit:
                              {{
                                statsData?.latest_stat?.last_opd_patient
                              }}</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <!--                          <span class="font-weight-bolder text-info py-1 font-size-lg font-size-h4">50</span>-->
                          <span
                            class="btn btn-light btn-sm"
                            (click)="getTransactionDetails('opd')"
                            >View</span
                          >
                          <!--end::Lable-->
                        </div>
                        <div
                          class="d-flex align-items-center mb-5 bg-light-warning rounded p-5"
                        >
                          <!--begin::Icon-->
                          <img
                            src="assets/media/hospital-icons/admissions%20(2).png"
                            class="mr-5"
                            style="width: 30px"
                          />
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >Admissions</a
                            >
                            <span class="text-muted font-weight-bold"
                              >{{ filterName }}:
                              {{
                                statsData?.stats?.ipd_count || 0 | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Yesterday:
                              {{
                                statsData?.yesterday_stats?.ipd_count || 0
                                  | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Last Admitted:
                              {{
                                statsData?.latest_stat?.last_ipd_patient
                              }}</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <!--                          <span class="font-weight-bolder text-warning py-1 font-size-lg font-size-h4">27</span>-->
                          <span
                            class="btn btn-light btn-sm"
                            (click)="getTransactionDetails('ipd')"
                            >View</span
                          >
                          <!--end::Lable-->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <!--begin::List Widget 11-->
                    <div class="card card-custom card-stretch gutter-b">
                      <div class="card-body pt-5">
                        <div
                          class="d-flex align-items-center mb-5 bg-light-lab rounded p-5"
                        >
                          <!--begin::Icon-->
                          <img
                            src="assets/media/hospital-icons/icons-test-tube.png"
                            class="mr-5"
                            style="width: 30px"
                          />
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >Lab</a
                            >
                            <span class="text-muted font-weight-bold"
                              >{{ filterName }}:
                              {{
                                statsData?.stats?.lab_count || 0 | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Yesterday:
                              {{
                                statsData?.yesterday_stats?.lab_count || 0
                                  | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Last Request:
                              {{ statsData?.latest_stat?.last_lab }}</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <span
                            class="btn btn-light btn-sm"
                            (click)="getTransactionDetails('lab_service')"
                            >View</span
                          >
                          <!--                                                    <span class="font-weight-bolder text-warning py-1 font-size-lg font-size-h4">Detail</span>-->
                          <!--end::Lable-->
                        </div>
                        <div
                          class="d-flex align-items-center mb-5 bg-light-radiology rounded p-5"
                        >
                          <!--begin::Icon-->
                          <img
                            src="assets/media/hospital-icons/total-xray.png"
                            class="mr-5"
                            style="width: 30px"
                          />
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >Radiology</a
                            >
                            <span class="text-muted font-weight-bold"
                              >{{ filterName }}:
                              {{
                                statsData?.stats?.radiology_count || 0 | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Yesterday:
                              {{
                                statsData?.yesterday_stats?.radiology_count || 0
                                  | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Last Request:
                              {{ statsData?.latest_stat?.last_radiology }}</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <span
                            class="btn btn-light btn-sm"
                            (click)="getTransactionDetails('radiology')"
                            >View</span
                          >
                          <!--                          <span class="font-weight-bolder text-warning py-1 font-size-lg font-size-h4">27</span>-->
                          <!--end::Lable-->
                        </div>
                        <div
                          class="d-flex align-items-center mb-5 bg-light-surgery rounded p-5"
                        >
                          <!--begin::Icon-->
                          <img
                            src="assets/media/hospital-icons/icons-data-pending.png"
                            class="mr-5"
                            style="width: 30px"
                          />
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >Surgery</a
                            >
                            <span class="text-muted font-weight-bold"
                              >{{ filterName }}:
                              {{
                                statsData?.stats?.surgery_count || 0 | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Yesterday:
                              {{
                                statsData?.yesterday_stats?.surgery_count || 0
                                  | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Last Request:
                              {{ statsData?.latest_stat?.last_surgery }}</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <!--                          <span class="font-weight-bolder text-warning py-1 font-size-lg font-size-h4">27</span>-->
                          <span
                            class="btn btn-light btn-sm"
                            (click)="getTransactionDetails('surgeries')"
                            >View</span
                          >
                          <!--end::Lable-->
                        </div>
                        <div
                          class="d-flex align-items-center mb-5 bg-light-green rounded p-5"
                        >
                          <!--begin::Icon-->
                          <img
                            src="assets/media/hospital-icons/performed.png"
                            class="mr-5"
                            style="width: 30px"
                          />
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >Dialysis</a
                            >
                            <span class="text-muted font-weight-bold"
                              >{{ filterName }}:
                              {{
                                statsData?.stats?.dialysis_count || 0 | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Yesterday:
                              {{
                                statsData?.yesterday_stats?.dialysis_count || 0
                                  | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Last Request:
                              {{ statsData?.latest_stat?.last_dialysis }}</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <!--                          <span class="font-weight-bolder text-green py-1 font-size-lg font-size-h4">8</span>-->
                          <span
                            class="btn btn-light btn-sm"
                            (click)="getTransactionDetails('dialysis')"
                            >View</span
                          >
                          <!--end::Lable-->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <!--begin::List Widget 11-->
                    <div class="card card-custom card-stretch gutter-b">
                      <!--                      <div class="card-header border-0">-->
                      <!--                        <h3 class="card-title font-weight-bolder text-dark">Today Stats</h3>-->
                      <!--                      </div>-->
                      <div class="card-body pt-5">
                        <div
                          class="d-flex align-items-center mb-5 bg-light-warning rounded p-5"
                        >
                          <!--begin::Icon-->
                          <img
                            src="assets/media/hospital-icons/born.png"
                            class="mr-5"
                            style="width: 30px"
                          />
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >New Born</a
                            >
                            <span class="text-muted font-weight-bold"
                              >{{ filterName }}:
                              {{
                                statsData?.stats?.new_born_count || 0 | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Yesterday:
                              {{
                                statsData?.yesterday_stats?.new_born_count || 0
                                  | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Last Birth Request:
                              {{ statsData?.latest_stat?.last_new_born }}</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <!--                          <span class="font-weight-bolder text-warning py-1 font-size-h4">28</span>-->
                          <span
                            class="btn btn-light btn-sm"
                            (click)="getTransactionDetails('new_born')"
                            >View</span
                          >
                          <!--end::Lable-->
                        </div>
                        <div
                          class="d-flex align-items-center mb-5 bg-blue-green rounded p-5"
                        >
                          <img
                            src="assets/media/hospital-icons/death.png"
                            class="mr-5"
                            style="width: 30px"
                          />
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >Deaths</a
                            >
                            <span class="text-muted font-weight-bold"
                              >{{ filterName }}:
                              {{
                                statsData?.stats?.deceased || 0 | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Yesterday:
                              {{
                                statsData?.yesterday_stats?.deceased || 0
                                  | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Last Death Request:
                              {{ statsData?.latest_stat?.last_deceased }}</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <!--                          <span class="font-weight-bolder text-blue py-1 font-size-h4">100</span>-->
                          <span
                            class="btn btn-light btn-sm"
                            (click)="getTransactionDetails('deceased')"
                            >View</span
                          >
                          <!--end::Lable-->
                        </div>
                        <div
                          class="d-flex align-items-center mb-5 bg-light-success rounded p-5"
                        >
                          <!--begin::Icon-->
                          <img
                            src="assets/media/hospital-icons/covid.png"
                            class="mr-5"
                            style="width: 30px"
                          />
                          <!--end::Icon-->
                          <!--begin::Title-->
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >Covid-19 Positive</a
                            >
                            <span class="text-muted font-weight-bold"
                              >{{ filterName }}:
                              {{
                                statsData?.stats?.possitve_covid_reports || 0
                                  | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Yesterday:
                              {{
                                statsData?.yesterday_stats
                                  ?.possitve_covid_reports || 0 | number
                              }}</span
                            >
                            <span class="text-muted font-weight-bold"
                              >Last Positive Patient:
                              {{
                                statsData?.latest_stat?.last_covid_positive
                              }}</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <!--                          <span class="font-weight-bolder text-success py-1 font-size-lg font-size-h4">50</span>-->
                          <span
                            class="btn btn-light btn-sm"
                            (click)="getTransactionDetails('covid')"
                            >View</span
                          >
                          <!--end::Lable-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="leavesTab"
                role="tabpanel"
                aria-labelledby="leavesTab"
              >
                <div class="card card-custom card-stretch gutter-b">
                  <!--begin::Header-->
                  <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">
                      Leave Request
                    </h3>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div
                        class="col-sm-6 col-12"
                        *ngFor="let index of list; let i = index"
                      >
                        <div
                          class="d-flex align-items-center mb-5 bg-light-warning rounded p-5"
                        >
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                              >{{
                                index?.leave_request?.employee?.first_name
                                  | titlecase
                              }}
                              <span
                                *ngIf="
                                  index?.leave_request?.employee
                                    ?.middle_name !== null
                                "
                              >
                                {{
                                  index?.leave_request?.employee?.middle_name
                                    | titlecase
                                }}</span
                              >
                              {{
                                index?.leave_request?.employee?.last_name
                                  | titlecase
                              }}</a
                            >
                            <span class="text-muted font-weight-bold">{{
                              index?.leave_request?.employee?.designation?.name
                            }}</span>
                            <span
                              class="text-muted font-weight-bold"
                              *ngIf="
                                index?.leave_request?.full_half_day ===
                                'Full Day'
                              "
                              >{{ index?.leave_request?.start_date }}
                              <strong>to</strong>
                              {{ index?.leave_request?.end_date }} ({{
                                index?.leave_request?.number_of_days
                              }}
                              Days)</span
                            >
                            <span
                              class="text-muted font-weight-bold"
                              *ngIf="
                                index?.leave_request?.full_half_day ===
                                'Half Day'
                              "
                              >{{ index?.leave_request?.start_date }}
                              <strong>to</strong>
                              {{ index?.leave_request?.start_date }} ({{
                                index?.leave_request?.number_of_days
                              }}
                              Day)</span
                            >
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <div class="text-center">
                            <span
                              class="btn btn-primary py-1 cursor-pointer"
                              (click)="approveLeaveRequest(index, i, 1)"
                              >Approve</span
                            ><br />
                            <span
                              class="btn btn-danger py-1 cursor-pointer w-100"
                              style="margin-top: 7px"
                              (click)="approveLeaveRequest(index, i, 3)"
                              >Reject</span
                            >
                          </div>
                          <!--end::Lable-->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Body-->
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="financialTab"
                role="tabpanel"
                aria-labelledby="financialTab"
              >
                <div style="max-width: 200px">
                  <select
                    class="form-control form-control-sm selectpicker show-menu-arrow show-tick"
                    (change)="getDashboardCounts($event.target.value, 3)"
                  >
                    <option value="" disabled>Select</option>
                    <option value="1" selected>Today</option>
                    <option value="3">Last 3 Days</option>
                    <option value="7">Week</option>
                    <option value="30">Month</option>
                    <option value="365">1 Year</option>
                    <option value="all">Lifetime</option>
                  </select>
                </div>

                <div class="row mt-5">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div
                      class="card card-custom card-stretch gutter-b bgi-no-repeat"
                    >
                      <!--begin::Body-->
                      <div class="card-body">
                        <h5>Revenue Breakdown / Top Departments</h5>
                        <figure class="highcharts-figure">
                          <div id="TopDepart-Chart"></div>
                        </figure>
                      </div>
                      <!--end::Body-->
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div
                      class="card card-custom card-stretch gutter-b bgi-no-repeat"
                    >
                      <!--begin::Body-->
                      <div class="card-body">
                        <h5>Revenue Breakdown / Patient Type</h5>
                        <figure class="highcharts-figure">
                          <div id="patientType-Chart"></div>
                        </figure>
                      </div>
                      <!--end::Body-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
        <!--begin::Container-->
        <div
          class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between"
        >
          <!--begin::Copyright-->
          <div class="text-dark order-2 order-md-1">
            <!--            <span class="text-muted font-weight-bold mr-2">2020©</span>-->
            <a
              class="text-dark-75 text-hover-primary mr-5"
              [routerLink]="['/dashboard']"
              >Home</a
            >
            <a class="text-dark-75 text-hover-primary" (click)="logout()"
              >Logout</a
            >
          </div>
          <!--end::Copyright-->
          <!--begin::Nav-->
          <div class="nav nav-dark">
            <!--            <a href="http://keenthemes.com/metronic" target="_blank" class="nav-link pl-0 pr-5">About</a>-->
            <!--            <a href="http://keenthemes.com/metronic" target="_blank" class="nav-link pl-0 pr-5">Team</a>-->
            <!--            <a href="http://keenthemes.com/metronic" target="_blank" class="nav-link pl-0 pr-0">Contact</a>-->
          </div>
          <!--end::Nav-->
        </div>
        <!--end::Container-->
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="leaveStatusModal"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header" style="padding: 10px 20px">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ leaveStatus === 1 ? "Approve" : "Reject" }} Request
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-6 col-12">
            <label class="font-weight-bold">
              <span>Leave Type:</span>
              {{ employeeLeaveData?.leave_request?.leave_type?.type_name }}
            </label>
          </div>
          <div class="col-sm-6 col-12">
            <label class="font-weight-bold">
              <span>Leave Address:</span>
              {{ employeeLeaveData?.leave_request?.leave_address }}
            </label>
          </div>
          <div class="col-sm-6 col-12">
            <label class="font-weight-bold">
              <span>Leave Location:</span>
              {{ employeeLeaveData?.leave_request?.location }}
            </label>
          </div>
          <div class="col-sm-6 col-12">
            <label class="font-weight-bold">
              <span>Reason For Leave:</span>
              {{ employeeLeaveData?.leave_request?.reason_for_leave }}
            </label>
          </div>
          <div class="col-6" id="is_deduction">
            <div class="form-group">
              <label>Deduction</label>
              <select
                class="form-control form-control-sm"
                [(ngModel)]="isDeduction"
              >
                <option [ngValue]="0">Leave Bank</option>
                <option [ngValue]="1">Paid</option>
                <option [ngValue]="2">Unpaid</option>
              </select>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="">
              <label class="font-weight-bold">Remarks:</label>
              <textarea
                rows="2"
                class="form-control form-control-sm selectpicker"
                [(ngModel)]="remarks"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer" style="padding: 10px 20px">
        <button
          type="button"
          class="btn btn-light-primary btn-sm font-weight-bold"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm font-weight-bold float-left"
          *ngIf="this.leaveStatus === 1"
          (click)="updateLeaveStatus(3)"
        >
          Reject
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm font-weight-bold float-left"
          *ngIf="this.leaveStatus === 3"
          (click)="updateLeaveStatus(1)"
        >
          Approve
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm font-weight-bold"
          (click)="updateLeaveStatus(this.leaveStatus)"
        >
          {{ leaveStatus === 1 ? "Approve" : "Reject" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="patientDetailsStatus"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header" style="padding: 10px 20px">
        <h5 class="modal-title" id="">Details</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div style="overflow: auto !important">
          <table class="table">
            <thead>
              <tr>
                <th style="color: #000000">Date</th>
                <th style="color: #000000">Mrn</th>
                <th style="color: #000000">Patient Name</th>
                <th style="color: #000000">Patient Type</th>
                <th style="color: #000000">Net Amount</th>
                <th style="color: #000000">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sale of transactionDetail">
                <th style="color: #403838 !important">
                  {{ sale?.date | date: "yyyy-MM-dd" }}
                </th>
                <td>{{ sale?.mrn }}</td>
                <td>{{ sale?.full_name }}</td>
                <td>{{ sale.patient_type }}</td>
                <td>{{ sale.net_amount }}</td>
                <td>{{ sale.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination_main">
          <ngb-pagination
            [(page)]="page"
            (pageChange)="loadPage(page)"
            [pageSize]="perPage"
            [collectionSize]="totalPages"
            [maxSize]="5"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
          >
          </ngb-pagination>
          <div class="dropdown">
            <a
              class="btn dropdown-toggle btn-light btn-sm"
              role="button"
              id="pagination_btn"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="page_count">
                {{ perPage }}
              </span>
              <span class="count_count_icon"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="pagination_btn">
              <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                >25</a
              >
              <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                >50</a
              >
              <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                >75</a
              >
              <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                >100</a
              >
              <a class="dropdown-item" (click)="selectPaginationLimit('')"
                >All</a
              >
            </div>
          </div>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer" style="padding: 10px 20px">
        <button
          type="button"
          class="btn btn-light-primary btn-sm font-weight-bold"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
