<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            PCR Configuration
          </h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button
          type="button"
          class="btn btn-sm btn-light-primary"
          (click)="openPcrModal('')"
        >
          Add
        </button>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--begin::Subheader-->
  <div class="d-flex flex-column-fluid main-content">
    <div class="container-fluid">
      <div class="card mt-4">
        <div class="card-body">
          <div class="table-responsive position-relative trial-table">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th
                    colspan="2"
                    class="text-center"
                    style="color: #000000 !important"
                  >
                    Low
                  </th>
                  <th
                    colspan="2"
                    class="text-center"
                    style="color: #000000 !important"
                  >
                    Medium
                  </th>
                  <th
                    colspan="2"
                    class="text-center"
                    style="color: #000000 !important"
                  >
                    High
                  </th>
                  <th class="text-center" style="color: #000000 !important">
                    Action
                  </th>
                </tr>
                <tr>
                  <th style="width: 10%; color: #000000 !important">
                    Type / Code
                  </th>
                  <th style="width: 20%; color: #000000 !important">
                    Name / Short Name
                  </th>
                  <th style="width: 10%; color: #000000 !important">Range</th>
                  <th
                    style="
                      width: 10%;
                      background-color: #d2c1a9a8;
                      color: #000000 !important;
                    "
                    class="text-center"
                  >
                    From
                  </th>
                  <th
                    style="
                      width: 10%;
                      background-color: #d2c1a9a8;
                      color: #000000 !important;
                    "
                    class="text-center"
                  >
                    To
                  </th>
                  <th
                    style="
                      width: 10%;
                      background-color: #20771373;
                      color: #000000 !important;
                    "
                    class="text-center"
                  >
                    From
                  </th>
                  <th
                    style="
                      width: 10%;
                      background-color: #20771373;
                      color: #000000 !important;
                    "
                    class="text-center"
                  >
                    To
                  </th>
                  <th
                    style="
                      width: 10%;
                      background-color: #eb8c96c4;
                      color: #000000 !important;
                    "
                    class="text-center"
                  >
                    From
                  </th>
                  <th
                    style="
                      width: 10%;
                      background-color: #eb8c96c4;
                      color: #000000 !important;
                    "
                    class="text-center"
                  >
                    To
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of pcrList">
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder"
                      *ngIf="data?.type === 1"
                      >Organism</span
                    >
                    <span
                      class="text-dark-75 font-weight-bolder"
                      *ngIf="data?.type === 2"
                      >Detector</span
                    >
                    <span class="font-weight-bold text-muted d-block">{{
                      data?.code
                    }}</span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder">{{
                      data?.name
                    }}</span>
                    <span class="font-weight-bold text-muted d-block">{{
                      data?.short_name
                    }}</span>
                  </td>
                  <td>
                    {{ data?.range_from || "--" }}-{{ data?.range_to || "--" }}
                  </td>
                  <td class="text-center" style="background-color: #d2c1a9a8">
                    {{ data?.low_from || "--" }}
                  </td>
                  <td class="text-center" style="background-color: #d2c1a9a8">
                    {{ data?.low_to || "--" }}
                  </td>
                  <td class="text-center" style="background-color: #20771373">
                    {{ data?.medium_from || "--" }}
                  </td>
                  <td class="text-center" style="background-color: #20771373">
                    {{ data?.medium_to || "--" }}
                  </td>
                  <td class="text-center" style="background-color: #eb8c96c4">
                    {{ data?.high_from || "--" }}
                  </td>
                  <td class="text-center" style="background-color: #eb8c96c4">
                    {{ data?.high_to || "--" }}
                  </td>
                  <td class="text-right">
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                      (click)="openPcrModal(data?.id)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                            ></path>
                            <path
                              d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            ></path>
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!--end::Table-->
          <!--pagination-->
          <div class="pagination_main">
            <ngb-pagination
              [(page)]="page"
              (pageChange)="loadPage(page)"
              [pageSize]="perPage"
              [collectionSize]="totalPages"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="false"
              [boundaryLinks]="true"
            ></ngb-pagination>
            <div class="dropdown">
              <a
                class="btn dropdown-toggle btn-light btn-sm"
                href="#"
                role="button"
                id="pagination_btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="page_count">
                  {{ perPage }}
                </span>
                <span class="count_count_icon"></span>
              </a>
              <div class="dropdown-menu" aria-labelledby="pagination_btn">
                <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                  >25</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                  >50</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                  >75</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                  >100</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--begin::Content-->

<!--PCR Modal-->
<div
  class="modal fade"
  id="pcrModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="!pcrId">Add</h5>
        <h5 class="modal-title" *ngIf="pcrId">Update</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="pcrForm" (ngSubmit)="submitPcr()">
        <div class="modal-body">
          <div class="row">
            <!--          TYPE-->
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div class="form-group">
                <label>Type <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm"
                  (change)="refreshAntiBiotic($event.target.value)"
                  formControlName="type"
                  [ngClass]="{
                    'is-invalid': isSubmit && pcrForm.controls.type.errors
                  }"
                >
                  <option value="" selected disabled>Select</option>
                  <option [ngValue]="1">Organism</option>
                  <option [ngValue]="2">Detector</option>
                </select>
              </div>
            </div>
            <!--          CODE-->
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div class="form-group">
                <label>Code <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Code"
                  formControlName="code"
                  (keypress)="notAllowSpace($event)"
                  [ngClass]="{
                    'is-invalid': isSubmit && pcrForm.controls.code.errors
                  }"
                />
              </div>
            </div>
            <!--          SHORT NAME-->
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div class="form-group">
                <label>Short Name <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Short Name"
                  formControlName="short_name"
                  [ngClass]="{
                    'is-invalid': isSubmit && pcrForm.controls.short_name.errors
                  }"
                />
              </div>
            </div>
            <!--  NAME-->
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div class="form-group">
                <label>Name <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Name"
                  formControlName="name"
                  [ngClass]="{
                    'is-invalid': isSubmit && pcrForm.controls.name.errors
                  }"
                />
              </div>
            </div>
            <!--  Antibiotic-->
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="pcrForm.get('type').value === 2"
            >
              <div class="form-group">
                <label>Anti Biotic <span class="text-danger">*</span></label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      isSubmit && pcrForm.controls.anti_biotic.errors
                  }"
                >
                  <select
                    class="form-control form-control-sm"
                    formControlName="anti_biotic"
                    id="anti_biotic"
                    multiple
                    data-live-search="true"
                    data-container="body"
                    data-none-selected-text="Select"
                  >
                    <option value="" disabled selected>Select</option>
                    <ng-container *ngFor="let biotic of cultureList">
                      <ng-container
                        *ngIf="biotic?.culture_type === 'sensitivity_group'"
                      >
                        <option [value]="biotic?.id">{{ biotic?.name }}</option>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <!-- Status-->
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-4"
              style="margin-top: 19px"
            >
              <div class="form-group">
                <div class="d-flex align-items-center justify-content-center">
                  <label>Status </label>
                  <span class="switch switch-icon ml-5">
                    <label>
                      <input
                        type="checkbox"
                        name="select"
                        formControlName="status"
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h5>Wound</h5>
          </div>
          <div class="row">
            <!--  Wound Range-->
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div class="form-group">
                <label>Range</label>
                <div class="d-flex input-container">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="From"
                    formControlName="wound_range_from"
                  />
                  <!--                    [ngClass]="{-->
                  <!--                      'is-invalid':-->
                  <!--                        isSubmit && pcrForm.controls.range_from.errors-->
                  <!--                    }"-->
                  <!--                  />-->
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="To"
                    formControlName="wound_range_to"
                  />
                  <!--                    [ngClass]="{-->
                  <!--                      'is-invalid': isSubmit && pcrForm.controls.range_to.errors-->
                  <!--                    }"-->
                  <!--                  />-->
                </div>
              </div>
            </div>
            <!--      Wound Comments-->
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
              <div class="form-group">
                <label>Comments</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Comments"
                  formControlName="wound_comments"
                />
                <!--                  [ngClass]="{-->
                <!--                    'is-invalid': isSubmit && pcrForm.controls.comments.errors-->
                <!--                  }"-->
                <!--                />-->
              </div>
            </div>
          </div>
          <div>
            <h5>UTI</h5>
          </div>
          <div class="row">
            <!--  Range-->
            <div
              [ngClass]="{
                'col-xl-3 col-lg-3 col-md-3 col-sm-6':
                  pcrForm.get('type').value === 1,
                'col-xl-4 col-lg-4 col-md-4 col-sm-6':
                  pcrForm.get('type').value !== 1
              }"
            >
              <!--              *ngIf="pcrForm.get('type').value === 1"-->
              <div class="form-group">
                <label>Range</label>
                <div class="d-flex input-container">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="From"
                    formControlName="range_from"
                  />
                  <!--                    [ngClass]="{-->
                  <!--                      'is-invalid':-->
                  <!--                        isSubmit && pcrForm.controls.range_from.errors-->
                  <!--                    }"-->
                  <!--                  />-->
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="To"
                    formControlName="range_to"
                  />
                  <!--                    [ngClass]="{-->
                  <!--                      'is-invalid': isSubmit && pcrForm.controls.range_to.errors-->
                  <!--                    }"-->
                  <!--                  />-->
                </div>
              </div>
            </div>
            <!-- LOW-->
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="pcrForm.get('type').value === 1"
            >
              <div class="form-group">
                <label>Low</label>
                <div class="d-flex input-container">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="From"
                    formControlName="low_from"
                  />
                  <!--                                      [ngClass]="{-->
                  <!--                                        'is-invalid': isSubmit && pcrForm.controls.low_from.errors-->
                  <!--                                      }"-->
                  <!--                                    />-->
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="To"
                    formControlName="low_to"
                  />
                  <!--                                      [ngClass]="{-->
                  <!--                                        'is-invalid': isSubmit && pcrForm.controls.low_to.errors-->
                  <!--                                      }"-->
                  <!--                                    />-->
                </div>
              </div>
            </div>
            <!--Medium-->
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="pcrForm.get('type').value === 1"
            >
              <div class="form-group">
                <label>Medium</label>
                <div class="d-flex input-container">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="From"
                    formControlName="medium_from"
                  />
                  <!--                                      [ngClass]="{-->
                  <!--                                        'is-invalid':-->
                  <!--                                          isSubmit && pcrForm.controls.medium_from.errors-->
                  <!--                                      }"-->
                  <!--                                    />-->
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="To"
                    formControlName="medium_to"
                  />
                  <!--                                      [ngClass]="{-->
                  <!--                                        'is-invalid':-->
                  <!--                                          isSubmit && pcrForm.controls.medium_to.errors-->
                  <!--                                      }"-->
                  <!--                                    />-->
                </div>
              </div>
            </div>
            <!--High-->
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="pcrForm.get('type').value === 1"
            >
              <div class="form-group">
                <label>High</label>
                <div class="d-flex input-container">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="From"
                    formControlName="high_from"
                  />
                  <!--                                      [ngClass]="{-->
                  <!--                                        'is-invalid':-->
                  <!--                                          isSubmit && pcrForm.controls.high_from.errors-->
                  <!--                                      }"-->
                  <!--                                    />-->
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="To"
                    formControlName="high_to"
                  />
                  <!--                                      [ngClass]="{-->
                  <!--                                        'is-invalid': isSubmit && pcrForm.controls.high_to.errors-->
                  <!--                                      }"-->
                  <!--                                    />-->
                </div>
              </div>
            </div>
            <!--          Comments-->
            <div
              [ngClass]="{
                'col-xl-12 col-lg-12 col-md-12 col-sm-12':
                  pcrForm.get('type').value === 1,
                'col-xl-8 col-lg-8 col-md-8 col-sm-8':
                  pcrForm.get('type').value !== 1
              }"
            >
              <div class="form-group">
                <label>Comments</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Comments"
                  formControlName="comments"
                />
                <!--                  [ngClass]="{-->
                <!--                    'is-invalid': isSubmit && pcrForm.controls.comments.errors-->
                <!--                  }"-->
                <!--                />-->
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-secondary mr-2"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            [disabled]="disableBtn"
            *ngIf="!pcrId"
          >
            Submit
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            [disabled]="disableBtn"
            *ngIf="pcrId"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
