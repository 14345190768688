<div class="card">
  <div class="card-body">
    <form [formGroup]="zakatForm" (ngSubmit)="submitZakat()">
      <div class="" aria-expanded="true">
        <table class="table table-vertical-center">
          <thead>
            <tr>
              <th style="width: 80%" class="font-weight-bold">Question</th>
              <th style="width: 20%; text-align: right">Marks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of questionnaires; let i = index">
              <td style="width: 80%">
                <label>{{ data?.question }}</label>
                <select
                  class="form-control form-control-sm"
                  (change)="updateOption(i, $event.target.value)"
                >
                  <option value="">Select</option>
                  <ng-container *ngFor="let opt of data.option_score_details">
                    <option [value]="opt?.options">{{ opt?.options }}</option>
                  </ng-container>
                </select>
              </td>
              <td style="width: 20%; text-align: right">
                {{ data?.score || 0 }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style="text-align: right">
                {{ totalScore || 0 | number: "1.0-2" }} / 100
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!--    OT Services Card -->
      <div class="row mt-6">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
          <div class="form-group">
            <label>Procedure <span class="text-danger">*</span></label>
            <div
              [ngClass]="{
                'is-invalid': isSubmit && zakatForm.controls.service_id.errors
              }"
            >
              <select
                class="form-control form-control-sm"
                id="service"
                data-container="body"
                data-live-search="true"
                formControlName="service_id"
                (change)="fetchServicePrice($event.target.value)"
              >
                <option value="" [disabled]="true">Select</option>
                <ng-container *ngFor="let service of allServices">
                  <option [value]="service?.id">{{ service?.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
          <div class="form-group">
            <label>Estimated Procedure Cost</label>
            <input
              type="number"
              disabled
              class="form-control form-control-sm"
              placeholder="0"
              formControlName="estimated_cost"
            />
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
          <div class="form-group">
            <label>Organization Share</label>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="0"
              formControlName="org_share"
              (keypress)="_onlyNumeric($event); noDecimalPoint($event)"
              (keyup)="updateShares('org_share')"
            />
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
          <div class="form-group">
            <label>Patient Share</label>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="0"
              formControlName="patient_share"
              (keypress)="_onlyNumeric($event); noDecimalPoint($event)"
              (keyup)="updateShares('patient_share')"
            />
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
          <div class="form-group">
            <label>Donor</label>
            <div
            >
              <select
                class="form-control form-control-sm"
                data-container="body"
                data-live-search="true"
                formControlName="donated_by"
              >
                <option value="" [disabled]="true">Select</option>
                <ng-container *ngFor="let pat of patients">
                  <option [value]="pat?.id">{{ pat?.first_name + ' ' + pat?.last_name}}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>


        <div class="col-12 text-right">
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            [disabled]="isDisabledSubmit"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!--Listing Card-->
<div class="card mt-4" *ngIf="zakatListing.length">
  <div class="card-body">
    <div class="table-responsive position-relative">
      <table
        class="table table-head-custom table-vertical-center"
        id="kt_advance_table_widget_1"
      >
        <thead>
          <tr class="text-left">
            <th class="pr-0" style="width: 20%">Procedure Name</th>
            <th class="pr-0" style="width: 15%; text-align: center">
              Procedure Cost
            </th>
            <th style="width: 15%; text-align: center">Organization Share</th>
            <th style="width: 20%; text-align: center">Patient Share</th>
            <th style="width: 15%; text-align: center">Donated by</th>
            <th style="width: 15%; text-align: center">Total Marks</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of zakatListing; let i = index">
            <tr>
              <td>{{ data?.service_name || "--" }}</td>
              <td class="text-center">{{ data?.estimated_cost || 0 }}</td>
              <td class="text-center">{{ data?.org_share || 0 }}</td>
              <td class="text-center">{{ data?.patient_share || 0 }}</td>
              <td class="text-center">{{ data?.donated_by}}</td>
              <td class="text-center">{{ data?.total_score || 0 }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <!--end::Table-->
    <!--    &lt;!&ndash;pagination&ndash;&gt;-->
    <!--    <div class="pagination_main d-print-none">-->
    <!--      <ngb-pagination-->
    <!--        [(page)]="page"-->
    <!--        (pageChange)="loadPage(page)"-->
    <!--        [pageSize]="perPage"-->
    <!--        [collectionSize]="totalPages"-->
    <!--        [maxSize]="5"-->
    <!--        [rotate]="true"-->
    <!--        [ellipses]="false"-->
    <!--        [boundaryLinks]="true"-->
    <!--      ></ngb-pagination>-->
    <!--      <div class="dropdown">-->
    <!--        <a-->
    <!--          class="btn dropdown-toggle btn-light btn-sm"-->
    <!--          role="button"-->
    <!--          id="pagination_btn"-->
    <!--          data-toggle="dropdown"-->
    <!--          aria-haspopup="true"-->
    <!--          aria-expanded="false"-->
    <!--        >-->
    <!--                <span class="page_count">-->
    <!--                  {{ perPageValue }}-->
    <!--                </span>-->
    <!--          <span class="count_count_icon"></span>-->
    <!--        </a>-->
    <!--        <div class="dropdown-menu" aria-labelledby="pagination_btn">-->
    <!--          <a class="dropdown-item" (click)="selectPaginationLimit(25)"-->
    <!--          >25</a-->
    <!--          >-->
    <!--          <a class="dropdown-item" (click)="selectPaginationLimit(50)"-->
    <!--          >50</a-->
    <!--          >-->
    <!--          <a class="dropdown-item" (click)="selectPaginationLimit(75)"-->
    <!--          >75</a-->
    <!--          >-->
    <!--          <a class="dropdown-item" (click)="selectPaginationLimit(100)"-->
    <!--          >100</a-->
    <!--          >-->
    <!--          <a class="dropdown-item" (click)="selectPaginationLimit('')"-->
    <!--          >All</a-->
    <!--          >-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>
