import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class VaccinationService {
  constructor(private http: HttpClient) {}

  getVaccinationType(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/vaccination_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getVaccinationTypeV2(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/ipd/vaccination_types/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getHistory(patient): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/patient_vaccinations?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getHistoryV2(patient, visitType): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/ipd/patient_vaccinations/` +
          patient.id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          patient.visit_id +
          '&visit_type=' +
          visitType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  add(dose, vaccination_type_id, observation, patient): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/patient_vaccinations',
        {
          auth_token: localStorage.getItem('auth_token'),
          visit_id: patient.visit_id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          dose: dose,
          vaccination_type_id: vaccination_type_id,
          observation: observation,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addV2(dose, vaccination_type_id, observation, patient): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/ipd/patient_vaccinations/` +
          patient.id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          visit: patient.visit_id,
          medical_unit: localStorage.getItem('current_medical_unit_id'),
          dose: dose,
          vaccination_type: vaccination_type_id,
          observation: observation,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
