import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BloodBankService } from '../../../../services/blood-bank.service';
import { EmployeeRegistrationService } from '../../../../services/employee-registration.service';
import { HospitalService } from '../../../../services/hospital.service';
import { OpdService } from '../../../../services/opd.service';
import { environment } from '../../../../../environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment/moment';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-blood-sugar',
  templateUrl: './blood-sugar.component.html',
  styleUrls: ['./blood-sugar.component.css'],
})
export class BloodSugarComponent implements OnInit {
  @Input() patient: any;
  @Input() visitType: any;
  @Input() isAdd: boolean;

  employeeSearch = {
    search_query: '',
    type: 'default',
    user: 'employee',
  };
  ngbModalStatic: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  bloodSugarForm: UntypedFormGroup;
  bloodSugarData: UntypedFormGroup;

  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  currentTimeData = formatDate(new Date(), 'hh:mm a', 'en');
  isSubmitBloodRequest = false;
  bloodData: any;
  yesterday = new Date();
  previousDateTime = this.yesterday.setDate(this.yesterday.getDate() - 1);
  allEmployees = [];
  insulinList: any;
  settingData: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private bloodSugar: BloodBankService,
    private employeeService: EmployeeRegistrationService,
    private hospitalService: HospitalService,
  ) {}

  ngOnInit(): void {
    const currentTime = moment().format('HH:mm');
    $('#currentTime').val(currentTime);
    this.currentTimeData = currentTime;

    this.bloodSugarForm = this.formBuilder.group({
      bsl_date: [this.today, Validators.required],
      bs_time: ['', Validators.required],
      bs_level: [0, Validators.required],
      checked_by: ['', Validators.required],
      insulin_req: ['no'],
      insulin_type: ['', Validators.required],
      doctor: ['', Validators.required],
      given_by_nurse: ['', Validators.required],
      unit: [0, Validators.required],
      comments: [''],
    });
    this.bloodSugarData = this.formBuilder.group({
      from_date: [this.today],
      to_date: [this.today],
    });
    this.bloodSugarForm.get('bs_time').setValue(this.currentTimeData);

    this.searchEmployee();
    this.configType('insulin_type');
    this.getBloodRequest('');
    this.getSettingsData();
  }

  ngOnChanges(changes: SimpleChanges): any {
    this.getBloodRequest(changes.patient.currentValue);
  }

  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetailsv2().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {},
    );
  }

  onBloodRequest(): any {
    if (this.bloodSugarForm.get('insulin_req').value === 'yes') {
      this.bloodSugarForm
        .get('given_by_nurse')
        .setValidators([Validators.required]);
      this.bloodSugarForm.get('given_by_nurse').updateValueAndValidity();
    } else {
      this.bloodSugarForm.get('given_by_nurse').clearValidators();
      this.bloodSugarForm.get('given_by_nurse').updateValueAndValidity();
    }
    if (this.bloodSugarForm.get('insulin_req').value === 'yes') {
      this.bloodSugarForm
        .get('insulin_type')
        .setValidators([Validators.required]);
      this.bloodSugarForm.get('insulin_type').updateValueAndValidity();
    } else {
      this.bloodSugarForm.get('insulin_type').clearValidators();
      this.bloodSugarForm.get('insulin_type').updateValueAndValidity();
    }
    this.isSubmitBloodRequest = true;
    if (this.bloodSugarForm.invalid) {
      return;
    }

    this.ngxLoader.start();
    this.bloodSugar
      .addBloodSugar(
        this.bloodSugarForm.value,
        this.patient.id,
        this.patient.visit_id,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.resetBloodSugarForm();
          this.getBloodRequest('');
          toastr.success('Blood Sugar Level Added');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  resetBloodSugarForm(): any {
    this.bloodSugarForm.reset({
      bsl_date: this.today,
      bs_time: '',
      bs_level: 0,
      checked_by: '',
      insulin_req: 'no',
      insulin_type: '',
      doctor: '',
      given_by_nurse: '',
      unit: 0,
      comments: '',
    });
    $('#givenByPicker').selectpicker('refresh');
    $('#givenByPicker').selectpicker();
    $('#checkedByPicker').selectpicker('refresh');
    $('#checkedByPicker').selectpicker();
    $('#docPicker').selectpicker('refresh');
    $('#docPicker').selectpicker();
    this.isSubmitBloodRequest = false;
    this.bloodSugarForm.get('bs_time').setValue(this.currentTimeData);
  }

  getBloodRequest(val): any {
    this.ngxLoader.start();
    this.bloodData = [];
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.bloodSugar
      .fetchBloodSugar(
        this.patient.id,
        this.bloodSugarData.value,
        this.visitType,
      )
      .subscribe(
        (data) => {
          this.bloodData = data.bsl_data;
          this.resetValues();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  resetValues(): any {
    this.bloodSugarForm.patchValue({
      bsl_date: this.today,
      bs_time: this.currentTimeData,
    });
  }

  isNumberKey(e): any {
    const charCode = e.which ? e.which : e.keyCode;
    if (e.target.value.includes('.')) {
      if (e.key === '.') {
        return false;
      }
    }
    if (
      charCode !== 190 &&
      charCode !== 96 &&
      charCode !== 97 &&
      charCode !== 98 &&
      charCode !== 99 &&
      charCode !== 100 &&
      charCode !== 101 &&
      charCode !== 102 &&
      charCode !== 103 &&
      charCode !== 104 &&
      charCode !== 105 &&
      charCode !== 46 &&
      charCode !== 110 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    ) {
      return false;
    }
    return true;
  }

  searchEmployee(): any {
    this.ngxLoader.start();
    this.employeeService.employeeListName(this.employeeSearch).subscribe(
      (data) => {
        this.allEmployees = data;
        setTimeout(() => {
          $('#checkedByPicker').selectpicker('refresh');
          $('#checkedByPicker').selectpicker();
          $('#docPicker').selectpicker('refresh');
          $('#docPicker').selectpicker();
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  configType(type): any {
    this.ngxLoader.start();
    this.employeeService.fetchConfigurationV2(type).subscribe(
      (data) => {
        this.insulinList = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  printCurrentScreen(): any {
    window.open(
      'pdf-reports/blood-sugar-report?id=' +
        this.patient.id +
        '&visit_id=' +
        this.patient.visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&invoice_id=' +
        '&from_date=' +
        this.bloodSugarData.value.from_date +
        '&to_date=' +
        this.bloodSugarData.value.to_date,
    );
    // window.print();
  }
  InsulineChanged(InsulineReqiured): any {
    if (InsulineReqiured === 'yes') {
      setTimeout(() => {
        $('#givenByPicker').selectpicker('refresh');
        $('#givenByPicker').selectpicker();
      }, 500);
    }
  }
}
