import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientService } from '../../../services/patient.service';
import { HospitalService } from '../../../services/hospital.service';
import { WardService } from '../../../services/ward.service';
import { PatientAdmissionService } from '../../../services/patient-admission.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { UserService } from '../../../services/user.service';
import { ServiceService } from '../../../services/service.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-ipd-v2',
  templateUrl: './ipd-v2.component.html',
  styleUrls: ['./ipd-v2.component.css'],
})
export class IpdV2Component implements OnInit {
  currentTime = new Date();
  wards = [];
  activeTab: any;
  isVitalsShow = false;
  isConsultationShow = false;
  isMedicinesShow = false;
  isDiagnosisShow = false;
  isServiceShow = false;
  isVaccinationShow = false;
  isBirthCertificateShow = false;
  isNotesShow = false;
  isOperationRequestShow = false;
  isNursingShow = false;
  isGlascoShow = false;
  isInOutTakeShow = false;
  ismyDocsShow = false;
  isbloodSugarShow = false;
  isDischargeShow = false;
  isReferralShow = false;
  isAnesthesiaShow = false;
  isMainShow = true;
  isHistoryTakingShow = false;
  screenControls = [];
  vitalsControl = false;
  consultationControl = false;
  diagnosisControl = false;
  medicinesControl = false;
  servicesControl = false;
  referralControl = false;
  vaccinationControl = false;
  nursingProcedureControl = false;
  birthCertificateControl = false;
  dischargeControl = false;
  notesControl = false;
  consentFormControl = false;
  operationRequestControl = false;
  inTakeOutPutControl = false;
  glasgowComaControl = false;
  documentsControl = false;
  bloodSugarControl = false;
  historyControl = false;
  mainControl = false;
  editMoNursingNotesControl = false;
  amountData: any;
  showMsg: any;
  selectedMrn: any;
  otRequestedId: any;
  get width(): any {
    return window.innerWidth;
  }

  @ViewChild('patientChangeConform') patientChangeConform: ElementRef;
  @ViewChild('changeBedModal') changeBedModal: ElementRef;

  ngbModalStaticLarge: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg',
  };

  PatientRegistrationForm: UntypedFormGroup;
  PatientSearchForm: UntypedFormGroup;
  patient: any;
  vitalWeight: any;
  patientTemp = [];
  patients = [];
  pricing = [''];
  current_environment: string;
  patientSearchWard = '';
  wardsList = [];

  freeWardList = [];
  freeBedsList = [];
  freeWardId = '';
  freeBedId = '';
  changeWardForm: UntypedFormGroup;

  showDoctorList = false;
  doctor_id: number;

  patientWardHistory = [];
  isBedAllocate = false;
  bedAllocateId = '';
  selectedPatientIndex = 0;
  doctor = [];
  doctors = [];
  doctorsAll = [];
  refdoctor = [];
  isVitalsUpdate = 0;
  isServicesUpdate = 0;
  isMainServicesUpdate = 0;
  isNotesUpdate = 0;
  isDiagnosisUpdate = 0;
  isMainVitalsUpdate = 0;
  isMainDiagnosisUpdate = 0;
  isConsultationUpdate = 0;
  isVitalsRecieved: number;
  isMainVitalsRecieved: number;
  isNotesRecieved: number;
  isDiagnosisRecieved: number;
  isMainDiagnosisRecieved: number;
  isMainConsultationRecieved: number;
  isMainServicesRecieved: number;
  isServicesRecieved: number;
  PatientListRefresh: any;
  resetMainData: any;
  patientMRN: any;
  isBedUpdateDisabled = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private hospitalDoctorsService: HospitaldoctorService,
    private patientService: PatientService,
    private hospitalService: HospitalService,
    private chRef: ChangeDetectorRef,
    private wardService: WardService,
    private patientAdmissionService: PatientAdmissionService,
    private serviceService: ServiceService,
    private modalService: NgbModal,
    private router: Router,
    private userService: UserService,
  ) {}
  @Output() bedModal: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): any {
    this.route.queryParams.subscribe((params) => {
      this.patientMRN = +params['mrn'];
    });
    this.selectedMrn = this.route.snapshot.queryParamMap.get('mrn');
    this.otRequestedId =
      this.route.snapshot.queryParamMap.get('ot_requested_id');

    this.ngxLoader.stop();

    $.getScript('../../assets/js/pages/custom/profile/profile.js');

    this.PatientSearchForm = this.formBuilder.group({
      search_query: [''],
      type: ['default'],
    });
    if (this.patientMRN) {
      this.PatientSearchForm.get('search_query').setValue(this.patientMRN);
      this.PatientSearchForm.get('type').setValue('mrn');
    }

    this.changeWardForm = this.formBuilder.group({
      wardId: [''],
      bedId: [''],
      condition: [''],
      comments: [''],
      informing_dr: [''],
      via: [''],
      response: [''],
    });

    this.current_environment = environment.rorUrl;

    this.searchPatient();
    this.getUserAssignWards();
    this.fetchDoctor();
    this.getScreenControls();
  }

  fetchDoctor(): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchOpdConsultationDoctorV2().subscribe(
      (data) => {
        this.doctor = data.users;
        this.doctors = data.users;
        this.doctorsAll = data.users;
        this.refdoctor = data.users;
        // this.consultationForm.patchValue({
        //   doctor_id: '',
        //   department_id: ''
        // });
        this.ngxLoader.stop();
        setTimeout(() => {
          $('.doctorSelectpicker').selectpicker();

          $('#docPicker').selectpicker('refresh');
          $('#docPicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  PatientsDataChenges($event: any): any {
    this.isVitalsRecieved = $event;
  }
  PatientsVitalsChenges($event: any): any {
    this.isMainVitalsRecieved = $event;
  }
  PatientsNotesChenges($event: any): any {
    this.isNotesRecieved = $event;
  }
  PatientsDiagnosisChenges($event: any): any {
    this.isDiagnosisRecieved = $event;
  }
  PatientsMainDiagnosisChenges($event: any): any {
    this.isMainDiagnosisRecieved = $event;
  }
  PatientsConsultationChenges($event: any): any {
    this.isMainConsultationRecieved = $event;
  }
  PatientsServicesChenges($event: any): any {
    this.isServicesRecieved = $event;
  }
  PatientsMainServicesChenges($event: any): any {
    this.isMainServicesRecieved = $event;
  }
  PatientRefresh($event: any): any {
    this.PatientListRefresh = $event;
    if (this.PatientListRefresh) {
      this.patient = null;
      this.fetchPatients();
      setTimeout(() => {
        $('#MainTabs').click();
        this.resetMainData = this.PatientListRefresh;
        window.scroll(0, 0);
      }, 1000);
    }
  }
  resetPatientDisId($event: any): any {
    this.patient.admissions[0].discharge_patient_id = $event;
    this.patient.discharge_patient_id = $event;
    this.patients[this.selectedPatientIndex].discharge_patient_id = $event;
    this.patients[
      this.selectedPatientIndex
    ].admissions[0].discharge_patient_id = $event;
  }

  toggleActive(tab): any {
    this.activeTab = tab;
    $('.navi .navi-item .navi-link').removeClass('active');
    window.scroll(0, 0);
    this.updateData();
  }
  updateData(): any {
    if (this.activeTab === 'Vitals') {
      this.isVitalsShow = true;
      this.isVitalsUpdate = this.isMainVitalsRecieved;
    }
    if (this.activeTab === 'Consultation') {
      this.isConsultationShow = true;
      this.isConsultationUpdate = this.isMainConsultationRecieved;
    }
    if (this.activeTab === 'Medicines') {
      this.isMedicinesShow = true;
    }
    if (this.activeTab === 'Diagnosis') {
      this.isDiagnosisShow = true;
      this.isDiagnosisUpdate = this.isMainDiagnosisRecieved;
    }
    if (this.activeTab === 'Services') {
      this.isServiceShow = true;
      this.isServicesUpdate = this.isMainServicesRecieved;
    }
    if (this.activeTab === 'Vaccination') {
      this.isVaccinationShow = true;
    }
    if (this.activeTab === 'BirthCertificate') {
      this.isBirthCertificateShow = true;
    }
    if (this.activeTab === 'Notes') {
      this.isNotesShow = true;
      this.isNotesUpdate = this.isNotesRecieved;
    }
    if (this.activeTab === 'Nursing') {
      this.isNursingShow = true;
    }
    if (this.activeTab === 'OperationRequest') {
      this.isOperationRequestShow = true;
    }
    if (this.activeTab === 'Glasco') {
      this.isGlascoShow = true;
    }
    if (this.activeTab === 'InOutTake') {
      this.isInOutTakeShow = true;
    }
    if (this.activeTab === 'myDocs') {
      this.ismyDocsShow = true;
    }
    if (this.activeTab === 'bloodSugar') {
      this.isbloodSugarShow = true;
    }
    if (this.activeTab === 'Discharge') {
      this.isDischargeShow = true;
    }
    if (this.activeTab === 'Referral') {
      this.isReferralShow = true;
    }
    if (this.activeTab === 'Anesthesia') {
      this.isAnesthesiaShow = true;
    }
    if (this.activeTab === 'Main') {
      this.isMainShow = true;
      this.isMainVitalsUpdate = this.isVitalsRecieved;
      this.isMainServicesUpdate = this.isServicesRecieved;
      this.isMainDiagnosisUpdate = this.isDiagnosisRecieved;
    }
    if (this.activeTab === 'HistoryTaking') {
      this.isHistoryTakingShow = true;
    }
  }

  updateDataOnPatientChange(): any {
    this.isVitalsShow = false;
    this.isConsultationShow = false;
    this.isMedicinesShow = false;
    this.isDiagnosisShow = false;
    this.isServiceShow = false;
    this.isVaccinationShow = false;
    this.isBirthCertificateShow = false;
    this.isNotesShow = false;
    this.isNursingShow = false;
    this.isOperationRequestShow = false;
    this.isGlascoShow = false;
    this.isInOutTakeShow = false;
    this.ismyDocsShow = false;
    this.isbloodSugarShow = false;
    this.isDischargeShow = false;
    this.isAnesthesiaShow = false;
    this.isReferralShow = false;
    this.isMainShow = true;
    this.isHistoryTakingShow = false;
    this.updateData();
  }

  getScreenControls(): any {
    this.ngxLoader.start();
    this.userService.UserControlByScreenV2().subscribe(
      (data) => {
        this.screenControls = data.roles;
        if (this.screenControls.find((m) => m.code === 'vitals')) {
          this.vitalsControl =
            this.screenControls.find((m) => m.code === 'vitals').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'consultation')) {
          this.consultationControl =
            this.screenControls.find((m) => m.code === 'consultation')
              .is_show || false;
        }
        if (this.screenControls.find((m) => m.code === 'diagnosis')) {
          this.diagnosisControl =
            this.screenControls.find((m) => m.code === 'diagnosis').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'medicines')) {
          this.medicinesControl =
            this.screenControls.find((m) => m.code === 'medicines').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'services')) {
          this.servicesControl =
            this.screenControls.find((m) => m.code === 'services').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'referral')) {
          this.referralControl =
            this.screenControls.find((m) => m.code === 'referral').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'vaccination')) {
          this.vaccinationControl =
            this.screenControls.find((m) => m.code === 'vaccination').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'nursingprocedure')) {
          this.nursingProcedureControl =
            this.screenControls.find((m) => m.code === 'nursingprocedure')
              .is_show || false;
        }
        if (this.screenControls.find((m) => m.code === 'birthcertificate')) {
          this.birthCertificateControl =
            this.screenControls.find((m) => m.code === 'birthcertificate')
              .is_show || false;
        }
        if (this.screenControls.find((m) => m.code === 'discharge')) {
          this.dischargeControl =
            this.screenControls.find((m) => m.code === 'discharge').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'notes')) {
          this.notesControl =
            this.screenControls.find((m) => m.code === 'notes').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'consentforms')) {
          this.consentFormControl =
            this.screenControls.find((m) => m.code === 'consentforms')
              .is_show || false;
        }
        if (this.screenControls.find((m) => m.code === 'operationrequest')) {
          this.operationRequestControl =
            this.screenControls.find((m) => m.code === 'operationrequest')
              .is_show || false;
        }
        if (this.screenControls.find((m) => m.code === 'intakeoutputchart')) {
          this.inTakeOutPutControl =
            this.screenControls.find((m) => m.code === 'intakeoutputchart')
              .is_show || false;
        }
        if (this.screenControls.find((m) => m.code === 'glasgowcoma')) {
          this.glasgowComaControl =
            this.screenControls.find((m) => m.code === 'glasgowcoma').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'documents')) {
          this.documentsControl =
            this.screenControls.find((m) => m.code === 'documents').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'bloodsugar')) {
          this.bloodSugarControl =
            this.screenControls.find((m) => m.code === 'bloodsugar').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'history')) {
          this.historyControl =
            this.screenControls.find((m) => m.code === 'history').is_show ||
            false;
        }
        if (this.screenControls.find((m) => m.code === 'main')) {
          this.mainControl =
            this.screenControls.find((m) => m.code === 'main').is_show || false;
        }
        if (this.screenControls.find((m) => m.code === 'editmonursingnotes')) {
          this.editMoNursingNotesControl =
            this.screenControls.find((m) => m.code === 'editmonursingnotes')
              .is_show || false;
        }
        // this.updateControlData();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updatePatientWard(value): any {
    this.patientSearchWard = value;
    this.searchPatient();
  }

  searchPatient(): any {
    this.fetchPatients();
  }

  fetchPatients(): any {
    this.patients = [];
    this.ngxLoader.start();
    this.patientService
      .searchIpdPatientV2(
        this.PatientSearchForm.value,
        this.patientSearchWard,
        this.doctor_id,
      )
      .subscribe(
        (data) => {
          this.patients = data.patients;
          if (this.patientMRN && !this.patient) {
            this.patient = this.patients[0];
            this.patientMRN = null;
          }
          if (this.selectedMrn && this.otRequestedId) {
            const patient = this.patients.find(
              (t) => t.mrn === this.selectedMrn,
            );
            this.patient = patient;
          }
          this.ngxLoader.stop();
          this.chRef.detectChanges();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  getUserAssignWards(): any {
    this.wardsList = [];
    this.wardService.getUserAssignWard().subscribe(
      (data) => {
        this.wardsList = data.wards;
      },
      (err) => {},
    );
  }
  getWardListing(): any {
    this.wards = [];
    this.wardService.activeWardNamesV2().subscribe(
      (data) => {
        this.wards = data.wards;
        setTimeout(() => {
          $('#patientWard').selectpicker('refresh');
        }, 500);
      },
      (error) => {
        toastr.error(error.error.message);
        this.ngxLoader.stop();
      },
    );
  }

  selectPatientType(event): any {
    this.showDoctorList = event.target.value === 'doctor_type';
  }

  fetchDoctorPatient(value): any {
    this.patientSearchWard = '';
    this.doctor_id = value;
    this.searchPatient();
  }
  getServiceUnpaidOrAdvanceAmount(): any {
    this.serviceService.getServiceUnpaidOrAdvanceAmount(this.patient).subscribe(
      (data) => {
        this.amountData = data;
        if (this.amountData) {
          if (
            this.amountData.total_unpaid_invoices >
            this.amountData.total_remaining_advances
          ) {
            this.showMsg =
              'Patient account is out of balance. Please request patient to submit advance.';
          }
        }
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }

  patientCompleteConform(patient, ind): any {
    this.patientTemp = patient;
    this.selectedPatientIndex = ind;
    this.modalService.open(this.patientChangeConform);
    this.freeBedsList = [];
    this.isBedAllocate = true;
    this.bedAllocateId = '';
    if (!patient.bed_id) {
      this.isBedAllocate = false;
      this.getFreeBedList(patient.admissions[0].ward_id);
    }
  }

  patientBedUpdate(): any {
    if (this.isBedAllocate === false) {
      if (this.bedAllocateId === '') {
        toastr.error('Assign Bed First');
        return;
      }
    }
    this.isBedUpdateDisabled = true;

    this.ngxLoader.start();
    this.patientAdmissionService
      .changePatientJustBedInIPD(this.patientTemp, this.bedAllocateId)
      .subscribe(
        (data) => {
          toastr.success('Bed added successfully');
          this.ngxLoader.stop();
          this.isBedUpdateDisabled = false;
          this.fetchPatientAtBedChange(this.patientTemp);
        },
        (err) => {
          this.ngxLoader.stop();
          this.isBedUpdateDisabled = false;
          toastr.error(err.error.message);
        },
      );
  }

  fetchPatientAtBedChange(patient): any {
    const obj = {
      search_query: patient.mrn,
      type: 'mrn',
    };
    this.patientSearchWard = '';
    this.ngxLoader.start();
    this.patientService
      .searchIpdPatientV2(obj, this.patientSearchWard, '')
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.patients[this.selectedPatientIndex] = data.patients[0];
          this.patient = data.patients[0];
          // this.labParID =data.patients[0].id;
          this.fetchPatientInfo(this.patient);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  fetchPatientInfo(patient): any {
    this.modalService.dismissAll(this.patientChangeConform);
    this.patient = patient;
    this.getPatientsVitalsWeight();
    this.updateDataOnPatientChange();
    this.getServiceUnpaidOrAdvanceAmount();
    this.closePatientList();
    // this.PatientRegistrationForm.value.dob = this.patient_dob;
  }

  getPatientsVitalsWeight(): any {
    this.ngxLoader.start();
    this.patientService.getPatientsVitalsWeight(this.patient).subscribe(
      (data) => {
        this.vitalWeight = data;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  changeBed(): any {
    this.freeBedsList = [];
    this.getWardListing();
    this.getFreeBedList(this.patient.admissions[0].ward_id);
    if (this.patient.admissions) {
      this.changeWardForm
        .get('wardId')
        .patchValue(this.patient.admissions[0].ward_id);
      this.changeWardForm.get('bedId').patchValue(this.patient.bed_id);
      this.changeWardForm
        .get('condition')
        .patchValue(this.patient.admissions[0].condition);
    }
    this.changeWardForm.patchValue({
      // condition: 'normal',
      comments: '',
      informing_dr: '',
      via: '',
      response: '',
    });
    this.modalService.open(this.changeBedModal, this.ngbModalStaticLarge);
  }

  getFreeBedList(wardId): any {
    this.changeWardForm.get('bedId').patchValue('');
    this.ngxLoader.start();
    this.wardService.getNewBedV2(wardId, true).subscribe(
      (data) => {
        this.ngxLoader.stop();
        this.freeBedsList = data.beds;
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updatePatientBed(): any {
    if (this.changeWardForm.get('wardId').value === '') {
      return;
    }
    this.ngxLoader.start();
    this.patientAdmissionService
      .changePatientBedV2(this.patient, this.changeWardForm.value)
      .subscribe(
        (data) => {
          toastr.success('Bed Change Successfully');
          this.modalService.dismissAll(this.changeBedModal);
          this.ngxLoader.stop();
          this.patient = null;
          this.fetchPatients();
          this.getUpdatedPatient();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  getUpdatedPatient(): any {
    this.PatientSearchForm.patchValue({
      search_query: [''],
      type: ['default'],
    });
    this.patientSearchWard = '';
    this.ngxLoader.start();
    this.patientService
      .searchIpdPatientV2(
        this.PatientSearchForm.value,
        this.patientSearchWard,
        this.doctor_id,
      )
      .subscribe(
        (data) => {
          this.patients[this.selectedPatientIndex] = data.patients[0];
          this.patient = data.patients[0];
          this.getPatientsVitalsWeight();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  openPatientList(): any {
    $('#patient-list').toggleClass('offcanvas-on');
  }
  openRemarksModal(): any{
    $('#remarksModal').modal('show');
  }

  closePatientList(): any {
    $('#patient-list').removeClass('offcanvas-on');
  }

  // print paitent bed transfer history
  printPatientBedHistory(): any {
    const URL =
      '/pdf-reports/bed-transfer-history-v2?admission_id=' +
      this.patient.admissions[0].id +
      '&id=' +
      this.patient.id +
      '&visit_id=' +
      this.patient.visit_id +
      '&mrn=' +
      this.patient.mrn;
    window.open(URL, '_blank');
  }

  toHistory(): any {
    this.router.navigate(['ipd/history-v2'], {
      queryParams: { mrn: this.patient.mrn, page: 'ipd' },
    });
  }
  goToOtSurgery(): any {
    this.router.navigate(['ot/surgery'], {
      queryParams: { mrn: this.patient.mrn, ot_request_id: this.otRequestedId },
    });
  }

  toPatientHistory(): any {
    window.open('/patient-history/v2?mrn=' + this.patient.mrn);
  }

  openHistTab(): any {
    const element: HTMLElement = document.getElementById(
      'HistoryTabs',
    ) as HTMLElement;
    element.click();
    window.scroll(0, 0);
  }
}
