import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class LocationService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/locations?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  countries(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/countries?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getCountries(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/list_countries/?status=true&auth_token=` +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getCountryState(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/list_states/?status=true&auth_token=` +
          localStorage.getItem('auth_token') +
          '&country_id=' +
          value,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getStateCity(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/list_cities/?status=true&auth_token=` +
          localStorage.getItem('auth_token') +
          '&state_id=' +
          value,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLocation(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/list_locations/?status=true&auth_token=` +
          localStorage.getItem('auth_token') +
          '&city_id=' +
          value,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  countriesV2(is_type): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/countries?default=` +
          is_type +
          '&auth_token=' +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  countriesV3(is_type): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_countries?default=` +
          is_type +
          '&auth_token=' +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchCountries(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/clinical_v1/get_countries_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addCountry(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/clinical_v1/get_countries_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          country_dial_code: value.country_dial_code,
          country_code_name: value.country_code_name,
          status: value.status,
          pattern: value.pattern,
          is_default: value.is_default,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateCountry(value, id): Observable<any> {
    const results = this.http
      .put(
        environment.pythonUrl +
          '/clinical_v1/get_countries_detail?auth_token=' +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          country_dial_code: value.country_dial_code,
          country_code_name: value.country_code_name,
          status: value.status,
          pattern: value.pattern,
          is_default: value.is_default,
          // id: id,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // add States against countary
  getStateList(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/clinical_v1/get_state_list?country_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLocationList(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/clinical_v1/get_location_list?city_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addState(value, country): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/clinical_v1/get_state_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          status: value.status,
          is_default: value.is_default,
          country: country,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateState(value, id): Observable<any> {
    const results = this.http
      .put(
        environment.pythonUrl +
          '/clinical_v1/get_state_detail?auth_token=' +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          status: value.status,
          is_default: value.is_default,
          // id: id,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateCity(value, id): Observable<any> {
    const results = this.http
      .put(
        environment.pythonUrl +
          '/clinical_v1/get_city_detail?auth_token=' +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          status: value.status,
          is_default: value.is_default,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateLocation(value, id): Observable<any> {
    const results = this.http
      .put(
        environment.pythonUrl +
          '/clinical_v1/get_location_detail?auth_token=' +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getCityList(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/clinical_v1/get_city_list?state_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getClinics(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/clinical_v1/get_clinics_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  fetchCities(location_id: number): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/regions?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&location_id=' +
          location_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addCity(value, state): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/clinical_v1/get_city_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          status: value.status,
          is_default: value.is_default,
          state: state,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addLocation(value, city): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/clinical_v1/get_location_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          status: value.status,
          city: city,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // addClinic(value, location): Observable<any> {
  //   const results = this.http.post(`${environment.pythonUrl}/clinical_v1/get_clinics_list?auth_token=` + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'), {
  //     name: value.name,
  //     status: value.status,
  //     location: location,
  //   }, { headers: new HttpHeaders({
  //       AuthenticationToken: localStorage.getItem('auth_token'),
  //       Accept: 'application/json'
  //     })}).pipe(map(data => data));
  //   return results;
  // }
  // getClinicList(id): Observable<any> {
  //   const results = this.http.get(`${environment.pythonUrl}/clinical_v1/get_clinics_list?location_id=` + id + '&auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id')
  //     , {headers: new HttpHeaders({
  //         AuthenticationToken: localStorage.getItem('auth_token'),
  //         Accept: 'application/json'
  //       })}).pipe(map(data => data));
  //   return results;
  // }
  // updateClinic(value, id): Observable<any> {
  //   const results = this.http.put(environment.pythonUrl + '/clinical_v1/get_clinics_detail?auth_token=' + localStorage.getItem('auth_token') + '&id=' + id , {
  //     medical_unit_id: localStorage.getItem('current_medical_unit_id'),
  //     name: value.name,
  //     status: value.status,
  //   }, {headers: new HttpHeaders({
  //       Accept: 'application/json'
  //     })}).pipe(map(data => data));
  //   return results;
  // }
}
