import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { formatDate } from '@angular/common';
import { RouteShareService } from '../../../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-add-weekly-off',
  templateUrl: './add-weekly-off.component.html',
  styleUrls: ['./add-weekly-off.component.css'],
})
export class AddWeeklyOffComponent implements OnInit {
  today = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  dayoffForm: UntypedFormGroup;
  isAdd = false;
  list: any;
  page = 1;
  totalPages: any;
  perPage = 25;
  filterList: any;
  activeScreenInd: any;
  perPageValue: any;
  screenName = '';

  constructor(
    private fb: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private routeSevice: RouteShareService,
    private rostarService: RosterServiceService,
  ) {
    this.screenName = this.routeSevice.getValue();
    this.dayoffForm = this.fb.group({
      day: [0, Validators.required],
      status: [true, Validators.required],
    });
  }
  openHolidayModal(): any {
    this.activeScreenInd = '';
    this.isAdd = false;
    $('#dayoffModal').modal('show');
    this.dayoffForm.patchValue({
      day: 0,
      status: true,
    });
  }
  onsubmit(): any {
    this.isAdd = true;
    if (this.dayoffForm.invalid) {
      return;
    }
    if (!this.activeScreenInd) {
      this.ngxLoader.start();
      this.rostarService.submitDayOff(this.dayoffForm.value).subscribe(
        (data) => {
          this.isAdd = false;
          this.getDayOffWithPagination();
          this.ngxLoader.stop();
          $('#dayoffModal').modal('hide');
          toastr.success('Add Successfully');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
    } else {
      this.isAdd = true;
      if (this.dayoffForm.invalid) {
        return;
      }
      this.ngxLoader.start();
      this.rostarService
        .updateDayOff(this.dayoffForm.value, this.activeScreenInd)
        .subscribe(
          (data) => {
            this.isAdd = false;
            this.getDayOffWithPagination();
            $('#dayoffModal').modal('hide');
            this.ngxLoader.stop();
            toastr.success(' Update Successfully');
          },
          (err) => {
            this.ngxLoader.stop();
            toastr.error(err.error.message);
          },
        );
    }
  }
  edit(list, id): any {
    $('#dayoffModal').modal('show');
    this.activeScreenInd = id;
    this.dayoffForm.patchValue({
      day: list.day,
      status: list.status,
    });
  }
  loadPage(event): any {
    this.getDayOffWithPagination();
  }
  searchFilterValue(): any {
    this.page = 1;
    this.getDayOffWithPagination();
  }
  selectPaginationLimit(event): any {
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    this.searchFilterValue();
  }
  getDayOffWithPagination(): any {
    this.ngxLoader.start();
    this.rostarService.getDayOff(this.page, this.perPage).subscribe((data) => {
      this.list = data;
      this.totalPages = this.list.total_count;
      this.filterList = data?.items;
      this.ngxLoader.stop();
    });
  }
  ngOnInit(): void {
    this.perPageValue = 25;
    this.searchFilterValue();
  }
}
