<div>
  <div class="card mb-4">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <h4 class="mb-10 d-print-none">Nursing Procedures Check List</h4>
        <button
          class="btn btn-sm btn-light-primary mb-10 d-print-none"
          *ngIf="isAdd === true || isAdd === false"
          (click)="printNursingProcedure()"
        >
          Print
        </button>
      </div>
      <div class="col-4 mb-4 d-flex pl-0" *ngIf="!isAdd">
        <input
          type="text"
          max="{{ currentDateForNursing | date: 'DD-MM-YYYY' }}"
          id="procedureDates"
          name="procedureDate"
          readonly
          class="form-control form-control-sm mt-1 mr-5"
          [(ngModel)]="procedureDate"
        />
        <button
          class="btn btn-light-primary btn-sm mr-2"
          (click)="getNursingProcedure('')"
        >
          Search
        </button>
      </div>
      <div class="table-responsive w-100">
        <table
          class="table table-head-custom table-vertical-center table-bordered"
          id="kt_advance_table_widget_4"
        >
          <thead>
            <tr style="border: 1px solid #181c321f">
              <th style="width: 12%">Procedure</th>
              <th style="width: 40%" class="d-print-none" *ngIf="isAdd">
                <div class="justify-content-around d-flex">
                  <!--                            <button class="button btn-light-info text-black d-block" (click)="getNursingProcedure()"><span style="font-size: 14px; padding: 5px"><</span></button>-->
                  <input
                    type="text"
                    max="{{ currentDateForNursing | date: 'DD-MM-YYYY' }}"
                    id="procedureDate"
                    name="procedureDate"
                    readonly
                    class="form-control form-control-sm mt-1 ml-5 mr-5"
                    [(ngModel)]="procedureDate"
                  />
                  <!--                            <input type="date" id="procedure" (change)='updateProcedureDate($event.target.value)'-->
                  <!--                                   max="{{currentDate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm">-->
                  <!--                            <button class="button btn-light-info text-black d-block" (click)="getNursingProcedure()"><span style="font-size: 12px; padding: 4px">Search</span></button>-->
                  <button
                    class="btn btn-light-primary btn-sm mr-2"
                    (click)="getNursingProcedure('')"
                  >
                    Search
                  </button>
                </div>
              </th>
              <th style="width: 12%">Morning</th>
              <th style="width: 12%">Afternoon</th>
              <th style="width: 12%">Evening</th>
              <th style="width: 12%">Night</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="let procedure of procedureData; let i = index"
            >
              <tr style="border: 1px solid #181c321f">
                <td>{{ procedure?.name }}</td>
                <td class="d-print-none" *ngIf="isAdd === true">
                  <ng-container *ngFor="let interval of procedure?.interval">
                    <button
                      class="btn btn-sm pr-1 ml-2 mt-2 buttonProcedure"
                      style="padding: 2px 5px; min-width: 80px"
                      *ngIf="interval?.name === 'morning'"
                      (click)="openModal(interval?.id, procedure?.id)"
                      [disabled]="interval?.is_checked === true"
                    >
                      <span style="font-size: 12px">{{
                        interval?.name | titlecase
                      }}</span>
                      <span class="d-block" style="font-size: 10px"
                        >{{ interval?.start_time }} -
                        {{ interval?.end_time }}</span
                      >
                    </button>
                    <button
                      class="btn btn-sm primaryBtn pr-1 ml-2 mt-2"
                      style="padding: 2px 5px; min-width: 80px"
                      *ngIf="interval?.name === 'afternoon'"
                      (click)="openModal(interval?.id, procedure?.id)"
                      [disabled]="interval?.is_checked === true"
                    >
                      <span style="font-size: 12px">{{
                        interval?.name | titlecase
                      }}</span>
                      <span class="d-block" style="font-size: 10px"
                        >{{ interval?.start_time }} -
                        {{ interval?.end_time }}</span
                      >
                    </button>
                    <button
                      class="btn btn-sm successBtn pr-1 ml-2 mt-2"
                      style="padding: 2px 5px; min-width: 80px"
                      *ngIf="interval?.name === 'evening'"
                      (click)="openModal(interval?.id, procedure?.id)"
                      [disabled]="interval?.is_checked === true"
                    >
                      <span style="font-size: 12px">{{
                        interval?.name | titlecase
                      }}</span>
                      <span class="d-block" style="font-size: 10px"
                        >{{ interval?.start_time }} -
                        {{ interval?.end_time }}</span
                      >
                    </button>
                    <button
                      class="btn btn-sm dangerBtn pr-1 ml-2 mt-2"
                      style="padding: 2px 5px; min-width: 80px"
                      *ngIf="interval?.name === 'night'"
                      (click)="openModal(interval?.id, procedure?.id)"
                      [disabled]="interval?.is_checked === true"
                    >
                      <span style="font-size: 12px">{{
                        interval?.name | titlecase
                      }}</span>
                      <span class="d-block" style="font-size: 10px"
                        >{{ interval?.start_time }} -
                        {{ interval?.end_time }}</span
                      >
                    </button>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngFor="let interval of procedure?.interval">
                    <ng-container *ngIf="interval?.name === 'morning'">
                      <span>{{ interval?.done_by?.full_name }}</span>
                      <div *ngIf="interval?.done_by?.full_name">
                        {{ interval?.modified_on | date: "h:mm a" }}
                      </div>
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngFor="let interval of procedure?.interval">
                    <ng-container *ngIf="interval?.name === 'afternoon'">
                      <span>{{ interval?.done_by?.full_name }}</span>
                      <div *ngIf="interval?.done_by?.full_name">
                        {{ interval?.modified_on | date: "h:mm a" }}
                      </div>
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngFor="let interval of procedure?.interval">
                    <ng-container *ngIf="interval?.name === 'evening'">
                      <span>{{ interval?.done_by?.full_name }}</span>
                      <div *ngIf="interval?.done_by?.full_name">
                        {{ interval?.modified_on | date: "h:mm a" }}
                      </div>
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngFor="let interval of procedure?.interval">
                    <ng-container *ngIf="interval?.name === 'night'">
                      <span>{{ interval?.done_by?.full_name }}</span>
                      <div *ngIf="interval?.done_by?.full_name">
                        {{ interval?.modified_on | date: "h:mm a" }}
                      </div>
                    </ng-container>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!--procedure modal-->
<div
  class="modal fade"
  id="procedureModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2">
        <h5 class="modal-title">Procedure confirmation</h5>

        <!--        <h5 class="modal-title" *ngIf="tagId">Updat</h5>-->

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form [formGroup]="procedureForm" (ngSubmit)="sendIdPassword()">
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-md-4 col-sm-12 col-12">
              <!--              <div class="form-group">-->

              <label>Emp Id <span class="text-danger">*</span></label>

              <input
                type="number"
                placeholder="Emp Id"
                class="form-control form-control-sm"
                formControlName="emp_id"
                [ngClass]="{
                  'is-invalid':
                    this.doneBy && this.procedureForm.controls.emp_id.errors
                }"
              />

              <!--              </div>-->
            </div>

            <div class="col-lg-6 col-md-4 col-sm-12 col-12">
              <!--              <div class="form-group">-->

              <label>Password <span class="text-danger">*</span></label>

              <input
                type="password"
                placeholder="Password"
                class="form-control form-control-sm"
                formControlName="password"
                [ngClass]="{
                  'is-invalid':
                    this.doneBy && this.procedureForm.controls.password.errors
                }"
              />

              <!--              </div>-->
            </div>
          </div>
        </div>

        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="closeModal()"
          >
            Close
          </button>

          <button type="submit" class="btn btn-sm btn-primary">Submit</button>

          <!--          <button type="submit" class="btn btn-sm btn-primary">Update</button>-->
        </div>
      </form>
    </div>
  </div>
</div>
