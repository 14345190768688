import { Component, OnInit } from '@angular/core';
import { PdfServicesService } from '../../../../services/pdf-services.service';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../../environments/environment';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-radiology-pdf-report',
  templateUrl: './radiology-pdf-report.component.html',
  styleUrls: ['./radiology-pdf-report.component.css'],
})
export class RadiologyPdfReportComponent implements OnInit {
  today = new Date();
  Id: number;
  patientRadiologyData: any;
  userName: any;
  settingData: any;
  hospitalName = environment.hospital;
  userData: any;
  uName: any;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private pdfService: PdfServicesService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.Id = +params['id'];
    });
    if (this.Id) {
      this.fetchRadiologyData();
    }
    this.userName = JSON.parse(localStorage.getItem('currentUser'));
    this.getSettingsData();
  }
  fetchRadiologyData(): any {
    this.ngxLoader.start();
    this.pdfService.getRadiologyReport(this.Id).subscribe(
      (data) => {
        this.patientRadiologyData = data.report;
        setTimeout(() => {
          if (this.patientRadiologyData) {
            document.getElementById('resultHTML').innerHTML = this
              .patientRadiologyData.report
              ? this.patientRadiologyData.report
              : '';
            document.getElementById('impressionHTML').innerHTML = this
              .patientRadiologyData.impression
              ? this.patientRadiologyData.impression
              : '';
            document.getElementById('addendumHTML').innerHTML = this
              .patientRadiologyData.addendum
              ? this.patientRadiologyData.addendum
              : '';
            $('.data table').css({
              'max-width': '100%',
              'margin-bottom': '10px',
              'margin-top': '10px',
            });
          }
        }, 500);
        console.log('patientRadiologyData', this.patientRadiologyData);
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
  }
  printReport(): any {
    window.print();
  }
  getSettingsData(): any {
    this.pdfService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
}
