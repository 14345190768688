import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {OpdService} from '../../../services/opd.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-opd-template-modal',
  templateUrl: './opd-template-modal.component.html',
  styleUrls: ['./opd-template-modal.component.css']
})
export class OpdTemplateModalComponent implements OnInit {
  @Output() getOpdTemplateData: EventEmitter<string[]> = new EventEmitter();
  @Input() type = '';
  templateName = '';
  self = true;
  description = '';
  opdTemplateData = [];
  isSubmit = false;
  constructor(private ngxLoader: NgxUiLoaderService,
              private opdService: OpdService) { }


  // Listing
  getOpdTemplateListing(): any{
    this.opdService.opdTemplateListing(this.type).subscribe(data => {
      this.opdTemplateData = data.opd_configurators;
    }, err => {
      toastr.error(err.error.message);
    });
  }
  stripHtml(content: string): string {
    return content.replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
      .replace(/&nbsp;/g, '') // Remove non-breaking spaces
      .trim();
  }
  submit(): any{
    const summernoteContent = $('#description').summernote('code'); // Get Summernote content
    if (this.templateName === '' || this.templateName.trim() === ''){
      toastr.error('Please add Template Name');
      return;
    } else if (!summernoteContent || this.stripHtml(summernoteContent) === '') {
      toastr.error('Please add Description');
      return;
    }
    const obj = {
      name: this.templateName,
      description: $('#description').summernote('isEmpty') === false ? $('#description').summernote('code') : '',
      self: this.self,
      type: this.type,
    };
    this.isSubmit = true;
    this.ngxLoader.start();
    this.opdService.addOpdTemplate(obj).subscribe(data => {
      this.ngxLoader.stop();
      this.isSubmit = false;
      this.getOpdTemplateListing();
      this.getOpdTemplateData.emit();
      this.templateName = '';
      $('#description').summernote('code', '');
      this.description = '';
      this.self = true;
    }, err => {
      this.ngxLoader.stop();
      this.isSubmit = false;
      toastr.error(err.error.message);
    });
  }
  ngOnInit(): void {
    $('#description').summernote({
      height: 100,
      // placeholder: 'Drug History',
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        // ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
      ],
    });
    $('#description').summernote('code', '');
    this.getOpdTemplateListing();
  }

  maxLength(event: KeyboardEvent): any{
    const inputElement = event.target as HTMLInputElement;

    // Check if the length of the value is 50 or more
    if (inputElement.value.length >= 50) {
      event.preventDefault(); // Prevent further input
    }
  }

}
