import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class DoctorSurveyService {
  constructor(private http: HttpClient) {}
  submitSurvey(doctor_survey): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/doctorsurvey/doctor_list_surveys/?`,
        {
          doctor_survey,
        },
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSurveyList(survey, page, per_page): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/doctorsurvey/doctor_list_surveys/?` +
          '&from_date=' +
          survey.from_date +
          '&to_date=' +
          survey.to_date +
          '&speciality=' +
          survey.speciality +
          '&country_of_practice=' +
          survey.country_of_practice +
          '&compensation=' +
          survey.compensation +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  Departmentsv2DoctorSurvey(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/camden_website/departments/?` +
          '&medical_unit_id=' +
          136,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getCountriesDoctorSurvey(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/camden_website/list_countries/?` +
          '&medical_unit_id=' +
          136,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
