import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { OpdService } from '../../../../services/opd.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.css'],
})
export class DiagnosisComponent implements OnInit {
  @Input() patient: any;
  @Input() visitType: any;
  @Input() isAdd: boolean;
  @Input() isDiagnosisUpdate: number;
  @Output() isDiagnosisRecieved = new EventEmitter<string>();

  diagnosisListData = [];
  diagnosisSearchResult = false;
  diagnosisSearch = '';
  diagnosisSelected = [];
  disableDiagnosisBtn = false;
  differentialSearch = '';
  differentialSelected = [];
  finalSearch = '';
  finalSelected = [];
  paitestDiagnosisHistory = [];
  paitestDiagnosisHistoryData: any;
  patientAllHistory = false;

  constructor(
    private opdService: OpdService,
    private ngxLoader: NgxUiLoaderService,
  ) {}

  ngOnInit(): void {
    // this.getPaitentDiagnosis('');
  }
  ngOnChanges(changes: SimpleChanges): any {
    if (changes.isDiagnosisUpdate?.currentValue) {
      this.getPaitentDiagnosis('');
    }
    this.getPaitentDiagnosis(changes.patient?.currentValue);
  }

  getDiagnosisList(): any {
    this.diagnosisListData = [];
    this.diagnosisSearchResult = false;
    if (this.diagnosisSearch.length < 2) {
      return;
    }
    this.opdService.getDiagnosisListFunv2(this.diagnosisSearch).subscribe(
      (data) => {
        this.diagnosisListData = data.data;
        this.diagnosisSearchResult = true;
      },
      (err) => {
        this.diagnosisListData = [];
        this.diagnosisSearchResult = true;
      },
    );
  }

  setDiagnosis(value): any {
    this.diagnosisSelected = [];
    this.diagnosisSelected.push(value);
    this.diagnosisSearch = value.name;
    $('.search-result-main').hide();
  }

  addDiagnosis(type): any {
    if (!this.diagnosisSearch) {
      return;
    }
    this.disableDiagnosisBtn = true;
    this.ngxLoader.start();
    this.opdService
      .addDiagnosisFunv2(
        this.diagnosisSelected,
        this.patient,
        type,
        this.diagnosisSearch,
      )
      .subscribe(
        (data) => {
          this.diagnosisSelected = [];
          this.diagnosisSearch = '';
          this.getPaitentDiagnosis('');
          this.isDiagnosisUpdate = Math.random();
          this.isDiagnosisRecieved.emit(this.isDiagnosisUpdate.toString());
          this.disableDiagnosisBtn = false;
        },
        (err) => {
          this.ngxLoader.stop();
          this.disableDiagnosisBtn = false;
        },
      );
  }

  getDifferentialDiagnosisList(): any {
    this.diagnosisListData = [];
    this.diagnosisSearchResult = false;
    if (this.differentialSearch.length < 2) {
      return;
    }
    this.opdService.getDiagnosisListFunv2(this.differentialSearch).subscribe(
      (data) => {
        this.diagnosisListData = data.data;
        this.diagnosisSearchResult = true;
      },
      (err) => {
        this.diagnosisListData = [];
        this.diagnosisSearchResult = true;
      },
    );
  }

  setDifferentialDiagnosis(value): any {
    this.differentialSelected = [];
    this.differentialSelected.push(value);
    this.differentialSearch = value.name;
    $('.search-result-main').hide();
  }

  addDifferentialDiagnosis(type): any {
    if (!this.differentialSearch) {
      return;
    }
    this.disableDiagnosisBtn = true;
    this.ngxLoader.start();
    this.opdService
      .addDiagnosisFunv2(
        this.differentialSelected,
        this.patient,
        type,
        this.differentialSearch,
      )
      .subscribe(
        (data) => {
          this.differentialSelected = [];
          this.differentialSearch = '';
          this.getPaitentDiagnosis('');
          this.disableDiagnosisBtn = false;
        },
        (err) => {
          this.disableDiagnosisBtn = false;
          this.ngxLoader.stop();
        },
      );
  }

  geFinalDiagnosisList(): any {
    this.diagnosisListData = [];
    this.diagnosisSearchResult = false;
    if (this.finalSearch.length < 2) {
      return;
    }
    this.opdService.getDiagnosisListFunv2(this.finalSearch).subscribe(
      (data) => {
        this.diagnosisListData = data.data;
        this.diagnosisSearchResult = true;
      },
      (err) => {
        this.diagnosisListData = [];
        this.diagnosisSearchResult = true;
      },
    );
  }

  setFinalDiagnosis(value): any {
    this.finalSelected = [];
    this.finalSelected.push(value);
    this.finalSearch = value.name;
    $('.search-result-main').hide();
  }

  addFinalDiagnosis(type): any {
    if (!this.finalSearch) {
      return;
    }
    this.disableDiagnosisBtn = true;
    this.ngxLoader.start();
    this.opdService
      .addDiagnosisFunv2(
        this.finalSelected,
        this.patient,
        type,
        this.finalSearch,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.finalSelected = [];
          this.finalSearch = '';
          this.getPaitentDiagnosis('');
          this.disableDiagnosisBtn = false;
        },
        (err) => {
          this.disableDiagnosisBtn = false;
          this.ngxLoader.stop();
        },
      );
  }

  getPaitentDiagnosis(val): any {
    this.paitestDiagnosisHistoryData = [];
    this.paitestDiagnosisHistory = [];
    this.diagnosisSearch = '';
    this.differentialSearch = '';
    this.finalSearch = '';
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.opdService
      .getPaitentDiagnosisFunv2(
        this.patient,
        this.patientAllHistory,
        this.visitType,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.paitestDiagnosisHistoryData = data;
          this.paitestDiagnosisHistory = data.patient_diagnoses;
          $('#diagnosisFilter').val('patient_diagnosis');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
}
