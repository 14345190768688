import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Radiology } from '../pages/_models/radiology';

@Injectable()
export class RadiologyService {
  constructor(private http: HttpClient) {}

  getAllServices(
    current_page: any,
    per_page: any,
    search_query: any,
  ): Observable<any> {
    return this.http
      .get(
        environment.rorUrl +
          '/v4/radiologies?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getAllServicesv2(
    current_page: any,
    per_page: any,
    search_query: any,
  ): Observable<any> {
    return this.http
      .get(
        environment.pythonUrl +
          '/api/radiologies?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  create(radiology: Radiology): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/radiologies?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          radiology.department_id,
        {
          radiology,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createv2(radiology: Radiology): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/radiologies/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          radiology.department_id,
        {
          radiology,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRadiology(id: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/radiologies/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRadiologyv2(id: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/radiologies/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  update(
    radiology_update: Radiology,
    id: number,
    service_id: number,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/radiologies/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&service_id=' +
          service_id,
        {
          radiology: radiology_update,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatev2(
    radiology_update: Radiology,
    id: number,
    service_id: number,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/radiologies/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&service_id=' +
          service_id,
        {
          radiology: radiology_update,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchTemplate(name: string, self): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/report_templates/search?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&name=' +
          name +
          '&self=' +
          self,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchTemplateV2(
    name: string,
    self: boolean,
    searchType: string,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/search_report_templates/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&name=' +
          name +
          '&self=' +
          self +
          '&search_type=' +
          searchType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTemplate(tempID): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/report_templates/` +
          tempID +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  createTemplate(name, report, impression, classic, user_ids): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/report_templates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          report_template: {
            name,
            report,
            impression,
            classic,
            user_ids,
          },
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createTemplatev2(
    name,
    report,
    impression,
    classic,
    user_ids,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/report_templates/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name,
          report,
          impression,
          classic,
          user_ids,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateTemplate(
    name,
    report,
    impression,
    classic,
    id,
    user_ids,
  ): Observable<any> {
    const results = this.http
      .put(
        environment.rorUrl +
          '/v4/report_templates/' +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          template: {
            name,
            report,
            impression,
            id,
            classic,
            user_ids,
          },
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateTemplatev2(
    name,
    report,
    impression,
    classic,
    id,
    user_ids,
  ): Observable<any> {
    const results = this.http
      .put(
        environment.pythonUrl +
          '/api/report_templates/' +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name,
          report,
          impression,
          id,
          classic,
          user_ids,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  saveReportWriting(
    name: string,
    report: string,
    impression: string,
    type: string,
    patient_service_id,
    classic,
    history,
    urgent_action,
  ): Observable<any> {
    let url = '';
    let obj = {};
    if (type === 'report') {
      url = 'reports';
      obj = {
        report,
        impression,
        patient_service_id,
        classic,
        history,
      };
    } else if (type === 'template') {
      url = 'report_templates';
      obj = {
        name,
        report,
        impression,
        classic,
        template_for_current_user: true,
      };
    } else {
      url = 'patient_services/finalize_report';
      obj = {
        report,
        impression,
        patient_service_id,
        classic,
        history,
        urgent_action,
      };
    }
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/` +
          url +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        obj,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            medical_unit_id: localStorage.getItem('current_medical_unit_id'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  saveReportWritingV2(
    name: string,
    report: string,
    impression: string,
    type: string,
    patient_service_id,
    classic,
    history,
    urgent_action,
  ): Observable<any> {
    let url = '';
    let obj = {};
    if (type === 'report') {
      url = 'report';
      obj = {
        report,
        impression,
        patient_service_id,
        classic,
        history,
      };
    } else if (type === 'template') {
      url = 'report_templates';
      obj = {
        name,
        report,
        impression,
        classic,
        template_for_current_user: true,
      };
    } else {
      url = 'finalize_report';
      obj = {
        report,
        impression,
        patient_service_id,
        history,
        classic,
        urgent_action,
      };
    }
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/` +
          url +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        obj,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            // medical_unit_id: localStorage.getItem('current_medical_unit_id'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchReportsByPatientId(id: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_services/` +
          id +
          `/reports?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchReportsByPatientIdV2(id: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/reports/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  rejectLabTest(id, reason): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services/reject`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
          reason,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  rejectLabTestV2(id, reason): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/reject/` +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
          reason,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  verifyToUnverify(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services/unverify_report`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  verifyToUnverifyV2(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/unverify_report/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchUnDeliveredReports(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_services/finalized_reports?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&filter_type=' +
          lab_filters.type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchUnDeliveredReportsV2(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/finalized_reports/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&filter_type=' +
          lab_filters.type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchDeliveredReports(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_services/delivered_reports?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&filter_type=' +
          lab_filters.type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchDeliveredReportsv2(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/delivered_reports/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&filter_type=' +
          lab_filters.type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  deliveredLabTestResultFun(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services/deliver_report/`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          patient_service_id: value.id,
          receiver_relation: value.receiver_relation,
          receiver_cnic: value.receiver_cnic,
          receiver_name: value.receiver_name,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  deliveredLabTestResultFunV2(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/deliver_report/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          patient_service_id: value.id,
          receiver_relation: value.receiver_relation,
          receiver_cnic: value.receiver_cnic,
          receiver_name: value.receiver_name,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getRadiologyServices(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/services/services_by_type?assignable_type=` +
          type +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRadiologyServicesv2(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/services_by_type/?assignable_type=` +
          type +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  radiologySubDeptByUser(): Observable<any> {
    const results = this.http
      .get(
        environment.rorUrl +
          '/v4/departments/radiology_user_wise_sub_departments?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  radiologySubDeptByUserV2(): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/radiology_user_wise_sub_departments/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  radiologySubDept(): Observable<any> {
    const results = this.http
      .get(
        environment.rorUrl +
          '/v4/departments/radiology_sub_departments?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  radiologySubDeptv2(): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/radiology_sub_departments/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchRadiolofyServicesName(id): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/services_by_department/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id +
          '&assignable_type=Radiology',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchRadiologyLogs(
    currentPage: any,
    perPage: any,
    radiologyFilters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_services/radiology_log_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          radiologyFilters.from_date +
          '&to_date=' +
          radiologyFilters.to_date +
          '&patient_mrn=' +
          radiologyFilters.patient_mrn +
          '&department_id=' +
          radiologyFilters.department_id +
          '&service_id=' +
          radiologyFilters.service_id +
          '&report_type=' +
          radiologyFilters.report_type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchRadiologyLogsv2(
    currentPage: any,
    perPage: any,
    radiologyFilters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/radiology_log_data/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          radiologyFilters.from_date +
          '&to_date=' +
          radiologyFilters.to_date +
          '&patient_mrn=' +
          radiologyFilters.patient_mrn +
          '&department_id=' +
          radiologyFilters.department_id +
          '&service_id=' +
          radiologyFilters.service_id +
          '&report_type=' +
          radiologyFilters.report_type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeDoctor(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services/change_doctor?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeDoctorV2(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/change_doctor/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getRadiologyDashboardData(value): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/dashboard/current_user_dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  receivedOrderFunction(value): Observable<any> {
    console.log('value', value);
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patient_services/update_requested_service?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          received_by: value.received_by,
          inform_to: value.inform_to,
          patient_service_id: value.order_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  receivedOrderFunctionV2(value): Observable<any> {
    console.log('value', value);
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_requested_service/` +
          value.order_id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          received_by: value.received_by,
          inform_to: value.inform_to,
          patient_service_id: value.order_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  cancelLabTest(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services/cancel`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  cancelLabTestV2(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/cancel/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  returnCancelLabTest(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services/uncancel`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  returnCancelLabTestV2(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/uncancel/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
