<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">Radiology Log Report</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card card-custom d-print-none">
        <div class="card-body">
          <form
            [formGroup]="radiologyServicesForm"
            (ngSubmit)="searchInvoices()"
          >
            <div class="row">
              <div class="col-3">
                <div class="form-group">
                  <label>From Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="fromDate"
                    data-toggle="datetimepicker"
                    data-target="#fromDate"
                  />
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <label>To Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="toDate"
                    data-target="#toDate"
                    data-toggle="datetimepicker"
                  />
                </div>
              </div>

              <div class="col-3">
                <label>Department</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm selectpicker"
                      (change)="fetchServicesByDepartment($event.target.value)"
                      id="department"
                      formControlName="department_id"
                      data-dropup-auto="false"
                      data-live-search="true"
                    >
                      <option value="" disabled>Select</option>
                      <option value="" *ngIf="subDepartments.length > 0">
                        All
                      </option>
                      <option
                        [value]="department.id"
                        *ngFor="let department of subDepartments"
                      >
                        {{ department?.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <label>Services</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm bootstrap-select-container selectpicker"
                      id="serviceiId"
                      formControlName="service_id"
                      data-dropup-auto="false"
                      data-live-search="true"
                    >
                      <option value="" disabled>Select</option>
                      <option value="" *ngIf="radiologyServices.length > 0">
                        All
                      </option>
                      <ng-container
                        *ngFor="let s of radiologyServices; let i = index"
                      >
                        <option [value]="s?.id">{{ s?.name }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <label>MRN</label>
                <div class="form-group">
                  <div class="d-block">
                    <input
                      class="form-control form-control-sm"
                      placeholder="Patient MRN"
                      formControlName="patient_mrn"
                    />
                  </div>
                </div>
              </div>

              <div class="col-3">
                <label>Status</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm selectpicker"
                      id="reportType"
                      formControlName="report_type"
                    >
                      <option value="" disabled>Select</option>
                      <option value="">All</option>
                      <!--                      <option value="created">Created</option>-->
                      <option value="delivered">Delivered</option>
                      <option value="acknowledged">Acknowledged</option>
                      <option value="final_signed">Final Signed</option>
                    </select>
                  </div>
                </div>
              </div>

              <!--add button-->
              <div class="col-12 text-right">
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm cus_btm"
                    ngbTooltip="Search Result"
                    placement="bottom"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="card card-custom gutter-b mt-7"
        *ngIf="pageDataList.length > 0"
      >
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Table-->

          <div class="mb-6 d-none d-print-block">
            <div class="d-flex">
              <div style="width: 85%">
                <!-- header table -->
                <table class="table">
                  <tbody>
                    <tr>
                      <td style="width: 15%; padding: 0" class="border-top-0">
                        <img
                          [src]="settingData?.logo?.url"
                          style="width: auto; height: 106px"
                        />
                      </td>
                      <td style="width: 85%" class="border-top-0">
                        <div class="text-center">
                          <h3>{{ settingData?.title }}</h3>
                        </div>
                        <div class="font-size-h5 text-center">
                          <h5>{{ settingData?.address }}</h5>
                        </div>
                        <div class="text-center">
                          <h3>Radiology Log Report</h3>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="table_top_bar mb-3 d-print-none">
            <div class="row">
              <div class="col-12 col-sm-12 text-right">
                <div class="dropdown dropdown-inline mr-2">
                  <button
                    type="button"
                    class="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <span class="svg-icon svg-icon-md">
                      <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Design/PenAndRuller.svg-->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                            d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                            fill="#000000"
                            opacity="0.3"
                          ></path>
                          <path
                            d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                            fill="#000000"
                          ></path>
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                    Export
                  </button>
                  <!--begin::Dropdown Menu-->
                  <div
                    class="dropdown-menu dropdown-menu-sm dropdown-menu-right"
                    x-placement="bottom-end"
                    style="
                      position: absolute;
                      transform: translate3d(-71px, 36px, 0px);
                      top: 0px;
                      left: 0px;
                      will-change: transform;
                    "
                  >
                    <!--begin::Navigation-->
                    <ul class="navi flex-column navi-hover py-2">
                      <li
                        class="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2"
                      >
                        Choose an option:
                      </li>
                      <li class="navi-item">
                        <a (click)="downloadCSV()" class="navi-link">
                          <span class="navi-icon">
                            <i class="fas fa-file-alt"></i>
                          </span>
                          <span class="navi-text">CSV</span>
                        </a>
                        <!--                        <a (click)="printReport()" class="navi-link">-->
                        <!--                                    <span class="navi-icon">-->
                        <!--                                    <i class="fas fa-file-alt"></i>-->
                        <!--                                    </span>-->
                        <!--                          <span class="navi-text">PDF</span>-->
                        <!--                        </a>-->
                      </li>
                    </ul>
                    <!--end::Navigation-->
                  </div>
                  <!--end::Dropdown Menu-->
                </div>
              </div>
            </div>
          </div>

          <!--          <div class="row">-->
          <!--            <div class="col-12 text-right">-->
          <!--              <div class="form-group">-->
          <!--                <button type="submit" class="btn btn-primary btn-sm cus_btm" *ngIf="pageDataList.length > 0" (click)="printReport()">Print Report</button>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <div>
            <div class="table-responsive position-relative">
              <table
                class="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_3"
              >
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th style="width: 21%">
                      Test Name /<br />
                      Accession No.
                    </th>
                    <th style="width: 15%">
                      Patient MRN /<br />
                      Name
                    </th>
                    <th style="width: 12%">
                      Department /<br />
                      Patient Category
                    </th>
                    <th style="width: 13%">Created</th>
                    <th style="width: 13%">Acknowledged</th>
                    <th style="width: 13%">Final Signed</th>
                    <th style="width: 13%">Delivered</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of pageDataList; let i = index">
                    <td>
                      <span>{{ i + 1 }}</span>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ data?.test_name }}</span
                      >
                      <span class="text-muted font-weight-bold d-block">{{
                        data?.accession_number
                      }}</span>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ data?.patient_data?.patient_mrn }}</span
                      >
                      <span class="text-muted font-weight-bold d-block">{{
                        data?.patient_data?.patient_name
                      }}</span>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ data?.department_title }}</span
                      >
                      <span class="text-muted font-weight-bold d-block">{{
                        data?.category
                      }}</span>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ data?.created_data?.created_by }}</span
                      >
                      <span class="text-muted font-weight-bold d-block">{{
                        data?.created_data?.created_at
                          | date: "dd-MM-yyyy h:mm a"
                      }}</span>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ data?.acknowledge_data?.acknowledged_by }}</span
                      >
                      <span class="text-muted font-weight-bold d-block">{{
                        data?.acknowledge_data?.acknowledged_date
                          | date: "dd-MM-yyyy h:mm a"
                      }}</span>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ data?.final_sign_data?.final_signed_by }}</span
                      >
                      <span class="text-muted font-weight-bold d-block">{{
                        data?.final_sign_data?.final_sign_date
                          | date: "dd-MM-yyyy h:mm a"
                      }}</span>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ data?.delivered_data?.delivered_by }}</span
                      >
                      <span class="text-muted font-weight-bold d-block">{{
                        data?.delivered_data?.delivered_date
                          | date: "dd-MM-yyyy h:mm a"
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
            <!--pagination-->
            <div class="pagination_main d-print-none">
              <ngb-pagination
                [(page)]="page"
                (pageChange)="loadPage(page)"
                [pageSize]="perPage"
                [collectionSize]="totalPages"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              ></ngb-pagination>
              <div class="dropdown">
                <a
                  class="btn dropdown-toggle btn-light btn-sm"
                  role="button"
                  id="pagination_btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="page_count">
                    {{ perPage }}
                  </span>
                  <span class="count_count_icon"></span>
                </a>
                <div class="dropdown-menu" aria-labelledby="pagination_btn">
                  <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                    >25</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                    >50</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                    >75</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                    >100</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit('')"
                    >All</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</div>
