import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {EPrescriptionComponent} from '../../opd/opd-components/e-prescription/e-prescription.component';

@Component({
  selector: 'app-prescription-review-history',
  templateUrl: './prescription-review-history.component.html',
  styleUrls: ['./prescription-review-history.component.css']
})
export class PrescriptionReviewHistoryComponent implements OnInit {
  @Input() label: any;
  getTemplateData: any;
  reviewSystemHistoryForm: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder) {
    this.reviewSystemHistoryForm = this.formBuilder.group({
      patient_ent_history_attributes: this.formBuilder.group({
        id: '',
        vision_disturbance: false,
        headache: false,
        eye_pain: false,
        ringing: false,
        congestion: false,
        hearing_loss: false,
        ear_pain: false,
        facial_swelling: false,
        visual_difdiculty: false,
      }),
      patient_general_health_history_attributes: this.formBuilder.group({
        id: '',
        fatigue: false,
        activity_change: false,
        fever: false,
        unexpected_weight_change: false,
        appetite_change: false,
        other: false,
      }),
      patient_cardiology_history_attributes: this.formBuilder.group({
        id: '',
        chest_pain: false,
        edema: false,
        pnd: false,
        palpitations: false,
        shortness_of_breath: false,
        orthopnea: false,
      }),
      patient_respiratory_history_attributes: this.formBuilder.group({
        id: '',
        cough: false,
        shortness_of_breath: false,
        stridor: false,
        wheeze: false,
        chest_tightness: false,
        choking: false,
      }),
      patient_skin_history_attributes: this.formBuilder.group({
        id: '',
        environmental_allergies: false,
        recurrent_infections: false,
        food_allergies: false,
        immunodeficiency: false,
      }),
      patient_neuro_history_attributes: this.formBuilder.group({
        id: '',
        dizziness: false,
        numbness: false,
        weakness: false,
        memory_loss: false,
        facial_asymmetry: false,
        seizures: false,
        fainting_spells: false,
        tingling: false,
        tremors: false,
        confusion: false,
        speech_difficulty: false,
      }),
      patient_other_review_history: this.formBuilder.group({
        id: '',
        cold_intolerance: false,
        polyphagia: false,
        polydipsia: false,
        polyuria: false,
        skin_changes: false,
        hair_changes: false,
        heat_intolerance: false,
        joint_pain: false,
        joint_swelling: false,
        muscle_cramps: false,
        neck_pain: false,
        joint_stiffness: false,
        muscle_aches: false,
        back_pain: false,
        gait_changes: false,
        agitation: false,
        suicidal_ideation: false,
        sleep_changes: false,
        self_injury: false,
        anxiety: false,
        mood_changes: false,
        decreased_concentration: false,
        past_history: false,
        bruising: false,
        lymph_node_swelling: false,
        bleeding: false,
        discharge: false,
        redness: false,
        pain: false,
        itching: false,
        sensitivity_to_light: false,
        pain_on_urination: false,
        increased_frequency: false,
        nocturia: false,
        incontinence: false,
        vaginal_discharge: false,
        testicular_pain: false,
        blood_in_urine: false,
        urgency: false,
        flank_pain: false,
        dyspareunia: false,
        vaginal_bleeding: false,
        nausea: false,
        diarrhea: false,
        blooting: false,
        blood_in_stool: false,
        vomitting: false,
        abdominal_pain: false,
        change_in_stool_habits: false,
        rectal_pain: false,
      }),
    });
  }
  getFormData(): any {
    return this.reviewSystemHistoryForm.value;
  }
  patchReviewHistoryData(): any{
    if(this.getTemplateData){
      this.reviewSystemHistoryForm.patchValue({
        patient_ent_history_attributes : {
          id: this.getTemplateData?.id ?? '',
          vision_disturbance: this.getTemplateData?.patient_ent_history_attributes?.vision_disturbance ?? false,
          headache: this.getTemplateData?.patient_ent_history_attributes?.headache ?? false,
          visual_difdiculty: this.getTemplateData?.patient_ent_history_attributes?.visual_difdiculty ?? false,
          eye_pain:  this.getTemplateData?.patient_ent_history_attributes?.eye_pain ?? false,
          ringing: this.getTemplateData?.patient_ent_history_attributes?.ringing ?? false,
          congestion: this.getTemplateData?.patient_ent_history_attributes?.congestion ?? false,
          hearing_loss:  this.getTemplateData?.patient_ent_history_attributes?.hearing_loss ?? false,
          ear_pain:  this.getTemplateData?.patient_ent_history_attributes?.ear_pain ?? false,
          facial_swelling:  this.getTemplateData?.patient_ent_history_attributes?.facial_swelling ?? false,
        },
        patient_general_health_history_attributes : {
          id: this.getTemplateData?.patient_general_health_history_attributes?.id ?? '',
          fatigue: this.getTemplateData?.patient_general_health_history_attributes?.fatigue ?? false,
          activity_change: this.getTemplateData?.patient_general_health_history_attributes?.activity_change ?? false,
          fever:  this.getTemplateData?.patient_general_health_history_attributes?.fever ?? false,
          unexpected_weight_change: this.getTemplateData?.patient_general_health_history_attributes?.unexpected_weight_change ?? false,
          appetite_change: this.getTemplateData?.patient_general_health_history_attributes?.appetite_change ?? false,
          other:  this.getTemplateData?.patient_general_health_history_attributes?.other ?? false,
        },
        patient_cardiology_history_attributes: {
          id: this.getTemplateData?.patient_cardiology_history_attributes?.id ?? '',
          chest_pain: this.getTemplateData?.patient_cardiology_history_attributes?.chest_pain ?? false,
          edema: this.getTemplateData?.patient_cardiology_history_attributes?.edema ?? false,
          pnd: this.getTemplateData?.patient_cardiology_history_attributes?.pnd ?? false,
          palpitations: this.getTemplateData?.patient_cardiology_history_attributes?.palpitations ?? false,
          shortness_of_breath: this.getTemplateData?.patient_cardiology_history_attributes?.shortness_of_breath ?? false,
          orthopnea: this.getTemplateData?.patient_cardiology_history_attributes?.orthopnea ?? false,
        },
        patient_respiratory_history_attributes: {
          id: this.getTemplateData?.patient_respiratory_history_attributes?.id ?? '',
          cough: this.getTemplateData?.patient_respiratory_history_attributes?.cough ?? false,
          shortness_of_breath: this.getTemplateData?.patient_respiratory_history_attributes?.shortness_of_breath ?? false,
          stridor: this.getTemplateData?.patient_respiratory_history_attributes?.stridor ?? false,
          wheeze: this.getTemplateData?.patient_respiratory_history_attributes?.wheeze ?? false,
          chest_tightness: this.getTemplateData?.patient_respiratory_history_attributes?.chest_tightness ?? false,
          choking: this.getTemplateData?.patient_respiratory_history_attributes?.choking ?? false,
        },
        patient_skin_history_attributes: {
          id: this.getTemplateData?.patient_skin_history_attributes?.id ?? '',
          environmental_allergies: this.getTemplateData?.patient_skin_history_attributes?.environmental_allergies ?? false,
          recurrent_infections: this.getTemplateData?.patient_skin_history_attributes?.recurrent_infections ?? false,
          food_allergies: this.getTemplateData?.patient_skin_history_attributes?.food_allergies ?? false,
          immunodeficiency: this.getTemplateData?.patient_skin_history_attributes?.immunodeficiency ?? false,
        },
        patient_neuro_history_attributes: {
          id: this.getTemplateData?.patient_neuro_history_attributes?.id ?? '',
          dizziness: this.getTemplateData?.patient_neuro_history_attributes?.dizziness ?? false,
          numbness: this.getTemplateData?.patient_neuro_history_attributes?.numbness ?? false,
          weakness: this.getTemplateData?.patient_neuro_history_attributes?.weakness ?? false,
          memory_loss: this.getTemplateData?.patient_neuro_history_attributes?.memory_loss ?? false,
          facial_asymmetry: this.getTemplateData?.patient_neuro_history_attributes?.facial_asymmetry ?? false,
          seizures: this.getTemplateData.patient_neuro_history_attributes?.seizures ?? false,
          fainting_spells: this.getTemplateData?.patient_neuro_history_attributes?.fainting_spells ?? false,
          tingling: this.getTemplateData?.patient_neuro_history_attributes?.tingling ?? false,
          tremors: this.getTemplateData?.patient_neuro_history_attributes?.tremors ?? false,
          confusion: this.getTemplateData?.patient_neuro_history_attributes?.confusion ?? '',
          speech_difficulty: this.getTemplateData?.patient_neuro_history_attributes?.speech_difficulty ?? '',
        },
        patient_other_review_history: {
          id: this.getTemplateData?.patient_other_review_history?.id ?? '',
          cold_intolerance: this.getTemplateData?.patient_other_review_history?.cold_intolerance ?? false,
          polyphagia: this.getTemplateData?.patient_other_review_history?.polyphagia ?? false,
          polydipsia: this.getTemplateData?.patient_other_review_history?.polydipsia ?? false,
          polyuria: this.getTemplateData?.patient_other_review_history?.polyuria ?? false,
          skin_changes: this.getTemplateData?.patient_other_review_history?.skin_changes ?? false,
          hair_changes: this.getTemplateData?.patient_other_review_history?.hair_changes ?? false,
          heat_intolerance: this.getTemplateData?.patient_other_review_history?.heat_intolerance ?? false,
          joint_pain: this.getTemplateData?.patient_other_review_history?.joint_pain ?? false,
          joint_swelling: this.getTemplateData?.patient_other_review_history?.joint_swelling ?? false,
          muscle_cramps: this.getTemplateData?.patient_other_review_history?.muscle_cramps ?? false,
          neck_pain: this.getTemplateData?.patient_other_review_history?.neck_pain ?? false,
          joint_stiffness: this.getTemplateData.patient_other_review_history?.joint_stiffness ?? false,
          muscle_aches: this.getTemplateData?.patient_other_review_history?.muscle_aches ?? false,
          back_pain: this.getTemplateData?.patient_other_review_history?.back_pain ?? false,
          gait_changes: this.getTemplateData?.patient_other_review_history?.gait_changes ?? false,
          agitation: this.getTemplateData?.patient_other_review_history?.agitation ?? false,
          suicidal_ideation: this.getTemplateData?.patient_other_review_history?.suicidal_ideation ?? false,
          sleep_changes: this.getTemplateData?.patient_other_review_history?.sleep_changes ?? false,
          self_injury: this.getTemplateData?.patient_other_review_history?.self_injury ?? false,
          anxiety: this.getTemplateData?.patient_other_review_history?.anxiety ?? false,
          mood_changes: this.getTemplateData?.patient_other_review_history?.mood_changes ?? false,
          decreased_concentration: this.getTemplateData?.patient_other_review_history?.decreased_concentration ?? false,
          past_history: this.getTemplateData?.patient_other_review_history?.past_history ?? false,
          bruising: this.getTemplateData?.patient_other_review_history?.bruising ?? false,
          lymph_node_swelling: this.getTemplateData?.patient_other_review_history?.lymph_node_swelling ?? false,
          bleeding: this.getTemplateData?.patient_other_review_history?.bleeding ?? false,
          discharge: this.getTemplateData?.patient_other_review_history?.discharge ?? false,
          redness: this.getTemplateData?.patient_other_review_history?.redness ?? false,
          pain: this.getTemplateData?.patient_other_review_history?.pain ?? false,
          itching: this.getTemplateData?.patient_other_review_history?.itching ?? false,
          sensitivity_to_light: this.getTemplateData?.patient_other_review_history?.sensitivity_to_light ?? false,
          pain_on_urination: this.getTemplateData?.patient_other_review_history?.pain_on_urination ?? false,
          increased_frequency: this.getTemplateData?.patient_other_review_history?.increased_frequency ?? false,
          nocturia: this.getTemplateData?.patient_other_review_history?.nocturia ?? false,
          incontinence: this.getTemplateData?.patient_other_review_history?.incontinence ?? false,
          vaginal_discharge: this.getTemplateData?.patient_other_review_history?.vaginal_discharge ?? false,
          testicular_pain: this.getTemplateData?.patient_other_review_history?.testicular_pain ?? false,
          blood_in_urine: this.getTemplateData?.patient_other_review_history?.blood_in_urine ?? false,
          urgency: this.getTemplateData?.patient_other_review_history?.urgency ?? false,
          flank_pain: this.getTemplateData?.patient_other_review_history?.flank_pain ?? false,
          dyspareunia: this.getTemplateData?.patient_other_review_history?.dyspareunia ?? false,
          vaginal_bleeding: this.getTemplateData?.patient_other_review_history?.vaginal_bleeding ?? false,
          nausea: this.getTemplateData?.patient_other_review_history?.nausea ?? false,
          diarrhea: this.getTemplateData?.patient_other_review_history?.diarrhea ?? false,
          blooting: this.getTemplateData?.patient_other_review_history?.blooting ?? false,
          blood_in_stool: this.getTemplateData?.patient_other_review_history?.blood_in_stool ?? false,
          vomitting: this.getTemplateData?.patient_other_review_history?.vomitting ?? false,
          abdominal_pain: this.getTemplateData?.patient_other_review_history?.abdominal_pain ?? false,
          change_in_stool_habits: this.getTemplateData?.patient_other_review_history?.change_in_stool_habits ?? false,
          rectal_pain: this.getTemplateData?.patient_other_review_history?.rectal_pain ?? false,
        },
      });
    }
  }
  sendData(data): any {
      this.getTemplateData = data;
      this.patchReviewHistoryData();
  }
    ngOnInit(): void {
  }

}
