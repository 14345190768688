<div>
  <!--begin::Card-->
  <div class="card mb-4" *ngIf="isAdd === true">
    <div class="card-body user-card-body">
      <h4 class="mb-8">Operation Request</h4>

      <form
        [formGroup]="operationRequestForm"
        (ngSubmit)="onSubmitoperationRequest()"
      >
        <div class="row mb-6">
          <div class="col-lg-3 col-md-3 col-sm-4 col-12">
            <div class="form-group selectOptionBottom">
              <label> Advised by </label>
              <div
                [ngClass]="{
                  'is-invalid':
                    isSubmitoperationRequest && c.advisory_doctor.errors
                }"
              >
                <select
                  class="form-control form-control-sm"
                  id="otAdviceBy"
                  data-live-search="true"
                  formControlName="advisory_doctor"
                  (change)="fetchDoctorDepart($event.target.value)"
                >
                  <option value="">select</option>
                  <option *ngFor="let doc of oRDoctors" [value]="doc.id">
                    {{ doc?.first_name }} {{ doc?.last_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-4 col-12">
            <div class="form-group selectOptionBottom">
              <label> Department </label>
              <div
                [ngClass]="{
                  'is-invalid': isSubmitoperationRequest && c.department.errors
                }"
              >
                <select
                  class="form-control form-control-sm"
                  id="otDepartment"
                  data-live-search="true"
                  formControlName="department"
                  (change)="fetchDepartDoctors($event.target.value)"
                >
                  <option value="">select</option>
                  <option *ngFor="let dep of department" [value]="dep.id">
                    {{ dep.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-4 col-12">
            <div class="form-group selectOptionBottom">
              <label> Operation Name </label>
              <div
                [ngClass]="{
                  'is-invalid': isSubmitoperationRequest && c.ot_service.errors
                }"
              >
                <select
                  class="form-control form-control-sm"
                  id="otServiceName"
                  data-live-search="true"
                  formControlName="ot_service"
                >
                  <option value="">select</option>
                  <option
                    *ngFor="let service of otServices"
                    [value]="service.id"
                  >
                    {{ service.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-4 col-12">
            <div class="form-group selectOptionBottom">
              <label> Operation Room </label>
              <div>
                <select
                  class="form-control form-control-sm"
                  id=""
                  data-live-search="true"
                  formControlName="operation_room"
                >
                  <option value="">select</option>
                  <option [value]="or.id" *ngFor="let or of operationRooms">
                    {{ or.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-4 col-12">
            <div class="form-group">
              <label> Anesthesia Type </label>
              <select
                class="form-control form-control-sm"
                data-live-search="true"
                formControlName="anesthesia_type_id"
                [ngClass]="{
                  'is-invalid':
                    isSubmitoperationRequest && c.anesthesia_type_id.errors
                }"
              >
                <option value="">select</option>
                <option
                  *ngFor="let anesthesia of anesthesiaTypes"
                  [value]="anesthesia.id"
                >
                  {{ anesthesia.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-4 col-12">
            <div class="form-group">
              <label>Suggested Date</label>
              <input
                type="date"
                class="form-control form-control-sm"
                min="{{ currentDate | date: 'yyyy-MM-dd' }}"
                formControlName="suggested_date"
              />
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-4 col-12">
            <div class="form-group">
              <label>Suggested Time</label>
              <input
                type="time"
                class="form-control form-control-sm"
                formControlName="suggested_time"
                [ngClass]="{
                  'is-invalid':
                    isSubmitoperationRequest && c.suggested_time.errors
                }"
              />
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-4 col-12">
            <div class="form-group">
              <label>Estimated Duration</label>

              <div style="display: flex !important">
                <div class="col-6 pl-0">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="est_dur_value"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitoperationRequest && c.est_dur_value.errors
                    }"
                  />
                </div>
                <div class="col-6 pr-0">
                  <select
                    class="form-control form-control-sm"
                    formControlName="est_dur_type_id"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitoperationRequest && c.est_dur_type_id.errors
                    }"
                  >
                    <option value="">Select</option>
                    <option
                      *ngFor="let duration of durations"
                      [value]="duration.id"
                    >
                      {{ duration.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-4 col-12">
            <div class="form-group">
              <label>Urgency</label>
              <select
                class="form-control form-control-sm"
                formControlName="urgency_id"
                [ngClass]="{
                  'is-invalid': isSubmitoperationRequest && c.urgency_id.errors
                }"
              >
                <option value="">Select</option>
                <option *ngFor="let urgency of urgency" [value]="urgency.id">
                  {{ urgency.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-8">
            <div class="form-group">
              <label>Comments</label>
              <input
                class="form-control form-control-sm"
                formControlName="comments"
                [ngClass]="{
                  'is-invalid': isSubmitoperationRequest && c.comments.errors
                }"
              />
            </div>
          </div>
        </div>

        <div class="row m-0">
          <div class="text-left">
            <div class="otherComponent">
              <div>
                <app-upload-images
                  [documentType]="'document'"
                  [isUploadImage]="true"
                  (imageData)="sendImages($event)"
                ></app-upload-images>
              </div>
            </div>
            <!--            <span class="btn btn-primary btn-sm" (click)="uploadDocument()">Upload Document</span>-->
          </div>
          <div class="col text-right">
            <button
              type="button"
              (click)="resetOperationRequestForm()"
              class="btn btn-primary btn-sm"
            >
              Clear
            </button>
            <button type="submit" class="btn btn-primary btn-sm ml-2">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="card mb-4" *ngIf="!isAdd || allOperationsRequests.length > 0">
    <div class="card-body" style="padding: 1.5rem 1rem 0rem 1rem !important">
      <ng-container *ngFor="let operations of allOperationsRequests">
        <div class="row m-0 mb-3">
          <div class="col-md-3 col-sm-3 col-6">
            <div class="font-weight-bold">Procedure Name:</div>
            <div class="font-weight-bold">Department:</div>
            <div
              *ngIf="operations?.anesthesia_type.name"
              class="font-weight-bold"
            >
              Anesthesia Type:
            </div>
            <div class="font-weight-bold">Advised By:</div>
          </div>
          <div class="col-md-3 col-sm-3 col-6 pl-0">
            <div>{{ " " + operations?.ot_service?.name }}</div>
            <div>{{ " " + operations?.department?.title }}</div>
            <div *ngIf="operations?.anesthesia_type.name">
              {{ " " + operations?.anesthesia_type?.name }}
            </div>
            <div>{{ " " + operations?.advisory_doctor?.full_name }}</div>
          </div>
          <div class="col-md-3 col-sm-3 col-6">
            <div class="font-weight-bold">
              <span *ngIf="operations?.status_type === 'requested'"
                >Suggested Date:</span
              >
              <span *ngIf="operations?.status_type === 'approved'"
                >Approved Date:</span
              >
            </div>
            <div class="font-weight-bold">
              <span *ngIf="operations?.status_type === 'requested'"
                >Suggested Time:</span
              >
              <span *ngIf="operations?.status_type === 'approved'"
                >Approved Time:</span
              >
            </div>
            <div *ngIf="operations?.urgency?.name" class="font-weight-bold">
              Urgency:
            </div>
            <div class="font-weight-bold">Status:</div>
          </div>
          <div class="col-md-3 col-sm-3 col-6 pl-0">
            <div>
              <span *ngIf="operations?.status_type === 'requested'">{{
                " " + operations?.suggested_date | date: "dd-MM-yyyy"
              }}</span>
              <span *ngIf="operations?.status_type === 'approved'">{{
                " " + operations?.approved_date
              }}</span>
            </div>
            <div>
              <span *ngIf="operations?.status_type === 'requested'">{{
                " " + operations?.suggested_time | date: "shortTime"
              }}</span>
              <span *ngIf="operations?.status_type === 'approved'">{{
                " " + operations?.approved_time
              }}</span>
            </div>
            <div *ngIf="operations?.urgency?.name">
              {{ " " + operations?.urgency?.name }}
            </div>
            <div>{{ " " + operations?.status_type | titlecase }}</div>
          </div>
          <div class="col-md-3 col-sm-3 col-6" *ngIf="operations?.comments">
            <div class="font-weight-bold">Comments:</div>
          </div>
          <div
            class="col-md-9 col-sm-9 col-6 pl-0"
            *ngIf="operations?.comments"
          >
            <div>{{ " " + operations?.comments }}</div>
          </div>
          <div class="col-12 mt-3 text-right">
            <button
              class="btn btn-primary btn-sm"
              (click)="printPatientoperationRequest(operations.id)"
            >
              Print
            </button>
            <button
              *ngIf="
                operations?.approved === false &&
                operations?.status_type !== 'cancelled' &&
                isAdd === true
              "
              class="btn btn-primary btn-sm ml-2"
              (click)="cancelPatientoperationRequest(operations.id)"
            >
              Cancel Request
            </button>
          </div>
        </div>

        <hr *ngIf="allOperationsRequests.length > 1" />
      </ng-container>
    </div>
  </div>
  <!--end::Card-->
</div>

<!--cancel Modal-->
<div class="example-preview">
  <div>
    <ng-template #cancelOperationRequest let-c="close" let-d="dismiss">
      <div class="">
        <form>
          <div class="modal-header p-3">
            <h4 class="modal-title">Confirmation</h4>
            <a
              type="button"
              class="close"
              aria-label="Close"
              (click)="d('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <div class="modal-body">
            <div>Are you sure you want to cancel the request?<br /></div>
          </div>
          <div class="modal-footer p-3">
            <a
              type="button"
              class="btn btn-sm btn-primary"
              (click)="c('Close click')"
              >Close</a
            >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              (submit)="cancelRequest()"
              (click)="cancelRequest()"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>
