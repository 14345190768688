import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class PatientAdmissionService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/admissions?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=Draft',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchClaimListing(
    page,
    per_page,
    from_date,
    to_date,
    company_id,
    admission_id,
    status,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/admissions/claim_admission_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&from_date=' +
          from_date +
          '&to_date=' +
          to_date +
          '&company_id=' +
          company_id +
          '&admission_id=' +
          admission_id +
          '&claimable_status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  indexV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/admissions/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=Draft',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  create(admission, admissionRequestId, signature): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/admissions?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          admission.referred_from +
          '&ward_id=' +
          admission.ward_id +
          '&patient_id=' +
          admission.patient_id +
          '&doctor_id=' +
          admission.doctor_id +
          '&admission_orders=' +
          admission.admission_orders,
        {
          admission,
          reservation_id: admissionRequestId,
          signature_url: signature,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  paymentReceived(admission_ids, payment_detail): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/admissions/claim_unpaid_services_to_paid?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          admission_ids,
          payment_detail,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createV2(admission, admissionRequestId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/admission_create/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          admission.referred_from +
          '&ward_id=' +
          admission.ward_id +
          '&patient_id=' +
          admission.patient_id +
          '&doctor_id=' +
          admission.doctor_id,
        {
          admission,
          reservation_id: admissionRequestId,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createAdmissionRequest(admission): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/reservations?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          admission.referred_from +
          '&ward_id=' +
          admission.ward_id +
          '&patient_id=' +
          admission.patient_id +
          '&doctor_id=' +
          admission.doctor_id +
          '&admission_orders=' +
          admission.admission_orders,
        {
          admission,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createAdmissionRequestV2(admission): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/admission_create/reservations/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          admission.referred_from +
          '&ward_id=' +
          admission.ward_id +
          '&patient_id=' +
          admission.patient_id +
          '&doctor_id=' +
          admission.doctor_id +
          '&admission_orders=' +
          admission.admission_orders,
        {
          admission,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  update(admission, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/admissions/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          admission.referred_from +
          '&ward_id=' +
          admission.ward_id +
          '&patient_id=' +
          admission.patient_id +
          '&doctor_id=' +
          admission.doctor_id,
        {
          admission,
          bed_id: admission.bed_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateV2(admission, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_admission/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          admission.referred_from +
          '&ward_id=' +
          admission.ward_id +
          '&patient_id=' +
          admission.patient_id +
          '&doctor_id=' +
          admission.doctor_id,
        {
          admission,
          bed_id: admission.bed_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  changePatientBed(patient, changeWardData): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/admissions/` +
          patient.admissions[0].id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&ward_id=' +
          changeWardData.wardId +
          '&patient_id=' +
          patient.id +
          '&bed_id=' +
          changeWardData.bedId +
          '&condition=' +
          changeWardData.condition +
          '&comments=' +
          changeWardData.comments +
          '&informing_dr=' +
          changeWardData.informing_dr +
          '&via=' +
          changeWardData.via +
          '&response=' +
          changeWardData.response,
        {},
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  changePatientBedV2(patient, changeWardData): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_admission/` +
          patient.admissions[0].id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&ward_id=' +
          changeWardData.wardId +
          '&patient_id=' +
          patient.id +
          '&bed_id=' +
          changeWardData.bedId +
          '&condition=' +
          changeWardData.condition +
          '&comments=' +
          changeWardData.comments +
          '&informing_dr=' +
          changeWardData.informing_dr +
          '&via=' +
          changeWardData.via +
          '&response=' +
          changeWardData.response,
        {},
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  changePatientJustBed(patient, bedId): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/admissions/` +
          patient.admissions[0].id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&ward_id=' +
          patient.admissions[0].ward_id +
          '&patient_id=' +
          patient.id +
          '&bed_id=' +
          bedId,
        {},
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  changePatientJustBedInIPD(patient, bedId): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_admission/` +
          patient.admissions[0].id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&ward_id=' +
          patient.admissions[0].ward_id +
          '&patient_id=' +
          patient.id +
          '&bed_id=' +
          bedId,
        {},
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  discharge(mrn, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v3/discharge_patients?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: mrn,
          admission_id: id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDischargeFromIpd(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v3/discharge_patients/get_discharge_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission_id=' +
          patient.admissions[0].id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDischargeFromIpdV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/discharge_patients/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission_id=' +
          patient,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDischargeFromIpdReport(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/discharge_report_print/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission_id=' +
          patient,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientAdmissionDischargeData(patientId): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/admissions/` +
          patientId +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission_id=' +
          patientId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  dischargeFromIpd(dischargePatient, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v3/discharge_patients?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patient.mrn,
          discharge_patient_id: patient.admissions[0].discharge_patient_id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          discharge_patient: dischargePatient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  dischargeFromIpdV2(dischargePatient, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/discharge_patients/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patient.mrn,
          discharge_patient_id: patient.admissions[0].discharge_patient_id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          discharge_patient: dischargePatient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  dischargePatientFromIpdV2(dischargePatient, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/discharge_patients/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patient.mrn,
          discharge_patient_id: patient.admissions[0].discharge_patient_id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          discharge_patient: dischargePatient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  savePresentingComplaintByAdmission(
    patient_complaint,
    patient,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_complaints.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_complaint,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatePresentingComplaintByAdmission(
    patient_complaint,
    patient,
    patient_complaint_id,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_complaints/` +
          patient_complaint_id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_complaint,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  savePatientAllergy(allergy, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` + patient.mrn + `/allergies.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          allergy,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  saveGeneralHistoryByAdmission(
    patient_general_history,
    patient,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_general_histories.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_general_history,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  saveGeneralHistoryByAdmissionV2(
    patient_general_history,
    patient,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/patient_general_histories/` +
          patient.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_general_history,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatesGeneralHistoryByAdmission(
    patient_general_history,
    patient,
    admission_history_id,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_general_histories/` +
          admission_history_id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_general_history,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createPatientCentralNervousSystemByAdmission(
    central_nervous_system,
    patient,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/central_nervous_systems.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          central_nervous_system,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createPatientCentralNervousSystemByAdmissionV2(
    central_nervous_system,
    patient,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/central_nervous_systems/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          central_nervous_system,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatePatientCentralNervousSystemByAdmission(
    central_nervous_system,
    patient,
    central_nervous_system_id,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/central_nervous_systems/` +
          central_nervous_system_id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          central_nervous_system,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchPatientComplaintsByAdmission(patient): Observable<any> {
    let admissonId = '';
    if (patient.admissions[0]) {
      admissonId = patient.admissions[0].id;
    }
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_complaints/patient_complaints_by_admission?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission_id=' +
          admissonId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchPatientComplaintsByAdmissionV2(patient): Observable<any> {
    let admissonId = '';
    if (patient.admissions[0]) {
      admissonId = patient.admissions[0].id;
    }
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/patient_complaints_by_admission/` +
          patient.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission_id=' +
          admissonId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  savePatientHistorySourceByAdmission(
    patient_history_source,
    patient,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_history_sources.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_history_source,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  savePatientHistorySourceByAdmissionV2(
    patient_history_source,
    patient,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/patient_history_sources/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_history_source,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatePatientHistorySourceByAdmission(
    patient_history_source,
    patient,
    patient_history_source_id,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_history_sources/` +
          patient_history_source_id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_history_source,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatePatientHistorySourceByAdmissionV2(
    patient_history_source,
    patient,
    patient_history_source_id,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/ipd/patient_history_sources/` +
          patient.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          patient_history_source_id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_history_source,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchPatientHistorySourcesByAdmission(patient): Observable<any> {
    let admissonId = '';
    if (patient.admissions[0]) {
      admissonId = patient.admissions[0].id;
    }
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_history_sources?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission_id=' +
          admissonId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchPatientHistorySourcesByAdmissionV2(patient): Observable<any> {
    let admissonId = '';
    if (patient.admissions[0]) {
      admissonId = patient.admissions[0].id;
    }
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/patient_history_sources/` +
          patient.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission_id=' +
          admissonId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientReturnToWardFun(patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v3/discharge_patients/return_discharged_patient?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patient.mrn,
          admission_id: patient.admissions[0].id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientReturnToWardFunV2(patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/return_discharged_patient/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patient.mrn,
          admission_id: patient.admissions[0].id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientHoldFunction(patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/admissions/` +
          patient.admissions[0].id +
          '/hold_admission?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&is_hold=true',
        {
          patient_id: patient.mrn,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientHoldFunctionV2(patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/hold_admission/` +
          patient.admissions[0].id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&is_hold=true',
        {
          patient_id: patient.mrn,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
