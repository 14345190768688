<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div
    class="subheader py-2 py-lg-6 subheader-solid d-print-none"
    id="kt_subheader"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-baseline justify-content-between flex-wrap mr-5 w-100"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <div class="dropdown dropdown-inline mr-2">
            <button class="btn btn-light-primary ml-3" (click)="printReport()">
              Print
            </button>
            <!--end::Dropdown Menu-->
          </div>
          <!--          <button class="btn btn-sm btn-primary" [routerLink]="['/roster/shifts-detail']"><i class="fas fa-long-arrow-alt-left"></i> Back</button>-->
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::Card-->
      <div class="card card-custom gutter-b d-print-none">
        <div class="card-body">
          <div class="row roster-bar">
            <!--Select Month-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Select Date <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  name="date"
                  autocomplete="off"
                  id="reportDate"
                  placeholder="Select Date"
                  [(ngModel)]="month"
                />
              </div>
            </div>
            <!--Department-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="d-block form-group">
                <label>Department:<span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  multiple
                  id="department"
                  name="department"
                  required="required"
                  (change)="getEmployeesOfDepartment($event.target.value)"
                  [(ngModel)]="department"
                >
                  <option value="" disabled>Select Department</option>
                  <ng-container *ngFor="let dep of depart">
                    <option [value]="dep.id">{{ dep.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--Employee Of Department-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="d-block form-group">
                <label>Employee:</label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  multiple
                  id="employeeDepartmentList"
                  name="employee"
                  [(ngModel)]="employeesOfSelectedDepartment"
                >
                  <option value="" [disabled]="true">Select Employee</option>
                  <ng-container *ngFor="let emp of employeesOfDepart">
                    <option [value]="emp.id">
                      {{ emp?.first_name }} {{ emp?.last_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--Location-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="d-block form-group">
                <label>Location:</label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  multiple
                  id="selectedLocation"
                  name="selectedLocation"
                  [(ngModel)]="selectedLocation"
                >
                  <option value="">Select Location</option>
                  <ng-container *ngFor="let location of locationData">
                    <option [value]="location.id">{{ location?.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="d-block form-group">
                <button
                  class="btn btn-light-primary btn-sm"
                  (click)="appendArray()"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <!--shifts-->
        </div>
      </div>
      <!--end::Card-->
      <div class="card card-custom gutter-b" *ngIf="employeeRoasterData.length">
        <div class="card-body">
          <!--        <div *ngIf="list?.length > 0">-->
          <div class="mb-6 d-none d-print-block">
            <div class="d-flex">
              <div style="width: 85%">
                <!-- header table -->
                <table class="table">
                  <tbody>
                    <tr>
                      <td style="width: 15%; padding: 0" class="border-top-0">
                        <img
                          [src]="settingData?.logo?.url"
                          style="width: auto; height: 106px"
                        />
                      </td>
                      <td style="width: 85%" class="border-top-0">
                        <div class="text-center">
                          <h3>{{ settingData?.title }}</h3>
                        </div>
                        <div class="font-size-h5 text-center">
                          <h5>{{ settingData?.address }}</h5>
                        </div>
                        <div class="text-center">
                          <h3>
                            Attendance Report Of
                            {{
                              employeeRoasterData[0]?.employee?.department
                                ?.title
                            }}
                            of {{ employeeRoasterData[0]?.date }}
                          </h3>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="table-responsive position-relative print-table">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 12%">Name</th>
                  <th style="width: 12%">Date</th>
                  <th style="width: 15%">Shift</th>
                  <th style="width: 15%">Check In</th>
                  <th style="width: 12%">Check In Status</th>
                  <th style="width: 14%">Check Out</th>
                  <th style="width: 12%">Check Out Status</th>
                  <th style="width: 8%">Status</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let data of employeeRoasterData; let i = index"
                >
                  <ng-container *ngFor="let subData of data?.attendance">
                    <tr>
                      <td>
                        <span style="word-break: break-word !important">{{
                          data?.employee?.first_name +
                            " " +
                            data?.employee?.middle_name +
                            " " +
                            data?.employee?.last_name
                        }}</span>
                      </td>
                      <td>{{ data?.date }}</td>
                      <td>{{ subData?.shift?.name }}</td>
                      <td>
                        {{ subData?.check_in_time }} <br /><span
                          style="font-size: 11px"
                          >Expected: {{ subData?.shift?.time_from }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="label label-lg label-primary label-inline"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_in_color"
                          *ngIf="subData?.check_in_status === 1"
                          >On Time</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_in_color"
                          *ngIf="subData?.check_in_status === 2"
                          >Early In</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_in_color"
                          *ngIf="subData?.check_in_status === 3"
                          >Late In</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_in_color"
                          *ngIf="subData?.check_in_status === 4"
                          >Absent</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_in_color"
                          *ngIf="subData?.check_in_status === 5"
                          >Pending</span
                        >
                      </td>
                      <td>
                        {{ subData?.check_out_time }} <br /><span
                          style="font-size: 11px"
                          >Expected: {{ subData?.shift?.time_to }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_out_color"
                          *ngIf="subData?.check_out_status === 1"
                          >On Time</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_out_color"
                          *ngIf="subData?.check_out_status === 2"
                          >Late Out</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_out_color"
                          *ngIf="subData?.check_out_status === 3"
                          >Early Out</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_out_color"
                          *ngIf="subData?.check_out_status === 4"
                          >Absent</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.check_out_color"
                          *ngIf="subData?.check_out_status === 5"
                          >Pending</span
                        >
                      </td>
                      <td>
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.color"
                          *ngIf="subData?.status === 1"
                          >P</span
                        >
                        <span
                          class="label label-lg label-inline label-primary"
                          style="color: #ffffff"
                          [style.background-color]="subData?.color"
                          *ngIf="subData?.status === 0"
                          >A</span
                        >
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
            <!--            <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal-->
<div
  class="modal fade"
  id="promissionsAlertModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <img src="../../../assets/media/error/allert.png" />
          <p class="font-weight-bold font-size-h5 text-dark-75 mt-3">
            No Record Found!
          </p>
          <!--          <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>-->
        </div>
      </div>
    </div>
  </div>
</div>
