<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Lab PDF Report</h5>
          <!--          xx  tab headings-->

          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-light-primary btn-sm" (click)="printReport()">
          Print
        </button>
        <!-- <button class="btn btn-primary btn-sm ml-2" (click)="goBack()">Back</button> -->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="card pdf-card" id="reports">
        <div class="card-body" style="padding-bottom: 0px">
          <div class="d-flex">
            <div style="width: 85%">
              <!-- header table -->
              <table class="table">
                <tbody>
                  <tr class="borders">
                    <td style="width: 15%; padding: 0">
                      <img
                        [src]="settingData?.logo?.url"
                        style="width: auto; height: 106px"
                      />
                    </td>
                    <td style="width: 85%">
                      <div class="text-center">
                        <h3 class="hosp-name">{{ settingData?.title }}</h3>
                      </div>
                      <div class="font-size-h5 text-center">
                        <h5 class="hosp-address">{{ settingData?.address }}</h5>
                      </div>
                      <div class="text-center">
                        <h3>
                          INVOICE <br />
                          <span *ngIf="patientServiceInvoicesData?.duplicate"
                            >(DUPLICATE)</span
                          >
                        </h3>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- patient info table -->
              <table class="w-100 info_table mb-2">
                <tbody>
                  <tr>
                    <td style="width: 45%">
                      <h2
                        *ngIf="patientServiceInvoicesData?.patient"
                        class="m-0 line-clamp font-print-bolder mr-5"
                      >
                        {{
                          patientServiceInvoicesData?.patient?.title | titlecase
                        }}
                        {{
                          patientServiceInvoicesData?.patient?.full_name
                            | titlecase
                        }}
                        {{ patientServiceInvoicesData?.patient?.age + "," }}
                        {{
                          patientServiceInvoicesData?.patient?.gender.charAt(0)
                            | titlecase
                        }}
                      </h2>
                    </td>
                    <td style="width: 55%">
                      <h2 class="m-0 font-print-bolder">
                        MRN#{{ patientServiceInvoicesData?.patient?.mrn }}
                      </h2>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- patient info detail table -->
              <table class="w-100 info_table">
                <tbody>
                  <tr>
                    <td style="width: 20%"><strong>Father/Husband:</strong></td>
                    <td style="width: 20%">
                      <span
                        *ngIf="patientServiceInvoicesData?.patient?.father_name"
                        >{{
                          patientServiceInvoicesData?.patient?.father_name
                            | titlecase
                        }}</span
                      >
                      <span
                        *ngIf="
                          !patientServiceInvoicesData?.patient?.father_name
                        "
                        >N/A</span
                      >
                    </td>
                    <td style="width: 5%"></td>
                    <td style="width: 15%"><strong>Patient Type:</strong></td>
                    <td style="width: 30%">
                      {{
                        patientServiceInvoicesData?.patient_type?.name || "N/A"
                      }}
                    </td>
                    <td style="width: 10%"></td>
                  </tr>
                  <tr>
                    <td style="width: 20%"><strong>Contact:</strong></td>
                    <td style="width: 20%">
                      {{ patientServiceInvoicesData?.patient?.phone1 }}
                    </td>
                    <td style="width: 5%"></td>
                    <td style="width: 15%"><strong>Reg. Date:</strong></td>
                    <td style="width: 30%">
                      {{
                        patientServiceInvoicesData?.patient?.created_at
                          | date: "dd-MM-yyyy h:mm: a"
                      }}
                    </td>
                    <td style="width: 10%"></td>
                  </tr>
                  <tr>
                    <td style="width: 20%"><strong>CNIC:</strong></td>
                    <td style="width: 20%">
                      {{
                        patientServiceInvoicesData?.patient?.patient_nic ||
                          "N/A"
                      }}
                    </td>
                    <td style="width: 5%"></td>
                    <td style="width: 15%"><strong>Visit Date:</strong></td>
                    <td style="width: 30%">
                      {{
                        patientServiceInvoicesData?.visit?.created_at
                          | date: "dd-MM-yyyy h:mm a"
                      }}
                    </td>
                    <td style="width: 10%"></td>
                  </tr>
                  <tr>
                    <td style="width: 20%"><strong>Address:</strong></td>
                    <td
                      style="width: 20%"
                      *ngIf="patientServiceInvoicesData?.patient?.address1"
                    >
                      {{ patientServiceInvoicesData?.patient?.address1 }}
                    </td>
                    <td
                      style="width: 20%"
                      *ngIf="!patientServiceInvoicesData?.patient?.address1"
                    >
                      N/A
                    </td>
                    <td style="width: 5%"></td>
                    <td style="width: 15%">
                      <strong
                        *ngIf="hospitalTitle === 'cloudSetting'; else InvoiceNo"
                        >Rcp. #:</strong
                      >
                      <ng-template #InvoiceNo>
                        <strong> Invoice # </strong>
                      </ng-template>
                    </td>
                    <td style="width: 30%">
                      {{ patientServiceInvoicesData?.id }}
                    </td>
                    <td style="width: 10%"></td>
                  </tr>
                  <!--                <tr *ngIf="patientServiceInvoicesData?.ward_name">-->
                  <!--                  <td style="width: 15%;display: contents;"><strong>Ward:</strong></td>-->
                  <!--                  <td style="width: 20%">{{ patientServiceInvoicesData?.ward_name }}</td>-->
                  <!--                  <td style="width: 10%"></td>-->
                  <!--                  <td style="width: 15%"></td>-->
                  <!--                  <td style="width: 20%"></td>-->
                  <!--                  <td style="width: 20%"></td>-->
                  <!--                </tr>-->
                  <tr>
                    <td style="width: 20%">
                      <span *ngIf="patientServiceInvoicesData?.admission_id"
                        ><strong>Admission Id:</strong></span
                      >
                    </td>
                    <td style="width: 20%">
                      <span *ngIf="patientServiceInvoicesData?.admission_id">{{
                        patientServiceInvoicesData?.admission_id
                      }}</span>
                    </td>
                    <td style="width: 5%"></td>
                    <td style="width: 15%">
                      <span *ngIf="patientServiceInvoicesData?.ward_name"
                        ><strong>Ward:</strong></span
                      >
                    </td>
                    <td style="width: 30%">
                      <span *ngIf="patientServiceInvoicesData?.ward_name">{{
                        patientServiceInvoicesData?.ward_name
                      }}</span>
                    </td>
                    <td style="width: 10%"></td>
                  </tr>
                  <tr>
                    <td style="width: 20%">
                      <span><strong>Category:</strong></span>
                    </td>
                    <td style="width: 20%">
                      <span *ngIf="patientServiceInvoicesData?.admission_id"
                        >IPD</span
                      >
                      <span *ngIf="!patientServiceInvoicesData?.admission_id"
                        >OPD</span
                      >
                    </td>
                    <td style="width: 5%"></td>
                    <td style="width: 15%">
                      <strong
                        class="d-flex"
                        *ngIf="
                          patientServiceInvoicesData?.inv_number &&
                          hospitalTitle === 'cloudSetting'
                        "
                        >Document ID:</strong
                      >
                      <span *ngIf="patientServiceInvoicesData?.clinic_name"
                        ><strong>Clinic:</strong></span
                      >
                    </td>
                    <td style="width: 30%">
                      <span
                        class="d-flex"
                        *ngIf="
                          patientServiceInvoicesData?.inv_number &&
                          hospitalTitle === 'cloudSetting'
                        "
                        >{{ patientServiceInvoicesData?.inv_number }}</span
                      >
                      <span *ngIf="patientServiceInvoicesData?.clinic_name">{{
                        patientServiceInvoicesData?.clinic_name
                      }}</span>
                    </td>
                    <td style="width: 10%"></td>
                  </tr>
                  <tr>
                    <td style="width: 20%">
                      <span
                        *ngIf="patientServiceInvoicesData?.consultant !== 'N/A'"
                        ><strong>Consultant:</strong></span
                      >
                    </td>
                    <td style="width: 20%">
                      <span
                        *ngIf="patientServiceInvoicesData?.consultant !== 'N/A'"
                        >{{ patientServiceInvoicesData?.consultant }}</span
                      >
                    </td>
                    <td style="width: 5%"></td>
                    <td style="width: 15%"></td>
                    <td style="width: 30%"></td>
                    <td style="width: 10%"></td>
                  </tr>
                  <!--                <tr *ngIf="patientServiceInvoicesData?.ward_name">-->
                  <!--                  <td style="width: 15%;display: contents;"><strong>Ward:</strong></td>-->
                  <!--                  <td style="width: 20%">{{ patientServiceInvoicesData?.ward_name }}</td>-->
                  <!--                  <td style="width: 10%"></td>-->
                  <!--                  <td style="width: 15%"></td>-->
                  <!--                  <td style="width: 20%"></td>-->
                  <!--                  <td style="width: 20%"></td>-->
                  <!--                </tr>-->
                </tbody>
              </table>
            </div>

            <div style="width: 15%">
              <div style="width: 100%; height: 180px">
                <img
                  class="qrCode"
                  style="height: 100%; width: 100%"
                  *ngIf="patientServiceInvoicesData?.patient?.qrcode_url"
                  [src]="patientServiceInvoicesData?.patient?.qrcode_url"
                  alt=""
                />
              </div>
              <!--                <img src="../assets/media/pdf-images/qr.png" alt="" style="max-height: 100px">-->
              <img
                class="brCode"
                *ngIf="patientServiceInvoicesData?.patient?.barcode_url"
                [src]="patientServiceInvoicesData?.patient?.barcode_url"
                alt=""
              />
              <div class="font-weight-bolder font-size-h3 mrnCode" id="mrn">
                {{ patientServiceInvoicesData?.patient?.mrn }}
              </div>
            </div>
          </div>

          <!--          <hr class="my-6 line">-->
          <!--          <div class="clearfix" style="border-top: 3px  #000000;height: 15px;width: 100%"></div>-->
          <div
            class="clearfix"
            style="border-top: 3px solid #000000; height: 15px; width: 100%"
          ></div>

          <div class="d-flex justify-content-between">
            <h2>Hemotology Report</h2>
            <p style="padding-right: 200px">
              Reporting time: 23 August, 2022 - 10:42 AM
            </p>
          </div>
          <div
            class="clearfix"
            style="border-top: 3px solid #000000; height: 15px; width: 100%"
          ></div>
          <div>
            <h2>C /E (Complete , CBC)</h2>
          </div>
          <table style="width: 100%">
            <tr
              style="
                border-bottom: 1px solid black;
                border-top: 2px solid black;
              "
            >
              <th style="border-right: 1px solid">Test</th>

              <th style="border-right: 1px solid">Unit</th>

              <th style="border-right: 1px solid">Reference Value</th>

              <th style="border-right: 1px solid">
                Result<br />
                23 Aug, 22 10:33 AM
              </th>
              <th>Previous Result</th>
            </tr>
            <tr>
              <td>RBC Count</td>
              <td>x 10^12/L</td>
              <td>sdfsdfs sdf sdds f ds dsf</td>
              <td style="color: #ff0500">12</td>
            </tr>
            <tr style="background-color: #efeeee">
              <td>WBC Count</td>
              <td>10^9/L</td>
              <td>4 - 11</td>
              <td>5</td>
              <td></td>
            </tr>
            <tr>
              <td>Hb (Haemoglobin)</td>
              <td>g/dl</td>
              <td>13.5 - 17.5</td>
              <td style="color: #ff0500">22</td>
            </tr>
            <tr style="background-color: #efeeee">
              <td>Range test111111</td>
              <td>IU/ml</td>
              <td>= 10</td>
              <td style="color: #ff0500">10</td>
              <td></td>
            </tr>
            <tr>
              <td colspan="5">
                <h3 style="background-color: #c2c0c0">CBC</h3>
              </td>
            </tr>
            <tr style="background-color: #efeeee">
              <td>PCV</td>
              <td>%</td>
              <td>40 - 52</td>
              <td style="color: #ff0500">60</td>
              <td></td>
            </tr>
            <tr>
              <td>MCV</td>
              <td>fl</td>
              <td>80 - 98</td>
              <td>85</td>
            </tr>
            <tr>
              <td colspan="5">
                <h3 style="background-color: #c2c0c0">RBC Morphology</h3>
              </td>
            </tr>
            <tr>
              <td>NOTE</td>
              <td>test notes</td>
            </tr>
          </table>
          <div class="d-flex" style="padding-top: 30px">
            <h3>Pathologist Remarks:</h3>
            <p style="margin-left: 10px">test remarks</p>
          </div>
          <div>
            <div class="d-flex" style="width: 100%">
              <p style="width: 50%">
                <u
                  >Note: Lab values should be corelated with clinical
                  picture.</u
                >
              </p>
              <p class="d-flex justify-content-around" style="width: 50%">
                Sufi Muzamal Hussain<br />
                Professor
              </p>
            </div>
            <div style="text-align: center">
              <p>
                <strong
                  >Electronically delivered, No signature(s) required</strong
                >
              </p>
            </div>
            <div style="text-align: center">
              <table style="width: 100%">
                <tr style="border-top: 2px solid #343434">
                  <th style="border-right: 1px solid; width: 20%">
                    Prof. Dr. Khalid Mehmood
                  </th>
                  <th style="border-right: 1px solid; width: 20%">iram</th>
                  <th style="border-right: 1px solid; width: 20%">Eitsam</th>
                  <th style="border-right: 1px solid; width: 20%">
                    Syed Ali Usmani
                  </th>
                  <th style="width: 20%">Naveed S</th>
                </tr>
                <tr style="border-bottom: 2px solid #363535">
                  <td style="border-right: 1px solid">
                    MBBS, M.Phil, PHD<br />
                    (Microbiology)<br />
                    Professor of Pathology
                  </td>
                  <td style="border-right: 1px solid">
                    msc<br />
                    Head of Department<br />
                    Head of Department
                  </td>
                  <td style="border-right: 1px solid">
                    PHD<br />SAP<br />Head of Department
                  </td>
                  <td style="border-right: 1px solid">
                    BSCS<br />
                    CCNA<br />
                    Professor
                  </td>
                  <td>
                    BSCS<br />
                    Degree<br />
                    it assistant
                  </td>
                </tr>
              </table>
              <div style="text-align: center">
                <div><strong>Ali Usmani</strong></div>
                <div>PHD</div>
                <div style="">SAP</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
