<div>
  <!--begin::Card-->

  <div
    [ngClass]="{ 'mb-4': isAdd === true, 'mb-0': isAdd === false }"
    *ngIf="isAdd === true"
    class="card"
  >
    <div class="card-body user-card-body">
      <h4 class="margin-bottom-10">Doctor Visit</h4>

      <form
        [formGroup]="consultationForm"
        (ngSubmit)="onSubmitConsultations('')"
      >
        <div class="row m-0">
          <div class="col-lg-3 col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label> Doctor </label>
              <div [ngClass]="{ 'is-invalid': isSubmit && f.doctor_id.errors }">
                <select
                  class="form-control form-control-sm doctorSelectpicker pickers"
                  id="ipdConsultingdoctor"
                  data-live-search="true"
                  formControlName="doctor_id"
                  (change)="fetchDoctorDepartments($event.target.value)"
                >
                  <option value="">select</option>
                  <option *ngFor="let doc of doctor" [value]="doc.id">
                    {{ doc.first_name }} {{ doc.last_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-3 col-sm-6 col-12 pl-4 pl-sm-4 pl-md-0 pl-lg-0"
          >
            <div class="form-group">
              <label> Department </label>
              <select
                class="form-control form-control-sm departSelectpicker bootstrap-select-container pickers"
                id="ipdConsultingDepartment"
                data-live-search="true"
                formControlName="department_id"
                [ngClass]="{ 'is-invalid': isSubmit && f.department_id.errors }"
                (change)="fetchDeptDoctors($event.target.value)"
              >
                <option value="">select</option>
                <option *ngFor="let dep of department" [value]="dep.id">
                  {{ dep.title }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-3 col-sm-6 col-12 pl-4 pl-sm-4 pl-md-0 pl-lg-0"
          >
            <div class="form-group">
              <label> Visit Type </label>
              <select
                class="form-control form-control-sm"
                formControlName="visit_type_id"
                [ngClass]="{ 'is-invalid': isSubmit && f.visit_type_id.errors }"
                (change)="fetchVisitService($event.target.value)"
              >
                <option value="">select</option>
                <ng-container *ngFor="let look of visitType">
                  <option
                    *ngIf="look.category == 'Visit Type'"
                    [value]="look.id"
                  >
                    {{ look.key }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
          <div
            class="col-lg-2 col-md-2 col-sm-6 col-10 pl-4 pl-sm-4 pl-md-0 pl-lg-0"
          >
            <div class="form-group">
              <label> Att./Visiting </label>
              <select
                class="form-control form-control-sm"
                formControlName="doctor_type_id"
                [ngClass]="{
                  'is-invalid': isSubmit && f.doctor_type_id.errors
                }"
              >
                <option value="">select</option>
                <option *ngFor="let config of attendVisit" [value]="config.id">
                  {{ config.name }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-lg-1 col-md-1 col-sm-6 col-2 pl-4 pl-sm-4 pl-md-0 pl-lg-0"
          >
            <div class="form-group">
              <label> Bill </label>
              <br />
              <label class="checkbox checkbox-sm">
                <input
                  type="checkbox"
                  formControlName="bill"
                  [checked]="false"
                />
                <span></span>
              </label>
            </div>
          </div>
        </div>

        <div class="row m-0 mt-3">
          <div class="col-12">
            <textarea
              rows="4"
              formControlName="detail"
              id="consultingDetails"
              name="consultingDetails"
            ></textarea>
          </div>

          <div class="col-12 mt-3 text-right">
            <button type="submit" class="btn btn-primary btn-sm">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="card mb-4" *ngIf="!isAdd || allConsultations.length > 0">
    <div class="card-body user-card-body">
      <div class="row m-0 mb-4" *ngIf="!isAdd">
        <h4>Consultation</h4>
      </div>
      <div class="table-responsive">
        <table class="table table-head-custom table-vertical-center">
          <tbody>
            <ng-container *ngFor="let val of allConsultations; let i = index">
              <tr>
                <ng-container class="table-responsive">
                  <table class="table margin-bottom-0">
                    <thead *ngIf="i === 0">
                      <tr>
                        <th
                          style="
                            min-width: 150px;
                            width: 150px;
                            max-width: 150px;
                          "
                        >
                          Date / Time
                        </th>
                        <th
                          style="
                            min-width: 150px;
                            width: 150px;
                            max-width: 150px;
                          "
                        >
                          Doctor Name
                        </th>
                        <th
                          style="
                            min-width: 200px;
                            width: 200px;
                            max-width: 200px;
                          "
                        >
                          Department
                        </th>
                        <th style="min-width: 100px; width: 100px">
                          Visit Type
                        </th>
                        <th style="min-width: 100px; width: 100px">
                          Att./Visiting
                        </th>
                        <th style="min-width: 100px; width: 100px">Bill</th>
                        <th
                          style="min-width: 100px; width: 100px"
                          *ngIf="isAdd === false"
                        >
                          Print
                        </th>
                      </tr>
                    </thead>

                    <tr style="background-color: transparent">
                      <td
                        style="min-width: 150px; width: 150px; max-width: 150px"
                      >
                        {{ val?.created_at | date: "dd-MM-yyyy h:mm a" }}
                      </td>
                      <td
                        style="min-width: 150px; width: 150px; max-width: 150px"
                      >
                        {{ val?.doctor?.first_name }}
                        {{ val?.doctor?.last_name }}
                      </td>
                      <td
                        style="min-width: 200px; width: 200px; max-width: 200px"
                      >
                        {{ val?.department?.title }}
                      </td>
                      <td style="min-width: 100px; width: 100px">
                        {{ val?.visit_type?.key }}
                      </td>
                      <td style="min-width: 100px; width: 100px">
                        {{ val?.doctor_type?.name }}
                      </td>
                      <td
                        style="min-width: 100px; width: 100px"
                        *ngIf="val?.bill === true"
                      >
                        <span
                          class="label label-lg label-light-primary label-inline"
                          >Billed</span
                        >
                      </td>
                      <td
                        style="min-width: 100px; width: 100px"
                        *ngIf="val?.bill === false"
                      ></td>
                      <td style="width: 10%" *ngIf="isAdd === false">
                        <button
                          type="button"
                          class="btn btn-light-primary btn-icon btn-sm"
                          (click)="printViewConsulVisits(val?.id, val?.status)"
                        >
                          <i class="fas fa-print"></i>
                        </button>
                      </td>
                    </tr>

                    <tr
                      *ngIf="val?.detail"
                      style="background-color: transparent"
                      class="tr-color"
                    >
                      <td
                        style="
                          min-width: 130px !important;
                          border-top: none !important;
                          width: 130px;
                        "
                      >
                        <b>Doctor Notes : </b>
                      </td>
                      <td
                        style="border-top: none !important"
                        colspan="5"
                        class="ul-ol"
                        [innerHTML]="val?.detail"
                      ></td>
                    </tr>
                  </table>
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div
        class="row text-right"
        *ngIf="patient && isAdd === true && allConsultations.length > 0"
      >
        <div class="col-12">
          <button
            type="button"
            class="btn btn-light-primary btn-icon btn-sm"
            (click)="printViewConsul()"
          >
            <i class="fas fa-print"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--end::Card-->
</div>
