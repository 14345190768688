import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { LayoutComponent } from './layouts/layout/layout.component';
import { AuthGuard } from './services/auth.guard';
import { UserVerificationComponent } from './auth/user-verification/user-verification.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { DashboardComponent } from './superadmin/dashboard/dashboard.component';
import { OnlineLabReportComponent } from './online-lab-report/online-lab-report.component';
import { OtSchedulingComponent } from './superadmin/ot-scheduling/ot-scheduling.component';
import { LoginV2Component } from './auth/login-v2/login-v2.component';
import { WorkerPrintComponent } from './worker-print/worker-print.component';
import { DoctorSurveyComponent } from './doctor-survey/doctor-survey/doctor-survey.component';
import { DoctorSurveyReportComponent } from './doctor-survey/doctor-survey-report/doctor-survey-report.component';
import { RegisterOrganizationComponent } from './Register/register-organization/register-organization.component';
import { ForgotPasswordV2Component } from './auth/forgot-password-v2/forgot-password-v2.component';
import { OnlineLabReportV2Component } from './online-lab-report-v2/online-lab-report-v2.component';
import { LabPdfReportComponent } from './reports/lab-pdf-report/lab-pdf-report.component';
import { RadPdfReportComponent } from './reports/rad-pdf-report/rad-pdf-report.component';

const routes: Routes = [
  { path: '', component: LoginV2Component },
  { path: 'login-old', component: LoginComponent },
  { path: 'login', component: LoginV2Component },
  { path: 'login-v2', component: LoginV2Component },
  { path: 'superadmin', component: DashboardComponent },
  { path: 'ot-scheduling', component: OtSchedulingComponent },
  { path: 'online-lab-report', component: OnlineLabReportComponent },
  { path: 'online-lab-report-v2', component: OnlineLabReportV2Component },
  { path: 'lab-report-pdf', component: LabPdfReportComponent },
  { path: 'rad-report-pdf', component: RadPdfReportComponent },
  { path: 'wmr', component: WorkerPrintComponent },
  { path: 'survey-form', component: DoctorSurveyComponent },
  { path: 'survey-report', component: DoctorSurveyReportComponent },
  { path: 'user-verification', component: UserVerificationComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password-v2', component: ForgotPasswordV2Component },
  { path: 'register-organization', component: RegisterOrganizationComponent },
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },
  { path: '**', component: LoginComponent },
];
// {preloadingStrategy: PreloadAllModules}
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
