import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class PatientServiceInvoicesService {
  constructor(private http: HttpClient) {}
  patientServicesInvoice(
    patient_service_invoice_item,
    patient,
    type,
    currentPage,
    perPage,
    pageUrl,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/patient_service_invoices?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_service_invoice_item.patient_id +
          '&parent_department_id=' +
          patient_service_invoice_item.parent_department_id +
          '&department_id=' +
          patient_service_invoice_item.department_id +
          '&from=' +
          patient_service_invoice_item.from_date +
          '&to=' +
          patient_service_invoice_item.to_date +
          '&patient_id=' +
          patient_service_invoice_item.patient_id +
          '&cancelled=false&open_status=' +
          type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&page_url=' +
          pageUrl,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientServicesInvoiceV2(
    patient_service_invoice_item,
    patient,
    type,
    currentPage,
    perPage,
    pageUrl,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_service_invoice/` +
          patient.mrn +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_service_invoice_item.patient_id +
          '&parent_department_id=' +
          patient_service_invoice_item.parent_department_id +
          '&department_id=' +
          patient_service_invoice_item.department_id +
          '&from=' +
          patient_service_invoice_item.from_date +
          '&to=' +
          patient_service_invoice_item.to_date +
          '&patient_id=' +
          patient_service_invoice_item.patient_id +
          '&cancelled=false&open_status=' +
          type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&page_url=' +
          pageUrl,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientServicesInvoiceApprovalV2(
    patient_service_invoice_item,
    patient,
    type,
    currentPage,
    perPage,
    pageUrl,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_service_invoice_for_approval/` +
          patient.mrn +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_service_invoice_item.patient_id +
          '&parent_department_id=' +
          patient_service_invoice_item.parent_department_id +
          '&department_id=' +
          patient_service_invoice_item.department_id +
          '&from=' +
          patient_service_invoice_item.from_date +
          '&to=' +
          patient_service_invoice_item.to_date +
          '&patient_id=' +
          patient_service_invoice_item.patient_id +
          '&cancelled=false&open_status=' +
          type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&page_url=' +
          pageUrl,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientServicesInvoiceForDiscount(
    patient_service_invoice_item,
    patient,
    type,
    currentPage,
    perPage,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/patient_service_invoices?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_service_invoice_item.patient_id +
          '&parent_department_id=' +
          patient_service_invoice_item.parent_department_id +
          '&department_id=' +
          patient_service_invoice_item.department_id +
          '&from=' +
          patient_service_invoice_item.from_date +
          '&to=' +
          patient_service_invoice_item.to_date +
          '&patient_id=' +
          patient_service_invoice_item.patient_id +
          '&cancelled=false&open_status=' +
          type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&include_fs_services=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientServicesInvoiceForDiscountV2(
    patient_service_invoice_item,
    patient,
    type,
    currentPage,
    perPage,
    admissionId,
    discountval,
    invoiceId,
    labOrderId,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_service_invoice_for_requests/` +
          patient.mrn +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_service_invoice_item.patient_id +
          '&parent_department_id=' +
          patient_service_invoice_item.parent_department_id +
          '&department_id=' +
          patient_service_invoice_item.department_id +
          '&from=' +
          patient_service_invoice_item.from_date +
          '&to=' +
          patient_service_invoice_item.to_date +
          '&patient_id=' +
          patient_service_invoice_item.patient_id +
          '&cancelled=false&open_status=' +
          type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&include_fs_services=true' +
          '&admission_id=' +
          admissionId +
          '&show_invoices=' +
          discountval +
          '&patient_service_id=' +
          invoiceId +
          '&lab_order_id=' +
          labOrderId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  update(patient_service_invoice): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patient_service_invoice_items/update_invoce_items?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_service_invoice,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateDiscount(
    patient_service_invoice,
    patient,
    discount_reason_id,
    discount_comments,
  ) {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/patient_service_invoices/request_discount?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_service_invoice,
          discount_reason_id,
          discount_comments,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDiscountV2(
    patient_service_invoice,
    patient,
    discount_reason_id,
    discount_comments,
  ) {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/request_discount/` +
          patient.mrn +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_service_invoice,
          discount_reason_id,
          discount_comments,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  cancelServices(cancel_services, patient): any {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/patient_service_invoices/cancel_multiple_services?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          invoice_ids: cancel_services.invoice_ids,
          service_ids: cancel_services.service_ids,
          cash: cancel_services.cash,
          receivable: cancel_services.receivable,
          patient_id: cancel_services.patient_id,
          service_cancel_reason_id: cancel_services.service_cancel_reason_id,
          service_cancel_comments: cancel_services.service_cancel_comments,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  cancelServicesV2(cancel_services, patient): any {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/cancel_multiple_services/` +
          patient.mrn +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          invoice_ids: cancel_services.invoice_ids,
          service_ids: cancel_services.service_ids,
          cash: cancel_services.cash,
          receivable: cancel_services.receivable,
          patient_id: cancel_services.patient_id,
          service_cancel_reason_id: cancel_services.service_cancel_reason_id,
          service_cancel_comments: cancel_services.service_cancel_comments,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  approvedRejectServices(patient_service, status) {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4` +
          '/patient_service_invoice_items/update_discount_status?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_service,
          status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  approvedRejectServicesV2(patient_service, status) {
    const results = this.http
      .post(
        `${environment.pythonUrl}` +
          '/api/update_discount_status/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_service,
          status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  approvedRejectServicesCancelation(invoice_ids, status, patient) {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/patient_service_invoices/approve_multiple_canceled_services?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          invoice_ids,
          status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  approvedRejectServicesCancelationV2(invoice_ids, status, patient) {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/approve_multiple_canceled_services/` +
          patient.mrn +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          invoice_ids,
          status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientAdvancesFin(patient, pageUrl): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/advances/patient_advances/?patient_id=` +
          patient.id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page_url=' +
          pageUrl,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientAdvancesFinV2(
    patient,
    pageUrl,
    admissionId,
    invoiceId,
    labOrderId,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_advances/?patient_id=` +
          patient.id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page_url=' +
          pageUrl +
          '&admission_id=' +
          admissionId +
          '&patient_service_id=' +
          invoiceId +
          '&lab_order_id=' +
          labOrderId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  cancelAdvances(cancel_advances): any {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/advances/cancel_advances/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          advance_ids: cancel_advances.advance_ids,
          patient_id: cancel_advances.patient_id,
          advance_cancel_reason_id: cancel_advances.advance_cancel_reason_id,
          advance_cancel_comments: cancel_advances.advance_cancel_comments,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  cancelAdvancesV2(cancel_advances): any {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/cancel_advances/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          advance_ids: cancel_advances.advance_ids,
          patient_id: cancel_advances.patient_id,
          advance_cancel_reason_id: cancel_advances.advance_cancel_reason_id,
          advance_cancel_comments: cancel_advances.advance_cancel_comments,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  approvedRejectAdvanceCancelation(advance, status): any {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/advances/approve_cancelled_advances/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          advance_ids: advance.advance_ids,
          status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  approvedRejectAdvanceCancelationV2(advance, status): any {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/approve_cancelled_advances/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          advance_ids: advance.advance_ids,
          status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
