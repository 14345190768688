<div>
  <!--begin::Card-->

  <div
    [ngClass]="{ 'mb-4': isAdd === true, 'mb-0': isAdd === false }"
    class="card"
  >
    <div class="card-body user-card-body" *ngIf="isAdd === true">
      <div class="text-danger font-weight-bold">{{ showMsg || "" }}</div>
      <div class="mt-6">
        <div
          class="row"
          *ngFor="let service of serviceData.patient_services; let i = index"
        >
          <div class="col-lg-2 col-md-2 col-sm-2 col-12">
            <div class="form-group">
              <label *ngIf="(i === 0 && width > 575) || width < 576"
                >Service Type</label
              >
              <select
                class="form-control form-control-sm"
                id="serviceType{{ i }}"
                (change)="fetchSelectedServices(i, '', $event.target.value)"
                [(ngModel)]="service.service_type"
              >
                <option value="" [selected]="true" [disabled]="true">
                  Select type
                </option>
                <ng-container *ngFor="let s of uniq_services">
                  <option [value]="s.name" *ngIf="s.name != 'DoctorFee'">
                    {{ s.name }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-4 col-sm-4 col-12 serviceReport pl-4 pl-sm-0 pl-md-0"
          >
            <div class="form-group">
              <label *ngIf="(i === 0 && width > 575) || width < 576"
                >Service Name <span class="text-danger">*</span></label
              >
              <!--                          data-width="387px"-->
              <select
                class="form-control form-control-sm serviceSelectpicker serviceName"
                id="serviceName{{ i }}"
                data-live-search="true"
                (change)="
                  updateServicesDept(i, uniq_services[i], $event.target.value)
                "
              >
                <option value="-1" [disabled]="true">Select service</option>
                <ng-container *ngFor="let s of services[i]">
                  <option [value]="s.id">{{ s.name }}</option>
                </ng-container>
              </select>
              <small *ngIf="service.billing_code"
                ><strong>Billing Code:</strong>
                {{ service.billing_code }}</small
              >
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-12">
            <div class="form-group">
              <label *ngIf="(i === 0 && width > 575) || width < 576"
                >Referring Doctor <span class="text-danger">*</span></label
              >
              <select
                class="form-control form-control-sm doctorSelectpicker pickers"
                id="referringDoctor{{ i }}"
                data-live-search="true"
                [(ngModel)]="service.referring_doctor_id"
              >
                <option value="">select</option>
                <option *ngFor="let doc of doctor" [value]="doc.id">
                  {{ doc.first_name }} {{ doc.last_name
                  }}<span *ngIf="!isopd"> ({{ doc.doc_dept_name }})</span>
                </option>
              </select>
            </div>
          </div>

          <!--                      <div class="col-lg-2 col-md-2 col-sm-2 col-6 pl-4 pl-sm-0 pl-md-0">-->
          <!--                        <div class="form-group">-->
          <!--                          <label *ngIf="(i === 0 && width > 575) || ( width < 576)">Code</label>-->
          <!--                          <input type="text" class="form-control form-control-sm" [readonly]="true" placeholder="Code"-->
          <!--                                 [(ngModel)]="service.billing_code"/>-->
          <!--                        </div>-->
          <!--                      </div>-->

          <div class="col-lg-1 col-md-1 col-sm-1 col-6 pl-4 pl-sm-0 pl-md-0">
            <div class="form-group">
              <label *ngIf="(i === 0 && width > 575) || width < 576">Qty</label>
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Qty"
                [readonly]="true"
                [(ngModel)]="service.quantity"
              />
            </div>
          </div>

          <div class="col-lg-1 col-md-1 col-sm-1 col-6 pl-4 pl-sm-0 pl-md-0">
            <div class="form-group text-center">
              <label *ngIf="(i === 0 && width > 575) || width < 576"
                >Urgent</label
              >
              <label class="checkbox checkbox-sm justify-content-center">
                <input [(ngModel)]="service.urgent" type="checkbox" />
                <span></span>
              </label>
            </div>
          </div>

          <div class="col-lg-1 col-md-1 col-sm-1 col-6 pl-4 pl-sm-0 pl-md-0">
            <div class="form-group text-right">
              <label
                class="d-block"
                *ngIf="(i === 0 && width > 575) || width < 576"
                >Action</label
              >
              <button
                type="button"
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                style="margin-right: 0px !important"
                (click)="removeServices(i, service.price)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                      <path
                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-lg-9 col-md-9 col-sm-9 col-4"></div>
          <div class="col-lg-2 col-md-2 col-sm-2 col-4 align-self-center">
            <!--                        <h5>Total: {{servicesTotalBill}}</h5>-->
          </div>
          <div class="col-lg-1 col-md-1 col-sm-1 col-4 text-right">
            <button
              type="button"
              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
              style="margin-right: 0px !important"
              (click)="addMoreServices()"
            >
              <span class="svg-icon svg-icon-primary svg-icon-2x">
                <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                    <path
                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                      fill="#000000"
                    />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="text-right">
        <button class="btn btn-primary btn-sm ml-2" (click)="addServices('')">
          Submit
        </button>
      </div>
    </div>
  </div>

  <div class="card mb-4">
    <div class="card-body user-card-body">
      <div class="row m-0 mb-3" *ngIf="isAdd === true">
        <div class="col-md-4 col-sm-4 col-12 pl-0">
          <select
            class="form-control form-control-sm"
            [(ngModel)]="servicesPrintRecent"
            (change)="updateServicesPrintRecent($event.target.value)"
          >
            <option value="false">Previous</option>
            <option value="true">Recent</option>
          </select>
        </div>
        <div class="col-md-4 col-sm-4 col-12"></div>
        <div
          class="col-md-4 col-sm-4 col-12 pr-4 pr-sm-0 pr-md-0 mt-2 mt-sm-0 mt-md-0 text-right"
        >
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-primary btn-sm mr-2"
              (click)="openLabParameterModal()"
            >
              Show lab Parameter History
            </button>
            <button
              class="btn btn-primary btn-sm ml-2"
              (click)="printRecentServicesSlip()"
            >
              Print
            </button>
          </div>
        </div>
      </div>

      <div class="overflow-auto table-responsive">
        <table class="table table-head-custom table-vertical-center">
          <thead>
            <tr>
              <th style="width: 5%" *ngIf="isAdd === true">
                <label class="checkbox checkbox-sm mt-2">
                  <input
                    type="checkbox"
                    class="recentServiceAll"
                    id="recentServiceAll"
                    (change)="selectAllRecentServices()"
                  />
                  <span></span>
                </label>
              </th>
              <th style="width: 20%">Date / Department</th>
              <th style="width: 50%">Name / Type</th>
              <!--                        <th style="width: 8%" class="text-center">Price</th>-->
              <!--                      *ngIf="history?.service?.assignable_type === 'LabInvestigation' && history?.is_external"-->
              <th *ngIf="isAdd === true" style="width: 10%" class="text-center">
                Image
              </th>
              <th style="width: 10%" class="text-center">Report</th>
              <th style="width: 10%" class="text-center">Status</th>
            </tr>
          </thead>
          <tbody *ngIf="patientServiceHistory.length > 0">
            <ng-container
              *ngFor="let history of patientServiceHistory; let i = index"
            >
              <tr
                *ngIf="
                  history.recent === servicesPrintRecent || isAdd === false
                "
              >
                <td style="width: 5%" *ngIf="isAdd === true">
                  <label class="checkbox checkbox-sm mt-2">
                    <input
                      type="checkbox"
                      class="recentService"
                      id="recentService{{ i }}"
                    />
                    <span></span>
                  </label>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                    >{{ history?.created_at | date: "dd-MM-yyyy h:mm a" }}</span
                  >
                  <span
                    class="text-muted font-weight-bold text-muted d-block"
                    >{{ history?.service?.department?.title }}</span
                  >
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                    >{{ history?.service?.name }}</span
                  >
                  <span
                    class="text-muted font-weight-bold text-muted d-block"
                    >{{ history?.service?.assignable_type }}</span
                  >
                </td>
                <!--                          <td class="text-center">{{ ( history?.patient_service_invoice_item?.base_price * history?.patient_service_invoice_item?.quantity ) - history?.patient_service_invoice_item?.approved_discount}}</td>-->
                <td *ngIf="isAdd === true" class="text-center">
                  <button
                    type="button"
                    class="btn btn-light-primary btn-icon btn-sm"
                    *ngIf="
                      history?.service?.assignable_type === 'Radiology' &&
                      history?.accession_number
                    "
                    (click)="openDiscomImage(history?.accession_number)"
                  >
                    <i class="fas fa-x-ray"></i>
                  </button>
                </td>
                <td class="text-center">
                  <span
                    *ngIf="
                      history?.service?.assignable_type === 'Radiology' &&
                      history?.is_final === true
                    "
                    (click)="printRadiologyReport(history?.report_id, patient)"
                  >
                    <a class="btn btn-icon btn-light-primary btn-sm mr-2">
                      <i class="flaticon-technology"></i>
                    </a>
                  </span>

                  <span
                    *ngIf="
                      history?.service?.assignable_type ===
                        'LabInvestigation' && history?.is_external === true
                    "
                    (click)="viewExternalReport(history?.lab_order_detail?.id)"
                  >
                    <a class="btn btn-icon btn-light-primary btn-sm mr-2">
                      <i class="flaticon-technology"></i>
                    </a>
                  </span>
                  <!--!history?.service?.assignable?.send_out_id &&-->
                  <span
                    *ngIf="
                      history?.service?.assignable_type ===
                        'LabInvestigation' &&
                      history?.is_external === false &&
                      (history?.lab_order_detail?.status === 'Verified' ||
                        history?.lab_order_detail?.status === 'Delivered')
                    "
                    (click)="
                      printService(
                        history?.lab_order_detail_id,
                        history?.lab_order_id,
                        history?.test_code
                      )
                    "
                  >
                    <a class="btn btn-icon btn-light-primary btn-sm mr-2">
                      <i class="flaticon-technology"></i>
                    </a>
                  </span>

                  <!--                            <span class="cursor-pointer" *ngIf="history?.is_external === true" (click)="viewExternalReport(history?.lab_order_detail?.id)"><i class="fas fa-print"></i></span>-->
                  <!--                            <span class="cursor-pointer" *ngIf="( history?.lab_order_detail?.status === 'Verified' || history?.lab_order_detail?.status === 'Delivered' ) && history?.is_external === false" (click)="printLabReport(history?.lab_order_detail.id)"><i class="fas fa-print"></i></span>-->
                  <span
                    class="cursor-pointer"
                    *ngIf="history?.consultation_id"
                    (click)="openPrescriptionSlip(history?.consultation_id)"
                  >
                    <a class="btn btn-icon btn-light-primary btn-sm mr-2">
                      <i class="flaticon-technology"></i>
                    </a>
                  </span>
                </td>
                <td class="text-center">
                  <span
                    class="label label-lg label-inline"
                    [ngClass]="{
                      'label-light-primary':
                        history?.service_status === 'Pending',
                      'label-light-warning':
                        history?.service_status === 'In process',
                      'label-light-success':
                        history?.service_status === 'Finalized',
                      'label-light-danger':
                        history?.service_status === 'Cancelled'
                    }"
                    *ngIf="history?.service_status !== ''"
                    >{{ history?.service_status }}</span
                  >
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <!--pagination-->
      <div class="pagination_main mt-4">
        <ngb-pagination
          [(page)]="page"
          (pageChange)="loadPage(page)"
          [pageSize]="perPage"
          [collectionSize]="totalPages"
          [maxSize]="5"
          [rotate]="true"
          [ellipses]="false"
          [boundaryLinks]="true"
        ></ngb-pagination>
        <div class="dropdown">
          <a
            class="btn dropdown-toggle btn-light btn-sm"
            role="button"
            id="pagination_btn"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="page_count">
              {{ perPage }}
            </span>
            <span class="count_count_icon"></span>
          </a>
          <div class="dropdown-menu" aria-labelledby="pagination_btn">
            <a class="dropdown-item" (click)="selectPaginationLimit(25)">25</a>
            <a class="dropdown-item" (click)="selectPaginationLimit(50)">50</a>
            <a class="dropdown-item" (click)="selectPaginationLimit(75)">75</a>
            <a class="dropdown-item" (click)="selectPaginationLimit(100)"
              >100</a
            >
            <a class="dropdown-item" (click)="selectPaginationLimit('')">All</a>
          </div>
        </div>
      </div>
      <!--pagination End-->
    </div>
  </div>

  <!--end::Card-->
</div>

<div
  class="modal fade"
  id="duplicateServicesAllertModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation!</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label class="checkbox checkbox-sm float-left mr-3">
          <input type="checkbox" [(ngModel)]="isChecked" />
          <span></span>
        </label>
        <span
          >This service is already added within same day. Are you sure you want
          to add this again ?</span
        >
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="addServicesDuplicateConfirm()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="lappatametermodal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="labparameter">Lab Parameter</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ng-container *ngIf="labParameterStatus">
        <div class="modal-body">
          <app-lab-parameter [patientId]="patient?.id"> </app-lab-parameter>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
