import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { DepartmentsByHospitalService } from '../../../../../services/departments-by-hospital.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { HospitalService } from '../../../../../services/hospital.service';
import { RouteShareService } from '../../../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-day-wise-report',
  templateUrl: './day-wise-report.component.html',
  styleUrls: ['./day-wise-report.component.css'],
})
export class DayWiseReportComponent implements OnInit {
  today = formatDate(new Date(), 'MM-yyyy', 'en');
  depart: any;
  employeesOfDepart: any;
  employeesOfSelectedDepartment: any;
  selectedLocation: any;
  dates: any;
  employeeRoasterData = [];
  employeeDetails: any;
  locationData = [];
  roasterData = [];
  shiftData = [];
  department: any;
  month: any;
  shiftFirstData: any;
  applyToAllShift = [];
  applyToAllConfirmation = false;
  applyToAllIndex: any;
  csvBaseFile: any;
  file: any;
  list: any;
  settingData: any;
  screenName = '';
  datalist: any = [];

  constructor(
    private departmentsByHospital: DepartmentsByHospitalService,
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private rosterServiceService: RosterServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef,
    private hospitalService: HospitalService,
    private routeService: RouteShareService,
  ) {
    this.screenName = this.routeService.getValue();
    this.getSettingsData();
    this.getLocation();
    this.getDepartments();
  }
  getLocation(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.locationData = data.location;
        setTimeout((e) => {
          $('#selectedLocation').selectpicker('refresh');
          $('#selectedLocation').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getEmployeesOfDepartment(id): any {
    this.employeesOfDepart = [];
    setTimeout((e) => {
      $('#employeeDepartmentList').selectpicker('refresh');
    }, 200);
    this.configurationService
      .getEmployeesOfSpecificDepartment(this.department)
      .subscribe(
        (data) => {
          this.employeesOfDepart = data;
          setTimeout((e) => {
            $('#employeeDepartmentList').selectpicker('refresh');
            $('#employeeDepartmentList').selectpicker();
          }, 500);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  appendArray(): any {
    const month = $('#reportDate').val();
    if (this.employeesOfSelectedDepartment === undefined) {
      this.employeesOfSelectedDepartment = '';
    }
    if (month === '' || month === undefined || month === null) {
      console.log('month', this.month);
      toastr.error('Select Month');
      return;
    }
    if (
      this.department === '' ||
      this.department === undefined ||
      (this.department === null && this.selectedLocation === '')
    ) {
      toastr.error('Select Department');
      return;
    }
    this.ngxLoader.start();
    this.rosterServiceService
      .getEmployeesattendanceByDate(
        this.employeesOfSelectedDepartment || '',
        month,
        this.department || '',
        this.selectedLocation || '',
      )
      .subscribe(
        (data) => {
          this.employeeRoasterData = data.list;
          this.employeeDetails = data.employee;
          if (!this.employeeRoasterData.length) {
            $('#promissionsAlertModal').modal('show');
          }
          // this.ngxLoader.stop();
          setTimeout(() => {
            $('.roasterSelection').selectpicker();
            this.ngxLoader.stop();
          }, 100);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  printReport(): any {
    window.print();
  }
  ngOnInit(): void {
    $('#reportDate').datepicker({
      format: 'dd-mm-yyyy',
      orientation: 'bottom left',
    });
  }
}
