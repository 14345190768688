import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WardService } from '../../../../services/ward.service';

@Component({
  selector: 'app-transfer-history',
  templateUrl: './transfer-history.component.html',
  styleUrls: ['./transfer-history.component.css'],
})
export class TransferHistoryComponent implements OnInit {
  @Input() patient: any;
  @Input() visitType: any;

  patientWardHistory = [];
  patientStayHistory = [];
  totalCount: any;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private wardService: WardService,
  ) {}

  ngOnInit(): void {
    this.getPatientWardHistory();
  }

  getPatientWardHistory(): any {
    this.ngxLoader.start();
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.wardService
      .fetchPatientWardHistoryV2(this.patient.admissions[0].id, this.visitType)
      .subscribe(
        (data) => {
          this.patientWardHistory = data.history;
          this.patientStayHistory = data.stay_summary;
          this.totalCount = data.total_over_an_all_time;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
}
