<!--begin::Content-->
<div
  class="content d-flex flex-column flex-column-fluid selectOptionBottom"
  id="kt_content"
>
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Patient Services</h5>

          <!--          tab headings-->

          <ul class="nav nav-pills nav-light-primary nav-bold border-0 ml-5">
            <li class="nav-item">
              <a
                class="nav-link border-0 active"
                data-toggle="tab"
                href="#services_tab"
                (click)="servicesTab()"
              >
                <!--                <span class="nav-icon"><i class="flaticon2-chat-1"></i></span>-->
                <span class="nav-text">Services</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="patient">
              <a
                class="nav-link border-0"
                data-toggle="tab"
                href="#history_tab"
                (click)="historyTabCall()"
              >
                <!--                <span class="nav-icon"><i class="flaticon2-analytics-1"></i></span>-->
                <span class="nav-text">History</span>
              </a>
            </li>
          </ul>

          <div class="d-flex row ml-6">
            <div class="col-12 patient-data">
              <span class="text-left padding-top-4" *ngIf="patient">
                {{ patient?.mrn }}
                <span class="ml-5">{{
                  " " + patient?.first_name + " " + patient?.last_name
                }}</span>
                <span class="ml-5"
                  >{{ patient?.age }}, {{ patient?.gender | titlecase }}</span
                >
              </span>

              <span class="ml-8" *ngIf="patient?.admissions.length > 0">
                {{
                  " " +
                    patient?.admissions[patient?.admissions.length - 1]?.ward
                      ?.title
                }}</span
              >

              <!--              <span class="ml-5" *ngIf="patient?.bed_name"> {{patient?.bed_name}}</span>-->

              <span class="ml-5" *ngIf="patient?.patient_type">
                {{ patient?.patient_type?.name }}</span
              >
            </div>
          </div>

          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Actions-->
        <!--        <a  class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a>-->
        <!--end::Actions-->
        <!--begin::Dropdown-->
        <!--        <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">-->
        <!--          <a  class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--											<span class="svg-icon svg-icon-success svg-icon-2x">-->
        <!--												&lt;!&ndash;begin::Svg Icon | path:assets/media/svg/icons/Files/File-plus.svg&ndash;&gt;-->
        <!--												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
        <!--													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
        <!--														<polygon points="0 0 24 0 24 24 0 24" />-->
        <!--														<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />-->
        <!--														<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000" />-->
        <!--													</g>-->
        <!--												</svg>-->
        <!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--											</span>-->
        <!--          </a>-->
        <!--          <div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">-->
        <!--            &lt;!&ndash;begin::Navigation&ndash;&gt;-->
        <!--            <ul class="navi navi-hover">-->
        <!--              <li class="navi-header font-weight-bold py-4">-->
        <!--                <span class="font-size-lg">Choose Label:</span>-->
        <!--                <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mb-3 opacity-70"></li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-success">Customer</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-danger">Partner</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-warning">Suplier</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-primary">Member</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-dark">Staff</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mt-3 opacity-70"></li>-->
        <!--              <li class="navi-footer py-4">-->
        <!--                <a class="btn btn-clean font-weight-bold btn-sm" >-->
        <!--                  <i class="ki ki-plus icon-sm"></i>Add new</a>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--            &lt;!&ndash;end::Navigation&ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="row">
        <div class="col-md-12 p-0">
          <!--          <div class="card card-custom gutter-b">-->
          <!--            <div class="card-header ">-->

          <!--&lt;!&ndash;              <div class="card-title">&ndash;&gt;-->
          <!--&lt;!&ndash;                <h3 class="card-label">Card Line Tabs With Icons</h3>&ndash;&gt;-->
          <!--&lt;!&ndash;              </div>&ndash;&gt;-->

          <!--&lt;!&ndash;              <div class="card-toolbar">&ndash;&gt;-->

          <!--&lt;!&ndash;              </div>&ndash;&gt;-->
          <!--            </div>-->

          <!--            <div class="card-body">-->

          <div class="tab-content">
            <!-- Services tab -->
            <div
              class="tab-pane fade show active"
              id="services_tab"
              role="tabpanel"
              aria-labelledby="services_tab"
            >
              <!-- Consultation area-->

              <div
                class="card mb-3"
                *ngIf="isAdmitted === false && this.showConsultantCard"
              >
                <div class="card-body service-card p-5">
                  <div class="row">
                    <div class="col-8">
                      <h4 class="m-0 mb-3">Consultation</h4>
                    </div>

                    <div
                      class="col-4 text-right"
                      *ngIf="consultation_services.length === 0"
                    >
                      <button
                        (click)="addConsultant()"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                        type="button"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                              <path
                                d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                fill="#000000"
                              />
                            </g></svg
                          ><!--end::Svg Icon-->
                        </span>
                      </button>
                    </div>
                  </div>

                  <div class="">
                    <table
                      class="table serviceTable m-0"
                      *ngIf="consultation_services.length > 0"
                    >
                      <thead>
                        <tr>
                          <th class="p-2">Doctor</th>
                          <th class="p-2">
                            Department <span class="text-danger">*</span>
                          </th>
                          <th class="p-2">
                            Sub Department <span class="text-danger">*</span>
                          </th>
                          <th class="p-2">
                            Visit Type <span class="text-danger">*</span>
                          </th>
                          <!--                        <th class="p-2">Visited Code</th>-->
                          <!--                          <th class="p-2">Service Type</th>-->
                          <th class="p-2">Clinic Name</th>
                          <!--                          <th class="p-2">Comments</th>-->
                          <th class="p-2">
                            Price <span class="text-danger">*</span>
                          </th>
                          <th class="p-2 text-center">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          *ngFor="
                            let consultant of consultation_services;
                            let i = index
                          "
                        >
                          <!--                          bootstrap-select-height-->
                          <td class="p-2 pickers" style="width: 18%">
                            <div id="conDoctorMain{{ i }}">
                              <select
                                (change)="
                                  fetchDoctorDepartments($event.target.value, i)
                                "
                                [(ngModel)]="consultant.doctor_id"
                                autofocus
                                data-live-search="true"
                                class="auto-focus-red form-control form-control-sm conDoctor doctorSelectpicker"
                                id="conDoctor{{ i }}"
                              >
                                <option [disabled]="true" value="">
                                  select
                                </option>
                                <ng-container *ngFor="let doc of doctors[i]">
                                  <option [value]="doc.id">
                                    {{ doc.first_name }} {{ doc.last_name }}
                                  </option>
                                </ng-container>
                              </select>
                              <div class="d-flex" *ngIf="consultant.doctor_id">
                                <span>
                                  <a
                                    style="color: #3699ff; cursor: pointer"
                                    (click)="updateRoomNumber()"
                                    ><small
                                      ><u>Room No: </u
                                      ><span>
                                        {{ doctorRoomNumber || "00" }}</span
                                      ></small
                                    ></a
                                  >
                                </span>
                              </div>
                            </div>
                          </td>

                          <td class="p-2 pickers" style="width: 18%">
                            <div id="conDeptMain{{ i }}">
                              <select
                                (change)="
                                  fetchDeptDoctors($event.target.value, i)
                                "
                                [(ngModel)]="consultant.department_id"
                                class="form-control form-control-sm conDept departmentSelectpicker"
                                data-live-search="true"
                                id="conDept{{ i }}"
                              >
                                <option [disabled]="true" value="">
                                  select
                                </option>
                                <option
                                  *ngFor="let dept of departments"
                                  [value]="dept.id"
                                >
                                  {{ dept.title }}
                                </option>
                              </select>
                            </div>
                            <small *ngIf="consultant.billing_code"
                              ><strong>Billing Code:</strong>
                              {{ consultant.billing_code }}</small
                            >
                          </td>
                          <td class="p-2 pickers" style="width: 14%">
                            <div id="conSubDept{{ i }}">
                              <select
                                [(ngModel)]="consultant.sub_department_id"
                                class="form-control form-control-sm conSubDept subDepartment"
                                data-live-search="true"
                              >
                                <option [disabled]="true" value="">
                                  select
                                </option>
                                <option
                                  *ngFor="let department of subDepartments[i]"
                                  [value]="department?.id"
                                >
                                  {{ department.title }}
                                </option>
                              </select>
                            </div>
                          </td>

                          <td class="p-2" style="width: 10%">
                            <div>
                              <select
                                (change)="
                                  fetchVisitService($event.target.value, i)
                                "
                                [(ngModel)]="consultant.service_type"
                                class="form-control form-control-sm conService"
                                id="conService{{ i }}"
                              >
                                <option [disabled]="true" value="">
                                  select
                                </option>
                                <!--                              <ng-container *ngFor="let lov of lookups">-->
                                <!--                                <option *ngIf="lov.category == 'Visit Type'" [value]="lov.id">{{lov.key}}</option>-->
                                <!--                              </ng-container>-->
                                <ng-container *ngFor="let lov of visitType[i]">
                                  <option [value]="lov.id">
                                    {{ lov.key }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </td>

                          <!--                        <td class="p-2" style="width: 10%">-->
                          <!--                          <input [(ngModel)]="consultant.billing_code" [readonly]="true" class="form-control form-control-sm" placeholder="Visited Code" type="text"/>-->
                          <!--                        </td>-->

                          <!--                          <td class="p-2" style="width: 10%">-->
                          <!--                            <select class="form-control form-control-sm">-->
                          <!--                              <option [disabled]="true" [selected]=true value="doctor_fee">Visit</option>-->
                          <!--                            </select>-->
                          <!--                          </td>-->

                          <td class="p-2" style="width: 10%">
                            <input
                              [(ngModel)]="consultant.clinic_name"
                              [readonly]="true"
                              class="form-control form-control-sm"
                              placeholder="Clinic Number"
                              type="text"
                            />
                          </td>

                          <!--                          <td class="p-2" style="width: 10%">-->
                          <!--                            <input [(ngModel)]="consultant.comments" class="form-control form-control-sm" placeholder="Comments" type="text"/>-->
                          <!--                          </td>-->

                          <td class="p-2" style="width: 10%">
                            <input
                              [(ngModel)]="consultant.price"
                              [readonly]="true"
                              class="form-control form-control-sm"
                              id="conPrice{{ i }}"
                              placeholder="Price"
                              type="number"
                            />
                          </td>

                          <td class="p-2 text-center" style="width: 5%">
                            <button
                              (click)="removeConsultant(i)"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path
                                      d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                      fill="#000000"
                                      fill-rule="nonzero"
                                    />
                                    <path
                                      d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                      fill="#000000"
                                      opacity="0.3"
                                    />
                                  </g></svg
                                ><!--end::Svg Icon--></span
                              >
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="5"></td>
                          <td>
                            <span *ngIf="consultation_services.length > 0"
                              ><strong>Total:</strong>
                              {{ consultantionTotal }}</span
                            >
                          </td>
                          <td class="text-center" style="width: 5%">
                            <button
                              *ngIf="consultation_services.length < 1"
                              (click)="addConsultant()"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                                ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="row text-right">
                    <div class="col-11"></div>
                    <div class="col-1"></div>
                  </div>

                  <!--                      <div class="p-0 text-right">-->
                  <!--                        <span *ngIf="consultation_services.length > 0" class="font-size-h4 font-weight-bold" style="margin-top: 15px !important;">Total: {{consultantionTotal}}</span>-->
                  <!--                        <button (click)="addConsultant()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-5"  type='button'><i class="fas fa-plus p-0"></i></button>-->
                  <!--                      </div>-->
                </div>
              </div>

              <!-- Service area -->

              <div class="card mb-3" *ngIf="showServiceCard">
                <div class="card-body service-card p-5">
                  <div class="row">
                    <div class="col-9 mb-3">
                      <h4
                        class="mx-0"
                        style="width: 160px; float: left; margin-top: 4px"
                      >
                        Other Services
                      </h4>

                      <select
                        (change)="fetchServicesMain($event.target.value)"
                        [disabled]="serviceTypeDisable"
                        [(ngModel)]="defaultServiceType"
                        *ngIf="requestedServices === false"
                        class="form-control form-control-sm serviceType mr-2"
                        id="serviceTypeMain"
                        style="width: 160px; float: left"
                      >
                        <option [disabled]="true" [selected]="true" value="">
                          Select
                        </option>
                        <ng-container *ngFor="let s of uniq_services">
                          <option [value]="s.name" [selected]="s?.is_default">
                            {{ s.name }}
                          </option>
                        </ng-container>
                      </select>
                      <div
                        style="float: left; width: 300px"
                        *ngIf="requestedServices === false"
                      >
                        <select
                          multiple
                          data-width="300px"
                          data-live-search="true"
                          class="form-control form-control-sm serviceName selectpicker bootstrap-select-container"
                          id="serviceNameMain"
                          title="Select"
                        >
                          <ng-container
                            *ngFor="let s of allServices[defaultServiceType]"
                          >
                            <option [value]="s.id">{{ s.name }}</option>
                          </ng-container>
                        </select>
                      </div>
                      <div
                        style="float: left; width: 300px"
                        *ngIf="requestedServices === true"
                      >
                        <!--                        (change)='fetchServicesDeptMain($event.target.value, true)'-->
                        <select
                          multiple
                          data-width="300px"
                          data-live-search="true"
                          class="form-control form-control-sm serviceName selectpicker bootstrap-select-container"
                          id="serviceRequested"
                        >
                          <option
                            [disabled]="true"
                            value="-1"
                            [selected]="true"
                          >
                            Select
                          </option>
                          <ng-container *ngFor="let s of requestedServicesList">
                            <option [value]="s.id">{{ s.name }}</option>
                          </ng-container>
                        </select>
                      </div>
                      <div style="float: left">
                        <button
                          (click)="addOtherServicesMain()"
                          [disabled]="disableAddServiceMainBtn"
                          class="btn btn-primary btn-sm ml-3"
                          type="button"
                        >
                          Add
                        </button>
                      </div>
                    </div>

                    <div class="col-3 text-right">
                      <div
                        class="form-group"
                        style="width: 160px; float: right"
                      >
                        <label
                          class="checkbox checkbox-sm"
                          style="float: right"
                        >
                          <input
                            type="checkbox"
                            id="dateRequired"
                            [(ngModel)]="requestedServices"
                            (change)="changeRequestedServices()"
                          />
                          <span class="mr-2"></span>Requested Services
                        </label>
                      </div>
                      <button
                        *ngIf="
                          patient_services.length === 0 &&
                          requestedServices === false
                        "
                        (click)="moreServices()"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                        type="button"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                              <path
                                d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                fill="#000000"
                              />
                            </g></svg
                          ><!--end::Svg Icon-->
                        </span>
                      </button>
                    </div>
                  </div>

                  <div class="">
                    <table
                      class="table serviceTable m-0"
                      *ngIf="patient_services.length > 0"
                    >
                      <thead>
                        <tr>
                          <th class="p-2" style="width: 14%">
                            Service Type <span class="text-danger">*</span>
                          </th>
                          <th class="p-2" style="width: 10%">Sub Dept</th>
                          <th class="p-2" style="width: 15%">
                            Service Name <span class="text-danger">*</span>
                          </th>
                          <!--                        <th class="p-2" style="width: 8%">Department</th>-->
                          <!--                        <th class="p-2" style="width: 8%">Code</th>-->
                          <th class="p-2" style="width: 10%">
                            Ref Doctor
                            <span
                              class="fa fa-plus cursor-pointer ml-1"
                              (click)="addNewReferringDoctor()"
                            ></span>
                          </th>
                          <th class="p-2" style="width: 10%">
                            {{
                              changeTextToRequestedDoctor
                                ? "Specific Radiologist Requested"
                                : "Perf Doctor"
                            }}
                            <!--                          <span *ngIf="!changeTextToRequestedDoctor; else showRadiology">Perf Doctor</span>-->
                            <!--                          <ng-template #showRadiology>Specific Radiologist Requested</ng-template>-->
                          </th>
                          <!--                      <th class="p-2" style="width: 8%">Comments</th>-->
                          <th class="p-2" style="width: 7%">
                            Qty <span class="text-danger">*</span>
                          </th>
                          <th
                            class="p-2"
                            style="width: 9%"
                            *ngIf="showPriceOnService"
                          >
                            Price <span class="text-danger">*</span>
                          </th>
                          <th
                            class="p-2"
                            style="width: 10%"
                            *ngIf="showPriceOnService"
                          >
                            Total Price <span class="text-danger">*</span>
                          </th>
                          <th class="p-2" style="width: 10%">Urgent</th>
                          <th class="p-2" style="width: 5%">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          *ngFor="
                            let service of patient_services;
                            let i = index
                          "
                        >
                          <td class="p-2">
                            <select
                              (change)="
                                fetchServices($event.target.value, i);
                                radiologyCheck($event.target.value);
                                fetchSubDepartments($event.target.value, i)
                              "
                              [disabled]="serviceTypeDisable"
                              [(ngModel)]="service.service_type"
                              [disabled]="requestedServices"
                              class="form-control form-control-sm serviceType"
                              id="serviceType{{ i }}"
                            >
                              <option [disabled]="true" value="">Select</option>
                              <ng-container *ngFor="let s of uniq_services">
                                <option
                                  [value]="s.name"
                                  [selected]="s?.is_default"
                                >
                                  {{ s.name }}
                                </option>
                              </ng-container>
                            </select>
                          </td>

                          <td>
                            <select
                              class="form-control form-control-sm"
                              (change)="fetchSelectedServices(i, false)"
                              [(ngModel)]="service.department_id"
                            >
                              <option value="">Select</option>
                              <option
                                *ngFor="
                                  let t of subDepartmentsAgainstServiceType[i]
                                "
                                [value]="t.id"
                              >
                                {{ t.title }}
                              </option>
                            </select>
                          </td>

                          <td class="p-2 serviceReport">
                            <!--                        <div class="form-group">-->
                            <select
                              (change)="
                                updateServicesDept(i, $event.target.value)
                              "
                              [disabled]="requestedServices"
                              data-container="body"
                              style="width: 300px"
                              data-live-search="true"
                              class="form-control form-control-sm serviceName bootstrap-select-container"
                              data-width="300px"
                              id="serviceName{{ i }}"
                            >
                              <option [selected]="true" value="-1">
                                Select
                              </option>
                              <ng-container
                                *ngIf="service.department_id !== ''"
                              >
                                <ng-container
                                  *ngFor="
                                    let s of allServices[service.service_type]
                                  "
                                >
                                  <ng-container *ngIf="s.department_id">
                                    <option
                                      *ngIf="
                                        service.department_id.toString() ===
                                        s.department_id.toString()
                                      "
                                      [value]="s.id"
                                    >
                                      {{ s.name }}
                                    </option>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="service.department_id === ''"
                              >
                                <ng-container
                                  *ngFor="
                                    let s of allServices[service.service_type]
                                  "
                                >
                                  <option [value]="s.id">{{ s.name }}</option>
                                </ng-container>
                              </ng-container>
                            </select>
                            <div
                              class="d-flex justify-content-between"
                              *ngIf="service.billing_code"
                            >
                              <span>
                                <small
                                  ><strong>Billing Code:</strong>
                                  {{ service.billing_code }}</small
                                >
                              </span>
                              <span *ngIf="!service.commentField">
                                <a
                                  style="color: #3699ff; cursor: pointer"
                                  (click)="commentfield(i)"
                                  ><small
                                    ><u>Comments</u
                                    ><i
                                      class="fa fa-chevron-down"
                                      style="
                                        font-size: 10px;
                                        color: #827d7d;
                                        margin-left: 5px;
                                      "
                                    ></i></small
                                ></a>
                              </span>
                              <span *ngIf="service.commentField">
                                <a
                                  style="color: #3699ff; cursor: pointer"
                                  (click)="commentfield(i)"
                                  ><small
                                    ><u>Comments</u
                                    ><i
                                      class="fa fa-chevron-up"
                                      style="
                                        font-size: 10px;
                                        color: #827d7d;
                                        margin-left: 5px;
                                      "
                                    ></i></small
                                ></a>
                              </span>
                            </div>
                            <div *ngIf="service.commentField">
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                [(ngModel)]="service.comments"
                                placeholder="Comment"
                              />
                            </div>
                            <!--                        </div>-->
                            <div
                              *ngIf="service.reporting_time_required === true"
                              style="padding-left: 11px"
                              class="mt-1 font-size-xs"
                            >
                              <a
                                class="text-primary"
                                *ngIf="service.flight_date_time === ''"
                                (click)="setReportingTimeModal(i)"
                                >Add Reporting Time</a
                              >
                              <a
                                class="text-primary"
                                *ngIf="service.flight_date_time !== ''"
                                (click)="setReportingTimeModal(i)"
                                >{{ service.flight_date_time }}</a
                              >
                            </div>
                          </td>

                          <!--                        <td class="p-2">-->
                          <!--                          <input [(ngModel)]="service.service_dept" [readonly]="true" class="form-control form-control-sm" placeholder="Department" type="text"/>-->
                          <!--                        </td>-->

                          <!--                        <td class="p-2" style="width: 8%">-->
                          <!--                          <input [(ngModel)]="service.billing_code" [readonly]="true" class="form-control form-control-sm" placeholder="Code" type="text"/>-->
                          <!--                        </td>-->

                          <td class="p-2">
                            <div [class]="'referringDoctor' + i">
                              <select
                                [(ngModel)]="service.referring_doctor_id"
                                class="form-control form-control-sm referringDoctors"
                                id="referringDoctor{{ i }}"
                                data-live-search="true"
                                data-width="120px"
                                (change)="updateReferringDoctor(i)"
                              >
                                <option [disabled]="true" value="">
                                  Select
                                </option>
                                <option
                                  *ngFor="let doc of refDoctorsAll"
                                  [value]="doc.id + '/' + doc.employee_type"
                                >
                                  {{ doc.full_name }}
                                  <span *ngIf="doc.department_name">
                                    ({{ doc.department_name }})</span
                                  >
                                </option>
                              </select>
                            </div>
                          </td>

                          <td class="p-2">
                            <div [class]="'performingDoctor' + i">
                              <select
                                [(ngModel)]="service.performing_doctor_id"
                                class="form-control form-control-sm performingDoctors"
                                id="performingDoctor{{ i }}"
                                data-width="120px"
                                data-live-search="true"
                              >
                                <option [disabled]="true" value="">
                                  Select
                                </option>
                                <option
                                  *ngFor="let d of doctorsAll"
                                  [value]="d.id"
                                >
                                  {{ d.first_name }} {{ d.last_name }} ({{
                                    d.doc_dept_name
                                  }})
                                </option>
                              </select>
                            </div>
                          </td>

                          <!--                      <td class="p-2" style="width: 8%">-->
                          <!--                        <input [(ngModel)]="service.comments" class="form-control form-control-sm" placeholder="Comments" type="text"/>-->
                          <!--                      </td>-->

                          <td class="p-2">
                            <input
                              [(ngModel)]="service.quantity"
                              [disabled]="!service.quantity_allowed"
                              id="serviceQuantity{{ i }}"
                              (keyup)="updateServiceQuantity(i)"
                              class="form-control form-control-sm serviceQuantity"
                              (keypress)="_onlyNumeric($event)"
                              placeholder="Quantity"
                              type="number"
                            />
                          </td>

                          <td class="p-2" *ngIf="showPriceOnService">
                            <input
                              [(ngModel)]="service.service_price"
                              [disabled]="!service.editable"
                              (keyup)="updateServicePrice(i)"
                              id="serviceprice{{ i }}"
                              class="form-control form-control-sm"
                              placeholder="service price"
                              type="number"
                            />
                          </td>

                          <td class="p-2" *ngIf="showPriceOnService">
                            <input
                              [(ngModel)]="service.price"
                              [readonly]="true"
                              class="form-control form-control-sm"
                              placeholder="Total"
                              type="number"
                            />
                          </td>

                          <td class="p-2 text-center">
                            <!--                          <input [(ngModel)]="service.urgent" type="checkbox"/>-->
                            <select
                              class="form-control form-control-sm"
                              [(ngModel)]="service.urgent_new"
                            >
                              <option [ngValue]="'routine'">Routine</option>
                              <option [ngValue]="'same_day'">Same Day</option>
                              <option [ngValue]="'emergency'">Emergency</option>
                            </select>
                          </td>

                          <td class="p-2">
                            <button
                              (click)="removeServices(i)"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path
                                      d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                      fill="#000000"
                                      fill-rule="nonzero"
                                    />
                                    <path
                                      d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                      fill="#000000"
                                      opacity="0.3"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td [attr.colspan]="showPriceOnService ? 7 : 6"></td>
                          <td colspan="2" *ngIf="showPriceOnService">
                            Total: {{ servicesTotal }}
                          </td>
                          <td class="p-2 text-right">
                            <button
                              (click)="moreServices()"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                                ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!--                  <div class="p-0 text-right">-->
                  <!--                    <span *ngIf="patient_services.length > 0" class="font-size-h4 font-weight-bold">Total: {{servicesTotal}}</span>-->
                  <!--                    <button (click)="addMoreServices()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-5" type='button'><i class="fas fa-plus p-0"></i></button>-->
                  <!--                  </div>-->
                </div>
              </div>

              <!--                  Calculation area-->

              <div class="card mb-3" *ngIf="showElecgiesFields">
                <div class="card-body service-card p-5">
                  <div class="row">
                    <div class="col-12 mb-4">
                      <label>Indication for Exam</label>
                      <textarea
                        class="form-control form-control-sm"
                        [(ngModel)]="userInfo"
                      ></textarea>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-center mb-4">
                        <label class="checkbox checkbox-sm">
                          <input
                            type="checkbox"
                            [(ngModel)]="anyAllergiesToIodinatedContrast"
                          />
                          <span class="mr-2"></span>Any allergies to iodinated
                          contrast?
                        </label>
                      </div>
                      <div class="mb-4">
                        <div class="d-flex align-items-center">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                [(ngModel)]="
                                  anyContraindicationsToReceiveContrast
                                "
                              />
                              <span class="mr-2"></span>Any contraindications to
                              receive contrast?
                            </label>
                          </div>
                        </div>
                        <textarea
                          class="form-control form-control-sm"
                          [(ngModel)]="
                            anyContraindicationsToReceiveContrastTextarea
                          "
                          placeholder="Any contraindications to receive contrast?"
                          style="min-height: 70px"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-body service-card p-5">
                  <h4 class="m-0">Payment Info</h4>
                  <div class="row mt-5">
                    <div class="col-3">
                      <div class="row m-0">
                        <div class="col-12 mb-5">
                          <label class="" style="vertical-align: middle"
                            >Payment Type</label
                          >
                          <select
                            [disabled]="paymentTypeDisabled"
                            class="form-control form-control-sm"
                            style="max-width: 130px"
                            [(ngModel)]="paymentStatus"
                            (change)="updatePaymentStatus()"
                          >
                            <option value="paid">Paid</option>
                            <option value="unpaid">Un-Paid</option>
                          </select>
                        </div>

                        <div class="col-12 mb-4">
                          <label>Payment Mode</label>
                          <select
                            class="form-control form-control-sm"
                            style="max-width: 130px"
                            [(ngModel)]="serviceForm.mode_of_payment"
                            (change)="paymentMethods($event.target.value)"
                            [disabled]="paymentStatus === 'unpaid'"
                          >
                            <!--                            <option *ngIf="panelType === false" value="Cash">Cash</option>-->
                            <!--                            <option *ngIf="panelType === false" value="Cheque">Cheque</option>-->
                            <!--                            <option *ngIf="panelType === false" value="Credit Card">Credit Card</option>-->
                            <!--                            <option *ngIf="panelType === true" value="Credit">Credit</option>-->
                            <option value="Cash">Cash</option>
                            <option value="Cheque">Cheque</option>
                            <option value="Credit Card">Credit Card</option>
                            <option
                              *ngIf="paymentStatus === 'unpaid'"
                              value="Credit"
                            >
                              Credit
                            </option>
                          </select>
                        </div>

                        <div
                          class="col-12"
                          *ngIf="
                            serviceForm.mode_of_payment === 'Credit Card' ||
                            serviceForm.mode_of_payment === 'Cheque'
                          "
                        >
                          <input
                            [(ngModel)]="serviceForm.payment_details"
                            class="form-control form-control-sm"
                            placeholder="payment detail"
                            type="text"
                          />
                        </div>

                        <!--                        <div class="col-12">-->
                        <!--                          <span *ngIf="panelType === false">-->
                        <!--          <div class="p-0 col-12 font-size-11 text-left">-->
                        <!--            <input (click)="paymentMethod('Cash', $event)" [checked]="serviceForm.mode_of_payment === 'Cash'"-->
                        <!--                   [disabled]="paymentStatus === 'unpaid'"-->
                        <!--                   class="mr-2 invoiceCheckbox"-->
                        <!--                   type="checkbox">Cash-->
                        <!--          </div>-->
                        <!--          <div class="p-0 col-12 font-size-11 text-left">-->
                        <!--            <input (click)="paymentMethod('Cheque', $event)" [checked]="serviceForm.mode_of_payment === 'Cheque'"-->
                        <!--                   [disabled]="paymentStatus === 'unpaid'"-->
                        <!--                   class="mr-2 invoiceCheckbox" type="checkbox">Cheque-->
                        <!--          </div>-->
                        <!--          <div class="p-0 col-12 font-size-11 text-left">-->
                        <!--            <input (click)="paymentMethod('Credit Card', $event)"-->
                        <!--                   [checked]="serviceForm.mode_of_payment === 'Credit Card'"-->
                        <!--                   [disabled]="paymentStatus === 'unpaid'"-->
                        <!--                   class="mr-2 invoiceCheckbox" type="checkbox">Credit Card-->
                        <!--            <input *ngIf="serviceForm.mode_of_payment == 'Credit Card'" [(ngModel)]="serviceForm.payment_details"-->
                        <!--                   class="form-control"-->
                        <!--                   placeholder="payment detail" type="text"/>-->
                        <!--          </div>-->
                        <!--        </span>-->
                        <!--                          <span *ngIf="panelType === true">-->
                        <!--          <div class="p-0 col-12 font-size-11 text-left">-->
                        <!--            <input (click)="paymentMethod('Credit', $event)" [checked]="serviceForm.mode_of_payment === 'Credit'"-->
                        <!--                   class="mr-2 invoiceCheckbox" type="checkbox">Credit-->
                        <!--          </div>-->
                        <!--        </span>-->
                        <!--                        </div>-->
                      </div>
                    </div>

                    <div class="col-7 text-left">
                      <ng-container *ngIf="showPriceOnService">
                        <!--                      <div class="row mx-0 my-2">-->
                        <!--                        <div class="col-4 "  style="margin-top: 5px"><label class="font-weight-bold" style="vertical-align: middle">Payment Type:</label></div>-->
                        <!--                        <div class="col-8 ">-->
                        <!--                          <select class="form-control form-control-sm" style="max-width: 130px" [(ngModel)]="paymentStatus" (change)="updatePaymentStatus()">-->
                        <!--                            <option value="paid">Paid</option>-->
                        <!--                            <option value="unpaid">Un-Paid</option>-->
                        <!--                          </select>-->
                        <!--                        </div>-->
                        <!--                      </div>-->
                        <div class="row">
                          <div class="col-3">
                            <div class="col-12">
                              <label
                                class="font-weight-bold"
                                style="font-size: 1.25rem"
                                >Grand Total:</label
                              >
                            </div>
                            <div class="col-12">
                              <label
                                style="font-size: 1.5rem; color: yellowgreen"
                                ><strong>{{
                                  servicesTotal + consultantionTotal
                                    | number: "1.0-0"
                                }}</strong></label
                              >
                            </div>
                          </div>
                          <div
                            class="col-4"
                            style="border-left: 1px solid #00000045"
                          >
                            <div class="col-12">
                              <label
                                class="font-weight-bold"
                                style="font-size: 1.25rem"
                                >Discount:</label
                              >
                            </div>
                            <div class="col-12">
                              <label style="font-size: 1.5rem; color: red"
                                ><strong>{{
                                  serviceForm.discount | number: "1.0-0"
                                }}</strong></label
                              >
                            </div>
                            <!--                            <div class="row mt-5">-->
                            <!--                              <div class="col-5">-->
                            <!--                                <label>Amount</label>-->
                            <!--                                <input class="form-control form-control-sm discount_amount" type="number" [disabled]="!allowSystemDiscount" [(ngModel)]="serviceForm.discount" (keyup)="calculateDiscountAmount('amount')" />-->
                            <!--                              &lt;!&ndash;                                <small>Discount in Amount</small>&ndash;&gt;-->
                            <!--                              </div>-->
                            <!--                              <div class="col-5">-->
                            <!--                                <label>Percentage</label>-->
                            <!--                                <input class="form-control form-control-sm discount_percentage" type="number" [disabled]="!allowSystemDiscount" [(ngModel)]="serviceForm.discount_percentage" (keyup)="calculateDiscountAmount('percentage')" />-->
                            <!--                              &lt;!&ndash;                                <small>Discount in Percentage</small>&ndash;&gt;-->
                            <!--                              </div>-->
                            <!--                            </div>-->
                          </div>
                          <div
                            class="col-5"
                            style="border-left: 1px solid #00000045"
                          >
                            <div class="col-12">
                              <label
                                class="font-weight-bold"
                                style="font-size: 1.25rem"
                                >Net Amount:</label
                              >
                            </div>
                            <div class="col-12">
                              <label style="font-size: 1.5rem; color: blue"
                                ><strong>
                                  {{
                                    consultantionTotal +
                                      servicesTotal -
                                      serviceForm.discount || 0
                                      | number: "1.0-0"
                                  }}
                                </strong></label
                              >
                            </div>
                            <div class="row mt-5">
                              <div class="col-4">
                                <label>Cash</label>
                                <input
                                  class="form-control form-control-sm"
                                  (keypress)="_onlyNumeric($event)"
                                  [disabled]="
                                    servicesTotal + consultantionTotal === 0 ||
                                    patientTypeCredit === true ||
                                    paymentStatus === 'unpaid'
                                  "
                                  (keyup)="updateServiceReceivable()"
                                  (keypress)="updateServiceReceivable()"
                                  [(ngModel)]="serviceForm.cash"
                                />
                              </div>
                              <div class="col-4">
                                <label>Receivable</label>
                                <input
                                  class="form-control form-control-sm"
                                  disabled
                                  [(ngModel)]="serviceForm.receivable"
                                />
                              </div>
                              <div class="col-4">
                                <label>Ledger</label>
                                <select
                                  class="form-control form-control-sm"
                                  id="consultantLedgerAll"
                                  [disabled]="
                                    serviceForm.receivable < 1 ||
                                    patientTypeCredit === true
                                  "
                                  [(ngModel)]="serviceForm.ledger"
                                >
                                  <option value="" [disabled]="true">
                                    Select
                                  </option>
                                  <ng-container
                                    *ngFor="let account of ledgersList"
                                  >
                                    <option
                                      value="{{ account.id }}"
                                      *ngIf="account.config_type === 'ledger'"
                                    >
                                      {{ account.name }}
                                    </option>
                                  </ng-container>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--                        <div class="d-flex">-->
                        <!--                        <div class="row mx-0 my-2">-->
                        <!--                          <div class="col-12 "><label class="font-weight-bold mt-2">Grand Total:</label></div>-->
                        <!--                          <div class="col-12"><label style="font-size: 20px"><strong>{{servicesTotal + consultantionTotal | number: '1.0-0'}}</strong></label></div>-->
                        <!--                        </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="row mx-0 my-2">-->
                        <!--&lt;!&ndash;                          <div class="col-8 ">&ndash;&gt;-->
                        <!--&lt;!&ndash;                            <div class="">&ndash;&gt;-->
                        <!--                              <div class="col-4" style="margin-top: 15px;">-->
                        <!--                                <label class="font-weight-bold">Discount:</label>-->
                        <!--                              </div>-->
                        <!--                              <div class="col-4">-->
                        <!--                                <label>Amount</label>-->
                        <!--                                <input class="form-control form-control-sm discount_amount" type="number" [(ngModel)]="serviceForm.discount" (keyup)="calculateDiscountAmount('amount')" />-->
                        <!--&lt;!&ndash;                                <small>Discount in Amount</small>&ndash;&gt;-->
                        <!--                              </div>-->
                        <!--                              <div class="col-4">-->
                        <!--                                <label>Percentage</label>-->
                        <!--                                <input class="form-control form-control-sm discount_percentage" type="number" [(ngModel)]="serviceForm.discount_percentage" (keyup)="calculateDiscountAmount('percentage')" />-->
                        <!--&lt;!&ndash;                                <small>Discount in Percentage</small>&ndash;&gt;-->
                        <!--                              </div>-->
                        <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                        <!--&lt;!&ndash;                          </div>&ndash;&gt;-->
                        <!--                        </div>-->
                        <!---->
                        <!--                        <div class="row mx-0 my-2">-->
                        <!--                          <div class="col-4 "><label class="font-weight-bold">Net Amount:</label></div>-->
                        <!--                          <div class="col-8 "><label style="font-size: 20px; color: blue">{{serviceForm.cash}}</label></div>-->
                        <!--                        </div>-->
                        <!---->
                        <!--                        <div class="row mx-0 mt-4">-->

                        <!--                          <div class="col-4">-->
                        <!--                            <label>Cash</label>-->
                        <!--                            <input class="form-control form-control-sm" (keypress)="_onlyNumeric($event)" style="max-width: 130px" [disabled]="servicesTotal + consultantionTotal === 0 || panelType === true || paymentStatus === 'unpaid'"-->
                        <!--                                   (keyup)="updateServiceReceivable()"-->
                        <!--                                   (keypress)="updateServiceReceivable()"-->
                        <!--                                   [(ngModel)]="serviceForm.cash">-->
                        <!--                          </div>-->

                        <!--                          <div class="col-4">-->
                        <!--                            <label>Receivable</label>-->
                        <!--                            <input class="form-control form-control-sm" disabled style="max-width: 130px" [(ngModel)]="serviceForm.receivable">-->
                        <!--                          </div>-->

                        <!--                          <div class="col-4">-->
                        <!--                            <label>Ledger</label>-->
                        <!--                            <select class="form-control form-control-sm" style="max-width: 130px" id="consultantLedgerAll"-->
                        <!--                                    [disabled]="serviceForm.receivable < 1  || panelType === true" [(ngModel)]="serviceForm.ledger">-->
                        <!--                              <option value="" [disabled]="true">Select</option>-->
                        <!--                              <ng-container *ngFor="let account of ledgersList">-->
                        <!--                                <option value="{{account.id}}" *ngIf="account.config_type === 'ledger'">{{account.name}}</option>-->
                        <!--                              </ng-container>-->
                        <!--                            </select>-->
                        <!--                          </div>-->

                        <!--                          &lt;!&ndash;                        <div class="col-4 ">&ndash;&gt;-->
                        <!--                          &lt;!&ndash;                          <label>Cash</label>&ndash;&gt;-->
                        <!--                          &lt;!&ndash;                        </div>&ndash;&gt;-->
                        <!--                          &lt;!&ndash;                        <div class="col-8 ">&ndash;&gt;-->
                        <!--                          &lt;!&ndash;                          <input class="form-control form-control-sm" (keypress)="_onlyNumeric($event)" style="max-width: 130px" [disabled]="servicesTotal + consultantionTotal === 0 || panelType === true || paymentStatus === 'unpaid'"&ndash;&gt;-->
                        <!--                          &lt;!&ndash;                                 (keyup)="updateServiceReceivable()"&ndash;&gt;-->
                        <!--                          &lt;!&ndash;                                 (keypress)="updateServiceReceivable()"&ndash;&gt;-->
                        <!--                          &lt;!&ndash;                                 [(ngModel)]="serviceForm.cash">&ndash;&gt;-->
                        <!--                          &lt;!&ndash;                        </div>&ndash;&gt;-->

                        <!--                        </div>-->

                        <!--                      <div class="row mx-0 my-2">-->
                        <!--                        <div class="col-4 ">-->
                        <!--                          <label>Receivable:</label>-->
                        <!--                        </div>-->
                        <!--                        <div class="col-8 ">-->
                        <!--                          <input class="form-control form-control-sm" disabled style="max-width: 130px"-->
                        <!--                                 [(ngModel)]="serviceForm.receivable">-->
                        <!--                        </div>-->
                        <!--                      </div>-->

                        <!--                      <div class="row mx-0 my-2">-->
                        <!--                        <div class="col-4 ">-->
                        <!--                          <label>Ledger</label>-->
                        <!--                        </div>-->
                        <!--                        <div class="col-8 ">-->
                        <!--                          <select class="form-control form-control-sm" style="max-width: 130px" id="consultantLedgerAll"-->
                        <!--                                  [disabled]="serviceForm.receivable < 1  || panelType === true" [(ngModel)]="serviceForm.ledger">-->
                        <!--                            <option value="" [disabled]="true">Select</option>-->
                        <!--                            <ng-container *ngFor="let account of ledgersList">-->
                        <!--                              <option value="{{account.id}}" *ngIf="account.config_type === 'ledger'">{{account.name}}</option>-->
                        <!--                            </ng-container>-->
                        <!--                          </select>-->
                        <!--                        </div>-->
                        <!--                      </div>-->
                      </ng-container>
                    </div>

                    <div class="col-2 text-right">
                      <button
                        (click)="backRoute()"
                        class="btn btn-primary"
                        type="button"
                      >
                        Back
                      </button>
                      <button
                        *ngIf="showConsultantCard || showServiceCard"
                        (click)="addServiceConfirm()"
                        class="btn btn-primary pull-right ml-3"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- hHistory tab -->
            <div
              class="tab-pane fade"
              id="history_tab"
              role="tabpanel"
              aria-labelledby="history_tab"
              *ngIf="historyTab === true"
            >
              <app-services-history [patient]="patient"></app-services-history>
            </div>
          </div>

          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>

      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<div class="example-preview">
  <div>
    <ng-template #serviceAddConfirm let-c="close" let-d="dismiss">
      <form>
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <a class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </a>
        </div>
        <div class="modal-body">
          <div>
            <!--            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" >-->
            Are you sure you want to add service!<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <a class="btn btn-sm btn-light-primary" (click)="c('Close click')"
            >Close</a
          >
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            [disabled]="isSubmitted"
            (submit)="addService()"
            (click)="addService()"
          >
            Confirm
          </button>
        </div>
      </form>
    </ng-template>
  </div>
</div>

<!--Update Performing Doc-->
<div class="example-preview">
  <div>
    <ng-template #updatePerformingModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Update Performing Doctor!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body custom-card-body">
          <div class="row">
            <div class="col-6">
              <select
                class="form-control form-control-sm"
                [(ngModel)]="selectedPerformingID"
              >
                <option value=""></option>
                <option *ngFor="let doc of doctorsAll" [value]="doc.id">
                  {{ doc.first_name }} {{ doc.last_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="closePerformingModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            (click)="updatePerforming()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Update Referring Doc-->
<div class="example-preview">
  <div>
    <ng-template #updateReferringModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Update Referring Doctor!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body custom-card-body">
          <div class="row">
            <div class="col-6">
              <select
                class="form-control form-control-sm"
                [(ngModel)]="selectedReferringID"
              >
                <option value=""></option>
                <option *ngFor="let doc of doctorsAll" [value]="doc.id">
                  {{ doc.first_name }} {{ doc.last_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="closeReferringModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            (click)="updateReferring()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div
  class="modal fade"
  id="reportingTimeModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Time</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Date/Time <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control form-control-sm datetimepicker-input"
            id="reportingTime"
            data-toggle="datetimepicker"
            data-target="#reportingTime"
            (keydown)="preventChangeDate()"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="setReportingTime()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="referringDoctorModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add New Referring Doctor</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          [formGroup]="employeeVisitForm"
          (ngSubmit)="onSubmitReferringDoctorAdd()"
        >
          <div class="row">
            <!--       1- Title-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Title <span class="text-danger">*</span></label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      isSubmitReferring &&
                      this.employeeVisitForm.controls.title.errors
                  }"
                >
                  <select
                    class="form-control form-control-sm bootstrap-select-container"
                    id="title"
                    data-container="body"
                    data-live-search="true"
                    formControlName="title"
                  >
                    <option value="''" [disabled]="true">Select title</option>
                    <ng-container *ngFor="let titles of titleList">
                      <option [value]="titles.id">{{ titles.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <!--        2- First Name-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>First Name <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm allowaplhabets"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  formControlName="first_name"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitReferring &&
                      this.employeeVisitForm.controls.first_name.errors
                  }"
                />
              </div>
            </div>
            <!--   3- Last Name-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  name="last_name"
                  placeholder="Last Name"
                  formControlName="last_name"
                />
              </div>
            </div>

            <!--        4- Gender*-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Gender <span class="text-danger">*</span></label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      isSubmitReferring &&
                      this.employeeVisitForm.controls.gender.errors
                  }"
                >
                  <select
                    name="gender"
                    id="gender"
                    class="form-control form-control-sm bootstrap-select-container"
                    formControlName="gender"
                    data-container="body"
                    data-live-search="true"
                  >
                    <option [value]="''" [disabled]="true">
                      Select Gender
                    </option>
                    <ng-container *ngFor="let gender of genderList">
                      <option [value]="gender.id">{{ gender.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>

            <!--        4- Speciality*-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Speciality</label>
                <div>
                  <select
                    name="gender"
                    id="speciality"
                    class="form-control form-control-sm"
                    formControlName="speciality"
                    data-container="body"
                    data-live-search="true"
                  >
                    <option [value]="''" [disabled]="true">
                      Select Speciality
                    </option>
                    <ng-container
                      *ngFor="let emp of allEmployeesStaffTypeDoctor"
                    >
                      <option [value]="emp.id">
                        {{ emp.first_name }} {{ emp.middle_name || "" }}
                        {{ emp.last_name }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>

            <!--        5- National ID card-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>National ID Card No</label>
                <input
                  type="text"
                  (keypress)="_onlyNumeric($event)"
                  class="form-control form-control-sm"
                  placeholder="National ID Card No"
                  name="nic"
                  id="nic"
                  formControlName="nic"
                />
              </div>
            </div>
            <!--        PMDC registration number-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>PMDC Registration Number</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="PMDC Registration Number"
                  name="nic"
                  id="pmdc_registration_no"
                  formControlName="pmdc_registration_no"
                />
              </div>
            </div>
            <!--        6- Primary Contact-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Primary Contact</label>
                <input
                  type="tel"
                  class="form-control form-control-sm"
                  (keypress)="_onlyNumeric($event)"
                  maxlength="11"
                  placeholder="Primary Contact"
                  name="primary_contact"
                  id="primary_contact"
                  formControlName="primary_contact"
                />
              </div>
            </div>
            <!--        Hospital name-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Clinic / Hospital name </label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="hospital_name"
                  placeholder="Hospital name"
                />
              </div>
            </div>
            <!--Country-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Country <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm country"
                  id="country"
                  data-live-search="true"
                  data-container="body"
                  (change)="updateCountryFun($event.target.value)"
                  formControlName="country"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitReferring &&
                      this.employeeVisitForm.controls.country.errors
                  }"
                >
                  <option value="" [selected]="true">select</option>
                  <ng-container *ngFor="let country of countries">
                    <option [value]="country.id">{{ country.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>State <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm"
                  formControlName="state"
                  (change)="updateStateFun($event.target.value)"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitReferring &&
                      this.employeeVisitForm.controls.state.errors
                  }"
                >
                  <option value="" [disabled]="true">Select</option>
                  <ng-container *ngFor="let state of states">
                    <option [value]="state.id">{{ state.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>City <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm"
                  formControlName="city"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitReferring &&
                      this.employeeVisitForm.controls.city.errors
                  }"
                >
                  <option value="" [disabled]="true">Select</option>
                  <ng-container *ngFor="let city of cities">
                    <option [value]="city.id">{{ city.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--        7- Address*-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Address <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="address"
                  placeholder="Address"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitReferring &&
                      employeeVisitForm.controls.address.errors
                  }"
                />
              </div>
            </div>
            <!-- Office Number-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Doctor Office Number</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="office_number"
                  placeholder="Office Number"
                />
              </div>
            </div>
            <!-- Mobile Number-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Doctor Mobile Number</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="mobile_number"
                  placeholder="Mobile Number"
                />
              </div>
            </div>
            <!--Staff Type-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Staff Type <span class="text-danger">*</span></label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      isSubmitReferring &&
                      this.employeeVisitForm.controls.staff_type.errors
                  }"
                >
                  <select
                    class="form-control form-control-sm bootstrap-select-container"
                    name="staff_type"
                    id="staff_type"
                    formControlName="staff_type"
                    data-container="body"
                    data-live-search="true"
                  >
                    <option [value]="''">Select Staff Type</option>
                    <ng-container *ngFor="let stafftype of staffTypeList">
                      <option [value]="stafftype.id">
                        {{ stafftype.name }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <!--  9- Department*-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Department <span class="text-danger">*</span></label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      isSubmitReferring &&
                      this.employeeVisitForm.controls.department.errors
                  }"
                >
                  <select
                    name="department"
                    id="refDepartment"
                    class="form-control form-control-sm bootstrap-select-container selectPickerDepartment"
                    data-container="body"
                    data-live-search="true"
                    formControlName="department"
                  >
                    <option [value]="''" [disabled]="true">
                      Select Department
                    </option>
                    <ng-container *ngFor="let dep of departments">
                      <option [value]="dep.id">{{ dep.title }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <!--        10- Designation*-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <div class="form-group">
                <label>Designation <span class="text-danger">*</span></label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      isSubmitReferring &&
                      this.employeeVisitForm.controls.designation.errors
                  }"
                >
                  <select
                    name="designation"
                    id="designation"
                    class="form-control form-control-sm selectPickerDesignation bootstrap-select-container"
                    data-container="body"
                    data-live-search="true"
                    formControlName="designation"
                  >
                    <option [value]="''" [disabled]="true">
                      Select Designation
                    </option>
                    <ng-container *ngFor="let desig of designationList">
                      <option [value]="desig.id">{{ desig.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="onSubmitReferringDoctorAdd()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<!--Room Number Modal-->
<div
  class="modal fade"
  id="updateRoomNumberModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <label>Room Number</label>
          <input
            type="number"
            class="form-control form-control-sm"
            min="1"
            placeholder="0"
            [(ngModel)]="roomNumberUpdated"
            (keypress)="onlyNumeric($event)"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          [disabled]="isUpdateRoomNumber"
          (click)="saveDoctorRoomNumber()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
