<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div
    class="subheader py-2 py-lg-6 subheader-solid d-print-none"
    id="kt_subheader"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-center justify-content-between flex-wrap mr-5 w-100"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
<!--          <div class="d-flex align-items-center">-->
<!--            <div class="dropdown dropdown-inline mr-2">-->
<!--              <button-->
<!--                type="button"-->
<!--                class="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle"-->
<!--                data-toggle="dropdown"-->
<!--                aria-haspopup="true"-->
<!--                aria-expanded="true"-->
<!--              >-->
<!--                <span class="svg-icon svg-icon-md">-->
<!--                  &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Design/PenAndRuller.svg&ndash;&gt;-->
<!--                  <svg-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                    xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--                    width="24px"-->
<!--                    height="24px"-->
<!--                    viewBox="0 0 24 24"-->
<!--                    version="1.1"-->
<!--                  >-->
<!--                    <g-->
<!--                      stroke="none"-->
<!--                      stroke-width="1"-->
<!--                      fill="none"-->
<!--                      fill-rule="evenodd"-->
<!--                    >-->
<!--                      <rect x="0" y="0" width="24" height="24"></rect>-->
<!--                      <path-->
<!--                        d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"-->
<!--                        fill="#000000"-->
<!--                        opacity="0.3"-->
<!--                      ></path>-->
<!--                      <path-->
<!--                        d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"-->
<!--                        fill="#000000"-->
<!--                      ></path>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                  &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                </span>-->
<!--                Export-->
<!--              </button>-->
<!--              &lt;!&ndash;begin::Dropdown Menu&ndash;&gt;-->
<!--              <div-->
<!--                class="dropdown-menu dropdown-menu-sm dropdown-menu-right"-->
<!--                style=""-->
<!--              >-->
<!--                &lt;!&ndash;begin::Navigation&ndash;&gt;-->
<!--                <ul class="navi flex-column navi-hover py-2">-->
<!--                  <li-->
<!--                    class="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2"-->
<!--                  >-->
<!--                    Choose an option:-->
<!--                  </li>-->
<!--                  <li class="navi-item cursor-pointer">-->
<!--                    <a class="navi-link">-->
<!--                      <span class="navi-icon">-->
<!--                        <i class="la la-print"></i>-->
<!--                      </span>-->
<!--                      <span class="navi-text" (click)="printReport()"-->
<!--                      >Print</span-->
<!--                      >-->
<!--                    </a>-->
<!--&lt;!&ndash;                    <a class="navi-link" (click)="downloadCSV()">&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="navi-icon">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="fas fa-file-alt"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                      </span>&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="navi-text">CSV</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </a>&ndash;&gt;-->
<!--                  </li>-->
<!--                </ul>-->
<!--                &lt;!&ndash;end::Navigation&ndash;&gt;-->
<!--              </div>-->
<!--              &lt;!&ndash;end::Dropdown Menu&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
<!--      <div class="card card-custom gutter-b d-print-none">-->
<!--        &lt;!&ndash;begin::Body&ndash;&gt;-->
<!--        <div class="card-body">-->
<!--          &lt;!&ndash;begin::Table&ndash;&gt;-->
<!--          <div-->
<!--            class="table_top_bar mb-3 hide-print-content d-block d-print-none"-->
<!--          >-->
<!--            <div class="row">-->
<!--              &lt;!&ndash;Starting Date (Day, Month , Year):&ndash;&gt;-->
<!--              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--                <div class="form-group">-->
<!--                  <label>Start Date <span class="text-danger">*</span></label>-->
<!--                  <input-->
<!--                    type="text"-->
<!--                    class="form-control form-control-sm start_date"-->
<!--                    placeholder="Start Date"-->
<!--                    readonly="readonly"-->
<!--                    [(ngModel)]="startDate"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; End Date (Day, Month , Year)  &ndash;&gt;-->

<!--              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--                <div class="form-group">-->
<!--                  <label>End Date <span class="text-danger">*</span></label>-->
<!--                  <input-->
<!--                    type="text"-->
<!--                    class="form-control form-control-sm end_date"-->
<!--                    placeholder="End Date"-->
<!--                    readonly="readonly"-->
<!--                    [(ngModel)]="endDate"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;Department&ndash;&gt;-->
<!--              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--                <div class="d-block form-group">-->
<!--                  <label>Department:</label>-->
<!--                  <select-->
<!--                    class="form-control form-control-sm selectpicker"-->
<!--                    data-live-search="true"-->
<!--                    id="department"-->
<!--                    name="department"-->
<!--                    [(ngModel)]="department"-->
<!--                    (change)="getEmployeesOfDepartment()"-->
<!--                  >-->
<!--                    <option value="">Select Department</option>-->
<!--                    <ng-container *ngFor="let dep of depart">-->
<!--                      <option [value]="dep.id">{{ dep.title }}</option>-->
<!--                    </ng-container>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;Designation&ndash;&gt;-->
<!--              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--                <div class="d-block form-group">-->
<!--                  <label>Designation:</label>-->
<!--                  <select-->
<!--                    class="form-control form-control-sm selectpicker"-->
<!--                    data-live-search="true"-->
<!--                    id="designation"-->
<!--                    name="designation"-->
<!--                    [(ngModel)]="designations"-->
<!--                  >-->
<!--                    <option value="">Select Designation</option>-->
<!--                    <ng-container *ngFor="let desig of designation">-->
<!--                      <option [value]="desig.id">{{ desig.name }}</option>-->
<!--                    </ng-container>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->

<!--              &lt;!&ndash;Employee&ndash;&gt;-->
<!--              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">-->
<!--                <div class="form-group">-->
<!--                  <label>Employee:</label>-->
<!--                  <select-->
<!--                    class="form-control form-control-sm selectpicker"-->
<!--                    data-live-search="true"-->
<!--                    data-container="body"-->
<!--                    id="employee"-->
<!--                    name="department"-->
<!--                    [(ngModel)]="employee"-->
<!--                  >-->
<!--                    <option value="">Select Employee</option>-->
<!--                    <ng-container *ngFor="let emp of employees">-->
<!--                      <option [value]="emp.id">-->
<!--                        {{ emp?.employee_file_no }} - {{ emp?.first_name }}-->
<!--                        {{ emp?.middle_name || "" }} {{ emp?.last_name }}-->
<!--                      </option>-->
<!--                    </ng-container>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;Salary Model&ndash;&gt;-->
<!--              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--                <div class="form-group">-->
<!--                  <label>Status:</label>-->
<!--                  <select-->
<!--                    class="form-control form-control-sm selectpicker"-->
<!--                    name="salary_model"-->
<!--                    id="salary_model"-->
<!--                    data-live-search="true"-->
<!--                    [(ngModel)]="status"-->
<!--                  >-->
<!--                    <option value="all">All</option>-->
<!--                    <option value="late_in">Late In</option>-->
<!--                    <option value="early_out">Early Out</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;Search Filter&ndash;&gt;-->
<!--              <div class="col-12 col-sm-3">-->
<!--                <div class="form-group">-->
<!--                  <div class="mt-3 text-left">-->
<!--                    <a-->
<!--                      class="btn btn-light-primary btn-sm px-6 font-weight-bold"-->
<!--                      ngbTooltip="Search Filter"-->
<!--                      placement="bottom"-->
<!--                      (click)="searchReport()"-->
<!--                    >Search</a-->
<!--                    >-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--              &lt;!&ndash;              <div class="col-12 col-sm-3">&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="d-flex align-items-center justify-content-between form-group">&ndash;&gt;-->
<!--              &lt;!&ndash;                  <label class="mr-3 mb-0 d-none d-md-block">Show Terminated Only:</label>&ndash;&gt;-->
<!--              &lt;!&ndash;                  <span class="switch switch-icon">&ndash;&gt;-->
<!--              &lt;!&ndash;                    <label>&ndash;&gt;-->
<!--              &lt;!&ndash;                      <input type="checkbox" name="select" [(ngModel)]="showterminated" />&ndash;&gt;-->
<!--              &lt;!&ndash;                      <span></span>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </label>&ndash;&gt;-->
<!--              &lt;!&ndash;                  </span>&ndash;&gt;-->
<!--              &lt;!&ndash;                </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              <div class="col-12">&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="mt-3 text-right">&ndash;&gt;-->
<!--              &lt;!&ndash;                  <a class="btn btn-light-primary btn-sm px-6 font-weight-bold" (click)="searchFilterValue()" ngbTooltip="Search Filter" placement="bottom">Search</a>&ndash;&gt;-->
<!--              &lt;!&ndash;                </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div
        class="card card-custom gutter-b d-block pdf-card"
        *ngIf="reportData?.length"
      >
        <div class="card-body">
          <div
            style="width: 100%; border-bottom: 3px solid #000"
            class="d-none d-print-block"
          >
            <!-- header table -->
            <table class="table" *ngIf="stateOfHeader === 'center'">
              <tbody>
              <tr class="borders">
                <td style="width: 15%; border-top: 0 !important">
                  <img
                    [src]="settingData?.logo?.url"
                    style="width: auto; height: 106px"
                  />
                </td>
                <td style="width: 70%; border-top: 0 !important">
                  <div class="text-center">
                    <h3>{{ settingData?.title }}</h3>
                  </div>
                  <div class="font-size-h5 text-center">
                    {{ settingData?.address }}
                  </div>
                  <div class="font-size-h5 text-center">
                    Late in & Early Out Report
                  </div>
                </td>
                <td style="width: 15%; border-top: 0 !important">></td>
              </tr>
              </tbody>
            </table>
            <table class="table" *ngIf="stateOfHeader === 'right'">
              <tbody>
              <tr class="borders">
                <td style="width: 15%; border-top: 0 !important">
                  <img
                    [src]="settingData?.logo?.url"
                    style="width: auto; height: 106px"
                  />
                </td>
                <td style="width: 70%; border-top: 0 !important">
                  <div class="text-right">
                    <h3>{{ settingData?.title }}</h3>
                  </div>
                  <div class="font-size-h5 text-right">
                    {{ settingData?.address }}
                  </div>
                  <div class="font-size-h5 text-right">
                    Late in & Early Out Report
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

            <!--            <div style="page-break-before: always"></div>-->

              <table
                class="table table-vertical-center table-head-custom"
                id="kt_advance_table_widget_1"
              >
                <thead>
                <tr>
                  <th
                    style="
                        width: 5%;
                      "
                  >
                    Sr#
                  </th>
                  <th
                    style="
                        width: 7%
                      "
                  >
                    Emp ID
                  </th>
                  <th style="width: 15%">
                    Employee Name / File No.
                  </th>
                  <th style="width: 15%">
                    Designation
                  </th>
                  <th style="width: 10%">
                    Total Late-In / Short Hours
                  </th>
                  <th style="width: 11%">
                    Total Deduction
                  </th>
                  <th style="width: 10%">
                     Deduction From Leave
                  </th>
                  <th style="width: 10%">
                     Deduction From Working Days
                  </th>
                  <th style="width: 7%;text-align: center">
                    Late In
                  </th>
                  <th style="width: 8%;text-align: center">
                    Short Hours
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let employee of reportData; let i = index">
                  <td
                    style="
                        text-align: left;
                      "
                  >
                    {{i+1 }}
                  </td>
                  <td
                    style="
                        text-align: left;
                      "
                  >
                    {{ employee?.id }}
                  </td>
                  <td
                    style="
                        text-align: left;
                      "
                  >
                    <span class="font-weight-bold d-block">{{ employee?.name }}</span>
                    <span class="text-muted font-weight-bold">{{ employee?.employee_code }}</span>
                  </td>
                  <td
                    style="
                        text-align: left;
                      "
                  >
                    {{ employee?.designation }}
                  </td>
                  <td
                    style="
                        text-align: center;
                      "
                  >
                    <span class="d-block">{{ employee?.late_in_count }}</span>
                    <span>{{employee?.short_hours}}</span>
                  </td>
                  <td
                    style="
                        text-align: center;
                      "
                  >
                    {{ employee?.total_deduction }}
                  </td>
                  <td
                    style="
                        text-align: center;
                      "
                  >
                    {{ employee?.deduction_from_leave }}
                  </td>
                  <td
                    style="
                        text-align: center;
                      "
                  >
                    {{ employee?.deduction_from_working_days }}
                  </td>
                  <td
                    style="
                        text-align: center;
                      "
                  >
                    <label
                      class="checkbox checkbox-sm justify-content-center"
                    >
                      <input class="printCheckbox" type="checkbox"  id="late_in{{i}}" [(ngModel)]="employee.late_in"/>
                      <span></span>
                    </label>
                  </td>
                  <td
                    style="
                        text-align: center;
                      "
                  >
                    <label
                      class="checkbox checkbox-sm justify-content-center"
                    >
                      <input class="printCheckbox" type="checkbox"/>
                      <span></span>
                    </label>
                  </td>
                </tr>
                </tbody>
              </table>

          <div class="row mt-4 d-print-none">
            <div class="col-12 text-right">
              <button type="button" class="btn btn-sm btn-primary" (click)="submit()">Submit</button>
            </div>
          </div>
          <!--pagination-->
          <!--          <div class="pagination_main d-print-none">-->
          <!--            <ngb-pagination [(page)]="page" (pageChange)="loadPage(page)" [pageSize]="perPage"-->
          <!--                            [collectionSize]="totalPages" [maxSize]="5" [rotate]="true" [ellipses]="false"-->
          <!--                            [boundaryLinks]="true"></ngb-pagination>-->
          <!--            <div class="dropdown">-->
          <!--              <a class="btn dropdown-toggle btn-light btn-sm" role="button" id="pagination_btn" data-toggle="dropdown"-->
          <!--                 aria-haspopup="true" aria-expanded="false">-->
          <!--                    <span class="page_count">-->
          <!--                        {{perPageValue}}-->
          <!--                    </span>-->
          <!--                <span class="count_count_icon"></span>-->
          <!--              </a>-->
          <!--              <div class="dropdown-menu" aria-labelledby="pagination_btn">-->
          <!--                <a class="dropdown-item" (click)="selectPaginationLimit(25)">25</a>-->
          <!--                <a class="dropdown-item" (click)="selectPaginationLimit(50)">50</a>-->
          <!--                <a class="dropdown-item" (click)="selectPaginationLimit(75)">75</a>-->
          <!--                <a class="dropdown-item" (click)="selectPaginationLimit(100)">100</a>-->
          <!--                <a class="dropdown-item" (click)="selectPaginationLimit('')">All</a>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
