import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientService } from '../../../services/patient.service';
import { OpdService } from '../../../services/opd.service';
import { StockService } from '../../../services/stock.service';
import { PatientAdmissionService } from '../../../services/patient-admission.service';
import { PatientHistoryService } from '../../../services/patient-history.service';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { formatDate } from '@angular/common';

declare var $: any;
declare var toastr: any;
declare var Chart: any;

@Component({
  selector: 'app-history-dashboard',
  templateUrl: './history-dashboard.component.html',
  styleUrls: ['./history-dashboard.component.css'],
})
export class HistoryDashboardComponent implements OnInit {
  PatientRegistrationForm: UntypedFormGroup;
  PatientSearchForm: UntypedFormGroup;
  ParentPatientSearchForm: UntypedFormGroup;
  patientComplaintForm: UntypedFormGroup;
  patientComplaint: any;
  patient_complaints: any;
  patient_gynaecological_histories_attributes: any;
  patient_obstractical_history: any;
  patient_breast_examination: any;
  patientGeneralHistoryForm: UntypedFormGroup;
  reviewSystemHistoryForm: UntypedFormGroup;
  physicalSystemicExaminationHistoryForm: UntypedFormGroup;
  patientCentralNervousSystemForm: UntypedFormGroup;
  patientHistorySourceForm: UntypedFormGroup;
  patientHistorySource: any;
  allergy: {};
  patientGeneralHistory: any;
  reviewSystemHistory: any;
  physicalSystemicExaminationHistory: any;
  patientCentralNervousSystem: any;
  submitted = false;
  isPatients = false;
  submittedPC = false;
  patient: any;
  patients = [];
  family_patient = '';
  patient_age: any;
  selected_age_type = 'years';
  age_value: any;
  patient_dob: any;
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  pricing = [''];
  current_environment: string;
  patientSearchWard = '';
  isMedShown = false;
  isCNSShown = false;
  isSysTabsShown = false;
  isPETabsShown = false;
  isGHShown = false;
  clearTimeout: any;
  paitentAllergiesSearch = '';
  allergiesListData = [];
  allergiesSearchResult = false;
  selectedMedicine = '';
  productListData = [];
  productSearchResult = false;
  productSearch = '';
  addMedicine = [];
  paitentMedicineHistory = [];
  servicesPrintRecent = true;
  presentingComplaintExist = false;
  patientHistorySourceExist = false;
  patient_complaint_id: any;
  patient_history_source_id: any;
  patientGeneralHistoryExist = false;
  patient_general_history_id: any;
  reviewSystemHistoryExist = false;
  review_system_history_id: any;
  patientCentralNervousSystemExist = false;
  central_nervous_system_id: any;
  physicalSystemicExaminationHistoryExist = false;
  physical_systemic_examination_id: any;
  patientAllergies = [];
  searchProductValue = '';
  selectedProductItem: any;
  idOfSingleProduct: any;
  productSearchValue = [''];
  searchValue: any;
  eventOfSearchBar: any;
  patientAllergy: string;
  diagnosisSearchResultMain = false;
  mainDiagnosisListAdd = {
    name: '',
  };
  allDiagnosisDataMain = [];
  mainDiagnosisAdd = [];

  name = 'slideToggle';
  id = 'materialSlideToggle';
  checked = false;
  disabled = false;
  vitalsByDate = [];
  createdDates = [];
  resultData = {};
  patientMRN: any;
  page: any;

  activeTab = 'Vitals';

  showAllTabs = true;

  isShowAll = false;
  paitestDiagnosisHistoryData = [];
  paitestDiagnosisHistory = [];
  patientAllHistory = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private opdService: OpdService,
    private stockService: StockService,
    private patientAdmissionService: PatientAdmissionService,
    private patientHistoryService: PatientHistoryService,
  ) {}

  ngOnInit(): any {
    this.ngxLoader.stop();

    this.route.queryParams.subscribe((params) => {
      this.patientMRN = +params['mrn'];
      this.page = params['page'];
    });

    if (this.patientMRN && (this.page === 'ipd' || this.page === 'radiology')) {
      this.ParentPatientSearchForm = this.formBuilder.group({
        search_query: [this.patientMRN],
        type: ['mrn'],
      });
    } else {
      this.router.navigate(['dashboard']);
    }

    this.PatientSearchForm = this.formBuilder.group({
      search_query: [''],
      type: ['default'],
    });

    this.patientHistorySourceForm = this.formBuilder.group({
      taken_from: ['', Validators.required],
      name: ['', Validators.required],
      diagnosis_value: [''],
    });

    this.patientComplaintForm = this.formBuilder.group({
      presenting_complaints: ['', Validators.required],
    });

    this.patientGeneralHistoryForm = this.formBuilder.group({
      patient_complaints: this.formBuilder.group({
        id: [''],
        married_for: [''],
        lmp: [''],
        edd: [''],
        gestational_age: [''],
        other: [''],
        presenting_complaints: [''],
        allergies: [''],
      }),
      patient_gynaecological_histories_attributes: this.formBuilder.group({
        id: [''],
        age_of_minarche: [''],
        menstrual_cycle: [''],
        length: [''],
        imb: [''],
        contraception: [''],
        pop_smear: [''],
        dysmenorrhea: [''],
        dyspareunia: [''],
        other: [''],
      }),
      patient_obstetrical_histories_attributes: this.formBuilder.group({
        g: [''],
        p: [''],
        a: [''],
        last_child_born: [''],
        mode_of_delivery: [''],
        other: [''],
        id: [''],
      }),
      patient_past_history_attributes: this.formBuilder.group({
        id: '',
        diabetes: false,
        diabetes_duration: '',
        hypertention: false,
        hypertention_duration: '',
        IHD: false,
        IHD_duration: '',
        operation: false,
        operation_details: '',
        major_illness: false,
        major_illness_details: '',
        other: false,
        other_details: '',
        hypertention_details: '',
        IHD_details: '',
        diabetes_details: '',
      }),
      patient_family_histories_attributes: this.formBuilder.group({
        id: '',
        DM: false,
        hypertention: false,
        IHD: false,
        malignancy: false,
        arthritis: false,
        other: '',
      }),
      patient_social_history_attributes: this.formBuilder.group({
        id: '',
        alcohol: false,
        smoker: false,
        other: false,
        other_details: '',
      }),
      patient_birth_histories_attributes: this.formBuilder.group({
        id: '',
        details: '',
      }),
    });

    this.reviewSystemHistoryForm = this.formBuilder.group({
      patient_general_health_history_attributes: this.formBuilder.group({
        id: '',
        weight_loss: false,
        fatigue_tiredness: false,
        fever_chills: false,
        night_sweats: false,
        other: false,
        other_details: '',
      }),
      patient_ent_history_attributes: this.formBuilder.group({
        id: '',
        glaucoma: false,
        visual_difdiculty: false,
        hearing_problem: false,
        swollen_glands: false,
        recurrent_sore_throat: false,
      }),
      patient_cardiology_history_attributes: this.formBuilder.group({
        id: '',
        heart_problem: false,
        high_blood_pressure: false,
        stroke: false,
        PCI: false,
        other: false,
        other_details: '',
      }),
      patient_respiratory_history_attributes: this.formBuilder.group({
        id: '',
        shirtness_of_breath: false,
        asthma: false,
        chronic_cought: false,
        lung_disease: false,
        heamoptysis: false,
      }),
      patient_neuro_history_attributes: this.formBuilder.group({
        id: '',
        seizures: false,
        headache: false,
        vertigo: false,
      }),
      patient_skin_history_attributes: this.formBuilder.group({
        id: '',
        rash: false,
        pigmentation: false,
      }),
    });

    this.physicalSystemicExaminationHistoryForm = this.formBuilder.group({
      patient_gpe_history_attributes: this.formBuilder.group({
        id: '',
        pallor: false,
        cyanosis: false,
        pedal: false,
        clubbing: false,
        rash: false,
        peropheral_vessel_palpable: false,
        lymphadenopathy: false,
      }),
      patient_heent_history_attributes: this.formBuilder.group({
        id: '',
        head: '',
        eye: '',
        ent: '',
        neck: 'Soft',
        neck_viens: 'Distended',
        enlarge_thyroid: false,
        carotid_bruit: false,
      }),
      patient_breast_examination_attributes: this.formBuilder.group({
        breast_examination: [''],
      }),
      patient_chest_history_attributes: this.formBuilder.group({
        id: '',
        chest_defermity: false,
        breathing: false,
        vesicular: false,
        bronchial: false,
        added_sounds: false,
        rhonchi: false,
        wheeeze: false,
        crepitations: false,
      }),
      patient_abdomen_history_attributes: this.formBuilder.group({
        id: '',
        soft: false,
        distended: false,
        ascities: false,
        bowel_sounds: false,
        hepatomegaly: false,
        spleenomegaly: false,
        inguinal_hernia: false,
        umbilical: false,
        tenderness: false,
        grade: '',
        area: '',
        genital_examination: '',
        other: '',
        pelvic_genitalia: '',
        rectal: '',
        extremities: '',
        musculoskeletal: '',
        palpable_contractions: '',
        fhs: '',
        scar_marks: '',
        ps_examination: '',
        pv_bm_examination: '',
      }),
      patient_cardiovascular_history_attributes: this.formBuilder.group({
        id: '',
        apex_beat: '',
      }),
    });

    this.patientCentralNervousSystemForm = this.formBuilder.group({
      gcs: '',
      confused: false,
      oriented: false,
      mood: '',
      speech: '',
      congnition: '',
      memory_recent: 'Intact',
      memory_past: 'Intact',
      pupil: 'non reactive',
      sensory_system: false,
      sensory_system_detail: '',
      motor_system: false,
      motor_system_detail: '',
      kerning: false,
      neck_stiffness: false,
      other: '',
    });

    this.current_environment = environment.rorUrl;

    this.fetchPatients();
    this.showsAll();

    $('html').click(function () {
      $('.search-result-main').hide();
    });

    let contax = this;
    setTimeout(function () {
      $('#tags').tagsinput({});
      $('.bootstrap-tagsinput input').keyup(function (event) {
        contax.getAllergiesList();
      });
    }, 1000);
  }

  selectSideTabs(id, removeClass, tab): any {
    this.activeTab = tab;
    $('.' + removeClass + ' a').removeClass('activeSideTabs');
    $('#' + id + ' a').addClass('activeSideTabs');
  }

  fetchPatients(): any {
    this.patients = [];
    this.isPatients = false;
    this.ngxLoader.start();
    this.patientService
      .searchPatientByType(this.ParentPatientSearchForm.value)
      .subscribe(
        (data) => {
          if (data.patients) {
            this.patient = data.patients[0];
            this.setDatesOfMedicines();
            this.fetchPatientHistorySources(this.patient);
            this.fetchPatientComplaints(this.patient);
            // this.fetchPatientAllergies(this.patient);
            this.fetchGeneralHistory(this.patient);
            this.fetchReviewSystemHistory(this.patient);
            this.fetchCentralNervousSystem(this.patient);
            this.fetchPhysicalSystemicExaminationHistory(this.patient);
            this.fetchMedicineHistory();
            this.getPaitentDiagnosis();
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  fetchPatientInfo(patient): any {
    $('#patientChangeConform').modal('hide');
    this.patient = patient;
    this.patient_dob =
      '' +
      patient.birth_year +
      '-' +
      patient.birth_month +
      '-' +
      patient.birth_day;
    this.PatientRegistrationForm.value.dob = this.patient_dob;
    this.calculateAge();
  }
  calculateAge(): any {
    this.patient_age = moment(
      this.PatientRegistrationForm.value.dob,
      'YYYYMMDD',
    ).fromNow();
    this.patient_age = this.patient_age.replace(' ago', '');
    this.age_value = this.patient_age.split(' ')[0];
    this.selected_age_type = this.patient_age.split(' ')[1];
  }
  moveNextTab(tab): any {
    $('#' + tab).trigger('click');
    $('#' + tab + ' a').trigger('click');
  }

  getHistorybyDate(date): any {
    this.patientHistoryService
      .patientHistory(this.patient, date, this.activeTab)
      .subscribe(
        (data) => {
          this.resultData = data;
        },
        (err) => {},
      );
  }

  fetchPatientHistorySources(patient): any {
    this.patientAdmissionService
      .fetchPatientHistorySourcesByAdmission(this.patient)
      .subscribe(
        (data) => {
          this.patientHistorySource = data;
          if (Object.keys(data).length !== 0) {
            this.patientHistorySourceExist = true;
          }
        },
        (err) => {},
      );
  }

  fetchPatientComplaints(patient): any {
    this.patientAdmissionService
      .fetchPatientComplaintsByAdmission(this.patient)
      .subscribe(
        (data) => {
          this.patientComplaint = data;
          $('#tags').tagsinput('add', data.allergies);
          if (Object.keys(data).length !== 0) {
            this.presentingComplaintExist = true;
          }
        },
        (err) => {},
      );
  }

  fetchGeneralHistory(patient): any {
    this.patientHistoryService.fetchGeneralHistory(this.patient).subscribe(
      (data) => {
        this.patientGeneralHistory = data.patient_general_history;
        // if (data.patient_general_history){
        //   this.patient_general_history_id = data.patient_general_history.id
        //   this.patientGeneralHistoryExist = true;
        // }
      },
      (err) => {},
    );
  }

  fetchReviewSystemHistory(patient): any {
    this.patientHistoryService.fetchReviewSystemHistory(this.patient).subscribe(
      (data) => {
        this.reviewSystemHistory = data.patient_review_system;
        // if (data.patient_review_system){
        //   this.review_system_history_id = data.patient_review_system.id
        //   this.reviewSystemHistoryExist = true;
        // }
      },
      (err) => {},
    );
  }

  fetchCentralNervousSystem(patient): any {
    this.patientHistoryService
      .fetchCentralNervousSystem(this.patient)
      .subscribe(
        (data) => {
          this.patientCentralNervousSystem = data;
          if (Object.keys(data).length !== 0) {
            this.central_nervous_system_id = data.id;
            this.patientCentralNervousSystemExist = true;
          }
        },
        (err) => {},
      );
  }

  fetchPhysicalSystemicExaminationHistory(patient): any {
    this.patientHistoryService
      .fetchPhysicalSystemicExamination(this.patient)
      .subscribe(
        (data) => {
          this.physicalSystemicExaminationHistory =
            data.physical_systemic_examination;
          if (data.physical_systemic_examination) {
            this.physical_systemic_examination_id =
              data.physical_systemic_examination.id;
            this.physicalSystemicExaminationHistoryExist = true;
          }
        },
        (err) => {},
      );
  }

  fetchPatientAllergies(patient): any {
    this.patientHistoryService
      .fetchPatientAllergiesByAdmission(this.patient)
      .subscribe(
        (data) => {
          this.patientAllergies = data.allergies;
        },
        (err) => {},
      );
  }

  fetchMedicineHistory(): any {
    this.ngxLoader.start();
    this.paitentMedicineHistory = [];
    this.patientHistoryService.fetchMedicineHistoryFun(this.patient).subscribe(
      (data) => {
        this.paitentMedicineHistory = data.patient_medications;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  toggleShow(tabVal, tab): any {
    if (tab === 'isMedShown') {
      this.isMedShown = !tabVal;
      this.isCNSShown = false;
      this.isSysTabsShown = false;
      this.isPETabsShown = false;
      this.isGHShown = false;
    } else if (tab === 'isCNSShown') {
      this.isCNSShown = !tabVal;
      this.isMedShown = false;
      this.isSysTabsShown = false;
      this.isPETabsShown = false;
      this.isGHShown = false;
    } else if (tab === 'isSysTabsShown') {
      this.isSysTabsShown = !tabVal;
      this.isCNSShown = false;
      this.isMedShown = false;
      this.isPETabsShown = false;
      this.isGHShown = false;
    } else if (tab === 'isPETabsShown') {
      this.isPETabsShown = !tabVal;
      this.isCNSShown = false;
      this.isSysTabsShown = false;
      this.isMedShown = false;
      this.isGHShown = false;
    } else if (tab === 'isGHShown') {
      this.isGHShown = !tabVal;
      this.isCNSShown = false;
      this.isSysTabsShown = false;
      this.isPETabsShown = false;
      this.isMedShown = false;
    }
  }

  toggleGHIcon(): any {
    // if (this.isGHShown === false) {
    //   this.isGHShown = true;
    // } else {
    //   this.isGHShown = false;
    //   console.log('123');
    // }
    this.isGHShown = !this.isGHShown;
  }
  toggleSysIcon(): any {
    this.isSysTabsShown = !this.isSysTabsShown;
  }
  toggleMedIcon(): any {
    this.isMedShown = !this.isMedShown;
  }
  togglePETIcon(): any {
    this.isPETabsShown = !this.isPETabsShown;
  }
  toggleCNSIcon(): any {
    this.isCNSShown = !this.isCNSShown;
  }

  getAllergiesList(): any {
    this.paitentAllergiesSearch = $('.bootstrap-tagsinput input').val();
    if (this.paitentAllergiesSearch.length < 2) {
      return;
    }
    this.allergiesSearchResult = false;
    this.allergiesListData = [];
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.opdService
        .getAllergiesListFun(this.paitentAllergiesSearch)
        .subscribe(
          (data) => {
            this.allergiesListData = data.allergy_types;
            this.allergiesSearchResult = true;
          },
          (err) => {},
        );
    }, 50);
  }

  setAllergiesNmae(name): any {
    let items = $('#tags').tagsinput('items');
    items = items[items.length - 1];
    $('#tags').tagsinput('remove', items);
    $('#tags').tagsinput('add', name);
  }
  // add medicine to patients start

  getProductList(ind): any {
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.productListData = [];
      this.productSearchResult = false;
      if (this.addMedicine[ind].name.length < 2) {
        return;
      }
      $('#Medicines #medicineSearch' + ind + ' .search-result-main').show();
      this.stockService.searchProduct('', this.addMedicine[ind].name).subscribe(
        (data) => {
          this.productListData = data.items;
          this.productSearchResult = true;
        },
        (err) => {
          this.productListData = [];
          this.productSearchResult = true;
        },
      );
    }, 1000);
  }
  setProduct(value, ind): any {
    this.addMedicine[ind].name = value.product_name;
    this.addMedicine[ind].item_id = value.id;
    $('#Medicines #medicineSearch' + ind + ' .search-result-main').hide();
  }
  setDatesOfMedicines(): any {
    this.addMedicine = [];
    // for ( let i = 0; i < 1; i++ ) {
    //   this.addMoreMedicine(i);
    // }
  }
  removeMedicine(ind): any {
    this.addMedicine.splice(ind, 1);
  }
  addMoreMedicine(ind): any {
    console.log('index', ind);
    let obj = {
      name: '',
      item_id: '',
      dose: 'bd',
      no_of_days: null,
      from_date: '',
      to_date: '',
    };
    this.addMedicine.push(obj);
    let now = new Date();
    let day = ('0' + now.getDate()).slice(-2);
    let month = ('0' + (now.getMonth() + 1)).slice(-2);
    let today = now.getFullYear() + '-' + month + '-' + day;
    this.addMedicine[this.addMedicine.length - 1].to_date = today;
    this.addMedicine[this.addMedicine.length - 1].from_date = today;
    $('#medicineStart' + ind, '#medicineEnd' + ind).val(today);
  }
  updateMedicineToDate(ind): any {
    let startdate = this.addMedicine[ind].from_date;
    let new_date = moment(startdate, 'YYYY-MM-DD').add(
      this.addMedicine[ind].no_of_days,
      'days',
    );
    let date = new_date.format('YYYY-MM-DD');
    this.addMedicine[ind].to_date = date;
    $('#medicineEnd' + ind).val(date);
  }
  addMedicineAgainstPatient(value): any {
    $('#Medicines input').css('border', '1px solid #ccc');

    for (let i = 0; i < this.addMedicine.length; i++) {
      if (this.addMedicine[i].name === '') {
        $('#medicineName' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (
        this.addMedicine[i].no_of_days === '' ||
        this.addMedicine[i].no_of_days === null
      ) {
        $('#medicineNoOfDays' + i).css('border', '1px solid #dc3545');
        return;
      }
    }

    this.ngxLoader.start();
    this.patientHistoryService
      .assignMedicineToPatient(this.addMedicine, this.patient)
      .subscribe(
        (data) => {
          toastr.success('Added Medicine');
          this.setDatesOfMedicines();
          this.fetchMedicineHistory();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  saveHistoryTakenFrom(): any {
    this.submitted = true;
    if (this.patientHistorySource) {
      this.patient_history_source_id = this.patientHistorySource.id;
    }
    if (this.patientHistorySourceForm.value.taken_from == 'Patient') {
      this.patientHistorySourceForm.value.name =
        this.patient.first_name + ' ' + this.patient.last_name;
    } else if (this.patientHistorySourceForm.value.taken_from == 'Guardian') {
      this.patientHistorySourceForm.value.name =
        this.patient.guardian_first_name +
        ' ' +
        this.patient.guardian_last_name;
    } else if (this.patientHistorySourceForm.value.taken_from == 'Both') {
      this.patientHistorySourceForm.value.name = ' ';
    }
    this.patientHistorySource = this.patientHistorySourceForm.value;
    if (
      !this.patientHistorySourceForm.value.taken_from &&
      !this.patientHistorySourceForm.value.name
    ) {
      return;
    }
    if (this.patientHistorySourceExist) {
      this.patientAdmissionService
        .updatePatientHistorySourceByAdmission(
          this.patientHistorySource,
          this.patient,
          this.patient_history_source_id,
        )
        .subscribe(
          (data) => {
            this.fetchPatientHistorySources(this.patient);
            this.ngxLoader.stop();
          },
          (err) => {
            this.ngxLoader.stop();
          },
        );
    } else {
      this.patientAdmissionService
        .savePatientHistorySourceByAdmission(
          this.patientHistorySource,
          this.patient,
        )
        .subscribe(
          (data) => {
            this.ngxLoader.stop();
            this.fetchPatientHistorySources(this.patient);
          },
          (err) => {
            this.ngxLoader.stop();
          },
        );
    }
  }

  savePresentingComplaint(): any {
    this.submittedPC = true;
    if (this.patientComplaint) {
      this.patient_complaint_id = this.patientComplaint.id;
    }
    this.patientComplaint = this.patientComplaintForm.value;
    this.patientComplaint.allergies = $('#tags').val();
    if (
      this.patientComplaintForm.invalid ||
      !this.patientHistorySourceForm.value.taken_from ||
      !this.patientHistorySourceForm.value.name
    ) {
      return;
    }
    this.ngxLoader.start();
    if (this.presentingComplaintExist) {
      this.patientAdmissionService
        .updatePresentingComplaintByAdmission(
          this.patientComplaint,
          this.patient,
          this.patient_complaint_id,
        )
        .subscribe(
          (data) => {
            this.ngxLoader.stop();
            this.fetchPatientComplaints(this.patient);
          },
          (err) => {
            this.ngxLoader.stop();
          },
        );
    } else {
      this.patientAdmissionService
        .savePresentingComplaintByAdmission(this.patientComplaint, this.patient)
        .subscribe(
          (data) => {
            this.ngxLoader.stop();
          },
          (err) => {
            this.ngxLoader.stop();
          },
        );
    }
  }

  createAllergy(allergy): any {
    this.submitted = true;
    this.allergy = { value: allergy };
    this.ngxLoader.start();
    this.patientAdmissionService
      .savePatientAllergy(this.allergy, this.patient)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  saveGeneralHistory(): any {
    if (this.presentingComplaintExist === true) {
      this.patientGeneralHistoryForm.patchValue({
        patient_complaints: { id: this.patientComplaint.id },
      });
    }
    this.patientGeneralHistoryForm.patchValue({
      patient_complaints: { allergies: $('#tags').val() },
    });
    // this.patientAllergy = $('#tags').val();
    this.submitted = true;
    this.patientGeneralHistory = this.patientGeneralHistoryForm.value;
    if (
      this.patientGeneralHistoryForm.invalid ||
      !this.patientHistorySourceForm.value.taken_from ||
      !this.patientHistorySourceForm.value.name
    ) {
      return;
    }
    // console.log('after', this.patientGeneralHistoryForm.value);
    this.ngxLoader.start();

    // if (this.patientGeneralHistoryExist){
    //   this.patientAdmissionService.updatesGeneralHistoryByAdmission(this.patientGeneralHistory, this.patient, this.patient_general_history_id).subscribe(data => {
    //       this.fetchGeneralHistory(this.patient);
    //       this.ngxLoader.stop();
    //     },
    //     err => {
    //       this.ngxLoader.stop();
    //     }
    //   );
    // }
    // else{

    this.patientAdmissionService
      .saveGeneralHistoryByAdmission(this.patientGeneralHistory, this.patient)
      .subscribe(
        (data) => {
          toastr.success('Successfully Save General History');
          this.ngxLoader.stop();
          this.fetchPatientComplaints(this.patient);
          this.fetchGeneralHistory(this.patient);
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );

    // }
  }

  saveReviewSystemHistory(): any {
    this.submitted = true;
    this.reviewSystemHistory = this.reviewSystemHistoryForm.value;
    if (
      this.reviewSystemHistoryForm.invalid ||
      !this.patientHistorySourceForm.value.taken_from ||
      !this.patientHistorySourceForm.value.name
    ) {
      return;
    }
    this.ngxLoader.start();
    // if (this.reviewSystemHistoryExist){
    //   this.patientHistoryService.updateReviewSystemHistoryByAdmission(this.reviewSystemHistory, this.patient, this.review_system_history_id).subscribe(data => {
    //       this.fetchReviewSystemHistory(this.patient)
    //       this.ngxLoader.stop();
    //     },
    //     err => {
    //       this.ngxLoader.stop();
    //     }
    //   );
    // }
    // else{
    this.patientHistoryService
      .createReviewSystemHistoryByAdmission(
        this.reviewSystemHistory,
        this.patient,
      )
      .subscribe(
        (data) => {
          toastr.success('Successfully Save Reveiw History');
          this.fetchReviewSystemHistory(this.patient);
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
    // }
  }

  savePhysicalSystemicExaminationHistory(): any {
    this.submitted = true;
    this.physicalSystemicExaminationHistory =
      this.physicalSystemicExaminationHistoryForm.value;
    if (
      this.reviewSystemHistoryForm.invalid ||
      !this.patientHistorySourceForm.value.taken_from ||
      !this.patientHistorySourceForm.value.name
    ) {
      return;
    }
    this.ngxLoader.start();
    // if(this.physicalSystemicExaminationHistoryExist){
    //   this.patientHistoryService.updatePhysicalSystemicExaminationHistoryByAdmission(this.physicalSystemicExaminationHistory, this.patient, this.physical_systemic_examination_id).subscribe(data => {
    //       this.fetchPhysicalSystemicExaminationHistory(this.patient)
    //       this.ngxLoader.stop();
    //     },
    //     err => {
    //       this.ngxLoader.stop();
    //     }
    //   );
    // }
    // else{
    this.patientHistoryService
      .createPhysicalSystemicExaminationHistoryByAdmission(
        this.physicalSystemicExaminationHistory,
        this.patient,
      )
      .subscribe(
        (data) => {
          toastr.success('Successfully Save Examination History');
          this.fetchPhysicalSystemicExaminationHistory(this.patient);
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
    // }
  }

  savePatientCentralNervousSystem(): any {
    this.submitted = true;
    this.patientCentralNervousSystem =
      this.patientCentralNervousSystemForm.value;
    if (
      this.patientCentralNervousSystemForm.invalid ||
      !this.patientHistorySourceForm.value.taken_from ||
      !this.patientHistorySourceForm.value.name
    ) {
      return;
    }
    this.ngxLoader.start();
    // if (this.patientCentralNervousSystemExist){
    //   this.patientAdmissionService.updatePatientCentralNervousSystemByAdmission(this.patientCentralNervousSystem, this.patient, this.central_nervous_system_id).subscribe(data => {
    //       this.fetchCentralNervousSystem(this.patient)
    //       this.ngxLoader.stop();
    //     },
    //     err => {
    //       this.ngxLoader.stop();
    //     }
    //   );
    // }
    // else{
    this.patientAdmissionService
      .createPatientCentralNervousSystemByAdmission(
        this.patientCentralNervousSystem,
        this.patient,
      )
      .subscribe(
        (data) => {
          toastr.success('Successfully Save Nervous System History');
          this.fetchCentralNervousSystem(this.patient);
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
    // }
  }

  printAdmissionHistory(): any {
    // console.log('patientMRN', );
    this.router.navigate(['/ipd/history-print'], {
      queryParams: { mrn: this.patientMRN },
    });
    // window.open(environment.rorUrl + '/v4/admissions/admission_history_report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&patient_id=' + this.patient.id);
  }
  get j(): any {
    return this.PatientSearchForm.controls;
  }
  get f(): any {
    return this.patientComplaintForm.controls;
  }
  get k(): any {
    return this.patientHistorySourceForm.controls;
  }
  get g(): any {
    return this.patientGeneralHistoryForm.controls;
  }

  showsAll(): any {
    if (this.showAllTabs === false) {
      $('.div-collapse').removeClass('show');
      // $('.div-collapse').addClass('collapsing');
      this.isGHShown = false;
      this.isSysTabsShown = false;
      this.isMedShown = false;
      this.isPETabsShown = false;
      this.isCNSShown = false;
    } else if (this.showAllTabs === true) {
      $('.div-collapse').addClass('show');
      // $('.div-collapse').removeClass('collapsing');
      this.isGHShown = true;
      this.isSysTabsShown = true;
      this.isMedShown = true;
      this.isPETabsShown = true;
      this.isCNSShown = true;
    }
    this.showAllTabs = !this.showAllTabs;
  }

  onSubmitAll(): any {
    // if ( ( this.patientHistorySourceForm.invalid || !this.patientHistorySourceForm.value.taken_from || !this.patientHistorySourceForm.value.name)
    //   && (this.patientGeneralHistoryForm.invalid ) && (this.reviewSystemHistoryForm.invalid) &&
    //   (this.patientCentralNervousSystemForm.invalid) && (this.physicalSystemicExaminationHistoryForm.invalid) &&
    //
    // ) {
    //   return;

    this.submitted = true;
    this.patientGeneralHistory = this.patientGeneralHistoryForm.value;
    if (
      this.patientGeneralHistoryForm.invalid ||
      !this.patientHistorySourceForm.value.taken_from ||
      !this.patientHistorySourceForm.value.name
    ) {
      return;
    }
    this.submitted = true;
    this.reviewSystemHistory = this.reviewSystemHistoryForm.value;
    if (
      this.reviewSystemHistoryForm.invalid ||
      !this.patientHistorySourceForm.value.taken_from ||
      !this.patientHistorySourceForm.value.name
    ) {
      return;
    }

    $('#Medicines input').css('border', '1px solid #ccc');
    for (let i = 0; i < this.addMedicine.length; i++) {
      if (this.addMedicine[i].name === '') {
        $('#medicineName' + i).css('border', '1px solid #dc3545');
        return;
      }
      // if ( this.addMedicine[i].no_of_days === '' || this.addMedicine[i].no_of_days !== null ) {
      //   $('#medicineNoOfDays' + i).css('border', '1px solid #dc3545');
      //   return;
      // }
      if (
        this.addMedicine[i].no_of_days === '' ||
        this.addMedicine[i].no_of_days === null
      ) {
        $('#medicineNoOfDays' + i).css('border', '1px solid #dc3545');
        return;
      }
    }

    this.submitted = true;
    this.physicalSystemicExaminationHistory =
      this.physicalSystemicExaminationHistoryForm.value;
    if (
      this.reviewSystemHistoryForm.invalid ||
      !this.patientHistorySourceForm.value.taken_from ||
      !this.patientHistorySourceForm.value.name
    ) {
      return;
    }

    this.submitted = true;
    this.patientCentralNervousSystem =
      this.patientCentralNervousSystemForm.value;
    if (
      this.patientCentralNervousSystemForm.invalid ||
      !this.patientHistorySourceForm.value.taken_from ||
      !this.patientHistorySourceForm.value.name
    ) {
      return;
    }

    this.saveGeneralHistory();
    this.submitMainDiagnosis();
    this.saveReviewSystemHistory();
    this.addMedicineAgainstPatient('submit');
    this.savePhysicalSystemicExaminationHistory();
    this.savePatientCentralNervousSystem();
  }

  shows(value): any {
    // console.log('Val : ', value);
    // console.log('isShowAll : ', this.isShowAll);
    //
    // $('#generalClick').click();
    // $('#reveiwClick').click();
    // $('#medicationClick').click();
    // $('#phyExamClick').click();
    // $('#nervousClick').click();
    // const generalClick: HTMLElement = document.getElementById('generalClick') as HTMLElement;
    // generalClick.click();
    //
    // const reveiwClick: HTMLElement = document.getElementById('reveiwClick') as HTMLElement;
    // reveiwClick.click();
    //
    // const medicationClick: HTMLElement = document.getElementById('medicationClick') as HTMLElement;
    // medicationClick.click();
    //
    // const phyExamClick: HTMLElement = document.getElementById('phyExamClick') as HTMLElement;
    // phyExamClick.click();
    //
    // const nervousClick: HTMLElement = document.getElementById('nervousClick') as HTMLElement;
    // nervousClick.click();
  }
  toBack(): any {
    if (this.page === 'ipd') {
      this.router.navigate(['/ipd'], {
        queryParams: { mrn: this.patient.mrn },
      });
    } else if (this.page === 'radiology') {
      this.router.navigate(['/radiology/radiology-writing']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
  addMoreDianosisMain(): any {
    const diagnosisObj = {
      id: '',
      name: '',
      type: '',
    };
    this.mainDiagnosisAdd.push(diagnosisObj);
  }
  getAllDiagnosisListMain(i): any {
    this.allDiagnosisDataMain = [];
    this.diagnosisSearchResultMain = false;
    if (this.mainDiagnosisAdd[i].name < 2) {
      return;
    }
    this.opdService
      .getDiagnosisListFun(this.mainDiagnosisAdd[i].name)
      .subscribe(
        (data) => {
          this.allDiagnosisDataMain = data.data;
          this.diagnosisSearchResultMain = true;
        },
        (err) => {
          this.allDiagnosisDataMain = [];
          this.diagnosisSearchResultMain = true;
        },
      );
  }
  setAllDiagnosisMain(value, i): any {
    this.mainDiagnosisAdd[i].id = value.id;
    this.mainDiagnosisAdd[i].name = value.name;
    $('.diagnosis-search-bar').hide();
  }
  removeDianosisMain(i: number): any {
    this.mainDiagnosisAdd.splice(i, 1);
    // if (this.mainDiagnosisAdd.length === 0) {
    //   this.addMoreDianosisMain();
    // }
  }

  submitMainDiagnosis(): any {
    $('.diagnosisMain .form-control').css('border', '1px solid #e4e6ef');
    // if (this.mainDiagnosisAdd.length === 0) {
    //   toastr.error('Add Diagnosis');
    //   return;
    // }
    for (let i = 0; i < this.mainDiagnosisAdd.length; i++) {
      if (this.mainDiagnosisAdd[i].name === '') {
        $('#diagnosisNameMain' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (this.mainDiagnosisAdd[i].type === '') {
        $('#diagnosisTypeMain' + i).css('border', '1px solid #dc3545');
        return;
      }
    }
    this.opdService
      .addDiagnosisFunMain(this.mainDiagnosisAdd, this.patient)
      .subscribe(
        (data) => {
          this.mainDiagnosisAdd = [];
          const diagnosisObj = {
            id: '',
            name: '',
            type: '',
          };
          this.mainDiagnosisAdd.push(diagnosisObj);
          this.getPaitentDiagnosis();
        },
        (err) => {},
      );
  }

  getPaitentDiagnosis(): any {
    this.paitestDiagnosisHistoryData = [];
    this.paitestDiagnosisHistory = [];
    // this.diagnosisSearch = '';
    // this.differentialSearch = '';
    // this.finalSearch = '';
    this.opdService
      .getPaitentDiagnosisFun(this.patient, this.patientAllHistory)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.paitestDiagnosisHistoryData = data;
          this.paitestDiagnosisHistory = data.patient_diagnoses;
          $('#diagnosisFilter').val('patient_diagnosis');
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
}
