<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            Birth Certificate
          </h5>
          <!--          tab headings-->

          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-primary btn-sm" (click)="printReport()">
          Print
        </button>
        <!--        <button class="btn btn-primary btn-sm ml-2" (click)="goBack()">Back</button>-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="card pdf-card" id="reports">
        <div class="card-body birth-main">
          <div
            *ngIf="currentControls?.ipd_controls?.is_birth_certificate_header"
            style="width: 100%"
            class="d-print-block"
          >
            <!-- header table -->
            <table class="table">
              <tbody>
                <tr class="borders">
                  <td style="width: 15%; border-top: 0 !important">
                    <img
                      [src]="settingData?.logo?.url"
                      style="width: auto; height: 106px"
                    />
                  </td>
                  <td style="width: 70%; border-top: 0 !important">
                    <div class="text-center">
                      <h3>{{ settingData?.title }}</h3>
                    </div>
                    <div class="font-size-h5 text-center">
                      <h5>{{ settingData?.address }}</h5>
                    </div>
                    <div class="font-size-h5 text-center">
                      <h5>Birth Certificate</h5>
                    </div>
                  </td>
                  <td style="width: 15%; border-top: 0 !important"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            *ngIf="!currentControls?.ipd_controls?.is_birth_certificate_header"
            class="d-none d-print-block"
            style="padding-top: 265px"
          ></div>
          <div class="row m-0">
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">MR No:</span>
                <span class="heading-detail">{{
                  patientInvoices?.mother?.mrn
                }}</span>
              </div>
            </div>
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Date:</span>
                <span class="heading-detail">{{
                  patientInvoices?.mother?.created_at
                    | date: "dd-MM-yyyy h:mm a"
                }}</span>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-12 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Name of Child:</span>
                <span class="heading-detail" *ngIf="patientInvoices?.patient">{{
                  patientInvoices?.patient?.first_name +
                    " " +
                    patientInvoices?.patient?.last_name
                }}</span>
                <span class="heading-detail" *ngIf="!patientInvoices?.patient"
                  >{{ patientInvoices?.baby_first_name || "" }}
                  {{ patientInvoices?.baby_last_name || "" }}</span
                >
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Sex/Gender:</span>
                <span class="heading-detail" *ngIf="patientInvoices?.patient">{{
                  patientInvoices?.patient?.gender | titlecase
                }}</span>
                <span
                  class="heading-detail"
                  *ngIf="!patientInvoices?.patient"
                  >{{ patientInvoices?.baby_gender | titlecase }}</span
                >
              </div>
            </div>
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Religion:</span>
                <span class="heading-detail">{{
                  patientInvoices?.religion
                }}</span>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Father's Name:</span>
                <span class="heading-detail">{{
                  patientInvoices?.father_first_name
                    ? patientInvoices?.father_first_name
                    : "" + " " + patientInvoices?.father_last_name
                      ? patientInvoices?.father_last_name
                      : ""
                }}</span>
              </div>
            </div>
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Father's CNIC:</span>
                <span class="heading-detail">{{
                  patientInvoices?.father_cnic
                    ? patientInvoices?.father_cnic
                    : ""
                }}</span>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Mother's Name:</span>
                <span class="heading-detail">{{
                  patientInvoices?.mother?.first_name
                    ? patientInvoices?.mother?.first_name
                    : "" + " " + patientInvoices?.mother?.last_name
                      ? patientInvoices?.mother?.last_name
                      : ""
                }}</span>
              </div>
            </div>
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Mother's CNIC:</span>
                <span class="heading-detail">{{
                  patientInvoices?.mother?.patient_nic
                    ? patientInvoices?.mother?.patient_nic
                    : ""
                }}</span>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-12 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Address:</span>
                <span class="heading-detail">
                  <span *ngIf="patientInvoices?.mother?.address1"
                    >{{ patientInvoices?.mother?.address1 }},
                  </span>
                  <span *ngIf="patientInvoices?.mother?.city_name"
                    >{{ patientInvoices?.mother?.city_name }},
                  </span>
                  <span *ngIf="patientInvoices?.mother?.state_name"
                    >{{ patientInvoices?.mother?.state_name }},
                  </span>
                  <span *ngIf="patientInvoices?.mother?.country_name"
                    >{{ patientInvoices?.mother?.country_name }}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Date of Birth:</span>
                <span class="heading-detail">{{
                  patientInvoices?.dob | date: "dd-MM-yyyy"
                }}</span>
              </div>
            </div>
            <div class="col-6 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Time of Birth:</span>
                <span class="heading-detail">{{
                  patientInvoices?.calculated_time_of_birth
                }}</span>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-12 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">(In Words):</span>
                <span class="heading-detail">{{ dateInWords }}</span>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-12 heading-main pl-0">
              <div class="heading-sub-main">
                <span class="heading-title">Place of Birth:</span>
                <span class="heading-detail">{{
                  patientInvoices?.birth_place
                }}</span>
              </div>
            </div>
          </div>

          <!--          <div class="row m-0 ">-->
          <!--            <div class="col-6 heading-main pl-0">-->
          <!--              <div class="heading-sub-main">-->
          <!--                <span class="heading-title">Doctor's Signature:</span>-->
          <!--                <span class="heading-detail"></span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="col-6 heading-main pl-0">-->
          <!--              <div class="heading-sub-main">-->
          <!--                <span class="heading-title">HR Official`s Signature:</span>-->
          <!--                <span class="heading-detail"></span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          <div class="row m-0 ">-->
          <!--            <div class="col-6 heading-main pl-0">-->
          <!--              <div class="heading-sub-main">-->
          <!--                <span class="heading-title">Name:</span>-->
          <!--                <span class="heading-detail"></span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="col-6 heading-main pl-0">-->
          <!--              <div class="heading-sub-main">-->
          <!--                <span class="heading-title">Name:</span>-->
          <!--                <span class="heading-detail"></span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          <div class="row m-0 ">-->
          <!--            <div class="col-6 heading-main pl-0">-->
          <!--              <div class="heading-sub-main">-->
          <!--                <span class="heading-title">Designation:</span>-->
          <!--                <span class="heading-detail"></span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="col-6 heading-main pl-0">-->
          <!--              <div class="heading-sub-main">-->
          <!--                <span class="heading-title">Designation:</span>-->
          <!--                <span class="heading-detail"></span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          footer table-->
          <div
            class="d-flex justify-content-between"
            style="margin-top: 100px; margin-bottom: 100px"
          >
            <div class="pt-3">
              <div *ngIf="patientInvoices?.issue_by">
                <strong style="font-weight: bold">Issued by: </strong
                ><span
                  >{{ patientInvoices?.issue_by }}
                  {{
                    patientInvoices?.issue_by_date_and_time
                      | date: "dd-MM-yyyy h:mm a"
                  }}</span
                >
              </div>
              <div>
                <strong style="font-weight: bold">Printed by: </strong
                ><span
                  >{{ patientInvoices?.print_by }}
                  {{
                    patientInvoices?.print_by_date_and_time
                      | date: "dd-MM-yyyy h:mm a"
                  }}</span
                >
              </div>
            </div>
            <div class="signature-heading col-3">
              <div class="border-for-signature"></div>
              <div class="pt-3">
                <strong style="font-weight: bold">Issuing Authority:</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
