import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HistoryDashboardComponent } from './history-dashboard/history-dashboard.component';
import { HistoryPrintComponent } from './history-print/history-print.component';
import { AuthorizationGuard } from '../../services/authorization.guard';
import { IpdV2Component } from './ipd-v2/ipd-v2.component';
import { HistoryV2Component } from './ipd-components/history-v2/history-v2.component';
import { HistoryPrintV2Component } from './history-print-v2/history-print-v2.component';

const routes: Routes = [
  { path: '', component: IpdV2Component, canActivate: [AuthorizationGuard] },
  { path: 'v2', component: IpdV2Component, canActivate: [AuthorizationGuard] },
  { path: 'history', component: HistoryDashboardComponent },
  { path: 'history-v2', component: HistoryV2Component },
  { path: 'history-print', component: HistoryPrintComponent },
  { path: 'history-print-v2', component: HistoryPrintV2Component },
  // { path: 'history/:mrn', component: HistoryDashboardComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IpdRoutingModule {}
