<!--begin::Content-->
<div
  class="content d-flex flex-column flex-column-fluid"
  style=""
  id="kt_content"
>
  <!--begin::Subheader-->
  <div class="subheader py-2 subheader-solid h-auto" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-center flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Report Writing</h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <div class="row mr-5">
          <div class="col-12">
            <span
              class="label label-lg label-light-success label-inline cursor-pointer mr-5 text-dark"
              *ngIf="currentPatient?.performing_doctor_name"
              (click)="
                changeDoctor(
                  'performing',
                  currentPatient.id,
                  currentPatient.performing_doctor_id
                )
              "
              >Performing Doctor:
              {{ currentPatient?.performing_doctor_name | titlecase }}</span
            >
            <span
              class="label label-lg label-light-warning label-inline cursor-pointer text-dark"
              *ngIf="currentPatient?.referring_doctor_name"
              (click)="
                changeDoctor(
                  'referring',
                  currentPatient.id,
                  currentPatient.referring_doctor_id
                )
              "
              >Referring Doctor:
              {{ currentPatient?.referring_doctor_name | titlecase }}</span
            >
          </div>
        </div>
        <span class="switch switch-icon mr-5">
          <span class="mr-2">Radiant</span>
          <label>
            <input
              type="checkbox"
              checked="checked"
              #urlCheck
              name="UrlCheck"
              (change)="updatePACSURL($event.target.checked)"
            />
            <span></span>
          </label>
          <span class="ml-1">Web</span>
        </span>
        <span>|</span>
        <select
          class="form-control form-control-sm ml-5"
          style="width: 100px"
          name="searchType"
          (change)="searchFilterValue()"
          [(ngModel)]="patientType"
        >
          <option value="self">Self</option>
          <option value="others">Others</option>
          <option value="all">All</option>
        </select>
        <!--        <span class="switch switch-icon ml-5">-->
        <!--          <span class="mr-2">Other</span>-->
        <!--          <label>-->
        <!--            <input type="checkbox" checked="checked" name="UrlCheck" [(ngModel)]="patientType" (change)="searchFilterValue()"/>-->
        <!--            <span></span>-->
        <!--          </label>-->
        <!--          <span class="ml-1">Self</span>-->
        <!--        </span>-->
        <!--        <button class="btn btn-primary btn-sm ml-10" type="button" (click)="openPatientList()">Patients</button>-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content" style="margin-top: -8px">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-4 pr-0">
              <div class="card card-custom mb-4" *ngIf="currentPatient">
                <div class="card-body custom-card-body" style="padding: 17px">
                  <!--begin::Top-->
                  <div class="d-flex">
                    <!--begin::Pic-->
                    <div class="flex-shrink-0 mr-1 align-items-center d-flex">
                      <div class="">
                        <img
                          *ngIf="
                            currentPatient?.patient?.gender === 'transgender'
                          "
                          src="assets/media/users/blank.png"
                          class="h-50px align-self-end"
                          alt=""
                        />
                        <img
                          *ngIf="
                            currentPatient?.patient?.gender === 'unidentified'
                          "
                          src="assets/media/users/blank.png"
                          class="h-50px align-self-end"
                          alt=""
                        />
                        <img
                          *ngIf="currentPatient?.patient?.gender === 'male'"
                          src="assets/media/svg/avatars/009-boy-4.svg"
                          class="h-50px align-self-end"
                          alt=""
                        />
                        <img
                          *ngIf="currentPatient?.patient?.gender === 'female'"
                          src="assets/media/svg/avatars/002-girl.svg"
                          class="h-50px align-self-end"
                          alt=""
                        />
                      </div>
                    </div>
                    <!--end::Pic-->
                    <!--begin: Info-->
                    <div class="flex-grow-1">
                      <!--begin::Title-->
                      <div
                        class="d-flex align-items-center justify-content-between flex-wrap mt-2"
                      >
                        <!--begin::User-->
                        <div class="mr-3">
                          <!--begin::Name-->
                          <a
                            class="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold"
                            ><span *ngIf="currentPatient?.patient?.title"
                              >{{
                                currentPatient?.patient?.title | titlecase
                              }} </span
                            >&nbsp;{{
                              currentPatient?.patient?.first_name +
                                " " +
                                currentPatient?.patient?.last_name | titlecase
                            }}</a
                          >
                          <!--end::Name-->
                          <!--begin::Contacts-->
                          <div class="d-flex flex-wrap">
                            <a
                              class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-1"
                              >{{ currentPatient?.patient?.mrn }}</a
                            >
                            <a
                              class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-1"
                              >{{ currentPatient?.patient?.age }}</a
                            >
                            <a
                              class="text-muted text-hover-primary font-weight-bold"
                              >{{
                                currentPatient?.patient?.gender | titlecase
                              }}</a
                            >
                          </div>
                          <div class="d-flex flex-wrap">
                            <a
                              class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-1"
                              >{{
                                currentPatient?.patient?.patient_type?.name
                                  | titlecase
                              }}</a
                            >
                          </div>
                          <div class="d-flex flex-wrap">
                            <a
                              class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-1"
                              >Acc No: {{ currentPatient?.accession_number }}</a
                            >
                            <a
                              class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-1"
                              >Films: {{ currentPatient?.films }}</a
                            >
                          </div>
                          <!--end::Contacts-->
                        </div>
                        <!--begin::User-->
                      </div>
                      <!--end::Title-->
                    </div>
                    <!--end::Info-->
                  </div>
                  <!--end::Top-->
                </div>
              </div>
            </div>
            <div class="col-8 pr-0">
              <div class="card card-custom mb-4" *ngIf="currentPatient">
                <div
                  class="card-body custom-card-body"
                  style="padding: 10px !important"
                >
                  <!--begin::Top-->
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <!--begin::Title-->
                      <div class="">
                        <!--begin::Actions-->
                        <div class="row">
                          <div class="col-3">
                            <label class="col-form-label text-right float-left"
                              >Classic View:</label
                            >
                            <span class="switch switch-icon">
                              <label>
                                <input
                                  type="checkbox"
                                  name="classic"
                                  (change)="updateClassicType()"
                                  [(ngModel)]="classic"
                                />
                                <span></span>
                              </label>
                            </span>
                          </div>
                          <div class="col-9 justify-content-around d-flex pb-1">
                            <a
                              type="button"
                              class="btn btn-primary btn-sm"
                              [href]="[
                                'ipd/history?mrn=' +
                                  currentPatient?.patient?.mrn +
                                  '&page=radiology'
                              ]"
                              target="_blank"
                              >View History</a
                            >
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              (click)="
                                openDiscomImage(
                                  currentPatient?.accession_number
                                )
                              "
                            >
                              Preview Images
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              (click)="rejectLabTest(currentPatient)"
                            >
                              Reject
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              (click)="openResultModal()"
                            >
                              Patient Details
                            </button>
                          </div>
                        </div>

                        <div class="row">
                          <label class="col-2">Urgent Action</label>
                          <label class="checkbox checkbox-sm col-2">
                            <input type="checkbox" [(ngModel)]="urgentAction" />
                            <span></span>
                          </label>
                          <label class="col-3 col-form-label text-right"
                            >Report Templates:</label
                          >
                          <div class="col-5">
                            <!-- selectOptionLeft -->
                            <select
                              class="form-control selectpicker"
                              id="templateList"
                              data-live-search="true"
                              (change)="setReportTemplete($event.target.value)"
                            >
                              <option
                                value=""
                                [selected]="true"
                                [disabled]="true"
                              >
                                select
                              </option>
                              <option
                                [value]="temp.id"
                                *ngFor="let temp of templateNames"
                              >
                                {{ temp.name }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <!--end::Actions-->
                      </div>
                      <!--end::Title-->
                    </div>
                    <!--end::Info-->
                  </div>
                  <!--end::Top-->
                </div>
              </div>
            </div>
            <div class="col-12 pr-0">
              <div class="card card-custom gutter-b">
                <!--begin::Body-->
                <div class="card-body" style="padding: 1rem">
                  <div class="form-group row">
                    <div class="col-9">
                      <h5 class="font-weight-bold m-0">
                        {{ currentPatient?.service_name }}
                      </h5>
                    </div>
                    <div class="col-3 text-right">
                      <label
                        >History
                        <a
                          *ngIf="showHistory === false"
                          class="text-hover-primary cursor-pointer ml-15"
                          (click)="showHistory = true"
                          >Show</a
                        >
                        <a
                          *ngIf="showHistory === true"
                          class="text-hover-primary cursor-pointer ml-15"
                          (click)="showHistory = false"
                          >Hide</a
                        >
                      </label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12" *ngIf="showHistory">
                      <div class="form-group">
                        <label>Indication For exam</label>
                        <textarea
                          class="form-control"
                          [(ngModel)]="history"
                          rows="2"
                          cols="20"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-12" *ngIf="showHistory">
                      <div class="mb-1">
                        <span class="mr-2"
                          >Any allergies to iodinated contrast?</span
                        >
                        <strong>{{
                          currentPatient?.any_allergies_to_iodinated_contrast
                            ? "Yes"
                            : "No"
                        }}</strong>
                      </div>
                      <div class="mb-1">
                        <span class="mr-2"
                          >Any contraindications to receive contrast?</span
                        >
                        <strong>{{
                          currentPatient?.any_contraindications_To_receive_contrast
                            ? "Yes"
                            : "No"
                        }}</strong>
                      </div>
                      <div class="mb-1">
                        {{
                          currentPatient?.any_contraindications_to_receive_contrast_textarea ||
                            ""
                        }}
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          >Reports <span class="text-danger">*</span></label
                        >
                        <div id="reportMain">
                          <div class="" id="reports"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group" *ngIf="classic === false">
                        <label
                          >Impressions <span class="text-danger">*</span></label
                        >
                        <div id="impressionsMain">
                          <div class="" id="impressions"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-5">
                      <input
                        class="form-control form-control-sm"
                        name="name"
                        placeholder="Template name"
                        [(ngModel)]="reportName"
                      />
                    </div>
                    <div class="col-7 mt-3 text-right">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm mr-3"
                        (click)="savePatientReport('template', false)"
                      >
                        Save as template
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm mr-3"
                        (click)="savePatientReport('report', false)"
                      >
                        Save Report
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm mr-3"
                        (click)="savePatientReport('report', true)"
                      >
                        Save & Print Report
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        (click)="savePatientReport('sign', false)"
                        *ngIf="showFinalSignButton === true"
                      >
                        Final Sign
                      </button>
                    </div>
                  </div>
                </div>
                <!--end::Body-->
              </div>
            </div>
            <div class="col-12 pr-0 mt-5">
              <div class="card card-custom gutter-b">
                <!--begin::Body-->
                <div class="card-body" style="padding: 1rem">
                  <div
                    class="table-responsive position-relative"
                    style="max-height: 300px; overflow: auto"
                  >
                    <h5>Previous Reports</h5>
                    <table
                      class="table table-head-custom table-vertical-center"
                    >
                      <thead>
                        <tr>
                          <th>Date / Acc Number</th>
                          <th>Name / MRN</th>
                          <th>Service Name / Department</th>
                          <th>Per Dr.</th>
                          <th>Ref Dr.</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container
                          *ngFor="let element of reportsData; let i = index"
                        >
                          <tr
                            class="radiologyReportPrevious"
                            id="radiologyReportPrevious{{ i }}"
                            (click)="displayReport(element, i)"
                          >
                            <td>
                              <span
                                class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                                >{{ element.order_date }}</span
                              >
                              <span
                                class="text-muted font-weight-bold d-block"
                                >{{ element.accession_number }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                                >{{ element.patient_name }}</span
                              >
                              <span
                                class="text-muted font-weight-bold d-block"
                                >{{ element.patient.mrn }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                                >{{ element.service_name }}</span
                              >
                              <span
                                class="text-muted font-weight-bold d-block"
                                >{{ element.service?.department?.title }}</span
                              >
                            </td>
                            <td>{{ element.performing_doctor_name }}</td>
                            <td>{{ element.referring_doctor_name }}</td>
                            <td>
                              <!--                        <u *ngIf="element?.report" class="pointer" (click)="printReport(element?.report?.id)">Print</u>-->
                              <a
                                class="btn btn-icon btn-light-primary btn-sm mr-2"
                                *ngIf="element?.report"
                                (click)="printReport(element?.report?.id)"
                              >
                                <i class="flaticon-technology"></i>
                              </a>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                  <!--end::Table-->
                </div>
                <!--end::Body-->
              </div>

              <div class="card card-custom gutter-b" *ngIf="showReport">
                <!--begin::Body-->
                <div class="card-body" style="padding: 1rem">
                  <div style="width: 100%; overflow: auto">
                    <h5>Previous Report</h5>
                    <div id="previousReport"></div>
                    <div id="previousReportImpression"></div>
                  </div>
                </div>
                <!--end::Body-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card card-custom">
            <div class="card-body custom-card-body">
              <form autocomplete="off">
                <div class="row m-0">
                  <div class="col-12 mb-2 text-right">
                    <button
                      (click)="updateFilters('')"
                      type="button"
                      class="btn btn-light-primary btn-sm mr-2"
                    >
                      All
                    </button>
                    <button
                      (click)="updateFilters('opd')"
                      type="button"
                      class="btn btn-light-primary btn-sm opd-btn mr-2"
                    >
                      OPD
                    </button>
                    <button
                      (click)="updateFilters('ipd')"
                      type="button"
                      class="btn btn-light-primary btn-sm ipd-btn mr-2"
                    >
                      IPD
                    </button>
                    <button
                      (click)="updateFilters('urgent')"
                      type="button"
                      class="btn btn-light-primary btn-sm urgent"
                    >
                      Urgent
                    </button>
                  </div>
                  <div class="col-6 p-0 pr-1 mb-2">
                    <label>From date</label>
                    <input
                      type="text"
                      id="fromDate"
                      name="fromDate"
                      class="form-control form-control-sm"
                      readonly="readonly"
                      autocomplete="off"
                    />
                  </div>
                  <div class="col-6 p-0 mb-2">
                    <label>To date</label>
                    <input
                      type="text"
                      id="toDate"
                      name="toDate"
                      class="form-control form-control-sm"
                      readonly="readonly"
                      autocomplete="off"
                    />
                  </div>
                  <div class="col-6 p-0 mb-2 pr-1">
                    <select
                      class="form-control form-control-sm selectpicker"
                      id="department"
                      name="department"
                      [(ngModel)]="departmentId"
                      data-dropup-auto="false"
                      data-live-search="true"
                    >
                      <option value="" disabled>Department</option>
                      <option value="" *ngIf="subDepartments.length > 0">
                        All
                      </option>
                      <option
                        [value]="department.id"
                        *ngFor="let department of subDepartments"
                      >
                        {{ department?.title }}
                      </option>
                    </select>
                  </div>
                  <div class="col-6 p-0 mb-2">
                    <select
                      class="form-control form-control-sm"
                      name="searchType"
                      [(ngModel)]="searchType"
                    >
                      <option value="default">All</option>
                      <option value="mrn">MRN</option>
                      <option value="name">Name</option>
                      <option value="phone1">Phone</option>
                      <option value="patient_nic">CNIC</option>
                    </select>
                  </div>
                  <div class="col-12 p-0 button-wrapper">
                    <input
                      type="text"
                      [(ngModel)]="manufactureSearchValue"
                      name="manufactureSearchValue"
                      maxlength="12"
                      minlength="12"
                      placeholder="Search patient here"
                      class="form-control form-control-sm"
                    />
                    <button
                      type="submit"
                      class="btn btn-primary btn-sm"
                      value="Search"
                      (click)="searchFilterValue()"
                    >
                      <span>Search</span>
                    </button>
                  </div>
                </div>
              </form>
              <br />
              <div class="patient-listing mb-5">
                <ng-container *ngFor="let pa of pageDataList; let i = index">
                  <div
                    class="d-flex py-3 patient-panel"
                    (click)="fetchReports(pa, i)"
                  >
                    <div class="symbol symbol-40 symbol-light-success mr-5">
                      <span class="symbol-label">
                        <img
                          *ngIf="pa?.patient?.gender === 'transgender'"
                          src="assets/media/users/blank.png"
                          class="h-100 align-self-end"
                          alt=""
                        />
                        <img
                          *ngIf="pa?.patient?.gender === 'unidentified'"
                          src="assets/media/users/blank.png"
                          class="h-100 align-self-end"
                          alt=""
                        />
                        <img
                          *ngIf="pa?.patient?.gender === 'male'"
                          src="assets/media/svg/avatars/009-boy-4.svg"
                          class="h-75 align-self-end"
                          alt=""
                        />
                        <img
                          *ngIf="pa?.patient?.gender === 'female'"
                          src="assets/media/svg/avatars/002-girl.svg"
                          class="h-75 align-self-end"
                          alt=""
                        />
                      </span>
                    </div>
                    <div
                      class="d-flex flex-column flex-grow-1 font-weight-bold"
                    >
                      <!--              text-hover-primary-->
                      <a class="text-dark mb-1 font-size-lg">{{
                        pa?.patient_name
                      }}</a>
                      <div class="d-inline">
                        <span class="text-muted mr-4">{{
                          pa?.patient?.mrn
                        }}</span>
                        <span class="text-muted mr-4">{{
                          pa?.patient?.gender
                        }}</span>
                      </div>
                      <div class="d-inline">
                        <span class="text-muted mr-4">{{
                          pa?.patient?.age
                        }}</span>
                        <span class="text-muted mr-4">{{
                          pa?.patient?.patient_type_name
                        }}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #conformCancelModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Order Rejected!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-8">
              <label>Reasons</label>
              <select class="form-control" [(ngModel)]="rejectedSampleReason">
                <option value="Contaminated">Contaminated</option>
                <option value="Patient Not Arrived">Patient Not Arrived</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="rejectLabTestFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #changeDrModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">
            Change
            <span *ngIf="changDrType === 'referring'">Referring</span>
            <span *ngIf="changDrType === 'performing'">Performing</span>
            Doctor!
          </h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-8">
              <label>Doctor</label>
              <select
                class="form-control form-control-sm"
                data-live-search="true"
                id="doctorsList"
                [(ngModel)]="changDrId"
              >
                <option value="" [disabled]="true">Select Doctor</option>
                <option [value]="doc.id" *ngFor="let doc of doctors">
                  {{ doc.first_name }} {{ doc.last_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="changeDoctorFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Modal-->
<div
  class="modal fade"
  id="ResultModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2">
        <h5 class="modal-title">Patient Details</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row align-items-start mx-0 mb-7 px-0 py-3">
          <div class="col-12" style="padding-top: 5px">
            <div class="d-flex justify-content-between">
              <div class="paraFont"><strong>Scan Completed:</strong></div>
              <div
                class="paraFont"
                *ngIf="currentPatient?.scan_completed === true"
              >
                Yes
              </div>
              <div
                class="paraFont"
                *ngIf="currentPatient?.scan_completed === false"
              >
                No
              </div>
            </div>
          </div>
          <div class="col-12" style="padding-top: 15px">
            <div class="d-flex justify-content-between">
              <div class="paraFont"><strong>Total Contrast Used:</strong></div>
              <div class="paraFont">
                {{ currentPatient?.total_contrast_used }}
              </div>
            </div>
          </div>
          <div class="col-12" style="padding-top: 15px">
            <div class="d-flex justify-content-between">
              <div class="paraFont">
                <strong>Type Of Contrast Used:</strong>
              </div>
              <div class="paraFont">{{ currentPatient?.type_of_contrast }}</div>
            </div>
          </div>
          <div class="col-12" style="padding-top: 15px">
            <div class="d-flex justify-content-between">
              <div class="paraFont"><strong>Total radiation dose:</strong></div>
              <div class="">
                <span class="paraFont">{{
                  currentPatient?.total_radiation_dose
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-12" style="padding-top: 15px">
            <div class="d-flex justify-content-between">
              <div class="paraFont">
                <strong>Any Issues In Image Acquisition:</strong>
              </div>
              <div
                class="paraFont"
                *ngIf="currentPatient?.image_acquisition_issue === true"
              >
                Yes
              </div>
              <div
                class="paraFont"
                *ngIf="currentPatient?.image_acquisition_issue === false"
              >
                No
              </div>
            </div>
          </div>
          <div class="col-12" style="padding-top: 15px">
            <div class="d-flex justify-content-between">
              <div class="paraFont"><strong>Comment On Issues:</strong></div>
              <div class="paraFont">{{ currentPatient?.issue_comments }}</div>
            </div>
          </div>
          <div class="col-12" style="padding-top: 15px">
            <div class="d-flex justify-content-between">
              <div class="paraFont">
                <strong>Any Other Comments For Radiologists:</strong>
              </div>
              <div class="paraFont" style="margin-left: 143px">
                {{ currentPatient?.other_comments }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="closeResultModal()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
