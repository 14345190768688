<!--begin::Content-->
<script src="../../configuration/configuration-routing.module.ts"></script>
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">Link Lab Parameters</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button
          class="btn btn-primary btn-sm"
          type="button"
          routerLink="/lab/test"
        >
          Back
        </button>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card gutter-b mb-3">
        <div class="card-body custom-card-body">
          <form
            [formGroup]="SearchParameterForm"
            (ngSubmit)="searchLabParmeter()"
          >
            <div class="row">
              <div class="col-lg-2 col-md-3 col-sm-12 col-12">
                <div class="pickers">
                  <select
                    class="form-control form-control-sm selectpicker"
                    data-live-search="true"
                    id="labTests"
                    formControlName="search_title"
                    (change)="setParameterCode($event.target.value)"
                  >
                    <option value="" [selected]="true" [disabled]="true">
                      Enter Title
                    </option>
                    <ng-container *ngFor="let lp of labParameters">
                      <option [value]="lp.id" *ngIf="lp.is_active === true">
                        {{ lp.title }} &nbsp; &nbsp; ({{ lp.parameter_type }})
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-12 col-12 pl-0">
                <div class="row m-0">
                  <div class="col-md-1 col-sm-12 col-12">
                    <p class="m-0 mt-2">/</p>
                  </div>

                  <div class="col-md-6 col-sm-12 col-12 pr-0 pr-sm-0 pr-md-4">
                    <input
                      type="text"
                      formControlName="search_code"
                      class="form-control form-control-sm"
                      placeholder="Enter Code"
                      [ngClass]="{
                        'is-invalid': searchSubmitted && s.search_code.errors
                      }"
                    />
                  </div>

                  <div class="col-md-4 col-sm-12 col-12 mt-3 mt-sm-3 mt-md-0">
                    <button class="btn btn-primary btn-sm">
                      <span>Search</span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-md-6 text-center">
                <span
                  class="font-size-14"
                  style="display: block; padding-top: 8px"
                  *ngIf="labTest"
                >
                  <span class="setHeading">Test Name: </span>
                  {{ labTest?.profile_name }}</span
                >
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card gutter-b mb-3">
        <div class="card-body custom-card-body">
          <h2 class="mb-4">Lab Parameters List</h2>

          <form
            [formGroup]="LabParameterForm"
            (ngSubmit)="linkLabParameters()"
            *ngIf="labParametersArray"
          >
            <table class="table-responsive" style="overflow: inherit">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Parameter Code</th>
                  <th>Title</th>
                  <th>UOM</th>
                  <th>Specimen</th>
                  <th>Parameter type</th>
                  <th>Lab Configuration</th>
                  <th>Parameters</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  formArrayName="parametersArray"
                  *ngFor="let g of labParametersArray.controls; let i = index"
                >
                  <ng-container [formGroupName]="i">
                    <tr>
                      <td style="width: 5%">
                        <input
                          type="number"
                          formControlName="sort_order"
                          value=""
                          class="form-control form-control-sm"
                          (keypress)="onlyNumeric($event)"
                          placeholder="Sorting"
                        />
                      </td>
                      <td style="width: 10%">
                        <input
                          type="text"
                          formControlName="parameter_code"
                          value=""
                          class="form-control form-control-sm disable-text-box"
                          placeholder="Code"
                          [readonly]="true"
                        />
                      </td>
                      <td style="width: 10%">
                        <input
                          type="text"
                          formControlName="title"
                          value=""
                          class="form-control form-control-sm disable-text-box"
                          placeholder="title"
                          [readonly]="true"
                        />
                      </td>
                      <td style="width: 10%">
                        <input
                          type="text"
                          formControlName="uom"
                          value=""
                          class="form-control form-control-sm disable-text-box"
                          placeholder="uom"
                          [readonly]="true"
                        />
                      </td>
                      <td style="width: 10%">
                        <input
                          type="text"
                          formControlName="specimen"
                          value=""
                          class="form-control form-control-sm disable-text-box"
                          placeholder="specimen"
                          [readonly]="true"
                        />
                      </td>
                      <td style="width: 15%">
                        <input
                          type="text"
                          formControlName="parameter_type"
                          value=""
                          class="form-control form-control-sm disable-text-box"
                          placeholder="parameter type"
                          [readonly]="true"
                        />
                      </td>
                      <td style="width: 15%">
                        <select
                          formControlName="lab_configuration_id"
                          class="form-control form-control-sm selectpicker"
                          id="labConfig"
                          data-width="173px"
                          data-live-search="true"
                          data-container="body"
                        >
                          <option value="">Select</option>
                          <ng-container
                            *ngFor="let heading of labConfigurations"
                          >
                            <!--                        *ngIf="heading.configurator_type === 'heading'"-->
                            <option value="{{ heading.id }}">
                              {{ heading.description }}
                            </option>
                          </ng-container>
                        </select>
                      </td>
                      <td style="width: 15%">
                        <select
                          formControlName="primary_id"
                          class="form-control form-control-sm selectpicker"
                          id="parameters"
                          data-width="173px"
                          data-live-search="true"
                          data-container="body"
                        >
                          <option value="">Select</option>
                          <ng-container *ngFor="let lp of labParameters">
                            <option
                              [value]="lp.id"
                              *ngIf="lp.is_active === true"
                            >
                              {{ lp.title }}
                            </option>
                          </ng-container>
                        </select>
                      </td>
                      <td style="width: 5%" class="text-center">
                        <label
                          class="checkbox checkbox-sm justify-content-center"
                        >
                          <input
                            type="checkbox"
                            formControlName="is_active"
                            value=""
                            class="margin-right-7px margin-left-7px"
                            [readonly]="true"
                          />
                          <span></span>
                        </label>
                      </td>
                      <td style="width: 5%" class="text-center">
                        <button
                          type="button"
                          class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                          (click)="removeLabParameter(i)"
                        >
                          <span class="svg-icon svg-icon-primary svg-icon-2x"
                            ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                />
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                              </g></svg
                            ><!--end::Svg Icon-->
                          </span>
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>

            <div class="row text-right mt-4">
              <div class="col-12">
                <button type="submit" class="btn btn-primary btn-sm">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!--End::Content-->
