<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::Dashboard-->

      <div class="row my-3 d-print-none">
        <div class="col-2">
          <label>Range Type</label>
          <select
            class="form-control form-control-sm selectpicker show-menu-arrow show-tick"
            [(ngModel)]="rangeType"
            (change)="radiologyfilters($event.target.value)"
          >
            <option value="1" selected>Today</option>
            <option value="3">Last 3 Days</option>
            <option value="7">Week</option>
            <option value="30">Month</option>
            <option value="365">1 Year</option>
            <option value="all">Lifetime</option>
            <option value="dateRange">Date Range</option>
          </select>
        </div>

        <div class="col-2" *ngIf="rangeType === 'dateRange'">
          <div class="form-group">
            <label>From Date </label>
            <input
              type="text"
              class="form-control form-control-sm"
              autocomplete="off"
              id="fromDate"
              (keydown)="preventChangeDate()"
            />
          </div>
        </div>

        <div class="col-2" *ngIf="rangeType === 'dateRange'">
          <div class="form-group">
            <label>To Date </label>
            <input
              type="text"
              class="form-control form-control-sm"
              id="toDate"
              autocomplete="off"
              (keydown)="preventChangeDate()"
            />
          </div>
        </div>
        <div class="col-2" *ngIf="rangeType === 'dateRange'">
          <button
            class="btn btn-sm btn-primary"
            type="button"
            (click)="searchDateFilters()"
            style="margin-top: 18px"
          >
            Submit
          </button>
        </div>
      </div>

      <!--widgets-->

      <div class="row d-print-none">
        <div class="col">
          <!--begin::Stats Widget 11-->
          <!--          style="background-position: right top; background-size: 15% auto; background-image: url(assets/media/svg/shapes/abstract-1.svg)"-->
          <div class="card card-custom card-stretch gutter-b bgi-no-repeat">
            <!--begin::Body-->
            <div class="card-body p-0">
              <div
                class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
              >
                <span class="symbol symbol-50 mr-2">
                  <span class="widget-symbol">
                    <img
                      src="assets/media/hospital-icons/total-xray.png"
                      alt="Patients"
                    />
                  </span>
                </span>
                <div class="d-flex flex-column text-right">
                  <span class="text-dark-75 font-weight-bolder font-size-h3">{{
                    this.radiologyDashboardStats?.total_investigations_count ||
                      0
                  }}</span>
                  <span class="font-weight-bold mt-2">Total Investigation</span>
                </div>
              </div>
              <!--              <div id="kt_stats_widget_11_chart" class="card-rounded-bottom" data-color="success" style="height: 150px"></div>-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Stats Widget 11-->
        </div>

        <div class="col">
          <!--begin::Stats Widget 11-->
          <!--          style="background-position: right top; background-size: 15% auto; background-image: url(assets/media/svg/shapes/abstract-1.svg)"-->
          <div class="card card-custom card-stretch gutter-b bgi-no-repeat">
            <!--begin::Body-->
            <div class="card-body p-0">
              <div
                class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
              >
                <span class="symbol symbol-50 mr-2">
                  <span class="widget-symbol">
                    <img
                      src="assets/media/hospital-icons/performed.png"
                      alt="OPD"
                    />
                  </span>
                </span>
                <div class="d-flex flex-column text-right">
                  <span class="text-dark-75 font-weight-bolder font-size-h3">{{
                    this.radiologyDashboardStats?.performed_count || 0
                  }}</span>
                  <span class="font-weight-bold mt-2">Performed</span>
                </div>
              </div>
              <!--              <div id="kt_stats_widget_11_chart" class="card-rounded-bottom" data-color="success" style="height: 150px"></div>-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Stats Widget 11-->
        </div>

        <div class="col">
          <!--begin::Stats Widget 11-->
          <!--          style="background-position: right top; background-size: 15% auto; background-image: url(assets/media/svg/shapes/abstract-1.svg)"-->
          <div class="card card-custom card-stretch gutter-b bgi-no-repeat">
            <!--begin::Body-->
            <div class="card-body p-0">
              <div
                class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
              >
                <span class="symbol symbol-50 mr-2">
                  <span class="widget-symbol">
                    <img
                      src="assets/media/hospital-icons/reported.png"
                      alt="Emergency"
                    />
                  </span>
                </span>
                <div class="d-flex flex-column text-right">
                  <span class="text-dark-75 font-weight-bolder font-size-h3">{{
                    this.radiologyDashboardStats?.reported_count || 0
                  }}</span>
                  <span class="font-weight-bold mt-2">Reported</span>
                </div>
              </div>
              <!--              <div id="kt_stats_widget_11_chart" class="card-rounded-bottom" data-color="success" style="height: 150px"></div>-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Stats Widget 11-->
        </div>

        <div class="col">
          <!--begin::Stats Widget 11-->
          <!--          style="background-position: right top; background-size: 15% auto; background-image: url(assets/media/svg/shapes/abstract-1.svg)"-->
          <div class="card card-custom card-stretch gutter-b bgi-no-repeat">
            <!--begin::Body-->
            <div class="card-body p-0">
              <div
                class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
              >
                <span class="symbol symbol-50 mr-2">
                  <span class="widget-symbol">
                    <img
                      src="assets/media/hospital-icons/income.png"
                      alt="Due"
                    />
                  </span>
                </span>
                <div class="d-flex flex-column text-right">
                  <span class="text-dark-75 font-weight-bolder font-size-h3">{{
                    this.radiologyDashboardStats?.due_count || 0
                  }}</span>
                  <span class="font-weight-bold mt-2">Due</span>
                </div>
              </div>
              <!--              <div id="kt_stats_widget_11_chart" class="card-rounded-bottom" data-color="success" style="height: 150px"></div>-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Stats Widget 11-->
        </div>
      </div>

      <!--charts-->

      <div class="row d-print-none">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="card card-custom card-stretch gutter-b bgi-no-repeat">
            <!--begin::Body-->
            <div class="card-body">
              <h5>Top 10 Investigation Performed</h5>
              <figure class="highcharts-figure">
                <div id="RadInvesPerformed-Chart"></div>
              </figure>
            </div>
            <!--end::Body-->
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="card card-custom card-stretch gutter-b bgi-no-repeat">
            <!--begin::Body-->
            <div class="card-body">
              <h5>Top 10 Radiology Department</h5>
              <figure class="highcharts-figure">
                <div id="RadTopDepart-Chart"></div>
              </figure>
            </div>
            <!--end::Body-->
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="card card-custom card-stretch gutter-b bgi-no-repeat">
            <!--begin::Body-->
            <div class="card-body">
              <h5>Top 10 Department Referrals</h5>
              <figure class="highcharts-figure">
                <div id="RadTopDepartReferrals-Chart"></div>
              </figure>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div
            class="card card-custom gutter-b mt-7"
            *ngIf="servicesList.length > 0"
          >
            <!--begin::Body-->
            <div class="card-body">
              <!--begin::Table-->
              <div class="clearfix d-print-none">
                <button
                  class="btn btn-light-primary btn-sm float-right"
                  (click)="printReport()"
                >
                  Print
                </button>
              </div>
              <div class="mb-6 d-none d-print-block">
                <div class="d-flex">
                  <div style="width: 85%">
                    <!-- header table -->
                    <table class="table">
                      <tbody>
                        <tr>
                          <td
                            style="width: 15%; padding: 0"
                            class="border-top-0"
                          >
                            <img
                              [src]="settingData?.logo?.url"
                              style="width: auto; height: 106px"
                            />
                          </td>
                          <td style="width: 85%" class="border-top-0">
                            <div class="text-center">
                              <h3>{{ settingData?.title }}</h3>
                            </div>
                            <div class="font-size-h5 text-center">
                              <h5>{{ settingData?.address }}</h5>
                            </div>
                            <div class="text-center">
                              <h3>Radiology User Wise Report</h3>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="clearfix">
                <div class="patient-table" style="overflow-x: auto">
                  <!-- style="min-width: 1130px" -->
                  <table
                    class="mb-0 table table-head-custom table-vertical-center w-100"
                  >
                    <thead>
                      <tr>
                        <!-- style="min-width: 130px;max-width: 130px;width: 130px;min-height: 52px" -->
                        <th>Department</th>
                        <th class="text-center">Total Count</th>
                        <th class="text-center">OPD Count</th>
                        <th class="text-center">IPD Count</th>
                        <th class="text-center">Total Amount</th>
                        <th class="text-center">Net Amount</th>
                        <th class="text-center">OPD Amount</th>
                        <th class="text-center">IPD Amount</th>

                        <!--                      <ng-container *ngFor="let data of servicesList[0]?.months">-->
                        <!--                        <th class="text-center">{{data?.name}}</th>-->
                        <!--                      </ng-container>-->
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let data of servicesList">
                        <ng-container>
                          <tr>
                            <!-- style="min-width: 130px;max-width: 130px;width: 130px" -->
                            <td class="font-weight-bolder">
                              {{ data?.department_name }}
                            </td>
                            <td class="font-weight-bolder text-center">
                              {{ data?.service_count }}
                            </td>
                            <td class="font-weight-bolder text-center">
                              {{ data?.total_opd_count }}
                            </td>
                            <td class="font-weight-bolder text-center">
                              {{ data?.total_ipd_count }}
                            </td>
                            <td class="font-weight-bolder text-center">
                              {{ data?.total_amount_sum }}
                            </td>
                            <td class="font-weight-bolder text-center">
                              {{ data?.net_amount_sum }}
                            </td>
                            <td class="font-weight-bolder text-center">
                              {{ data?.opd_amount_sum }}
                            </td>
                            <td class="font-weight-bolder text-center">
                              {{ data?.ipd_amount_sum }}
                            </td>
                            <!--                          <ng-container *ngFor="let subData of data?.months">-->
                            <!--                            <td class="text-center">{{subData?.count}}</td>-->
                            <!--                          </ng-container>-->
                          </tr>
                          <tr
                            *ngFor="let subDepartment of data?.sub_department"
                          >
                            <!-- style="min-width: 130px;max-width: 130px;width: 130px" -->
                            <td>{{ subDepartment?.department_name }}</td>
                            <td class="text-center">
                              {{ subDepartment?.service_count }}
                            </td>
                            <td class="text-center">
                              {{ subDepartment?.total_opd_count }}
                            </td>
                            <td class="text-center">
                              {{ subDepartment?.total_ipd_count }}
                            </td>
                            <td class="text-center">
                              {{ subDepartment?.total_amount_sum }}
                            </td>
                            <td class="text-center">
                              {{ subDepartment?.net_amount_count }}
                            </td>
                            <td class="text-center">
                              {{ subDepartment?.opd_amount_sum }}
                            </td>
                            <td class="text-center">
                              {{ subDepartment?.ipd_amount_sum }}
                            </td>
                            <!--                          <ng-container *ngFor="let subData of subDepartment?.months">-->
                            <!--                            <td class="text-center">{{subData?.count}}</td>-->
                            <!--                          </ng-container>-->
                          </tr>
                        </ng-container>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--end::Table-->
              <!--pagination-->
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
