<!--begin::Main-->
<div class="d-flex flex-column flex-root">
  <!--begin::Login-->
  <div
    class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
    id="kt_login"
  >
    <!--begin::Aside-->
    <div
      class="login-aside d-flex flex-column flex-row-auto"
      style="background-color: #3599fe38; height: 100vh; position: relative"
    >
      <!--begin::Aside Top-->
      <div
        class="d-flex flex-column-auto flex-column pt-lg-40 pt-15"
        style="z-index: 99"
      >
        <!--begin::Aside header-->
        <!--        <a  class="text-center mb-10">-->
        <!--          <img src="assets/media/logos/logo-letter-1.png" class="max-h-70px" alt="" />-->
        <!--        </a>-->
        <!--end::Aside header-->
        <!--begin::Aside title-->
        <!--        <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: #986923;">Affordable, scalable <br> & secure healthcare solutions<br></h3>-->
        <!--end::Aside title-->
      </div>
      <!--end::Aside Top-->
      <!--begin::Aside Bottom-->
      <!--      <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" style="background-image: url(assets/media/svg/illustrations/login-visual-1.svg)"></div>-->
      <!--      <img src="assets/media/svg/illustrations/loginbg.png" style="width: 100%;position: absolute;bottom: 0px;max-height: 100vh;">-->
      <img
        [src]="homeImage"
        style="width: 100%; position: absolute; bottom: 0px; max-height: 100vh"
      />
      <!--end::Aside Bottom-->
    </div>
    <!--begin::Aside-->

    <!--    <div class="flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden background-image-design" [style.background]="'url(' + backgroundImage + ')'" [ngClass]="{'background-image-design' : backgroundImage}">-->
    <div
      class="flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden background-image-design"
    >
      <img
        *ngIf="backgroundImage"
        [src]="backgroundImage"
        style="
          width: 100%;
          position: absolute;
          bottom: 0px;
          max-height: 100vh;
          z-index: 111;
        "
      />
      <!--begin:: Login Content-->
      <div
        *ngIf="!isShowLogin"
        class="login-content p-7 mx-auto"
        style="z-index: 333"
      >
        <!--begin::Content body-->
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <!--begin::Form-->

            <form class="form" [formGroup]="loginForm" (ngSubmit)="submit()">
              <!--begin::Title-->
              <div class="pb-13 pt-lg-0 pt-5">
                <h2 class="font-weight-bolder text-dark">
                  Welcome to<br />{{
                    organizationName || "Camden Health System"
                  }}
                </h2>
                <!--              <span class="text-muted font-weight-bold font-size-h4">New Here?-->
                <!--									<a href="javascript:;" id="kt_login_signup" class="text-primary font-weight-bolder">Create an Account</a></span>-->
              </div>
              <!--begin::Title-->

              <!--begin::Form group-->
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Username</label
                  >
                  <!--              <a data-toggle="modal" data-target="#resetusername" class="text-muted cursor-pointer  font-weight-bolder pt-5">Forgot Username ?</a>-->
                </div>
                <input
                  class="form-control h-auto p-3"
                  type="text"
                  autocomplete="new-name"
                  formControlName="email"
                  [ngClass]="{ 'is-invalid': isSubmit && f.email.errors }"
                />
              </div>
              <!--end::Form group-->

              <!--begin::Form group-->
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Password</label
                  >
                  <!--                data-toggle="modal" data-target="#resetpassword"-->
                  <a
                    class="text-muted cursor-pointer font-weight-bolder pt-5"
                    (click)="resetForgotPassForm(); openPasswordModal()"
                    >Forgot Password ?</a
                  >
                </div>
                <input
                  class="form-control h-auto p-3"
                  formControlName="password"
                  [ngClass]="{ 'is-invalid': isSubmit && f.password.errors }"
                  type="password"
                  autocomplete="off"
                />
              </div>
              <!--end::Form group-->
              <!--begin::Action-->
              <div class="pb-lg-0 pb-5">
                <!--              id="kt_login_signin_submit"-->
                <button
                  type="submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-6 py-2 mb-3 mr-3"
                >
                  Sign In
                </button>
                <!--              <button type="button" class="btn btn-light-primary font-weight-bolder px-6 py-2 my-3 font-size-lg">-->
                <!--									<span class="svg-icon svg-icon-md">-->
                <!--										&lt;!&ndash;begin::Svg Icon | path:assets/media/svg/social-icons/google.svg&ndash;&gt;-->
                <!--										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
                <!--											<path d="M19.9895 10.1871C19.9895 9.36767 19.9214 8.76973 19.7742 8.14966H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7346 15.0813L13.7155 15.2051L16.7429 17.4969L16.9527 17.5174C18.879 15.7789 19.9895 13.221 19.9895 10.1871Z" fill="#4285F4" />-->
                <!--											<path d="M10.1993 19.9313C12.9527 19.9313 15.2643 19.0454 16.9527 17.5174L13.7346 15.0813C12.8734 15.6682 11.7176 16.0779 10.1993 16.0779C7.50243 16.0779 5.21352 14.3395 4.39759 11.9366L4.27799 11.9466L1.13003 14.3273L1.08887 14.4391C2.76588 17.6945 6.21061 19.9313 10.1993 19.9313Z" fill="#34A853" />-->
                <!--											<path d="M4.39748 11.9366C4.18219 11.3166 4.05759 10.6521 4.05759 9.96565C4.05759 9.27909 4.18219 8.61473 4.38615 7.99466L4.38045 7.8626L1.19304 5.44366L1.08875 5.49214C0.397576 6.84305 0.000976562 8.36008 0.000976562 9.96565C0.000976562 11.5712 0.397576 13.0882 1.08875 14.4391L4.39748 11.9366Z" fill="#FBBC05" />-->
                <!--											<path d="M10.1993 3.85336C12.1142 3.85336 13.406 4.66168 14.1425 5.33717L17.0207 2.59107C15.253 0.985496 12.9527 0 10.1993 0C6.2106 0 2.76588 2.23672 1.08887 5.49214L4.38626 7.99466C5.21352 5.59183 7.50242 3.85336 10.1993 3.85336Z" fill="#EB4335" />-->
                <!--										</svg>-->
                <!--                    &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
                <!--									</span>Sign in with Google</button>-->
              </div>
              <!--end::Action-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Signin-->
        </div>
        <!--end::Content body-->
        <!--begin::Content footer-->
        <div class="py-7 py-lg-0">
          <div class="text-dark-50 font-size-lg font-weight-bolder">
            <span class="mr-1">2021©</span>
            <a
              href="https://irvinetek.com/"
              target="_blank"
              class="text-dark-75 text-hover-primary"
              >Powered By IrvineTek</a
            >
          </div>
          <br />
          <!--          <a [routerLink]="'/register-organization'" class="text-primary font-weight-bolder mr-5 font-size-lg">Register</a>-->
          <a class="text-primary font-weight-bolder font-size-lg">Terms</a>
          <!--        <a  class="text-primary ml-5 font-weight-bolder font-size-lg">Plans</a>-->
          <a class="text-primary ml-5 font-weight-bolder font-size-lg"
            >Contact Us</a
          >
        </div>
        <!--end::Content footer-->
      </div>
      <!--end:: Login Content-->
    </div>

    <!--begin:: Update Password Content-->
    <div
      *ngIf="isShowLogin"
      class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
    >
      <!--begin::Content body-->
      <div class="d-flex flex-column-fluid flex-center">
        <!--begin::Signin-->
        <div class="login-form login-signin">
          <!--begin::Form-->

          <form
            class="form"
            [formGroup]="passwordResetForm"
            (ngSubmit)="onUpdate()"
          >
            <!--begin::Title-->
            <div class="pb-13 pt-lg-0 pt-5">
              <h2 class="font-weight-bolder text-dark mb-3">Update Password</h2>
              <span class="warning text-white"
                >It is necessary to update your password before first
                login</span
              >
            </div>
            <!--begin::Title-->

            <!--begin::Form group-->
            <div class="form-group">
              <div class="d-flex justify-content-between mt-n5">
                <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                  >New Password</label
                >
              </div>
              <div class="input-icon">
                <input
                  class="form-control h-auto p-3"
                  type="text"
                  autocomplete="new-name"
                  type="password"
                  formControlName="newPassword"
                  #new
                  [ngClass]="{
                    'is-invalid': isSubmitPass && fUpdate.newPassword.errors
                  }"
                />
                <span>
                  <i
                    (click)="
                      new.type = new.type == 'password' ? 'text' : 'password';
                      changeEyeIconPassword()
                    "
                    class="eye-icon"
                    [ngClass]="newPassIcon ? 'far fa-eye' : 'far fa-eye-slash'"
                  >
                  </i>
                </span>
              </div>
            </div>
            <!--end::Form group-->

            <!--begin::Form group-->
            <div class="form-group">
              <div class="d-flex justify-content-between mt-n5">
                <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                  >Confirm Password</label
                >
              </div>
              <div class="input-icon">
                <input
                  class="form-control h-auto p-3"
                  formControlName="newPasswordConfirm"
                  #newC
                  [ngClass]="{
                    'is-invalid':
                      isSubmitPass && fUpdate.newPasswordConfirm.errors
                  }"
                  type="password"
                  autocomplete="off"
                />
                <span>
                  <i
                    (click)="
                      newC.type = newC.type == 'password' ? 'text' : 'password';
                      changeEyeIconConfirmPassword()
                    "
                    class="eye-icon"
                    [ngClass]="
                      newConfirmPassIcon ? 'far fa-eye' : 'far fa-eye-slash'
                    "
                  >
                  </i>
                </span>
              </div>
            </div>
            <!--end::Form group-->
            <!--begin::Action-->
            <div class="pb-lg-0 pb-5">
              <button
                type="submit"
                class="btn btn-primary font-weight-bolder font-size-h6 px-6 py-2 mb-3 mr-3"
              >
                Submit
              </button>
            </div>
            <!--end::Action-->
          </form>
          <!--end::Form-->
        </div>
        <!--end::Signin-->
      </div>
      <!--end::Content body-->
      <!--begin::Content footer-->
      <div class="py-7 py-lg-0">
        <div class="text-dark-50 font-size-lg font-weight-bolder">
          <span class="mr-1">2021©</span>
          <a
            href="https://irvinetek.com/"
            target="_blank"
            class="text-dark-75 text-hover-primary"
            >Powered By IrvineTek</a
          >
        </div>
        <br />
        <a class="text-primary font-weight-bolder font-size-lg">Terms</a>
        <!--        <a  class="text-primary ml-5 font-weight-bolder font-size-lg">Plans</a>-->
        <a class="text-primary ml-5 font-weight-bolder font-size-lg"
          >Contact Us</a
        >
      </div>
      <!--end::Content footer-->
    </div>
    <!--end:: Update Password Content-->
  </div>
  <!--end::Login-->
</div>
<!--end::Main-->

<!-- Modal-->
<div
  class="modal fade"
  id="resetusername"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content w-70">
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <p>Enter email or mobile number associated with your account</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Mobile"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Email"
              />
            </div>
          </div>
        </div>

        <div class="row text-center">
          <div class="col">
            <button
              class="btn btn-primary btn-sm submit-btn"
              (click)="forgotUser()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="resetpassword"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content w-70">
      <div class="modal-body">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="submitForgotPass()">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <p>Enter email associated with your account</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <input
                  type="email"
                  class="form-control form-control-sm"
                  formControlName="email"
                  placeholder="Email"
                  [ngClass]="{ 'is-invalid': isForgot && cont.email.errors }"
                />
              </div>
            </div>
          </div>

          <div class="row text-center">
            <div class="col">
              <button class="btn btn-primary btn-sm submit-btn" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="promissionsAlertModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <img src="../../../assets/media/error/allert.png" />
          <p class="font-weight-bold font-size-h5 text-dark-75 mt-3">
            you do not have permission to access this functionality.
          </p>
          <p>Please connect with IT Administration</p>
          <!--          <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>-->
        </div>
      </div>
    </div>
  </div>
</div>
