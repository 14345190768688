<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Consent Report</h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-primary btn-sm" (click)="printReport()">
          Print
        </button>
        <!--        <button class="btn btn-primary btn-sm ml-2" (click)="goBack()">Back</button>-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="card pdf-card" id="reports">
        <div class="card-body birth-main">
          <div class="d-flex">
            <div style="width: 85%">
              <!-- header table -->
              <table class="table">
                <tbody>
                <tr class="borders">
                  <td style="width: 15%">
                    <img
                      [src]="settingData?.logo?.url"
                      style="width: auto; height: 106px"
                    />
                  </td>
                  <td style="width: 85%">
                    <div class="text-center">
                      <h2 style="font-size: 19px">
                        {{ settingData?.title }}
                      </h2>
                    </div>
                    <div class="font-size-h5 text-center">
                      <h5 style="font-size: 17px">
                        {{ settingData?.address }}
                      </h5>
                    </div>
                    <div class="text-center">
                      <h2 style="font-size: 17px">Consent Report</h2>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <!-- patient info table -->
              <!-- patient info detail table -->
              <table class="w-100 info_table">
                <tbody>
                <tr>
                  <app-patient-info></app-patient-info>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr class="my-6 line" />

          <ng-container *ngFor="let dataItem of checkListDetail; let i = index">
            <div
              *ngIf="dataItem.check_list_temp_config.length > 0"
              style="border-bottom: 1px solid #a08d8d; margin-bottom: 21px"
            >
              <h3>{{ dataItem.heading | titlecase }}</h3>
              <div
                class="row"
                *ngFor="
                  let data of dataItem.check_list_temp_config;
                  let z = index
                "
              >
                <div
                  class="col"
                  *ngFor="
                    let item of data.check_list_template_configurators;
                    let j = index
                  "
                >
                  <ng-container
                    *ngIf="item.check_list_type === 'heading'"
                  ></ng-container>
                  <ng-container *ngIf="item.check_list_type === 'text_editor'">
                    <strong class="mr-2">{{ item.name | titlecase }}:</strong
                    ><span [innerHTML]="item.value"></span>
                  </ng-container>
                  <ng-container
                    *ngIf="item.check_list_type === 'all_employees_list'"
                  >
                    <strong class="mr-2">{{ item.name | titlecase }}:</strong>
                    {{ item.employee_name }}
                  </ng-container>
                  <ng-container
                    *ngIf="item.check_list_type === 'all_doctor_list'"
                  >
                    <strong class="mr-2">{{ item.name | titlecase }}:</strong>
                    {{ item.employee_name }}
                  </ng-container>
                  <ng-container *ngIf="item.check_list_type === 'drop_down'">
                    <strong class="mr-2">{{ item.name | titlecase }}:</strong>
                    {{ item.value_name }}
                  </ng-container>
                  <ng-container
                    *ngIf="
                      item.check_list_type !== 'text_editor' &&
                      item.check_list_type !== 'all_employees_list' &&
                      item.check_list_type !== 'all_doctor_list' &&
                      item.check_list_type !== 'heading' &&
                      item.check_list_type !== 'drop_down'
                    "
                  >
                    <strong class="mr-2">{{ item.name | titlecase }}:</strong>
                    {{ item.value }}
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="d-flex" style="margin-bottom: 10px" *ngIf="signature">
            <table class="info_table w-100">
              <tbody>
              <tr class="mt-5">
<!--                <td style="width: 50%">-->
<!--                  <strong>Patient/Attendant Signature</strong>-->
<!--                </td>-->
                <td class="text-left">
                  <div style="height: 100px">
                    <img
                      style="width: 150px"
                      src="{{ signature }}"
                    />
                  </div>
                  <div><strong>Patient/Attendant Signature</strong></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
