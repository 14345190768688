import { Component, OnInit } from '@angular/core';
import { StockService } from '../../../services/stock.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialysisService } from '../../../services/dialysis.service';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-default-dialysis-config',
  templateUrl: './default-dialysis-config.component.html',
  styleUrls: ['./default-dialysis-config.component.css'],
})
export class DefaultDialysisConfigComponent implements OnInit {
  isPSSearching = false;
  productListData = [];
  productSearchResult = false;
  primingObj = {
    config_type: 'dialysis_priming_solution',
    defaultable_type: 'Item',
    defaultable_id: null,
  };
  productName = '';
  primingSolDefaultVal: any;
  screenName = '';

  constructor(
    private stockService: StockService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private dialysisService: DialysisService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  ngOnInit(): void {
    this.fetchPrimingSol();
  }

  fetchPrimingSol(): any {
    this.ngxLoader.start();
    this.dialysisService.getDialysisPrimingSol().subscribe(
      (data) => {
        this.primingSolDefaultVal = data.default_configurators[0];
        this.ngxLoader.stop();
        if (this.primingSolDefaultVal) {
          this.productName = this.primingSolDefaultVal.defaultable.product_name;
          this.primingObj.defaultable_id =
            this.primingSolDefaultVal.defaultable_id;
          $('.search-result-ps').hide();
          this.isPSSearching = false;
        }
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  getProductList(event, type, className): any {
    setTimeout(() => {
      this.productListData = [];
      this.productSearchResult = false;
      $(className).hide();
      if (event.target.value.length < 2) {
        return;
      }
      if (type === 'priming_sol') {
        this.isPSSearching = true;
      } else {
        // this.isSearching = true;
      }
      $(className).show();
      this.stockService.searchProduct('', event.target.value).subscribe(
        (data) => {
          this.productListData = data.items;
          this.productSearchResult = true;
        },
        (err) => {
          this.productListData = [];
          this.productSearchResult = true;
        },
      );
    }, 1000);
  }
  setProduct(item): any {
    this.productName = item.product_name;
    this.primingObj.defaultable_id = item.id;
    $('.search-result-ps').hide();
    this.isPSSearching = false;
  }

  createDialysisService(): any {
    // this.submitted = true;
    if (!this.primingObj.defaultable_id) {
      toastr.error('Select Product');
      return;
    }
    this.ngxLoader.start();
    this.dialysisService.createDialysisPrimingSol(this.primingObj).subscribe(
      (data) => {
        toastr.success('Save Default Priming Sol');
        this.productName = '';
        // this.router.navigate(['/dialysis/dialysis-services']);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
}
