import 'rxjs/add/operator/do';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  userName: any;
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    this.userName = JSON.parse(localStorage.getItem('currentUser'));
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let modifiedRequest = request.clone();
    // const modifiedRequestnew = request.clone({ headers: request.headers.append('tz-system', '3242342') });
    return next.handle(request).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (
            err.status === 401 ||
            err.status === 403 ||
            err.status === 500 ||
            err.status === 400
          ) {
            if (!modifiedRequest.urlWithParams.includes('logs/logs')) {
              let obj = {
                request: modifiedRequest.body,
                err: err.error,
                url: modifiedRequest.urlWithParams || '',
                method: modifiedRequest.method || '',
                status: err.status || '',
                user: this.userName?.full_name || '',
              };
              this.authService.saveErrorLogs(obj).subscribe(
                (data) => {},
                (err) => {},
              );
            }
            if (err.status === 401 || err.status === 403) {
              localStorage.clear();
              this.router.navigate(['/login']);
            }
          }
        }
      },
    );
  }
}
