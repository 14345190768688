import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataSharingServiceService {
  allTrue = false;
  isArrayData = false;
  isImageData = true;
  isUploadData = true;
  imageFiles: any = [];
  receivedFiles: any[];
  id: any;
  constructor() {}
}
