<!--begin::Entry-->
<div class="d-flex flex-column-fluid main-content">
  <!--begin::Container-->
  <!-- container-xl container-xxl -->
  <div class="container-fluid p-0">
    <div class="card card-custom d-print-none">
      <div class="card-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <div class="form-group">
              <label> Date <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm apptDate"
                [(ngModel)]="date"
                readonly="readonly"
              />
            </div>
          </div>
<!--          Department-->
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <div class="form-group">
            <label>Department </label>
            <select
              class="form-control form-control-sm bootstrap-select-container departmentSelectPicker"
              data-live-search="true"
              id="departmentSelectPicker"
              data-none-selected-text="Select"
              (change)="getDoctors($event.target.value)"
              [(ngModel)]="department_Id"
            >
              <option value="">select</option>
              <option *ngFor="let dep of departments" [value]="dep.id">
                {{ dep.title }}
              </option>
            </select>
          </div>
          </div>
<!--          Doctor-->
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <div class="form-group">
              <label> Doctor </label>
              <select
                class="form-control form-control-sm bootstrap-select-container doctorSelectPicker"
                (change)="getDepartments($event.target.value)"
                data-none-selected-text="Select"
                data-container="body"
                data-live-search="true"
                [(ngModel)]="Doctor_Id"
              >
                <option value="" [selected]="true">
                  Select
                </option>
                <option [value]="doc.id" *ngFor="let doc of doctors_by_dept">
                  {{ doc.first_name }} {{ doc.last_name }}
                </option>
              </select>
            </div>
          </div>
          <!--Status -->
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <div class="form-group">
              <label>Status</label>
              <select class="form-control form-control-sm" [(ngModel)]="status">
                <option value="">Select</option>
                <option [ngValue]="'reserved'">Booked</option>
                <option [ngValue]="'booked'">Confirmed</option>
              </select>
            </div>
          </div>
          <!--Search button-->
          <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
            <div class="form-group">
              <button
                type="submit"
                class="btn btn-primary btn-sm"
                ngbTooltip="Search" (click)="getBookingListing()"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    Listing Start-->
    <div
      class="card card-custom gutter-b mt-7"
      *ngIf="bookedApptListing.length > 0"
    >
      <!--begin::Body-->
      <div class="card-body">
<!--        <div class="table_top_bar mb-3 d-print-none">-->
<!--          <div class="row">-->
<!--            <div class="col-12 col-sm-12 text-right">-->
<!--              <div class="dropdown dropdown-inline mr-2">-->
<!--                <button-->
<!--                  type="button"-->
<!--                  class="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle"-->
<!--                  data-toggle="dropdown"-->
<!--                  aria-haspopup="true"-->
<!--                  aria-expanded="true"-->
<!--                >-->
<!--                    <span class="svg-icon svg-icon-md">-->
<!--                      &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Design/PenAndRuller.svg&ndash;&gt;-->
<!--                      <svg-->
<!--                        xmlns="http://www.w3.org/2000/svg"-->
<!--                        xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--                        width="24px"-->
<!--                        height="24px"-->
<!--                        viewBox="0 0 24 24"-->
<!--                        version="1.1"-->
<!--                      >-->
<!--                        <g-->
<!--                          stroke="none"-->
<!--                          stroke-width="1"-->
<!--                          fill="none"-->
<!--                          fill-rule="evenodd"-->
<!--                        >-->
<!--                          <rect x="0" y="0" width="24" height="24"></rect>-->
<!--                          <path-->
<!--                            d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"-->
<!--                            fill="#000000"-->
<!--                            opacity="0.3"-->
<!--                          ></path>-->
<!--                          <path-->
<!--                            d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"-->
<!--                            fill="#000000"-->
<!--                          ></path>-->
<!--                        </g>-->
<!--                      </svg>-->
<!--                      &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                  Export-->
<!--                </button>-->
<!--                &lt;!&ndash;begin::Dropdown Menu&ndash;&gt;-->
<!--                <div-->
<!--                  class="dropdown-menu dropdown-menu-sm dropdown-menu-right"-->
<!--                  x-placement="bottom-end"-->
<!--                  style="-->
<!--                      position: absolute;-->
<!--                      transform: translate3d(-71px, 36px, 0px);-->
<!--                      top: 0px;-->
<!--                      left: 0px;-->
<!--                      will-change: transform;-->
<!--                    "-->
<!--                >-->
<!--                  &lt;!&ndash;begin::Navigation&ndash;&gt;-->
<!--                  <ul class="navi flex-column navi-hover py-2">-->
<!--                    <li-->
<!--                      class="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2"-->
<!--                    >-->
<!--                      Choose an option:-->
<!--                    </li>-->
<!--                    <li class="navi-item">-->
<!--                      <a class="navi-link">-->
<!--                          <span class="navi-icon">-->
<!--                            <i class="fas fa-file-alt"></i>-->
<!--                          </span>-->
<!--                        <span class="navi-text" (click)="printReport()">Print</span>-->
<!--                      </a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                  &lt;!&ndash;end::Navigation&ndash;&gt;-->
<!--                </div>-->
<!--                &lt;!&ndash;end::Dropdown Menu&ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="d-none d-print-flex">
          <div style="width: 85%">
            <!-- header table -->
            <table class="table">
              <tbody>
              <tr class="borders">
                <td style="width: 15%">
                  <img
                    [src]="settingData?.logo?.url"
                    alt="Hospital"
                    style="width: auto; height: 106px"
                  />
                </td>
                <td style="width: 85%">
                  <div class="text-center">
                    <h3>{{ settingData?.title }}</h3>
                  </div>
                  <div class="font-size-h5 text-center">
                    <h5>{{ settingData?.address }}</h5>
                  </div>
<!--                  <div class="text-center"><h3> Report</h3></div>-->
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div style="width: 15%">
          </div>
        </div>

        <hr class="my-6 line d-none d-print-block" />
        <div class="table-responsive position-relative" id="doctor_wise_share_report">
          <table
            class="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_3"
          >
            <thead>
            <tr>
              <th style="width: 20%">Appointment Date</th>
              <th style="width: 30%">Patient Info</th>
              <th style="width: 30%">Doctor / Department</th>
              <th style="width: 10%">Status</th>
              <th style="width: 10%; text-align: right" >Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container>
              <tr *ngFor="let invoice_item of bookedApptListing; let i = index">
                <td style="vertical-align: top">
                    <span
                      class="text-dark-75  mb-1 font-size-lg"
                    >{{
                        invoice_item.date | dateFormat
                      }}</span
                    >
                </td>
                <td style="vertical-align: top">
                    <span
                      class="text-dark-75  mb-1 font-size-lg"
                    >{{
                        invoice_item.full_name
                      }}</span
                    >
                  <span class="text-muted font-weight-bold d-block">{{
                      invoice_item?.mrn
                    }}</span>
                </td>
                <td style="vertical-align: top">
                    <span
                      class="text-dark-75  mb-1 font-size-lg"
                    >{{
                        invoice_item.doctor_name
                      }}</span
                    >
                  <span class="text-muted font-weight-bold d-block">{{
                      invoice_item?.dept_name
                    }}</span>
                </td>
                <td  style="vertical-align: middle">
                     <span
                       class="label label-lg label-light-success label-inline"
                       *ngIf="invoice_item?.slot_status === 'booked'"
                     >Confirmed</span
                     >
                  <span
                    class="label label-lg label-light-primary label-inline"
                    *ngIf="invoice_item?.slot_status === 'reserved'"
                  >Booked</span
                  >
                </td>
                <td class="text-right">
                  <div class="dropdown dropleft">
                    <button
                      type="button"
                      class="btn btn-light-primary btn-icon btn-sm"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="ki ki-bold-more-ver"></i>
                    </button>
<!--                    <div class="dropdown-menu">-->
<!--                    </div>-->
                  </div>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <!--pagination-->
<!--        <div class="pagination_main d-print-none">-->
<!--          <ngb-pagination-->
<!--            [(page)]="page"-->
<!--            (pageChange)="loadPage(page)"-->
<!--            [pageSize]="perPage"-->
<!--            [collectionSize]="totalPages"-->
<!--            [maxSize]="5"-->
<!--            [rotate]="true"-->
<!--            [ellipses]="false"-->
<!--            [boundaryLinks]="true"-->
<!--          >-->
<!--          </ngb-pagination>-->
<!--          <div class="dropdown">-->
<!--            <a-->
<!--              class="btn dropdown-toggle btn-light btn-sm"-->
<!--              role="button"-->
<!--              id="pagination_btn"-->
<!--              data-toggle="dropdown"-->
<!--              aria-haspopup="true"-->
<!--              aria-expanded="false"-->
<!--            >-->
<!--                <span class="page_count">-->
<!--                  {{ perPage }}-->
<!--                </span>-->
<!--              <span class="count_count_icon"></span>-->
<!--            </a>-->
<!--            <div class="dropdown-menu" aria-labelledby="pagination_btn">-->
<!--              <a class="dropdown-item" (click)="selectPaginationLimit(25)"-->
<!--              >25</a-->
<!--              >-->
<!--              <a class="dropdown-item" (click)="selectPaginationLimit(50)"-->
<!--              >50</a-->
<!--              >-->
<!--              <a class="dropdown-item" (click)="selectPaginationLimit(75)"-->
<!--              >75</a-->
<!--              >-->
<!--              <a class="dropdown-item" (click)="selectPaginationLimit(100)"-->
<!--              >100</a-->
<!--              >-->
<!--              <a class="dropdown-item" (click)="selectPaginationLimit('')"-->
<!--              >All</a-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <!--end::Body-->
    </div>

  </div>
</div>
