import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ExternalHospitalMaintenanceService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/send_outs?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  indexV2(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/send_outs/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
