import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HospitalService } from '../../services/hospital.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { formatDate } from '@angular/common';

declare var toastr: any;
declare var $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
  hospitalName: any;
  settingData: any;
  TopBarImage: any;
  expiryData = [];
  today = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  isMainModalOpen = true;
  openAddTaskModal = false;
  currentUrl = '';
  constructor(
    private authService: AuthService,
    private hospitalService: HospitalService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.hospitalName = JSON.parse(localStorage.getItem('currentUser'));
    this.settingData = this.hospitalName.medical_units[0];
    this.hospitalName = this.hospitalName.medical_units[0].title;
    // this.TopBarImage = this.
  }
  getSettingsData(): any {
    this.ngxService.start();
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = '';
        this.settingData = data.medical_unit;
        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.error);
      },
    );
  }

  ngOnInit(): void {
    // this.getSettingsData();
    this.currentUrl = this.router.url;
    this.router.events.subscribe(() => {
      this.currentUrl = this.router.url;
    });
    this.getExpiryModule();
    $('#viewFullScreen').on('click', () => {
      $('body').toggleClass('hideTopBarSideBar');
    });
  }
  getExpiryModule(): any {
    this.hospitalService.getExpiryModuleOfHospital().subscribe(
      (data) => {
        this.expiryData = data;
        if (this.expiryData.length > 0) {
          $('#kt_header').css('top', '30px');
          $('#kt_subheader').css('top', '95px');
          $('#kt_content').css('padding-top', '45px');
        }
      },
      (err) => {},
    );
  }
  changeOfRoutes(): any {
    if (this.expiryData.length > 0) {
      $('#kt_header').css('top', '30px');
      $('#kt_subheader').css('top', '95px');
      $('#kt_content').css('padding-top', '45px');
    } else {
      $('#kt_header').css('top', '0px');
      $('#kt_subheader').css('top', '65px');
      $('#kt_content').css('padding-top', '15px');
    }
  }
  logout(): any {
    this.authService
      .logOutv2(localStorage.getItem('auth_token'))
      .subscribe((data) => {
        localStorage.clear();
        this.router.navigate(['login-v2']);
        localStorage.setItem('is_session_expired', 'true');
      });
  }
  activeToggleBar(): any {
    if ($('.hospital_name_toggle').hasClass('active')) {
      $('.hospital_name_toggle').removeClass('active');
    } else {
      $('.hospital_name_toggle').addClass('active');
    }
  }

  openModal(): any {
    this.openAddTaskModal = true;
    $('#viewTaskModal').modal('show');
    $('#medicationsAllergies').val(null).trigger('change');
  }
  closeAddTaskModal(): any{
    this.openAddTaskModal = false;
    $('#viewTaskModal').modal('hide');
  }
}
