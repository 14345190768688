import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../../../services/patient.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientServiceInvoicesService } from '../../../services/patient-service-invoices.service';
import { EmployeeRegistrationService } from '../../../services/employee-registration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RouteShareService } from '../../../services/route-share.service';
import {FinanceConfigurationService} from '../../../services/finance-configuration.service';
import {FinanceControlsService} from '../../../services/finance-controls.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-patient-discount-approval-v2',
  templateUrl: './patient-discount-approval-v2.component.html',
  styleUrls: ['./patient-discount-approval-v2.component.css'],
})
export class PatientDiscountApprovalV2Component implements OnInit {
  @ViewChild('updateConfirm') updateConfirm: ElementRef;
  @ViewChild('invoiceItemDetailsModal') invoiceItemDetailsModal: ElementRef;
  @ViewChild('updateDiscountStatusConfirm')
  updateDiscountStatusConfirm: ElementRef;
  @ViewChild('updateCancelStatusConfirm') updateCancelStatusConfirm: ElementRef;
  @ViewChild('updateAdvanceCancelStatusConfirm')
  updateAdvanceCancelStatusConfirm: ElementRef;
  PatientSearchForm: UntypedFormGroup;
  PatientServiceInvoiceForm: UntypedFormGroup;
  submitted = false;
  family_mrn: any;
  patient: any;
  patient_admissions = [];
  current_environment: string;
  patientServiceHistory = [];
  patientDiscount = [];
  copyPatientDiscount = [];
  isChecked = false;

  patientServiceInvoices = [];
  discount_comments = '';
  discount_reason_id = '';
  discountReasonList = [];
  cancelReasonList = [];
  invoiceItemDetails: any;
  discountTypeInvoiceAll = 'amount';
  discountTypeValueInvoiceAll = 0;
  totalDiscountedInvoiceAmount = 0;
  totalDiscountedAmount = 0;
  filterType = 'approvedDiscount';
  selectedServices = [];
  cancelServicesList = [];
  isOpen = true;
  screenName = '';

  patinetAdvancesList = [];
  bookAccounts = [];
  batchList = [];
  book_of_account: '';
  batch: '';
  financeControlsDataBill = false;
  cancelAdvances = {
    advance_ids: [],
    cash: 0,
    patient_id: '',
    advance_cancel_reason_id: '',
    advance_cancel_comments: '',
  };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private patientServiceInvoicesService: PatientServiceInvoicesService,
    private chRef: ChangeDetectorRef,
    private employeeService: EmployeeRegistrationService,
    private modalService: NgbModal,
    private routeSevice: RouteShareService,
    private financeConfigService: FinanceConfigurationService,
    private financeControlService: FinanceControlsService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  ngOnInit(): any {
    // const today = new Date() ;
    // const yesterday = new Date();
    // yesterday.setDate(yesterday.getDate() - 1);
    // const formttedYesterday = date.format(yesterday, 'YYYY-MM-DDThh:mm');
    // const formattedToday = date.format(today, 'YYYY-MM-DDThh:mm');

    this.current_environment = environment.rorUrl;
    this.PatientSearchForm = this.formBuilder.group({
      search_query: [''],
      type: ['default'],
    });
    this.PatientServiceInvoiceForm = this.formBuilder.group({
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      patient_id: ['', Validators.required],
      department_id: [''],
      parent_department_id: [''],
      sub_department: [''],
    });
    this.getFinanceControl();
    this.searchPatient();
    // this.getDiscountReason();
    this.getCancelReasonList();
    this.getBookAccounts();
  }
  getDiscountReason(): any {
    this.ngxLoader.start();
    this.employeeService.fetchConfigurationV2('discount_reason').subscribe(
      (data) => {
        this.discountReasonList = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getCancelReasonList(): any {
    this.ngxLoader.start();
    this.employeeService
      .fetchConfigurationV2('service_cancel_reason')
      .subscribe(
        (data) => {
          this.cancelReasonList = data.configurator_types;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  searchPatient(): any {
    this.fetchPatients();
  }
  fetchPatients(): any {
    if (this.route.snapshot.queryParams.patient_id) {
      this.PatientSearchForm.patchValue({
        search_query: this.route.snapshot.queryParams.patient_id,
      });
    }
    this.ngxLoader.start();
    const referal = false;
    this.patientService
      .searchPatientByAdjustmentApprovalV2(
        this.PatientSearchForm.value,
        '/patient/adjustments-approval-v2',
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.patient_admissions = data.patients;
          if (this.patient_admissions.length > 0) {
            this.selectPatient(this.patient_admissions[0]);
          }
        },
        (err) => {
          this.patient_admissions = [];
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  selectPatient(data): any {
    this.isOpen = true;
    this.patient = data;
    this.closePatientList();
    this.searchInvoices();
    this.resetSelectBoxesCancelAdvance();
    this.patientAdvances();
  }
  updateInvoiceTypePatient(): any {
    this.closePatientList();
    this.searchInvoices();
  }
  patientAdvances(): any {
    this.ngxLoader.start();
    this.patientServiceInvoicesService
      .patientAdvancesFinV2(
        this.patient,
        '/patient/adjustments-approval-v2',
        '',
        '',
        '',
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.patinetAdvancesList = data.advances;
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  searchInvoices(): any {
    this.patientServiceInvoices = [];
    this.copyPatientDiscount = [];
    this.patientDiscount = [];
    this.selectedServices = [];
    this.discount_reason_id = '';
    this.discount_comments = '';
    this.discountTypeInvoiceAll = 'amount';
    this.discountTypeValueInvoiceAll = 0;
    this.totalDiscountedInvoiceAmount = 0;
    this.totalDiscountedAmount = 0;
    this.ngxLoader.start();
    this.PatientServiceInvoiceForm.controls.patient_id.setValue(
      this.patient.mrn,
    );
    this.patientServiceInvoicesService
      .patientServicesInvoiceApprovalV2(
        this.PatientServiceInvoiceForm.value,
        this.patient,
        this.isOpen,
        '',
        '',
        '/patient/adjustments-approval-v2',
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.patientServiceInvoices = data.patient_service_invoices;
          for (let i = 0; i < this.patientServiceInvoices.length; i++) {
            const patient_service_invoices = {
              id: this.patientServiceInvoices[i].id,
              patient_service_invoice_items: [],
              manual_discount:
                this.patientServiceInvoices[i].manual_discount || 0,
              discount_on_receivable:
                this.patientServiceInvoices[i].discount_on_receivable || 0,
              discount_on_cash:
                this.patientServiceInvoices[i].discount_on_cash || 0,
              type_amount: this.patientServiceInvoices[i].type_amount || 0,
              quantity: 0,
              selected: false,
            };
            for (
              let j = 0;
              j <
              this.patientServiceInvoices[i].patient_service_invoice_items
                .length;
              j++
            ) {
              let manualDiscount =
                this.patientServiceInvoices[i].patient_service_invoice_items[j]
                  .manual_discount;
              if (
                this.patientServiceInvoices[i].patient_service_invoice_items[j]
                  .discount_approved_status === 'approved'
              ) {
                manualDiscount = 0;
              }
              const obj = {
                id: this.patientServiceInvoices[i]
                  .patient_service_invoice_items[j].id,
                unit_type:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].unit_type || 'amount',
                type_value:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].type_value || 0,
                type_amount:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].type_amount || 0,
                manual_discount: manualDiscount,
                discount_on_receivable:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].discount_on_receivable,
                discount_on_cash:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].discount_on_cash,
                quantity:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].quantity,
                // invoice_amount: ( this.patientServiceInvoices[i].patient_service_invoice_items[j].base_price - this.patientServiceInvoices[i].patient_service_invoice_items[j].discount ) * this.patientServiceInvoices[i].patient_service_invoice_items[j].quantity,
                invoice_amount:
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].base_price *
                    this.patientServiceInvoices[i]
                      .patient_service_invoice_items[j].quantity -
                  this.patientServiceInvoices[i].patient_service_invoice_items[
                    j
                  ].approved_discount,
                selected: false,
              };
              patient_service_invoices.patient_service_invoice_items.push(obj);
            }
            this.patientDiscount.push(patient_service_invoices);
            // this.copyPatientDiscount.push(patient_service_invoices);
          }
          this.copyPatientDiscount = JSON.parse(
            JSON.stringify(this.patientDiscount),
          );
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  _onlyNumeric(event: any) {
    if (event.charCode != 46) {
      const pattern = /^\d{1,6}(\.\d{1,2})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }
  updateDiscountValue(i, j): any {
    const invoice = this.patientDiscount[i];
    if (invoice.patient_service_invoice_items[j].unit_type === 'amount') {
      if (
        invoice.patient_service_invoice_items[j].type_value >=
        invoice.patient_service_invoice_items[j].invoice_amount
      ) {
        invoice.patient_service_invoice_items[j].type_value =
          invoice.patient_service_invoice_items[j].invoice_amount;
      }
      invoice.patient_service_invoice_items[j].type_amount =
        invoice.patient_service_invoice_items[j].invoice_amount -
          invoice.patient_service_invoice_items[j].type_value || 0;
      invoice.patient_service_invoice_items[j].manual_discount =
        invoice.patient_service_invoice_items[j].type_value || 0;
    }
    if (invoice.patient_service_invoice_items[j].unit_type === 'percentage') {
      if (invoice.patient_service_invoice_items[j].type_value >= 100) {
        invoice.patient_service_invoice_items[j].type_value = 100;
      }
      const percentageAmount =
        (invoice.patient_service_invoice_items[j].invoice_amount / 100) *
        invoice.patient_service_invoice_items[j].type_value;
      invoice.patient_service_invoice_items[j].type_amount =
        invoice.patient_service_invoice_items[j].invoice_amount -
          percentageAmount || 0;
      invoice.patient_service_invoice_items[j].manual_discount =
        invoice.patient_service_invoice_items[j].invoice_amount -
          invoice.patient_service_invoice_items[j].type_amount || 0;

      // var percentageAmount = ( invoice.patient_service_invoice_items[j].invoice_amount / 100) * invoice.patient_service_invoice_items[j].type_value;
      // invoice.patient_service_invoice_items[j].type_amount = ( invoice.patient_service_invoice_items[j].invoice_amount - percentageAmount ) || 0 ;
      // invoice.patient_service_invoice_items[j].manual_discount = invoice.patient_service_invoice_items[j].invoice_amount - invoice.patient_service_invoice_items[j].type_amount || 0;
    }
    this.patientDiscount[i] = invoice;
    this.patientDiscount[i].manual_discount = 0;
    this.patientDiscount[i].type_amount = 0;

    this.patientDiscount[i].discount_on_cash = 0;
    this.patientDiscount[i].discount_on_receivable = 0;
    this.patientDiscount[i].quantity = 0;

    for (let z = 0; z < invoice.patient_service_invoice_items.length; z++) {
      this.patientDiscount[i].manual_discount =
        this.patientDiscount[i].manual_discount +
        invoice.patient_service_invoice_items[z].manual_discount;
      this.patientDiscount[i].type_amount =
        this.patientDiscount[i].type_amount +
        invoice.patient_service_invoice_items[z].type_amount;

      this.patientDiscount[i].discount_on_cash =
        this.patientDiscount[i].discount_on_cash +
        invoice.patient_service_invoice_items[z].discount_on_cash;
      this.patientDiscount[i].discount_on_receivable =
        this.patientDiscount[i].discount_on_receivable +
        invoice.patient_service_invoice_items[z].discount_on_receivable;
      this.patientDiscount[i].quantity =
        this.patientDiscount[i].quantity +
        invoice.patient_service_invoice_items[z].quantity;
    }
    this.updateTotalAmount();
  }
  updateSelectBoxOfInvoice(i): any {
    for (
      let j = 0;
      j < this.patientDiscount[i].patient_service_invoice_items.length;
      j++
    ) {
      if (
        this.patientDiscount[i].patient_service_invoice_items[j]
          .discount_approved_status !== 'approved'
      ) {
        this.patientDiscount[i].patient_service_invoice_items[j].selected =
          this.patientDiscount[i].selected;
      }
    }
    setTimeout(() => {
      if (this.patientDiscount[i].selected === false) {
        $('#discountTypeInvoiceAll' + i).val('amount');
        $('#discountTypeValueInvoiceAll' + i).val('');
        this.patientDiscount[i] = JSON.parse(
          JSON.stringify(this.copyPatientDiscount[i]),
        );
      }
    }, 500);
    this.updateAllDiscountValueOfInvoice();
    this.updateAllDiscountTypeOfInvoice();
    this.updateTotalAmount();
    this.chRef.detectChanges();
  }
  updateSelectBoxOfInvoiceItems(i, j): any {
    setTimeout(() => {
      if (
        this.patientDiscount[i].patient_service_invoice_items[j].selected ===
        false
      ) {
        this.patientDiscount[i].patient_service_invoice_items[j] = JSON.parse(
          JSON.stringify(
            this.copyPatientDiscount[i].patient_service_invoice_items[j],
          ),
        );
      }
    }, 500);
    // if ( !(this.copyPatientDiscount[i].patient_service_invoice_items[j].type_value > 0) ) {
    // }
    this.updateAllDiscountValueOfInvoice();
    this.updateAllDiscountTypeOfInvoice();
    this.updateTotalAmount();
    this.chRef.detectChanges();
  }
  updateTotalAmount(): any {
    this.totalDiscountedInvoiceAmount = 0;
    this.totalDiscountedAmount = 0;
    for (let i = 0; i < this.patientDiscount.length; i++) {
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        if (
          this.patientDiscount[i].patient_service_invoice_items[j].selected ===
          true
        ) {
          this.totalDiscountedInvoiceAmount =
            this.totalDiscountedInvoiceAmount +
            this.patientDiscount[i].patient_service_invoice_items[j]
              .invoice_amount;
          this.totalDiscountedAmount =
            this.totalDiscountedAmount +
            this.patientDiscount[i].patient_service_invoice_items[j]
              .manual_discount;
        }
      }
    }
  }
  updateDiscountTypeOfInvoice(value, i): any {
    for (
      let j = 0;
      j < this.patientDiscount[i].patient_service_invoice_items.length;
      j++
    ) {
      if (
        this.patientDiscount[i].patient_service_invoice_items[j].selected ===
        true
      ) {
        this.patientDiscount[i].patient_service_invoice_items[j].unit_type =
          value;
        this.updateDiscountValue(i, j);
        this.chRef.detectChanges();
      }
    }
    this.chRef.detectChanges();
  }

  updateAllDiscountTypeOfInvoice(): any {
    for (let i = 0; i < this.patientDiscount.length; i++) {
      if (this.patientDiscount[i].selected === true) {
        $('#discountTypeInvoiceAll' + i).val(this.discountTypeInvoiceAll);
      }
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        if (
          this.patientDiscount[i].patient_service_invoice_items[j].selected ===
          true
        ) {
          this.patientDiscount[i].patient_service_invoice_items[j].unit_type =
            this.discountTypeInvoiceAll;
          this.updateDiscountValue(i, j);
          this.chRef.detectChanges();
        }
      }
    }
    this.chRef.detectChanges();
  }

  updateDiscountValueOfInvoice(value, i): any {
    if ($('#discountTypeInvoiceAll' + i).val() === 'percentage') {
      if (value >= 100) {
        $('#discountTypeValueInvoiceAll' + i).val(100);
        value = '100';
      }
    }
    for (
      let j = 0;
      j < this.patientDiscount[i].patient_service_invoice_items.length;
      j++
    ) {
      if (
        this.patientDiscount[i].patient_service_invoice_items[j].selected ===
        true
      ) {
        this.patientDiscount[i].patient_service_invoice_items[j].type_value =
          Number(value);
        this.updateDiscountValue(i, j);
      }
    }
    this.chRef.detectChanges();
  }

  updateAllDiscountValueOfInvoice(): any {
    if ($('#discountTypeInvoiceAll').val() === 'percentage') {
      if (this.discountTypeValueInvoiceAll >= 100) {
        $('#discountTypeValueInvoiceAll').val(100);
        this.discountTypeValueInvoiceAll = 100;
      }
    }
    for (let i = 0; i < this.patientDiscount.length; i++) {
      if (this.patientDiscount[i].selected === true) {
        $('#discountTypeValueInvoiceAll' + i).val(
          this.discountTypeValueInvoiceAll,
        );
      }
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        if (
          this.patientDiscount[i].patient_service_invoice_items[j].selected ===
          true
        ) {
          this.patientDiscount[i].patient_service_invoice_items[j].type_value =
            Number(this.discountTypeValueInvoiceAll);
        }
        this.updateDiscountValue(i, j);
      }
    }
    this.chRef.detectChanges();
  }

  updateDiscountOnRece(i, j): any {
    const invoice = this.patientDiscount[i];
    if (
      invoice.patient_service_invoice_items[j].discount_on_receivable >
      invoice.patient_service_invoice_items[j].manual_discount
    ) {
      invoice.patient_service_invoice_items[j].discount_on_receivable =
        invoice.patient_service_invoice_items[j].manual_discount;
    }
    invoice.patient_service_invoice_items[j].discount_on_cash =
      invoice.patient_service_invoice_items[j].manual_discount -
      invoice.patient_service_invoice_items[j].discount_on_receivable;
    this.patientDiscount[i] = invoice;
    this.updateDiscountValue(i, j);
  }

  updateDiscountOnCash(i, j): any {
    const invoice = this.patientDiscount[i];
    if (
      invoice.patient_service_invoice_items[j].discount_on_cash >
      invoice.patient_service_invoice_items[j].manual_discount
    ) {
      invoice.patient_service_invoice_items[j].discount_on_cash =
        invoice.patient_service_invoice_items[j].manual_discount;
    }
    invoice.patient_service_invoice_items[j].discount_on_receivable =
      invoice.patient_service_invoice_items[j].manual_discount -
      invoice.patient_service_invoice_items[j].discount_on_cash;
    this.patientDiscount[i] = invoice;
    this.updateDiscountValue(i, j);
  }

  updateDiscount(): any {
    let errorCount = 0;
    $('.form-control').css('border', '1px solid #ccc');
    for (let i = 0; i < this.patientDiscount.length; i++) {
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        const services =
          this.patientDiscount[i].patient_service_invoice_items[j];
        if (services.type_value === null || services.type_value === undefined) {
          $('#serviceDiscount' + i + j).css('border', '1px solid red');
          errorCount++;
        }
        if (
          services.type_value > 0 &&
          services.discount_on_receivable === 0 &&
          services.discount_on_cash === 0
        ) {
          $('#serviceDiscountOnRece' + i + j).css('border', '1px solid red');
          $('#serviceDiscountOnCash' + i + j).css('border', '1px solid red');
          errorCount++;
        }
        if (
          services.type_value > 0 &&
          services.discount_on_receivable + services.discount_on_cash !==
            services.manual_discount
        ) {
          $('#serviceDiscountOnRece' + i + j).css('border', '1px solid red');
          $('#serviceDiscountOnCash' + i + j).css('border', '1px solid red');
          errorCount++;
        }
      }
    }

    if (this.discount_reason_id === '') {
      $('#serviceDisReasonsId').css('border', '1px solid red');
      errorCount++;
    }
    if (this.discount_comments === '') {
      $('#serviceDisComments').css('border', '1px solid red');
      errorCount++;
    }

    if (errorCount > 0) {
      return;
    }

    this.isChecked = false;
    this.modalService.open(this.updateConfirm);
  }

  addDiscount(): any {
    if (this.isChecked === false) {
      toastr.error('Select confirmation');
      return;
    }
    this.modalService.dismissAll(this.updateConfirm);
    this.ngxLoader.start();
    this.patientServiceInvoicesService
      .updateDiscountV2(
        this.patientDiscount,
        this.patient,
        this.discount_reason_id,
        this.discount_comments,
      )
      .subscribe(
        (data) => {
          this.searchInvoices();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  get k(): any {
    return this.PatientSearchForm.controls;
  }

  openPatientList(): any {
    $('#patient-list').toggleClass('offcanvas-on');
  }

  closePatientList(): any {
    $('#patient-list').removeClass('offcanvas-on');
  }
  printInvoice(id): any {
    window.open(
      this.current_environment +
        '/v4/patient_services/' +
        id +
        '/patient_services_invoice_slip.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&print_type=true&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      '_blank',
    );
  }
  openInvoiceItemDetails(i, j, data): any {
    this.invoiceItemDetails = data;
    this.modalService.open(this.invoiceItemDetailsModal, { size: 'lg' });
  }
  resetSelectBoxes(): any {
    this.patientDiscount = JSON.parse(JSON.stringify(this.copyPatientDiscount));
    this.unSelectAllInvoicesItem();
    this.discountTypeInvoiceAll = 'amount';
    this.discountTypeValueInvoiceAll = 0;
    this.totalDiscountedInvoiceAmount = 0;
    this.totalDiscountedAmount = 0;
    this.selectedServices = [];
    this.cancelServicesList = [];
    this.updateTotalAmount();
    this.resetSelectBoxesCancelAdvance();
  }
  selectToggleAllInvoices(value): any {
    if (value === true) {
      this.selectAllInvoicesItem();
    } else {
      this.unSelectAllInvoicesItem();
    }
  }
  selectAllInvoicesItem(): any {
    for (let i = 0; i < this.patientDiscount.length; i++) {
      // this.patientDiscount[i].selected = true;
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        if (
          this.patientServiceInvoices[i].patient_service_invoice_items[j]
            .discount_approved_status === 'requested'
        ) {
          this.patientDiscount[i].patient_service_invoice_items[j].selected =
            true;
        }
      }
    }
    this.updateTotalAmount();
    $('.mainRow').removeClass('collapsed');
    $('.collapseDetail').addClass('show');
  }
  unSelectAllInvoicesItem(): any {
    for (let i = 0; i < this.patientDiscount.length; i++) {
      this.patientDiscount[i].selected = false;
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        this.patientDiscount[i].patient_service_invoice_items[j].selected =
          false;
      }
    }
    this.updateTotalAmount();
  }
  selectToggleAllInvoicesItem(value, i): any {
    if (value === true) {
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        if (
          this.patientServiceInvoices[i].patient_service_invoice_items[j]
            .discount_approved_status === 'requested'
        ) {
          this.patientDiscount[i].patient_service_invoice_items[j].selected =
            true;
        }
      }
    } else {
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        this.patientDiscount[i].patient_service_invoice_items[j].selected =
          false;
      }
    }
    this.updateTotalAmount();
  }
  selectToggleInvoicesItem(value, i, j): any {
    if (value === true) {
      this.patientDiscount[i].patient_service_invoice_items[j].selected = true;
    } else {
      this.patientDiscount[i].patient_service_invoice_items[j].selected = false;
    }
    this.updateTotalAmount();
  }

  approvedOrRejectDiscount(): any {
    this.selectedServices = [];
    for (let i = 0; i < this.patientDiscount.length; i++) {
      for (
        let j = 0;
        j < this.patientDiscount[i].patient_service_invoice_items.length;
        j++
      ) {
        if (
          this.patientDiscount[i].patient_service_invoice_items[j].selected ===
          true
        ) {
          this.selectedServices.push(
            this.patientDiscount[i].patient_service_invoice_items[j].id,
          );
        }
      }
    }
    this.isChecked = false;
    if (this.selectedServices.length === 0) {
      toastr.error('Select services first');
      return;
    }
    this.modalService.open(this.updateDiscountStatusConfirm);
  }
  approvedServices(): any {
    if (this.isChecked === false) {
      toastr.error('Select Confirmation');
      return;
    }
    this.modalService.dismissAll(this.updateDiscountStatusConfirm);
    this.ngxLoader.start();
    let type = 'approved';
    if (this.filterType === 'approvedDiscount') {
      type = 'approved';
    } else {
      type = 'rejected';
    }
    this.patientServiceInvoicesService
      .approvedRejectServicesV2(this.selectedServices, type)
      .subscribe(
        (data) => {
          this.searchInvoices();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
          // this._flashMessagesService.show(err['error']['message'], { cssClass: 'alert-danger', timeout: 5000 });
        },
      );
  }

  selectToggleAllInvoicesItemForCancel(value, id): any {
    if (value === true) {
      this.cancelServicesList.push(id);
    } else {
      var ind = this.cancelServicesList.indexOf(id);
      this.cancelServicesList.splice(ind, 1);
    }
  }

  approvedOrRejectService(): any {
    if (this.cancelServicesList.length === 0) {
      toastr.error('Select invoices');
      return;
    }
    this.isChecked = false;
    this.modalService.open(this.updateCancelStatusConfirm);
  }

  approvedServicesCancel(): any {
    if (this.isChecked === false) {
      toastr.error('Select Confirmation');
      return;
    }
    this.modalService.dismissAll(this.updateCancelStatusConfirm);
    this.ngxLoader.start();
    let type = 'approved';
    if (this.filterType === 'approvedServices') {
      type = 'approved';
    } else {
      type = 'rejected';
    }
    this.patientServiceInvoicesService
      .approvedRejectServicesCancelationV2(
        this.cancelServicesList,
        type,
        this.patient,
      )
      .subscribe(
        (data) => {
          this.resetSelectBoxes();
          this.searchInvoices();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
          // this._flashMessagesService.show(err['error']['message'], { cssClass: 'alert-danger', timeout: 5000 });
        },
      );
  }

  toggleCheckBoxAdvance(data): any {
    if (this.cancelAdvances.advance_ids.includes(data.id)) {
      const ind = this.cancelAdvances.advance_ids.indexOf(data.id);
      this.cancelAdvances.advance_ids.splice(ind, 1);
      this.cancelAdvances.cash -= data.amount;
    } else {
      this.cancelAdvances.advance_ids.push(data.id);
      this.cancelAdvances.cash += data.amount;
    }
  }
  approvedOrRejectAdvance(): any {
    if (this.cancelAdvances.advance_ids.length === 0) {
      toastr.error('Select Advances');
      return;
    }
    if (this.filterType === 'approvedAdvance' && this.bookAccounts.length){
      this.book_of_account = this.bookAccounts.find(t => t.is_default).id;
      if (this.book_of_account){
        this.getBatches(this.book_of_account);
      }
    } else {
      this.book_of_account = '';
      this.batch = '';
    }
    this.isChecked = false;
    this.modalService.open(this.updateAdvanceCancelStatusConfirm);
  }
  approvedAdvanceCancel(): any {
    let type = 'approved';
    if (this.filterType === 'approvedAdvance') {
      if(!this.book_of_account && this.financeControlsDataBill){
        toastr.error('Select book of account');
        return;
      }
      if(!this.batch && this.financeControlsDataBill){
        toastr.error('Select Batch');
        return;
      }
      type = 'approved';
    } else {
      type = 'rejected';
    }
    if (this.isChecked === false) {
      toastr.error('Select Confirmation');
      return;
    }
    this.cancelAdvances.patient_id = this.patient.id;
    this.ngxLoader.start();
    this.patientServiceInvoicesService
      .approvedRejectAdvanceCancelationV2(this.cancelAdvances, type)
      .subscribe(
        (data) => {
          toastr.success(data.message);
          this.patientAdvances();
          if (this.financeControlsDataBill) {
            this.createAdvanceCancelVoucher(this.cancelAdvances.advance_ids);
          }
          this.modalService.dismissAll(this.updateAdvanceCancelStatusConfirm);
          this.resetSelectBoxesCancelAdvance();
          this.getBookAccounts();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  createAdvanceCancelVoucher(advanceIds): any {
    this.patientService.createAdvanceCancelVoucher(advanceIds, this.book_of_account, this.batch).subscribe(
      (data) => {
          // toastr.success(data.message);
          this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  resetSelectBoxesCancelAdvance(): any {
    this.cancelAdvances = {
      advance_ids: [],
      cash: 0,
      patient_id: '',
      advance_cancel_reason_id: '',
      advance_cancel_comments: '',
    };
    $('.cancelCheckbox').prop('checked', false);
  }
  getFinanceControl(): any {
    this.ngxLoader.start();
    this.financeControlService.getFinanceControls().subscribe(
      (data) => {
        // this.financeControlsData = data;
        if (data.import_bill) {
          this.financeControlsDataBill = data.import_bill;
        }
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
  }
  getBookAccounts(): any {
    this.bookAccounts = [];
    this.financeConfigService.activeBookOfAccounts().subscribe(
      (data) => {
        this.bookAccounts = data;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  getBatches(id): any {
    this.batchList = [];
    if (!id || id === '') {
      return;
    }
    this.ngxLoader.start();
    this.financeConfigService.getBatchListing('', '', '', '', id).subscribe(
      (data) => {
        this.batchList = data;
        if (this.batchList){
          this.batch = this.batchList[0]?.id ?? '';
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
}
