import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class PatientInOutTakeService {
  constructor(private http: HttpClient) {}

  index(patient, date): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_in_out_takes?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.mrn +
          '&date=' +
          date,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  create(patientInOutTake, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_in_out_takes?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.mrn,
        {
          patient_in_out_take: patientInOutTake,
          admission_id: patient.admissions[patient.admissions.length - 1].id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createInOutTake(patientInOutTake, patient): Observable<any> {
    return this.http
      .post(
        `${environment.rorUrl}/v4/patient_in_out_takes?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.mrn,
        {
          taken_time: patientInOutTake.taken_time,
          in_take_data: patientInOutTake.in_take_data,
          out_take_data: patientInOutTake.out_take_data,
          admission_id: patient.admissions[patient.admissions.length - 1].id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  createInOutTakeV2(patientInOutTake, patient): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/ipd/patient_in_out_takes/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.mrn,
        {
          taken_time: patientInOutTake.taken_time,
          in_take_data: patientInOutTake.in_take_data,
          out_take_data: patientInOutTake.out_take_data,
          admission_id: patient.admissions[patient.admissions.length - 1].id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  fetchInOutTake(patient, date, currentVisitID): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_in_out_takes/get_in_out_takes?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.mrn +
          '&date=' +
          date +
          '&visit_id=' +
          currentVisitID,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchInOutTakeV2(patient, date, currentVisitID, visitType): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/patient_in_out_takes/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.mrn +
          '&date=' +
          date +
          '&visit_id=' +
          currentVisitID +
          '&visit_type=' +
          visitType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
