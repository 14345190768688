<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-baseline justify-content-between flex-wrap mr-5 w-100"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <!--          <button class="btn btn-sm btn-primary" [routerLink]="['/roster/shifts-detail']"><i class="fas fa-long-arrow-alt-left"></i> Back</button>-->
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center"></div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::Card-->
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <div class="row">
            <!--Select Month-->
            <div class="col-sm-2 col-6">
              <div class="form-group">
                <label>Select Month <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm month"
                  placeholder="Select Month"
                  readonly="readonly"
                  [(ngModel)]="month"
                />
              </div>
            </div>
            <!--Department-->
            <div class="col-sm-3 col-6">
              <div class="d-block form-group">
                <label>Department:</label>
                <select
                  class="form-control form-control-sm selectpicker"
                  multiple
                  data-live-search="true"
                  data-container="body"
                  id="department"
                  name="department"
                  (change)="getEmployeesOfDepartment()"
                  [(ngModel)]="department"
                >
                  <option value="" [disabled]="true">Select Department</option>
                  <ng-container *ngFor="let dep of depart">
                    <option [value]="dep.id">{{ dep.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!--Employee Of Department-->
            <div class="col-sm-3 col-6">
              <div class="d-block form-group">
                <label>Employee:</label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  data-container="body"
                  multiple
                  id="employeeDepartmentList"
                  name="department"
                  [(ngModel)]="employeesOfSelectedDepartment"
                >
                  <option value="" [disabled]="true">Select Employee</option>
                  <ng-container *ngFor="let emp of employeesOfDepart">
                    <option [value]="emp.id">
                      {{ emp?.employee_file_no }} - {{ emp?.first_name }}
                      {{ emp?.middle_name || "" }} {{ emp?.last_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-sm-2 col-6">
              <div class="d-block form-group">
                <label>Location:<span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm selectpicker"
                  data-live-search="true"
                  data-container="body"
                  id="selectedLocation"
                  name="selectedLocation"
                  [(ngModel)]="selectedLocation"
                >
                  <option value="" [disabled]="true">Select Location</option>
                  <ng-container *ngFor="let location of locationData">
                    <option [value]="location.id">{{ location?.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-sm-2 col-6">
              <div class="d-block form-group">
                <span
                  class="btn btn-light-primary btn-sm"
                  type="button"
                  (click)="appendArray()"
                  >Search</span
                >
              </div>
            </div>
          </div>
          <!--shifts-->
        </div>
      </div>
      <!--end::Card-->

      <div class="card card-custom" *ngIf="employeeRoasterData.length > 0">
        <div class="card-body">
          <div
            class="employeeShiftTable"
            style="
              overflow-x: auto;
              position: relative;
              max-height: calc(100vh - 400px);
            "
          >
            <table class="table table-head-custom table-vertical-center">
              <thead>
                <tr class="rosterDates">
                  <th
                    style="
                      min-width: 270px;
                      width: 270px;
                      position: sticky;
                      position: -webkit-sticky;
                      top: 0;
                      background: #fff;
                    "
                  >
                    <span style="height: 100%; width: 100%"></span>
                  </th>
                  <th
                    style="
                      min-width: 150px;
                      width: 150px;
                      position: sticky;
                      position: -webkit-sticky;
                      top: 0;
                      background: #fff;
                    "
                    *ngFor="let data of employeeRoasterData[0]?.roster_detail"
                  >
                    {{ data.show_date }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let data of employeeRoasterData; let i = index"
                  id="rosterValue{{ i }}"
                  class="rosterShiftTop"
                >
                  <ng-container>
                    <td
                      style="
                        min-width: 270px;
                        width: 270px;
                        position: sticky;
                        left: 0;
                        background: #fff;
                      "
                    >
                      <div
                        style="
                          display: flex;
                          height: 100px;
                          align-items: center;
                        "
                      >
                        <span class="mr-3 d-flex">
                          <!--                      <a class="btn btn-sm btn-clean btn-icon btn-light btn-hover-primary " (click)="openApplyToAllModal(i)">-->
                          <!--                        <span class="svg-icon svg-icon-md svg-icon-primary">-->
                          <!--                          <i class="la la-download"></i>-->
                          <!--                        </span>-->
                          <!--                      </a>-->
                          <a
                            class="btn btn-sm btn-clean btn-icon btn-light btn-hover-primary ml-2"
                            title="Delete"
                            (click)="removefromArray(i)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                  ></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </a>
                        </span>
                        <div
                          class="d-flex font-weight-bold"
                          style="flex-direction: column"
                        >
                          <span
                            class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            style="word-break: break-word"
                            >{{ data?.employee_name }}</span
                          >
                          <span
                            class="text-muted font-weight-bold text-muted d-block"
                            style="word-break: break-word"
                            >{{ data?.designation }}</span
                          >
                          <span
                            class="text-muted font-weight-bold text-muted d-block"
                            >{{ data?.employee_file_no }}</span
                          >
                        </div>
                      </div>
                    </td>
                    <!--                  (click)="singleShiftModalOpen(i, j)"-->
                    <th
                      style="min-width: 170px; width: 170px"
                      *ngFor="let roster of data.roster_detail; let j = index"
                      class="rosterShiftMain"
                    >
                      <div
                        class="rosterShift employeeShiftTableList"
                        style="overflow: auto"
                        (click)="singleShiftModalOpen(i, j)"
                        id="{{ i }}roster{{ j }}"
                        [ngStyle]="{
                          'border-color':
                            roster?.is_overlap === true ? '#fff094' : '#ddd',
                          background:
                            roster?.is_overlap === true ? '#fff094' : '#fff'
                        }"
                      >
                        <ng-container
                          *ngFor="let shiftData of roster?.shift; let k = index"
                        >
                          <span
                            *ngIf="
                              shiftData?.is_removed === false &&
                              shiftData?.is_replaced === false
                            "
                            class="label label-lg label-inline label-primary rosterShiftObj d-flex align-items-center p-2"
                            [style.background-color]="shiftData?.shift_bg_color"
                            ngbPopover="{{ shiftData?.user_name }}"
                            triggers="mouseenter:mouseleave"
                            placement="bottom"
                            [style.color]="shiftData?.shift_text_color"
                            style="height: auto"
                            [ngClass]="{
                              'opacity-30': shiftData?.is_rejected === true
                            }"
                            (click)="
                              $event.stopPropagation();
                              singleShiftUpdateModalOpen(
                                i,
                                j,
                                k,
                                shiftData,
                                roster,
                                data
                              )
                            "
                          >
                            <span style="flex-grow: 1; text-align: center">
                              {{ shiftData?.shift_name }} -
                              {{ shiftData?.location_name }}
                            </span>
                            <span *ngIf="shiftData?.status === 0">
                              <i
                                *ngIf="
                                  shiftData?.can_delete === true &&
                                  shiftData?.is_replaced === false &&
                                  shiftData?.status === 0
                                "
                                style="font-size: 7px; line-height: 1"
                                aria-hidden="true"
                                class="ki ki-close remove-shift"
                                [style.color]="shiftData?.shift_text_color"
                                (click)="
                                  $event.stopPropagation();
                                  openRemoveModal(i, j, k, shiftData?.shift_id)
                                "
                              ></i>
                              <!--                            <i *ngIf="shiftData?.can_delete === true && shiftData?.is_rejected === false && shiftData?.is_replaced === false" style="font-size: 7px; line-height: 1" aria-hidden="true" class="ki ki-close remove-shift" [style.color]="shiftData?.shift_text_color" (click)="$event.stopPropagation();openRemoveModal(i,j,k)"></i>-->
                            </span>
                            <i
                              *ngIf="shiftData?.status === 1"
                              style="font-size: 7px; line-height: 1"
                              aria-hidden="true"
                              class="fas fa-check"
                              [style.color]="shiftData?.shift_text_color"
                            ></i>
                          </span>
                        </ng-container>
                      </div>
                    </th>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="text-right mt-5">
            <span>
              <span
                class="btn btn-primary btn-sm"
                type="button"
                (click)="onSubmit()"
                >Save Changes</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end::Content-->

<!-- Modal-->
<div
  class="modal fade"
  id="rosterApplyToAll"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Apply to All</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div class="col-sm-8">
          <div class="form-group">
            <label>Shift:</label>
            <select
              class="form-control form-control-sm selectpicker"
              data-live-search="true"
              name="selectedLocation"
              [(ngModel)]="selectedShiftAdd"
            >
              <option value="" [disabled]="true">Select Shift</option>
              <ng-container *ngFor="let shift of shiftData">
                <option [value]="shift.id">
                  {{ shift.name }} - [{{ shift?.time_from }} -
                  {{ shift?.time_to }}]
                </option>
              </ng-container>
            </select>
          </div>
        </div>

        <div class="col-sm-8">
          <div class="form-group">
            <label>Location:</label>
            <select
              class="form-control form-control-sm selectpicker"
              data-live-search="true"
              name="selectedLocation"
              [(ngModel)]="selectedLocationAdd"
            >
              <option value="" [disabled]="true">Select Location</option>
              <ng-container *ngFor="let location of locationData">
                <option [value]="location.id">{{ location?.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <!--Shift Confirmation-->
        <div class="col-12">
          <span class="d-flex align-items-center">
            <label class="checkbox mr-4">
              <input type="checkbox" [(ngModel)]="applyToAllConfirmation" />
              <span></span>
            </label>
            <span>Are You sure you want to apply to all shifts.</span>
          </span>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary font-weight-bold"
          (click)="saveModalChanges()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- singleShiftModalClick-->
<div
  class="modal fade"
  id="addNewShiftModal"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <h5 class="modal-title">Create Single shift</h5>
        <a type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </a>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-8">
            <div class="form-group">
              <label>Shift: <span class="text-danger">*</span></label>
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                data-container="body"
                name="selectedLocation"
                [(ngModel)]="selectedShiftAdd"
              >
                <option value="" [disabled]="true">Select Shift</option>
                <ng-container *ngFor="let shift of shiftData">
                  <option [value]="shift.id">
                    {{ shift.name }} - [{{ shift?.time_from }} -
                    {{ shift?.time_to }}]
                  </option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="col-sm-8">
            <div class="form-group">
              <label>Location: <span class="text-danger">*</span></label>
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                data-container="body"
                name="selectedLocation"
                [(ngModel)]="selectedLocationAdd"
              >
                <option value="" [disabled]="true">Select Location</option>
                <ng-container *ngFor="let location of locationData">
                  <option [value]="location.id">{{ location?.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <a
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
          >Close</a
        >
        <button
          type="submit"
          class="btn btn-primary font-weight-bold"
          (click)="addNewShiftToEmployee()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="updateShiftModal"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <h5 class="modal-title">Update shift</h5>
        <a type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </a>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-8">
            <div class="form-group">
              <label
                >Replacement Employee (if you want to transfer this shift to
                another employee):</label
              >
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                name="selectedEmployeeAdd"
                [(ngModel)]="selectedEmployeeAdd"
                (change)="getAvailableShiftData($event.target.value)"
              >
                <option value="">Select Employee</option>
                <ng-container *ngFor="let emp of employeesOfDepart">
                  <option [value]="emp.id">
                    {{ emp?.employee_file_no }} - {{ emp?.first_name }}
                    {{ emp?.middle_name || "" }} {{ emp?.last_name }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="col-sm-8">
            <div class="form-group">
              <label>Shift:</label>
              <select
                class="form-control form-control-sm selectpicker"
                id="selectedLocationForUpdate"
                data-live-search="true"
                name="selectedLocation"
                [(ngModel)]="selectedShiftAdd"
              >
                <option value="">Select Shift</option>
                <ng-container *ngFor="let shift of availableShiftData">
                  <option [value]="shift.id">
                    {{ shift.name }} - [{{ shift?.time_from }} -
                    {{ shift?.time_to }}]
                  </option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="col-sm-8">
            <div class="form-group">
              <label>Location:</label>
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                name="selectedLocation"
                [(ngModel)]="selectedLocationAdd"
              >
                <option value="">Select Location</option>
                <ng-container *ngFor="let location of locationData">
                  <option [value]="location.id">{{ location?.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <a
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
          >Close</a
        >
        <button
          type="submit"
          class="btn btn-primary font-weight-bold"
          (click)="updateShiftOfEmployee()"
        >
          Update changes
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="singleShiftModalRemoveClick"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <h5 class="modal-title">Remove Single shift Confirmation</h5>
        <a type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </a>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <span class="d-flex align-items-center">
              <label class="checkbox mr-4">
                <input
                  type="checkbox"
                  [(ngModel)]="removeSingleEmployeeShift"
                />
                <span></span>
              </label>
              <span>Are You sure you want to remove the shifts.</span>
            </span>
          </div>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <a
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
          >Close</a
        >
        <button
          type="submit"
          class="btn btn-primary font-weight-bold"
          (click)="removeAllSingleShiftModal()"
        >
          All
        </button>
        <button
          type="submit"
          class="btn btn-light-primary font-weight-bold"
          (click)="removeSingleShiftModal()"
        >
          Current
        </button>
      </div>
    </div>
  </div>
</div>
