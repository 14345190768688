<form [formGroup]="cultureForm">
  <div class="card card-custom mb-5">
    <div class="card-body">
      <div class="row">
        <div class="col-8 p-lr-0">
          <h4 class="margin-0">Organism</h4>
        </div>
        <div class="col-4 p-lr-0 text-right">
          <button
            (click)="addFieldsInCulture('organism'); getTextAccordingToTest()"
            class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
            type="button"
          >
            <span class="svg-icon svg-icon-primary svg-icon-2x"
              ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                  <path
                    d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                    fill="#000000"
                  />
                </g></svg
              ><!--end::Svg Icon-->
            </span>
          </button>
        </div>
      </div>
      <div class="clearfix" *ngIf="cultureForm.value.organism.length > 0">
        <table class="table table-condensed table-hover">
          <thead>
            <tr>
              <th>Organism</th>
              <th>Result</th>
              <th>Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              formArrayName="organism"
              *ngFor="let g of organism.controls; let i = index"
            >
              <ng-container [formGroupName]="i">
                <td style="width: 30%">
                  <select
                    class="form-control form-control-sm"
                    formControlName="organism_id"
                    (change)="
                      getOrganismValues($event.target.value, i);
                      getTextAccordingToTest()
                    "
                  >
                    <option value="">Select</option>
                    <ng-container *ngFor="let type of organismList">
                      <option [value]="type?.id">
                        {{ type?.name }}
                      </option>
                    </ng-container>
                  </select>
                </td>
                <td style="width: 30%">
                  <select
                    class="form-control form-control-sm"
                    (change)="getTextAccordingToTest()"
                    formControlName="organism_result"
                  >
                    <option value="">Select</option>
                    <option value="Negative">Negative</option>
                    <option value="Positive">Positive</option>
                  </select>
                </td>
                <td style="width: 30%">
                  <select
                    class="form-control form-control-sm"
                    (change)="getTextAccordingToTest()"
                    formControlName="organism_type"
                  >
                    <option value="">--</option>
                    <option value="m">M</option>
                    <option value="l">L</option>
                    <option value="h">H</option>
                    <!--                                    <option value="Undetermined">-->
                    <!--                                      Undetermined-->
                    <!--                                    </option>-->
                  </select>
                </td>
                <td style="width: 10%" class="text-right">
                  <button
                    (click)="
                      removeCultureIndex('organism', i);
                      getTextAccordingToTest()
                    "
                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                    type="button"
                  >
                    <span class="svg-icon svg-icon-primary svg-icon-2x"
                      ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                          />
                          <path
                            d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g></svg
                      ><!--end::Svg Icon--></span
                    >
                  </button>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card card-custom mb-5">
    <div class="card-body">
      <div class="row">
        <div class="col-8 p-lr-0">
          <h4 class="margin-0">Detector</h4>
        </div>
        <div class="col-4 p-lr-0 text-right">
          <button
            (click)="addFieldsInCulture('detector'); getTextAccordingToTest()"
            class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
            type="button"
          >
            <span class="svg-icon svg-icon-primary svg-icon-2x"
              ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                  <path
                    d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                    fill="#000000"
                  />
                </g></svg
              ><!--end::Svg Icon-->
            </span>
          </button>
        </div>
      </div>
      <div class="clearfix" *ngIf="cultureForm.value.detector.length > 0">
        <div class="table-responsive">
          <table class="table table-condensed table-hover w-100">
            <thead>
              <tr>
                <th style="width: 30%">Anti Biotic</th>
                <th style="width: 30%">Detector</th>
                <th style="width: 30%">Result</th>
                <th style="width: 10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                formArrayName="detector"
                *ngFor="let g of detector.controls; let i = index"
              >
                <ng-container [formGroupName]="i">
                  <td style="width: 30%">
                    <select
                      class="form-control form-control-sm bootstrap-select-container"
                      (change)="getTextAccordingToTest()"
                      formControlName="anti_biotic"
                      id="anti_biotic{{ i }}"
                      data-live-search="true"
                      data-container="body"
                      style="width: 300px"
                      data-none-selected-text="Select"
                      data-width="300px"
                      multiple
                    >
                      <option value="" disabled selected>Select</option>
                      <ng-container *ngFor="let biotic of cultureList">
                        <option
                          *ngIf="biotic?.culture_type === 'sensitivity_group'"
                          [value]="biotic?.id"
                        >
                          {{ biotic?.name }}
                        </option>
                      </ng-container>
                    </select>
                  </td>
                  <td style="width: 30%">
                    <select
                      class="form-control form-control-sm bootstrap-select-container"
                      id="detector{{ i }}"
                      data-none-selected-text="Select"
                      style="width: 300px"
                      multiple
                      data-container="body"
                      data-width="300px"
                      data-live-search="true"
                      formControlName="detector_ids"
                      (change)="getAntiBiotic(i); getTextAccordingToTest()"
                    >
                      <option value="" selected disabled>Select</option>
                      <ng-container *ngFor="let type of detectorList">
                        <option [value]="type?.id">
                          {{ type?.name }}
                        </option>
                      </ng-container>
                    </select>
                  </td>
                  <td style="width: 30%">
                    <select
                      class="form-control form-control-sm"
                      formControlName="detector_result"
                      (change)="getTextAccordingToTest()"
                    >
                      <option value="" selected>Select</option>
                      <option value="S">S</option>
                      <option value="R">R</option>
                    </select>
                  </td>
                  <td style="width: 10%" class="text-right">
                    <button
                      (click)="
                        removeCultureIndex('detector', i);
                        getTextAccordingToTest()
                      "
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                      type="button"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                            />
                            <path
                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      >
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-custom mb-5">
    <div class="card-body">
      <div class="clearfix">
        <div class="col-xs-12 p-lr-0">
          <h4 class="margin-0"></h4>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="form-group">
            <label class="font-weight-bolder">Introduction</label>
            <textarea
              type="text"
              class="form-control form-control-sm"
              formControlName="introduction"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="form-group">
            <label class="font-weight-bolder">Result Interpretation</label>
            <textarea
              class="form-control form-control-sm"
              formControlName="result_interpretation"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="form-group">
            <label class="font-weight-bolder">Limitations</label>
            <textarea
              type="text"
              class="form-control form-control-sm"
              formControlName="limitations"
              rows="4"
            ></textarea>
          </div>
        </div>
      </div>
      <!--                      <div class="clearfix">-->
      <!--                        <table class="table table-striped margin-bottom-0">-->
      <!--                          <thead>-->
      <!--                            <tr>-->
      <!--                              <th style="width: 100%">Introduction</th>-->
      <!--                            </tr>-->
      <!--                            <tr>-->
      <!--                              <th style="width: 100%">Result Interpretation</th>-->
      <!--                            </tr>-->
      <!--                            <tr>-->
      <!--                              <th style="width: 100%">Limitations</th>-->
      <!--                            </tr>-->
      <!--                          </thead>-->
      <!--                          <tbody>-->
      <!--                            <tr>-->
      <!--                              <td>-->
      <!--                                <input-->
      <!--                                  type="text"-->
      <!--                                  class="form-control form-control-sm"-->
      <!--                                  formControlName="introduction"-->
      <!--                                />-->
      <!--                              </td>-->
      <!--                            </tr>-->
      <!--                            <tr>-->
      <!--                              <td>-->
      <!--                                <input-->
      <!--                                  type="text"-->
      <!--                                  class="form-control form-control-sm"-->
      <!--                                  formControlName="result_interpretation"-->
      <!--                                />-->
      <!--                              </td>-->
      <!--                            </tr>-->
      <!--                            <tr>-->
      <!--                              <td>-->
      <!--                                <input-->
      <!--                                  type="text"-->
      <!--                                  class="form-control form-control-sm"-->
      <!--                                  formControlName="Limitations"-->
      <!--                                />-->
      <!--                              </td>-->
      <!--                            </tr>-->
      <!--                          </tbody>-->
      <!--                        </table>-->
      <!--                      </div>-->
    </div>
  </div>
  <div class="card card-custom mb-5">
    <div class="card-body">
      <div class="clearfix">
        <div class="col-xs-12 p-lr-0">
          <h4 class="margin-0">Remarks</h4>
        </div>
      </div>
      <div class="clearfix">
        <table class="table table-striped margin-bottom-0">
          <thead>
            <tr>
              <th style="width: 20%">Remarks</th>
              <th style="width: 80%">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <select
                  class="form-control form-control-sm"
                  (change)="updatePcrRemarks($event.target.value)"
                >
                  <option value="">Select</option>
                  <ng-container
                    *ngFor="let comments of pcrListData?.pathologist_remarks"
                  >
                    <option value="{{ comments.remarks }}">
                      {{ comments.remarks }}
                    </option>
                  </ng-container>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  [value]="pcrRemarks"
                  id="pcrRemark"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card card-custom mb-5">
    <div class="card-body text-right">
      <button
        type="button"
        class="btn btn-sm btn-default mr-2"
        (click)="closePcrModals()"
      >
        Close
      </button>
      <button
        type="button"
        class="btn btn-sm btn-light-primary mr-2"
        [disabled]="resultSubmitBtn"
        (click)="submitCulturePcrReport(true)"
      >
        Save
      </button>
      <button
        type="button"
        class="btn btn-sm btn-primary"
        [disabled]="resultSubmitBtn"
        (click)="submitCulturePcrReport(false)"
        *ngIf="
          pcrListData.status != 'Unverified' &&
          pcrListData.status != 'Delivered' &&
          pcrListData.status != 'Verified'
        "
      >
        Submit
      </button>
    </div>
  </div>
</form>
