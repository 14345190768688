<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center"></div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <div class="card mb-4">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-12"><h4>Default Dialysis Values</h4></div>
          </div>

          <div class="row">
            <div class="col-4">
              <label>Priming Solution Value</label>
              <input
                class="form-control form-control-sm input-transparent"
                placeholder="Search here"
                type="text"
                [ngModel]="productName"
                (keyup)="
                  getProductList($event, 'priming_sol', '.search-result-ps')
                "
              />
              <div class="search-result-ps" *ngIf="isPSSearching">
                <p
                  *ngIf="
                    productListData.length === 0 &&
                    productSearchResult === false
                  "
                >
                  Searching...
                </p>
                <p
                  *ngIf="
                    productListData.length === 0 && productSearchResult === true
                  "
                >
                  No Result Found
                </p>
                <span *ngIf="productListData.length > 0">
                  <p
                    *ngFor="let product of productListData"
                    (click)="setProduct(product)"
                  >
                    {{ product.product_name }}
                  </p>
                </span>
              </div>
            </div>
          </div>

          <div class="row text-right">
            <div class="col-12">
              <button
                class="btn btn-primary btn-sm"
                type="button"
                (click)="createDialysisService()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--end::Ot Calendar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
