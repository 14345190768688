import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { DepartmentsByHospitalService } from '../../../../../services/departments-by-hospital.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { RouteShareService } from '../../../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-roster-approval',
  templateUrl: './roster-approval.component.html',
  styleUrls: ['./roster-approval.component.css'],
})
export class RosterApprovalComponent implements OnInit {
  today = formatDate(new Date(), 'MM-yyyy', 'en');
  depart: any;
  employeesOfDepart: any;
  employeesOfSelectedDepartment: any;
  selectedLocation = '';
  dates: any;
  employeeRoasterData = [];
  roasterData = [];
  shiftData = [];
  locationData = [];
  department: any;
  month: any;
  selectedMonthRoster: any;
  shiftFirstData: any;
  applyToAllShift = [];
  applyToAllConfirmation = false;
  applyToAllIndex: any;
  singleShiftIndex: any;
  removeShiftIndex: any;
  singleShiftEmployeeIndex: any;
  removeSingleEmployeeShift = false;
  selectedLocationAdd = '';
  selectedShiftAdd = '';
  removeShiftId: any;
  screenName = '';

  constructor(
    private departmentsByHospital: DepartmentsByHospitalService,
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private rosterServiceService: RosterServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private routeSevice: RouteShareService,
    private ref: ChangeDetectorRef,
  ) {
    this.screenName = this.routeSevice.getValue();
    this.getShiftListing();
    this.getDepartments();
  }

  getShiftListing(): any {
    this.ngxLoader.start();
    this.configurationService.fetchShiftListing('', '', '', true, '').subscribe(
      (data) => {
        this.shiftData = data;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        this.locationData = data.location;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getEmployeesOfDepartment(): any {
    this.employeesOfDepart = [];
    setTimeout((e) => {
      $('#employeeDepartmentList').selectpicker('refresh');
    }, 200);
    this.configurationService
      .getDepartmentEmployeeForRoster(this.department)
      .subscribe(
        (data) => {
          this.employeesOfDepart = data;
          setTimeout((e) => {
            $('#employeeDepartmentList').selectpicker('refresh');
            $('#employeeDepartmentList').selectpicker();
          }, 500);
        },
        (err) => {
          setTimeout((e) => {
            $('#employeeDepartmentList').selectpicker('refresh');
          }, 500);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  appendArray(): any {
    this.month = $('.month').val();
    // const month = $('.month').val();
    if (this.month === '') {
      toastr.error('Select Month');
      return;
    }
    if (this.employeesOfSelectedDepartment === undefined) {
      this.employeesOfSelectedDepartment = '';
    }
    if (this.department === undefined) {
      this.department = '';
    }
    if (this.selectedLocation === '' || this.selectedLocation === undefined) {
      toastr.error('Select location');
      return;
    }
    this.ngxLoader.start();
    this.rosterServiceService
      .getEmployRoasterForApproval(
        this.employeesOfSelectedDepartment,
        this.selectedLocation,
        this.month,
        this.department,
      )
      .subscribe(
        (data) => {
          this.selectedMonthRoster = data.month;
          this.employeeRoasterData = data.value;
          if (!this.employeeRoasterData.length) {
            toastr.error('Record Not Found!');
          }
          setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
            this.ngxLoader.stop();
          }, 100);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  onSubmit(): any {
    this.ngxLoader.start();
    this.rosterServiceService
      .approvedEmployeeRoster(
        this.employeeRoasterData,
        this.selectedMonthRoster,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.employeeRoasterData = [];
          toastr.success('Updated Successfully');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  openApplyToAllModal(ind): any {
    $('.roasterSelection.popup').selectpicker('refresh');
    $('#rosterApplyToAll').modal('show');
    this.applyToAllIndex = ind;
  }
  saveModalChanges(): any {
    if (this.applyToAllConfirmation === false) {
      toastr.error('Please click to agree with that');
      return;
    }
    this.ngxLoader.start();
    for (
      var i = 0;
      i < this.employeeRoasterData[this.applyToAllIndex].roster_detail.length;
      i++
    ) {
      this.employeeRoasterData[this.applyToAllIndex].roster_detail[i].shift =
        this.applyToAllShift;
    }
    this.ngxLoader.stop();
    setTimeout(() => {
      $(
        '#rosterValue' + this.applyToAllIndex + ' .roasterSelection',
      ).selectpicker('refresh');
      this.ngxLoader.stop();
    }, 100);
    $('#rosterApplyToAll').modal('hide');
    this.applyToAllConfirmation = false;
    this.applyToAllShift = [];
  }
  removefromArray(index): any {
    this.employeeRoasterData.splice(index, 1);
    // this.employeeRoasterData[index].is_removed = true;
  }
  singleShiftModalOpen(i, j): any {
    this.singleShiftEmployeeIndex = i;
    this.singleShiftIndex = j;
    this.selectedLocationAdd = '';
    this.selectedShiftAdd = '';
    $('#singleShiftModalClick').modal('show');
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').selectpicker();
    }, 500);
  }
  keyFunctions(): any {
    $('body').keyup((e) => {
      var code = e.keyCode || e.which;
      if (code == '9') {
        if ($('.rosterShiftMain').hasClass('focusAuto')) {
          $('.rosterShiftMain.focusAuto').next().addClass('focusAuto');
          $('.employeeShiftTable').scrollLeft(
            Math.min($('.employeeShiftTable').scrollLeft() + 170),
          );
          $('.rosterShiftMain.focusAuto').prev().removeClass('focusAuto');
        } else {
          $('.rosterShiftMain').first().addClass('focusAuto');
        }
        if ($('.rosterShiftMain').last().hasClass('focusAuto')) {
          console.log('In');
        }
        var ids = $('.rosterShiftMain.focusAuto .rosterShift').attr('id');
        ids = ids.split('roster');
        this.singleShiftEmployeeIndex = ids[0];
        this.singleShiftIndex = ids[1];
      }
      if (code == '13') {
        if ($('.rosterShiftMain').hasClass('focusAuto')) {
          $('#singleShiftModalClick').modal('show');
        }
      }
    });
  }
  checkOverlap = (timeSegments) => {
    if (timeSegments.length === 1) return false;

    timeSegments.sort((timeSegment1, timeSegment2) =>
      timeSegment1[0].localeCompare(timeSegment2[0]),
    );

    for (let i = 0; i < timeSegments.length - 1; i++) {
      const currentEndTime = timeSegments[i][1];
      const nextStartTime = timeSegments[i + 1][0];

      if (currentEndTime > nextStartTime) {
        return true;
      }
    }

    return false;
  };

  addNewShiftToEmployee(): any {
    if (this.selectedShiftAdd === '') {
      toastr.error('Select Shift');
      return;
    }
    if (this.selectedLocationAdd === '') {
      toastr.error('Select Location');
      return;
    }
    const shift = this.shiftData.filter(
      (m) => m.id === Number(this.selectedShiftAdd),
    )[0];
    const location = this.locationData.filter(
      (m) => m.id === Number(this.selectedLocationAdd),
    )[0];
    console.log('shift', shift);
    console.log('location', location);
    let perviousShift =
      this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
        this.singleShiftIndex
      ].shift;
    let shiftTimeArray = [];
    let dummyArray = [];
    dummyArray.push(moment(shift.time_from, ['h:mm A']).format('HH:mm'));
    dummyArray.push(moment(shift.time_to, ['h:mm A']).format('HH:mm'));
    shiftTimeArray.push(dummyArray);
    console.log('shiftTimeArray1', shiftTimeArray);
    for (let i = 0; i < perviousShift.length; i++) {
      if (perviousShift[i].is_removed === false) {
        dummyArray = [];
        console.log('perviousShift', perviousShift[i]);
        const prevShift = this.shiftData.filter(
          (m) => m.id === perviousShift[i].shift_id,
        )[0];
        console.log('prevShift', prevShift);
        dummyArray.push(
          moment(prevShift.time_from, ['h:mm A']).format('HH:mm'),
        );
        dummyArray.push(moment(prevShift.time_to, ['h:mm A']).format('HH:mm'));
        shiftTimeArray.push(dummyArray);
        console.log('shiftTimeArray2', shiftTimeArray);
      }
    }
    console.log(this.checkOverlap(shiftTimeArray));
    if (this.checkOverlap(shiftTimeArray) === true) {
      toastr.error('Shift timing Overlap with previous');
      return;
    }

    const obj = {
      shift_name: shift.name,
      shift_id: shift.id,
      shift_text_color: shift.shift_text_color,
      shift_bg_color: shift.shift_bg_color,
      location_name: location.name,
      location_id: location.id,
      is_removed: false,
      can_delete: true,
    };
    console.log('obj', obj);
    this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
      this.singleShiftIndex
    ].shift.push(obj);
    console.log('employeeRoasterData', this.employeeRoasterData);
    $('#singleShiftModalClick').modal('hide');
  }
  openRemoveModal(i, j, k, removeShiftId): any {
    this.removeSingleEmployeeShift = false;
    this.singleShiftEmployeeIndex = i;
    this.singleShiftIndex = j;
    this.removeShiftIndex = k;
    this.removeShiftId = removeShiftId;
    $('#singleShiftModalRemoveClick').modal('show');
  }
  removeSingleShiftModal(): any {
    if (this.removeSingleEmployeeShift === false) {
      toastr.error('Please click to agree with that');
      return;
    }
    // this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[this.singleShiftIndex].shift[this.removeShiftIndex].is_rejected = true;
    $('#singleShiftModalRemoveClick').modal('hide');
    this.removeSingleEmployeeShift = false;
    if (
      this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
        this.singleShiftIndex
      ].shift[this.removeShiftIndex].id
    ) {
      this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
        this.singleShiftIndex
      ].shift[this.removeShiftIndex].is_removed = true;
    } else {
      this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
        this.singleShiftIndex
      ].shift.splice(this.removeShiftIndex, 1);
    }
  }
  removeAllSingleShiftModal(): any {
    if (this.removeSingleEmployeeShift === false) {
      toastr.error('Please click to agree with that');
      return;
    }
    $('#singleShiftModalRemoveClick').modal('hide');
    for (
      let i = 0;
      i <
      this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail
        .length;
      i++
    ) {
      for (
        let j = 0;
        j <
        this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[i]
          .shift.length;
        j++
      ) {
        if (
          this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
            i
          ].shift[j].shift_id === this.removeShiftId
        ) {
          this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
            i
          ].shift[j].is_removed = true;
        }
      }
    }
  }
  ngOnInit(): void {
    $('.month').datepicker({
      format: 'mm-yyyy',
      viewMode: 'months',
      minViewMode: 'months',
      orientation: 'bottom left',
      startDate: this.today,
    });
    $('.month').datepicker('setDate', this.today);
    this.month = this.today;
    this.department = '';
    this.getEmployeesOfDepartment();
    // this.keyFunctions();
  }
}
