import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabTestService } from '../../../services/lab-test.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../../../services/patient.service';
import { OpdService } from '../../../services/opd.service';
import { environment } from '../../../../environments/environment';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-admission-history',
  templateUrl: './admission-history.component.html',
  styleUrls: ['./admission-history.component.css'],
})
export class AdmissionHistoryComponent implements OnInit {
  PatientSearchForm: UntypedFormGroup;
  patientAdmissions = [];
  @Input() patient: any;
  mergeOTMedicines = true;
  mrn: any;
  visitId: any;
  // patientId: 12312;
  beforeSettlement = true;
  constructor(
    private hospitalDoctorsService: HospitaldoctorService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private opdService: OpdService,
  ) {}

  ngOnInit(): void {
    this.getPatientAdmissions();
  }

  getPatientAdmissions(): any {
    this.patientAdmissions = [];
    this.opdService.getPatientAdmissionHistoryV2(this.patient.id).subscribe(
      (data) => {
        this.patientAdmissions = data.admissions;
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }

  _onlyNumeric(event: any): any {
    if (event.charCode != 46) {
      const pattern = /^\d{1,6}(\.\d{1,2})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }

  get f(): any {
    return this.PatientSearchForm.controls;
  }

  billBeforeSettlement(id, admission, visit_id): any {
    // const URL = '/sale/invoice?id=' + id + '&admission=' + admission + '&status=' + status;
    // window.open(URL, '_blank');
    window.open(
      'sale/invoice-v2?id=' +
        '&visit_id=' +
        visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&status=true',
    );
  }

  billAfterSettlement(id, admission, visit_id): any {
    // const URL = '/sale/invoice?id=' + id + '&admission=' + admission + '&status=' + status;
    // window.open(URL, '_blank');
    window.open(
      'sale/invoice-v2?id=' +
        '&visit_id=' +
        visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&status=false',
    );
  }
  printHswBill(mrn, admission, status, visit_id): any {
    // window.open('sale/hsw-invoice?id=' + mrn + '&status=' + status + '&hsw_bill=true' + '&admission=' + admission );
    // setTimeout( () => {
    //   window.open('sale/hsw-invoice?id=' + mrn + '&status=' + status + '&hsw_bill=false' + '&admission=' + admission );
    // }, 1000 );
    window.open(
      'sale/hsw-invoice-v2?id=' +
        '&visit_id=' +
        visit_id +
        '&mrn=' +
        mrn +
        '&status=' +
        status +
        '&hsw_bill=true',
    );
    setTimeout(() => {
      window.open(
        'sale/hsw-invoice-v2?id=' +
          '&visit_id=' +
          visit_id +
          '&mrn=' +
          mrn +
          '&status=' +
          status +
          '&hsw_bill=false',
      );
    }, 1000);
  }
  printSummarizeBill(mrn, admission, visit_id): any {
    window.open(
      'sale/summerize-bill?id=' + '&visit_id=' + visit_id + '&mrn=' + mrn,
    );
  }
  clearanceCertificate(mrn, visit_id): any {
    const URL =
      '/pdf-reports/clearance-certificate?mrn=' +
      mrn +
      '&visit_id=' +
      visit_id +
      '&id=' +
      '';
    window.open(URL, '_blank');
  }
  openDischargePdf(admission): any {
    window.open(
      'pdf-reports/discharge-report?id=' +
        this.patient.id +
        '&visit_id=' +
        admission.visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&admission_id=' +
        admission.id,
    );
    // window.open(environment.rorUrl + '/v3/discharge_patients/discharge_report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&patient_id=' + this.patient.mrn + '&admission_id=' + id);
  }

  printIpdInvoicesReport(visit_id): any {
    window.open(
      'sale/invoice-v2?id=' +
        '&visit_id=' +
        visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&status=true',
    );
    // window.open(environment.rorUrl + '/v4/patients/' + this.patient.mrn + '/final_settlements/ipd_invoice.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&admission_id=' + id );
  }
  issuedItemPrint(id): any {
    const URL = '/patient/issue-item-print/' + id + '/' + this.patient.mrn;
    window.open(URL, '_blank');
  }

  printandView(mrn, visit_id, before): any {
    this.visitId = visit_id;
    this.mrn = mrn;
    this.beforeSettlement = before;
    $('#otMedicineModal').modal('show');
  }
  printBill(): any{
    this.printSummerizeBill2();
  }

  printSummerizeBill2(): any {
    window.open(
      'sale/summarized-bill-v2?id=' +
      '&visit_id=' +
      this.visitId +
      '&mrn=' +
      this.mrn +
      '&status=' +
      this.beforeSettlement +
      '&hsw_bill=true' +
      '&merge_ot_medicine=' +
      this.mergeOTMedicines,
    );
    setTimeout(() => {
      window.open(
        'sale/summarized-bill-v2?id=' +
        '&visit_id=' +
        this.visitId +
        '&mrn=' +
        this.mrn +
        '&status=' +
        this.beforeSettlement +
        '&hsw_bill=false' +
        '&merge_ot_medicine=' +
        this.mergeOTMedicines,
      );
    }, 1000);
  }
}
