import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TaskManagementService {
  constructor(private http: HttpClient) {}
  addLoanType(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_priority_detail?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDepartmentLocations(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_department_location?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addComplaint(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_management/task_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getComplaintListing(page, isSelf, per_page, search): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/task_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&status=' +
          search.status +
          '&employee=' +
          search.employee +
          '&is_self=' +
          isSelf +
          '&priority=' +
          search.priority +
          '&project=' +
          search.project,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTaskListing(page, per_page, value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/filter_wise_task_list?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&page=' +
        page +
        '&per_page=' +
        per_page +
        '&title=' +
        value.title +
        '&status=' +
        value.status +
        '&department=' +
        value.department +
        '&location=' +
        value.location +
        '&priority=' +
        value.priority +
        '&type=' +
        value.type +
        '&project=' +
        value.project +
        '&related_to=' +
        value.related_to +
        '&screen=' +
        value.screen +
        '&tags=' +
        value.tags +
        '&assign_to=' +
        value.assign_to +
        '&task_id=' +
        value.task_id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateComplainStatus(cancelRequestId, status): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/task_management/abc?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          cancelRequestId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          status,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getComplaints(isSelf): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/complaint_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&is_self=' +
          isSelf,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getComplaintDataById(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/task_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateAsign(value, type, task_id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/task_management/update_assignee?auth_token=` +
          localStorage.getItem('auth_token') +
          '&task_id=' +
          task_id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          type,
          task_id,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateTask(value, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_management/task_transaction_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          id,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  //add task comments against task id
  taskCommentsSubmit(comments, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_management/task_comment_transaction_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          comments,
          id,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getComplaintReport(
    start_date,
    end_date,
    department,
    location,
    status,
    priority,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/task_get_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&department=' +
          department +
          '&location=' +
          location +
          '&status=' +
          status +
          '&priority=' +
          priority,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTagList(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/tag_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addTag(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_management/tag_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          is_removed: value.is_removed,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateTag(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/task_management/tag_detail` +
          '?&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          is_removed: value.is_removed,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getStatusList(status): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/status_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') + '&status=' + status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addStatus(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_management/status_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          color: value.color,
          is_default: value.is_default,
          is_active: value.is_active,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateTaskStatus(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/task_management/status_detail` +
          '?&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          color: value.color,
          is_default: value.is_default,
          is_active: value.is_active,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployees(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_department_employee?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatePriority(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/task_management/task_priority_detail` +
          '?&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          is_removed: value.is_removed,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPriority(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/task_priority_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addPriority(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_management/task_priority_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          is_default: value.is_default,
          is_active: value.is_active,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addTimelog(value, task_id, employee_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_management/time_lock?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit: localStorage.getItem('current_medical_unit_id'),
          value,
          task_id,
          employee_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTimelog(id, emp_id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/time_lock?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&task_id=' +
          id +
          '&employee_id=' +
          emp_id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployTask(
    from_date,
    to_date,
    task_priority,
    task_status,
    employee,
    project,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/employee_task_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          from_date +
          '&to_date=' +
          to_date +
          '&status=' +
          task_status +
          '&priority=' +
          task_priority +
          '&employee_id=' +
          employee +
          '&project=' +
          project,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateProject(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/task_management/project_detail` +
          '?&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          ref_medical_unit: value.ref_medical_unit,
          is_active: value.is_active,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getProject(status): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/project_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') + '&status=' + status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRoleBaseProject(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/user_projects/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addProject(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_management/project_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          ref_medical_unit: value.ref_medical_unit,
          is_default: value.is_default,
          is_active: value.is_active,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  //  get Employee's All Tasks
  getEmployeeAllTasks(
    startDate: any,
    endDate: any,
    employeeIds: any,
    department: any,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/weekly_task_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          startDate +
          '&end_date=' +
          endDate +
          '&employees=' +
          employeeIds +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addDocuments(value, taskId, check): Observable<any> {
    const body = new FormData();
    if (check === false){
      for (let i = 0; i < value.length; i++) {
        let ind = i + 1;
        body.append('document' + ind, value[i]);
      }
      body.append('length', value.length);
    } else {
      body.append('document1', value);
      body.append('length', '1');
    }
    body.append('task', taskId);
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_management/upload_document?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        body,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDocuments(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/upload_document?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&task_id=' +
        id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addTimeLogAndComments(value, task_id, type, commentDocs, recordedBlob): Observable<any> {
      const body = new FormData();
      if (type === 'comments') {
        for (let i = 0; i < commentDocs.length; i++) {
          let ind = i + 1;
          body.append('document' + ind, commentDocs[i]);
        }
        body.append('comment', value.comment);
        if (commentDocs.length > 0) {
          body.append('length', commentDocs.length);
        }
        body.append('task_id', task_id);
        body.append('type', type);
        body.append('recording', recordedBlob || '');
        body.append('is_completed', value.is_completed);
      } else if (type === 'daily_log_comments') {
        body.append('comment', value.comment);
        body.append('daily_log', value.daily_log);
        body.append('task_id', task_id);
        body.append('daily_log_unit', value.daily_log_unit);
        body.append('date', value.date);
        body.append('is_completed', value.is_completed);
        body.append('type', type);
      }
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_management/time_lock?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        body,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getTimeLogAndComments(type, taskId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/time_lock_and_comments?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&type=' +
        type +
        '&task_id=' +
        taskId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getLiveProjects(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/dashboard/new_patient_creation_summary_last_24_hours/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTaskStatus(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/status_wise_task_count?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTaskPriority(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/priority_wise_task_count?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTaskTag(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/tag_wise_task_count?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getTodayAndTotalTickets(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/dashboard/tickets_summary/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAuthorList(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/task_management/dashboard/emp_task_summary/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  deleteDocument(docId): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/task_management/upload_document` +
        '?&auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          document_id: docId
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addWorkOrderList(workOrderForm, taskId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/task_management/work_order/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') + '&task_id=' + taskId,
        {
          medical_unit: localStorage.getItem('current_medical_unit_id'),
          work_order_list: workOrderForm
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
