import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class FinanceConfigurationService {
  constructor(private http: HttpClient) {}

  // new finance config apis

  // get book of account wit its flex value
  getBookOfAccountsWithFlexValue(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/book_of_account/coa_book_of_account/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&is_active=true',
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // get specific account group against book of account
  getAccountGroupAgainstBookAccount(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/account_groups/coa_account_group/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_of_account=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  // get specific subsidiary account against account group

  // Invoice Paybale payment Details
  getPaymentDetails(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/grn_voucher/invoice_payable_detail/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getSubsidiaryAccountAgainstAccountGroup(
    bookAccountId,
    accountGroupId,
  ): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/subsidiary_account/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_of_account=' +
          bookAccountId +
          '&account_group=' +
          accountGroupId,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  // subsidiary over account group
  getSubsidiaryAccountAgainstAG(accountGroupId): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/subsidiary_account/subsidiary_account_for_lga/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&account_group=' +
          accountGroupId,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // Book of Account Crud
  activeBookOfAccounts(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/book_of_account/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&is_active=true',
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  activeBatchLists(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/batch/batch_list/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&is_active=true' +
          '&book_of_account=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  activeBookOfAccountsWithTheirCoa(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/book_of_account_wise_coa/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getBookOfAccountListing(
    page,
    perPage,
    value,
    status: boolean,
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(
        `${environment.pythonUrl}/finance/book_of_account/?page=` +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&is_active=' +
          status,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateDefaultBookAccount(bookAccount): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/book_of_account/default_book_of_account/?book_of_account=` +
          bookAccount +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addBookOfAccount(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/book_of_account/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateBookOfAccount(value, id): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/finance/book_of_account/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getSpecificBookOfAccount(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/book_of_account/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // Flex Level Crud
  activeFlexLevels(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/flex_levels/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getFlexLevelListing(page, perPage, value, status): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(
        `${environment.pythonUrl}/finance/flex_levels/?page=` +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&is_active=' +
          status,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addFlexLevel(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/flex_levels/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateFlexLevel(value, id): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/finance/flex_levels/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getSpecificFlexLevel(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/flex_levels/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // Flex Field Crud
  getFlexFieldListing(page, perPage, value): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(
        `${environment.pythonUrl}/finance/flex_fields/?page=` +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addFlexField(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/flex_fields/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateFlexField(value, id): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/finance/flex_fields/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getSpecificFlexField(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/flex_fields/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  checkFlexFieldOfBookAccount(bookAccountId): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/flex_fields/check_book_of_account_field?book_of_account=` +
          bookAccountId +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // Account Group Crud
  getAccountGroupListing(page, perPage, value): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(
        `${environment.pythonUrl}/finance/account_groups/?page=` +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&book_of_account=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addAccountGroup(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/account_groups/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateAccountGroup(value, id): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/finance/account_groups/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getSpecificAccountGroup(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/account_groups/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // Chart of Account Crud / func
  getFlowLevels(accountGroupId, isParent: boolean): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/account_groups/coa_account_group_fl/?account_group=` +
          accountGroupId +
          '&is_account_group_parent=' +
          isParent +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getFlowChildLevels(parentFLId, isParentFL: boolean): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/account_groups/coa_account_group_fl/?parent_fl_id=` +
          parentFLId +
          '&is_parent_fl=' +
          isParentFL +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  createFlowLevels(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/account_groups/create_coa_account_group_fl/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getChartOfAccountListing(page, perPage, value, status): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(
        `${environment.pythonUrl}/finance/chart_of_account/?page=` +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&book_of_account=' +
          value + '&is_active=' + status,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addChartOfAccount(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/chart_of_account/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateChartOfAccount(value, id): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/finance/chart_of_account/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateChartOfAccountNameOrStatus(name, status, id, chartName): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/update_chart_of_account/` + id + '/?book_of_account=' +
        chartName +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          name,
          is_active: status,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificChartOfAccount(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/chart_of_account/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // Fiscal Year Crud
  activeFiscalYears(bookAccountId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(
        `${environment.pythonUrl}/finance/fiscal_year/fy_for_batch/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_of_account=' +
          bookAccountId,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  unPostedFiscalYears(bookAccountId): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/fiscal_year/unposted_fiscal_years/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_of_account=' +
          bookAccountId,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  checkFiscalYearBatches(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/fiscal_year/check_fiscal_year_open_batch/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&fiscal_id=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  closeAndOpenFiscalYear(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/fiscal_year/close_fiscal_year/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  fiscalYearPeriods(id): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(
        `${environment.pythonUrl}/finance/fiscal_year/fp_of_fy_for_batch/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&fiscal_year=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getFiscalYearListing(page, perPage, value, bookAccountId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(
        `${environment.pythonUrl}/finance/fiscal_year/?page=` +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&book_of_account=' +
          bookAccountId,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addFiscalYear(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/fiscal_year/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateFiscalYear(value, id): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/finance/fiscal_year/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getSpecificFiscalYear(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/fiscal_year/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // Batch Type Crud
  getBatchTypeListing(page, perPage, value, status): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(
        `${environment.pythonUrl}/finance/batch_type/?page=` +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&book_of_account=' +
          value +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addBatchType(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/batch_type/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateBatchType(value, id): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/finance/batch_type/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getSpecificBatchType(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/batch_type/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // batch crud
  getBatchListing(
    page,
    perPage,
    value,
    fiscalId,
    bookAccountId,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/batch/?page=` +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&fiscal_year_id=' +
          fiscalId +
          '&book_of_account=' +
          bookAccountId,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addBatch(value): Observable<any> {
    const results = this.http
      .post(`${environment.pythonUrl}/finance/batch/`, JSON.stringify(value), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('auth_token'),
        }),
      })
      .pipe(map((data) => data));
    return results;
  }
  updateBatch(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/batch/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificBatch(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/batch/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // voucher crud
  getVoucherTypeListing(page, perPage, value, status): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/voucher_type/?page=` +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addVoucherType(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/voucher_type/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateVoucherType(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/voucher_type/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificVoucherType(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/voucher_type/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // bank setup crud
  getBankManagementListingWithBatch(batchId): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/bank_management/bank_management_for_voucher?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&batch_id=' +
          batchId,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getBankManagementListingAgainstBookOfAccount(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/bank_management/bank_management_against_book_of_account?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_of_account=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getBankSetupListing(page, perPage, value, bookAccountId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/bank_management?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&book_of_account=' +
          bookAccountId,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addBankSetup(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/bank_management/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateBankSetup(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/bank_management/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificBankSetup(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/bank_management/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Cash Management
  getCashManagementListing(page, perPage, value, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/cash_management?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&book_of_account=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addCashManagement(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/cash_management/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateCashManagement(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/cash_management/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificCashManagement(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/cash_management/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // cheque book crud
  getChequebookListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/cheque_book?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addChequebook(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/cheque_book/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateChequebook(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/cheque_book/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificChequebook(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/cheque_book/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // voucher crud
  getBankChequeBooksListing(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/cheque_book/bank_cheque_book?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&bank_management=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getBankChequeBooksLeavesListing(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/cheque_book/cheque_book_leaves?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&cheque_book=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSubsidiaryAccounts(batchId, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/subsidiary_account/subsidiary_account_for_voucher?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&batch=' +
          batchId +
          '&book_of_account=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSubsidiaryAccountsAgainstBOA(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/subsidiary_account/subsidiary_account_against_boa?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_of_account=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getBatchesVoucherListing(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/voucher?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getVoucherListing(batchId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/voucher?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&batch_id=' +
          batchId,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addVoucher(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/voucher/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateVoucher(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/voucher/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificVoucher(id, values, page, per_page): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/voucher?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&batch=' +
          id +
          '&voucher_type=' +
          values.voucher_type +
          '&voucher_no=' +
          values.voucher_no +
          '&voucher_date=' +
          values.voucher_date +
          '&source=' +
          values.source +
          '&description=' +
          values.description +
          '&voucher_month=' +
          values.voucher_month +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificVoucherDetail(id, isEdit): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') + '&is_edit=' + isEdit,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  grnVoucher(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/grn_voucher/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Bank Crud
  getBanks(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/bank?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getBankListing(page, perPage, value): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(
        `${environment.pythonUrl}/finance/bank/?page=` +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addBank(value): Observable<any> {
    return this.http
      .post(`${environment.pythonUrl}/finance/bank/`, JSON.stringify(value), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('auth_token'),
        }),
      })
      .pipe(map((data) => data));
  }
  updateBank(value, id): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/finance/bank/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getSpecificBank(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/bank/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // Tax Type Crud
  getTaxTypes(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/bank?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getTaxTypeListing(page, perPage, value): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(
        `${environment.pythonUrl}/finance/bank/?page=` +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addTaxType(value): Observable<any> {
    return this.http
      .post(`${environment.pythonUrl}/finance/bank/`, JSON.stringify(value), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('auth_token'),
        }),
      })
      .pipe(map((data) => data));
  }
  updateTaxType(value, id): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/finance/bank/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getSpecificTaxType(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/bank/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  // old finance config apis
  // get countries
  countries(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/countries?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // get company
  getCompanyListing(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/company_basic_info?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // narration memo crud
  getNarrationMemoListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/narration_memo?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addNarrationMemo(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/narration_memo/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateNarrationMemo(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/narration_memo/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificNarrationMemo(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/narration_memo/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // voucher mapping crud
  getVoucherMappingListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/voucher_mapping?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addVoucherMapping(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/voucher_mapping/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateVoucherMapping(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/voucher_mapping/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificVoucherMapping(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/voucher_mapping/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // system setup crud
  getSystemSetupListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/system_setup?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addSystemSetup(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/system_setup/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateSystemSetup(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/system_setup/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificSystemSetup(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/system_setup/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // financial year crud
  getFinancialYearListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/financial_year?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addFinancialYear(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/financial_year/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateFinancialYear(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/financial_year/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificFinancialYear(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/financial_year/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Currency crud
  getCurrencyListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/currency?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addCurrency(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/currency/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateCurrency(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/currency/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificCurrency(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/currency/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Analysis crud
  getAnalysisListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/analysis?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addAnalysis(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/analysis/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateAnalysis(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/analysis/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificAnalysis(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/analysis/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Cost Center crud
  getCostCenterListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/cost_center?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addCostCenter(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/cost_center/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateCostCenter(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/cost_center/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificCostCenter(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/cost_center/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // reporting crud
  getReportingListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/reporting?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addReporting(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/reporting/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateReporting(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/reporting/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificReporting(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/reporting/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Chart of Account crud
  // getChartOfAccountListing(page, perPage, value): Observable<any> {
  //   const results = this.http.get(`${environment.pythonUrl}/finance/chart_of_account?medical_unit_id=` + localStorage.getItem('current_medical_unit_id') + '&page=' + page + '&per_page=' + perPage + '&search_string=' + value
  //     , {
  //       headers: new HttpHeaders({
  //         Authorization: localStorage.getItem('auth_token'),
  //         'Content-Type': 'application/json'
  //       })
  //     }).pipe(map(data => data));
  //   return results;
  // }
  // addChartOfAccount(value): Observable<any> {
  //   const results = this.http.post(`${environment.pythonUrl}/finance/chart_of_account/`, JSON.stringify(value) ,
  //     {headers: new HttpHeaders({
  //         Authorization: localStorage.getItem('auth_token'),
  //         'Content-Type': 'application/json'
  //       })}).pipe(map(data => data));
  //   return results;
  // }
  // updateChartOfAccount(value, id): Observable<any> {
  //   const results = this.http.put(`${environment.pythonUrl}/finance/chart_of_account/` + id + '/', JSON.stringify(value),
  //     { headers: new HttpHeaders({
  //         Authorization: localStorage.getItem('auth_token'),
  //         'Content-Type': 'application/json'
  //       })}).pipe(map(data => data));
  //   return results;
  // }
  // getSpecificChartOfAccount(id): Observable<any> {
  //   const results = this.http.get(`${environment.pythonUrl}/finance/chart_of_account/` + id + '/?medical_unit_id=' + localStorage.getItem('current_medical_unit_id')
  //     , {
  //       headers: new HttpHeaders({
  //         Authorization: localStorage.getItem('auth_token'),
  //         'Content-Type': 'application/json'
  //       })
  //     }).pipe(map(data => data));
  //   return results;
  // }

  // subsidiary Account crud
  getSubsidiaryAccountListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/subsidiary_account?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addSubsidiaryAccount(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/subsidiary_account/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateSubsidiaryAccount(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/subsidiary_account/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificSubsidiaryAccount(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/subsidiary_account/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // business nature crud
  getBusinessNatureListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/business_nature?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addBusinessNature(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/business_nature/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateBusinessNature(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/business_nature/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificBusinessNature(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/business_nature/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // party crud
  getPartyListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/party?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addParty(value): Observable<any> {
    const results = this.http
      .post(`${environment.pythonUrl}/finance/party/`, JSON.stringify(value), {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem('auth_token'),
          'Content-Type': 'application/json',
        }),
      })
      .pipe(map((data) => data));
    return results;
  }
  updateParty(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/party/` + id + '/',
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificParty(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/party/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllSubsidiaries(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/subsidiary_account/all_subsidiary?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  subsidiaryAccountAgainstBoa(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/subsidiary_account/subsidiary_account_against_boa?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_of_account=' +
          id,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getPaymentModes(bookOfAccount): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/clinical_v1/get_payment_mode?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&auth_token=' +
          localStorage.getItem('auth_token') + '&book_of_account=' + bookOfAccount,
        {
          headers: new HttpHeaders({
            // Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updatePaymentMode(value, bookOfAccount): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/clinical_v1/get_payment_mode?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          book_of_account: bookOfAccount,
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getAdvances(bookOfAccount): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/advance_coa_setting/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
        '&book_of_account=' + bookOfAccount,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateAdvances(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/advance_coa_setting/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getIssueItems(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/issue_coa_setting/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&auth_token=' +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateIssueItems(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/issue_coa_setting/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getGRNSetting(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/grn_coa_setting/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&auth_token=' +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateGRNSetting(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/grn_coa_setting/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateFLEntry(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/account_groups/update_fl_entry/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  // get Last child Against Account Group
  getAccountGroupLastChild(bookAccountId): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/account_groups/coa_account_group_last_fl/?book_account=` +
          bookAccountId +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateAGChilds(value): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/finance/account_groups/update_fl_list/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  // tax type list
  getTaxType(currentPage, perPage, name): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/inventory/tax_type_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&search_string=' +
          name,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getProcedureCategory(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/procedure_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&is_active=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addNewProcedureCategory(procedure_configurator: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/procedure_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          procedure_configurator,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateProcedureCategory(procedure_configurator, id): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/procedure_configurators/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          procedure_configurator,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // For All Active Employees
  getAllActiveEmployees(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/hr/employee_staff?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
  }
  // doctor service coa New
  searchDoctorWiseService(
    page,
    per_page,
    doctorName,
    service,
    department,
    subDepartment,
    type,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/finance/dept_wise_doctor_services/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&doctor=' +
          doctorName +
          '&service=' +
          service +
          '&department=' +
          department +
          '&sub_department=' +
          subDepartment +
          '&service_type=' +
          type,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // updateCheck API
  updateCheck(employee, subsidiary_account, services): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/doctor_services_mapping/?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          employee,
          subsidiary_account,
          services,
        },
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  // discount settings API
  getDiscountSetting(discountBookOfAccount): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/discount_coa_setting/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
        '&book_of_account=' + discountBookOfAccount,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  // Doctor Share settings API
  getDoctorShareSetting(discountBookOfAccount): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/doctor_share_setting/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
        '&book_of_account=' + discountBookOfAccount,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateDoctorShareSetting(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/doctor_share_setting/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateDiscountSetting(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/discount_coa_setting/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  // assign services to doc crud
  addDocServices(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/doctor_services_mapping/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateDocServices(value, id): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/finance/doctor_services_mapping/` + id + `/`,
        JSON.stringify(value),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth_token'),
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getDocServices(employeeId): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/doctor_services_mapping/` +
          employeeId +
          '?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  // get voucher payment data
  getPaymentVoucherDetail(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/cheque_request_detail/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  // get Cash Voucher Data
  getCashVoucherDetail(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/cash_request_detail/` +
          id +
          '/?medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
}
