<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Actions-->
        <!--        <a  class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a>-->
        <!--end::Actions-->
        <!--begin::Dropdown-->
        <!--        <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">-->
        <!--          <a  class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--											<span class="svg-icon svg-icon-success svg-icon-2x">-->
        <!--												&lt;!&ndash;begin::Svg Icon | path:assets/media/svg/icons/Files/File-plus.svg&ndash;&gt;-->
        <!--												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
        <!--													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
        <!--														<polygon points="0 0 24 0 24 24 0 24" />-->
        <!--														<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />-->
        <!--														<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000" />-->
        <!--													</g>-->
        <!--												</svg>-->
        <!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--											</span>-->
        <!--          </a>-->
        <!--          <div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">-->
        <!--            &lt;!&ndash;begin::Navigation&ndash;&gt;-->
        <!--            <ul class="navi navi-hover">-->
        <!--              <li class="navi-header font-weight-bold py-4">-->
        <!--                <span class="font-size-lg">Choose Label:</span>-->
        <!--                <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mb-3 opacity-70"></li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-success">Customer</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-danger">Partner</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-warning">Suplier</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-primary">Member</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-dark">Staff</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mt-3 opacity-70"></li>-->
        <!--              <li class="navi-footer py-4">-->
        <!--                <a class="btn btn-clean font-weight-bold btn-sm" >-->
        <!--                  <i class="ki ki-plus icon-sm"></i>Add new</a>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--            &lt;!&ndash;end::Navigation&ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Form-->

      <div class="card mb-4">
        <div class="card-body">
          <form [formGroup]="PatientSearchForm" (ngSubmit)="fetchPatient()">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label> MRN<span class="text-danger">*</span> </label>
                  <input
                    class="form-control form-control-sm"
                    autofocus
                    type="text"
                    formControlName="search_query"
                    type="text"
                    maxlength="8"
                    minlength="8"
                    placeholder="Enter Patient MRN"
                    (keypress)="_onlyNumeric($event)"
                    [ngClass]="{
                      'is-invalid': isSubmit && f.search_query.errors
                    }"
                    [value]="default_patient_mrn"
                  />
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label> Name </label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    disabled
                    placeholder="Patient Name"
                    [value]="patient_full_name"
                  />
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label> Age/Gender </label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    disabled
                    placeholder="Gender"
                    [value]="patient_gender_age"
                  />
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label> Patient Type </label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    disabled
                    placeholder="Patient Type"
                    [value]="patient?.patient_type?.name"
                  />
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label> Company </label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    disabled
                    placeholder="Company"
                    [value]="patient?.company?.name"
                  />
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label> Group </label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    disabled
                    placeholder="Group"
                    [value]="patient?.group?.name"
                  />
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-6 col-12"></div>

              <div
                class="col-lg-3 col-md-3 col-sm-6 col-12 align-self-end text-right"
              >
                <button type="submit" class="btn btn-primary btn-sm">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-vertical-center table-head-custom">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Department</th>
                  <th>Discharge Report</th>
                  <th>IPD Bill</th>
                  <th><span class="d-block">Bill Before</span> FS</th>
                  <th><span class="d-block">Bill After</span> FS</th>
                  <th>HSW</th>
                  <th>HSW Before FS</th>
                  <th>Clearance Certificate</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let v of patientAdmissions">
                  <td>{{ v.created_at | date: "dd-MM-yyyy" }}</td>
                  <td>{{ v?.referred_to?.title }}</td>
                  <td>
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                      (click)="openAdmissionPdf(v.id)"
                      *ngIf="v?.discharge_patient_id"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\File.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            />
                            <rect
                              fill="#000000"
                              x="6"
                              y="11"
                              width="9"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              x="6"
                              y="15"
                              width="5"
                              height="2"
                              rx="1"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      >
                    </a>
                  </td>
                  <td>
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                      (click)="printIpdInvoicesReport(v.id)"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\File.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            />
                            <rect
                              fill="#000000"
                              x="6"
                              y="11"
                              width="9"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              x="6"
                              y="15"
                              width="5"
                              height="2"
                              rx="1"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      >
                    </a>
                  </td>
                  <td>
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                      (click)="billBeforeSettlement(patient.mrn, v.id)"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              fill="#000000"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      >
                    </a>
                  </td>
                  <td>
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                      (click)="billAfterSettlement(patient.mrn, v.id)"
                      *ngIf="v?.final_settlement"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              fill="#000000"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      >
                    </a>
                  </td>
                  <td>
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                      (click)="printHswBill(patient.mrn, v.id, false)"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              fill="#000000"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      >
                    </a>
                  </td>
                  <td>
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                      (click)="printHswBill(patient.mrn, v.id, true)"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              fill="#000000"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      >
                    </a>
                  </td>
                  <td>
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                      (click)="clearanceCertificate(patient.mrn, v.visit_id)"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              fill="#000000"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      >
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!--end:: Form-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
