import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ShareDataService } from './share-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  menus: any;
  subMenus: any;
  activeRoute;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.activeRoute = state.url;
    if (this.activeRoute.indexOf('?') !== -1) {
      this.activeRoute = this.activeRoute.substr(
        0,
        this.activeRoute.indexOf('?'),
      );
    }
    if (this.activeRoute.charAt(0) !== '/') {
      this.activeRoute = '/' + this.activeRoute;
    }
    this.menus = JSON.parse(localStorage.getItem('currentUser'));
    this.subMenus = this.menus.role;
    if (this.subMenus) {
      for (const mainMenu of this.subMenus.role_menus) {
        for (const mainMenuSecond of mainMenu.role_menu_sub_menus) {
          for (const menuItem of mainMenuSecond?.role_menu_sub_menu_screens) {
            if (menuItem.screen) {
              let urls = menuItem.screen.url;
              if (urls.charAt(0) !== '/') {
                urls = '/' + urls;
              }
              if (urls === this.activeRoute) {
                return true;
              } else {
              }
            }
          }
        }
      }
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigate(['dashboard']);
    }
  }
}
