import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareDataService {
  dataChanged = new EventEmitter<any>();

  private messageSource = new BehaviorSubject(
    JSON.parse(localStorage.getItem('currentScreen')),
  );
  currentMessage = this.messageSource.asObservable();

  private controlChecks = new BehaviorSubject('');
  controlCheck = this.controlChecks.asObservable();

  private countryData = new BehaviorSubject([]);
  countriesData = this.countryData.asObservable();

  private productData = new BehaviorSubject([]);
  productsDataList = this.productData.asObservable();

  private inventoryDashboardId = new BehaviorSubject([]);
  inventoryDashboardDataId = this.inventoryDashboardId.asObservable();

  private inventoryDashboardMasterCheck = new BehaviorSubject([]);
  inventoryDashboardDataMasterCheck =
    this.inventoryDashboardMasterCheck.asObservable();

  private updateLabData = new BehaviorSubject<any>('');
  updateLabsData = this.updateLabData.asObservable();

  private updateDialysisData = new BehaviorSubject<any>('');
  updateDialysisServiceData = this.updateDialysisData.asObservable();

  private updateRadiologyData = new BehaviorSubject<any>('');
  updateRadiologyServiceData = this.updateRadiologyData.asObservable();

  private updateMiscData = new BehaviorSubject<any>('');
  updateMiscServiceData = this.updateMiscData.asObservable();

  private updateOtData = new BehaviorSubject<any>('');
  updateOtServiceData = this.updateOtData.asObservable();

  constructor() {}
  // Method to update the data
  updateMiscService(data: any): any {
    this.updateMiscData.next(data);
  }
  updateOtService(data: any): any {
    this.updateOtData.next(data);
  }
  updateRadiologyService(data: any): any {
    this.updateRadiologyData.next(data);
  }
  sendUpdateLabData(data: any): any {
    this.updateLabData.next(data);
  }
  sendUpdateDialysisData(data: any): any {
    this.updateDialysisData.next(data);
  }
  updateData(newData): any {
    this.dataChanged.emit(newData);
  }
  changeMessage(message): any {
    this.messageSource.next(message);
  }
  changeControlChecks(data): any {
    this.controlChecks.next(data);
  }
  updateCountryData(data): any {
    this.countryData.next(data);
  }
  updateProductData(data): any {
    this.productData.next(data);
  }
  inventoryDbData(data): any {
    this.inventoryDashboardId.next(data);
  }
  checkMasterDashboard(data): any {
    this.inventoryDashboardMasterCheck.next(data);
  }
}
