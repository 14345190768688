import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { OpdService } from '../../../../services/opd.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VitalsValidationService } from '../../../../services/vitals-validation.service';
import * as moment from 'moment/moment';
import { environment } from '../../../../../environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;
declare var toastr: any;
declare var Chart: any;

@Component({
  selector: 'app-vitals',
  templateUrl: './vitals.component.html',
  styleUrls: ['./vitals.component.css'],
})
export class VitalsComponent implements OnInit {
  @Input() patient: any;
  @Input() isAdd: boolean;
  @Input() isVitalsUpdate: number;
  @Input() visitType: any;
  // @Input() isMainVitalsUpdate: any;
  // @Input() isVitalsUpdatedData: number;
  // @Input() isMainVitalsUpdatedData: number;
  @ViewChild('alertBox') alertBox: ElementRef;
  @Output() isVitalsRecieved = new EventEmitter<string>();

  vitalsListData = [];
  vitalsListDataAppend = [];
  patientAllHistory = false;
  alert_message = '';
  disableAddVitalsBtn = false;
  vitalsData: any = {
    weight: '',
    height: '',
    temperature: '',
    bp_systolic: '',
    bp_diastolic: '',
    pulse: '',
    resp_rate: '',
    o2_saturation: '',
    bmi: '',
    bsr: '',
  };

  constructor(
    private opdService: OpdService,
    private chRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private vitalsValidation: VitalsValidationService,
    private ngxLoader: NgxUiLoaderService,
  ) {}

  ngOnInit(): void {
    // this.getVitals('');
  }
  ngOnChanges(changes: SimpleChanges): any {
    if (changes.isVitalsUpdate?.currentValue) {
      this.getVitals('');
    }
    this.getVitals(changes.patient?.currentValue);
    // console.log('isVitalsUpdateisVitalsUpdate', changes.isMainVitalsUpdate.currentValue);
  }
  addVitals(value): any {
    if (!this.patient) {
      this.modalService.open(this.alertBox);
      this.alert_message = 'Select patient';
      return;
    }
    if (
      this.vitalsData.temperature === '' &&
      this.vitalsData.bp_systolic === '' &&
      this.vitalsData.bp_diastolic === '' &&
      this.vitalsData.pulse === '' &&
      this.vitalsData.resp_rate === '' &&
      this.vitalsData.o2_saturation === '' &&
      this.vitalsData.height === '' &&
      this.vitalsData.weight === '' &&
      this.vitalsData.bsr === ''
    ) {
      toastr.error('Add at least 1 vital');
      return;
    }
    if (this.vitalsValidation.actionOnVitals(this.vitalsData) === false) {
      return;
    }
    this.disableAddVitalsBtn = true;
    this.ngxLoader.start();
    this.opdService.addVitalsFunV2(this.vitalsData, this.patient).subscribe(
      (data) => {
        this.vitalsData = {
          weight: '',
          height: '',
          temperature: '',
          bp_systolic: '',
          bp_diastolic: '',
          pulse: '',
          resp_rate: '',
          o2_saturation: '',
          bmi: '',
          bsr: '',
        };
        this.isVitalsUpdate = Math.random();
        this.isVitalsRecieved.emit(this.isVitalsUpdate.toString());
        if (value === 'submit') {
          this.getVitals('');
        }
        if (value === 'next') {
          $('.navi .navi-item .navi-link').removeClass('active');
          $('.navi .navi-item .navi-link').removeClass('active');
          const element: HTMLElement = document.getElementById(
            'HistoryTabs',
          ) as HTMLElement;
          element.click();

          // $('#HistoryTabs').trigger('click');
          // $('#HistoryTabs a').trigger('click');
        }
        this.ngxLoader.stop();
        this.disableAddVitalsBtn = false;
      },
      (err) => {
        this.modalService.open(this.alertBox);
        this.alert_message = err.error.message[0];
      },
    );
  }

  getVitals(value): any {
    if (!this.patient) {
      return;
    }
    this.vitalsData = {
      weight: '',
      height: '',
      temperature: '',
      bp_systolic: '',
      bp_diastolic: '',
      pulse: '',
      resp_rate: '',
      o2_saturation: '',
      bmi: '',
      bsr: '',
    };
    this.vitalsListData = [];
    this.vitalsListDataAppend = [];
    console.log('this.visitType', this.visitType);
    if (this.visitType === 'both') {
      console.log('this.visitType', this.visitType);
      this.visitType = '';
    }
    console.log('this.visitType', this.visitType);

    this.opdService
      .getVitalsFunV2(this.patient, this.patientAllHistory, this.visitType)
      .subscribe(
        (data) => {
          this.vitalsListDataAppend = data.vitals;
          for (let i = 0; i < this.vitalsListDataAppend.length; i++) {
            const bmi = this.calculateBMI(
              this.vitalsListDataAppend[i].weight,
              this.vitalsListDataAppend[i].height,
            );
            this.vitalsListDataAppend[i].bmi = bmi;
          }
          this.vitalsListData = this.vitalsListDataAppend;
          //
          this.chRef.markForCheck();
          // this.ngAfterViewInit();

          setTimeout(() => {
            if (this.vitalsListData) {
              this.getTemperatureChart(this.vitalsListData);
              this.getRespirationChart(this.vitalsListData);
              this.getBPChart(this.vitalsListData);
              this.getPulseChart(this.vitalsListData);
            }
          }, 1000);
        },
        (err) => {},
      );
  }

  calculateBMI(weight, height): any {
    if (height && weight) {
      // var finalHeight = height * .025;
      const finalHeight = height * 0.01;
      const BMI = weight / Math.pow(finalHeight, 2);
      return BMI.toFixed(0);
    } else {
      return;
    }
  }
  isNumberKey(e): any {
    const charCode = e.which ? e.which : e.keyCode;
    if (e.target.value.includes('.')) {
      if (e.key === '.') {
        return false;
      }
    }
    if (
      charCode !== 190 &&
      charCode !== 96 &&
      charCode !== 97 &&
      charCode !== 98 &&
      charCode !== 99 &&
      charCode !== 100 &&
      charCode !== 101 &&
      charCode !== 102 &&
      charCode !== 103 &&
      charCode !== 104 &&
      charCode !== 105 &&
      charCode !== 46 &&
      charCode !== 110 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    ) {
      return false;
    }
    return true;
  }
  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  bmiCalculate(): any {
    if (this.vitalsData.weight === '' || this.vitalsData.height === '') {
      this.vitalsData.bmi = '';
    }
    if (this.vitalsData.weight && this.vitalsData.height) {
      this.vitalsData.bmi = '';
      // var finalHeight = this.vitalsData.height * .025;
      const finalHeight = this.vitalsData.height * 0.01;
      const BMI = this.vitalsData.weight / Math.pow(finalHeight, 2);
      this.vitalsData.bmi = BMI.toFixed(0);
      // const convertMeter = this.vitalsData.height / 100;
      // const meterSqr = convertMeter * convertMeter;
      // const abc = this.vitalsData.weight / meterSqr;
      // this.vitalsData.bmi = abc;
    }
  }
  getLastTwentyVitals(vitals): any {
    if (vitals.length > 20) {
      vitals = vitals.slice(Math.max(vitals.length - 20, 1));
    }
    return vitals;
  }

  formatGraphDate(dates): any {
    const date = moment(dates).format('DD-MM-yyyy');
    return date;
  }

  getTemperatureChart(vitals): any {
    const control = this;
    vitals = this.getLastTwentyVitals(vitals);
    const canvas: any = document.getElementById('temperatureChart');
    const resultArray = [];
    const resultArrayDate = [];
    for (let s = 0; s < vitals.length; s++) {
      if (vitals[s].temperature) {
        resultArray.push(vitals[s].temperature);
        resultArrayDate.push(this.formatGraphDate(vitals[s].created_at));
      }
    }
    // const resultArray = vitals.map(function (a) { return a.temperature === null ? 0 : a.temperature; });
    // const resultArrayDate = vitals.map(function (a) { return control.formatGraphDate(a.created_at); });
    const ctx = canvas.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: resultArrayDate,
        datasets: [
          {
            data: resultArray,
            label: 'Temperature',
            borderColor: '#3cba9f',
            backgroundColor: '#3cba9f',
            fill: 'boundary',
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            display: false,
          },
        },
        title: {
          display: true,
          text: '',
        },
        legend: {
          display: true,
          position: 'bottom',
          // itemMaxWidth: 20,
          labels: {
            // boxWidth: 10
            // itemMaxWidth: 20
            // fontColor: '#333',
            usePointStyle: true,
            // fill:true,
            // backgroundColor: 'transparent',
            // borderWidth: 1
            // fillColor:true
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                userCallback(label, index, labels) {
                  // only show if whole number
                  if (Math.floor(label) === label) {
                    return label;
                  }
                },
              },
            },
          ],
        },
      },
    });
  }

  getRespirationChart(vitals): any {
    const control = this;
    vitals = this.getLastTwentyVitals(vitals);
    const canvas: any = document.getElementById('respirationChart');
    const resultArray = [];
    const resultArrayDate = [];
    for (let s = 0; s < vitals.length; s++) {
      if (vitals[s].resp_rate) {
        resultArray.push(vitals[s].resp_rate);
        resultArrayDate.push(this.formatGraphDate(vitals[s].created_at));
      }
    }
    // const resultArray = vitals.map(function (a) { return a.resp_rate === null ? 0 : a.resp_rate; });
    // const resultArrayDate = vitals.map(function (a) { return control.formatGraphDate(a.created_at); });
    const ctx = canvas.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: resultArrayDate,
        datasets: [
          {
            data: resultArray,
            label: 'Respiration',
            borderColor: '#3cba9f',
            backgroundColor: '#3cba9f',
            fill: 'boundary',
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            display: false,
          },
        },
        title: {
          display: true,
          text: '',
        },
        legend: {
          display: true,
          position: 'bottom',
          // itemMaxWidth: 20,
          labels: {
            // boxWidth: 10
            // itemMaxWidth: 20
            // fontColor: '#333',
            usePointStyle: true,
            // fill:true,
            // backgroundColor: 'transparent',
            // borderWidth: 1
            // fillColor:true
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                userCallback(label, index, labels) {
                  // only show if whole number
                  if (Math.floor(label) === label) {
                    return label;
                  }
                },
              },
            },
          ],
        },
      },
    });
  }

  getBPChart(vitals): any {
    const control = this;
    vitals = this.getLastTwentyVitals(vitals);
    const canvas: any = document.getElementById('bPChart');
    const systolicResultArray = [];
    const diastolicResultArray = [];
    const resultArrayDate = [];
    for (let s = 0; s < vitals.length; s++) {
      if (vitals[s].bp_systolic || vitals[s].bp_diastolic) {
        systolicResultArray.push(vitals[s].bp_systolic);
        diastolicResultArray.push(vitals[s].bp_diastolic);
        resultArrayDate.push(this.formatGraphDate(vitals[s].created_at));
      }
    }
    // const systolicResultArray = vitals.map(function (a) { return a.bp_systolic === null ? 0 : a.bp_systolic; });
    // const diastolicResultArray = vitals.map(function (a) { return a.bp_diastolic === null ? 0 : a.bp_diastolic; });
    // const resultArrayDate = vitals.map(function (a) { return control.formatGraphDate(a.created_at); });
    const ctx = canvas.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: resultArrayDate,
        datasets: [
          {
            data: systolicResultArray,
            label: 'BP Systolic',
            borderColor: '#3cba9f',
            backgroundColor: '#3cba9f',
            fill: 'boundary',
          },
          {
            data: diastolicResultArray,
            label: 'BP Diastolic',
            borderColor: 'red',
            backgroundColor: 'red',
            fill: 'boundary',
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            display: false,
          },
        },
        title: {
          display: true,
          text: '',
        },
        legend: {
          display: true,
          position: 'bottom',
          // itemMaxWidth: 20,
          labels: {
            // boxWidth: 10
            // itemMaxWidth: 20
            // fontColor: '#333',
            usePointStyle: true,
            // fill:true,
            // backgroundColor: 'transparent',
            // borderWidth: 1
            // fillColor:true
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                userCallback(label, index, labels) {
                  // only show if whole number
                  if (Math.floor(label) === label) {
                    return label;
                  }
                },
              },
            },
          ],
        },
      },
    });
  }

  getPulseChart(vitals): any {
    const control = this;
    vitals = this.getLastTwentyVitals(vitals);
    const canvas: any = document.getElementById('pulseChart');
    const resultArray = [];
    const resultArrayDate = [];
    for (let s = 0; s < vitals.length; s++) {
      if (vitals[s].pulse) {
        resultArray.push(vitals[s].pulse);
        resultArrayDate.push(this.formatGraphDate(vitals[s].created_at));
      }
    }
    // const resultArray = vitals.map(function (a) { return a.pulse === null ? 0 : a.pulse; });
    // const resultArrayDate = vitals.map(function (a) { return control.formatGraphDate(a.created_at); });
    const ctx = canvas.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: resultArrayDate,
        datasets: [
          {
            data: resultArray,
            label: 'Pulse',
            borderColor: '#3cba9f',
            backgroundColor: '#3cba9f',
            fill: 'boundary',
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            display: false,
          },
        },
        title: {
          display: true,
          text: '',
        },
        legend: {
          display: true,
          position: 'bottom',
          // itemMaxWidth: 20,
          labels: {
            // boxWidth: 10
            // itemMaxWidth: 20
            // fontColor: '#333',
            usePointStyle: true,
            // fill:true,
            // backgroundColor: 'transparent',
            // borderWidth: 1
            // fillColor:true
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                userCallback(label, index, labels) {
                  // only show if whole number
                  if (Math.floor(label) === label) {
                    return label;
                  }
                },
              },
            },
          ],
        },
      },
    });
  }

  printVitals(value): any {
    window.open(
      'pdf-reports/vitals-patient-history?id=' +
        this.patient.id +
        '&visit_id=' +
        this.patient.visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&history=' +
        value +
        '&invoice_id=',
    );
    // window.open(environment.rorUrl + '/v4/patients/patient_vitals.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&patient_mrn=' + this.patient.mrn + '&history=' + value);
  }
}
