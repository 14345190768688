<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <div class="container-fluid">
    <!--begin::Subheader-->
    <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1 w-100">
          <div
            class="d-flex align-items-baseline flex-wrap mr-5 w-100 justify-content-between"
          >
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">
              {{ screenName }}
            </h5>
            <div>
              <button
                class="btn btn-light-primary btn-sm ml-2"
                (click)="openModal('')"
              >
                Add New
              </button>
            </div>
            <!--end::Breadcrumb-->
          </div>
        </div>
        <!--end::Info-->
      </div>
    </div>
    <div class="card card-custom mb-5">
      <div class="card-body p-5">
        <div class="row">
          <!--Department-->
          <div class="col-2">
            <div class="d-block form-group">
              <label>Employees Department:</label>
              <select
                class="form-control form-control-sm selectpicker"
                multiple
                data-live-search="true"
                data-container="body"
                id="department"
                name="department"
                (change)="getEmployeesOfDepartment()"
                [(ngModel)]="department"
              >
                <option value="" [disabled]="true">Select Department</option>
                <ng-container *ngFor="let dep of allDepartments">
                  <option [value]="dep.id">{{ dep.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <!--Employee Of Department-->
          <div class="col-2">
            <div class="d-block form-group">
              <label>Employees:</label>
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                data-container="body"
                multiple
                id="employeeDepartmentList"
                name="department"
                [(ngModel)]="employees"
              >
                <option value="" [disabled]="true">Select Employee</option>
                <ng-container *ngFor="let emp of employeesOfDepart">
                  <option [value]="emp.id">
                    {{ emp?.employee_file_no }} - {{ emp?.first_name }}
                    {{ emp?.middle_name || "" }} {{ emp?.last_name }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col-2 form-group">
            <label>Select Week</label>
            <select
              class="form-control form-control-sm employee_category"
              name="employee_category"
              [(ngModel)]="weekDuration"
              (change)="selectWeekDuration()"
            >
              <option [value]="'current'">Current Week</option>
              <option [value]="'next'">Next Week</option>
              <option [value]="'previous'">Previous Week</option>
              <option [value]="'custom'">Custom</option>
            </select>
          </div>
          <ng-container *ngIf="weekDuration === 'custom'">
            <div class="col-2 form-group">
              <label>From Date</label>
              <input
                type="date"
                class="form-control form-control-sm"
                id="fromDate"
                [(ngModel)]="fromDate"
              />
            </div>
            <div class="col-2 form-group">
              <label>To Date</label>
              <input
                type="date"
                class="form-control form-control-sm"
                id="toDate"
                [(ngModel)]="toDate"
              />
            </div>
          </ng-container>
          <div class="col-2 form-group">
            <button
              class="btn btn-sm btn-primary btn-hover-primary"
              (click)="getEmployeeAllTasks()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div *ngIf="allTasks.length > 0">
              <div style="overflow-x: auto; white-space: nowrap;">
                <table class="table table-head-custom table-vertical-center" style="width: 100%">
                  <thead>
                  <tr>
                    <th style="text-align: center; width: 15%">
                      <span>Team</span>
                    </th>
                    <th
                      style="text-align: center; width: 17%"
                      *ngFor="let data of allTasks[0]?.all_task"
                    >
                      <span>{{ data?.date | date: "EEE dd, MMM" }}</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let data of allTasks">
                    <tr>
                      <td style="padding-right: 0; padding-left: 0">
                        <div class="d-flex" style="min-width: 15rem">
                          <div>
                            <img
                              *ngIf="
                                data.employee_image === '' ||
                                data.employee_image === null
                              "
                              src="../../../assets/media/users/default.jpg"
                              style="
                                object-fit: cover;
                                margin: 0 !important;
                                padding: 5px;
                                max-width: 70%;
                                height: 100%;
                                border-radius: 10px;
                                height: 100%;
                              "
                            />
                            <img
                              *ngIf="
                                data?.employee_image !== '' &&
                                data?.employee_image !== null
                              "
                              src="{{ data?.employee_image }}"
                              style="
                                object-fit: cover;
                                margin: 0 !important;
                                padding: 5px;
                                max-width: 70%;
                                height: 100%;
                                border-radius: 10px;
                                height: 100%;
                              "
                            />
                          </div>
                          <div style="align-self: center">
                            <span
                              class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            >{{ data?.name }}</span
                            >
                            <p class="mt-0">{{ data?.designation }}</p>
                          </div>
                        </div>
                      </td>
                      <td *ngFor="let allTasks of data.all_task">
                        <div
                          class="align-items-center"
                          style="
                            border: solid 1px #ddd;
                            border-radius: 8px;
                            text-align: center;
                            min-height: 8rem;
                            min-width: 13rem;
                          "
                          (drop)="onDrop($event)"
                          (dragover)="allowDrop($event)"
                        >
                          <ng-container
                            *ngFor="let task of allTasks.tasks; let i = index"
                          >
                            <span
                              draggable="true"
                              (dragstart)="onDragStart($event, i)"
                              [ngStyle]="{
                                'background-color': task?.status_color
                              }"
                              ngbTooltip="{{task.name}}"
                              class="d-inline-block label label-lg label-inline font-weight-bold m-1"
                              style="
                                cursor: pointer;
                                font-size: 12px;
                                padding-top: 3px;
                                color: #fff;
                              "
                              (click)="openModal(task?.task_id)"
                            >{{ task.task_id }}</span
                            >
                          </ng-container>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div>
                            <span>WH: 08</span>
                          </div>
                          <div>
                            <span
                            >Task Time: {{ allTasks?.allocated_time }}</span
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--open Modal-->
<div
  class="modal fade main-modal"
  id="viewTaskModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
  [ngClass]="{ 'scroll-active': isMainModalOpen }"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="!taskId" class="modal-title">Add Task</h5>
        <h5 *ngIf="taskId" class="modal-title">Update Task</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeAddTaskModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ng-container  *ngIf="openAddTaskModal">
      <div class="modal-body">
        <app-view-task
          (newStatusEvent)="addTask($event)"
          [taskId]="taskId"
        ></app-view-task>
      </div>
      </ng-container>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
