<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>

          <div class="d-flex row ml-6"></div>

          <!--          tab headings-->

          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Actions-->
        <button
          class="btn btn-primary btn-sm"
          type="button"
          (click)="openPatientList()"
        >
          Patients
        </button>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="row">
        <div class="col-md-10 col-sm-12 col-12 pr-0">
          <div class="card card-custom gutter-b">
            <div class="card-body">
              <!--begin::Top-->
              <div class="d-flex" *ngIf="patient">
                <!--begin::Pic-->
                <div class="flex-shrink-0 mr-7">
                  <div class="symbol symbol-50 symbol-lg-120">
                    <img
                      *ngIf="patient?.gender === 'transgender'"
                      src="assets/media/users/blank.png"
                      class="h-100 align-self-end"
                      alt=""
                    />
                    <img
                      *ngIf="patient?.gender === 'unidentified'"
                      src="assets/media/users/blank.png"
                      class="h-100 align-self-end"
                      alt=""
                    />
                    <img
                      *ngIf="patient?.gender === 'male'"
                      src="assets/media/svg/avatars/009-boy-4.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                    <img
                      *ngIf="patient?.gender === 'female'"
                      src="assets/media/svg/avatars/002-girl.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </div>
                </div>
                <!--end::Pic-->
                <!--begin: Info-->
                <div class="flex-grow-1">
                  <!--begin::Title-->
                  <div
                    class="d-flex align-items-center justify-content-between flex-wrap mt-2"
                  >
                    <!--begin::User-->
                    <div class="mr-3">
                      <!--begin::Name-->
                      <a
                        class="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3"
                        >{{ patient?.title || "" | titlecase }}
                        {{
                          patient?.first_name + " " + patient?.last_name
                            | titlecase
                        }}</a
                      >
                      <!--end::Name-->
                      <!--begin::Contacts-->
                      <div class="d-flex flex-wrap my-2">
                        <a
                          class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >{{ patient?.mrn }}</a
                        >
                        <a
                          class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >{{ patient?.age }}</a
                        >
                        <a
                          class="text-muted text-hover-primary font-weight-bold"
                          >{{ patient?.gender | titlecase }}</a
                        >
                      </div>
                      <div class="d-flex flex-wrap my-2">
                        <a
                          class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >{{ patient?.patient_type?.name | titlecase }}</a
                        >
                        <a
                          class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <span *ngIf="patient?.admissions.length > 0"
                            >{{
                              " " +
                                patient.admissions[
                                  patient.admissions.length - 1
                                ].ward.title
                            }}
                            ({{ patient?.bed_name | titlecase }})
                          </span>
                        </a>
                      </div>
                      <div class="d-flex flex-wrap my-2">
                        <a
                          class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >{{ patient?.phone1 }}</a
                        >
                      </div>
                      <!--end::Contacts-->
                    </div>
                    <!--begin::User-->
                    <!--begin::Actions-->
                    <div class="my-lg-0 my-1">
                      <!--                      <a  class="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2">Ask</a>-->
                      <!--                      <a  class="btn btn-sm btn-primary font-weight-bolder text-uppercase">Hire</a>-->
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Title-->
                </div>
                <!--end::Info-->
              </div>
              <!--end::Top-->
            </div>
          </div>

          <!--          transaction rows-->

          <div class="card">
            <div class="card-body custom-card-body">
              <div class="row">
                <div class="col-3">
                  <div class="form-group ml-2">
                    <select
                      class="form-control form-control-sm"
                      [(ngModel)]="filterType"
                      (change)="resetSelectBoxes()"
                    >
                      <!--                      <option value="updateDiscount">Apply Discount</option>-->
                      <option value="approvedDiscount">Approve Discount</option>
                      <option value="cancelledDiscount">Reject Discount</option>
                      <option value="approvedServices">
                        Approve Cancel Services Request
                      </option>
                      <option value="cancelledServices">
                        Reject Cancel Services Request
                      </option>
                      <option value="approvedAdvance">
                        Approve Advance Cancel Request
                      </option>
                      <option value="cancelAdvance">
                        Reject Advance Cancel Request
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="col-9 d-flex justify-content-end"
                  *ngIf="
                    filterType !== 'approvedAdvance' &&
                    filterType !== 'cancelAdvance'
                  "
                >
                  <span class="mt-4 mr-2">All Invoices</span>
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        name="select"
                        [(ngModel)]="isOpen"
                        (change)="updateInvoiceTypePatient()"
                      />
                      <span></span>
                    </label>
                  </span>
                  <span class="mt-4 ml-1">Open Invoices</span>
                </div>
              </div>
              <div class="row" *ngIf="patientServiceInvoices?.length > 0">
                <div class="col-md-12 mt-0">
                  <div
                    class="transaction-table"
                    *ngIf="
                      filterType !== 'approvedAdvance' &&
                      filterType !== 'cancelAdvance'
                    "
                  >
                    <div
                      class=""
                      id="grandparentContemainRownt"
                      aria-expanded="true"
                    >
                      <table class="table">
                        <thead>
                          <tr>
                            <th style="width: 2%">
                              <label
                                class="checkbox checkbox-sm"
                                *ngIf="
                                  filterType === 'approvedDiscount' ||
                                  filterType === 'cancelledDiscount'
                                "
                              >
                                <input
                                  class=""
                                  type="checkbox"
                                  (change)="
                                    selectToggleAllInvoices(
                                      $event.target.checked
                                    )
                                  "
                                />
                                <span></span>
                              </label>
                            </th>
                            <th style="width: 20%">
                              <span
                                *ngIf="
                                  filterType === 'approvedDiscount' ||
                                  filterType === 'cancelledDiscount'
                                "
                                >Select All</span
                              >
                            </th>
                            <th style="width: 20%"></th>
                            <th style="width: 20%"></th>
                            <th style="width: 20%"></th>
                            <th style="width: 8%"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <!--            collapsed-->
                          <ng-container
                            *ngFor="
                              let invoice of patientServiceInvoices;
                              let i = index
                            "
                          >
                            <!--                          <ng-container *ngIf=" !(( filterType === 'approvedServices' || filterType === 'cancelledServices' ) && invoice.cancelled_request === false)">-->
                            <ng-container
                              *ngIf="
                                ((filterType === 'approvedDiscount' ||
                                  filterType === 'cancelledDiscount') &&
                                  invoice.discount_requested === true) ||
                                ((filterType === 'approvedServices' ||
                                  filterType === 'cancelledServices') &&
                                  invoice.cancelled_request === true)
                              "
                            >
                              <tr class="pointer mainRow collapsed">
                                <td>
                                  <label
                                    class="checkbox checkbox-sm"
                                    *ngIf="filterType === 'updateDiscount'"
                                  >
                                    <input
                                      class=""
                                      type="checkbox"
                                      [(ngModel)]="patientDiscount[i].selected"
                                      (change)="updateSelectBoxOfInvoice(i)"
                                    />
                                    <span></span>
                                  </label>
                                  <label
                                    class="checkbox checkbox-sm"
                                    *ngIf="
                                      filterType === 'approvedDiscount' ||
                                      filterType === 'cancelledDiscount'
                                    "
                                  >
                                    <input
                                      class=""
                                      type="checkbox"
                                      [(ngModel)]="patientDiscount[i].selected"
                                      (change)="
                                        selectToggleAllInvoicesItem(
                                          $event.target.checked,
                                          i
                                        )
                                      "
                                    />
                                    <span></span>
                                  </label>
                                  <label
                                    class="checkbox checkbox-sm"
                                    *ngIf="
                                      filterType === 'approvedServices' ||
                                      filterType === 'cancelledServices'
                                    "
                                  >
                                    <input
                                      class=""
                                      type="checkbox"
                                      [(ngModel)]="patientDiscount[i].selected"
                                      (change)="
                                        selectToggleAllInvoicesItemForCancel(
                                          $event.target.checked,
                                          invoice.id
                                        )
                                      "
                                    />
                                    <span></span>
                                  </label>
                                </td>
                                <td
                                  collapsed
                                  data-toggle="collapse"
                                  [attr.data-target]="'.parentContent' + i"
                                >
                                  <span
                                    class="text-dark-75 font-weight-bolder mb-1 font-size-lg cursor-pointer"
                                    >{{
                                      invoice.created_at
                                        | date: "dd-MM-yyyy h:mm a"
                                    }}</span
                                  >
                                  <span
                                    class="text-muted font-weight-bold mb-1 text-muted d-block"
                                    >Invoice # {{ invoice.id }}</span
                                  >
                                  <span
                                    class="text-muted font-weight-bold text-muted d-block"
                                    >Status {{ invoice.payment_status }}</span
                                  >
                                </td>
                                <td>
                                  <span class="text-dark-75 mb-1"
                                    >Invoice Amount
                                    {{
                                      invoice.cash + invoice.receivable | number
                                    }}</span
                                  >
                                  <span
                                    class="text-muted font-weight-bold mb-1 text-muted d-block"
                                    >Cash {{ invoice.cash | number }}</span
                                  >
                                  <span
                                    class="text-muted font-weight-bold text-muted d-block"
                                    >Receivable {{ invoice.receivable }}</span
                                  >
                                </td>
                                <td>
                                  <div class="d-flex">
                                    <div class="w-50">Discount Type</div>
                                    <div class="w-50">
                                      <select
                                        class="form-control form-control-sm"
                                        [disabled]="
                                          patientDiscount[i].selected ===
                                            false ||
                                          filterType !== 'updateDiscount'
                                        "
                                        id="discountTypeInvoiceAll{{ i }}"
                                        (change)="
                                          updateDiscountTypeOfInvoice(
                                            $event.target.value,
                                            i
                                          )
                                        "
                                      >
                                        <option value="amount">Amount</option>
                                        <option value="percentage">
                                          Percentage
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="d-flex">
                                    <div class="w-50">Discount Value</div>
                                    <div class="w-50">
                                      <input
                                        type="number"
                                        class="form-control form-control-sm"
                                        [disabled]="
                                          patientDiscount[i].selected ===
                                            false ||
                                          filterType !== 'updateDiscount'
                                        "
                                        id="discountTypeValueInvoiceAll{{ i }}"
                                        (keyup)="
                                          updateDiscountValueOfInvoice(
                                            $event.target.value,
                                            i
                                          )
                                        "
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    (click)="printInvoice(invoice?.id)"
                                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                                  >
                                    <span
                                      class="svg-icon svg-icon-primary svg-icon-2x"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          stroke-width="1"
                                          fill="none"
                                          fill-rule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          />
                                          <path
                                            d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                          />
                                          <path
                                            d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                            fill="#000000"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="10"
                                            y="9"
                                            width="7"
                                            height="2"
                                            rx="1"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="7"
                                            y="9"
                                            width="2"
                                            height="2"
                                            rx="1"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="7"
                                            y="13"
                                            width="2"
                                            height="2"
                                            rx="1"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="10"
                                            y="13"
                                            width="7"
                                            height="2"
                                            rx="1"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="7"
                                            y="17"
                                            width="2"
                                            height="2"
                                            rx="1"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="10"
                                            y="17"
                                            width="7"
                                            height="2"
                                            rx="1"
                                          />
                                        </g>
                                      </svg>
                                      <!--end::Svg Icon-->
                                    </span>
                                  </a>
                                </td>
                              </tr>

                              <!-- show-->
                              <tr
                                class="collapse collapseDetail parentContent{{
                                  i
                                }} childRow"
                                *ngFor="
                                  let service of invoice?.patient_service_invoice_items;
                                  let j = index
                                "
                                [ngClass]="{
                                  cancelServices:
                                    service.cancelled_request === true
                                }"
                              >
                                <td>
                                  <label
                                    class="checkbox checkbox-sm"
                                    *ngIf="filterType === 'updateDiscount'"
                                  >
                                    <input
                                      class=""
                                      type="checkbox"
                                      [(ngModel)]="
                                        patientDiscount[i]
                                          .patient_service_invoice_items[j]
                                          .selected
                                      "
                                      (change)="
                                        updateSelectBoxOfInvoiceItems(i, j)
                                      "
                                      [disabled]="
                                        service.cancelled_request === true ||
                                        service.cancelled === true ||
                                        service.discount_approved_status ===
                                          'approved'
                                      "
                                    />
                                    <span></span>
                                  </label>
                                  <label
                                    class="checkbox checkbox-sm"
                                    *ngIf="
                                      (filterType === 'approvedDiscount' ||
                                        filterType === 'cancelledDiscount') &&
                                      service.discount_approved_status ===
                                        'requested'
                                    "
                                  >
                                    <input
                                      class=""
                                      type="checkbox"
                                      [(ngModel)]="
                                        patientDiscount[i]
                                          .patient_service_invoice_items[j]
                                          .selected
                                      "
                                      (change)="
                                        selectToggleInvoicesItem(
                                          $event.target.checked,
                                          i,
                                          j
                                        )
                                      "
                                    />
                                    <span></span>
                                  </label>
                                </td>
                                <td colspan="2">
                                  <span
                                    class="text-dark-75 mb-1 font-size-lg"
                                    >{{ service?.service?.name }}</span
                                  >
                                  <!--                              <span class="text-muted font-weight-bold text-muted d-block">Net Amount {{(service.base_price * service.quantity) - service.approved_discount | number}}</span>-->
                                  <span
                                    class="text-muted font-weight-bold text-muted d-block"
                                    >Net Amount
                                    {{
                                      patientDiscount[i]
                                        .patient_service_invoice_items[j]
                                        .invoice_amount | number
                                    }}</span
                                  >
                                  <span
                                    class="text-muted font-weight-bold text-muted d-block"
                                    *ngIf="
                                      patientDiscount[i]
                                        .patient_service_invoice_items[j]
                                        .manual_discount > 0
                                    "
                                  >
                                    New Amount
                                    {{
                                      patientDiscount[i]
                                        .patient_service_invoice_items[j]
                                        .type_amount
                                    }}
                                    Discount Rs.{{
                                      patientDiscount[i]
                                        .patient_service_invoice_items[j]
                                        .manual_discount | number: "1.1-2"
                                    }}
                                    <span
                                      *ngIf="
                                        patientDiscount[i]
                                          .patient_service_invoice_items[j]
                                          .unit_type === 'amount'
                                      "
                                    >
                                      /
                                      {{
                                        (patientDiscount[i]
                                          .patient_service_invoice_items[j]
                                          .manual_discount /
                                          (service.base_price *
                                            service.quantity -
                                            service.approved_discount)) *
                                          100 | number: "1.1-2"
                                      }}%
                                    </span>

                                    <span
                                      *ngIf="
                                        patientDiscount[i]
                                          .patient_service_invoice_items[j]
                                          .unit_type === 'percentage'
                                      "
                                    >
                                      /
                                      {{
                                        patientDiscount[i]
                                          .patient_service_invoice_items[j]
                                          .type_value
                                      }}%
                                    </span>
                                  </span>
                                  <span
                                    class="text-muted font-weight-bold text-muted d-block"
                                    *ngIf="
                                      patientServiceInvoices[i]
                                        .patient_service_invoice_items[j]
                                        .performing_doctor_name !== ''
                                    "
                                    >Performing Doctor:
                                    {{
                                      patientServiceInvoices[i]
                                        .patient_service_invoice_items[j]
                                        .performing_doctor_name
                                    }}</span
                                  >
                                </td>
                                <td>
                                  <div class="d-flex">
                                    <div class="w-50">Discount Type</div>
                                    <div class="w-50">
                                      <select
                                        class="form-control form-control-sm"
                                        [disabled]="
                                          patientDiscount[i]
                                            .patient_service_invoice_items[j]
                                            .selected === false ||
                                          service.cancelled_request === true ||
                                          service.cancelled === true ||
                                          service.discount_approved_status ===
                                            'approved' ||
                                          filterType !== 'updateDiscount'
                                        "
                                        (change)="updateDiscountValue(i, j)"
                                        [(ngModel)]="
                                          patientDiscount[i]
                                            .patient_service_invoice_items[j]
                                            .unit_type
                                        "
                                      >
                                        <option value="amount">Amount</option>
                                        <option value="percentage">
                                          Percentage
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div class="d-flex mt-3">
                                    <div class="w-50">Cash</div>
                                    <div class="w-50">
                                      <input
                                        type="number"
                                        [id]="'serviceDiscountOnCash' + i + j"
                                        [disabled]="
                                          patientDiscount[i]
                                            .patient_service_invoice_items[j]
                                            .selected === false ||
                                          service.cancelled_request === true ||
                                          service.cancelled === true ||
                                          service.discount_approved_status ===
                                            'approved' ||
                                          filterType !== 'updateDiscount'
                                        "
                                        class="form-control form-control-sm"
                                        (keyup)="updateDiscountOnCash(i, j)"
                                        [(ngModel)]="
                                          patientDiscount[i]
                                            .patient_service_invoice_items[j]
                                            .discount_on_cash
                                        "
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="d-flex">
                                    <div class="w-50">Discount Value</div>
                                    <div class="w-50">
                                      <input
                                        type="number"
                                        [id]="'serviceDiscount' + i + j"
                                        [disabled]="
                                          patientDiscount[i]
                                            .patient_service_invoice_items[j]
                                            .selected === false ||
                                          service.cancelled_request === true ||
                                          service.cancelled === true ||
                                          service.discount_approved_status ===
                                            'approved' ||
                                          filterType !== 'updateDiscount'
                                        "
                                        class="form-control form-control-sm"
                                        (keyup)="updateDiscountValue(i, j)"
                                        [(ngModel)]="
                                          patientDiscount[i]
                                            .patient_service_invoice_items[j]
                                            .type_value
                                        "
                                      />
                                    </div>
                                  </div>

                                  <div class="d-flex mt-3">
                                    <div class="w-50">Receivable</div>
                                    <div class="w-50">
                                      <input
                                        type="number"
                                        [id]="'serviceDiscountOnRece' + i + j"
                                        [disabled]="
                                          patientDiscount[i]
                                            .patient_service_invoice_items[j]
                                            .selected === false ||
                                          service.cancelled_request === true ||
                                          service.cancelled === true ||
                                          service.discount_approved_status ===
                                            'approved' ||
                                          filterType !== 'updateDiscount'
                                        "
                                        class="form-control form-control-sm"
                                        (keyup)="updateDiscountOnRece(i, j)"
                                        [(ngModel)]="
                                          patientDiscount[i]
                                            .patient_service_invoice_items[j]
                                            .discount_on_receivable
                                        "
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    (click)="
                                      openInvoiceItemDetails(i, j, service)
                                    "
                                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                                  >
                                    <span
                                      class="svg-icon svg-icon-primary svg-icon-2x"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          stroke-width="1"
                                          fill="none"
                                          fill-rule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          />
                                          <path
                                            d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                          />
                                          <path
                                            d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                            fill="#000000"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="10"
                                            y="9"
                                            width="7"
                                            height="2"
                                            rx="1"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="7"
                                            y="9"
                                            width="2"
                                            height="2"
                                            rx="1"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="7"
                                            y="13"
                                            width="2"
                                            height="2"
                                            rx="1"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="10"
                                            y="13"
                                            width="7"
                                            height="2"
                                            rx="1"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="7"
                                            y="17"
                                            width="2"
                                            height="2"
                                            rx="1"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="10"
                                            y="17"
                                            width="7"
                                            height="2"
                                            rx="1"
                                          />
                                        </g>
                                      </svg>
                                      <!--end::Svg Icon-->
                                    </span>
                                  </a>
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="patinetAdvancesList?.length > 0">
                <div class="col-md-12 mt-0">
                  <div
                    *ngIf="
                      filterType === 'approvedAdvance' ||
                      filterType === 'cancelAdvance'
                    "
                  >
                    <div class="table-responsive">
                      <table
                        class="table table-hover table-head-custom table-vertical-center"
                      >
                        <thead>
                          <tr>
                            <th style="width: 5px"></th>
                            <th style="width: 25%">Invoice Date</th>
                            <th style="width: 20%">Payment Mode</th>
                            <th class="text-center" style="width: 15%">
                              Amount
                            </th>
                            <th style="width: 30%">Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container
                            *ngFor="let data of patinetAdvancesList"
                          >
                            <tr *ngIf="data.cancelled_request === true">
                              <td>
                                <input
                                  type="checkbox"
                                  class="cancelCheckbox"
                                  (change)="toggleCheckBoxAdvance(data)"
                                />
                              </td>
                              <td>
                                <span
                                  class="text-dark-75 font-weight-bolder mb-1 font-size-lg cursor-pointer"
                                  >{{
                                    data.created_at | date: "dd-MM-yyyy h:mm a"
                                  }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="text-dark-75 font-weight-bolder mb-1 font-size-lg cursor-pointer"
                                  >{{ data.mode_of_payment }}</span
                                >
                                <span
                                  class="text-muted font-weight-bold mb-1 text-muted d-block"
                                  >{{ data.payment_details }}</span
                                >
                              </td>
                              <td class="text-center">{{ data.amount }}</td>
                              <td class="">
                                <span
                                  class="d-flex"
                                  *ngIf="data.cancelled_request === true"
                                  ><b>Reason: </b>
                                  <span class="ml-2">{{
                                    data?.advance_cancel_reason?.name
                                  }}</span></span
                                >
                                <span
                                  class="d-flex"
                                  *ngIf="data.cancelled_request === true"
                                  ><b>Comment: </b>
                                  <span class="ml-2">{{
                                    data?.advance_cancel_comments
                                  }}</span></span
                                >
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="card">
            <div class="card-body custom-card-body min-h-400px">
              <div class="row" *ngIf="patientServiceInvoices?.length > 0">
                <div
                  class="col-12"
                  *ngIf="
                    filterType === 'updateDiscount' ||
                    filterType === 'cancelledDiscount' ||
                    filterType === 'approvedDiscount'
                  "
                >
                  <div class="form-group">
                    <label
                      >Total Amount:
                      <span class="font-weight-bold">{{
                        totalDiscountedInvoiceAmount
                      }}</span></label
                    >
                  </div>
                </div>
                <div class="col-12" *ngIf="filterType === 'updateDiscount'">
                  <div class="form-group">
                    <label class="mb-0">Discount Type</label>
                    <select
                      class="form-control form-control-sm"
                      id="discountTypeInvoiceAll"
                      [(ngModel)]="discountTypeInvoiceAll"
                      (change)="updateAllDiscountTypeOfInvoice()"
                    >
                      <option value="amount">Amount</option>
                      <option value="percentage">Percentage</option>
                    </select>
                  </div>
                </div>

                <div class="col-12" *ngIf="filterType === 'updateDiscount'">
                  <div class="form-group">
                    <label class="mb-0">Discount Value</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="discountTypeValueInvoiceAll"
                      [(ngModel)]="discountTypeValueInvoiceAll"
                      (keyup)="updateAllDiscountValueOfInvoice()"
                    />
                  </div>
                </div>
                <div
                  class="col-12"
                  *ngIf="
                    filterType === 'updateDiscount' ||
                    filterType === 'cancelledDiscount' ||
                    filterType === 'approvedDiscount'
                  "
                >
                  <div class="form-group">
                    <label
                      >Discount:
                      <span class="font-weight-bold">{{
                        totalDiscountedAmount
                      }}</span>
                    </label>
                  </div>
                </div>

                <div class="col-12" *ngIf="filterType === 'updateDiscount'">
                  <div class="form-group">
                    <label class="mb-0">Reason</label>
                    <select
                      id="serviceDisReasonsId"
                      class="form-control form-control-sm"
                      [(ngModel)]="discount_reason_id"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let reason of discountReasonList"
                        [value]="reason.id"
                      >
                        {{ reason.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12" *ngIf="filterType === 'updateDiscount'">
                  <div class="form-group">
                    <label class="mb-0">Comments</label>
                    <input
                      class="form-control form-control-sm"
                      id="serviceDisComments"
                      [(ngModel)]="discount_comments"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <div class="text-right">
                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        *ngIf="filterType === 'updateDiscount'"
                        (click)="updateDiscount()"
                      >
                        Add Discount
                      </button>
                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        *ngIf="filterType === 'approvedDiscount'"
                        (click)="approvedOrRejectDiscount()"
                      >
                        Approved Discount
                      </button>
                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        *ngIf="filterType === 'cancelledDiscount'"
                        (click)="approvedOrRejectDiscount()"
                      >
                        Reject Discount
                      </button>

                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        *ngIf="filterType === 'approvedServices'"
                        (click)="approvedOrRejectService()"
                      >
                        Approved Cancel Service
                      </button>
                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        *ngIf="filterType === 'cancelledServices'"
                        (click)="approvedOrRejectService()"
                      >
                        Reject Cancel Service
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="patinetAdvancesList?.length > 0">
                <div class="col-12">
                  <button
                    class="btn btn-primary btn-sm"
                    type="button"
                    *ngIf="filterType === 'approvedAdvance'"
                    (click)="approvedOrRejectAdvance()"
                  >
                    Approved Advance Cancel Request
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    type="button"
                    *ngIf="filterType === 'cancelAdvance'"
                    (click)="approvedOrRejectAdvance()"
                  >
                    Reject Advance Cancel Request
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="text-right"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<!--begin::Patient Panel-->
<div id="patient-list" class="offcanvas offcanvas-right p-5">
  <!--begin::Header-->
  <div
    class="offcanvas-header d-flex align-items-center justify-content-between pb-7"
  >
    <h4 class="font-weight-bold m-0">Patient List</h4>
    <button
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      (click)="closePatientList()"
    >
      <i class="ki ki-close icon-xs text-muted"></i>
    </button>
  </div>
  <!--end::Header-->
  <!--begin::Content-->
  <div class="offcanvas-content">
    <!--begin::Wrapper-->
    <div class="offcanvas-wrapper mb-5 scroll-pull scroll ps ps--active-y">
      <form [formGroup]="PatientSearchForm" (ngSubmit)="fetchPatients()">
        <div class="row m-0">
          <div class="col-12 p-0 mb-2">
            <select
              class="form-control form-control-sm"
              formControlName="type"
              [(ngModel)]="PatientSearchForm.value.type"
            >
              <option value="default">Default</option>
              <option value="mrn">MRN</option>
              <option value="phone1">phone1</option>
              <option value="patient_nic">CNIC</option>
              <option value="name">Name</option>
            </select>
          </div>
          <div class="col-12 p-0 button-wrapper">
            <input
              type="text"
              formControlName="search_query"
              placeholder="Search patient here"
              class="form-control form-control-sm"
            />
            <button type="submit" class="btn btn-primary btn-sm" value="Search">
              <span>Search</span>
            </button>
          </div>
        </div>
      </form>

      <br />

      <div class="patient-listing mb-5">
        <ng-container *ngFor="let pa of patient_admissions">
          <div class="d-flex py-3 patient-panel" (click)="selectPatient(pa)">
            <div class="symbol symbol-40 symbol-light-success mr-5">
              <span class="symbol-label">
                <img
                  *ngIf="pa?.gender === 'transgender'"
                  src="assets/media/users/blank.png"
                  class="h-100 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="pa?.gender === 'unidentified'"
                  src="assets/media/users/blank.png"
                  class="h-100 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="pa?.gender === 'male'"
                  src="assets/media/svg/avatars/009-boy-4.svg"
                  class="h-75 align-self-end"
                  alt=""
                />
                <img
                  *ngIf="pa?.gender === 'female'"
                  src="assets/media/svg/avatars/002-girl.svg"
                  class="h-75 align-self-end"
                  alt=""
                />
              </span>
            </div>

            <div class="d-flex flex-column flex-grow-1 font-weight-bold">
              <!--              text-hover-primary-->
              <a class="text-dark mb-1 font-size-lg">{{
                pa?.first_name + " " + pa?.last_name
              }}</a>
              <div class="d-inline">
                <span class="text-muted mr-4">{{ pa?.mrn }}</span>
                <!--                        / {{patient?.gender | titlecase}}-->
                <!--                      <span class="text-muted mr-4">{{pa?.age}}</span>-->
              </div>
              <span class="text-muted mr-4">{{
                pa?.admissions[pa.admissions.length - 1]?.ward?.title
                  | titlecase
              }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!--end::Wrapper-->
    <!--begin::Purchase-->
    <div class="offcanvas-footer"></div>
    <!--end::Purchase-->
  </div>
  <!--end::Content-->
</div>
<!--end::Patient Panel-->

<div class="example-preview">
  <div>
    <ng-template #invoiceItemDetailsModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">{{ invoiceItemDetails.service.name }}</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-6 col-12">
              <span class="text-dark-75 mb-1 font-size-lg d-block"
                >Base Price:
                {{
                  invoiceItemDetails.base_price * invoiceItemDetails.quantity
                }}
                &nbsp;&nbsp; Qty: {{ invoiceItemDetails.quantity }}</span
              >
              <span class="text-dark-75 mb-1 font-size-lg d-block"
                >Sys. Discount:
                {{
                  invoiceItemDetails.discount * invoiceItemDetails.quantity
                }}</span
              >
              <span class="text-dark-75 mb-1 font-size-lg d-block"
                >Invoice Amount:
                {{
                  invoiceItemDetails.base_price * invoiceItemDetails.quantity -
                    invoiceItemDetails.approved_discount
                }}</span
              >
              <span class="text-dark-75 mb-1 font-size-lg d-block"
                >Manually Discount:
                {{ invoiceItemDetails.approved_manual_discount }}</span
              >
              <span class="text-dark-75 mb-1 font-size-lg d-block"
                >Net Amount:
                {{
                  invoiceItemDetails.base_price * invoiceItemDetails.quantity -
                    invoiceItemDetails.approved_discount
                }}</span
              >
            </div>
            <div class="col-sm-6 col-12">
              <span class="text-dark-75 mb-1 font-size-lg d-block"
                >Discount Status:
                {{
                  invoiceItemDetails.discount_approved_status | titlecase
                }}</span
              >
              <span class="text-dark-75 mb-1 font-size-lg d-block"
                >Discount Reasons:
                {{ invoiceItemDetails.dicount_reason_name | titlecase }}</span
              >
              <span class="text-dark-75 mb-1 font-size-lg d-block"
                >Discount Comments:
                {{ invoiceItemDetails.discount_comments | titlecase }}</span
              >
              <span class="text-dark-75 mb-1 font-size-lg d-block"
                >Cancel Status:
                <span
                  *ngIf="
                    invoiceItemDetails.cancelled_request === false &&
                    invoiceItemDetails.cancelled === true
                  "
                  class="fontSize"
                  >Cancel</span
                >
                <span
                  *ngIf="
                    invoiceItemDetails.cancelled_request === false &&
                    invoiceItemDetails.cancelled === false
                  "
                  class="fontSize"
                  >Unapplied</span
                >
                <span
                  *ngIf="
                    invoiceItemDetails.cancelled_request === true &&
                    invoiceItemDetails.cancelled === false
                  "
                  class="fontSize"
                  >Applied</span
                >
              </span>
              <span class="text-dark-75 mb-1 font-size-lg d-block"
                >Cancel Reasons:
                {{
                  invoiceItemDetails.service_cancel_reason_name | titlecase
                }}</span
              >
              <span class="text-dark-75 mb-1 font-size-lg d-block"
                >Cancel Comments:
                {{
                  invoiceItemDetails.service_cancel_comments | titlecase
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button type="submit" class="btn btn-sm btn-primary">Print</button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Confirm Confirm Modal-->
<div class="example-preview">
  <div>
    <ng-template #updateConfirm let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" />
            Are you sure you want to apply the discount!<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="addDiscount()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #updateDiscountStatusConfirm let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="filterType === 'approvedDiscount'">
            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" />Are
            you sure you want to approved this discount!<br />
          </div>
          <div *ngIf="filterType === 'cancelledDiscount'">
            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" />Are
            you sure you want to cancelled this discount!<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="approvedServices()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #updateCancelStatusConfirm let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="filterType === 'approvedServices'">
            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" />Are
            you sure you want to approved this cancelation request!<br />
          </div>
          <div *ngIf="filterType === 'cancelledServices'">
            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" />Are
            you sure you want to reject this cancelation request!<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="approvedServicesCancel()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template
      #updateAdvanceCancelStatusConfirm
      let-c="close"
      let-d="dismiss"
    >
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div  *ngIf="filterType === 'approvedAdvance'">
            <div class="row">

              <div class="col-6 mt-3">
                <div class="form-group">
                  <label
                  >Book of Account <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control form-control-sm"
                    (change)="getBatches($event.target.value)"
                    [(ngModel)]="book_of_account"
                  >
                    <option value="">Select</option>
                    <option
                      *ngFor="let data of bookAccounts"
                      [value]="data?.id"
                    >
                      {{ data?.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-6 mt-3">
                <div class="form-group">
                  <label>Batch <span class="text-danger">*</span></label>
                  <select
                    class="form-control form-control-sm"
                    [(ngModel)]="batch"
                  >
                    <option value="">Select</option>
                    <option
                      *ngFor="let data of batchList"
                      [value]="data?.id"
                    >
                      {{ data?.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="filterType === 'approvedAdvance'">
            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" />Are
            you sure you want to Approved Advance cancel request!<br />
          </div>
          <div *ngIf="filterType === 'cancelAdvance'">
            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" />Are
            you sure you want to Reject Advance cancel request!<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="approvedAdvanceCancel()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
