import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DepartmentsbyhospitalService } from '../../../../services/departmentsbyhospital.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../../environments/environment';
import { RadiologyService } from '../../../../services/radiology.service';
import * as moment from 'moment';
import { ConfigurationService } from '../../../../services/configuration.service';
import { ShareDataService } from '../../../../services/share-data.service';
import { SystemControlsService } from '../../../../services/system-controls.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-reports-reprint-v2',
  templateUrl: './reports-reprint-v2.component.html',
  styleUrls: ['./reports-reprint-v2.component.css'],
})
export class ReportsReprintV2Component implements OnInit {
  current_environment: string;
  labServicesForm: UntypedFormGroup;
  page = 1;
  totalPages: any;
  perPage = 25;
  departments = [];
  subDepartments = [];
  pageDataList = [];
  filterType = 'All';
  radiologyPacsUrls: any;
  currentPacUrl: any;
  currentControls: any;
  labId: any;
  visitId: any;
  patMrn: any;
  showHeader = true;
  showFooter = true;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private departmentsbyhospitalService: DepartmentsbyhospitalService,
    private ngxLoader: NgxUiLoaderService,
    private radiologyService: RadiologyService,
    private ref: ChangeDetectorRef,
    private configService: ConfigurationService,
    private shareDataService: ShareDataService,
    private systemControlService: SystemControlsService,
  ) {}

  ngOnInit(): void {
    this.current_environment = environment.rorUrl;
    this.labServicesForm = this.formBuilder.group({
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      patient_id: ['', Validators.required],
      department_id: [''],
      parent_department_id: [''],
      type: [''],
    });
    this.getDepartment();
    this.getRadiologyPACSUrls();
    setTimeout(() => {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      $('#fromDate').datetimepicker({
        defaultDate: yesterday,
        format: 'DD-MM-YYYY h:mm a',
        autoClose: true,
      });
      let previousDateTime = moment(yesterday).format('DD-MM-YYYY h:mm a');
      $('#fromDate').val(previousDateTime);
      $('#toDate').datetimepicker({
        defaultDate: new Date(),
        format: 'DD-MM-YYYY h:mm a',
      });
      let current_time = moment().format('DD-MM-YYYY h:mm a');
      $('#toDate').val(current_time);
      this.searchInvoices();
    }, 1000);
    // this.shareDataService.controlCheck.subscribe(data => {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
    });
  }
  getDepartment(): any {
    this.departments = [];
    this.subDepartments = [];
    this.departmentsbyhospitalService.indexV2().subscribe(
      (data) => {
        this.departments = data.departments;
      },
      (err) => {},
    );
  }
  getSubDepartment(value): any {
    this.subDepartments = [];
    this.labServicesForm.patchValue({
      department_id: '',
    });
    if (value !== '') {
      this.ngxLoader.start();
      this.departmentsbyhospitalService.parent_dept(value).subscribe(
        (data) => {
          this.subDepartments = data.departments;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
    }
  }

  searchInvoices(): any {
    this.labServicesForm.patchValue({
      from_date: $('#fromDate').val(),
      to_date: $('#toDate').val(),
      type: '',
    });
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }

  filtersInvoices(filter): any {
    this.labServicesForm.patchValue({
      from_date: $('#fromDate').val(),
      to_date: $('#toDate').val(),
      type: filter,
    });
    if (filter === '') {
      this.filterType = 'All';
    }
    if (filter === 'opd') {
      this.filterType = 'OPD';
    }
    if (filter === 'ipd') {
      this.filterType = 'IPD';
    }
    if (filter === 'urgent') {
      this.filterType = 'Urgent';
    }
    if (filter === 'returned') {
      this.filterType = 'Returned';
    }
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }
  searchFilter(): any {
    this.ngxLoader.start();
    this.radiologyService
      .fetchDeliveredReportsv2(
        this.page,
        this.perPage,
        this.labServicesForm.value,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          if (data.patient_services) {
            this.pageDataList = data.patient_services;
          } else {
            this.pageDataList = [];
          }
          this.ref.markForCheck();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.searchFilter();
  }
  loadPage(event): any {
    this.searchFilter();
  }
  printConfirmModal(id, visitId, mrn): any {
    this.labId = id;
    (this.visitId = visitId),
      (this.patMrn = mrn),
      $('#printConfirmation').modal('show');
    this.showHeader =
      this.currentControls.radiology_services.is_radiology_invoice_header;
    this.showFooter =
      this.currentControls.radiology_services.is_radiology_invoice_footer;
  }
  printReport(id, visitId, mrn): any {
    if (this.currentControls !== '') {
      if (
        this.currentControls.radiology_services.is_radiology_invoice === false
      ) {
        window.open(
          '/radiology/pdf-report-v2?rad_id=' +
            id +
            '&visit_id=' +
            visitId +
            '&mrn=' +
            mrn +
            '&show_header=' +
            this.showHeader +
            '&show_footer=' +
            this.showFooter,
        );
      } else {
        window.open(
          this.current_environment +
            '/v4/reports/print_report.pdf?auth_token=' +
            localStorage.getItem('auth_token') +
            '&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id') +
            '&id=' +
            id +
            '&show_header=' +
            this.showHeader +
            '&show_footer=' +
            this.showFooter,
        );
      }
    } else {
      window.open(
        this.current_environment +
          '/v4/reports/print_report.pdf?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id +
          '&show_header=' +
          this.showHeader +
          '&show_footer=' +
          this.showFooter,
      );
    }
    $('#printConfirmation').modal('hide');
  }

  getRadiologyPACSUrls(): any {
    this.configService.getPACSUrlV2().subscribe(
      (data) => {
        if (data.radiology_configurator) {
          this.radiologyPacsUrls = data.radiology_configurator;
          this.updatePACSURL(true);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  openDiscomImage(id): any {
    const newUrl = this.currentPacUrl.replace('{:id}', id);
    window.open(newUrl);
  }
  updatePACSURL(value: boolean): any {
    if (value === true) {
      this.currentPacUrl = this.radiologyPacsUrls.web_url;
    } else {
      this.currentPacUrl = this.radiologyPacsUrls.radiant_url;
    }
  }
}
