import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProfileService } from '../../services/profile.service';
import { MustMatch } from '../../custom-pipes/match-password.validator';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-forgot-password-v2',
  templateUrl: './forgot-password-v2.component.html',
  styleUrls: ['./forgot-password-v2.component.css'],
})
export class ForgotPasswordV2Component implements OnInit {
  passwordResetForm: UntypedFormGroup;
  submit = false;
  firstIcon = false;
  secondIcon = false;
  thirdIcon = false;
  user: any;
  isAuth: any;
  tToken = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    if (this.route.snapshot.queryParams.t_token) {
      this.tToken = this.route.snapshot.queryParams.t_token;
    }
    this.ngxLoader.stop();
  }

  ngOnInit(): any {
    this.passwordResetForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(8)]],
        newPasswordConfirm: ['', [Validators.required]],
      },
      {
        validator: MustMatch('newPassword', 'newPasswordConfirm'),
      },
    );
    this.verifyToken();
  }
  verifyToken(): any {
    this.ngxLoader.start();
    this.profileService.verifyTokenV2(this.tToken).subscribe(
      (data) => {
        this.ngxLoader.stop();
        this.isAuth = data.status;
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.message);
      },
    );
  }

  preventWhitespace(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }

  changeEyeIconFirst(): any {
    this.firstIcon = !this.firstIcon;
  }
  changeEyeIconSecond(): any {
    this.secondIcon = !this.secondIcon;
  }
  changeEyeIconThird(): any {
    this.thirdIcon = !this.thirdIcon;
  }

  onSubmit(): any {
    this.submit = true;

    if (this.passwordResetForm.invalid) {
      return;
    }

    this.ngxLoader.start();
    this.profileService
      .forgotPassV2(this.passwordResetForm.value.newPassword, this.tToken)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          toastr.success('Password update successfully');
          this.router.navigate(['login']);
        },
        (error) => {
          this.ngxLoader.stop();
          toastr.error(error.error.message);
        },
      );
  }

  resetForm(formGroup: UntypedFormGroup): any {
    let control: AbstractControl = null;
    formGroup.reset();
    formGroup.markAsUntouched();
    Object.keys(formGroup.controls).forEach((name) => {
      control = formGroup.controls[name];
      control.setErrors(null);
    });
  }

  get f(): any {
    return this.passwordResetForm.controls;
  }
}
