import { Component, OnInit } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { PdfServicesService } from '../../../services/pdf-services.service';
import { HospitalService } from '../../../services/hospital.service';
import * as moment from 'moment';
import { ShareDataService } from '../../../services/share-data.service';
import { SystemControlsService } from '../../../services/system-controls.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-birth-cirtificate',
  templateUrl: './birth-cirtificate.component.html',
  styleUrls: ['./birth-cirtificate.component.css'],
})
export class BirthCirtificateComponent implements OnInit {
  // today = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  today = new Date();
  pattern = '9999-9999';
  patientInvoices: any;
  patient: any;
  user: any;
  patientInvoiceAdvances = [];
  patientInvoiceServices = [];
  birthId: any;
  previousStatus: any;
  settingData: any;
  admissionId: any;
  hswBill: any;
  dateInWords: any;
  currentControls: any;

  constructor(
    private location: Location,
    private ngxLoader: NgxUiLoaderService,
    private pdfService: PdfServicesService,
    private hospitalService: HospitalService,
    private route: ActivatedRoute,
    private shareDataService: ShareDataService,
    private systemControlService: SystemControlsService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.birthId = params['id'];
    });
    console.log('birthId', this.birthId);

    if (this.birthId) {
      this.fetchPatientInvoiceData();
    }
    this.getSettingsData();
    setTimeout(() => {
      // this.shareDataService.controlCheck.subscribe(data => {
      this.systemControlService.getUpdatedChecks().subscribe((data) => {
        this.currentControls = data.report_controls;
        console.log('currents', this.currentControls);
      });
    }, 300);
  }

  fetchPatientInvoiceData(): any {
    this.ngxLoader.start();
    this.pdfService.getBirthInvoice(this.birthId).subscribe(
      (data) => {
        this.patientInvoices = data?.birth_certificate;
        this.dateInWords = moment(this.patientInvoices?.dob).format(
          'dddd Do MMMM YYYY',
        );
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
  }

  printReport(): any {
    window.print();
  }

  goBack(): any {
    this.location.back();
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
}
