<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Radiology Report</h5>
          <!--          tab headings-->

          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-light-primary btn-sm" (click)="printReport()">
          Print
        </button>
        <!-- <button class="btn btn-primary btn-sm ml-2" (click)="goBack()">Back</button> -->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="card pdf-card" id="reports">
        <div class="card-body" *ngIf="hospitalName === 'hameedLatif'">
          <table style="width: 100%">
            <thead>
              <tr>
                <th><div class="table-header"></div></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="d-flex mb-4">
                    <div style="width: 85%">
                      <!-- patient info table -->
                      <!--                    <table class="table" *ngIf="hospitalName !== 'hameedLatif'">-->
                      <!--                      <tbody>-->
                      <!--                      <tr class="borders">-->
                      <!--                        <td style="width: 15%;padding: 0;">-->
                      <!--                          <img [src]="settingData?.logo?.url" style="width: auto;height: 106px">-->
                      <!--                        </td>-->
                      <!--                        <td style="width: 85%;">-->
                      <!--                          <div class="font-size-h2 text-center"><h3 class="hosp-name">{{settingData?.title}}</h3></div>-->
                      <!--                          <div class="font-size-h3 text-center"><h5 class="hosp-address">{{settingData?.address}}</h5></div>-->
                      <!--&lt;!&ndash;                          <div class="text-center"><h3>INVOICE </h3></div>&ndash;&gt;-->
                      <!--                        </td>-->
                      <!--                      </tr>-->
                      <!--                      </tbody>-->
                      <!--                    </table>-->

                      <table class="w-100 info_table mb-2">
                        <tbody>
                          <tr>
                            <td style="width: 45%">
                              <h3
                                *ngIf="
                                  patientRadiologyData?.patient_service?.patient
                                "
                                class="m-0 line-clamp font-print-bold mr-5"
                              >
                                {{
                                  patientRadiologyData?.patient_service?.patient
                                    ?.title | titlecase
                                }}
                                {{
                                  patientRadiologyData?.patient_service?.patient
                                    ?.full_name | titlecase
                                }}
                                {{
                                  patientRadiologyData?.patient_service?.patient
                                    ?.age + ","
                                }}
                                {{
                                  patientRadiologyData?.patient_service?.patient?.gender.charAt(
                                    0
                                  ) | titlecase
                                }}
                              </h3>
                            </td>
                            <td style="width: 55%">
                              <h3 class="m-0 font-print-bold">
                                MRN#{{
                                  patientRadiologyData?.patient_service?.patient
                                    ?.mrn
                                }}
                              </h3>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- patient info detail table -->
                      <table class="w-100 info_table">
                        <tbody class="font-size-content">
                          <tr>
                            <td style="width: 16%">
                              <strong>Father/Husband:</strong>
                            </td>
                            <td style="width: 20%">
                              {{
                                patientRadiologyData?.patient_service?.patient
                                  ?.father_name | titlecase
                              }}
                            </td>
                            <td style="width: 9%"></td>
                            <td style="width: 15%">
                              <strong>Reg. Date:</strong>
                            </td>
                            <td style="width: 20%">
                              {{
                                patientRadiologyData?.patient_service?.patient
                                  ?.created_at | date: "dd-MM-yyyy h:mm a"
                              }}
                            </td>
                            <td style="width: 20%"></td>
                          </tr>
                          <tr>
                            <td style="width: 16%">
                              <strong>Contact:</strong>
                            </td>
                            <td style="width: 20%">
                              {{
                                patientRadiologyData?.patient_service?.patient
                                  ?.phone1
                              }}
                            </td>
                            <td style="width: 9%"></td>
                            <td style="width: 15%">
                              <strong>Department:</strong>
                            </td>
                            <td style="width: 20%">
                              {{
                                patientRadiologyData?.patient_service
                                  ?.department?.title
                              }}
                            </td>
                            <td style="width: 20%"></td>
                          </tr>
                          <tr>
                            <td style="width: 16%"><strong>CNIC:</strong></td>
                            <td style="width: 20%">
                              {{
                                patientRadiologyData?.patient_service?.patient
                                  ?.patient_nic
                              }}
                            </td>
                            <td style="width: 9%"></td>
                            <td style="width: 15%">
                              <strong>Consultant:</strong>
                            </td>
                            <td
                              style="width: 20%"
                              *ngIf="
                                patientRadiologyData?.patient_service?.patient
                                  ?.consultant
                              "
                            >
                              {{
                                patientRadiologyData?.patient_service?.patient
                                  ?.consultant
                              }}
                            </td>
                            <td
                              style="width: 20%"
                              *ngIf="
                                !patientRadiologyData?.patient_service?.patient
                                  ?.consultant
                              "
                            >
                              N/A
                            </td>
                            <td style="width: 20%"></td>
                          </tr>
                          <tr>
                            <td style="width: 16%">
                              <strong>Address:</strong>
                            </td>
                            <td
                              style="width: 20%"
                              *ngIf="
                                patientRadiologyData?.patient_service?.patient
                                  ?.address1
                              "
                            >
                              {{
                                patientRadiologyData?.patient_service?.patient
                                  ?.address1
                              }}
                            </td>
                            <td
                              style="width: 20%"
                              *ngIf="
                                !patientRadiologyData?.patient_service?.patient
                                  ?.address1
                              "
                            >
                              N/A
                            </td>
                            <td style="width: 9%"></td>
                            <td style="width: 15%">
                              <strong>Date of Exam:</strong>
                            </td>
                            <td style="width: 20%">
                              {{
                                patientRadiologyData?.patient_service
                                  ?.acknowledged_date
                                  | date: "dd-MM-yyyy h:mm a"
                              }}
                            </td>
                            <td style="width: 20%"></td>
                          </tr>
                          <tr>
                            <td style="width: 16%">
                              <strong>Patient Type:</strong>
                            </td>
                            <td style="width: 20%">
                              {{
                                patientRadiologyData?.patient_service
                                  ?.patient_type?.name
                              }}
                            </td>
                            <td style="width: 9%"></td>
                            <td style="width: 15%">
                              <strong>Accession #:</strong>
                            </td>
                            <td style="width: 20%">
                              {{
                                patientRadiologyData?.patient_service
                                  ?.accession_number
                              }}
                            </td>
                            <td style="width: 20%"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style="border-top: 3px solid #000000"></div>
                </td>
              </tr>
              <tr>
                <td class="text-break">
                  <div class="row">
                    <div class="col-12">
                      <h2 class="mt-8 font-size-service-name">
                        {{
                          patientRadiologyData?.patient_service?.service?.name
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" *ngIf="patientRadiologyData?.report">
                      <h2 class="mt-8 font-size-service-name">Result</h2>
                    </div>
                    <div class="col-12 data">
                      <div class="radiology-report-area" id="resultHTML"></div>
                      <!--                      <div [innerHTML]="patientRadiologyData?.report"></div>-->
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-12"
                      *ngIf="patientRadiologyData?.impression"
                    >
                      <h2 class="mt-8 font-size-service-name">Impression</h2>
                    </div>
                    <div class="col-12 data">
                      <div
                        class="radiology-report-area"
                        id="impressionHTML"
                      ></div>
                      <!--                      <div [innerHTML]="patientRadiologyData?.impression"></div>-->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" *ngIf="patientRadiologyData?.addendum">
                      <h2 class="mt-8 font-size-service-name">Addendum</h2>
                    </div>
                    <div class="col-12 data">
                      <div
                        class="radiology-report-area"
                        id="addendumHTML"
                      ></div>
                      <!--                      <div [innerHTML]="patientRadiologyData?.addendum"></div>-->
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div style="height: 80px"></div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="card-body" *ngIf="hospitalName !== 'hameedLatif'">
          <table
            style="
              position: relative;
              bottom: 0px;
              margin-top: 0in;
              width: 100%;
            "
          >
            <tbody>
              <tr>
                <td class="text-break">
                  <div style="width: 85%">
                    <!-- patient info table -->
                    <table class="table">
                      <tbody>
                        <tr class="borders">
                          <td style="width: 15%; padding: 0">
                            <img
                              [src]="settingData?.logo?.url"
                              style="width: auto; height: 206px"
                            />
                          </td>
                          <td style="width: 85%; vertical-align: middle">
                            <div class="font-size-h2 text-center">
                              <h3 class="hosp-name">
                                {{ settingData?.title }}
                              </h3>
                            </div>
                            <div class="font-size-h3 text-center">
                              <h5 class="hosp-address">
                                {{ settingData?.address }}
                              </h5>
                            </div>
                            <!--                          <div class="text-center"><h3>INVOICE </h3></div>-->
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="w-100 info_table mb-2">
                      <tbody>
                        <tr>
                          <td style="width: 45%">
                            <h3
                              *ngIf="
                                patientRadiologyData?.patient_service?.patient
                              "
                              class="m-0 line-clamp font-print-bold mr-5"
                            >
                              {{
                                patientRadiologyData?.patient_service?.patient
                                  ?.title | titlecase
                              }}
                              {{
                                patientRadiologyData?.patient_service?.patient
                                  ?.full_name | titlecase
                              }}
                              {{
                                patientRadiologyData?.patient_service?.patient
                                  ?.age + ","
                              }}
                              {{
                                patientRadiologyData?.patient_service?.patient?.gender.charAt(
                                  0
                                ) | titlecase
                              }}
                            </h3>
                          </td>
                          <td style="width: 55%">
                            <h3 class="m-0 font-print-bold">
                              MRN#{{
                                patientRadiologyData?.patient_service?.patient
                                  ?.mrn
                              }}
                            </h3>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- patient info detail table -->
                    <table class="w-100 info_table">
                      <tbody class="font-size-content">
                        <tr>
                          <td style="width: 16%">
                            <strong>Father/Husband:</strong>
                          </td>
                          <td style="width: 20%">
                            {{
                              patientRadiologyData?.patient_service?.patient
                                ?.father_name | titlecase
                            }}
                          </td>
                          <td style="width: 9%"></td>
                          <td style="width: 15%">
                            <strong>Reg. Date:</strong>
                          </td>
                          <td style="width: 20%">
                            {{
                              patientRadiologyData?.patient_service?.patient
                                ?.created_at | date: "dd-MM-yyyy h:mm a"
                            }}
                          </td>
                          <td style="width: 20%"></td>
                        </tr>
                        <tr>
                          <td style="width: 16%"><strong>Contact:</strong></td>
                          <td style="width: 20%">
                            {{
                              patientRadiologyData?.patient_service?.patient
                                ?.phone1
                            }}
                          </td>
                          <td style="width: 9%"></td>
                          <td style="width: 15%">
                            <strong>Department:</strong>
                          </td>
                          <td style="width: 20%">
                            {{
                              patientRadiologyData?.patient_service?.department
                                ?.title
                            }}
                          </td>
                          <td style="width: 20%"></td>
                        </tr>
                        <tr>
                          <td style="width: 16%"><strong>CNIC:</strong></td>
                          <td style="width: 20%">
                            {{
                              patientRadiologyData?.patient_service?.patient
                                ?.patient_nic
                            }}
                          </td>
                          <td style="width: 9%"></td>
                          <td style="width: 15%">
                            <strong>Consultant:</strong>
                          </td>
                          <td
                            style="width: 20%"
                            *ngIf="
                              patientRadiologyData?.patient_service?.patient
                                ?.consultant
                            "
                          >
                            {{
                              patientRadiologyData?.patient_service?.patient
                                ?.consultant
                            }}
                          </td>
                          <td
                            style="width: 20%"
                            *ngIf="
                              !patientRadiologyData?.patient_service?.patient
                                ?.consultant
                            "
                          >
                            N/A
                          </td>
                          <td style="width: 20%"></td>
                        </tr>
                        <tr>
                          <td style="width: 16%"><strong>Address:</strong></td>
                          <td
                            style="width: 20%"
                            *ngIf="
                              patientRadiologyData?.patient_service?.patient
                                ?.address1
                            "
                          >
                            {{
                              patientRadiologyData?.patient_service?.patient
                                ?.address1
                            }}
                          </td>
                          <td
                            style="width: 20%"
                            *ngIf="
                              !patientRadiologyData?.patient_service?.patient
                                ?.address1
                            "
                          >
                            N/A
                          </td>
                          <td style="width: 9%"></td>
                          <td style="width: 15%">
                            <strong>Date of Exam:</strong>
                          </td>
                          <td style="width: 20%">
                            {{
                              patientRadiologyData?.patient_service
                                ?.acknowledged_date | date: "dd-MM-yyyy h:mm a"
                            }}
                          </td>
                          <td style="width: 20%"></td>
                        </tr>
                        <tr>
                          <td style="width: 16%">
                            <strong>Patient Type:</strong>
                          </td>
                          <td style="width: 20%">
                            {{
                              patientRadiologyData?.patient_service
                                ?.patient_type?.name
                            }}
                          </td>
                          <td style="width: 9%"></td>
                          <td style="width: 15%">
                            <strong>Accession #:</strong>
                          </td>
                          <td style="width: 20%">
                            {{
                              patientRadiologyData?.patient_service
                                ?.accession_number
                            }}
                          </td>
                          <td style="width: 20%"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--                <div style="width: 15%;">-->
                  <!--                  <div style="width: 100%;height: 180px;">-->
                  <!--                    <img  class="qrCode" style="height: 100%;width: 100%;" *ngIf="patientServiceInvoicesData?.patient?.qrcode_url" [src]="patientServiceInvoicesData?.patient?.qrcode_url" alt="" >-->
                  <!--                  </div>-->
                  <!--                  &lt;!&ndash;                <img src="../assets/media/pdf-images/qr.png" alt="" style="max-height: 100px">&ndash;&gt;-->
                  <!--                  <img  class="brCode" *ngIf="patientServiceInvoicesData?.patient?.barcode_url" [src]="patientServiceInvoicesData?.patient?.barcode_url" alt="" >-->
                  <!--                  <div  class="font-weight-bolder font-size-h3 mrnCode" id="mrn">{{ patientServiceInvoicesData?.patient?.mrn }}</div>-->
                  <!--                </div>-->

                  <div style="border-top: 3px solid #000000"></div>
                  <div class="row">
                    <div class="col-12">
                      <h2 class="mt-8 font-size-service-name">
                        {{
                          patientRadiologyData?.patient_service?.service?.name
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" *ngIf="patientRadiologyData?.report">
                      <h2 class="mt-8 font-size-service-name">Result</h2>
                    </div>
                    <div class="col-12 data">
                      <div class="radiology-report-area" id="resultHTML"></div>
                      <!--                      <div [innerHTML]="patientRadiologyData?.report"></div>-->
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-12"
                      *ngIf="patientRadiologyData?.impression"
                    >
                      <h2 class="mt-8 font-size-service-name">Impression</h2>
                    </div>
                    <div class="col-12 data">
                      <div
                        class="radiology-report-area"
                        id="impressionHTML"
                      ></div>
                      <!--                      <div [innerHTML]="patientRadiologyData?.impression"></div>-->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" *ngIf="patientRadiologyData?.addendum">
                      <h2 class="mt-8 font-size-service-name">Addendum</h2>
                    </div>
                    <div class="col-12 data">
                      <div
                        class="radiology-report-area"
                        id="addendumHTML"
                      ></div>
                      <!--                      <div [innerHTML]="patientRadiologyData?.addendum"></div>-->
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="table-footer"></td>
              </tr>
            </tfoot>
          </table>

          <div style="" id="footer">
            <div>
              <div>
                <span><strong>Dictated & Authenticated By: </strong></span>
              </div>
              <div>
                <span>{{ patientRadiologyData?.verified_by_name }}</span>
              </div>
              <div>
                <span>{{
                  patientRadiologyData?.verified_by_qualification
                }}</span>
              </div>
              <div>
                <span>{{ patientRadiologyData?.verified_by_designation }}</span>
              </div>
              <div>
                <span
                  ><strong>Date & Time: </strong
                  >{{
                    patientRadiologyData?.verification_date
                      | date: "EE, MMM dd, yy, h:mm:ss a"
                  }}</span
                >
              </div>
              <div>
                <span
                  ><strong
                    >No Signature(s) required, Electronically Verified</strong
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
