<!--begin::Content-->
<div
  class="content d-flex flex-column flex-column-fluid selectOptionBottom"
  id="kt_content"
>
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            {{ screenName || "Generate Invoice" }}
          </h5>

          <!--          tab headings-->

          <ul class="nav nav-pills nav-light-primary nav-bold border-0 ml-5">
            <li class="nav-item">
              <a
                class="nav-link border-0 active"
                data-toggle="tab"
                href="#services_tab"
                (click)="servicesTab()"
              >
                <!--                <span class="nav-icon"><i class="flaticon2-chat-1"></i></span>-->
                <span class="nav-text">Services</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="patient">
              <a
                class="nav-link border-0"
                data-toggle="tab"
                href="#history_tab"
                (click)="fetchServicesHistory(true, true)"
              >
                <!--                <span class="nav-icon"><i class="flaticon2-analytics-1"></i></span>-->
                <span class="nav-text">History</span>
              </a>
            </li>

            <!--            <li class="nav-item" *ngIf="patient && showProductTab">-->
            <!--              <a class="nav-link border-0" data-toggle="tab" href="#product_tab">-->
            <!--                &lt;!&ndash;                <span class="nav-icon"><i class="flaticon2-analytics-1"></i></span>&ndash;&gt;-->
            <!--                <span class="nav-text">Products</span>-->
            <!--              </a>-->
            <!--            </li>-->
          </ul>
          <!--          <div class="d-flex row ml-6">-->
          <!--            <div class="col-12 patient-data">-->

          <!--          <span class="text-left  padding-top-4" *ngIf="patient">-->
          <!--            {{ patient?.mrn }}-->
          <!--            <span class="ml-5">{{ ' ' + patient?.first_name + ' ' + patient?.last_name}}</span>-->
          <!--            <span class="ml-5">{{patient?.age}}, {{patient?.gender | titlecase}}</span>-->
          <!--          </span>-->

          <!--&lt;!&ndash;              <span class="ml-8" *ngIf="patient?.admissions.length > 0">&ndash;&gt;-->
          <!--&lt;!&ndash;                {{ ' ' + patient?.admissions[patient?.admissions.length - 1]?.ward?.title}}</span>&ndash;&gt;-->

          <!--              &lt;!&ndash;              <span class="ml-5" *ngIf="patient?.bed_name"> {{patient?.bed_name}}</span>&ndash;&gt;-->

          <!--&lt;!&ndash;              <span class="ml-5" *ngIf="patient?.patient_type"> {{patient?.patient_type?.name}}</span>&ndash;&gt;-->

          <!--            </div>-->
          <!--          </div>-->

          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Actions-->
        <!--        <a  class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a>-->
        <!--end::Actions-->
        <!--begin::Dropdown-->
        <!--        <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">-->
        <!--          <a  class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--											<span class="svg-icon svg-icon-success svg-icon-2x">-->
        <!--												&lt;!&ndash;begin::Svg Icon | path:assets/media/svg/icons/Files/File-plus.svg&ndash;&gt;-->
        <!--												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
        <!--													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
        <!--														<polygon points="0 0 24 0 24 24 0 24" />-->
        <!--														<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />-->
        <!--														<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000" />-->
        <!--													</g>-->
        <!--												</svg>-->
        <!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--											</span>-->
        <!--          </a>-->
        <!--          <div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">-->
        <!--            &lt;!&ndash;begin::Navigation&ndash;&gt;-->
        <!--            <ul class="navi navi-hover">-->
        <!--              <li class="navi-header font-weight-bold py-4">-->
        <!--                <span class="font-size-lg">Choose Label:</span>-->
        <!--                <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mb-3 opacity-70"></li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-success">Customer</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-danger">Partner</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-warning">Suplier</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-primary">Member</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-dark">Staff</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mt-3 opacity-70"></li>-->
        <!--              <li class="navi-footer py-4">-->
        <!--                <a class="btn btn-clean font-weight-bold btn-sm" >-->
        <!--                  <i class="ki ki-plus icon-sm"></i>Add new</a>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--            &lt;!&ndash;end::Navigation&ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="row">
        <div class="col-md-12 p-0">
          <!--          <div class="card card-custom gutter-b">-->
          <!--            <div class="card-header ">-->

          <!--&lt;!&ndash;              <div class="card-title">&ndash;&gt;-->
          <!--&lt;!&ndash;                <h3 class="card-label">Card Line Tabs With Icons</h3>&ndash;&gt;-->
          <!--&lt;!&ndash;              </div>&ndash;&gt;-->

          <!--&lt;!&ndash;              <div class="card-toolbar">&ndash;&gt;-->

          <!--&lt;!&ndash;              </div>&ndash;&gt;-->
          <!--            </div>-->

          <!--            <div class="card-body">-->

          <div class="tab-content">
            <!-- Services tab -->
            <div
              class="tab-pane fade show active"
              id="services_tab"
              role="tabpanel"
              aria-labelledby="services_tab"
            >
              <!-- Consultation area-->

              <!--              <div class="card mb-3" *ngIf="isAdmitted === false">-->
              <!--                <div class="card-body service-card p-5">-->

              <!--                  <div class="row">-->

              <!--                    <div class="col-8">-->
              <!--                      <h4 class="m-0 mb-3">Consultation</h4>-->
              <!--                    </div>-->

              <!--                    <div class="col-4 text-right" *ngIf="consultation_services.length === 0">-->
              <!--                      <button (click)="addConsultant()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"  type='button'>-->
              <!--                            <span class="svg-icon svg-icon-primary svg-icon-2x">&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg&ndash;&gt;<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
              <!--                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
              <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
              <!--                                        &lt;!&ndash;                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>&ndash;&gt;-->
              <!--                                          <path d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z" fill="#000000"/>-->
              <!--                                      </g>-->
              <!--                                  </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;-->
              <!--                            </span>-->
              <!--                      </button>-->
              <!--                    </div>-->

              <!--                  </div>-->

              <!--                  <div class="">-->
              <!--                    <table class="table serviceTable m-0" *ngIf="consultation_services.length > 0">-->

              <!--                      <thead>-->
              <!--                      <tr>-->
              <!--                        <th class="p-2">Doctor</th>-->
              <!--                        <th class="p-2">Department</th>-->
              <!--                        <th class="p-2">Sub Department</th>-->
              <!--                        <th class="p-2">Visit Type</th>-->
              <!--                        <th class="p-2">Code</th>-->
              <!--                        &lt;!&ndash;                          <th class="p-2">Service Type</th>&ndash;&gt;-->
              <!--                        <th class="p-2">Clinic</th>-->
              <!--                        &lt;!&ndash;                          <th class="p-2">Comments</th>&ndash;&gt;-->
              <!--                        <th class="p-2">Price</th>-->
              <!--                        <th class="p-2 text-center">Action</th>-->
              <!--                      </tr>-->
              <!--                      </thead>-->

              <!--                      <tbody>-->

              <!--                      <tr *ngFor="let  consultant of consultation_services; let i = index">-->

              <!--                        &lt;!&ndash;                          bootstrap-select-height&ndash;&gt;-->
              <!--                        <td class="p-2 pickers" style="width: 18%">-->
              <!--                          <div id="conDoctorMain{{i}}">-->
              <!--                            <select (change)="fetchDoctorDepartments($event.target.value, i)" [(ngModel)]="consultant.doctor_id"-->
              <!--                                    autofocus data-live-search="true"-->
              <!--                                    class="auto-focus-red form-control form-control-sm conDoctor doctorSelectpicker" id="conDoctor{{i}}">-->
              <!--                              <option [disabled]="true" value="">select</option>-->
              <!--                              <ng-container *ngFor="let doc of doctors[i]">-->
              <!--                                <option [value]="doc.id">{{doc.first_name}} {{doc.last_name}}</option>-->
              <!--                              </ng-container>-->
              <!--                            </select>-->
              <!--                          </div>-->
              <!--                        </td>-->

              <!--                        <td class="p-2 pickers" style="width: 18%">-->
              <!--                          <div id="conDeptMain{{i}}">-->
              <!--                            <select (change)="fetchDeptDoctors($event.target.value, i)" [(ngModel)]="consultant.department_id"-->
              <!--                                    class="form-control form-control-sm conDept departmentSelectpicker" data-live-search="true" id="conDept{{i}}">-->
              <!--                              <option [disabled]="true" value="">select</option>-->
              <!--                              <option *ngFor="let dept of departments" [value]="dept.id">{{dept.title}}</option>-->
              <!--                            </select>-->
              <!--                          </div>-->
              <!--                        </td>-->
              <!--                        <td class="p-2 pickers" style="width: 14%">-->
              <!--                          <div>-->
              <!--                            <select [(ngModel)]="consultant.sub_department_id" id="conSubDept{{i}}"-->
              <!--                                    class="form-control form-control-sm conSubDept subDepartment"  data-live-search="true">-->
              <!--                              <option [disabled]="true" value="">select</option>-->
              <!--                              <option *ngFor="let department of subDepartments[i]" [value]="department?.id">{{department.title}}</option>-->
              <!--                            </select>-->
              <!--                          </div>-->
              <!--                        </td>-->

              <!--                        <td class="p-2" style="width: 10%">-->
              <!--                          <div>-->
              <!--                            <select (change)='fetchVisitService($event.target.value, i)' [(ngModel)]="consultant.service_type"-->
              <!--                                    class="form-control form-control-sm conService" id="conService{{i}}">-->
              <!--                              <option [disabled]="true" value="">select</option>-->
              <!--                              <ng-container *ngFor="let lov of lookups">-->
              <!--                                <option *ngIf="lov.category == 'Visit Type'" [value]="lov.id">{{lov.key}}</option>-->
              <!--                              </ng-container>-->
              <!--                            </select>-->
              <!--                          </div>-->
              <!--                        </td>-->

              <!--                        <td class="p-2" style="width: 10%">-->
              <!--                          <input [(ngModel)]="consultant.billing_code" [readonly]="true" class="form-control form-control-sm" placeholder="Code" type="text"/>-->
              <!--                        </td>-->

              <!--                        &lt;!&ndash;                          <td class="p-2" style="width: 10%">&ndash;&gt;-->
              <!--                        &lt;!&ndash;                            <select class="form-control form-control-sm">&ndash;&gt;-->
              <!--                        &lt;!&ndash;                              <option [disabled]="true" [selected]=true value="doctor_fee">Visit</option>&ndash;&gt;-->
              <!--                        &lt;!&ndash;                            </select>&ndash;&gt;-->
              <!--                        &lt;!&ndash;                          </td>&ndash;&gt;-->

              <!--                        <td class="p-2" style="width: 10%">-->
              <!--                          <input [readonly]="true" class="form-control form-control-sm" placeholder="Clinic #" type="text"/>-->
              <!--                        </td>-->

              <!--                        &lt;!&ndash;                          <td class="p-2" style="width: 10%">&ndash;&gt;-->
              <!--                        &lt;!&ndash;                            <input [(ngModel)]="consultant.comments" class="form-control form-control-sm" placeholder="Comments" type="text"/>&ndash;&gt;-->
              <!--                        &lt;!&ndash;                          </td>&ndash;&gt;-->

              <!--                        <td class="p-2" style="width: 10%">-->
              <!--                          <input [(ngModel)]="consultant.price" [readonly]="true" class="form-control form-control-sm" placeholder="Price" type="number"/>-->
              <!--                        </td>-->

              <!--                        <td class="p-2 text-center" style="width: 5%">-->
              <!--                          <button (click)="removeConsultant(i)" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm "  type='button'>-->
              <!--                              <span class="svg-icon svg-icon-primary svg-icon-2x">&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg&ndash;&gt;<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
              <!--                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
              <!--                                    <rect x="0" y="0" width="24" height="24"/>-->
              <!--                                    <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"/>-->
              <!--                                    <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>-->
              <!--                                </g>-->
              <!--                            </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;</span>-->
              <!--                          </button>-->
              <!--                        </td>-->
              <!--                      </tr>-->

              <!--                      <tr>-->
              <!--                        <td colspan="6"></td>-->
              <!--                        <td><span *ngIf="consultation_services.length > 0"><strong>Total:</strong> {{consultantionTotal}}</span></td>-->
              <!--                        <td class="text-center" style="width: 5%;"><button (click)="addConsultant()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"  type='button'>-->
              <!--                            <span class="svg-icon svg-icon-primary svg-icon-2x">&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg&ndash;&gt;<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
              <!--                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
              <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
              <!--                                        &lt;!&ndash;                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>&ndash;&gt;-->
              <!--                                          <path d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z" fill="#000000"/>-->
              <!--                                      </g>-->
              <!--                                  </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;-->
              <!--                                    </span>-->
              <!--                        </button></td>-->
              <!--                      </tr>-->

              <!--                      </tbody>-->

              <!--                    </table>-->
              <!--                  </div>-->

              <!--                  <div class="row text-right">-->
              <!--                    <div class="col-11">-->
              <!--                    </div>-->
              <!--                    <div class="col-1">-->
              <!--                    </div>-->
              <!--                  </div>-->

              <!--                  &lt;!&ndash;                      <div class="p-0 text-right">&ndash;&gt;-->
              <!--                  &lt;!&ndash;                        <span *ngIf="consultation_services.length > 0" class="font-size-h4 font-weight-bold" style="margin-top: 15px !important;">Total: {{consultantionTotal}}</span>&ndash;&gt;-->
              <!--                  &lt;!&ndash;                        <button (click)="addConsultant()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-5"  type='button'><i class="fas fa-plus p-0"></i></button>&ndash;&gt;-->
              <!--                  &lt;!&ndash;                      </div>&ndash;&gt;-->

              <!--                </div>-->
              <!--              </div>-->

              <!-- Patient Card : Start -->
              <div class="card mb-4">
                <div class="card-body px-2 py-0">
                  <div class="mt-0 pb-0" style="padding: 8px">
                    <h6 class="mb-0">Client Information</h6>
                  </div>
                  <div class="d-flex">
                    <!--begin: Pic-->
                    <div
                      class="flex-shrink-0 mr-2 mt-lg-0 mt-3"
                      style="padding: 8px"
                    >
                      <div class="symbol symbol-45 symbol-lg-45">
                        <img
                          class="medical_fitness_certificate_image"
                          src="assets/media/users/blank.png"
                          alt="image"
                        />
                      </div>
                    </div>
                    <!--end::Pic-->
                    <!--begin::Info-->
                    <div class="flex-grow-1">
                      <!--begin::Title-->
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex">
                          <a
                            class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                            >{{ patient?.title | titlecase }}
                            {{ patient?.first_name }}
                            {{ patient?.middle_name || "" }}
                            {{ patient?.last_name }}</a
                          >
                          <!--                      {{patient?.first_name + ' ' + patient?.last_name}}-->
                          <a>
                            <i
                              class="flaticon2-correct text-success font-size-h5"
                            ></i>
                          </a>
                        </div>
                      </div>
                      <!--end::Title-->
                      <!--begin::Content-->
                      <div
                        class="d-flex flex-wrap justify-content-between mt-1"
                      >
                        <div class="d-flex flex-column flex-grow-1 pr-8">
                          <div class="d-flex flex-wrap mb-4">
                            <a
                              class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                            >
                              <i
                                class="flaticon2-placeholder mr-2 font-size-lg"
                              ></i
                              >{{ patient?.mrn }}</a
                            >
                            <!--                        {{patient?.mrn}}-->
                            <a
                              class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                            >
                              <i class="fas fa-male mr-2 font-size-lg"></i
                              >{{ patient?.gender | titlecase }}</a
                            >
                            <!--                        {{patient?.gender}}-->
                            <a
                              class="text-dark-50 text-hover-primary mr-5 font-weight-bold"
                            >
                              <i class="fa fa-birthday-cake mr-2 font-size-lg">
                                {{ patient?.age }}</i
                              ></a
                            >

                            <!--                          <span>{{ ' ' + patients?.admission[patients?.admission.length - 1].ward.title}} ({{patients?.bed_name | titlecase}}) </span>-->
                            <!--                        {{patient?.age}}-->
                          </div>

                          <!--        <span class="font-weight-bold text-dark-50">{{patient?.patient_type?.name | titlecase}}</span>-->
                          <!--                      {{patient?.patient_type?.name | titlecase}}-->
                        </div>
                      </div>
                      <!--end::Content-->
                    </div>
                    <div class="d-flex p-0" style="margin-right: 35px">
                      <div style="padding: 12px">
                        <label> Search Client:</label>
                      </div>
                      <!--begin::Search-->
                      <div id="kt_quick_search_toggle" class="location_name">
                        <div
                          class="quick-search quick-search-dropdown p-0 quick-search-has-result"
                          style="width: 234px"
                          id="kt_quick_search_dropdown"
                        >
                          <!--begin:Form-->
                          <form method="get" class="quick-search-form">
                            <div class="input-group" style="height: 33px">
                              <div class="input-group-prepend">
                                <span class="input-group-text px-2 py-0">
                                  <span
                                    class="svg-icon svg-icon-primary svg-icon-lg"
                                  >
                                    <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        />
                                        <path
                                          d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                          fill="#000000"
                                          fill-rule="nonzero"
                                          opacity="0.3"
                                        />
                                        <path
                                          d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                          fill="#000000"
                                          fill-rule="nonzero"
                                        />
                                      </g>
                                    </svg>
                                    <!--end::Svg Icon-->
                                  </span>
                                </span>
                              </div>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                autocomplete="off"
                                id="product_Search"
                                name="searchProductValue"
                                maxlength="14"
                                style="max-height: 31px"
                                [(ngModel)]="searchProductValue"
                                #search_value
                                (keyup)="searchProduct(search_value.value)"
                                placeholder="Search Client..."
                              />
                              <div class="input-group-append">
                                <span
                                  class="input-group-text px-2 py-0"
                                  *ngIf="searchProductValue"
                                >
                                  <i
                                    class="quick-search-close ki ki-close icon-sm text-muted"
                                    (click)="removeSearchString()"
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </form>
                          <div *ngIf="searchProductValue">
                            <div
                              class="location-search-bar pointOfSale"
                              style="width: 17%"
                              [ngClass]="{ active: productSearchList?.length }"
                            >
                              <div
                                *ngFor="
                                  let Patient of productSearchList;
                                  let i = index
                                "
                              >
                                <div
                                  class="cursor-pointer dropdown-location d-flex justify-content-between px-1"
                                  (click)="appendRow(Patient.mrn)"
                                >
                                  <div>{{ Patient.mrn }}</div>
                                  <div>
                                    {{ patient.title || ""
                                    }}{{ Patient.full_name }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--end::Scroll-->
                        </div>
                      </div>
                      <div class="form-group">
                        <select
                          class="form-control form-control-sm selectpicker"
                          data-live-search="true"
                          [(ngModel)]="searchProductType"
                          (change)="resetValues()"
                        >
                          <option [value]="'crn'">CRN</option>
                          <option [value]="'nic'" value="'nic'">CNIC</option>
                          <option
                            [value]="'phone_number'"
                            value="'phone_number'"
                          >
                            Phone No.
                          </option>
                        </select>
                      </div>
                      <!--end::Search-->
                    </div>
                    <!--end::Info-->
                    <div class="d-flex">
                      <div class="p-3">Booking:</div>
                      <div style="min-width: 250px">
                        <select
                          name="bookingList"
                          id="bookingList"
                          class="form-control form-control-sm selectPickerBooking pr-4"
                          title="Select Booking"
                          [(ngModel)]="selectedBooking"
                          (change)="fetchSelectedBooking($event.target.value)"
                          data-container="body"
                          data-live-search="true"
                        >
                          <!--                                data-container="body" data-live-search="true" formControlName="department">-->
                          <option [value]="''">Select Booking</option>
                          <ng-container *ngFor="let booking of bookingList">
                            <option [value]="booking.id">
                              Booking No# {{ booking.booking_number }}
                            </option>
                          </ng-container>
                        </select>
                        <!--                        <select class="form-control selectPickerBooking" (click)="fetchSelectedBookingData()"  data-live-search="true" data-container="body">-->
                        <!--                          <option value="" disabled>select</option>-->
                        <!--                          <option value="15994" >Booking 1</option>-->
                        <!--                          <option value="15993" >Booking 1</option>-->
                        <!--&lt;!&ndash;                          <option value="booking_1" ><span (click)="fetchServicesDeptBooking2('')">Booking 2</span></option>&ndash;&gt;-->
                        <!--                        </select>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Patient Card : End -->

              <!--Services Card: Start-->
              <div class="card mb-3">
                <div class="card-body service-card p-5">
                  <div class="row">
                    <div class="col-8">
                      <h4
                        class="mx-0"
                        style="width: 160px; float: left; margin-top: 4px"
                      >
                        Services
                      </h4>
                      <div style="width: 160px; float: left; margin-right: 5px">
                        <select
                          name="department"
                          id="department"
                          class="form-control form-control-sm selectPickerDepartment"
                          title="Select Department"
                          [(ngModel)]="defaultServiceType"
                          (change)="fetchServicesMain($event.target.value)"
                          name="department"
                          data-container="body"
                          data-live-search="true"
                        >
                          <!--                              data-container="body" data-live-search="true" formControlName="department">-->
                          <option [value]="''" [disabled]="true">
                            Select Department
                          </option>
                          <ng-container *ngFor="let dep of depart">
                            <option [value]="dep.id">{{ dep.title }}</option>
                          </ng-container>
                        </select>
                      </div>

                      <!--                      <select (change)='fetchServicesMain($event.target.value)' [(ngModel)]="defaultServiceType" *ngIf="requestedServices === false" class="form-control form-control-sm serviceType mr-2" id="serviceTypeMain" style="width: 160px;float: left">-->
                      <!--                        <option [disabled]="true" [selected]=true value="">Select</option>-->
                      <!--                          <option [value]="'MiscService'">Hair</option>-->
                      <!--                          <option [value]="'MiscService'">MakeUp</option>-->
                      <!--                          <option [value]="'MiscService'">Spa</option>-->
                      <!--&lt;!&ndash;                        <ng-container *ngFor="let s of uniq_services">&ndash;&gt;-->
                      <!--&lt;!&ndash;                          <option [value]="s.name">{{s.name}}</option>&ndash;&gt;-->
                      <!--&lt;!&ndash;                        </ng-container>&ndash;&gt;-->
                      <!--                      </select>-->
                      <div style="float: left; width: 300px">
                        <select
                          (change)="
                            fetchServicesDeptMain($event.target.value, true)
                          "
                          title="Select Service"
                          id="serviceNameMain"
                          [disabled]="disableServiceCard"
                          [ngClass]="{ disabled: disableServiceCard === true }"
                          data-width="300px"
                          data-live-search="true"
                          class="form-control form-control-sm serviceName selectpicker bootstrap-select-container"
                        >
                          <option
                            [disabled]="true"
                            value="-1"
                            [selected]="true"
                          >
                            Select
                          </option>
                          <ng-container *ngFor="let s of servicesMain">
                            <option [value]="s.id">{{ s.name }}</option>
                          </ng-container>
                        </select>
                      </div>
                      <!--                      <div style="float: left;width: 300px" *ngIf="requestedServices === true">-->
                      <!--                        <select (change)='fetchServicesDeptMain($event.target.value, true)' data-width="300px" data-live-search="true" class="form-control form-control-sm serviceName selectpicker bootstrap-select-container" id="serviceRequested">-->
                      <!--                          <option [disabled]="true" value="-1" [selected]="true">Select</option>-->
                      <!--                          <ng-container *ngFor="let s of requestedServicesList">-->
                      <!--                            <option [value]="s.id">{{s.name}}</option>-->
                      <!--                          </ng-container>-->
                      <!--                        </select>-->
                      <!--                      </div>-->
                    </div>
                    <div *ngIf="showRedeemVoucher" class="col-4">
                      <div
                        class="d-flex mr-1"
                        style="justify-content: flex-end"
                      >
                        <div class="mt-3">Redeem Voucher:</div>
                        <div style="min-width: 240px" class="ml-3">
                          <select
                            class="form-control form-control-sm selectPickerVoucher"
                            data-live-search="true"
                            placeholder="Select Voucher"
                            (change)="fetchSelectedVoucher($event.target.value)"
                          >
                            <option value="">Select Voucher</option>
                            <ng-container *ngFor="let dep of VoucherList">
                              <option [value]="dep.id">{{ dep.code }}</option>
                            </ng-container>
                          </select>
                        </div>
                        <!--                          <input type="text" class="form-control form-control-sm" placeholder="search Voucher....">-->
                      </div>
                    </div>
                    <!--                    <div class="col-1 text-right" [ngClass]="{'marginLeft': !showRedeemVoucher}">-->
                    <!--                    </div>-->
                  </div>
                  <div class="">
                    <table
                      class="table serviceTable m-0"
                      *ngIf="patient_services.length > 0"
                    >
                      <thead>
                        <tr>
                          <th class="" style="width: 17%">Department</th>
                          <th class="" style="width: 17%">Service Name</th>
                          <th class="" style="width: 8%">Price</th>
                          <th class="" style="width: 10%">
                            Client Cat. Dis(%)
                          </th>
                          <th class="" style="width: 11%">Manual Dis(%)</th>
                          <th class="" style="width: 11%">
                            Service Voucher(%)
                          </th>
                          <!--                        <th class="" style="width: 8%">Qty</th>-->
                          <th class="" style="width: 8%">Net Price</th>
                          <th class="" style="width: 11%">Service Advance</th>
                          <th class="" style="width: 7%; text-align: right">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let service of patient_services;
                            let i = index
                          "
                        >
                          <td style="padding: 7px">
                            <select
                              (change)="getServices($event.target.value, i, '')"
                              [disabled]="
                                disableServiceCard ||
                                service.code ||
                                service.booking_id !== ''
                              "
                              name="department"
                              id="serviceType{{ i }}"
                              class="form-control form-control-sm selectPickerDepartment"
                              data-container="body"
                              data-live-search="true"
                              [(ngModel)]="service.department_id"
                            >
                              <option [value]="''" [disabled]="true">
                                Select Department
                              </option>
                              <ng-container *ngFor="let dep of depart">
                                <option [value]="dep.id">
                                  {{ dep.title }}
                                </option>
                              </ng-container>
                            </select>
                          </td>
                          <td class="p-2 serviceReport">
                            <!--                        <div class="form-group">-->
                            <select
                              (change)="
                                updateServicesDept(i, $event.target.value)
                              "
                              [disabled]="
                                disableServiceCard ||
                                service.code ||
                                service.booking_id !== ''
                              "
                              [ngClass]="{ disabled: service.code }"
                              data-live-search="true"
                              class="form-control form-control-sm serviceName"
                              id="serviceName{{ i }}"
                              [(ngModel)]="service.service_id"
                            >
                              <option value="" disabled>Select Service</option>
                              <ng-container *ngFor="let s of services[i]">
                                <option [value]="s.id">{{ s.name }}</option>
                              </ng-container>
                            </select>
                            <small *ngIf="service.billing_code"
                              ><strong>Code:</strong>
                              {{ service.billing_code }}</small
                            >
                            <small *ngIf="service.time"
                              ><strong>Service Time:</strong>
                              {{ service.time }}</small
                            >
                            <!--                        </div>-->
                            <div
                              *ngIf="service.reporting_time_required === true"
                              style="padding-left: 11px"
                              class="mt-1 font-size-xs"
                            >
                              <a
                                class="text-primary"
                                *ngIf="service.flight_date_time === ''"
                                (click)="setReportingTimeModal(i)"
                                >Add Reporting Time</a
                              >
                              <a
                                class="text-primary"
                                *ngIf="service.flight_date_time !== ''"
                                (click)="setReportingTimeModal(i)"
                                >{{ service.flight_date_time }}</a
                              >
                            </div>
                          </td>
                          <!--                        <td>-->
                          <!--                          <input [(ngModel)]="service.time" type="text" readonly placeholder="30min" class="form-control form-control-sm">-->
                          <!--                        </td>-->
                          <td class="p-2" *ngIf="showPriceOnService">
                            <input
                              [(ngModel)]="service.service_price"
                              [disabled]="
                                !service.editable || disableServiceCard
                              "
                              (keyup)="updateServicePrice(i)"
                              class="form-control form-control-sm"
                              placeholder="service price"
                              type="number"
                            />
                          </td>
                          <td class="p-2">
                            <input
                              [(ngModel)]="service.sys_dis"
                              [disabled]="disableServiceCard"
                              readonly
                              class="form-control form-control-sm"
                              placeholder="0"
                              type="number"
                            />
                          </td>
                          <td class="p-2">
                            <div class="d-flex">
                              <div>
                                <input
                                  [(ngModel)]="service.man_dis"
                                  max="100"
                                  min="0"
                                  (keyup)="
                                    updateManualDiscount(i, $event.target.value)
                                  "
                                  [readonly]="
                                    !service.manual_discount_field_read_only
                                  "
                                  [disabled]="
                                    disableServiceCard || !service.discount_type
                                  "
                                  style="min-width: 120px"
                                  class="form-control form-control-sm"
                                  placeholder="0"
                                  type="number"
                                />
                                <small
                                  ><strong>{{
                                    service.discount_type
                                  }}</strong></small
                                >
                              </div>
                              <div style="margin-left: 2px">
                                <!--                          <select class="form-control form-control-sm" [disabled]="disableServiceCard" style="max-width: 75px;">-->
                                <select
                                  (change)="
                                    getManualDiscountDetail(
                                      $event.target.value,
                                      i
                                    )
                                  "
                                  [disabled]="
                                    disableServiceCard ||
                                    !service.billing_code ||
                                    service.billing_package_service_id !== ''
                                  "
                                  style="max-width: 20px"
                                  class="form-control form-control-sm"
                                  data-container="body"
                                  data-live-search="true"
                                  [(ngModel)]="service.man_discount_type"
                                >
                                  <option [value]="''">
                                    Select Manual Discount
                                  </option>
                                  <ng-container
                                    *ngFor="let dis of manualDiscountList"
                                  >
                                    <option [value]="dis.manual_discount">
                                      {{ dis.discount_type }}
                                    </option>
                                  </ng-container>
                                </select>
                              </div>
                            </div>
                          </td>
                          <!--                        <td class="p-2">-->
                          <!--                          <input [(ngModel)]="service.quantity" [disabled]="!service.quantity_allowed" id="serviceQuantity{{i}}" (keyup)="updateServiceQuantity(i)" class="form-control form-control-sm serviceQuantity" (keypress)="_onlyNumeric($event)" placeholder="Quantity" type="number"/>-->
                          <!--                        </td>-->

                          <td class="p-2">
                            <!--                          <input [(ngModel)]="service.quantity" [disabled]="disableServiceCard" (keyup)="updateServiceQuantity(i)" class="form-control form-control-sm serviceQuantity" style="text-align: end" (keypress)="_onlyNumeric($event)" placeholder="Quantity" type="number"/>-->
                            <input
                              [(ngModel)]="service.voucher_discount_percentage"
                              readonly
                              [disabled]="disableServiceCard"
                              class="form-control form-control-sm serviceQuantity"
                              placeholder="Voucher Discount Value"
                              type="number"
                            />
                            <small *ngIf="service.code"
                              ><strong>Code:</strong> {{ service.code }}</small
                            >
                          </td>
                          <td class="p-2" *ngIf="showPriceOnService">
                            <input
                              [(ngModel)]="service.price"
                              [readonly]="true"
                              [disabled]="disableServiceCard"
                              class="form-control form-control-sm"
                              placeholder="Total"
                              type="number"
                            />
                          </td>

                          <td class="p-2">
                            <input
                              type="text"
                              readonly
                              [disabled]="disableServiceCard"
                              [(ngModel)]="service.service_advance"
                              class="form-control form-control-sm"
                              (keypress)="_onlyNumeric($event)"
                              placeholder="Service Advance"
                            />
                          </td>
                          <td class="p-2 text-right">
                            <button
                              (click)="removeServices(i)"
                              class="btn btn-icon-sm pr-1 btn-sm btn-light btn-hover-primary btn-sm mr-2"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-sm"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path
                                      d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                      fill="#000000"
                                      fill-rule="nonzero"
                                    />
                                    <path
                                      d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                      fill="#000000"
                                      opacity="0.3"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </button>
                            <div class="dropdown dropdown-inline">
                              <button
                                type="button"
                                class="btn btn-light-primary btn-icon-sm pr-1 btn-sm"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="ki ki-bold-more-ver"></i>
                              </button>
                              <div
                                class="dropdown-menu dropdown-menu-sm dropdown-menu-right"
                              >
                                <a
                                  class="dropdown-item cursor-pointer"
                                  *ngIf="service.service_id"
                                  (click)="
                                    openSignatureModal(service.service_id)
                                  "
                                  >Signature Modal</a
                                >
                                <a
                                  class="dropdown-item cursor-pointer"
                                  *ngIf="service.service_id && showKitButton"
                                  (click)="
                                    openRecipyModal(service.service_id, i)
                                  "
                                  >Edit Recipe</a
                                >
                              </div>
                            </div>
                            <!--                          <button class="btn btn-sm pr-1 btn-clean btn-icon-sm btn-light btn-hover-primary" title="Copy Roster" [disabled]="disableServiceCard" (click)="openSignatureModal()">-->
                            <!--                            <i class="far fa-copy"></i>-->
                            <!--                          </button>-->
                            <!--                          <button class="btn btn-sm pr-1 btn-clean btn-icon-sm btn-light btn-hover-primary" title="Copy Roster" [disabled]="disableServiceCard" (click)="openRecipyModal()">-->
                            <!--                            <i class="far fa-copy"></i>-->
                            <!--                          </button>-->
                          </td>
                        </tr>
                        <!--                      <tr>-->
                        <!--                        <td></td>-->
                        <!--                        <td></td>-->
                        <!--                        <td></td>-->
                        <!--                        <td></td>-->
                        <!--                        <td></td>-->
                        <!--                        <td></td>-->
                        <!--                        <td *ngIf="showPriceOnService"><span class="font-weight-bold">Total:</span> {{servicesTotal}}</td>-->
                        <!--                        <td></td>-->
                        <!--                        <td></td>-->
                        <!--                        <td class="p-2 text-right"><button (click)="addMoreServices()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3" type='button'>-->
                        <!--                        <span class="svg-icon svg-icon-primary svg-icon-2x">&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg&ndash;&gt;<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                        <!--                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                        <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
                        <!--                                        &lt;!&ndash;                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>&ndash;&gt;-->
                        <!--                                          <path d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z" fill="#000000"/>-->
                        <!--                                      </g>-->
                        <!--                                  </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;-->
                        <!--                                    </span>-->
                        <!--                        </button></td>-->
                        <!--                      </tr>-->
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-end">
                      <button
                        [disabled]="disableServiceCard"
                        (click)="addMoreServices()"
                        class="btn btn-icon-sm pr-1 btn-sm btn-light btn-hover-primary btn-sm mr-2"
                        type="button"
                      >
                        <!--                            <span class="svg-icon svg-icon-primary svg-icon-sm">-->
                        <!--                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                        <!--                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                        <!--                                    <rect x="0" y="0" width="24" height="24"/>-->
                        <!--                                    <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"/>-->
                        <!--                                    <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>-->
                        <!--                                </g>-->
                        <!--                              </svg>-->
                        <!--                            </span>-->

                        <span class="svg-icon svg-icon-primary svg-icon-sm">
                          <i
                            class="fa fa-plus"
                            style="
                              color: #03a9f4;
                              font-size: 14px;
                              padding: 2px;
                            "
                          ></i>
                          <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg-->
                          <!--                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                          <!--                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                          <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
                          <!--                                        &lt;!&ndash;                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>&ndash;&gt;-->
                          <!--                                          <path d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z" fill="#000000"/>-->
                          <!--                                      </g>-->
                          <!--                                  </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;-->
                        </span>
                      </button>
                      <!--                      <button [disabled]="disableServiceCard"  (click)="addMoreServices()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-2" type='button'>-->
                      <!--                        <span class="svg-icon svg-icon-primary svg-icon-2x">&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg&ndash;&gt;-->
                      <!--                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                      <!--                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                      <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
                      <!--                                        &lt;!&ndash;                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>&ndash;&gt;-->
                      <!--                                          <path d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z" fill="#000000"/>-->
                      <!--                                      </g>-->
                      <!--                                  </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;-->
                      <!--                                    </span>-->
                      <!--                    </button>-->
                    </div>
                  </div>

                  <!--                  <div class="p-0 text-right">-->
                  <!--                    <span *ngIf="patient_services.length > 0" class="font-size-h4 font-weight-bold">Total: {{servicesTotal}}</span>-->
                  <!--                    <button (click)="addMoreServices()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm ml-5" type='button'><i class="fas fa-plus p-0"></i></button>-->
                  <!--                  </div>-->
                </div>
              </div>
              <!--Services Card: End-->

              <!--Credit Services: Start-->
              <div *ngIf="hideCreditCard" class="card mb-2">
                <div class="card-body" style="padding: 1.5rem !important">
                  <div
                    class="cursor-pointer"
                    data-toggle="collapse"
                    data-target="#creditServices"
                    aria-expanded="false"
                    aria-controls="history"
                  >
                    <div class="row">
                      <div class="col-md-10">
                        <h4 class="mb-0 mt-2">
                          Credit Services<span
                            style="
                              color: red;
                              font-weight: bolder;
                              margin-left: 30px;
                            "
                            >{{
                              patient?.credit_services_grand_total || 0
                            }}</span
                          >
                        </h4>
                      </div>
                      <div class="col-md-2 text-right">
                        <span>
                          <button
                            class="btn btn-sm btn-secondary toggle-btn-rotate"
                            data-toggle="collapse"
                            [attr.data-target]="'#creditServices'"
                            (click)="fetchServicesHistory(true, true)"
                          >
                            <i class="fas fa-angle-down p-0"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="creditServices" class="collapse margin-bottom-10">
                  <div class="row">
                    <div class="col-6">
                      <!--                      <h4 class="mx-0 mb-3">Previous Services</h4>-->
                    </div>
                    <!--                    <div class="col-3">-->
                    <!--                      <div class="d-flex justify-content-end">-->
                    <!--                        <span class="mt-2 mr-2">Paid</span>-->
                    <!--                        <span class="switch switch-icon">-->
                    <!--                              <label>-->
                    <!--                                <input type="checkbox" #paymentTypes name="select" [(ngModel)]="paymentTypeStatus" (click)="fetchServicesHistory(visitTypes.checked, paymentTypes.checked)" />-->
                    <!--                                <span></span>-->
                    <!--                              </label>-->
                    <!--                            </span>-->
                    <!--                        <span class="mt-2 ml-1">Unpaid</span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <div class="col-3 d-none">
                      <div class="d-flex justify-content-end">
                        <span class="mt-2 mr-2">IPD</span>
                        <span class="switch switch-icon">
                          <label>
                            <input
                              type="checkbox"
                              #visitTypes
                              name="select"
                              [(ngModel)]="visitTypeStatus"
                              (click)="
                                fetchServicesHistory(
                                  visitTypes.checked,
                                  paymentTypes.checked
                                )
                              "
                            />
                            <span></span>
                          </label>
                        </span>
                        <span class="mt-2 ml-1">OPD</span>
                      </div>
                    </div>
                  </div>

                  <div class="" id="grandparentContent" aria-expanded="true">
                    <table class="table table-hover m-0">
                      <thead>
                        <tr>
                          <th style="width: 5%"></th>
                          <!--                        <th style="width: 5%">-->
                          <!--                          <input type="checkbox" [checked]="all_invoices_checked" class="margin-right-5 cursor-pointer invoiceCheckbox" (click)="select_all_invoices($event)">-->
                          <!--                        </th>-->
                          <th style="width: 17%">Department</th>
                          <th style="width: 17%">Service Name</th>
                          <th style="width: 7%">Price</th>
                          <th style="width: 8%">Sys. Dis.(%)</th>
                          <th style="width: 8%">Man. Dis.(%)</th>
                          <th style="width: 5%">Qty</th>
                          <!--                        <th style="width: 10%">Total Discount</th>-->
                          <th style="width: 8%">Net Price</th>
                          <th style="width: 9%">Paid Amount</th>
                          <th style="width: 11%">Payable Amount</th>
                          <th style="width: 5%">Action</th>
                          <!--                        <th style="width: 13%">Doctor</th>-->
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container
                          *ngFor="
                            let invoice of patientServiceHistory;
                            let i = index
                          "
                        >
                          <!--                            <ng-container *ngIf="invoice.admissioplun_id === null">-->

                          <tr class="mainRow">
                            <th class="icon-class" style="width: 5%">
                              <button
                                class="btn btn-sm btn-secondary toggle-btn-rotate"
                                data-toggle="collapse"
                                [attr.data-target]="'.parentContent' + i"
                              >
                                <i class="fas fa-angle-down p-0"></i>
                              </button>
                            </th>
                            <!--                          <th style="width: 5%">-->
                            <!--                            &lt;!&ndash;                                  <input type="checkbox" *ngIf="invoice.payment_status === 'unpaid'" class="margin-right-5 invoiceCheckbox" [checked]="selctedInvoceIds.indexOf(invoice.id) > -1 " (click)="$event.stopPropagation();selectInvoice(invoice, $event)">&ndash;&gt;-->
                            <!--                            <input type="checkbox" *ngIf="invoice.payment_status === 'paid'" class="margin-right-5 invoiceCheckbox" [(ngModel)]="invoice.selected" (click)="$event.stopPropagation();selectInvoice()">-->
                            <!--                          </th>-->
                            <th style="width: 17%"></th>
                            <th style="width: 17%">
                              <u
                                class="cursor-pointer text-hover-primary"
                                (click)="print(invoice.id)"
                                >Print</u
                              >
                            </th>
                            <!--                          <th style="width: 10%"><u class="cursor-pointer text-hover-primary" (click)="print(invoice.id)">Print</u></th>-->
                            <th style="width: 7%">
                              {{ invoice?.base_price | number }}
                            </th>
                            <th style="width: 8%">
                              {{ invoice?.discount | number }}
                            </th>
                            <th style="width: 8%">
                              {{ invoice?.manual_discount | number }}
                            </th>
                            <th style="width: 5%">-</th>
                            <th style="width: 8%">
                              {{ invoice?.net_amount | number }}
                            </th>
                            <!--                          <th style="width: 9%">{{(invoice.base_price - invoice.approved_discount) | number}}</th>-->
                            <th style="width: 11%">
                              {{ invoice?.cash | number }}
                            </th>
                            <th style="width: 11%">
                              {{ invoice?.payable_amount | number }}
                            </th>
                            <!--                          <th style="width: 8%">{{invoice.receivable | number}}</th>-->
                            <!--                          <th style="width: 6%">{{invoice.payment_status | titlecase}}</th>-->
                            <th style="width: 5%">
                              <input
                                type="checkbox"
                                class="margin-right-5 invoiceCheckbox"
                                [(ngModel)]="invoice.selected"
                                (click)="
                                  $event.stopPropagation(); selectInvoice()
                                "
                              />
                              <!--                            <input type="checkbox" class="margin-right-5 invoiceCheckbox" [checked]="selctedInvoceIds.indexOf(invoice.id) > -1 " (click)="$event.stopPropagation();selectInvoice(invoice, $event)">-->
                              <!--                            <input type="checkbox" [checked]="all_invoices_checked" class="margin-right-5 cursor-pointer invoiceCheckbox" (click)="select_all_invoices($event)">-->
                            </th>
                          </tr>

                          <tr
                            class="collapse parentContent{{ i }} childRow"
                            *ngFor="
                              let service of invoice?.all_items;
                              let j = index
                            "
                          >
                            <td></td>
                            <td>{{ service?.service?.department_name }}</td>
                            <td>{{ service?.service?.name }}</td>
                            <!--                          <td>-->
                            <!--                            <a class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer" (click)="printInvoiceCancelItem(service.id)" *ngIf="service.cancelled === true">-->
                            <!--                                      <span class="svg-icon svg-icon-primary svg-icon-2x">&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg&ndash;&gt;<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                            <!--                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                            <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
                            <!--                                          <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>-->
                            <!--                                          <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"/>-->
                            <!--                                        </g>-->
                            <!--                                      </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;</span>-->
                            <!--                            </a>-->
                            <!--                            <span class="label label-light-primary label-inline py-4 cursor-pointer" (click)="printDialysisService(service.dialysis_id)" *ngIf="service?.service_type === 'DialysisService' && service?.dialysis_status === 'completed'">Flow Sheet</span>-->
                            <!--                            <a class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer" (click)="openPrescriptionSlip(invoice.visit_id)" *ngIf="service.is_doctor_fee_service === true">-->
                            <!--                                      <span class="svg-icon svg-icon-primary svg-icon-2x">&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg&ndash;&gt;<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                            <!--                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                            <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
                            <!--                                          <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>-->
                            <!--                                          <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"/>-->
                            <!--                                          <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"/>-->
                            <!--                                        </g>-->
                            <!--                                      </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;</span>-->
                            <!--                            </a>-->
                            <!--                          </td>-->
                            <td>{{ service.base_price }}</td>
                            <td>{{ service.discount }}</td>
                            <td>{{ service.approved_manual_discount }}</td>
                            <td>{{ service.quantity || 1 }}</td>
                            <td>
                              {{
                                service.base_price * service.quantity -
                                  service?.approved_discount
                              }}
                            </td>
                            <td>
                              <!--                            {{service.cash}}-->
                            </td>
                            <!--                          <td>{{service.approved_discount}}</td>-->
                            <td>
                              <!--                            {{(service.base_price * service.quantity) - service?.approved_discount}}-->
                            </td>

                            <!--                          <td colspan="2">-->
                            <!--                                  <span class="text-muted font-weight-bold d-block mb-2">-->
                            <!--                                    <span class="label label-lg label-light-dark label-inline cursor-pointer w-100 h-100 justify-content-start py-1 px-2 truncate-text" (click)="changePerformingType(service)">Per: {{service?.performing_doctor_name || 'Not Assigned'}}</span>-->
                            <!--                                  </span>-->
                            <!--                          </td>-->
                            <!--                          <td colspan="2">-->
                            <!--                                  <span class="text-muted font-weight-bold d-block">-->
                            <!--                                    <span class="label label-lg label-light-dark label-inline cursor-pointer w-100 h-100 justify-content-start py-1 px-2 truncate-text" (click)="changeReferringType(service)">Ref: {{service?.referring_doctor_name || 'Not Assigned'}}</span>-->
                            <!--                                  </span>-->
                            <!--                          </td>-->
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>

                  <!--                  <div class="row mt-4">-->
                  <!--                    <div class="col-md-6 text-left">-->
                  <!--                      <div class="row m-0">-->
                  <!--                        <div class="col-3">-->
                  <!--                          <label>Cash</label>-->
                  <!--                          &lt;!&ndash;                                (keyup)="updateServiceReceivableOfInvoices()"&ndash;&gt;-->
                  <!--                          &lt;!&ndash;                                (keypress)="updateServiceReceivableOfInvoices()"&ndash;&gt;-->
                  <!--                          <input class="form-control form-control-sm" type="number" (keypress)="_onlyNumeric($event)" style="max-width: 130px" [disabled]="panelType === true"-->
                  <!--                                 [(ngModel)]="servicePaidForm.cash"-->
                  <!--                                 (change)="updateServiceReceivableOfInvoices()"-->
                  <!--                                 (keyup)="updateServiceReceivableOfInvoices()"-->
                  <!--                          >-->
                  <!--                        </div>-->

                  <!--                        <div class="col-3">-->
                  <!--                          <label>Receivable %</label>-->
                  <!--                          <input class="form-control form-control-sm" disabled style="max-width: 130px"-->
                  <!--                                 [(ngModel)]="servicePaidForm.receivable_percentage">-->
                  <!--                        </div>-->
                  <!--                        <div class="col-3">-->
                  <!--                          <label>Receivable Amount:</label>-->
                  <!--                          <input class="form-control form-control-sm" disabled style="max-width: 130px"-->
                  <!--                                 [(ngModel)]="servicePaidForm.receivable">-->
                  <!--                        </div>-->

                  <!--                        <div class="col-3 ">-->
                  <!--                          <label>Ledger</label>-->
                  <!--                          <select class="form-control form-control-sm" style="max-width: 130px" id="consultantLedger"-->
                  <!--                                  [disabled]="servicePaidForm.receivable < 1  || panelType === true" [(ngModel)]="servicePaidForm.ledger">-->
                  <!--                            <option value="" [disabled]="true">Select</option>-->
                  <!--                            <ng-container *ngFor="let account of ledgersList">-->
                  <!--                              <option value="{{account.id}}" *ngIf="account.config_type === 'ledger'">{{account.name}}</option>-->
                  <!--                            </ng-container>-->
                  <!--                          </select>-->
                  <!--                        </div>-->

                  <!--                      </div>-->
                  <!--                    </div>-->

                  <!--                    <div class="col-md-3">-->
                  <!--        <span *ngIf="panelType === false">-->
                  <!--          <div class="padding-0 col-xs-12 font-size-11 text-left">-->
                  <!--            <input (click)="paymentMethodForInvoices('Cash', $event)" [checked]="servicePaidForm.mode_of_payment === 'Cash'"-->
                  <!--                   class="mr-3 invoiceCheckbox"-->
                  <!--                   type="checkbox">Cash-->
                  <!--          </div>-->
                  <!--          <div class="padding-0 col-xs-12 font-size-11 text-left">-->
                  <!--            <input (click)="paymentMethodForInvoices('Cheque', $event)" [checked]="servicePaidForm.mode_of_payment === 'Cheque'"-->
                  <!--                   class="mr-3 invoiceCheckbox" type="checkbox">Cheque-->
                  <!--          </div>-->
                  <!--          <div class="padding-0 col-xs-12 font-size-11 text-left">-->
                  <!--            <input (click)="paymentMethodForInvoices('Credit Card', $event)"-->
                  <!--                   [checked]="servicePaidForm.mode_of_payment[mode_of_payment] === 'Credit Card'"-->
                  <!--                   class="mr-3 invoiceCheckbox" type="checkbox">Credit Card-->
                  <!--            <input *ngIf="servicePaidForm.mode_of_payment == 'Credit Card'" [(ngModel)]="servicePaidForm.payment_details"-->
                  <!--                   class="form-control"-->
                  <!--                   placeholder="payment detail" type="text"/>-->
                  <!--          </div>-->
                  <!--        </span>-->
                  <!--                      <span *ngIf="panelType === true">-->
                  <!--          <div class="padding-0 col-xs-12 font-size-11 text-left">-->
                  <!--            <input (click)="paymentMethodForInvoices('Credit', $event)" [checked]="servicePaidForm.mode_of_payment === 'Credit'"-->
                  <!--                   class="mr-3 invoiceCheckbox" type="checkbox">Credit-->
                  <!--          </div>-->
                  <!--        </span>-->
                  <!--                    </div>-->

                  <!--                    <div class="col-md-3 text-right">-->
                  <!--                      <button (click)='backRoute()' class="btn btn-primary margin-left-15" id='service-btn'-->
                  <!--                              type="button">Back-->
                  <!--                      </button>-->
                  <!--                      <button (click)="unpaidToPaidServicesConfirm()" class="btn btn-primary pull-right ml-5 mr-4" id='proceed-btn' type="submit">-->
                  <!--                        Submit-->
                  <!--                      </button>-->
                  <!--                    </div>-->

                  <!--                  </div>-->
                </div>
              </div>
              <!--Credit Services: End-->

              <!--                  Calculation area-->
              <div class="card mb-3" *ngIf="showElecgiesFields">
                <div class="card-body service-card p-5">
                  <div class="row">
                    <div class="col-12 mb-4">
                      <label>Indication for Exam</label>
                      <textarea
                        class="form-control form-control-sm"
                        [(ngModel)]="userInfo"
                      ></textarea>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-center mb-4">
                        <label class="checkbox checkbox-sm">
                          <input
                            type="checkbox"
                            [(ngModel)]="anyAllergiesToIodinatedContrast"
                          />
                          <span class="mr-2"></span>Any allergies to iodinated
                          contrast?
                        </label>
                      </div>
                      <div class="mb-4">
                        <div class="d-flex align-items-center">
                          <div class="form-group">
                            <label class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                [(ngModel)]="
                                  anyContraindicationsToReceiveContrast
                                "
                              />
                              <span class="mr-2"></span>Any contraindications to
                              receive contrast?
                            </label>
                          </div>
                        </div>
                        <textarea
                          class="form-control form-control-sm"
                          [(ngModel)]="
                            anyContraindicationsToReceiveContrastTextarea
                          "
                          placeholder="Any contraindications to receive contrast?"
                          style="min-height: 70px"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card" *ngIf="!isConsultant">
                <div class="card-body service-card p-5">
                  <h4 class="m-0">Payment Info</h4>
                  <div class="row mt-5">
                    <!--                    <div class="col-2">-->
                    <!--                      <div class="row m-0">-->

                    <!--&lt;!&ndash;                        <div class="col-12 mb-5">&ndash;&gt;-->
                    <!--&lt;!&ndash;                          <label class="" style="vertical-align: middle">Payment Type</label>&ndash;&gt;-->
                    <!--&lt;!&ndash;                          <select [disabled]="paymentTypeDisabled" class="form-control form-control-sm" style="max-width: 130px" [(ngModel)]="paymentStatus" (change)="updatePaymentStatus()">&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <option value="paid">Paid</option>&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <option value="unpaid">Un-Paid</option>&ndash;&gt;-->
                    <!--&lt;!&ndash;                          </select>&ndash;&gt;-->
                    <!--&lt;!&ndash;                        </div>&ndash;&gt;-->

                    <!--                        <div class="col-12 mb-4">-->
                    <!--                          <label>Payment Mode</label>-->
                    <!--&lt;!&ndash;                          <select class="form-control form-control-sm" style="max-width: 130px" [(ngModel)]="serviceForm.mode_of_payment" (change)="paymentMethods($event.target.value)" [disabled]="paymentStatus === 'unpaid'">&ndash;&gt;-->
                    <!--                          <select class="form-control form-control-sm" style="max-width: 130px" [(ngModel)]="serviceForm.mode_of_payment" (change)="paymentMethods($event.target.value)">-->
                    <!--                            &lt;!&ndash;                            <option *ngIf="panelType === false" value="Cash">Cash</option>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            <option *ngIf="panelType === false" value="Cheque">Cheque</option>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            <option *ngIf="panelType === false" value="Credit Card">Credit Card</option>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            <option *ngIf="panelType === true" value="Credit">Credit</option>&ndash;&gt;-->
                    <!--                            <option value="Cash">Cash</option>-->
                    <!--&lt;!&ndash;                            <option value="Cheque">Cheque</option>&ndash;&gt;-->
                    <!--                            <option value="Credit Card">Credit Card</option>-->
                    <!--&lt;!&ndash;                            <option *ngIf="paymentStatus === 'unpaid'" value="Credit">Credit</option>&ndash;&gt;-->
                    <!--                          </select>-->
                    <!--                        </div>-->
                    <!--                        <div class="col-12 row">-->
                    <!--                          <img style="width: auto; height: 30px" class="ml-2" src="assets/images/bop.png">-->
                    <!--                          <img style="width: auto; height: 30px" class="ml-2" src="assets/images/hbl.jpg">-->
                    <!--                          <img style="width: auto; height: 30px" class="ml-2" src="assets/images/UBL.png">-->

                    <!--                        </div>-->
                    <!--                        <div class="col-12" *ngIf="serviceForm.mode_of_payment == 'Credit Card'">-->
                    <!--                          <input [(ngModel)]="serviceForm.payment_details"-->
                    <!--                                 class="form-control form-control-sm" placeholder="payment detail" type="text"/>-->
                    <!--                        </div>-->

                    <!--                        &lt;!&ndash;                        <div class="col-12">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                          <span *ngIf="panelType === false">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          <div class="p-0 col-12 font-size-11 text-left">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;            <input (click)="paymentMethod('Cash', $event)" [checked]="serviceForm.mode_of_payment === 'Cash'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   [disabled]="paymentStatus === 'unpaid'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   class="mr-2 invoiceCheckbox"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   type="checkbox">Cash&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          </div>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          <div class="p-0 col-12 font-size-11 text-left">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;            <input (click)="paymentMethod('Cheque', $event)" [checked]="serviceForm.mode_of_payment === 'Cheque'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   [disabled]="paymentStatus === 'unpaid'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   class="mr-2 invoiceCheckbox" type="checkbox">Cheque&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          </div>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          <div class="p-0 col-12 font-size-11 text-left">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;            <input (click)="paymentMethod('Credit Card', $event)"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   [checked]="serviceForm.mode_of_payment === 'Credit Card'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   [disabled]="paymentStatus === 'unpaid'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   class="mr-2 invoiceCheckbox" type="checkbox">Credit Card&ndash;&gt;-->
                    <!--                        &lt;!&ndash;            <input *ngIf="serviceForm.mode_of_payment == 'Credit Card'" [(ngModel)]="serviceForm.payment_details"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   class="form-control"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   placeholder="payment detail" type="text"/>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          </div>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;        </span>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                          <span *ngIf="panelType === true">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          <div class="p-0 col-12 font-size-11 text-left">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;            <input (click)="paymentMethod('Credit', $event)" [checked]="serviceForm.mode_of_payment === 'Credit'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   class="mr-2 invoiceCheckbox" type="checkbox">Credit&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          </div>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;        </span>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                        </div>&ndash;&gt;-->

                    <!--                      </div>-->

                    <!--                    </div>-->

                    <div class="col-11 text-left">
                      <ng-container *ngIf="showPriceOnService">
                        <!--                      <div class="row mx-0 my-2">-->
                        <!--                        <div class="col-4 "  style="margin-top: 5px"><label class="font-weight-bold" style="vertical-align: middle">Payment Type:</label></div>-->
                        <!--                        <div class="col-8 ">-->
                        <!--                          <select class="form-control form-control-sm" style="max-width: 130px" [(ngModel)]="paymentStatus" (change)="updatePaymentStatus()">-->
                        <!--                            <option value="paid">Paid</option>-->
                        <!--                            <option value="unpaid">Un-Paid</option>-->
                        <!--                          </select>-->
                        <!--                        </div>-->
                        <!--                      </div>-->
                        <div class="row">
                          <div class="col-3">
                            <div class="row">
                              <div class="col-8">
                                <label
                                  class="font-weight-bold"
                                  style="font-size: 1.25rem"
                                  >Grand Total:</label
                                >
                              </div>
                              <div class="col-4">
                                <label style="font-size: 1.5rem; color: blue"
                                  ><strong>{{
                                    servicesGrandTotal +
                                      consultantionTotal +
                                      productsTotal +
                                      selectedCreditServiceTotal
                                      | number: "1.0-0"
                                  }}</strong></label
                                >
                              </div>
                              <div class="col-8 mt-2">Current Services:</div>
                              <div class="col-4 mt-2">
                                {{ servicesGrandTotal | number: "1.0-0" }}
                              </div>
                              <div class="col-8 mt-2">Credit Services:</div>
                              <div class="col-4 mt-2">
                                {{
                                  selectedCreditServiceTotal | number: "1.0-0"
                                }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-3"
                            style="border-left: 1px solid #00000045"
                          >
                            <div class="row">
                              <div class="col-8">
                                <label
                                  class="font-weight-bold"
                                  style="font-size: 1.25rem"
                                  >Discount:</label
                                >
                              </div>
                              <div class="col-4">
                                <label
                                  style="font-size: 1.5rem; color: yellowgreen"
                                  ><strong>{{
                                    servicesGrandTotal - serviceForm.cash
                                      | number: "1.0-0"
                                  }}</strong></label
                                >
                                <!--                              <label style="font-size: 1.5rem; color: yellowgreen"><strong>{{serviceForm.discount | number: '1.0-0'}}</strong></label>-->
                              </div>
                              <div class="col-8 mt-2">
                                Client Cat. Discount.({{
                                  this.serviceForm.systemDiscountPercentage
                                    | number: "1.0-2"
                                }}%)
                              </div>

                              <div class="col-4 mt-2">
                                {{ this.sysDisAmount | number: "1.0-0" }}
                              </div>
                              <!--                              <div class="col-5 mt-2">{{this.serviceForm.discount_percentage}}</div>-->
                              <div class="col-8 mt-2">
                                Manual Discount({{
                                  this.serviceForm.manualDiscountPercentage
                                    | number: "1.0-2"
                                }}%)
                              </div>
                              <div class="col-4 mt-2">
                                {{ this.manDisAmount | number: "1.0-0" }}
                              </div>
                              <div class="col-8 mt-2">
                                Service Voucher({{
                                  this.serviceForm.voucherDiscountPercentage
                                    | number: "1.0-2"
                                }}%)
                              </div>
                              <div class="col-4 mt-2">
                                {{ this.vocDisAmount | number: "1.0-0" }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-6"
                            style="border-left: 1px solid #00000045"
                          >
                            <div class="row">
                              <div class="col-3">
                                <label
                                  class="font-weight-bold"
                                  style="font-size: 1.25rem"
                                  >Net Amount:</label
                                >
                              </div>
                              <div class="col-3">
                                <label style="font-size: 1.5rem; color: blue"
                                  ><strong>{{
                                    serviceForm.cash +
                                      selectedCreditServiceTotal
                                      | number: "1.0-0"
                                  }}</strong></label
                                >
                              </div>
                              <div class="col-3" style="margin-top: 5px">
                                <label
                                  class="font-weight-bold"
                                  style="vertical-align: middle"
                                  >Payment Mode:</label
                                >
                              </div>
                              <div class="col-3">
                                <select
                                  class="form-control form-control-sm"
                                  (change)="resetCard()"
                                  [(ngModel)]="serviceForm.mode_of_payment"
                                  style="max-width: 130px"
                                >
                                  <option [ngValue]="'cash'" selected>
                                    Cash
                                  </option>
                                  <option
                                    [ngValue]="'credit_card'"
                                    [disabled]="
                                      serviceForm.totalServiceAdvance >= 1
                                    "
                                  >
                                    Card
                                  </option>
                                  <option [ngValue]="'online'">Online</option>
                                  <option [ngValue]="'mix'">Mix Payment</option>
                                </select>
                              </div>
                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  serviceForm.mode_of_payment === 'cash' ||
                                  serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                <label>Cash</label>
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  serviceForm.mode_of_payment === 'cash' ||
                                  serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.cashAmount"
                                  placeholder="0"
                                />
                              </div>
                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  serviceForm.mode_of_payment ===
                                    'credit_card' ||
                                  serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                <label>Card</label>
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  serviceForm.mode_of_payment ===
                                    'credit_card' ||
                                  serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.creditCardAmount"
                                  placeholder="0"
                                />
                              </div>
                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  serviceForm.mode_of_payment === 'online' ||
                                  serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                <label>Online</label>
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  serviceForm.mode_of_payment === 'online' ||
                                  serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.onlineAmount"
                                  placeholder="0"
                                />
                              </div>
                              <div
                                class="col-3 mt-5"
                                *ngIf="serviceForm.mode_of_payment === 'mix'"
                              >
                                <label>Wallet</label>
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="serviceForm.mode_of_payment === 'mix'"
                              >
                                <input
                                  type="number"
                                  [min]="0"
                                  placeholder="0"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.walletAmount"
                                  (keyup)="checkWalletLimit()"
                                />
                              </div>
                              <!--                            <div class="col-3 mt-5">-->
                              <!--                              <label>Balance</label>-->
                              <!--                            </div>-->
                              <!--                              <div class="col-3 mt-5 pl-6">{{(serviceForm.cash - (serviceForm.cashAmount + serviceForm.creditCardAmount + serviceForm.onlineAmount + serviceForm.walletAmount)) || 0 }}</div>-->
                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  this.serviceForm.mode_of_payment ===
                                    'credit_card' ||
                                  this.serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                Card Bank
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  this.serviceForm.mode_of_payment ===
                                    'credit_card' ||
                                  this.serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                <select
                                  class="form-control form-control-sm selectPickerBankDetail dropdown-menu-down"
                                  [(ngModel)]="serviceForm.cardBank"
                                  data-container="body"
                                  data-live-search="true"
                                  name="bank_name"
                                >
                                  <option [value]="''">Select Bank</option>
                                  <ng-container *ngFor="let bank of bankList">
                                    <option [ngValue]="bank.id">
                                      {{ bank.bank_name }}
                                    </option>
                                  </ng-container>
                                </select>
                              </div>

                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  this.serviceForm.mode_of_payment ===
                                    'online' ||
                                  this.serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                Online Bank
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  this.serviceForm.mode_of_payment ===
                                    'online' ||
                                  this.serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                <select
                                  class="form-control form-control-sm selectPickerBankDetail dropdown-menu-down"
                                  [(ngModel)]="serviceForm.onlineBank"
                                  data-container="body"
                                  data-live-search="true"
                                  name="bank_name"
                                  id="bank_name"
                                >
                                  <option [ngValue]="''">Select Bank</option>
                                  <ng-container *ngFor="let bank of bankList">
                                    <option [ngValue]="bank.id">
                                      {{ bank.bank_name }}
                                    </option>
                                  </ng-container>
                                </select>
                              </div>

                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  this.serviceForm.mode_of_payment ===
                                    'credit_card' ||
                                  this.serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                Card Bank Ref #
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  this.serviceForm.mode_of_payment ===
                                    'credit_card' ||
                                  this.serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                <input
                                  type="text"
                                  placeholder="Reference No."
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.cardBankRef"
                                />
                              </div>

                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  this.serviceForm.mode_of_payment ===
                                    'online' ||
                                  this.serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                Online Bank Ref #
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  this.serviceForm.mode_of_payment ===
                                    'online' ||
                                  this.serviceForm.mode_of_payment === 'mix'
                                "
                              >
                                <input
                                  type="text"
                                  placeholder="Reference No."
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.onlineBankRef"
                                />
                              </div>
                              <div class="col-3 mt-5">
                                <label>Service Advance</label>
                              </div>
                              <div class="col-3 mt-5 pl-6">
                                {{
                                  serviceForm.totalServiceAdvance || 0
                                    | number: "1.0-0"
                                }}
                                <!--                                <input type="number" placeholder="0" class="form-control form-control-sm" [(ngModel)]="payableAmount" >-->
                              </div>
                              <div class="col-3 mt-5">
                                <label>Payable</label>
                              </div>
                              <div class="col-3 mt-5 pl-6">
                                {{
                                  serviceForm.cash +
                                    selectedCreditServiceTotal -
                                    (serviceForm.cashAmount +
                                      serviceForm.creditCardAmount +
                                      serviceForm.onlineAmount +
                                      serviceForm.walletAmount +
                                      serviceForm.totalServiceAdvance) || 0
                                    | number: "1.0-0"
                                }}
                                <!--                                <input type="number" placeholder="0" class="form-control form-control-sm" [(ngModel)]="payableAmount" >-->
                              </div>

                              <!--                                <div class="col-3 mt-5">Tax(%)</div>-->
                              <!--                                <div class="col-3 mt-3">-->
                              <!--                                  <select class="form-control tax" id="tax" data-live-search="true" data-container="body" [(ngModel)]="serviceForm.taxType">-->
                              <!--                                  <option value="" disabled>select</option>-->
                              <!--                                  <option value="tax">Car Tax</option>-->
                              <!--                                  <option value="tax">Sale Tax</option>-->
                              <!--                                  <option value="tax">Vat Tax</option>-->
                              <!--                                  <option value="tax">Pet Tax</option>-->
                              <!--                                </select>-->
                              <!--                                </div>-->
                              <!--                              <div class="col-3 mt-5">Tax Amount</div>-->
                              <!--                              <div class="col-3 mt-5">-->
                              <!--                                <input type="text" placeholder="Tax Amount"  class="form-control form-control-sm" [(ngModel)]="serviceForm.taxAmount">-->
                              <!--                              </div>-->
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>

                    <div
                      class="col-1 text-right"
                      style="border-left: 1px solid #00000045"
                    >
                      <div class="row">
                        <!--                        <div class="col-2 "><label class="font-weight-bold" style="font-size: 1.1rem">Wallet Amount:</label></div>-->
                        <!--                        <div class="col-12 mb-5 d-flex">-->
                        <!--                          <button class="btn btn-sm btn-primary" type="button" (click)="openWalletModal()"><div>Wallet</div><div>3,000</div></button>-->
                        <!--                          <button class="btn btn-sm btn-info" style="line-height: 10px" (click)="openWalletModal()">-->
                        <!--                              <div>-->
                        <!--&lt;!&ndash;                                <img src="assets/icons/ewallet.png"/>&ndash;&gt;-->
                        <!--                                Wallet-->
                        <!--                              </div>-->
                        <!--                              <div style="font-size: 10px">30,000</div>-->
                        <!--                          </button>-->
                        <!--                        </div>-->
                        <div class="col-12 mb-5 d-flex">
                          <!--                          <button class="btn btn-sm btn-primary" type="button" (click)="openWalletModal()"><div>Wallet</div><div>3,000</div></button>-->
                          <button
                            class="btn btn-primary"
                            *ngIf="showSubmit"
                            (click)="addServiceConfirm()"
                          >
                            <i
                              class="fas fa-sign-in-alt"
                              style="font-size: 11px"
                            ></i
                            >Submit
                          </button>
                          <!--                          <button class="btn btn-primary" *ngIf="!showSubmit"><i class="fas fa-save pl-2" style="font-size: 15px"></i>Save</button>-->
                        </div>
                        <div class="col-12 mb-5 d-flex">
                          <!--                          <button class="btn btn-sm btn-primary" type="button" (click)="openWalletModal()"><div>Wallet</div><div>3,000</div></button>-->
                          <button
                            class="btn d-flex btn-success"
                            style="line-height: 12px"
                            (click)="openWalletModal()"
                          >
                            <span class="mt-2"
                              ><i
                                class="far fa-money-bill-alt fas"
                                style="font-size: 12px"
                              ></i
                            ></span>
                            <span
                              class="d-flex flex-column align-items-start ms-2"
                            >
                              <span class="fs-3 fw-bold">Wallet</span>
                              <!--                            <span class="fs-7 pl-2" style="font-size: 9px">{{patient?.wallet.amount + totalServiceAdvance|| 0}}</span>-->
                              <span class="fs-7 pl-2" style="font-size: 9px">{{
                                patient?.wallet?.amount || 0
                              }}</span>
                            </span>
                          </button>
                        </div>
                        <div class="col-12 mb-5 d-flex">
                          <!--                          <button class="btn btn-sm btn-primary" type="button" (click)="openWalletModal()"><div>Wallet</div><div>3,000</div></button>-->
                          <button
                            class="btn btn-danger px-6"
                            (click)="goBack()"
                          >
                            <i
                              class="fas fa-arrow-left"
                              style="font-size: 11px"
                            ></i
                            >Back
                          </button>
                        </div>
                        <!--                        <div class="col-12 mb-5">-->
                        <!--                          <button (click)="addServiceConfirm()" class="btn btn-sm btn-primary" type="submit">Submit</button>-->
                        <!--                        </div>-->
                        <!--                        <div class="col-2 mt-2"><label class="font-weight-bold" style="font-size: 1.1rem">Credit Amount:</label></div>-->
                        <!--                        <div class="col-6">-->
                        <!--                          <button class="btn btn-sm btn-primary" type="button">Pay</button>-->
                        <!--                        </div>-->
                        <!--                        <div class="col-12">-->
                        <!--                          <button class="btn btn-sm btn-warning" type="button" [routerLink]="['/client/registration-v2']">Back</button>-->
                        <!--                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="isConsultant">
                <div class="card-body service-card p-5">
                  <!--                  <h4 class="m-0">Payment Info</h4>-->
                  <div class="row mt-5">
                    <div class="col-12 mb-5 text-right">
                      <button class="btn btn-primary" (click)="saveAsDraft()">
                        <i
                          class="fas fa-sign-in-alt"
                          style="font-size: 11px"
                        ></i
                        >Save as Draft
                      </button>
                      <button class="btn btn-danger ml-3" (click)="goBack()">
                        <i class="fas fa-arrow-left" style="font-size: 11px"></i
                        >Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- hHistory tab -->
            <div
              class="tab-pane fade"
              id="history_tab"
              role="tabpanel"
              aria-labelledby="history_tab"
            >
              <!--                  previous history area-->
              <div class="card mb-3">
                <div class="card-body service-card p-5">
                  <div class="row">
                    <div class="col-6">
                      <h4 class="mx-0 mb-3">Previous Services</h4>
                    </div>
                    <!--                    <div class="col-3">-->
                    <!--                      <div class="d-flex justify-content-end">-->
                    <!--                        <span class="mt-2 mr-2">Paid</span>-->
                    <!--                        <span class="switch switch-icon">-->
                    <!--                              <label>-->
                    <!--                                <input type="checkbox" #paymentTypes name="select" [(ngModel)]="paymentTypeStatus" (click)="fetchServicesHistory(true, true)" />-->
                    <!--                                <span></span>-->
                    <!--                              </label>-->
                    <!--                            </span>-->
                    <!--                        <span class="mt-2 ml-1">Unpaid</span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-3 d-none">-->
                    <!--                      <div class="d-flex justify-content-end">-->
                    <!--                        <span class="mt-2 mr-2">IPD</span>-->
                    <!--                        <span class="switch switch-icon">-->
                    <!--                              <label>-->
                    <!--                                <input type="checkbox" #visitTypes name="select" [(ngModel)]="visitTypeStatus" (click)="fetchServicesHistory(visitTypes.checked, paymentTypes.checked)" />-->
                    <!--                                <span></span>-->
                    <!--                              </label>-->
                    <!--                            </span>-->
                    <!--                        <span class="mt-2 ml-1">OPD</span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>

                  <div class="" id="grandparentContent" aria-expanded="true">
                    <table class="table table-hover m-0">
                      <thead>
                        <tr>
                          <th style="width: 6%"></th>
                          <th style="width: 5%">
                            <!--                          <input type="checkbox" [checked]="all_invoices_checked" class="margin-right-5 cursor-pointer invoiceCheckbox" (click)="select_all_invoices($event)">-->
                          </th>
                          <th style="width: 5%"></th>
                          <th style="width: 10%"></th>
                          <th style="width: 6%">Base Price</th>
                          <th style="width: 5%">Qty</th>
                          <th style="width: 8%">System Discount</th>
                          <th style="width: 8%">Manual Discount</th>
                          <th style="width: 7%">Total Discount</th>
                          <th style="width: 7%">Net Amount</th>
                          <th style="width: 6%">Cash</th>
                          <th style="width: 8%">Receivable</th>
                          <th style="width: 6%">Status</th>
                          <th style="width: 13%">Doctor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container
                          *ngFor="
                            let invoice of patientServiceHistory;
                            let i = index
                          "
                        >
                          <!--                            <ng-container *ngIf="invoice.admissioplun_id === null">-->

                          <tr class="mainRow">
                            <th class="icon-class" style="width: 6%">
                              <button
                                class="btn btn-sm btn-secondary toggle-btn-rotate"
                                data-toggle="collapse"
                                [attr.data-target]="'.parentContent' + i"
                              >
                                <i class="fas fa-angle-down p-0"></i>
                              </button>
                            </th>
                            <th style="width: 5%">
                              <!--                                  <input type="checkbox" *ngIf="invoice.payment_status === 'unpaid'" class="margin-right-5 invoiceCheckbox" [checked]="selctedInvoceIds.indexOf(invoice.id) > -1 " (click)="$event.stopPropagation();selectInvoice(invoice, $event)">-->
                              <input
                                type="checkbox"
                                *ngIf="invoice.payment_status === 'unpaid'"
                                class="margin-right-5 invoiceCheckbox"
                                [(ngModel)]="invoice.selected"
                                (click)="
                                  $event.stopPropagation(); selectInvoice()
                                "
                              />
                            </th>
                            <th style="width: 5%"></th>
                            <th style="width: 10%">
                              <u
                                class="cursor-pointer text-hover-primary"
                                (click)="print(invoice.id)"
                                >Print</u
                              >
                            </th>
                            <th style="width: 6%">
                              {{ invoice.base_price | number }}
                            </th>
                            <th style="width: 5%">-</th>
                            <th style="width: 8%">
                              {{ invoice?.discount | number }}
                            </th>
                            <th style="width: 8%">
                              {{ invoice?.approved_manual_discount | number }}
                            </th>
                            <th style="width: 7%">
                              {{ invoice?.approved_discount | number }}
                            </th>
                            <th style="width: 7%">
                              {{
                                invoice.base_price - invoice.approved_discount
                                  | number
                              }}
                            </th>
                            <th style="width: 6%">
                              {{ invoice.cash | number }}
                            </th>
                            <th style="width: 8%">
                              {{ invoice.receivable | number }}
                            </th>
                            <th style="width: 6%">
                              {{ invoice.payment_status | titlecase }}
                            </th>
                            <th style="width: 13%"></th>
                          </tr>

                          <tr
                            class="collapse parentContent{{ i }} childRow"
                            *ngFor="
                              let service of invoice?.all_items;
                              let j = index
                            "
                          >
                            <td colspan="3">{{ service?.service?.name }}</td>
                            <td>
                              <a
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                                (click)="printInvoiceCancelItem(service.id)"
                                *ngIf="service.cancelled === true"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                      <path
                                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                        fill="#000000"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="10"
                                        y="9"
                                        width="7"
                                        height="2"
                                        rx="1"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="7"
                                        y="9"
                                        width="2"
                                        height="2"
                                        rx="1"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="7"
                                        y="13"
                                        width="2"
                                        height="2"
                                        rx="1"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="10"
                                        y="13"
                                        width="7"
                                        height="2"
                                        rx="1"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="7"
                                        y="17"
                                        width="2"
                                        height="2"
                                        rx="1"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="10"
                                        y="17"
                                        width="7"
                                        height="2"
                                        rx="1"
                                      />
                                    </g></svg
                                  ><!--end::Svg Icon--></span
                                >
                              </a>
                              <span
                                class="label label-light-primary label-inline py-4 cursor-pointer"
                                (click)="
                                  printDialysisService(service.dialysis_id)
                                "
                                *ngIf="
                                  service?.service_type === 'DialysisService' &&
                                  service?.dialysis_status === 'completed'
                                "
                                >Flow Sheet</span
                              >
                              <a
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                                (click)="openPrescriptionSlip(invoice.visit_id)"
                                *ngIf="service.is_doctor_fee_service === true"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                      <path
                                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                        fill="#000000"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="10"
                                        y="9"
                                        width="7"
                                        height="2"
                                        rx="1"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="7"
                                        y="9"
                                        width="2"
                                        height="2"
                                        rx="1"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="7"
                                        y="13"
                                        width="2"
                                        height="2"
                                        rx="1"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="10"
                                        y="13"
                                        width="7"
                                        height="2"
                                        rx="1"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="7"
                                        y="17"
                                        width="2"
                                        height="2"
                                        rx="1"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="10"
                                        y="17"
                                        width="7"
                                        height="2"
                                        rx="1"
                                      />
                                    </g></svg
                                  ><!--end::Svg Icon--></span
                                >
                              </a>
                            </td>
                            <td>{{ service.base_price }}</td>
                            <td>{{ service.quantity }}</td>
                            <td>{{ service.discount }}</td>
                            <td>{{ service.approved_manual_discount }}</td>
                            <td>{{ service.approved_discount }}</td>
                            <td>
                              {{
                                service.base_price * service.quantity -
                                  service?.approved_discount
                              }}
                            </td>
                            <td colspan="2">
                              <span
                                class="text-muted font-weight-bold d-block mb-2"
                              >
                                <span
                                  class="label label-lg label-light-dark label-inline cursor-pointer w-100 h-100 justify-content-start py-1 px-2 truncate-text"
                                  (click)="changePerformingType(service)"
                                  >Per:
                                  {{
                                    service?.performing_doctor_name ||
                                      "Not Assigned"
                                  }}</span
                                >
                              </span>
                            </td>
                            <td colspan="2">
                              <span class="text-muted font-weight-bold d-block">
                                <span
                                  class="label label-lg label-light-dark label-inline cursor-pointer w-100 h-100 justify-content-start py-1 px-2 truncate-text"
                                  (click)="changeReferringType(service)"
                                  >Ref:
                                  {{
                                    service?.referring_doctor_name ||
                                      "Not Assigned"
                                  }}</span
                                >
                              </span>
                            </td>
                          </tr>

                          <!--                            </ng-container>-->
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!--   previous history area calculation-->
              <!--              <div class="card">-->
              <!--                <div class="card-body service-card p-5">-->
              <!--                  <div class="row">-->
              <!--                    <div class="col-md-6 text-left">-->
              <!--                      <div class="row m-0">-->
              <!--                        <div class="col-3">-->
              <!--                          <label>Cash</label>-->
              <!--                          &lt;!&ndash;                                (keyup)="updateServiceReceivableOfInvoices()"&ndash;&gt;-->
              <!--                          &lt;!&ndash;                                (keypress)="updateServiceReceivableOfInvoices()"&ndash;&gt;-->
              <!--                          <input class="form-control form-control-sm" type="number" (keypress)="_onlyNumeric($event)" style="max-width: 130px" [disabled]="panelType === true"-->
              <!--                                 [(ngModel)]="servicePaidForm.cash"-->
              <!--                                 (change)="updateServiceReceivableOfInvoices()"-->
              <!--                                 (keyup)="updateServiceReceivableOfInvoices()"-->
              <!--                          >-->
              <!--                        </div>-->

              <!--                        <div class="col-3">-->
              <!--                          <label>Receivable %</label>-->
              <!--                          <input class="form-control form-control-sm" disabled style="max-width: 130px"-->
              <!--                                 [(ngModel)]="servicePaidForm.receivable_percentage">-->
              <!--                        </div>-->
              <!--                        <div class="col-3">-->
              <!--                          <label>Receivable Amount:</label>-->
              <!--                          <input class="form-control form-control-sm" disabled style="max-width: 130px"-->
              <!--                                 [(ngModel)]="servicePaidForm.receivable">-->
              <!--                        </div>-->

              <!--                        <div class="col-3 ">-->
              <!--                          <label>Ledger</label>-->
              <!--                          <select class="form-control form-control-sm" style="max-width: 130px" id="consultantLedger"-->
              <!--                                  [disabled]="servicePaidForm.receivable < 1  || panelType === true" [(ngModel)]="servicePaidForm.ledger">-->
              <!--                            <option value="" [disabled]="true">Select</option>-->
              <!--                            <ng-container *ngFor="let account of ledgersList">-->
              <!--                              <option value="{{account.id}}" *ngIf="account.config_type === 'ledger'">{{account.name}}</option>-->
              <!--                            </ng-container>-->
              <!--                          </select>-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                    </div>-->

              <!--                    <div class="col-md-3">-->
              <!--        <span *ngIf="panelType === false">-->
              <!--          <div class="padding-0 col-xs-12 font-size-11 text-left">-->
              <!--            <input (click)="paymentMethodForInvoices('Cash', $event)" [checked]="servicePaidForm.mode_of_payment === 'Cash'"-->
              <!--                   class="mr-3 invoiceCheckbox"-->
              <!--                   type="checkbox">Cash-->
              <!--          </div>-->
              <!--          <div class="padding-0 col-xs-12 font-size-11 text-left">-->
              <!--            <input (click)="paymentMethodForInvoices('Cheque', $event)" [checked]="servicePaidForm.mode_of_payment === 'Cheque'"-->
              <!--                   class="mr-3 invoiceCheckbox" type="checkbox">Cheque-->
              <!--          </div>-->
              <!--          <div class="padding-0 col-xs-12 font-size-11 text-left">-->
              <!--            <input (click)="paymentMethodForInvoices('Credit Card', $event)"-->
              <!--                   [checked]="servicePaidForm.mode_of_payment === 'Credit Card'"-->
              <!--                   class="mr-3 invoiceCheckbox" type="checkbox">Credit Card-->
              <!--            <input *ngIf="servicePaidForm.mode_of_payment == 'Credit Card'" [(ngModel)]="servicePaidForm.payment_details"-->
              <!--                   class="form-control"-->
              <!--                   placeholder="payment detail" type="text"/>-->
              <!--          </div>-->
              <!--        </span>-->
              <!--                      <span *ngIf="panelType === true">-->
              <!--          <div class="padding-0 col-xs-12 font-size-11 text-left">-->
              <!--            <input (click)="paymentMethodForInvoices('Credit', $event)" [checked]="servicePaidForm.mode_of_payment === 'Credit'"-->
              <!--                   class="mr-3 invoiceCheckbox" type="checkbox">Credit-->
              <!--          </div>-->
              <!--        </span>-->
              <!--                    </div>-->

              <!--                    <div class="col-md-3 text-right">-->
              <!--                      <button (click)='backRoute()' class="btn btn-primary margin-left-15" id='service-btn'-->
              <!--                              type="button">Back-->
              <!--                      </button>-->
              <!--                      <button (click)="unpaidToPaidServicesConfirm()" class="btn btn-primary pull-right ml-5" id='proceed-btn' type="submit">-->
              <!--                        Submit-->
              <!--                      </button>-->
              <!--                    </div>-->

              <!--                  </div>-->

              <!--                </div>-->
              <!--              </div>-->
            </div>
            <!--Product Card: Start-->
            <div
              class="tab-pane fade"
              id="product_tab"
              role="tabpanel"
              aria-labelledby="product_tab"
            >
              <!-- Patient Card : Start -->
              <div class="card mb-4">
                <div class="card-body px-2 py-0">
                  <div class="mt-0 pb-0" style="padding: 8px">
                    <h6 class="mb-0">Client Information</h6>
                  </div>
                  <div class="d-flex">
                    <!--begin: Pic-->
                    <div
                      class="flex-shrink-0 mr-2 mt-lg-0 mt-3"
                      style="padding: 8px"
                    >
                      <div class="symbol symbol-45 symbol-lg-45">
                        <img
                          class="medical_fitness_certificate_image"
                          src="assets/media/users/blank.png"
                          alt="image"
                        />
                      </div>
                    </div>
                    <!--end::Pic-->
                    <!--begin::Info-->
                    <div class="flex-grow-1">
                      <!--begin::Title-->
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex">
                          <a
                            class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                            >{{ patient?.first_name }}
                            {{ patient?.middle_name || "" }}
                            {{ patient?.last_name }}</a
                          >
                          <!--                      {{patient?.first_name + ' ' + patient?.last_name}}-->
                          <a>
                            <i
                              class="flaticon2-correct text-success font-size-h5"
                            ></i>
                          </a>
                        </div>
                      </div>
                      <!--end::Title-->
                      <!--begin::Content-->
                      <div
                        class="d-flex flex-wrap justify-content-between mt-1"
                      >
                        <div class="d-flex flex-column flex-grow-1 pr-8">
                          <div class="d-flex flex-wrap mb-4">
                            <a
                              class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                            >
                              <i
                                class="flaticon2-placeholder mr-2 font-size-lg"
                              ></i
                              >{{ patient?.mrn }}</a
                            >
                            <!--                        {{patient?.mrn}}-->
                            <a
                              class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                            >
                              <i class="fas fa-male mr-2 font-size-lg"></i
                              >{{ patient?.gender | titlecase }}</a
                            >
                            <!--                        {{patient?.gender}}-->
                            <a
                              class="text-dark-50 text-hover-primary mr-5 font-weight-bold"
                            >
                              <i class="fa fa-birthday-cake mr-2 font-size-lg">
                                {{ patient?.age }}</i
                              ></a
                            >

                            <!--                          <span>{{ ' ' + patients?.admission[patients?.admission.length - 1].ward.title}} ({{patients?.bed_name | titlecase}}) </span>-->
                            <!--                        {{patient?.age}}-->
                          </div>

                          <!--        <span class="font-weight-bold text-dark-50">{{patient?.patient_type?.name | titlecase}}</span>-->
                          <!--                      {{patient?.patient_type?.name | titlecase}}-->
                        </div>
                      </div>
                      <!--end::Content-->
                    </div>
                    <!--end::Info-->
                    <!--                    <div class="d-flex">-->
                    <!--                      <div class="px-3 py-3">Booking:</div>-->
                    <!--                      <div style="min-width: 200px">-->
                    <!--                        <select class="form-control selectpicker"  data-live-search="true" data-container="body">-->
                    <!--                          <option value="" disabled>select</option>-->
                    <!--                          <option value="15994" >Booking 1</option>-->
                    <!--                          <option value="15993" >Booking 1</option>-->
                    <!--                          &lt;!&ndash;                          <option value="booking_1" ><span (click)="fetchServicesDeptBooking2('')">Booking 2</span></option>&ndash;&gt;-->
                    <!--                        </select>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>

              <!--              <app-patient-card-->
              <div class="card mb-2">
                <div class="card-body" style="padding: 1.5rem !important">
                  <div
                    class="cursor-pointer"
                    data-toggle="collapse"
                    data-target="#Products"
                    aria-expanded="false"
                    aria-controls="history"
                  >
                    <div class="row">
                      <div class="col-md-10">
                        <h4>Products</h4>
                      </div>
                      <div class="col-md-2 text-right">
                        <span>
                          <button
                            class="btn btn-sm btn-secondary toggle-btn-rotate"
                            data-toggle="collapse"
                            [attr.data-target]="'#Products'"
                          >
                            <i class="fas fa-angle-down p-0"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="Products" class="collapse margin-bottom-10">
                  <div class="px-5 pt-0">
                    <div class="">
                      <table
                        class="table serviceTable m-0"
                        *ngIf="product.length > 0"
                      >
                        <thead>
                          <tr>
                            <th class="p-2" style="width: 20%">Product Type</th>
                            <th class="p-2" style="width: 25%">Product Name</th>
                            <th class="p-2" style="width: 15%">UOM</th>
                            <th class="p-2" style="width: 10%">Unit Price</th>
                            <th class="p-2" style="width: 10%">Qty</th>
                            <th class="p-2" style="width: 15%">Total Price</th>
                            <th class="p-2" style="width: 5%">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let pData of product; let i = index">
                            <td class="p-2 serviceReport">
                              <select
                                class="form-control form-control-sm bootstrap-select-container productType"
                                (change)="
                                  getProductsViaType($event.target.value, i)
                                "
                                data-live-search="true item-type"
                                id="type{{ i }}"
                                name="type"
                                [(ngModel)]="pData.type"
                              >
                                <option [value]="''">Select</option>
                                <ng-container *ngFor="let item of lineItems">
                                  <option [value]="item.id">
                                    {{ item.type_name }}
                                  </option>
                                </ng-container>
                              </select>
                            </td>
                            <td class="p-2 serviceReport">
                              <select
                                data-live-search="true"
                                class="form-control bootstrap-select-container form-control-sm productsName"
                                (change)="
                                  getProductDetails(i, $event.target.value)
                                "
                                [(ngModel)]="pData.product"
                                id="productName{{ i }}"
                              >
                                <option [value]="''">Select</option>
                                <ng-container *ngFor="let s of productList[i]">
                                  <option [value]="s.id">{{ s.name }}</option>
                                </ng-container>
                              </select>
                            </td>
                            <td class="p-2">
                              <select
                                class="form-control form-control-sm bootstrap-select-container pickerUOM"
                                data-live-search="true"
                                [(ngModel)]="pData.uom"
                                id="pickerUOM{{ i }}"
                              >
                                <option [ngValue]="''">Select</option>
                                <option
                                  *ngFor="let data of uomList"
                                  [value]="data?.id"
                                >
                                  {{ data?.unit_name }}
                                </option>
                              </select>
                            </td>
                            <td class="p-2">
                              <input
                                [(ngModel)]="pData.unit_price"
                                disabled
                                class="form-control form-control-sm"
                                placeholder="0"
                                type="number"
                              />
                            </td>
                            <td class="p-2">
                              <input
                                [(ngModel)]="pData.quantity"
                                id="serviceQuantity{{ i }}"
                                (keyup)="updateProductTotal(i)"
                                class="form-control form-control-sm"
                                min="1"
                                (keypress)="_onlyNumericPositive($event)"
                                placeholder="Quantity"
                                type="number"
                              />
                            </td>
                            <td class="p-2">
                              <input
                                [(ngModel)]="pData.total_price"
                                disabled
                                class="form-control form-control-sm serviceQuantity"
                              />
                            </td>
                            <td class="p-2">
                              <button
                                (click)="removeProductServices(i)"
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                                type="button"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                        fill="#000000"
                                        fill-rule="nonzero"
                                      />
                                      <path
                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <span class="font-weight-bold">Total:</span>
                              {{ productsTotal }}
                            </td>
                            <td class="p-2">
                              <button
                                (click)="addMoreProducts()"
                                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                                type="button"
                              >
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg-->
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                      <path
                                        d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                        fill="#000000"
                                      />
                                    </g></svg
                                  ><!--end::Svg Icon-->
                                </span>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-body service-card p-5">
                  <h4 class="m-0">Payment Info</h4>
                  <div class="row mt-5">
                    <!--                    <div class="col-2">-->
                    <!--                      <div class="row m-0">-->

                    <!--&lt;!&ndash;                        <div class="col-12 mb-5">&ndash;&gt;-->
                    <!--&lt;!&ndash;                          <label class="" style="vertical-align: middle">Payment Type</label>&ndash;&gt;-->
                    <!--&lt;!&ndash;                          <select [disabled]="paymentTypeDisabled" class="form-control form-control-sm" style="max-width: 130px" [(ngModel)]="paymentStatus" (change)="updatePaymentStatus()">&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <option value="paid">Paid</option>&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <option value="unpaid">Un-Paid</option>&ndash;&gt;-->
                    <!--&lt;!&ndash;                          </select>&ndash;&gt;-->
                    <!--&lt;!&ndash;                        </div>&ndash;&gt;-->

                    <!--                        <div class="col-12 mb-4">-->
                    <!--                          <label>Payment Mode</label>-->
                    <!--&lt;!&ndash;                          <select class="form-control form-control-sm" style="max-width: 130px" [(ngModel)]="serviceForm.mode_of_payment" (change)="paymentMethods($event.target.value)" [disabled]="paymentStatus === 'unpaid'">&ndash;&gt;-->
                    <!--                          <select class="form-control form-control-sm" style="max-width: 130px" [(ngModel)]="serviceForm.mode_of_payment" (change)="paymentMethods($event.target.value)">-->
                    <!--                            &lt;!&ndash;                            <option *ngIf="panelType === false" value="Cash">Cash</option>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            <option *ngIf="panelType === false" value="Cheque">Cheque</option>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            <option *ngIf="panelType === false" value="Credit Card">Credit Card</option>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            <option *ngIf="panelType === true" value="Credit">Credit</option>&ndash;&gt;-->
                    <!--                            <option value="Cash">Cash</option>-->
                    <!--&lt;!&ndash;                            <option value="Cheque">Cheque</option>&ndash;&gt;-->
                    <!--                            <option value="Credit Card">Credit Card</option>-->
                    <!--&lt;!&ndash;                            <option *ngIf="paymentStatus === 'unpaid'" value="Credit">Credit</option>&ndash;&gt;-->
                    <!--                          </select>-->
                    <!--                        </div>-->
                    <!--                        <div class="col-12 row">-->
                    <!--                          <img style="width: auto; height: 30px" class="ml-2" src="assets/images/bop.png">-->
                    <!--                          <img style="width: auto; height: 30px" class="ml-2" src="assets/images/hbl.jpg">-->
                    <!--                          <img style="width: auto; height: 30px" class="ml-2" src="assets/images/UBL.png">-->

                    <!--                        </div>-->
                    <!--                        <div class="col-12" *ngIf="serviceForm.mode_of_payment == 'Credit Card'">-->
                    <!--                          <input [(ngModel)]="serviceForm.payment_details"-->
                    <!--                                 class="form-control form-control-sm" placeholder="payment detail" type="text"/>-->
                    <!--                        </div>-->

                    <!--                        &lt;!&ndash;                        <div class="col-12">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                          <span *ngIf="panelType === false">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          <div class="p-0 col-12 font-size-11 text-left">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;            <input (click)="paymentMethod('Cash', $event)" [checked]="serviceForm.mode_of_payment === 'Cash'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   [disabled]="paymentStatus === 'unpaid'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   class="mr-2 invoiceCheckbox"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   type="checkbox">Cash&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          </div>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          <div class="p-0 col-12 font-size-11 text-left">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;            <input (click)="paymentMethod('Cheque', $event)" [checked]="serviceForm.mode_of_payment === 'Cheque'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   [disabled]="paymentStatus === 'unpaid'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   class="mr-2 invoiceCheckbox" type="checkbox">Cheque&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          </div>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          <div class="p-0 col-12 font-size-11 text-left">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;            <input (click)="paymentMethod('Credit Card', $event)"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   [checked]="serviceForm.mode_of_payment === 'Credit Card'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   [disabled]="paymentStatus === 'unpaid'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   class="mr-2 invoiceCheckbox" type="checkbox">Credit Card&ndash;&gt;-->
                    <!--                        &lt;!&ndash;            <input *ngIf="serviceForm.mode_of_payment == 'Credit Card'" [(ngModel)]="serviceForm.payment_details"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   class="form-control"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   placeholder="payment detail" type="text"/>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          </div>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;        </span>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                          <span *ngIf="panelType === true">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          <div class="p-0 col-12 font-size-11 text-left">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;            <input (click)="paymentMethod('Credit', $event)" [checked]="serviceForm.mode_of_payment === 'Credit'"&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                   class="mr-2 invoiceCheckbox" type="checkbox">Credit&ndash;&gt;-->
                    <!--                        &lt;!&ndash;          </div>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;        </span>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                        </div>&ndash;&gt;-->

                    <!--                      </div>-->

                    <!--                    </div>-->

                    <div class="col-11 text-left">
                      <ng-container *ngIf="showPriceOnService">
                        <!--                      <div class="row mx-0 my-2">-->
                        <!--                        <div class="col-4 "  style="margin-top: 5px"><label class="font-weight-bold" style="vertical-align: middle">Payment Type:</label></div>-->
                        <!--                        <div class="col-8 ">-->
                        <!--                          <select class="form-control form-control-sm" style="max-width: 130px" [(ngModel)]="paymentStatus" (change)="updatePaymentStatus()">-->
                        <!--                            <option value="paid">Paid</option>-->
                        <!--                            <option value="unpaid">Un-Paid</option>-->
                        <!--                          </select>-->
                        <!--                        </div>-->
                        <!--                      </div>-->
                        <div class="row">
                          <div class="col-3">
                            <div class="row">
                              <div class="col-8">
                                <label
                                  class="font-weight-bold"
                                  style="font-size: 1.25rem"
                                  >Grand Total:</label
                                >
                              </div>
                              <div class="col-4">
                                <label style="font-size: 1.5rem; color: blue"
                                  ><strong>{{
                                    servicesTotal +
                                      consultantionTotal +
                                      productsTotal +
                                      servicePaidForm.cash | number: "1.0-0"
                                  }}</strong></label
                                >
                              </div>
                              <div class="col-8 mt-3">Current Services:</div>
                              <div class="col-4 mt-3">
                                {{ servicesTotal | number: "1.0-0" }}
                              </div>
                              <div class="col-8 mt-3">Credit Services:</div>
                              <div class="col-4 mt-3">
                                {{ servicePaidForm.cash | number: "1.0-0" }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-3"
                            style="border-left: 1px solid #00000045"
                          >
                            <div class="row">
                              <div class="col-7">
                                <label
                                  class="font-weight-bold"
                                  style="font-size: 1.25rem"
                                  >Discount:</label
                                >
                              </div>
                              <div class="col-5">
                                <label
                                  style="font-size: 1.5rem; color: yellowgreen"
                                  ><strong>{{
                                    serviceForm.discount | number: "1.0-0"
                                  }}</strong></label
                                >
                              </div>
                              <div class="col-7 mt-2">
                                Client Cat. Discount.(%)
                              </div>
                              <div class="col-5 mt-2">0</div>
                              <div class="col-7 mt-2">Manual Discount(%)</div>
                              <div class="col-5 mt-2">0</div>
                              <div class="col-7 mt-2">Service Voucher(%)</div>
                              <div class="col-5 mt-2">0</div>
                            </div>
                            <!--                            <div class="row mt-5">-->
                            <!--                              <div class="col-5">-->
                            <!--                                <label>Amount</label>-->
                            <!--                                <input class="form-control form-control-sm discount_amount" type="number" [(ngModel)]="serviceForm.discount" (keyup)="calculateDiscountAmount('amount')" />-->
                            <!--                              &lt;!&ndash;                                <small>Discount in Amount</small>&ndash;&gt;-->
                            <!--                              </div>-->
                            <!--                              <div class="col-5">-->
                            <!--                                <label>Percentage</label>-->
                            <!--                                <input class="form-control form-control-sm discount_percentage" type="number" [(ngModel)]="serviceForm.discount_percentage" (keyup)="calculateDiscountAmount('percentage')" />-->
                            <!--                              &lt;!&ndash;                                <small>Discount in Percentage</small>&ndash;&gt;-->
                            <!--                              </div>-->
                            <!--                            </div>-->
                          </div>
                          <div
                            class="col-6"
                            style="border-left: 1px solid #00000045"
                          >
                            <div class="row">
                              <div class="col-3">
                                <label
                                  class="font-weight-bold"
                                  style="font-size: 1.25rem"
                                  >Net Amount:</label
                                >
                              </div>
                              <div class="col-3">
                                <label style="font-size: 1.5rem; color: blue"
                                  ><strong>{{
                                    serviceForm.cash | number: "1.0-0"
                                  }}</strong></label
                                >
                              </div>
                              <div class="col-3" style="margin-top: 5px">
                                <label
                                  class="font-weight-bold"
                                  style="vertical-align: middle"
                                  >Payment Mode:</label
                                >
                              </div>
                              <div class="col-3">
                                <select
                                  class="form-control form-control-sm"
                                  [(ngModel)]="paymentMode"
                                  style="max-width: 130px"
                                >
                                  <option [ngValue]="'cash'">Cash</option>
                                  <option [ngValue]="'credit_card'">
                                    Card
                                  </option>
                                  <option [ngValue]="'online'">Online</option>
                                  <option [ngValue]="'mix'">Mix Payment</option>
                                </select>
                              </div>
                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  paymentMode === 'cash' ||
                                  paymentMode === 'mix'
                                "
                              >
                                <label>Cash</label>
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  paymentMode === 'cash' ||
                                  paymentMode === 'mix'
                                "
                              >
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.cashAmount"
                                  placeholder="0"
                                />
                              </div>
                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  paymentMode === 'credit_card' ||
                                  paymentMode === 'mix'
                                "
                              >
                                <label>Card</label>
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  paymentMode === 'credit_card' ||
                                  paymentMode === 'mix'
                                "
                              >
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.creditCardAmount"
                                  placeholder="0"
                                />
                              </div>
                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  paymentMode === 'online' ||
                                  paymentMode === 'mix'
                                "
                              >
                                <label>Online</label>
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  paymentMode === 'online' ||
                                  paymentMode === 'mix'
                                "
                              >
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.onlineAmount"
                                  placeholder="0"
                                />
                              </div>
                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  paymentMode === 'mix' ||
                                  paymentMode === 'cash'
                                "
                              >
                                <label>Wallet</label>
                              </div>
                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  paymentMode === 'mix' ||
                                  paymentMode === 'cash'
                                "
                              >
                                <input
                                  type="number"
                                  placeholder="0"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.walletAmount"
                                />
                              </div>
                              <div class="col-3 mt-5">
                                <label>Payable</label>
                              </div>
                              <div class="col-3 mt-5">
                                {{
                                  serviceForm.cash -
                                    (serviceForm.cashAmount +
                                      serviceForm.creditCardAmount +
                                      serviceForm.onlineAmount +
                                      serviceForm.walletAmount) || 0
                                }}
                                <!--                                <input type="number" placeholder="0" class="form-control form-control-sm" [(ngModel)]="payableAmount" >-->
                              </div>
                              <div class="col-3 mt-5">
                                <label>Balance</label>
                              </div>
                              <div class="col-3 mt-5">
                                {{
                                  serviceForm.cash -
                                    (serviceForm.cashAmount +
                                      serviceForm.creditCardAmount +
                                      serviceForm.onlineAmount +
                                      serviceForm.walletAmount) || 0
                                }}
                              </div>

                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  this.paymentMode === 'credit_card' ||
                                  this.paymentMode === 'mix'
                                "
                              >
                                Card Bank
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  this.paymentMode === 'credit_card' ||
                                  this.paymentMode === 'mix'
                                "
                              >
                                <select
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.cardBank"
                                >
                                  <option>UBL</option>
                                  <option>HBL</option>
                                  <option>Meezan Bank</option>
                                </select>
                              </div>

                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  this.paymentMode === 'online' ||
                                  this.paymentMode === 'mix'
                                "
                              >
                                Online Bank
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  this.paymentMode === 'online' ||
                                  this.paymentMode === 'mix'
                                "
                              >
                                <select
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.onlineBank"
                                >
                                  <option>UBL</option>
                                  <option>HBL</option>
                                  <option>Meezan Bank</option>
                                </select>
                              </div>

                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  this.paymentMode === 'credit_card' ||
                                  this.paymentMode === 'mix'
                                "
                              >
                                Card Bank Ref #
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  this.paymentMode === 'credit_card' ||
                                  this.paymentMode === 'mix'
                                "
                              >
                                <input
                                  type="text"
                                  placeholder="Reference No."
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.cardBankRef"
                                />
                              </div>

                              <div
                                class="col-3 mt-5"
                                *ngIf="
                                  this.paymentMode === 'online' ||
                                  this.paymentMode === 'mix'
                                "
                              >
                                Online Bank Ref #
                              </div>
                              <div
                                class="col-3 mt-3"
                                *ngIf="
                                  this.paymentMode === 'online' ||
                                  this.paymentMode === 'mix'
                                "
                              >
                                <input
                                  type="text"
                                  placeholder="Reference No."
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.onlineBankRef"
                                />
                              </div>

                              <div class="col-3 mt-5">Tax(%)</div>
                              <div class="col-3 mt-3">
                                <select
                                  class="form-control tax"
                                  id="tax"
                                  data-live-search="true"
                                  data-container="body"
                                  [(ngModel)]="serviceForm.taxType"
                                >
                                  <option value="" disabled>select</option>
                                  <option value="tax">Car Tax</option>
                                  <option value="tax">Sale Tax</option>
                                  <option value="tax">Vat Tax</option>
                                  <option value="tax">Pet Tax</option>
                                </select>
                              </div>
                              <div class="col-3 mt-5">Tax Amount</div>
                              <div class="col-3 mt-5">
                                <input
                                  type="text"
                                  placeholder="Reference No."
                                  readonly
                                  class="form-control form-control-sm"
                                  [(ngModel)]="serviceForm.taxAmount"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>

                    <div
                      class="col-1 text-right"
                      style="border-left: 1px solid #00000045"
                    >
                      <div class="row">
                        <!--                        <div class="col-2 "><label class="font-weight-bold" style="font-size: 1.1rem">Wallet Amount:</label></div>-->
                        <div class="col-12 mb-5 d-flex">
                          <!--                          <button class="btn btn-sm btn-primary" type="button" (click)="openWalletModal()"><div>Wallet</div><div>3,000</div></button>-->
                          <!--                          <button class="btn btn-sm btn-info" style="line-height: 10px" (click)="openWalletModal()">-->
                          <!--                              <div>-->
                          <!--&lt;!&ndash;                                <img src="assets/icons/ewallet.png"/>&ndash;&gt;-->
                          <!--                                Wallet-->
                          <!--                              </div>-->
                          <!--                              <div style="font-size: 10px">30,000</div>-->
                          <!--                          </button>-->
                        </div>
                        <div class="col-12 mb-5 d-flex">
                          <!--                          <button class="btn btn-sm btn-primary" type="button" (click)="openWalletModal()"><div>Wallet</div><div>3,000</div></button>-->
                          <button
                            class="btn btn-primary"
                            *ngIf="showSubmit"
                            (click)="addServiceConfirm()"
                          >
                            <i
                              class="fas fa-sign-in-alt"
                              style="font-size: 11px"
                            ></i
                            >Submit
                          </button>
                          <button class="btn btn-primary" *ngIf="!showSubmit">
                            <i
                              class="fas fa-save pl-2"
                              style="font-size: 15px"
                            ></i
                            >Save
                          </button>
                        </div>
                        <div class="col-12 mb-5 d-flex">
                          <!--                          <button class="btn btn-sm btn-primary" type="button" (click)="openWalletModal()"><div>Wallet</div><div>3,000</div></button>-->
                          <button
                            class="btn d-flex btn-success"
                            style="line-height: 12px"
                            (click)="openWalletModal()"
                          >
                            <span class="mt-2"
                              ><i
                                class="far fa-money-bill-alt fas"
                                style="font-size: 12px"
                              ></i
                            ></span>
                            <span
                              class="d-flex flex-column align-items-start ms-2"
                            >
                              <span class="fs-3 fw-bold">Wallet</span>
                              <span class="fs-7 pl-2" style="font-size: 9px"
                                >30,000</span
                              >
                            </span>
                          </button>
                        </div>
                        <div class="col-12 mb-5 d-flex ml-2">
                          <!--                          <button class="btn btn-sm btn-primary" type="button" (click)="openWalletModal()"><div>Wallet</div><div>3,000</div></button>-->
                          <button
                            class="btn btn-danger"
                            [routerLink]="['/slot/day-wise']"
                          >
                            <i
                              class="fas fa-arrow-left"
                              style="font-size: 11px"
                            ></i
                            >Back
                          </button>
                        </div>
                        <!--                        <div class="col-12 mb-5">-->
                        <!--                          <button (click)="addServiceConfirm()" class="btn btn-sm btn-primary" type="submit">Submit</button>-->
                        <!--                        </div>-->
                        <!--                        <div class="col-2 mt-2"><label class="font-weight-bold" style="font-size: 1.1rem">Credit Amount:</label></div>-->
                        <!--                        <div class="col-6">-->
                        <!--                          <button class="btn btn-sm btn-primary" type="button">Pay</button>-->
                        <!--                        </div>-->
                        <!--                        <div class="col-12">-->
                        <!--                          <button class="btn btn-sm btn-warning" type="button" [routerLink]="['/client/registration-v2']">Back</button>-->
                        <!--                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Product Card: End-->
          </div>
        </div>
      </div>
      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<div class="example-preview">
  <div>
    <ng-template #serviceAddConfirm let-c="close" let-d="dismiss">
      <!--      <form>-->

      <div class="modal-header p-3">
        <h4 class="modal-title">Confirmation</h4>
        <a class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </a>
      </div>
      <div class="modal-body">
        <div>
          <!--            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" >-->
          <!--            Are you sure you want to add service!<br></div>-->
          Please request client to make payment.<br />
        </div>
      </div>
      <div class="modal-footer p-3">
        <a class="btn btn-sm btn-light-primary" (click)="c('Close click')"
          >Close</a
        >
        <button
          class="btn btn-sm btn-primary"
          [disabled]="isSubmitted"
          (click)="addService()"
        >
          Confirm
        </button>
        <!--          <button  class="btn btn-sm btn-primary" [disabled]="isSubmitted" (click)="saveAsDraft()">Confirm</button>-->
      </div>

      <!--      </form>-->
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #serviceHistoryUpdateConfirm let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <!--            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" >-->
            Are you sure you want to update the services!<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="unpaidToPaidServices()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Update Performing Doc-->
<div class="example-preview">
  <div>
    <ng-template #updatePerformingModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Update Performing Doctor!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body custom-card-body">
          <div class="row">
            <div class="col-6">
              <select
                class="form-control form-control-sm"
                [(ngModel)]="selectedPerformingID"
              >
                <option value=""></option>
                <option *ngFor="let doc of doctorsAll" [value]="doc.id">
                  {{ doc.first_name }} {{ doc.last_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="closePerformingModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            (click)="updatePerforming()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Update Referring Doc-->
<div class="example-preview">
  <div>
    <ng-template #updateReferringModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Update Referring Doctor!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body custom-card-body">
          <div class="row">
            <div class="col-6">
              <select
                class="form-control form-control-sm"
                [(ngModel)]="selectedReferringID"
              >
                <option value=""></option>
                <option *ngFor="let doc of doctorsAll" [value]="doc.id">
                  {{ doc.first_name }} {{ doc.last_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            (click)="closeReferringModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            (click)="updateReferring()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div
  class="modal fade"
  id="reportingTimeModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Time</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Date/Time <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control form-control-sm datetimepicker-input"
            id="reportingTime"
            data-toggle="datetimepicker"
            data-target="#reportingTime"
            (keydown)="preventChangeDate()"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <!--        <button type="button" class="btn btn-primary btn-sm" (click)="setReportingTime()">Save changes</button>-->
      </div>
    </div>
  </div>
</div>

<!--<div class="modal fade" id="recipyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
<!--  <div class="modal-dialog modal-xl" role="document">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-header">-->
<!--        <h5 class="modal-title" >Request Service kit</h5>-->
<!--        <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--          <span aria-hidden="true">&times;</span>-->
<!--        </button>-->
<!--      </div>-->
<!--      <div class="modal-body">-->
<!--        <div class="card-body custom-card-body" style="padding: 0px !important;">-->
<!--&lt;!&ndash;          <h3 class="mb-4">Recipe Ingredients</h3>&ndash;&gt;-->
<!--          <div class="row">-->

<!--            <div class="col-lg-3 col-md-3 col-sm-12 col-12">-->
<!--              <div class="pickers">-->
<!--                <label>Store</label>-->

<!--                <select class="form-control form-control-sm bootstrap-select-container" id="store" data-none-selected-text="Select Store" multiple data-live-search="true"  data-size="10" [(ngModel)]="store" >-->
<!--                  <option [value]="''" disabled>Select Store</option>-->
<!--                  <ng-container *ngFor="let data of stores">-->
<!--                    <option [value]="data?.id">{{data?.name}}</option>-->
<!--                  </ng-container>-->
<!--                </select>-->

<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-md-3 col-sm-12 col-12">-->
<!--              <div class="pickers">-->
<!--                <label>Department</label>-->
<!--                <select class="form-control form-control-sm lineItem" data-live-search="true" id="lineItem" [(ngModel)]="lineItem">-->
<!--                  <option value="" [selected]="true">Select Department</option>-->
<!--                  <ng-container *ngFor="let data of lineItems">-->
<!--                    <option [value]="data?.id">{{data?.type_name}}</option>-->
<!--                  </ng-container>-->
<!--                </select>-->

<!--              </div>-->
<!--            </div>-->

<!--            <div class="col-lg-3 col-md-3 col-sm-12 col-12">-->
<!--              <div class="pickers">-->
<!--                <label>Products</label>-->
<!--                <select  class="form-control form-control-sm selectpicker" data-live-search="true" id="labTests" formControlName="search_title" (change)="getProductId($event.target.value)">-->
<!--                  &lt;!&ndash;                  <select  class="form-control form-control-sm selectpicker" data-live-search="true" id="labTests" formControlName="search_title" (change)="setParameterCode($event.target.value)">&ndash;&gt;-->
<!--                  <option value="" [selected]=true [disabled]="true">Select product </option>-->
<!--                  <ng-container *ngFor="let lp of list">-->
<!--                    <option [value]="lp.id"> {{lp.name}}</option>-->
<!--                  </ng-container>-->
<!--                </select>-->

<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;              <div class="col-md-4 col-sm-12 col-12 mt-3 mt-sm-3 mt-md-0">&ndash;&gt;-->
<!--            <div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 1.5rem">-->
<!--              <button class="btn btn-primary btn-sm" (click)="addProduct()">-->
<!--                <span>Add</span>-->
<!--              </button>-->
<!--            </div>-->

<!--&lt;!&ndash;            <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="font-size-14" style="display: block;padding-top: 22px;" > <span class="setHeading font-weight-bolder">Service Name: </span> {{serviceName}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;              &lt;!&ndash;                <span class="font-size-14" style="display: block;padding-top: 8px;" *ngIf="labTest"> <span class="setHeading">Test Name: </span> {{labTest?.profile_name}}</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->

<!--          </div>-->
<!--          &lt;!&ndash;          <form [formGroup]="LabParameterForm" (ngSubmit)='linkLabParameters()' *ngIf="labParametersArray">&ndash;&gt;-->
<!--          <table class="table table-head-custom table-vertical-center w-100">-->
<!--            <thead>-->
<!--            <tr>-->
<!--              <th style="width: 5%">Sr#</th>-->
<!--              <th style="width: 10%">Code</th>-->
<!--              <th style="width: 17%">Name</th>-->
<!--              <th style="width: 16%">Brand</th>-->
<!--              <th style="width: 17%">Department</th>-->
<!--              <th style="width: 12%">Qty. Per Unit</th>-->
<!--              <th style="width: 13%">Qty Required</th>-->
<!--              <th style="width: 8%">UOM</th>-->
<!--&lt;!&ndash;              <th style="width: 7%">Cost</th>&ndash;&gt;-->
<!--              <th style="width: 7%" class="text-center">Action</th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <ng-container *ngFor="let product of productArrayList; let i = index">-->
<!--              <tr>-->
<!--                <td>{{i+1}}</td>-->
<!--                <td>{{product?.code || 'code-007'}}</td>-->
<!--                <td>{{product?.name || 'procuct Name'}}</td>-->
<!--                <td>NewOne Brand</td>-->
<!--                <td>DepartMakeUp</td>-->
<!--                <td>10</td>-->
<!--                <td>-->
<!--                  <input type="text" class="form-control form-control-sm" placeholder="unit required" style="max-width: 125px" [(ngModel)]="product.unit_req">-->
<!--                </td>-->
<!--                <td>{{product?.uom || 'unit'}}</td>-->
<!--&lt;!&ndash;                <td>3000</td>&ndash;&gt;-->
<!--                <td class="text-center">-->
<!--                  <button type='button' class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3" (click)="removeProductData(i)">-->
<!--                    &lt;!&ndash;                      <button type='button' class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3" (click)='removeLabParameter(i)'>&ndash;&gt;-->
<!--                    <span class="svg-icon svg-icon-primary svg-icon-2x">&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg&ndash;&gt;<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
<!--                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                        <rect x="0" y="0" width="24" height="24"/>-->
<!--                        <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"/>-->
<!--                        <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>-->
<!--                      </g>-->
<!--                    </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                  </button>-->
<!--                </td>-->
<!--              </tr>-->
<!--            </ng-container>-->
<!--            &lt;!&ndash;            <ng-container>&ndash;&gt;-->
<!--            &lt;!&ndash;              <tr>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td colspan="7">&ndash;&gt;-->
<!--            &lt;!&ndash;                </td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td style="font-weight: 700">Total Recipe Cost:</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td>70</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td></td>&ndash;&gt;-->
<!--            &lt;!&ndash;              </tr>&ndash;&gt;-->
<!--            &lt;!&ndash;              <tr>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td colspan="7">&ndash;&gt;-->
<!--            &lt;!&ndash;                </td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td style="font-weight: 700">Worker Cost:</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td><input type="number" class="form-control form-control-sm" placeholder="0"></td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td></td>&ndash;&gt;-->
<!--            &lt;!&ndash;              </tr>&ndash;&gt;-->
<!--            &lt;!&ndash;              <tr>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td colspan="7">&ndash;&gt;-->
<!--            &lt;!&ndash;                </td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td style="font-weight: 700">Over Head Cost:</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td><input type="number" class="form-control form-control-sm" placeholder="0"></td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td></td>&ndash;&gt;-->
<!--            &lt;!&ndash;              </tr>&ndash;&gt;-->
<!--            &lt;!&ndash;              <tr>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td colspan="7">&ndash;&gt;-->
<!--            &lt;!&ndash;                </td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td style="font-weight: 700">Other Cost:</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td><input type="number" class="form-control form-control-sm" placeholder="0"></td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td></td>&ndash;&gt;-->
<!--            &lt;!&ndash;              </tr>&ndash;&gt;-->
<!--            &lt;!&ndash;              <tr>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td colspan="7">&ndash;&gt;-->
<!--            &lt;!&ndash;                </td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td style="font-weight: 700">Total Service Cost:</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td>70</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td></td>&ndash;&gt;-->
<!--            &lt;!&ndash;              </tr>&ndash;&gt;-->
<!--            &lt;!&ndash;            </ng-container>&ndash;&gt;-->
<!--            </tbody>-->
<!--          </table>-->
<!--&lt;!&ndash;          <div class="row text-right mt-4">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="col-12">&ndash;&gt;-->
<!--&lt;!&ndash;              <button type="submit" class="btn btn-primary btn-sm">Save</button>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          &lt;!&ndash;          </form>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="modal-footer">-->
<!--        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>-->
<!--        <button type="button" class="btn btn-primary btn-sm" (click)="setReportingTime()">Save changes</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div
  class="modal fade"
  id="recipyModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Edit Recipe</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-2 col-md-3 col-sm-12 col-12">
            <div class="pickers">
              <label>Store</label>

              <select
                class="form-control form-control-sm bootstrap-select-container"
                id="store"
                data-live-search="true"
                data-size="10"
                [(ngModel)]="store"
              >
                <option [value]="''" disabled>Select Store</option>
                <ng-container *ngFor="let data of stores">
                  <option [value]="data?.id">{{ data?.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-12 col-12">
            <div class="pickers">
              <label>Department</label>
              <select
                class="form-control form-control-sm lineItem"
                data-live-search="true"
                id="lineItem"
                [(ngModel)]="lineItem"
                (change)="getProductsById($event.target.value)"
              >
                <option value="" [selected]="true">Select Department</option>
                <ng-container *ngFor="let data of lineItems">
                  <option [value]="data?.id">{{ data?.type_name }}</option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="col-lg-2 col-md-3 col-sm-12 col-12">
            <div class="pickers">
              <label>Products</label>
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                id="products"
                formControlName="search_title"
                (change)="getProductId($event.target.value)"
              >
                <!--                  <select  class="form-control form-control-sm selectpicker" data-live-search="true" id="labTests" formControlName="search_title" (change)="setParameterCode($event.target.value)">-->
                <option value="" [selected]="true" [disabled]="true">
                  Select product
                </option>
                <ng-container *ngFor="let lp of list">
                  <option [value]="lp.id">
                    {{ lp.code }} - {{ lp.name }}
                    <span *ngIf="lp?.manufacturer_name">{{
                      "(" + lp?.manufacturer_name + ")"
                    }}</span>
                  </option>
                  <!--                  <option [value]="lp.id"> {{lp.name}}</option>-->
                </ng-container>
              </select>
            </div>
          </div>
          <!--              <div class="col-md-4 col-sm-12 col-12 mt-3 mt-sm-3 mt-md-0">-->
          <div
            class="col-lg-2 col-md-3 col-sm-12 col-12"
            style="margin-top: 1.5rem"
          >
            <button class="btn btn-primary btn-sm" (click)="addProduct()">
              <span>Add</span>
            </button>
          </div>

          <!--            <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">-->
          <!--              <span class="font-size-14" style="display: block;padding-top: 22px;" > <span class="setHeading font-weight-bolder">Service Name: </span>{{this.MiscServicesForm?.service_name}}</span>-->
          <!--              &lt;!&ndash;                <span class="font-size-14" style="display: block;padding-top: 8px;" *ngIf="labTest"> <span class="setHeading">Test Name: </span> {{labTest?.profile_name}}</span>&ndash;&gt;-->
          <!--            </div>-->
        </div>
        <form [formGroup]="servicesKit">
          <table class="table table-head-custom table-vertical-center w-100">
            <thead>
              <tr>
                <th style="width: 3%">Sr#</th>
                <th style="width: 10%">Code</th>
                <th style="width: 15%">Name</th>
                <th style="width: 10%">Brand</th>
                <th style="width: 10%">Department</th>
                <th style="width: 10%">Qty. Per Unit</th>
                <th style="width: 10%">Qty Required</th>
                <th style="width: 10%">UOM</th>
                <th style="width: 5%">Cost</th>
                <th style="width: 5%">T.Cost</th>
                <th style="width: 5%" class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                formArrayName="serviceKit"
                *ngFor="let product of controlDose.controls; let i = index"
              >
                <ng-container *ngIf="product?.get('is_remove').value === false">
                  <tr [formGroupName]="i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ product?.get("code").value }}</td>
                    <!--                <td>{{product?.code}}</td>-->
                    <td>{{ product?.get("name").value }}</td>
                    <td>
                      {{ product?.get("manufacturer_name").value || "--" }}
                    </td>
                    <td>{{ product?.get("line_item").value }}</td>
                    <td>{{ product?.get("unit_quantity").value }}</td>
                    <td>
                      <!--                  <input type="text" class="form-control form-control-sm" placeholder="unit required" style="max-width: 125px" [(ngModel)]="product.unit_req">-->
                      <span style="display: none">
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          value="1"
                          formControlName="old_unit_req"
                          placeholder="unit required"
                          style="max-width: 125px"
                        />
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        value="1"
                        formControlName="unit_req"
                        placeholder="unit required"
                        style="max-width: 125px"
                        (keyup)="checkModification(i)"
                      />
                      <!--                  <input type="number" class="form-control form-control-sm" value="1" formControlName="unit_req" placeholder="unit required" style="max-width: 125px" (keyup)="calculateServiceCostNew($event.target.value)">-->
                    </td>
                    <td>{{ product?.get("uom").value || "--" }}</td>
                    <td>{{ product?.get("purchase_price").value }}</td>
                    <td>
                      {{
                        product?.get("purchase_price").value *
                          product?.get("unit_req").value
                      }}
                    </td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                        (click)="removeProductData(i)"
                      >
                        <!--                      <button type='button' class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3" (click)='removeLabParameter(i)'>-->
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <path
                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              />
                              <path
                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g></svg
                          ><!--end::Svg Icon-->
                        </span>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <!--            <ng-container>-->
              <!--              <tr>-->
              <!--                <td colspan="7">-->
              <!--                </td>-->
              <!--                <td style="font-weight: 700">Total Recipe Cost:</td>-->
              <!--                <td>70</td>-->
              <!--                <td></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td colspan="7">-->
              <!--                </td>-->
              <!--                <td style="font-weight: 700">Worker Cost:</td>-->
              <!--                <td><input type="number" class="form-control form-control-sm" placeholder="0"></td>-->
              <!--                <td></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td colspan="7">-->
              <!--                </td>-->
              <!--                <td style="font-weight: 700">Over Head Cost:</td>-->
              <!--                <td><input type="number" class="form-control form-control-sm" placeholder="0"></td>-->
              <!--                <td></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td colspan="7">-->
              <!--                </td>-->
              <!--                <td style="font-weight: 700">Other Cost:</td>-->
              <!--                <td><input type="number" class="form-control form-control-sm" placeholder="0"></td>-->
              <!--                <td></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td colspan="7">-->
              <!--                </td>-->
              <!--                <td style="font-weight: 700">Total Service Cost:</td>-->
              <!--                <td>70</td>-->
              <!--                <td></td>-->
              <!--              </tr>-->
              <!--            </ng-container>-->
            </tbody>
          </table>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="submitNewKit()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="signatureModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Consent Form</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <h5>{{ consetData?.template_data[0].heading }}</h5>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <!--                      <label>Details <span class="text-danger">*</span></label>-->
              <div class="summernote" id="reports"></div>
            </div>
          </div>
        </div>
        <h5>Signature</h5>
        <div class="signature border w-750px h-200px">
          <canvas #canvas width="750" height="200"></canvas>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="submitConsentForm()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<!--<div class="modal fade" id="referringDoctorModal" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">-->
<!--  <div class="modal-dialog modal-xl" role="document">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-header">-->
<!--        <h5 class="modal-title">Add New Referring Doctor</h5>-->
<!--        <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--          <span aria-hidden="true">&times;</span>-->
<!--        </button>-->
<!--      </div>-->
<!--      <div class="modal-body">-->
<!--        <form [formGroup]="employeeVisitForm" (ngSubmit)="onSubmitReferringDoctorAdd()">-->
<!--          <div class="row">-->
<!--            &lt;!&ndash;       1- Title&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Title <span class="text-danger">*</span></label>-->
<!--                <div [ngClass]="{'is-invalid': isSubmitReferring && this.employeeVisitForm.controls.title.errors}">-->
<!--                  <select class="form-control form-control-sm bootstrap-select-container" id="title" data-container="body" data-live-search="true"-->
<!--                          formControlName="title">-->
<!--                    <option value="''" [disabled]="true">Select title</option>-->
<!--                    <ng-container *ngFor="let titles of titleList">-->
<!--                      <option [value]="titles.id">{{titles.name}}</option>-->
<!--                    </ng-container>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;        2- First Name&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>First Name <span class="text-danger">*</span></label>-->
<!--                <input type="text" class="form-control form-control-sm allowaplhabets" name="first_name" id="first_name"-->
<!--                       placeholder="First Name" formControlName="first_name"-->
<!--                       [ngClass]="{'is-invalid': isSubmitReferring && this.employeeVisitForm.controls.first_name.errors}">-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;   3- Last Name&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Last Name</label>-->
<!--                <input type="text" class="form-control form-control-sm" name="last_name"-->
<!--                       placeholder="Last Name" formControlName="last_name">-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;        4- Gender*&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Gender <span class="text-danger">*</span></label>-->
<!--                <div [ngClass]="{'is-invalid': isSubmitReferring && this.employeeVisitForm.controls.gender.errors}">-->
<!--                  <select name="gender" id="gender" class="form-control form-control-sm bootstrap-select-container" formControlName="gender"-->
<!--                          data-container="body" data-live-search="true">-->
<!--                    <option [value]="''" [disabled]="true">Select Gender</option>-->
<!--                    <ng-container *ngFor="let gender of genderList">-->
<!--                      <option [value]="gender.id">{{gender.name}}</option>-->
<!--                    </ng-container>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;        4- Speciality*&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Speciality</label>-->
<!--                <div>-->
<!--                  <select name="gender" id="speciality" class="form-control form-control-sm" formControlName="speciality"-->
<!--                          data-container="body" data-live-search="true">-->
<!--                    <option [value]="''" [disabled]="true">Select Speciality</option>-->
<!--                    <ng-container *ngFor="let emp of allEmployeesStaffTypeDoctor">-->
<!--                      <option [value]="emp.id">{{emp.first_name}} {{emp.middle_name || ''}} {{emp.last_name}}</option>-->
<!--                    </ng-container>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;        5- National ID card&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>National ID Card No</label>-->
<!--                <input type="text" (keypress)="_onlyNumeric($event)" class="form-control form-control-sm"-->
<!--                       placeholder="National ID Card No" name="nic" id="nic" formControlName="nic">-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;        PMDC registration number&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>PMDC Registration Number</label>-->
<!--                <input type="text" class="form-control form-control-sm"-->
<!--                       placeholder="PMDC Registration Number" name="nic" id="pmdc_registration_no" formControlName="pmdc_registration_no">-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;        6- Primary Contact&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Primary Contact</label>-->
<!--                <input type="tel" class="form-control form-control-sm" (keypress)="_onlyNumeric($event)" maxlength="11"-->
<!--                       placeholder="Primary Contact" name="primary_contact" id="primary_contact"-->
<!--                       formControlName="primary_contact">-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;        Hospital name&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Clinic / Hospital name </label>-->
<!--                <input type="text" class="form-control form-control-sm" formControlName="hospital_name" placeholder="Hospital name"/>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;Country&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Country <span class="text-danger">*</span></label>-->
<!--                <select class="form-control form-control-sm country" id="country" data-live-search="true" data-container="body" (change)="updateCountryFun($event.target.value)" formControlName="country" [ngClass]="{'is-invalid': isSubmitReferring && this.employeeVisitForm.controls.country.errors}">-->
<!--                  <option value="" [selected]="true">select</option>-->
<!--                  <ng-container *ngFor="let country of countries">-->
<!--                    <option [value]="country.id">{{country.name}}</option>-->
<!--                  </ng-container>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>State <span class="text-danger">*</span></label>-->
<!--                <select class="form-control form-control-sm" formControlName="state" (change)="updateStateFun($event.target.value)" [ngClass]="{'is-invalid': isSubmitReferring && this.employeeVisitForm.controls.state.errors}">-->
<!--                  <option value="" [disabled]="true">Select</option>-->
<!--                  <ng-container *ngFor="let state of states">-->
<!--                    <option [value]="state.id">{{state.name}}</option>-->
<!--                  </ng-container>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>City <span class="text-danger">*</span></label>-->
<!--                <select  class="form-control form-control-sm" formControlName="city" [ngClass]="{'is-invalid': isSubmitReferring && this.employeeVisitForm.controls.city.errors}">-->
<!--                  <option value="" [disabled]="true">Select</option>-->
<!--                  <ng-container *ngFor="let city of cities">-->
<!--                    <option [value]="city.id">{{city.name}}</option>-->
<!--                  </ng-container>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;        7- Address*&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Address <span class="text-danger">*</span></label>-->
<!--                <input type="text" class="form-control form-control-sm" formControlName="address" placeholder="Address"-->
<!--                       [ngClass]="{'is-invalid' : isSubmitReferring && employeeVisitForm.controls.address.errors}"/>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; Office Number&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Doctor Office Number</label>-->
<!--                <input type="text" class="form-control form-control-sm" formControlName="office_number" placeholder="Office Number"/>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; Mobile Number&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Doctor Mobile Number</label>-->
<!--                <input type="text" class="form-control form-control-sm" formControlName="mobile_number" placeholder="Mobile Number"/>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;Staff Type&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Staff Type <span class="text-danger">*</span></label>-->
<!--                <div [ngClass]="{'is-invalid': isSubmitReferring && this.employeeVisitForm.controls.staff_type.errors}">-->
<!--                  <select class="form-control form-control-sm bootstrap-select-container" name="staff_type" id="staff_type" formControlName="staff_type" data-container="body" data-live-search="true">-->
<!--                    <option [value]="''">Select Staff Type</option>-->
<!--                    <ng-container *ngFor="let stafftype of staffTypeList">-->
<!--                      <option [value]="stafftype.id">{{stafftype.name}}</option>-->
<!--                    </ng-container>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;  9- Department*&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Department <span class="text-danger">*</span></label>-->
<!--                <div>-->
<!--                  <select name="department" id="refDepartment" class="form-control form-control-sm bootstrap-select-container selectPickerDepartment"-->
<!--                          data-container="body" data-live-search="true" formControlName="department">-->
<!--                    <option [value]="''" [disabled]="true">Select Department</option>-->
<!--                    <ng-container *ngFor="let dep of departments">-->
<!--                      <option [value]="dep.id">{{dep.title}}</option>-->
<!--                    </ng-container>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;        10- Designation*&ndash;&gt;-->
<!--            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">-->
<!--              <div class="form-group">-->
<!--                <label>Designation <span class="text-danger">*</span></label>-->
<!--                <div >-->
<!--                  <select name="designation" id="designation"-->
<!--                          class="form-control form-control-sm selectPickerDesignation bootstrap-select-container"-->
<!--                          data-container="body" data-live-search="true" formControlName="designation">-->
<!--                    <option [value]="''" [disabled]="true">Select Designation</option>-->
<!--                    <ng-container *ngFor="let desig of designationList">-->
<!--                      <option [value]="desig.id">{{desig.name}}</option>-->
<!--                    </ng-container>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--        </form>-->
<!--      </div>-->
<!--      <div class="modal-footer">-->
<!--        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>-->
<!--        <button type="button" class="btn btn-primary btn-sm" (click)="onSubmitReferringDoctorAdd()">Save changes</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div
  class="modal fade"
  id="walletModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Wallet History</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-3">
            <label>Booking</label>
            <select
              name="bookingList"
              id="bookingListwallet"
              class="form-control form-control-sm selectPickerBooking"
              [(ngModel)]="walletSelectedBooking"
              (change)="fetchSelectedBookingWallet($event.target.value)"
              data-container="body"
              data-live-search="true"
            >
              <option [value]="''">Select Booking</option>
              <ng-container *ngFor="let booking of bookingList">
                <option [value]="booking.id">
                  {{ booking.booking_number }}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-3">
            <label>Services</label>
            <select
              data-live-search="true"
              class="form-control form-control-sm"
              [(ngModel)]="walletService"
            >
              <option [value]="''">Select</option>
              <ng-container *ngFor="let s of servicesWalletList">
                <option [value]="s.id">{{ s.service_name }}</option>
              </ng-container>
            </select>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-3">
            <label>Payment Mode</label>
            <select
              class="form-control-sm form-control"
              (change)="resetWalletCard()"
              [(ngModel)]="walletPaymentMode"
            >
              <option [ngValue]="'cash'">Cash</option>
              <option [ngValue]="'credit_card'">Card</option>
              <option [ngValue]="'online'">Online</option>
              <option [ngValue]="'mix'">Mix Payment</option>
            </select>
          </div>
          <div
            class="col-3 mt-3"
            *ngIf="walletPaymentMode === 'cash' || walletPaymentMode === 'mix'"
          >
            <label>Cash</label>
            <input
              type="number"
              class="form-control form-control-sm"
              [(ngModel)]="walletCashAmount"
              placeholder="0"
            />
          </div>
          <div
            class="col-3 mt-3"
            *ngIf="
              walletPaymentMode === 'credit_card' || walletPaymentMode === 'mix'
            "
          >
            <label>Card</label>
            <input
              type="number"
              class="form-control form-control-sm"
              [(ngModel)]="walletCreditCardAmount"
              placeholder="0"
            />
          </div>
          <div
            class="col-3 mt-3"
            *ngIf="
              walletPaymentMode === 'credit_card' || walletPaymentMode === 'mix'
            "
          >
            <label>Card's Bank</label>
            <select
              class="form-control form-control-sm selectPickerBankDetail dropdown-menu-down"
              [(ngModel)]="walletCardBank"
              data-container="body"
              data-live-search="true"
              name="bank_name"
            >
              <option [value]="''">Select Bank</option>
              <ng-container *ngFor="let bank of bankList">
                <option [ngValue]="bank.id">{{ bank.bank_name }}</option>
              </ng-container>
            </select>
          </div>
          <div
            class="col-3 mt-3"
            *ngIf="
              walletPaymentMode === 'credit_card' || walletPaymentMode === 'mix'
            "
          >
            <label>Card's Bank Ref#</label>
            <input
              type="text"
              placeholder="Reference No."
              class="form-control form-control-sm"
              [(ngModel)]="walletCardBankRef"
            />
          </div>
          <div
            class="col-3 mt-3"
            *ngIf="
              walletPaymentMode === 'online' || walletPaymentMode === 'mix'
            "
          >
            <label>Online</label>
            <input
              type="number"
              class="form-control form-control-sm"
              [(ngModel)]="walletOnlineAmount"
              placeholder="0"
            />
          </div>
          <div
            class="col-3 mt-3"
            *ngIf="
              walletPaymentMode === 'online' || walletPaymentMode === 'mix'
            "
          >
            <label>Online Bank</label>
            <!--              <select class="form-control form-control-sm" [(ngModel)]="walletOnlineBank">-->
            <!--                <option [ngValue]="'ubl'">UBL</option>-->
            <!--                <option [ngValue]="'hbl'">HBL</option>-->
            <!--                <option [ngValue]="'meezan'">Meezan Bank</option>-->
            <!--              </select>-->
            <select
              class="form-control form-control-sm selectPickerBankDetail dropdown-menu-down"
              [(ngModel)]="walletOnlineBank"
              data-container="body"
              data-live-search="true"
              name="bank_name"
            >
              <option [value]="''">Select Bank</option>
              <ng-container *ngFor="let bank of bankList">
                <option [ngValue]="bank.id">{{ bank.bank_name }}</option>
              </ng-container>
            </select>
          </div>
          <div
            class="col-3 mt-3"
            *ngIf="
              walletPaymentMode === 'online' || walletPaymentMode === 'mix'
            "
          >
            <label>Online Bank Ref #</label>
            <input
              type="text"
              placeholder="Reference No."
              class="form-control form-control-sm"
              [(ngModel)]="walletOnlineBankRef"
            />
          </div>

          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-9">
            <button
              type="button"
              (click)="addWalletAmount()"
              class="btn btn-sm btn-primary"
            >
              Save
            </button>
          </div>
        </div>
        <div class="mt-4"><h5>Bill Summary</h5></div>
        <div class="row">
          <!--       Advance     -->
          <div class="col">
            <!--begin::Stats Widget 11-->
            <div
              class="card card-custom card-stretch gutter-b bgi-no-repeat"
              style="background-color: #bdb76b"
            >
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="d-flex align-items-center justify-content-center card-spacer flex-grow-1"
                >
                  <div class="d-flex flex-column text-center">
                    <span
                      class="text-dark-75 font-weight-bolder font-size-h3"
                      >{{ walletServicesAdvance || 0 }}</span
                    >
                    <span class="font-weight-bold mt-2">Service Advance</span>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Stats Widget 11-->
          </div>
          <!--       Active Balance     -->
          <div class="col">
            <!--begin::Stats Widget 11-->
            <div
              class="card card-custom card-stretch gutter-b bgi-no-repeat"
              style="background-color: #deb887"
            >
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="d-flex align-items-center justify-content-center card-spacer flex-grow-1"
                >
                  <div class="d-flex flex-column text-center">
                    <span
                      class="text-dark-75 font-weight-bolder font-size-h3"
                      >{{ walletUseAbleAmount || 0 }}</span
                    >
                    <span class="font-weight-bold mt-2">Usable Balance</span>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Stats Widget 11-->
          </div>
          <!--     Total Balance           -->
          <div class="col">
            <!--begin::Stats Widget 11-->
            <div
              class="card card-custom card-stretch gutter-b bgi-no-repeat"
              style="background-color: #a9a9a9"
            >
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="d-flex align-items-center justify-content-center card-spacer flex-grow-1"
                >
                  <div class="d-flex flex-column text-center">
                    <span
                      class="text-dark-75 font-weight-bolder font-size-h3"
                      >{{
                        walletServicesAdvance + walletUseAbleAmount || 0
                      }}</span
                    >
                    <span class="font-weight-bold mt-2">Total Balance</span>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Stats Widget 11-->
          </div>
          <!--       Credit Service     -->
          <div class="col">
            <!--begin::Stats Widget 11-->
            <div
              class="card card-custom card-stretch gutter-b bgi-no-repeat"
              style="background-color: lightskyblue"
            >
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="d-flex align-items-center justify-content-center card-spacer flex-grow-1"
                >
                  <div class="d-flex flex-column text-center">
                    <span
                      class="text-dark-75 font-weight-bolder font-size-h3"
                      >{{ walletCreditInvoices || 0 }}</span
                    >
                    <span class="font-weight-bold mt-2">Credit Services</span>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Stats Widget 11-->
          </div>
          <!--       Net Balance     -->
          <div class="col">
            <!--begin::Stats Widget 11-->
            <div
              class="card card-custom card-stretch gutter-b bgi-no-repeat"
              style="background-color: #bc8f8f"
            >
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="d-flex align-items-center justify-content-center card-spacer flex-grow-1"
                >
                  <div class="d-flex flex-column text-center">
                    <span
                      class="text-dark-75 font-weight-bolder font-size-h3"
                      >{{
                        walletServicesAdvance +
                          walletUseAbleAmount -
                          walletCreditInvoices
                      }}</span
                    >
                    <span class="font-weight-bold mt-2">Net Balance</span>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Stats Widget 11-->
          </div>
        </div>
        <div><h5>Transactions</h5></div>
        <div class="mt-4 nu-table-bod">
          <table
            class="table table-head-custom table-vertical-center table-a w-100"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr>
                <th style="width: 10%">ID</th>
                <th style="width: 18%">Date</th>
                <th style="width: 42%">Transaction Description</th>
                <th style="width: 15%">Credit</th>
                <th style="width: 15%">Debit</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of walletTransactionList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ data.created_date | date: "dd-MM-yyyy" }}</td>
                <td>{{ data.description }}</td>
                <td>{{ data.credit_amount || 0 }}</td>
                <td>{{ data.debit_amount || 0 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          (click)="closeWalletModal()"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
