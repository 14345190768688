<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-baseline flex-wrap mr-5 w-100 justify-content-between"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <div>
            <button
              class="btn btn-light-primary btn-sm ml-2"
              (click)="openIndentList()"
            >
              Add New
            </button>
          </div>
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <div>
            <!--            <div class="text-right mr-2">-->
            <!--              <button type="button" class="btn btn-light-warning btn-sm mr-4" (click)="searchStatusFilter(-1)">All-->
            <!--              </button>-->
            <!--              <button type="button" class="btn btn-light-primary btn-sm mr-4" (click)="searchStatusFilter(1)">Open-->
            <!--              </button>-->
            <!--              <button type="button" class="btn btn-light-success btn-sm mr-4" (click)="searchStatusFilter(2)">In-->
            <!--                Process-->
            <!--              </button>-->
            <!--              <button type="button" class="btn btn-light-danger btn-sm mr-4" (click)="searchStatusFilter(3)">Close-->
            <!--              </button>-->
            <!--            </div>-->
            <!--begin::Table-->
            <div class="table_top_bar mb-3">
              <form [formGroup]="searchForm" (ngSubmit)="searchFilter()">
                <div class="row">
                  <div class="col-12 col-sm-3">
                    <div class="d-block form-group">
                      <label class="">Status:</label>
                      <select
                        class="form-control form-control-sm selectpicker"
                        data-live-search="true"
                        id="modalSelect"
                        formControlName="status"
                        name="modalSelect"
                      >
                        <option value="" [disabled]="true">
                          Select Status
                        </option>
                        <ng-container *ngFor="let status of taskStatus">
                          <option [value]="status.id">
                            {{ status?.name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3">
                    <div class="d-block form-group">
                      <label>Employee:</label>
                      <select
                        class="form-control form-control-sm selectpicker"
                        data-live-search="true"
                        multiple
                        formControlName="employee"
                        name="employee"
                      >
                        <option value="" [disabled]="true">
                          Select Employee
                        </option>
                        <ng-container *ngFor="let emp of employee">
                          <option [value]="emp.id">
                            {{ emp?.employee_file_no }} - {{ emp?.first_name }}
                            {{ emp?.middle_name || "" }} {{ emp?.last_name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3">
                    <div class="d-block form-group">
                      <label class="mr-3 mb-0 d-none d-md-block"
                        >Priority</label
                      >
                      <select
                        class="form-control form-control-sm selectpicker"
                        data-live-search="true"
                        id="prioritySelect2"
                        formControlName="priority"
                        name="modalSelect"
                      >
                        <ng-container *ngFor="let p of priority">
                          <option [value]="p.id">{{ p?.name }}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3">
                    <div class="d-block form-group">
                      <label class="mr-3 mb-0 d-none d-md-block">Project</label>
                      <select
                        class="form-control form-control-sm selectpicker"
                        data-live-search="true"
                        id="searchProject"
                        formControlName="project"
                        name="modalSelect"
                      >
                        <ng-container *ngFor="let p of project">
                          <option [value]="p.id">{{ p?.name }}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3">
                    <div class="form-group">
                      <div class="mt-3">
                        <a
                          class="btn btn-light-primary btn-sm px-6 font-weight-bold"
                          (click)="searchFilterValue()"
                          ngbTooltip="Search Filter"
                          placement="bottom"
                          >Search</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!--Complaint List Start-->
            <div class="">
              <table
                class="table table-head-custom table-responsive-sm table-vertical-center"
                id="kt_advance_table_widget_4"
              >
                <thead>
                  <tr>
                    <th style="width: 15%">Title</th>
                    <th style="width: 5%">Status</th>
                    <th style="width: 5%">Priority</th>
                    <th style="width: 40%">Assign to</th>
                    <th style="width: 20%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let index of list">
                    <tr>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer"
                          (click)="openComplaint(index?.id)"
                          >{{ index.name }}</span
                        >
                        <span
                          class="text-muted font-weight-bold text-muted d-block"
                          >{{ index?.code }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          <span
                            class="label label-lg label-light-primary label-inline"
                            >{{ index.task_status.name }}</span
                          >
                        </span>
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          <span
                            class="label label-lg label-light-green label-inline"
                            >{{ this.index.priority.name }}</span
                          >
                        </span>
                      </td>
                      <td style="padding-top: 17px">
                        <ng-container *ngFor="let task of index?.task_assign">
                          <p style="line-height: 4px" class="">
                            <span class="text-dark-75 font-weight-bold">{{
                              task?.name.name
                            }}</span>
                            <span
                              style="font-size: 10px"
                              class="ml-2 text-dark-75"
                              >{{ task?.start_date | date: "dd-MM-yyyy" }}</span
                            >
                            <span
                              style="font-size: 10px"
                              class="text-dark-75 font-weight-bold ml-1"
                              >to</span
                            >
                            <span
                              style="font-size: 10px"
                              class="ml-1 text-dark-75"
                              >{{ task?.end_date | date: "dd-MM-yyyy" }}</span
                            >
                            <span
                              style="font-size: 10px"
                              class="ml-1 text-dark-75 font-weight-bold"
                              >({{ task?.allocated_time }})</span
                            >
                          </p>
                        </ng-container>
                      </td>

                      <td>
                        <div class="d-flex justify-content-between">
                          <button
                            type="button"
                            class="btn btn-light-primary btn-sm"
                            (click)="openView(index?.id)"
                          >
                            View Task
                          </button>
                          <button
                            type="button"
                            class="btn btn-light-primary btn-sm"
                            (click)="showView(index?.id)"
                          >
                            Show
                          </button>
                        </div>
                      </td>
                      <td class="text-right"></td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!--pagination-->
            <div class="pagination_main">
              <ngb-pagination
                [(page)]="page"
                (pageChange)="loadPage(page)"
                [pageSize]="perPage"
                [collectionSize]="totalPages"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              ></ngb-pagination>
              <div class="dropdown">
                <a
                  class="btn dropdown-toggle btn-light btn-sm"
                  role="button"
                  id="pagination_btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="page_count">
                    {{ perPageValue }}
                  </span>
                  <span class="count_count_icon"></span>
                </a>
                <div class="dropdown-menu" aria-labelledby="pagination_btn">
                  <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                    >25</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                    >50</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                    >75</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                    >100</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit('')"
                    >All</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--begin::Quick Cart-->
<div
  id="indent-complaint"
  class="offcanvas offcanvas-right p-5"
  style="overflow: auto"
>
  <!--begin::Header-->
  <!--begin::Header-->
  <div
    class="offcanvas-header d-flex align-items-center justify-content-between pb-7"
  >
    <h4 class="font-weight-bold m-0">Add Task</h4>
    <button
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      (click)="closeIndentList()"
    >
      <i class="ki ki-close icon-xs text-muted"></i>
    </button>
  </div>
  <!--begin::Content-->
  <div class="offcanvas-content ot-patients">
    <!--begin::Wrapper-->
    <div class="offcanvas-wrapper mb-5 scroll-pull">
      <form [formGroup]="addComplaint" (ngSubmit)="onSubmit()">
        <!--begin::Content-->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid main-content">
          <!--begin::Container-->
          <!--begin::Card-->
          <div class="">
            <div class="row">
              <!--Name-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="form-group">
                  <label>Title<span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Name"
                    formControlName="name"
                    [ngClass]="{
                      'is-invalid': isAdd && addComplaint.controls.name.errors
                    }"
                  />
                </div>
              </div>
              <!--Status-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="form-group">
                  <label>Task Status <span class="text-danger">*</span></label>
                  <div
                    [ngClass]="{
                      'is-invalid':
                        isAdd && addComplaint.controls.location.errors
                    }"
                  >
                    <select
                      class="form-control form-control-sm selectpicker"
                      data-live-search="true"
                      id="selectStatus"
                      formControlName="status"
                      name="selectStatus"
                    >
                      <ng-container *ngFor="let status of taskStatus">
                        <!--                        <option [value] [defaultSelected]="status.id">{{status?.name}}</option>-->
                        <option [value]="status.id" *ngIf="status.is_active">
                          {{ status?.name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
              <!--Priority-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-4">
                <div class="form-group">
                  <label>Priority <span class="text-danger">*</span></label>
                  <div
                    [ngClass]="{
                      'is-invalid':
                        isAdd && addComplaint.controls.priority.errors
                    }"
                  >
                    <select
                      class="form-control form-control-sm selectpicker"
                      data-live-search="true"
                      id="prioritySelect3"
                      formControlName="priority"
                      name="modalSelect"
                    >
                      <option value="" [disabled]="true">Select Status</option>
                      <ng-container *ngFor="let p of priority">
                        <option [value]="p.id">{{ p?.name }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
              <!--Location-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="form-group">
                  <label>Location</label>

                  <select
                    class="form-control form-control-sm selectpicker"
                    data-live-search="true"
                    id="selectedLocation"
                    formControlName="location"
                    name="selectedLocation"
                  >
                    <option value="" [disabled]="true">Select Location</option>
                    <ng-container *ngFor="let location of locationData">
                      <option [value]="location.id">
                        {{ location?.name }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <!--         project-->

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-4">
                <div class="form-group">
                  <label>Project <span class="text-danger">*</span></label>
                  <div
                    [ngClass]="{
                      'is-invalid':
                        isAdd && addComplaint.controls.project.errors
                    }"
                  >
                    <select
                      class="form-control form-control-sm selectpicker"
                      data-live-search="true"
                      id="addProject"
                      formControlName="project"
                      name="modalSelect"
                    >
                      <option value="" [disabled]="true">Select Project</option>
                      <ng-container *ngFor="let p of project">
                        <option [value]="p.id" *ngIf="p.is_active">
                          {{ p?.name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>

              <!--File Upload-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-4">
                <div class="form-group">
                  <label class="mr-2">File Upload</label>
                  <input
                    type="file"
                    class="w-100"
                    (change)="fileUploadFunc($event)"
                  />
                </div>
              </div>

              <hr
                class="w-100 line-hide"
                style="margin-bottom: 20px !important"
              />
              <!--Department-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="form-group">
                  <label>Department </label>
                  <select
                    class="form-control form-control-sm selectpicker"
                    data-live-search="true"
                    id="department"
                    name="department"
                    formControlName="department"
                  >
                    <option value="" [disabled]="true">
                      Select Department
                    </option>
                    <ng-container *ngFor="let dep of depart">
                      <option [value]="dep.id">{{ dep.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <!--Contact Person Name-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="d-block form-group">
                  <label>Contact Person</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Contact Person"
                    formControlName="contact_person"
                    [ngClass]="{
                      'is-invalid':
                        isAdd && addComplaint.controls.contact_person.errors
                    }"
                  />
                </div>
              </div>
              <!--Name-->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="form-group">
                  <label>Telephone No.</label>
                  <input
                    type="tel"
                    class="form-control form-control-sm"
                    (keypress)="_onlyNumeric($event)"
                    placeholder="Telephone Number"
                    formControlName="phone"
                  />
                </div>
              </div>
              <!--link-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="form-group">
                  <label>External Link.</label>
                  <input
                    type="tel"
                    class="form-control form-control-sm"
                    placeholder="Add link"
                    formControlName="external_link"
                  />
                </div>
              </div>
              <!--Description-->
              <div class="col-12">
                <div class="form-group">
                  <label>Description <span class="text-danger">*</span></label>
                  <textarea
                    style="height: 150px; resize: none"
                    class="form-control form-control-sm"
                    placeholder="Description"
                    formControlName="description"
                    [ngClass]="{
                      'is-invalid':
                        isAdd && addComplaint.controls.description.errors
                    }"
                  ></textarea>
                </div>
              </div>
              <!--submit button-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="form-group">
                  <div class="text-right mt-3">
                    <button class="btn btn-sm btn-primary" type="submit">
                      Add Task
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Card-->
        </div>
      </form>
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Content-->
</div>
<!--end::Quick Cart-->
<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ complaintSingledata?.name }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="getComplainData" (ngSubmit)="onSubmitData()">
        <div class="modal-body">
          <div class="row">
            <div class="col-12 p-0">
              <div class="gutter-b">
                <!-- Description Card-->
                <div class="">
                  <!--Description-->
                  <div
                    class="d-flex"
                    style="
                      border-bottom: 1px solid #dddddd;
                      margin-bottom: 13px;
                    "
                  >
                    <div class="col-6">
                      <h6>Task Info</h6>
                      <div class="form-group">
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Status</strong></label></span
                          >
                          <span
                            >{{ complaintSingledata?.task_status.name }}
                          </span>
                        </div>
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Priority:</strong></label></span
                          >
                          <span>{{ complaintSingledata?.priority.name }}</span>
                        </div>
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Tags</strong></label></span
                          >
                          <ng-container
                            *ngFor="let t of complaintSingledata?.tags"
                          >
                            <span class="badge badge-secondary ml-1">{{
                              t.name
                            }}</span>
                          </ng-container>
                        </div>
                        <div
                          class="d-flex"
                          *ngIf="complaintSingledata?.external_link"
                        >
                          <span class="mr-4"
                            ><label
                              ><strong>External Link:</strong></label
                            ></span
                          >
                          <a
                            href="{{ complaintSingledata?.external_link }}"
                            *ngIf="complaintSingledata?.external_link"
                            target="_blank"
                          >
                            Link</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <h6>Creator Info</h6>
                      <div class="form-group">
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Name:</strong></label></span
                          >
                          <span
                            >{{ complaintSingledata?.owner?.first_name }}
                            <span
                              *ngIf="
                                complaintSingledata?.owner?.middle_name !== null
                              "
                            >
                              {{
                                complaintSingledata?.owner?.middle_name
                              }}</span
                            >
                            {{ complaintSingledata?.owner?.last_name }}</span
                          >
                        </div>
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Designation:</strong></label></span
                          >
                          <span>{{
                            complaintSingledata?.owner?.designation
                          }}</span>
                        </div>
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Department:</strong></label></span
                          >
                          <span>{{ complaintSingledata?.department }}</span>
                        </div>
                      </div>
                    </div>
                    <!--                    <div class="col-6">-->
                    <!--                      <h6>Creator Info</h6>-->
                    <!--                      <div class="form-group">-->
                    <!--                        <div class="d-flex">-->
                    <!--                          <span class="mr-4"><label><strong>Name:</strong></label></span>-->
                    <!--                          <span>{{complaintSingledata?.owner?.first_name}} <span-->
                    <!--                            *ngIf="complaintSingledata?.owner?.middle_name !== null"> {{complaintSingledata?.owner?.middle_name}}</span> {{complaintSingledata?.owner?.last_name}}</span>-->
                    <!--                        </div>-->
                    <!--                        <div class="d-flex">-->
                    <!--                          <span class="mr-4"><label><strong>Designation:</strong></label></span>-->
                    <!--                          <span>{{complaintSingledata?.owner?.designation}}</span>-->
                    <!--                        </div>-->
                    <!--                        <div class="d-flex">-->
                    <!--                          <span class="mr-4"><label><strong>Department:</strong></label></span>-->
                    <!--                          <span>{{complaintSingledata?.department}}</span>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-6">-->
                    <!--                      <h6>Contact Person Info</h6>-->
                    <!--                      <div class="d-flex">-->
                    <!--                        <span class="mr-4"><label><strong>Name:</strong></label></span>-->
                    <!--                        <span>{{complaintSingledata?.contact_person}}</span>-->
                    <!--                      </div>-->
                    <!--                      <div class="d-flex">-->
                    <!--                        <span class="mr-4"><label><strong>Phone No.:</strong></label></span>-->
                    <!--                        <span>{{complaintSingledata?.phone}}</span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label
                        >Description <span class="text-danger">*</span></label
                      >
                      <textarea
                        style="height: 70px; resize: none"
                        disabled
                        class="form-control form-control-sm"
                        placeholder="Description"
                        id="description"
                        formControlName="description"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gutter-b">
                <!-- Description Card-->
                <div class="d-flex">
                  <!--Status-->
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <div class="form-group">
                      <label
                        >Task Status <span class="text-danger">*</span></label
                      >
                      <select
                        class="form-control form-control-sm selectpicker"
                        data-live-search="true"
                        id="taskStatus"
                        formControlName="status"
                        name="modalSelect"
                      >
                        <option value="" [disabled]="true">
                          Select Status
                        </option>
                        <ng-container *ngFor="let status of taskStatus">
                          <option [value]="status.id">
                            {{ status?.name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <!--Priority-->
                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="form-group">
                      <label>Priority <span class="text-danger">*</span></label>
                      <select
                        class="form-control form-control-sm selectpicker"
                        data-live-search="true"
                        id="prioritySelect"
                        formControlName="priority"
                        name="modalSelect"
                      >
                        <option value="" [disabled]="true">
                          Select Status
                        </option>
                        <ng-container *ngFor="let p of priority">
                          <option [value]="p.id">{{ p?.name }}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <!--Employee Of Department-->
                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="form-group tagsInput">
                      <label>Tag </label>
                      <select
                        class="form-control form-control-sm select2"
                        multiple="multiple"
                        id="medicationsAllergies"
                      >
                        <ng-container *ngFor="let tag of tagListData">
                          <option [value]="tag.id" *ngIf="tag.is_removed">
                            {{ tag.name }}
                          </option>
                        </ng-container>
                      </select>
                      <!-- <input type="text" class="form-control form-control-sm" formControlName="tag" placeholder="tag"/>-->
                    </div>
                  </div>
                  <!--                  <div class="col-lg-3 col-md-3 ">-->
                  <!--                    <button (click)="addAssignData()"-->
                  <!--                            class="btn btn-primary btn-md mt-5" type="button"> Assign person-->
                  <!--                    </button>-->
                  <!--                  </div>-->
                </div>
                <div class="col-lg-12">
                  <div
                    class="mt-5"
                    formArrayName="assign"
                    *ngFor="let f of assignData.controls; let i = index"
                  >
                    <ng-container *ngIf="f.get('is_remove').value === false">
                      <div [formGroupName]="i" class="row">
                        <div class="col-lg-4">
                          <div class="">
                            <label>Employee:</label>
                            <select
                              class="form-control form-control-sm selectpicker"
                              data-live-search="true"
                              id="employee{{ i }}"
                              formControlName="assign_to"
                              name="employee"
                              (change)="
                                getfocusdata(
                                  $event.target.value,
                                  'assign_to',
                                  i,
                                  complaintSingledata?.id
                                )
                              "
                            >
                              <option value="" [disabled]="true">
                                Select Employee
                              </option>
                              <ng-container *ngFor="let emp of employee">
                                <option [value]="emp.id">
                                  {{ emp?.employee_file_no }} -
                                  {{ emp?.first_name }}
                                  {{ emp?.middle_name || "" }}
                                  {{ emp?.last_name }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <label>Start Date</label>
                          <div class="form-group">
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              id="fromDate{{ i }}"
                              (focusout)="
                                getfocusdata(
                                  $event.target.value,
                                  'start_date',
                                  i,
                                  complaintSingledata?.id
                                )
                              "
                              formControlName="start_date"
                            />
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <label>Due Date</label>
                          <div class="form-group">
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              id="toDate{{ i }}"
                              (focusout)="
                                getfocusdata(
                                  $event.target.value,
                                  'due_date',
                                  i,
                                  complaintSingledata?.id
                                )
                              "
                              formControlName="due_date"
                            />
                          </div>
                        </div>
                        <!--                      <div class="col-lg-2">-->
                        <!--                        <label>Duration</label>-->
                        <!--                        <div class="form-group">-->
                        <!--                          <input type="text" class="form-control" formControlName="duration" placeholder="Duration"/>-->
                        <!--                        </div>-->
                        <!--                      </div>-->
                        <div class="col-lg-2">
                          <label>Time</label>
                          <div class="form-group">
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              formControlName="time"
                              (focusout)="
                                getfocusdata(
                                  $event.target.value,
                                  'time',
                                  i,
                                  complaintSingledata?.id
                                )
                              "
                              placeholder="Time"
                            />
                          </div>
                        </div>
                        <div class="col-lg-1 ml-auto">
                          <button
                            (click)="deleteAssignData(i)"
                            class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mt-4"
                            type="button"
                          >
                            <span class="svg-icon svg-icon-primary svg-icon-2x">
                              <svg
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                height="24px"
                                version="1.1"
                                viewBox="0 0 24 24"
                                width="24px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  fill="none"
                                  fill-rule="evenodd"
                                  stroke="none"
                                  stroke-width="1"
                                >
                                  <rect
                                    height="24"
                                    width="24"
                                    x="0"
                                    y="0"
                                  ></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                  ></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-lg-2 text-right" style="margin-left: auto">
                  <button
                    (click)="addAssignData()"
                    class="btn btn-primary btn-sm mt-5"
                    type="button"
                  >
                    Assign person
                  </button>
                </div>
              </div>

              <div class="gutter-b mt-3">
                <!-- Description Card-->
                <div class="">
                  <!--Description-->
                  <div class="col-12">
                    <div class="form-group">
                      <label>Comments<span class="text-danger">*</span></label>
                      <textarea
                        style="height: 70px; resize: none"
                        class="form-control form-control-sm"
                        placeholder="comments"
                        id="comments"
                        formControlName="comments"
                        [ngClass]="{
                          'is-invalid':
                            isAddTransaction &&
                            getComplainData.controls.comments.errors
                        }"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-6">
                <h4>Transaction History</h4>

                <div class="mt-6">
                  <div class="timeline timeline-3">
                    <div class="timeline-items">
                      <ng-container
                        *ngFor="
                          let indexvalue of complaintSingledata?.transaction
                        "
                      >
                        <div class="timeline-item">
                          <div class="timeline-media">
                            <img
                              *ngIf="
                                indexvalue?.employee?.profile_image === '' ||
                                indexvalue?.employee?.profile_image === null
                              "
                              src="../../../../../assets/media/users/blank.png"
                              style="height: 100%; width: 100%"
                            />
                            <img
                              *ngIf="
                                indexvalue?.employee?.profile_image !== '' &&
                                indexvalue?.employee?.profile_image !== null
                              "
                              src="{{ imagePath }}{{
                                indexvalue?.employee?.profile_image
                              }}"
                              style="height: 100%; width: 100%"
                              class="side_applicant_image"
                            />
                          </div>
                          <div class="timeline-content">
                            <div
                              class="d-flex align-items-center justify-content-between mb-3"
                            >
                              <div class="w-100 mr-2">
                                <a
                                  class="text-dark-75 text-hover-primary font-weight-bold"
                                >
                                  <span
                                    class="font-weight-bolder text-hover-primary pb-1"
                                    >{{ indexvalue?.employee?.first_name }}
                                    <span
                                      *ngIf="
                                        indexvalue?.employee?.middle_name !==
                                        null
                                      "
                                    >
                                      {{
                                        indexvalue?.employee?.middle_name
                                      }}</span
                                    >
                                    {{ indexvalue?.employee?.last_name }}</span
                                  >
                                  <span
                                    class="text-muted font-weight-bold text-muted ml-2"
                                    >{{
                                      indexvalue?.created_at
                                        | date: "dd-MM-yyyy h:mm a"
                                    }}</span
                                  >
                                </a>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div
                                style="flex-basis: 50%"
                                *ngIf="indexvalue?.description"
                              >
                                <div class="d-block">
                                  <span><strong>Changes:</strong></span
                                  ><span class="ml-2"
                                    >{{ indexvalue?.description }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="indexvalue?.comments">
                              <p class="p-0">
                                <strong>Comments: </strong
                                >{{ indexvalue?.comments }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="viewModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="viewModalLabel">
          {{ complaintSingledata?.name }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="getComplainData" (ngSubmit)="onSubmitData()">
        <div class="modal-body">
          <div class="row">
            <div class="col-12 p-0">
              <div class="gutter-b">
                <!-- Description Card-->
                <div class="">
                  <!--Description-->
                  <div
                    class="d-flex"
                    style="
                      border-bottom: 1px solid #dddddd;
                      margin-bottom: 13px;
                    "
                  >
                    <div class="col-6">
                      <h6>Task Info</h6>
                      <div class="form-group">
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Status</strong></label></span
                          >
                          <span
                            >{{ complaintSingledata?.task_status.name }}
                          </span>
                        </div>
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Priority:</strong></label></span
                          >
                          <span>{{ complaintSingledata?.priority.name }}</span>
                        </div>
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Tags</strong></label></span
                          >
                          <ng-container
                            *ngFor="let t of complaintSingledata?.tags"
                          >
                            <span class="badge badge-secondary ml-1">{{
                              t.name
                            }}</span>
                          </ng-container>
                        </div>
                        <div
                          class="d-flex"
                          *ngIf="complaintSingledata?.external_link"
                        >
                          <span class="mr-4"
                            ><label
                              ><strong>External Link:</strong></label
                            ></span
                          >
                          <a
                            href="{{ complaintSingledata?.external_link }}"
                            *ngIf="complaintSingledata?.external_link"
                            target="_blank"
                          >
                            Link</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <h6>Creator Info</h6>
                      <div class="form-group">
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Name:</strong></label></span
                          >
                          <span
                            >{{ complaintSingledata?.owner?.first_name }}
                            <span
                              *ngIf="
                                complaintSingledata?.owner?.middle_name !== null
                              "
                            >
                              {{
                                complaintSingledata?.owner?.middle_name
                              }}</span
                            >
                            {{ complaintSingledata?.owner?.last_name }}</span
                          >
                        </div>
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Designation:</strong></label></span
                          >
                          <span>{{
                            complaintSingledata?.owner?.designation
                          }}</span>
                        </div>
                        <div class="d-flex">
                          <span class="mr-4"
                            ><label><strong>Department:</strong></label></span
                          >
                          <span>{{ complaintSingledata?.department }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label
                        >Description <span class="text-danger">*</span></label
                      >
                      <textarea
                        style="height: 70px; resize: none"
                        disabled
                        class="form-control form-control-sm"
                        placeholder="Description"
                        id="description"
                        formControlName="description"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="gutter-b">
                  <!-- Description Card-->
                  <!--                <div class="d-flex">-->
                  <!--                  &lt;!&ndash;Status&ndash;&gt;-->
                  <!--                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">-->
                  <!--                    <div class="form-group">-->
                  <!--                      <label>Task Status <span class="text-danger">*</span></label>-->
                  <!--                      <h4>{{complaintSingledata?.task_status.name}}</h4>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  &lt;!&ndash;Priority&ndash;&gt;-->
                  <!--                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">-->
                  <!--                    <div class="form-group">-->
                  <!--                      <label>Priority <span class="text-danger">*</span></label>-->
                  <!--                      <h4>{{complaintSingledata?.priority.name}}</h4>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  &lt;!&ndash;Employee Of Department&ndash;&gt;-->
                  <!--                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">-->
                  <!--                    <div class="form-group tagsInput" *ngFor="let t of complaintSingledata?.tags">-->
                  <!--                      <label>Tag <span class="text-danger">*</span></label>-->
                  <!--                      <h4>{{t.name}}</h4>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <!--                  <div class="col-lg-3 col-md-3 ">-->
                  <!--                    <button (click)="addAssignData()"-->
                  <!--                            class="btn btn-primary btn-md mt-5" type="button"> Assign person-->
                  <!--                    </button>-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="gutter-b mt-3">
                <!-- Assignto Card-->
                <div class="">
                  <!--Assignto-->
                  <div
                    class="col-12"
                    id="grandparentContent"
                    aria-expanded="true"
                  >
                    <table
                      class="table table-responsive-sm table-vertical-center"
                    >
                      <th style="width: 25%">Name</th>
                      <th style="width: 25%">Start Date</th>
                      <th style="width: 25%">Due Date</th>
                      <th style="width: 15%">Allocated Time</th>
                      <th style="width: 10%">Action</th>

                      <ng-container
                        *ngFor="
                          let a of complaintSingledata?.task_assign;
                          let i = index
                        "
                      >
                        <tr
                          class="collapsed pointer mainRow isShow"
                          [ngClass]="'isShow' + i"
                          data-toggle="collapse"
                          [attr.data-target]="'.testContent' + i"
                        >
                          <td>
                            {{ a?.name.name }}
                          </td>
                          <td>
                            {{ a?.start_date | date: "dd-MM-yyyy" }}
                          </td>
                          <td>
                            {{ a?.end_date | date: "dd-MM-yyyy" }}
                          </td>
                          <td>
                            {{ a?.allocated_time }}
                          </td>
                          <td>
                            <button
                              class="btn btn-primary btn-sm"
                              type="button"
                              (click)="
                                $event.stopPropagation();
                                timeLog(
                                  a.employee,
                                  complaintSingledata.id,
                                  a.is_completed
                                )
                              "
                            >
                              Add Time
                            </button>
                          </td>
                        </tr>
                        <tr class="collapse testContent{{ i }} ">
                          <td colspan="5">
                            <table class="table info-table table-responsive-sm">
                              <th style="width: 20%">
                                <strong>Date: </strong>
                              </th>
                              <th style="width: 25%">
                                <strong>Log Time: </strong>
                              </th>
                              <th style="width: 25%">
                                <strong>Comments: </strong>
                              </th>

                              <ng-container *ngFor="let subData of a.time_lock">
                                <tr>
                                  <td>
                                    {{
                                      subData?.created_at
                                        | date: "dd-MM-yyyy h:mm a"
                                    }}
                                  </td>
                                  <td>{{ subData?.time }}</td>
                                  <td>{{ subData?.comments }}</td>
                                </tr>
                              </ng-container>
                            </table>
                          </td>

                          <!--                          <td><span></span> {{subData.comments}}</td>-->
                          <!--                          <td> <span><strong>Time Log: </strong></span>{{subData.time}}</td>-->
                          <!--                          <td> <span><strong>Date: </strong></span>{{subData.created_at | date:'small' }}</td>-->
                        </tr>
                      </ng-container>
                    </table>
                  </div>
                </div>
              </div>

              <div class="gutter-b mt-3">
                <!-- Description Card-->
                <div class="">
                  <!--Description-->
                  <div class="col-12">
                    <div class="d-block form-group">
                      <label class="">Status:</label>
                      <select
                        class="form-control form-control-sm selectpicker"
                        data-live-search="true"
                        id="viewStatus"
                        formControlName="status"
                        name="modalSelect"
                      >
                        <option value="" [disabled]="true">
                          Select Status
                        </option>
                        <ng-container *ngFor="let status of taskStatus">
                          <option [value]="status.id">
                            {{ status?.name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-6">
                <h4>Transaction History</h4>

                <div class="mt-6">
                  <div class="timeline timeline-3">
                    <div class="timeline-items">
                      <ng-container
                        *ngFor="
                          let indexvalue of complaintSingledata?.transaction
                        "
                      >
                        <div class="timeline-item">
                          <div class="timeline-media">
                            <img
                              *ngIf="
                                indexvalue?.employee?.profile_image === '' ||
                                indexvalue?.employee?.profile_image === null
                              "
                              src="../../../../../assets/media/users/blank.png"
                              style="height: 100%; width: 100%"
                            />
                            <img
                              *ngIf="
                                indexvalue?.employee?.profile_image !== '' &&
                                indexvalue?.employee?.profile_image !== null
                              "
                              src="{{ imagePath }}{{
                                indexvalue?.employee?.profile_image
                              }}"
                              style="height: 100%; width: 100%"
                              class="side_applicant_image"
                            />
                          </div>
                          <div class="timeline-content">
                            <div
                              class="d-flex align-items-center justify-content-between mb-3"
                            >
                              <div class="w-100 mr-2">
                                <a
                                  class="text-dark-75 text-hover-primary font-weight-bold"
                                >
                                  <span
                                    class="font-weight-bolder text-hover-primary pb-1"
                                    >{{ indexvalue?.employee?.first_name }}
                                    <span
                                      *ngIf="
                                        indexvalue?.employee?.middle_name !==
                                        null
                                      "
                                    >
                                      {{
                                        indexvalue?.employee?.middle_name
                                      }}</span
                                    >
                                    {{ indexvalue?.employee?.last_name }}</span
                                  >
                                  <span
                                    class="text-muted font-weight-bold text-muted ml-2"
                                    >{{
                                      indexvalue?.created_at
                                        | date: "dd-MM-yyyy h:mm a"
                                    }}</span
                                  >
                                </a>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div
                                style="flex-basis: 50%"
                                *ngIf="indexvalue?.description"
                              >
                                <div class="d-block">
                                  <span><strong>Changes:</strong></span
                                  ><span class="ml-2"
                                    >{{ indexvalue?.description }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="indexvalue?.comments">
                              <p class="p-0">
                                <strong>Comments: </strong
                                >{{ indexvalue?.comments }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="timeLogModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Time and Comments</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="timeLogData" (ngSubmit)="onSubmitData2()">
        <div class="modal-body">
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label>Time<span class="text-danger">*</span></label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="Time"
                  formControlName="time"
                  [ngClass]="{
                    'is-invalid': timeCheck && timeLogData.controls.time.errors
                  }"
                />
              </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6 col-12 mt-5">
              <div class="form-group">
                <!--                  <label> Status  </label>-->
                <label class="checkbox checkbox-sm">
                  <input
                    type="checkbox"
                    id="dateRequired"
                    formControlName="is_completed"
                  />
                  <span class="mr-2"></span>Mark As Completed
                </label>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label>Comments<span class="text-danger">*</span></label>
                <textarea
                  style="height: 70px; resize: none"
                  class="form-control form-control-sm"
                  placeholder="comments"
                  formControlName="comments"
                  [ngClass]="{
                    'is-invalid':
                      timeCheck && timeLogData.controls.comments.errors
                  }"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </div>
  </div>
</div>
