<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid pt-0" id="kt_content">
  <!--Page Header :: Start-->
  <div class="card card-custom headerCard">
    <div class="card-header ribbon ribbon-top">
      <!--      <div class="ribbon-target bg-danger" style="top: -2px; right: 20px;"></div>-->
      <div class="card-toolbar">
        <ul class="nav nav-light-success nav-bold nav-pills">
          <li class="nav-item">
            <h5>Survey</h5>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div>
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card" style="margin-top: 25px">
        <div class="card-body">
          <form
            [formGroup]="searchForm"
            (ngSubmit)="searchFilterValue()"
            autocomplete="off"
          >
            <div class="row">
              <div class="col-sm-3 col-6">
                <div class="form-group">
                  <label>From Date </label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="fromDate"
                    data-target="#fromDate"
                    data-toggle="datetimepicker"
                    formControlName="from_date"
                  />
                </div>
              </div>

              <div class="col-sm-3 col-6">
                <div class="form-group">
                  <label>To Date </label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="toDate"
                    data-target="#toDate"
                    data-toggle="datetimepicker"
                    formControlName="to_date"
                  />
                </div>
              </div>
              <!--              <div class="col-sm-3 col-6">-->
              <!--                <div class="form-group">-->
              <!--                  <label>From Date</label>-->
              <!--                  <input type="text" placeholder="From Date" readonly class="form-control form-control-sm" id="start_date" formControlName="from_date">-->
              <!--                </div>-->
              <!--              </div>-->

              <!--              <div class="col-sm-3 col-6">-->
              <!--                <div class="form-group">-->
              <!--                  <label>To Date </label>-->
              <!--                  <input type="text" placeholder="From Date" readonly class="form-control form-control-sm" id="end_date" formControlName="to_date">-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              speciality-->
              <div class="col-sm-3 col-6">
                <div class="form-group">
                  <label>Speciality</label>
                  <select
                    class="form-control form-control-sm selectPickerDepartment bootstrap-select-container"
                    data-live-search="true"
                    formControlName="speciality"
                  >
                    <option value="" [selected]="true">Select</option>
                    <ng-container *ngFor="let dep of depart">
                      <option [value]="dep.id">{{ dep.title }}</option>
                    </ng-container>
                    <!--                      <option>QA</option>-->
                    <!--                      <option>SE</option>-->
                    <!--                      <option>DR</option>-->
                  </select>
                </div>
              </div>
              <div class="col-sm-3 col-6">
                <label>Country of Practice</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm country"
                      data-live-search="true"
                      formControlName="country_of_practice"
                    >
                      <option value="" [selected]="true">Select</option>
                      <ng-container *ngFor="let country of countriesList">
                        <option [value]="country.id">{{ country.name }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
              <!--how wold you like receive the compensation?-->
              <div class="col-sm-3 col-6">
                <label>Compensation Type</label>
                <!--                    <label>How would you like receive the compensation?</label>-->
                <div class="form-group">
                  <select
                    class="form-control form-control-sm selectpicker"
                    formControlName="compensation"
                  >
                    <option [ngValue]="''" [disabled]="true">Select</option>
                    <option [ngValue]="1">Voucher</option>
                    <option [ngValue]="2">Bank transfer</option>
                    <option [ngValue]="3">Donation to charity</option>
                  </select>
                  <!--                  <small style="font-size: 8px">* How would you like to receive the compensation?</small>-->
                </div>
              </div>
              <div class="col-sm-3 col-6" style="margin-top: 20px">
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    ngbTooltip="Search Report"
                    placement="bottom"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <!--add button-->
          </form>
        </div>
      </div>

      <div class="card card-custom gutter-b mt-7" *ngIf="surveyData.length">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Table-->
          <!--          <div class="table_top_bar mb-3">-->
          <!--            <div class="row">-->
          <!--              <div class="col-12 col-sm-12 text-right">-->
          <!--                <div class="dropdown dropdown-inline mr-2">-->
          <!--                  <button type="button" class="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">-->
          <!--                      <span class="svg-icon svg-icon-md">-->
          <!--                         &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Design/PenAndRuller.svg&ndash;&gt;-->
          <!--                         <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
          <!--                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
          <!--                               <rect x="0" y="0" width="24" height="24"></rect>-->
          <!--                               <path d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z" fill="#000000" opacity="0.3"></path>-->
          <!--                               <path d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z" fill="#000000"></path>-->
          <!--                            </g>-->
          <!--                         </svg>-->
          <!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
          <!--                      </span>-->
          <!--                    Export-->
          <!--                  </button>-->
          <!--                  &lt;!&ndash;begin::Dropdown Menu&ndash;&gt;-->
          <!--                  <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right" x-placement="bottom-end" style="position: absolute; transform: translate3d(-71px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">-->
          <!--                    &lt;!&ndash;begin::Navigation&ndash;&gt;-->
          <!--                    <ul class="navi flex-column navi-hover py-2">-->
          <!--                      <li class="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Choose an option:</li>-->
          <!--                      <li class="navi-item">-->
          <!--                        <a class="navi-link">-->
          <!--                                    <span class="navi-icon">-->
          <!--                                    <i class="fas fa-file-alt"></i>-->
          <!--                                    </span>-->
          <!--                          <span class="navi-text">CSV</span>-->
          <!--                        </a>-->
          <!--                      </li>-->
          <!--                    </ul>-->
          <!--                    &lt;!&ndash;end::Navigation&ndash;&gt;-->
          <!--                  </div>-->
          <!--                  &lt;!&ndash;end::Dropdown Menu&ndash;&gt;-->
          <!--                </div>-->
          <!--              </div>-->

          <!--            </div>-->
          <!--          </div>-->
          <div class="table-responsive position-relative">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_3"
            >
              <thead>
                <tr>
                  <th style="width: 25%">
                    Name/Personal Contact/Hospital Address
                  </th>
                  <th style="width: 25%">Medical ID./Workplace Contact</th>
                  <th style="width: 25%">Speciality/Country</th>
                  <th style="width: 25%">Email/Hospital Name</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let survey of surveyData; let i = index">
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ survey?.first_name || "--" }}</span
                    >
                    <span class="text-muted font-weight-bold d-block">{{
                      survey?.personal_phone_number || "--"
                    }}</span>
                    <!--                  <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{invoice_item?.admission_date | date:'dd-MM-yyyy h:mm a'}}</span>-->
                    <span class="text-muted font-weight-bold d-block">{{
                      survey?.address || "--"
                    }}</span>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ survey.medical_id_number || "--" }}</span
                    >
                    <span class="text-muted font-weight-bold d-block">{{
                      survey?.work_place_phone_number || "--"
                    }}</span>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ survey.speciality || "--" }}</span
                    >
                    <span class="text-muted font-weight-bold d-block">{{
                      survey?.country_of_practice || "--"
                    }}</span>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ survey.email || "--" }}</span
                    >
                    <span class="text-muted font-weight-bold d-block">{{
                      survey?.primary_practice_name || "--"
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
          <!--pagination-->
          <div class="pagination_main" *ngIf="surveyData.length">
            <ngb-pagination
              [(page)]="page"
              (pageChange)="loadPage(page)"
              [pageSize]="perPage"
              [collectionSize]="totalPages"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="false"
              [boundaryLinks]="true"
            ></ngb-pagination>
            <div class="dropdown">
              <a
                class="btn dropdown-toggle btn-light btn-sm"
                role="button"
                id="pagination_btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="page_count">
                  {{ perPage }}
                </span>
                <span class="count_count_icon"></span>
              </a>
              <div class="dropdown-menu" aria-labelledby="pagination_btn">
                <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                  >25</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                  >50</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                  >75</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                  >100</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit('')"
                  >All</a
                >
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</div>
