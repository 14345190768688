import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  transform(birthdate: string): string {
    const today = moment();
    const birthdateMoment = moment(birthdate, 'YYYY-MM-DD');

    const years = today.diff(birthdateMoment, 'years');
    birthdateMoment.add(years, 'years');

    const months = today.diff(birthdateMoment, 'months');
    birthdateMoment.add(months, 'months');

    const days = today.diff(birthdateMoment, 'days');
    const weeks = Math.floor(days / 7);
    const remainingDays = days % 7;

    if (years >= 20) {
      return `${years} yrs`;
    } else if (years < 20 && years > 1) {
      return `${years} yr ${months} mo`;
    } else if (years < 1 && months > 1) {
      return `${months} mo ${days} d`;
    } else if (years === 1 && months === 0){
      return `${years} yr`;
    } else if (years === 1 && months >= 1){
      return `${years} yr ${months} mo`;
    } else if (years < 1 && months <= 1) {
      if (months === 1 && days === 0) {
        return `${months} mo`;
      } else if (months === 1) {
        return `${months} mo ${days} d`;
      } else {
        return `${weeks} wk ${remainingDays} d`;
      }
    }
  }
}
