import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BirthCertificate } from '../../../_models/birth-certificate';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BirthCertificateService } from '../../../../services/birth-certificate.service';
import { formatDate } from '@angular/common';
import { StaffService } from '../../../../services/staff.service';
import { HospitaldoctorService } from '../../../../services/hospitaldoctor.service';
import { PatientService } from '../../../../services/patient.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-birth-certificate',
  templateUrl: './birth-certificate.component.html',
  styleUrls: ['./birth-certificate.component.css'],
})
export class BirthCertificateComponent implements OnInit {
  @Input() patient: any;
  @Input() doctors: any;

  BirthCertificateForm: UntypedFormGroup;
  ParentPatientSearchForm: UntypedFormGroup;

  birth_certificate: BirthCertificate;
  submitted = false;
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  staffNurses = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private birthCertificateService: BirthCertificateService,
    private staffService: StaffService,
    private patientService: PatientService,
    private hospitalDoctorsService: HospitaldoctorService,
  ) {}

  ngOnInit(): void {
    this.BirthCertificateForm = this.formBuilder.group({
      father_id: [''],
      mother_id: [''],
      ward_id: [''],
      father_first_name: [''],
      father_last_name: [''],
      father_cnic: [''],
      profession: [''],
      phone_number: [''],
      comments: [''],
      last_menstruation_date: [''],
      baby_weight: [''],
      gestational_age: [''],
      doctor_conducting_delivery: [''],
      neonatal_doctor: [''],
      meconium_aspiration_syndrome: [false],
      doctor_not_attended_delivery: [false],
      birth_type: ['', Validators.required],
      caesarian_number: [''],
      rank_of_pregnancy: ['', Validators.required],
      mortality: [false],
      morbidity: [false],
      request_status: ['requested'],
    });
    this.ParentPatientSearchForm = this.formBuilder.group({
      search_query: [''],
      type: [''],
    });
    this.fetchNursingStaff();

    setTimeout(() => {
      $('.doctorSelectpicker').selectpicker();
    }, 500);
  }

  saveBirthCertificate(): any {
    this.submitted = true;
    if (this.BirthCertificateForm.invalid || !this.patient) {
      return;
    }
    if (
      this.BirthCertificateForm.get('doctor_conducting_delivery').value === null
    ) {
      this.BirthCertificateForm.get('doctor_conducting_delivery').setValue('');
    }
    this.BirthCertificateForm.get('request_status').setValue('requested');
    this.birth_certificate = this.BirthCertificateForm.value;
    this.ngxLoader.start();
    this.birthCertificateService
      .createBirthCertificateV2(this.birth_certificate, this.patient)
      .subscribe(
        (data) => {
          this.birth_certificate = data.birth_certificate;
          this.ngxLoader.stop();
          this.BirthCertificateForm.reset();
          this.submitted = false;
          toastr.success('Submitted');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  familyPatient(): any {
    this.ParentPatientSearchForm.value.search_query =
      this.BirthCertificateForm.value.father_id;
    if (this.ParentPatientSearchForm.value.search_query.length == 11) {
      this.ParentPatientSearchForm.value.type = 'phone1';
    } else if (this.ParentPatientSearchForm.value.search_query.length == 8) {
      this.ParentPatientSearchForm.value.type = 'mrn';
    } else if (this.ParentPatientSearchForm.value.search_query.length == 13) {
      this.ParentPatientSearchForm.value.type = 'patient_nic';
    }
    if (this.BirthCertificateForm.get('father_id').value.length === 8) {
      this.fetchFamilyPatients();
    } else {
      this.BirthCertificateForm.patchValue({
        father_first_name: '',
        father_last_name: '',
        phone_number: '',
        father_cnic: '',
      });
    }
  }

  fetchFamilyPatients(): any {
    this.ngxLoader.start();
    this.patientService
      .searchPatientByTypeAdmissionV2(this.ParentPatientSearchForm.value)
      .subscribe(
        (data) => {
          if (data.patients) {
            this.BirthCertificateForm.patchValue({
              father_first_name: data.patients[0]?.first_name,
              father_last_name: data.patients[0]?.last_name,
              phone_number: data.patients[0]?.phone1,
              father_cnic: data.patients[0]?.patient_nic,
            });
            this.birth_certificate = this.BirthCertificateForm.value;
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // onlyNumericAllowDecimal(event: any): any {
  //   if (event.charCode != 46) {
  //     const pattern = /^\d{1,6}(\.\d{1,2})?$/;
  //     const inputChar = String.fromCharCode(event.charCode);
  //     if (!pattern.test(inputChar)) {
  //       // invalid character, prevent input
  //       event.preventDefault();
  //     }
  //   }
  // }
  maxLengthOrAllowDecimal(event: any): any {
    const maxLengthBeforeDecimal = 3;
    const value = event.target.value;
    const decimalIndex = value.indexOf('.');

    if (decimalIndex !== -1 && decimalIndex <= maxLengthBeforeDecimal) {
      const valueAfterDecimal = value.substring(decimalIndex + 1);
      if (valueAfterDecimal.length >= 3) {
        event.preventDefault();
      }
    } else if (value.length >= maxLengthBeforeDecimal) {
      event.preventDefault();
    }
  }

  positiveNoWithDecimal(e: any, decimal: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(e.charCode);
    const value = String(e.target.value);
    const index = value.indexOf('.');
    const val = value.split('.');
    if (inputChar === '.' && value.includes('.')) {
      event.preventDefault();
      return false;
    }
    if (inputChar === '.') {
      return true;
    }
    if (index >= 0 && val.length > 1) {
      if (val[1].length >= decimal - 1) {
        event.preventDefault();
        return false;
      }
    }
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  _keyPressOnlyChar(event: any): any {
    const pattern = /^[a-zA-Z\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  get b(): any {
    return this.BirthCertificateForm.controls;
  }

  daysOfBaby(value: any): any {
    if (value > 300) {
      this.BirthCertificateForm.get('gestational_age').patchValue(300);
    }
  }
  fetchNursingStaff(): any {
    this.staffNurses = [];
    this.staffService.getNursingStaff().subscribe(
      (data) => {
        this.staffNurses = data;
        setTimeout(() => {
          $('.doctorConductingDelivery').selectpicker();
          $('#givenByPicker').selectpicker('refresh');
          $('#givenByPicker').selectpicker();
          $('#checkedByPicker').selectpicker('refresh');
          $('#checkedByPicker').selectpicker();
        }, 500);
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
}
