import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { DepartmentsByHospitalService } from '../../../../../services/departments-by-hospital.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { HospitalService } from '../../../../../services/hospital.service';
import { RouteShareService } from '../../../../../services/route-share.service';
import * as moment from 'moment';
import { UserService } from '../../../../../services/user.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-employee-attendance-reoprt',
  templateUrl: './employee-attendance-reoprt.component.html',
  styleUrls: ['./employee-attendance-reoprt.component.css'],
})
export class EmployeeAttendanceReoprtComponent implements OnInit {
  today = formatDate(new Date(), 'MM-yyyy', 'en');
  depart: any;
  employeesOfDepart: any;
  employeesOfSelectedDepartment: any;
  dates: any;
  employeeRoasterData = [];
  employeeDetails: any;
  roasterData = [];
  shiftData = [];
  department: any;
  month: any;
  shiftFirstData: any;
  applyToAllShift = [];
  applyToAllConfirmation = false;
  applyToAllIndex: any;
  csvBaseFile: any;
  file: any;
  list: any;
  settingData: any;
  screenName = '';
  screenControls = [];
  isShowAttendance = false;
  currentUser: any;
  user: any;
  userdepart: any;

  constructor(
    private departmentsByHospital: DepartmentsByHospitalService,
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private rosterServiceService: RosterServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef,
    private hospitalService: HospitalService,
    private routeService: RouteShareService,
    private userService: UserService,
  ) {
    this.getScreenControls();
    this.screenName = this.routeService.getValue();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userdepart = JSON.parse(
      localStorage.getItem('currentUser'),
    ).doctor_department_id;
    this.user = this.currentUser.employee_id;
    console.log('userdepart', this.userdepart);
    console.log('currentUser', this.user);
    this.getSettingsData();
  }
  getScreenControls(): any {
    this.ngxLoader.start();
    this.userService.UserControlByScreenV2().subscribe(
      (data) => {
        this.screenControls = data.roles;
        this.updateRolesCheck();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  updateRolesCheck(): any {
    if (this.screenControls.length > 0) {
      if (
        this.screenControls.filter((m) => m.code === 'allowattendance').length >
        0
      ) {
        this.isShowAttendance = this.screenControls.filter(
          (m) => m.code === 'allowattendance',
        )[0].is_show;
      }
      this.getDepartments();
      if (!this.isShowAttendance) {
        this.department = this.userdepart;
        this.getEmployeesOfDepartment(this.department);
        console.log('this.department', this.department);
      }
    }
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getEmployeesOfDepartment(id): any {
    this.employeesOfDepart = [];
    this.configurationService.getEmployeesOfSpecificDepartment(id).subscribe(
      (data) => {
        this.employeesOfDepart = data;
        if (!this.isShowAttendance) {
          this.employeesOfSelectedDepartment = this.user;
        }
        setTimeout((e) => {
          $('#employeeDepartmentList').selectpicker('refresh');
          $('#employeeDepartmentList').selectpicker();
        }, 500);
      },
      (err) => {
        setTimeout((e) => {
          $('#employeeDepartmentList').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  appendArray(): any {
    const month = $('.month').val();
    if (this.employeesOfSelectedDepartment === undefined) {
      this.employeesOfSelectedDepartment = '';
    }
    if (this.month === '') {
      toastr.error('Select Month');
      return;
    }
    if (!this.department) {
      toastr.error('Select Department');
      return;
    }
    if (!this.employeesOfSelectedDepartment) {
      toastr.error('Select Employee');
      return;
    }
    this.ngxLoader.start();
    this.rosterServiceService
      .getEmployattendance(
        this.employeesOfSelectedDepartment,
        month,
        this.department,
      )
      .subscribe(
        (data) => {
          this.employeeRoasterData = data.list;
          this.employeeDetails = data.employee;
          if (!this.employeeRoasterData.length) {
            toastr.error('Data not Found');
            // $('#promissionsAlertModal').modal('show');
          }
          // if ( !this.employeeDetails.length ) {
          //   $('#promissionsAlertModal').modal('show');
          // }
          setTimeout(() => {
            $('.roasterSelection').selectpicker();
            this.ngxLoader.stop();
          }, 100);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  // onSubmit(): any {
  //   const month = $('.month').val();
  //   this.ngxLoader.start();
  //   this.rosterServiceService.submitEmployRoaster(this.employeeRoasterData, this.selectedLocation, month, this.department).subscribe(data => {
  //     this.ngxLoader.stop();
  //     toastr.success('Updated Successfully');
  //     this.router.navigate(['/roster/shifts-detail']);
  //   }, err => {
  //     this.ngxLoader.stop();
  //     toastr.error(err.error.message);
  //   });
  // }

  openApplyToAllModal(ind): any {
    $('#rosterApplyToAll').modal('show');
    this.applyToAllIndex = ind;
  }
  saveModalChanges(): any {
    this.ngxLoader.start();
    if (this.applyToAllConfirmation === false) {
      this.ngxLoader.stop();
      toastr.error('Please click to agree with that');
      return;
    }
    for (
      var i = 0;
      i < this.employeeRoasterData[this.applyToAllIndex].roster_detail.length;
      i++
    ) {
      this.employeeRoasterData[this.applyToAllIndex].roster_detail[i].shift =
        this.applyToAllShift;
    }
    setTimeout(() => {
      $('.roasterSelection').selectpicker('refresh');
      this.ngxLoader.stop();
    }, 500);
    $('#rosterApplyToAll').modal('hide');
    this.applyToAllConfirmation = false;
    this.applyToAllShift = [];
  }
  fileUploadFunc($event): any {
    this.file = $event;
    const file = ($event.target as HTMLInputElement).files[0];
    this.convertToBase64(file);
  }
  convertToBase64(file: File): any {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.csvBaseFile = d;
    });
  }
  readFile(file: File, subscriber: Subscriber<any>): any {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);

    filereader.onload = (e) => {
      subscriber.next(filereader.result);
      subscriber.complete();
    };

    filereader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
  removeCsvImport(): any {
    this.csvBaseFile = '';
    $('#csvImportModal').modal('hide');
  }
  submitCsvImport(): any {
    this.ngxLoader.start();
    this.rosterServiceService
      .submitAttendenceReport(this.csvBaseFile)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.removeCsvImport();
          this.appendArray();
          toastr.success('Uploaded Successfully');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  printReport(): any {
    window.print();
  }
  ngOnInit(): void {
    $('.month').datepicker({
      format: 'mm-yyyy',
      viewMode: 'months',
      minViewMode: 'months',
      orientation: 'bottom left',
    });
    $('.month').datepicker('setDate', this.today);
    this.month = this.today;
  }
}
