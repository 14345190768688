import { Component, OnInit } from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DepartmentsbyhospitalService} from '../../../services/departmentsbyhospital.service';
import {HospitaldoctorService} from '../../../services/hospitaldoctor.service';
import {ReservationManagmentServiceService} from '../../../services/reservation-managment-service.service';
import * as moment from 'moment/moment';
import {HospitalService} from '../../../services/hospital.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-booking-report',
  templateUrl: './booking-report.component.html',
  styleUrls: ['./booking-report.component.css']
})
export class BookingReportComponent implements OnInit {
  status = '';
  departmentId = '';
  doctorID = '';
  toType = '';
  Doctor_Id = '';
  department_Id = '';
  settingData: any;
  date = '';
  departments = [];
  bookedApptListing = [];
  doctors_by_dept: any;
  constructor(private ngxLoader: NgxUiLoaderService,
              private departmentService: DepartmentsbyhospitalService,
              private hospitalDoctorsService: HospitaldoctorService,
              private reservationService: ReservationManagmentServiceService,
              private hospitalService: HospitalService) { }
  getDate(): any {
    setTimeout(() => {
      $('.apptDate').datepicker({
        format: 'dd-mm-yyyy',
        orientation: 'bottom-left',
      });
      $('.apptDate').datepicker('setDate', new Date());
    }, 500);
  }

  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentService.getOpdDepartmentsListV2().subscribe(
      (data) => {
        this.departments = data.departments;
        setTimeout(() => {
          $('.departmentSelectPicker').selectpicker();
          $('.departmentSelectPicker').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  fetchDoctors(): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchOpdConsultationDoctorV2().subscribe(
      (data) => {
        this.doctors_by_dept = data.users;
        setTimeout(() => {
          $('.doctorSelectPicker').selectpicker();
          $('.doctorSelectPicker').selectpicker('refresh');
        }, 500);
        // if (this.appointedDoctorId) {
        //   this.Doctor_Id = this.appointedDoctorId;
        //   setTimeout(() => {
        //     $('.doctorSelectPicker').selectpicker();
        //     $('.doctorSelectPicker').selectpicker('refresh');
        //     this.getDepartments(this.Doctor_Id);
        //   }, 500);
        // }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getDoctors(departmentId): any {
    this.departmentId = departmentId;
    this.fetchDeptDoctors(this.departmentId);
  }
  fetchDeptDoctors(id): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchDepartmentDoctorListV2(id).subscribe(
      (data) => {
        this.Doctor_Id = '';
        this.doctors_by_dept = data.users;
        setTimeout(() => {
          $('.doctorSelectPicker').selectpicker();
          $('.doctorSelectPicker').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getDepartments(doctorId): any {
    this.doctorID = doctorId;
    this.toType = 'forward';
    // this.startDate = new Date();
    const dep_id = this.doctors_by_dept.filter(
      (t) => t?.id === Number(doctorId),
    )[0]?.doctor_department_id;
    this.department_Id = dep_id;
    setTimeout(() => {
      $('#departmentSelectPicker').selectpicker('refresh');
      $('#departmentSelectPicker').selectpicker('');
    }, 500);
    // this.getListingData();
  }
  getBookingListing(): any{
    this.date = $('.apptDate').val();
    const apptDate = moment($('.apptDate').val(), 'DD-MM-yyyy').format('yyyy-MM-DD');
    this.ngxLoader.start();
    this.bookedApptListing = [];
    this.reservationService.getBookingListing(apptDate, this.department_Id, this.Doctor_Id, this.status).subscribe(data => {
      this.bookedApptListing = data?.slots;
      this.ngxLoader.stop();
    }, err => {
      this.ngxLoader.stop();
      toastr.error(err.error.message);
    });
  }
  printReport(): any{
    window.print();
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  ngOnInit(): void {
    this.getDate();
    this.fetchDepartments();
    this.getSettingsData();
    this.fetchDoctors();
  }

}
