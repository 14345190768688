import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ConsentFormService {
  constructor(private http: HttpClient) {}

  consentTempletes(templete_type): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/consent_templetes?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&templete_type=' +
          templete_type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createConsentTemplete(consent_templete): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/consent_templetes.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          consent_templete: consent_templete,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  showTemplete(id: number): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/consent_templetes/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateConsentTemplete(consent_templete): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/consent_templetes/` +
          consent_templete.id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          consent_templete: consent_templete,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createAnesthesiaConsent(
    anesthesia_consent,
    patient,
    signature,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/anesthesia_consents.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_id: patient.id,
          signature_url: signature,
          anesthesia_consent: anesthesia_consent,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // createAnesthesiaConseasdasntForm(anesthesia_consent, patient , signature) : Observable<any> {
  //   let results = this.http.post(`${environment.pythonUrl}/ipd/anesthesia_consents/` + '?auth_token=' + localStorage.getItem('auth_token'),
  //    + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'), {
  //     auth_token: localStorage.getItem('auth_token'),
  //     medical_unit_id: localStorage.getItem('current_medical_unit_id'),
  //
  //   },{headers: new HttpHeaders({
  //       'AuthenticationToken': localStorage.getItem('auth_token'),
  //       'Accept': 'application/json'
  //     })}).pipe(map(data => data));
  //   return results;
  // }

  createAnesthesiaConsentForm(
    anesthesia_consent,
    patient,
    signature,
  ): Observable<any> {
    let results = this.http
      .post(
        environment.pythonUrl +
          '/ipd/anesthesia_consents/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_id: patient.id,
          signature_url: signature,
          anesthesia_consent: anesthesia_consent,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createOperationConsent(
    operation_consent,
    patient,
    signature,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/operation_consents.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          patient_id: patient.id,
          signature_url: signature,
          operation_consent: operation_consent,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchPatientAnesthesiaConsent(patient): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/anesthesia_consents/patient_consent?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&admission_id=' +
          patient.admissions[patient.admissions.length - 1].id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchPatientOperationConsent(patient): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/operation_consents/patient_consent?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&admission_id=' +
          patient.admissions[patient.admissions.length - 1].id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
