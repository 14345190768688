import { Component, Input, OnInit } from '@angular/core';
import { LabParameterService } from '../../../services/lab-parameter.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-lab-parameter',
  templateUrl: './lab-parameter.component.html',
  styleUrls: ['./lab-parameter.component.css'],
})
export class LabParameterComponent implements OnInit {
  @Input() patientId: any;
  labData: [];
  dateRange: [];
  dataRange: [];
  constructor(
    private labService: LabParameterService,
    private ngxLoader: NgxUiLoaderService,
  ) {}

  ngOnInit(): void {
    console.log('Patient ID', this.patientId);
    this.getFrequency();
  }

  getFrequency(): any {
    let id = this.patientId;
    console.log('in Function');
    this.labData = [];
    this.ngxLoader.start();
    this.labService.getLabParameter(id).subscribe(
      (data) => {
        this.dateRange = data.date_range;
        this.dataRange = data.data;
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }
}
