<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            Issued Items Invoice
          </h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-primary btn-sm mr-2" (click)="back()">
          Back
        </button>
        <button class="btn btn-light-primary btn-sm" (click)="printReport()">
          Print
        </button>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->
      <div class="card pdf-card" id="reports">
        <div class="card-body">
          <table style="width: 100% !important">
            <thead>
              <tr>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <div class="d-flex">
                      <div style="width: 85%">
                        <!-- header table -->
                        <table class="table">
                          <tbody>
                            <tr class="borders">
                              <td style="width: 15%; padding: 0">
                                <img
                                  [src]="settingData?.logo?.url"
                                  style="width: auto; height: 106px"
                                />
                              </td>
                              <td style="width: 85%">
                                <div class="text-center">
                                  <h3 class="invoice-title">
                                    {{ settingData?.title }}
                                  </h3>
                                </div>
                                <div class="font-size-h5 text-center">
                                  <h5>{{ settingData?.address }}</h5>
                                </div>
                                <div class="text-center">
                                  <h3 class="invoice-sub-title">
                                    <!--                              <span *ngIf="hospitalName !== 'uolHospital'">Prescription Slip </span>-->
                                    <!--                              <span *ngIf="visitData?.duplicate_slip === true">(Duplicate)</span>-->
                                  </h3>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- patient info table -->
                        <table
                          class="w-100 info_table mb-2 font-size-print-16 mt-print-15"
                        >
                          <tbody>
                            <tr>
                              <td style="width: 48%">
                                <h3
                                  *ngIf="patient"
                                  class="m-0 line-clamp font-print-bold"
                                >
                                  {{ patient?.title | titlecase }}
                                  {{
                                    patient?.first_name +
                                      " " +
                                      patient?.last_name +
                                      "," | titlecase
                                  }}
                                  {{ patient?.age + "," }}
                                  {{ patient?.gender.charAt(0) | titlecase }}
                                </h3>
                              </td>
                              <td style="width: 52%">
                                <h3 class="m-0 font-print-bold">
                                  MRN#{{ patient?.mrn }}
                                </h3>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- patient info detail table -->
                        <table class="w-100 info_table font-size-print-16">
                          <tbody>
                            <tr>
                              <td style="width: 17%">
                                <strong>Father/Husband:</strong>
                              </td>
                              <td style="width: 20%">
                                <span *ngIf="patient?.father_name">{{
                                  patient?.father_name | titlecase
                                }}</span>
                                <span *ngIf="!patient?.father_name">N/A</span>
                              </td>
                              <td style="width: 10%"></td>
                              <td style="width: 17%">
                                <strong>Reg. Date & Time:</strong>
                              </td>
                              <td style="width: 36%">
                                {{
                                  patient?.created_at
                                    | date: "dd-MM-yyyy h:mm a"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 17%">
                                <strong>Contact:</strong>
                              </td>
                              <td style="width: 20%">{{ patient?.phone1 }}</td>
                              <td style="width: 10%"></td>
                              <td style="width: 17%"><strong>Ward:</strong></td>
                              <td style="width: 36%">
                                <span *ngIf="patient?.admissions[0]?.ward">{{
                                  patient?.admissions[0]?.ward?.title
                                }}</span>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 17%"><strong>CNIC:</strong></td>
                              <td style="width: 20%">
                                {{ patient?.patient_nic || "N/A" }}
                              </td>
                              <td style="width: 10%"></td>
                              <td style="width: 17%">
                                <strong>Patient Type:</strong>
                              </td>
                              <td style="width: 36%">
                                {{ patient?.patient_type?.name }}
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 17%">
                                <strong>Address:</strong>
                              </td>
                              <td style="width: 20%" *ngIf="patient?.address1">
                                {{ patient?.address1 }}
                              </td>
                              <td style="width: 20%" *ngIf="!patient?.address1">
                                N/A
                              </td>
                              <td style="width: 10%"></td>
                              <td style="width: 17%">
                                <strong>Admission Id:</strong>
                              </td>
                              <td style="width: 36%">
                                <span *ngIf="patient?.admissions[0]?.ward">{{
                                  patient?.admissions[0]?.id
                                }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div style="width: 15%">
                        <div style="width: 100%; height: auto">
                          <!--                        <img  class="qrCode" style="height: 100%;width: 100%;" *ngIf="patient?.qrcode_url?.url" [src]="patient?.qrcode_url?.url" alt="QR Code">-->
                          <img
                            class="qrCode"
                            style="height: 100%; width: 100%; padding: 30px"
                            *ngIf="patient?.qrcode_image_url; else qrCodeElse"
                            [src]="pythonBaseUrl + patient?.qrcode_image_url"
                            alt="QR Code"
                          />
                          <ng-template #qrCodeElse>
                            <img
                              class="qrCode"
                              style="height: 100%; width: 100%"
                              *ngIf="patient?.qrcode_url?.url"
                              [src]="patient?.qrcode_url?.url"
                              alt="QR Code"
                            />
                          </ng-template>
                        </div>
                        <!--                      <img  class="brCode" *ngIf="patient?.barcode_url?.url" [src]="patient?.barcode_url?.url" alt="Bar Code" >-->
                        <img
                          class="brCode"
                          *ngIf="patient?.barcode_image_url; else barCodeElse"
                          [src]="pythonBaseUrl + patient?.barcode_image_url"
                          alt="Bar Code"
                        />
                        <ng-template #barCodeElse>
                          <img
                            class="brCode"
                            *ngIf="patient?.barcode_url?.url"
                            [src]="patient?.barcode_url?.url"
                            alt="Bar Code"
                          />
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <hr class="my-6 line" />
                  <!-- Listing -->
                  <div>
                    <table class="w-100 table table-striped m-0">
                      <thead class="service-table">
                        <tr style="background-color: #f9f9f9">
                          <th style="width: 10%">Date</th>
                          <th style="width: 10%">Ref. No</th>
                          <th style="width: 10%">Item Code</th>
                          <th style="width: 30%">Item Name</th>
                          <th style="width: 10%">UOM</th>
                          <th style="width: 10%">Issued Qty</th>
                          <th style="width: 10%">Sale Price</th>
                          <th style="width: 10%">Amount</th>
                        </tr>
                      </thead>
                      <tbody class="service-table">
                        <ng-container *ngFor="let data of listing">
                          <tr style="background-color: #ffffff !important">
                            <td>{{ data?.created_on | date: "dd-MM-yyyy" }}</td>
                            <td>{{ data?.reference_no }}</td>
                            <td>{{ data?.product?.code }}</td>
                            <td>{{ data?.product?.name }}</td>
                            <td>{{ data?.product?.uom }}</td>
                            <td>
                              {{ data?.issue_quantity - data?.return_qty }}
                            </td>
                            <td>{{ data?.sale_price }}</td>
                            <td>
                              {{
                                (data?.issue_quantity - data?.return_qty) *
                                  data?.sale_price | number: "1.0-2"
                              }}
                            </td>
                          </tr>
                        </ng-container>
                        <tr
                          style="
                            background-color: #ffffff !important;
                            border: 0;
                          "
                        >
                          <td colspan="6" style="border: 0"></td>
                          <td style="border: 0"><strong>Total:</strong></td>
                          <td style="border: 0">
                            <strong>{{ totalOTCash | number: "1.0-2" }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
