import { Component, Input, OnInit } from '@angular/core';
import { SystemControlsService } from '../../../../services/system-controls.service';
import { HistoryService } from '../../../../services/history.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../../environments/environment';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-admissions',
  templateUrl: './admissions.component.html',
  styleUrls: ['./admissions.component.css'],
})
export class AdmissionsComponent implements OnInit {
  @Input() patient: any;
  @Input() visitType: any;

  currentControls: any;
  visitTypes = [];

  constructor(
    private systemControlService: SystemControlsService,
    private historyService: HistoryService,
    private ngxLoader: NgxUiLoaderService,
  ) {}

  ngOnInit(): void {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
    });
    this.getPatientVisits();
  }

  getPatientVisits(): any {
    this.visitTypes = [];
    this.ngxLoader.start();
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.historyService
      .getPatientVisitslHistoryV2(this.patient.mrn, this.visitType)
      .subscribe(
        (data) => {
          this.visitTypes = data.visits;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  printAdmissionSlip(id): any {
    if (this.currentControls !== '') {
      if (
        this.currentControls.admission_controls.admission_form_web === false
      ) {
        window.open(
          'pdf-reports/patient-admission-v2?id=' +
            this.patient.id +
            '&visit_id=' +
            id +
            '&mrn=' +
            this.patient.mrn,
        );
      } else {
        window.open(
          environment.rorUrl +
            '/v3/patients/' +
            this.patient.id +
            '/admission_slip.pdf?auth_token=' +
            localStorage.getItem('auth_token') +
            '&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id'),
        );
      }
    } else {
      window.open(
        environment.rorUrl +
          '/v3/patients/ ' +
          this.patient.id +
          '/admission_slip.pdf?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
      );
    }
  }
}
