import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class LabconfigurationService {
  constructor(private http: HttpClient) {}

  create(lab_configuration): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_configurations`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          lab_configuration,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createConfigV2(lab_configuration): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/lab_configurations/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_configuration,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getLabConfigurators(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/lab_configurations?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLabConfiguratorsV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/lab_configurations?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getLabSpecificConfigurator(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/lab_configurations/lab_configuration_by_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&configurator_type=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLabSpecificConfiguratorV2(
    type,
    status,
    code,
    currentPage: any,
    perPage: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/lab_configuration_by_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&configurator_type=' +
          type +
          '&status=' +
          status +
          '&code=' +
          code +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLabData(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/lab_investigations/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  uploadCsv(detectorCsv, organismCsv): Observable<any> {
    const body = new FormData();
    body.append('detector_csv', detectorCsv);
    body.append('organism_csv', organismCsv);
    const results = this.http.post(
      `${environment.pythonUrl}/api/upload_pcr_data/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }

  updateLabConfigurators(lab_configurations): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_configurations/update_lab_configurations`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          lab_configurations,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateLabConfiguratorsV2(lab_configurations): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/lab_configurations/` +
          lab_configurations.id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_configurations,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateReportedDateAndTime(date, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/verify_lab_order/update_verification_time/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          verification_time: date,
          id
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
