import { AbstractControl, ValidatorFn } from '@angular/forms';

export function customValidations(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value && control.value.trim().length === 0) {
      // Validation failed
      return { required: true };
    } else {
      // Validation passed
      return null;
    }
  };
}
