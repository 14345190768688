<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-baseline flex-wrap mr-5 justify-content-between w-100"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <a
            class="btn btn-sm btn-primary font-weight-bolder font-size-sm"
            [routerLink]="['/roster/add-attendance-policy']"
          >
            <span class="svg-icon svg-icon-md svg-icon-white">
              <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Add-user.svg-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                    opacity="0.3"
                  ></path>
                  <path
                    d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
            Add New
          </a>
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card card-custom gutter-b">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Table-->
          <div class="table_top_bar mb-3">
            <div class="row">
              <!--              <div class="col-12 col-sm-3">-->
              <!--                <div class="d-block form-group">-->
              <!--                  <label>Status:</label>-->
              <!--                  <select class="form-control form-control-sm selectpicker" data-live-search="true" id="status">-->
              <!--                    <option value="" [disabled]="true">Select</option>-->
              <!--                    <option>Contractual</option>-->
              <!--                    <option>Daily Wager</option>-->
              <!--                    <option>Internee</option>-->
              <!--                    <option>Permanent</option>-->
              <!--                    <option>Probation</option>-->
              <!--                  </select>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="col-12 col-sm-3">
                <div class="d-block form-group">
                  <!--                  <label>Name:</label>-->
                  <input
                    class="form-control form-control-sm"
                    placeholder="search"
                    name="Employee Name"
                    [(ngModel)]="employeeName"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-3">
                <div class="form-group">
                  <div class="mt-3 text-left">
                    <a
                      class="btn btn-light-primary btn-sm px-6 font-weight-bold"
                      (click)="searchFilterValue()"
                      ngbTooltip="Search Filter"
                      placement="bottom"
                      >Search</a
                    >
                  </div>
                </div>
              </div>
              <!--              <div class="col-12 col-sm-3">-->
              <!--                <div class="d-flex align-items-center justify-content-between form-group">-->
              <!--                  <label class="mr-3 mb-0 d-none d-md-block">Show Terminated Only:</label>-->
              <!--                  <span class="switch switch-icon">-->
              <!--                    <label>-->
              <!--                      <input type="checkbox" name="select" [(ngModel)]="showterminated" />-->
              <!--                      <span></span>-->
              <!--                    </label>-->
              <!--                  </span>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="col-12">-->
              <!--                <div class="mt-3 text-right">-->
              <!--                  <a class="btn btn-light-primary btn-sm px-6 font-weight-bold" (click)="searchFilterValue()" ngbTooltip="Search Filter" placement="bottom">Search</a>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="table-responsive position-relative">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 10%">Code</th>
                  <th style="width: 50%">Name</th>
                  <!--                <th style="width: 20%">Type</th>-->
                  <th style="width: 10%">Status</th>
                  <th class="text-center" style="width: 10%">Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let indexvalue of list; let i = index">
                  <tr>
                    <td>{{ indexvalue?.code }}</td>
                    <td>{{ indexvalue?.name }}</td>
                    <!--                  <td>-->
                    <!--                    <span *ngIf="indexvalue?.policy_type === 0">Loan</span>-->
                    <!--                    <span *ngIf="indexvalue?.policy_type === 1">Leave</span>-->
                    <!--                  </td>-->
                    <td>
                      <span
                        *ngIf="indexvalue?.status === true"
                        class="label label-lg label-light-primary label-inline"
                        >Active</span
                      >
                      <span
                        *ngIf="indexvalue?.status === false"
                        class="label label-lg label-light-warning label-inline"
                        >Inactive</span
                      >
                    </td>
                    <td class="text-center">
                      <a
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                        (click)="editIndex(indexvalue.id)"
                      >
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                              ></path>
                              <path
                                d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                              ></path>
                            </g>
                          </svg>
                          <!--end::Svg Icon-->
                        </span>
                      </a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
          <!--pagination-->
          <div class="pagination_main">
            <ngb-pagination
              [(page)]="page"
              (pageChange)="loadPage(page)"
              [pageSize]="perPage"
              [collectionSize]="totalPages"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="false"
              [boundaryLinks]="true"
            ></ngb-pagination>
            <div class="dropdown">
              <a
                class="btn dropdown-toggle btn-light btn-sm"
                role="button"
                id="pagination_btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="page_count">
                  {{ perPageValue }}
                </span>
                <span class="count_count_icon"></span>
              </a>
              <div class="dropdown-menu" aria-labelledby="pagination_btn">
                <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                  >25</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                  >50</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                  >75</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                  >100</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit('')"
                  >All</a
                >
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</div>
