import { Component, OnInit } from '@angular/core';
import { RadiologyService } from '../../../services/radiology.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';

declare var toastr: any;
declare var $: any;
declare var tinymce: any;
@Component({
  selector: 'app-define-templates-v2',
  templateUrl: './define-templates-v2.component.html',
  styleUrls: ['./define-templates-v2.component.css'],
})
export class DefineTemplatesV2Component implements OnInit {
  searchString = '';
  searchType = 'template_name';
  report = '';
  impression = '';
  name = '';
  reportsData: any;
  id: any;
  classic = true;
  doctors = [];
  doctorIds = [];
  constructor(
    private radiologyService: RadiologyService,
    private ngxLoader: NgxUiLoaderService,
    private hospitalDoctorsService: HospitaldoctorService,
  ) {}

  ngOnInit(): void {
    this.searchTemplate();
    this.fetchDoctors();
    // $('.summernote').summernote({
    //   height: 230
    // });
    setTimeout(() => {
      tinymce.remove('#reports');
      tinymce.init({
        selector: '#reports',
        menubar: false,
        height: '500',
        toolbar: [
          'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code',
          'styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
        ],
        plugins:
          'advlist autolink link image lists charmap code table lineheight',
      });
      tinymce.remove('#impressions');
      tinymce.init({
        selector: '#impressions',
        menubar: false,
        height: '500',
        toolbar: [
          'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code',
          'styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
        ],
        plugins:
          'advlist autolink link image lists charmap code table lineheight',
      });
    }, 500);
  }

  fetchDoctors(): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchRadiologyTemplatesDoctorslist().subscribe(
      (data) => {
        this.doctors = data.users;
        setTimeout(() => {
          $('#doctorsTemplates').selectpicker('refresh');
        }, 500);
        setTimeout(() => {
          $('#doctorsTemplates').selectpicker();
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  searchTemplate(): any {
    this.ngxLoader.start();
    this.radiologyService
      .searchTemplateV2(this.searchString, false, this.searchType)
      .subscribe(
        (data) => {
          this.reportsData = data.templates;
          this.ngxLoader.stop();
        },
        (err) => {
          toastr.error(err.error.message);
          this.ngxLoader.stop();
        },
      );
  }
  createTemplate(): any {
    // this.report = $('#reports').summernote('code');
    this.report = tinymce.get('reports').getContent();
    this.impression = '';
    if (this.name === '') {
      toastr.error('Template Name cannot be empty');
      return;
    }
    let reportsMsgTxt = $.trim($(this.report).text());
    if (reportsMsgTxt.trim() === '') {
      toastr.error('Report cannot be empty');
      return;
    }
    if (this.classic === false) {
      this.impression = tinymce.get('impressions').getContent();
      var impressionsMsgTxt = $.trim($(this.impression).text());
      if (impressionsMsgTxt.trim() === '') {
        toastr.error('Impression cannot be empty');
        return;
      }
    }
    this.ngxLoader.start();
    this.radiologyService
      .createTemplatev2(
        this.name,
        this.report,
        this.impression,
        this.classic,
        this.doctorIds,
      )
      .subscribe(
        (data) => {
          this.searchTemplate();
          this.clear();
          toastr.success('Template create');
        },
        (err) => {
          toastr.error(err.error.message);
          this.ngxLoader.stop();
        },
      );
  }

  UpdateTemplate(): any {
    // this.report = $('#reports').summernote('code');
    this.report = tinymce.get('reports').getContent();
    this.impression = '';
    if (this.name === '') {
      toastr.error('Template Name cannot be empty');
      return;
    }
    let reportsMsgTxt = $.trim($(this.report).text());
    if (reportsMsgTxt.trim() === '') {
      toastr.error('Report cannot be empty');
      return;
    }
    if (this.classic === false) {
      this.impression = tinymce.get('impressions').getContent();
      var impressionsMsgTxt = $.trim($(this.impression).text());
      if (impressionsMsgTxt.trim() === '') {
        toastr.error('Impression cannot be empty');
        return;
      }
    }
    this.ngxLoader.start();
    this.radiologyService
      .updateTemplatev2(
        this.name,
        this.report,
        this.impression,
        this.classic,
        this.id,
        this.doctorIds,
      )
      .subscribe(
        (data) => {
          this.searchTemplate();
          toastr.success('Template update');
          this.clear();
        },
        (err) => {
          toastr.error(err.error.message);
          this.ngxLoader.stop();
        },
      );
  }

  getDetails(template): any {
    const drId = '#doctorsTemplates';
    setTimeout(() => {
      $(drId).selectpicker('refresh');
    }, 500);
    this.id = template.id;
    this.name = template.name;
    this.doctorIds = template.user_ids;
    this.report = template.report;
    this.impression = template.impression;
    this.classic = template.classic;
    // $('#reports').summernote('code', this.report);
    tinymce.get('reports').setContent(this.report);
    setTimeout(() => {
      $(drId).selectpicker();
    }, 500);
    if (template.classic === false) {
      setTimeout(() => {
        // $('#impressions').summernote({
        //   height: 230
        // });
        tinymce.remove('#impressions');
        tinymce.init({
          selector: '#impressions',
          menubar: false,
          height: '500',
          toolbar: [
            'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap  code',
            'styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
          ],
          plugins:
            'advlist autolink link image lists charmap code table lineheight',
        });
      }, 500);
      setTimeout(() => {
        if (this.classic === false) {
          // $('#impressions').summernote('code', this.impression);
          tinymce.get('impressions').setContent(this.impression);
        }
      }, 500);
    }
  }
  clear(): any {
    this.id = null;
    this.name = '';
    this.doctorIds = [];
    const drId = '#doctorsTemplates';
    setTimeout(() => {
      $(drId).selectpicker('refresh');
    }, 500);
    setTimeout(() => {
      $(drId).selectpicker();
    }, 500);
    // $('#reports').summernote('code', '');
    // $('#impressions').summernote('code', '');
    tinymce.get('reports').setContent('');
    tinymce.get('impressions').setContent('');
  }
  updateClassicType(): any {
    setTimeout(() => {
      if (this.classic === false) {
        // $('#impressions').summernote({
        //   height: 230
        // });
        tinymce.remove('#impressions');
        tinymce.init({
          selector: '#impressions',
          menubar: false,
          height: '500',
          toolbar: [
            'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code',
            'styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
          ],
          plugins:
            'advlist autolink link image lists charmap code table lineheight',
        });
        tinymce.get('impressions').setContent('');
      }
    }, 500);
  }

  printTemplate(): any {
    window.open('radiology/print-template?id=' + this.id);
  }
}
