import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
  ChangeDetectorRef, HostListener
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TaskManagementService } from '../../../services/task-management.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscriber } from 'rxjs';
import { RosterServiceService } from '../../../services/roster-service.service';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { SystemConfigurationService } from '../../../services/system-configuration.service';
import * as RecordRTC from 'recordrtc';
import {logWarnings} from "protractor/built/driverProviders";
declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-view-task',
  templateUrl: './view-task.component.html',
  styleUrls: ['./view-task.component.css'],
})
export class ViewTaskComponent implements OnInit {
  @Output() newStatusEvent = new EventEmitter<string>();
  @Input() taskId: any;
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault(); // Prevent the browser's default save behavior
      this.onSubmit();
    }
  }

  onStatusChange(type: any) {
    if (type === 'true') {
      this.newStatusEvent.emit('true');
    } else {
      this.newStatusEvent.emit('false');
    }
  }

  addComplaint: UntypedFormGroup;
  timeLogData: UntypedFormGroup;
  taskTab: UntypedFormGroup;

  isAdd = false;
  taskStatus: any;
  priority: any;
  locationData: any;
  project: any;
  depart: any;
  tagListData: any;
  complaintSingledata: any;
  employee: any;
  complaintId: any;
  imagePath: any;
  disabledSubmitBtn = false;
  commentBtn = false;
  taskComments = '';
  screens = [];
  featureData = [];
  featureCode: any;
  featureName: any;
  featureStatus: false;
  featureId = '';
  activeScreenId: any;
  timeCheck = false;
  employee_id: any;
  documentsList = [];
  timeLockAndComments = [];
  docs = [];
  commentDocs = [];
  isAddType = false;
  recording = false;
  recordingText = 'Recording...'; // Initial text to display
  recordRTC: any;
  uploadedDocument: any;
  mediaStream: MediaStream | any;
  recordedBlob: Blob | null = null;
  clearModal = true;

  constructor(
    private task: TaskManagementService,
    private ngxLoader: NgxUiLoaderService,
    private rosterServiceService: RosterServiceService,
    private fb: UntypedFormBuilder,
    private systemConfigService: SystemConfigurationService,
    private taskServices: TaskManagementService,
    private cdr: ChangeDetectorRef
  ) {
    this.addComplaint = this.fb.group({
      name: ['', Validators.required],
      phone: '',
      description: [''],
      department: '',
      location: '',
      priority: ['', Validators.required],
      project: ['', Validators.required],
      related_to: ['hims related', Validators.required],
      screen_id: ['-1', Validators.required],
      feature_id: ['-1', Validators.required],
      frequency_of_occurrence: ['new', Validators.required],
      screen_detail: [''],
      feature_detail: [''],
      type: ['new feature', Validators.required],
      file: [null],
      tag: '',
      assign: this.fb.array([]),
      status: [1, Validators.required],
      contact_person: '',
      external_link: '',
      comments: '',
    });
    setTimeout(() => {
      $('.select2').select2({
        placeholder: 'Select or type tags',
        tags: false,
      });
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    let currentId = changes?.['taskId'].currentValue;
    if (currentId) {
      $('#commentTab').click();
      this.getTaskDetail(this.taskId);
      this.deleteAudio();
    } else {
      this.resetForm();
    }
  }

  ngOnInit(): void {
    this.timeLogData = this.fb.group({
      daily_log: [0, Validators.required],
      comment: ['', Validators.required],
      daily_log_unit: ['hour'],
      is_completed: false,
    });
    this.taskTab = this.fb.group({
      name: ['', Validators.required],
      is_removed: false,
    });
    this.initializeSummernote();
    this.getStatus();
    this.getPriority();
    this.getLocation();
    this.getProject();
    this.getDepartments();
    this.getAllTags();
    this.getEmployess();
    this.fetchScreens();
    this.resetForm();
    if (this.taskId){
      this.getTimeLogAndComments('comments');
    }
    this.addComplaint.patchValue({
      feature_id: '-1',
    });
    setTimeout(() => {
      $('#feature_id').selectpicker('refresh');
    }, 500);
  }

  initializeSummernote() {
    setTimeout(() => {
      $('.summerNoteEditor').summernote({
        height: 150,
        lineHeights: [
          '0.2',
          '0.3',
          '0.4',
          '0.5',
          '0.6',
          '0.8',
          '1.0',
          '1.2',
          '1.4',
          '1.5',
          '2.0',
          '3.0',
        ],
        toolbar: [
          ['style', ['style']],
          ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
          ['fontname', ['fontname']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['height', ['height']],
          ['table', ['table']],
          ['insert', ['link', 'picture', 'video']],
          ['view', ['fullscreen', 'codeview', 'help']],
        ],
      });
      $('.summerNoteCommentEditor').summernote({
        height: 150,
        lineHeights: [
          '0.2',
          '0.3',
          '0.4',
          '0.5',
          '0.6',
          '0.8',
          '1.0',
          '1.2',
          '1.4',
          '1.5',
          '2.0',
          '3.0',
        ],
        toolbar: [
          ['style', ['style']],
          ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
          ['fontname', ['fontname']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['height', ['height']],
          ['table', ['table']],
          ['insert', ['link', 'picture', 'video']],
          ['view', ['fullscreen', 'codeview', 'help']],
        ],
      });
    }, 500);
  }

  resetForm() {
    this.isAdd = false;
    if (this.assignData) {
      while (this.assignData.length) {
        this.assignData.removeAt(0);
      }
    }
    this.addComplaint.patchValue({
      name: '',
      phone: '',
      description: '',
      department: '',
      location: '',
      priority: '',
      project: '',
      file: null,
      tag: '',
      assign: [],
      status: '',
      contact_person: '',
      comments: '',
      external_link: '',
      type: 'new feature',
      screen_detail: '',
      feature_detail: '',
      screen_id: '',
      feature_id: '',
    });
    setTimeout(() => {
      $('#selectStatus').selectpicker('refresh');
      $('#selectedLocation').selectpicker('refresh');
      $('.depar').selectpicker('refresh');
      $('#prioritySelect2').selectpicker('refresh');
      $('#prioritySelect3').selectpicker('refresh');
      $('#addProject').selectpicker('refresh');
      $('#description').summernote('code', '');
      $('#comments').summernote('code', '');
    }, 800);
    setTimeout(() => {
      $('.select2').select2({
        placeholder: 'Select or type tags',
        tags: false,
      });
    }, 1000);
  }

  getStatus(): any {
    this.task.getStatusList(true).subscribe(
      (data) => {
        this.taskStatus = data;
        setTimeout(() => {
          if (this.taskId){
            this.addComplaint.patchValue({
              status: this.complaintSingledata?.task_status.id,
            });
          } else {
            const defaultStatus = this.taskStatus.filter(
              (t) => t.is_default === true,
            );
            this.addComplaint.get('status').patchValue(defaultStatus[0].id);
          }
          setTimeout((e) => {
            $('#selectStatus').selectpicker('refresh');
            $('#selectStatus').selectpicker();
          }, 500);
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getPriority(): any {
    this.priority = [];
    this.task.getPriority().subscribe(
      (data) => {
        this.priority = data;
        setTimeout(() => {
          if (this.taskId){
            this.addComplaint.patchValue({
              priority: this.complaintSingledata?.priority.id,
            });
          } else {
            this.addComplaint.get('priority').patchValue(this.priority[0].id);
          }
          setTimeout((e) => {
            $('#prioritySelect3').selectpicker('refresh');
            $('#prioritySelect3').selectpicker();
          }, 500);
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getLocation(): any {
    this.task.getDepartmentLocations().subscribe(
      (data) => {
        this.locationData = data.location;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getProject(): any {
    this.task.getRoleBaseProject().subscribe(
      (data) => {
        this.project = data;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        this.locationData = data.location;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getAllTags(): any {
    this.tagListData = [];
    this.task.getTagList().subscribe(
      (data) => {
        this.tagListData = data;
        // setTimeout((e) => {
        //   $('.select2').selectpicker('refresh');
        //   $('.select2').selectpicker();
        // }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getEmployess(): any {
    this.task.getEmployees('').subscribe(
      (data) => {
        this.employee = data;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  //  upload file
  fileUploadFunc($event): any {
    const file = ($event.target as HTMLInputElement).files[0];
    this.uploadedDocument = file;
  }

  readFile(file: File, subscriber: Subscriber<any>): any {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);

    filereader.onload = () => {
      subscriber.next(filereader.result);
      subscriber.complete();
    };
    filereader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }

  _onlyNumeric(e): any {
    var charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // taskCommentsSubmit() {
  //   this.commentBtn = true;
  //   if ($('#comments').summernote('isEmpty')) {
  //     this.taskComments = '';
  //   } else {
  //     this.taskComments = $('#comments').summernote('code');
  //   }
  //   if ($('#comments').summernote('isEmpty')) {
  //     toastr.error('Add Comment');
  //     this.commentBtn = false;
  //     return;
  //   }
  //   this.task.taskCommentsSubmit(this.taskComments, this.taskId).subscribe(
  //     (data) => {
  //       this.taskComments = '';
  //       $('#comments').summernote('code', '');
  //       this.getTaskDetail(this.complaintId);
  //       this.getTimeLogAndComments('comments');
  //       this.assignData.removeAt(0);
  //       this.updateassignForm('');
  //       this.ngxLoader.stop();
  //       this.commentBtn = false;
  //     },
  //     (err) => {
  //       this.commentBtn = false;
  //       this.ngxLoader.stop();
  //       toastr.error(err.error.error);
  //     },
  //   );
  // }

  onSubmit(): any {
    this.disabledSubmitBtn = true;
    this.addComplaint
      .get('tag')
      .setValue($('#medicationsAllergies').val().toString());
    // this.addComplaint.get('department').setValue($('#department').val());
    if (!this.taskId) {
      for (let i = 0; i < this.assignData.length; i++) {
        this.assignData
          .at(i)
          .get('start_date')
          .patchValue(
            $('#fromDate' + i).val()
              ? moment($('#fromDate' + i).val(), 'YYYY-MM-DD').format(
                'MM-DD-YYYY',
              )
              : '',
          );
        this.assignData
          .at(i)
          .get('due_date')
          .patchValue(
            $('#toDate' + i).val()
              ? moment($('#toDate' + i).val(), 'YYYY-MM-DD').format(
                'MM-DD-YYYY',
              )
              : '',
          );
      }
    }
    if ($('#description').summernote('isEmpty')) {
      this.addComplaint.get('description')?.patchValue('');
    } else {
      this.addComplaint
        .get('description')
        ?.patchValue($('#description').summernote('code'));
    }
    this.isAdd = true;
    if (this.addComplaint.invalid) {
      this.disabledSubmitBtn = false;
      return;
    }
    const descriptionContent = $('.summerNoteEditor').summernote('code').trim();
    const plainTextContent = descriptionContent.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').trim();
    if (
      !plainTextContent || plainTextContent === ''
    ) {
      toastr.error('Add Description');
      this.disabledSubmitBtn = false;
      return;
    }
    if (this.addComplaint.get('screen_id').value === '-1') {
      this.addComplaint.get('screen_id').setValue('');
    }
    if (this.addComplaint.get('feature_id').value === '-1') {
      this.addComplaint.get('feature_id').setValue('');
    }
    if (this.taskId) {
      this.ngxLoader.start();
      this.task.updateTask(this.addComplaint.value, this.taskId).subscribe(
        (data) => {
          this.getTaskDetail(this.complaintId);
          if (this.uploadedDocument){
            this.sendImages(this.uploadedDocument, true);
          }
          this.onStatusChange('false');
          this.assignData.removeAt(0);
          this.updateassignForm('');
          this.ngxLoader.stop();
          this.disabledSubmitBtn = false;
        },
        (err) => {
          this.disabledSubmitBtn = false;
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
    } else {
      this.ngxLoader.start();
      this.task.addComplaint(this.addComplaint.value).subscribe(
        (data) => {
          this.isAdd = false;
          this.ngxLoader.stop();
          // $('#department').val('');
          this.addComplaint.get('department')?.setValue('');
          this.onStatusChange('true');
          if (this.uploadedDocument){
            this.taskId = data?.task_id;
            this.sendImages(this.uploadedDocument, true);
          }
          this.resetForm();
          this.disabledSubmitBtn = false;
        },
        (err) => {
          this.disabledSubmitBtn = false;
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
    }
    $('#description').summernote('code', '');
    $('#comments').summernote('code', '');
  }

  addAssignData(): any {
    this.assignData.push(this.assignForm());
    for (let i = 0; i < this.assignData.length; i++) {
      setTimeout(() => {
        $('#employee' + i).selectpicker('refresh');
        $('#employee' + i).selectpicker();
      }, 200);
    }
  }

  get assignData(): any {
    return this.addComplaint.get('assign') as UntypedFormArray;
  }

  assignForm(): UntypedFormGroup {
    return this.fb.group({
      id: '',
      is_remove: false,
      assign_to: ['', Validators.required],
      time: [0, Validators.required],
      start_date: ['', Validators.required],
      due_date: ['', Validators.required],
      unit: ['hour'],
    });
  }

  // getfocusdata(value, type, index, task_id): any {
  //   if (this.addComplaint.get(['assign', index]).get('id').value === '') {
  //     return;
  //   }
  //   let values: any;
  //   values = this.addComplaint.get(['assign', index]);
  //   this.task.updateAsign(values.value, type, task_id).subscribe(
  //     (data) => {
  //       this.ngxLoader.stop();
  //     },
  //     (err) => {
  //       this.ngxLoader.stop();
  //       toastr.error(err.error.message);
  //     },
  //   );
  // }
  getTaskDetail(id): any {
    this.updateassignForm('');
    this.initializeSummernote();
    this.complaintId = id;
    if (this.assignData) {
      while (this.assignData.length) {
        this.assignData.removeAt(0);
      }
    }
    this.task.getComplaintDataById(id).subscribe(
      (data) => {
        this.complaintSingledata = data;
        const tagsData = [];
        for (let j = 0; j < this.complaintSingledata.task_assign.length; j++) {
          this.assignData.push(
            this.updateassignForm(this.complaintSingledata.task_assign[j]),
          );
          setTimeout(() => {
            $('#employee' + j).selectpicker('refresh');
            $('#employee' + j).selectpicker();
          }, 500);
        }
        // for (let i = 0; i < this.complaintSingledata.tags.length; i++) {
        //   const tag = this.complaintSingledata.tags[i];
        //   const matchingTag = this.tagListData.find(
        //     (tagItem) => tagItem.id === tag.id
        //   );
        //   if (matchingTag) {
        //     tagsData.push(tag.id);
        //   }
        // }
        for (let i = 0; i < this.complaintSingledata.tags.length; i++) {
            tagsData.push(this.complaintSingledata.tags[i].id);
        }
        $('#medicationsAllergies').select2({
          placeholder: 'Select or type Tags',
          tags: true,
        });
        setTimeout(() => {
          $('#medicationsAllergies').val(tagsData);
          $('#medicationsAllergies').trigger('change');
        }, 2000);
        this.imagePath = environment.pythonUrl;
        this.addComplaint.patchValue({
          name: this.complaintSingledata?.name,
          project: this.complaintSingledata?.project,
          // tag: this.complaintSingledata?.tags.id,
          department: this.complaintSingledata?.department,
          location: this.complaintSingledata?.location,
          // description: this.complaintSingledata?.description,
          external_link: this.complaintSingledata?.external_link,
          contact_person: this.complaintSingledata?.contact_person,
          status: this.complaintSingledata?.task_status.id,
          priority: this.complaintSingledata?.priority.id,
          phone: this.complaintSingledata?.phone,
          related_to: this.complaintSingledata?.related_to,
          screen_id: this.complaintSingledata?.screen_id,
          screen_detail: this.complaintSingledata?.screen_detail,
          feature_id: this.complaintSingledata?.feature_id,
          feature_detail: this.complaintSingledata?.feature_detail,
          type: this.complaintSingledata?.type,
          frequency_of_occurrence: this.complaintSingledata?.frequency_of_occurrence,
        });
        if (this.complaintSingledata?.feature_id === null || this.complaintSingledata?.feature_id === '') {
          this.addComplaint.get('feature_id').setValue('-1');
        }
        if (this.complaintSingledata?.screen_id === null || this.complaintSingledata?.screen_id === '') {
          this.addComplaint.get('screen_id').setValue('-1');
        }
        this.getScreenfeatures(this.addComplaint.get('screen_id').value);
        setTimeout(() => {
          $('#description').summernote(
            'code',
            this.complaintSingledata.description,
          );
        }, 800);
        setTimeout(() => {
          $('#selectStatus').selectpicker('refresh');
          $('#selectedLocation').selectpicker('refresh');
          $('.depar').selectpicker('refresh');
          $('#prioritySelect2').selectpicker('refresh');
          $('#prioritySelect3').selectpicker('refresh');
          $('#addProject').selectpicker('refresh');
          $('#screen_id').selectpicker('refresh');
          $('#feature_id').selectpicker('refresh');
        }, 800);
        setTimeout(() => {
          $('.select2').select2({
            placeholder: 'Select or type tags',
            tags: false,
          });
        }, 1000);
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  deleteAssignData(i: number): any {
    let id = this.addComplaint.get(['assign', i]).get('id').value;
    if (id === '') {
      this.assignData.removeAt(i);
    } else {
      this.addComplaint.get(['assign', i]).get('is_remove').setValue(true);
    }
  }

  updateassignForm(data): UntypedFormGroup {
    return this.fb.group({
      id: [data.id],
      is_remove: [false],
      assign_to: [data.employee, Validators.required],
      time: [data.allocated_time, Validators.required],
      start_date: [data.start_date, Validators.required],
      due_date: [data.end_date, Validators.required],
      unit: [data.unit],
    });
  }

  fetchScreens(): any {
    this.ngxLoader.start();
    this.screens = [];
    this.systemConfigService.getScreensV2('true').subscribe(
      (data) => {
        this.screens = data.screens;
        this.addComplaint.patchValue({
          screen_id: '-1',
        });
        setTimeout(() => {
          $('#screen_id').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
  }

  getScreenfeatures(screenId): any {
    this.activeScreenId = screenId;
    if (screenId === '-1') {
      this.featureData = [];
      setTimeout(() => {
        $('#feature_id').selectpicker('refresh');
      }, 500);
    }
    if (screenId !== '-1') {
      this.addComplaint.get('screen_detail').setValue('');
      this.ngxLoader.start();
      this.systemConfigService.getScreenFeatures(screenId, 'true').subscribe(
        (data) => {
          this.featureData = data.screen_features;
          setTimeout(() => {
            $('#feature_id').selectpicker('refresh');
          }, 500);
          this.ngxLoader.stop();
        },
        (err) => {
          toastr.error(err.error.message);
          this.ngxLoader.stop();
        },
      );
    }
  }
  onFeaturesChange(value): any{
    if (value !== '-1'){
      this.addComplaint.get('feature_detail').setValue('');
    }
  }

  addFeature(): any {
    $('#featureModal').modal('show');
  }

  closeFeatureModal(): any {
    this.featureCode = '';
    this.featureName = '';
    this.featureStatus = false;
    this.featureId = '';
    $('#featureModal').modal('hide');
  }

  _keyPressOnlyCharNoSpace(event: any): any {
    const pattern = /^[a-z]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  addNewFeature(): any {
    if (this.featureCode === '') {
      toastr.error('Please Add Feature Code');
      return;
    } else if (this.featureName === '') {
      toastr.error('Please Add Feature Name');
      return;
    }
    this.ngxLoader.start();
    this.systemConfigService
      .addNewFeature(
        this.featureCode,
        this.featureName,
        this.featureStatus,
        this.activeScreenId,
      )
      .subscribe(
        (data) => {
          this.featureCode = '';
          this.featureName = '';
          this.featureStatus = false;
          this.featureId = '';
          this.getScreenfeatures(this.activeScreenId);
          this.ngxLoader.stop();
        },
        (err) => {
          toastr.error(err.error.message);
          this.ngxLoader.stop();
        },
      );
  }

  updateFeatureControl(): any {
    if (this.featureCode === '') {
      toastr.error('Please Add Feature Code');
      return;
    } else if (this.featureName === '') {
      toastr.error('Please Add Feature Name');
      return;
    }
    this.ngxLoader.start();
    this.systemConfigService
      .updateScreenFeature(
        this.featureCode,
        this.featureName,
        this.featureStatus,
        this.activeScreenId,
        this.featureId,
      )
      .subscribe(
        (data) => {
          this.featureCode = '';
          this.featureName = '';
          this.featureStatus = false;
          this.featureId = '';
          this.getScreenfeatures(this.activeScreenId);
          this.ngxLoader.stop();
        },
        (err) => {
          toastr.error(err.error.message);
          this.ngxLoader.stop();
        },
      );
  }

  updateFeature(data): any {
    this.featureId = data.id;
    this.featureCode = data.code;
    this.featureName = data.name;
    this.featureStatus = data.is_active;
  }

  onChangeRelatedTo(value): any {
    if (value === 'hims related') {
      this.addComplaint.get('screen_id').enable();
      this.addComplaint.get('feature_id').enable();
      this.addComplaint.get('screen_id').setValidators([Validators.required]);
      this.addComplaint.get('screen_id').updateValueAndValidity();
      this.addComplaint.get('feature_id').setValidators([Validators.required]);
      this.addComplaint.get('feature_id').updateValueAndValidity();
      setTimeout(() => {
        $('#screen_id').selectpicker('refresh');
        $('#feature_id').selectpicker('refresh');
      }, 100);
    } else {
      this.addComplaint.get('screen_id').disable();
      this.addComplaint.get('feature_id').disable();
      this.addComplaint.get('screen_id').clearValidators();
      this.addComplaint.get('screen_id').updateValueAndValidity();
      this.addComplaint.get('feature_id').clearValidators();
      this.addComplaint.get('feature_id').updateValueAndValidity();
      this.addComplaint.get('feature_id').setValue('');
      this.addComplaint.get('screen_id').setValue('');
      setTimeout(() => {
        $('#screen_id').selectpicker('refresh');
        $('#feature_id').selectpicker('refresh');
      }, 100);
    }
  }

  timeLog(is_completed): any {
    this.timeLogData.get('is_completed').patchValue(is_completed);

    $('#timeLogModal').modal('show');
  }

  onSubmitData2(type): any {
    if (type === 'daily_log_comments'){
      this.timeCheck = true;
      if (this.timeLogData.invalid) {
        return;
      }
    }
    if (type === 'comments') {
      if ($('#comments').summernote('isEmpty')) {
        this.taskComments = '';
      } else {
        this.taskComments = $('#comments').summernote('code');
      }
      const commentContent = $('.summerNoteCommentEditor').summernote('code').trim();
      const plainTextContent = commentContent.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').trim();
      if (
        !plainTextContent || plainTextContent === ''
      ) {
        toastr.error('Add Comment');
        this.commentBtn = false;
        return;
      }
      this.timeLogData.get('comment').setValue(this.taskComments);
    }
    this.ngxLoader.start();
    this.task
      .addTimeLogAndComments(this.timeLogData.value, this.taskId, type, this.commentDocs, this.recordedBlob)
      .subscribe(
        (data) => {
          if (type === 'comments'){
            this.taskComments = '';
            ($('#comments') as any).summernote('reset');
            this.getTimeLogAndComments('comments');
            this.getTaskDetail(this.taskId);
            this.taskComments = '';
            this.timeLogData.get('comment').setValue('');
            this.deleteAudio();
          }
          if (type === 'daily_log_comments'){
            this.timeCheck = false;
            this.timeLogData.patchValue({
              daily_log: 0,
              daily_log_unit: 'hour',
              comment: '',
            });
            this.getTimeLogAndComments('time_lock');
          }
          this.ngxLoader.stop();
          $('#timeLogModal').modal('hide');
        },
        (err) => {
          this.ngxLoader.stop();
          this.timeLogData.get('comment').setValue('');
          toastr.error(err.error.error);
        },
      );
  }
  closeTimeLogModal(): any{
    $('#timeLogModal').modal('hide');
  }

  sendImages(event, check): any {
    this.docs = event;
    if (this.docs) {
      this.ngxLoader.start();
      this.task
        .addDocuments(this.docs, this.taskId, check)
        .subscribe(
          (data) => {
            this.getDocuments();
            this.ngxLoader.stop();
          },
          (err) => {
            this.ngxLoader.stop();
            toastr.error(err.error.error);
          },
        );
    }
  }

  getDocuments(): any{
    this.documentsList = [];
    this.ngxLoader.start();
    this.task.getDocuments(this.taskId).subscribe(
      (data) => {
        this.documentsList = data.documents;
        this.ngxLoader.stop();
      },

      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  openDoc(url, id): any{
    window.open(url, '_blank');
  }
  deleteDoc(docId: string): void {
    this.ngxLoader.start();
    this.task.deleteDocument(docId).subscribe(
      (data) => {
        this.getDocuments();
        this.ngxLoader.stop();
      },

      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getTimeLogAndComments(type): any{
    this.timeLockAndComments = [];
    this.ngxLoader.start();
    this.task.getTimeLogAndComments(type, this.taskId).subscribe(
      (data) => {
        this.timeLockAndComments = data.result;
        this.ngxLoader.stop();
      },

      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  addTags(): any {
    $('#addTypeModal').modal('show');
  }

  submitType(): any {
    this.isAddType = true;
    if (this.taskTab.invalid) {
      return;
    }
      this.taskServices.addTag(this.taskTab.value).subscribe(
        (data) => {
          this.isAddType = false;
          this.closeAddTagModal();
          this.ngxLoader.stop();
          this.getAllTags();
        },
        (err) => {
          console.log('err', err);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  closeAddTagModal(): any{
    $('#addTypeModal').modal('hide');
    this.taskTab.reset({
      name: '',
      is_removed: false,
    });
  }

  toggleRecording(): any {
    if (!this.recording) {
      this.startRecording();
    } else {
      this.stopRecording();
    }
    this.recording = !this.recording;
  }

  startRecording(): any{
    this.recordingText = 'Recording...';
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream: MediaStream) => {
        this.mediaStream = stream;
        this.recordRTC = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm'
        });
        this.recordRTC.startRecording();
      })
      .catch(err => console.error('Error accessing media devices: ', err));
  }

  stopRecording(): any {
    if (this.recordRTC) {
      this.recordingText = '';
      this.recordRTC.stopRecording((audioUrl: string) => {
        if (this.mediaStream) {
          this.mediaStream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        }
        this.recordedBlob = this.recordRTC.getBlob();
        this.createAudioElement(this.recordedBlob);
        this.cdr.detectChanges();
      }, (err: any) => {
        console.error('Error while stopping recording: ', err);
      });
    }
  }

  createAudioElement(blob: Blob): void {
    const audio = document.createElement('audio');
    audio.controls = true;
    audio.style.width = '155px';
    audio.style.height = '35px';
    const audioURL = window.URL.createObjectURL(blob);
    audio.src = audioURL;
    const style = document.createElement('style');
    style.textContent = `
    ::-webkit-media-controls-time-remaining-display,
    ::-webkit-media-controls-current-time-display {
      display: none;
    }
     audio::-webkit-media-controls-play-button {
      margin-right: -20px !important;
    }
    audio::-webkit-media-controls-volume-slider {
      margin-left: -10px !important;
    }
    audio::-webkit-media-controls-mute-button {
      margin-right: -10px !important;
    }
  `;
    audio.appendChild(style);
    const audioContainer = document.getElementById('audioContainer'); // Make sure to have an element with this ID in your template
    if (audioContainer) {
      audioContainer.innerHTML = '';
      audioContainer.appendChild(audio);
    }
  }

  deleteAudio(): void {
    this.recordedBlob = null;
    const audioContainer = document.getElementById('audioContainer');
    if (audioContainer) {
      audioContainer.innerHTML = '';
    }
    this.cdr.detectChanges();
  }

  sendCommentDocs(event): any {
    this.commentDocs = event;
  }
}
