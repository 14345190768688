import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { PdfServicesService } from '../../../../services/pdf-services.service';
import { environment } from '../../../../../environments/environment';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-pdf-report',
  templateUrl: './pdf-report.component.html',
  styleUrls: ['./pdf-report.component.css'],
})
export class PdfReportComponent implements OnInit {
  today = new Date();
  patient: any;
  user: any;
  settingData: any;
  serviceId: number;
  userName: any;
  patientServiceInvoicesData: any;
  paramsdata: any;
  hospitalTitle: any = environment.hospital;
  currentControls: any;

  showShort = 0;
  constructor(
    private location: Location,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private pdfService: PdfServicesService,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.serviceId = +params['id'];
    });
  }
  ngOnInit(): void {
    this.userName = JSON.parse(localStorage.getItem('currentUser'));
    if (this.serviceId) {
      this.fetchPatientServices();
    }
    this.getSettingsData();
  }
  fetchPatientServices(): any {
    this.ngxLoader.start();
    this.pdfService.getPatientServices(this.serviceId).subscribe(
      (data) => {
        this.paramsdata = data;
        this.patientServiceInvoicesData = data.patient_service_invoices;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  printReport(): any {
    const currentDate = new Date();
    this.today = currentDate;

    window.print();
  }
  goBack(): any {
    this.location.back();
  }
  getSettingsData(): any {
    this.pdfService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
}
