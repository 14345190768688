import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'app-prescription-physical-examination',
  templateUrl: './prescription-physical-examination.component.html',
  styleUrls: ['./prescription-physical-examination.component.css']
})
export class PrescriptionPhysicalExaminationComponent implements OnInit {
  @Input() label: any;

  physicalSystemicExaminationHistoryForm: UntypedFormGroup;
  getTemplateData: any;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.physicalSystemicExaminationHistoryForm = this.formBuilder.group({
      id: '',
      patient_gpe_history_attributes: this.formBuilder.group({
        id: '',
        pallor: false,
        cyanosis: false,
        pedal: false,
        clubbing: false,
        rash: false,
        peropheral_vessel_palpable: false,
        lymphadenopathy: false,
      }),
      patient_chest_history_attributes: this.formBuilder.group({
        id: '',
        chest_defermity: false,
        breathing: false,
        vesicular: false,
        bronchial: false,
        added_sounds: false,
        rhonchi: false,
        wheeeze: false,
        crepitations: false,
      }),
      patient_abdomen_history_attributes: this.formBuilder.group({
        id: '',
        soft: false,
        distended: false,
        ascities: false,
        bowel_sounds: false,
        hepatomegaly: false,
        spleenomegaly: false,
        inguinal_hernia: false,
        umbilical: false,
        tenderness: false,
      }),
    });
  }
  getFormData(): any {
    return this.physicalSystemicExaminationHistoryForm.value;
  }

  patchphysicalExaminationData(): any{
    if (this.getTemplateData){
      const dataPhySysExam = this.getTemplateData;
      if (dataPhySysExam) {
        this.physicalSystemicExaminationHistoryForm.get('id').patchValue(dataPhySysExam.id);
        if (dataPhySysExam.patient_gpe_history_attributes) {
          this.physicalSystemicExaminationHistoryForm
            .get('patient_gpe_history_attributes')
            .patchValue({
              // id: dataPhySysExam.patient_gpe_history_attributes.id || '',
              pallor:
                dataPhySysExam.patient_gpe_history_attributes.pallor ||
                false,
              cyanosis:
                dataPhySysExam.patient_gpe_history_attributes.cyanosis ||
                false,
              pedal:
                dataPhySysExam.patient_gpe_history_attributes.pedal ||
                false,
              clubbing:
                dataPhySysExam.patient_gpe_history_attributes.clubbing ||
                false,
              rash:
                dataPhySysExam.patient_gpe_history_attributes.rash || false,
              peropheral_vessel_palpable:
                dataPhySysExam.patient_gpe_history_attributes
                  .peropheral_vessel_palpable || false,
              lymphadenopathy:
                dataPhySysExam.patient_gpe_history_attributes
                  .lymphadenopathy || false,
            });
        }
        if (dataPhySysExam.patient_chest_history_attributes) {
          this.physicalSystemicExaminationHistoryForm
            .get('patient_chest_history_attributes')
            .patchValue({
              // id: dataPhySysExam.patient_chest_history_attributes.id || '',
              chest_defermity:
                dataPhySysExam.patient_chest_history_attributes
                  .chest_defermity || false,
              breathing:
                dataPhySysExam.patient_chest_history_attributes.breathing ||
                false,
              vesicular:
                dataPhySysExam.patient_chest_history_attributes.vesicular ||
                false,
              bronchial:
                dataPhySysExam.patient_chest_history_attributes.bronchial ||
                false,
              added_sounds:
                dataPhySysExam.patient_chest_history_attributes
                  .added_sounds || false,
              rhonchi:
                dataPhySysExam.patient_chest_history_attributes.rhonchi ||
                false,
              wheeeze:
                dataPhySysExam.patient_chest_history_attributes.wheeeze ||
                false,
              crepitations:
                dataPhySysExam.patient_chest_history_attributes
                  .crepitations || false,
            });
        }
        if (dataPhySysExam.patient_abdomen_history_attributes) {
          this.physicalSystemicExaminationHistoryForm
            .get('patient_abdomen_history_attributes')
            .patchValue({
              soft:
                dataPhySysExam.patient_abdomen_history_attributes.soft ||
                false,
              distended:
                dataPhySysExam.patient_abdomen_history_attributes
                  .distended || false,
              ascities:
                dataPhySysExam.patient_abdomen_history_attributes
                  .ascities || false,
              bowel_sounds:
                dataPhySysExam.patient_abdomen_history_attributes
                  .bowel_sounds || false,
              hepatomegaly:
                dataPhySysExam.patient_abdomen_history_attributes
                  .hepatomegaly || false,
              spleenomegaly:
                dataPhySysExam.patient_abdomen_history_attributes
                  .spleenomegaly || false,
              inguinal_hernia:
                dataPhySysExam.patient_abdomen_history_attributes
                  .inguinal_hernia || false,
              umbilical:
                dataPhySysExam.patient_abdomen_history_attributes
                  .umbilical || false,
              tenderness:
                dataPhySysExam.patient_abdomen_history_attributes
                  .tenderness || false,
            });
        }
      }
    }
  }

  sendData(data): any {
    this.getTemplateData = data;
    this.patchphysicalExaminationData();
  }

}
