import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.css'],
})
export class UserVerificationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.queryParams.user_id) {
      this.ngxLoader.start();
      this.authService
        .userAuthenticate(this.route.snapshot.queryParams.user_id)
        .subscribe(
          (data) => {
            localStorage.setItem('currentUser', JSON.stringify(data.user));
            localStorage.setItem('auth_token', data.user.authentication_token);
            localStorage.setItem(
              'current_medical_unit_id',
              data.user.medical_units[0].id,
            );
            localStorage.setItem(
              'currentScreen',
              JSON.stringify(data.user.role.role_menus[0]),
            );
            this.router.navigate([
              this.route.snapshot.queryParams.redirecturl || 'dashboard',
            ]);
            this.ngxLoader.stop();
          },
          (error) => {
            this.ngxLoader.stop();
            this.router.navigate(['/login']);
          },
        );
    } else {
      this.router.navigate(['/login']);
    }
  }
}
